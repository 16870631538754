import {
  Form,
  Input,
  PopConfirm,
  Select,
  CustomFilter,
  EllipsisSpan,
  Paging,
  Table,
  Wrapper,
  useBreadcrumbRoutes,
  usePaging,
  Button,
} from '@maxtropy/components';
import { Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {
  E_PRICE_SCOPE_MAP,
  EPriceQueryParams,
  EPriceRecord,
  EPriceScope,
  fetchEPricePage,
  removeEPrice,
} from '../../api/electricityPrice';
import { fetchOuList, getOuId, Ou } from '../../api/ou';
import { PermissionsType } from '../../common/permissionsConst';
import { useHasPermission } from '../../utils/hooks';
import { PlusOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

interface ElectricityPriceManageProps {}

const ElectricityPriceManage: React.FC<ElectricityPriceManageProps> = () => {
  const routesContext = useBreadcrumbRoutes();
  const queryClient = useQueryClient();
  const [form] = useForm();
  const [searchParams, setSearchParams] = useState<EPriceQueryParams>();
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const { data, isLoading, refetch } = useQuery(['ePricePage', searchParams, pageSize, pageOffset], () =>
    fetchEPricePage({ ...searchParams, page: pageOffset, size: pageSize })
  );
  const { data: ouList } = useQuery('ouList', async () => {
    const ouType = (await getOuId())?.filter(i => i.key === 'MICRONET')[0]?.id;
    if (!ouType) return [];
    return fetchOuList(ouType);
  });

  const ouOptions = useMemo(() => {
    if (ouList && ouList.length !== 0) {
      return ouList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [ouList]);

  useEffect(() => {
    if (data) {
      setTotalCount(data.total);
    }
  }, [data, setTotalCount]);

  const hasCreatePermission = useHasPermission(PermissionsType.B_CREATEEPRICE);
  const hasDeletePermission = useHasPermission(PermissionsType.B_DELETEEPRICE);
  const hasEditPermission = useHasPermission(PermissionsType.B_UPDATEEPRICE);

  const actionColumn: ColumnType<EPriceRecord> = {
    title: '操作',
    dataIndex: 'action',
    width: 200,
    fixed: 'right' as const,
    render: (_, record) => (
      <Space size={16}>
        {hasEditPermission && record.isAdmin && (
          <Button
            type="link"
            onClick={() => {
              window.open(`/dmes/circuit/e-price/${record.id}/edit`, '_self');
            }}
          >
            编辑
          </Button>
        )}
        <Button
          type="link"
          onClick={() => {
            window.open(`/dmes/circuit/e-price/${record.id}`, '_self');
          }}
        >
          查看
        </Button>
        {hasDeletePermission && record.isAdmin && (
          <PopConfirm
            title="确定移除电价？"
            onConfirm={() => {
              removeEPrice(record.id).then(() => {
                queryClient.invalidateQueries('ePricePage');
              });
            }}
            okText="确定"
            cancelText="取消"
          >
            <Button type="link">删除</Button>
          </PopConfirm>
        )}
      </Space>
    ),
  };

  const filters = (
    <CustomFilter
      form={form}
      onFinish={(values: any) => {
        setPageOffset(1);
        setSearchParams(values);
        refetch();
      }}
      onReset={() => {
        setSearchParams(undefined);
        setPageOffset(1);
        refetch();
      }}
    >
      <Form.Item name="codeOrName" label="编号/名称">
        <Input placeholder={'请输入编号或名称查询'} />
      </Form.Item>
      <Form.Item name="ouId" label="所属运营单元">
        <Select placeholder="请选择" allowClear options={ouOptions} showSearch optionFilterProp="label" />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper routes={routesContext?.routes} filters={filters}>
      {hasCreatePermission && (
        <div style={{ marginBottom: 10 }}>
          <Button
            type="primary"
            onClick={() => {
              window.open('/dmes/circuit/e-price/new', '_self');
            }}
          >
            <PlusOutlined />
            新建电价
          </Button>
        </div>
      )}
      <Table
        loading={isLoading}
        sticky
        rowKey="id"
        scroll={{ x: 1500 }}
        columns={[...columns, actionColumn]}
        dataSource={data?.list}
      />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

const columns: ColumnType<EPriceRecord>[] = [
  {
    title: '电价编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '电价名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '电价范围',
    dataIndex: 'scopeType',
    ellipsis: { showTitle: true },
    render: (v: EPriceScope) => <EllipsisSpan value={E_PRICE_SCOPE_MAP[v]} />,
  },
  {
    title: '所属运营单元',
    dataIndex: 'ou',
    ellipsis: { showTitle: true },
    render: (v: Ou) => <EllipsisSpan value={v?.name} />,
  },
  {
    title: '使用数量',
    dataIndex: 'usageAmount',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'updateByUsername',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export default ElectricityPriceManage;
