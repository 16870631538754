import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import styles from './index.module.scss';
import { DatePicker, Radio, message } from '@maxtropy/components';

const { RangePicker } = DatePicker;

export enum DataType {
  HOUR = 1,
  DAY,
  MONTH,
}

const DATA_TYPE_DISPLAY = {
  [DataType.HOUR]: '按小时统计',
  [DataType.DAY]: '按日统计',
  [DataType.MONTH]: '按月统计',
};

export interface QueryParams {
  dataType: DataType;
  from: number;
  to: number;
}

export interface PickerProps extends QueryParams {
  onChange: (params: Partial<QueryParams>) => void;
  start: string;
}

const Picker: React.FC<PickerProps> = ({ dataType, from, to, onChange }) => {
  const disabledDate = (current: Dayjs) => {
    return current && current.valueOf() >= dayjs().endOf('day').valueOf();
    // || current.valueOf() < dayjs(start).startOf('day').valueOf());
  };
  return (
    <div className={styles.picker}>
      <Radio.Group
        buttonStyle="solid"
        value={dataType}
        onChange={e => {
          let from = 0;
          let to = 0;
          if (e.target.value === DataType.HOUR) {
            from = dayjs().startOf('day').valueOf();
            to = dayjs().endOf('day').valueOf();
          } else if (e.target.value === DataType.DAY) {
            from = dayjs().subtract(30, 'days').startOf('day').valueOf();
            to = dayjs().endOf('day').valueOf();
          } else {
            from = dayjs().subtract(11, 'months').startOf('month').valueOf();
            to = dayjs().endOf('month').valueOf();
          }
          onChange({ dataType: e.target.value, from, to });
        }}
      >
        {Object.entries(DATA_TYPE_DISPLAY).map(([k, v]) => (
          <Radio.Button key={k} value={+k}>
            {v}
          </Radio.Button>
        ))}
      </Radio.Group>

      <div className={styles.datePickerArea}>
        <span className={styles.label}>选择日期</span>
        <RangePicker
          value={[dayjs(from), dayjs(to)]}
          disabledDate={disabledDate}
          onChange={e => {
            const value = e as [dayjs.Dayjs, dayjs.Dayjs];
            let from = 0;
            let to = 0;
            if (dataType === DataType.HOUR) {
              if (value[1].endOf('day').diff(value[0].startOf('day'), 'days', true) > 7) {
                return message.error('最大跨度为7天');
              }
              from = value[0].startOf('day').valueOf();
              to = value[1].endOf('day').valueOf();
            } else if (dataType === DataType.DAY) {
              if (value[1].endOf('day').diff(value[0].startOf('day'), 'days', true) > 31) {
                return message.error('最大跨度为31天');
              }
              from = value[0].startOf('day').valueOf();
              to = value[1].endOf('day').valueOf();
            } else {
              if (value[1].endOf('month').diff(value[0].startOf('month'), 'years', true) > 3) {
                return message.error('最大跨度为3年');
              }
              from = value[0].startOf('month').valueOf();
              to = value[1].endOf('month').valueOf();
            }
            onChange({ from, to });
          }}
          picker={dataType === DataType.MONTH ? 'month' : 'date'}
          allowClear={false}
          getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
        />
      </div>
    </div>
  );
};

export default Picker;
