import { CircuitTypeFormat } from '@/api/circuit';
import { CircuitLossRes } from '@/api/lineLoss';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Row, Space } from 'antd';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from '../index.module.scss';
import NextCircuitModal from './NextCircuitModal';
import { Button, Form } from '@maxtropy/components';

const LossBaseInfo: React.FC<{ data?: CircuitLossRes }> = ({ data }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [nextCircuitVisible, setNextCircuitVisible] = useState(false);
  return (
    <Form>
      <Row>
        <Col span={24}>
          <Form.Item label="回路名称">
            <Space size={10}>
              <div>{data?.circuitName ?? '--'}</div>
              {!data?.circuitConfig && (
                <Space size={10}>
                  <div>回路配置未完成</div>
                  <div>
                    <Button type="link" onClick={() => navigate(`/dmes/circuit/circuit/configure/${id}`)}>
                      点击前往配置
                    </Button>
                  </div>
                </Space>
              )}
            </Space>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="回路类型">{data?.circuitType ? CircuitTypeFormat[data.circuitType] : '--'}</Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="下一级回路数量">{data?.downstreamCircuitCount ?? '--'}</Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="下一级回路所需配置项配置情况">
            <Space size={30} align="start">
              <div>
                <Space>
                  <div>{data?.downstreamCircuitConfig ? '已完成回路配置' : '回路配置未完成'}</div>
                  <div>
                    <Button type="link" onClick={() => setNextCircuitVisible(true)}>
                      点击查看
                    </Button>
                  </div>
                </Space>
                <div className={styles.tips_text}>
                  <InfoCircleOutlined style={{ color: '#F5AC00' }} />
                  <span className={styles.txt}>
                    若存在未配置的配置项, 请先前往回路列表中, 对回路进行配置, 否则线损计算会不完整。
                  </span>
                </div>
              </div>
            </Space>
          </Form.Item>
        </Col>
      </Row>
      {nextCircuitVisible && <NextCircuitModal onCancel={() => setNextCircuitVisible(false)} />}
    </Form>
  );
};

export default LossBaseInfo;
