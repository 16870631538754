import {
  Button,
  CustomFilter,
  EllipsisSpan,
  Form,
  Input,
  Modal,
  Paging,
  Select,
  Table,
  usePaging,
  useUpdate,
} from '@maxtropy/components';
import {
  V2BsaSecondEditionFcsPagePostResponse,
  V2DispatchUetStructFcsPagePostResponse,
  apiV2BsaSecondEditionFcsPagePost,
  apiV2BsaSecondEditionOuListPost,
  apiV2BsaSecondEditionSoftwareVersionListPost,
} from '@maxtropy/dmes-apis-v2';
import { useRequest } from 'ahooks';
import { Col, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { isNil } from 'lodash-es';

// fcs类型
export enum FcsType {
  BESS1POINT0 = 1,
  BESS2POINT0 = 2,
}

export const FcsTypeLabel = {
  [FcsType.BESS1POINT0]: 'BESS-1.0',
  [FcsType.BESS2POINT0]: 'BESS-2.0',
};

interface SearchParams {
  codeOrName?: string;
  ouId?: number;
  softwareVersion?: number;
}

export type RowInfo = Exclude<V2BsaSecondEditionFcsPagePostResponse['list'], undefined>[number];

interface Iprops {
  onConfirm: (fcsId?: number) => void;
  onCancel: () => void;
  bindFcsId?: number;
  bsaId?: number;
}

const columns = [
  {
    title: 'FCS编号',
    dataIndex: 'serialNumber',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: 'FCS名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: 'FCS类型',
    dataIndex: 'type',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={!isNil(v) ? FcsTypeLabel[v as FcsType] : '--'} />,
  },
  {
    title: '软件版本',
    dataIndex: 'softwareVersion',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '运营单元',
    dataIndex: 'ouName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const FcsSelectModal = (props: Iprops) => {
  const { bindFcsId, bsaId } = props;
  const [form] = Form.useForm<SearchParams>();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [update, setUpdate] = useUpdate();
  // const [currentRow, setCurrentRow] = useState<RowInfo>();
  const [currentRowKey, setCurrentRowKey] = useState<number>();

  useEffect(() => {
    if (bindFcsId) {
      setCurrentRowKey(bindFcsId);
    }
  }, [bindFcsId]);

  // ou下拉列表
  const { data: ouList } = useRequest(
    async () => {
      const res = await apiV2BsaSecondEditionOuListPost({
        id: Number(bsaId),
      });
      return res.list;
    },
    {
      ready: !!bsaId,
      refreshDeps: [bsaId],
    }
  );

  // 查询软件版本
  const { data: softWareList } = useRequest(
    async () => {
      const res = await apiV2BsaSecondEditionSoftwareVersionListPost({
        id: Number(bsaId),
      });
      return res.list;
    },
    {
      ready: !!bsaId,
      refreshDeps: [bsaId],
    }
  );

  // 表格
  const { data: list, loading } = useRequest(
    async () => {
      const softwareVersion = form.getFieldValue('softwareVersion');
      const res = await apiV2BsaSecondEditionFcsPagePost({
        ...form.getFieldsValue(),
        softwareVersion: !isNil(softwareVersion) ? [softwareVersion] : undefined,
        size: pageSize,
        page: pageOffset,
        neoBsaId: bsaId,
      });
      setTotalCount(res.total ?? 0);
      return res.list;
    },
    {
      ready: !!bsaId,
      refreshDeps: [update, pageOffset, pageSize, bsaId],
    }
  );

  const searchBtn = () => {
    setUpdate();
    setPageOffset(1);
  };
  const resetBtn = () => {
    form.resetFields();
    setUpdate();
    setPageOffset(1);
  };

  return (
    <Modal
      title="FCS选择"
      // width={640}
      size="big"
      open={true}
      onOk={() => props.onConfirm?.(currentRowKey)}
      onCancel={() => props.onCancel?.()}
    >
      <CustomFilter form={form} onFinish={searchBtn} onReset={resetBtn}>
        <Form.Item label="名称/编号" name="codeOrName">
          <Input placeholder="请输入名称/编号查询" />
        </Form.Item>

        <Form.Item label="软件版本" name="softwareVersion">
          <Select placeholder="请选择软件版本" options={softWareList?.map(item => ({ label: item, value: item }))} />
        </Form.Item>

        <Form.Item label="运营单元" name="ouId">
          <Select placeholder="请选择运营单元" options={ouList?.map(item => ({ label: item.name, value: item.id }))} />
        </Form.Item>
      </CustomFilter>
      <div className={styles.action_line}>
        点击前往
        <span
          onClick={() => window.open(`${window.IOTPLATFORMORIGIN ?? ''}/device/fcs/list/create`, '_blank')}
          className={styles.click_text}
        >
          添加FCS
        </span>
      </div>
      <Table
        loading={loading}
        rowKey="id"
        dataSource={list}
        columns={[...columns]}
        scroll={{ y: 300 }}
        rowSelection={{
          type: 'radio',
          onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
            setCurrentRowKey(selectedRowKeys[0] as number);
            // setCurrentRow(selectedRows[0] as RowInfo);
          },
          selectedRowKeys: currentRowKey ? [currentRowKey] : [],
          getCheckboxProps: (record: RowInfo) => ({
            // 被绑定的不能选择(除了自己)
            disabled: record.id !== bindFcsId && record.binding,
          }),
        }}
        pagination={false}
      />
      <Paging pagingInfo={pagingInfo} />
    </Modal>
  );
};

export default FcsSelectModal;
