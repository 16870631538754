import React, { useEffect } from 'react';
import { Space } from 'antd';
import { createInverter, InverterPayload, PVInverter, pvInverterKeys, updateInverter } from '@/api/pv-inverter';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { combinerBoxKeys, getCombinerBoxes } from '@/api/pv';
import { Button, Form, Input, Modal, Select } from '@maxtropy/components';

export interface PVInverterFormModalProps {
  pvId: number;
  row?: PVInverter;
  isDraft: boolean;
  isDisable: boolean;
  onCancel: () => void;
  onOk: () => void;
  onNextStep: (payload: InverterPayload) => void;
}

const PVInverterFormModal: React.FC<PVInverterFormModalProps> = ({
  row,
  onCancel,
  isDraft,
  isDisable,
  pvId,
  onNextStep,
  onOk,
}) => {
  const [form] = Form.useForm();

  const queryClient = useQueryClient();
  const { mutate: createOrUpdate, isLoading } = useMutation(row ? updateInverter : createInverter, {
    onSuccess: () => queryClient.invalidateQueries(pvInverterKeys.all),
  });
  const { data: combinerBoxes } = useQuery(combinerBoxKeys.list(pvId), () => getCombinerBoxes(pvId));

  useEffect(() => {
    if (row) {
      form.setFieldsValue({
        ...row,
      });
    }
  }, [form, row]);

  return (
    <Modal
      open
      title={`${!!row ? '编辑' : '添加'}逆变器`}
      maskClosable={false}
      contentStyle={{ padding: '32px 72px 8px' }}
      onCancel={onCancel}
      footer={
        <Space>
          <Button onClick={onCancel}>取消</Button>
          {!row && (isDraft || isDisable) && (
            <Button
              loading={isLoading}
              type={'primary'}
              onClick={() => form.validateFields().then(values => onNextStep({ ...values, pvId }))}
            >
              下一步
            </Button>
          )}
          <Button
            loading={isLoading}
            type="primary"
            onClick={() =>
              form.validateFields().then(values => createOrUpdate({ ...row, ...values, pvId }, { onSuccess: onOk }))
            }
          >
            确定
          </Button>
        </Space>
      }
    >
      <Form form={form}>
        <Form.Item
          name={'code'}
          label={'逆变器编号'}
          rules={[
            { required: true },
            { max: 20, message: '最多20个字符' },
            { pattern: /^[A-Za-z0-9\\-]{1,20}$/, message: '仅能够输入数字、英文大小写和英文状态下的-' },
          ]}
        >
          <Input placeholder={'请输入'} disabled={!(isDraft || isDisable)} />
        </Form.Item>
        <Form.Item
          name={'combinerBoxId'}
          label={'关联汇流箱'}
          rules={[{ required: true, message: '请选择关联汇流箱' }]}
        >
          <Select placeholder={'请选择'}>
            {combinerBoxes?.map(item => (
              <Select.Option key={item.id} value={item.id}>
                {item.code}
                {/*<Row gutter={10}>*/}
                {/*  <Col span={8} style={{ overflow: "hidden", textOverflow: "ellipsis" }}>*/}
                {/*    {item.code}*/}
                {/*  </Col>*/}
                {/*  <Col span={8} style={{ overflow: "hidden", textOverflow: "ellipsis" }}>*/}
                {/*    {item.deviceCode ?? "--"}*/}
                {/*  </Col>*/}
                {/*  <Col span={8} style={{ overflow: "hidden", textOverflow: "ellipsis" }}>*/}
                {/*    {item.deviceName ?? "--"}*/}
                {/*  </Col>*/}
                {/*</Row>*/}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PVInverterFormModal;
