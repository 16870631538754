import React, { CSSProperties } from 'react';
import styles from './index.module.scss';
import tittleDecoration from '../../imgs/tittleDecoration.png';
import classNames from 'classnames';

export interface CardBoxProps {
  title?: React.ReactNode;
  children: React.ReactNode;
  extraContent?: React.ReactNode;
  style?: CSSProperties;
  showIcon?: boolean;
  className?: string;
}

const CardBox: React.FC<CardBoxProps> = props => {
  const { title, style, extraContent, showIcon = true, className } = props;

  return (
    <div className={styles.personalFormContainer} style={style}>
      <div className={styles.headerWrapper}>
        {title ? (
          <div className={styles.formTitle}>
            {showIcon && <img src={tittleDecoration} alt="pic" className={styles.titleBorder} />}

            {title}
          </div>
        ) : (
          <div />
        )}
        {extraContent ? extraContent : <div />}
      </div>
      <div className={styles.borderStyles} />

      <div className={classNames(styles.content, className)}>{props.children}</div>
    </div>
  );
};

export default CardBox;
