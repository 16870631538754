import React, { useEffect, useRef } from 'react';
import G6, { NodeConfig, TreeGraph } from '@antv/g6';
import ResizeObserver from 'rc-resize-observer';
import { graphsProps } from '@/pages/CircuitManage/components/CircuitTopologicalGraghTree';
import { nodeGap, nodeSize, registerG6 } from './registerG6';
import { Modal } from '@maxtropy/components';

interface Props {
  circuit: graphsProps;
  focusId?: number;
  setCurrentCircuitInfo: (v: any) => void;
  setCircuitDetailVisible: (v: boolean) => void;
}

const TopologicalGraph: React.FC<Props> = ({ circuit, focusId, setCurrentCircuitInfo, setCircuitDetailVisible }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const graphRef = useRef<TreeGraph>();

  useEffect(() => {
    registerG6();
  }, []);

  useEffect(() => {
    if (!containerRef.current || !circuit) return;
    const graph = new G6.TreeGraph({
      container: containerRef.current,
      linkCenter: true,
      width: containerRef.current.scrollWidth,
      height: containerRef.current.scrollHeight,
      modes: {
        default: ['drag-canvas', 'zoom-canvas', 'click-select'],
      },
      defaultNode: {
        type: 'customNode',
      },
      defaultEdge: {
        type: 'customLine',
        style: {
          endArrow: true,
        },
      },
      layout: {
        type: 'indented',
        direction: 'LR',
        dropCap: false,
        indent: nodeSize[0] + nodeGap[0],
        getHeight: () => {
          return nodeGap[1];
        },
      },
    });
    graphRef.current = graph;
    graph.data(circuit);
    graph.render();
    graph.fitView([20]);

    const focusNode = graph.getNodes().find(item => {
      return item.getModel().id === String(focusId);
    });
    focusNode?.setState('isSelected', true);

    graph.on('node:click', e => {
      // 展开收缩节点按钮
      if (e.target.get('name') === 'collapse-circle' || e.target.get('name') === 'collapse-text') {
        e.item!.getModel().collapsed = !e.item!.getModel().collapsed;
        // @ts-ignore
        graph.setItemState(e.item!, 'collapsed', e.item.getModel().collapsed);
        graph.layout();
      }
      // 点击节点事件
      if (e.target.get('name') === 'main-box' || e.target.get('name') === 'name') {
        graph.getNodes().forEach(node => node.setState('isSelected', false));
        e.item!.getModel().isSelected = true;

        const data = e.item?.getModel() as graphsProps & NodeConfig;
        if (!data.value.hasOu) {
          setCurrentCircuitInfo(undefined);
          Modal.warning({
            title: `当前无【${data.value.name}】的数据权限，请联系管理员添加。`,
          });
        } else {
          setCurrentCircuitInfo(data);
          setCircuitDetailVisible(true);
        }
      }
    });

    // hover事件
    graph.on('node:mouseenter', e => {
      graph.setItemState(e.item!, 'hover', true);
    });
    graph.on('node:mouseleave', e => {
      graph.setItemState(e.item!, 'hover', false);
    });

    return () => {
      graph.destroy();
    };
  }, [circuit, containerRef]);

  const onResize = (size: { width: number; height: number }) => {
    if (!containerRef.current) return;
    if (!graphRef.current) return;
    graphRef.current?.changeSize(size.width, size.height);
  };

  return (
    <ResizeObserver onResize={onResize}>
      <div
        style={{
          height: 'calc(100% - 60px)',
        }}
        ref={containerRef}
      ></div>
    </ResizeObserver>
  );
};

export default TopologicalGraph;
