import React, { useCallback, useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import { Form, Input, PopConfirm, Radio, EllipsisSpan, Table, Tabs, Button } from '@maxtropy/components';
import { ColumnType } from 'antd/es/table';
import {
  BsaConfig,
  BsaDataConfig,
  BsaDataConfigType,
  BsaDeviceResponse,
  DataConfigItem,
  Device,
} from '../../api/bsaConfig';
import dayjs from 'dayjs';
import DataFormModal from './DataFormModal';

const { TabPane } = Tabs;

export const tabsConfig = [
  {
    key: 'airCondition',
    name: '空调数据',
    canByDevice: true,
  },
  {
    key: 'fireControl',
    name: '消防数据',
    canByDevice: true,
  },
  {
    key: 'pcs',
    name: 'PCS数据',
    canByDevice: false,
  },
  {
    key: 'stack',
    name: '电池堆数据',
    canByDevice: false,
  },
  {
    key: 'rack',
    name: '电池簇数据',
    canByDevice: false,
  },
  {
    key: 'pack',
    name: '电池组数据',
    canByDevice: false,
  },
];

export interface DataCardConfigProps {
  devices: BsaDeviceResponse;
  config: BsaConfig;
  onChange: (config: BsaConfig) => void;
  errors?: any;
}

const DataCardConfig: React.FC<DataCardConfigProps> = ({ devices, config, onChange, errors }) => {
  const filteredTabsConfig = useMemo(() => {
    // FB/1.1版本不显示空调和消防数据
    if (config.fcsSoftwareVersion !== 'FB/1.1') return tabsConfig;
    return tabsConfig.slice(2);
  }, [config]);

  return (
    <Tabs>
      {filteredTabsConfig
        .filter(tab => devices[(tab.key + 'Devices') as keyof BsaDeviceResponse].length > 0)
        .map(tab => (
          <TabPane key={tab.key} tab={tab.name}>
            <TabContent
              dataKey={tab.key}
              devices={devices[(tab.key + 'Devices') as keyof BsaDeviceResponse]}
              config={config[(tab.key + 'Config') as keyof BsaConfig] as BsaDataConfig}
              name={tab.name}
              canByDevice={tab.canByDevice}
              onChange={changed => onChange({ ...config, [tab.key + 'Config']: changed })}
              errors={errors?.[tab.key + 'Error']}
            />
          </TabPane>
        ))}
    </Tabs>
  );
};

interface TabContentProps {
  name: string;
  devices: Device[];
  config?: BsaDataConfig;
  onChange: (config: BsaDataConfig) => void;
  canByDevice: boolean;
  dataKey: string;
  errors?: any;
}

const TabContent: React.FC<TabContentProps> = ({ dataKey, name, devices, config, onChange, canByDevice, errors }) => {
  const [row, setRow] = useState<DataConfigItem>();
  const [currentDeviceId, setCurrentDeviceId] = useState<number>();
  const [formModalVisible, setFormModalVisible] = useState(false);

  const onDataChange = useCallback(
    (changed: Partial<BsaDataConfig>) => {
      if (changed.deviceItems) {
        changed.deviceItems = devices
          .filter(item => changed.deviceItems?.some(device => device.deviceId === item.id))
          .map(item => changed.deviceItems?.find(device => device.deviceId === item.id)!);
      }
      onChange({ ...config, ...changed });
    },
    [config, onChange, devices]
  );

  const actionColumn: (id?: number) => ColumnType<DataConfigItem> = useCallback(
    id => ({
      title: '操作',
      dataIndex: 'action',
      width: 250,
      fixed: 'right' as const,
      render: (_, record) => (
        <div className="action-buttons">
          <PopConfirm
            okText="继续"
            title={'确定删除'}
            onConfirm={() => {
              if (id) {
                // 按设备
                const newDeviceItems = [...(config?.deviceItems || [])];
                const items = newDeviceItems.find(item => item.deviceId === id)!.items;
                items.splice(items.indexOf(record), 1);
                onDataChange({ deviceItems: newDeviceItems });
              } else {
                // 按类型
                const newTypeItems = [...(config?.typeItems || [])];
                newTypeItems.splice(newTypeItems.indexOf(record), 1);
                onDataChange({ typeItems: newTypeItems });
              }
            }}
          >
            <Button type="link">删除</Button>
          </PopConfirm>
          <Button
            type="link"
            onClick={() => {
              setRow(record);
              setCurrentDeviceId(id);
              setFormModalVisible(true);
            }}
          >
            编辑
          </Button>
        </div>
      ),
    }),
    [config?.deviceItems, config?.typeItems, onDataChange]
  );

  return (
    <>
      <div>
        {formModalVisible && (
          <DataFormModal
            deviceId={currentDeviceId}
            dataKey={dataKey}
            row={row}
            config={config}
            onCancel={() => {
              setRow(undefined);
              setCurrentDeviceId(undefined);
              setFormModalVisible(false);
            }}
            onOK={changed => {
              setRow(undefined);
              setCurrentDeviceId(undefined);
              setFormModalVisible(false);
              onDataChange(changed);
            }}
            name={name}
          />
        )}
        <Row>
          <Col span={12}>
            <Form.Item
              label={`是否显示${name}`}
              required
              validateStatus={!!errors?.display ? 'error' : undefined}
              help={errors?.display}
            >
              <Radio.Group value={config?.display} onChange={e => onDataChange({ display: e.target.value })}>
                <Radio value={false}>隐藏</Radio>
                <Radio value={true}>显示</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          {config?.display && (
            <>
              <Col span={12}>
                <Form.Item
                  label={`${name}卡片名称`}
                  required
                  validateStatus={!!errors?.name ? 'error' : undefined}
                  help={errors?.name}
                >
                  <Input
                    placeholder={'请输入'}
                    value={config?.name}
                    style={{ width: 400 }}
                    onChange={e => onDataChange({ name: e.target.value })}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                {canByDevice && (
                  <Form.Item
                    label={`配置模式`}
                    required
                    validateStatus={!!errors?.configType ? 'error' : undefined}
                    help={errors?.configType}
                  >
                    <Radio.Group
                      value={config?.configType}
                      onChange={e => onDataChange({ configType: e.target.value })}
                    >
                      {Object.entries(BsaDataConfigType.MAP).map(([k, v]) => (
                        <Radio key={k} value={+k}>
                          {v}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                )}
              </Col>
            </>
          )}
        </Row>

        {config?.display && (
          <>
            {!canByDevice || config?.configType === BsaDataConfigType.BY_TYPE ? (
              <>
                <Button
                  type={'primary'}
                  onClick={() => {
                    setFormModalVisible(true);
                  }}
                >{`新增${name}`}</Button>
                <Table
                  style={{ marginTop: 10 }}
                  sticky
                  rowKey="id"
                  scroll={{ x: 1500 }}
                  columns={[...columns, actionColumn()]}
                  dataSource={config?.typeItems}
                />
              </>
            ) : (
              devices.map(obj => {
                let device = config?.deviceItems?.find(item => item.deviceId === obj.id);
                return (
                  <>
                    <Form.Item
                      key={obj.id + 'radio'}
                      label={obj.name}
                      required
                      validateStatus={!!errors?.[obj.id] ? 'error' : undefined}
                      help={errors?.[obj.name]}
                    >
                      <Radio.Group
                        value={device?.display}
                        onChange={e => {
                          const newDeviceItems = [...(config?.deviceItems || [])];
                          if (device) {
                            newDeviceItems.splice(newDeviceItems.indexOf(device), 1, {
                              ...device,
                              display: e.target.value,
                            });
                          } else {
                            newDeviceItems.push({
                              deviceId: obj.id,
                              display: e.target.value,
                              deviceName: obj.name,
                              items: [],
                            });
                          }
                          onDataChange({ deviceItems: newDeviceItems });
                        }}
                      >
                        <Radio value={false}>隐藏</Radio>
                        <Radio value={true}>显示</Radio>
                      </Radio.Group>
                    </Form.Item>
                    {device?.display && (
                      <Button
                        key={obj.id + 'btn'}
                        type={'primary'}
                        onClick={() => {
                          setRow(row);
                          setCurrentDeviceId(obj.id);
                          setFormModalVisible(true);
                        }}
                      >{`新增显示数据`}</Button>
                    )}
                    {device?.display && (
                      <Table
                        key={device.deviceId + 'table'}
                        style={{ marginTop: 10 }}
                        sticky
                        rowKey="dataPropertyId"
                        scroll={{ x: 1500 }}
                        columns={[...columns, actionColumn(device.deviceId)]}
                        dataSource={[...device.items]}
                      />
                    )}
                  </>
                );
              })
            )}
          </>
        )}
      </div>
    </>
  );
};

const columns = [
  {
    title: '数据名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '显示权重',
    dataIndex: 'weight',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '数据属性',
    dataIndex: 'dataPropertyName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '修改时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm') : undefined} />,
  },
];

export default DataCardConfig;
