import { BsaDataStaticsGisRes, StationList } from '@/api/bsaMap';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import { FC, useEffect, useState } from 'react';
import { DetailInfoProps } from '..';
import DetailInfo from './components/DetailInfo';
import ListInfo from './components/ListInfo';
import styles from './index.module.scss';

const { Panel } = Collapse;

interface ILeftInfo {
  detailInfo?: DetailInfoProps;
  changeIsDetail: (id: number | undefined, showPosition: boolean, showDetails: boolean) => void;
  divHeight?: number;
  data?: BsaDataStaticsGisRes;
}

const LeftInfo: FC<ILeftInfo> = props => {
  const { detailInfo, changeIsDetail, divHeight, data } = props;
  const [itemInfo, setItemInfo] = useState<StationList>();

  useEffect(() => {
    if (data && detailInfo && detailInfo.id) {
      setItemInfo(data.list?.find(i => i.id === detailInfo.id));
    } else {
      setItemInfo(undefined);
    }
  }, [detailInfo, data]);

  return (
    <div className={styles.CollapseStyle}>
      <Collapse
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? -90 : 90} />}
      >
        <Panel header="储能站" key="1">
          {!detailInfo?.id || !detailInfo.showDetails ? (
            <ListInfo divHeight={divHeight} changeIsDetail={changeIsDetail} itemInfo={itemInfo} data={data} />
          ) : (
            <DetailInfo divHeight={divHeight} changeIsDetail={changeIsDetail} itemInfo={itemInfo} />
          )}
        </Panel>
      </Collapse>
    </div>
  );
};

export default LeftInfo;
