import React from 'react';
import { Space } from 'antd';
import useMergedState from './hooks/useMergedState';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { Dayjs } from 'dayjs';
import styles from './index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { DatePicker, Radio, TimePicker } from '@maxtropy/components';

export enum EffectiveTimeType {
  INSTANT = 1,
  SPECIFIED = 2,
}

export enum EffectiveTimeMode {
  BIND = 'bind',
  UNBIND = 'unbind',
}

const EffectiveTimeModeDisplay = {
  [EffectiveTimeMode.BIND]: '生效',
  [EffectiveTimeMode.UNBIND]: '解绑',
};

export interface EffectiveTimeValue {
  type?: EffectiveTimeType;
  date?: Dayjs | null;
  time?: Dayjs | null;
}

interface EffectiveTimePickerProps {
  mode: EffectiveTimeMode;
  value?: EffectiveTimeValue;
  onChange?: (value: EffectiveTimeValue) => void;
}

const format = 'HH:mm';

const EffectiveTimePicker: React.FC<EffectiveTimePickerProps> = props => {
  const { mode } = props;
  const [mergedValue, setMergedValue] = useMergedState({}, { value: props.value, onChange: props.onChange });

  const triggerChange = (changedValue: EffectiveTimeValue) => {
    setMergedValue?.({ ...changedValue });
  };

  const onRadioChange = (e: RadioChangeEvent) => {
    const value = e.target.value;
    if (value === EffectiveTimeType.INSTANT) {
      triggerChange({ type: EffectiveTimeType.INSTANT });
    } else {
      triggerChange({ type: EffectiveTimeType.SPECIFIED });
    }
  };

  const onDatePickerChange = (date: Dayjs | null) => {
    triggerChange({ ...mergedValue, date });
  };

  const onTimePickerChange = (time: Dayjs | null) => {
    triggerChange({ ...mergedValue, time });
  };

  return (
    <>
      <Radio.Group onChange={onRadioChange} value={mergedValue.type}>
        <Space direction="vertical">
          <Radio value={EffectiveTimeType.INSTANT}>立即{EffectiveTimeModeDisplay[mode]}</Radio>
          <Radio value={EffectiveTimeType.SPECIFIED}>
            <Space>
              <span>从指定时间{EffectiveTimeModeDisplay[mode]}</span>
              <DatePicker value={mergedValue.date} onChange={onDatePickerChange} />
              <TimePicker value={mergedValue.time} format={format} minuteStep={15} onChange={onTimePickerChange} />
            </Space>
          </Radio>
        </Space>
      </Radio.Group>
      {mode === EffectiveTimeMode.BIND ? (
        <div className={styles.extra}>
          <InfoCircleOutlined style={{ color: 'var(--warning-color)', marginRight: 4 }}></InfoCircleOutlined>
          <span>从该日期起监控指标数据将从新的设备/数据属性查询新的数据。</span>
        </div>
      ) : (
        <div className={styles.extra}>
          <InfoCircleOutlined style={{ color: 'var(--warning-color)', marginRight: 4 }}></InfoCircleOutlined>
          <span>从该日期起监控指标将不再从设备上查询新的数据</span>
        </div>
      )}
    </>
  );
};

export default EffectiveTimePicker;

export const getEffectiveTime = (value: EffectiveTimeValue): number => {
  if (value.type === EffectiveTimeType.INSTANT) {
    const minute = Math.floor(dayjs().minute() / 15) * 15;
    return dayjs()
      .minute(minute + 15)
      .second(0)
      .unix();
  } else {
    return value
      .date!.set('hour', value.time ? value.time.get('hour') : 0)
      .set('minute', value.time ? value.time.get('minute') : 0)
      .set('second', 0)
      .unix();
  }
};
