import './public-path';
import { Fragment, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.scss';
import { isDev, ThemeProvider, Themes, microAppRewrite } from '@maxtropy/components';
import { setupVersions } from '@maxtropy/strategy';

import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');

microAppRewrite();

setupVersions();

const root = createRoot(document.getElementById('root') as Element);
const StrictModeComponent = isDev() ? Fragment : StrictMode;

root.render(
  <StrictModeComponent>
    <ThemeProvider theme={Themes.DARK}>
      <App />
    </ThemeProvider>
  </StrictModeComponent>
);
