import React, { FC } from 'react';
import styles from '@/pages/PvRunningOverview/TodayData/index.module.scss';
import classnames from 'classnames';
import ReactEcharts from 'echarts-for-react';
import circle from '../images/todayDataCircle.png';
import { customFixed } from '@/utils/utils';

const TargetCompletion: FC<{
  title: string;
  value: number | undefined;
  percent: number | undefined;
  className?: string;
  style?: React.CSSProperties;
}> = props => {
  const { title, value, percent, className, style } = props;

  const gaugeData = [
    {
      value: percent && percent * 100,
    },
  ];
  const option = {
    series: [
      {
        type: 'gauge',
        radius: '100%',
        startAngle: 90,
        endAngle: -270,
        pointer: {
          show: false,
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: '#92ED79',
                },
                {
                  offset: 1,
                  color: '#59D744',
                },
              ],
              global: false,
            },
          },
        },
        axisLine: {
          lineStyle: {
            width: 6,
            color: [[1, '#28323f']],
          },
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        detail: {
          show: false,
        },
        data: gaugeData,
      },
    ],
  };

  return (
    <div className={classnames(styles.targetCompletion, className)} style={style}>
      <div className={styles.chartWrap}>
        <ReactEcharts
          option={option}
          style={{
            height: '100%',
            width: '100%',
          }}
          className={styles.absoluteCenter}
        ></ReactEcharts>
        <img
          className={styles.absoluteCenter}
          src={circle}
          style={{
            width: 66,
            height: 66,
          }}
        />
        <div className={classnames(styles.label, styles.absoluteCenter)}>
          <div style={{ marginBottom: 3 }}>{customFixed(percent! * 100)}%</div>
          <div style={{ color: 'var(--mx-text-secondary-color)' }}>完成率</div>
        </div>
      </div>
      <div className={styles.right}>
        <div>{title}</div>
        <div className={styles.value}>{customFixed(value)}</div>
        <div>kWh</div>
      </div>
    </div>
  );
};

export default TargetCompletion;
