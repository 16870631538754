import { FC, useContext, useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { queryMonitoringIndicatorList, StatisticsPartition } from '@/api/circuit';
import { getCircuitCumulative } from '@/api/device';
import dayjs from 'dayjs';
import { DataNameType } from '@/api/const';
import { FormatterItem } from '@/pages/Statistics/components/Chart/helper';
import { isNil } from 'lodash-es';
import { Empty } from '@maxtropy/components';
import { ScaleContext } from '@/pages/InComingOverview';
import { TypeAction } from '../SettingModal';
import { apiV2CircuitDataEnergyAnalysisPost } from '@maxtropy/dmes-apis-v2';

interface Iprops {
  currentCircuit?: number; // 回路id
  openTimer: (isOver: boolean) => void;
  timerState?: number;
  settingType?: TypeAction;
}

interface StackChartData {
  xAxisData?: string[];
  seriesData?: (number | undefined)[][];
}

const propertyList = [
  { type: '尖电量', id: 10122 },
  { type: '峰电量', id: 10123 },
  { type: '平电量', id: 10124 },
  { type: '谷电量', id: 10125 },
];

const PeakValleyAnalysis: FC<Iprops> = ({ currentCircuit, openTimer, timerState, settingType }) => {
  const scale = useContext(ScaleContext);
  const [chartData, setChartData] = useState<StackChartData>();
  const [totalKwh, setTotalKwh] = useState<number>(0);

  useEffect(() => {
    (async () => {
      // 原始值
      if (settingType === TypeAction.ORIGIN) {
        if (currentCircuit) {
          openTimer?.(false);
          const response = await queryMonitoringIndicatorList(String(currentCircuit))
            .then(res => res)
            .catch(_ => []);
          let deviceInfo = response?.find(m => m.circuitPhysicalQuantityId === DataNameType.EP);
          if (deviceInfo) {
            const cumulativeData = await getCircuitCumulative({
              deviceIds: deviceInfo.deviceId,
              propertyIds: propertyList.map(m => m.id).join(),
              resolution: StatisticsPartition.DAY,
              startTime: dayjs().subtract(1, 'month').startOf('month').valueOf(),
              endTime: dayjs().endOf('month').valueOf(),
            })
              .then(res => res)
              .catch(_ => []);
            let seriesData: number[][] = [];
            let totalElectricity = 0;
            propertyList.forEach(property => {
              let keyData = cumulativeData.find(item => item.propertyId === property.id);
              if (keyData) {
                let keyTotal =
                  keyData?.values?.reduce((acc, i) => {
                    return (acc += i.value ?? 0);
                  }, 0) || 0;
                totalElectricity += keyTotal;
                seriesData.push(keyData?.values?.map(i => i.value) || []);
              }
            });
            setTotalKwh(totalElectricity);
            let xAxisData = cumulativeData[0]?.values?.map(i => dayjs(i.time).format('MM-DD'));
            setChartData({ xAxisData, seriesData });
            openTimer?.(true);
          } else {
            openTimer?.(true);
          }
        }
      }
      // 计算值
      if (settingType === TypeAction.CALC) {
        if (currentCircuit) {
          openTimer?.(false);

          const cumulativeData = await apiV2CircuitDataEnergyAnalysisPost({
            circuitId: currentCircuit,
            from: dayjs().subtract(1, 'month').startOf('month').valueOf(),
            to: dayjs().endOf('month').valueOf(),
          })
            .then(res => res.list)
            .catch(_ => []);
          let seriesData: (number | undefined)[][] = [];
          let totalElectricity = 0;
          propertyList.forEach(property => {
            let keyData = cumulativeData?.find(item => item.propertyId === property.id);
            if (keyData) {
              let keyTotal =
                keyData?.values?.reduce((acc, i) => {
                  return (acc += i.value ?? 0);
                }, 0) || 0;
              totalElectricity += keyTotal;
              seriesData.push(keyData?.values?.map(i => i?.value) || []);
            }
          });
          setTotalKwh(totalElectricity);
          let xAxisData = cumulativeData?.[0]?.values?.map(i => dayjs(i.time).format('MM-DD'));
          setChartData({ xAxisData, seriesData });
          openTimer?.(true);
        } else {
          openTimer?.(true);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCircuit, timerState, settingType]);

  const getChartOption = () => {
    const colors = ['#0050B3', '#00ADFF', '#63BC7F', '#007CFF40'];
    return {
      grid: {
        left: 50,
        right: 20,
        top: 20,
        bottom: 70,
      },
      color: colors,
      tooltip: {
        trigger: 'axis',
        formatter(params: FormatterItem[]) {
          var relVal = params[0].name;
          for (var i = 0; i < params.length; i++) {
            let { marker, seriesName, value } = params[i];
            relVal += `<br/>${marker}${seriesName} : ${!isNil(value) ? Number(value.toFixed(2)) : '--'} kWh`;
          }
          return relVal;
        },
      },
      xAxis: {
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        type: 'category',
        data: chartData?.xAxisData,
        axisLabel: {},
      },
      yAxis: {
        type: 'value',
        name: 'kWh',
        nameTextStyle: {
          fontSize: 14,
          align: 'right',
        },
        nameGap: 30,
        splitLine: {
          lineStyle: {
            color: '#FFFFFF30',
          },
        },
      },
      legend: {
        show: true,
        y: 'bottom',
        x: 'center',
        itemHeight: 8,
        itemWidth: 8,
        data: ['尖电量', '峰电量', '', '平电量', '谷电量'],
        textStyle: {
          color: '#AFBCC4',
          rich: {
            name: {
              align: 'left',
              width: 60,
              color: 'rgba(255, 255, 255, 0.8)',
            },
            b: {
              align: 'left',
              width: 80,
              color: '#14D0E4',
            },
            v: {
              align: 'left',
              width: 60,
              color: '#FAAD14',
            },
          },
        },
        formatter: (name: string) => {
          let index = propertyList.findIndex(i => i.type === name);
          let total =
            chartData?.seriesData?.[index]?.reduce((acc, val) => {
              return (acc! += val ?? 0);
            }, 0) || 0;
          let percent = isNaN(total / totalKwh) ? '--' : ((total / totalKwh) * 100).toFixed(2);
          return `{name|${name}}{b|${Number(total.toFixed(2))}kWh}{v|${percent}%}`;
        },
        icon: 'rect',
        itemGap: 10,
      },
      series: [
        {
          type: 'line',
          stack: 'Total',
          color: '#59D744',
          symbol: 'none',
          emphasis: {
            focus: 'series',
          },
          areaStyle: {
            opacity: 0.4,
          },
          name: '谷电量',
          data: chartData?.seriesData?.[3],
        },
        {
          type: 'line',
          stack: 'Total',
          color: '#6DC8EC',
          emphasis: {
            focus: 'series',
          },
          symbol: 'none',
          areaStyle: {
            opacity: 0.4,
          },
          name: '平电量',
          data: chartData?.seriesData?.[2],
        },
        {
          type: 'line',
          stack: 'Total',
          color: '#FFD100',
          emphasis: {
            focus: 'series',
          },
          symbol: 'none',
          areaStyle: {
            opacity: 0.4,
          },
          name: '峰电量',
          data: chartData?.seriesData?.[1],
        },
        {
          type: 'line',
          stack: 'Total',
          color: '#E8684A',
          emphasis: {
            focus: 'series',
          },
          symbol: 'none',
          areaStyle: {
            opacity: 0.4,
          },
          name: '尖电量',
          data: chartData?.seriesData?.[0],
        },
      ],
    };
  };

  return (
    <div style={{ height: '90%' }}>
      {chartData?.xAxisData?.length ? (
        <ReactEcharts
          option={getChartOption()}
          style={{ height: '98%', zoom: 1 / scale }}
          notMerge
          lazyUpdate={false}
        />
      ) : (
        <Empty style={{ paddingTop: 40 }} description={'暂无数据'} />
      )}
    </div>
  );
};

export default PeakValleyAnalysis;
