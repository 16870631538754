import CircuitManage from '@/pages/CircuitManage';
import CircuitDetail from '@/pages/CircuitManage/Detail';
import { PermissionsType } from '@/common/permissionsConst';
import LineLossConfig from './LineLossConfig';
import CircuitTopologicalGraghTree from './components/CircuitTopologicalGraghTree';
const routes = [
  {
    path: '/dmes/circuit/circuit',
    permission: PermissionsType.P_CIRCUIT_LIST,
    element: <CircuitManage />,
  },
  {
    path: '/dmes/circuit/circuit/detail/:id',
    permission: PermissionsType.P_CIRCUIT_LIST,
    element: <CircuitDetail />,
  },
  {
    path: '/dmes/circuit/circuit/lineLoss/:id',
    element: <LineLossConfig />,
  },
  {
    path: '/dmes/circuit/circuit/lineLoss/:id/edit',
    element: <LineLossConfig isEdit />,
  },
  {
    path: '/dmes/circuit/topologicalGraph',
    permission: PermissionsType.P_CIRCUITTOPOLOGY,
    element: <CircuitTopologicalGraghTree />,
  },
];
export default routes;
