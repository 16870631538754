import {
  Button,
  Form,
  SubContent,
  FormTitle,
  Input,
  InputNumber,
  Radio,
  Select,
  Wrapper,
  useBreadcrumbRoutes,
} from '@maxtropy/components';
import { Space, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createEPrice,
  E_PRICE_CHARGE_TYPE_MAP,
  E_PRICE_SCOPE_MAP,
  E_PRICE_TIMING_TYPE_MAP,
  EPrice,
  EPriceChargeType,
  EPricePart,
  EPriceScope,
  EPriceTimingType,
  fetchEPrice,
  updateEPrice,
} from '../../api/electricityPrice';
import { fetchOuList, getOuId } from '../../api/ou';
import EPriceTimeFormModal from '../../components/EPriceTime/Modal';
import EPriceTimeTable from '../../components/EPriceTime/Table';
import styles from './index.module.scss';

interface ElectricityPriceFormProps {
  isEdit?: boolean;
}

const ElectricityPriceForm: React.FC<ElectricityPriceFormProps> = ({ isEdit = false }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const routesContext = useBreadcrumbRoutes();
  const routes = [{ name: `${isEdit ? '编辑' : '新建'}电价` }];
  const [form] = Form.useForm();

  const [showTimeModal, setShowTimeModal] = useState(false);
  const [editRow, setEditRow] = useState<number>();
  const [priceParts, setPriceParts] = useState<EPricePart[]>([]);

  const { data: ouList } = useQuery('ouList', async () => {
    const ouType = (await getOuId())?.filter(i => i.key === 'MICRONET')[0]?.id;
    if (!ouType) return [];
    return fetchOuList(ouType);
  });

  const { data, isLoading } = useQuery(['ePrice', +id], () => fetchEPrice(+id), { enabled: !!id });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const submit = id
    ? (data: Omit<EPrice, 'id'>) => updateEPrice(+id, data)
    : (data: Omit<EPrice, 'id'>) => createEPrice(data);
  const handleSubmit = (values: any) => {
    setIsSubmitting(true);
    if (values.scopeType === EPriceScope.OUTLET) {
      values.chargeType = undefined;
    }
    submit({
      ...values,
      ePriceParts: values.timingType === EPriceTimingType.TIMING ? priceParts : [],
      fixedEnergyRate: values.timingType === EPriceTimingType.NO_TIMING ? values.fixedEnergyRate : undefined,
      demandRate: values.chargeType === EPriceChargeType.DOUBLE ? values.demandRate : undefined,
      capacityRate: values.chargeType === EPriceChargeType.DOUBLE ? values.capacityRate : undefined,
      ouId: id ? data?.ou?.id : values.ouId,
    })
      .then(() => {
        navigate(-1);
      })
      .finally(() => setIsSubmitting(false));
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
      setPriceParts(data.ePriceParts);
    }
  }, [data, form]);

  return (
    <>
      {showTimeModal && (
        <EPriceTimeFormModal
          onOk={ePricePart => {
            if (editRow !== undefined) {
              const newPriceParts = [...priceParts];
              newPriceParts[editRow] = ePricePart;
              setPriceParts(newPriceParts.sort((a, b) => a.months[0] - b.months[0]));
            } else {
              setPriceParts([...priceParts, ePricePart].sort((a, b) => a.months[0] - b.months[0]));
            }
            setEditRow(undefined);
            setShowTimeModal(false);
          }}
          onCancel={() => {
            setShowTimeModal(false);
            setEditRow(undefined);
          }}
          disabledMonths={
            editRow !== undefined
              ? priceParts
                  .reduce((a, b) => a.concat(b.months), [] as number[])
                  .filter(n => !priceParts[editRow].months.includes(n))
              : priceParts.reduce((a, b) => a.concat(b.months), [] as number[])
          }
          ePricePart={editRow !== undefined ? priceParts[editRow] : undefined}
        />
      )}
      <Wrapper className={styles.wrapperPadding} routes={[...(routesContext?.routes ?? []), ...routes]}>
        <Spin spinning={isLoading}>
          <Form
            form={form}
            onFinish={handleSubmit}
            initialValues={{
              scopeType: EPriceScope.POWER_GRID,
              timingType: EPriceTimingType.TIMING,
              chargeType: EPriceChargeType.SINGLE,
            }}
          >
            <FormTitle title={id ? '编辑电价' : '新建电价'} />
            <SubContent>
              <Form.Item name="name" label="电价名称" rules={[{ required: true }]}>
                <Input placeholder="请输入" style={{ width: 360 }} />
              </Form.Item>
              <Form.Item name="ouId" label="所属运营单元" required rules={[{ required: !id }]}>
                {id ? (
                  data?.ou?.name
                ) : (
                  <Select placeholder="请选择" style={{ width: 360 }}>
                    {ouList
                      ?.filter(i => i.userType === 1)
                      ?.map(item => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item name="scopeType" label="电价范围" rules={[{ required: true }]}>
                <Radio.Group>
                  <Radio value={EPriceScope.POWER_GRID}>{E_PRICE_SCOPE_MAP[EPriceScope.POWER_GRID]}</Radio>
                  <Radio value={EPriceScope.OUTLET}>{E_PRICE_SCOPE_MAP[EPriceScope.OUTLET]}</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item noStyle dependencies={['scopeType']}>
                {({ getFieldValue }) =>
                  getFieldValue('scopeType') === EPriceScope.POWER_GRID && (
                    <>
                      <Form.Item name="chargeType" label="电价类型" rules={[{ required: true }]}>
                        <Radio.Group>
                          <Radio value={EPriceChargeType.SINGLE}>
                            {E_PRICE_CHARGE_TYPE_MAP[EPriceChargeType.SINGLE]}
                          </Radio>
                          <Radio value={EPriceChargeType.DOUBLE}>
                            {E_PRICE_CHARGE_TYPE_MAP[EPriceChargeType.DOUBLE]}
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item noStyle dependencies={['chargeType']}>
                        {({ getFieldValue }) =>
                          getFieldValue('chargeType') === EPriceChargeType.DOUBLE && (
                            <>
                              <Form.Item label="需量电价" name="demandRate" rules={[{ required: true }]}>
                                <InputNumber
                                  precision={4}
                                  min={0}
                                  max={99.9999}
                                  placeholder="0~99.9999"
                                  style={{ width: 360 }}
                                  addonAfter="元/kWh"
                                />
                              </Form.Item>
                              <Form.Item label="容量电价" name="capacityRate" rules={[{ required: true }]}>
                                <InputNumber
                                  precision={4}
                                  min={0}
                                  max={99.9999}
                                  placeholder="0~99.9999"
                                  style={{ width: 360 }}
                                  addonAfter="元/kWh"
                                />
                              </Form.Item>
                            </>
                          )
                        }
                      </Form.Item>
                    </>
                  )
                }
              </Form.Item>
              <Form.Item name="timingType" label="电度电费" rules={[{ required: true }]}>
                <Radio.Group>
                  <Radio value={EPriceTimingType.TIMING}>{E_PRICE_TIMING_TYPE_MAP[EPriceTimingType.TIMING]}</Radio>
                  <Radio value={EPriceTimingType.NO_TIMING}>
                    {E_PRICE_TIMING_TYPE_MAP[EPriceTimingType.NO_TIMING]}
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item noStyle dependencies={['timingType']}>
                {({ getFieldValue }) =>
                  getFieldValue('timingType') === EPriceTimingType.TIMING ? (
                    <>
                      <Form.Item name="" label="分时电价" required>
                        <Button type="primary" onClick={() => setShowTimeModal(true)}>
                          添加
                        </Button>
                      </Form.Item>
                      <EPriceTimeTable
                        dataSource={priceParts}
                        onDelete={index => {
                          setPriceParts(priceParts.filter((_, i) => i !== index));
                        }}
                        onEdit={index => {
                          setEditRow(index);
                          setShowTimeModal(true);
                        }}
                      />
                    </>
                  ) : (
                    <Form.Item name="fixedEnergyRate" label="电度电价" rules={[{ required: true }]}>
                      <InputNumber
                        precision={4}
                        min={0}
                        max={99.9999}
                        placeholder="0~99.9999"
                        style={{ width: 360 }}
                        addonAfter="元/kWh"
                      />
                    </Form.Item>
                  )
                }
              </Form.Item>
            </SubContent>
          </Form>

          <Space
            style={{
              marginLeft: 32,
              marginTop: -13,
            }}
            className={'sticky-footer-left'}
            size={8}
          >
            <Button type="primary" onClick={() => form.submit()} loading={isSubmitting}>
              保存
            </Button>
            <Button onClick={() => navigate(-1)}>取消</Button>
          </Space>
        </Spin>
      </Wrapper>
    </>
  );
};

export default ElectricityPriceForm;
