import { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import { Button, combineURL, EllipsisSpan, Modal, PopConfirm, Table } from '@maxtropy/components';

import { App, Col, Row, Select, Space } from 'antd';

import styles from './index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';

import { DeviceStatus, DeviceStatusDisplay, DriveType, DriveTypeLabels } from '@/api/type';

import {
  apiV2NeoBsaDeviceBindNeoBsaPcsPost,
  apiV2NeoBsaDeviceListNeoBsaPcsPost,
  apiV2NeoBsaDeviceListPcsPost,
  V2NeoBsaDeviceListPcsPostResponse,
} from '@maxtropy/dmes-apis-v2';

import { padStart } from '@/pages/Bsa/utils';
import { PCSType } from '@/pages/Bsa/components/BaseInfo';

import { useRequest } from 'ahooks';
import { ChildDataRef, DataValueContext } from '../const';
import { deletePcs } from '@/api/bsa';

const columns = [
  {
    title: '名称',
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },

  {
    title: 'PCS编号',
    dataIndex: 'bsaName',
    ellipsis: { showTitle: true },
    render: (v: string, record: any, index: number) => <EllipsisSpan value={padStart(index + 1)} />,
  },
  {
    title: '驱动类型',
    dataIndex: 'driveType',
    ellipsis: { showTitle: true },
    render: (v: DriveType) => <EllipsisSpan value={DriveTypeLabels[v]} />,
  },
  {
    title: '所属类目',
    dataIndex: 'typeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '型号',
    dataIndex: 'modelName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '功率',
    dataIndex: 'power',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ?? '--'} />,
  },
  {
    title: '设备状态',
    dataIndex: 'deviceStatus',
    ellipsis: { showTitle: true },
    render: (value: DeviceStatus) => {
      return <EllipsisSpan value={DeviceStatusDisplay[value]} />;
    },
  },
];
type PcsItem = Exclude<V2NeoBsaDeviceListPcsPostResponse['list'], undefined>[number];
const PcsForm = forwardRef<ChildDataRef>((props, ref) => {
  const { message } = App.useApp();
  const { refresh, bsaId, data: detail, isCanEdit, isView } = useContext(DataValueContext);

  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [selectPcs, setSelectPcs] = useState<Omit<PcsItem, 'id'>>();
  const [data, setData] = useState<Omit<PcsItem, 'id'>[]>([]);

  // pcs下拉列表
  const { data: pcsList, refresh: pcsListApi } = useRequest(
    () => apiV2NeoBsaDeviceListPcsPost({ neoBsaId: Number(bsaId) }).then(res => res.list),
    {
      refreshDeps: [bsaId],
      ready: !!bsaId && !isView,
    }
  );
  // 保存pcs
  const { runAsync } = useRequest(parasm => apiV2NeoBsaDeviceBindNeoBsaPcsPost(parasm), {
    manual: true,
    refreshDeps: [bsaId],
  });
  // 储能站关联的pcs列表
  const {
    data: tableList,
    loading: tableLoading,
    refresh: refreshTableList,
  } = useRequest(() => apiV2NeoBsaDeviceListNeoBsaPcsPost({ neoBsaId: Number(bsaId) }).then(res => res.list), {
    ready: !!bsaId,
    refreshDeps: [bsaId],
  });
  useEffect(() => {
    if (tableList) {
      setData(tableList);
    }
  }, [tableList]);

  // 已选择的pcs设备
  const selectedPcsIds = useMemo(() => {
    return (data ?? []).map(i => i.deviceId);
  }, [data]);

  // 阵列为单PCS类型时，此处只能添加一个PCS，且至少有一个PCS，如果添加了一个PCS之后，添加按钮置灰
  const addBtnIsDisabled = useMemo(() => {
    return (detail?.pcsStruct === PCSType.SIMPLE && data.length === 1) || data.length === detail?.pcsCount;
  }, [data, detail]);

  // 判断驱动类型是否一致
  const driveTypeIsDiffrent = useMemo(() => {
    let driveTypeList = data.map(item => item.driveType);
    return driveTypeList.length > 1 && [...new Set(driveTypeList)].length > 1;
  }, [data]);

  // 未被选择的pcs下拉列表
  const unSelectPcsList = useMemo(() => {
    return (pcsList ?? []).filter(i => !(selectedPcsIds ?? []).includes(i.deviceId!));
  }, [pcsList, selectedPcsIds]);

  // 删除行
  const deleteRecord = (index: number, id?: number) => {
    deletePcs(id).then(() => {
      pcsListApi();
      data.splice(index, 1);
      setData([...data]);
    });
  };
  const buildColumns = [
    ...columns,

    {
      title: '操作',
      width: 180,
      fixed: 'right' as const,
      render: (v: undefined, record: any, index: number) => (
        <Space size={16}>
          {isCanEdit && (
            <PopConfirm
              placement="bottomRight"
              title={'是否确认删除？删除后相关的电池堆也一并删除。'}
              okText="确认"
              cancelText="取消"
              onConfirm={() => deleteRecord(index, record.id)}
            >
              <Button type="link">删除</Button>
            </PopConfirm>
          )}

          <Button
            onClick={() => {
              window.open(
                combineURL(window.IOTPLATFORMORIGIN, `/device/manage/device/${record.deviceId}/detail`),
                '_blank'
              );
            }}
            type="link"
          >
            查看详情
          </Button>
        </Space>
      ),
    },
  ];
  // 保存api入参
  const apiParams = useMemo(() => {
    return data.map((item, index) => {
      return {
        sequence: index,
        deviceId: item.deviceId,
      };
    });
  }, [data]);
  const saveForm = () => {
    if (!apiParams.length) {
      message.success('请添加pcs!');
      return;
    }
    if (driveTypeIsDiffrent) return;
    runAsync({
      action: 1,
      bsaId,
      pcsList: apiParams,
    }).then(() => refresh?.());
  };
  const saveDraft = () => {
    if (!isCanEdit) return;
    runAsync({
      action: 0,
      bsaId,
      pcsList: apiParams,
    }).then(() => {
      refreshTableList();
      message.success('保存草稿成功!');
    });
  };
  useImperativeHandle(ref, () => ({
    saveForm: saveForm,
    saveDraft: saveDraft,
  }));
  return (
    <div className={styles.pcs_box}>
      <div className={styles.title}>PCS列表</div>
      {isCanEdit && (
        <div className={styles.add_pcs}>
          <Space size={15}>
            <Button
              type="primary"
              disabled={addBtnIsDisabled}
              onClick={() => {
                setIsAdd(true);
              }}
            >
              +增加PCS
            </Button>
            {driveTypeIsDiffrent && (
              <div className={styles.tips_text}>
                <InfoCircleOutlined />
                <span>&nbsp;当前所选PCS驱动类型不一致，请保持一致</span>
              </div>
            )}
          </Space>
        </div>
      )}

      <Table
        loading={tableLoading}
        rowKey="deviceId"
        columns={buildColumns}
        dataSource={data}
        rowClassName={(record: any) => {
          return record.deviceStatus === DeviceStatus.INVALID ? 'device-invalid' : '';
        }}
      />
      {!isView && (
        <div className={styles.pcs_tips}>
          <span className={styles.tips_txt}>已配置</span>
          <span className={data.length === detail?.pcsCount ? styles.full_count : styles.unfull_count}>
            {data.length}/{detail?.pcsCount ?? ''}
          </span>
        </div>
      )}

      <Modal
        title="关联PCS"
        width={700}
        open={isAdd}
        onCancel={() => {
          setSelectPcs(undefined);

          setIsAdd(false);
        }}
        onOk={() => {
          if (selectPcs) {
            setData([...data, selectPcs]);
            setSelectPcs(undefined);
            setIsAdd(false);
          }
        }}
      >
        <Space direction="vertical" style={{ width: '100%', height: 150 }}>
          <Space>
            关联PCS
            <a
              href={combineURL(window.IOTPLATFORMORIGIN, `/device/manage/device/create`)}
              target="_blank"
              rel="noreferrer"
            >
              <InfoCircleOutlined />
              找不到？点这里去创建PCS
            </a>
          </Space>
          <Select
            style={{ width: '80%' }}
            onChange={(v: number) => {
              const pcs = unSelectPcsList.find(i => i.deviceId === v);
              if (pcs) {
                setSelectPcs(pcs);
              }
            }}
            value={selectPcs?.deviceId}
          >
            {(unSelectPcsList ?? []).map(i => (
              <Select.Option
                key={i.deviceId}
                value={i.deviceId}
              >{`${i.deviceName} ${i.typeName} ${i.modelName}`}</Select.Option>
            ))}
          </Select>
          <Row>
            <Col span={12}>没有配置数采配置的PCS在这里是选不到的</Col>
            <Col span={12}>
              <a
                href={combineURL(window.IOTPLATFORMORIGIN, `/device/manage/device/daq-deployment`)}
                target="_blank"
                rel="noreferrer"
              >
                <InfoCircleOutlined />
                还没配置？点这里去创建PCS数采配置
              </a>
            </Col>
          </Row>
        </Space>
      </Modal>
    </div>
  );
});

export default PcsForm;
