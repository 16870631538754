import { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from 'react';
import { Button, EllipsisSpan, Table } from '@maxtropy/components';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { Space } from 'antd';
import { cloneDeep, uniq } from 'lodash-es';
import { useSearchParams } from 'react-router-dom';
import { TopologicalType } from '@/api/type';
import { ComputationalLogic, computationalLogicDisplay } from '@/types';
import { DataProperty, StackPointWithUuid } from './index';
import { BatteryType, PointType } from '../const';
import { DataRow } from './DataTable';
import StaticDialog from './StaticDialog';
import { v4 } from 'uuid';
import { ColumnStatusEnum } from '../const';
import ActionStatusTag from '../components/ActionStatusTag';

interface Props {
  stackId: number;
  stackPointList: StackPointWithUuid[];
  setStackPointList: (stackPointList: StackPointWithUuid[]) => void;
  topologicalType: TopologicalType;
  batteryType: BatteryType;
  dataPropertiesAll: DataProperty[];
  hasCreated: boolean;
}

export interface StaticTableRefProps {
  setEditingRow: (editingRow: DataRow) => void;
  setEditingRowIndex: (editingRowIndex: number) => void;
}

const StaticTable: ForwardRefRenderFunction<StaticTableRefProps, Props> = (props, ref) => {
  const { stackPointList, stackId, setStackPointList, hasCreated, dataPropertiesAll } = props;
  const [searchParams] = useSearchParams();
  const showSingleStep = searchParams.get('showSingleStep') === 'true';

  const [editingRow, setEditingRow] = useState<DataRow | undefined>(undefined);
  const [editingRowIndex, setEditingRowIndex] = useState<number>(-1);

  useImperativeHandle(ref, () => {
    return { setEditingRow, setEditingRowIndex };
  });

  const columnsBefore: ColumnsType<any> = [
    {
      title: '数据属性/标识符',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      width: 180,
      render: (_: any, record) => <EllipsisSpan value={record.dataPropertyName} />,
    },
    {
      title: '单位',
      dataIndex: 'physicalUnitGeneralName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v ? v : '--'} />,
    },
  ];

  const columnsAfter: ColumnsType<any> = [
    {
      title: '计算逻辑',
      dataIndex: 'computationalLogic',
      ellipsis: { showTitle: true },
      render: (v: ComputationalLogic) => <EllipsisSpan value={computationalLogicDisplay[v]} />,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
  ];

  const actionColumns: ColumnType<any>[] = [
    {
      title: '操作状态',
      width: 80,
      key: 'status',
      fixed: 'right',
      render: (_, record) => <ActionStatusTag columnStatus={record.status}></ActionStatusTag>,
    },
    {
      title: '操作',
      width: 140,
      key: 'action',
      fixed: 'right',
      render: (_, record, index) => (
        <Space>
          <Button
            type="link"
            onClick={() => {
              setEditingRowIndex(index);
              setEditingRow(record);
            }}
          >
            编辑
          </Button>
          <Button
            type="link"
            onClick={() => {
              const newStackPointList = cloneDeep(stackPointList);
              newStackPointList.forEach(stackPoint => {
                if (stackPoint.stackId === stackId && stackPoint.pointType === PointType.STATIC) {
                  stackPoint.templates.splice(index, 1);
                }
              });
              setStackPointList(newStackPointList);
            }}
          >
            删除
          </Button>
        </Space>
      ),
    },
  ];

  const currentStackPointList = stackPointList.filter(item => item.stackId === stackId);
  const data = currentStackPointList.find(item => item.pointType === PointType.STATIC)?.templates || [];
  const columns = [...columnsBefore, ...(!hasCreated || showSingleStep ? actionColumns : []), ...columnsAfter];

  const usedProperties = uniq(
    data
      .filter(item => item.dataPropertyId)
      .filter(item => item.dataPropertyId !== editingRow?.dataPropertyId)
      .map(item => item.dataPropertyId!)
  );

  const usedComputationalLogic =
    data
      .filter(item => item.computationalLogic !== editingRow?.computationalLogic)
      .map(item => item?.computationalLogic)
      .filter(item => item !== undefined) || [];

  return (
    <>
      <Table rowKey="uuid" columns={columns} dataSource={data}></Table>

      <StaticDialog
        row={editingRow}
        onCancel={() => {
          setEditingRowIndex(-1);
          setEditingRow(undefined);
        }}
        onOk={(res, shouldContinue) => {
          const newStackPointList = cloneDeep(stackPointList);
          // rowIndex为-1，是新增
          if (editingRowIndex === -1) {
            newStackPointList.forEach(stackPoint => {
              if (stackPoint.stackId === stackId && stackPoint.pointType === PointType.STATIC) {
                res.uuid = v4();
                res.status = ColumnStatusEnum.ADD;
                stackPoint.templates.push(res);
              }
            });
          }
          // rowIndex不为-1，是编辑
          else {
            newStackPointList.forEach(stackPoint => {
              if (stackPoint.stackId === stackId && stackPoint.pointType === PointType.STATIC) {
                // 没有id，状态设置为新增，否则设置为编辑
                if (!stackPoint.templates[editingRowIndex].id) res.status = ColumnStatusEnum.ADD;
                else res.status = ColumnStatusEnum.EDIT;
                stackPoint.templates[editingRowIndex] = res;
              }
            });
          }
          setStackPointList(newStackPointList);
          // 完成并继续添加
          if (shouldContinue) {
            setEditingRowIndex(-1);
            setEditingRow({ hasProperty: true } as any);
          }
        }}
        usedProperties={usedProperties}
        dataPropertiesAll={dataPropertiesAll}
        usedComputationalLogic={usedComputationalLogic as number[]}
      ></StaticDialog>
    </>
  );
};

export default forwardRef(StaticTable);
