import circuitBtns from '../../config';
import { ChartData } from '../../index';
import { DataType } from '@/api/const';
import { CircuitStatisticsDataType, StatisticsPartition, StatisticsPartitionTime } from '@/api/circuit';
import { DatePickerType } from '@/pages/Statistics/components/DateSwitch';
import { isNil } from 'lodash-es';
import { FormatterItem } from '@/pages/Statistics/components/Chart/helper';
import palette from '@/pages/Statistics/components/Chart/palette';
import dayjs from 'dayjs';

// 30分钟间隔的数据在15分钟间隔下显示
const interpolateValues = (arr: CircuitStatisticsDataType[]) => {
  return arr.reduce(
    (acc, curr) => [...acc, ...[curr, { time: curr.time + 15 * 60, value: null }]],
    [] as CircuitStatisticsDataType[]
  );
};

interface ChartOption {
  chartData: { dataType: DataType; aggrby: StatisticsPartition; data: ChartData[]; btnType: DatePickerType }[];
  tsStart: number;
  tsEnd: number;
}

export const getChartOption = ({ chartData, tsStart, tsEnd }: ChartOption) => {
  const [firstChartData, secondChartData] = chartData;
  const firstItem = circuitBtns.find(item => item.id === chartData[0].dataType);
  const secondItem = circuitBtns.find(item => item.id === chartData[1].dataType);
  const aggrby =
    firstChartData.aggrby === StatisticsPartition.MINUTE_15 || secondChartData.aggrby === StatisticsPartition.MINUTE_15
      ? StatisticsPartition.MINUTE_15
      : firstChartData.aggrby;

  const first = firstItem?.getChartOption({
    chartData:
      aggrby === StatisticsPartition.MINUTE_15 && firstChartData.aggrby === StatisticsPartition.MINUTE_30
        ? firstChartData.data.map(item => ({ ...item, data: interpolateValues(item.data) }))
        : firstChartData.data,
    query: {
      btnType:
        firstChartData.aggrby === StatisticsPartition.MINUTE_15
          ? DatePickerType.ORIGINAL
          : firstChartData.aggrby === StatisticsPartition.MINUTE_30
          ? DatePickerType.THIRTYMINUTES
          : firstChartData.aggrby === StatisticsPartition.DAY
          ? DatePickerType.DAY
          : firstChartData.aggrby === StatisticsPartition.MINUTE_1
          ? DatePickerType.ONEMINUTE
          : DatePickerType.MONTH,
      dataType: firstChartData.dataType,
      aggrby,
      name: firstItem.name,
      tsStart,
      tsEnd,
    },
    unit: firstItem?.unit,
  });

  const second = secondItem?.getChartOption({
    chartData:
      aggrby === StatisticsPartition.MINUTE_15 && secondChartData.aggrby === StatisticsPartition.MINUTE_30
        ? secondChartData.data.map(item => ({ ...item, data: interpolateValues(item.data) }))
        : secondChartData.data,
    query: {
      btnType:
        firstChartData.aggrby === StatisticsPartition.MINUTE_15
          ? DatePickerType.ORIGINAL
          : firstChartData.aggrby === StatisticsPartition.MINUTE_30
          ? DatePickerType.THIRTYMINUTES
          : firstChartData.aggrby === StatisticsPartition.DAY
          ? DatePickerType.DAY
          : firstChartData.aggrby === StatisticsPartition.MINUTE_1
          ? DatePickerType.ONEMINUTE
          : DatePickerType.MONTH,
      dataType: secondChartData.dataType,
      aggrby,
      name: secondItem.name,
      tsStart,
      tsEnd,
    },
    unit: secondItem?.unit,
  });

  // 去掉标记线 最大值 最小值
  const seriesData = first.series.concat(second.series).map((item: any, index: number) => {
    const { ...rest } = item;
    const p = palette as any;
    const color =
      index >= first.series.length
        ? p[secondItem!.id] === p[firstItem!.id]
          ? p[secondItem!.id].slice(p[secondItem!.id].length / 2, p[secondItem!.id].length)[index - first.series.length]
          : p[secondItem!.id][index - first.series.length]
        : p[firstItem!.id][index];
    return {
      ...rest,
      yAxisIndex: firstItem!.id !== secondItem!.id && index >= first.series.length ? 1 : 0,
      color: color,
    };
  });

  const legendData = first.legend.data.concat(second.legend.data);
  if (legendData.length > 3) {
    legendData.splice(3, 0, '');
  }

  return {
    ...first,
    right: 80,
    yAxis:
      firstItem!.id === secondItem!.id
        ? first.yAxis
        : [{ ...first.yAxis }, { ...second.yAxis, nameTextStyle: { align: 'left' } }],
    legend: {
      ...first.legend,
      data: legendData,
      top: legendData.length > 3 ? 30 : 50,
      right: 50,
    },
    series: seriesData,
    tooltip: {
      trigger: 'axis',
      formatter(items: FormatterItem[]) {
        const { axisValue } = items[0];
        let str: string = dayjs(axisValue, 'x').format(StatisticsPartitionTime[firstChartData.aggrby]);
        items.forEach(item => {
          const { seriesName, data, marker, seriesIndex } = item;
          const unit = seriesIndex < first.series.length ? firstItem!.unit : secondItem!.unit;
          const value = !isNil(data[1]) ? `${transValueByBtnType(aggrby, data[1])}${unit}` : '--';
          str += `<br> ${marker}${seriesName}: ${value}`;
        });
        return str;
      },
    },
  };
};

const transValueByBtnType = (type: StatisticsPartition, value?: number | null) => {
  if (value) {
    value = Number(value); // value过来是string类型，导致toFixed方法出错，转成number类型
    switch (type) {
      case StatisticsPartition.MINUTE_1:
        return value.toFixed(3);
      case StatisticsPartition.MINUTE_15:
      case StatisticsPartition.MINUTE_30:
      case StatisticsPartition.DAY:
      case StatisticsPartition.MONTH:
        return value.toFixed(2);
      default:
        return value;
    }
  }
  return value;
};
