export enum SendStatus {
  SUCCESS,
  FAILED,
  SENDING,
}
export const sendStatusDisplay = {
  [SendStatus.SUCCESS]: '发送成功',
  [SendStatus.FAILED]: '发送失败',
  [SendStatus.SENDING]: '发送中',
};
export const sendStatusColor = {
  [SendStatus.SUCCESS]: 'success',
  [SendStatus.FAILED]: 'error',
  [SendStatus.SENDING]: 'info',
};
