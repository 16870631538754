import Page404 from './Page404';
import Page500 from './Page500';

const routes = [
  {
    path: '*',
    element: <Page404 />,
  },
  {
    path: '/system/500',
    element: <Page500 />,
  },
];

export default routes;
