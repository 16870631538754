import { PermissionsType } from '@/common/permissionsConst';
import SiteAccess from '@/pages/SiteAccess';
import Storage from '@/pages/SiteAccess/Storage';
const routes = [
  {
    path: '/pv-storage/basic/manage/siteDataProcessing',
    permission: '',
    element: <SiteAccess />,
  },
  {
    path: '/energy-storage/basic/bsa/startingTimeOfStorage',
    permission: '',
    element: <Storage />,
  },
  {
    path: '/device/chargingStation/management/startingTimeOfStorage',
    permission: '',
    element: <Storage />,
  },
];
export default routes;
