import { AlarmLevelNumEnum } from '@/api/bsaPVOperationDashBoard';
import { formateValuesFixTwo } from '@/components/PvOperationCard/utils';
import { Button, EllipsisSpan, Table } from '@maxtropy/components';
import { Space } from 'antd';
import { isNil } from 'lodash-es';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { PvStationItem } from '../..';

const columns = [
  {
    title: '光伏站名称',
    dataIndex: 'name',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string, record: PvStationItem) => (
      <EllipsisSpan
        value={
          <span
            style={{
              color:
                !isNil(record.levelNum?.find(i => i.level === AlarmLevelNumEnum.MAX)?.num) &&
                !isNil(record.levelNum?.find(i => i.level === AlarmLevelNumEnum.HIGH)?.num) &&
                record.levelNum?.find(i => i.level === AlarmLevelNumEnum.MAX)?.num! +
                  record.levelNum?.find(i => i.level === AlarmLevelNumEnum.HIGH)?.num! >
                  0
                  ? 'red'
                  : 'unset',
            }}
          >
            {v}
          </span>
        }
      />
    ),
  },
  {
    title: '装机容量',
    dataIndex: 'installedCapacity',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={`${!isNil(v) ? formateValuesFixTwo(v) + 'MW' : '--MW'}`} />,
  },
  {
    title: '实时功率',
    dataIndex: 'realTimePower',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={`${!isNil(v) ? Number(v).toFixed(2) + 'kW' : '--kW'}`} />,
  },
  {
    title: '今日发电量',
    dataIndex: 'charge',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={`${!isNil(v) ? formateValuesFixTwo(v) + 'kWh' : '--kWh'}`} />,
  },
  {
    title: '辐照强度',
    dataIndex: 'irradiationIntensity',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={!isNil(v) ? Number(v).toFixed(2) + 'W/㎡' : '--W/㎡'} />,
  },
  {
    title: '实时发电效率',
    dataIndex: 'powerGenerationEfficiency',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string, record: PvStationItem) => (
      <EllipsisSpan
        value={
          record.showPowerGenerationEfficiency ? (
            !isNil(v) ? (
              (Number(v) * 100).toFixed(2) + '%'
            ) : (
              '--%'
            )
          ) : (
            <span style={{ color: '#ffffff80' }}>缺少辐照数据</span>
          )
        }
      />
    ),
  },
];

export interface ITableList {
  isLoading: boolean;
  data: PvStationItem[];
  isChannel?: boolean;
}

const TableList: FC<ITableList> = props => {
  const { isLoading, data, isChannel } = props;

  const mergeColums = isChannel
    ? [
        {
          title: '所属租户',
          dataIndex: 'tenantName',
          width: 160,
          ellipsis: { showTitle: true },
          render: (v: string) => <EllipsisSpan value={v} />,
        },
        {
          title: '运营单元',
          dataIndex: 'ouName',
          width: 160,
          ellipsis: { showTitle: true },
          render: (v: string) => <EllipsisSpan value={v} />,
        },
      ]
    : [
        {
          title: '运营单元',
          dataIndex: 'ouName',
          width: 160,
          ellipsis: { showTitle: true },
          render: (v: string) => <EllipsisSpan value={v} />,
        },
        {
          title: '报警信息',
          dataIndex: 'alarm',
          width: 230,
          ellipsis: { showTitle: true },
          render: (v: string, record: PvStationItem) => {
            const findMax = record.levelNum?.find(i => i.level === AlarmLevelNumEnum.MAX);
            const findHigh = record.levelNum?.find(i => i.level === AlarmLevelNumEnum.HIGH);
            const findMid = record.levelNum?.find(i => i.level === AlarmLevelNumEnum.MIDDLE);
            const findLow = record.levelNum?.find(i => i.level === AlarmLevelNumEnum.LOW);
            const findWarn = record.levelNum?.find(i => i.level === AlarmLevelNumEnum.WARNING);
            return (
              <>
                <span style={{ color: '#FF4D4F' }}>{findMax ? findMax.num : '--'}</span>/
                <span style={{ color: '#D89614' }}>{findHigh ? findHigh.num : '--'}</span>/
                <span style={{ color: '#157DDC' }}>{findMid ? findMid.num : '--'}</span>/
                <span style={{ color: '#4AA91A' }}>{findLow ? findLow.num : '--'}</span>/
                <span style={{ color: '#A7B809' }}>{findWarn ? findWarn.num : '--'}</span>
                <Button type="link" style={{ padding: 0, fontSize: '12px', marginLeft: 6 }}>
                  <Link to={`/assets/alarm/overview?assetCode=${record.assetCode}`} target="_blank">
                    报警记录
                  </Link>
                </Button>
              </>
            );
          },
        },
        {
          title: '操作',
          fixed: 'right' as 'right',
          width: 160,
          render: (v: undefined, record: PvStationItem) => (
            <Space size={16}>
              <Button
                type="link"
                onClick={() =>
                  window.open(`${window.SEVENNINENINESEVEN}/station-analysis?stationId=${record.id}`, '_blank')
                }
              >
                运行数据
              </Button>
              <Button
                type="link"
                onClick={() =>
                  window.open(`${window.SEVENNINENINESEVEN}/operate-overview?stationId=${record.id}`, '_blank')
                }
              >
                站点详情
              </Button>
            </Space>
          ),
        },
      ];

  const buildColumns = [...columns, ...mergeColums];

  return (
    <Table
      sticky
      rowKey="id"
      scroll={{ x: 1200 }}
      columns={[...buildColumns]}
      dataSource={data}
      loading={isLoading}
      pagination={false}
    />
  );
};

export default TableList;
