import { EllipsisSpan } from '@maxtropy/components';
import { useMemo } from 'react';
import { useGetDataPropertyInfo } from '../../hooks';
import { DataPropertyValues } from '../../type';
import { getTsByPropertyId, getValueByPropertyId } from '../utils';
import PcsPowerTitle from './PcsPowerTitle';

export type DataPropertyValuesItem = Exclude<DataPropertyValues, undefined>[number];
interface Iprops {
  tableList?: DataPropertyValues;
  tableTitle?: React.ReactNode;
  type?: 'connect' | 'pcs';
}

export const useGetTableColumns = ({ tableList, tableTitle, type }: Iprops) => {
  const { deviceIds, dataPropertyIds } = useMemo(() => {
    return {
      deviceIds: tableList?.filter(Boolean).map(item => item.deviceId),
      dataPropertyIds: tableList?.filter(Boolean)?.map(item => item.datapropertyId!),
    };
  }, [tableList]);

  const { data: valuesMap } = useGetDataPropertyInfo({ deviceIds: deviceIds as unknown as number[], dataPropertyIds });
  const columns = [
    {
      title: tableTitle,
      children: [
        {
          title: '名称',
          dataIndex: 'datapropertyName',
          width: 250,
          render: (v: string, record: any) => {
            return (
              <div style={{ whiteSpace: 'normal' }}>{`${type === 'pcs' ? record.deviceName ?? '' : ''} ${v}`}</div>
            );
            // return <EllipsisSpan value={`${type === 'pcs' ? record.deviceName ?? '' : ''} ${v}`} />;
          },
        },
        {
          title: '数据',
          dataIndex: 'value',
          className: 'self_color',
          render: (v: string, record: any) => {
            const { datapropertyId, deviceId, generalName, enumMap, dataPropertyValues } = record;
            return getValueByPropertyId({
              datapropertyId,
              deviceId,
              valuesMap,
              unit: generalName,
              enumMap,
              datapropertyIds: dataPropertyValues?.map((item: any) => item.datapropertyId!),
            });
          },
        },
        {
          title: '更新时间',
          dataIndex: 'updateTime',
          render: (v: string, record: any) => {
            return getTsByPropertyId(record.datapropertyId, record.deviceId, valuesMap);
          },
        },
      ],
    },
  ];

  return columns;
};
