import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { DataNameType, dataNameTypeDisplay, DataType, E_PRICE_PEAK_TYPE_LABEL_MAP } from '../../../../api/const';
import { StatisticsPartition } from '../../../../api/circuit';
import { E_PRICE_PEAK_TYPE_COLORS_MAP, EPriceTime } from '../../../../api/electricityPrice';
import { ChartData, Query } from '../../index';
import { DatePickerType } from '../DateSwitch';
import { icons } from './chartOption';

const xAxis = {
  axisTick: {
    show: false,
  },
  splitLine: {
    show: false,
  },
};

export const intervalDict: any = {
  [StatisticsPartition.MINUTE_1]: 1 * 60 * 1000,
  [StatisticsPartition.MINUTE_30]: 30 * 60 * 1000,
  [StatisticsPartition.MINUTE_15]: 15 * 60 * 1000,
};

export const rangeCatXAxis = (key: string, dataType?: DataType) => {
  // 按天： 最小为1天
  // 按月： 最小为28天
  let minInterval =
    key === 'Day' ? { minInterval: 3600 * 24 * 1000 } : key === 'Month' ? { minInterval: 28 * 3600 * 24 * 1000 } : {};

  return dataType === DataType.P
    ? [
        {
          ...xAxis,
          ...minInterval,
          // type: 'category', 之前是类目轴，已废弃
          type: 'time',
          axisPointer:
            dataType === DataType.P // 有功功率x轴是十字
              ? {
                  label: {
                    formatter: function (params: { value: string; seriesData: string | any[] }) {
                      return dayjs(params.value, 'x').format('YYYY-MM-DD');
                    },
                  },
                }
              : undefined,
          axisLabel: {
            formatter: function (e: number) {
              if (key === 'Hour') {
                return dayjs(e, 'x').format('MM-DD[\n]HH:mm');
              } else if (key === 'Day') {
                return dayjs(e, 'x').format('MM-DD');
              } else {
                return dayjs(e, 'x').format('YYYY-MM');
              }
            },
          },
        },
      ]
    : [
        {
          ...xAxis,
          ...minInterval,

          type: 'time',
          axisLabel: {
            formatter: function (e: number) {
              if (key === 'Hour') {
                return dayjs(e, 'x').format('MM-DD[\n]HH:mm');
              } else if (key === 'Day') {
                return dayjs(e).format('MM-DD');
              } else {
                return dayjs(e, 'x').format('YYYY-MM');
              }
            },
          },
        },
      ];
};

export const getNormalAxis = (query: Query) => {
  switch (query.aggrby) {
    case StatisticsPartition.MINUTE_1:
    case StatisticsPartition.MINUTE_15:
    case StatisticsPartition.MINUTE_30:
      return rangeCatXAxis('Hour');
    case StatisticsPartition.DAY:
      return rangeCatXAxis('Day', query.dataType);
    case StatisticsPartition.MONTH:
      return rangeCatXAxis('Month');
    default:
      return {};
  }
};

export const getMarkAreaData = (ePriceTimes: EPriceTime[], query: Query) => {
  return ePriceTimes?.map(item => {
    const { intervalStart, intervalEnd, type } = item;
    const color = `${E_PRICE_PEAK_TYPE_COLORS_MAP[type]}40`;
    const interval = (30 * 60 * 1000) / intervalDict[query.aggrby];
    const start = intervalStart * interval;
    const end = (intervalEnd + 1) * interval;
    // 开始时间与结束时间的时间戳，以前是用类目轴是start与end
    // 减去0.5，使两个时间段中间的柱子在markArea之内，而不是横跨两个markArea。
    const startTime = query.tsStart + intervalDict[query.aggrby] * (start - 0.5);
    const endTime = query.tsStart + intervalDict[query.aggrby] * (end - 0.5);

    return [
      {
        xAxis: startTime,
        itemStyle: {
          color,
        },
      },
      {
        xAxis: endTime,
      },
    ];
  });
};

export const getVisualMap = (ePriceTimes: EPriceTime[], query: Query) => {
  const pieces = ePriceTimes?.map(item => {
    const { intervalEnd, type } = item;
    const color = `${E_PRICE_PEAK_TYPE_COLORS_MAP[type]}ff`;
    const interval = (30 * 60 * 1000) / intervalDict[query.aggrby];
    const end = (intervalEnd + 1) * interval;
    return {
      lt: end,
      color,
      label: `${E_PRICE_PEAK_TYPE_LABEL_MAP[type]}电量`,
    };
  });
  return {
    type: 'piecewise',
    dimension: 0,
    pieces,
    show: true,
    right: 20,
    top: 50,
    orient: 'horizontal',
    itemSymbol: 'rect',
    textStyle: {
      color: '#AFBCC4',
    },
  };
};

export type FormatterItem = {
  name: string;
  value: number;
  marker: string;
  data: [string, number | null];
  seriesName: string;
  dataIndex: number;
  axisValue: string;
  seriesIndex: number;
};
// 所有数据属性最大值最小值, 1分钟颗粒度保留3位，其他颗粒度2位
export const formatterMarkPointLabel = (type: DatePickerType) => {
  return {
    formatter: (data: any) => {
      let value = data?.value;
      if (value) {
        value = Number(value);
        if (type === DatePickerType.ONEMINUTE) {
          return value.toFixed(3);
        }
        return value.toFixed(2);
      }
      return value;
    },
  };
};
/**
 *
 * @param type 颗粒度
 * @param maxIcon 图标1
 * @param minIcon 图标2
 * @returns
 * @decription 代替chartOptions文件中的markPoint
 */
export const getCommonMarkPint = (type: DatePickerType, maxIcon = icons[3], minIcon = icons[4]) => {
  return {
    label: {
      normal: {
        show: true,
        offset: [0, -4],
      },
    },
    emphasis: {
      show: true,
      offset: [0, -4],
    },
    symbolSize: [60, 26],
    symbolOffset: [0, -13],
    data: [
      {
        type: 'max',
        name: '最大值',
        symbol: `image://${maxIcon}`,
        itemStyle: {
          normal: {
            color: '#e16b31',
            opacity: 0.75,
          },
        },
        label: {
          ...formatterMarkPointLabel(type),
        },
      },
      {
        type: 'min',
        name: '最小值',
        symbol: `image://${minIcon}`,
        itemStyle: {
          normal: {
            color: '#4a90e2',
            opacity: 0.75,
          },
        },
        label: {
          ...formatterMarkPointLabel(type),
        },
      },
    ],
    itemStyle: {
      normal: {
        color: '#d6a223',
      },
    },
  };
};
const transValueByBtnType = (type: DatePickerType, value?: number | null) => {
  if (value) {
    value = Number(value); // value过来是string类型，导致toFixed方法出错，转成number类型
    switch (type) {
      case DatePickerType.ONEMINUTE:
        return value.toFixed(3);
      case DatePickerType.ORIGINAL:
      case DatePickerType.THIRTYMINUTES:
      case DatePickerType.DAY:
      case DatePickerType.MONTH:
        return value.toFixed(2);

      default:
        return value;
    }
  }
  return value;
};

export const getTooltip = ({ unit = '', mode }: { unit?: string; date: number; mode: DatePickerType }) => ({
  trigger: 'axis',
  formatter(items: FormatterItem[]) {
    const { axisValue } = items[0];
    // 根据选择的颗粒度，展示不同的tooltip
    let str: string;
    switch (mode) {
      case DatePickerType.ONEMINUTE:
      case DatePickerType.ORIGINAL:
      case DatePickerType.THIRTYMINUTES:
        str = dayjs(axisValue, 'x').format('MM-DD[\n]HH:mm');
        break;
      case DatePickerType.DAY:
        str = dayjs(axisValue, 'x').format('MM-DD');
        break;
      default:
        str = dayjs(axisValue, 'x').format('YYYY-MM');
    }
    items.forEach(item => {
      const { seriesName, data, marker } = item;
      const value = !isNil(data[1]) ? `${transValueByBtnType(mode, data[1])}${unit}` : '--';
      str += `<br> ${marker}${seriesName}: ${value}`;
    });
    return str;
  },
});

export const translateName = (name: DataNameType, prefix?: string) => {
  const legendName = dataNameTypeDisplay[name];
  return prefix ? `${prefix}_${legendName}` : legendName;
};

interface LegendProps {
  show: boolean;
  name: DataNameType | DataNameType[];
  colors: string[];
  chartData: ChartData[];
  right?: number;
}
export const getLegend = ({ show, name, colors, chartData, right }: LegendProps) => {
  const prefix = chartData.length > 0 ? chartData[0].circuitName : '';

  const data = Array.isArray(name)
    ? name.map((i, index) => ({
        name: translateName(i, prefix),
        color: colors[index],
      }))
    : [{ name: translateName(name, prefix) }];

  return {
    show,
    right: right ?? 20,
    top: 50,
    textStyle: {
      color: '#AFBCC4',
    },
    data: data.map(({ name, ...rest }) => ({
      name,
      icon: 'rect',
      ...rest,
    })),
  };
};

export const maxValueSpan = (_aggrby: StatisticsPartition) => {
  return undefined;
  // switch (aggrby) {
  //   case StatisticsPartition.MINUTE_15:
  //     return 96;
  //   case StatisticsPartition.MINUTE_30:
  //     return 48;
  //   case StatisticsPartition.DAY:
  //   case StatisticsPartition.MONTH:
  //   default:
  //     return undefined;
  // }
};

// 判断是否有尖峰平谷，还是选择的日期内有没有尖峰平谷的，也有有尖峰平谷的
export const judgeEPHasNone = (chartData: ChartData[], query: Query, ePriceTimes?: EPriceTime[]): boolean => {
  return chartData.some(item =>
    item.data.some(
      (v, index) =>
        !ePriceTimes?.find(
          e =>
            index >= (query.btnType === DatePickerType.ONEMINUTE ? e.intervalStart * 30 : e.intervalStart * 2) &&
            index <= (query.btnType === DatePickerType.ONEMINUTE ? e.intervalEnd * 30 + 29 : e.intervalEnd * 2 + 1)
        )?.type
    )
  );
};
