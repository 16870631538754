import React from 'react';
import { Space } from 'antd';
import { InputNumber } from '@maxtropy/components';
import { useControlledState as useMergedState } from '@/utils/hooks';

interface NumberRangeProps {
  value?: number[];
  onChange?: (value: number[]) => void;
}

const NumberRange: React.FC<NumberRangeProps> = props => {
  const [mergedValue, setMergedValue] = useMergedState([], { value: props.value, onChange: props.onChange });

  return (
    <Space size={10}>
      <InputNumber
        placeholder="请输入"
        style={{ width: 110 }}
        value={mergedValue[0]}
        onChange={value => setMergedValue([value! as number, mergedValue[1]])}
      />
      <span>-</span>
      <InputNumber
        placeholder="请输入"
        style={{ width: 110 }}
        value={mergedValue[1]}
        onChange={value => setMergedValue([mergedValue[0], value! as number])}
      />
    </Space>
  );
};

export default NumberRange;
