import { FCSType, StrategyVersion, TemplateDetail } from '@/api/strategy';
import { StrategyOverview, VersionType } from '@maxtropy/strategy';
import { Modal, Button, SubContent, BaseInfoContent, Form } from '@maxtropy/components';
import { Col, Row } from 'antd';
import classnames from 'classnames/bind';
import { Key, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
const cx = classnames.bind(styles);
interface Iprops {
  detail?: TemplateDetail;
  strategyVersion?: StrategyVersion;
}
const FcsFormItemDetail: React.FC<Iprops> = ({ detail, strategyVersion }) => {
  const mainFcs = useMemo(() => {
    let fcsList = detail?.fcsList;
    return fcsList?.find(item => item.linkType === FCSType.MAIN);
  }, [detail]);
  const otherFcsList = useMemo(() => {
    let fcsList = detail?.fcsList;
    return fcsList?.filter(item => item.linkType === FCSType.OTHER) ?? [];
  }, [detail]);
  // 非主从控
  const noneFcs = useMemo(() => {
    let fcsList = detail?.fcsList;
    return fcsList?.find(item => item.linkType === FCSType.NONE);
  }, [detail]);
  const pcsList = useMemo(() => {
    return detail?.pcsList ?? [];
  }, [detail]);
  const goFcsPage = (serialNumber?: string) => {
    if (!serialNumber) return;
    window.open(`${window.IOTPLATFORMORIGIN}/device/fcs/list?serialNumber=${serialNumber ?? ''}`, '_blank');
  };
  const goNetPage = () => {
    if (!detail?.netNo) return;
    window.open(`${window.IOTPLATFORMORIGIN}/device/networking/networkingList?netNo=${detail?.netNo ?? ''}`, '_blank');
  };
  const goDevicePropsPage = (power: Key, id: number) => {
    window.open(`${window.IOTPLATFORMORIGIN}/device/manage/device/${id}/attribute/edit`, '_blank');
  };
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const previewBtn = (visible: boolean) => setPreviewModalVisible(visible);
  return (
    <SubContent>
      {/* 非主从fcs显示区域 */}
      {strategyVersion === StrategyVersion.v1 && (
        <div className={styles.none_fcs}>
          <Row>
            <Col span={24}>
              <div className={styles.main_line_item}>
                <div className={styles.left}>FCS名称 :</div>
                <div className={styles.right}>
                  <Button type="link" style={{ padding: 0 }} onClick={() => goFcsPage(noneFcs?.serialNumber)}>
                    {noneFcs?.name ?? '--'}
                  </Button>
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div className={styles.main_line_item}>
                <div className={styles.left}>FCS软件版本 :</div>
                <div className={styles.right}>{`${noneFcs?.softwareVersion ?? '-'}${
                  noneFcs?.softwareName ?? '-'
                }`}</div>
              </div>
            </Col>
          </Row>
        </div>
      )}

      {strategyVersion === StrategyVersion.v2 && (
        <Form isOrigin={true} style={{ rowGap: 16 }}>
          {/* 主控fcs显示区域 */}
          <BaseInfoContent title="主控FCS" layout="horizontal" className={styles.fcsMarginBottom}>
            <Form.Item label="主控FCS">
              <Button type="link" onClick={() => goFcsPage(mainFcs?.serialNumber)}>
                {mainFcs?.name ?? '--'}
              </Button>
            </Form.Item>
            <Form.Item label="主控FCS所在组网">
              <Button type="link" onClick={goNetPage}>
                {detail?.networkName ?? '--'}
              </Button>
            </Form.Item>
            <Form.Item label="FCS软件版本">
              {`${mainFcs?.softwareVersion ?? ''}-${mainFcs?.softwareName ?? ''}`}
            </Form.Item>
          </BaseInfoContent>
          {/* 从控fcs显示区域 */}
          <BaseInfoContent
            title="从控FCS"
            layout="horizontal"
            colNum={1}
            className={`${styles.fcsMarginBottom} ${styles.other_fcs}`}
          >
            <Row gutter={16} style={{ width: '100%' }}>
              {otherFcsList?.map(item => (
                <Col span={8} key={item.id}>
                  <div className={styles.other_line_item}>
                    <Form.Item label="FCS名称">
                      <Button type="link" onClick={() => goFcsPage(item.serialNumber)}>
                        {item.name}
                      </Button>
                    </Form.Item>
                    <Form.Item label="FCS软件版本">{`${item.softwareVersion}-${item.softwareName}`}</Form.Item>
                  </div>
                </Col>
              ))}
            </Row>
          </BaseInfoContent>
          <BaseInfoContent
            title={
              <div className={styles.pcs_title}>
                PCS信息
                <div>
                  <InfoCircleOutlined />
                  PCS需要额定功率固定属性，才可以投放策略，否则不允许投放。点击PCS名称，可以前往配置PCS固定属性
                </div>
              </div>
            }
            layout="horizontal"
            colNum={1}
            className={`${styles.fcsMarginBottom} ${styles.other_fcs}`}
          >
            <Row gutter={16} style={{ width: '100%' }}>
              {pcsList?.map(item => (
                <Col span={8} key={item.id}>
                  <div className={styles.other_line_item}>
                    <Form.Item label="PCS名称">
                      <Button type="link" onClick={() => goDevicePropsPage(item.power, item.deviceId)}>
                        {item.deviceName}
                      </Button>
                    </Form.Item>
                    <Form.Item label="PCS功率">
                      {item.power ?? '--'} {item.powerUnit ?? '--'}
                    </Form.Item>
                  </div>
                </Col>
              ))}
            </Row>
          </BaseInfoContent>

          <div className={cx('main_line_item')}>
            <div className={cx('left')}>策略预览 </div>
            <div className={cx('right', 'click', { disable: !detail?.bsaName })} onClick={() => previewBtn(true)}>
              点击预览
            </div>
          </div>
        </Form>
      )}

      {strategyVersion === StrategyVersion.v2 && detail && (
        <Modal
          open={previewModalVisible}
          title="策略预览"
          size="big"
          bodyScroll
          footer={
            <Button type="primary" onClick={() => previewBtn(false)}>
              确定
            </Button>
          }
          onCancel={() => previewBtn(false)}
        >
          <StrategyOverview version={detail.sysVersionId as VersionType} sectionsData={detail.sections} />
        </Modal>
      )}
    </SubContent>
  );
};

export default FcsFormItemDetail;
