import styles from './index.module.scss';
import superHighTemper from './imgs/super_high_temper.png';
import superLowTemper from './imgs/super_low_temper.png';
import normalLink from './imgs/normal_link.png';
import noLink from './imgs/no_link.png';
import highTemper from './imgs/high_temper.png';
import lowTemper from './imgs/low_temper.png';
import classnames from 'classnames/bind';
import { Bsa } from '@/api/bsa';
import { LinkStatus } from '@/api/device';
const cx = classnames.bind(styles);
interface Iprops {
  label?: string;
  value?: number;
  bsaDetail?: Bsa;
  type?: BlockStyle; // 样式风格
  status?: LinkStatus;
}
enum BlockStyle {
  SUPER_LOW_TEMPER,
  LOW_TEMPER,
  NORMAL_LINK,
  HIGH_TEMPER,
  SUPER_HIGH_TEMPER,
  NO_LINK,
}
const classMap = {
  [BlockStyle.SUPER_LOW_TEMPER]: 'superLow',
  [BlockStyle.LOW_TEMPER]: 'low',
  [BlockStyle.NORMAL_LINK]: 'normal',
  [BlockStyle.HIGH_TEMPER]: 'high',
  [BlockStyle.SUPER_HIGH_TEMPER]: 'superHigh',
  [BlockStyle.NO_LINK]: 'noLink',
};
const imgMap = {
  [BlockStyle.SUPER_LOW_TEMPER]: superLowTemper,
  [BlockStyle.LOW_TEMPER]: lowTemper,
  [BlockStyle.NORMAL_LINK]: normalLink,
  [BlockStyle.HIGH_TEMPER]: highTemper,
  [BlockStyle.SUPER_HIGH_TEMPER]: superHighTemper,
  [BlockStyle.NO_LINK]: noLink,
};
const BlockItem: React.FC<Iprops> = props => {
  let type = BlockStyle.NORMAL_LINK;
  let status = props.status ?? LinkStatus.NORMAL;
  const tempV = props.value!;
  const detail = props?.bsaDetail!;
  const num = detail.monomerTemperatureLayerNumber;
  if (status === LinkStatus.NORMAL) {
    if (num === 2) {
      let upper = detail.monomerTemperatureUpperOne!;
      let low = detail.monomerTemperatureLowerOne!;
      if (tempV >= upper) {
        type = BlockStyle.HIGH_TEMPER;
      }
      if (tempV < low) {
        type = BlockStyle.LOW_TEMPER;
      }
    }
    if (num === 4) {
      let {
        monomerTemperatureLowerOne: lowOne,
        monomerTemperatureLowerTwo: lowTwo,
        monomerTemperatureUpperOne: UpperOne,
        monomerTemperatureUpperTwo: UpperTwo,
      } = detail;
      if (tempV >= Math.max(UpperOne!, UpperTwo!)) {
        type = BlockStyle.SUPER_HIGH_TEMPER;
      }
      if (tempV < Math.min(lowOne!, lowTwo!)) {
        type = BlockStyle.SUPER_LOW_TEMPER;
      }
      // if (type > Math.min(UpperOne!, UpperOne!)) {
      //   type = BlockStyle.HIGH_TEMPER
      // }
      // 在区间范围
      if (tempV < Math.max(lowOne!, lowTwo!) && tempV >= Math.min(lowOne!, lowTwo!)) {
        type = BlockStyle.LOW_TEMPER;
      }
      if (tempV < Math.max(UpperOne!, UpperTwo!) && tempV >= Math.min(UpperOne!, UpperTwo!)) {
        type = BlockStyle.HIGH_TEMPER;
      }
    }
  } else {
    type = BlockStyle.NO_LINK;
  }
  return (
    <>
      <div className={cx('block_box', classMap[type])}>
        <div className={styles.img}>
          <img src={imgMap[type]} alt="" />
        </div>
        <div className={styles.content}>
          <div className={styles.label}>{props.label ?? '--'}</div>
          <div className={styles.value}>
            {status === LinkStatus.NORMAL ? `${props.value?.toFixed(1) ?? '--'}℃` : '连接异常'}
          </div>
        </div>
      </div>
    </>
  );
};
export default BlockItem;
