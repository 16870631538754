import { Cascader, Col, FormInstance, Row, Space } from 'antd';
import { FC, useContext, useEffect, useMemo } from 'react';
import { Form, SubContent, Tag } from '@maxtropy/components';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import {
  apiV2PvDispersionRatePvWithAreaPost,
  V2PvDispersionRateGetAnalysisConfigPostResponse,
} from '@maxtropy/dmes-apis-v2';
import { PvAreaContext } from '../const';

interface Props {
  form: FormInstance;
  resData: V2PvDispersionRateGetAnalysisConfigPostResponse | undefined;
}

const ArrayStruct: FC<Props> = props => {
  const { form, resData } = props;
  const pvAreaContext = useContext(PvAreaContext);
  const { setAreaIdList, setPvStationName, existComponents, setExistComponents } = pvAreaContext;

  const localAreaIdList: [number, number | undefined][] | undefined = Form.useWatch('localAreaIdList', form); // 表单中的片区列表，是级联，带有光伏站id的

  const { data: areaList } = useRequest(async () => {
    const res = await apiV2PvDispersionRatePvWithAreaPost({});
    return res.list;
  });

  const areaOptions = useMemo(() => {
    return areaList?.map(item => {
      return {
        value: item.pvId,
        label: item.pvStationName,
        existComponents: item.existComponents === 1,
        children: item.pvAreaList?.map(area => {
          // 绑定过了就禁用
          let disabled = area.status;
          // 如果是当前配置的片区，不禁用
          if (resData?.areaNameList?.map(item => item.areaId!)?.includes(area.areaId!)) {
            disabled = false;
          }
          return {
            value: area.areaId,
            label: area.pvAreaName,
            disabled: disabled,
          };
        }),
      };
    });
  }, [resData?.areaNameList, areaList]);

  const memoAreaOptions = useMemo(() => {
    if (!localAreaIdList?.length) return areaOptions;
    return areaOptions?.map(item => {
      return {
        ...item,
        disabled: item.value !== localAreaIdList?.[0]?.[0],
      };
    });
  }, [areaOptions, localAreaIdList]);

  useEffect(() => {
    if (!localAreaIdList?.length) {
      setAreaIdList?.([]);
      setPvStationName?.('');
      setExistComponents?.(false);
      return;
    }
    setAreaIdList?.(localAreaIdList.filter(item => item[1]).map(item => item[1]!));
    setPvStationName?.(areaOptions?.find(pv => pv.value === localAreaIdList?.[0]?.[0])?.label ?? '');
    setExistComponents?.(areaOptions?.find(pv => pv.value === localAreaIdList?.[0]?.[0])?.existComponents ?? false);
  }, [localAreaIdList, areaOptions]);

  return (
    <Row>
      <Col span={24}>
        <SubContent title="阵列结构">
          <Form.Item
            label="适用光伏站-片区"
            name="localAreaIdList"
            rules={[{ required: true }]}
            extra={
              <>
                <Space size={5} style={{ marginTop: 8 }}>
                  <InfoCircleOutlined style={{ color: 'var(--warning-color)' }}></InfoCircleOutlined>
                  <span>已配置的光伏站片区不可重复配置</span>
                </Space>
                <div style={{ marginTop: 12 }}>
                  <Space size={8} wrap>
                    {localAreaIdList?.map((item, index) => {
                      return (
                        <Tag
                          key={item[0] + '-' + item[1]}
                          closable
                          onClose={() => {
                            form.setFieldsValue({
                              localAreaIdList: localAreaIdList?.filter(
                                areaIdItem => !(areaIdItem[0] === item[0] && areaIdItem[1] === item[1])
                              ),
                            });
                          }}
                        >
                          {areaOptions?.find(pv => pv.value === item[0])?.label}/
                          {
                            areaOptions
                              ?.find(pv => pv.value === item[0])
                              ?.children?.find(area => area.value === item[1])?.label
                          }
                        </Tag>
                      );
                    })}
                  </Space>
                </div>
              </>
            }
          >
            <Cascader
              options={memoAreaOptions}
              multiple
              showCheckedStrategy={Cascader.SHOW_CHILD}
              onChange={v => {
                // 如果选择的一级下面没有二级，清空
                if (!v?.[0]?.[1]) {
                  form.setFieldsValue({
                    localAreaIdList: [],
                  });
                }
              }}
            ></Cascader>
          </Form.Item>
        </SubContent>
      </Col>
    </Row>
  );
};

export default ArrayStruct;
