import { Shape, Node } from '@antv/x6';
import { ReactShapeConfig, register } from '@antv/x6-react-shape';
import { DeviceTypeEnum, deviceTypeNodeShape, NodeSizeEnum } from '../const';
import ConnectPoints from './ConnectPoints';
import Precinct from './Precinct';
import VirtualGrid from './VirtualGrid';
import VirtualRoot from './VirtualRoot';

// 逆变器, 汇流箱, 回路, 并网点
const commonShapeConfigList = Object.entries(deviceTypeNodeShape).map(([k, v]) => {
  return {
    shape: v,
    width: NodeSizeEnum.COMMON_W,
    height: NodeSizeEnum.COMMON_H,
    effect: ['data'],
    component: props => <ConnectPoints {...props} deviceType={+k as DeviceTypeEnum} />,
  } as ReactShapeConfig;
});
// 其他节点
const specialShapeConfigList: ReactShapeConfig[] = [
  {
    shape: 'precinct', // 片区节点
    width: NodeSizeEnum.AREA_W,
    height: NodeSizeEnum.AREA_H,
    effect: ['data'],
    component: props => <Precinct {...props} />,
  },
  {
    shape: 'virtualRoot', // 虚拟根节点(占位用)
    width: 10,
    height: 40,
    effect: ['data'],
    component: VirtualRoot,
  },
  {
    shape: 'virtualGrid', // 虚拟回路(占位用)
    width: NodeSizeEnum.COMMON_W,
    height: NodeSizeEnum.COMMON_H,
    effect: ['data'],
    component: VirtualGrid,
  },
];

const nodeCofig: ReactShapeConfig[] = [...commonShapeConfigList, ...specialShapeConfigList];

// 边配置
Shape.Edge.config({
  zIndex: 1,
  router: {
    name: 'manhattan',
    args: {
      startDirections: ['right'],
      endDirections: ['left'],
      padding: 40,
    },
  },
  attrs: {
    line: {
      targetMarker: {
        name: 'block',
        size: 6,
      },

      stroke: '#979797',
      strokeWidth: 1,
      strokeDasharray: '3 3',
    },
  },
});
nodeCofig.forEach(item => register(item));
