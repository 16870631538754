import { ColumnType } from 'antd/lib/table';
import { getHandleModeMapping } from './utils';
import { EllipsisSpan } from '@maxtropy/components';
import {
  OBJECT_TYPE,
  VALUE_TYPE,
  valueTypeFormatter,
  objectFormatter,
  TopologicalType,
  BsaDeviceTypeEnum,
} from '../../../../../api/type';
import { EdgeDeviceTemplatePoint } from '.';
import { showFormulaFormItem } from '../../FormulaFormItem';

const topologicalTypeMapper = {
  [TopologicalType.PCS_Penetrate_BMS]: '读PCS',
  [TopologicalType.BMS_SIMULTANEOUSLY_PCS_AND_BAMS]: '读BAMS',
};

export const getColumns = (
  topologicalType: TopologicalType,
  bsaDeviceType: BsaDeviceTypeEnum,
  fixed?: boolean
): ColumnType<EdgeDeviceTemplatePoint>[] => {
  return [
    {
      title: '数据属性/标识符',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      width: 140,
      fixed: fixed ? 'left' : false,
      render: (_: undefined, record) => (
        <EllipsisSpan value={!!record.hasProperty ? record.dataPropertyName : record.identifier} />
      ),
    },
    {
      title: '单位',
      dataIndex: 'physicalUnitGeneralName',
      ellipsis: { showTitle: true },
      width: 80,
      fixed: fixed ? 'left' : false,
      render: (value: string) => <EllipsisSpan value={value} />,
    },
    {
      title: '采集点类型',
      dataIndex: 'hasProperty',
      ellipsis: { showTitle: true },
      width: 120,
      render: (value: boolean) => <EllipsisSpan value={value ? '建模采集点' : '非建模采集点'} />,
    },
    {
      title: '是否可写',
      dataIndex: 'writable',
      ellipsis: { showTitle: true },
      width: 80,
      render: (v: string) => <EllipsisSpan value={v ? '是' : '否'} />,
    },
    {
      title: 'IP地址',
      width: 120,
      dataIndex: 'ip',
      ellipsis: { showTitle: true },
      render: () => <EllipsisSpan value={topologicalTypeMapper[topologicalType]} />,
    },
    {
      title: '端口',
      width: 120,
      dataIndex: 'port',
      ellipsis: { showTitle: true },
      render: () => <EllipsisSpan value={topologicalTypeMapper[topologicalType]} />,
    },
    {
      title: '单元标识符',
      width: 120,
      dataIndex: '',
      ellipsis: { showTitle: true },
      render: () => <EllipsisSpan value={topologicalTypeMapper[topologicalType]} />,
    },
    {
      title: '功能码',
      width: 120,
      dataIndex: 'objectType',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => <EllipsisSpan value={objectFormatter[record.objectType as OBJECT_TYPE]} />,
    },
    // {
    //   title: '开始地址',
    //   width: 120,
    //   dataIndex: 'starting',
    //   ellipsis: { showTitle: true },
    //   render: (_: undefined, record) => {
    //     const value = record.parameters?.starting;
    //     const starting = !isNil(value) ? (value as Number).toString(16) : value;
    //     return <EllipsisSpan value={!isNil(value) ? `0x${starting}` : value} />;
    //   },
    // },
    // {
    //   title: '长度',
    //   width: 100,
    //   dataIndex: 'length',
    //   ellipsis: { showTitle: true },
    //   render: (_: undefined, record) => <EllipsisSpan value={lengthFormatter[record.parameters?.length as LENGTH]} />,
    // },
    {
      title: '解码类型',
      width: 140,
      dataIndex: 'valueType',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => <EllipsisSpan value={valueTypeFormatter[record.valueType as VALUE_TYPE]} />,
    },
    {
      title: '处理方式',
      dataIndex: 'handleMode',
      ellipsis: { showTitle: true },
      width: 140,
      render: (_: undefined, record) => {
        const { objectType, length, handleMode } = record;
        let value;
        const formatter = getHandleModeMapping(objectType, length);
        if (formatter) {
          const target = Object.entries(formatter).find(item => item[0] === `${handleMode}`);
          value = target ? target[1] : '--';
        }
        return <EllipsisSpan value={value} />;
      },
    },
    {
      title: '起始地址',
      width: 140,
      dataIndex: 'c',
      ellipsis: { showTitle: true },
      render: (v: number, record) => <EllipsisSpan value={showFormulaFormItem(bsaDeviceType, record)} />,
    },
    // {
    //   title: '乘积系数k',
    //   dataIndex: 'k',
    //   ellipsis: { showTitle: true },
    //   render: (_: undefined, record) => <EllipsisSpan value={record.parameters?.k} />,
    // },
    // {
    //   title: '偏移系数b',
    //   dataIndex: 'b',
    //   ellipsis: { showTitle: true },
    //   render: (_: undefined, record) => <EllipsisSpan value={record.parameters?.b} />,
    // },
    // {
    //   title: '位操作模式',
    //   dataIndex: 'mode',
    //   ellipsis: { showTitle: true },
    //   render: (_: undefined, record) => {
    //     const { bitMode, bitNum, byteNum } = record.parameters;
    //     const value = getMode(bitMode, bitNum, byteNum);
    //     return <EllipsisSpan value={value}  />;
    //   },
    // },
    // {
    //   title: '数值合理区间',
    //   dataIndex: 'dataRange',
    //   ellipsis: { showTitle: true },
    //   width: 120,
    //   render: (_: undefined, record) => {
    //     const value = getRange(record.parameters?.valueFloor, record.parameters?.valueCeiling);
    //     return <EllipsisSpan value={value} />
    //   },
    // },
    // {
    //   title: '备注',
    //   dataIndex: 'remark',
    //   ellipsis: { showTitle: true },
    //   render: (value: string) => <EllipsisSpan value={value} />,
    // },
  ];
};
