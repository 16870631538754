import { FC } from 'react';
import { Form } from 'antd';
import { Radio } from '@maxtropy/components';
import { DriveType, DriveTypeLabels, TopologicalType, TopologicalTypeLabels } from '@/api/type';

interface Props {
  disabled?: boolean;
}

const TopConfig: FC<Props> = props => {
  const { disabled } = props;

  const { Item } = Form;

  return (
    <>
      <Item
        required
        label="拓扑构型"
        name="topologicalType"
        initialValue={TopologicalType.PCS_Penetrate_BMS}
        rules={[{ required: true, message: '请选择' }]}
      >
        <Radio.Group buttonStyle="solid" disabled={disabled}>
          <Radio.Button value={TopologicalType.PCS_Penetrate_BMS}>
            {TopologicalTypeLabels[TopologicalType.PCS_Penetrate_BMS]}
          </Radio.Button>
          <Radio.Button value={TopologicalType.BMS_SIMULTANEOUSLY_PCS_AND_BAMS}>
            {TopologicalTypeLabels[TopologicalType.BMS_SIMULTANEOUSLY_PCS_AND_BAMS]}
          </Radio.Button>
          <Radio.Button disabled value={3}>
            EMS同时连接PCS和BCMU
          </Radio.Button>
          <Radio.Button disabled value={4}>
            EMS同时连接PCS和BMU
          </Radio.Button>
        </Radio.Group>
      </Item>
      <Item
        required
        label="驱动类型"
        name="driveType"
        initialValue={DriveType.MODBUS_TCP}
        rules={[{ required: true, message: '请选择' }]}
      >
        <Radio.Group buttonStyle="solid" disabled={disabled}>
          <Radio.Button value={DriveType.MODBUS_TCP}>{DriveTypeLabels[DriveType.MODBUS_TCP]}</Radio.Button>
        </Radio.Group>
      </Item>
    </>
  );
};

export default TopConfig;
