import { Space } from 'antd';
import { CSSProperties, FC, ReactNode } from 'react';
import styles from './index.module.scss';
import { Button } from '@maxtropy/components';

export interface Iprops {
  title: ReactNode;
  style?: CSSProperties;
  onOk?: () => void;
  okText?: string;
  onCancel?: () => void;
  cancelText?: string;
  showCancel?: boolean;
  showOk?: boolean;
  children?: React.ReactNode;
}

const DmesPageHeader: FC<Iprops> = props => {
  const {
    title,
    style,
    onOk,
    okText = '保存',
    onCancel,
    cancelText = '取消',
    showCancel = true,
    children,
    showOk = true,
  } = props;

  return (
    <>
      <div className={styles.pageHeaderWrapper} style={style}>
        <div className={styles.titleWrapper}>
          <p className={styles.formTitle}>
            <span className={styles.titleBorder} />
            {title}
          </p>
          <div>
            <Space>
              {showCancel && <Button onClick={() => onCancel && onCancel()}>{cancelText}</Button>}
              {children}
              {showOk && (
                <Button type="primary" onClick={() => onOk && onOk()}>
                  {okText}
                </Button>
              )}
            </Space>
          </div>
        </div>
      </div>
    </>
  );
};

export default DmesPageHeader;
