import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { BsaConfig } from '../../api/bsaConfig';
import { HardWareVersion, multiPcsHardWare } from '@/api/bsa';
import { Form, ShowInput } from '@maxtropy/components';
interface Iporps {
  config?: BsaConfig;
}
const BasicInfoConfig: React.FC<Iporps> = ({ config }) => {
  const [hardwareVersion, setHardwareVersion] = useState<HardWareVersion>();
  const [form] = Form.useForm();
  useEffect(() => {
    if (config) {
      form.setFieldsValue({
        bsaName: config.bsaName,
        hardwareVersion: config.hardwareVersion,
        fcsName: config.fcsName,
        fcsSoftwareVersion: config.fcsSoftwareVersion,
      });
      setHardwareVersion(config.hardwareVersion);
    }
  }, [config, form]);
  return (
    <>
      <Form form={form} layout="vertical">
        <Row>
          <Col span={12}>
            <Form.Item label="阵列名称" name="bsaName">
              <ShowInput />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="硬件结构版本" name="hardwareVersion">
              <ShowInput />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                hardwareVersion && multiPcsHardWare.includes(hardwareVersion as HardWareVersion)
                  ? '主控FCS名称'
                  : 'FCS名称'
              }
              name="fcsName"
            >
              <ShowInput />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                hardwareVersion && multiPcsHardWare.includes(hardwareVersion as HardWareVersion)
                  ? '主控FCS软件版本'
                  : 'FCS软件版本'
              }
              name="fcsSoftwareVersion"
            >
              <ShowInput />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default BasicInfoConfig;
