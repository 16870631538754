import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

interface Iprops {
  status: boolean;
}
const StatusTag: React.FC<Iprops> = ({ status }) => {
  return (
    <div className={classNames(styles.common, status ? styles.success : styles.fail)}>{status ? '成功' : '失败'}</div>
  );
};
export default StatusTag;
