import React, { FC } from 'react';
import styles from './index.module.scss';
import { V2PvOutlinePvPowerStatisticsPostResponse } from '@maxtropy/dmes-apis-v2';
import { ElecType } from './type';
import { isNil } from 'lodash-es';
import { Tooltip } from '@maxtropy/components';

export interface IItemPowerRender {
  title?: string;
  chartData?: V2PvOutlinePvPowerStatisticsPostResponse;
  type?: ElecType;
}

const ItemPowerRender: FC<IItemPowerRender> = props => {
  const { title, chartData, type } = props;
  return (
    <div className={styles.item_box}>
      <div className={styles.item_top_box}>
        <span className={styles.title}>{title}</span>
        <span className={styles.value}>
          <Tooltip title={keepTwoOrLine(chartData?.powerList?.find(i => i.type === type)?.totalPower)}>
            {keepTwoOrLine(chartData?.powerList?.find(i => i.type === type)?.totalPower)}
          </Tooltip>
        </span>
      </div>
      <div className={styles.item_bottom_box}>
        <div className={styles.item_power} style={{ color: '#FF6D2C' }}>
          尖&nbsp;&nbsp;
          <Tooltip title={keepTwoOrLine(chartData?.powerList?.find(i => i.type === type)?.summitPower)}>
            {keepTwoOrLine(chartData?.powerList?.find(i => i.type === type)?.summitPower)}
          </Tooltip>
        </div>
        <div className={styles.item_power} style={{ color: '#CE90D1' }}>
          峰&nbsp;&nbsp;
          <Tooltip title={keepTwoOrLine(chartData?.powerList?.find(i => i.type === type)?.peakPower)}>
            {keepTwoOrLine(chartData?.powerList?.find(i => i.type === type)?.peakPower)}
          </Tooltip>
        </div>
        <div className={styles.item_power} style={{ color: '#0DB6D9' }}>
          平&nbsp;&nbsp;
          <Tooltip title={keepTwoOrLine(chartData?.powerList?.find(i => i.type === type)?.plainPower)}>
            {keepTwoOrLine(chartData?.powerList?.find(i => i.type === type)?.plainPower)}
          </Tooltip>
        </div>
        <div className={styles.item_power} style={{ color: '#63BC7F' }}>
          谷&nbsp;&nbsp;
          <Tooltip title={keepTwoOrLine(chartData?.powerList?.find(i => i.type === type)?.vallyPower)}>
            {keepTwoOrLine(chartData?.powerList?.find(i => i.type === type)?.vallyPower)}
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default ItemPowerRender;

export const keepTwoOrLine = (data?: number) => {
  if (!isNil(data)) {
    return Number(data.toFixed(2)).toLocaleString();
  } else {
    return '--';
  }
};
