import { useEffect, useMemo, useState } from 'react';
import { getPhysicalUnits } from '../../../../api/physicalUnit';
import { DataProperty, PhysicalUnit } from '../../../../api/type';

export const useDataProperties = (dataPropertiesAll?: DataProperty[], usedProperties?: number[]) => {
  const [dataPropertyId, setDataPropertyId] = useState<number>();
  const [units, setUnits] = useState<PhysicalUnit[]>([]);

  const dataProperties = useMemo(
    () => dataPropertiesAll?.filter(item => !usedProperties?.includes(item.id)),
    [dataPropertiesAll, usedProperties]
  );

  const dataPropertyType = useMemo(
    () => dataProperties?.find(item => item.id === dataPropertyId)?.type,
    [dataProperties, dataPropertyId]
  );

  useEffect(() => {
    if (dataPropertyId) {
      getPhysicalUnits(dataPropertyId).then(setUnits);
    } else {
      setUnits([]);
    }
  }, [dataPropertyId]);

  return {
    dataProperties,
    units,
    dataPropertyType,
    dataPropertyId,
    setDataPropertyId,
  };
};
