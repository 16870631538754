import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';
import { useRequest, useSize } from 'ahooks';
import { cancelFullscreen, goFullscreen, setFitScale } from './utils';
import Header from './components/Header';
import CenterLeftBox from './components/CenterLeftBox';
import CenterGraph from './components/CenterGraph';
import CenterRightBox from './components/CenterRightBox';
import BottomBox from './components/BottomBox';
import { Col, Row } from 'antd';
import StringPower from './components/BottomBox/components/StringPower';
import StringDetails from './components/BottomBox/components/StringDetails';
import { apiV2PvNoticeboardPvInfoPost, V2PvNoticeboardPvInfoPostResponse } from '@maxtropy/dmes-apis-v2';

export interface ShareDataProps {
  baseDivRef?: HTMLDivElement;
  pvInfo?: V2PvNoticeboardPvInfoPostResponse;
}

export const ShareDataContext = createContext<ShareDataProps>({});

const PvDemonstrationBase = () => {
  const resizeRef = useRef<any>();
  const baseDivRef = useRef<HTMLDivElement>();
  const size = useSize(baseDivRef);
  const innerDivRef = useRef<HTMLDivElement>();
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false); // 是否全屏

  // 光伏信息（地址容量+组串信息）
  const { data: pvInfo } = useRequest(async () => {
    return await apiV2PvNoticeboardPvInfoPost({});
  });

  const handleResize = () => {
    if (size?.width && size?.width > 1920) {
      setFitScale(innerDivRef.current, size?.width, size?.height);
    }
  };

  // 小屏幕
  const isSmallScreen = useMemo(() => {
    return !!(size?.width && size.width <= 1600);
  }, [size?.width]);

  useEffect(() => {
    if (resizeRef.current) {
      window.removeEventListener('resize', handleResize);
    }
    resizeRef.current = window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [size?.width]);

  // 全屏
  const handleFullScreen = () => {
    setIsFullScreen(document.fullscreenElement !== null);
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreen);
    return () => document.removeEventListener('fullscreenchange', handleFullScreen);
  }, []);

  const fullBtn = () => {
    if (baseDivRef.current) {
      isFullScreen ? cancelFullscreen() : goFullscreen(baseDivRef.current);
    }
  };

  console.log('size?.width', size?.width);

  return (
    <div
      className={styles.outer_wrap}
      id="pv_full_screen_box"
      style={{ overflowY: isSmallScreen ? 'auto' : 'hidden' }}
      ref={ref => {
        if (ref) {
          baseDivRef.current = ref;
        }
      }}
    >
      <div
        className={styles.inner}
        ref={ref => {
          if (ref) {
            innerDivRef.current = ref;
          }
        }}
      >
        <ShareDataContext.Provider
          value={{
            baseDivRef: innerDivRef.current,
            pvInfo,
          }}
        >
          {isSmallScreen ? (
            <div>
              <div className={styles.inner_top}>
                <Header fullBtn={fullBtn} isFullScreen={isFullScreen} />
              </div>
              <Row style={{ height: `calc(${size?.width! * (9 / 16)}px)` }}>
                <Col span={24} style={{ display: 'flex', height: '100%' }}>
                  {/* 中间图 */}
                  <CenterGraph />
                </Col>
              </Row>
              <Row style={{ height: 650 }}>
                <Col span={12}>
                  {/* 左侧（描述、今日运行、效率分析） */}
                  <CenterLeftBox />
                </Col>
                <Col span={12}>
                  {/* 右侧（报警列表、离散率分析） */}
                  <CenterRightBox />
                </Col>
              </Row>
              <Row style={{ height: 300 }}>
                <Col span={24} style={{ display: 'flex', height: '100%' }}>
                  <StringPower />
                </Col>
              </Row>
              <Row style={{ height: 320 }}>
                <Col span={24} style={{ display: 'flex', height: '100%' }}>
                  <StringDetails />
                </Col>
              </Row>
            </div>
          ) : (
            <>
              {/* 上方header区域 */}
              <div className={styles.inner_top}>
                <Header fullBtn={fullBtn} isFullScreen={isFullScreen} />
              </div>
              {/* 中间content区域 */}
              <div className={styles.inner_center}>
                <div className={styles.center_left_box}>
                  {/* 左侧（描述、今日运行、效率分析） */}
                  <CenterLeftBox />
                </div>
                <div className={styles.center_center_box}>
                  {/* 中间图 */}
                  <CenterGraph />
                </div>
                <div className={styles.center_left_box}>
                  {/* 右侧（报警列表、离散率分析） */}
                  <CenterRightBox />
                </div>
              </div>
              <div className={styles.inner_bottom}>
                <BottomBox />
              </div>
            </>
          )}
        </ShareDataContext.Provider>
      </div>
    </div>
  );
};

export default PvDemonstrationBase;
