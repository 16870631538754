import { PermissionsType } from '@/common/permissionsConst';
import PvDemonstrationBase from './index';

const routes = [
  {
    path: '/distributed/intelligentPv/demonstrationBase',
    permission: PermissionsType.P_PVVIEW,
    element: <PvDemonstrationBase />,
  },
];

export default routes;
