import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Form, Row, Space, Spin } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import dayjs from 'dayjs';
import { Button, Breadcrumb, Checkbox, Input, message, FormContent, useBreadcrumbRoutes } from '@maxtropy/components';
import {
  apiV2BsaSecondEditionInfoBsaSecondEditionPost,
  apiV2BsaSecondEditionUpdateBsaSecondEditionPost,
  V2BsaSecondEditionInfoBsaSecondEditionPostResponse,
  V2BsaSecondEditionUpdateBsaSecondEditionPostRequest,
} from '@maxtropy/dmes-apis-v2';
import ShowInput from '@/components/ShowInput';
import BorderWrapper from '@/components/BorderWrapper';
import DividerContent from '@/components/DividerContent2';
import { LayerNumberPropsType } from './HealthFormItem';
import AddressCascader from './AddressCascader';
import HealthFormItem from './HealthFormItem';
import { BSAStatus, bsaStatusFormat } from '@/api/type';
import styles from './index.module.scss';

interface BsaNewBasicProps {
  disabled?: boolean;
}

// 表单收集的数据结构（为了提交数据的时候做变化）
export interface FormParams extends Omit<V2BsaSecondEditionUpdateBsaSecondEditionPostRequest, 'id'> {
  selectHealth: HealthParamsType[];
}

export enum PCSType {
  SIMPLE = 1,
  MORE = 2,
}

const fomatePCStype = {
  [PCSType.SIMPLE]: '单pcs',
  [PCSType.MORE]: '多pcs',
};

// 储能柜健康参数设定类型
export enum HealthParamsType {
  HASAMBIENTTEMPERATURECONFIG = 1, // 环境温度
  HASMONOMERTEMPERATURECONFIG = 2, // 单体电芯温度
  HASMONOMERVOLTAGECONFIG = 3, // 单体电压
  HASVOLTAGEUNIFORMCONFIG = 4, // 电压一致性
  HASTEMPERATUREUNIFORMCONFIG = 5, // 温度一致性
}

export const HealthParamsTypeProps = {
  [HealthParamsType.HASAMBIENTTEMPERATURECONFIG]: '环境温度', // 环境温度
  [HealthParamsType.HASMONOMERTEMPERATURECONFIG]: '单体电芯温度', // 单体电芯温度
  [HealthParamsType.HASMONOMERVOLTAGECONFIG]: '单体电压', // 单体电压
  [HealthParamsType.HASVOLTAGEUNIFORMCONFIG]: '电压一致性', // 电压一致性
  [HealthParamsType.HASTEMPERATUREUNIFORMCONFIG]: '温度一致性', // 温度一致性
};

const FORMATTIME = 'YYYY-MM-DD';

const BsaNewBasic: FC<BsaNewBasicProps> = props => {
  const { disabled = false } = props;
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const routesContext = useBreadcrumbRoutes();
  const routes = [{ name: `储能阵列基础信息` }];
  const navigate = useNavigate();
  const [data, setData] = useState<V2BsaSecondEditionInfoBsaSecondEditionPostResponse>();
  const [healthParamsSelect, setHealthParamsSelect] = useState<HealthParamsType[]>([]);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const exportsInfo = useRef<{ lngLats: string; adcode: string }>({ lngLats: '', adcode: '' });
  const getLngLat = (lngLats: string, adcode: string) => {
    exportsInfo.current = {
      lngLats,
      adcode,
    };
  };

  useEffect(() => {
    setLoading(true);
    apiV2BsaSecondEditionInfoBsaSecondEditionPost({ id: Number(id) })
      .then(res => {
        setData(res);
        form.setFieldsValue({
          ...res,
          status: bsaStatusFormat[res?.status as BSAStatus],
          location: res.location ? JSON.parse(res.location) : undefined,
          createStationTime: res?.createStationTime && dayjs(res?.createStationTime),
        });
        exportsInfo.current.adcode = res.adcode ?? '';
      })
      .finally(() => setLoading(false));
  }, [id]);

  // 储能柜健康参数回显
  useEffect(() => {
    if (!data) return;
    let selectHealth = [];
    data.hasAmbientTemperatureConfig && selectHealth.push(HealthParamsType.HASAMBIENTTEMPERATURECONFIG);
    data.hasTemperatureUniformConfig && selectHealth.push(HealthParamsType.HASTEMPERATUREUNIFORMCONFIG);
    data.hasMonomerTemperatureConfig && selectHealth.push(HealthParamsType.HASMONOMERTEMPERATURECONFIG);
    data.hasMonomerVoltageConfig && selectHealth.push(HealthParamsType.HASMONOMERVOLTAGECONFIG);
    data.hasVoltageUniformConfig && selectHealth.push(HealthParamsType.HASVOLTAGEUNIFORMCONFIG);
    form.setFieldsValue({
      selectHealth,
    });
    setHealthParamsSelect(selectHealth);
  }, [data, form]);

  // 储能柜健康参数改变引起下面动态表单的变化
  const onCheckBoxHealthChange = (value: HealthParamsType[] | CheckboxValueType[]) => {
    setHealthParamsSelect(value as HealthParamsType[]);
  };

  // 环境温度，单体温度，单体电压等检验
  const inputTemperatureValid = (): boolean => {
    if (healthParamsSelect.length === 0) {
      return true;
    }
    // 环境温度
    if (healthParamsSelect.includes(HealthParamsType.HASAMBIENTTEMPERATURECONFIG)) {
      if (form.getFieldValue('ambientTemperatureLayerNumber') === LayerNumberPropsType.Two) {
        if (form.getFieldValue('ambientTemperatureUpperOne') <= form.getFieldValue('ambientTemperatureLowerOne')) {
          message.warning('环境温度下限1必须低于上限1');
          return false;
        }
      }
      if (form.getFieldValue('ambientTemperatureLayerNumber') === LayerNumberPropsType.Four) {
        if (
          form.getFieldValue('ambientTemperatureUpperOne') <= form.getFieldValue('ambientTemperatureLowerOne') ||
          form.getFieldValue('ambientTemperatureUpperOne') <= form.getFieldValue('ambientTemperatureLowerTwo') ||
          form.getFieldValue('ambientTemperatureUpperTwo') <= form.getFieldValue('ambientTemperatureLowerOne') ||
          form.getFieldValue('ambientTemperatureUpperTwo') <= form.getFieldValue('ambientTemperatureLowerTwo')
        ) {
          message.warning('环境温度下限必须低于上限');
          return false;
        }
        if (form.getFieldValue('ambientTemperatureUpperOne') <= form.getFieldValue('ambientTemperatureUpperTwo')) {
          message.warning('环境温度上限1必须大于上限2');
          return false;
        }
        if (form.getFieldValue('ambientTemperatureLowerOne') >= form.getFieldValue('ambientTemperatureLowerTwo')) {
          message.warning('环境温度下限1必须小于下限2');
          return false;
        }
      }
    }

    // 单体温度
    if (healthParamsSelect.includes(HealthParamsType.HASMONOMERTEMPERATURECONFIG)) {
      if (form.getFieldValue('monomerTemperatureLayerNumber') === LayerNumberPropsType.Two) {
        if (form.getFieldValue('monomerTemperatureUpperOne') <= form.getFieldValue('monomerTemperatureLowerOne')) {
          message.warning('单体温度下限1必须低于上限1');
          return false;
        }
      }
      if (form.getFieldValue('monomerTemperatureLayerNumber') === LayerNumberPropsType.Four) {
        if (form.getFieldValue('monomerTemperatureUpperOne') <= form.getFieldValue('monomerTemperatureUpperTwo')) {
          message.warning('单体温度上限1必须大于上限2');
          return false;
        }
        if (form.getFieldValue('monomerTemperatureLowerOne') >= form.getFieldValue('monomerTemperatureLowerTwo')) {
          message.warning('单体温度下限1必须小于下限2');
          return false;
        }
        if (
          form.getFieldValue('monomerTemperatureUpperOne') <= form.getFieldValue('monomerTemperatureLowerOne') ||
          form.getFieldValue('monomerTemperatureUpperOne') <= form.getFieldValue('monomerTemperatureLowerTwo') ||
          form.getFieldValue('monomerTemperatureUpperTwo') <= form.getFieldValue('monomerTemperatureLowerOne') ||
          form.getFieldValue('monomerTemperatureUpperTwo') <= form.getFieldValue('monomerTemperatureLowerTwo')
        ) {
          message.warning('单体温度下限必须低于上限');
          return false;
        }
      }
    }

    // 单体电压
    if (healthParamsSelect.includes(HealthParamsType.HASMONOMERVOLTAGECONFIG)) {
      if (form.getFieldValue('monomerVoltageLayerNumber') === LayerNumberPropsType.Two) {
        if (form.getFieldValue('monomerVoltageUpperOne') <= form.getFieldValue('monomerVoltageLowerOne')) {
          message.warning('单体电压下限1必须低于上限1');
          return false;
        }
      }
      if (form.getFieldValue('monomerVoltageLayerNumber') === LayerNumberPropsType.Four) {
        if (form.getFieldValue('monomerVoltageUpperOne') <= form.getFieldValue('monomerVoltageUpperTwo')) {
          message.warning('单体电压上限1必须大于上限2');
          return false;
        }

        if (form.getFieldValue('monomerVoltageLowerOne') >= form.getFieldValue('monomerVoltageLowerTwo')) {
          message.warning('单体电压下限1必须小于下限2');
          return false;
        }
        if (
          form.getFieldValue('monomerVoltageUpperOne') <= form.getFieldValue('monomerVoltageLowerOne') ||
          form.getFieldValue('monomerVoltageUpperOne') <= form.getFieldValue('monomerVoltageLowerTwo') ||
          form.getFieldValue('monomerVoltageUpperTwo') <= form.getFieldValue('monomerVoltageLowerOne') ||
          form.getFieldValue('monomerVoltageUpperTwo') <= form.getFieldValue('monomerVoltageLowerTwo')
        ) {
          message.warning('单体电压下限必须低于上限');
          return false;
        }
      }
    }

    return true;
  };

  const formatFormValues = (
    val: FormParams
  ): Omit<V2BsaSecondEditionUpdateBsaSecondEditionPostRequest, 'id' | 'latitude' | 'longitude'> => {
    let params = { ...val };
    params.hasAmbientTemperatureConfig = params.selectHealth.includes(HealthParamsType.HASAMBIENTTEMPERATURECONFIG);
    params.hasMonomerTemperatureConfig = params.selectHealth.includes(HealthParamsType.HASMONOMERTEMPERATURECONFIG);
    params.hasMonomerVoltageConfig = params.selectHealth.includes(HealthParamsType.HASMONOMERVOLTAGECONFIG);
    params.hasTemperatureUniformConfig = params.selectHealth.includes(HealthParamsType.HASTEMPERATUREUNIFORMCONFIG);
    params.hasVoltageUniformConfig = params.selectHealth.includes(HealthParamsType.HASVOLTAGEUNIFORMCONFIG);
    return params;
  };

  const onFinish = () => {
    let isPassTemperature = inputTemperatureValid();
    if (!isPassTemperature) return;
    form.validateFields().then((res: FormParams) => {
      setConfirmLoading(true);
      let newRes = formatFormValues(res);
      apiV2BsaSecondEditionUpdateBsaSecondEditionPost({
        ...newRes,
        status: undefined, // 不传状态
        id: Number(id),
        location: JSON.stringify(newRes.location),
        adcode: exportsInfo.current.adcode,
        createStationTime: dayjs(newRes.createStationTime).format(FORMATTIME),
        longitude: exportsInfo.current.lngLats ? Number(exportsInfo.current.lngLats.split(',')[0]) : undefined,
        latitude: exportsInfo.current.lngLats ? Number(exportsInfo.current.lngLats.split(',')[1]) : undefined,
      })
        .then(() => {
          navigate('/energy-storage/basic/bsaNew');
          message.success('保存成功');
        })
        .finally(() => {
          setConfirmLoading(false);
        });
    });
  };

  return (
    <div className={styles.page_box}>
      <div className="page">
        <div className="page__header">
          <Breadcrumb routes={[...(routesContext?.routes ?? []), ...routes]} />
        </div>
        <BorderWrapper>
          <div className="page__content">
            <Spin spinning={loading}>
              <Form labelCol={{ span: 24 }} wrapperCol={{ span: 18 }} form={form} labelAlign="left" labelWrap>
                <FormContent title="储能阵列基础信息" style={{ padding: '15px 10px' }}>
                  <DividerContent title="基础资料">
                    <Row>
                      <Col span={8}>
                        <Form.Item
                          label="阵列名称"
                          name="name"
                          rules={[
                            { required: true, message: '请输入' },
                            { max: 30, message: '最多30个字' },
                            {
                              pattern: /^[A-Za-z0-9\u4e00-\u9fa5-]+$/g,
                              message: '只支持汉字、字母、数字、-',
                            },
                          ]}
                        >
                          <Input placeholder="请输入" disabled={disabled} />
                        </Form.Item>
                      </Col>
                      <AddressCascader form={form} disabled={disabled} getLngLat={getLngLat} />
                      <Col span={8}>
                        <Form.Item label="组织名称" name="customerName">
                          <ShowInput />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label="运营单元" name="ouName">
                          <ShowInput />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label="阵列结构类型" noStyle dependencies={['pcsStruct']}>
                          {({ getFieldValue }) => {
                            let value = getFieldValue('pcsStruct') as PCSType;
                            let label = value ? fomatePCStype[value] : '--';
                            return (
                              <Form.Item label="阵列结构类型">
                                <ShowInput value={label} />
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item noStyle shouldUpdate>
                          {({ getFieldValue }) => {
                            let hardwareVersion = getFieldValue('hardwareVersion');
                            let hardwareName = getFieldValue('hardwareName');
                            return (
                              <Form.Item label="硬件版本">
                                <ShowInput
                                  value={`${hardwareVersion ?? ''}${hardwareName ? '-' + hardwareName : ''}`}
                                />
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label="阵列编号" name="serialNumber">
                          <ShowInput />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label="状态" name="status">
                          <ShowInput />
                        </Form.Item>
                      </Col>
                    </Row>
                  </DividerContent>

                  <DividerContent title="储能柜健康参数设定">
                    <Form.Item name="selectHealth">
                      <Checkbox.Group onChange={onCheckBoxHealthChange} disabled={disabled}>
                        <Checkbox value={HealthParamsType.HASAMBIENTTEMPERATURECONFIG}>
                          {HealthParamsTypeProps[HealthParamsType.HASAMBIENTTEMPERATURECONFIG]}
                        </Checkbox>
                        <Checkbox value={HealthParamsType.HASMONOMERTEMPERATURECONFIG}>
                          {HealthParamsTypeProps[HealthParamsType.HASMONOMERTEMPERATURECONFIG]}
                        </Checkbox>
                        <Checkbox value={HealthParamsType.HASMONOMERVOLTAGECONFIG}>
                          {HealthParamsTypeProps[HealthParamsType.HASMONOMERVOLTAGECONFIG]}
                        </Checkbox>
                        <Checkbox value={HealthParamsType.HASVOLTAGEUNIFORMCONFIG}>
                          {HealthParamsTypeProps[HealthParamsType.HASVOLTAGEUNIFORMCONFIG]}
                        </Checkbox>
                        <Checkbox value={HealthParamsType.HASTEMPERATUREUNIFORMCONFIG}>
                          {HealthParamsTypeProps[HealthParamsType.HASTEMPERATUREUNIFORMCONFIG]}
                        </Checkbox>
                      </Checkbox.Group>
                    </Form.Item>
                    {healthParamsSelect.length !== 0 ? (
                      healthParamsSelect.map((item: HealthParamsType, index) => (
                        <SubDividerContent key={index} title={HealthParamsTypeProps[item] + '参数'}>
                          <HealthFormItem itemHealthSelect={item} disabled={disabled} />
                        </SubDividerContent>
                      ))
                    ) : (
                      <></>
                    )}

                    <Form.Item style={{ marginTop: 10 }}>
                      <Space>
                        {!disabled && (
                          <Button type="primary" onClick={onFinish} loading={confirmLoading}>
                            保存
                          </Button>
                        )}
                        <Button onClick={() => navigate('/energy-storage/basic/bsaNew')}>取消</Button>
                      </Space>
                    </Form.Item>
                  </DividerContent>
                </FormContent>
              </Form>
            </Spin>
          </div>
        </BorderWrapper>
      </div>
    </div>
  );
};

export default BsaNewBasic;

interface SubDividerContentProps {
  title: string;
  children: React.ReactNode;
}

const SubDividerContent: FC<SubDividerContentProps> = props => {
  const { title, children } = props;

  return (
    <div className={styles.subDividerContent}>
      <div className={styles.title}>
        <span>{title}</span>
        <div className={styles.titleLine}></div>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
