import { CircuitProps } from '@/api/circuit';
import { Form, message, Modal, Radio, ShowInput } from '@maxtropy/components';
import { apiV2CircuitDataPeakValleyConfigPost, apiV2CircuitDataPeakValleyDetailPost } from '@maxtropy/dmes-apis-v2';
import { Space } from 'antd';
import { isNil } from 'lodash-es';
import React, { FC, useEffect } from 'react';

// 数据配置方式
export enum TypeAction {
  ORIGIN = 0, // 采集尖峰平谷原始值
  CALC = 1, // 回路尖峰平谷计算值
}

// 数据配置方式的值
export const TypeActionValue = {
  [TypeAction.ORIGIN]: '采集尖峰平谷原始值',
  [TypeAction.CALC]: '回路尖峰平谷计算值',
};

export interface ISettingModal {
  settingVisible: boolean;
  onCancel: () => void;
  currentCircuitId?: number;
  circuitList: CircuitProps[];
  setSettingType: React.Dispatch<React.SetStateAction<TypeAction | undefined>>;
}

const SettingModal: FC<ISettingModal> = props => {
  const { settingVisible, onCancel, currentCircuitId, circuitList, setSettingType } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    if (currentCircuitId && circuitList) {
      apiV2CircuitDataPeakValleyDetailPost({ id: currentCircuitId }).then(res => {
        form.setFieldsValue({
          name: circuitList.find(i => i.id === currentCircuitId)?.name,
          type: res.type,
        });
        setSettingType(!isNil(res.type) ? res.type : TypeAction.CALC);
      });
    }
  }, [settingVisible, currentCircuitId, circuitList]);

  const onFinish = (values: any) => {
    apiV2CircuitDataPeakValleyConfigPost({ circuitId: currentCircuitId, type: values.type }).then(res => {
      message.success('保存成功');
      setSettingType(values.type);
      onCancel();
    });
  };

  return (
    <Modal
      open={settingVisible}
      title="峰谷分析数据配置"
      contentClassName="modal-form-content"
      destroyOnClose
      onOk={() => {
        form.submit();
      }}
      okText="保存"
      onCancel={onCancel}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item label="进线名称" name="name">
          <ShowInput />
        </Form.Item>
        <Form.Item label="数据配置方式" name="type" initialValue={TypeAction.CALC}>
          <Radio.Group>
            <Space direction="vertical">
              <Radio value={TypeAction.ORIGIN}>{TypeActionValue[TypeAction.ORIGIN]}</Radio>
              <Radio value={TypeAction.CALC}>{TypeActionValue[TypeAction.CALC]}</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SettingModal;
