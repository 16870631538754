import { Device } from './bsaConfig';
import { PageRequest, PageResponse } from './page';
import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { Ou } from './ou';
import { PVStatus } from './type';
import request from '@/utils/request';

export interface LoadTransformersProps {
  deviceId: number;
  targetLoad?: number;
  totalLoadDeviceId?: number;
  productionLoadDeviceId?: number;
  level: string;
  sequence: number;
  rootDeviceId?: number;
  parentDeviceId?: number;
  device?: Device;
}
export interface NetInfoProp {
  name?: string;
  address?: string;
  level?: number;
  meterId?: number;
  loadTransformerIds?: number[];
  pvStationId?: number;
  id?: number;
  meter?: Device[];
  createTime?: any;
  updateTime?: any;
  loadTransformers?: LoadTransformersProps[];
}
// 汇流箱属性
export interface CombinerBoxProps {
  id?: number;
  code?: string;
  deviceCode?: string;
  deviceId?: number;
  deviceName?: string;
  gridConnectionPointId?: number;
  gridConnectionPointName?: string;
  pvAreaId?: number;
  pvAreaCode?: number;
  pcAreaName?: string;
  pvId?: any;
}
export interface CombinerDeviceProps {
  id?: number;
  code?: string;
  name?: string;
  deviceType?: string;
  deviceTag?: string;
  customerName?: string;
  typeId?: number;
}
export interface CombinerReq extends PageRequest {
  pvId: any;
  code?: string;
  gridConnectionPointId?: any;
  pvAreaId?: any;
}
// 查询汇流箱列表
export function getCombinerBoxList(params: CombinerReq) {
  return fetch<PageResponse<CombinerBoxProps>>(`/api/combiner-box/page?${qs.stringify(params, { indices: false })}`);
}
// 创建汇流箱
export function createCombinerBox(query: CombinerBoxProps) {
  return fetch(`/api/combiner-box/create`, {
    method: 'POST',
    body: JSON.stringify(query),
  });
}
// 编辑/更新汇流箱
export function updateCombinerBox(query: CombinerBoxProps) {
  return fetch(`/api/combiner-box/update`, {
    method: 'PUT',
    body: JSON.stringify(query),
  });
}
// 删除汇流箱
export function deleteCombinerBox(id: any) {
  return fetch<boolean>(`/api/combiner-box/delete/${id}`, {
    method: 'DELETE',
  });
}
// 删除所有汇流箱
export function deleteAllCombinerBox(pvId: any) {
  return fetch<boolean>(`/api/combiner-box/delete-all/${pvId}`, {
    method: 'DELETE',
  });
}
// 移除汇流箱设备 汇流箱id
export function removeCombinerBoxDevice(id: string) {
  return fetch(`/api/combiner-box/remove-device/${id}`, {
    method: 'PUT',
  });
}
// 更换汇流箱设备 汇流箱id
export function changeCombinerBoxDevice(id: any, deviceId: any) {
  return fetch(`/api/combiner-box/update-device/${id}?deviceId=${deviceId}`, {
    method: 'PUT',
  });
}
// 导出汇流箱 光伏站id
export function exportCombinerBox(pvId: any) {
  // return fetch(`/api/combiner-box/export/${pvId}`, {
  //   method: "GET",
  // });
  return request({ url: `/api/combiner-box/export/${pvId}`, responseType: 'blob' });
}
// 查询汇流箱设备列表
export function getCombinerBoxDeviceList(params: PageRequest) {
  return fetch<PageResponse<CombinerDeviceProps>>(
    `/api/combiner-box/device-page?${qs.stringify(params, { indices: false })}`
  );
}

export interface PvStationReq extends PageRequest {
  nameOrCode?: string;
  ouId?: string;
}
// 所属组织
export interface Customer {
  mcid?: string;
  name?: string;
  fullName?: string;
  uscc?: string;
  address?: string;
  phone?: string;
  provinceCityDistrict?: string;
  adCode?: string;
  disabled?: boolean;
  logoUrl?: string;
}

// 光伏站列表
export interface PvListItem {
  weatherStationId?: number;
  weatherStation?: WeatherStation;
  id?: number;
  ou?: Ou;
  code?: string;
  hasPutOn?: boolean; // 是否已投放
  status?: PVStatus;
  createTime?: any;
  updateTime?: any;
  mcid?: string;
  updateByUserId?: string;
  updateByUsername?: string;
  uetId?: number;
  installedCapacity?: number;
  carbonEmissionFactor?: number;
  solarPanelArea?: number;
  conversionEfficiency?: number;
  location?: any;
  customer?: Customer;
  address?: string;
  adcode?: string; // 高德地图区域编码
  remark?: string;
  latitude?: number;
  longitude?: number;
  stage?: string;
  createStationTime?: string;
  existCombinerBox?: number;
  existComponents?: number;
}
// 光伏站管理列表
export function getPvStationList(params: PvStationReq) {
  return fetch<PageResponse<PvListItem>>(`/api/pvStation/page?${qs.stringify(params, { indices: false })}`);
}
// 修改/更新光伏站状态
export function updatePvStationStatus(id: number, status: PVStatus) {
  return fetch<boolean>(`/api/pvStation/${id}?${qs.stringify({ status }, { indices: false })}`, {
    method: 'PUT',
  });
}
// 获取光伏相关的成本信息
export interface CostInfo {
  investmentCost?: number;
  recoveredCost?: number;
  electricityDiscount?: number;
  internetRevenueSharing?: number;
}
export function getPvCostInfo(id: number) {
  return fetch<CostInfo>(`/api/pvStation/${id}/costInfo`);
}
// 更新/新增光伏相关的成本信息
export function updatePvCostInfo(id: number, data: CostInfo) {
  return fetch<boolean>(`/api/pvStation/${id}/costInfo`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

// 获取光伏站详情
export function getPvInfo(id: string) {
  return fetch<PvListItem>(`/api/pvStation/${id}`);
}
// 新增/编辑基础信息
export function editPvStationBaseInfo(id: string, data: PvListItem) {
  return fetch(`/api/pvStation/${id}/basicInfo`, {
    method: 'PUT',
    body: JSON.stringify(data),
  });
}
export interface PvAreaInfo {
  id?: number;
  code?: string;
  name?: string;
  address?: string;
  remark?: string;
  pvStationId?: number | string;
}
// 获取片区列表
export function getPvAreaList(id: string) {
  return fetch<PvAreaInfo[]>(`/api/pvStation/${id}/pvArea`);
}
// 新增片区
export function addPvArea(id: string, data: PvAreaInfo) {
  return fetch<boolean>(`/api/pvStation/${id}/pvArea`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}
// 修改片区
export function editPvArea(id: string | number, areaId: string | number, data: PvAreaInfo) {
  return fetch<boolean>(`/api/pvStation/${id}/pvArea/${areaId}`, {
    method: 'PUT',
    body: JSON.stringify(data),
  });
}
// 删除片区
export function deletePvArea(id?: string, areaId?: number) {
  return fetch<boolean>(`/api/pvStation/${id}/pvArea/${areaId}`, {
    method: 'DELETE',
  });
}

// 地理位置
// 行政区域查询
interface LocationReq {
  keywords?: string;
  subdistrict?: string;
}
export type LocationResponse = {
  name: string;
  adcode: string;
  districts?: Location[];
};
export function getDistrict(params: LocationReq) {
  return fetch<LocationResponse>(`/api/location/district?${qs.stringify(params, { indices: false })}`);
}
// 获取输入框提示
interface AddressTips {
  keywords?: string;
  city?: string;
  cityLimit?: boolean;
}
export interface AddressTipsResponse {
  id?: string;
  name?: string;
  address?: string;
  location?: string;
  district?: string;
}
export function getAddressTips(params: AddressTips) {
  return fetch<{ tips?: AddressTipsResponse[] }>(`/api/location/inputTips?${qs.stringify(params, { indices: false })}`);
}

// 获取某一光伏站下的并网点列表
export function getGridNetPointList(id: any, params: PageRequest) {
  return fetch<PageResponse<NetInfoProp>>(
    `/api/pvStation/${id}/gridConnectionPoints?${qs.stringify(params, { indices: false })}`
  );
}

// 修改当前阵列步骤
export function modifyStage(id: any, stage: any) {
  return fetch<boolean>(`/api/pv/modify-stage/${id}?stage=${stage}`, {
    method: 'POST',
  });
}

export const gridConnectionPointKeys = {
  list: (pvId: number) => ['gridConnectionPoints', pvId],
};

export const getGridConnectionPointList = (id: number) =>
  request<NetInfoProp[]>({ url: `/api/pvStation/${id}/gridConnectionPointList` });

export const combinerBoxKeys = {
  list: (pvId: number) => ['combineBoxes', pvId],
};

export const getCombinerBoxes = (pvId: number) =>
  request<CombinerBoxProps[]>({ url: `/api/combiner-box/list`, params: { pvId } });

export interface WeatherStation {
  id: number;
  name: string;
  sn: string;
  code: string;
}

export const getWeatherStations = (pvId: number) =>
  request<WeatherStation[]>({ url: `/api/pvStation/${pvId}/candidateWeatherStations` });
