import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
interface Iprops {
  prevRoute?: string;
}
const Page500: React.FC = (props: Iprops) => {
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.box}>500, 您的页面出现未知错误, 请联系管理员</div>
      <div className={styles.back} onClick={() => navigate(props.prevRoute ?? '/home')}>
        -&gt; 点击返回首页
      </div>
    </>
  );
};

export default Page500;
