import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { PageRequest, PageResponse } from './page';
import { CircuitProps } from './circuit';

import { Key } from 'react';
import { ProcessStatus } from './type';
import { PVType } from '@/components/SelectDeviceModal';
import request from '@/utils/request';

export interface DevicePageReq extends PageRequest {
  codeOrName?: string;
  tag?: string;
  typeIds?: number[];
  ouId: number;
}

export interface PVDevicePageQuery extends PageRequest {
  codeOrName?: string;
  deviceTag?: string;
  deviceTypes?: number[];
  ouId: number;
  pvId: number;
  pvType: PVType;
}

export interface DevicePageProps {
  code: string;
  customerFullName: string;
  customerMcid: string;
  customerName: string;
  id: number;
  name: string;
  rootMcid: string;
  sn: string;
  status: number;
  tags: string[];
  tenantName: string;
  typeId: number;
  typeName: string;
  updateTime: string;
  circuits: CircuitProps[];
}

export interface CircuitDevicesRes {
  deviceDto: DevicePageProps;
  deviceId: number;
  deviceType: number;
  host: boolean;
}

export const getDevicePage = (query: DevicePageReq) =>
  fetch<PageResponse<DevicePageProps>>(`/api/device/page?${qs.stringify(query, { indices: false })}`);

export const getPVDevicePage = (query: PVDevicePageQuery) =>
  fetch<PageResponse<DevicePageProps>>(`/api/combiner-box/device-page?${qs.stringify(query, { indices: false })}`);

export interface DeviceTreeProps {
  id: number;
  name: string;
  children?: DeviceTreeProps[];
}

export const getDeviceTypeTree = () => fetch<DeviceTreeProps[]>(`/api/device-type/tree`);

/** BSA */
export interface BsaDeviceTemplateRequest {
  bsaId: Key;
  deviceTypeId: number;
  modelName: string;
}

export function createRackDevice(request: BsaDeviceTemplateRequest) {
  return fetch<void>(`/api/device/bsa/rack`, {
    method: 'POST',
    body: JSON.stringify(request),
  });
}
// 生成电池芯设备
export function createCellDevice(request: BsaDeviceTemplateRequest) {
  return fetch<void>(`/api/device/bsa/cell`, {
    method: 'POST',
    body: JSON.stringify(request),
  });
}

export function createPackDevice(request: BsaDeviceTemplateRequest) {
  return fetch<void>(`/api/device/bsa/pack`, {
    method: 'POST',
    body: JSON.stringify(request),
  });
}

export function submitRackDevice(bsaId: Key) {
  return fetch<void>(`/api/device/bsa/rack/created?bsaId=${bsaId}`, {
    method: 'POST',
  });
}

export function submitPackDevice(bsaId: Key) {
  return fetch<void>(`/api/device/bsa/pack/created?bsaId=${bsaId}`, {
    method: 'POST',
  });
}
// 电池芯创建
export function submitCellDevice(bsaId: Key) {
  return fetch<void>(`/api/device/bsa/cell/created?bsaId=${bsaId}`, {
    method: 'POST',
  });
}

export function rackStatusCheck(bsaId: Key) {
  return fetch<ProcessStatus>(`/api/device/bsa/rack/status-check?bsaId=${bsaId}`);
}

export function packStatusCheck(bsaId: Key) {
  return fetch<ProcessStatus>(`/api/device/bsa/pack/status-check?bsaId=${bsaId}`);
}
// 电池芯设备状态
export function cellStatusCheck(bsaId: Key) {
  return fetch<ProcessStatus>(`/api/device/bsa/cell/status-check?bsaId=${bsaId}`);
}

/** BSA */

export const queryDeviceListByCircuitId = (circuitId: number) =>
  fetch<DevicePageProps[]>(`/api/device/listByCircuitId?${qs.stringify({ circuitId }, { indices: false })}`);

export interface DeviceDataQuery {
  deviceIds: string;
  propertyIds: string;
  startTime: number;
  endTime: number;
}

export interface PointValue {
  time: number;
  value: number;
  originalTime?: number;
}

export interface DeviceData {
  deviceId: number;
  propertyId: number;
  values: PointValue[];
}
export interface DeviceDataForRaw {
  year: number;
  deviceId: number;
  propertyId: number;
  time: number;
  value: number;
}
export enum GrainChoice {
  ORIGINAL = 'original',
  MINUTE_1 = '1M',
  MINUTE_15 = '15M',
}

export interface SocDataQuery {
  bsaId: number;
  startTime: number;
  resolution?: GrainChoice; // 15分钟 1分钟
  endTime: number;
}

export interface PcsPowerDataQuery extends SocDataQuery {}

// 15分钟
export const getDeviceDataInstantaneous = (params: DeviceDataQuery) =>
  request<DeviceData[]>({ url: `/api/device/dataProperty/resampled/instantaneous`, params });
// 1分钟
export const getDeviceDataInstantaneousFor1m = (params: DeviceDataQuery) =>
  request<DeviceData[]>({ url: `/api/device/dataProperty/resampled/instantaneous1m`, params });

// 原始值
export const getDeviceDataInstantaneousForRaw = (params: DeviceDataQuery): Promise<DeviceData[]> =>
  request<DeviceDataForRaw[]>({ url: `/api/device/dataProperty/raw`, params }).then(res => {
    let ids = res.map(item => item.propertyId + '$' + item.deviceId);
    let uniqueIds = [...new Set(ids)];
    let temps = uniqueIds.map(str => {
      let datas = res.filter(i => i.propertyId + '$' + i.deviceId === str);
      let tempArr = str.split('$');
      return {
        deviceId: +tempArr[1],
        propertyId: +tempArr[0],
        values: datas,
      };
    });
    return temps;
  });

// 储能系统 15分钟 1分钟
export const getBsaSocData = (params: SocDataQuery) =>
  request<PointValue[]>({ url: `/api/device/dataProperty/soc`, params });
// pcs功率 15分钟 1分钟
export const getBsaPcsPowerData = (params: PcsPowerDataQuery) =>
  request<PointValue[]>({ url: `/api/device/dataProperty/power`, params });

export interface DeviceDataRecentQuery {
  deviceIds: string;
  propertyIds: string;
}

export enum LinkStatus {
  OFFLINE,
  NORMAL,
  PARTONLINE,
}
export interface DeviceDataRecentResponse {
  deviceId: number;
  propertyId: number;
  value: number;
  status?: LinkStatus;
  ts: string;
}
// 温度实时数据
export const getDeviceDataRecent = (params: DeviceDataRecentQuery) =>
  request<DeviceDataRecentResponse[]>({ url: `/api/device/dataProperty/recent`, params });

// 瞬时量聚合值
export interface DeviceTimeAggrInstantaneousQuery {
  deviceIds: string;
  propertyIds: string;
  resolution: string;
  aggrType: string;
  startTime: number;
  endTime: number;
}

// 根据设备id、数据属性id和时间颗粒度查询瞬时量聚合值
export const getTimeAggrInstantaneous = (params: DeviceTimeAggrInstantaneousQuery) =>
  request<DeviceData[]>({ url: `/api/device/dataProperty/time-aggr/instantaneous`, params });

interface valueItem {
  time: number;
  value: number;
}
export interface cumulativeRes {
  deviceId: number;
  propertyId: number;
  values: valueItem[];
}

export interface cumulativeReq {
  deviceIds: number;
  propertyIds: string;
  resolution: string;
  startTime: number;
  endTime: number;
}
export const getCircuitCumulative = (query: cumulativeReq) => {
  return fetch<cumulativeRes[]>(
    `/api/device/dataProperty/time-aggr/cumulative?${qs.stringify(query, { indices: false })}`
  );
};
// 查询设备下的ou
export function getOuIdsByDeviceId(id: number) {
  return fetch<{ list: { id: number }[] }>(
    `/api/v2/device/ou/list`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  ).then(res => res.list);
}
