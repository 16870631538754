import { Breadcrumb, useBreadcrumbRoutes } from '@maxtropy/components';
import { StrategyForm, VersionType } from '@maxtropy/strategy';
import { useForm } from 'antd/es/form/Form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  fetchFieldStrategyDetail,
  FieldStrategyBody,
  getTemplatePcsDeviceLink,
  TemplatePcsDeviceLinkRes,
  updateFieldStatus,
  updateFieldStrategy,
} from '../../../api/strategy';
import BorderWrapper from '../../../components/BorderWrapper';
import FormHeader from '../../../components/FormHeader';
import { FormStatus } from '../../../types';
import { StrategyRunningStatus } from '../types';
import { App } from 'antd';
import qs from 'qs';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';

interface FieldStrategyPageProps {
  formStatus?: FormStatus;
}

const FieldStrategyPage: React.FC<FieldStrategyPageProps> = ({ formStatus = FormStatus.Create }) => {
  const { id } = useParams<{ id: string }>();
  const query: any = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const navigate = useNavigate();
  const routes = [{ name: `${FormStatus.MAP[formStatus] + '就地策略'}` }];
  const { modal, message } = App.useApp();
  const routesContext = useBreadcrumbRoutes();

  const { mutateAsync, isLoading: isSubmitting } = useMutation((data: FieldStrategyBody) =>
    updateFieldStrategy(query.bsaId, data)
  );

  const mutateStatus = useMutation(
    (params: { strategyIds: number; status: StrategyRunningStatus; version: number; globalVersion: number }) =>
      updateFieldStatus(query.bsaId, params)
  );

  const { data, isLoading } = useQuery(['fieldStrategy', id], () => fetchFieldStrategyDetail(query.bsaId, +id), {
    enabled: !!id,
  });

  const [form] = useForm();

  const onSubmit = () => {
    form.submit();
  };

  const onUpdateStatus = () => {
    if (!data) {
      return;
    }
    mutateStatus
      .mutateAsync({
        strategyIds: data.id,
        version: data.version,
        globalVersion: data.globalVersion,
        status:
          data.status === StrategyRunningStatus.PUT_INTO_OPERATION
            ? StrategyRunningStatus.OUT_OF_SERVICE
            : StrategyRunningStatus.PUT_INTO_OPERATION,
      })
      .then(res => {
        if (res) {
          message.success('操作成功!');
          navigate('/dmes/energy-storage/strategy/calendar', { replace: true });
        }
      });
  };

  const onFinish = (values: any) => {
    mutateAsync({
      ...values,
      version: data?.version,
      id: data?.id,
      templateId: data!.templateId,
      globalVersion: data!.globalVersion,
      executiveTime: data!.executiveTime,
      status: data?.status,
    }).then(res => {
      if (!res) {
        return;
      }
      if (res.result) {
        message.success('保存成功！');
        // 恢复原来的筛选条件,并查询
        navigate('/dmes/energy-storage/strategy/calendar?bsaId=' + query.bsaId, { replace: true });
      } else {
        modal.error({
          content: res.message,
        });
      }
    });
  };
  const [upstreamTransformerMeterParamList, setUpstreamTransformerMeterParamList] = useState<
    TemplatePcsDeviceLinkRes[]
  >([]);
  // CF版本策略查询储能策略上端变压器模板列表, 点击增加智能充放电时段用
  useEffect(() => {
    if (data?.sysVersionId && +data.sysVersionId === VersionType.CF && query.bsaId) {
      getTemplatePcsDeviceLink(+query.bsaId).then(res => setUpstreamTransformerMeterParamList(res));
    }
  }, [formStatus, data?.sysVersionId, query.bsaId]);
  return (
    <div className={styles.page_box}>
      {' '}
      <div className="page">
        <div className="page__header">
          <Breadcrumb routes={[...(routesContext?.routes ?? []), ...routes]} />
        </div>
        <BorderWrapper isLoading={isLoading}>
          <div className="page__content">
            {formStatus !== FormStatus.Display ? (
              <FormHeader
                title={FormStatus.MAP[formStatus] + '就地策略'}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                onCancel={() => navigate('/dmes/energy-storage/strategy/calendar', { replace: true })}
              />
            ) : (
              <>
                <FormHeader
                  title={'就地策略'}
                  submitButtonText={data?.status === StrategyRunningStatus.OUT_OF_SERVICE ? '投运' : '停运'}
                  onSubmit={onUpdateStatus}
                  isSubmitting={mutateStatus.isLoading}
                  cancelButtonText={'返回日历'}
                  showSubmitButton={!query.expired}
                  onCancel={() => navigate('/dmes/energy-storage/strategy/calendar', { replace: true })}
                />
                {/*<FCSBasicInfo bsaId={query.bsaId}/>*/}
              </>
            )}
            {data?.sysVersionId && (
              <StrategyForm
                version={data.sysVersionId as VersionType}
                data={{ ...data, upstreamTransformerMeterParamList }}
                isField
                state={Number(formStatus)}
                form={form}
                onFinish={onFinish}
              />
            )}
          </div>
        </BorderWrapper>
      </div>
    </div>
  );
};

export default FieldStrategyPage;
