import React, { useMemo, useState } from 'react';
import { useHasPermission } from '../../../utils/hooks';
import { PermissionsType } from '../../../common/permissionsConst';
import classnames from 'classnames';
import { updateStatus } from '../../../api/bsa';
import { BSAStatus } from '../../../api/type';

import styles from '../index.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import EditCostModal from '@/pages/PvStation/CostModal/Edit';
import ViewCostModal from '@/pages/PvStation/CostModal/View';
import { StationType } from '@/pages/SiteAccess';
import { Button, Modal } from '@maxtropy/components';

interface Iporps {
  id: number;
  status: BSAStatus;
  bsaConfig: boolean; // bsaUI配置是否存在
  bsaUiAirConfigByDevice: boolean; // 就地端空调消防是否是设备
  bsaDeliverStatus: boolean; // bsa设备是否修改
  hasRack: boolean;
  hasPack: boolean;
  hasCell: boolean;
  hardwareVersion: string; // 硬件结构版本
  update?: () => void;
}

const ActionContent: React.FC<Iporps> = ({
  id,
  status,
  bsaConfig,
  bsaUiAirConfigByDevice,
  bsaDeliverStatus,
  hasRack,
  hasPack,
  hasCell,
  update,
}) => {
  const hasEditPermission = useHasPermission(PermissionsType.B_UPDATE_BSA),
    hasDetailPermission = useHasPermission(PermissionsType.B_BSA_INFO),
    hasStatusPermission = useHasPermission(PermissionsType.B_UPDATEBSASTATUS),
    hasBatchUpdatePermission = useHasPermission(PermissionsType.B_BATCHUPDATEBSA),
    hasAllotPermission = useHasPermission(PermissionsType.B_CONFIG_DRIVER),
    hasStoragePermission = useHasPermission(PermissionsType.B_ENERGY_STORAGE_DATA),
    hasUpdateCostPermission = useHasPermission(PermissionsType.B_BSA_COSTINFO_EDIT),
    hasViewCostPermission = useHasPermission(PermissionsType.B_BSA_COSTINFO_VIEW),
    hasControlPermission = useHasPermission(PermissionsType.B_REMOTE_CONTROL_SET);
  const navigate = useNavigate();
  const changeStatus = (id: number, status: BSAStatus) => {
    updateStatus(id, status).then(_ => {
      update?.();
    });
  };
  // 就地端配置按钮颜色
  const isDisable = useMemo(() => {
    return status !== BSAStatus.ENABLE;
  }, [status]);
  const isRed = useMemo(() => {
    return status === BSAStatus.ENABLE && (!bsaConfig || (bsaConfig && bsaUiAirConfigByDevice && bsaDeliverStatus));
  }, [status, bsaConfig, bsaUiAirConfigByDevice, bsaDeliverStatus]);

  // 就地端配置页面跳转
  const goToPage = (id: number) => {
    if (isDisable) return;
    window.open(`/energy-storage/basic/bsa/${id}/config`, '_blank');
  };

  // 储能数据按钮配置
  const storageDisable = useMemo(() => {
    return status === BSAStatus.DRAFT;
  }, [status]);

  // 储能数据页面跳转
  const goToStoragePage = (id: number) => {
    if (storageDisable) return;
    window.open(`/dmes/energy-storage/charts?bsaId=${id}`, '_blank');
  };
  const [editCostModalOpen, setEditCostModalOpen] = useState(false);
  const [viewCostModalOpen, setViewCostModalOpen] = useState(false);

  return (
    <div className={styles.actionContent}>
      <Button
        type="link"
        onClick={() => navigate(`/energy-storage/basic/bsa/startingTimeOfStorage?id=${id}&type=${StationType.BSA}`)}
      >
        设置站点起算时间
      </Button>
      {hasViewCostPermission && (
        <Button
          className={styles.btn}
          type="link"
          onClick={() => {
            setViewCostModalOpen(true);
          }}
        >
          查看成本信息
        </Button>
      )}

      {hasUpdateCostPermission && (
        <div className={classnames(styles.action, status === BSAStatus.INVALID && styles.disable)}>
          <Button
            className={styles.btn}
            type="link"
            onClick={() => {
              status !== BSAStatus.INVALID && setEditCostModalOpen(true);
            }}
          >
            编辑成本信息
          </Button>
        </div>
      )}

      {
        // 储能数据按钮
        hasStoragePermission && (
          <div className={classnames(styles.action, storageDisable && styles.disable)}>
            <Button className={styles.btn} type="link" onClick={() => goToStoragePage(id)}>
              储能数据
            </Button>
          </div>
        )
      }
      {
        // 下发记录按钮
        <div className={classnames(styles.action)}>
          <Button className={styles.btn} type="link">
            <Link to={`/energy-storage/basic/bsa/record/${id}`} target="_blank">
              下发记录
            </Link>
          </Button>
        </div>
      }
      {
        // 就地端配置按钮
        hasAllotPermission && (
          <div className={classnames(styles.action, isDisable && styles.disable, isRed && styles.red)}>
            <Button className={styles.btn} type="link" onClick={() => goToPage(id)}>
              就地端配置
            </Button>
          </div>
        )
      }
      {hasEditPermission && (
        <div className={classnames(styles.action, status === BSAStatus.INVALID && styles.disable)}>
          <Button
            className={styles.btn}
            disabled={status === BSAStatus.INVALID}
            type="link"
            onClick={() => {
              status !== BSAStatus.INVALID && window.open(`/energy-storage/basic/bsa/edit/${id}`, '_blank');
            }}
          >
            阵列编辑
          </Button>
        </div>
      )}
      {typeof status === 'number' && status !== BSAStatus.DRAFT && status !== BSAStatus.INVALID && hasStatusPermission && (
        <div className={styles.action}>
          {status === BSAStatus.DISABLE && (
            <Button
              className={styles.btn}
              type="link"
              onClick={() => {
                Modal.confirm({
                  title: '确定启用?',
                  content: '',
                  onOk: () => changeStatus(id, BSAStatus.ENABLE),
                });
              }}
            >
              启用
            </Button>
          )}
          {status === BSAStatus.ENABLE && (
            <Button
              className={classnames(styles.btn, styles.gray)}
              type="link"
              onClick={() => {
                Modal.confirm({
                  title: '确定停用?',
                  content: '',
                  onOk: () => changeStatus(id, BSAStatus.DISABLE),
                });
              }}
            >
              停用
            </Button>
          )}
        </div>
      )}
      {typeof status === 'number' && status !== BSAStatus.INVALID && hasStatusPermission && (
        <div className={styles.action}>
          <Button
            className={classnames(styles.btn, styles.gray)}
            type="link"
            onClick={() => {
              Modal.confirm({
                title: '确定作废?',
                content: <span style={{ color: 'red' }}>作废后不可恢复，请谨慎操作！</span>,
                onOk: () => changeStatus(id, BSAStatus.INVALID),
              });
            }}
          >
            作废
          </Button>
        </div>
      )}
      {typeof status === 'number' && status !== BSAStatus.DRAFT && hasDetailPermission && (
        <div className={styles.action}>
          <Button className={styles.btn} type="link">
            <Link to={`/energy-storage/basic/bsa/detail/${id}`} target="_blank">
              查看
            </Link>
          </Button>
        </div>
      )}
      {hasBatchUpdatePermission && (status === BSAStatus.DISABLE || status === BSAStatus.ENABLE) && (
        <>
          {hasRack && (
            <div className={styles.action}>
              <Button className={styles.btn} type="link">
                <Link to={`/energy-storage/basic/bsa/rack-point/${id}`} target="_blank">
                  批量编辑电池簇采集点
                </Link>
              </Button>
            </div>
          )}
          {hasPack && (
            <div className={styles.action}>
              <Button className={styles.btn} type="link">
                <Link to={`/energy-storage/basic/bsa/pack-point/${id}`} target="_blank">
                  批量编辑电池组采集点
                </Link>
              </Button>
            </div>
          )}
          {hasCell && (
            <div className={styles.action}>
              <Button className={styles.btn} type="link">
                <Link to={`/energy-storage/basic/bsa/cell-point/${id}`} target="_blank">
                  批量编辑电芯采集点
                </Link>
              </Button>
            </div>
          )}
        </>
      )}
      {hasControlPermission && (
        <div className={styles.action}>
          <Button className={styles.btn} type="link">
            <Link to={`/energy-storage/basic/bsa/order/${id}`} target="_blank">
              控制指令配置
            </Link>
          </Button>
        </div>
      )}

      {editCostModalOpen && (
        <EditCostModal
          id={id}
          source="bsa"
          cancel={() => {
            setEditCostModalOpen(false);
          }}
          confirm={() => {
            update?.();
            setEditCostModalOpen(false);
          }}
        />
      )}
      {viewCostModalOpen && (
        <ViewCostModal
          id={id}
          source="bsa"
          cancel={() => {
            setViewCostModalOpen(false);
          }}
        />
      )}
    </div>
  );
};
export default ActionContent;
