import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import CardBox from '../../../CardBox';
import { EllipsisSpan, Table, Tabs, Tooltip } from '@maxtropy/components';
import styles from './index.module.scss';
import { useRequest, useSize } from 'ahooks';
import { apiV2PvNoticeboardPvModuleDataPost, V2PvNoticeboardPvModuleDataPostResponse } from '@maxtropy/dmes-apis-v2';
import { isNil } from 'lodash-es';
import electricCurrent from '../../../../imgs/electricCurrent.png';
import voltage from '../../../../imgs/voltage.png';
import {
  AlarmLevel,
  AlarmLevelColor,
  AlarmLevelLabel,
  OnlineStatus,
  OnlineStatusColorDisplay,
  OnlineStatusDisplay,
} from '@/pages/PvDemonstrationBase/const';
import { keepTwoNull, keepTwoOrLine } from '@/pages/PvDemonstrationBase/utils';
import { ShareDataContext } from '@/pages/PvDemonstrationBase';
import { Timeout } from 'ahooks/lib/useRequest/src/types';

export type PvNoticeboardPvModuleDataProps = Exclude<
  V2PvNoticeboardPvModuleDataPostResponse['moduleDataList'],
  undefined
>[number];

const columns = [
  {
    title: '组件编号',
    dataIndex: 'code',
    width: 100,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '发电量(kWh)',
    dataIndex: 'powerGeneration',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={keepTwoOrLine(v)} />,
  },
  {
    title: '功率(kW)',
    dataIndex: 'power',
    width: 90,
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={keepTwoOrLine(v)} />,
  },
  {
    title: '电压(V)',
    dataIndex: 'voltage',
    width: 90,
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={keepTwoOrLine(v)} />,
  },
  {
    title: '电流 (A)',
    dataIndex: 'electricCurrent',
    width: 90,
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={keepTwoOrLine(v)} />,
  },
  {
    title: '温度 (℃)',
    dataIndex: 'temperature',
    width: 90,
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={keepTwoOrLine(v)} />,
  },
  {
    title: '通讯状态',
    dataIndex: 'communicationStatus',
    ellipsis: { showTitle: true },
    width: 100,
    render: (v: OnlineStatus) => {
      return (
        <Tooltip
          title={!isNil(v) ? OnlineStatusDisplay[v] : '--'}
          getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
        >
          <span style={{ color: OnlineStatusColorDisplay[v] }}>{!isNil(v) ? OnlineStatusDisplay[v] : '--'}</span>
        </Tooltip>
      );
    },
  },
  {
    title: '报警状态',
    dataIndex: 'alarmStatus',
    width: 100,
    ellipsis: { showTitle: true },
    render: (v: string, record: PvNoticeboardPvModuleDataProps) => {
      return (
        <>
          {Object.keys(AlarmLevel).map((level, index) => {
            return (
              <span key={level}>
                <Tooltip
                  title={AlarmLevelLabel[Number(level) as AlarmLevel]}
                  getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                >
                  <span key={level} style={{ color: AlarmLevelColor[Number(level) as AlarmLevel] }}>
                    {index === 0 && (!isNil(record.topHighAlarmNum) ? record.topHighAlarmNum : '--')}
                    {index === 1 && (!isNil(record.highAlarmNum) ? record.highAlarmNum : '--')}
                    {index === 2 && (!isNil(record.middleAlarmNum) ? record.middleAlarmNum : '--')}
                    {index === 3 && (!isNil(record.lowerAlarmNum) ? record.lowerAlarmNum : '--')}
                    {index === 4 && (!isNil(record.earlyWarningAlarmNum) ? record.earlyWarningAlarmNum : '--')}
                  </span>
                </Tooltip>
                {index < Object.keys(AlarmLevelColor).filter(level => !Number.isNaN(Number(level))).length - 1 && '/'}
              </span>
            );
          })}
        </>
      );
    },
  },
];

const StringDetails = () => {
  const { pvInfo } = useContext(ShareDataContext);
  const [activeKey, setActiveKey] = useState<string>();
  const timeRef = useRef<number>();

  useEffect(() => {
    const list = pvInfo?.pvStringList?.filter(c => !isNil(c.longValue));
    if (list?.length) {
      setActiveKey(list[0].longValue!.toString());
    }
  }, [pvInfo?.pvStringList]);

  // 轮播
  // useEffect(() => {
  //   const list = pvInfo?.pvStringList?.filter(c => !isNil(c.longValue));
  //   if ((list ?? []).length && activeKey) {
  //     const currentActiveIndex = list?.findIndex(i => i.longValue?.toString() === activeKey);
  //     if ((currentActiveIndex ?? 0) + 1 === (list ?? []).length) {
  //       timeRef.current = window.setTimeout(() => {
  //         setActiveKey(list?.[0]?.longValue?.toString());
  //       }, 10000);
  //     } else {
  //       timeRef.current = window.setTimeout(() => {
  //         setActiveKey(list?.[(currentActiveIndex ?? 0) + 1]?.longValue?.toString());
  //       }, 10000);
  //     }
  //   }
  //   return () => clearTimeout(timeRef.current);
  // }, [activeKey]);

  // 组串tab
  const stringList = useMemo(() => {
    return pvInfo?.pvStringList
      ?.filter(c => !isNil(c.longValue))
      .map(item => ({
        label: item.stringValue,
        key: item.longValue!.toString(),
      }));
  }, [pvInfo?.pvStringList]);

  // 组串详情
  const { data, loading } = useRequest(
    async () => {
      return await apiV2PvNoticeboardPvModuleDataPost({
        id: activeKey,
      });
    },
    {
      pollingInterval: 2 * 60000,
      ready: !!activeKey,
      refreshDeps: [activeKey],
    }
  );

  const tableRef = useRef<HTMLDivElement>();
  const size = useSize(tableRef);

  return (
    <CardBox
      title={
        <>
          <span className={styles.title}>组串详情</span>
        </>
      }
      extraContent={
        <div className={styles.bottomStyles}>
          <Tabs
            getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
            className={styles.tabsStyle}
            animated={true}
            activeKey={activeKey as string}
            onChange={v => {
              setActiveKey(v);
            }}
            items={stringList ?? []}
          />
        </div>
      }
      className={styles.cardBoxSty}
    >
      <div className={styles.content_wrapper}>
        <div className={styles.left_card_box}>
          <div className={styles.item_card} style={{ backgroundColor: 'rgba(82,231,255,0.1)' }}>
            <img className={styles.img} src={electricCurrent} alt="" />
            <div className={styles.label}>组串电流</div>
            <Tooltip
              title={keepTwoOrLine(data?.electricCurrent) + 'A'}
              getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
            >
              <div className={styles.value} style={{ color: '#52E7FF' }}>
                {keepTwoOrLine(data?.electricCurrent)}A
              </div>
            </Tooltip>
          </div>
          <div className={styles.item_card} style={{ backgroundColor: 'rgba(22,221,142,0.1)' }}>
            <img className={styles.img} src={voltage} alt="" />
            <div className={styles.label}>组串电压</div>
            <Tooltip
              title={keepTwoOrLine(data?.voltage) + 'V'}
              getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
            >
              <div className={styles.value} style={{ color: '#16DD8E' }}>
                {keepTwoOrLine(data?.voltage)}V
              </div>
            </Tooltip>
          </div>
        </div>
        <div
          className={styles.right_table_box}
          ref={ref => {
            if (ref) {
              tableRef.current = ref;
            }
          }}
        >
          <Table
            rowKey="id"
            columns={columns}
            dataSource={data?.moduleDataList ?? []}
            loading={loading}
            scroll={{ x: 'max-content', y: (size?.height ?? 200) - 50 }}
            pagination={false}
          />
        </div>
      </div>
    </CardBox>
  );
};

export default StringDetails;
