import React from 'react';
import { AlertFilled } from '@ant-design/icons';
import styles from './index.module.scss';
import { AlarmFaultSignboardAllAlarmGetResponse } from '@maxtropy/dmes-apis';
import { FormTitle } from '@maxtropy/components';

interface Props {
  alarmPageInfo?: AlarmFaultSignboardAllAlarmGetResponse;
}
const CustomInfoBoxGroup: React.FC<Props> = ({ alarmPageInfo }) => {
  return (
    <div className={styles.top_filter}>
      <FormTitle className={styles.filterTitle} title="未恢复报警和异常诊断统计" />

      <div className={styles.filter_right}>
        <div className={styles.total}>
          <div>
            <span style={{ marginRight: '15px', marginTop: '-9px' }} className={styles.icon}>
              <AlertFilled />
            </span>
            <span>总数</span>
          </div>
          <span>{(alarmPageInfo as any)?.allNum ?? '--'}</span>
        </div>
        <div className={styles.highest_color}>
          <div>
            <span></span>
            <span>最高级</span>
          </div>
          <span>{alarmPageInfo?.topHighAlarmNum ?? '--'}</span>
        </div>
        <div className={styles.high_color}>
          <div>
            <span></span>
            <span>高级</span>
          </div>
          <span>{alarmPageInfo?.highAlarmNum ?? '--'}</span>
        </div>
        <div className={styles.mid_color}>
          <div>
            <span></span>
            <span>中级</span>
          </div>
          <span>{alarmPageInfo?.middleAlarmNum ?? '--'}</span>
        </div>
        <div className={styles.low_color}>
          <div>
            <span></span>
            <span>低级</span>
          </div>
          <span>{alarmPageInfo?.lowerAlarmNum ?? '--'}</span>
        </div>
        <div className={styles.lowest_color}>
          <div>
            <span></span>
            <span>最低级</span>
          </div>
          <span>{alarmPageInfo?.earlyWarningAlarmNum ?? '--'}</span>
        </div>
      </div>
    </div>
  );
};

export default CustomInfoBoxGroup;
