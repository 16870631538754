import React, { CSSProperties, FC, Key, useEffect, useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { Spin } from 'antd';
import { Empty, Input, Tree } from '@maxtropy/components';
import { DataNode } from 'antd/lib/tree';
import { useLocation } from 'react-router-dom';
import {
  apiV2ChannelTenantTenantCustomerOuTreePost,
  V2ChannelTenantTenantCustomerOuTreePostResponse,
} from '@maxtropy/dmes-apis-v2';

export interface TreeProps {
  className?: string;
  style?: CSSProperties;
  changeTreeSelect?: (value: React.Key[], info: any) => void; // info就是过程或者出口入口的值
}

const { Search } = Input;

const OrgOuTree: FC<TreeProps> = props => {
  const { className, changeTreeSelect, style } = props;
  const [searchParams, setSearchParams] = useState<{ name: string }>(); // 搜索
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]); // 选择树
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<DataNode[]>();

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const ouIdParam = urlSearchParams.get('ouId');

  const [flag, setFlag] = useState<boolean>(true);

  // 请求树数据
  useEffect(() => {
    setLoading(true);
    apiV2ChannelTenantTenantCustomerOuTreePost({ ouName: searchParams?.name })
      .then(res => {
        if (res) {
          const treeData = transformTree(res.list);
          const flattenTreeData = flattenMap(treeData);
          setExpandedKeys(Array.from(flattenTreeData.keys()) as string[]);
          setData(treeData);
          if (!treeData.length) {
            setSelectedKeys([]);
            changeTreeSelect?.([], undefined);
          } else {
            if (ouIdParam && flag) {
              const ouIdParamKey = ouIdParam + 'ouList';
              setSelectedKeys([ouIdParamKey]);
              changeTreeSelect?.([ouIdParamKey], flattenTreeData.get(ouIdParamKey)?.info);
              setFlag(false);
            } else {
              // 默认选择第一个

              const stackKey = selectDefaltKey(treeData);
              if (stackKey) {
                changeTreeSelect?.([stackKey], flattenTreeData.get(stackKey)?.info);
                setSelectedKeys([stackKey]);
              } else {
                changeTreeSelect?.([], undefined);
                setSelectedKeys([]);
              }
            }
          }
        }
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, ouIdParam]);

  // 搜索树的选择框
  const onSelect = (selectedKeys: Key[], e: any) => {
    changeTreeSelect?.(selectedKeys, e.node.info);
    setSelectedKeys(selectedKeys);
  };

  return (
    <div className={classNames(className, styles.treeArea)} style={style}>
      <div className={styles.searchArea}>
        <Search placeholder="请输入运营单元名称" allowClear onSearch={v => setSearchParams({ name: v })} />
      </div>

      <div className={styles.treeBox}>
        <Spin spinning={loading}>
          {data && data.length ? (
            <Tree
              showIcon
              blockNode
              treeData={data}
              expandedKeys={expandedKeys}
              selectedKeys={selectedKeys}
              onExpand={expandedKeys => {
                setExpandedKeys(expandedKeys as string[]);
              }}
              onSelect={onSelect}
            />
          ) : (
            <Empty />
          )}
        </Spin>
      </div>
    </div>
  );
};

export default OrgOuTree;

// 使得返回的数据变成DataNode格式
export const transformTree = (tree?: V2ChannelTenantTenantCustomerOuTreePostResponse['list']): DataNode[] => {
  if (!tree) return [];
  return tree.map(item => {
    return {
      // icon: <img src={circuitIcon} alt="" />,
      key: item.tenantMcid + 'tenant',
      title: item.tenantName ? (
        <>
          <span style={{ marginRight: '5px' }} id={`${item.tenantMcid}`}>
            {item.tenantName}
          </span>
        </>
      ) : (
        '--'
      ),
      selectable: false,
      info: {
        name: item.tenantName,
        id: item.tenantMcid,
        // processTreeVos: item.ouList,
      },
      children: (item.customerOuTreeVoList ?? []).map(c => {
        return {
          key: c.mcid + 'customer',
          title: c.customerName ? (
            <>
              <span style={{ marginRight: '5px' }} id={`${c.mcid}`}>
                {c.customerName}
              </span>
            </>
          ) : (
            '--'
          ),
          selectable: false,
          info: {
            name: c.customerName,
            id: c.mcid,
            processTreeVos: c.ouList,
          },
          children: (c.ouList ?? []).map(i => {
            return {
              className: 'circuit-title',
              key: i.id + 'ouList',
              title: i.name ? (
                <>
                  <span style={{ marginRight: '5px' }} id={`${i.id}`}>
                    {i.name}
                  </span>
                </>
              ) : (
                '--'
              ),
              selectable: true,
              info: {
                type: 'ou',
                name: i.name,
                id: i.id,
                serialNumber: i.serialNumber,
              },
            };
          }),
        };
      }),
    };
  });
};

// 平铺所有树结构
export const flattenMap = (data: DataNode[], map: Map<Key, DataNode & { info?: any }> = new Map()) => {
  data.forEach(i => {
    map.set(i.key, i);
    if (i.children) {
      flattenMap(i.children, map);
    }
  });
  return map;
};

// 寻找第一个key
export function selectDefaltKey(treeData: (DataNode & { info?: any })[]) {
  const res: Key[] = [];
  function loop(array: (DataNode & { info?: any })[], result: Key[]) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].info.type === 'ou') {
        result.push(array[i].key);
      } else {
        if (array[i].children && array[i].children!.length > 0) {
          loop(array[i].children!, result);
        }
      }
    }
  }
  loop(treeData, res);
  return res[0];
}
