import { Space, Spin } from 'antd';
import styles from './index.module.scss';
import classname from 'classnames/bind';
import CustomTooltip from '../CustomTooltip';
import { Graph, Node } from '@antv/x6';
import { EdgeColor, NodeTreeData } from '../const';
import { Key, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { GraphContext } from '..';
import { changeIncomingEdges, getPropertyInfo, keepTwoNull } from '../utils';

import { DataValuesIprops, useGetDataValues } from './hooks';

const cx = classname.bind(styles);
interface Iprops {
  node: Node;
  graph: Graph;
}
const Precinct = ({ node, graph }: Iprops) => {
  const { currentNodeInfo, setCurrentNodeInfo, alarmDeviceList } = useContext(GraphContext);
  // 当前节点的数据
  const data = node?.getData() as NodeTreeData;

  const [searchParams, setSearchParams] = useState<DataValuesIprops>({ isRequest: false });
  const { dataValues, loading } = useGetDataValues(searchParams);
  const onOpenChange = (visible: boolean, deviceId?: Key) => {
    setSearchParams({
      isRequest: visible,
      deviceType: data.type,
      deviceId: deviceId,
    });
  };
  useEffect(() => {
    if (!data.extral || !data.extral.stringList?.length) return;
    let alarmIds = data.extral?.stringList?.filter(item => alarmDeviceList?.includes(item.deviceId!));
    let linkInvert = alarmIds?.map(item => item.inverterId!);
    const lineColor = linkInvert && linkInvert?.length > 0 ? EdgeColor.ALARM : EdgeColor.INITIAL;
    changeIncomingEdges(graph, node, lineColor, linkInvert);
  }, [alarmDeviceList, data]);

  return (
    <div className={styles.precinct_box}>
      <div className={styles.header_box}>
        <Space size={16}>
          <div className={styles.main_title}>{data?.extral?.areaName ?? '--'}</div>
          <div className={styles.sub_title}>{data?.extral?.remarks ?? ''}</div>
        </Space>
      </div>
      <div className={styles.scroll_box}>
        <div className={styles.content_box}>
          {data.extral?.stringList?.map(item => (
            <CustomTooltip
              key={item.stringId}
              overlayClassName={styles.graph_inner_tooltip}
              title={
                <Spin spinning={loading}>
                  <div className={styles.tooltip_box} style={{ minWidth: 120 }}>
                    <div className={styles.tooltip_title}>{item.code ?? '--'}</div>
                    <div className={styles.tooltip_title}>{item.name ?? '--'}</div>
                    {dataValues?.map(d => {
                      return (
                        <div className={styles.tooltip_content}>
                          <span className={styles.tooltip_content_left}>
                            {getPropertyInfo(d.propertyId)?.propertyName ?? '--'}
                          </span>
                          <span className={styles.tooltip_content_right}>{`${keepTwoNull(d.value)}${
                            getPropertyInfo(d.propertyId)?.unit ?? ''
                          }`}</span>
                        </div>
                      );
                    })}
                  </div>
                </Spin>
              }
              onOpenChange={visible => onOpenChange(visible, item.deviceId)}
            >
              <div
                className={cx({
                  content_box_item: true,
                  high_light_close:
                    currentNodeInfo && item.inverterId && !currentNodeInfo?.id?.includes(item.inverterId.toString()),

                  alarm: alarmDeviceList?.includes(item.deviceId!),
                })}
              >
                <div className={styles.content_box_txt}>{item.code ?? '--'}</div>
              </div>
            </CustomTooltip>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Precinct;
