import React, { FC } from 'react';
import styles from './index.module.scss';
import { predictDaySummaryItemDataProps } from '.';
import dayjs from 'dayjs';
import { keepTwoOrLine } from '../../utils';

export interface IItemCard {
  itemData?: predictDaySummaryItemDataProps;
}

const ItemCard: FC<IItemCard> = props => {
  const { itemData } = props;
  return (
    <div className={styles.wrapper}>
      <div className={styles.left_box}>
        <div className={styles.year}>{itemData?.date ? dayjs(itemData?.date).format('YYYY') : '--'}</div>
        <div className={styles.date}>{itemData?.date ? dayjs(itemData?.date).format('MM-DD') : '--'}</div>
      </div>
      <div className={styles.right_box}>
        <div className={styles.item_data}>
          <div className={styles.title}>发电量 (kWh)</div>
          <div className={styles.value} style={{ color: '#16DD8E' }}>
            {keepTwoOrLine(itemData?.electricPredictData)}
          </div>
        </div>
        <div className={styles.item_data}>
          <div className={styles.title}>上网电量 (kWh)</div>
          <div className={styles.value} style={{ color: '#FFCB47' }}>
            {keepTwoOrLine(itemData?.netPredictData)}
          </div>
        </div>
        <div className={styles.item_data}>
          <div className={styles.title}>消纳电量 (kWh)</div>
          <div className={styles.value} style={{ color: '#CE90D1' }}>
            {keepTwoOrLine(itemData?.absorptionPredictData)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemCard;
