export enum AlarmStatus {
  TopHighAlarm = 0,
  HighAlarm = 1,
  MiddleAlarm = 2,
  LowerAlarm = 3,
  EarlyWarningAlarm = 4,
}

export const AlarmStatusDisplay = {
  [AlarmStatus.TopHighAlarm]: '最高级',
  [AlarmStatus.HighAlarm]: '高级',
  [AlarmStatus.MiddleAlarm]: '中级',
  [AlarmStatus.LowerAlarm]: '低级',
  [AlarmStatus.EarlyWarningAlarm]: '最低级',
};
export const AlarmStatusColorDisplay = {
  [AlarmStatus.TopHighAlarm]: '#E64242',
  [AlarmStatus.HighAlarm]: '#FAAD14',
  [AlarmStatus.MiddleAlarm]: '#4A90E2',
  [AlarmStatus.LowerAlarm]: '#52C41A',
  [AlarmStatus.EarlyWarningAlarm]: '#ABD335',
};

export enum AlarmType {
  ServerAlarm = 0, //0服务端报警
  EdgeAlarm = 1, // 1边缘报警
}
