import DragResize from '@/components/DragResize';
import { useBreadcrumbRoutes, Wrapper } from '@maxtropy/components';
import { Layout } from 'antd';

import { useState } from 'react';
import MainOut from './MainOut';
import styles from './index.module.scss';
import BsaTree from '../BsaHistoryData/components/BsaTree';
import { BsaType } from '../BsaHistoryData/const';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import React from 'react';
import { RealData, RealTimeDataType } from './type';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/hooks';
import { isNil } from 'lodash-es';
import qs from 'qs';
const { Content, Sider } = Layout;

export const ShareContext = React.createContext<{
  bsaId?: number;
  bsaType?: BsaType;
  dataType?: RealTimeDataType;
  setDataType?: React.Dispatch<React.SetStateAction<RealTimeDataType>>;
  realData?: RealData;
  setRealData?: React.Dispatch<React.SetStateAction<RealData>>;
  hasRemoteBtn?: boolean;
  hasConfigBtn?: boolean;
}>({});
const BsaRealTimeMonitor = () => {
  const { id, type } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const routesContext = useBreadcrumbRoutes();
  const [siderWidth, setSiderWidth] = useState(260); // 初始值
  const [dragStatus, setDragStatus] = useState(false); // 拖拽状态
  const [openSider, setOpenSider] = useState(true); // 左侧树形toggle
  const [currentBsaId, setCurrentBsaId] = useState<number | undefined>(id ? Number(id) : undefined);
  const [currentBsaType, setCurrentBsaType] = useState<BsaType | undefined>(
    !isNil(type) ? (Number(type) as BsaType) : undefined
  );
  const [dataType, setDataType] = useState<RealTimeDataType>(RealTimeDataType.METER);
  const [realData, setRealData] = useState<RealData>();
  const hasRemoteBtn = useHasPermission(PermissionsType.B_REALTIME_POINT_REMOTE);
  const hasConfigBtn = useHasPermission(PermissionsType.B_REALTIME_CONFIG_DATA);
  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };
  return (
    <Wrapper routes={routesContext?.routes} className={styles.wrapper}>
      <Layout className={styles.layout}>
        <Sider
          width={openSider ? siderWidth : 0}
          style={{ transition: dragStatus ? 'none' : 'all 0.2s', background: '#232324' }}
        >
          <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
          <BsaTree
            defaultBsaId={id ? Number(id) : undefined}
            defaultBsaType={!isNil(type) ? (Number(type) as BsaType) : undefined}
            onSelect={(id, type) => {
              setCurrentBsaId(id);
              setCurrentBsaType(type);
            }}
          />
        </Sider>

        <Content className={styles.content}>
          <div
            className={styles.toggleBtn}
            style={{ left: openSider ? -30 : 0 }}
            onClick={() => setOpenSider(!openSider)}
          >
            {openSider ? <LeftOutlined /> : <RightOutlined />}
          </div>
          <ShareContext.Provider
            value={{
              bsaId: currentBsaId,
              bsaType: currentBsaType,
              dataType,
              setDataType,
              realData,
              setRealData,
              hasRemoteBtn,
              hasConfigBtn,
            }}
          >
            <MainOut />
          </ShareContext.Provider>
        </Content>
      </Layout>
    </Wrapper>
  );
};

export default BsaRealTimeMonitor;
