import Alarm from '@/pages/LineLoss/Alarm';
import Relog from '@/pages/LineLoss/Relog';

import { PermissionsType } from '@/common/permissionsConst';
import CreateRules from './Alarm/CreateRules';
import DetailRules from './Alarm/DetailRules';
import DetailLogs from './Relog/Detail';

const routes = [
  {
    path: '/dmes/lineLoss/alarmRules',
    permission: PermissionsType.P_LINE_LOSS_ALARM_RULE_LIST,
    element: <Alarm />,
  },
  {
    path: '/dmes/lineLoss/alarmLogs',
    permission: PermissionsType.P_LINE_LOSS_ALARM_LOG_LIST,
    element: <Relog />,
  },
  {
    path: '/dmes/lineLoss/alarmRules/create',
    permission: PermissionsType.B_LINE_LOSS_ALARM_RULE_ADD,
    element: <CreateRules />,
  },
  {
    path: '/dmes/lineLoss/alarmRules/:id/edit',
    permission: PermissionsType.B_LINE_LOSS_ALARM_RULE_ADD,
    element: <CreateRules isEdit />,
  },
  {
    path: '/dmes/lineLoss/alarmRules/createMore',
    permission: PermissionsType.B_LINE_LOSS_ALARM_RULE_ADD,
    element: <CreateRules isMore />,
  },
  {
    path: '/dmes/lineLoss/alarmRules/:id/view',
    permission: '',
    element: <DetailRules />,
  },
  {
    path: '/dmes/lineLoss/alarmLogs/:id/view',
    permission: '',
    element: <DetailLogs />,
  },
];
export default routes;
