import ExpectPage from './ExpectPage';
import ImagePage from './ImagePage';

import pendingUrl from './pending.png';
import sprintgUrl from './sprint.png';

const routes = [
  {
    path: '/pending/manufacturer/*',
    element: <ExpectPage imgUrl={pendingUrl} title="等待合作厂商数据对接，敬请期待！" />,
  },
  {
    path: '/module/sprint/*',
    element: <ExpectPage imgUrl={sprintgUrl} title="模块迭代中，敬请期待！" />,
  },
  {
    path: '/image/loading/*',
    element: <ImagePage />,
  },
];

export default routes;
