import { FC, useMemo } from 'react';
import { Button, FormTitle, Steps } from '@maxtropy/components';
import { Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import styles from './HeadStep.module.scss';
import { Step } from '../const';
import { V2BsaSecondEditionInfoBsaSecondEditionPostResponse } from '@maxtropy/dmes-apis-v2';

interface HeadStepProps {
  title: string;
  current: Step;
  bsaData: V2BsaSecondEditionInfoBsaSecondEditionPostResponse | undefined;
  onPre?: () => void;
  onNext?: () => void;
  onDraft?: (() => void) | false;
  onSave?: () => void;
  nextDisabled?: boolean;
}

const HeadStep: FC<HeadStepProps> = props => {
  const { title, current, onPre, onNext, onDraft, onSave, bsaData, nextDisabled } = props;

  const navigate = useNavigate();

  const stepItems = useMemo(() => {
    if (!bsaData) return [];
    let list: { title: string }[] = [
      {
        title: '全局数采配置',
      },
    ];
    if (bsaData.hasRack) list.push({ title: '电池簇数据点配置' });
    if (bsaData.hasPack) list.push({ title: '电池组数据点配置' });
    if (bsaData.hasCell) list.push({ title: '电芯数据点配置' });
    return list;
  }, [bsaData]);

  const onCancel = () => {
    navigate('/energy-storage/basic/bsaNew');
  };

  return (
    <>
      <div className={styles.head}>
        <FormTitle title={title}></FormTitle>
        <Space>
          {onPre && (
            <Button
              onClick={() => {
                onPre();
              }}
            >
              上一步
            </Button>
          )}
          {onNext && (
            <Button type="primary" onClick={onNext} disabled={nextDisabled}>
              下一步
            </Button>
          )}
          {onDraft && (
            <Button type="primary" onClick={onDraft}>
              存草稿
            </Button>
          )}
          {onSave && (
            <Button type="primary" onClick={onSave}>
              完成
            </Button>
          )}
          <Button onClick={onCancel}>取消</Button>
        </Space>
      </div>

      <div className={styles.stepWrap}>
        <Steps className={styles.step} items={stepItems} current={current}></Steps>
      </div>
    </>
  );
};

export default HeadStep;
