import { isNil } from 'lodash-es';

// 格式化
export const formateValues = (value?: number) => {
  return !isNil(value) ? Number(value.toFixed(2)).toLocaleString() : '--';
};

// Gwh
export const GwhFormat = (value: number | null | undefined) => {
  if (undefined === value || null === value) return { value: '--', unit: '' };
  if (typeof value === 'string') value = Number(value);
  if (value > 1000 * 1000) {
    return {
      value: `${(value / (1000 * 1000)).toLocaleString('zh', {
        maximumFractionDigits: 2,
      })}`,
      unit: 'GWh',
    };
  }
  if (value > 1000) {
    return {
      value: `${(value / 1000).toLocaleString('zh', {
        maximumFractionDigits: 2,
      })}`,
      unit: 'MWh',
    };
  }
  return {
    value: `${value.toLocaleString('zh', { maximumFractionDigits: 2 })}`,
    unit: 'kWh',
  };
};

// GW
export const GWFormat = (value: number | null | undefined) => {
  if (undefined === value || null === value) return { value: '--', unit: '' };
  if (typeof value === 'string') value = Number(value);
  if (value > 1000 * 1000) {
    return {
      value: `${(value / (1000 * 1000)).toLocaleString('zh', {
        maximumFractionDigits: 2,
      })}`,
      unit: 'GW',
    };
  }
  if (value > 1000) {
    return {
      value: `${(value / 1000).toLocaleString('zh', {
        maximumFractionDigits: 2,
      })}`,
      unit: 'MW',
    };
  }
  return {
    value: `${value.toLocaleString('zh', { maximumFractionDigits: 2 })}`,
    unit: 'kW',
  };
};

export const formateValuesPercent = (value?: number) => {
  return !isNil(value) ? Number(value).toFixed(2) + '%' : '--';
};

export enum AlarmLevel {
  MAX = 0,
  HIGH = 1,
  MIDDLE = 2,
  LOW = 3,
  WARNING = 4,
}

export const AlarmLevelColor = {
  [AlarmLevel.MAX]: '#FF4D4F',
  [AlarmLevel.HIGH]: '#D89614',
  [AlarmLevel.MIDDLE]: '#157DDC',
  [AlarmLevel.LOW]: '#4AA91A',
  [AlarmLevel.WARNING]: '#1890FF',
};

export const AlarmLevelLabel = {
  [AlarmLevel.MAX]: '最高级',
  [AlarmLevel.HIGH]: '高级',
  [AlarmLevel.MIDDLE]: '中级',
  [AlarmLevel.LOW]: '低级',
  [AlarmLevel.WARNING]: '最低级',
};
