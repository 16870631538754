import { FC, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { Button, EllipsisSpan, Modal, Table } from '@maxtropy/components';
import {
  BsaListProps,
  DeviceDetailList,
  DevicePointMirrorProps,
  generatePointMirror,
  generatePointMirrorLog,
} from '@/api/bsa';
import dayjs from 'dayjs';

import { mirroTextMap } from './mirroText';

const columns = [
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '数据属性',
    dataIndex: 'dataPropertyName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '生成结果',
    dataIndex: 'result',
    ellipsis: { showTitle: true },
    render: (v: boolean, record: DeviceDetailList) => (
      <EllipsisSpan
        value={v && record.needMirror ? '成功' : <span style={{ color: 'red' }}>{record.description}</span>}
      />
    ),
  },
  {
    title: '生成时间',
    dataIndex: 'generateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />,
  },
];
interface IDataPointMirrorProps {
  bsaInfo: BsaListProps;
  isCompleted?: boolean; // 是否完成储能阵列编辑
  mirrorCompleted?: boolean; // 生成数据点镜像是否成功
}

const DataPointMirror: FC<IDataPointMirrorProps> = props => {
  const { bsaInfo } = props;
  const [mirrorModal, setMirrorModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<DevicePointMirrorProps>();

  useEffect(() => {
    if (bsaInfo.id && mirrorModal) {
      setLoading(true);
      generatePointMirrorLog(bsaInfo.id).then(res => {
        if (res) {
          setData(res);
          setLoading(false);
        }
      });
    }
  }, [bsaInfo.id, mirrorModal]);

  const onOneTouchMirror = () => {
    if (bsaInfo.id) {
      setLoading(true);
      generatePointMirror(bsaInfo.id).then(res => {
        if (res) {
          setData(res);
          setLoading(false);
        }
      });
    }
  };

  return (
    <>
      {bsaInfo.isCompleted && (
        <>
          <div style={{ width: '100%' }}>
            <Button
              type="primary"
              style={{
                width: 130,
                color: bsaInfo.mirrorCompleted ? undefined : 'red',
              }}
              onClick={() => setMirrorModal(true)}
            >
              数据点镜像
            </Button>
            <div style={{ fontSize: '12px', position: 'absolute', right: 0 }}>
              <div>提示：需要确保数据点镜像全部成功生成，策略才可以正常运行。</div>
            </div>
          </div>
          <Modal
            destroyOnClose
            title={'数据点镜像'}
            maskClosable={false}
            open={mirrorModal}
            size="large"
            footer={null}
            onCancel={() => {
              setMirrorModal(false);
            }}
          >
            <Button type="primary" onClick={() => onOneTouchMirror()}>
              一键生成数据点镜像
            </Button>
            {/* 数据点镜像文描 */}
            {bsaInfo.softwareVersion
              ? mirroTextMap[bsaInfo.softwareVersion] ?? mirroTextMap['others']
              : mirroTextMap['others']}
            <h4>最新生成结果：</h4>
            {<p style={{ color: 'red' }}>{data?.description}</p> ?? <></>}
            {data?.deviceDetailList && data.deviceDetailList.length > 0 ? (
              <Spin spinning={loading}>
                <Table rowKey="id" columns={columns} dataSource={data.deviceDetailList} />
              </Spin>
            ) : (
              '--'
            )}
          </Modal>
        </>
      )}
    </>
  );
};

export default DataPointMirror;
