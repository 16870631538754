import { Col, Row, Spin, Tree } from 'antd';
import { FC, Key, useContext, useEffect, useMemo, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import styles from './index.module.scss';
import { DataNode } from 'antd/lib/tree';
import { BathCircuitStatisticsResponse, CircuitTreeData3, v2GetCircuitTree3 } from '@/api/circuit';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import useRequestDataUpdate from './useRequestDataUpdate';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Empty } from '@maxtropy/components';
import { ScaleContext } from '../../index';
dayjs.extend(isBetween);
interface Iprops {
  id?: number; // 回路id
  openTimer: (isOver: boolean) => void;
  timerState?: number;
  height: number | undefined;
}
interface CurrentInfo {
  hasOu?: boolean;
  name?: string;
}

// 递归寻找节点的子集
const findNodeChildren = (node: CircuitTreeData3[], key: number): CircuitTreeData3[] => {
  let tempNode: CircuitTreeData3[] = [];
  const loop = (node: CircuitTreeData3[]) => {
    node.some(item => {
      if (item.id === key) {
        tempNode = item.children ?? [];
        return true;
      }
      if (item.children) {
        loop(item.children);
      }
      return false;
    });
  };
  loop(node);
  return tempNode;
};
// 根据id寻找节点name
const findNodeNameBykey = (node: CircuitTreeData3[], key: number): string => {
  let nodeName = '';
  const loop = (node: CircuitTreeData3[]) => {
    node.some(item => {
      if (item.id === key) {
        nodeName = item.name ?? '';
        return true;
      }
      if (item.children) {
        loop(item.children);
      }
      return false;
    });
  };
  loop(node);
  return nodeName;
};
const ElectricityAnalysisTopology: FC<Iprops> = ({ id, openTimer, timerState, height }) => {
  const scale = useContext(ScaleContext);
  const [circuitData, setCircuitData] = useState<CircuitTreeData3[]>();
  const [currentKeys, setCurrentKeys] = useState<Key[]>([]);
  const [currentKeyInfo, setCurrentKeyInfo] = useState<CurrentInfo>();
  const [expand, setExpand] = useState(false);
  const [currentChildCircuitHasOu, setCurrentChildCircuitHasOu] = useState<boolean>(false);

  // 获取回路数
  useEffect(() => {
    if (id) {
      expand && setExpand(false);
      v2GetCircuitTree3(id).then(res => {
        setCircuitData(res);
        let rootId = res[0].id;
        rootId && setCurrentKeys([rootId]);
        rootId && setCurrentKeyInfo(res[0]);
        setExpand(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const currentChildren = useMemo(() => {
    let key = currentKeys[0] as number;
    if (circuitData && circuitData.length > 0) {
      let tempData = findNodeChildren(circuitData, key);
      return tempData;
    }
    return [];
  }, [currentKeys, circuitData]);

  // 根据当前的母回路找到所有的下游回路
  const currentChildCircuitIds = useMemo(() => {
    return currentChildren.map(item => item.id);
  }, [currentChildren]);

  // 判断子回路是否存在数据权限
  useEffect(() => {
    let isCurrentHasOu: boolean = true;
    currentChildren.forEach(item => {
      if (!item.hasOu) {
        isCurrentHasOu = false;
        return;
      }
    });
    setCurrentChildCircuitHasOu(isCurrentHasOu);
  }, [currentChildren]);

  const currentCircuitId = useMemo(() => {
    return currentKeys[0];
  }, [currentKeys]);

  const { currentMonthElec, lastMonthElec, currentBathData, isLoading } = useRequestDataUpdate(
    currentCircuitId as number,
    currentChildCircuitIds,
    currentKeyInfo?.hasOu!,
    currentChildCircuitHasOu,
    openTimer,
    timerState
  );

  const formateCircuitData = useMemo(() => {
    if (circuitData && circuitData.length > 0) {
      return loop(circuitData);
    }
    return [];
  }, [circuitData]);

  const flattenTreeDataHasPermission = (data: CircuitTreeData3[]) => {
    let treeList: CircuitTreeData3[] = [];
    let flatten = function (data: CircuitTreeData3[]) {
      for (let i = 0; i < data.length; i++) {
        treeList.push(data[i]);
        if (data[i].children?.length) {
          flatten(data[i].children ?? []);
        }
      }
    };
    flatten(data);
    let findHasPermission = treeList.find(item => item.hasOu === true);
    if (findHasPermission) {
      return true;
    } else {
      return false;
    }
  };

  // 如果回路数都没有权限，则显示无权限
  const IsircuitTreeHasPermission = useMemo(() => {
    if (circuitData && circuitData.length > 0) {
      return flattenTreeDataHasPermission(circuitData);
    }
    return true;
  }, [circuitData]);

  const formateElec = useMemo(() => {
    let temp = Number(currentMonthElec);
    if (isNaN(temp)) return '--';
    return temp.toFixed(2);
  }, [currentMonthElec]);

  const formateCompare = useMemo(() => {
    let curr = Number(currentMonthElec);
    let last = Number(lastMonthElec);
    if (isNaN(curr) || isNaN(last)) return '--';
    if (last === 0) return '--';
    let perfix = '+';
    let compare = ((curr - last) * 100) / last;
    if (compare < 0) {
      perfix = '';
    }
    return perfix + compare.toFixed(2);
  }, [currentMonthElec, lastMonthElec]);

  const onSelect = (selectedKeys: Key[], e: any) => {
    setCurrentKeys(selectedKeys);
    setCurrentKeyInfo(e.node.info);
  };
  const isEmpty = useMemo(() => {
    return currentBathData.length === 0;
  }, [currentBathData]);

  const currentNotHasOuAndChildrenHasOu = (
    <div>
      <Row>
        <Col span={12}>
          本月用电: <span className={styles.month_elec}>{formateElec}kWh</span>
        </Col>
        <Col span={12}>
          环比: <span className={styles.circle_compare}>{formateCompare}%</span>
        </Col>
      </Row>
      <Empty style={{ paddingTop: 60 }} description={<div>下游回路数据权限不完整，请检查后重试</div>} />
    </div>
  );

  const currentHasOuAndChildrenNotHasOu = (
    <div>
      <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <InfoCircleOutlined style={{ color: 'var(--warning-color)', marginRight: 4 }}></InfoCircleOutlined>
        <div>所选回路无权限，本月用电和环比无显示</div>
      </Row>
      {/* echart图表 */}
      <Spin spinning={isLoading}>
        <div style={{ height: '100%' }}>
          {!isEmpty ? (
            <ReactEcharts
              option={getOption(currentBathData, circuitData!)}
              style={{ height: '98%', zoom: 1 / scale }}
              notMerge
              lazyUpdate={false}
            />
          ) : !currentChildCircuitIds || currentChildCircuitIds.length === 0 ? (
            <Empty style={{ paddingTop: 60 }} description="当前回路无下游回路" />
          ) : (
            <Empty style={{ paddingTop: 60 }} />
          )}
        </div>
      </Spin>
    </div>
  );

  const currentNotHasOuAndChildrenNotHasOu = (
    <div>
      <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <InfoCircleOutlined style={{ color: 'var(--warning-color)', marginRight: 4 }}></InfoCircleOutlined>
        <div>所选回路无权限，本月用电和环比无显示</div>
      </Row>
      <Empty style={{ paddingTop: '20px' }} description={<div>下游回路数据权限不完整，请检查后重试</div>} />
    </div>
  );

  console.log(height);

  return (
    <div className={styles.container_box}>
      <Row gutter={20} style={{ height: '100%' }}>
        <Col span={9}>
          {/* 树 */}
          <div className={styles.tree_outer}>
            <div className={styles.tree_box}>
              {!IsircuitTreeHasPermission ? (
                <Empty style={{ marginTop: '50px' }} description="无权限" />
              ) : !expand || formateCircuitData.length === 0 ? (
                <Empty style={{ marginTop: '50px' }} />
              ) : (
                <Tree
                  defaultExpandParent
                  blockNode
                  selectedKeys={currentKeys}
                  onSelect={onSelect}
                  defaultExpandAll
                  treeData={formateCircuitData}
                  height={height && height - 80}
                />
              )}
            </div>
          </div>
        </Col>
        <Col span={15}>
          {!IsircuitTreeHasPermission ? (
            <Empty style={{ marginTop: '50px' }} description="无权限" />
          ) : !currentKeyInfo ? (
            <Empty style={{ paddingTop: '20px' }} />
          ) : !currentKeyInfo.hasOu && currentChildCircuitHasOu ? (
            currentHasOuAndChildrenNotHasOu
          ) : currentKeyInfo.hasOu && !currentChildCircuitHasOu ? (
            currentNotHasOuAndChildrenHasOu
          ) : !currentKeyInfo.hasOu && !currentChildCircuitHasOu ? (
            currentNotHasOuAndChildrenNotHasOu
          ) : (
            <div style={{ height: '100%' }}>
              <Row>
                <Col span={12}>
                  本月用电: <span className={styles.month_elec}>{formateElec}kWh</span>
                </Col>
                <Col span={12}>
                  环比: <span className={styles.circle_compare}>{formateCompare}%</span>
                </Col>
              </Row>
              {/* echart图表 */}
              <Spin spinning={isLoading}>
                <div style={{ height: '100%' }}>
                  {!isEmpty ? (
                    <ReactEcharts
                      option={getOption(currentBathData, circuitData!)}
                      style={{ height: '98%', zoom: 1 / scale }}
                      notMerge
                      lazyUpdate={false}
                    />
                  ) : !currentChildCircuitIds || currentChildCircuitIds.length === 0 ? (
                    <Empty style={{ paddingTop: 60 }} description="当前回路无下游回路" />
                  ) : (
                    <Empty style={{ paddingTop: 60 }} />
                  )}
                </div>
              </Spin>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};
// 递归方法
const loop = (circuitData: CircuitTreeData3[]): DataNode[] => {
  return circuitData.map(item => {
    return {
      title: item.name,
      key: item.id,
      info: {
        hasOu: item.hasOu,
        name: item.name,
      },
      children: item.children && loop(item.children),
    };
  });
};
const getOption = (data: BathCircuitStatisticsResponse[], circuitData: CircuitTreeData3[]) => {
  let seriesData: any[] = [];
  let total: any;
  if (data.length > 0) {
    let tempArr = data.map(item => {
      // 求和
      let sum = item.sum;
      // let sum =
      //   tempDatas && tempDatas.length > 0
      //     ? tempDatas.reduce((sum, obj) => (sum = parseFloat((sum + obj.sum!).toFixed(2))), 0)
      //     : null;
      let label = findNodeNameBykey(circuitData, item.circuitId);
      return {
        name: label,
        value: sum,
      };
    });

    let othersData: any[] = [];
    let prevData = [...tempArr].splice(0, 4);
    let others = [...tempArr].slice(4);
    if (others.length > 0) {
      // 数据>4
      let tempOthers = others.filter(item => item.value || item.value === 0);
      let totalValue =
        tempOthers && tempOthers.length > 0 ? tempOthers.reduce((sum, obj) => (sum += obj.value!), 0) : null;
      othersData = [{ name: '其它', value: totalValue?.toFixed(2) }];
    }
    seriesData = [...prevData, ...othersData];
    let tempArrDatas = tempArr.filter(item => item.value || item.value === 0);
    total = tempArrDatas && tempArrDatas.length > 0 ? tempArrDatas.reduce((sum, obj) => (sum += obj.value!), 0) : null;
  }
  return {
    tooltip: {
      trigger: 'item',
    },
    title: {
      text: '下游回路用电情况',
      left: '25%',
      x: 'center',
      y: 'bottom',
      textAlign: 'center',
      textStyle: {
        color: 'rgba(255,255,255,0.65)',
        fontSize: '12px',
        fontWeight: '400',
      },
    },
    graphic: {
      type: 'text',
      z: 100,
      right: '5%',
      top: '5%',
      style: {
        fill: '#fff',
        textAlign: 'left',
        text: [`总电量 : ${typeof total === 'number' ? total.toFixed(2) : '--'}kWh`].join('\n'),
        font: '14px Microsoft YaHei',
      },
    },
    legend: {
      orient: 'vertical',
      right: '2%',
      // left: '2%',
      top: 'middle',
      // bottom: 20,
      textStyle: {
        color: '#fff',
        rich: {
          a: {
            fontSize: 14,
            width: 150,
            color: 'white',
          },
          b: {
            fontSize: 14,
            width: 110,
            color: 'white',
          },
        },
      },
      icon: 'circle',
      formatter: (name: string) => {
        let data = seriesData.find(item => item.name === name);
        let value = data.value ? (+data.value).toFixed(2) + 'kWh' : '--';
        if (name.length > 10) {
          name = name.slice(0, 10) + '...';
        }
        return ['{a|' + name + '}', '{b|' + value + '}'].join('');
      },
    },
    series: [
      {
        type: 'pie',
        radius: ['50%', '70%'],
        center: ['25%', '50%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'right',
        },
        labelLine: {
          show: false,
        },
        data: seriesData,
      },
    ],
  };
};

export default ElectricityAnalysisTopology;
