import React, { useEffect } from 'react';
import { Space } from 'antd';
import { createString, PVString, pvStringKeys, StringPayload, updateString } from '@/api/pv-string';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getInverterList, pvInverterKeys } from '@/api/pv-inverter';
import { Button, Form, Input, Modal, Select } from '@maxtropy/components';

export interface PVStringFormModalProps {
  pvId: number;
  row?: PVString;
  isDraft: boolean;
  isDisable: boolean;
  onCancel: () => void;
  onOk: () => void;
  onNextStep: (payload: StringPayload) => void;
}

const PVStringFormModal: React.FC<PVStringFormModalProps> = ({
  row,
  onCancel,
  isDraft,
  isDisable,
  pvId,
  onNextStep,
  onOk,
}) => {
  const [form] = Form.useForm();

  const queryClient = useQueryClient();
  const { mutate: createOrUpdate, isLoading } = useMutation(row ? updateString : createString, {
    onSuccess: () => queryClient.invalidateQueries(pvStringKeys.all),
  });
  const { data: inverters } = useQuery(pvInverterKeys.list(pvId), () => getInverterList(pvId));

  useEffect(() => {
    if (row) {
      form.setFieldsValue({
        ...row,
      });
    }
  }, [form, row]);

  return (
    <Modal
      open
      title={`${!!row ? '编辑' : '添加'}组串`}
      maskClosable={false}
      onCancel={onCancel}
      contentStyle={{ padding: '32px 72px 8px' }}
      footer={
        <Space>
          <Button onClick={onCancel}>取消</Button>
          {!row && (isDraft || isDisable) && (
            <Button
              loading={isLoading}
              type={'primary'}
              onClick={() => form.validateFields().then(values => onNextStep({ ...values, pvId }))}
            >
              下一步
            </Button>
          )}
          <Button
            loading={isLoading}
            type="primary"
            onClick={() =>
              form.validateFields().then(values => createOrUpdate({ ...row, ...values, pvId }, { onSuccess: onOk }))
            }
          >
            确定
          </Button>
        </Space>
      }
    >
      <Form form={form}>
        <Form.Item
          name={'code'}
          label={'组串编号'}
          rules={[
            { required: true },
            { max: 20, message: '最多20个字符' },
            { pattern: /^[A-Za-z0-9\\-]{1,20}$/, message: '仅能够输入数字、英文大小写和英文状态下的-' },
          ]}
        >
          <Input placeholder={'请输入'} disabled={!(isDraft || isDisable)} />
        </Form.Item>
        <Form.Item name={'pvInverterId'} label={'关联逆变器'} rules={[{ required: true, message: '请选择关联逆变器' }]}>
          <Select placeholder={'请选择'}>
            {inverters?.map(item => (
              <Select.Option key={item.id} value={item.id}>
                {item.code}
                {/*<Row gutter={10}>*/}
                {/*  <Col span={8} style={{ overflow: "hidden", textOverflow: "ellipsis" }}>*/}
                {/*    {item.code}*/}
                {/*  </Col>*/}
                {/*  <Col span={8} style={{ overflow: "hidden", textOverflow: "ellipsis" }}>*/}
                {/*    {item.deviceCode ?? "--"}*/}
                {/*  </Col>*/}
                {/*  <Col span={8} style={{ overflow: "hidden", textOverflow: "ellipsis" }}>*/}
                {/*    {item.deviceName ?? "--"}*/}
                {/*  </Col>*/}
                {/*</Row>*/}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PVStringFormModal;
