import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { Key } from 'react';

export interface BsaSelectListRes {
  id: number;
  name: string; //阵列名称
}

export interface BsaChargeCountRes {
  beChargeByDay: number; // 当日充电量
  disChargeByDay: number; // 当日放电量
  beChargeByMonth: number; // 当月充电量
  disChargeByMonth: number; // 当月放电量
  beChargeByYear: number; // 本年充电量
  disChargeByYear: number; // 本年放电量
}

export interface BsaDashBoardPageReq {
  ids: Key[]; //储能站id
  chargeState: Key; //充放电状态   1充电 -1放电 0待机 2全部
  ouIds: Key[]; //运营单元
  pageNum: Key; //分页
  pageSize: Key; //分页
}

export interface BsaDashBoardPageRes {
  page: number; // 当前页数
  size: number; // 每页的数量
  total: number; // 总记录数
  list: BsaList[]; // 结果集
  all: number; // 全部数量
  beCharge: number; // 充电数量
  disCharge: number; // 放电数量
  standBy: number; // 待机数量
}

export interface BsaList {
  assetCode?: Key;
  id: number;
  name: string; // 阵列名称
  beCharge: number; // 充电量
  disCharge: number; // 放电量
  realTimePower: number; // 实时功率
  chargeState: ChargeStatus; // 充放电状态	1 :充电 -1 :放电 0 :待机 2 :全部
  connectState: ConnectStateEnum; // 连接状态
  maxTemperature: number; // 最高温度
  minTemperature: number; // 最低温度
  maxSoc: number; // Soc最大值
  minSoc: number; // Soc最小值
  levelNum: BsaLevelNum[]; // 报警等级
  stackNum?: number; // soc数量
  realTimeSoc?: number; // Soc瞬时值
  thermometerNum?: number; // 电池堆数量
}

export interface BsaLevelNum {
  level: AlarmLevelNumEnum; // 报警等级：0:最高级;1:高级;2:中级;3:低级;4:预警
  num: number; // 数量
}

export interface PVStationDashBoardPageReq {
  ids: Key[]; //光伏站id
  ouIds: Key[]; //运营单元
  pageNum: Key;
  pageSize: Key;
}

export interface PVStationDashBoardPageRes {
  page: number;
  size: number;
  total: number;
  list: PVStationList[];
}

export interface PVStationList {
  assetCode: Key;
  id: number;
  name: string; // 光伏站名称
  charge: number; // 发电量
  realTimePower: number; // 实时功率
  irradiationIntensity: number; // 辐照强度
  weather: string; // 天气
  adcode: string; // 高德地图区域编码
  referenceLighting: number; // 参考光强
  powerGenerationEfficiency: number; // 实时发电效率
  installedCapacity: number; // 装机容量
  levelNum: PVStationLevelNum[]; // 报警等级
  showPowerGenerationEfficiency: boolean; // 是否展示实时发电效率
}

export interface PVStationLevelNum {
  level: AlarmLevelNumEnum; // 报警等级
  num: number; // 数量
}

export enum ConnectStateEnum {
  OFFLINE = 0,
  ONLINE = 1,
}

export const ConnectStateEnumDisplay = {
  [ConnectStateEnum.OFFLINE]: '离线',
  [ConnectStateEnum.ONLINE]: '在线',
};

// 储能站列表
export const getBsaSelectList = () =>
  fetch<BsaSelectListRes[]>(
    `/api/devops/bsa/bsa-select/list`
    // `http://10.50.16.213:40001/mock/283/api/devops/bsa/bsa-select/list`
  );

// 储能站充放电统计
export const getBsaChargeCount = () =>
  fetch<BsaChargeCountRes>(
    `/api/devops/bsa/bsa-charge/cout`
    // `http://10.50.16.213:40001/mock/283/api/devops/bsa/bsa-charge/cout`
  );

// 储能站看板
export function getBsaDashBoardPage(params: BsaDashBoardPageReq) {
  return fetch<BsaDashBoardPageRes>(
    `/api/devops/bsa/dashboard?${qs.stringify(params, { indices: false })}`
    // `http://10.50.16.213:40001/mock/283/api/devops/bsa/dashboard?${qs.stringify(params, { indices: false })}`
  );
}

export interface PVStationListRes {
  id: number;
  name: string; // 光伏站名称
  charge: number; // 发电量
  realTimePower: number; // 实时功率
  irradiationIntensity: number; // 辐照强度
  capacity: number; // 装机容量
  levelNum: LevelNum[]; // 报警等级：0:最高级;1:高级;2:中级;3:低级;4:预警
}

export interface LevelNum {
  level: AlarmLevelNumEnum; // 报警等级：0:最高级;1:高级;2:中级;3:低级;4:预警
  num: number; // 数量
}

export interface PVStationCountRes {
  chargeByDay: number; // 当日发电量
  realTimePower: number; //实时功率
  chargeByMonth: number; //本月发电量
  chargeByYear: number; //本年发电量
  carbonReducedByYear: number; //本年二氧化碳减排
}

export enum ChargeStatus {
  DISCHARGE = -1,
  STANDBY = 0,
  CHARGE = 1,
  ALL = 2,
}
export const ChargeStatusDisplay = {
  [ChargeStatus.DISCHARGE]: '放电',
  [ChargeStatus.STANDBY]: '待机',
  [ChargeStatus.CHARGE]: '充电',
  [ChargeStatus.ALL]: '全部',
};

export enum AlarmLevelNumEnum {
  MAX = 0,
  HIGH = 1,
  MIDDLE = 2,
  LOW = 3,
  WARNING = 4,
}
