import { AlarmLevelNumEnum, ConnectStateEnum, ConnectStateEnumDisplay } from '@/api/bsaPVOperationDashBoard';
import { PermissionsType } from '@/common/permissionsConst';
import {
  AlarmLevel,
  AlarmLevelColor,
  AlarmLevelLabel,
  formateValues,
  formateValuesPercent,
  GWFormat,
  GwhFormat,
} from '../../utils';
import { useHasPermission } from '@/utils/hooks';
import { Button, Table, EllipsisSpan, Tooltip } from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import { isNil } from 'lodash-es';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { V2DevopsBsaDashboardPostResponse } from '@maxtropy/dmes-apis-v2';
import styles from './index.module.scss';
import { BsaType } from '@/pages/BsaHistoryData/const';

type DataItem = Exclude<V2DevopsBsaDashboardPostResponse['list'], undefined>[number];

const columns = [
  {
    title: '储能站名称',
    dataIndex: 'name',
    width: 160,
    fixed: 'left' as 'left',
    ellipsis: { showTitle: true },
    render: (v: string, record: DataItem) => (
      <EllipsisSpan
        value={
          <span
            style={{
              color:
                !isNil(record.levelNum?.find(i => i.level === AlarmLevelNumEnum.MAX)?.num) &&
                !isNil(record.levelNum?.find(i => i.level === AlarmLevelNumEnum.HIGH)?.num) &&
                record.levelNum?.find(i => i.level === AlarmLevelNumEnum.MAX)?.num! +
                  record.levelNum?.find(i => i.level === AlarmLevelNumEnum.HIGH)?.num! >
                  0
                  ? 'red'
                  : 'unset',
            }}
          >
            {v}
          </span>
        }
      />
    ),
  },
  {
    title: '连接状态',
    dataIndex: 'connectState',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: ConnectStateEnum) => <EllipsisSpan value={!isNil(v) ? ConnectStateEnumDisplay[v] : '--'} />,
  },
  {
    title: '整站功率',
    dataIndex: 'realTimePower',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={`${GWFormat(v).value}${GWFormat(v).unit}`} />,
  },
  {
    title: '今日充/放电',
    key: 'charge',
    width: 260,
    ellipsis: { showTitle: true },
    render: (_: any, record: DataItem) => (
      <EllipsisSpan
        value={`${GwhFormat(record.beCharge).value}${GwhFormat(record.beCharge).unit}/${
          GwhFormat(record.disCharge).value
        }${GwhFormat(record.disCharge).unit}`}
      />
    ),
  },
  {
    title: '进线功率',
    dataIndex: 'incomingPower',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={`${GWFormat(v).value}${GWFormat(v).unit}`} />,
  },
  {
    title: '变压器功率(kW)/容量(kVA)',
    dataIndex: 'transformerPower',
    align: 'center' as 'center',
    width: 260,
    ellipsis: { showTitle: true },
    render: (_: any, record: DataItem) => (
      <Row gutter={[4, 5]} className={styles.maxH130}>
        {record.transformerPower?.length ? (
          record.transformerPower?.map((item, index) => {
            return (
              <Col span={12} key={index}>
                <div className={styles.item}>
                  <EllipsisSpan
                    value={
                      <>
                        <span>{formateValues(item.value)}</span>
                        <span>/</span>
                        <span>{formateValues(record.transformerCapacity?.[index]?.value)}</span>
                      </>
                    }
                  />
                </div>
              </Col>
            );
          })
        ) : (
          <Col span={24} className={styles.flexCenter}>
            --
          </Col>
        )}
      </Row>
    ),
  },
  {
    title: 'PCS功率(kW)/电池堆SOC',
    dataIndex: 'pcsPower',
    align: 'center' as 'center',
    width: 260,
    ellipsis: { showTitle: true },
    render: (_: any, record: DataItem) => (
      <Row gutter={[4, 5]} className={styles.maxH130}>
        {record.pcsPower?.length ? (
          record.pcsPower?.map((item, index) => {
            return (
              <Col span={12} key={index}>
                <div className={styles.item}>
                  <EllipsisSpan
                    value={
                      <>
                        <span>{formateValues(item.value)}</span>
                        <span>/</span>
                        <span>{formateValuesPercent(record.stackSoc?.[index]?.value)}</span>
                      </>
                    }
                  ></EllipsisSpan>
                </div>
              </Col>
            );
          })
        ) : (
          <Col span={24} className={styles.flexCenter}>
            --
          </Col>
        )}
      </Row>
    ),
  },
  {
    title: '报警信息',
    dataIndex: 'alarm',
    fixed: 'right' as 'right',
    width: 230,
    ellipsis: { showTitle: true },
    render: (v: string, record: DataItem) => {
      return (
        <>
          {Object.keys(AlarmLevel)
            .filter(level => !Number.isNaN(Number(level)))
            .map((level, index) => {
              return (
                <>
                  <Tooltip title={AlarmLevelLabel[Number(level) as AlarmLevel]}>
                    <span key={level} style={{ color: AlarmLevelColor[Number(level) as AlarmLevel] }}>
                      {record.levelNum?.find(item => item.level === Number(level))?.num ?? '--'}
                    </span>
                  </Tooltip>
                  {index < Object.keys(AlarmLevelColor).filter(level => !Number.isNaN(Number(level))).length - 1 && '/'}
                </>
              );
            })}
          <Button type="link" style={{ padding: 0, fontSize: '12px', marginLeft: 6 }}>
            <Link to={`/assets/alarm/overview?assetCode=${record.assetCode}&expand=true`} target="_blank">
              报警记录
            </Link>
          </Button>
        </>
      );
    },
  },
];

export interface ITableList {
  isLoading: boolean;
  data: DataItem[] | undefined;
}

const TableList: FC<ITableList> = props => {
  const { isLoading, data } = props;
  const remoteControlBtn = useHasPermission(PermissionsType.B_REMOTE_CONTROL);
  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 280,
      render: (v: undefined, record: DataItem) => (
        <Space size={16}>
          <Button
            type="link"
            onClick={() => {
              window.open(`/energy-storage/realTime/monitor?id=${record.id}&type=${record.type}`, '_blank');
            }}
          >
            实时数据
          </Button>
          {remoteControlBtn && record.type === BsaType.OLD && (
            <Button
              type="link"
              onClick={() =>
                window.open(`/energy-storage/remote/control?bsaId=${record.id}&bsaName=${record.name}`, '_blank')
              }
            >
              远程控制
            </Button>
          )}
          <Button type="link">
            <Link to={`/energy-storage/bsa/historyData?bsaId=${record.id}&bsaType=${record.type}`} target="_blank">
              运行数据
            </Link>
          </Button>
          <Button
            type="link"
            onClick={() =>
              window.open(
                `/energyStorage/stationOperation/overview?id=${record.id}&bsaType=${record.type}&tenantMcid=${
                  record.tenantMcid || ''
                }`,
                '_blank'
              )
            }
          >
            站点详情
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Table
      sticky
      rowKey={record => `${record.id}-${record.type}`}
      scroll={{ x: 1200 }}
      columns={[...buildColumns]}
      dataSource={data}
      loading={isLoading}
      pagination={false}
      className={styles.tableWrap}
    />
  );
};

export default TableList;
