import React, { useEffect, useState } from 'react';
import { Empty, Modal, Tag, Button } from '@maxtropy/components';
import { Space } from 'antd';
import { getPutRecordList, PutRecordResponse } from '@/api/strategy';
import { StrategyTemplate } from '../types';
import styles from './index.module.scss';
import dayjs from 'dayjs';
import { colorDisc } from '@/components/StrategyCalendar/components/utils';
interface PutRecordTemplateProps {
  close?: () => void;
  row?: StrategyTemplate;
  visible?: boolean;
}

const PutRecordTemplateModal: React.FC<PutRecordTemplateProps> = ({ close, visible, row }) => {
  const [recordList, setRecordList] = useState<PutRecordResponse[]>([]);
  useEffect(() => {
    let id = row?.id;
    if (id && visible) {
      getPutRecordList(id).then(setRecordList);
    }
  }, [row, visible]);
  return (
    <Modal
      title="策略模板投放记录"
      open={visible}
      bodyScroll
      maskClosable={false}
      onCancel={close}
      footer={
        <Space>
          <Button type="primary" onClick={close}>
            确定
          </Button>
        </Space>
      }
    >
      {recordList.length === 0 ? (
        <Empty style={{ paddingTop: '50px' }} description="暂无投放记录" />
      ) : (
        <div className={styles.list_box}>
          {recordList.map(item => {
            let tempDates = item.deliverDates;
            // 分离出年月相同
            let newDates = item.deliverDates.map(item => dayjs(item).format('YYYY-MM'));
            let uniqueDates = [...new Set(newDates)];
            let splitDates = uniqueDates.sort().map(item => ({
              datesTitle: item,
              datesList: tempDates.filter(d => d.includes(item)).sort(),
            }));
            return (
              <>
                <div className={styles.header}>
                  <div>
                    <Space>
                      <span className={styles.sign}>{item.userAccount}</span>
                      <span>在</span>
                      <span>{dayjs(item.deliverTime).format('YYYY-MM-DD HH:mm:ss')}</span>
                      <span>投放了模板到储能站:</span>
                      <span className={styles.sign}>{item.bsaName}</span>
                    </Space>
                  </div>
                  <div>
                    <span>投放日期为 : {recordList.length === 0 ? '--' : ''}</span>
                  </div>
                </div>
                {splitDates.map(dates => (
                  <div className={styles.list_line} key={dates.datesTitle + item.id}>
                    <div className={styles.left}>
                      {dayjs(dates.datesTitle).format('YYYY')}年{dayjs(dates.datesTitle).format('MM')}月:
                    </div>
                    <div className={styles.right}>
                      {dates.datesList.map(d => {
                        const color = colorDisc[dayjs(d).day()];
                        const rgbaC = [
                          parseInt('0x' + color.slice(1, 3)),
                          parseInt('0x' + color.slice(3, 5)),
                          parseInt('0x' + color.slice(5, 7)),
                          0.2,
                        ];
                        const rgba = `rgba(${rgbaC.join(',')})`;
                        return (
                          <Tag
                            style={{ borderColor: color, backgroundColor: rgba }}
                            key={dates.datesTitle + item.id + d}
                            color="#fff"
                          >
                            {dayjs(d).format('YYYY-MM-DD')}
                          </Tag>
                        );
                      })}
                    </div>
                  </div>
                ))}
              </>
            );
          })}
        </div>
      )}
    </Modal>
  );
};

export default PutRecordTemplateModal;
