import { Empty } from '@maxtropy/components';
import { V2DevopsBsaDashboardPostResponse } from '@maxtropy/dmes-apis-v2';
import { Spin } from 'antd';
import { FC } from 'react';
import styles from './index.module.scss';
import Card from './Card';

export interface ICardList {
  isLoading: boolean;
  data: V2DevopsBsaDashboardPostResponse['list'];
}

const CardList: FC<ICardList> = props => {
  const { isLoading, data } = props;

  return (
    <>
      {(!data || data.length === 0) && !isLoading ? (
        <Empty />
      ) : (
        <Spin spinning={isLoading}>
          <div className={styles.cardListStyle}>
            {data?.map(item => (
              <Card key={`${item.id}-${item.type}`} data={item} />
            ))}
          </div>
        </Spin>
      )}
    </>
  );
};

export default CardList;
