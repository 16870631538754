import { PermissionsType } from '@/common/permissionsConst';
import EnergyAssetProjectIncome from '.';

const routes = [
  {
    path: '/energy/asset/projectIncome',
    permission: PermissionsType.P_PROJECTINCOMESTATISTICALANALYSIS,
    element: <EnergyAssetProjectIncome />,
  },
];
export default routes;
