import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import {
  DriveType,
  ActionType,
  ActionTypeColors,
  ActionTypeDisplay,
  DataProperty,
  BsaDeviceTypeEnum,
  TopologicalType,
} from '../../../../api/type';
import ModbusPoint, { EdgeDeviceTemplatePoint as EdgeDeviceTemplateModbusPoint } from './modbusPoint';

import { Divider, Space } from 'antd';
import { Button, PopConfirm } from '@maxtropy/components';

export type EdgeDeviceTemplatePoint = EdgeDeviceTemplateModbusPoint;

const stateColumns: ColumnsType<EdgeDeviceTemplatePoint> = [
  {
    title: '操作状态',
    dataIndex: 'actionType',
    fixed: 'right',
    width: 80,
    render: (v: ActionType) => (
      <div
        style={{
          width: 45,
          height: 20,
          textAlign: 'center',
          borderRadius: 10,
          fontSize: 12,
          color: ActionTypeColors[v].color,
          background: ActionTypeColors[v].background,
        }}
      >
        {ActionTypeDisplay[v]}
      </div>
    ),
  },
];

export interface PointRef {
  add: () => void;
  validateFields: () => Promise<EdgeDeviceTemplatePoint[] | undefined>;
}

interface PointProps {
  fixed?: boolean;
  loading?: boolean;
  editable?: boolean;
  driveType: DriveType;
  dataSource?: EdgeDeviceTemplatePoint[];
  dataPropertiesAll?: DataProperty[];
  bsaDeviceType: BsaDeviceTypeEnum;
  topologicalType?: TopologicalType;
}

const Point: ForwardRefRenderFunction<PointRef, PointProps> = (props, ref) => {
  const {
    fixed,
    loading,
    driveType,
    // onUpdate,
    editable = true,
    dataSource,
    dataPropertiesAll,
    bsaDeviceType,
    topologicalType,
  } = props;

  const [row, setRow] = useState<Partial<EdgeDeviceTemplatePoint> | undefined>();

  const [data, setData] = useState<EdgeDeviceTemplatePoint[]>([]);

  useEffect(() => {
    if (dataSource && Array.isArray(dataSource)) {
      setData(
        dataSource.map(i => ({
          ...i,
          actionType: i.actionType ?? ActionType.NONE,
        }))
      );
    } else {
      setData([]);
    }
  }, [dataSource]);

  useImperativeHandle(ref, () => ({
    add: () => {
      setRow({});
    },
    validateFields: () => {
      return Promise.resolve(data.filter(i => i.actionType !== ActionType.DELETE));
    },
  }));

  const onDelete = (record: EdgeDeviceTemplatePoint) => {
    const index = data.indexOf(record);
    if (index !== -1) {
      if (record.actionType === ActionType.ADD) {
        setData([...data.slice(0, index), ...data.slice(index + 1)]);
      } else {
        setData([
          ...data.slice(0, index),
          {
            ...record,
            actionType: ActionType.DELETE,
          },
          ...data.slice(index + 1),
        ]);
      }
    }
  };

  const onUpdate = async (record: EdgeDeviceTemplatePoint) => {
    const copy = data?.slice() || [];
    if (row) {
      if (row.id) {
        const index = copy.findIndex(i => i.id === row.id);
        copy[index] = {
          id: row!.id,
          ...record,
          actionType: ActionType.EDIT,
        };
        setData(copy);
      } else if (row!.actionType === ActionType.ADD) {
        const index = copy!.indexOf(row as EdgeDeviceTemplatePoint);
        copy[index] = {
          ...record,
          actionType: ActionType.ADD,
        };
        setData(copy);
      } else {
        copy.push({ ...record, actionType: ActionType.ADD });
        setData(copy);
      }
    }
  };

  const opColumns: ColumnsType<EdgeDeviceTemplatePoint> = [
    {
      title: '操作',
      width: 150,
      fixed: 'right',
      render: (_, record: EdgeDeviceTemplatePoint) => (
        <Space size={16}>
          <Button
            type="link"
            onClick={() => {
              setRow(record);
            }}
            disabled={record.actionType === ActionType.DELETE}
          >
            编辑
          </Button>
          <Divider type="vertical" />
          <PopConfirm
            okText="继续"
            placement="topRight"
            title={
              <>
                <div>确定删除？</div>
                <div style={{ color: '#f5222d' }}>
                  删除后不可恢复<span style={{ color: 'rgba(0, 0, 0, 0.3)' }}>，你还要继续吗？</span>
                </div>
              </>
            }
            disabled={record.actionType === ActionType.DELETE}
            onConfirm={() => onDelete(record)}
          >
            <Button type="link" disabled={record.actionType === ActionType.DELETE}>
              删除
            </Button>
          </PopConfirm>
        </Space>
      ),
    },
  ];

  const editColumns = [...(editable ? stateColumns : []), ...(editable ? opColumns : [])];

  const usedProperties: number[] = useMemo(
    () =>
      (data ?? [])
        .filter(i => i.dataPropertyId)
        .filter(item => item.actionType !== ActionType.DELETE && item.dataPropertyId !== row?.dataPropertyId)
        .map(item => item.dataPropertyId!),
    [data, row]
  );

  const usedIdentifier = useMemo(
    () =>
      ((data ?? [])
        ?.filter(item => item.actionType !== ActionType.DELETE && item.identifier !== row?.identifier)
        .map(item => item.identifier)
        .filter(Boolean) as string[]) || [],
    [data, row]
  );

  const renderPoint = () => {
    if (driveType === DriveType.MODBUS_TCP) {
      return (
        <ModbusPoint
          fixed={fixed}
          loading={loading}
          editColumns={editColumns as ColumnsType<EdgeDeviceTemplatePoint>}
          dataSource={data}
          onUpdate={onUpdate}
          dataPropertiesAll={dataPropertiesAll}
          usedProperties={usedProperties}
          usedIdentifier={usedIdentifier}
          row={row}
          setRow={setRow}
          bsaDeviceType={bsaDeviceType}
          topologicalType={topologicalType!}
        />
      );
    } else {
      return null;
    }
  };

  return <div>{topologicalType !== undefined && renderPoint()}</div>;
};

export default forwardRef(Point);
