import { Node, TreeLayout } from '@antv/g6';
import { Cell, Graph, Model } from '@antv/x6';
import { isNil } from 'lodash-es';
import { Key, useEffect, useState } from 'react';
import { v4 } from 'uuid';

import {
  ApiTreeData,
  NodeTreeData,
  deviceTypeNodeShape,
  DeviceTypeEnum,
  LayoutTreeModel,
  CustomDeviceTypeEnum,
  NodeSizeEnum,
  AreaStringItem,
  EdgeColor,
  dataPropertyMap,
  DataPropertyItem,
} from './const';

// 初始化画布
export const useInitGraph = (dom?: HTMLDivElement | null) => {
  const [graph, setGraph] = useState<Graph>();
  useEffect(() => {
    if (!dom) return;
    let temp = new Graph({
      container: dom,
      width: dom.clientWidth,
      height: dom.clientHeight,
      interacting: false,
      panning: true,
      mousewheel: true,
      autoResize: true,
      grid: {
        size: 4,
        visible: true,
        type: 'dot',
        args: {
          color: '#ffffff35', // 网点颜色
          thickness: 1, // 网点大小
        },
      },
    });
    setGraph(temp);
  }, [dom]);

  return graph;
};

// 生成树形布局
export const initTreeLayout = (graph: Graph, treeData?: ApiTreeData[], areaList?: AreaStringItem[]) => {
  if (!treeData || !treeData.length) return;
  const data = transformApiTreeDataToLayoutData(treeData, areaList);

  const layout = new TreeLayout({
    type: 'compactBox',
    direction: 'LR', // 'TB'代表从上到下的方向 (Top to Bottom)
    getHeight: (data: ApiTreeData) => {
      // 节点高度
      if (data.type === CustomDeviceTypeEnum.AREA) {
        let nums = data.extral?.stringList?.length ?? 0;
        return getAreaHeight(nums);
        // return NodeSizeEnum.AREA_H;
      }
      return NodeSizeEnum.COMMON_H;
    },
    getWidth: (data: ApiTreeData) => {
      // 节点宽度

      if (data.type === CustomDeviceTypeEnum.ROOT) return 4;
      return NodeSizeEnum.COMMON_W;
    },
    getVGap: () => NodeSizeEnum.V_GAP, // 节点之间的垂直间距
    getHGap: (data: ApiTreeData) => {
      // 节点之间的水平间距

      if (data.type === CustomDeviceTypeEnum.ROOT) return 10;

      return NodeSizeEnum.H_GAP;
    },
  });

  const model = layout.layout(data) as LayoutTreeModel;

  const modelData = transformModelToGraphJson(model);
  // 渲染节点和边
  graph.fromJSON(modelData);
  // graph.centerContent();
  graph.zoomToFit({ padding: 24 });
};
// 将树形model转换成 Model.FromJSONData
const transformModelToGraphJson = (model: LayoutTreeModel) => {
  // 将树形结构转换为节点和边数据
  const nodes: any[] = [];
  const edges: any[] = [];

  const transform = (model: LayoutTreeModel, parent?: LayoutTreeModel) => {
    if (model.children) {
      model.children.forEach((child: any) => transform(child, model));
    }
    const data = model.data as ApiTreeData;

    if (data?.type === CustomDeviceTypeEnum.AREA) {
      // 片区关联逆变器的数量
      const trueCounts = data.invertNodeIds ?? [];
      const portsItems = trueCounts.map(nodeId => ({
        id: `ports-${nodeId ?? ''}`,
        group: 'areaLeftLine',
      }));

      nodes.push({
        id: model.id,
        label: data?.deviceName,
        data: { ...data, isCollapsed: false },
        x: model.x,
        y: model.y,
        zIndex: 4, // 优先级高于边
        width: NodeSizeEnum.AREA_W,
        height: getAreaHeight(data.extral?.stringList?.length),
        shape: 'precinct',
        ports: {
          groups: {
            areaLeftLine: {
              position: 'left',
              attrs: {
                circle: {
                  magnet: true,
                  r: 0,
                },
              },
            },
          },
          items: portsItems,
        },
      });
    } else {
      nodes.push({
        id: model.id,
        label: data?.deviceName,
        data: { ...data, isCollapsed: false },
        x: model.x,
        y: model.y,
        zIndex: 4, // 优先级高于边
        shape: getDeviceNodeShape(data?.type),
      });
    }
    // 添加边
    if (!parent) return;
    /**
     * 逆变器和片区之间的连线单独处理
     * 片区关联多个逆变器时为画布协调性, 在数据处理时关联了某一个逆变器, 故parent为undefined的情况
     * 按照如下方式处理
     */
    const isInvertToArea =
      parent.data?.type === DeviceTypeEnum.INVERTER && model.data?.type === CustomDeviceTypeEnum.AREA;
    if (isInvertToArea) {
      let total = model.data?.invertNodeIds?.length ?? 0;

      model.data?.invertNodeIds?.forEach((n, i) => {
        edges.push({
          source: n,
          target: { cell: model.id, port: `ports-${n ?? ''}` }, // 目标节点 ID 和连接桩 ID
          // 需要计算逆变器和片区节点之间的水平距离, 再算每个边的偏移量
          // router: {
          //   args: {
          //     padding: computeEdgeRouterPadding(i, total),
          //   },
          // },
        });
      });
      return;
    }
    const parentIsVirtual = parent.data?.type === CustomDeviceTypeEnum.ROOT;
    if (parentIsVirtual) return;
    // 并网点有没有父节点(树形数据结构默认是回路的的子节点)
    const connectHasNoParent =
      model.data?.type === DeviceTypeEnum.CONNECT && typeof model.data.hasParent === 'boolean' && !model.data.hasParent;
    if (connectHasNoParent) return;

    edges.push({
      source: parent.id,
      target: model.id,
    });
  };

  transform(model);

  return { nodes, edges } as Model.FromJSONData;
};

// 将api树形结构转换成layout所需要的data, 主要添加属性id
const transformApiTreeDataToLayoutData = (treeData?: ApiTreeData[], areaList?: AreaStringItem[]): NodeTreeData => {
  // 给没有关联回路的并网点设置一个虚拟回路(布局占位用)
  addVirtualGrid(treeData);
  // 临时存储数中所有的逆变器

  const tempInvertListNodes: ExpandPropsApiTreeData[] = [];
  // 添加树的虚拟根节点
  const rootNode: NodeTreeData = {
    id: 'root',
    deviceName: '',
    children: traverseTree(treeData, areaList, tempInvertListNodes),
    type: CustomDeviceTypeEnum.ROOT,
  };

  // 添加逆变器的子节点
  addAreaNode(tempInvertListNodes, areaList);

  return rootNode;
};

// * 遍历树形结构, 添加id
interface ExpandPropsApiTreeData extends ApiTreeData {
  id?: string;
  children?: ExpandPropsApiTreeData[];
}
const traverseTree = (
  node?: ExpandPropsApiTreeData[],
  areaList?: AreaStringItem[],
  tempInvertListNodes?: ExpandPropsApiTreeData[]
): NodeTreeData[] => {
  if (!node || !node.length) return [];

  node.forEach(item => {
    item.id = `${item.objectId ?? ''}-${typeof item.type === 'number' ? item.type : ''}`;
    if (item.type === DeviceTypeEnum.INVERTER) {
      tempInvertListNodes?.push(item);
    }
    // 如果节点有子节点，递归遍历每个子节点
    const tempChildrenList = item.childrenList;
    if (!tempChildrenList || !tempChildrenList.length) return;

    item.children = tempChildrenList;
    delete item.childrenList;
    traverseTree(item.children, areaList, tempInvertListNodes);
  });
  return node as NodeTreeData[];
};
// 重置所有边
export const resetAllEdges = (graph?: Graph) => {
  if (!graph) return;
  const edges = graph.getEdges();

  edges.forEach(edge => {
    edge.setZIndex(1);
    edge.attr({
      line: {
        stroke: EdgeColor.INITIAL,
      },
    });
  });
};
// 修改某一节点输出边样式
export const changeOutEdges = (graph?: Graph, cell?: Cell | string) => {
  if (!graph) return;
  const edges = graph.getEdges();
  edges.forEach(edge => {
    let currentColor = edge.attr('line/stroke');
    if (currentColor === EdgeColor.ALARM) {
      edge.data = { cacheColor: currentColor };
    }

    if (currentColor === EdgeColor.SELECTED) {
      edge.attr({
        line: {
          targetMarker: { size: 6 },
          stroke: edge.data?.cacheColor ?? EdgeColor.INITIAL,
        },
      });
      return;
    }
  });
  if (cell) {
    const outEdges = graph?.getOutgoingEdges(cell);
    if (outEdges) {
      outEdges.forEach(edge => {
        edge.attr({
          line: {
            stroke: EdgeColor.SELECTED,
            targetMarker: { size: 8 },
          },
        });
      });
    }
    return;
  }
};

// 修改某一节点所有入边样式
export const changeIncomingEdges = (graph?: Graph, cell?: Cell, color = EdgeColor.ALARM, invertIds?: Key[]) => {
  if (!graph || !cell) return;
  // 获取所有与该节点相关的入边
  const incomingEdges = graph?.getIncomingEdges(cell);

  if (incomingEdges) {
    incomingEdges
      .filter(item => {
        // 片区string报警时, 找出和string关联的逆变器
        const sourceData = item.getSourceNode()?.getData<ApiTreeData>();
        if (sourceData?.type === DeviceTypeEnum.INVERTER && invertIds && invertIds?.length > 0) {
          return sourceData?.objectId && invertIds.includes(sourceData?.objectId);
        }
        let currentColor = item.attr('line/stroke');
        if (currentColor === color) return false;
        return true;
      })
      .forEach(edge => {
        edge.attr({
          line: {
            stroke: color,
          },
        });

        edge.setZIndex(3);
        // 递归调用，处理源节点
        const sourceNode = edge.getSourceNode();
        if (sourceNode) {
          changeIncomingEdges(graph, sourceNode, color, invertIds);
        }
      });
  }
};
const getDeviceNodeShape = (type?: number) => {
  if (typeof type !== 'number') return deviceTypeNodeShape[DeviceTypeEnum.GRID];
  return { ...deviceTypeNodeShape }[type];
};

const addVirtualGrid = (treeData?: ApiTreeData[]) => {
  /**
   * 和后端约定好, 后端会帮前端创建一个虚拟网点且objectId=-9999
   */

  treeData?.forEach(item => {
    if (item.type === DeviceTypeEnum.GRID && item.objectId === -9999) {
      item.objectId = v4() as any;
      item.type = CustomDeviceTypeEnum.VIRTUALGRID;
    }
    // let filtersHasParent = item.childrenList?.filter(
    //   item => !(item.type === DeviceTypeEnum.CONNECT && typeof item.hasParent === 'boolean' && !item.hasParent)
    // );
    // let filtersNoParent = item.childrenList?.filter(
    //   item => item.type === DeviceTypeEnum.CONNECT && typeof item.hasParent === 'boolean' && !item.hasParent
    // );
    // item.childrenList = filtersHasParent;
    // if (filtersNoParent && filtersNoParent.length > 0) {
    //   temp.push({
    //     objectId: v4() as any,
    //     type: CustomDeviceTypeEnum.VIRTUALGRID,
    //     childrenList: filtersNoParent,
    //   });
    // }
  });

  return treeData;
};

// 给关联同一个片区的逆变器添加片区子节点
const addAreaNode = (invert: ExpandPropsApiTreeData[], areaList?: AreaStringItem[]) => {
  if (invert && invert.length > 0) {
    const allAreaIds = invert.map(chid => chid.childrenId!);
    const uniqueIds = [...new Set(allAreaIds)];
    uniqueIds.forEach(id => {
      const invertArr = invert.filter(chid => chid.childrenId === id);
      // 计算中间位置
      const middleIndex = Math.floor(invertArr.length / 2);
      const middelInverter = invertArr[middleIndex];

      const areaInfo = areaList?.find(area => id === area.areaId);
      // 只要中间的逆变器添加片区节点
      if (areaInfo) {
        middelInverter.children = [
          {
            id: `${areaInfo.areaId}-${CustomDeviceTypeEnum.AREA}`,
            type: CustomDeviceTypeEnum.AREA,
            extral: areaInfo,
            invertNodeIds: invertArr.map(v => `${v.objectId ?? ''}-${typeof v.type === 'number' ? v.type : ''}`),
          },
        ];
      }
    });
  }
};

const computeEdgeRouterPadding = (index: number, total: number) => {
  let middleIndexs: number[] = [];
  let base = 60;
  let max = 60;
  if (total <= 3) return base;

  // 偶数
  if (total % 2 === 0) {
    middleIndexs = [total / 2 - 1, total / 2];
    max = base + (total / 2 - 1) * 10;
  } else {
    middleIndexs = [Math.floor(total / 2)];
    max = base + 10 * Math.floor(total / 2);
  }

  if (middleIndexs.includes(index)) {
    return max;
  }
  let middleIndex = middleIndexs[1] || middleIndexs[0];
  if (index < middleIndex) {
    return base + 10 * index;
  }
  if (index > middleIndex) {
    return max - (index - middleIndex) * 10;
  }

  return base;
};
// 根据组件数量计算片区节点的高度
const getAreaHeight = (stringNums?: number) => {
  const bsaH = 48;
  if (stringNums) {
    let rows = Math.ceil(stringNums / 8);
    let h = 32 * rows + 2 * (rows - 1) + bsaH;
    return h;
  }

  return bsaH + 32;
};
export const getPropertyInfo = (propertyId?: number) => {
  if (!propertyId) return {} as DataPropertyItem;
  let list = Object.values(dataPropertyMap).flat();
  return list.find(item => item.propertyId === propertyId);
};

export function goFullscreen(element: HTMLDivElement) {
  if (document.fullscreenElement) {
    return Promise.reject(new Error('全屏模式被禁用'));
  }
  let result = null;
  if (element.requestFullscreen) {
    result = element.requestFullscreen();
  }
  return result || Promise.reject(new Error('不支持全屏'));
}
export function cancelFullscreen() {
  document.exitFullscreen?.();
}
export const keepTwoNull = (data?: number) => {
  if (!isNil(data)) {
    return Number(data.toFixed(2));
  }
  return '--';
};
