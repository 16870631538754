import { EllipsisSpan, Modal, Paging, Table, Tag, usePaging } from '@maxtropy/components';
import { apiV2NeoBsaRemoteControlLogsPost, apiV2DeviceDataPropertyRecentPost } from '@maxtropy/dmes-apis-v2';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { ShareContext } from '../..';
import { OpStatus, OpStatusDisplay, OpStatusTagColor } from '../../type';
interface Iprops {
  onCancel?: () => void;
  onConfirm?: () => void;
}
const RemoteLogModal = ({ onCancel, onConfirm }: Iprops) => {
  const pagingInfo = usePaging();
  const { bsaId, bsaType } = useContext(ShareContext);
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;

  const { data: list } = useRequest(
    () => {
      return apiV2NeoBsaRemoteControlLogsPost({
        page: pageOffset,
        size: pageSize,
        bsaId,
        bsaType,
      }).then(res => {
        setTotalCount(res.total ?? 0);
        return res.list;
      });
    },
    {
      refreshDeps: [pageOffset, pageSize],
    }
  );
  const colunms = [
    {
      title: '设备名称',
      dataIndex: 'deviceName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '数据名称',
      dataIndex: 'datapropertyName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '远控值',
      dataIndex: 'value',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '操作人',
      dataIndex: 'operationName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },

    {
      title: '操作时间',
      dataIndex: 'operationTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />,
    },
    {
      title: '状态',
      dataIndex: 'status',
      ellipsis: { showTitle: true },
      render: (v: OpStatus) => (
        <EllipsisSpan
          value={
            <Tag border="solid" color={OpStatusTagColor[v]}>
              {OpStatusDisplay[v]}
            </Tag>
          }
        />
      ),
    },
  ];
  return (
    <Modal open title="远控日志" size="big" onCancel={() => onCancel?.()} onOk={() => onConfirm?.()}>
      <Table rowKey="logId" scroll={{ y: 450 }} columns={colunms} dataSource={list} />
      <Paging pagingInfo={pagingInfo} />
    </Modal>
  );
};

export default RemoteLogModal;
