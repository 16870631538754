import { formateValuesProcessPercent } from '@/pages/MapStation/utils';
import { Divider, Progress } from 'antd';
import { useContext } from 'react';
import { RightValue } from '../..';
import CurrentMonthMaxMinPoint from './CurrentMonthMaxMinPoint';
import styles from './index.module.scss';

const SystemEfficiency = () => {
  const rightValue = useContext(RightValue);

  return (
    <div>
      <div className={styles.RightInfoTitle}>系统效率概览</div>
      <div className={styles.ItemInfo}>
        <div style={{ marginRight: 6 }}>当月平均</div>
        <Progress
          percent={formateValuesProcessPercent(rightValue?.thisMonthSystemEfficiency)}
          steps={25}
          size="small"
          strokeColor="#51D9EE"
          trailColor="#FFFFFF20"
        />
      </div>
      <div className={styles.DividerStyle}>
        <Divider dashed />
      </div>

      <CurrentMonthMaxMinPoint
        title={'本月最高站点'}
        name={rightValue?.thisMonthMax?.name ?? '--'}
        id={rightValue?.thisMonthMax?.id}
        percent={formateValuesProcessPercent(rightValue?.thisMonthMax?.value)}
      />
      <CurrentMonthMaxMinPoint
        title={'本月最低站点'}
        name={rightValue?.thisMonthMin?.name ?? '--'}
        id={rightValue?.thisMonthMin?.id}
        percent={formateValuesProcessPercent(rightValue?.thisMonthMin?.value)}
        strokeColor={'#ABD335'}
      />
      <CurrentMonthMaxMinPoint
        title={'昨日最高站点'}
        name={rightValue?.lastDayMax?.name ?? '--'}
        id={rightValue?.lastDayMax?.id}
        percent={formateValuesProcessPercent(rightValue?.lastDayMax?.value)}
      />
      <CurrentMonthMaxMinPoint
        title={'昨日最低站点'}
        name={rightValue?.lastDayMin?.name ?? '--'}
        id={rightValue?.lastDayMin?.id}
        percent={formateValuesProcessPercent(rightValue?.lastDayMin?.value)}
        strokeColor={'#ABD335'}
      />
    </div>
  );
};

export default SystemEfficiency;
