import { Space } from 'antd';
import styles from './index.module.scss';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  addCabinet,
  CabinetWithCircuitResponse,
  deleteCabinet,
  editCabinet,
  getCabinetWithCircuitList,
} from '@/api/circuit';
import { DataNode } from 'antd/lib/tree';
import cabinetIcon from '@/assets/images/cabinet.svg';
import circuitIcon from '@/assets/images/circuit.svg';
import { FormOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Modal,
  useBreadcrumbRoutes,
  useUpdate,
  Empty,
  Tree,
  Button,
  Wrapper,
  FormContent,
  FormTitle,
  SubContent,
} from '@maxtropy/components';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/hooks';
const { Search } = Input;
interface CabinetInfo {
  id?: number;
  name?: string;
}

type FunCallback = (cabinet: CabinetInfo) => void;
const IconOps = ({
  name,
  id,
  edit,
  remove,
  showIcon,
  hasEditPermission,
  hasRemovePermission,
}: {
  name: string;
  id: number;
  showIcon: boolean;
  edit?: FunCallback;
  remove?: FunCallback;
  hasEditPermission: boolean;
  hasRemovePermission: boolean;
}) => {
  return (
    <>
      <Space size={10}>
        {name}
        {showIcon && (
          <Space size={10}>
            {hasEditPermission && <FormOutlined onClick={() => edit?.({ id, name })} style={{ color: '#4a90e2' }} />}
            {hasRemovePermission && (
              <MinusCircleOutlined
                onClick={() => {
                  Modal.confirm({
                    title: '确定删除该配电柜？删除后无法恢复',
                    onOk: () => remove?.({ id, name }),
                  });
                }}
                style={{ color: '#4a90e2' }}
              />
            )}
          </Space>
        )}
      </Space>
    </>
  );
};

const loop = (
  circuitData: CabinetWithCircuitResponse[],
  edit: FunCallback,
  remove: FunCallback,
  showIcon: boolean,
  hasEditPermission: boolean,
  hasRemovePermission: boolean
): DataNode[] => {
  return circuitData.map(item => {
    return {
      title: (
        <IconOps
          hasEditPermission={hasEditPermission}
          hasRemovePermission={hasRemovePermission}
          showIcon={showIcon}
          name={item.name}
          id={item.id}
          edit={edit}
          remove={remove}
        />
      ),
      key: item.id,
      icon: <img src={cabinetIcon} alt="" />,
      children: item.circuitList?.map(cirs => {
        return {
          title: cirs.name,
          icon: <img src={circuitIcon} alt="" />,
          key: `${item.id}-${cirs.id}`,
        };
      }),
    };
  });
};
const filterCabinetsByCircuitName = (circuitData: CabinetWithCircuitResponse[], name: string) => {
  let data: CabinetWithCircuitResponse[] = [];
  if (circuitData.length === 0) return [];
  circuitData.forEach(item => {
    let temp = item.circuitList.filter(cir => cir.name.toLowerCase().indexOf(name.toLowerCase()) > -1);
    if (temp && temp.length > 0) {
      data.push({
        ...item,
        circuitList: temp,
      });
    }
  });
  return data;
};
const CabinetsManage: React.FC = () => {
  const [circuitData, setCircuitData] = useState<CabinetWithCircuitResponse[]>();
  const currentCabinet = useRef<CabinetInfo>();
  const [form] = Form.useForm();
  const editInitCabinetName = useRef<string>();
  // 备份数据搜索时用
  const tempCircuitData = useRef<CabinetWithCircuitResponse[]>();
  const [opType, setOpType] = useState<string>('add');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [update, setUpdate] = useUpdate();
  const [showEditIcon, setShowEditIcon] = useState(false);
  // 搜索
  const onSearch = (value: string) => {
    if (value.trim()) {
      let dataList = tempCircuitData.current;
      // 找配电柜
      let filterCabinets = dataList?.filter(item => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1);
      if (filterCabinets && filterCabinets.length > 0) {
        setCircuitData(filterCabinets);
        return;
      }
      // 没找到配电柜, 找出回路
      let temp = filterCabinetsByCircuitName(dataList!, value);
      setCircuitData(temp);
      return;
    }
    setCircuitData(tempCircuitData.current);
  };
  const clearFormValue = () => form.setFieldsValue({ cabinetName: undefined });
  // 新建配电柜安按钮
  const addBtn = () => {
    setOpType('add');
    setOpenModal(true);
  };
  // 编辑button
  const switchStatus = () => {
    setShowEditIcon(!showEditIcon);
  };
  // 编辑icon
  const editBtn = (cabinet: CabinetInfo) => {
    setOpType('edit');
    setOpenModal(true);
    form.setFieldsValue({ cabinetName: cabinet.name });
    currentCabinet.current = cabinet;
    editInitCabinetName.current = cabinet.name;
  };
  // 移除icon
  const removeBtn = (cabinet: CabinetInfo) => {
    deleteCabinet(cabinet.id!).then(setUpdate);
  };
  // 当前配电柜名称
  const cabinetNames = useMemo(() => {
    return circuitData?.map(item => item.name);
  }, [circuitData]);

  // 显示弹框
  const showConfimModal = (title: string) => {
    Modal.warning({
      title: title,
      okText: '好的',
    });
  };
  // 弹框确认
  const confirmBtn = async () => {
    let isPass = await form
      .validateFields()
      .then(_ => true)
      .catch(_ => false);
    if (!isPass) return;
    let currentCabinetName = form.getFieldValue('cabinetName');
    // 校验回路名是否重复
    if (opType === 'add') {
      if (cabinetNames?.includes(currentCabinetName)) {
        showConfimModal('配电柜名称重复，请重新输入配电柜名称');
        return;
      }
      addCabinet(currentCabinetName).then(_ => {
        setUpdate();
        setOpenModal(false);
        clearFormValue();
      });
      return;
    }
    if (opType === 'edit') {
      if (currentCabinetName.trim() !== editInitCabinetName.current) {
        if (cabinetNames?.includes(currentCabinetName)) {
          showConfimModal('配电柜名称重复，请重新输入配电柜名称');
          return;
        }
        // api请求
        editCabinet(currentCabinet.current?.id!, currentCabinetName).then(_ => {
          setUpdate();
          setOpenModal(false);
          clearFormValue();
        });
        return;
      }
      // api请求
      editCabinet(currentCabinet.current?.id!, currentCabinetName).then(_ => {
        setUpdate();
        setOpenModal(false);
        clearFormValue();
      });
    }
  };
  // 权限
  const hasAddPermission = useHasPermission(PermissionsType.B_CABINET_ADD);
  const hasEditPermission = useHasPermission(PermissionsType.B_CABINET_EDIT);
  const hasDeletePermission = useHasPermission(PermissionsType.B_CABINET_DELETE);

  const formateCircuitData = useMemo(() => {
    if (circuitData && circuitData.length > 0) {
      return loop(circuitData, editBtn, removeBtn, showEditIcon, !!hasEditPermission, !!hasDeletePermission);
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circuitData, showEditIcon, hasEditPermission, hasDeletePermission]);

  const modalCancelBtn = () => {
    setOpenModal(false);
    clearFormValue();
  };
  // 获取配电柜列表
  useEffect(() => {
    getCabinetWithCircuitList().then(res => {
      setCircuitData(res);
      tempCircuitData.current = res;
    });
  }, [update]);
  const routesContext = useBreadcrumbRoutes();

  const modalTitle = useMemo(() => {
    return opType === 'add' ? '新建配电柜' : '编辑配电柜';
  }, [opType]);
  return (
    <>
      <Wrapper className={styles.wrapperPadding} routes={routesContext?.routes ?? []}>
        <FormTitle title="基本信息" />
        <SubContent>
          <div className={styles.searchArea}>
            <Search placeholder="请输入配电柜/回路名称" allowClear onSearch={onSearch} style={{ width: 250 }} />
          </div>
          <Space size={8}>
            {hasAddPermission && (
              <Button type="primary" onClick={addBtn}>
                <PlusOutlined />
                新建配电柜
              </Button>
            )}
            {hasEditPermission && formateCircuitData.length > 0 && (
              <Button type="primary" onClick={switchStatus}>
                {showEditIcon ? '关闭' : '编辑'}
              </Button>
            )}
          </Space>
          <div className={styles.tree_outer}>
            <div className={styles.tree_area}>
              {formateCircuitData.length === 0 ? (
                <Empty style={{ paddingTop: '50px' }} />
              ) : (
                <Tree blockNode showIcon defaultExpandAll treeData={formateCircuitData} />
              )}
            </div>
          </div>
        </SubContent>
      </Wrapper>

      <Modal
        contentClassName="modal-form-content"
        open={openModal}
        size="normal"
        onCancel={modalCancelBtn}
        onOk={confirmBtn}
        title={modalTitle}
      >
        <Form form={form}>
          <Form.Item label="配电柜名称" rules={[{ required: true }]} name="cabinetName">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CabinetsManage;
