import React, { useMemo, useState } from 'react';
import CardBox from '../../../CardBox';
import { Button, Empty, MXTabsProps, Tabs, Tag, Tooltip } from '@maxtropy/components';
import { CheckCircleFilled, DoubleRightOutlined, InfoCircleOutlined, WarningFilled } from '@ant-design/icons';
import styles from './index.module.scss';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import { AlarmLevel, AlarmLevelColorDisplay, AlarmLevelLabel } from '@/pages/PvDemonstrationBase/const';
import { useRequest } from 'ahooks';
import {
  apiV2PvNoticeboardAlarmCountPost,
  apiV2PvNoticeboardAlarmDataPagePost,
  apiV2PvNoticeboardAlarmWeekPost,
} from '@maxtropy/dmes-apis-v2';
import { isNil } from 'lodash-es';
import { keepTwoOrLine } from '@/pages/PVPowerForecast/utils';
import { formatDeviceDesc } from '@/pages/PvDemonstrationBase/utils';
import noAlarm from '../../../../imgs/noAlarm.png';

const SceneType = 4; //场景类型	是4的时候是离散率报警，传0服务端报警，否则传1边缘报警

const AlarmList = () => {
  const [activeKey, setActiveKey] = useState<string>('all');

  // 数量统计
  const { data: numberData } = useRequest(
    () => {
      return apiV2PvNoticeboardAlarmCountPost({});
    },
    {
      pollingInterval: 2 * 60000,
      ready: !!activeKey,
      refreshDeps: [activeKey],
    }
  );

  const items: MXTabsProps['items'] = useMemo(() => {
    return [
      {
        key: 'all',
        label: <>全部({keepTwoOrLine(numberData?.allNum)})</>,
      },
      {
        key: '0',
        label: <>最高级({keepTwoOrLine(numberData?.topHighAlarmNum)})</>,
      },
      {
        key: '1',
        label: <>高级({keepTwoOrLine(numberData?.highAlarmNum)})</>,
      },
      {
        key: '2',
        label: <>中级({keepTwoOrLine(numberData?.middleAlarmNum)})</>,
      },
      {
        key: '3',
        label: <>低级({keepTwoOrLine(numberData?.lowerAlarmNum)})</>,
      },
      {
        key: '4',
        label: <>最低级({keepTwoOrLine(numberData?.earlyWarningAlarmNum)})</>,
      },
    ];
  }, [numberData]);

  const { data: eachlist, loading: eachLoading } = useRequest(
    () =>
      apiV2PvNoticeboardAlarmDataPagePost({
        level: activeKey === 'all' ? undefined : Number(activeKey),
        attentionLog: false,
        page: 1,
        size: 10,
      }).then(res => {
        return res?.list;
      }),
    {
      pollingInterval: 2 * 60000,
      ready: !!activeKey,
      refreshDeps: [activeKey],
    }
  );

  const { data: alarmWeek } = useRequest(() => {
    return apiV2PvNoticeboardAlarmWeekPost({});
  });

  const renderIcon = useMemo(() => {
    if (alarmWeek?.alarmDeviceList?.length === 0 || isNil(alarmWeek?.alarmDeviceList)) {
      return <CheckCircleFilled style={{ color: '#52C41A', width: 16, height: 16 }} />;
    } else {
      return <WarningFilled style={{ color: '#FAAD14', width: 16, height: 16 }} />;
    }
  }, [alarmWeek]);

  const renderDevices = useMemo(() => {
    if (alarmWeek?.alarmDeviceList?.length === 0 || isNil(alarmWeek?.alarmDeviceList)) {
      return (
        <>
          各设备报警次数均<span style={{ color: '#52C41A' }}>低于5次</span>，建议保持观察
        </>
      );
    } else {
      return (
        <>
          其中<span className={styles.tip_word}>{formatDeviceDesc(alarmWeek?.alarmDeviceList)}</span>报警次数累计超
          <span className={styles.tip_word}>5次</span>，建议尽快维修保养
        </>
      );
    }
  }, [alarmWeek]);

  return (
    <CardBox
      style={{ width: 0 }}
      title={
        <>
          报警列表
          <Tooltip
            title="当前设备未恢复报警统计"
            getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
          >
            <InfoCircleOutlined style={{ width: 12, marginLeft: 4, color: '#FAAD14' }} />
          </Tooltip>
        </>
      }
      extraContent={
        <Button type="link" style={{ padding: '0' }} onClick={() => window.open(`/assets/alarm/overview`, '_blank')}>
          更多报警
          <DoubleRightOutlined className={styles.translationStyles} />
        </Button>
      }
      className={styles.cardBoxContentSty}
    >
      <div className={styles.desc_box}>
        {renderIcon}
        本站点过去一周累计触发报警<span className={styles.tip_word}>{alarmWeek?.count ?? 0}条</span>，{renderDevices}
      </div>
      <div className={styles.top_box}>
        <Tabs
          getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
          size="small"
          className={styles.tabSty}
          onChange={v => setActiveKey(v)}
          activeKey={activeKey}
          items={items}
        />
        {activeKey && (
          <div
            className={styles.tab_content}
            // style={{ height: isSmallScreen ? 650 : undefined, flex: isSmallScreen ? 'unset' : 1 }}
          >
            {eachlist && eachlist.length > 0 ? (
              <Spin spinning={eachLoading}>
                {(eachlist ?? []).map(item => (
                  <div
                    className={styles.item_sty}
                    key={item.alarmId}
                    onClick={() =>
                      window.open(`${window.IOTPLATFORMORIGIN}/device/alarm/record/detail/${item?.alarmId}`, '_blank')
                    }
                  >
                    <div className={styles.item_wrapper_title}>
                      <Tag border="solid" color={AlarmLevelColorDisplay[item.level as AlarmLevel]}>
                        {AlarmLevelLabel[item.level as AlarmLevel]}
                      </Tag>
                    </div>
                    <Tooltip
                      title={item.deviceName}
                      getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                    >
                      <div className={styles.item_wrapper_deviceName}>{item.deviceName ?? '--'}</div>
                    </Tooltip>
                    <div className={styles.item_wrapper_content}>
                      <Tooltip
                        // overlayStyle={{ minWidth: 300 }}
                        getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                        title={
                          <>
                            <span className={styles.tooltip_label_sty}>设备名称：</span>
                            <span className={styles.tooltip_value_sty}>{item.deviceName ?? '--'}</span>
                            <br />
                            <span className={styles.tooltip_label_sty}>报警信息：</span>
                            <span className={styles.tooltip_value_sty}>
                              {item.sceneType === SceneType ? item.alarmMsg ?? '--' : item.ruleName ?? '--'}
                            </span>
                            <br />
                            <span className={styles.tooltip_label_sty}>触发时间：</span>
                            <span className={styles.tooltip_value_sty}>
                              {item.alarmTime ? dayjs(item.alarmTime).format('YYYY-MM-DD HH:mm:ss') : '--'}
                            </span>
                          </>
                        }
                      >
                        <div className={styles.attentionLogNum_wrapper}>
                          <div className={styles.attentionLogNumContent}>
                            {item.sceneType === SceneType ? item.alarmMsg ?? '--' : item.ruleName ?? '--'}
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                    <div className={styles.item_wrapper_time}>
                      {item.alarmTime ? dayjs(item.alarmTime).format('YYYY-MM-DD HH:mm:ss') : '--'}
                    </div>
                  </div>
                ))}
              </Spin>
            ) : (
              <Empty image={<img src={noAlarm}></img>} />
            )}
          </div>
        )}
      </div>
    </CardBox>
  );
};

export default AlarmList;
