import { useEffect, useRef, useState } from 'react';
import AMapLoader from '@amap/amap-jsapi-loader';
import styles from './index.module.scss';
import './index.module.scss';
import { Spin } from 'antd';
import LeftInfo from './LeftInfo';
import { useBreadcrumbRoutes, Breadcrumb, Wrapper } from '@maxtropy/components';
import TopSummary from './TopSummary';
import RightInfo from './RightInfo';
import { BsaDataStaticsGisRes, getBsaDataStaticsGis, getGisConfig } from '@/api/bsaMap';
import classnames from 'classnames/bind';
import { useSize } from 'ahooks';
import { flexible } from './utils';

const cx = classnames.bind(styles);

// let points = [
//   { id: 1, lnglat: [116.373051, 39.797837] },
//   { id: 2, lnglat: [120.354513, 31.501462] },
//   { id: 3, lnglat: [112.884156, 28.124426] },
//   { id: 4, lnglat: [120.247579, 31.451202] },
//   { id: 5, lnglat: [114.638578, 32.050894] },
// ];

export interface DetailInfoProps {
  id: number | undefined;
  showPosition: boolean;
  showDetails: boolean;
}

const MapStation = () => {
  const routesContext = useBreadcrumbRoutes();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<BsaDataStaticsGisRes>();
  const [detailInfo, setDetailInfo] = useState<DetailInfoProps>();
  const map = useRef<any>();
  const aMapLoad = useRef<any>();
  const detailIdRef = useRef<number>();
  const cluster = useRef<any>();

  useEffect(() => {
    getBsaDataStaticsGis().then((res: any) => {
      setData(res);
    });
  }, []);

  useEffect(() => {
    let loadingStatus: number;
    if (data) {
      setLoading(true);
      getGisConfig().then((res: any) => {
        window._AMapSecurityConfig = {
          securityJsCode: res.securityJsCode, // 开发环境使用
        };
        AMapLoader.load({
          key: res.key,
          version: '2.0',
          plugins: ['AMap.MarkerClusterer', 'AMap.Size', 'AMap.Pixel'],
        })
          .then(AMap => {
            aMapLoad.current = AMap;
            map.current = new AMap.Map('container', {
              mapStyle: res.mapStyle,
              viewMode: '2D', // 默认使用 2D 模式，如果希望使用带有俯仰角的 3D 模式，请设置 viewMode: '3D'
              // center: [116.397428, 39.90923], // 初始化地图中心点
              zoom: 5, // 初始化地图层级
              zooms: [1, 15],
              resizeEnable: true, // 是否监控地图容器尺寸变化
              zIndex: 0,
              defaultCursor: 'default',
              isHotspot: false,
              defaultLayer: 0,
            });
            initMarkerClusterer(map.current, AMap);
            // 地图图块加载完成后触发
            map.current.on('complete', function () {
              loadingStatus = window.setTimeout(() => {
                setLoading(false);
              }, 1000);
            });
          })
          .catch(_ => {});
      });
    }
    return () => clearInterval(loadingStatus);
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const divRef = useRef<HTMLDivElement>();
  const div2Ref = useRef<HTMLDivElement>();
  // const divSize = useSize(divRef);
  const div2Size = useSize(div2Ref);

  // 自适应屏幕缩放
  const handleResize = () => {
    // let unsubscribe: () => void;
    flexible(divRef.current);
  };

  // 自适应窗口变化
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const initMarkerClusterer = (map: any, AMap: any) => {
    map.plugin(['AMap.MarkerClusterer'], function () {
      cluster.current = new AMap.MarkerClusterer(
        map,
        data?.list?.map(i => {
          return {
            id: i.id,
            lnglat: [i.longitude, i.latitude],
          };
        }),
        {
          gridSize: 80,
          minClusterSize: 3,
          maxZoom: 7,
          zoomOnClick: true,
          renderClusterMarker: (context: any) => {
            let size = 80;
            //context.count = 899;
            const div = document.createElement('div');
            div.classList.add('maker-cluster');
            const valueLen = `${context.count}`.length;
            div.classList.add(`size-mode-${valueLen}`);
            const text = document.createElement('span');
            text.innerHTML = context.count;
            div.append(text);

            if (valueLen === 1) size = 48;
            if (valueLen === 2) size = 60;
            context.marker.setOffset(new AMap.Pixel(-size / 2, -size / 2));
            context.marker.setContent(div);
          },
          renderMarker: (marker: any) => {
            marker.marker.setClickable(true);
            marker.marker.setCursor('pointer');
            if (marker.data.length > 0 && detailIdRef.current && marker.data[0].id === detailIdRef.current) {
              marker.marker.setIcon('https://webapi.amap.com/theme/v1.3/markers/n/mark_r.png');
            }

            marker.marker.on('click', (_: any) => {
              if (marker.data.length > 0) {
                detailIdRef.current = marker.data[0].id;
                marker.marker.setIcon('https://webapi.amap.com/theme/v1.3/markers/n/mark_r.png');
                setDetailInfo({ id: marker.data[0].id, showPosition: true, showDetails: true });
              }
            });
            return marker;
          },
        }
      );

      cluster.current.on('click', (item: any) => {
        //这⾥是计算所有聚合点的中⼼点
        let alllng = 0,
          alllat = 0;
        for (const mo of item.clusterData) {
          alllng += mo.lnglat.lng;
          alllat += mo.lnglat.lat;
        }
        const lat = alllat / item.clusterData.length;
        const lng = alllng / item.clusterData.length;
        //这⾥是放⼤地图，此处写死了每次点击放⼤的级别，可以根据点的数量和当前⼤⼩适应放⼤，体验更佳

        map.setZoomAndCenter(8, [lng, lat]);
      });
    });
  };

  useEffect(() => {
    if (detailInfo && detailInfo.showPosition) {
      const point = data?.list?.map(i => {
        return {
          id: i.id,
          lnglat: [i.longitude, i.latitude],
        };
      });
      let zoom = map.current.getZoom();
      if (zoom < 8) {
        zoom = 8;
      } else {
        zoom = Math.ceil(zoom);
      }
      detailIdRef.current = detailInfo.id;
      cluster.current.setMap(null);
      initMarkerClusterer(map.current, aMapLoad.current);
      if (point?.find(i => i.id === detailInfo.id)) {
        map.current.setZoomAndCenter(8, point?.find(i => i.id === detailInfo.id)?.lnglat);
      } else {
        map.current.setZoom(5);
      }
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailInfo]);

  return (
    <div
      className="page"
      ref={ref => {
        if (ref) {
          divRef.current = ref;
        }
      }}
      style={{
        width: '100%',
        height: '100%',
        // overflow: divSize ? (divSize.width + 26 < 1366 && divSize.width > 992 - 70 ? 'hidden' : 'auto') : 'auto',
      }}
    >
      <div className="page__header">
        <Breadcrumb routes={routesContext?.routes ?? []} />
      </div>
      <div
        ref={ref => {
          if (ref) {
            div2Ref.current = ref;
          }
        }}
      >
        <div className={cx('border-wrapper')}>
          <div className={cx('border-wrapper-inner')}>
            <div className={cx('pageContent')}>
              <div className={styles.LeftWapper}>
                <LeftInfo
                  divHeight={div2Size ? div2Size.height : undefined}
                  detailInfo={detailInfo}
                  changeIsDetail={(id: number | undefined, showPosition: boolean, showDetails: boolean) =>
                    setDetailInfo({ id, showPosition, showDetails })
                  }
                  data={data}
                />
              </div>
              <div className={styles.TopWapper}>
                <TopSummary data={data} />
              </div>
              <div className={styles.RightWapper}>
                <RightInfo data={data} divHeight={div2Size ? div2Size.height : undefined} />
              </div>
            </div>
            <Spin spinning={loading}>
              <div
                id="container"
                style={{
                  height: div2Size ? div2Size.height - 40 : 'unset',
                  backgroundColor: loading ? 'black' : undefined,
                }}
              ></div>
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapStation;
