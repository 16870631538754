import DividerContent from '@/components/DividerContent';
import { Empty, Form, Upload } from '@maxtropy/components';
import { useForm } from 'antd/es/form/Form';
import { UploadFileStatus } from 'antd/es/upload/interface';
import React, { forwardRef, useImperativeHandle } from 'react';
import { CostInfoRef } from '../CostInfo';

interface Props {
  isView?: boolean;
}

const FileInfo = forwardRef<CostInfoRef, Props>((props, ref) => {
  const { isView } = props;
  const [uploadStatus, setHasUploadStatus] = React.useState<UploadFileStatus>();

  const [form] = useForm();

  useImperativeHandle(ref, () => {
    return {
      async getData() {
        const res = await form.validateFields();
        res.files = res.files.map((i: string) => ({
          fileKey: i,
        }));
        return res;
      },
      setData(data: any) {
        form.setFieldsValue({
          ...data,
          files: (data.files ?? []).map((i: any) => i.fileKey),
        });
      },
      uploadStatus, // 上传状态
    };
  });

  return (
    <DividerContent title="站点布局图">
      <Form form={form}>
        <Form.Item label="文件上传" name="files">
          {isView && (form.getFieldValue('files') ?? []).length == 0 ? (
            <Empty style={{ width: 100 }} description="暂无图片" />
          ) : (
            <Upload
              disabled={isView}
              multiple
              accept={['.jpg', '.jpeg', '.png']}
              uploadText="上传文件"
              fileSize={10}
              onUploadStatus={status => {
                setHasUploadStatus(status);
              }}
              maxCount={isView ? (form.getFieldValue('files') ?? []).length : 999}
              tip="仅支持jpg、jpeg、png，大小不超过10M"
            />
          )}
        </Form.Item>
      </Form>
    </DividerContent>
  );
});

export default FileInfo;
