import { Button, EllipsisSpan, Table, usePaging, useUpdate } from '@maxtropy/components';
import { FC, useState } from 'react';
import { useRequest } from 'ahooks';
import {
  apiV2PvDispersionRateThresholdPagePost,
  V2PvDispersionRateThresholdPagePostResponse,
} from '@maxtropy/dmes-apis-v2';
import {
  InverterOrComponentThresholdType,
  InverterOrComponentThresholdTypeColor,
  InverterOrComponentThresholdTypeLabel,
  InverterOrComponentThresholdTypeList,
  ThresholdDeviceType,
} from './const';
import { TableColumnsType } from 'antd';
import { v4 } from 'uuid';
import styles from './index.module.scss';
import InverterOrComponentModal from './InverterOrComponentModal';

export type DataItem = Exclude<V2PvDispersionRateThresholdPagePostResponse['list'], undefined>[number];

const generateThresholdColumns = (): TableColumnsType<DataItem> => {
  const findItem = (record: DataItem, type: InverterOrComponentThresholdType) =>
    record.thresholdValueResponseList?.find(item => item.type === type);

  return InverterOrComponentThresholdTypeList.map(type => ({
    title: (
      <div className={styles.thresholdTitle} style={{ color: InverterOrComponentThresholdTypeColor[type] }}>
        <div className={styles.thresholdIcon} style={{ background: InverterOrComponentThresholdTypeColor[type] }}></div>
        {InverterOrComponentThresholdTypeLabel[type]}
      </div>
    ),
    children: [
      {
        title: '上限',
        key: 'abnormalValue',
        ellipsis: { showTitle: true },
        render: (_, record) => (
          <EllipsisSpan
            value={findItem(record, type)?.upperLimit && findItem(record, type)?.upperLimit + '%'}
          ></EllipsisSpan>
        ),
      },
      {
        title: '下限',
        key: 'differenceValue',
        ellipsis: { showTitle: true },
        render: (_, record) => (
          <EllipsisSpan
            value={findItem(record, type)?.lowerLimit && findItem(record, type)?.lowerLimit + '%'}
          ></EllipsisSpan>
        ),
      },
    ],
  }));
};

interface Props {
  objectName: string;
  type: ThresholdDeviceType;
  pagingInfo: ReturnType<typeof usePaging>;
}

const InverterOrComponentTable: FC<Props> = props => {
  const { objectName, type, pagingInfo } = props;
  const { pageOffset, pageSize, setPageOffset, setTotalCount } = pagingInfo;
  const [modalOpen, setModalOpen] = useState(false);
  const [editData, setEditData] = useState<DataItem>();
  const [updateState, updateFn] = useUpdate();

  const { data, loading } = useRequest(
    async () => {
      const res = await apiV2PvDispersionRateThresholdPagePost({
        page: pageOffset,
        size: pageSize,
        objectName: objectName,
        type: type,
      });
      setTotalCount(res.total ?? 0);
      return res.list ?? [];
    },
    {
      refreshDeps: [pageSize, pageOffset, objectName, type, updateState],
    }
  );

  const columns: TableColumnsType<DataItem> = [
    {
      title: '',
      children: [
        {
          title: type === ThresholdDeviceType.INVERTER ? '逆变器名称' : '组串名称',
          dataIndex: type === ThresholdDeviceType.INVERTER ? 'pvInverterName' : 'pvStringName',
          ellipsis: { showTitle: true },
          width: 210,
          render: v => <EllipsisSpan value={v}></EllipsisSpan>,
        },
        {
          title: type === ThresholdDeviceType.INVERTER ? '所属光伏站' : '所属逆变器',
          dataIndex: type === ThresholdDeviceType.INVERTER ? 'pvStationName' : 'pvInverterName',
          ellipsis: { showTitle: true },
          width: 210,
          render: v => <EllipsisSpan value={v}></EllipsisSpan>,
        },
      ],
    },
    ...generateThresholdColumns(),
    {
      title: '操作',
      key: 'action',
      width: 100,
      ellipsis: { showTitle: true },
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => {
            setEditData(record);
            setModalOpen(true);
          }}
        >
          编辑
        </Button>
      ),
    },
  ];

  return (
    <>
      <Table bordered rowKey={() => v4()} loading={loading} dataSource={data} columns={columns}></Table>

      <InverterOrComponentModal
        type={type}
        open={modalOpen}
        setOpen={setModalOpen}
        data={editData}
        onOk={() => updateFn()}
      ></InverterOrComponentModal>
    </>
  );
};

export default InverterOrComponentTable;
