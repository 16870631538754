import React, { useContext, useMemo } from 'react';
import { PvForecastContext } from '../..';
import tittleDecoration from '../imgs/tittleDecoration.png';
import styles from './index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import ReactEcharts from 'echarts-for-react';
import { Empty } from '@maxtropy/components';
import { Spin } from 'antd';
import { useRequest } from 'ahooks';
import { apiV2BsaDataPredictPowerPost } from '@maxtropy/dmes-apis-v2';
import { getChartOption } from './chart';

const PowerForecast = () => {
  const { stationId, date } = useContext(PvForecastContext);

  // 功率预测
  const { data: chartData, loading } = useRequest(
    () => {
      return apiV2BsaDataPredictPowerPost({
        predictDate: date?.valueOf(),
        pvStationId: stationId,
      });
    },
    {
      ready: !!stationId && !!date,
      refreshDeps: [stationId, date],
    }
  );

  // 判断三者是否都为空，如果都为空则显示empty
  const isShowEmpty = useMemo(() => {
    if (
      chartData?.inverterActualDataList?.length ||
      chartData?.pccActualDataList?.length ||
      chartData?.powerPredictDataList?.length
    ) {
      return false;
    }
    return true;
  }, [chartData]);

  // 图表
  const chartOption = useMemo(() => getChartOption(chartData), [chartData]);

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <img src={tittleDecoration} alt="pic" className={styles.titleBorder} />
        <span className={styles.title}>功率预测</span>
        <div className={styles.tooltip}>
          <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
          <span>支持光伏站未来3天的发电预测</span>
        </div>
      </div>

      {loading ? (
        <Spin spinning={true} />
      ) : isShowEmpty ? (
        <Empty className={styles.empty_box} />
      ) : (
        <ReactEcharts style={{ height: 300 }} option={chartOption} notMerge lazyUpdate={false} />
      )}
    </div>
  );
};

export default PowerForecast;
