import { Col, FormInstance, Row, Space } from 'antd';
import { FC } from 'react';
import { Form, Radio, SubContent, TimePicker } from '@maxtropy/components';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Resolution, ResolutionLabel } from '@/pages/PvDispersionRate/AnalysisConfigSetting/const';
import dayjs from 'dayjs';

interface Props {
  form: FormInstance;
}

const TimeSetting: FC<Props> = props => {
  const { form } = props;

  const analysisStartTime = Form.useWatch('analysisStartTime', form);
  const analysisEndTime = Form.useWatch('analysisEndTime', form);

  return (
    <Row>
      <Col span={24}>
        <SubContent title="时间段设置">
          <Form.Item
            required
            label="分析时间段"
            extra={
              <Space size={5} style={{ marginTop: 8 }}>
                <InfoCircleOutlined style={{ color: 'var(--warning-color)' }}></InfoCircleOutlined>
                <span>请选择光照条件较好的时间短，以保证分析准确</span>
              </Space>
            }
          >
            <Space split="~">
              <Form.Item
                noStyle
                name="analysisStartTime"
                label="开始时间"
                rules={[{ required: true }]}
                initialValue={dayjs('10:00', 'HH:mm')}
              >
                <TimePicker
                  placeholder="请选择开始时间"
                  format="HH:mm"
                  minuteStep={15}
                  disabledTime={now => {
                    if (!analysisEndTime) return {};
                    return {
                      disabledHours: () => {
                        let list: number[] = [];
                        for (let i = 23; i > analysisEndTime.hour(); i--) {
                          list.push(i);
                        }
                        return list;
                      },
                      disabledMinutes: selectedHour => {
                        let list: number[] = [];
                        if (selectedHour === analysisEndTime.hour()) {
                          for (let i = 45; i >= analysisEndTime.minute(); i -= 15) {
                            list.push(i);
                          }
                        }
                        return list;
                      },
                    };
                  }}
                ></TimePicker>
              </Form.Item>
              <Form.Item
                noStyle
                name="analysisEndTime"
                label="结束时间"
                rules={[{ required: true }]}
                initialValue={dayjs('14:00', 'HH:mm')}
              >
                <TimePicker
                  placeholder="请选择结束时间"
                  format="HH:mm"
                  minuteStep={15}
                  disabledTime={() => {
                    if (!analysisStartTime) return {};
                    return {
                      disabledHours: () => {
                        let list: number[] = [];
                        for (let i = 0; i < analysisStartTime.hour(); i++) {
                          list.push(i);
                        }
                        return list;
                      },
                      disabledMinutes: selectedHour => {
                        let list: number[] = [];
                        if (selectedHour === analysisStartTime.hour()) {
                          for (let i = 0; i <= analysisStartTime.minute(); i += 15) {
                            list.push(i);
                          }
                        }
                        return list;
                      },
                    };
                  }}
                ></TimePicker>
              </Form.Item>
            </Space>
          </Form.Item>

          <Form.Item
            label="分析颗粒度"
            name="resolution"
            rules={[{ required: true }]}
            initialValue={Resolution.DAY}
            extra={
              <Space size={5} style={{ marginTop: 8 }}>
                <InfoCircleOutlined style={{ color: 'var(--warning-color)' }}></InfoCircleOutlined>
                <span>调整颗粒度将对关联光伏站下所有设置组均生效</span>
              </Space>
            }
          >
            <Radio.Group>
              <Radio value={Resolution.DAY}>{ResolutionLabel[Resolution.DAY]}</Radio>
              <Radio value={Resolution.HOUR}>{ResolutionLabel[Resolution.HOUR]}</Radio>
            </Radio.Group>
          </Form.Item>
        </SubContent>
      </Col>
    </Row>
  );
};

export default TimeSetting;
