import { FC, useEffect, useState } from 'react';
import { message, Modal, Radio } from '@maxtropy/components';
import { useForm } from 'antd/es/form/Form';
import { Form, Input, Spin } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { apiV2BsaSecondEditionCostDetailPost, apiV2BsaSecondEditionCostUpsetPost } from '@maxtropy/dmes-apis-v2';
import ShowInput from '@/components/ShowInput';

interface Props {
  disabled: boolean;
  id: number | undefined;
  setId: (value: number | undefined) => void;
}

const rules = [
  {
    pattern: /^(\d){1,3}[0-8]?(\.(\d){1,2})?$|^9999(\.0{1,2})?$/,
    message: '输入范围为0-9999，支持小数点后2位。',
  },
];
const sigleRules = {
  pattern: /^([0](\.(\d){1,2}))$|^[0-1]{1}(\.0{1,2})?$/,
  message: '输入范围为0.00-1.00，支持小数点后两位。',
};

const CostInfoModal: FC<Props> = props => {
  const { disabled, id, setId } = props;
  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const open = !!id;

  useEffect(() => {
    if (!open) {
      form.resetFields();
      return;
    }
    if (!id) return;
    setLoading(true);
    apiV2BsaSecondEditionCostDetailPost({ id: id })
      .then(res => {
        form.setFieldsValue(res);
      })
      .finally(() => setLoading(false));
  }, [open]);

  const onOk = () => {
    form.validateFields().then(res => {
      setLoading(true);
      apiV2BsaSecondEditionCostUpsetPost({
        neoBsaId: id,
        ...res,
      })
        .then(() => {
          message.success('保存成功');
          setId(undefined);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <Modal
      open={open}
      onCancel={() => setId(undefined)}
      onOk={onOk}
      size="normal"
      title={(disabled ? '查看' : '编辑') + '成本信息'}
      contentClassName="modal-form-content"
      footer={disabled ? false : undefined}
    >
      <Spin spinning={loading}>
        <Form form={form} labelCol={{ style: { width: 110 } }}>
          <Form.Item name="investmentCost" label="投建成本" rules={rules}>
            {disabled ? <ShowInput addonAfter="万元"></ShowInput> : <Input addonAfter="万元"></Input>}
          </Form.Item>
          <Form.Item name="recoveredCost" label="已回收成本" rules={rules}>
            {disabled ? <ShowInput addonAfter="万元"></ShowInput> : <Input addonAfter="万元"></Input>}
          </Form.Item>
          <Form.Item
            name="electricityDiscount"
            label="折扣"
            rules={[{ required: true, message: '请输入折扣' }, { ...sigleRules }]}
            required={!disabled}
          >
            {disabled ? <ShowInput></ShowInput> : <Input></Input>}
          </Form.Item>
          {!disabled && (
            <Form.Item label={<span></span>} colon={false}>
              <InfoCircleOutlined style={{ color: 'var(--warning-color)', marginRight: 4 }}></InfoCircleOutlined>
              <span>
                投资方给企业客户的折扣,如果是自建储能，无需与其他企业结算，此处输入0，例如分成比例是9折，这里填写0.1
              </span>
            </Form.Item>
          )}
          <Form.Item name="settlementWay" label="结算方式" rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={1}>用电折扣</Radio>
              <Radio value={2}>收益分成</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CostInfoModal;
