// 老电池热力图
import { PermissionsType } from '@/common/permissionsConst';
import TemperRealTimeData from '@/pages/TemperRealTimeData';

const routes = [
  {
    path: '/dmes/energy-storage/temper',
    permission: PermissionsType.P_ENERGY_REAL_TIME_LINE,
    element: <TemperRealTimeData />,
  },
];

export default routes;
