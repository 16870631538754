import { useMemo } from 'react';
import { GrainChoice } from './ChargingAmout';

export const getMarkLine = (label: string, position: string, value: number | null) => {
  return {
    silent: true,
    symbol: ['none', 'none'],
    lineStyle: {
      type: 'dashed',
    },
    data: [
      {
        type: 'average',
        name: 'Avg',
        yAxis: value?.toFixed(2),
        label: { formatter: `${label}: ${value?.toFixed(2) ?? '--'}kWh`, position: position },
      },
    ],
  };
};

export const getBasicTooltipsConfig = () => {
  return {
    trigger: 'axis',
    confine: true,
    backgroundColor: 'rgba(0,0,0,0.8)',
    borderColor: 'transparent',
    textStyle: {
      color: 'rgba(255,255,255,0.65)',
    },
    showDelay: 0,
    axisPointer: {
      type: 'shadow',
      shadowStyle: {
        color: 'rgba(255,255,255,0.1)',
        width: '1',
      },
    },
  };
};

export const useComputeTimeResolution = (rangeTime?: GrainChoice) => {
  const res = useMemo(() => {
    if (rangeTime === GrainChoice.MINUTE_15) return 'MM-DD HH:mm';
    if (rangeTime === GrainChoice.HOUR) return 'MM-DD HH:00';
    if (rangeTime === GrainChoice.DAY) return 'YYYY-MM-DD';
    return 'YYYY-MM';
  }, [rangeTime]);

  return res;
};

export const sumTools = (arr: (number | null)[]) => {
  let temp = arr.filter(item => typeof item === 'number');
  if (temp.length === 0) return null;
  return temp.reduce((pre, next) => pre! + next!);
};
