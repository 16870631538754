import { useTenantPermissions } from '@maxtropy/components';
import { Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import NotAuthorized from '../../pages/NotAuthorized';

interface AuthorizedPermissionProps {
  permissionKey: string;
  children?: React.ReactNode;
}

const AuthorizedPermission: FC<AuthorizedPermissionProps> = props => {
  const { permissionKey, children } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [state, setState] = useState(false);

  const permission = useTenantPermissions();

  useEffect(() => {
    if (permission) {
      const state = permission.includes(permissionKey);
      setState(state);
      setLoading(false);
    }
  }, [permission, permissionKey]);

  return loading ? <Spin spinning={loading}></Spin> : state ? <>{children}</> : <NotAuthorized />;
};

export default AuthorizedPermission;
