import { SettingOutlined } from '@ant-design/icons';
import { Table, useUpdate } from '@maxtropy/components';

import { Space } from 'antd';
import { useContext, useMemo, useState } from 'react';
import { ShareContext } from '../..';
import { useGetPcsTableDataSplit } from '../../hooks';
import { DatapropertyType } from '../../type';
import { getCommonTableData, useGetPcsTableColumns } from '../utils';

import styles from './index.module.scss';
import RemoteControlModal from './RemoteControlModal';
import SettingModal from './SettingModal';

// 用于行合并
export const rowType = {
  alarm: 1, // 故障/告警
  status: 2, // 状态
  yc: 3, // 遥测
};
export const RowTypeDisplay = {
  [rowType.alarm]: '故障/告警',
  [rowType.status]: '状态',
  [rowType.yc]: '遥测',
};

const Pcs = () => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [remoteVisible, setRemoteVisible] = useState<boolean>(false);
  const [currentRemoteData, setCurrentRemoteData] = useState<any>({});

  const [modalType, setModalType] = useState<number>();
  const { realData, hasRemoteBtn, hasConfigBtn } = useContext(ShareContext);
  const [update, setUpdate] = useUpdate();

  const { ycDatas, alarmDatas, statusDatas } = useGetPcsTableDataSplit({ data: realData, update });

  const tableData = useMemo(() => {
    if (realData?.length === 0) {
      return [];
    }
    return [
      { datapropertyName: '遥测', datapropertyId: 'yc' },
      ...ycDatas,
      // 报警
      { datapropertyName: rowType.alarm, datapropertyId: 'alarm' },
      ...alarmDatas,
      // 状态
      { datapropertyName: rowType.status, datapropertyId: 'status' },
      ...statusDatas,
    ];
  }, [ycDatas, alarmDatas, statusDatas]);

  // 第几行的数据需要合并
  const rowIndexList = useMemo(() => {
    let alarmIndex = tableData.findIndex(item => item.datapropertyId === 'alarm');
    let statusIndex = tableData.findIndex(item => item.datapropertyId === 'status');
    return [0, alarmIndex, statusIndex];
  }, [tableData]);

  const remoteOp = (record: any) => {
    setRemoteVisible(true);
    setCurrentRemoteData(record);
  };
  const setOp = (v: number) => {
    setModalType(v);
    setModalVisible(true);
  };

  const columns = useGetPcsTableColumns({ list: realData, remoteOp, setOp, hasRemoteBtn, hasConfigBtn });

  return (
    <div className={styles.content}>
      <div className={styles.pcs_table}>
        <Table
          rowKey="datapropertyId"
          rowClassName={(record: any, index: number) => (rowIndexList.includes(index) ? 'self_row_color' : '')}
          columns={columns}
          scroll={{ x: 'max-content', y: 'calc(100vh - 308px)' }}
          dataSource={tableData}
        />
      </div>

      {modalVisible && (
        <SettingModal
          modalType={modalType}
          realData={realData}
          onConfirm={() => {
            setUpdate();
            setModalVisible(false);
          }}
          onCancel={() => setModalVisible(false)}
        />
      )}
      {remoteVisible && (
        <RemoteControlModal
          {...currentRemoteData}
          onConfirm={() => {
            setUpdate();
            setRemoteVisible(false);
          }}
          onCancel={() => setRemoteVisible(false)}
        />
      )}
    </div>
  );
};
export default Pcs;
