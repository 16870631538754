import { PermissionsType } from '@/common/permissionsConst';
import BsaNewBasic from '@/pages/BsaNewBasic';

const routes = [
  {
    path: '/energy-storage/basic/bsaNew/basicDetail/:id',
    permission: PermissionsType.P_BSA_INFO_VIEW_NEW,
    element: <BsaNewBasic disabled={true} />,
  },
  {
    path: '/energy-storage/basic/bsaNew/basicEdit/:id',
    permission: PermissionsType.P_BSA_INFO_EDIT_NEW,
    element: <BsaNewBasic disabled={false} />,
  },
];
export default routes;
