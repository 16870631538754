import BsaHistoryData from './index';
import { PermissionsType } from '@/common/permissionsConst';

const routes = [
  {
    path: '/energy-storage/bsa/historyData',
    element: <BsaHistoryData />,
    permissions: PermissionsType.P_HISTORICAL_ENERGY_STORAGE_DATA,
  },
];

export default routes;
