import { Breadcrumb, DatePicker, Filter, Paging, Table, useBreadcrumbRoutes, usePaging } from '@maxtropy/components';
import { Col, Form, Select } from 'antd';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';

import BorderWrapper from '../../components/BorderWrapper';

import { columns, getLastTypeName } from '.';
import {
  apiV2BsaRemoteControlBsaListPost,
  apiV2BsaRemoteControlLogDeviceListPost,
  apiV2BsaRemoteControlLogPagePost,
} from '@maxtropy/dmes-apis-v2';
import { useRequest } from 'ahooks';
import qs from 'qs';
import { sendStatusDisplay } from './const';
import { RangePickerProps } from 'antd/es/date-picker';
const routes = [{ name: '远程控制日志' }];

interface SearchParamsProps {
  bsaId?: number;
  status?: string;
  deviceId?: string;
  ts?: any;
}

const BsaRecordList: React.FC = () => {
  const { bsaId } = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  }) as Record<string, string>;
  const [form] = Form.useForm();
  const pagingInfo = usePaging(20);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParamsProps>();
  const routesContext = useBreadcrumbRoutes();
  const formBsaId = Form.useWatch('bsaId', form);

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > dayjs().endOf('day');
  };
  const { data: bsaList } = useRequest(() => apiV2BsaRemoteControlBsaListPost().then(res => res.list));
  const { data: targetDeviceList } = useRequest(
    () => apiV2BsaRemoteControlLogDeviceListPost({ id: formBsaId }).then(res => res.list),
    {
      ready: !!formBsaId,
      refreshDeps: [formBsaId],
    }
  );
  useEffect(() => {
    if (bsaId) {
      let exist = bsaList?.find(item => item.id === +bsaId);
      if (exist) {
        form.setFieldsValue({ bsaId: exist.id });
        setSearchParams({ bsaId: exist.id });
        return;
      }
    }
    if (bsaList && bsaList.length) {
      form.setFieldsValue({ bsaId: bsaList[0].id });
      setSearchParams({ bsaId: bsaList[0].id });
    }
  }, [bsaId, bsaList]);

  const { data: tableList, loading } = useRequest(
    () =>
      apiV2BsaRemoteControlLogPagePost({
        page: pageOffset,
        size: pageSize,
        ...searchParams,
        ts: searchParams?.ts ? dayjs(searchParams?.ts).startOf('day').valueOf() : undefined,
      }).then(res => {
        setTotalCount(res.total!);
        return res.list;
      }),
    {
      ready: !!searchParams,
      refreshDeps: [searchParams, pageOffset, pageSize],
    }
  );

  return (
    <div className="page">
      <div className="page__header">
        <Breadcrumb routes={[...(routesContext?.routes ?? []), ...routes]} />
      </div>
      <BorderWrapper>
        <div className="page__filter">
          <Filter<SearchParamsProps>
            form={form}
            onFinish={v => {
              setSearchParams(v);
              setPageOffset(1);
            }}
            onReset={() => {
              setSearchParams({ bsaId: undefined, status: undefined, ts: undefined, deviceId: undefined });
              setPageOffset(1);
            }}
            collapseItems={
              <Col span={6}>
                <Form.Item name="status" label="发送状态">
                  <Select
                    placeholder="请选择"
                    options={Object.entries(sendStatusDisplay).map(([key, value]) => ({ label: value, value: key }))}
                  />
                </Form.Item>
              </Col>
            }
          >
            <>
              <Col span={6}>
                <Form.Item name="bsaId" label="储能站">
                  <Select
                    placeholder="请选择"
                    showSearch
                    optionFilterProp="label"
                    options={bsaList?.map(item => ({
                      label: `${item.customerName ?? ''}${item.customerName ? '-' : ''}${item.name}`,
                      value: item.id,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="deviceId" label="目标设备">
                  <Select
                    placeholder="请选择"
                    showSearch
                    optionFilterProp="label"
                    options={targetDeviceList?.map(item => ({
                      label: `${getLastTypeName(item.typeName)}${
                        item.physicalModelNo ? '-' + item.physicalModelNo + '-' : '-'
                      }${item.name}`,
                      value: item.id,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="ts" label="发送日期">
                  <DatePicker disabledDate={disabledDate} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </>
          </Filter>
        </div>

        <div className="page__content">
          <Table
            sticky
            rowKey="id"
            scroll={{ x: 600 }}
            columns={[...columns]}
            dataSource={tableList}
            loading={loading}
          />
          <Paging pagingInfo={pagingInfo} />
        </div>
      </BorderWrapper>
    </div>
  );
};

export default BsaRecordList;
