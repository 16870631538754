import { V2BsaDataPredictElectricPostResponse } from '@maxtropy/dmes-apis-v2';
import { keepTwoNull } from '../../utils';
import dayjs from 'dayjs';
import * as echarts from 'echarts';
import { isNil } from 'lodash-es';

export const getChartOption = (chartData?: V2BsaDataPredictElectricPostResponse) => {
  // 预测发电量
  let electricPredictData = (chartData?.electricPredictDataList ?? []).map(item => [
    dayjs(item.ts).valueOf(),
    keepTwoNull(item.value),
  ]);
  // 预测上网电量
  let netPredictData = (chartData?.netPredictDataList ?? []).map(item => [
    dayjs(item.ts).valueOf(),
    keepTwoNull(item.value),
  ]);
  // 预测消纳电量
  let absorptionPredictData = (chartData?.absorptionPredictDataList ?? []).map(item => [
    dayjs(item.ts).valueOf(),
    keepTwoNull(item.value),
  ]);
  // 预测消纳率
  let absorptionRatePredictData = (chartData?.absorptionRatePredictDataList ?? []).map(item => [
    dayjs(item.ts).valueOf(),
    keepTwoNull(item.value),
    '消纳率',
  ]);

  const maxElectricPredictData = Math.max(...electricPredictData.map(i => i[1] ?? 0));
  const maxNetPredictData = Math.max(...netPredictData.map(i => i[1] ?? 0));
  const maxAbsorptionPredictData = Math.max(...absorptionPredictData.map(i => i[1] ?? 0));

  const minElectricPredictData = Math.min(...electricPredictData.map(i => i[1] ?? 0));
  const minNetPredictData = Math.min(...netPredictData.map(i => i[1] ?? 0));
  const minAbsorptionPredictData = Math.min(...absorptionPredictData.map(i => i[1] ?? 0));

  let maxPower = Math.ceil(Math.max(maxElectricPredictData, maxNetPredictData, maxAbsorptionPredictData) / 4) * 4;
  let minPower = Math.floor(Math.min(minElectricPredictData, minNetPredictData, minAbsorptionPredictData) / 4) * 4;

  // 间隔计算
  let powerInterVal = Number(((maxPower - minPower) / 4).toFixed(0));

  return {
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
      },
      formatter: (items: any) => {
        let { axisValue } = items[0];
        let firstStr = dayjs(axisValue).format('YYYY-MM-DD HH:mm:ss');
        let otherStr = '';
        let str = '';

        items.forEach((item: any) => {
          const { seriesName, data, marker } = item;
          let unit = '';
          if (data[2] && data[2] === '消纳率') {
            unit = '%';
          } else {
            unit = 'kWh';
          }
          otherStr += `<div style="margin-top:8px"><span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${
            item.color
          };"></span>${seriesName} : &nbsp;${data[1] || data[1] === 0 ? data[1] + unit : '-'}</div>`;
          str = firstStr + otherStr;
        });

        return str;
      },
    },
    grid: {
      left: 50,
      right: 20,
      top: 60,
      bottom: 40,
      containLabel: true,
    },
    color: ['#16DD8E', '#FFCB47', '#CE90D1', '#00ADFF'],
    dataZoom: {
      type: 'slider',
      left: 20,
      right: 20,
      height: 20,
      bottom: 10,
      textStyle: {
        fontSize: 10,
      },
    },
    legend: [
      {
        right: 100,
        textStyle: {
          color: 'rgba(255, 255, 255, 0.85)',
        },
        inactiveColor: 'rgba(255, 255, 255, 0.45)',
        itemWidth: 12,
        itemHeight: 12,
        data: [
          {
            name: '预测发电量',
            icon: 'rect',
          },
          {
            name: '预测上网电量',
            icon: 'rect',
          },
          {
            name: '预测消纳电量',
            icon: 'rect',
          },
        ],
      },
      {
        right: 10,
        textStyle: {
          color: 'rgba(255, 255, 255, 0.85)',
        },
        inactiveColor: 'rgba(255, 255, 255, 0.45)',
        itemWidth: 16,
        itemHeight: 8,
        data: [
          {
            name: '预测消纳率',
            icon: 'path://M0 426.666667h1024v170.666666H0z',
          },
        ],
      },
    ],

    xAxis: [
      {
        type: 'time',
        axisLabel: {
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
          formatter: function (e: number) {
            return dayjs(e, 'x').format('MM-DD[\n]HH:mm');
          },
        },
        axisPointer: {
          type: 'shadow',
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: '功率（kWh）',
        // splitNumber: 5, //优先级太低只能硬算
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        max: maxPower,
        min: minPower,
        interval: powerInterVal,
        axisLabel: {
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
          formatter: '{value}',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#66686A',
          },
        },
      },
      {
        type: 'value',
        name: '消纳率（%）',
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        max: 100,
        min: 0,
        interval: 25,
        axisLabel: {
          formatter: '{value}',
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: '#66686A',
          },
        },
      },
    ],
    series: [
      {
        name: '预测发电量',
        type: 'bar',
        yAxisIndex: 0,
        data: electricPredictData,
      },
      {
        name: '预测上网电量',
        type: 'bar',
        data: netPredictData,
        yAxisIndex: 0,
        stack: 'Ad',
      },
      {
        name: '预测消纳电量',
        type: 'bar',
        data: absorptionPredictData,
        yAxisIndex: 0,
        stack: 'Ad',
      },
      {
        name: '预测消纳率',
        type: 'line',
        data: absorptionRatePredictData,
        yAxisIndex: 1,
        connectNulls: true,
        symbol: 'circle',
        symbolSize: 2,
        showSymbol: false, // 只有在 tooltip hover 的时候显示symbol
        itemStyle: {
          // symbol样式
          normal: {
            borderColor: 'rgba(255, 255, 255, 0.2)', // symbol边框颜色
            borderWidth: 6, // symbol边框宽度
          },
        },
      },
    ],
  };
};
