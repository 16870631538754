import { PermissionsType } from '@/common/permissionsConst';
import BsaList from '@/pages/BsaList';
const routes = [
  {
    path: '/energy-storage/basic/bsa',
    permission: PermissionsType.P_BSA_MANAGE,
    element: <BsaList />,
  },
];
export default routes;
