import { Space } from 'antd';
import { Button, FormTitle } from '@maxtropy/components';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';

interface FormHeaderProps {
  extra?: React.ReactNode;
  title?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  onCancel?: () => void;
  onSubmit?: () => void;
  isSubmitting?: boolean;
  submitButtonText?: string;
  cancelButtonText?: string;
  showSubmitButton?: boolean;
  showCancelButton?: boolean;
}

const FormHeader: React.FC<FormHeaderProps> = ({
  extra,
  title,
  onCancel,
  onSubmit,
  isSubmitting = false,
  submitButtonText = '保存',
  cancelButtonText = '放弃并关闭页面',
  showSubmitButton = true,
  showCancelButton = true,
}) => {
  const navigate = useNavigate();
  return (
    <FormTitle
      className={styles.FormHeader}
      title={title}
      extraContent={
        extra ? (
          extra
        ) : (
          <Space>
            {showSubmitButton && (
              <Button type="primary" onClick={onSubmit} loading={isSubmitting}>
                {submitButtonText}
              </Button>
            )}
            {showCancelButton && (
              <Button
                onClick={onCancel ? onCancel : () => navigate('/dmes/energy-storage/strategy', { replace: true })}
              >
                {cancelButtonText}
              </Button>
            )}
          </Space>
        )
      }
    />
  );
};

export default FormHeader;
