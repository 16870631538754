import { Form, useBreadcrumbRoutes, SubContent, Button, Wrapper, ShowInput, FormTitle } from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import { getLossAlarmLogDetail } from '@/api/lineLoss';
import dayjs from 'dayjs';

const Detail: React.FC = () => {
  const routesContext = useBreadcrumbRoutes();
  const routes = [{ name: '日志详情' }];
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [ruleIsDelete, setRuleIsDelete] = useState<boolean>(false);
  // 回路编号id
  const circuitId = useRef<number>();
  // 报警规则id
  const ruleId = useRef<number>();
  // 表单回填
  useEffect(() => {
    if (id) {
      getLossAlarmLogDetail(id).then(res => {
        circuitId.current = res.circuitId;
        ruleId.current = res.ruleId;
        setRuleIsDelete(res.ruleDelete ?? false);
        let { lossRate, circuitTotalElectricity, circuitChildrenElectricity, lossElectricity } = res;
        if (lossRate) {
          res.lossRate = +(lossRate * 100).toFixed(2);
        }
        if (circuitTotalElectricity) {
          res.circuitTotalElectricity = +circuitTotalElectricity.toFixed(2);
        }
        if (circuitChildrenElectricity) {
          res.circuitChildrenElectricity = +circuitChildrenElectricity.toFixed(2);
        }
        if (lossElectricity) {
          res.lossElectricity = +lossElectricity.toFixed(2);
        }
        form.setFieldsValue(res);
      });
    }
  }, [id, form]);

  // 路由跳转
  const goToRulesDetail = () => {
    if (!ruleId.current) return;
    navigate(`/dmes/lineLoss/alarmRules/${ruleId.current}/view`);
  };
  const goToCircuitDetail = () => {
    if (!circuitId.current) return;
    navigate(`/dmes/circuit/circuit/detail/${circuitId.current}`);
  };

  return (
    <>
      <Wrapper className={styles.wrapperPadding} routes={[...(routesContext?.routes ?? []), ...routes]}>
        <FormTitle title="日志详情" />
        <SubContent>
          <Form
            form={form}
            initialValues={{
              alarmLogic: 1,
            }}
            layout="vertical"
          >
            <Row>
              <Col span={8}>
                <Form.Item label="报警规则" name="ruleName">
                  <ShowInput
                    btn={
                      ruleIsDelete ? (
                        '(报警规则已被删除)'
                      ) : (
                        <Button type="link" onClick={goToRulesDetail}>
                          查看
                        </Button>
                      )
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="规则编号" name="ruleCode">
                  <ShowInput btn={ruleIsDelete && '(报警规则已被删除)'} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item dependencies={['alarmTime']} label="报警时间">
                  {({ getFieldValue }) => {
                    let time = getFieldValue('alarmTime');
                    if (!time) {
                      return <div>--</div>;
                    }
                    let formateTime = dayjs(time).format('YYYY-MM-DD HH:mm:ss');
                    return <div>{formateTime}</div>;
                  }}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="回路编号" name="circuitCode">
                  <ShowInput
                    btn={
                      // 回路详情
                      <Button type="link" onClick={goToCircuitDetail} className={styles.clickViews}>
                        查看
                      </Button>
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="回路名称" name="circuitName">
                  <ShowInput></ShowInput>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="线损统计区间" name="interval">
                  <ShowInput></ShowInput>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="回路总电量" name="circuitTotalElectricity">
                  <ShowInput></ShowInput>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="下游回路电量和" name="circuitChildrenElectricity">
                  <ShowInput></ShowInput>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="损耗率" name="lossRate">
                  <ShowInput addonAfter="%" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="触发时线损电量" name="lossElectricity">
                  <ShowInput />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </SubContent>
        <Space
          style={{
            marginLeft: 32,
            marginTop: -13,
          }}
          className={'sticky-footer-left'}
        >
          <Button onClick={() => navigate(`/dmes/lineLoss/alarmLogs`)}>返回</Button>
        </Space>
      </Wrapper>
    </>
  );
};

export default Detail;
