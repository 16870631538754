import React, { useEffect, useMemo, useState } from 'react';
import { Col } from 'antd';
import { CustomFilter, EllipsisSpan, Form, Input, Modal, Paging, Table, usePaging } from '@maxtropy/components';
import {
  AlarmRule,
  AlarmRuleStatus,
  AlarmRuleType,
  AlarmType,
  BsaAlarmRulesQuery,
  getBsaAlarmRules,
} from '../../api/bsaConfig';
import { PageResponse } from '../../api/page';

interface AlarmRuleSelectModalProps {
  onCancel: () => void;
  onOK: (changed: AlarmRule[]) => void;
  name: string;
  selectedRules: AlarmRule[];
  bsaId: number;
}

const AlarmRuleSelectModal: React.FC<AlarmRuleSelectModalProps> = ({ onCancel, onOK, selectedRules, bsaId }) => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<Partial<BsaAlarmRulesQuery>>();
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<PageResponse<AlarmRule>>();
  const [selectedRows, setSelectedRows] = useState<AlarmRule[]>([]);

  const selectedRowKeys = useMemo(() => selectedRows.map(item => item.id), [selectedRows]);

  useEffect(() => {
    setSelectedRows([...selectedRules]);
  }, [selectedRules]);

  useEffect(() => {
    setIsLoading(true);
    getBsaAlarmRules({
      ...searchParams,
      bsaId,
      page: pageOffset,
      size: pageSize,
    })
      .then(setData)
      .finally(() => setIsLoading(false));
  }, [bsaId, pageOffset, pageSize, searchParams]);

  useEffect(() => {
    if (data) {
      setTotalCount(data.total);
    }
  }, [data, setTotalCount]);

  return (
    <Modal
      open
      size="big"
      title={`报警规则选择`}
      maskClosable={false}
      onCancel={onCancel}
      onOk={() => {
        onOK(selectedRows);
      }}
    >
      <div>
        <CustomFilter
          form={form}
          onFinish={(values: any) => {
            setSearchParams({ ...values });
            setPageOffset(1);
          }}
          onReset={() => {
            setSearchParams(undefined);
            setPageOffset(1);
          }}
          colSpan={8}
        >
          <Form.Item name="ruleName" label="编号/名称">
            <Input placeholder={'请输入编号或名称查询'} />
          </Form.Item>
        </CustomFilter>
        <Table
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys,
            preserveSelectedRowKeys: true,
            onChange: (_, records) => {
              setSelectedRows(records);
            },
          }}
          loading={isLoading}
          sticky
          rowKey="id"
          scroll={{ x: 1500 }}
          columns={columns}
          dataSource={data?.list}
          showSelectedCount
          selectedCount={selectedRows.length}
        />

        <Paging pagingInfo={pagingInfo} />
      </div>
    </Modal>
  );
};

const columns = [
  {
    title: '报警规则编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警规则名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警类型',
    dataIndex: 'type',
    ellipsis: { showTitle: true },
    render: (v: AlarmRuleType) => <EllipsisSpan value={AlarmRuleType.MAP[v]} />,
  },
  {
    title: '报警等级',
    dataIndex: 'alarmType',
    ellipsis: { showTitle: true },
    render: (v: AlarmType) => <EllipsisSpan value={AlarmType.MAP[v]} />,
  },
  {
    title: '报警信息',
    dataIndex: 'alarmName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: AlarmRuleStatus) => <EllipsisSpan value={AlarmRuleStatus.MAP[v]} />,
  },
];

export default AlarmRuleSelectModal;
