import { DetailInfoRes } from '@/api/chargingStationManagement';
import AddressCascader from '@/pages/PvStation/AddressCascader';
import { Form, ShowInput, Tag } from '@maxtropy/components';
import { Col, FormInstance, Row } from 'antd';
import { FC } from 'react';

interface BasicInfoFormProps {
  getLngLat?: (laglat: string, adcode: string) => void;
  form: FormInstance;
  isEdit?: boolean;
  data?: DetailInfoRes;
}

const BasicInfoForm: FC<BasicInfoFormProps> = props => {
  const { form, data, isEdit, getLngLat } = props;

  return (
    <div>
      <Row>
        <Col span={24}>
          <Form.Item label="充电站编号">
            <Tag border="solid" color="#3c89e8">
              {data?.code ?? '--'}
            </Tag>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="name" label="充电站名称">
            <ShowInput />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="ouName" label="运营单元">
            <ShowInput />
          </Form.Item>
        </Col>
        <Col span={24}>
          {/* 所在地区及详细地址 */}
          <AddressCascader
            createStationTime={data?.createStationTime}
            adcode={data?.adcode}
            getLngLat={getLngLat}
            form={form}
            disabled={!isEdit}
          />
        </Col>
      </Row>
    </div>
  );
};

export default BasicInfoForm;
