import { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from 'react';
import {
  BsaDeviceTypeEnum,
  OBJECT_TYPE,
  objectFormatter,
  TopologicalType,
  VALUE_TYPE,
  valueTypeFormatter,
  DataProperty as FormDataProperty,
} from '@/api/type';
import { ColumnType } from 'antd/es/table';
import { cloneDeep, isNil, uniq } from 'lodash-es';
import { v4 } from 'uuid';
import { Button, EllipsisSpan, Table } from '@maxtropy/components';
import { getHandleModeMapping } from '@/pages/Bsa/components/Point/modbusPoint/utils';
import { showFormulaFormItem } from '@/pages/Bsa/components/FormulaFormItem';
import { BatteryType, PointType } from '@/pages/BsaDataCollectionConfigNew/const';
import CollectionDialog from './CollectionDialog';
import { StackPointWithUuid, DataProperty } from './index';
import { Space } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { DataRow } from './DataTable';
import ActionStatusTag from '../components/ActionStatusTag';
import { ColumnStatusEnum } from '../const';

interface Props {
  stackId: number;
  stackPointList: StackPointWithUuid[];
  setStackPointList: (stackPointList: StackPointWithUuid[]) => void;
  topologicalType: TopologicalType;
  batteryType: BatteryType;
  dataPropertiesAll: DataProperty[];
  hasCreated: boolean;
}

export interface CollectionTableRefProps {
  setEditingRow: (editingRow: DataRow) => void;
  setEditingRowIndex: (editingRowIndex: number) => void;
}

const CollectionTable: ForwardRefRenderFunction<CollectionTableRefProps, Props> = (props, ref) => {
  const { stackId, stackPointList, setStackPointList, topologicalType, batteryType, dataPropertiesAll, hasCreated } =
    props;
  const [searchParams] = useSearchParams();
  const showSingleStep = searchParams.get('showSingleStep') === 'true';

  const [editingRow, setEditingRow] = useState<DataRow | undefined>(undefined);
  const [editingRowIndex, setEditingRowIndex] = useState<number>(-1);

  useImperativeHandle(ref, () => {
    return { setEditingRow, setEditingRowIndex };
  });

  const topologicalTypeMapper = {
    [TopologicalType.PCS_Penetrate_BMS]: '读PCS',
    [TopologicalType.BMS_SIMULTANEOUSLY_PCS_AND_BAMS]: '读BAMS',
  };

  const dataColumns: ColumnType<any>[] = [
    {
      title: '序号',
      key: 'index',
      width: 70,
      ellipsis: { showTitle: true },
      render: (_, _record, index) => index + 1,
    },
    {
      title: '数据属性/标识符',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      width: 140,
      render: (_: undefined, record) => (
        <EllipsisSpan value={!!record.hasProperty ? record.dataPropertyName : record.identifier} />
      ),
    },
    {
      title: '单位',
      dataIndex: 'physicalUnitGeneralName',
      ellipsis: { showTitle: true },
      width: 80,
      render: (value: string) => <EllipsisSpan value={value} />,
    },
    {
      title: '采集点类型',
      dataIndex: 'hasProperty',
      ellipsis: { showTitle: true },
      width: 120,
      render: (value: boolean) => <EllipsisSpan value={value ? '建模采集点' : '非建模采集点'} />,
    },
    {
      title: '是否可写',
      dataIndex: 'writable',
      ellipsis: { showTitle: true },
      width: 80,
      render: (v: string) => <EllipsisSpan value={v ? '是' : '否'} />,
    },
    {
      title: 'IP地址',
      width: 120,
      dataIndex: 'ip',
      ellipsis: { showTitle: true },
      render: () => <EllipsisSpan value={topologicalTypeMapper[topologicalType]} />,
    },
    {
      title: '端口',
      width: 120,
      dataIndex: 'port',
      ellipsis: { showTitle: true },
      render: () => <EllipsisSpan value={topologicalTypeMapper[topologicalType]} />,
    },
    {
      title: '单元标识符',
      width: 120,
      dataIndex: '',
      ellipsis: { showTitle: true },
      render: () => {
        return <EllipsisSpan value={topologicalTypeMapper[topologicalType]} />;
      },
    },
    {
      title: '起始地址',
      width: 140,
      dataIndex: 'c',
      ellipsis: { showTitle: true },
      render: (v: number, record) => (
        <EllipsisSpan value={showFormulaFormItem(batteryType as unknown as BsaDeviceTypeEnum, record)} />
      ),
      onCell: record => {
        if (isNil(record.c))
          return {
            style: { border: 'solid 1px red' },
          };
        else return {};
      },
    },
    {
      title: '功能码',
      width: 120,
      dataIndex: 'objectType',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => <EllipsisSpan value={objectFormatter[record.objectType as OBJECT_TYPE]} />,
      onCell: record => {
        if (isNil(record.objectType))
          return {
            style: { border: 'solid 1px red' },
          };
        else return {};
      },
    },
    {
      title: '解码类型',
      width: 140,
      dataIndex: 'valueType',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => <EllipsisSpan value={valueTypeFormatter[record.valueType as VALUE_TYPE]} />,
    },
    {
      title: '处理方式',
      dataIndex: 'handleMode',
      ellipsis: { showTitle: true },
      width: 140,
      render: (_: undefined, record) => {
        const { objectType, length, handleMode } = record;
        let value;
        const formatter = getHandleModeMapping(objectType, length);
        if (formatter) {
          const target = Object.entries(formatter).find(item => item[0] === `${handleMode}`);
          value = target ? target[1] : '--';
        }
        return <EllipsisSpan value={value} />;
      },
    },
  ];

  const actionColumns: ColumnType<any>[] = [
    {
      title: '操作状态',
      width: 80,
      key: 'status',
      fixed: 'right',
      render: (_, record) => <ActionStatusTag columnStatus={record.status}></ActionStatusTag>,
    },
    {
      title: '操作',
      width: 140,
      key: 'action',
      fixed: 'right',
      ellipsis: { showTitle: true },
      render: (_, record, index) => (
        <Space>
          <Button
            type="link"
            onClick={() => {
              setEditingRowIndex(index);
              setEditingRow(record);
            }}
          >
            编辑
          </Button>
          <Button
            type="link"
            onClick={() => {
              const newStackPointList = cloneDeep(stackPointList);
              newStackPointList.forEach(stackPoint => {
                if (stackPoint.stackId === stackId && stackPoint.pointType === PointType.COLLECTION) {
                  stackPoint.templates.splice(index, 1);
                }
              });
              setStackPointList(newStackPointList);
            }}
          >
            删除
          </Button>
        </Space>
      ),
    },
  ];

  const columns = [...dataColumns, ...(!hasCreated || showSingleStep ? actionColumns : [])];
  const currentStackPointList = stackPointList.filter(item => item.stackId === stackId);
  const data = currentStackPointList.find(item => (item.pointType = PointType.COLLECTION))?.templates || [];
  const usedProperties = uniq(
    data
      .filter(item => item.dataPropertyId)
      .filter(item => item.dataPropertyId !== editingRow?.dataPropertyId)
      .map(item => item.dataPropertyId!)
  );
  const usedIdentifier = data
    .filter(item => item.identifier)
    .filter(item => item.identifier !== editingRow?.identifier)
    .map(item => item.identifier!);

  return (
    <>
      <Table rowKey="uuid" scroll={{ x: 'max-content' }} columns={columns} dataSource={data}></Table>

      <CollectionDialog
        row={editingRow as any}
        onCancel={() => {
          setEditingRowIndex(-1);
          setEditingRow(undefined);
        }}
        onOk={(res, shouldContinue) => {
          const newStackPointList = cloneDeep(stackPointList);
          // rowIndex为-1，是新增
          if (editingRowIndex === -1) {
            newStackPointList.forEach(stackPoint => {
              if (stackPoint.stackId === stackId && stackPoint.pointType === PointType.COLLECTION) {
                res.uuid = v4();
                res.status = ColumnStatusEnum.ADD;
                stackPoint.templates.push(res);
              }
            });
          }
          // rowIndex不为-1，是编辑
          else {
            newStackPointList.forEach(stackPoint => {
              if (stackPoint.stackId === stackId && stackPoint.pointType === PointType.COLLECTION) {
                // 没有id，状态设置为新增，否则设置为编辑
                if (!stackPoint.templates[editingRowIndex].id) res.status = ColumnStatusEnum.ADD;
                else res.status = ColumnStatusEnum.EDIT;
                stackPoint.templates[editingRowIndex] = res;
              }
            });
          }
          setStackPointList(newStackPointList);
          // 完成并继续添加
          if (shouldContinue) {
            setEditingRowIndex(-1);
            setEditingRow({} as any);
          }
        }}
        dataPropertiesAll={dataPropertiesAll as FormDataProperty[]}
        usedProperties={usedProperties as number[]}
        usedIdentifier={usedIdentifier}
        bsaDeviceType={batteryType as unknown as BsaDeviceTypeEnum}
      ></CollectionDialog>
    </>
  );
};

export default forwardRef(CollectionTable);
