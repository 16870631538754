import React, { FocusEventHandler, useRef, Fragment } from 'react';
import { InputNumber, Space } from 'antd';
import useControlledState from './useMergedState';
import _ from 'lodash';

interface IpAddressProps {
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: FocusEventHandler<any> | undefined;
  disabled?: boolean;
}

const IpAddress: React.FC<IpAddressProps> = props => {
  const { disabled = false } = props;

  const [value, onChange] = useControlledState('', {
    value: props.value,
    onChange: props.onChange,
  });
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const _onChange = (value: number | null, index: number) => {
    if (value) {
      const _values = values.slice();
      _values.splice(index, 1, value);
      onChange(_values.join('.'));
    }
  };

  const onInput = (value: string, index: number) => {
    if (value === '' || isNaN(Number(value))) return;
    if (index < 3) {
      if (!/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value + '0')) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  let values: (number | undefined)[] = [undefined, undefined, undefined, undefined];
  if (value) {
    const _values = value.split('.').map(item => (item === '' || isNaN(Number(item)) ? undefined : Number(item)));
    _values.length = 4;
    values = _values.fill(undefined, _values.length);
  }

  return (
    <Space size={10}>
      {Array(4)
        .fill(undefined)
        .map((_, index) => (
          <Fragment key={index}>
            <InputNumber
              ref={ref => {
                inputRefs.current[index] = ref;
              }}
              value={values[index]}
              min={0}
              max={255}
              precision={0}
              disabled={disabled}
              onChange={value => _onChange(value, index)}
              onInput={value => onInput(value, index)}
              onBlur={e => {
                setTimeout(() => {
                  if (!inputRefs.current.some(item => item === document.activeElement)) {
                    return props?.onBlur?.(e);
                  }
                });
              }}
            />
            {index !== 3 && <b>.</b>}
          </Fragment>
        ))}
    </Space>
  );
};

export default IpAddress;

export const ipAddressValidator = (_: any, value: string) => {
  if (
    !/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      value
    )
  ) {
    return Promise.reject(new Error('请输入正确的ip地址'));
  }
  return Promise.resolve();
};
