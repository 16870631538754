import { DataNameType, DataType } from '../../api/const';
import { CircuitEnergyConfig, CircuitStatisticsType, StatisticsPartition } from '../../api/circuit';
import { EPricePeakType, EPriceTime } from '../../api/electricityPrice';
import { DatePickerType } from './components/DateSwitch';
import {
  getCOSQChart,
  getEPChart,
  getFChart,
  getLineBoxplotChart,
  getLineCurrentAChart,
  getLineVChart,
  getLoadRateChart,
  getPChart,
  getPdmdChart,
  getPEChart,
  getResidualAChart,
  getUAChart,
  getUVChart,
} from './components/Chart/chartOption';
import { ChartData, Query } from './index';
import { average, max, min, sum } from './utils';

export interface CardData {
  cardName: string;
  key: string | number;
  unit: string;
}

export interface DateBtn {
  btnType: DatePickerType;
  aggrby: StatisticsPartition;
  rangeDays?: number;
}

export interface ParamsOption {
  chartData: ChartData[];
  query: Query;
  ePriceTimes?: EPriceTime[];
  unit?: string;
  circuitEnergyConfig?: CircuitEnergyConfig;
}

export interface CircuitBtn {
  id: DataType;
  btnName: string;
  unit: string;
  name: DataNameType | DataNameType[];
  dateBtn: DateBtn[];
  defaultSelectBtn: DateBtn;
  getChartOption: (value: ParamsOption) => any;
  cardData: CardData[];
  comparable: DataType[];
}

export const calculateEPCardData = ({ chartData = [], ePriceTimes, query }: ParamsOption) => {
  if (chartData.length > 0) {
    const arr = chartData[0].data?.map(item => item.value);
    const ep1Arr: any = [];
    const ep2Arr: any = [];
    const ep3Arr: any = [];
    const ep4Arr: any = [];
    // 按1min计算
    if (query.btnType === DatePickerType.ONEMINUTE) {
      if (ePriceTimes && ePriceTimes.length > 0) {
        let unit = arr.length / (ePriceTimes[ePriceTimes.length - 1].intervalEnd + 1);
        ePriceTimes?.forEach(item => {
          const { intervalStart, intervalEnd, type } = item;
          const temp = arr.slice(unit * intervalStart, unit * (intervalEnd + 1));
          switch (type) {
            case EPricePeakType.SUMMIT:
              ep1Arr.push(temp);
              break;
            case EPricePeakType.PEAK:
              ep2Arr.push(temp);
              break;
            case EPricePeakType.PLAIN:
              ep3Arr.push(temp);
              break;
            case EPricePeakType.VALLEY:
              ep4Arr.push(temp);
              break;
            default:
              break;
          }
        });
      }
    }
    // 按15分钟计算
    if (query.btnType === DatePickerType.ORIGINAL) {
      ePriceTimes?.forEach(item => {
        const { intervalStart, intervalEnd, type } = item;
        const temp = arr.slice(intervalStart * 2, (intervalEnd + 1) * 2);

        switch (type) {
          case EPricePeakType.SUMMIT:
            ep1Arr.push(temp);
            break;
          case EPricePeakType.PEAK:
            ep2Arr.push(temp);
            break;
          case EPricePeakType.PLAIN:
            ep3Arr.push(temp);
            break;
          case EPricePeakType.VALLEY:
            ep4Arr.push(temp);
            break;
          default:
            break;
        }
      });
    }

    // 按30分钟计算
    if (query.btnType === DatePickerType.THIRTYMINUTES) {
      ePriceTimes?.forEach(item => {
        const { intervalStart, intervalEnd, type } = item;
        const temp = arr.slice(intervalStart, intervalEnd + 1);
        switch (type) {
          case EPricePeakType.SUMMIT:
            ep1Arr.push(temp);
            break;
          case EPricePeakType.PEAK:
            ep2Arr.push(temp);
            break;
          case EPricePeakType.PLAIN:
            ep3Arr.push(temp);
            break;
          case EPricePeakType.VALLEY:
            ep4Arr.push(temp);
            break;
          default:
            break;
        }
      });
    }

    return {
      totalEP: sum(arr),
      [EPricePeakType.SUMMIT]: sum(ep1Arr.flat()),
      [EPricePeakType.PEAK]: sum(ep2Arr.flat()),
      [EPricePeakType.PLAIN]: sum(ep3Arr.flat()),
      [EPricePeakType.VALLEY]: sum(ep4Arr.flat()),
    };
  }
};

export const billFactor = (cosq: number) => {
  if (cosq >= 0.95 && cosq <= 1.0) {
    return -0.75;
  } else if (cosq >= 0.9 && cosq < 0.95) {
    return (0.9 - cosq) * 15;
  } else if (cosq >= 0.7 && cosq < 0.9) {
    return (0.9 - cosq) * 50;
  } else if (cosq < 0.7 && cosq >= 0.65) {
    return 10 + (0.7 - cosq) * 100;
  } else {
    return 15 + (0.65 - cosq) * 200;
  }
};

export const getCardData = ({ chartData, ePriceTimes, query, circuitEnergyConfig }: ParamsOption) => {
  const arr = chartData[0]?.data?.map(item => item.value);
  const avgValue = average(arr);

  switch (query.dataType) {
    case DataType.EP:
      return calculateEPCardData({ chartData, ePriceTimes, query });
    case DataType.LOAD_RATE:
      const { loadControlTarget } = circuitEnergyConfig || {};
      const capacity = chartData[0].capacity;
      const times = chartData[0]?.data?.filter(
        i =>
          i.value &&
          loadControlTarget &&
          capacity &&
          Number(((i.value * 100) / capacity).toFixed(2)) > loadControlTarget
      );
      if (query.btnType === DatePickerType.ONEMINUTE) {
        return {
          overTime: times.length,
        };
      } else if (query.btnType === DatePickerType.ORIGINAL) {
        return {
          overTime: times.length * 15,
        };
      } else {
        // 预留
        return {
          overTime: times.length * 15,
        };
      }
    case DataType.COSQ:
      const cosq = avgValue;
      return {
        electricityCoe: billFactor(cosq),
      };
    case DataType.P: {
      // 平均 最大, 最小list
      const listAverage = chartData.find(i => i.type === CircuitStatisticsType.AVERAGE)?.data.map(i => i.value);
      const listMax = chartData.find(i => i.type === CircuitStatisticsType.MAX)?.data.map(i => i.value);
      const listMin = chartData.find(i => i.type === CircuitStatisticsType.MIN)?.data.map(i => i.value);
      const maxValue = max(listMax);
      const minValue = min(listMin);
      const avg = average(listAverage);
      return {
        maxP: maxValue,
        minP: minValue,
        avgP: avg,
        vtp: maxValue - minValue,
        vtpr: ((maxValue - minValue) * 100) / maxValue,
      };
    }
    case DataType.U:
    case DataType.LINE_VOLTAGE:
    case DataType.I:
    case DataType.T:
      const cardData: any = {};
      (Array.isArray(query.name) ? query.name : [query.name]).forEach(a => {
        let result = chartData.find(i => i.id === a)?.data?.map(item => item.value);
        cardData[`max${a}`] = max(result);
        cardData[`min${a}`] = min(result);
      });
      return cardData;
    case DataType.PE:
      if (chartData.length > 0) {
        return {
          [`total${DataNameType.FPE}`]: sum(chartData[0]?.data?.map(item => item.value)),
          [`total${DataNameType.BPE}`]: sum(chartData[1]?.data?.map(item => item.value)),
        };
      }
      return {};
    default:
      return {};
  }
};

const circuitBtns: CircuitBtn[] = [
  {
    id: DataType.EP,
    name: DataNameType.EP,
    btnName: '电量',
    unit: 'kWh',
    dateBtn: [
      { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
      { btnType: DatePickerType.ORIGINAL, aggrby: StatisticsPartition.MINUTE_15 },
      { btnType: DatePickerType.DAY, aggrby: StatisticsPartition.DAY },
      { btnType: DatePickerType.MONTH, aggrby: StatisticsPartition.MONTH },
    ],
    defaultSelectBtn: { btnType: DatePickerType.ORIGINAL, aggrby: StatisticsPartition.MINUTE_15 },
    getChartOption: getEPChart,
    cardData: [
      {
        cardName: '总电量',
        key: 'totalEP',
        unit: 'kWh',
      },
      {
        cardName: '尖电量',
        key: EPricePeakType.SUMMIT,
        unit: 'kWh',
      },
      {
        cardName: '峰电量',
        key: EPricePeakType.PEAK,
        unit: 'kWh',
      },
      {
        cardName: '平电量',
        key: EPricePeakType.PLAIN,
        unit: 'kWh',
      },
      {
        cardName: '谷电量',
        key: EPricePeakType.VALLEY,
        unit: 'kWh',
      },
    ],
    comparable: [DataType.LOAD_RATE, DataType.P, DataType.U, DataType.LINE_VOLTAGE],
  },
  {
    id: DataType.PDMD,
    name: DataNameType.PDMD,
    btnName: '最大需量',
    unit: 'kW',
    dateBtn: [
      { btnType: DatePickerType.DAY, aggrby: StatisticsPartition.DAY },
      { btnType: DatePickerType.MONTH, aggrby: StatisticsPartition.MONTH },
    ],
    defaultSelectBtn: { btnType: DatePickerType.DAY, aggrby: StatisticsPartition.DAY },
    getChartOption: getPdmdChart,
    cardData: [],
    comparable: [DataType.P],
  },
  {
    id: DataType.LOAD_RATE,
    name: DataNameType.LOAD_RATE,
    btnName: '负载率',
    unit: '%',
    dateBtn: [
      { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
      { btnType: DatePickerType.ORIGINAL, aggrby: StatisticsPartition.MINUTE_15 },
    ],
    defaultSelectBtn: { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
    getChartOption: getLoadRateChart,
    cardData: [
      {
        cardName: '超过控制目标时长',
        key: 'overTime',
        unit: 'min',
      },
    ],
    comparable: [DataType.EP, DataType.P, DataType.I, DataType.T],
  },
  {
    id: DataType.COSQ,
    name: DataNameType.COSQ,
    btnName: '功率因数',
    unit: '',
    dateBtn: [
      { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
      { btnType: DatePickerType.ORIGINAL, aggrby: StatisticsPartition.MINUTE_15 },
      { btnType: DatePickerType.DAY, aggrby: StatisticsPartition.DAY },
      { btnType: DatePickerType.MONTH, aggrby: StatisticsPartition.MONTH },
    ],
    defaultSelectBtn: { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
    getChartOption: getCOSQChart,
    cardData: [
      {
        cardName: '电费系数',
        key: 'electricityCoe',
        unit: '%',
      },
    ],
    comparable: [DataType.P],
  },
  {
    id: DataType.P,
    name: DataNameType.P,
    btnName: '有功功率',
    unit: 'kW',
    dateBtn: [
      { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
      { btnType: DatePickerType.ORIGINAL, aggrby: StatisticsPartition.MINUTE_15 },
      { btnType: DatePickerType.DAY, aggrby: StatisticsPartition.DAY },
    ],
    defaultSelectBtn: { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
    getChartOption: getPChart,
    cardData: [
      {
        cardName: '最大功率',
        key: 'maxP',
        unit: 'kW',
      },
      {
        cardName: '最小功率',
        key: 'minP',
        unit: 'kW',
      },
      {
        cardName: '平均功率',
        key: 'avgP',
        unit: 'kW',
      },
      {
        cardName: '峰谷差',
        key: 'vtp',
        unit: 'kW',
      },
      {
        cardName: '峰谷差率',
        key: 'vtpr',
        unit: '%',
      },
    ],
    comparable: [DataType.EP, DataType.LOAD_RATE, DataType.PDMD, DataType.COSQ, DataType.T, DataType.I],
  },
  {
    id: DataType.U,
    name: [DataNameType.UA, DataNameType.UB, DataNameType.UC],
    btnName: '相电压',
    unit: 'V',
    dateBtn: [
      { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
      { btnType: DatePickerType.ORIGINAL, aggrby: StatisticsPartition.MINUTE_15 },
    ],
    defaultSelectBtn: { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
    getChartOption: getLineBoxplotChart,
    cardData: [
      {
        cardName: 'A相最高电压',
        key: `max${DataNameType.UA}`,
        unit: 'V',
      },
      {
        cardName: 'A相最低电压',
        key: `min${DataNameType.UA}`,
        unit: 'V',
      },
      {
        cardName: 'B相最高电压',
        key: `max${DataNameType.UB}`,
        unit: 'V',
      },
      {
        cardName: 'B相最低电压',
        key: `min${DataNameType.UB}`,
        unit: 'V',
      },
      {
        cardName: 'C相最高电压',
        key: `max${DataNameType.UC}`,
        unit: 'V',
      },
      {
        cardName: 'C相最低电压',
        key: `min${DataNameType.UC}`,
        unit: 'V',
      },
    ],
    comparable: [DataType.EP, DataType.LINE_VOLTAGE, DataType.T, DataType.UNBALANCED_V],
  },

  {
    id: DataType.LINE_VOLTAGE,
    name: [DataNameType.UAB, DataNameType.UBC, DataNameType.UCA],
    btnName: '线电压',
    unit: 'V',
    dateBtn: [
      { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
      { btnType: DatePickerType.ORIGINAL, aggrby: StatisticsPartition.MINUTE_15 },
    ],
    defaultSelectBtn: { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
    getChartOption: getLineBoxplotChart,
    cardData: [
      {
        cardName: '最高AB线电压',
        key: `max${DataNameType.UAB}`,
        unit: 'V',
      },
      {
        cardName: '最低AB线电压',
        key: `min${DataNameType.UAB}`,
        unit: 'V',
      },
      {
        cardName: '最高BC线电压',
        key: `max${DataNameType.UBC}`,
        unit: 'V',
      },
      {
        cardName: '最低BC线电压',
        key: `min${DataNameType.UBC}`,
        unit: 'V',
      },
      {
        cardName: '最高CA线电压',
        key: `max${DataNameType.UCA}`,
        unit: 'V',
      },
      {
        cardName: '最低CA线电压',
        key: `min${DataNameType.UCA}`,
        unit: 'V',
      },
    ],
    comparable: [DataType.EP, DataType.U, DataType.T, DataType.I],
  },
  {
    id: DataType.I,
    name: [DataNameType.IA, DataNameType.IB, DataNameType.IC],
    btnName: '电流',
    unit: 'A',
    dateBtn: [
      { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
      { btnType: DatePickerType.ORIGINAL, aggrby: StatisticsPartition.MINUTE_15 },
    ],
    defaultSelectBtn: { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
    getChartOption: getLineBoxplotChart,
    cardData: [
      {
        cardName: 'A相最高电流',
        key: `max${DataNameType.IA}`,
        unit: 'A',
      },
      {
        cardName: 'A相最低电流',
        key: `min${DataNameType.IA}`,
        unit: 'A',
      },
      {
        cardName: 'B相最高电流',
        key: `max${DataNameType.IB}`,
        unit: 'A',
      },
      {
        cardName: 'B相最低电流',
        key: `min${DataNameType.IB}`,
        unit: 'A',
      },
      {
        cardName: 'C相最高电流',
        key: `max${DataNameType.IC}`,
        unit: 'A',
      },
      {
        cardName: 'C相最低电流',
        key: `min${DataNameType.IC}`,
        unit: 'A',
      },
    ],
    comparable: [DataType.LOAD_RATE, DataType.P, DataType.LINE_VOLTAGE, DataType.T, DataType.UNBALANCED_A],
  },
  {
    id: DataType.T,
    name: [DataNameType.TA, DataNameType.TB, DataNameType.TC],
    btnName: '线缆温度',
    unit: '℃',
    dateBtn: [
      { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
      { btnType: DatePickerType.ORIGINAL, aggrby: StatisticsPartition.MINUTE_15 },
    ],
    defaultSelectBtn: { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
    getChartOption: getLineBoxplotChart,
    cardData: [
      {
        cardName: 'A相最高温度',
        key: `max${DataNameType.TA}`,
        unit: '℃',
      },
      {
        cardName: 'A相最低温度',
        key: `min${DataNameType.TA}`,
        unit: '℃',
      },
      {
        cardName: 'B相最高温度',
        key: `max${DataNameType.TB}`,
        unit: '℃',
      },
      {
        cardName: 'B相最低温度',
        key: `min${DataNameType.TB}`,
        unit: '℃',
      },
      {
        cardName: 'C相最高温度',
        key: `max${DataNameType.TC}`,
        unit: '℃',
      },
      {
        cardName: 'C相最低温度',
        key: `min${DataNameType.TC}`,
        unit: '℃',
      },
    ],
    comparable: [DataType.LOAD_RATE, DataType.P, DataType.U, DataType.LINE_VOLTAGE, DataType.I],
  },
  {
    id: DataType.PE,
    name: [DataNameType.FPE, DataNameType.BPE],
    btnName: '有功电能',
    unit: 'kWh',
    dateBtn: [
      { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
      { btnType: DatePickerType.ORIGINAL, aggrby: StatisticsPartition.MINUTE_15 },
      { btnType: DatePickerType.DAY, aggrby: StatisticsPartition.DAY },
      { btnType: DatePickerType.MONTH, aggrby: StatisticsPartition.MONTH },
    ],
    defaultSelectBtn: { btnType: DatePickerType.ORIGINAL, aggrby: StatisticsPartition.MINUTE_15 },
    getChartOption: getPEChart,
    cardData: [
      {
        cardName: '正向有功总电能',
        key: `total${DataNameType.FPE}`,
        unit: 'kWh',
      },
      {
        cardName: '反向有功总电能',
        key: `total${DataNameType.BPE}`,
        unit: 'kWh',
      },
    ],
    comparable: [],
  },
  {
    id: DataType.F,
    btnName: '频率',
    name: DataNameType.F,
    unit: 'Hz',
    getChartOption: getFChart,
    dateBtn: [
      { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
      { btnType: DatePickerType.ORIGINAL, aggrby: StatisticsPartition.MINUTE_15 },
    ],
    defaultSelectBtn: { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
    cardData: [],
    comparable: [],
  },
  {
    id: DataType.UNBALANCED_V,
    name: DataNameType.UNBALANCED_V,
    btnName: '三相电压不平衡度',
    unit: '%',
    dateBtn: [
      { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
      { btnType: DatePickerType.ORIGINAL, aggrby: StatisticsPartition.MINUTE_15 },
    ],
    defaultSelectBtn: { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
    getChartOption: getUVChart,
    cardData: [],
    comparable: [DataType.U],
  },
  {
    id: DataType.UNBALANCED_A,
    name: DataNameType.UNBALANCED_A,
    btnName: '三相电流不平衡度',
    unit: '%',
    dateBtn: [
      { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
      { btnType: DatePickerType.ORIGINAL, aggrby: StatisticsPartition.MINUTE_15 },
    ],
    defaultSelectBtn: { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
    getChartOption: getUAChart,
    cardData: [],
    comparable: [DataType.I],
  },
  {
    id: DataType.RESIDUAL_A,
    name: DataNameType.RESIDUAL_A,
    btnName: '剩余电流',
    unit: 'A',
    dateBtn: [
      { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
      { btnType: DatePickerType.ORIGINAL, aggrby: StatisticsPartition.MINUTE_15 },
    ],
    defaultSelectBtn: { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
    getChartOption: getResidualAChart,
    cardData: [],
    comparable: [],
  },
  {
    id: DataType.CURRENT_H,
    name: [DataNameType.CH_A, DataNameType.CH_B, DataNameType.CH_C],
    btnName: '电流谐波',
    unit: '%',
    dateBtn: [
      { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
      { btnType: DatePickerType.ORIGINAL, aggrby: StatisticsPartition.MINUTE_15 },
    ],
    defaultSelectBtn: { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
    getChartOption: getLineCurrentAChart,
    cardData: [],
    comparable: [],
  },
  {
    id: DataType.VOLTAGE_H,
    name: [DataNameType.VH_A, DataNameType.VH_B, DataNameType.VH_C],
    btnName: '电压谐波',
    unit: '%',
    dateBtn: [
      { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
      { btnType: DatePickerType.ORIGINAL, aggrby: StatisticsPartition.MINUTE_15 },
    ],
    defaultSelectBtn: { btnType: DatePickerType.ONEMINUTE, aggrby: StatisticsPartition.MINUTE_1 },
    getChartOption: getLineVChart,
    cardData: [],
    comparable: [],
  },
];

export default circuitBtns;
