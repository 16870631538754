import { PermissionsType } from '@/common/permissionsConst';
import EnergyManage from '@/pages/Energy';
const routes = [
  {
    path: '/dmes/circuit/energyConfig',
    permission: PermissionsType.P_ENERGY_SAVING,
    element: <EnergyManage />,
  },
];

export default routes;
