import { FC } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@maxtropy/components';
import { DatePickerProps } from 'antd/es/date-picker';

const { RangePicker } = DatePicker;

interface Props {
  timeRange: [Dayjs, Dayjs];
  setTimeRange: (timeRange: [Dayjs, Dayjs]) => void;
  type?: 'date' | 'month';
  maxRange: number;
}

const DateRangePicker: FC<Props> = props => {
  const { timeRange, setTimeRange, type = 'date', maxRange } = props;

  const disabledDate: DatePickerProps['disabledDate'] = (current, { from }) => {
    if (current > dayjs().endOf('day')) return true;

    if (from) {
      if (type === 'month') {
        return Math.abs(current.startOf('month').diff(from, 'months')) > maxRange;
      } else {
        return Math.abs(current.startOf('day').diff(from, 'days')) > maxRange;
      }
    }
    return false;
  };

  return (
    <RangePicker
      allowClear={false}
      picker={type}
      disabledDate={disabledDate}
      value={timeRange}
      onChange={val => {
        setTimeRange(val as [Dayjs, Dayjs]);
      }}
    ></RangePicker>
  );
};

export default DateRangePicker;
