import React, { useEffect, useMemo, useRef, useState } from 'react';
import CardBox from '../../../CardBox';
import styles from './index.module.scss';
import { EllipsisSpan, Table, Tabs } from '@maxtropy/components';
import { useRequest } from 'ahooks';
import {
  apiV2PvNoticeboardDispersionRateCurvePost,
  apiV2PvNoticeboardDispersionRateDevicePost,
} from '@maxtropy/dmes-apis-v2';
import { DiscreteType } from '@/pages/PvDemonstrationBase/const';
import classnames from 'classnames/bind';
import ReactEcharts from 'echarts-for-react';
import { getChartOption } from './charts';

const cx = classnames.bind(styles);

const DiscreteAnalysis = () => {
  const [activeKey, setActiveKey] = useState<string>('0');
  const timeRef = useRef<number>();

  const columns = [
    {
      title: '',
      dataIndex: 'title',
      ellipsis: { showTitle: true },
      width: 80,
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: (
        <>
          <div className={cx('circle', 'abnormal_circle')}></div>
          <span className={cx('word', 'abnormal_word')}>异常</span>
        </>
      ),
      dataIndex: 'abnormalQuantity',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan className={cx('word', 'abnormal_word')} value={v} />,
    },
    {
      title: (
        <>
          <div className={cx('circle', 'poor_circle')}></div>
          <span className={cx('word', 'poor_word')}>较差</span>
        </>
      ),
      dataIndex: 'poorQuantity',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan className={cx('word', 'poor_word')} value={v} />,
    },
    {
      title: (
        <>
          <div className={cx('circle', 'general_circle')}></div>
          <span className={cx('word', 'general_word')}>一般</span>
        </>
      ),
      dataIndex: 'generalQuantity	',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan className={cx('word', 'general_word')} value={v} />,
    },
    {
      title: (
        <>
          <div className={cx('circle', 'excellent_circle')}></div>
          <span className={cx('word', 'excellent_word')}>优秀</span>
        </>
      ),
      dataIndex: 'excellentQuantity',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan className={cx('word', 'excellent_word')} value={v} />,
    },
    {
      title: (
        <>
          <div className={cx('circle', 'noData_circle')}></div>
          <span className={cx('word', 'noData_word')}>无数据</span>
        </>
      ),
      dataIndex: 'noDataQuantity',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan className={cx('word', 'noData_word')} value={v} />,
    },
  ];

  const tabList = [
    {
      label: '逆变器',
      key: '0',
    },
    {
      label: '组串(电流)',
      key: '1',
    },
    {
      label: '组串(温度)',
      key: '2',
    },
  ];

  const { data: list, loading } = useRequest(() =>
    apiV2PvNoticeboardDispersionRateDevicePost({}).then(res => {
      return [
        { ...res.list?.filter(i => i.type === DiscreteType.INVERTER)?.[0], title: '逆变器' },
        { ...res.list?.filter(i => i.type === DiscreteType.CURRENT)?.[0], title: '组串(电流)' },
        { ...res.list?.filter(i => i.type === DiscreteType.TEMPERATURE)?.[0], title: '组串(温度)' },
      ];
    })
  );

  // 轮播
  useEffect(() => {
    if ((tabList ?? []).length && activeKey) {
      const currentActiveIndex = tabList?.findIndex(i => i.key === activeKey);
      if ((currentActiveIndex ?? 0) + 1 === (tabList ?? []).length) {
        timeRef.current = window.setTimeout(() => {
          setActiveKey(tabList?.[0]?.key?.toString());
        }, 10000);
      } else {
        timeRef.current = window.setTimeout(() => {
          setActiveKey(tabList?.[(currentActiveIndex ?? 0) + 1]?.key?.toString());
        }, 10000);
      }
    }
    return () => clearTimeout(timeRef.current);
  }, [activeKey]);

  // 离散率曲线(过去30天)
  const { data: chartsData } = useRequest(
    () => {
      return apiV2PvNoticeboardDispersionRateCurvePost({
        type: activeKey,
      });
    },
    {
      ready: !!activeKey,
      refreshDeps: [activeKey],
    }
  );

  const chartRef = useRef<ReactEcharts>();
  // 查询图表
  const chartOption = useMemo(() => getChartOption(chartsData), [chartsData]);

  const handleResize = () => {
    chartRef.current?.getEchartsInstance().resize();
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <CardBox
      title={
        <>
          <span className={styles.title}>离散率分析</span>
        </>
      }
      extraContent={
        <div className={styles.bottomStyles}>
          <Tabs
            getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
            className={styles.tabsStyle}
            animated={true}
            activeKey={activeKey as string}
            onChange={v => {
              setActiveKey(v);
            }}
            items={tabList ?? []}
          />
        </div>
      }
      className={styles.cardBoxSty}
    >
      <div className={styles.table}>
        <Table rowKey="title" style={{ height: '100%' }} loading={loading} dataSource={list ?? []} columns={columns} />
      </div>
      <div className={styles.chart_box}>
        <ReactEcharts
          style={{ height: '100%' }}
          ref={d => {
            if (d) {
              chartRef.current = d;
            }
          }}
          option={chartOption}
          notMerge
          lazyUpdate={false}
        />
      </div>
    </CardBox>
  );
};

export default DiscreteAnalysis;
