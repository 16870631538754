import request from '@/utils/request';
// import dayjs from 'dayjs';
import qs from 'qs';

import { DataGrain } from './bsa-overview';

export const circuitLineLossKeys = {
  dataAll: 'circuitLineLossDataAll',
  data: (circuitId: number, filters: CircuitLineLossDataParams & { timeResolution: DataGrain }) => [
    circuitLineLossKeys.dataAll,
    circuitId,
    filters,
  ],
};

export interface CircuitLineLossDataParams {
  from: number;
  to: number;
}

export enum CircuitLineLossDataType {
  HOUR = 1,
  DAY,
  MONTH,
}

export interface CircuitLineLossDataQuery extends CircuitLineLossDataParams {
  dataType: CircuitLineLossDataType;
}

export enum CircuitLineLossDataReason {
  VALID,
  INCOMPLETE_CONFIGURATION,
  INCOMPLETE_DATA,
  NO_DATA,
}

export interface CircuitLineLossData {
  time: string;
  reason: CircuitLineLossDataReason;
  loss: number | null;
  lossRate: number | null;
  electricityConsume: number | null;
  electricityProduce: number | null;
}

export interface CircuitLineLossExportQuery {
  circuitId: number;
  exportDate: number;
  timeLevel: CircuitLineLossDataType;
}

export const getCircuitLineLossData = (
  circuitId: number,
  params: CircuitLineLossDataParams & { timeResolution: DataGrain }
) => request<CircuitLineLossData[]>({ url: `/api/circuit-line-loss/data/${circuitId}`, params });

export const exportData = (query: CircuitLineLossExportQuery) =>
  window.open(`/api/circuit-line-loss/export?${qs.stringify(query, { indices: false })}`, '__blank');

// export const aggregateBy = (rawData: CircuitLineLossData[], dataType: CircuitLineLossDataType) => {
//   if (dataType === CircuitLineLossDataType.HOUR) {
//     return rawData;
//   } else {
//     const isEqual = (left: string, right: string) =>
//       dayjs(left).isSame(right, dataType === CircuitLineLossDataType.DAY ? 'day' : 'month');
//     return rawData
//       .reduce((acc, curr) => {
//         const currentGroup = acc[acc.length - 1];
//         if (!currentGroup || !isEqual(curr.time, currentGroup[0].time)) {
//           acc.push([curr]);
//         } else {
//           currentGroup.push(curr);
//         }
//         return acc;
//       }, [] as CircuitLineLossData[][])
//       .map(group => {
//         const time = group[0].time;
//         let loss = null;
//         let lossRate = null;
//         let electricityConsume = null;
//         let electricityProduce = null;

//         // 全部是配置不完整或数据不完整或没有数据
//         for (const reason of [
//           CircuitLineLossDataReason.INCOMPLETE_CONFIGURATION,
//           CircuitLineLossDataReason.INCOMPLETE_DATA,
//           CircuitLineLossDataReason.NO_DATA,
//         ]) {
//           if (group.every(item => item.reason === reason)) {
//             return {
//               time,
//               loss,
//               lossRate,
//               electricityConsume,
//               electricityProduce,
//               reason,
//             };
//           }
//         }

//         const validItems = group.filter(item => item.reason === CircuitLineLossDataReason.VALID);
//         if (!validItems.length) {
//           // 没有正常的，视为数据不完整
//           return {
//             time,
//             loss,
//             lossRate,
//             electricityConsume,
//             electricityProduce,
//             reason: CircuitLineLossDataReason.INCOMPLETE_DATA,
//           };
//         }

//         electricityProduce = validItems.reduce((acc, curr) => acc + (curr.electricityProduce ?? 0), 0);
//         electricityConsume = validItems.reduce((acc, curr) => acc + (curr.electricityConsume ?? 0), 0);
//         loss = validItems.reduce((acc, curr) => acc + (curr.loss ?? 0), 0);
//         lossRate = electricityProduce > 0 ? loss / electricityProduce : null;

//         return {
//           time,
//           loss,
//           lossRate,
//           electricityConsume,
//           electricityProduce,
//           reason: CircuitLineLossDataReason.VALID,
//         };
//       });
//   }
// };
