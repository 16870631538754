import React from 'react';
import { Space } from 'antd';
import { DataType, E_PRICE_PEAK_TYPE_LABEL_MAP } from '../../../../api/const';
import { EPricePeakType, E_PRICE_PEAK_TYPE_COLORS_MAP, EPriceTime } from '../../../../api/electricityPrice';
import { Query, ChartData } from '../../index';
import { notEmptyForChart } from '../../utils';
import styles from './index.module.scss';
import { DatePickerType } from '../DateSwitch';
import { CircuitEnergyConfig } from '../../../../api/circuit';
import { isNil } from 'lodash-es';

export interface EPriceTimesLegendProps {
  chartData?: ChartData[];
  query?: Query;
  ePriceTimes?: EPriceTime[];
  circuitEnergyConfig?: CircuitEnergyConfig;
}

const EPriceTimesSpan: React.FC<EPriceTimesLegendProps> = props => {
  const { query, ePriceTimes } = props;
  if (
    (query?.btnType === DatePickerType.ORIGINAL ||
      query?.btnType === DatePickerType.THIRTYMINUTES ||
      query?.btnType === DatePickerType.ONEMINUTE) &&
    Array.isArray(ePriceTimes) &&
    ePriceTimes.length > 0
  ) {
    return (
      <Space className={styles.block}>
        {Object.entries(E_PRICE_PEAK_TYPE_LABEL_MAP).map(([value, key]) => {
          const color = `${E_PRICE_PEAK_TYPE_COLORS_MAP[+value as EPricePeakType]}40`;
          return (
            <span key={key} className={styles.item}>
              <span
                style={{
                  background: color,
                }}
                className={styles.pipe}
              />
              {key}时段
            </span>
          );
        })}
      </Space>
    );
  } else {
    return null;
  }
};

const EPriceTimesLegend: React.FC<EPriceTimesLegendProps> = props => {
  const { chartData, query, ePriceTimes, circuitEnergyConfig } = props;
  const { needControlTarget, loadControlTarget, currentHarmonicUpper, voltageHarmonicUpper } =
    circuitEnergyConfig || {};
  let filteredChartData = chartData?.filter(i => !!i);
  if (filteredChartData && notEmptyForChart(filteredChartData)) {
    return (
      <div>
        {query?.dataType === DataType.PDMD && !isNil(needControlTarget) && (
          <span className={styles.txt}>{`需量控制目标：${needControlTarget}kW `}</span>
        )}
        {query?.dataType === DataType.LOAD_RATE && !isNil(loadControlTarget) && (
          <span className={styles.txt}>{`负载控制目标：${loadControlTarget}% `}</span>
        )}
        {query?.dataType === DataType.CURRENT_H && !isNil(currentHarmonicUpper) && (
          <span className={styles.txt}>{`电流谐波控制目标：${currentHarmonicUpper}% `}</span>
        )}
        {query?.dataType === DataType.VOLTAGE_H && !isNil(voltageHarmonicUpper) && (
          <span className={styles.txt}>{`电压谐波控制目标：${voltageHarmonicUpper}% `}</span>
        )}
        <EPriceTimesSpan query={query} ePriceTimes={ePriceTimes} />
      </div>
    );
  } else {
    return null;
  }
};

export default EPriceTimesLegend;
