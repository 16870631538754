import styles from './index.module.scss';
import packIcon from '../../icons/icon-batteryPack@2x.png';

import { Empty, Table } from '@maxtropy/components';

import { ConfigProvider, Space, Empty as AntDEmpty } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { rowType, RowTypeDisplay } from '../PCS';
import { DataPropertyValues } from '../../type';
import { DataPropertyValuesItem, useGetTableColumns } from './utils';
import { ShareContext } from '../..';
import { useContext, useMemo } from 'react';

interface Iprops {
  tableList?: DataPropertyValues;
  type: number; // 1告警 2状态;
  remoteOp?: (record: DataPropertyValuesItem) => void;
  setOp?: (v: number) => void;
}
const YxTable = ({ tableList, type, remoteOp, setOp }: Iprops) => {
  const { hasConfigBtn } = useContext(ShareContext);
  const columns = useGetTableColumns({ tableList, remoteOp, type });
  const hasData = useMemo(() => tableList && tableList?.length > 0, [tableList]);
  return (
    <div className={styles.table_item}>
      <div className={styles.card_title}>
        <Space size={8}>
          {RowTypeDisplay[type]}
          {hasConfigBtn && (
            <Space
              size={5}
              style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
              onClick={() => {
                setOp?.(type);
              }}
            >
              <SettingOutlined />
              配置
            </Space>
          )}
        </Space>
      </div>
      <ConfigProvider
        renderEmpty={() => (
          <Empty image={AntDEmpty.PRESENTED_IMAGE_SIMPLE} description={<>暂无配置{RowTypeDisplay[type]}</>}></Empty>
        )}
      >
        <Table
          columns={columns}
          rowKey="datapropertyId"
          dataSource={tableList}
          scroll={{ y: hasData ? 245 : undefined, x: hasData && type === rowType.alarm ? 'max-content' : undefined }}
        />
      </ConfigProvider>
    </div>
  );
};

export default YxTable;
