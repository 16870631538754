import {
  getEnergyAssetInvestmentRecoverySituationInfo,
  InvestmentRecoverySituationInfoRes,
  OuList,
} from '@/api/energyAssetOverview';
import { Col, Progress, Row, Spin } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import HeadTitle from '../HeadTitle/HeadTitle';
import ValueLineTitle from '../ValueLineTitle';
import styles from './index.module.scss';
import moneyImg from '../../imgs/money.png';
import accountbookFilledImg from '../../imgs/accountbook-filled.png';
import { formateValuesToFix2 } from '../../utils';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';

export interface IInvestmentRecovery {
  ouInfo: OuList;
  height: string | undefined;
}

const InvestmentRecovery: FC<IInvestmentRecovery> = props => {
  const { ouInfo, height } = props;
  const [investmentRecoverySituationInfo, setInvestmentRecoverySituationInfo] =
    useState<InvestmentRecoverySituationInfoRes>();
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    if (ouInfo.id) {
      setDataLoading(true);
      // 投资回收信息
      getEnergyAssetInvestmentRecoverySituationInfo({ ouId: ouInfo.id }).then(res => {
        setInvestmentRecoverySituationInfo(res);
        setDataLoading(false);
      });
    }
  }, [ouInfo]);

  const hasOnehundredPercent = useMemo(() => {
    if (investmentRecoverySituationInfo && investmentRecoverySituationInfo.recycledPercentage) {
      return investmentRecoverySituationInfo.recycledPercentage * 100 >= 100 ? true : false;
    }
  }, [investmentRecoverySituationInfo]);

  return (
    <div
      className={styles.ColSty}
      style={{
        height: height,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <HeadTitle title="投资收回情况" />
      <Spin spinning={dataLoading}>
        <Row>
          <Col span={12}>
            <ValueLineTitle value={investmentRecoverySituationInfo?.totalInvestment} title={'总投资（万元）'} />
          </Col>
          <Col span={12}>
            <ValueLineTitle value={investmentRecoverySituationInfo?.recycled} title={'已收回（万元）'} />
          </Col>
        </Row>
        <Row style={{ margin: '24px 10px' }}>
          <Col span={24}>
            <Progress
              percent={
                !isNil(investmentRecoverySituationInfo?.recycledPercentage)
                  ? investmentRecoverySituationInfo!.recycledPercentage * 100 >= 100
                    ? 100
                    : Number((investmentRecoverySituationInfo!.recycledPercentage * 100).toFixed(2))
                  : 0
              }
              format={percent => (percent === 100 ? '100%' : `${percent}%`)}
              size="default"
              strokeColor={'#51D9EE'}
              trailColor={'#FFFFFF20'}
            />
          </Col>
        </Row>

        {hasOnehundredPercent && (
          <Row gutter={8}>
            <Col span={12}>
              <div className={styles.cardDivSty}>
                <div className={styles.cardDivStyTitle}>
                  <div>收回投资后盈利</div>
                  <div>{formateValuesToFix2(investmentRecoverySituationInfo?.profit)}</div>
                </div>
                <div className={styles.cardDivStyImg}>
                  <img className={styles.ImgSty} src={moneyImg} alt="" />
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className={styles.cardDivSty}>
                <div className={styles.cardDivStyTitle}>
                  <div>完成投资收回日期</div>
                  <div>
                    {investmentRecoverySituationInfo?.recoveryDate
                      ? dayjs(investmentRecoverySituationInfo?.recoveryDate).format('YYYY-MM-DD')
                      : '--'}
                  </div>
                </div>
                <div className={styles.cardDivStyImg}>
                  <img className={styles.ImgSty} src={accountbookFilledImg} alt="" />
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Spin>
    </div>
  );
};

export default InvestmentRecovery;
