import React from 'react';
const charge = () => (
  <svg
    width="1em"
    height="1em"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>放电</title>
    <g id="页面-1" stroke="none" stroke-width="1" fill="currentColor">
      <g
        id="储能整体运维看板-卡片模式"
        transform="translate(-536.000000, -372.000000)"
        fill="currentColor"
        fill-rule="nonzero"
      >
        <g id="卡片" transform="translate(50.000000, 360.000000)">
          <g id="看板卡片备份" transform="translate(338.000000, 0.000000)">
            <g id="充电" transform="translate(144.000000, 7.000000)">
              <g id="放电" transform="translate(4.000000, 5.000000)">
                <rect id="矩形" opacity="0" x="0" y="0" width="13" height="13"></rect>
                <path
                  d="M10.4967659,4.48495432 L8.06482324,4.48495432 L10.2516949,1.17206383 C10.2969558,1.10191473 10.2561106,1 10.1821478,1 L5.94860264,1 C5.91769279,1 5.88788687,1.01985352 5.87243195,1.05294272 L3.01216717,6.97590914 C2.97794555,7.04605824 3.01989463,7.13473729 3.08833786,7.13473729 L5.01357977,7.13473729 L4.02667251,11.8678162 C4.00569797,11.9710545 4.10946674,12.0438507 4.17349428,11.9697309 L10.5574816,4.66628313 C10.6148856,4.6014283 10.5762483,4.48495432 10.4967659,4.48495432 Z"
                  id="路径"
                ></path>
                <path
                  d="M9.10526316,9 L9.89473684,9 C9.96491228,9 10,9.01111111 10,9.03333333 L10,11.9666667 C10,11.9888889 9.96491228,12 9.89473684,12 L9.10526316,12 C9.03508772,12 9,11.9888889 9,11.9666667 L9,9.03333333 C9,9.01111111 9.03508772,9 9.10526316,9 Z M8.03488372,10 L10.9651163,10 C10.9883721,10 11,10.0350877 11,10.1052632 L11,10.8947368 C11,10.9649123 10.9883721,11 10.9651163,11 L8.03488372,11 C8.01162791,11 8,10.9649123 8,10.8947368 L8,10.1052632 C8,10.0350877 8.01162791,10 8.03488372,10 Z"
                  id="形状"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default charge;
