import { isNil } from 'lodash-es';

export const formateValuesToFix2 = (value?: number) => {
  return !isNil(value) ? Number(value.toFixed(2)).toLocaleString() : '--';
};

export function goFullscreen(element: HTMLDivElement) {
  if (document.fullscreenElement) {
    return Promise.reject(new Error('全屏模式被禁用'));
  }
  let result = null;
  if (element.requestFullscreen) {
    result = element.requestFullscreen();
  }
  return result || Promise.reject(new Error('不支持全屏'));
}
export function cancelFullscreen() {
  document.exitFullscreen?.();
}

// 万元转换元
export const formateValuesToWanYuan = (value?: number) => {
  return !isNil(value)
    ? Number(value / 10000)
        .toFixed(2)
        .toLocaleString()
    : '--';
};

export type NumberLikeValue = string | number;
/**
 * 判断参数是否为有效数字
 */
const validateNumbers = (fn: (...values: number[]) => number, ...args: NumberLikeValue[]) => {
  if (!args.every(arg => isNumber(arg))) return NaN;
  return fn(...args.map(item => +item));
};

const divide = (a: NumberLikeValue, b: NumberLikeValue) => validateNumbers((a, b) => (b !== 0 ? a / b : NaN), a, b);
/**
 * 数组求和
 */
export const sum = (set: any) => {
  const numbers = filterNumber(set);
  return numbers.length ? numbers.reduce((acc, curr) => acc + curr, 0) : NaN;
};
/**
 * 数组求平均数
 */
export const average = (set: any) => {
  const numbers = filterNumber(set);
  return numbers.length ? divide(sum(numbers), numbers.length) : NaN;
};

/**
 * 过滤掉非有效数字
 */
export const filterNumber = (dataset: any): number[] =>
  Array.isArray(dataset)
    ? dataset
        .filter((value: any) => !isNil(value))
        .filter((value: NumberLikeValue) => isNumber(value))
        .map(item => +item)
    : [];

/**
 * 是否是有效的数字
 */
export const isNumber = (value: NumberLikeValue) => !isNaN(+value) && isFinite(+value);
