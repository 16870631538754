import React, { useEffect, useReducer, useState } from 'react';
import { App, Divider, RadioChangeEvent, Space, Spin } from 'antd';
import { isNil } from 'lodash-es';
import dayjs from 'dayjs';
import { DataNameType, DataType, SelectCircuit } from '../../../../api/const';
import {
  CircuitDataType,
  CircuitStatisticsResponse,
  getCircuitStatistics,
  StatisticsPartition,
} from '../../../../api/circuit';
import DateSwitch, { DatePickerType } from '../DateSwitch';
import CustomSelect from './CustomSelect';
import Chart from '../Chart';
import styles from './index.module.scss';
import circuitBtns from '../../config';
import { ChartData, Query } from '../../index';
import { getDefaultTime } from '@/pages/Statistics/utils';
import { getChartOption } from '@/pages/Statistics/components/CompareModal/chartOption';
import { Button, Empty, Form, Modal, Radio } from '@maxtropy/components';
import { useForm } from 'antd/es/form/Form';
import HistoryChart from '../HistoryChart';
import HistoryCompare, { dayDefaltFromTo, todayDefaltFromTo } from './HistoryCompare';
import { getHisChartOption } from './chartOptionsHis';
import { circuitBtnsByHistory } from './config';
// import { getHisChartOption } from './chartOptionsHis';
// import { getChartOptionByHistory } from '../HistoryChart/chartOptionsHis';

export interface DefaultValue {
  name?: string;
  circuitId?: number;
  circuitName?: string;
  cabinetId?: number;
  measurementId?: number;
  dataType?: DataType;
  type?: number; // 此处的type就是measurementId
}

interface CompareModalProps {
  tabData?: CircuitDataType[];
  defaultValue?: DefaultValue;
  visible: boolean;
  onCancel: () => void;
}

// 对比类型
export enum CompareType {
  OBJECT = '1',
  HISTORY = '2',
}

export const CompareTypeDisplay = {
  [CompareType.OBJECT]: '对象对比',
  [CompareType.HISTORY]: '历史对比',
};

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const getAggrBy = (btnType: DatePickerType, dataType: DataType) => {
  let aggrby = StatisticsPartition.MINUTE_1;
  if (btnType === DatePickerType.MONTH) {
    aggrby = StatisticsPartition.MONTH;
  } else if (btnType === DatePickerType.ORIGINAL) {
    aggrby = StatisticsPartition.MINUTE_15;
  } else if (btnType === DatePickerType.THIRTYMINUTES) {
    aggrby = StatisticsPartition.MINUTE_30;
  } else if (btnType === DatePickerType.DAY) {
    aggrby = StatisticsPartition.DAY;
  } else {
    const defaultSelectBtn = circuitBtns.find(item => item.id === dataType)?.defaultSelectBtn;
    if (defaultSelectBtn?.btnType === DatePickerType.ONEMINUTE) {
      aggrby = defaultSelectBtn.aggrby;
    }
  }
  return aggrby;
};
const getBtnGroup = (leftDataType: DataType, rightDataType: DataType) => {
  const left = circuitBtns.find(item => item.id === leftDataType)!.dateBtn;
  const right = circuitBtns.find(item => item.id === rightDataType)!.dateBtn;
  return left.filter(item => right.some(o => o.btnType === item.btnType));
};

const CompareModal: React.FC<CompareModalProps> = props => {
  const { visible, onCancel, tabData, defaultValue } = props;

  const [xx, forceUpdate] = useReducer(x => x + 1, 0);
  const [isReset, setIsReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const { message } = App.useApp();
  const [chartData, setChartData] =
    useState<{ dataType: DataType; aggrby: StatisticsPartition; data: ChartData[]; btnType: DatePickerType }[]>();
  const [btnType, setBtnType] = useState(DatePickerType.ORIGINAL || DatePickerType.THIRTYMINUTES);
  const [circuits, setCircuits] = useState<Array<SelectCircuit | undefined>>([]);

  const [dateRange, setDateRange] = useState<{ tsStart: number; tsEnd: number }>(
    getDefaultTime(StatisticsPartition.MINUTE_15 || StatisticsPartition.MINUTE_30, dayjs())
  );

  const [compareType, setCompareType] = useState<CompareType>(CompareType.OBJECT);
  const [historyForm] = useForm();
  const [hisChartsHeight, setHisChartsHeight] = useState<number>(400);

  useEffect(() => {
    if (
      circuits.filter(item => !isNil(item?.dataType)).length === 2 &&
      !(circuits[0]?.id === circuits[1]?.id && circuits[0]?.dataType === circuits[1]?.dataType)
    ) {
      const btnGroup = getBtnGroup(circuits[0]?.dataType!, circuits[1]?.dataType!);
      setBtnType(
        btnGroup.find(item => item.btnType === DatePickerType.THIRTYMINUTES || item.btnType === DatePickerType.ORIGINAL)
          ? btnGroup.find(
              item => item.btnType === DatePickerType.THIRTYMINUTES || item.btnType === DatePickerType.ORIGINAL
            )!.btnType
          : btnGroup[0].btnType
      );
      setDateRange(getDefaultTime(btnGroup[0].aggrby, dayjs()));
    }
  }, [circuits]);

  useEffect(() => {
    if (defaultValue && defaultValue.circuitId && defaultValue.name) {
      setCircuits([
        {
          id: defaultValue?.circuitId,
          name: defaultValue?.name,
          dataType: defaultValue?.dataType,
        },
      ]);
    }
  }, [defaultValue]);

  const formatChartData = (response: CircuitStatisticsResponse[], circuit?: SelectCircuit) => {
    console.log(333, circuit);
    if (circuit) {
      return response.map((item, index) => ({
        ...item,
        capacity: circuit.capacity,
        circuitName: circuits?.find(item => item?.id === circuit.id)?.name,
        id: circuit.dataType === DataType.LOAD_RATE ? DataNameType.LOAD_RATE : item.id,
        data: item.data.map(item => ({
          ...item,
          value: !isNil(item.value)
            ? index === 1 && circuit.dataType === DataType.PE
              ? -item.value.toFixed(2)
              : +item.value.toFixed(2)
            : null,
        })),
      }));
    }
  };

  const formatHisChartData = (
    response: CircuitStatisticsResponse[],
    index: number,
    values: any,
    currentCircuitCapacity?: number | null
  ) => {
    return response.map((item, i) => ({
      ...item,
      capacity: currentCircuitCapacity,
      circuitName: `时间${index + 1}`,
      id: values.point === DataType.LOAD_RATE ? DataNameType.LOAD_RATE : item.id,
      data: item.data.map(item => ({
        ...item,
        value: !isNil(item.value)
          ? i === 1 && values.point === DataType.PE
            ? -item.value.toFixed(2)
            : +item.value.toFixed(2)
          : null,
      })),
    }));
  };

  useEffect(() => {
    const length = circuits.filter(item => !isNil(item?.dataType)).length;
    if (length >= 2) {
      setLoading(true);
      const promises = circuits.map(i => {
        const aggrby = getAggrBy(btnType, i!.dataType!);
        return getCircuitStatistics({
          circuitId: i?.id!,
          dataType: i!.dataType!,
          timeResolution: aggrby,
          from: dateRange.tsStart,
          to: dateRange.tsEnd,
        });
      });
      Promise.all(promises)
        .then(datas => {
          setLoading(false);
          const dataArr = datas.map((data, index) => ({
            data: formatChartData(data, circuits[index]),
            aggrby: getAggrBy(btnType, circuits[index]?.dataType!),
            dataType: circuits[index]?.dataType!,
          }));
          setChartData(dataArr as any);
        })
        .finally(() => setLoading(false));
    } else {
      setChartData(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xx]);

  const validate = () => {
    const length = circuits.filter(item => !!item?.id).length;
    if (length < 2) {
      message.error('请选择回路');
      return false;
    }
    if (circuits.some(item => isNil(item?.dataType))) {
      message.error('请选择监控指标');
      return false;
    }
    if (circuits[0]?.id === circuits[1]?.id && circuits[0]?.dataType === circuits[1]?.dataType) {
      message.error('不能选择相同回路下的相同监控指标');
      return false;
    }
    return true;
  };

  const onChangeData = (value: Omit<Query, 'dataType' | 'name'>) => {
    const { tsStart, tsEnd, btnType } = value;
    setBtnType(btnType);
    setDateRange({
      tsStart,
      tsEnd,
    });
    forceUpdate();
  };

  const beforeChange = async (index: number, circuit?: SelectCircuit) => {
    if (isReset) {
      setIsReset(false);
    }
    circuits[index] = circuit;
    setCircuits([...circuits]);
  };

  const onCompare = () => {
    if (validate()) {
      forceUpdate();
    }
  };

  const onReset = () => {
    setIsReset(true);
    setCircuits([circuits[0]]);
    setChartData(undefined);
  };

  const onCompareTypeChange = (e: RadioChangeEvent) => {
    setCompareType(e.target.value);
    setChartData(undefined);
    setHisChartData(undefined);
  };

  const onHistoryFormFinish = (values: any) => {
    setHisChartLoading(true);
    setHisChartsHeight(400 + values?.timeRangeList?.length * 100);
    const promises = values.timeRangeList.map((i: any) => {
      return getCircuitStatistics({
        circuitId: values.circuitId,
        dataType: values.point,
        timeResolution: values.timeResolution,
        from: dayjs(i.fromAndTo[0]).startOf('day').valueOf(),
        to: dayjs(i.fromAndTo[1]).endOf('day').valueOf(),
      });
    });
    Promise.all(promises)
      .then(datas => {
        setHisChartLoading(false);
        const dataArr = datas.map((data, index) => ({
          data: formatHisChartData(datas[index], index, values, currentCircuitCapacity),
          aggrby: values.timeResolution,
          dataType: values.point,
          tsStart: dayjs(values.timeRangeList[index].fromAndTo[0]).startOf('day').valueOf(),
          tsEnd: dayjs(values.timeRangeList[index].fromAndTo[1]).endOf('day').valueOf(),
        }));
        setHisChartData(dataArr as any);
      })
      .finally(() => setHisChartLoading(false));
  };

  const [hisChartData, setHisChartData] = useState<
    {
      dataType: DataType;
      aggrby: StatisticsPartition;
      data: ChartData[];
      btnType: DatePickerType;
      tsStart: number;
      tsEnd: number;
    }[]
  >(); // 历史对比图表数据
  const [hisChartLoading, setHisChartLoading] = useState(false); // 历史图表的loading
  const [currentCircuitCapacity, setCurrentCircuitCapacity] = useState<number | null>();

  useEffect(() => {
    if (compareType) {
      const timeResolution = circuitBtnsByHistory.find(i => i.id === defaultValue?.dataType)?.defaultTimeResolution;
      historyForm.setFieldsValue({
        circuitType: defaultValue?.type,
        distributionCabinetId: defaultValue?.cabinetId,
        circuitId: defaultValue?.circuitId,
        point: defaultValue?.dataType,
        timeResolution: timeResolution,
        timeRangeList: [
          {
            fromAndTo: timeResolution === StatisticsPartition.MINUTE_15 ? todayDefaltFromTo : dayDefaltFromTo,
          },
        ],
      });
    }
  }, [compareType]);

  return (
    <Modal
      destroyOnClose
      maskClosable={false}
      title="数据对比"
      open={visible}
      onCancel={() => onCancel()}
      footer={null}
      width={1400}
    >
      <Radio.Group
        options={Object.entries(CompareTypeDisplay).map(([k, v]) => ({
          label: v,
          value: k,
        }))}
        onChange={e => {
          onCompareTypeChange(e);
        }}
        value={compareType}
        optionType="button"
        buttonStyle="solid"
      />
      {compareType === CompareType.OBJECT && (
        <div className={styles.compareModalBody}>
          <div className={styles.leftCol}>
            <CustomSelect
              index={0}
              isReset={isReset}
              defaultValue={defaultValue}
              dataType={circuits[0]?.dataType}
              circuits={circuits}
              tabData={tabData}
              onChange={(circuit?: SelectCircuit) => beforeChange(0, circuit)}
              setChartData={setChartData}
            />
            <Divider dashed>
              VS <i className={styles.circle} />
            </Divider>
            <CustomSelect
              index={1}
              isReset={isReset}
              defaultValue={defaultValue}
              dataType={circuits[1]?.dataType}
              circuits={circuits}
              tabData={tabData}
              onChange={(circuit?: SelectCircuit) => beforeChange(1, circuit)}
              setChartData={setChartData}
            />
            <div className={styles.btnArea}>
              <Space>
                <Button type="primary" onClick={onCompare}>
                  对比
                </Button>
                <Button onClick={onReset}>重置</Button>
              </Space>
            </div>
          </div>
          <div className={styles.rightCol}>
            {circuits.filter(item => !isNil(item?.dataType)).length === 2 &&
              !(circuits[0]?.id === circuits[1]?.id && circuits[0]?.dataType === circuits[1]?.dataType) && (
                <DateSwitch
                  btnGroup={getBtnGroup(circuits[0]?.dataType!, circuits[1]?.dataType!)}
                  selectBtn={btnType}
                  aggrby={
                    btnType === DatePickerType.ORIGINAL
                      ? StatisticsPartition.MINUTE_15
                      : btnType === DatePickerType.THIRTYMINUTES
                      ? StatisticsPartition.MINUTE_30
                      : btnType === DatePickerType.DAY
                      ? StatisticsPartition.DAY
                      : btnType === DatePickerType.ONEMINUTE
                      ? StatisticsPartition.MINUTE_1
                      : StatisticsPartition.MONTH
                  }
                  style={{ float: 'none' }}
                  value={[dayjs(dateRange.tsStart, 'x'), dayjs(dateRange.tsEnd, 'x')]}
                  onQueryChange={onChangeData}
                />
              )}
            <div className={styles.chartArea}>
              <Spin spinning={loading}>
                {chartData ? (
                  <Chart
                    loading={loading}
                    chartData={chartData.map(item => item.data).flat()}
                    height={500}
                    option={getChartOption({
                      chartData,
                      tsStart: dateRange.tsStart,
                      tsEnd: dateRange.tsEnd,
                    })}
                  />
                ) : (
                  <Empty
                    style={{
                      marginTop:
                        circuits.filter(item => !isNil(item?.dataType)).length === 2 &&
                        !(circuits[0]?.id === circuits[1]?.id && circuits[0]?.dataType === circuits[1]?.dataType)
                          ? 98
                          : 130,
                    }}
                  />
                )}
              </Spin>
            </div>
          </div>
        </div>
      )}
      {compareType === CompareType.HISTORY && (
        <>
          <div className={styles.compareModalBody}>
            <div className={styles.leftCol} style={{ width: 400 }}>
              <Form form={historyForm} onFinish={onHistoryFormFinish} {...formLayout}>
                <HistoryCompare
                  form={historyForm}
                  setHisChartData={setHisChartData}
                  hisChartData={hisChartData}
                  tabData={tabData}
                  defaultValue={defaultValue}
                  getCapacity={(val?: number | null) => setCurrentCircuitCapacity(val)}
                />
              </Form>

              <div className={styles.btnArea}>
                <Space>
                  <Button
                    style={{ width: '100%' }}
                    type="primary"
                    onClick={() => {
                      historyForm.submit();
                    }}
                  >
                    对比
                  </Button>
                  <Button
                    onClick={() => {
                      const timeResolution = circuitBtnsByHistory.find(
                        i => i.id === defaultValue?.dataType
                      )?.defaultTimeResolution;
                      historyForm.setFieldsValue({
                        circuitType: defaultValue?.type,
                        distributionCabinetId: defaultValue?.cabinetId,
                        circuitId: defaultValue?.circuitId,
                        point: defaultValue?.dataType,
                        timeResolution: timeResolution,
                        timeRangeList: [
                          {
                            fromAndTo:
                              timeResolution === StatisticsPartition.MINUTE_15 ? todayDefaltFromTo : dayDefaltFromTo,
                          },
                        ],
                      });
                      setHisChartData(undefined);
                    }}
                  >
                    重置
                  </Button>
                </Space>
              </div>
            </div>
            <div className={styles.rightCol}>
              <div className={styles.chartArea}>
                <Spin spinning={hisChartLoading}>
                  {hisChartData ? (
                    <Chart
                      loading={hisChartLoading}
                      chartData={hisChartData.map(item => item.data).flat()}
                      height={hisChartsHeight}
                      option={getHisChartOption(hisChartData)}
                    />
                  ) : (
                    <Empty style={{ marginTop: 120 }} />
                  )}
                </Spin>
              </div>

              {/* <div className={styles.chartArea} style={{ marginTop: 20 }}>
                <HistoryChart
                  height={600}
                  loading={hisChartLoading}
                  hisSearchParams={hisSearchParams}
                  chartData={hisChartData}
                  // option={historyOption}
                />
              </div> */}
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default CompareModal;
