import { V2DevopsBsaDashboardPostResponse } from '@maxtropy/dmes-apis-v2';
import { FC } from 'react';
import styles from './index.module.scss';
import { AlarmLevelNumEnum, ConnectStateEnum } from '@/api/bsaPVOperationDashBoard';
import { OfflineTag, OnlineTag } from './CustomTags';
import { AlertFilled, InfoCircleOutlined } from '@ant-design/icons';
import { Col, Row, Space } from 'antd';
import { Button, Tooltip } from '@maxtropy/components';
import {
  AlarmLevel,
  AlarmLevelColor,
  AlarmLevelLabel,
  formateValues,
  formateValuesPercent,
  GWFormat,
  GwhFormat,
} from '../../utils';
import { useHasPermission } from '@/utils/hooks';
import { PermissionsType } from '@/common/permissionsConst';
import classnames from 'classnames';
import { isNil } from 'lodash-es';
import { BsaType } from '@/pages/BsaHistoryData/const';

interface Props {
  data: Exclude<V2DevopsBsaDashboardPostResponse['list'], undefined>[number];
}

const Card: FC<Props> = props => {
  const { data } = props;
  const remoteControlBtn = useHasPermission(PermissionsType.B_REMOTE_CONTROL);

  const isAlarm =
    !isNil(data.levelNum?.find(i => i.level === AlarmLevelNumEnum.MAX)?.num) &&
    !isNil(data.levelNum?.find(i => i.level === AlarmLevelNumEnum.HIGH)?.num) &&
    data.levelNum?.find(i => i.level === AlarmLevelNumEnum.MAX)?.num! +
      data.levelNum?.find(i => i.level === AlarmLevelNumEnum.HIGH)?.num! >
      0
      ? true
      : false;

  return (
    <div
      className={classnames(
        styles.card,
        data.connectState === ConnectStateEnum.OFFLINE && styles.cardOffline,
        isAlarm && styles.isAlarm
      )}
    >
      <div className={styles.topTipLine}></div>
      <div className={styles.topTitle}>
        <div className={styles.icon}>
          <AlertFilled />
        </div>
        <span className={styles.name}>{data.name}</span>
        {data.connectState === ConnectStateEnum.ONLINE && <OnlineTag />}
        {data.connectState === ConnectStateEnum.OFFLINE && <OfflineTag />}
      </div>

      <Row className={styles.statistics}>
        <Col span={6} className={styles.statisticsItem}>
          <div className={styles.value}>
            {GWFormat(data.realTimePower).value} {GWFormat(data.realTimePower).unit}
          </div>
          <div className={styles.label}>
            整站功率
            <Tooltip title="包含PCS和辅助设备等">
              <InfoCircleOutlined style={{ color: 'var(--warning-color)', marginLeft: 5 }}></InfoCircleOutlined>
            </Tooltip>
          </div>
        </Col>
        <Col span={6} className={styles.statisticsItem}>
          <div className={styles.value}>
            {GwhFormat(data.beCharge).value} {GwhFormat(data.beCharge).unit}
          </div>
          <div className={styles.label}>今日充电量</div>
        </Col>
        <Col span={6} className={styles.statisticsItem}>
          <div className={styles.value}>
            {GwhFormat(data.disCharge).value} {GwhFormat(data.disCharge).unit}
          </div>
          <div className={styles.label}>今日放电量</div>
        </Col>
        <Col span={6} className={styles.statisticsItem}>
          <div className={styles.value}>
            {GWFormat(data.incomingPower).value} {GWFormat(data.incomingPower).unit}
          </div>
          <div className={styles.label}>进线功率</div>
        </Col>
      </Row>

      <div className={styles.detailWrap}>
        <Row wrap gutter={[4, 5]} className={styles.wFull}>
          <Col span={6} className={styles.label}>
            变压器功率(kW)/容量(kVA):
          </Col>
          <Col span={18}>
            <Row wrap gutter={[4, 5]}>
              {data.transformerPower?.length ? (
                data.transformerPower?.map((item, index) => {
                  return (
                    <Col span={8} key={index}>
                      <div className={styles.item}>
                        <span>{formateValues(item.value)}</span>
                        <span>/</span>
                        <span>{formateValues(data.transformerCapacity?.[index]?.value)}</span>
                      </div>
                    </Col>
                  );
                })
              ) : (
                <Col span={8} className={styles.flexCenter}>
                  --
                </Col>
              )}
            </Row>
          </Col>
        </Row>

        <Row wrap style={{ marginTop: 9 }} gutter={[4, 5]} className={styles.wFull}>
          <Col span={6} className={styles.label}>
            PCS功率(kW)/电池堆SOC:
          </Col>
          <Col span={18}>
            <Row wrap gutter={[4, 5]}>
              {data.pcsPower?.length ? (
                data.pcsPower?.map((item, index) => {
                  return (
                    <Col span={8} key={index}>
                      <div className={styles.item}>
                        <span>{formateValues(item.value)}</span>
                        <span>/</span>
                        <span>{formateValuesPercent(data.stackSoc?.[index]?.value)}</span>
                      </div>
                    </Col>
                  );
                })
              ) : (
                <Col span={8} className={styles.flexCenter}>
                  --
                </Col>
              )}
            </Row>
          </Col>
        </Row>

        <Row style={{ marginTop: 8 }}>
          <Col span={6} className={styles.label}>
            报警信息：
          </Col>
          <Col span={18}>
            {Object.keys(AlarmLevel)
              .filter(level => !Number.isNaN(Number(level)))
              .map((level, index) => {
                return (
                  <>
                    <Tooltip title={AlarmLevelLabel[Number(level) as AlarmLevel]}>
                      <span key={level} style={{ color: AlarmLevelColor[Number(level) as AlarmLevel] }}>
                        {data.levelNum?.find(item => item.level === Number(level))?.num ?? '--'}
                      </span>
                    </Tooltip>
                    {index < Object.keys(AlarmLevelColor).filter(level => !Number.isNaN(Number(level))).length - 1 &&
                      '/'}
                  </>
                );
              })}
          </Col>
        </Row>
      </div>

      <div className={styles.footer}>
        <Space size={16}>
          <Button
            type="link"
            style={{ padding: 0, fontSize: 12 }}
            onClick={() => {
              window.open(`/energy-storage/realTime/monitor?id=${data.id}&type=${data.type}`, '_blank');
            }}
          >
            实时数据
          </Button>
          {remoteControlBtn && data.type === BsaType.OLD && (
            <Button
              type="link"
              style={{ padding: 0, fontSize: 12 }}
              onClick={() =>
                window.open(`/energy-storage/remote/control?bsaId=${data.id}&bsaName=${data.name}`, '_blank')
              }
            >
              远程控制
            </Button>
          )}
          <Button
            style={{ padding: 0, fontSize: 12 }}
            type="link"
            onClick={() =>
              window.open(
                `/energyStorage/stationOperation/overview?id=${data.id}&bsaType=${data.type}&tenantMcid=${
                  data.tenantMcid || ''
                }`,
                '_blank'
              )
            }
          >
            站点详情
          </Button>
          <Button
            style={{ padding: 0, fontSize: 12 }}
            type="link"
            onClick={() =>
              window.open(`/energy-storage/bsa/historyData?bsaId=${data.id}&bsaType=${data.type}`, '_blank')
            }
          >
            运行数据
          </Button>
          <Button
            style={{ padding: 0, fontSize: 12 }}
            type="link"
            onClick={() => window.open(`/assets/alarm/overview?assetCode=${data.assetCode}&expand=true`, '_blank')}
          >
            报警记录
          </Button>
        </Space>
      </div>
    </div>
  );
};

export default Card;
