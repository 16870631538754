import { DeviceTreeProps, getDeviceTypeTree } from '@/api/device';
import { useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import { getModel } from '@/api/model';
import { getModel as getPhysicalModel } from '@/api/chargingStationManagement';
import { CreateStatus, templateNameMap, TemplateType } from './const';

// 堆簇组芯下拉列表

const formatTreeData = (tree: DeviceTreeProps[], name: string) => {
  let res: DeviceTreeProps[] = [];
  tree.forEach(i => {
    if (i.name === '储能电池组件') {
      res = res.concat(i.children ?? []);
    }
  });
  return res.filter(i => i.name.includes(name));
};
/**
 *
 * @param name 电池簇/电池组/电芯
 * @returns 二级类目下拉列表
 */
export const useGetDeviceTypes = (type: TemplateType, status?: CreateStatus) => {
  const [deviceTypes, setDeviceTypes] = useState<DeviceTreeProps[]>();

  useEffect(() => {
    let name = templateNameMap[type];
    if (status !== CreateStatus.DONE) {
      getDeviceTypeTree().then(res => {
        setDeviceTypes(formatTreeData(res, name));
      });
    }
  }, [status]);
  return deviceTypes;
};
/**
 *
 * @param deviceTypeId 二级类目设备id
 * @returns modelList/physicalModelList 型号和物模型下拉列表
 */
export const useGetModalsList = (deviceTypeId: number) => {
  // 物模型下拉列表
  const { data: physicalModelList } = useRequest(() => getPhysicalModel({ deviceTypeIds: deviceTypeId }), {
    ready: !!deviceTypeId,
    refreshDeps: [deviceTypeId],
  });
  // 型号下拉列表
  const { data: modelList } = useRequest(() => getModel(deviceTypeId!), {
    ready: !!deviceTypeId,
    refreshDeps: [deviceTypeId],
  });

  return {
    modelList,
    physicalModelList,
  };
};
