import {
  Form,
  Radio,
  useBreadcrumbRoutes,
  SubContent,
  ShowInput,
  Button,
  Wrapper,
  FormTitle,
  Tag,
} from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import { apiV2LineLossAlarmRuleDetailPost, V2LineLossAlarmRuleDetailPostResponse } from '@maxtropy/dmes-apis-v2';

const routes = [{ name: '报警详情' }];

export enum AlarmLevel {
  HIGHEST,
  HIGH,
  MIDDLE,
  LOW,
  LOWEST,
}

export const AlarmLevelDisplay = {
  [AlarmLevel.HIGHEST]: '最高级',
  [AlarmLevel.HIGH]: '高级',
  [AlarmLevel.MIDDLE]: '中级',
  [AlarmLevel.LOW]: '低级',
  [AlarmLevel.LOWEST]: '最低级',
};

export const AlarmLevelColorDisplay = {
  [AlarmLevel.HIGHEST]: 'error',
  [AlarmLevel.HIGH]: 'warning',
  [AlarmLevel.MIDDLE]: 'info',
  [AlarmLevel.LOW]: 'success',
  [AlarmLevel.LOWEST]: 'default',
};

const DetailRules: React.FC = () => {
  const routesContext = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [data, setData] = useState<V2LineLossAlarmRuleDetailPostResponse>();
  // 表单回填
  useEffect(() => {
    if (id) {
      apiV2LineLossAlarmRuleDetailPost({ id }).then(res => {
        form.setFieldsValue(res);
        setData(res);
      });
    }
  }, [id, form]);

  return (
    <>
      <Wrapper className={styles.wrapper} routes={[...(routesContext?.routes ?? []), ...routes]}>
        <Form
          form={form}
          initialValues={{
            alarmLogic: 1,
          }}
        >
          <FormTitle title="报警详情" />
          <SubContent title="基础设定">
            <Row>
              <Col span={12}>
                <Form.Item label="规则名称" name="name" rules={[{ required: true, message: '请输入规则名称' }]}>
                  <ShowInput></ShowInput>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item label="所属运营单元" name="ouName" rules={[{ required: true, message: '请输入规则名称' }]}>
                  <ShowInput></ShowInput>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item label="报警等级">
                  <ShowInput
                    value={
                      <Tag
                        border="solid"
                        color={AlarmLevelColorDisplay[data?.alarmLevel as keyof typeof AlarmLevelColorDisplay]}
                      >
                        {AlarmLevelDisplay[data?.alarmLevel as keyof typeof AlarmLevelDisplay]}
                      </Tag>
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </SubContent>
          <SubContent title="逻辑设定">
            <Row>
              <Col span={12}>
                <Form.Item label="回路" name="circuitName" rules={[{ required: true, message: '请选择' }]}>
                  <ShowInput></ShowInput>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item label="报警逻辑" name="alarmLogic" rules={[{ required: true, message: '请选择' }]}>
                  <Radio.Group>
                    <Radio disabled value={1}>
                      回路线损率及线损量
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item label="查询周期" name="cycle" rules={[{ required: true, message: '请选择' }]}>
                  <Radio.Group disabled>
                    <Radio value={1}>按日</Radio>
                    <Radio value={2}>按月</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item label="损耗率" name="lossRate" rules={[{ required: true, message: '请选择' }]}>
                  <ShowInput addonAfter="%" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="损耗电量"
                  name="loss"
                  rules={[
                    { required: true, message: '请选择' },
                    { pattern: /^[1-9]\d{0,7}$/, message: '只能输入8位正整数' },
                  ]}
                >
                  <ShowInput addonAfter="kWh" />
                </Form.Item>
              </Col>
            </Row>
            <div className={styles.tips_text}>
              <InfoCircleOutlined style={{ color: '#F5AC00' }} />
              <span className={styles.txt}>
                逻辑说明：根据设定的统计周期, 查询周期内所选择回路损耗率和损耗电量, 若都大于等于输入的值, 则报警
              </span>
            </div>
          </SubContent>
        </Form>
        <Space
          style={{
            marginLeft: 32,
            marginTop: -13,
          }}
          className={'sticky-footer-left'}
        >
          <Button onClick={() => navigate(`/dmes/lineLoss/alarmRules`)}>返回</Button>
        </Space>
      </Wrapper>
    </>
  );
};
export default DetailRules;
