import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { Key } from 'react';
import { BsaType } from './constans';
import { BsaDeviceTypeEnum, BSAStatus, TopologicalType } from './type';
import { CellDevice, PackDevice, PcsDevice, RackDevice, StackDevice, ModbusPointAttributes } from './entity';
import { PageRequest, PageResponse } from './page';
import { PCSType } from '@/pages/Bsa/components/BaseInfo';
import { FCSInfo } from './strategy';
import { CostInfo } from './pv';
import { apiV2BsaCostInfoPost, apiV2BsaUpsetCostInfoPost } from '@maxtropy/dmes-apis-v2';

export interface DeviceProps {
  deviceId?: number;
  deviceName?: number;
  deviceCode?: number;
  deviceType?: number;
  sequence?: number;
}

export interface UpdateBsaRequest {
  fcsSoftware?: string;
  id: number;
  name: string;
  createStationTime?: string;
  hasRack?: boolean;
  rackCountInStack?: number;
  hasPack?: boolean;
  hasCell?: boolean;
  cellCountInPack: number;
  packCountInRack?: number;
  fireEquipmentList?: DeviceProps[];
  airConditionerList?: DeviceProps[];
  temperatureAndHumidityMeterList?: DeviceProps[];
  smokeDetectorList?: DeviceProps[];
  eol?: number;
  multiplyingPowerUpperLimit?: number;
  cycles?: number;
  hardwareVersion?: string;
  pcsStruct?: PCSType;
  fcsSoftwareList?: string[];
  location: string;
  address: string;

  latitude: number | null;
  longitude: number | null;
  adcode: string;

  ambientTemperatureLayerNumber?: number;
  ambientTemperatureUpperOne?: number;
  ambientTemperatureUpperTwo?: number;
  ambientTemperatureLowerOne?: number;
  ambientTemperatureLowerTwo?: number;
  hasMonomerTemperatureConfig?: boolean;
  monomerTemperatureLayerNumber?: number;
  monomerTemperatureUpperOne?: number;
  monomerTemperatureUpperTwo?: number;
  monomerTemperatureLowerOne?: number;
  monomerTemperatureLowerTwo?: number;
  hasMonomerVoltageConfig?: boolean;
  monomerVoltageLayerNumber?: number;
  monomerVoltageUpperOne?: number;
  monomerVoltageUpperTwo?: number;
  monomerVoltageLowerOne?: number;
  monomerVoltageLowerTwo?: number;
  voltageUniformDeviation?: number;
  chargeVoltageUpper?: number; // 最高充电电压
  temperatureUniformDeviation?: number;
  hasAmbientTemperatureConfig?: boolean;
  hasTemperatureUniformConfig?: boolean;
  hasVoltageUniformConfig?: boolean;
  hardwareName?: string;
  softwareName?: string;
}
export interface DeviceReq {
  deviceType?: number;
  ouId?: number;
}
export interface Bsa extends UpdateBsaRequest {
  fcsSoftware?: string;
  mcid: string;
  customerName?: string;
  ouId: number;
  ouName: string;
  serialNumber: string;
  status: BSAStatus;
  loadTransformerId: number;
  fcsId?: number;
  uetId: number;
  pcsStruct?: PCSType;
  fcsSoftwareList?: string[];
  hardwareVersion?: string;
  createStationTime?: string;
}
export interface BsaRecordReq extends PageRequest {
  bsaId?: string;
  status?: string;
}
export interface BsaRecordProps {
  id: number;
  result: boolean;
  reason: string;
  updateTime: string;
}
// 根据设备类型及ouId查询设备列表
export function getDeviceListByOuId(params: DeviceReq) {
  return fetch<DeviceProps[]>(`/api/device/bsa/especially/list?${qs.stringify(params, { indices: false })}`);
}
// 下发记录列表
export function getBsaRecordList(params: BsaRecordReq) {
  return fetch<PageResponse<BsaRecordProps>>(
    `/api/bsaConfig/publish/history?${qs.stringify(params, { indices: false })}`
  );
}

export function getBsa(id: Key) {
  return fetch<Bsa>(`/api/bsa/detail/${id}`);
}

export function updateBsa(query: UpdateBsaRequest) {
  return fetch<boolean>(`/api/bsa/update`, {
    method: 'PUT',
    body: JSON.stringify(query),
  });
}

export interface UetDevice {
  deviceId: number;
  deviceCode: string;
  deviceName: string;
  ouList: {
    id: number;
    name: string;
    serialNumber: string;
  }[];
}

export interface UETResponse {
  capacity: number;
  unit: string;
  targetLoad: number;
  fcsId: number;
  fcsName: string;
  totalLoadDevice?: UetDevice;
  productionLoadDevice?: UetDevice;
  deviceId: number;
  deviceName: string;
  level: 0 | 1 | 2;
  circuitName: string;
}

// export enum HardWareVersion {
//   HA1 = 'HA1',
//   HA2 = 'HA2',
//   MP1 = 'MP1',
//   HA11 = 'HA1_1',
// }

export enum HardWareVersion {
  HA1 = 'HS1.1',
  HA2 = 'HS1.2',
  MP1 = 'HS1.3',
  HA11 = 'HS1.4',
  SH1 = 'HS1.5',
  YJ1 = 'HS1.6',
  JN1 = 'HS1.7',
  JN2 = 'HS1.8',
  ZG1 = 'HS1.9',
}
// 多pcs且是主控的硬件版本
export const multiPcsHardWare = [HardWareVersion.MP1, HardWareVersion.YJ1, HardWareVersion.JN1, HardWareVersion.JN2];
// 多pcs 没有主从关系的硬件版本
export const multiPcsHardWareWithoutControl = [HardWareVersion.ZG1];

// 多pcs主控软件版本
export const multiPcsSoftWare = ['FC/1.0', 'FC/1.1', 'FC/1.2', 'FG/1.0', 'FI/1.0', 'FK/1.0'];
// 是否有就地端接线功能
export const hasLineConfig = ['FC/1.0', 'FC/1.1', 'FD/1.0', 'FK/1.0', 'FL/1.0'];

export function getUetByBsa(bsaId: Key) {
  return fetch<UETResponse>(`/api/bsa/uet-by-bsa/${bsaId}`);
}

export function getPcsList(bsaId: Key) {
  return fetch<Omit<PcsDevice, 'id'>[]>(`/api/bsa/pcs-list/${bsaId}`);
}

export function getFcsByBsaAndHardList(bsaId: Key, hardwareVersion: string) {
  return fetch<FCSInfo[]>(`/api/bsa/fcs-list-by-bsa?bsaId=${bsaId}&hardwareVersion=${hardwareVersion}`);
}

export function getBsaBindingPcsList(bsaId: Key) {
  return fetch<{ list: PcsDevice[] }>(
    `/api/v2/bsa/pcs-binding-list`,
    {
      method: 'POST',
      body: JSON.stringify({ id: bsaId }),
    },
    true
  ).then(res => res.list);
}

export interface BindPcsRequest {
  bsaId: number;
  pcsList: { deviceId: number; sequence: number }[];
}

export function updatePcsWithMeter(request: BindPcsRequest) {
  return fetch<{ result: boolean }>(
    `/api/v2/bsa/pcs-binding-meter`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export function bindPcs(request: BindPcsRequest) {
  return fetch<{ result: boolean }>(
    `/api/v2/bsa/pcs-binding-bsa`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export interface BsaDraftRequest {
  bsaId: number;
  content?: string;
}

export function saveDraft(request: BsaDraftRequest) {
  return fetch<void>(`/api/bsa/draft`, {
    method: 'POST',
    body: JSON.stringify(request),
  });
}

// export interface DraftContent extends BsaDraftRequest {
//   fsmType: BsaType;
//   updateTime: string;
// }

export interface BsaDraft {
  id: number;
  bsaId: number;
  fsmType: BsaType;
  content?: string;
  updateTime: string;
}

export function getDraft(bsaId: Key) {
  return fetch<BsaDraft>(`/api/bsa/draft?bsaId=${bsaId}`);
}

export interface Fsm {
  id: number;
  bsaId: number;
  fsmType: BsaType;
  sequence: number;
  value?: string;
  executed: boolean;
  updateTime: string;
}

export interface BsaFsm {
  currentFsm: BsaType;
  fsmList: Fsm[];
}

export function getFsm(bsaId: Key) {
  return fetch<BsaFsm>(`/api/bsa/fsm?bsaId=${bsaId}`);
}

export interface StackListDevice extends Omit<StackDevice, 'pcsName' | 'pcsId'> {}

export function getStackList(bsaId: Key) {
  return fetch<StackListDevice[]>(`/api/bsa/stack-list/${bsaId}`);
}

export function getBsaBindingStackList(bsaId: Key) {
  return fetch<StackDevice[]>(`/api/bsa/stack-binding-list/${bsaId}`);
}

export interface BindStackRequest {
  bsaId: number;
  stackList: {
    deviceId: number;
    pcsId: number;
    sequence: number;
  }[];
}

export function bindStack(request: BindStackRequest) {
  return fetch<void>(`/api/bsa/stack-binding-pcs`, {
    method: 'POST',
    body: JSON.stringify(request),
  });
}

export interface BsaPageReq {
  mcid?: string;
  ouId?: number;
  statusFilter?: boolean;
  name?: string;
}

export interface BsaListProps {
  edgeNetworkName?: string;
  edgeNetworkNo?: string;
  edgeNetworkId?: number;
  fcsId: number;
  ouId: number;
  ouName: string;
  createTime: string;
  createUser: string;
  fcsName: string;
  id: number;
  bsaConfig: boolean;
  lastUpdateUser: string;
  bsaUiUpdate: boolean;
  bsaUiAirConfigByDevice: boolean;
  mcid: string;
  name: string;
  status: BSAStatus;
  topologicalType: TopologicalType;
  updateTime: string;
  hasRack: boolean;
  hasPack: boolean;
  hasCell: boolean;
  bsaDeliverStatus: boolean;
  totalPower: number;
  hardwareVersion: string;
  hardwareName: string;
  hardwareVersionEnum: string;
  installedCapacity: number; // 装机容量
  ratedPower: number; // 额定功率
  customerName: string; // 组织名称
  isCompleted?: boolean; // 是否完成储能阵列编辑
  mirrorCompleted?: boolean; // 生成数据点镜像是否成功
  softwareVersion?: string; // 软件版本
}
export interface BsaAllotsResponse {
  result: boolean;
  reason?: string;
}
export const getBsaList = (query?: BsaPageReq) =>
  fetch<{ list: BsaListProps[] }>(
    `/api/v2/bsa/list`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  ).then(res => res.list);

export const getBsaListWithOu = (query?: BsaPageReq) =>
  fetch<BsaListProps[]>(`/api/bsa/listWithOu?${qs.stringify(query, { indices: false })}`);
// 下发就地配置
export const bsaAllots = (bsaId: Key) => {
  return fetch<BsaAllotsResponse>(`/api/bsaConfig/publish/${bsaId}`, {
    method: 'POST',
  });
};
export function getRackDeviceList(bsaId: Key) {
  return fetch<RackDevice[]>(`/api/bsa/rack-binding-list/${bsaId}`);
}

export interface PackDeviceRequest extends PageRequest {
  // bsaId: Key;
  pcsSequence?: string;
  rackSequence?: string;
  // deviceType?: any;
}
export interface CellDeviceRequest extends PageRequest {
  pcsSequence?: string;
  rackSequence?: string;
  packSequence?: string;
}

export function getPackDevicePage(bsaId: Key, request: PackDeviceRequest) {
  return fetch<PageResponse<PackDevice>>(
    `/api/bsa/pack-binding-list/${bsaId}?${qs.stringify(request, { indices: false })}`
  );
}

// 获取电芯设备列表

export function getCellDevicePage(bsaId: Key, request: CellDeviceRequest) {
  return fetch<PageResponse<CellDevice>>(
    `/api/bsa/cell-binding-list/${bsaId}?${qs.stringify(request, { indices: false })}`
  );
}
export const updateStatus = (id: number, status: BSAStatus) =>
  fetch(`/api/bsa/update-status/${id}?status=${status}`, {
    method: 'POST',
  });

export function bsaFinish(bsaId: Key) {
  return fetch<void>(`/api/bsa/finish?bsaId=${bsaId}`, {
    method: 'POST',
  });
}

export interface Stack {
  pcsId: number;
  stackId: number;
  stackCode: string;
  stackSequence: number;
  deviceId: number;
  deviceName: string;
  deviceCode: string;
}

export interface Rack {
  rackId: number;
  rackSequence: number;
  pcsId: number;
  stackId: number;
  stackCode: string;
  stackSequence: number;
  deviceId: number;
  deviceName: string;
  deviceCode: string;
}

export interface Pack {
  pcsId: number;
  packSequence: number;
  stackId: number;
  stackCode: string;
  stackSequence: number;
  deviceId: number;
  deviceName: string;
  deviceCode: string;
  rackId: number;
  rackCode: string;
  rackSequence: number;
  packId: number;
}

export function isPack(device: Stack | Rack | Pack): device is Pack {
  return 'packId' in device;
}

export function isRack(device: Stack | Rack | Pack): device is Rack {
  return !isPack(device) && 'rackId' in device;
}

export function isStack(device: Stack | Rack | Pack): device is Stack {
  return !isPack(device) && !isRack(device);
}

export const getStacks = (bsaId: number, pcsId?: number) =>
  fetch<Stack[]>(`/api/bsa/${bsaId}/stack?${qs.stringify({ pcsId }, { indices: false })}`);
export const getRacks = (bsaId: number, stackId?: number) =>
  fetch<Rack[]>(`/api/bsa/${bsaId}/rack?${qs.stringify({ stackId }, { indices: false })}`);
export const getPacks = (bsaId: number, rackId?: number) =>
  fetch<Pack[]>(`/api/bsa/${bsaId}/pack?${qs.stringify({ rackId }, { indices: false })}`);
export const getCells = (bsaId: number, packId?: number) =>
  fetch<Pack[]>(`/api/bsa/${bsaId}/cell?${qs.stringify({ packId }, { indices: false })}`);

// 获取电池堆列表
export interface StackListItemProps {
  id: number;
  sequence: number;
}
export const getStackListByBsaId = (bsaId: Key) => {
  return fetch<StackListItemProps[]>(`/api/edge-device/bsa/point/stack/list/${bsaId}`);
};

// 获取数据采集点模板列表
export interface PointTemplateReq {
  type: BsaDeviceTypeEnum;
  bsaId: Key;
  stackId: Key;
}
export interface PointTemplateRes {
  templates: ModbusPointAttributes[];
  bsaId: number;
  type: number; //  1 :PCS 2 :电池堆 3 :电池蔟 4 :电池组 5 :电池芯
  stackId: number;
  stackSequence?: number;
}
export const getPointTemplatesByStackId = (params: PointTemplateReq) => {
  return fetch<PointTemplateRes>(`/api/edge-device/bsa/point/template?${qs.stringify(params, { indices: false })}`);
};

// 插入采集点
export const createPointTemplate = (data: PointTemplateRes[], bsaId: Key, type: BsaDeviceTypeEnum) => {
  return fetch(`/api/v2/edge-device/bsa/point/create/template`, {
    method: 'POST',
    body: JSON.stringify({ bsaEdgeDevicePointTemplateModbusTcps: data, bsaId, type, timestamp: new Date().valueOf() }),
  });
};
// 更新生成采集点
export const updatePointTemplate = (data: PointTemplateRes[], bsaId: Key, type: BsaDeviceTypeEnum) => {
  return fetch(`/api/v2/edge-device/bsa/point/update/template`, {
    method: 'POST',
    body: JSON.stringify({ bsaEdgeDevicePointTemplateModbusTcps: data, bsaId, type, timestamp: new Date().valueOf() }),
  });
};

// 获取全部电池堆数据采集点模板列表
export const getPointTemplatesByAllStackIds = (params: Omit<PointTemplateReq, 'stackId'>) => {
  return fetch<{ data: { list: PointTemplateRes[] } }>(`/api/v2/edge-device/bsa/point/template/all`, {
    method: 'POST',
    body: JSON.stringify({
      timestamp: new Date().valueOf(),
      ...params,
    }),
  }).then(res => res.data.list);
};

// 生成数据点镜像
export const generatePointMirror = (id: number) =>
  fetch<DevicePointMirrorProps>(`/api/bsaConfig/point/mirror/generate/${id}`, {
    method: 'POST',
  });

export interface DevicePointMirrorProps {
  id?: number;
  bsaId?: number; //储能阵列id
  result?: boolean; // 结果(true:成功 false:失败)
  description?: string; //结果说明
  createTime?: string; //创建时间
  updateTime?: string; //修改时间
  tenantMcid?: string; //租户mcid
  deviceDetailList?: DeviceDetailList[]; //生成设备详情
}

export interface DeviceDetailList {
  needMirror?: boolean;
  id: number;
  bsaPointMirrorGenerateLogId?: number; //生成镜像日志id
  deviceId?: number; //设备id
  deviceName?: string; //设备名称
  dataPropertyId?: number; //数据属性id
  dataPropertyName?: string; //数据属性
  dataPointMirrorId?: number; //数据点镜像id
  generateTime?: string; //生成时间
  result?: boolean; //结果(true:成功 false:失败)
  description?: string; //结果说明
  createTime?: string; //创建时间
  updateTime?: string; //修改时间
  tenantMcid?: string; //租户mcid
}

// 查看数据点镜像
export const generatePointMirrorLog = (bsaId: number) =>
  fetch<DevicePointMirrorProps>(`/api/bsaConfig/point/mirror/generate/log/${bsaId}`, {
    method: 'GET',
  });

export function getBsaCostInfo(id: number) {
  return apiV2BsaCostInfoPost({ id });
}
// 更新/新增相关的成本信息
export function updateBsaCostInfo(id: number, data: CostInfo) {
  return apiV2BsaUpsetCostInfoPost({
    bsaId: id,
    ...data,
  });
}

// 新储能阵列删除pcs
export function deletePcs(id?: number) {
  return fetch(
    `/api/v2/neo-bsa-device/del/pcs`,
    {
      method: 'POST',
      body: JSON.stringify({
        timestamp: new Date().valueOf(),
        id,
      }),
    },
    true
  );
}
