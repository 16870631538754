import Layout from '@/Layout';
import { TenantLayout as LayoutComponents, RoutePathItem, getRoutesPathsByFileContext } from '@maxtropy/components';
import { Outlet } from 'react-router-dom';
// 获取所有pages文件夹中所有子文件中的routes.tsx
const routesContext = require.context('../pages', true, /routes\.tsx$/);
const mainRoutesPaths = getRoutesPathsByFileContext(routesContext);

// // 获取所有pages文件夹中所有子文件中的routes.tsx
// const modules = import.meta.glob('../pages/**/routes.tsx', {
//   eager: true,
//   import: 'default',
// });
//
// const mainRoutesPaths = Object.keys(modules).map(key => {
//   const routes = modules[key] || {};
//   const routesList = Array.isArray(routes) ? routes : [routes];
//   return routesList;
// });

const mainRoutes: RoutePathItem[] = [
  {
    element: (
      <Layout>
        <LayoutComponents>
          <Outlet />
        </LayoutComponents>
      </Layout>
    ),
    children: [...mainRoutesPaths],
  },
];

export default mainRoutes;
