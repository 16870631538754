import { PermissionsType } from '@/common/permissionsConst';
import BsaRecordList from '@/pages/BsaRecordList';
const routes = [
  {
    path: '/energy-storage/basic/bsa/record/:id',
    permission: PermissionsType.P_UPDATE_BSA,
    element: <BsaRecordList />,
  },
];
export default routes;
