import React, { useRef } from 'react';
import ReactEcharts from 'echarts-for-react';
import { ChartData } from '../../index';
import { notEmptyForChart } from '../../utils';
import { Skeleton } from 'antd';
import { Empty } from '@maxtropy/components';
import { isNil } from 'lodash-es';
import dayjs from 'dayjs';
import { StatisticsPartition } from '@/api/circuit';

export interface HistoryChartProps {
  loading?: boolean;
  chartData: ChartData[];
  option?: any;
  height?: number;
}

const HistoryChart = (props: HistoryChartProps) => {
  const { option, chartData, height } = props;
  let filteredChartData = chartData?.filter(i => !!i);
  const chartRef = useRef<ReactEcharts>();
  // let widthArr = Array.from({ length: 8 }, (_, i) => 11 * i + '%');

  return (
    <>
      {notEmptyForChart(filteredChartData) ? (
        <Skeleton style={{ padding: '120px 80px' }} loading={props.loading} paragraph={{ rows: 6 }}>
          <ReactEcharts
            theme={'dark'}
            option={option}
            style={{ height: height ?? 'calc(100%)' }}
            notMerge
            lazyUpdate={false}
            ref={d => {
              if (d) {
                chartRef.current = d;
              }
            }}
            onEvents={{
              brushSelected: (params: any) => {
                var brushed: any[] = [];
                let startTime;
                let endTime;
                var brushComponent = params.batch[0];
                const chartObject = chartRef.current?.getEchartsInstance();
                const series = chartObject?.getOption()?.series as any[];
                brushComponent.selected.forEach((item: any) => {
                  const findSeries = series?.find(i => i.name === item.seriesName);
                  if (findSeries && findSeries.type === 'bar') {
                    let arr: any[] = [];
                    item.dataIndex.forEach((c: number, index: number) => {
                      if (index === 0) {
                        startTime = getTimeAxis(findSeries.timeResolution, findSeries.data[c][0]);
                      }
                      if (index === item.dataIndex.length - 1) {
                        endTime = getTimeAxis(findSeries.timeResolution, findSeries.data[c][0]);
                      }
                      arr.push(findSeries.data[c][1]);
                    });
                    let total = null;
                    if (arr.filter(x => !isNil(x)).length > 0) {
                      total = arr.filter(x => !isNil(x)).reduce((a, b) => a + b);
                    }
                    brushed.push(
                      item.seriesName + '（累计）：' + (!isNil(total) ? total.toFixed(2) : '--') + findSeries.unit
                    );
                  }
                });
                if (brushComponent.areas.length > 0) {
                  chartObject?.setOption({
                    title: {
                      show: true,
                      padding: 8,
                      backgroundColor: 'rgba(0,0,0,0.8)',
                      borderColor: 'transparent',
                      borderRadius: 2,
                      textStyle: {
                        color: 'rgba(255,255,255,0.85)',
                        fontSize: 12,
                        fontWeight: 500,
                      },
                      text: startTime + ' ~ ' + endTime + '\n\n' + brushed.join('\n\n'),
                      top: 100,
                      // top: 0,
                      // right: 260,
                      left: brushComponent.areas[0].range[1] - 240,
                      zlevel: 99999,
                    },
                  });
                } else {
                  chartObject?.setOption({
                    title: {
                      show: false,
                    },
                  });
                }
              },
            }}
          />
        </Skeleton>
      ) : (
        <Empty style={{ marginTop: 120 }} />
      )}
    </>
  );
};

export default HistoryChart;

export const getTimeAxis = (aggrby: StatisticsPartition, time: number) => {
  switch (aggrby) {
    case StatisticsPartition.MINUTE_1:
    case StatisticsPartition.MINUTE_15:
    case StatisticsPartition.MINUTE_30:
      return dayjs(time).format('YYYY-MM-DD HH:mm');
    case StatisticsPartition.DAY:
      return dayjs(time).format('YYYY-MM-DD');
    case StatisticsPartition.MONTH:
      return dayjs(time).format('YYYY-MM');
    default:
      return '--';
  }
};
