import { PermissionsType } from '@/common/permissionsConst';
import ElectricityPriceManage from '@/pages/ElectricityPriceManage';
import ElectricityPriceDetail from '@/pages/ElectricityPriceManage/Detail';
import ElectricityPriceForm from '@/pages/ElectricityPriceManage/Form';
const routes = [
  {
    path: '/dmes/circuit/e-price',
    permission: PermissionsType.P_CIRCUIT_LIST,
    element: <ElectricityPriceManage />,
  },
  {
    path: '/dmes/circuit/e-price/new',
    permission: PermissionsType.P_CREATEEPRICE,
    element: <ElectricityPriceForm />,
  },
  {
    path: '/dmes/circuit/e-price/:id/edit',
    permission: PermissionsType.P_UPDATEEPRICE,
    element: <ElectricityPriceForm isEdit />,
  },
  {
    path: '/dmes/circuit/e-price/:id',
    permission: PermissionsType.p_E_PRICE_LIST,
    element: <ElectricityPriceDetail />,
  },
];

export default routes;
