import { CSSProperties, FC, ReactNode } from 'react';

import { Divider } from 'antd';

import styles from './index.module.scss';

interface DividerContentProps {
  title?: ReactNode;
  style?: CSSProperties;
  children?: React.ReactNode;
}

const DividerContent: FC<DividerContentProps> = props => {
  const { title, children, style } = props;

  return (
    <div className={styles.contentWrapper}>
      {title !== undefined && (
        <Divider orientation="left" className={styles.divider}>
          {title}
        </Divider>
      )}
      <div className={styles.content} style={style}>
        {children}
      </div>
    </div>
  );
};

export default DividerContent;
