export enum BsaType {
  ARRAY_INFO_INIT = 1,
  PCS_INIT = 2,
  STACK_INIT = 3,
  RACK_DEVICE_CREATE = 4,
  RACK_DEVICE_CREATED = 5,
  RACK_DEVICE_COMPLETED = 6,
  PACK_DEVICE_CREATE = 7,
  PACK_DEVICE_CREATED = 8,
  PACK_DEVICE_COMPLETED = 9,
  CELL_DEVICE_CREATE = 10,
  CELL_DEVICE_CREATED = 11,
  CELL_DEVICE_COMPLETED = 12,
  ACQUISITION_CONFIG = 13,
  RACK_EDGE_DEVICE_INIT = 14,
  RACK_EDGE_DEVICE_TEMPLATE_APPLYING = 15,
  RACK_EDGE_DEVICE_TEMPLATE_APPLIED = 16,
  RACK_EDGE_DEVICE_COMPLETED = 17,
  RACK_DAQ_POINT_CREATE = 18,
  RACK_DAQ_POINT_CREATED = 19,
  RACK_DAQ_POINT_COMPLETED = 20,
  PACK_EDGE_DEVICE_INIT = 21,
  PACK_EDGE_DEVICE_TEMPLATE_APPLYING = 22,
  PACK_EDGE_DEVICE_TEMPLATE_APPLIED = 23,
  PACK_EDGE_DEVICE_COMPLETED = 24,
  PACK_DAQ_POINT_CREATE = 25,
  PACK_DAQ_POINT_CREATED = 26,
  PACK_DAQ_POINT_COMPLETED = 27,
  CELL_EDGE_DEVICE_INIT = 28, // 电池芯数采设置
  CELL_EDGE_DEVICE_TEMPLATE_APPLYING = 29, // 分配电池芯模板
  CELL_EDGE_DEVICE_TEMPLATE_APPLIED = 30, // 分配电池芯模板完成
  CELL_EDGE_DEVICE_COMPLETED = 31, // 电池芯数采信息设置
  CELL_DAQ_POINT_CREATE = 32, // 点击生成电池芯采集点
  CELL_DAQ_POINT_CREATED = 33, // 新建电池芯采集点
  CELL_DAQ_POINT_COMPLETED = 34, // 电池组采集芯创建完成
  BSA_COMPLETED = 35, // 完成阵列
}
