const mirroText1 = (
  <div style={{ margin: '10px 0' }}>
    <h4>所需镜像信息（设备变更/设备数采变更后，此处不会自动更新镜像，需要重新一键生成镜像。）：</h4>
    1、总负载电表：总有功功率、设备通讯状态
    <br />
    2、所有PCS：PCS功率控制、设备通讯状态、工作模式设置、运行功率、市电状态、工作模式、启停状态
    <br />
    3、所有电池堆：电池堆可放电量、电池堆可充电量、允许最大放电功率、允许最大充电功率、电池堆SOC、电池堆电芯最高电压、电池堆电芯最低电压，
    'MSD、机柜门开关状态'
    {/* <br />
    4、生产负载电表：总有功功率、设备通讯状态 */}
    <br />
    4、计量电表：总有功功率、设备通讯状态
    <br />
    5、上端变压器电表: 总有功功率、设备通讯状态
    <br />
    6、并网点电表: 总有功功率、设备通讯状态
    <br />
    7、DIDO: DI1-浪涌保护器、DI2断路器、DO3-散热风扇
  </div>
);
const mirroText2 = (
  <div style={{ margin: '10px 0' }}>
    <h4>所需镜像信息（设备变更/设备数采变更后，此处不会自动更新镜像，需要重新一键生成镜像）：</h4>
    1、总负载电表: 总有功功率、设备通讯状态
    <br />
    2、所有PCS: PCS功率控制、设备通讯状态、工作模式设置、运行功率、市电状态、工作模式
    <br />
    3、所有电池堆:电池堆可放电量、电池堆可充电量、允许最大放电功率、允许最大充电功率、电池堆SOC、电池堆电芯最高电压、电池堆电芯最低电压
    {/* <br />
    4、生产负载电表: 总有功功率、设备通讯状态 */}
    <br />
    4、计量电表: 总有功功率，设备通讯状态
    <br />
    5、上端变压器电表: 总有功功率、设备通讯状态
    <br />
    6、并网点电表: 总有功功率、设备通讯状态
    <br />
    7、DIDO：DI1（10471）、DI2（10472）、DI3（10473）、DI4（10474）、DO1（10475）、DO2（10476）、DO3（10477）、DO4（10478）
  </div>
);

export const mirroTextMap: Record<string, JSX.Element> = {
  others: mirroText1,
  'FK/1.0': mirroText2,
  'FI/1.0': mirroText2,
};
