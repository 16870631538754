import { fetch } from '@maxtropy/components';

export interface Ou {
  id: number;
  name: string;
  ouTypeId: number;
  ouTypeName: string;
  userType: number;
}

export const fetchOuList = (type: number) => fetch<Ou[]>(`/api/ou/list?type=${type}`);

export interface OuList {
  id: number;
  key: string;
  name: string;
}

export const getOuId = () => fetch<OuList[]>(`/api/ou/type/list`);

export const getOuListByMcid = (mcid: string, type: number) =>
  fetch<Ou[]>(`/api/ou/list-by-mcid?mcid=${mcid}&type=${type}`);
