import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Row, Skeleton, Space, Spin } from 'antd';
import dayjs, { Dayjs, OpUnitType } from 'dayjs';
import ReactEcharts from 'echarts-for-react';
import { useRequest } from 'ahooks';
import { exportChargingData, getBsaStaticsByGrain15Minu, getBsaStaticsByGrainCharge } from '@/api/bsa-overview';
import { Button, DatePicker, Empty, Radio } from '@maxtropy/components';
import { getBasicTooltipsConfig, getMarkLine, sumTools, useComputeTimeResolution } from './utils';
import styles from './index.module.scss';
import CardItem from './CardItem';
const { RangePicker } = DatePicker;

export interface Iprops {
  stack_h_property_id: number;
  stack_l_property_id: number;
  rack_h_property_id: number;
  rack_l_property_id: number;
  pack_h_property_id: number;
  pack_l_property_id: number;
  unit: string;
  type: string;
}

// 颗粒度枚举
export enum GrainChoice {
  MINUTE_15 = '15M',
  HOUR = 'H',
  DAY = 'D',
  MONTH = 'M',
}
// 时间范围
export const timeRange: Record<GrainChoice, number> = {
  [GrainChoice.MINUTE_15]: 3,
  [GrainChoice.HOUR]: 15,
  [GrainChoice.DAY]: 62,
  [GrainChoice.MONTH]: 24 - 1, // 24个月
};
type RangeValue = [Dayjs | null, Dayjs | null] | null;
// 默认时间map
const defaultTimeMap: Record<GrainChoice, RangeValue> = {
  [GrainChoice.MINUTE_15]: [dayjs().subtract(3 - 1, 'days'), dayjs()],
  [GrainChoice.HOUR]: [dayjs().subtract(15 - 1, 'days'), dayjs()],
  [GrainChoice.DAY]: [dayjs().subtract(62 - 1, 'days'), dayjs()],
  [GrainChoice.MONTH]: [dayjs().subtract(12 - 1, 'M'), dayjs()],
};
interface ChargingAmoutProps {
  bsaId: number;
}

const ChargingAmout: React.FC<ChargingAmoutProps> = props => {
  // const { id } = useParams<{ id: string }>();
  // const bsaId = +id;
  const { bsaId } = props;

  const [currentGrain, setCurrentGrain] = useState<GrainChoice>(GrainChoice.DAY);
  const [dates, setDates] = useState<RangeValue>(null);
  const [rangeTimeValue, setRangeTimeValue] = useState<RangeValue>(null);
  const formateStr = useComputeTimeResolution(currentGrain);
  // 设置默认日期
  useEffect(() => {
    setRangeTimeValue(defaultTimeMap[currentGrain]);
  }, [currentGrain]);
  // 日期面板选择范围
  const disabledDate = useCallback(
    (current: Dayjs) => {
      if (!dates) {
        return false;
      }
      const tooLate =
        dates[0] &&
        current.diff(dates[0], currentGrain === GrainChoice.MONTH ? 'M' : 'days') >= timeRange[currentGrain];
      const tooEarly = current && current > dayjs().endOf('day');
      return !!tooEarly || !!tooLate;
    },
    [currentGrain, dates]
  );

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };
  /**
   *
   * 两根柱子，一根是充电量，一根是放电量
   * 充电总量：所选择日期范围内的充电量之和, 即尖峰平谷充电量之和
   * 放电总量：所选择日期范围内的放电量之和, 即即尖峰平放电量之和
   * 储能站充电量：储能站计量电表的尖、峰、平、谷正向有功电能之和
   * 储能站放电量：储能站计量电表的尖、峰、平、谷反向有功电能之和
   */
  const requestApi = useMemo(() => {
    return currentGrain === GrainChoice.MINUTE_15 ? getBsaStaticsByGrain15Minu : getBsaStaticsByGrainCharge;
  }, [currentGrain]);
  const { data: chartData, loading } = useRequest(
    () => {
      let timeof: OpUnitType = currentGrain === GrainChoice.MONTH ? 'month' : 'date';
      return requestApi({
        bsaId: bsaId!,
        timeResolution: currentGrain as any,
        from: rangeTimeValue?.[0]?.startOf(timeof).valueOf()!,
        to: rangeTimeValue?.[1]?.endOf(timeof).valueOf()!,
      });
    },
    {
      refreshDeps: [bsaId, rangeTimeValue],
      ready: !!(bsaId && currentGrain && rangeTimeValue),
    }
  );

  const isDataEmpty = useMemo(() => {
    return !(chartData && chartData.length > 0);
  }, [chartData]);

  const { totalCharge, totalDisCharge, averageCharge, averageDisCharge } = useMemo(() => {
    let chargeValues = chartData
      ?.map(item => sumTools([item.summitCharge, item.peakCharge, item.plainCharge, item.valleyCharge]))
      .filter(item => typeof item === 'number');
    let disChargeValues = chartData
      ?.map(item => sumTools([item.summitDischarge, item.peakDischarge, item.plainDischarge, item.valleyDischarge]))
      .filter(item => typeof item === 'number');
    let totalCharge =
      !chargeValues || chargeValues.length === 0 ? null : chargeValues?.reduce((pre, next) => pre! + next!);
    let totalDisCharge =
      !disChargeValues || disChargeValues.length === 0 ? null : disChargeValues?.reduce((pre, next) => pre! + next!);
    return {
      totalCharge,
      totalDisCharge,
      averageCharge: totalCharge && totalCharge / (chargeValues?.length ?? 1),
      averageDisCharge: totalDisCharge && totalDisCharge / (disChargeValues?.length ?? 1),
    };
  }, [chartData]);

  const exportBtn = () => {
    let timeof: OpUnitType = currentGrain === GrainChoice.MONTH ? 'month' : 'date';
    exportChargingData({
      // @ts-ignore
      timestamp: dayjs().valueOf() as any,
      bsaId: bsaId!,
      timeResolution: currentGrain as any,
      from: rangeTimeValue?.[0]?.startOf(timeof).valueOf()!,
      to: rangeTimeValue?.[1]?.endOf(timeof).valueOf()!,
    });
  };
  const getChartOption = () => {
    const xData = chartData?.map(item => item.time);

    return {
      color: ['#57FB8B', '#00ADFF'],
      legend: {
        icon: 'rect',
        right: 0,
        itemWidth: 16,
        itemHeight: 4,
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
      },
      grid: {
        left: 30,
        right: 30,
        bottom: 35,
        containLabel: true,
      },
      dataZoom: [
        {
          type: 'slider',
          start: [GrainChoice.MINUTE_15].includes(currentGrain) ? 50 : 0,
          end: 100,
          bottom: 10,
          height: 20,
          textStyle: {
            fontSize: 10,
          },
          labelFormatter: (index: any) => {
            return dayjs(xData?.[index]).format(formateStr);
          },
        },
        { type: 'inside' },
      ],
      tooltip: {
        ...getBasicTooltipsConfig(),

        formatter: (params: any) => {
          if (!params) return;
          let originTime = params[0].name;
          let time = dayjs(+originTime).format(formateStr);
          let timeDom = `<div style="padding-bottom: 10px">${time}</div>`;
          let otherDoms = params
            .map((item: any) => {
              return `
              
              <div style="display: flex; align-items: center">
                ${item.marker}
                <span style="padding-right: 47px">${item.seriesName}</span>
                <span>${item.value?.toFixed(2) ?? '--'}kWh</span>
              </div>
            `;
            })
            .join('');

          let extrals = [
            { label: '平均充电量', value: averageCharge },
            { label: '平均放电量', value: averageDisCharge },
          ]
            .map(item => {
              return `
            <div style="display: flex; align-items: center">
              <span style="padding-right: 20px;padding-left: 14px">${item.label}</span>
              <span>${item.value?.toFixed(2) ?? '--'}kWh</span>
            </div>
        `;
            })
            .join('');
          return timeDom + otherDoms + extrals;
        },
      },

      xAxis: {
        type: 'category',
        data: xData,
        axisLabel: {
          formatter: function (value: number) {
            return dayjs(+value).format(formateStr);
          },
        },
      },
      yAxis: {
        type: 'value',
        name: 'kWh',
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
      },
      series: [
        {
          name: '充电量',
          data: chartData?.map(item =>
            sumTools([item.summitCharge, item.peakCharge, item.plainCharge, item.valleyCharge])
          ),
          type: 'bar',
          markLine: getMarkLine('平均充电量', 'insideEndTop', averageCharge),
        },
        {
          name: '放电量',
          data: chartData?.map(item =>
            sumTools([item.summitDischarge, item.peakDischarge, item.plainDischarge, item.valleyDischarge])
          ),
          type: 'bar',
          markLine: getMarkLine('平均放电量', 'insideEndBottom', averageDisCharge),
        },
      ],
    };
  };
  return (
    <div>
      <Row style={{ padding: '0 20px' }} gutter={30} align={'middle'}>
        <Space size={20}>
          <Space>
            颗粒度:
            <Radio.Group buttonStyle={'solid'} value={currentGrain} onChange={e => setCurrentGrain(e.target.value)}>
              <Radio.Button value={GrainChoice.MINUTE_15}>15分钟</Radio.Button>
              <Radio.Button value={GrainChoice.HOUR}>1小时</Radio.Button>
              <Radio.Button value={GrainChoice.DAY}>日</Radio.Button>
              <Radio.Button value={GrainChoice.MONTH}>月</Radio.Button>
            </Radio.Group>
          </Space>
          <Space>
            {currentGrain === GrainChoice.MONTH ? '选择月份' : '选择日期'}:
            <RangePicker
              picker={currentGrain === GrainChoice.MONTH ? 'month' : 'date'}
              allowClear={false}
              value={dates || rangeTimeValue}
              disabledDate={disabledDate}
              onCalendarChange={val => {
                setDates(val);
              }}
              onChange={val => {
                setRangeTimeValue(val);
              }}
              onOpenChange={onOpenChange}
            />
          </Space>
          <Button type="primary" onClick={exportBtn}>
            导出
          </Button>
        </Space>
      </Row>

      <div className={styles.card_area}>
        <Space>
          <CardItem title="充电总量(kWh)" value={totalCharge} />
          <CardItem title="放电总量(kWh)" value={totalDisCharge} />
        </Space>
      </div>

      <Spin spinning={false}>
        <div className={styles.echart_box}>
          {loading ? (
            <Skeleton />
          ) : isDataEmpty ? (
            <Empty />
          ) : (
            <ReactEcharts
              option={getChartOption()}
              style={{ height: 'calc(100% - 30px)' }}
              notMerge
              lazyUpdate={false}
            />
          )}
        </div>
      </Spin>
    </div>
  );
};

export default ChargingAmout;
