import boardBg from '../../assets/images/energyGateway/board@2x.png';
import energyLocation from '../../assets/images/energyGateway/energyStorage-line@2x.png';

export const formatNumber = (number: number | null | undefined, decimal: number = 1) => {
  if (number === null || number === undefined) {
    return '--';
  }
  const roundedNumber = number.toFixed(decimal);
  if (roundedNumber.endsWith('.00')) {
    return parseInt(roundedNumber, 10);
  }
  return parseFloat(roundedNumber);
};

const imagePaths = {
  energyLocation,
  boardBg,
};

const initialState = {
  pvPanelData: {
    bottom: 40,
    left: 21,
    stationNum: null,
    stationName: '光伏站',
    deviceNum: null,
    deviceName: '逆变器',
    devicePositonLeft: 109,
    totalCapacity: null,
    totalCapacityPositonLeft: 190,
    capacityUnit: '总装机容量(MW)',
    panelPicUrl: imagePaths.boardBg,
    locationPicUrl: imagePaths.energyLocation,
    borderColorValue: '#00ADFF',
    listData: [],
  },
  chargePanelData: {
    bottom: 60,
    left: 90,
    stationNum: null,
    stationName: '充电站',
    deviceNum: null,
    deviceName: '充电桩数',
    devicePositonLeft: 109,
    totalCapacity: null,
    totalCapacityPositonLeft: 190,
    capacityUnit: '总装机容量(MW)',
    panelPicUrl: imagePaths.boardBg,
    locationPicUrl: imagePaths.energyLocation,
    borderColorValue: '#00ADFF',
    listData: [],
  },
  bsaPanelData: {
    bottom: 17,
    left: 37,
    stationNum: null,
    stationName: '储能站',
    deviceNum: null,
    deviceName: '储能柜',
    devicePositonLeft: 109,
    totalCapacity: null,
    totalCapacityPositonLeft: 190,
    capacityUnit: '总装机容量(MW/MWh)',
    panelPicUrl: imagePaths.boardBg,
    locationPicUrl: imagePaths.energyLocation,
    borderColorValue: '#00ADFF',
    listData: [],
  },
  leanManagePanelData: {
    bottom: 70,
    left: 31,
    type: 'leanManage',
    stationNum: null,
    stationName: '日综合能耗',
    stationNameWrap: '(kgce)',
    deviceNum: null,
    deviceName: '日产量',
    devicePositonLeft: 109,
    totalCapacity: null,
    totalCapacityPositonLeft: 190,
    capacityUnit: '开线状态',
    panelPicUrl: imagePaths.boardBg,
    locationPicUrl: imagePaths.energyLocation,
    borderColorValue: '#00ADFF',
    listData: [],
  },
  basicManagePanelData: {
    bottom: 41,
    left: 68,
    stationNum: null,
    stationName: '回路数',
    deviceNum: null,
    deviceName: '水监测点数',
    devicePositonLeft: 109,
    totalCapacity: null,
    totalCapacityPositonLeft: 190,
    capacityUnit: '气监测点数',
    panelPicUrl: imagePaths.boardBg,
    locationPicUrl: imagePaths.energyLocation,
    borderColorValue: '#00ADFF',
    listData: [],
  },
  importPanelData: {
    bottom: 60,
    left: 53,
    stationNum: null,
    stationName: '绿色能源使用率',
    deviceNum: null,
    deviceName: '累计碳减排',
    deviceNameWrap: '(tCO2e)',
    devicePositonLeft: 109,
    totalCapacity: null,
    totalCapacityPositonLeft: 190,
    capacityUnit: '单位产值能源成本',
    capacityUnitWrap: '(元/万元)',
    panelPicUrl: imagePaths.boardBg,
    locationPicUrl: imagePaths.energyLocation,
    borderColorValue: '#00ADFF',
    listData: [],
  },
};

export default initialState;
