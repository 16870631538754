import { forwardRef, useImperativeHandle } from 'react';
import { ChildDataRef } from '../const';

const EmptyForm = forwardRef<ChildDataRef>((props, ref) => {
  useImperativeHandle(ref, () => ({
    saveForm: () => {},
    saveDraft: () => {},
  }));

  return <></>;
});

export default EmptyForm;
