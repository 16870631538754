import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React, { FC } from 'react';
import { HealthParamsType } from '.';
import { Form, InputNumber, Radio } from '@maxtropy/components';

interface HealthFormItemProps {
  itemHealthSelect: HealthParamsType;
  disabled?: boolean;
}

// 层数枚举类型
export enum LayerNumberPropsType {
  Two = 2, // 2层
  Four = 4, // 4层
}

// 层数枚举类型的值
export const LayerNumberPropsTypeValue = {
  [LayerNumberPropsType.Two]: '2',
  [LayerNumberPropsType.Four]: '4',
};

const HealthFormItem: FC<HealthFormItemProps> = props => {
  const { itemHealthSelect, disabled } = props;

  const limitDecimalsF = (value: any) => {
    let reg = /^(-)*(\d+)\.(\d\d).*$/;
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace(reg, '$1$2.$3');
  };
  const limitDecimalsPOne = (value: any) => {
    let reg = /^(-)*(\d+)\.(\d).*$/;
    return value.replace(/￥\s?|(,*)/g, '').replace(reg, '$1$2.$3');
  };
  const limitDecimalsPTwo = (value: any) => {
    let reg = /^(-)*(\d+)\.(\d\d).*$/;
    return value.replace(/￥\s?|(,*)/g, '').replace(reg, '$1$2.$3');
  };

  return (
    <>
      {itemHealthSelect === HealthParamsType.HASAMBIENTTEMPERATURECONFIG ? (
        <>
          <Form.Item
            label="环境温度限制层数"
            name="ambientTemperatureLayerNumber"
            rules={[{ required: true, message: '请选择环境温度限制层数' }]}
          >
            <Radio.Group buttonStyle="solid" disabled={disabled}>
              <Radio.Button value={LayerNumberPropsType.Two}>
                {LayerNumberPropsTypeValue[LayerNumberPropsType.Two]}
              </Radio.Button>
              <Radio.Button value={LayerNumberPropsType.Four}>
                {LayerNumberPropsTypeValue[LayerNumberPropsType.Four]}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item noStyle dependencies={['ambientTemperatureLayerNumber']}>
            {({ getFieldValue }) =>
              getFieldValue('ambientTemperatureLayerNumber') === LayerNumberPropsType.Two && (
                <>
                  <Row>
                    <Col span={10}>
                      <Form.Item
                        label="环境温度上限1设定"
                        name="ambientTemperatureUpperOne"
                        labelCol={{ flex: '150px' }}
                        rules={[{ required: true, message: '请输入环境温度上限1设定' }]}
                      >
                        <InputNumber
                          disabled={disabled}
                          style={{ width: '100%' }}
                          placeholder="请输入"
                          addonAfter="℃"
                          formatter={limitDecimalsF}
                          parser={limitDecimalsPOne}
                          step="0.1"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <InfoCircleOutlined style={{ color: 'red' }} />
                      <span style={{ paddingLeft: '5px' }}>上限必须高于下限，上限1必须大于上限2</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10}>
                      <Form.Item
                        label="环境温度下限1设定"
                        name="ambientTemperatureLowerOne"
                        labelCol={{ flex: '150px' }}
                        rules={[{ required: true, message: '请输入环境温度下限1设定' }]}
                      >
                        <InputNumber
                          disabled={disabled}
                          style={{ width: '100%' }}
                          placeholder="请输入"
                          addonAfter="℃"
                          formatter={limitDecimalsF}
                          parser={limitDecimalsPOne}
                          step="0.1"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <InfoCircleOutlined style={{ color: 'red' }} />
                      <span style={{ paddingLeft: '5px' }}>下限必须低于上限，下限1必须小于下限2</span>
                    </Col>
                  </Row>
                </>
              )
            }
          </Form.Item>
          <Form.Item noStyle dependencies={['ambientTemperatureLayerNumber']}>
            {({ getFieldValue }) =>
              getFieldValue('ambientTemperatureLayerNumber') === LayerNumberPropsType.Four && (
                <>
                  <Row>
                    <Col span={8}>
                      <Form.Item
                        label="环境温度上限1设定"
                        name="ambientTemperatureUpperOne"
                        labelCol={{ flex: '150px' }}
                        rules={[{ required: true, message: '请输入环境温度上限1设定' }]}
                      >
                        <InputNumber
                          disabled={disabled}
                          style={{ width: '100%' }}
                          placeholder="请输入"
                          addonAfter="℃"
                          formatter={limitDecimalsF}
                          parser={limitDecimalsPOne}
                          step="0.1"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="环境温度上限2设定"
                        name="ambientTemperatureUpperTwo"
                        labelCol={{ flex: '150px' }}
                        rules={[{ required: true, message: '请输入环境温度上限2设定' }]}
                      >
                        <InputNumber
                          disabled={disabled}
                          style={{ width: '100%' }}
                          placeholder="请输入"
                          addonAfter="℃"
                          formatter={limitDecimalsF}
                          parser={limitDecimalsPOne}
                          step="0.1"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <InfoCircleOutlined style={{ color: 'red' }} />
                      <span style={{ paddingLeft: '5px' }}>上限必须高于下限，上限1必须大于上限2</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <Form.Item
                        label="环境温度下限1设定"
                        name="ambientTemperatureLowerOne"
                        labelCol={{ flex: '150px' }}
                        rules={[{ required: true, message: '请输入环境温度下限1设定' }]}
                      >
                        <InputNumber
                          disabled={disabled}
                          style={{ width: '100%' }}
                          placeholder="请输入"
                          addonAfter="℃"
                          formatter={limitDecimalsF}
                          parser={limitDecimalsPOne}
                          step="0.1"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="环境温度下限2设定"
                        name="ambientTemperatureLowerTwo"
                        labelCol={{ flex: '150px' }}
                        rules={[{ required: true, message: '请输入环境温度下限2设定' }]}
                      >
                        <InputNumber
                          disabled={disabled}
                          style={{ width: '100%' }}
                          placeholder="请输入"
                          addonAfter="℃"
                          formatter={limitDecimalsF}
                          parser={limitDecimalsPOne}
                          step="0.1"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <InfoCircleOutlined style={{ color: 'red' }} />
                      <span style={{ paddingLeft: '5px' }}>下限必须低于上限，下限1必须小于下限2</span>
                    </Col>
                  </Row>
                </>
              )
            }
          </Form.Item>
        </>
      ) : // 单体温度参数
      itemHealthSelect === HealthParamsType.HASMONOMERTEMPERATURECONFIG ? (
        <>
          <Form.Item
            label="单体温度限制层数"
            name="monomerTemperatureLayerNumber"
            rules={[{ required: true, message: '请选择单体温度限制层数' }]}
          >
            <Radio.Group buttonStyle="solid" disabled={disabled}>
              <Radio.Button value={LayerNumberPropsType.Two}>
                {LayerNumberPropsTypeValue[LayerNumberPropsType.Two]}
              </Radio.Button>
              <Radio.Button value={LayerNumberPropsType.Four}>
                {LayerNumberPropsTypeValue[LayerNumberPropsType.Four]}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item noStyle dependencies={['monomerTemperatureLayerNumber']}>
            {({ getFieldValue }) =>
              getFieldValue('monomerTemperatureLayerNumber') === LayerNumberPropsType.Two && (
                <>
                  <Row>
                    <Col span={8}>
                      <Form.Item
                        label="单体温度上限1设定"
                        name="monomerTemperatureUpperOne"
                        labelCol={{ flex: '150px' }}
                        rules={[{ required: true, message: '请输入单体温度上限1设定' }]}
                      >
                        <InputNumber
                          disabled={disabled}
                          style={{ width: '100%' }}
                          placeholder="请输入"
                          addonAfter="℃"
                          formatter={limitDecimalsF}
                          parser={limitDecimalsPOne}
                          step="0.1"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <InfoCircleOutlined style={{ color: 'red' }} />
                      <span style={{ paddingLeft: '5px' }}>上限必须高于下限，上限1必须大于上限2</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <Form.Item
                        label="单体温度下限1设定"
                        name="monomerTemperatureLowerOne"
                        labelCol={{ flex: '150px' }}
                        rules={[{ required: true, message: '请输入单体温度下限1设定' }]}
                      >
                        <InputNumber
                          disabled={disabled}
                          style={{ width: '100%' }}
                          placeholder="请输入"
                          addonAfter="℃"
                          formatter={limitDecimalsF}
                          parser={limitDecimalsPOne}
                          step="0.1"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <InfoCircleOutlined style={{ color: 'red' }} />
                      <span style={{ paddingLeft: '5px' }}>下限必须低于上限，下限1必须小于下限2</span>
                    </Col>
                  </Row>
                </>
              )
            }
          </Form.Item>
          <Form.Item noStyle dependencies={['monomerTemperatureLayerNumber']}>
            {({ getFieldValue }) =>
              getFieldValue('monomerTemperatureLayerNumber') === LayerNumberPropsType.Four && (
                <>
                  <Row>
                    <Col span={8}>
                      <Form.Item
                        label="单体温度上限1设定"
                        name="monomerTemperatureUpperOne"
                        labelCol={{ flex: '150px' }}
                        rules={[{ required: true, message: '请输入单体温度上限1设定' }]}
                      >
                        <InputNumber
                          disabled={disabled}
                          style={{ width: '100%' }}
                          placeholder="请输入"
                          addonAfter="℃"
                          formatter={limitDecimalsF}
                          parser={limitDecimalsPOne}
                          step="0.1"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="单体温度上限2设定"
                        name="monomerTemperatureUpperTwo"
                        labelCol={{ flex: '150px' }}
                        rules={[{ required: true, message: '请输入单体温度上限2设定' }]}
                      >
                        <InputNumber
                          disabled={disabled}
                          style={{ width: '100%' }}
                          placeholder="请输入"
                          addonAfter="℃"
                          formatter={limitDecimalsF}
                          parser={limitDecimalsPOne}
                          step="0.1"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <InfoCircleOutlined style={{ color: 'red' }} />
                      <span style={{ paddingLeft: '5px' }}>上限必须高于下限，上限1必须大于上限2</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <Form.Item
                        label="单体温度下限1设定"
                        name="monomerTemperatureLowerOne"
                        labelCol={{ flex: '150px' }}
                        rules={[{ required: true, message: '请输入单体温度下限1设定' }]}
                      >
                        <InputNumber
                          disabled={disabled}
                          style={{ width: '100%' }}
                          placeholder="请输入"
                          addonAfter="℃"
                          formatter={limitDecimalsF}
                          parser={limitDecimalsPOne}
                          step="0.1"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="单体温度下限2设定"
                        name="monomerTemperatureLowerTwo"
                        labelCol={{ flex: '150px' }}
                        rules={[{ required: true, message: '请输入单体温度下限2设定' }]}
                      >
                        <InputNumber
                          disabled={disabled}
                          style={{ width: '100%' }}
                          placeholder="请输入"
                          addonAfter="℃"
                          formatter={limitDecimalsF}
                          parser={limitDecimalsPOne}
                          step="0.1"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <InfoCircleOutlined style={{ color: 'red' }} />
                      <span style={{ paddingLeft: '5px' }}>下限必须低于上限，下限1必须小于下限2</span>
                    </Col>
                  </Row>
                </>
              )
            }
          </Form.Item>
        </>
      ) : itemHealthSelect === HealthParamsType.HASMONOMERVOLTAGECONFIG ? (
        <>
          <Form.Item
            label="单体电压限制层数"
            name="monomerVoltageLayerNumber"
            rules={[{ required: true, message: '请选择单体电压限制层数' }]}
          >
            <Radio.Group buttonStyle="solid" disabled={disabled}>
              <Radio.Button value={LayerNumberPropsType.Two}>
                {LayerNumberPropsTypeValue[LayerNumberPropsType.Two]}
              </Radio.Button>
              <Radio.Button value={LayerNumberPropsType.Four}>
                {LayerNumberPropsTypeValue[LayerNumberPropsType.Four]}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item noStyle dependencies={['monomerVoltageLayerNumber']}>
            {({ getFieldValue }) =>
              getFieldValue('monomerVoltageLayerNumber') === LayerNumberPropsType.Two && (
                <>
                  <Row>
                    <Col span={8}>
                      <Form.Item
                        label="单体电压上限1设定"
                        name="monomerVoltageUpperOne"
                        labelCol={{ flex: '150px' }}
                        rules={[{ required: true, message: '请输入单体电压上限1设定' }]}
                      >
                        <InputNumber
                          disabled={disabled}
                          style={{ width: '100%' }}
                          placeholder="请输入"
                          addonAfter="V"
                          formatter={limitDecimalsF}
                          parser={limitDecimalsPOne}
                          step="0.1"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <InfoCircleOutlined style={{ color: 'red' }} />
                      <span style={{ paddingLeft: '5px' }}>上限必须高于下限，上限1必须大于上限2</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <Form.Item
                        label="单体电压下限1设定"
                        name="monomerVoltageLowerOne"
                        labelCol={{ flex: '150px' }}
                        rules={[{ required: true, message: '请输入单体电压下限1设定' }]}
                      >
                        <InputNumber
                          disabled={disabled}
                          style={{ width: '100%' }}
                          placeholder="请输入"
                          addonAfter="V"
                          formatter={limitDecimalsF}
                          parser={limitDecimalsPOne}
                          step="0.1"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <InfoCircleOutlined style={{ color: 'red' }} />
                      <span style={{ paddingLeft: '5px' }}>下限必须低于上限，下限1必须小于下限2</span>
                    </Col>
                  </Row>
                </>
              )
            }
          </Form.Item>
          <Form.Item noStyle dependencies={['monomerVoltageLayerNumber']}>
            {({ getFieldValue }) =>
              getFieldValue('monomerVoltageLayerNumber') === LayerNumberPropsType.Four && (
                <>
                  <Row>
                    <Col span={8}>
                      <Form.Item
                        label="单体电压上限1设定"
                        name="monomerVoltageUpperOne"
                        labelCol={{ flex: '150px' }}
                        rules={[{ required: true, message: '请输入单体电压上限1设定' }]}
                      >
                        <InputNumber
                          disabled={disabled}
                          style={{ width: '100%' }}
                          placeholder="请输入"
                          addonAfter="V"
                          formatter={limitDecimalsF}
                          parser={limitDecimalsPOne}
                          step="0.1"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="单体电压上限2设定"
                        name="monomerVoltageUpperTwo"
                        labelCol={{ flex: '150px' }}
                        rules={[{ required: true, message: '请输入单体电压上限2设定' }]}
                      >
                        <InputNumber
                          disabled={disabled}
                          style={{ width: '100%' }}
                          placeholder="请输入"
                          addonAfter="V"
                          formatter={limitDecimalsF}
                          parser={limitDecimalsPOne}
                          step="0.1"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <InfoCircleOutlined style={{ color: 'red' }} />
                      <span style={{ paddingLeft: '5px' }}>上限必须高于下限，上限1必须大于上限2</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <Form.Item
                        label="单体电压下限1设定"
                        name="monomerVoltageLowerOne"
                        labelCol={{ flex: '150px' }}
                        rules={[{ required: true, message: '请输入单体电压下限1设定' }]}
                      >
                        <InputNumber
                          disabled={disabled}
                          style={{ width: '100%' }}
                          placeholder="请输入"
                          addonAfter="V"
                          formatter={limitDecimalsF}
                          parser={limitDecimalsPOne}
                          step="0.1"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="单体电压下限2设定"
                        name="monomerVoltageLowerTwo"
                        labelCol={{ flex: '150px' }}
                        rules={[{ required: true, message: '请输入单体电压下限2设定' }]}
                      >
                        <InputNumber
                          disabled={disabled}
                          style={{ width: '100%' }}
                          placeholder="请输入"
                          addonAfter="V"
                          formatter={limitDecimalsF}
                          parser={limitDecimalsPOne}
                          step="0.1"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <InfoCircleOutlined style={{ color: 'red' }} />
                      <span style={{ paddingLeft: '5px' }}>下限必须低于上限，下限1必须小于下限2</span>
                    </Col>
                  </Row>
                </>
              )
            }
          </Form.Item>
        </>
      ) : itemHealthSelect === HealthParamsType.HASVOLTAGEUNIFORMCONFIG ? (
        <>
          <Form.Item
            label="电压偏差幅度"
            name="voltageUniformDeviation"
            labelCol={{ flex: '150px' }}
            rules={[{ required: true, message: '请输入电压偏差幅度' }]}
          >
            <InputNumber
              disabled={disabled}
              placeholder="建议不大于0.4"
              min={0.01}
              addonBefore="±"
              addonAfter="V"
              step="0.01"
              formatter={limitDecimalsF}
              parser={limitDecimalsPTwo}
            />
          </Form.Item>
          <Form.Item
            label="最高充电电压"
            name="chargeVoltageUpper"
            labelCol={{ flex: '150px' }}
            rules={[{ required: true, message: '请输入最高充电电压' }]}
          >
            <InputNumber
              disabled={disabled}
              placeholder="请输入"
              min={0.1}
              max={999.9}
              addonAfter="V"
              step="0.1"
              formatter={limitDecimalsF}
              parser={limitDecimalsPOne}
            />
          </Form.Item>
        </>
      ) : itemHealthSelect === HealthParamsType.HASTEMPERATUREUNIFORMCONFIG ? (
        <Form.Item
          label="温度偏差幅度"
          name="temperatureUniformDeviation"
          labelCol={{ flex: '150px' }}
          rules={[{ required: true, message: '请输入温度偏差幅度' }]}
        >
          <InputNumber
            disabled={disabled}
            placeholder="建议不大于10"
            min={0.1}
            addonBefore="±"
            addonAfter="℃"
            step="0.1"
            formatter={limitDecimalsF}
            parser={limitDecimalsPOne}
          />
        </Form.Item>
      ) : (
        <></>
      )}
    </>
  );
};

export default HealthFormItem;
