import { Button, Form, Input, Modal, Radio, SubContent, Tag, TimePicker } from '@maxtropy/components';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { useRequest } from 'ahooks';
import {
  apiV2PvDispersionRateAnalysisSetUpPost,
  apiV2PvDispersionRateGetAnalysisConfigPost,
} from '@maxtropy/dmes-apis-v2';
import { Col, Row, Space, Spin } from 'antd';
import ExcludeTable, { ExcludeTableRef } from '../components/ExcludeTable';
import { ExcludeType, PvAreaContext } from '../const';
import ArrayStruct from './ArrayStruct';
import TimeSetting from './TimeSetting';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';

const Index = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = useForm();
  const [areaIdList, setAreaIdList] = useState<number[]>([]);
  const [pvStationName, setPvStationName] = useState<string>('');
  const [existComponents, setExistComponents] = useState<boolean>(false); // 光伏站是否存在组件
  const stringExcludeTableRef = useRef<ExcludeTableRef>(null);
  const componentExcludeTableRef = useRef<ExcludeTableRef>(null);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const { data, loading } = useRequest(
    async () => {
      const res = await apiV2PvDispersionRateGetAnalysisConfigPost({ id: id });
      form.setFieldsValue({
        ...res,
        localAreaIdList: res.areaNameList?.map(item => [res.pvId, item.areaId]),
        analysisStartTime: dayjs(res.analysisStartTime, 'HH:mm'),
        analysisEndTime: dayjs(res.analysisEndTime, 'HH:mm'),
      });
      return res;
    },
    {
      ready: !!id,
    }
  );

  const onSave = () => {
    form.validateFields().then(values => {
      setConfirmLoading(true);
      apiV2PvDispersionRateAnalysisSetUpPost({
        id: data?.id,
        configName: values.configName,
        analysisStartTime: values.analysisStartTime.format('HH:mm'),
        analysisEndTime: values.analysisEndTime.format('HH:mm'),
        resolution: values.resolution,
        pvId: values.localAreaIdList[0][0],
        areaIdList: areaIdList,
        pvStringIdAddList: stringExcludeTableRef.current?.getAddObjectIdList() ?? [],
        pvStringIdDelList: stringExcludeTableRef.current?.getDelObjectIdList()?.map(item => item.objectId) ?? [],
        pvModuleIdAddList: componentExcludeTableRef.current?.getAddObjectIdList() ?? [],
        pvModuleIdDelList: componentExcludeTableRef.current?.getDelObjectIdList()?.map(item => item.objectId) ?? [],
      })
        .then(() => {
          navigate('/pv/dispersionRate/config');
        })
        .finally(() => {
          setConfirmLoading(false);
        });
    });
  };

  return (
    <PvAreaContext.Provider
      value={{
        areaIdList,
        setAreaIdList,
        pvStationName,
        setPvStationName,
        existComponents,
        setExistComponents,
      }}
    >
      <Spin spinning={loading}>
        <Form form={form}>
          <Row>
            <Col span={24}>
              <SubContent>
                <Form.Item label="设置组名称" name="configName" rules={[{ required: true }]}>
                  <Input maxLength={30}></Input>
                </Form.Item>
              </SubContent>
            </Col>
          </Row>

          <ArrayStruct form={form} resData={data}></ArrayStruct>

          <TimeSetting form={form}></TimeSetting>
        </Form>
        <SubContent
          title="排除组串设置"
          extraContent={
            <Space size={5} style={{ flex: 1, marginLeft: 9 }}>
              <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
              <span
                style={{
                  color: 'var(--mx-text-desc-color)',
                }}
              >
                被排除组串将不参与所属逆变器的组串电流离散率计算以及对应组串的电流离散率和温度离散率计算
              </span>
            </Space>
          }
        >
          <ExcludeTable
            ref={stringExcludeTableRef}
            isEdit={true}
            excludeType={ExcludeType.STRING}
            id={+id}
          ></ExcludeTable>
        </SubContent>

        {existComponents && (
          <SubContent
            title="排除组件设置"
            extraContent={
              <Space size={5} style={{ flex: 1, marginLeft: 9 }}>
                <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
                <span
                  style={{
                    color: 'var(--mx-text-desc-color)',
                  }}
                >
                  被排除组件将不参与所属组串的电流离散率和温度离散率计算
                </span>
              </Space>
            }
          >
            <ExcludeTable
              ref={componentExcludeTableRef}
              isEdit={true}
              excludeType={ExcludeType.COMPONENT}
              id={+id}
            ></ExcludeTable>
          </SubContent>
        )}

        <Space size={8} className="sticky-footer-left" style={{ paddingLeft: 32 }}>
          <Button type="primary" onClick={onSave} loading={confirmLoading}>
            保存
          </Button>
          <Button
            onClick={() => {
              Modal.confirm({
                title: '这样做会造成未保存的信息丢失，确定要这样做吗？',
                onOk: () => {
                  navigate('/pv/dispersionRate/config');
                },
              });
            }}
          >
            取消
          </Button>
        </Space>
      </Spin>
    </PvAreaContext.Provider>
  );
};

export default Index;
