import { getEnergyAssetProjectInfo, OuList, ProjectInfoRes } from '@/api/energyAssetOverview';
import { Col, Divider, Row, Spin } from 'antd';
import { FC, useContext, useEffect, useState, useMemo } from 'react';
import HeadTitle from '../HeadTitle/HeadTitle';
import ValueLineTitle from '../ValueLineTitle';
import userOutlined from '../../imgs/user-outlined.png';
import locationOutlined from '../../imgs/location-outlined.png';
import styles from './index.module.scss';
import { isNil } from 'lodash-es';
import { StationHasCount } from '../..';

export interface IProjectInfo {
  ouInfo: OuList;
  height: string | undefined;
}

const ProjectInfo: FC<IProjectInfo> = props => {
  const { ouInfo, height } = props;
  const stationHasCount = useContext(StationHasCount);
  const [projectInfo, setProjectInfo] = useState<ProjectInfoRes>();
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    if (ouInfo.id) {
      // 查询项目信息
      setDataLoading(true);
      getEnergyAssetProjectInfo({ ouId: ouInfo.id }).then(res => {
        setProjectInfo(res);
        setDataLoading(false);
      });
    }
  }, [ouInfo]);

  const dataCount = useMemo(() => {
    return [stationHasCount?.bsaCount, stationHasCount?.evCount, stationHasCount?.pvCount].filter(Boolean).length;
  }, [stationHasCount]);
  const colSpan = useMemo(() => {
    if (dataCount === 1) return 24;
    if (dataCount === 2) return 12;
    if (dataCount === 3) return 12;
    return 24;
  }, [dataCount]);

  return (
    <div
      className={styles.ColSty}
      style={{
        height: height,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <HeadTitle title="项目信息" />
      <Spin spinning={dataLoading}>
        <Row>
          {stationHasCount?.pvCount && (
            <Col span={colSpan}>
              <ValueLineTitle
                value={
                  stationHasCount?.pvCount
                    ? !isNil(projectInfo?.pvInstalledCapacity)
                      ? projectInfo?.pvInstalledCapacity! / 1000
                      : undefined
                    : '无光伏站'
                }
                title={'光伏站(MW)'}
              />
            </Col>
          )}
          {stationHasCount?.bsaCount && (
            <Col span={colSpan}>
              <ValueLineTitle
                value={
                  stationHasCount?.bsaCount
                    ? (!isNil(projectInfo?.bsaRatedPower) ? projectInfo?.bsaRatedPower : '--') +
                      '/' +
                      (!isNil(projectInfo?.bsaInstalledCapacity) ? projectInfo?.bsaInstalledCapacity : '--')
                    : '无储能站'
                }
                title={'储能站(MW/MWh)'}
              />
            </Col>
          )}
          {stationHasCount?.evCount && (
            <Col span={colSpan}>
              <ValueLineTitle
                value={stationHasCount?.evCount ? projectInfo?.evInstalledCapacity : '无充电站'}
                title={'充电站(MW)'}
              />
            </Col>
          )}

          <Col span={dataCount === 3 ? 12 : 24}>
            <div className={styles.ProjectaddressStyle}>
              <div className={styles.eachProinfo}>
                <img className={styles.imgSty} src={userOutlined} alt="" />
                <span className={styles.persenSty} title={`负责人：${projectInfo?.directorName ?? '--'}`}>
                  负责人：{projectInfo?.directorName === '' ? '--' : projectInfo?.directorName ?? '--'}
                </span>
              </div>
              <Divider />
              <div className={styles.eachAddress}>
                <img className={styles.imgSty} src={locationOutlined} alt="" />
                <div className={styles.addressSty} title={`项目地址：${projectInfo?.projectAddress ?? '--'}`}>
                  项目地址：{projectInfo?.projectAddress === '' ? '--' : projectInfo?.projectAddress ?? '--'}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default ProjectInfo;
