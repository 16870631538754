import { CSSProperties, FC, ReactNode } from 'react';

import styles from './index.module.scss';

interface DividerContentProps {
  title?: ReactNode;
  style?: CSSProperties;
  children?: React.ReactNode;
}

const DividerContent: FC<DividerContentProps> = props => {
  const { title, children, style } = props;

  return (
    <div className={styles.contentWrapper}>
      {title !== undefined && <div className={styles.divider}>{title}</div>}
      <div className={styles.content} style={style}>
        {children}
      </div>
    </div>
  );
};

export default DividerContent;
