import { getBsaCostInfo } from '@/api/bsa';
import { getPvCostInfo } from '@/api/pv';
import ShowInput from '@/components/ShowInput';
import { Form, Modal, Button } from '@maxtropy/components';
import { Space } from 'antd';
import { useEffect, useMemo } from 'react';
interface Iprops {
  cancel?: () => void;
  confirm?: () => void;
  id?: number;
  source?: string; // bsa, pv
}
const ViewCostModal: React.FC<Iprops> = props => {
  const [form] = Form.useForm();
  const getApi = useMemo(() => {
    return props.source === 'pv' ? getPvCostInfo : getBsaCostInfo;
  }, [props.source]);
  useEffect(() => {
    if (props.id) {
      getApi(props.id).then(res => {
        form.setFieldsValue(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id, form]);
  const cancelBtn = () => {
    props.cancel?.();
  };

  const settlementWay = Form.useWatch('settlementWay', form);

  const settlementWayLabel = useMemo(() => {
    if (settlementWay === 1) return '用电折扣';
    else if (settlementWay === 2) return '收益分成';
    else return '--';
  }, [settlementWay]);

  return (
    <Modal
      open
      title="查看成本信息"
      size="small"
      onCancel={cancelBtn}
      contentClassName="modal-form-content"
      footer={
        <Space>
          <Button type="primary" onClick={cancelBtn}>
            好的
          </Button>
        </Space>
      }
    >
      <Form form={form}>
        <Form.Item name="investmentCost" label="投建成本">
          <ShowInput addonAfter="万元" />
        </Form.Item>
        <Form.Item name="recoveredCost" label="已回收成本">
          <ShowInput addonAfter="万元" />
        </Form.Item>
        <Form.Item name="electricityDiscount" label="用电折扣">
          <ShowInput />
        </Form.Item>
        {props.source === 'pv' && (
          <Form.Item name="internetRevenueSharing" label="上网分成比例">
            <ShowInput />
          </Form.Item>
        )}
        {props.source === 'bsa' && (
          <>
            <Form.Item name="settlementWay" label="结算方式">
              <Form.Item noStyle>
                <ShowInput value={settlementWayLabel} />
              </Form.Item>
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default ViewCostModal;
