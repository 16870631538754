import React, { createContext, useState, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { GrainChoice } from '@/api/device';
import { getRacks, getStacks, Rack, Stack } from '@/api/bsa';
import dayjs from 'dayjs';

enum TimeChoice {
  TODAY,
  LAST_24_HOURS,
  CUSTOM,
}

enum LevelChoice {
  STACK,
  RACK,
  PACK,
}

interface MyContextValue {
  timeChoice: number;
  grainChoice: GrainChoice;
  levelChoice: LevelChoice;
  selectedStacks: number[];
  selectedRacks: number[];
  stacks: Stack[] | undefined;
  racks: Rack[] | undefined;

  customDateRange?: [dayjs.Dayjs, dayjs.Dayjs];
  comparePcs: boolean;
  setTimeChoice: React.Dispatch<React.SetStateAction<number>>;

  setGrainChoice: React.Dispatch<React.SetStateAction<GrainChoice>>;

  setLevelChoice: React.Dispatch<React.SetStateAction<LevelChoice>>;

  setSelectedStacks: React.Dispatch<React.SetStateAction<number[]>>;

  setSelectedRacks: React.Dispatch<React.SetStateAction<number[]>>;

  setCustomDateRange: any;

  setComparePcs: React.Dispatch<React.SetStateAction<boolean>>;
}

export const StorageContext = createContext<MyContextValue | null>(null);

interface StorageProviderProps {
  bsaId?: number;
  children: React.ReactNode;
}

export const StorageProvider: React.FC<StorageProviderProps> = ({ children, bsaId }) => {
  const [timeChoice, setTimeChoice] = useState<TimeChoice>(TimeChoice.TODAY);
  const [grainChoice, setGrainChoice] = useState<GrainChoice>(GrainChoice.MINUTE_1);
  const [levelChoice, setLevelChoice] = useState<LevelChoice>(LevelChoice.STACK);
  const [selectedStacks, setSelectedStacks] = useState<number[]>([]);
  const [selectedRacks, setSelectedRacks] = useState<number[]>([]);
  const [customDateRange, setCustomDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>();
  const [comparePcs, setComparePcs] = useState<boolean>(false);

  const MaxselectNums = 10;

  const { data: stacks } = useQuery(['stacks', bsaId], () => getStacks(Number(bsaId)), {
    enabled: !!bsaId,
  });

  const { data: racks } = useQuery(['racks', bsaId], () => getRacks(Number(bsaId)), {
    enabled: !!bsaId,
  });

  useEffect(() => {
    if (levelChoice === LevelChoice.RACK && racks && racks.length !== 0) {
      setSelectedRacks(racks!.map(item => item.rackId).slice(0, MaxselectNums));
    }
    if (levelChoice === LevelChoice.STACK && stacks && stacks.length !== 0) {
      setSelectedStacks(stacks!.map(item => item.stackId).slice(0, MaxselectNums));
    }
  }, [levelChoice, racks, stacks]);

  const contextValue = useMemo<MyContextValue>(
    () => ({
      timeChoice,
      setTimeChoice,
      grainChoice,
      setGrainChoice,
      levelChoice,
      setLevelChoice,
      selectedStacks,
      setSelectedStacks,
      selectedRacks,
      setSelectedRacks,
      stacks,
      racks,
      customDateRange,
      setCustomDateRange,
      comparePcs,
      setComparePcs,
    }),
    [
      timeChoice,
      setTimeChoice,
      grainChoice,
      setGrainChoice,
      levelChoice,
      setLevelChoice,
      selectedStacks,
      setSelectedStacks,
      selectedRacks,
      setSelectedRacks,
      stacks,
      racks,
      customDateRange,
      setCustomDateRange,
      comparePcs,
      setComparePcs,
    ]
  );

  return <StorageContext.Provider value={contextValue}>{children}</StorageContext.Provider>;
};
