import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { Space } from 'antd';

import { useParams } from 'react-router-dom';
import { BsaDeviceTypeEnum, DataProperty, DriveType, ProcessStatusEnum, TopologicalType } from '../../../../api/type';
import { BsaComponentsProps } from '../../factory';
import { BsaType } from '../../../../api/constans';
import { getGlobalEdgeConfig } from '../../../../api/edgeDevice';
import { getDataPropertyList } from '../../../../api/dataProperty';

import Point, { EdgeDeviceTemplatePoint, PointRef } from '../Point';
import styles from './index.module.scss';
import { getPointTemplatesByStackId } from '@/api/bsa';
import { Button, Form } from '@maxtropy/components';
import { PlusOutlined } from '@ant-design/icons';
export interface CollectionPointRef {
  getPointTemplates: () => Promise<EdgeDeviceTemplatePoint[]>;
}
interface Iprops extends BsaComponentsProps {
  editable?: boolean;
  updateTemplateClickState: boolean;
  updateTemplateState?: ProcessStatusEnum;
  stackId: number;
  templateData?: EdgeDeviceTemplatePoint[];
  shouldGetTemplate?: boolean;
  bsaDeviceType: BsaDeviceTypeEnum;
}
const CollectionPoint = forwardRef<CollectionPointRef, Iprops>((props, ref) => {
  const {
    disabled = false,
    typeList,
    currentType,
    editable = false,
    bsaDeviceType,
    updateTemplateClickState,
    updateTemplateState,
    templateData,
    stackId,
  } = props;
  const pointRef = useRef<PointRef>(null);

  const { id } = useParams<{ id: string }>();
  const [templateForm] = Form.useForm();

  const [data, setData] = useState<EdgeDeviceTemplatePoint[]>([]);

  const [topologicalType, setTopologicalType] = useState<TopologicalType>();

  const [updateOrCreate, setUpdateOrCreate] = useState<boolean>(false);

  // bug修改，如有状态为false，则为update而不是create
  useEffect(() => {
    if (id) {
      if (typeList && currentType) {
        if (typeList[typeList.length - 1] > currentType) {
          setUpdateOrCreate(true);
        }
      }
    }
  }, [id, typeList, currentType]);
  const currrentBsaType = useMemo(() => {
    return bsaDeviceType === BsaDeviceTypeEnum.RACK
      ? BsaType.RACK_DAQ_POINT_CREATE
      : bsaDeviceType === BsaDeviceTypeEnum.PACK
      ? BsaType.PACK_DAQ_POINT_CREATE
      : BsaType.CELL_DAQ_POINT_CREATE;
  }, [bsaDeviceType]);

  const isInit = useMemo(() => {
    return (typeList ?? []).includes(currrentBsaType);
  }, [typeList, currrentBsaType]);

  useEffect(() => {
    if (id) {
      getGlobalEdgeConfig(id).then(res => {
        setTopologicalType(res.topologicalType);
        templateForm.setFieldsValue({
          topologicalType: res.topologicalType,
          driveType: res.driveType,
        });
      });
    }
  }, [id, templateForm]);

  const [dataPropertiesAll, setDataPropertiesAll] = useState<DataProperty[]>([]);

  useEffect(() => {
    if (id) {
      if (Array.isArray(typeList)) {
        templateData && setData(templateData);
      }
    }
  }, [id, typeList, templateData]);

  useEffect(() => {
    if (id) {
      getDataPropertyList(id, bsaDeviceType).then(res => {
        setDataPropertiesAll(res.filter(i => !i.used));
      });
    }
  }, [id, bsaDeviceType]);

  useEffect(() => {
    if (id) {
      if (editable && updateTemplateState === ProcessStatusEnum.SUCCESS) {
        getPointTemplatesByStackId({
          bsaId: id,
          stackId: stackId,
          type: bsaDeviceType,
        }).then(res => {
          setData(res.templates);
        });
      }
    }
  }, [id, updateTemplateState, editable, bsaDeviceType, stackId]);

  const onAdd = () => {
    pointRef.current?.add();
  };

  // 获取数据点
  const getPointTemplates = async () => {
    let res = await pointRef.current?.validateFields();
    return res ?? [];
  };
  // 给父组件暴露值, 批量创建采集点时用
  useImperativeHandle(ref, () => ({
    getPointTemplates,
  }));

  // 添加采集点批量创建公式按钮 是否显示
  const showCreatePointsBtn = useMemo(() => {
    return !editable && !updateOrCreate ? !isInit : !updateTemplateClickState;
  }, [editable, updateOrCreate, isInit, updateTemplateClickState]);

  return (
    <>
      <div className={styles.pointsBox}>
        <div className={styles.title}>采集点</div>
        <div className={styles.divide}></div>
      </div>
      <Space style={{ marginBottom: 15 }}>
        {showCreatePointsBtn && (
          <Button type="primary" onClick={onAdd}>
            <PlusOutlined />
            添加采集点批量创建公式
          </Button>
        )}
      </Space>
      <Point
        ref={pointRef}
        driveType={DriveType.MODBUS_TCP}
        dataPropertiesAll={dataPropertiesAll}
        dataSource={data}
        bsaDeviceType={bsaDeviceType}
        editable={!(disabled || isInit) || (editable && !updateTemplateClickState)}
        fixed={(disabled || isInit) && editable && !updateTemplateClickState}
        topologicalType={topologicalType}
      />
    </>
  );
});

export default CollectionPoint;
