import React, { useContext, useEffect, useMemo, useState } from 'react';
import tittleDecoration from '../../imgs/tittleDecoration.png';
import styles from './index.module.scss';
import { Spin } from 'antd';
import { Empty, Tabs } from '@maxtropy/components';
import ReactEcharts from 'echarts-for-react';
import { useRequest } from 'ahooks';
import { ResolutionEnum, SitePerforAnalysisYearContext } from '../..';
import { apiV2PvPerformanceAnalysePowerPost } from '@maxtropy/dmes-apis-v2';
import { getChartOption } from './chart';
import { isNil } from 'lodash-es';

export enum ActiveKey {
  REALITY = '1',
  THEORY = '2',
  PLAN = '3',
}

const ElecForecast = () => {
  const [activeKey, setActiveKey] = useState<ActiveKey>(ActiveKey.REALITY);
  const { pvId, resolution, date } = useContext(SitePerforAnalysisYearContext);

  useEffect(() => {
    if (pvId) {
      setActiveKey(ActiveKey.REALITY);
    }
  }, [pvId]);

  const tabItems = useMemo(() => {
    if (resolution === ResolutionEnum.YEAR) {
      return [
        {
          label: '实际发电量',
          key: ActiveKey.REALITY,
        },
        {
          label: '理论发电量',
          key: ActiveKey.THEORY,
        },
        {
          label: '计划发电量',
          key: ActiveKey.PLAN,
        },
      ];
    } else {
      return [
        {
          label: '实际发电量',
          key: ActiveKey.REALITY,
        },
        {
          label: '理论发电量',
          key: ActiveKey.THEORY,
        },
      ];
    }
  }, [resolution]);

  // 发电趋势
  const { data: chartData, loading } = useRequest(
    () => {
      return apiV2PvPerformanceAnalysePowerPost({
        id: pvId,
        type: resolution,
        time: date?.valueOf(),
      });
    },
    {
      ready: !!pvId && !!date && !!resolution,
      refreshDeps: [pvId, date, resolution],
    }
  );

  // 判断三者是否都为空，如果都为空则显示empty
  const isShowEmpty = useMemo(() => {
    if (activeKey === ActiveKey.REALITY) {
      if (
        chartData?.list?.map(i => i.inverterPower).filter(c => !isNil(c)).length ||
        chartData?.list?.map(i => i.gridConnectedPower).filter(c => !isNil(c)).length ||
        chartData?.list?.map(i => i.cumulativeTiltTotalRadiation).filter(c => !isNil(c)).length
      ) {
        return false;
      }
    } else if (activeKey === ActiveKey.THEORY) {
      if (
        chartData?.list?.map(i => i.theoreticalPowerGeneration).filter(c => !isNil(c)).length ||
        chartData?.list?.map(i => i.inverterPower).filter(c => !isNil(c)).length ||
        chartData?.list?.map(i => i.systemEfficiency).filter(c => !isNil(c)).length
      ) {
        return false;
      }
    } else {
      if (
        chartData?.list?.map(i => i.inverterPower).filter(c => !isNil(c)).length ||
        chartData?.list?.map(i => i.planPowerGeneration).filter(c => !isNil(c)).length ||
        chartData?.list?.map(i => i.accomplishmentRatio).filter(c => !isNil(c)).length
      ) {
        return false;
      }
    }

    return true;
  }, [chartData, activeKey]);

  // 图表
  const chartOption = useMemo(
    () => getChartOption(chartData, activeKey, resolution),
    [chartData, activeKey, resolution]
  );

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <img src={tittleDecoration} alt="pic" className={styles.titleBorder} />
        <span className={styles.title}>电量预测</span>
      </div>
      <div className={styles.content_box}>
        <Tabs
          activeKey={activeKey}
          items={tabItems.map(m => ({
            label: m.label,
            key: m.key,
          }))}
          onChange={val => setActiveKey(val as ActiveKey)}
        />
        <div className={styles.chart_box}>
          {loading ? (
            <Spin spinning={true} className={styles.spin_box} />
          ) : isShowEmpty ? (
            <Empty className={styles.empty_box} />
          ) : (
            <ReactEcharts style={{ height: 340 }} option={chartOption} notMerge lazyUpdate={false} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ElecForecast;
