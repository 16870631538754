import { useUpdate } from '@maxtropy/components';
import { Spin } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFsm } from '../../api/bsa';
import { BsaType } from '../../api/constans';

import BsaPackEdgePoint from './components/PackEdgePoint';

const EditPackEdgePoint: FC = () => {
  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState<boolean>(false);

  const [updateState, updateFn] = useUpdate();

  const [typeList, setTypeList] = useState<BsaType[]>();
  const [currentType, setCurrentType] = useState<BsaType>();
  const [foucsType, setFoucsType] = useState<BsaType>();

  useEffect(() => {
    if (id) {
      setLoading(true);
      getFsm(id).then(res => {
        setLoading(false);
        const typeList = res.fsmList.filter(i => i.executed).map(i => i.fsmType);
        setCurrentType(res.currentFsm);
        if (res.currentFsm) {
          if (res.currentFsm === BsaType.BSA_COMPLETED) {
            setFoucsType(typeList[-1]);
          } else {
            setFoucsType(res.currentFsm);
          }
        } else {
          setFoucsType(BsaType.ARRAY_INFO_INIT);
        }
        setTypeList(typeList.filter(i => i !== BsaType.BSA_COMPLETED));
      });
    }
  }, [id, updateState]);

  return (
    <Spin spinning={loading} style={{ minHeight: 300 }}>
      <BsaPackEdgePoint
        typeList={typeList}
        currentType={currentType}
        foucsType={foucsType}
        changeType={setFoucsType}
        updateFn={updateFn}
        editable
      />
    </Spin>
  );
};

export default EditPackEdgePoint;
