import React, { forwardRef, Ref, useEffect, useImperativeHandle, useReducer, useState } from 'react';
import { isNil } from 'lodash-es';
import { DataType, SelectCircuit, SelectOptionProp } from '../../../../api/const';
import {
  CircuitDataType,
  CircuitProps,
  CircuitType,
  CircuitTypeFormat,
  getCircuitList,
  StatisticsPartition,
} from '../../../../api/circuit';
import { DefaultValue } from './index';
import styles from './index.module.scss';
import { getDistributionCabinetList } from '@/api/distribution';
import circuitBtns from '../../config';
import { ChartData } from '../..';
import { DatePickerType } from '../DateSwitch';
import { Select } from '@maxtropy/components';

export interface CustomSelectProps {
  onChange: (circuit?: SelectCircuit) => void;
  defaultValue?: DefaultValue;
  isReset?: boolean;
  circuits?: Array<SelectCircuit | undefined>;
  index: number;
  dataType?: DataType;
  tabData?: CircuitDataType[];
  setChartData: React.Dispatch<
    React.SetStateAction<
      | {
          dataType: DataType;
          aggrby: StatisticsPartition;
          data: ChartData[];
          btnType: DatePickerType;
        }[]
      | undefined
    >
  >;
}

export interface CustomSelectRef {
  reset?: () => void;
}

const NO_CABINET_ID = -1;

const CustomSelect = forwardRef((props: CustomSelectProps, ref: Ref<CustomSelectRef>) => {
  const { onChange, defaultValue, isReset, index, circuits, tabData, setChartData } = props;

  const [xx, forceUpdate] = useReducer(x => x + 1, 0);

  const [circuitOptions, setCircuitOptions] = useState<CircuitProps[]>([]);
  const [pointOptions, setPointOptions] = useState<SelectOptionProp[]>([]);

  const [circuitValue, setCircuitValue] = useState<number | undefined>();
  const [pointValue, setPointValue] = useState<DataType | undefined>();

  // 获取回路
  useEffect(() => {
    getCircuitList({}).then(res => {
      let circuitList = res ?? [];
      setCircuitOptions(circuitList);
    });
  }, []);

  // 对比回路默认选中当前回路和监控指标
  useEffect(() => {
    if (index === 0) {
      setCircuitValue(defaultValue?.circuitId);
      setPointValue(defaultValue?.dataType);
      if (circuitOptions) {
        const target = circuitOptions?.find(item => item.id === circuitValue);
        onChange({ ...target!, dataType: defaultValue?.dataType });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, index, xx, circuitOptions]);

  // 获取监控指标
  useEffect(() => {
    if (index === 0 && circuitOptions && !isNil(circuitValue)) {
      let points = tabData?.filter(item => item.name !== '线损').map(item => ({ id: item.id, name: item.name }));
      setPointOptions(points ?? []);
    }
  }, [circuitValue, circuitOptions, index, tabData, xx]);

  useEffect(() => {
    if (index === 1) {
      const dataType = circuits?.[0]?.dataType;
      if (dataType) {
        const options = [dataType, ...circuitBtns.find(item => item.id === dataType)!.comparable].map(item => ({
          id: item,
          name: circuitBtns.find(o => o.id === item)!.btnName,
        }));
        setPointOptions(options);
        if (pointValue && !options.find(item => item.id === pointValue)) {
          setPointValue(undefined);
          const target = circuitOptions?.find(item => item.id === circuitValue);
          onChange({ ...target!, dataType: undefined });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circuits, index, xx]);

  useEffect(() => {
    if (isReset) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReset]);

  const reset = () => {
    forceUpdate();
    if (index === 0) {
      setCircuitValue(defaultValue?.circuitId);
      setPointValue(defaultValue?.dataType);
    } else {
      setCircuitValue(undefined);
      setPointValue(undefined);
    }
  };

  // 选择回路
  const onCircuitChange = (value: number) => {
    setPointValue(undefined);
    const target = circuitOptions?.find(item => item.id === value);
    // onChange({ ...target!, dataType: pointValue });
    onChange({ ...target!, dataType: undefined });
    setCircuitValue(value);
    setChartData(undefined);
  };

  // 选择监控指标
  const onPointChange = (value: number) => {
    setPointValue(value);
    const target = circuitOptions?.find(item => item.id === circuitValue);
    onChange({ ...target!, dataType: value });
    setChartData(undefined);
  };

  useImperativeHandle(ref, () => ({ reset }));

  return (
    <div className={styles.customSelect}>
      <div className={styles.row}>
        <span className={styles.label}>回路</span>
        <Select
          placeholder="请选择回路"
          style={{ width: 180 }}
          value={circuitValue}
          onChange={onCircuitChange}
          options={circuitOptions}
          fieldNames={{ label: 'name', value: 'id' }}
          showSearch
          optionFilterProp="name"
          filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
        />
      </div>

      <div className={styles.row}>
        <span className={styles.label}>监控指标</span>
        <Select
          placeholder="请选择监控指标"
          fieldNames={{ label: 'name', value: 'id' }}
          options={pointOptions}
          style={{ width: 180 }}
          value={pointValue}
          onChange={onPointChange}
        />
      </div>

      <div className={styles.row}>
        <span className={styles.label}>回路类型</span>
        <span>
          {!isNil(circuitOptions.find(item => item.id === circuitValue)?.type)
            ? CircuitTypeFormat[circuitOptions.find(item => item.id === circuitValue)?.type as CircuitType]
            : '-'}
        </span>
      </div>

      <div className={styles.row}>
        <span className={styles.label}>配电柜</span>
        <span>
          {!isNil(circuitOptions.find(item => item.id === circuitValue)?.distributionCabinetName)
            ? circuitOptions.find(item => item.id === circuitValue)?.distributionCabinetName
            : '-'}
        </span>
      </div>
    </div>
  );
});

export default CustomSelect;
