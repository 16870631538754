import styles from './index.module.scss';
import installedCapacityImg from '../../../imgs/installedCapacity.png';
import stationNumberImg from '../../../imgs/stationNumber.png';
import { useContext } from 'react';
import { RightValue } from '../..';
import { formateValuesToFix2 } from '@/pages/MapStation/utils';
import { isNil } from 'lodash-es';

const CurrentScale = () => {
  const rightValue = useContext(RightValue);

  return (
    <div>
      <div className={styles.RightInfoTitle}>当前规模</div>
      <div className={styles.ItemInfo}>
        <img style={{ width: 57, height: 50 }} src={installedCapacityImg} alt="" />
        <div className={styles.valueInfo}>
          <div>装机容量</div>
          <div className={styles.valueStyle}>{`${formateValuesToFix2(rightValue?.ratedPower)}MW/${formateValuesToFix2(
            rightValue?.installedCapacity
          )}MWh`}</div>
        </div>
      </div>
      <div className={styles.ItemInfo}>
        <img style={{ width: 57, height: 50 }} src={stationNumberImg} alt="" />
        <div className={styles.valueInfo}>
          <div>站点数</div>
          <div className={styles.valueStyle}>{!isNil(rightValue?.num) ? rightValue?.num : '--'}</div>
        </div>
      </div>
    </div>
  );
};

export default CurrentScale;
