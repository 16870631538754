import { DoubleRightOutlined } from '@ant-design/icons';
import { Button, Modal, Paging, Select, Table, combineURL, usePaging } from '@maxtropy/components';
import {
  apiV2BsaSecondEditionCabinetDeviceTypeListPost,
  apiV2BsaSecondEditionCabinetLinkCreatePost,
  apiV2BsaSecondEditionDevicePagePost,
} from '@maxtropy/dmes-apis-v2';
import { Col, Form, Row } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { CabinetItem, OptionItem } from '../const';
import { useParams } from 'react-router-dom';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  currentCabinet?: CabinetItem;
  updateData?: () => void;
}

interface DataType {
  num: number;
  id: number;
  typeId: number;
  name: string;
  code: string;
  binding: boolean;
}

const columns: ColumnsType<DataType> = [
  {
    title: '序号',
    dataIndex: 'num',
  },
  {
    title: '设备名称',
    dataIndex: 'name',
  },
  {
    title: '设备编号',
    dataIndex: 'code',
  },
];

const SupportDeviceModal: React.FC<Props> = ({ open, setOpen, currentCabinet, updateData }) => {
  const [form] = Form.useForm();
  const { bsaId } = useParams<{ bsaId: string }>();
  const pagingInfo = usePaging();
  const [loading, setLoading] = useState<boolean>(false);
  const { pageSize, pageOffset, setTotalCount, setPageOffset } = pagingInfo;
  const [deviceTypeList, setDeviceTypeList] = useState<OptionItem[]>([]);
  const deviceTypes = Form.useWatch('deviceTypes', form);
  const [deviceList, setDeviceList] = useState<DataType[]>([]);
  const [selectedRows, setSelectedRows] = useState<DataType[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    apiV2BsaSecondEditionCabinetDeviceTypeListPost({}).then(res => {
      const typeList = (res?.list ?? []).map(m => ({
        label: m.name!,
        value: m.id!,
      }));
      setDeviceTypeList(typeList);
    });
  }, []);

  useEffect(() => {
    setPageOffset(1);
  }, [deviceTypes, pageSize, setPageOffset]);

  useEffect(() => {
    setLoading(true);
    apiV2BsaSecondEditionDevicePagePost({
      page: pageOffset,
      size: pageSize,
      bsaId: Number(bsaId),
      deviceTypeIdList: deviceTypes,
      filterDeviceIds: (currentCabinet?.deviceList ?? []).map(m => m.deviceId!),
    })
      .then(res => {
        const list = (res?.list ?? []).map((k, index) => ({
          num: pageSize * (pageOffset - 1) + index + 1,
          id: k.id!,
          typeId: k.typeId!,
          name: k.name!,
          code: k.code!,
          binding: !!k.binding,
        }));
        setDeviceList(list);
        setTotalCount(res?.total ?? 0);
        setSelectedKeys([]);
        setSelectedRows([]);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceTypes, pageOffset, pageSize, currentCabinet]);

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.binding,
    }),
  };

  const saveLink = () => {
    apiV2BsaSecondEditionCabinetLinkCreatePost({
      list: selectedRows.map(k => ({
        timestamp: new Date().valueOf(),
        cabinetId: currentCabinet?.id,
        deviceId: k.id,
        deviceTypeId: k.typeId,
        bsaId: Number(bsaId),
      })),
    }).then(() => {
      setOpen(false);
      updateData?.();
    });
  };

  return (
    <Modal size="large" bodyScroll title="添加配套设备" open={open} onOk={saveLink} onCancel={() => setOpen(false)}>
      <Form form={form}>
        <Row>
          <Col span={14}>
            <Form.Item label="设备类型" required name="deviceTypes">
              <Select mode="multiple" placeholder="请选择" options={deviceTypeList} />
            </Form.Item>
          </Col>
          <Col span={10}>
            <div style={{ float: 'right' }}>
              <Button
                type="link"
                onClick={() => {
                  window.open(combineURL(window.IOTPLATFORMORIGIN, `/device/manage/device`), '_blank');
                }}
              >
                前往查看IOT配置
                <DoubleRightOutlined />
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        loading={loading}
        rowKey="id"
        scroll={{ y: 320 }}
        showSelectedCount
        columns={columns}
        dataSource={deviceList}
      >
        <Paging pagingInfo={pagingInfo} />
      </Table>
    </Modal>
  );
};

export default SupportDeviceModal;
