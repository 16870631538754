import React, { Key, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import { DataNode } from 'antd/lib/tree';
import classNames from 'classnames';
import { CircuitTreeRequest, CircuitType, getCircuitTree } from '../../../api/circuit';
import { CircuitTreeData, SimpleCabinet } from '../../../api/const';
import cabinetIcon from '../../../assets/images/cabinet.svg';
import circuitIcon from '../../../assets/images/circuit.svg';
import styles from './index.module.scss';
import { Input, Tree } from '@maxtropy/components';

const { Search } = Input;

export interface CircuitTreeLeafInfo {
  capacity?: number | null;
  circuitName: string;
  circuitId: number;
  cabinetId: number;
  measurementId: CircuitType;
  createTime: string;
}

export interface CircuitTreeProps {
  className?: string;
  changeTreeSelect?: (value: React.Key[], info: CircuitTreeLeafInfo) => void;
}

const flattenMap = (data: DataNode[], map: Map<Key, DataNode> = new Map()) => {
  data.forEach(i => {
    map.set(i.key, i);
    if (i.children) {
      flattenMap(i.children, map);
    }
  });
  return map;
};

const transformTree = (tree?: CircuitTreeData[]): DataNode[] => {
  if (!tree) return [];

  const formatCabinet = (cabinets: SimpleCabinet[], measurementId: number): DataNode[] => {
    if (!cabinets) return [];
    return cabinets.map(cabinet => {
      let children: DataNode[] = [];
      if (cabinet.circuits && cabinet.circuits.length) {
        children = cabinet.circuits.map(item => ({
          icon: <img src={circuitIcon} alt="" />,
          className: 'circuit-title',
          key: item.id,
          title: item.name,
          info: {
            capacity: item.capacity,
            circuitName: item.name,
            circuitId: item.id,
            cabinetId: cabinet.id,
            measurementId,
            createTime: item.createTime,
          },
        }));
      }
      return {
        icon: <img src={cabinetIcon} alt="" />,
        className: 'cabinet-title',
        key: `cabinet-${measurementId}-${cabinet.id}`,
        title: cabinet.name,
        selectable: false,
        children,
      };
    });
  };

  return tree.map(item => {
    return {
      className: 'measurement-title',
      key: `measurement-${item.value}`,
      title: item.desc,
      selectable: false,
      children: item.cabinets ? formatCabinet(item.cabinets, item.value) : [],
    };
  });
};

const CircuitTree: React.FC<CircuitTreeProps> = props => {
  const { className, changeTreeSelect } = props;

  const [data, setData] = useState<DataNode[]>();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useState<CircuitTreeRequest>({ type: CircuitType.GRID_BILLING_CIRCUIT });

  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  const [selectedKey, setSelectedKey] = useState<Key[]>([]);

  useEffect(() => {
    setLoading(true);
    getCircuitTree({ ...searchParams })
      .then(res => {
        if (res) {
          const treeData = transformTree(res);
          // 对treeData进行平铺
          const flattenTreeData = flattenMap(treeData);
          setExpandedKeys(Array.from(flattenTreeData.keys()) as string[]);
          setData(treeData);
          if (
            treeData.length > 0 &&
            treeData[0].children &&
            treeData[0].children.length > 0 &&
            treeData[0].children[0].children &&
            treeData[0].children[0].children.length > 0
          ) {
            // @ts-ignore
            changeTreeSelect?.([treeData[0].children[0].children[0].key], treeData[0].children[0].children[0]?.info);
            setSelectedKey([treeData[0].children[0].children[0].key]);
          }
        }
      })
      .finally(() => setLoading(false));
  }, [searchParams]);

  const onSelect = (selectedKeys: React.Key[], e: any) => {
    setSelectedKey(selectedKeys);
    changeTreeSelect?.(selectedKeys, e.node.info);
  };

  const [height, setHeight] = useState<number>(0);
  const treeContainer = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (treeContainer.current) {
        setHeight(treeContainer.current.clientHeight);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={classNames(className, styles.treeArea)}>
      <div className={styles.searchArea}>
        <Search
          placeholder="请输入配电柜/回路名称"
          allowClear
          onSearch={v => setSearchParams({ ...searchParams, name: v })}
        />
      </div>
      <Spin spinning={loading}>
        <div ref={treeContainer}>
          <Tree
            style={{ height: 'calc(100vh - 212px)' }}
            showIcon
            blockNode
            treeData={data}
            expandedKeys={expandedKeys}
            selectedKeys={selectedKey}
            onExpand={expandedKeys => {
              setExpandedKeys(expandedKeys as string[]);
            }}
            onSelect={onSelect}
            height={height}
          />
        </div>
      </Spin>
    </div>
  );
};

export default CircuitTree;
