export enum OnlineStatus {
  Offline = 0,
  Online = 1,
}

export const OnlineStatusDisplay = {
  [OnlineStatus.Offline]: '离线',
  [OnlineStatus.Online]: '在线',
};
export const OnlineStatusColorDisplay = {
  [OnlineStatus.Offline]: 'rgba(255,255,255,0.45)',
  [OnlineStatus.Online]: '#52C41A',
};

export enum AlarmLevel {
  MAX = 0,
  HIGH = 1,
  MIDDLE = 2,
  LOW = 3,
  WARNING = 4,
}

export const AlarmLevelColor = {
  [AlarmLevel.MAX]: '#FF4D4F',
  [AlarmLevel.HIGH]: '#D89614',
  [AlarmLevel.MIDDLE]: '#157DDC',
  [AlarmLevel.LOW]: '#4AA91A',
  [AlarmLevel.WARNING]: '#1890FF',
};

export const AlarmLevelLabel = {
  [AlarmLevel.MAX]: '最高级',
  [AlarmLevel.HIGH]: '高级',
  [AlarmLevel.MIDDLE]: '中级',
  [AlarmLevel.LOW]: '低级',
  [AlarmLevel.WARNING]: '最低级',
};

export const AlarmLevelColorDisplay = {
  [AlarmLevel.MAX]: '#E64242',
  [AlarmLevel.HIGH]: '#FAAD14',
  [AlarmLevel.MIDDLE]: '#4A90E2',
  [AlarmLevel.LOW]: '#52C41A',
  [AlarmLevel.WARNING]: '#ABD335',
};

// 0 逆变器 1组串电流 2 组串温度
export enum DiscreteType {
  INVERTER = 0,
  CURRENT = 1,
  TEMPERATURE = 2,
}

// 机器人在线状态
export enum RobotPresenceStatus {
  Offline = 0,
  Online = 1,
}

export const RobotPresenceStatusDisplay = {
  [RobotPresenceStatus.Offline]: '离线',
  [RobotPresenceStatus.Online]: '在线',
};

export const RobotPresenceColorDisplay = {
  [RobotPresenceStatus.Offline]: 'rgba(255,255,255,0.45)',
  [RobotPresenceStatus.Online]: '#52C41A',
};

// 机器人工作状态
export enum RobotWorkStatus {
  SelfClean = 0,
  StandBy = 1,
  CloudControl = 2,
  PhysicalControl = 3,
  LoseControl = 4,
  Other = 5,
}

export const RobotWorkStatusDisplay = {
  [RobotWorkStatus.SelfClean]: '自主清洁',
  [RobotWorkStatus.StandBy]: '待机',
  [RobotWorkStatus.CloudControl]: '云端遥控',
  [RobotWorkStatus.PhysicalControl]: '实体遥控器遥控',
  [RobotWorkStatus.LoseControl]: '失联',
  [RobotWorkStatus.Other]: '其他',
};

// 机器人运动状态
export enum RobotSportStatus {
  Stop = 0,
  Forward = 1,
  CrossSeam = 2,
  Back = 3,
  Turn = 4,
  Free = 5,
}

export const RobotSportStatusDisplay = {
  [RobotSportStatus.Stop]: '静止',
  [RobotSportStatus.Forward]: '直行',
  [RobotSportStatus.CrossSeam]: '跨缝',
  [RobotSportStatus.Back]: '后退',
  [RobotSportStatus.Turn]: '转弯',
  [RobotSportStatus.Free]: '自由',
};

// 机器人设备状态
export enum RobotDeviceStatus {
  Normal = 0,
  Warning = 1,
  Error = 2,
}

export const RobotDeviceStatusDisplay = {
  [RobotDeviceStatus.Normal]: '正常',
  [RobotDeviceStatus.Warning]: '告警提示',
  [RobotDeviceStatus.Error]: '故障/异常',
};
