import React, { useState } from 'react';
import styles from '../index.module.scss';
import { Radio, Select, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { CalendarMode } from 'antd/lib/calendar/generateCalendar';
import { dateOfMonth } from './utils';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
dayjs.extend(localeData);

interface HeaderRenderProps {
  config: {
    value: Dayjs;
    type: CalendarMode;
    onChange: (date: Dayjs) => void;
    onTypeChange: (type: CalendarMode) => void;
  };
  onSelectDate: (val: string[]) => void;
  selectDate: string[];
}

const HeaderRender: React.FC<HeaderRenderProps> = ({ config, selectDate, onSelectDate }) => {
  const { value, onChange } = config;
  const [radioValue, setRadioValue] = useState<number | undefined>();
  const disabled = dayjs(value).startOf('month') < dayjs().startOf('month');
  const options = [];
  const year = value.year();
  for (let i = 2022; i <= 2022 + 10; i += 1) {
    options.push(
      <Select.Option key={i} value={i} className="year-item">
        {i}
      </Select.Option>
    );
  }
  const start = 0;
  const end = 12;
  const monthOptions = [];
  const current = value.clone();
  const localeData = value.localeData();
  const months = [];
  for (let i = 0; i < 12; i++) {
    months.push(localeData.monthsShort(current.month(i)));
  }
  const month = value.month();
  for (let index = start; index < end; index++) {
    monthOptions.push(
      <Select.Option className="month-item" key={`${index}`}>
        {months[index]}
      </Select.Option>
    );
  }

  const onChangeRadio = (e: number) => {
    const selectRadio = radioValue === e ? undefined : e;
    setRadioValue(selectRadio);
    const newlist = dateOfMonth(value, e);
    if (selectRadio !== undefined) {
      const res = selectDate
        .filter(i => dayjs(i).format('YYYY-MM') !== dayjs(newlist[0]).format('YYYY-MM'))
        .concat(newlist);
      onSelectDate(res);
    } else {
      const res = selectDate.filter(i => !newlist.includes(i));
      onSelectDate(res);
    }
  };

  return (
    <div className={styles.head}>
      <Radio.Group value={radioValue} disabled={disabled}>
        <Radio.Button value={9} onClick={() => onChangeRadio(9)}>
          全选
        </Radio.Button>
        <Radio.Button value={8} onClick={() => onChangeRadio(8)}>
          工作日
        </Radio.Button>
        <Radio.Button value={7} onClick={() => onChangeRadio(7)}>
          双休日
        </Radio.Button>
        <Radio.Button value={1} onClick={() => onChangeRadio(1)}>
          星期一
        </Radio.Button>
        <Radio.Button value={2} onClick={() => onChangeRadio(2)}>
          星期二
        </Radio.Button>
        <Radio.Button value={3} onClick={() => onChangeRadio(3)}>
          星期三
        </Radio.Button>
        <Radio.Button value={4} onClick={() => onChangeRadio(4)}>
          星期四
        </Radio.Button>
        <Radio.Button value={5} onClick={() => onChangeRadio(5)}>
          星期五
        </Radio.Button>
        <Radio.Button value={6} onClick={() => onChangeRadio(6)}>
          星期六
        </Radio.Button>
        <Radio.Button value={0} onClick={() => onChangeRadio(0)}>
          星期日
        </Radio.Button>
      </Radio.Group>
      <Space>
        <Select
          dropdownMatchSelectWidth={false}
          className="my-year-select"
          onChange={newYear => {
            const now = value.clone().year(Number(newYear));
            onChange(now);
            setRadioValue(undefined);
          }}
          value={String(year)}
        >
          {options}
        </Select>
        <Select
          dropdownMatchSelectWidth={false}
          value={String(month)}
          onChange={selectedMonth => {
            const newValue = value.clone().month(parseInt(selectedMonth, 10));
            onChange(newValue);
            setRadioValue(undefined);
          }}
        >
          {monthOptions}
        </Select>
      </Space>
    </div>
  );
};

export default HeaderRender;
