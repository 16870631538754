import {
  EllipsisSpan,
  getRealUrl,
  Paging,
  Table,
  useBreadcrumbRoutes,
  usePaging,
  useUpdate,
  Form,
  InputNumber,
  Modal,
  PopConfirm,
  Select,
  message,
  Wrapper,
  CustomFilter,
  Button,
} from '@maxtropy/components';
import { Divider, Space, Spin, Upload } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ColumnType } from 'antd/es/table';
import { Rule } from 'antd/es/form';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { isNil } from 'lodash-es';

import {
  BatchImportPageResponse,
  CircuitPageProps,
  CircuitType,
  CircuitTypeFormat,
  getCircuitEnergyConfigImportRecord,
  getCircuitPage,
  updateCircuitEnergyConfig,
} from '../../api/circuit';
import { fetchOuList, getOuId } from '../../api/ou';
import { useHasPermission } from '../../utils/hooks';
import { PermissionsType } from '../../common/permissionsConst';
import { UploadOutlined } from '@ant-design/icons';
import { extname } from 'path';
import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';
import dayjs from 'dayjs';

interface EnergyManageProps {}

interface TableItem extends CircuitPageProps {}

interface SearchParams {
  codeOrName?: string;
  type?: CircuitType;
  ouId?: number;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  record: TableItem;
  index: number;
  rules?: Rule[];
  children: React.ReactNode;
}

const fileSize = 50 * 1024 * 1024;

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  // title,
  // record,
  // index,
  children,
  rules,
  ...restProps
}) => {
  const inputNode = <InputNumber precision={2} />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }} rules={[...(rules ?? [])]}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EnergyManage: React.FC<EnergyManageProps> = () => {
  // const hasBatchImportPermission = useHasPermission(PermissionsType.B_CREATECIRCUIT);
  const routesContext = useBreadcrumbRoutes();

  const hasEditPermission = useHasPermission(PermissionsType.B_ENERGY_SAVING_EDIT);

  const [filterForm] = useForm();
  const [form] = useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [editingKey, setEditingKey] = useState<number | undefined>();

  const [updateState, updateFn] = useUpdate();
  const pagingInfo = usePaging(); // 外层节能配置页面
  const pagingInfo1 = usePaging(20); // modal框页面
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const { pageOffset: pageOffset1, pageSize: pageSize1, setTotalCount: setTotalCount1 } = pagingInfo1;
  const [uploadState, setUploadState] = useState<boolean>(false); // 批量导入状态
  const [batchRecordOpen, setBatchRecordOpen] = useState<boolean>(false); // 批量操作日志弹窗
  const [records, setRecords] = useState<BatchImportPageResponse[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  useEffect(() => {
    if (batchRecordOpen) {
      setTableLoading(true);
      getCircuitEnergyConfigImportRecord({
        page: pageOffset1,
        size: pageSize1,
      }).then(res => {
        setRecords(res.list);
        setTotalCount1(res.total);
        setTableLoading(false);
      });
    }
  }, [batchRecordOpen, setTotalCount1, pageOffset1, pageSize1]);

  const { data, isLoading, refetch } = useQuery(
    ['circuitPage', pageOffset, pageSize, searchParams, setTotalCount, updateState],
    async () => {
      const res = await getCircuitPage({
        ...searchParams,
        page: pageOffset,
        size: pageSize,
      });
      if (!res) return [];
      setTotalCount(res.total);
      return res.list;
    }
  );

  const { data: ouList } = useQuery('ouList', async () => {
    const ouType = (await getOuId())?.filter(i => i.key === 'MICRONET')[0]?.id;
    if (!ouType) return [];
    return fetchOuList(ouType);
  });

  const isEditing = (record: TableItem) => record.id === editingKey;

  const edit = (record: Partial<TableItem>) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey(undefined);
  };

  const save = async (id: number) => {
    try {
      const row = (await form.validateFields()) as TableItem;
      const {
        needControlTarget,
        loadControlTarget,
        currentHarmonicUpper,
        lineVoltageLower,
        lineVoltageUpper,
        phaseVoltageLower,
        phaseVoltageUpper,
        voltageHarmonicUpper,
      } = row;
      if (phaseVoltageUpper && phaseVoltageLower) {
        if (phaseVoltageUpper <= phaseVoltageLower) {
          message.error('相电压越界上限需大于越界下限');
          return;
        }
      }
      if (lineVoltageUpper && lineVoltageLower) {
        if (lineVoltageUpper <= lineVoltageLower) {
          message.error('线电压越界上限需大于越界下限');
          return;
        }
      }

      await updateCircuitEnergyConfig(
        {
          needControlTarget: needControlTarget ?? null,
          loadControlTarget: loadControlTarget ?? null,
          currentHarmonicUpper: currentHarmonicUpper ?? null,
          lineVoltageLower: lineVoltageLower ?? null,
          lineVoltageUpper: lineVoltageUpper ?? null,
          phaseVoltageLower: phaseVoltageLower ?? null,
          phaseVoltageUpper: phaseVoltageUpper ?? null,
          voltageHarmonicUpper: voltageHarmonicUpper ?? null,
        },
        id
      );
      setEditingKey(undefined);
      updateFn();
    } catch (errInfo) {}
  };

  const actionColumn: ColumnType<TableItem> = {
    title: '操作',
    dataIndex: 'action',
    width: 150,
    fixed: 'right' as const,
    render: (_, record) => {
      const editable = isEditing(record);
      return (
        <Space size={16}>
          {editable ? (
            <>
              <Button type="link" onClick={() => save(record.id)}>
                保存
              </Button>
              <PopConfirm title="确定取消？" onConfirm={cancel} okText="确定" cancelText="取消">
                <Button type="link">取消</Button>
              </PopConfirm>
            </>
          ) : (
            <>
              {hasEditPermission && (
                <Button type="link" disabled={!!editingKey} onClick={() => edit(record)}>
                  编辑
                </Button>
              )}
            </>
          )}
        </Space>
      );
    },
  };

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: TableItem) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        rules: col.rules,
      }),
    };
  });

  const ouOptions = useMemo(() => {
    if (ouList && ouList.length !== 0) {
      return ouList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [ouList]);

  const buildColumns = [
    ...batchReocrdscolumns,
    {
      title: '操作',
      dataIndex: 'operation',
      fixed: 'right' as const,
      render: (value: undefined, record: BatchImportPageResponse) => {
        return (
          <Space split={<Divider type="vertical" />}>
            <Button
              type="link"
              download
              disabled={!(record.numFail ? record.numFail > 0 : false)}
              href={getRealUrl(record.dataFail)}
            >
              下载失败数据
            </Button>
            <Button
              type="link"
              download
              disabled={!(record.numSuccess ? record.numSuccess > 0 : false)}
              href={getRealUrl(record.dataSuccess)}
            >
              下载成功数据
            </Button>
          </Space>
        );
      },
    },
  ];

  const filters = (
    <CustomFilter
      form={filterForm}
      onFinish={(values: any) => {
        setSearchParams(values);
        setPageOffset(1);
        refetch();
      }}
      onReset={() => {
        setSearchParams(undefined);
        setPageOffset(1);
        refetch();
      }}
    >
      <Form.Item name="ouId" label="所属运营单元">
        <Select placeholder="请选择" allowClear options={ouOptions} showSearch optionFilterProp="label" />
      </Form.Item>

      <Form.Item name="type" label="回路类型">
        <Select placeholder="请选择">
          <Select.Option value={CircuitType.GRID_BILLING_CIRCUIT}>
            {CircuitTypeFormat[CircuitType.GRID_BILLING_CIRCUIT]}
          </Select.Option>
          <Select.Option value={CircuitType.INCOMING_CIRCUIT}>
            {CircuitTypeFormat[CircuitType.INCOMING_CIRCUIT]}
          </Select.Option>
          <Select.Option value={CircuitType.OUTGOING_CIRCUIT}>
            {CircuitTypeFormat[CircuitType.OUTGOING_CIRCUIT]}
          </Select.Option>
          <Select.Option value={CircuitType.BUS_COUPLER_CIRCUIT}>
            {CircuitTypeFormat[CircuitType.BUS_COUPLER_CIRCUIT]}
          </Select.Option>
          <Select.Option value={CircuitType.TRANSFORMER_CIRCUIT}>
            {CircuitTypeFormat[CircuitType.TRANSFORMER_CIRCUIT]}
          </Select.Option>
        </Select>
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper routes={routesContext?.routes ?? []} filters={filters}>
      <Spin spinning={uploadState}>
        <Space size={8} style={{ marginBottom: 10 }}>
          <Upload
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            action="/api/circuit/energyConfig/import"
            onChange={(uploadFile: UploadChangeParam<UploadFile>) => {
              const { file } = uploadFile;
              if (file.status === 'uploading') {
                setUploadState(true);
              }
              if (file.status === 'error') {
                setUploadState(false);
                Modal.warning({
                  content: file.response.errorMessage || '未知错误！ 请联系管理员。',
                });
              }
              if (file.status === 'done') {
                setUploadState(false);
                updateFn();
              }
            }}
            beforeUpload={file => {
              const { name } = file;
              const extName = extname(name);
              const limitFileSize = file.size <= fileSize;
              const limitFileType = extName === '.xlsx' || extName === '.xls';
              if (!limitFileSize) {
                Modal.warning({
                  content: `上传文件的大小不得超过50M`,
                });
                return false;
              }
              if (!limitFileType) {
                Modal.warning({
                  content: `文件格式错误！仅支持.xls、.xlsx`,
                });
                return false;
              }
              return true;
            }}
            showUploadList={false}
          >
            <Button type="primary">
              <UploadOutlined />
              批量导入
            </Button>
          </Upload>
          <Button type="primary" onClick={() => window.open(`/api/circuit/energyConfig/download`, '_blank')}>
            模板下载
          </Button>
          <Button type="primary" onClick={() => setBatchRecordOpen(true)}>
            批量操作日志
          </Button>
        </Space>
      </Spin>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          loading={isLoading}
          sticky
          rowKey="id"
          scroll={{ x: 1500 }}
          columns={[...mergedColumns, actionColumn]}
          dataSource={data}
          rowClassName="editable-row"
        />
      </Form>
      <Paging pagingInfo={pagingInfo} />
      <Modal
        open={batchRecordOpen}
        size="large"
        title={`批量操作日志`}
        maskClosable={false}
        onCancel={() => setBatchRecordOpen(false)}
        footer={null}
      >
        <Table sticky loading={tableLoading} scroll={{ y: 520 }} columns={buildColumns} dataSource={records} />
        <Paging pagingInfo={pagingInfo1} />
      </Modal>
    </Wrapper>
  );
};

const batchReocrdscolumns = [
  {
    title: '批量导入状态',
    dataIndex: 'batchImportStatus',
    ellipsis: { showTitle: true },
    width: 160,
    render: (v: number, record: BatchImportPageResponse) => (
      <EllipsisSpan
        value={
          <div>
            成功 : {record.numSuccess ?? 0}&nbsp;&nbsp;失败 : {record.numFail ?? 0}
          </div>
        }
      />
    ),
  },
  {
    title: '批量导入时间',
    dataIndex: 'createTime',
    ellipsis: { showTitle: true },
    width: 170,
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />,
  },
  {
    title: '操作人',
    dataIndex: 'staffName',
    width: 150,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const columns = [
  {
    title: '回路名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    width: 130,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '回路类型',
    dataIndex: 'type',
    ellipsis: { showTitle: true },
    width: 130,
    render: (v: CircuitType) => <EllipsisSpan value={CircuitTypeFormat[v]} />,
  },
  {
    title: '运营单元',
    dataIndex: 'ouName',
    width: 130,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '当前装机容量（kVA）',
    dataIndex: 'capacity',
    ellipsis: { showTitle: true },
    width: 160,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '需量控制目标（kW）',
    dataIndex: 'needControlTarget',
    ellipsis: { showTitle: true },
    width: 160,
    render: (v: number) => <EllipsisSpan value={!isNil(v) ? v.toFixed(2) : v} />,
    editable: true,
    rules: [{ max: 9999999, min: 0, message: '范围是0-9999999', type: 'number' }],
  },
  {
    title: '负载控制目标（%）',
    dataIndex: 'loadControlTarget',
    ellipsis: { showTitle: true },
    width: 160,
    render: (v: number) => <EllipsisSpan value={!isNil(v) ? v.toFixed(2) : v} />,
    editable: true,
    rules: [{ max: 100, min: 0, message: '范围是0-100', type: 'number' }],
  },
  {
    title: '电压谐波上限（%）',
    dataIndex: 'voltageHarmonicUpper',
    ellipsis: { showTitle: true },
    width: 160,
    render: (v: number) => <EllipsisSpan value={!isNil(v) ? v.toFixed(2) : v} />,
    editable: true,
    rules: [{ max: 100, min: 0, message: '范围是0-100', type: 'number' }],
  },
  {
    title: '电流谐波上限（%）',
    dataIndex: 'currentHarmonicUpper',
    ellipsis: { showTitle: true },
    width: 160,
    render: (v: number) => <EllipsisSpan value={!isNil(v) ? v.toFixed(2) : v} />,
    editable: true,
    rules: [{ max: 100, min: 0, message: '范围是0-100', type: 'number' }],
  },
  {
    title: '线电压-越界上限（V）',
    dataIndex: 'lineVoltageUpper',
    ellipsis: { showTitle: true },
    width: 160,
    render: (v: number) => <EllipsisSpan value={!isNil(v) ? v.toFixed(2) : v} />,
    editable: true,
    rules: [{ max: 9999999, min: 0, message: '范围是0-9999999', type: 'number' }],
  },
  {
    title: '线电压-越界下限（V）',
    dataIndex: 'lineVoltageLower',
    ellipsis: { showTitle: true },
    width: 160,
    render: (v: number) => <EllipsisSpan value={!isNil(v) ? v.toFixed(2) : v} />,
    editable: true,
    rules: [{ max: 9999999, min: 0, message: '范围是0-9999999', type: 'number' }],
  },
  {
    title: '相电压-越界上限（V）',
    dataIndex: 'phaseVoltageUpper',
    ellipsis: { showTitle: true },
    width: 160,
    render: (v: number) => <EllipsisSpan value={!isNil(v) ? v.toFixed(2) : v} />,
    editable: true,
    rules: [{ max: 9999999, min: 0, message: '范围是0-9999999', type: 'number' }],
  },
  {
    title: '相电压-越界下限（V）',
    dataIndex: 'phaseVoltageLower',
    ellipsis: { showTitle: true },
    width: 160,
    render: (v: number) => <EllipsisSpan value={!isNil(v) ? v.toFixed(2) : v} />,
    editable: true,
    rules: [{ max: 9999999, min: 0, message: '范围是0-9999999', type: 'number' }],
  },
];

export default EnergyManage;
