import { Space } from 'antd';
import styles from './index.module.scss';
import icon from '../../icons/icon-pcs@2x.png';
import battery from '../../icons/icon-batteryStacks@2x.png';
import { ShareContext } from '../..';
import { Key, useContext, useMemo } from 'react';
import { RealTimeDataType } from '../../type';
import { goToDeviceHistoryPage } from '../utils';
interface Iprops {
  title?: string;
  deviceId?: Key;
}
const PcsTitle = ({ title, deviceId }: Iprops) => {
  const { dataType } = useContext(ShareContext);

  return (
    <div className={styles.title_box}>
      <Space size={16}>
        <Space>
          <div className={styles.img_box}>
            <img src={dataType && +dataType === RealTimeDataType.PCS ? icon : battery} />
          </div>
          <span className={styles.main_title}>{title ?? '--'}</span>
        </Space>
      </Space>
      <Space>
        <span className={styles.right_label} onClick={() => goToDeviceHistoryPage(deviceId)}>
          历史数据
        </span>
      </Space>
    </div>
  );
};
export default PcsTitle;
