import { Col, Row, Image } from 'antd';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from '../index.module.scss';
import computerLineLoss from '@/assets/images/computerLineLoss.png';
import { LossComputerRefProp } from '../LineLossConfig';
import { CircuitLossRes, getNextCircuitOptions, NextCircuitPageProps } from '@/api/lineLoss';
import dayjs, { Dayjs } from 'dayjs';
import { Form, DatePicker, Modal, Radio, Select, Button } from '@maxtropy/components';

interface Iprops {
  data?: CircuitLossRes;
  isEdit?: boolean;
}
const LossComputerConfig = forwardRef<LossComputerRefProp, Iprops>(({ data, isEdit }, childRef) => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [computeVisible, setComputeVisible] = useState(false);
  const [circuitOptions, setCircuitOptions] = useState<NextCircuitPageProps[]>([]);
  const getFormValues = () => form.validateFields();
  useImperativeHandle(childRef, () => ({
    getFormValues,
  }));
  // 表单回填
  useEffect(() => {
    if (isEdit && data) {
      let gap = data.computationalTime;
      if (gap) {
        data.computationalTime = dayjs(gap);
      }
      form.setFieldsValue(data);
    }
  }, [isEdit, data, form]);
  // 下拉列表查询
  useEffect(() => {
    if (id) {
      getNextCircuitOptions(id).then(res => {
        setCircuitOptions(res);
      });
    }
  }, [id]);
  const disabledDate = (current: Dayjs) => {
    // 当天起始时间
    let currentStartTime = dayjs(dayjs().format('YYYY-MM-DD') + ' 0:0:0');
    return current && current < currentStartTime;
  };
  return (
    <>
      <Form
        form={form}
        initialValues={{
          computationalLogic: 1,
        }}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label="开始计算线损日期"
              name="computationalTime"
              rules={[{ required: true, message: '请选择日期' }]}
            >
              <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" disabledDate={disabledDate} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="计算逻辑" name="computationalLogic" rules={[{ required: true, message: '请选择' }]}>
              <Radio.Group>
                <Radio value={1}>
                  两级线损
                  <Button type="link" onClick={() => setComputeVisible(true)}>
                    点击查看
                  </Button>
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="下游发电回路" name="downstreamCircuitIds">
              <Select mode="multiple">
                {circuitOptions.map(item => (
                  <Select.Option key={item.circuitId} value={item.circuitId}>
                    {item.circuitName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        open={computeVisible}
        title="计算逻辑"
        onCancel={() => setComputeVisible(false)}
        footer={
          <Button type="primary" onClick={() => setComputeVisible(false)}>
            好的
          </Button>
        }
        size="large"
      >
        <Image preview={false} src={computerLineLoss} />
      </Modal>
    </>
  );
});

export default LossComputerConfig;
