import { Tooltip, TooltipProps } from 'antd';

/**
 * @descripe
 * 兼容全屏状态下popup框不显示问题
 * @param props
 * @returns
 */
const CustomTooltip = ({ show = true, ...rest }: TooltipProps & { children?: React.ReactNode; show?: boolean }) => {
  if (show) {
    return (
      <Tooltip
        {...rest}
        arrow={false}
        color="#090C10"
        getPopupContainer={() => document.getElementById('full_screen_box') ?? document.body}
        title={rest.title}
      >
        {rest.children}
      </Tooltip>
    );
  }
  return <>{rest.children}</>;
};

export default CustomTooltip;
