import { PermissionsType } from '@/common/permissionsConst';
import EnergyAssetsOverview from '.';

const routes = [
  {
    path: '/energy/asset/overview',
    permission: PermissionsType.P_ENERGYASSETSUMARY,
    element: <EnergyAssetsOverview />,
  },
];
export default routes;
