import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { CustomFilter, EllipsisSpan, Form, Input, Modal, Paging, Select, Table, usePaging } from '@maxtropy/components';
import { ExcludeType, PvAreaContext } from '../../const';
import { useRequest } from 'ahooks';
import {
  apiV2PvDispersionRatePvDeviceListPost,
  apiV2PvDispersionRatePvDevicePagePost,
  V2PvDispersionRatePvDevicePagePostRequest,
  V2PvDispersionRatePvDevicePagePostResponse,
} from '@maxtropy/dmes-apis-v2';
import { ColumnType } from 'antd/es/table';
import { EditObjectIdListItem } from '../ExcludeTable';

type SearchParams = Omit<V2PvDispersionRatePvDevicePagePostRequest, 'timestamp'>;
type DataItem = Exclude<V2PvDispersionRatePvDevicePagePostResponse['list'], undefined>[number];
type DataItemWithId = DataItem & { id: number };

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  addObjectIdList: EditObjectIdListItem[];
  delObjectIdList: EditObjectIdListItem[];
  excludeType: ExcludeType;
  onOk: (addObjectIdList: EditObjectIdListItem[], delObjectIdList: EditObjectIdListItem[]) => void;
}

const ExcludeSelectModal: FC<Props> = props => {
  const {
    open,
    setOpen,
    excludeType,
    addObjectIdList: propDddObjectIdList,
    delObjectIdList: propDelObjectIdList,
    onOk,
  } = props;
  const pvAreaContext = useContext(PvAreaContext);
  const { pvStationName, areaIdList } = pvAreaContext;

  const [searchForm] = Form.useForm();
  const searchFormInverterId = Form.useWatch('pvInverterId', searchForm);
  const [searchParams, setSearchParams] = useState<SearchParams>({});
  const pagingInfo = usePaging(20);
  const { pageSize, pageOffset, setTotalCount, setPageOffset } = pagingInfo;
  const [addObjectIdList, setAddObjectIdList] = useState<EditObjectIdListItem[]>([]);
  const [delObjectIdList, setDelObjectIdList] = useState<EditObjectIdListItem[]>([]);

  useEffect(() => {
    setDelObjectIdList(propDelObjectIdList);
  }, [propDelObjectIdList]);

  useEffect(() => {
    setAddObjectIdList(propDddObjectIdList);
  }, [propDddObjectIdList]);

  // 逆变器列表
  const { data: inverterOptions } = useRequest(
    async () => {
      const res = await apiV2PvDispersionRatePvDeviceListPost({
        type: 0,
        areaIdList: areaIdList,
      });
      return res.list?.map(item => {
        return {
          value: item.objectId,
          label: item.objectCode,
        };
      });
    },
    { ready: open, refreshDeps: [areaIdList] }
  );

  // 组串列表
  const { data: stringOptions } = useRequest(
    async () => {
      const res = await apiV2PvDispersionRatePvDeviceListPost({
        type: 1,
        areaIdList: areaIdList,
        inverterId: searchFormInverterId,
      });
      return res.list?.map(item => {
        return {
          value: item.objectId,
          label: item.objectCode,
        };
      });
    },
    {
      ready: open && excludeType === ExcludeType.COMPONENT,
      refreshDeps: [areaIdList, searchFormInverterId],
    }
  );

  // 表格数据
  const { data, loading } = useRequest(
    async () => {
      const res = await apiV2PvDispersionRatePvDevicePagePost({
        ...searchParams,
        page: pageOffset,
        size: pageSize,
        type: excludeType,
        areaIdList: areaIdList,
      });
      setTotalCount(res.total ?? 0);
      return res.list?.map(item => {
        return { ...item, id: excludeType === ExcludeType.STRING ? item.pvStringId! : item.moduleId! };
      });
    },
    {
      refreshDeps: [searchParams, pageOffset, pageSize, excludeType, areaIdList],
      ready: open,
    }
  );

  // 当前页已选择的id列表
  const currentPageSelectedIds = useMemo(() => {
    // 后端返回已选择的
    const ids = data?.filter(item => item.status).map(item => item.id) ?? [];
    // 前端新增勾选的
    for (const item of addObjectIdList) {
      if (data?.filter(dataItem => dataItem.id === item.objectId)) {
        ids.push(item.objectId);
      }
    }
    // 前端取消勾选的
    return ids.filter(id => !delObjectIdList.map(item => item.objectId).includes(id));
  }, [data, delObjectIdList, addObjectIdList]);

  const onSelectChange = (ids: number[], selectedRows: DataItemWithId[]) => {
    // 对比当前页已选择的id列表，和变化后的ids，得到新增和删除的id列表
    const addIds = ids.filter(id => !currentPageSelectedIds.includes(id));
    const delIds = currentPageSelectedIds.filter(id => !ids.includes(id));

    // 如果addId在已删除的列表中，说明是取消删除，从删除列表中删除
    let cancelDelIds = delObjectIdList.filter(id => addIds.includes(id.objectId));
    // 如果delId在已添加的列表中，说明是取消添加，从添加列表中删除
    let cancelAddIds = addObjectIdList.filter(item => delIds.includes(item.objectId));

    // 真正的添加和删除列表
    let realAddIds = addIds.filter(id => !cancelDelIds.map(item => item.objectId).includes(id));
    let realDelIds = delIds.filter(id => !cancelAddIds.map(item => item.objectId).includes(id));

    // 完整的添加列表，之前的添加列表加上新增的，去掉取消删除的
    const newAddObjectIdList = addObjectIdList
      .concat(
        realAddIds.map(id => ({
          objectId: id,
          areaId: selectedRows.find(selectedRow => selectedRow.id === id)?.areaId!,
        }))
      )
      .filter(item => !cancelAddIds.map(item => item.objectId).includes(item.objectId));
    // 完整的删除列表，之前的删除列表加上新增的，去掉取消添加的
    const newDelObjectIdList = delObjectIdList
      .concat(
        realDelIds.map(id => ({
          objectId: id,
          areaId: selectedRows.find(selectedRow => selectedRow.id === id)?.areaId!,
        }))
      )
      .filter(id => !cancelDelIds.includes(id));

    setAddObjectIdList(newAddObjectIdList);
    setDelObjectIdList(newDelObjectIdList);
  };

  const columns: ColumnType<DataItemWithId>[] = [
    {
      title: excludeType === ExcludeType.STRING ? '组串编号' : '组件编号',
      dataIndex: excludeType === ExcludeType.STRING ? 'pvStringCode' : 'moduleCode',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v} />,
    },
    {
      title: '设备编号',
      dataIndex: 'deviceCode',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v} />,
    },
    {
      title: '设备名称',
      dataIndex: 'deviceName',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v} />,
    },
    {
      title: '上级组串',
      dataIndex: 'pvStringCode',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v} />,
      hidden: excludeType === ExcludeType.STRING,
    },
    {
      title: '上级逆变器',
      dataIndex: 'pvInverterCode',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v} />,
    },
    {
      title: '所属光伏站',
      key: 'pvStationName',
      ellipsis: { showTitle: true },
      render: () => <EllipsisSpan value={pvStationName} />,
    },
  ];

  return (
    <Modal
      open={open}
      onCancel={() => {
        setOpen(false);
      }}
      onOk={() => {
        onOk(addObjectIdList, delObjectIdList);
        setOpen(false);
      }}
      title={excludeType === ExcludeType.STRING ? '选择组串' : '选择组件'}
      size="big"
    >
      <CustomFilter
        colSpan={8}
        onFinish={(val: SearchParams) => {
          setSearchParams(val);
          setPageOffset(1);
        }}
        onReset={() => {
          setSearchParams({});
          setPageOffset(1);
        }}
        form={searchForm}
      >
        <Form.Item label={excludeType === ExcludeType.STRING ? '组串编号' : '组件编号'} name="codeOrName">
          <Input></Input>
        </Form.Item>
        <Form.Item label="所属逆变器" name="pvInverterId">
          <Select
            allowClear
            mode="multiple"
            options={inverterOptions}
            onChange={() => {
              searchForm.setFieldValue('pvStringId', undefined);
            }}
            filterOption={(input, option) => {
              return option!.label!.toUpperCase().includes(input.toUpperCase());
            }}
          ></Select>
        </Form.Item>
        {excludeType === ExcludeType.COMPONENT && (
          <Form.Item label="所属组串" name="pvStringId">
            <Select
              mode="multiple"
              allowClear
              options={stringOptions}
              filterOption={(input, option) => {
                return option!.label!.toUpperCase().includes(input.toUpperCase());
              }}
            ></Select>
          </Form.Item>
        )}
      </CustomFilter>

      <Table
        rowKey={excludeType === ExcludeType.STRING ? 'pvStringId' : 'moduleId'}
        loading={loading}
        scroll={{ y: 420 }}
        dataSource={data}
        columns={columns}
        rowSelection={{
          preserveSelectedRowKeys: true,
          selectedRowKeys: currentPageSelectedIds,
          onChange: (ids, selectedRows) => {
            onSelectChange(ids as number[], selectedRows);
          },
        }}
      ></Table>
      <Paging pagingInfo={pagingInfo}></Paging>
    </Modal>
  );
};

export default ExcludeSelectModal;
