import React from 'react';
import { List } from 'antd';
import { Modal, combineURL } from '@maxtropy/components';
import { DevicesProps } from '../../../../api/circuit';
import { DataType } from '../../../../api/const';
import styles from './index.module.scss';

interface DeviceModalListProps {
  visible: boolean;
  onCancel: () => void;
  dataType?: DataType;
  list: DevicesProps[];
}

const DeviceModalList: React.FC<DeviceModalListProps> = props => {
  const { visible, onCancel, dataType, list } = props;

  return (
    <Modal open={visible} onCancel={onCancel} footer={null} title="采集颗粒度">
      <p>当前监控指标包含以下设备，请选择要查看的设备数据</p>
      <List
        dataSource={list}
        bordered
        renderItem={item => (
          <List.Item
            className={styles.item}
            onClick={() =>
              window.open(
                `${combineURL(
                  window.IOTPLATFORMORIGIN,
                  `/data/history/device?deviceId=${item.id}&dataType=${dataType}`
                )}`,
                '_blank'
              )
            }
          >
            {item.name}
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default DeviceModalList;
