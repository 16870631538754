import { useBreadcrumbRoutes, Wrapper, Tabs } from '@maxtropy/components';
import AnalysisConfig from './components/AnalysisConfig';
import ThresholdConfig from './components/ThresholdConfig';
import styles from './index.module.scss';

const PvDispersionRateConfig = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const tabItems = [
    {
      key: '1',
      label: '分析设置',
      children: <AnalysisConfig />,
    },
    {
      key: '2',
      label: '阈值设置',
      children: <ThresholdConfig />,
    },
  ];

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.wrapper}>
      <Tabs items={tabItems} selectedLinePlacement="top" bordered destroyInactiveTabPane></Tabs>
    </Wrapper>
  );
};

export default PvDispersionRateConfig;
