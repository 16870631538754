import { EnergyAssetThirtyDaysBsaInfoRes, getEnergyAssetThirtyDaysBsaInfo, OuList } from '@/api/energyAssetOverview';
import { Spin } from 'antd';
import { FC, useContext, useEffect, useState } from 'react';
import HeadTitle from '../HeadTitle/HeadTitle';
import styles from './index.module.scss';
import ReactEcharts from 'echarts-for-react';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { average } from '../../utils';
import { StationHasCount } from '../..';
import { Empty } from '@maxtropy/components';

export interface ICurrentThirtyDaysBsa {
  ouInfo: OuList;
  height: string | undefined;
}

const CurrentThirtyDaysBsa: FC<ICurrentThirtyDaysBsa> = props => {
  const { ouInfo, height } = props;
  const stationHasCount = useContext(StationHasCount);
  const [thirtyDaysBsaInfo, setThirtyDaysBsaInfo] = useState<EnergyAssetThirtyDaysBsaInfoRes>();
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    if (ouInfo.id) {
      setDataLoading(true);
      // 近 30 日储能站发电量
      getEnergyAssetThirtyDaysBsaInfo({ ouId: ouInfo.id }).then(res => {
        setThirtyDaysBsaInfo(res);
        setDataLoading(false);
      });
    }
  }, [ouInfo]);

  const option = {
    grid: {
      left: 60,
      right: 30,
      top: 55,
      bottom: 20,
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      textStyle: {
        color: '#fff',
      },
      formatter(items: any[]) {
        const { axisValue } = items[0];
        // // 根据选择的颗粒度，展示不同的tooltip
        let str: string = dayjs(axisValue, 'x').format('YYYY-MM-DD');
        const avgBeCharge = average(thirtyDaysBsaInfo?.bsaBeChargeInfo?.map(i => i.electricityQuantity));
        const avgDisCharge = average(thirtyDaysBsaInfo?.bsaDisChargeInfo?.map(i => i.electricityQuantity));
        const avgBeChargeValue = isNaN(avgBeCharge) ? '--' : avgBeCharge.toFixed(2);
        const avgDisChargeValue = isNaN(avgDisCharge) ? '--' : avgDisCharge.toFixed(2);
        str += `<br>近30日平均充电量：<span style='display:inline-block;width:86px;text-align:right;color:#57FB8B'>${avgBeChargeValue}kWh</span>`;
        str += `<br>近30日平均放电量：<span style='display:inline-block;width:86px;text-align:right;color:#2D8DFF'>${avgDisChargeValue}kWh</span>`;

        items.forEach(item => {
          const { seriesName, data, marker } = item;
          const value = !isNil(data[1]) ? `${data[1].toFixed(2)}` : '--';
          str += `<br><span style='display:inline-block;width:82px;text-align:right'>${marker}${seriesName}：</span> ${value}kWh<br>
          <span style='display:inline-block;width:80px;text-align:right'>环比：</span>
          ${
            data[2] && data[2] > 0
              ? `<span style='display:inline-block;border-radius:10px;color:#FF4D4F;width:86px;'>${
                  !isNil(data[2]) ? '+' + (Number(data[2]) * 100).toFixed(2) + '%' : '--'
                }</span>`
              : `<span style='display:inline-block;border-radius:10px;color:#59D744;width:86px;'>${
                  !isNil(data[2]) ? (Number(data[2]) * 100).toFixed(2) + '%' : '--'
                }</span>`
          }`;
        });

        return str;
      },
    },
    xAxis: {
      type: 'time',
      minInterval: 60 * 1000 * 60 * 24,
      axisLabel: {
        hideOverlap: true,
        formatter: function (e: number) {
          return dayjs(e, 'x').format('MM-DD');
        },
      },
      axisPointer: {
        type: 'shadow',
      },
    },
    yAxis: {
      type: 'value',
      name: 'kWh',
      nameTextStyle: {
        fontSize: 14,
        align: 'right',
      },
      nameGap: 30,
      splitLine: {
        lineStyle: {
          color: '#FFFFFF30',
        },
      },
    },
    legend: {
      show: true,
      right: 20,
      top: 0,
      textStyle: {
        color: '#AFBCC4',
      },
      data: [
        {
          name: '充电量',
          // icon: 'rect',
        },
        {
          name: '放电量',
          // icon: 'rect',
        },
      ],
    },
    series: [
      {
        type: 'bar',
        name: '充电量',
        color: '#57FB8B',
        data: thirtyDaysBsaInfo?.bsaBeChargeInfo?.map(a => {
          return [a.datetime, a.electricityQuantity, a.electricityQuantityRate];
        }),
        markLine: {
          silent: true,
          symbol: ['none', 'none'],
          lineStyle: {
            type: 'dashed',
            color: '#57FB8B',
          },
          data: [
            {
              label: {
                show: false,
                formatter: `平均充电量: {c}kWh`,
                position:
                  average(thirtyDaysBsaInfo?.bsaBeChargeInfo?.map(i => i.electricityQuantity)) ===
                  average(thirtyDaysBsaInfo?.bsaDisChargeInfo?.map(i => i.electricityQuantity))
                    ? 'insideEndTop'
                    : average(thirtyDaysBsaInfo?.bsaBeChargeInfo?.map(i => i.electricityQuantity)) >
                      average(thirtyDaysBsaInfo?.bsaDisChargeInfo?.map(i => i.electricityQuantity))
                    ? 'insideEndTop'
                    : 'insideEndBottom',
                color: '#fff',
              },
              yAxis: average(thirtyDaysBsaInfo?.bsaBeChargeInfo?.map(i => i.electricityQuantity)).toFixed(2),
            },
          ],
        },
      },
      {
        type: 'bar',
        name: '放电量',
        color: '#2D8DFF',
        data: thirtyDaysBsaInfo?.bsaDisChargeInfo?.map(a => {
          return [a.datetime, a.electricityQuantity, a.electricityQuantityRate];
        }),
        markLine: {
          silent: true,
          symbol: ['none', 'none'],
          lineStyle: {
            type: 'dashed',
            color: '#2D8DFF',
          },
          data: [
            {
              label: {
                show: false,
                formatter: `平均放电量: {c}kWh`,
                position:
                  average(thirtyDaysBsaInfo?.bsaBeChargeInfo?.map(i => i.electricityQuantity)) ===
                  average(thirtyDaysBsaInfo?.bsaDisChargeInfo?.map(i => i.electricityQuantity))
                    ? 'insideEndBottom'
                    : average(thirtyDaysBsaInfo?.bsaDisChargeInfo?.map(i => i.electricityQuantity)) >
                      average(thirtyDaysBsaInfo?.bsaBeChargeInfo?.map(i => i.electricityQuantity))
                    ? 'insideEndTop'
                    : 'insideEndBottom',
                color: '#fff',
              },
              yAxis: average(thirtyDaysBsaInfo?.bsaDisChargeInfo?.map(i => i.electricityQuantity)).toFixed(2),
            },
          ],
        },
      },
    ],
  };

  return (
    <div
      className={styles.ColSty}
      style={{
        height: height,
      }}
    >
      <HeadTitle title="近30日储能站-充放电量" />
      <Spin spinning={dataLoading}>
        {stationHasCount?.bsaCount ? (
          <ReactEcharts
            option={option}
            notMerge
            lazyUpdate={false}
            style={{ height: height === 'unset' ? 240 : `calc(${height} - 50px)` }} // 样式的设置width与height
          />
        ) : (
          <Empty
            style={{ height: height === 'unset' ? 240 : `calc(${height} - 50px)`, paddingTop: '10%' }}
            description={'无储能站'}
          />
        )}
      </Spin>
    </div>
  );
};

export default CurrentThirtyDaysBsa;
