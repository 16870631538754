import { Col, Row, Space, Spin } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { CombinerBoxProps, getGridNetPointList, getPvAreaList, NetInfoProp, PvAreaInfo } from '@/api/pv';
import qs from 'qs';
import { PVStatus } from '@/api/type';
import { Button, Form, Input, Modal, Select } from '@maxtropy/components';

interface Iprops {
  visible: boolean;
  opType: string;
  pvId?: any;
  row?: CombinerBoxProps;
  loading?: boolean;
  nextStep?: (v: CombinerBoxProps) => void;
  onCancel?: () => void;
  onConfirm?: (v: CombinerBoxProps) => void;
}
const formLayout = {
  labelCol: { flex: '120px' },
};
const CombinerBoxModal: React.FC<Iprops> = ({
  visible = false,
  opType = 'add',
  loading,
  nextStep,
  onCancel,
  onConfirm,
  pvId,
  row,
}) => {
  const { status } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const pvStatus = Number(status);
  const [form] = Form.useForm();
  const [formLoading, setFormLoading] = useState(false);
  const [areaList, setAreaList] = useState<PvAreaInfo[]>([]);
  const [gridPointsList, setGridPointsList] = useState<NetInfoProp[]>([]);
  const title = useMemo(() => (opType === 'add' ? '添加汇流箱' : '编辑汇流箱'), [opType]);
  // const codeDisable = useMemo(() => pvStatus === PVStatus.DISABLE, [pvStatus]);

  // 获取片区/并网点列表
  useEffect(() => {
    if (visible) {
      setFormLoading(true);
      Promise.all([
        getPvAreaList(pvId).then(setAreaList),
        getGridNetPointList(pvId, {
          size: 1000,
          page: 1,
        }).then(res => setGridPointsList(res.list || [])),
      ]).finally(() => setFormLoading(false));
    }
  }, [visible, pvId]);

  // 编辑时表单回显
  useEffect(() => {
    if (row && visible) {
      form.setFieldsValue({ ...row });
    }
  }, [visible, form, row]);

  const cancelClick = () => {
    form.resetFields();
    onCancel && onCancel();
  };
  // 下一步按钮
  const nextBtn = () => {
    form.validateFields().then(values => {
      nextStep && nextStep({ ...row, ...values });
    });
  };
  // 汇流箱弹框确认按钮
  const confirmBtn = () => {
    form.validateFields().then(values => {
      onConfirm && onConfirm({ ...row, ...values });
    });
  };

  return (
    <Modal
      title={title}
      contentStyle={{ padding: '32px 72px 8px 72px' }}
      open={visible}
      destroyOnClose
      onCancel={cancelClick}
      maskClosable={false}
      footer={
        <Space>
          <Button onClick={cancelClick}>取消</Button>
          <Button type="primary" onClick={nextBtn}>
            下一步选择设备
          </Button>
          <Button loading={loading} type="primary" onClick={confirmBtn}>
            确认
          </Button>
        </Space>
      }
    >
      <Spin spinning={formLoading}>
        <Form {...formLayout} form={form} preserve={false}>
          <Form.Item
            label="汇流箱编号"
            name="code"
            rules={[
              { required: true, message: '请输入汇流箱编号' },
              { max: 20, message: '最多20个字符' },
              { pattern: /^[A-Za-z0-9\\-]{1,20}$/, message: '仅能够输入数字、英文大小写和英文状态下的-' },
            ]}
          >
            <Input placeholder="请输入" maxLength={20} />
          </Form.Item>
          <Form.Item label="片区" name="pvAreaId" rules={[{ required: true, message: '请选择片区' }]}>
            <Select placeholder="请选择">
              {areaList.map(v => (
                <Select.Option value={v.id} key={v.code}>
                  <Row gutter={10}>
                    <Col span={12} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {v.code}
                    </Col>
                    <Col span={12} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {v.name}
                    </Col>
                  </Row>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="关联并网点"
            name="gridConnectionPointId"
            rules={[{ required: true, message: '请选择关联并网点' }]}
          >
            <Select placeholder="请选择">
              {gridPointsList.map(v => (
                <Select.Option value={v.id} key={v.id}>
                  {v.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CombinerBoxModal;
