import { PageResponse } from '@/api/page';
import {
  CombinerBoxProps,
  createCombinerBox,
  deleteAllCombinerBox,
  exportCombinerBox,
  getCombinerBoxList,
  updateCombinerBox,
} from '@/api/pv';
import { PVStatus } from '@/api/type';
import SelectDeviceModal, { PVType } from '@/components/SelectDeviceModal';
import { Button, EllipsisSpan, Paging, Table, usePaging, useUpdate } from '@maxtropy/components';
import { App, Col, Row, Space } from 'antd';
import qs from 'qs';
import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';
import ListBtns from './ListBtns';
import NewCombinerModal from './NewCombinerModal';
import SearchParams from './SearchParams';
import { DeleteOutlined, ExportOutlined, PlusOutlined } from '@ant-design/icons';

interface CombinerBoxParams {
  pvId: any;
  code?: string;
  gridConnectionPointId?: any;
  pvAreaId?: any;
}
const CombinerBox: React.FC<{ isEdit?: boolean }> = ({ isEdit }) => {
  const { id, status, ouId } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const currentOuId = Number(ouId);
  const pvStatus = Number(status);
  const [isLoading, setIsLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [data, setData] = useState<PageResponse<CombinerBoxProps>>();
  const [combinerVisible, setCombinerVisible] = useState(false);
  const [searchParams, setSearchParams] = useState<CombinerBoxParams>({ pvId: id });
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [rand, forceUpdate] = useUpdate();
  const isView = useMemo(() => !isEdit, [isEdit]);
  const [deviceModalVisible, setDeviceModalVisible] = useState(false);
  const combinerInfo = useRef<CombinerBoxProps>();
  const [combinerOpType, setCombinerOpType] = useState('add');
  const [combinerEditRow, setCombinerEditRow] = useState<CombinerBoxProps>();
  const showAddBtn = useMemo(
    () => (pvStatus === PVStatus.DRAFT || pvStatus === PVStatus.DISABLE) && !isView,
    [pvStatus, isView]
  );
  const { modal } = App.useApp();
  useEffect(() => {
    setIsLoading(true);
    getCombinerBoxList({
      ...searchParams,
      page: pageOffset,
      size: pageSize,
    })
      .then(setData)
      .finally(() => setIsLoading(false));
  }, [pageOffset, pageSize, searchParams, rand]);
  useEffect(() => {
    if (data) {
      setTotalCount(data.total);
    }
  }, [data, setTotalCount]);
  const research = () => {
    let total = data?.total;
    if (total) {
      // 如果是最后一页最后一条
      if (pageSize * (pageOffset - 1) + 1 === total) {
        setPageOffset(pageOffset - 1);
        return;
      }
    }
    forceUpdate();
  };
  const columns = [
    {
      title: '序号',
      dataIndex: 'order',
      ellipsis: { showTitle: true },
      render: (v: string, rows: any, index: number) => <EllipsisSpan value={(pageOffset - 1) * pageSize + index + 1} />,
    },
    {
      title: '汇流箱编号',
      dataIndex: 'code',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '设备编号',
      dataIndex: 'deviceCode',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '设备名称',
      dataIndex: 'deviceName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '关联并网点',
      dataIndex: 'gridConnectionPointName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '所在片区编号',
      dataIndex: 'pvAreaCode',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '所在片区名称',
      dataIndex: 'pvAreaName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
  ];
  const actionColums = {
    title: '操作',
    width: 260,
    fixed: 'right' as const,
    render: (_: any, v: CombinerBoxProps) => {
      return (
        <ListBtns
          {...v}
          editBtn={() => combinerOp('edit', v)}
          status={pvStatus}
          ouId={currentOuId}
          pvId={id}
          deleteOk={research}
          update={forceUpdate}
        />
      );
    },
  };
  const tableColumns = isView ? columns : [...columns, actionColums];
  // 添加/编辑汇流箱弹窗
  const combinerOp = (op: string, row?: any) => {
    setCombinerOpType(op);
    setCombinerVisible(true);
    setCombinerEditRow(row);
  };
  // 删除所有汇流箱
  const removeAllCombinerBox = () => {
    modal.confirm({
      title: (
        <>
          <div>确认批量删除汇流箱吗 ?</div>
          <div>注: 需保证不存在逆变器关联任何一个汇流箱</div>
        </>
      ),
      onOk: () => {
        deleteAllCombinerBox(id).then(res => {
          if (!res) return;
          forceUpdate();
        });
      },
    });
  };
  // 导出汇流箱
  const exportCombinerBoxBtn = () => {
    exportCombinerBox(id).then(res => {
      downLoadStream(res);
    });
  };
  // 下载流
  const downLoadStream = (data: any) => {
    let blob = new Blob([data]);
    let a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = '汇流箱.xlsx';
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    a.remove();
  };
  const nextStep = (values?: CombinerBoxProps) => {
    combinerInfo.current = values;
    setCombinerVisible(false);
    setDeviceModalVisible(true);
  };
  // 汇流箱弹框确认按钮
  const combinerConfirmBtn = (values?: CombinerBoxProps) => {
    const params = {
      pvId: id,
      ...values,
    };
    combinerBoxApi(params, () => {
      setCombinerVisible(false);
      forceUpdate();
    });
  };
  // 选择设备确认按钮
  const deviceModalConfirm = (deviceId: number) => {
    const params = {
      pvId: id,
      ...combinerInfo.current,
      deviceId,
    };
    combinerBoxApi(params, () => {
      setDeviceModalVisible(false);
      forceUpdate();
    });
  };
  const combinerBoxApi = (params: CombinerBoxProps, callback?: () => void) => {
    const confirmApi = combinerOpType === 'add' ? createCombinerBox : updateCombinerBox;
    setModalLoading(true);
    confirmApi(params)
      .then(res => {
        if (!res) return;
        callback && callback();
      })
      .finally(() => setModalLoading(false));
  };
  /**
   * 草稿状态显示添加/删除/导出
   * 禁用状态显示导出
   */
  return (
    <>
      <div className={styles.insideBox}>
        <div className={styles.headerBox}>汇流箱信息</div>
        {/* 查看显示筛选框 */}
        {isView && (
          <SearchParams
            onFinish={(v?: CombinerBoxProps) => setSearchParams({ ...searchParams, ...v })}
            onReset={() => setSearchParams({ pvId: id })}
            pvId={id}
          />
        )}
        <Row>
          <Col>
            <Space>
              {showAddBtn && (
                <Button type="primary" icon={<PlusOutlined />} onClick={() => combinerOp('add')}>
                  添加汇流箱
                </Button>
              )}
              {showAddBtn && (
                <Button type="primary" icon={<DeleteOutlined />} onClick={removeAllCombinerBox}>
                  删除所有汇流箱
                </Button>
              )}
              <Button type="primary" icon={<ExportOutlined />} onClick={exportCombinerBoxBtn}>
                导出汇流箱
              </Button>
            </Space>
          </Col>
          <Col flex={1}>
            <div className={styles.currentNumBox}>
              当前数量 :<span className={styles.currentNum}>{data?.total || 0}</span>个
            </div>
          </Col>
        </Row>
        <div style={{ height: '15px' }}></div>
        <Table
          sticky
          rowKey="id"
          scroll={{ x: 1500 }}
          columns={tableColumns}
          dataSource={data?.list}
          loading={isLoading}
        />
        <Paging pagingInfo={pagingInfo} />
      </div>
      {/* 新建汇流箱弹框 */}
      <NewCombinerModal
        onConfirm={combinerConfirmBtn}
        onCancel={() => setCombinerVisible(false)}
        opType={combinerOpType}
        nextStep={nextStep}
        pvId={id}
        loading={modalLoading}
        row={combinerEditRow}
        visible={combinerVisible}
      />
      {deviceModalVisible && (
        <SelectDeviceModal
          pvId={Number(id)}
          loading={modalLoading}
          pvType={PVType.COMBINER_BOX}
          ouId={currentOuId}
          initialDeviceId={combinerEditRow?.deviceId}
          typeName="汇流箱"
          onOk={deviceModalConfirm}
          onCancel={() => setDeviceModalVisible(false)}
        />
      )}
    </>
  );
};
export default CombinerBox;
