import React, { useEffect, useMemo, useState } from 'react';
import { Breadcrumb, Select, Tabs, useBreadcrumbRoutes } from '@maxtropy/components';
import BorderWrapper from '@/components/BorderWrapper';
import SocTabContent from '@/pages/Storage/Charts/SocTabContent';
import PcsTabContent from '@/pages/Storage/Charts/PcsTabContent';
import BatteryTemper from './Charts/BatteryTemper';
import EnvTemper from './Charts/EnvTemper';
import { BsaListProps, getBsaListWithOu } from '@/api/bsa';
import qs from 'qs';
import CurrentTabContent from './Charts/CurrentTabContent';
import VoltageTabContent from './Charts/VoltageTabContent';
import { StorageProvider } from './StorageContext';
import ChargingAmout from './Charts/ChargingAmout';
export interface StorageChartsProps {}
// 10058	电池堆最高电压
// 10059	电池堆最低电压
// 10387	电池簇最高电压
// 10388	电池簇最低电压
// 10389	电池组最高电压
// 10390	电池组最低电压

// 10391	电池堆最高温度
// 10392	电池堆最低温度
// 10393	电池簇最高温度
// 10394	电池簇最低温度
// 10395	电池组最高温度
// 10396	电池组最低温度
// 10397	电芯温度

// 电池温度监测数据属性
const temperProperty = {
  stack_h_property_id: 10391,
  stack_l_property_id: 10392,
  rack_h_property_id: 10393,
  rack_l_property_id: 10394,
  pack_h_property_id: 10395,
  pack_l_property_id: 10396,
  unit: '℃',
  type: 'temper',
};
// 电池单体电压数据属性
export const volProperty = {
  stack_h_property_id: 10058,
  stack_l_property_id: 10059,
  rack_h_property_id: 10387,
  rack_l_property_id: 10388,
  pack_h_property_id: 10389,
  pack_l_property_id: 10390,
  unit: 'V',
  type: 'vol',
};

const { TabPane } = Tabs;

const StorageCharts: React.FC<StorageChartsProps> = () => {
  const routesContext = useBreadcrumbRoutes();
  const [currentBsaId, setCurrentBsaId] = useState<number>();
  const [bsaList, setBsaList] = useState<BsaListProps[]>([]);
  const { bsaId, tab } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const [activeKey, setActiveKey] = useState<string>((tab as string) ?? undefined); // Tab选择
  const queryBsaId = useMemo(() => {
    if (bsaId) {
      let tempNum = Number(bsaId as string);
      if (!isNaN(Number(tempNum))) {
        return tempNum;
      }
    }
  }, [bsaId]);

  useEffect(() => {
    if (bsaList && bsaList.length > 0) {
      if (queryBsaId) {
        setCurrentBsaId(queryBsaId);
      } else {
        setCurrentBsaId(bsaList[0].id);
      }
    }
  }, [queryBsaId, bsaList]);

  useEffect(() => {
    getBsaListWithOu({ statusFilter: true }).then(res => {
      setBsaList(res);
    });
  }, []);

  // 储能阵列的options
  const options = useMemo(() => {
    return bsaList.map(i => ({ label: `${i.name} -- ${i.customerName}`, value: i.id }));
  }, [bsaList]);

  const tabs = [
    {
      name: '充放电量',
      component: <ChargingAmout bsaId={currentBsaId!} />,
    },
    {
      name: '电池堆SOC',
      component: <SocTabContent bsaId={currentBsaId!} />,
    },
    {
      name: 'PCS功率',
      component: <PcsTabContent bsaId={currentBsaId!} />,
    },
    {
      name: '电池温度监测',
      component: <BatteryTemper {...temperProperty} bsaId={currentBsaId} />,
    },
    {
      name: '电池单体电压',
      component: <BatteryTemper {...volProperty} bsaId={currentBsaId!} />,
    },
    {
      name: '环境温度',
      component: <EnvTemper bsaId={currentBsaId!} />,
    },
    {
      name: '电池堆总电压',
      component: <VoltageTabContent bsaId={currentBsaId!} />,
    },
    {
      name: '电池堆总电流',
      component: <CurrentTabContent bsaId={currentBsaId!} />,
    },
  ];

  return (
    <div className="page">
      <div style={{ paddingLeft: 10, marginBottom: 7, height: 20 }}>
        <Breadcrumb routes={routesContext?.routes} />
      </div>

      <BorderWrapper>
        <div style={{ background: 'var(--component-background)', padding: '0 14px' }}>
          <div style={{ padding: '12px 0' }}>
            <span>切换储能阵列：</span>
            <Select
              style={{ width: 200 }}
              showSearch
              placeholder="请选择"
              optionFilterProp="label"
              onChange={(id: number) => {
                setCurrentBsaId(id);
              }}
              options={options}
              value={currentBsaId}
            />
          </div>
          <StorageProvider bsaId={currentBsaId!}>
            <Tabs destroyInactiveTabPane onChange={v => setActiveKey(v)} activeKey={activeKey}>
              {tabs?.map(item => (
                <TabPane tab={<div style={{ width: 100, textAlign: 'center' }}>{item.name}</div>} key={item.name}>
                  {item.component}
                </TabPane>
              ))}
            </Tabs>
          </StorageProvider>
        </div>
      </BorderWrapper>
    </div>
  );
};

export default StorageCharts;
