import { Col, FormInstance, Row } from 'antd';
import { FC } from 'react';
import { Form, ShowInput } from '@maxtropy/components';
import styles from '@/pages/PvStation/BaseInfo/CostInfo/index.module.scss';
import dayjs from 'dayjs';
import { InfoCircleOutlined } from '@ant-design/icons';
import { formLayout, GridConnectionStatus, GridConnectionStatusLabel } from '@/pages/PvStation/BaseInfo/CostInfo/index';

interface Props {
  form: FormInstance;
}

const Detail: FC<Props> = props => {
  const { form } = props;
  const gridConnectionStatus = Form.useWatch('gridConnectionStatus', form);
  const feedInElectricityPrices = Form.useWatch('feedInElectricityPrices', form);
  const subsidyElectricityPrices = Form.useWatch('subsidyElectricityPrices', form);

  return (
    <>
      <Form.Item noStyle name="gridConnectionStatus"></Form.Item>
      <Form.Item label="并网模式" dependencies={['gridConnectionStatus']} {...formLayout}>
        {({ getFieldValue }) => {
          return (
            <ShowInput
              value={GridConnectionStatusLabel[getFieldValue('gridConnectionStatus') as GridConnectionStatus]}
            ></ShowInput>
          );
        }}
      </Form.Item>

      <Row gutter={16}>
        <Col span={7}>
          <Form.Item label="度电成本：" className={styles.mb6}></Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="投建成本" className={styles.mb6}></Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="已回收成本" className={styles.mb6}></Form.Item>
        </Col>
      </Row>

      <Row gutter={16} className={styles.mb24}>
        <Col span={7}>
          <Form.Item name="costElectricityPrice" noStyle>
            <ShowInput addonAfter="元/kWh" />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item name="investmentCost" noStyle>
            <ShowInput addonAfter="万元" />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item name="recoveredCost" noStyle>
            <ShowInput addonAfter="万元" />
          </Form.Item>
        </Col>
      </Row>

      {gridConnectionStatus !== GridConnectionStatus.ALL_DROP && (
        <div className={styles.mb24}>
          <Form.List name="feedInElectricityPrices" layout="vertical">
            {(fields, { add, remove }, { errors }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <Row key={index} gutter={16}>
                      <Col span={7}>
                        <Form.Item label={index === 0 ? '上网电价：' : ''} key={key}>
                          <Form.Item {...restField} label="上网电价" noStyle>
                            <ShowInput addonAfter="元/kWh" value={feedInElectricityPrices[name]['electricityPrice']} />
                          </Form.Item>
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item label={index === 0 ? '起始日期：' : ''}>
                          <Form.Item {...restField} label="起始日期" dependencies={['feedInElectricityPrices']} noStyle>
                            {({ getFieldValue }) => {
                              return (
                                <ShowInput
                                  value={
                                    getFieldValue(['feedInElectricityPrices', name, 'beginDate']) &&
                                    dayjs(getFieldValue(['feedInElectricityPrices', name, 'beginDate'])).format(
                                      'YYYY-MM-DD'
                                    )
                                  }
                                ></ShowInput>
                              );
                            }}
                          </Form.Item>
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item label={index === 0 ? '结束日期：' : ''}>
                          <Form.Item {...restField} label="结束日期" dependencies={['feedInElectricityPrices']} noStyle>
                            {({ getFieldValue }) => {
                              return (
                                <ShowInput
                                  value={
                                    getFieldValue(['feedInElectricityPrices', name, 'endDate']) &&
                                    dayjs(getFieldValue(['feedInElectricityPrices', name, 'endDate'])).format(
                                      'YYYY-MM-DD'
                                    )
                                  }
                                ></ShowInput>
                              );
                            }}
                          </Form.Item>
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </>
              );
            }}
          </Form.List>
        </div>
      )}

      <div className={styles.mb24}>
        <Form.List name="subsidyElectricityPrices" layout="vertical">
          {(fields, { add, remove }, { errors }) => {
            return (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Row key={index} gutter={16}>
                    <Col span={7}>
                      <Form.Item label={index === 0 ? '补贴电价：' : ''}>
                        <Form.Item {...restField} label="补贴电价" noStyle>
                          <ShowInput addonAfter="元/kWh" value={subsidyElectricityPrices[name]['electricityPrice']} />
                        </Form.Item>
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item label={index === 0 ? '起始日期：' : ''}>
                        <Form.Item {...restField} label="起始日期" dependencies={['subsidyElectricityPrices']} noStyle>
                          {({ getFieldValue }) => {
                            return (
                              <ShowInput
                                value={
                                  getFieldValue(['subsidyElectricityPrices', name, 'beginDate']) &&
                                  dayjs(getFieldValue(['subsidyElectricityPrices', name, 'beginDate'])).format(
                                    'YYYY-MM-DD'
                                  )
                                }
                              ></ShowInput>
                            );
                          }}
                        </Form.Item>
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item label={index === 0 ? '结束日期：' : ''}>
                        <Form.Item {...restField} label="结束日期" dependencies={['subsidyElectricityPrices']} noStyle>
                          {({ getFieldValue }) => {
                            return (
                              <ShowInput
                                value={
                                  getFieldValue(['subsidyElectricityPrices', name, 'endDate']) &&
                                  dayjs(getFieldValue(['subsidyElectricityPrices', name, 'endDate'])).format(
                                    'YYYY-MM-DD'
                                  )
                                }
                              ></ShowInput>
                            );
                          }}
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
              </>
            );
          }}
        </Form.List>
      </div>

      <Form.Item label="补贴折扣" name="subsidyDiscount" {...formLayout}>
        <ShowInput />
      </Form.Item>

      {gridConnectionStatus !== GridConnectionStatus.ALL_CONNECTED && (
        <Form.Item label="用电折扣" name="electricityDiscount" {...formLayout}>
          <ShowInput />
        </Form.Item>
      )}

      {gridConnectionStatus !== GridConnectionStatus.ALL_DROP && (
        <Form.Item label="上网分成" name="internetRevenueSharing" {...formLayout}>
          <ShowInput />
        </Form.Item>
      )}
    </>
  );
};

export default Detail;
