import { Table } from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import styles from './index.module.scss';
import rackIcon from '../../icons/icon-batteryClusters@2x.png';

import { MapValueObj, RealData } from '../../type';
import { useMemo } from 'react';
import NextLevelDevice from './NextLevelDevice';
import { useRequest } from 'ahooks';
import { apiV2NeoBsaRealTimeDataPost, apiV2DeviceDataPropertyRecentPost } from '@maxtropy/dmes-apis-v2';
import { getTsByPropertyId, getValueByPropertyId, goToDeviceHistoryPage } from '../utils';
import { useGetDataPropertyInfo } from '../../hooks';
export type FirstLevelDevice = Exclude<RealData, undefined>[number];

interface Iprops {
  data?: FirstLevelDevice;
}
const CardItem = ({ data }: Iprops) => {
  // 查询数据数据属性值
  const { deviceIds, dataPropertyIds } = useMemo(() => {
    return {
      deviceIds: [data?.deviceId!].filter(Boolean),
      dataPropertyIds: data?.dataPropertyValues?.filter(Boolean)?.map(item => item.datapropertyId!),
    };
  }, [data]);

  const { data: valuesMap } = useGetDataPropertyInfo({ deviceIds: deviceIds as unknown as number[], dataPropertyIds });

  return (
    <div className={styles.card_box}>
      <div className={styles.card_title}>
        <div className={styles.left}>
          <div className={styles.img_box}>
            <img src={rackIcon} alt="" />
          </div>
          <span className={styles.img_title}>{data?.deviceName ?? '--'}</span>
        </div>
        <div className={styles.right} onClick={() => goToDeviceHistoryPage(data?.deviceId)}>
          历史数据
        </div>
      </div>
      <div className={styles.block_list_box}>
        <Row gutter={[20, 12]}>
          {data?.dataPropertyValues?.map(item => (
            <Col key={item.datapropertyId} xxl={8} lg={12}>
              <div className={styles.block_item}>
                <div className={styles.block_item_left}>
                  <div className={styles.property_name}>{item.datapropertyName}&nbsp;:&nbsp;</div>
                  <div className={styles.property_value}>
                    {getValueByPropertyId({
                      datapropertyId: item.datapropertyId,
                      deviceId: item.deviceId,
                      valuesMap,
                      unit: item.generalName,
                    })}
                  </div>
                </div>
                <div className={styles.block_item_right}>
                  <div className={styles.time}>{getTsByPropertyId(item.datapropertyId, item.deviceId, valuesMap)}</div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <div className={styles.table}>
        <Row gutter={[20, 20]}>
          {data?.nextLevelsRealTimeData?.map(item => (
            <Col key={item.deviceId} xxl={8} lg={12}>
              <NextLevelDevice data={item} />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default CardItem;
