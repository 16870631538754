import React from 'react';
import { isNumber } from '../../utils';
import styles from './index.module.scss';

export interface CardForDataProps {
  value: number;
  name: string;
  unit: string;
}

const CardForDara: React.FC<CardForDataProps> = props => {
  const { value, name, unit } = props;
  return (
    <div className={styles.card}>
      <div className={styles.cardTitle}>
        <span>{name}</span>
      </div>
      <div className={styles.valueContainer}>
        <span className={styles.value}>{isNumber(value) ? value.toFixed(2) : '--'}</span>
        <span className={styles.unit}>{unit}</span>
      </div>
    </div>
  );
};

export default CardForDara;
