import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Button, Checkbox, EllipsisSpan, Radio, ShowInput, Table, Tooltip } from '@maxtropy/components';
import { Space, Typography, Table as AntdTable } from 'antd';
import styles from './index.module.scss';
import { useLocation } from 'react-router-dom';
import { DataGrain } from '@/api/bsa-overview';
import { BsaType } from '@/pages/BsaHistoryData/const';
import { useRequest } from 'ahooks';
import { apiV2BsaDataCostInfoPost, apiV2BsaDataDataPost, V2BsaDataDataPostResponse } from '@maxtropy/dmes-apis-v2';
import DateRangePicker from '../DateRangePicker';
import { TimePeriod, TimePeriodColorMap } from '../../const';
import { v4 } from 'uuid';
import qs from 'qs';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useHasPermission } from '@/utils/hooks';
import { PermissionsType } from '@/common/permissionsConst';
const { Text } = Typography;

enum SettlementWay {
  ElecDiscount = 1,
  IncomeDivide = 2,
}

type DataItem = Exclude<V2BsaDataDataPostResponse['list'], undefined>[number];
type DataGridItemWithId = Exclude<DataItem['gridDataList'], undefined>[number] & { id?: string };
type DataWithId = Omit<DataItem, 'gridDataList'> & { id?: string; gridDataList?: DataGridItemWithId[] };

interface Props {
  timeRange: [Dayjs, Dayjs];
  setTimeRange: (timeRange: [Dayjs, Dayjs]) => void;
  bsaId: number | undefined;
  bsaType: BsaType | undefined;
}

export interface ElecStatisticsRef {
  timeResolution: DataGrain;
}

const ElecStatistics: ForwardRefRenderFunction<ElecStatisticsRef, Props> = (props, ref) => {
  const { timeRange, setTimeRange, bsaId, bsaType } = props;
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  let url_timeResolution = urlSearchParams.get('timeResolution') || undefined;

  const [timeResolution, setTimeResolution] = useState<DataGrain>((url_timeResolution as DataGrain) || 'D');
  const [monthRange, setMonthRange] = useState<[Dayjs, Dayjs]>([dayjs().subtract(12, 'months'), dayjs()]);

  const [showIncome, setShowIncome] = useState(false);

  const hasPermissionShowIncome = useHasPermission(PermissionsType.B_INCOME_COLUMN_PRESENTATION);

  useImperativeHandle(ref, () => ({
    timeResolution,
  }));

  const { data: costInfo } = useRequest(
    () => {
      return apiV2BsaDataCostInfoPost({ bsaId: bsaId, type: bsaType });
    },
    {
      ready: !!bsaId,
      refreshDeps: [bsaId, bsaType],
    }
  );

  const columns = [
    {
      title: '日期',
      dataIndex: 'time',
      key: 'time',
      render: (v: string, record: any) => {
        // record没有deviceName，是主数据，取时间
        // record没有deviceName有deviceName，是展开的数据，取设备名
        if (!record.deviceName)
          return (
            <EllipsisSpan
              value={
                v
                  ? timeResolution === DataGrain.DAY
                    ? dayjs(v).format('YYYY-MM-DD')
                    : dayjs(v).format('YYYY-MM')
                  : '--'
              }
            />
          );
        else return <div className={styles.deviceCell}>{record.deviceName}</div>;
      },
    },
    {
      title: '峰谷时段充电量',
      children: [
        {
          title: (
            <span
              style={{
                color: TimePeriodColorMap[TimePeriod.SUMMIT],
              }}
            >
              尖-充电量（kWh）
            </span>
          ),
          dataIndex: 'summitCharge',
          key: 'summitCharge',
          render: (v: number) => <EllipsisSpan value={v ? v.toFixed(2) : '--'} />,
        },
        {
          title: (
            <span
              style={{
                color: TimePeriodColorMap[TimePeriod.PEAK],
              }}
            >
              峰-充电量（kWh）
            </span>
          ),
          dataIndex: 'peakCharge',
          key: 'peakCharge',
          render: (v: number) => <EllipsisSpan value={v ? v.toFixed(2) : '--'} />,
        },
        {
          title: (
            <span
              style={{
                color: TimePeriodColorMap[TimePeriod.PLAIN],
              }}
            >
              平-充电量（kWh）
            </span>
          ),
          dataIndex: 'plainCharge',
          key: 'plainCharge',
          render: (v: number) => <EllipsisSpan value={v ? v.toFixed(2) : '--'} />,
        },
        {
          title: (
            <span
              style={{
                color: TimePeriodColorMap[TimePeriod.VALLEY],
              }}
            >
              谷-充电量（kWh）
            </span>
          ),
          dataIndex: 'valleyCharge',
          key: 'valleyCharge',
          render: (v: number) => <EllipsisSpan value={v ? v.toFixed(2) : '--'} />,
        },
      ],
    },
    {
      title: '峰谷时段放电量',
      children: [
        {
          title: (
            <span
              style={{
                color: TimePeriodColorMap[TimePeriod.SUMMIT],
              }}
            >
              尖-放电量（kWh）
            </span>
          ),
          dataIndex: 'summitDischarge',
          key: 'summitDischarge',
          render: (v: number) => <EllipsisSpan value={v ? v.toFixed(2) : '--'} />,
        },
        {
          title: (
            <span
              style={{
                color: TimePeriodColorMap[TimePeriod.PEAK],
              }}
            >
              峰-放电量（kWh）
            </span>
          ),
          dataIndex: 'peakDischarge',
          key: 'peakDischarge',
          render: (v: number) => <EllipsisSpan value={v ? v.toFixed(2) : '--'} />,
        },
        {
          title: (
            <span
              style={{
                color: TimePeriodColorMap[TimePeriod.PLAIN],
              }}
            >
              平-放电量（kWh）
            </span>
          ),
          dataIndex: 'plainDischarge',
          key: 'plainDischarge',
          render: (v: number) => <EllipsisSpan value={v ? v.toFixed(2) : '--'} />,
        },
        {
          title: (
            <span
              style={{
                color: TimePeriodColorMap[TimePeriod.VALLEY],
              }}
            >
              谷-放电量（kWh）
            </span>
          ),
          dataIndex: 'valleyDischarge',
          key: 'valleyDischarge',
          render: (v: number) => <EllipsisSpan value={v ? v.toFixed(2) : '--'} />,
        },
      ],
    },
    ...(showIncome
      ? [
          {
            title: '收益统计',
            children: [
              {
                title: '收益（元）',
                dataIndex: 'income',
                key: 'income',
                render: (v: number) => <EllipsisSpan value={v ? v.toFixed(2) : '--'} />,
              },
              {
                title: '总-充电电费（元）',
                dataIndex: 'consume',
                key: 'consume',
                render: (v: number) => <EllipsisSpan value={v ? v.toFixed(2) : '--'} />,
              },
              {
                title: '总-放电电费（元）',
                dataIndex: 'produce',
                key: 'produce',
                render: (v: number) => <EllipsisSpan value={v ? v.toFixed(2) : '--'} />,
              },
            ],
          },
        ]
      : []),
  ];

  const { data: reportList, loading } = useRequest(
    async () => {
      const res = await apiV2BsaDataDataPost({
        bsaId: Number(bsaId),
        type: bsaType,
        timeResolution: timeResolution,
        from: timeResolution === 'D' ? timeRange![0]!.valueOf() : monthRange![0]!.valueOf(),
        to: timeResolution === 'D' ? timeRange![1]!.valueOf() : monthRange![1]!.valueOf(),
        incomeDisplay: showIncome,
      });
      const list: DataWithId[] = res.list ?? [];
      list.forEach(item => {
        item.id = v4();
        item.gridDataList?.forEach(gridItem => {
          gridItem.id = v4();
        });
      });
      return list;
    },
    {
      ready: !!bsaId && !!timeRange && !!monthRange,
      refreshDeps: [bsaId, timeRange, monthRange, timeResolution, showIncome],
    }
  );

  return (
    <div className={styles.wrap}>
      <div className={styles.top}>
        <div className={styles.ctrlWrap}>
          <Radio.Group buttonStyle="solid" value={timeResolution} onChange={e => setTimeResolution(e.target.value)}>
            <Radio.Button value="D">按日</Radio.Button>
            <Radio.Button value="M">按月</Radio.Button>
          </Radio.Group>
          {timeResolution === 'D' ? (
            <DateRangePicker
              type="date"
              timeRange={timeRange}
              setTimeRange={setTimeRange}
              maxRange={31}
            ></DateRangePicker>
          ) : (
            <DateRangePicker
              type="month"
              timeRange={monthRange}
              setTimeRange={setMonthRange}
              maxRange={24}
            ></DateRangePicker>
          )}

          {costInfo?.settlementWay === SettlementWay.ElecDiscount && (
            <span style={{ color: 'var(--mx-text-secondary-color)', display: 'flex' }}>
              用电折扣：<ShowInput value={costInfo.electricityDiscount}></ShowInput>
            </span>
          )}
          {costInfo?.settlementWay === SettlementWay.IncomeDivide && (
            <span style={{ color: 'var(--mx-text-secondary-color)', display: 'flex' }}>
              收益分成比例：<ShowInput value={costInfo.electricityDiscount}></ShowInput>
            </span>
          )}
          {hasPermissionShowIncome && (
            <Checkbox checked={showIncome} onChange={e => setShowIncome(e.target.checked)}>
              显示收益
            </Checkbox>
          )}
          <div className={styles.descInfo}>
            <Tooltip title="系统重采样计算，可能与抄表记录的差值计算存在些许差异">
              <InfoCircleOutlined style={{ color: 'var(--warning-color)', marginRight: 5 }} />
              <span style={{ color: 'var(--mx-text-desc-color)' }}>
                系统重采样计算，可能与抄表记录的差值计算存在些许差异
              </span>
            </Tooltip>
          </div>
        </div>
        <Button
          type="primary"
          wrapClassName={styles.export}
          disabled={!bsaId}
          onClick={() => {
            window.open(
              '/api/v2/bsaData/data/export' +
                qs.stringify(
                  {
                    bsaId: Number(bsaId),
                    type: bsaType,
                    timeResolution: timeResolution,
                    from: timeResolution === 'D' ? timeRange![0]!.valueOf() : monthRange![0]!.valueOf(),
                    to: timeResolution === 'D' ? timeRange![1]!.valueOf() : monthRange![1]!.valueOf(),
                    incomeDisplay: showIncome,
                  },
                  { addQueryPrefix: true }
                )
            );
          }}
        >
          导出
        </Button>
      </div>

      <Table
        rowKey="id"
        loading={loading}
        columns={columns}
        dataSource={reportList}
        pagination={false}
        scroll={{ y: 'calc(100vh - 420px)' }}
        bordered
        expandable={{
          childrenColumnName: 'gridDataList',
        }}
        summary={pageData => {
          let totalIncome = 0; // 总收益

          let totalTotalCharge = 0;
          let totalConsume = 0;
          let totalTotalDischarge = 0;
          let totalProduce = 0;

          let totalSummitCharge = 0;
          let totalPeakCharge = 0;
          let totalPlainCharge = 0;
          let totalValleyCharge = 0;

          let totalSummitDischarge = 0;
          let totalPeakDischarge = 0;
          let totalPlainDischarge = 0;
          let totalValleyDischarge = 0;

          pageData.forEach(
            ({
              income,
              totalCharge,
              consume,
              totalDischarge,
              produce,
              summitCharge,
              peakCharge,
              plainCharge,
              valleyCharge,
              summitDischarge,
              peakDischarge,
              plainDischarge,
              valleyDischarge,
            }) => {
              totalIncome += income ?? 0;
              totalTotalCharge += totalCharge ?? 0;
              totalConsume += consume ?? 0;
              totalTotalDischarge += totalDischarge ?? 0;
              totalProduce += produce ?? 0;

              totalSummitCharge += summitCharge ?? 0;
              totalPeakCharge += peakCharge ?? 0;
              totalPlainCharge += plainCharge ?? 0;
              totalValleyCharge += valleyCharge ?? 0;

              totalSummitDischarge += summitDischarge ?? 0;
              totalPeakDischarge += peakDischarge ?? 0;
              totalPlainDischarge += plainDischarge ?? 0;
              totalValleyDischarge += valleyDischarge ?? 0;
            }
          );

          return (
            <AntdTable.Summary fixed={'top'}>
              <AntdTable.Summary.Row>
                <AntdTable.Summary.Cell index={0}>总计</AntdTable.Summary.Cell>

                <AntdTable.Summary.Cell index={1}>
                  <Text type="warning">{totalSummitCharge.toFixed(2)}</Text>
                </AntdTable.Summary.Cell>
                <AntdTable.Summary.Cell index={2}>
                  <Text type="warning">{totalPeakCharge.toFixed(2)}</Text>
                </AntdTable.Summary.Cell>
                <AntdTable.Summary.Cell index={3}>
                  <Text type="warning">{totalPlainCharge.toFixed(2)}</Text>
                </AntdTable.Summary.Cell>
                <AntdTable.Summary.Cell index={4}>
                  <Text type="warning">{totalValleyCharge.toFixed(2)}</Text>
                </AntdTable.Summary.Cell>

                <AntdTable.Summary.Cell index={5}>
                  <Text type="warning">{totalSummitDischarge.toFixed(2)}</Text>
                </AntdTable.Summary.Cell>
                <AntdTable.Summary.Cell index={6}>
                  <Text type="warning">{totalPeakDischarge.toFixed(2)}</Text>
                </AntdTable.Summary.Cell>
                <AntdTable.Summary.Cell index={7}>
                  <Text type="warning">{totalPlainDischarge.toFixed(2)}</Text>
                </AntdTable.Summary.Cell>
                <AntdTable.Summary.Cell index={8}>
                  <Text type="warning">{totalValleyDischarge.toFixed(2)}</Text>
                </AntdTable.Summary.Cell>

                {showIncome && (
                  <>
                    <AntdTable.Summary.Cell index={9}>
                      <Text type="warning">{totalIncome.toFixed(2)}</Text>
                    </AntdTable.Summary.Cell>
                    <AntdTable.Summary.Cell index={10}>
                      <Text type="warning">{totalConsume.toFixed(2)}</Text>
                    </AntdTable.Summary.Cell>
                    <AntdTable.Summary.Cell index={11}>
                      <Text type="warning">{totalProduce.toFixed(2)}</Text>
                    </AntdTable.Summary.Cell>
                  </>
                )}
              </AntdTable.Summary.Row>
            </AntdTable.Summary>
          );
        }}
      />
    </div>
  );
};

export default forwardRef(ElecStatistics);
