import { FC } from 'react';
import classNames from 'classnames';

import { Col, Descriptions, Row } from 'antd';

import styles from './index.module.scss';
import { UETResponse } from '../../../../api/bsa';
import { Form } from '@maxtropy/components';

export interface UetProps {
  value?: UETResponse;
}

const Uet: FC<UetProps> = ({ value }) => {
  return (
    <>
      <Form.Item name="" label="负载变压器">
        <span>{value?.circuitName ?? '--'}</span>
      </Form.Item>
      <div className={styles.desc}>
        <Descriptions colon={false} layout="vertical">
          <Descriptions.Item label="容量">{value?.capacity ?? '--'}kVA</Descriptions.Item>
          <Descriptions.Item label="目标负载">{value?.targetLoad ?? '--'} kWh</Descriptions.Item>
          <Descriptions.Item label="目标负载率">
            {value?.targetLoad && value?.capacity ? ((value?.targetLoad * 100) / value?.capacity).toFixed(2) : '--'}%
          </Descriptions.Item>
        </Descriptions>
      </div>
      {value?.productionLoadDevice ? (
        <div className={classNames(styles.desc, styles.background)}>
          <Descriptions title="储能调节生产负载电表" colon={false} layout="vertical" column={4}>
            <Descriptions.Item label="项目编号">
              <Row>
                {(value?.productionLoadDevice.ouList ?? []).map(i => (
                  <Col span={24} key={i.id}>
                    {i.serialNumber}
                  </Col>
                ))}
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label="项目名称">
              <Row>
                {(value?.productionLoadDevice.ouList ?? []).map(i => (
                  <Col span={24} key={i.id}>
                    {i.name}
                  </Col>
                ))}
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label="设备编号">{value?.productionLoadDevice?.deviceCode}</Descriptions.Item>
            <Descriptions.Item label="设备名称">{value?.productionLoadDevice?.deviceName}</Descriptions.Item>
          </Descriptions>
        </div>
      ) : null}
      {value?.totalLoadDevice ? (
        <div className={classNames(styles.desc, styles.background)}>
          <Descriptions title="储能调节总负载电表" colon={false} layout="vertical" column={4}>
            <Descriptions.Item label="项目编号">
              <Row>
                {(value?.totalLoadDevice.ouList ?? []).map(i => (
                  <Col span={24} key={i.id}>
                    {i.serialNumber}
                  </Col>
                ))}
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label="项目名称">
              <Row>
                {(value?.totalLoadDevice.ouList ?? []).map(i => (
                  <Col span={24} key={i.id}>
                    {i.name}
                  </Col>
                ))}
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label="设备编号">{value?.totalLoadDevice?.deviceCode}</Descriptions.Item>
            <Descriptions.Item label="设备名称">{value?.totalLoadDevice?.deviceName}</Descriptions.Item>
          </Descriptions>
        </div>
      ) : null}
    </>
  );
};

export default Uet;
