import { ExcludeType, PvAreaContext } from '../../const';
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Space } from 'antd';
import { Button, EllipsisSpan, Paging, Table, usePaging } from '@maxtropy/components';
import { PlusOutlined } from '@ant-design/icons';
import {
  apiV2PvDispersionRateExcludeDevicePagePost,
  V2PvDispersionRateExcludeDevicePagePostResponse,
} from '@maxtropy/dmes-apis-v2';
import { ColumnType } from 'antd/es/table';
import ExcludeSelectModal from '../ExcludeSelectModal';
import { v4 } from 'uuid';
import { cloneDeep, isEqual } from 'lodash-es';

type DataItem = Exclude<V2PvDispersionRateExcludeDevicePagePostResponse['list'], undefined>[number];

export interface EditObjectIdListItem {
  objectId: number;
  areaId: number;
}

interface Props {
  isEdit: boolean;
  excludeType: ExcludeType;
  id: number;
}

export interface ExcludeTableRef {
  getAddObjectIdList: () => EditObjectIdListItem[];
  getDelObjectIdList: () => EditObjectIdListItem[];
}

const ExcludeTable = forwardRef<ExcludeTableRef, Props>((props, ref) => {
  const { isEdit, excludeType, id } = props;

  const pvAreaContext = useContext(PvAreaContext);
  const { areaIdList } = pvAreaContext;
  const lastAreaIdList = useRef<number[]>();
  useEffect(() => {
    // 获取删除的areaIdList
    const delAreaIdList = lastAreaIdList.current?.filter(id => !areaIdList?.includes(id)) ?? [];
    // 对应删除
    setAddObjectIdList(addObjectIdList.filter(item => !delAreaIdList.includes(item.areaId)));
    setDelObjectIdList(delObjectIdList.filter(item => !delAreaIdList.includes(item.areaId)));

    lastAreaIdList.current = areaIdList;
  }, [areaIdList]);

  const pagingInfo = usePaging(20);
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;
  const [modalOpen, setModalOpen] = useState(false);
  const [addObjectIdList, setAddObjectIdList] = useState<EditObjectIdListItem[]>([]);
  const [delObjectIdList, setDelObjectIdList] = useState<EditObjectIdListItem[]>([]);
  const lastParams = useRef<any>();
  const [data, setData] = useState<DataItem[]>([]);
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    getAddObjectIdList: () => addObjectIdList,
    getDelObjectIdList: () => delObjectIdList,
  }));

  useEffect(() => {
    const params = {
      page: pageOffset,
      size: pageSize,
      excludeType: excludeType,
      id: id,
      areaIdList: areaIdList,
      addObjectIdList: addObjectIdList.map(item => ({ objectId: item.objectId })),
      delObjectIdList: delObjectIdList.map(item => item.objectId),
    };
    // 如果参数没有变化，不请求
    if (isEqual(params, lastParams.current)) {
      return;
    }

    // 如果没有片区，直接返回空数组，减少重复请求
    if (!areaIdList?.length) {
      setTotalCount(0);
      setData([]);
    } else {
      setLoading(true);
      apiV2PvDispersionRateExcludeDevicePagePost(params)
        .then(res => {
          setTotalCount(res.total ?? 0);
          setData(res.list ?? []);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    lastParams.current = cloneDeep(params);
  }, [pageOffset, pageSize, excludeType, id, areaIdList, addObjectIdList, delObjectIdList]);

  const columns: ColumnType<DataItem>[] = [
    {
      title: excludeType === ExcludeType.STRING ? '组串编号' : '组件编号',
      dataIndex: 'objectCode',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v}></EllipsisSpan>,
    },
    {
      title: '设备编号',
      dataIndex: 'deviceCode',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v}></EllipsisSpan>,
    },
    {
      title: '设备名称',
      dataIndex: 'deviceName',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v}></EllipsisSpan>,
    },
  ];

  return (
    <>
      {isEdit && (
        <Space style={{ marginBottom: 10 }}>
          <Button type="dashed" ghost icon={<PlusOutlined />} onClick={() => setModalOpen(true)}>
            选择排除{excludeType === ExcludeType.STRING ? '组串' : '组件'}
          </Button>
        </Space>
      )}

      <Table
        rowKey={() => v4()}
        loading={loading}
        dataSource={data}
        columns={columns}
        scroll={{
          y: 280,
        }}
      ></Table>
      <Paging pagingInfo={pagingInfo}></Paging>

      {modalOpen && (
        <ExcludeSelectModal
          open={modalOpen}
          setOpen={setModalOpen}
          excludeType={excludeType}
          addObjectIdList={addObjectIdList}
          delObjectIdList={delObjectIdList}
          onOk={(addObjectIdList, delObjectIdList) => {
            setAddObjectIdList(addObjectIdList);
            setDelObjectIdList(delObjectIdList);
          }}
        />
      )}
    </>
  );
});

export default ExcludeTable;
