import { ComponentType } from 'react';
import { BsaType } from '../../api/constans';

import BSaBaseInfo, { PCSType } from './components/BaseInfo';
import BsaPcs from './components/Pcs';
import BsaStack from './components/Stack';
import BsaRack from './components/Rack';
import BsaPack from './components/Pack';
import BsaGlobalEdge from './components/GlobalEdge';
import BsaRackEdgeTemplate from './components/RackEdgeTemplate';
import BsaRackEdgePoint from './components/RackEdgePoint';
import BsaPackEdgeTemplate from './components/PackEdgeTemplate';
import BsaPackEdgePoint from './components/PackEdgePoint';
import BsaCell from './components/Cell';
import BsaCellEdgePoint from './components/CellEdgePoint';
import BsaCellEdgeTemplate from './components/CellEdgeTemplate';
import { BSAStatus } from '@/api/type';

export interface BsaComponentsProps {
  disabled?: boolean;
  typeList?: BsaType[];
  currentType?: BsaType;
  foucsType?: BsaType;
  isDraft?: boolean;
  status?: BSAStatus;
  pcsStruct?: PCSType; // pcs构型
  updateFn?: (update?: boolean, type?: BsaType) => void;
  changeType?: (type: BsaType) => void;
  hardwareVersion?: string;
}

export const BsaComponentByType: Partial<Record<BsaType, ComponentType<BsaComponentsProps>>> = {
  [BsaType.ARRAY_INFO_INIT]: BSaBaseInfo,
  [BsaType.PCS_INIT]: BsaPcs,
  [BsaType.STACK_INIT]: BsaStack,
  [BsaType.RACK_DEVICE_CREATE]: BsaRack,
  [BsaType.RACK_DEVICE_CREATED]: BsaRack,
  [BsaType.RACK_DEVICE_COMPLETED]: BsaRack,
  [BsaType.PACK_DEVICE_CREATE]: BsaPack,
  [BsaType.PACK_DEVICE_CREATED]: BsaPack,
  [BsaType.PACK_DEVICE_COMPLETED]: BsaPack,
  [BsaType.CELL_DEVICE_CREATE]: BsaCell,
  [BsaType.CELL_DEVICE_CREATED]: BsaCell,
  [BsaType.CELL_DEVICE_COMPLETED]: BsaCell,
  [BsaType.ACQUISITION_CONFIG]: BsaGlobalEdge,
  [BsaType.RACK_EDGE_DEVICE_INIT]: BsaRackEdgeTemplate,
  [BsaType.RACK_EDGE_DEVICE_TEMPLATE_APPLYING]: BsaRackEdgeTemplate,
  [BsaType.RACK_EDGE_DEVICE_TEMPLATE_APPLIED]: BsaRackEdgeTemplate,
  [BsaType.RACK_EDGE_DEVICE_COMPLETED]: BsaRackEdgeTemplate,
  [BsaType.RACK_DAQ_POINT_CREATE]: BsaRackEdgePoint,
  [BsaType.RACK_DAQ_POINT_CREATED]: BsaRackEdgePoint,
  [BsaType.RACK_DAQ_POINT_COMPLETED]: BsaRackEdgePoint,
  [BsaType.PACK_EDGE_DEVICE_INIT]: BsaPackEdgeTemplate,
  [BsaType.PACK_EDGE_DEVICE_TEMPLATE_APPLYING]: BsaPackEdgeTemplate,
  [BsaType.PACK_EDGE_DEVICE_TEMPLATE_APPLIED]: BsaPackEdgeTemplate,
  [BsaType.PACK_EDGE_DEVICE_COMPLETED]: BsaPackEdgeTemplate,
  [BsaType.PACK_DAQ_POINT_CREATE]: BsaPackEdgePoint,
  [BsaType.PACK_DAQ_POINT_CREATED]: BsaPackEdgePoint,
  [BsaType.PACK_DAQ_POINT_COMPLETED]: BsaPackEdgePoint,
  [BsaType.CELL_EDGE_DEVICE_INIT]: BsaCellEdgeTemplate,
  [BsaType.CELL_EDGE_DEVICE_TEMPLATE_APPLYING]: BsaCellEdgeTemplate,
  [BsaType.CELL_EDGE_DEVICE_TEMPLATE_APPLIED]: BsaCellEdgeTemplate,
  [BsaType.CELL_EDGE_DEVICE_COMPLETED]: BsaCellEdgeTemplate,
  [BsaType.CELL_DAQ_POINT_CREATE]: BsaCellEdgePoint,
  [BsaType.CELL_DAQ_POINT_CREATED]: BsaCellEdgePoint,
  [BsaType.CELL_DAQ_POINT_COMPLETED]: BsaCellEdgePoint,
};

// export const BsaTitileByType: Record<
//   BsaType,
//   string
// > = {
//   [BsaType.ARRAY_INFO_INIT]: "储能阵列基础信息",
//   [BsaType.PCS_INIT]: "PCS设置",
//   [BsaType.STACK_INIT]: "电池堆设置",
//   [BsaType.RACK_INIT]: "电池簇设置",
//   [BsaType.RACK_DEVICE_CREATED]: "电池簇设置",
//   [BsaType.PACK_INIT]: "电池组设置",
//   [BsaType.PACK_DEVICE_CREATED]: "电池组设置",
//   [BsaType.CELL_INIT]: "电芯设置",
//   [BsaType.CELL_DEVICE_CREATED]: "电芯设置",
//   [BsaType.ACQUISITION_CONFIG]: "电池全局数采配置",
//   [BsaType.RACK_EDGE_DEVICE_INIT]: "电池簇数据模板配置",
//   [BsaType.RACK_EDGE_DEVICE_TEMPLATE_CONFIG]: "电池簇数据模板配置",
//   [BsaType.RACK_DAQ_POINT_CREATED]: "电池簇数据点配置",
//   [BsaType.PACK_EDGE_DEVICE_INIT]: "电池组数据模板配置",
//   [BsaType.PACK_EDGE_DEVICE_CONFIG]: "电池组数据模板配置",
//   [BsaType.PACK_DAQ_POINT_CREATED]: "电池组数据点配置",
// };

export const BsaTitileByType: Map<BsaType, string> = new Map([
  [BsaType.ARRAY_INFO_INIT, '储能阵列基础信息'],
  [BsaType.PCS_INIT, 'PCS设置'],
  [BsaType.STACK_INIT, '电池堆设置'],
  [BsaType.RACK_DEVICE_CREATE, '电池簇设置'],
  [BsaType.RACK_DEVICE_CREATED, '电池簇设置'],
  [BsaType.RACK_DEVICE_COMPLETED, '电池簇设置'],
  [BsaType.PACK_DEVICE_CREATE, '电池组设置'],
  [BsaType.PACK_DEVICE_CREATED, '电池组设置'],
  [BsaType.PACK_DEVICE_COMPLETED, '电池组设置'],
  [BsaType.CELL_DEVICE_CREATE, '电芯设置'],
  [BsaType.CELL_DEVICE_CREATED, '电芯设置'],
  [BsaType.CELL_DEVICE_COMPLETED, '电芯设置'],
  [BsaType.ACQUISITION_CONFIG, '电池全局数采配置'],
  [BsaType.RACK_EDGE_DEVICE_INIT, '电池簇数据模板配置'],
  [BsaType.RACK_EDGE_DEVICE_TEMPLATE_APPLYING, '电池簇数据模板配置'],
  [BsaType.RACK_EDGE_DEVICE_TEMPLATE_APPLIED, '电池簇数据模板配置'],
  [BsaType.RACK_EDGE_DEVICE_COMPLETED, '电池簇数据模板配置'],
  [BsaType.CELL_EDGE_DEVICE_INIT, '电芯数据模板配置'],
  [BsaType.CELL_EDGE_DEVICE_TEMPLATE_APPLYING, '电芯数据模板配置'],
  [BsaType.CELL_EDGE_DEVICE_TEMPLATE_APPLIED, '电芯数据模板配置'],
  [BsaType.CELL_EDGE_DEVICE_COMPLETED, '电芯数据模板配置'],
  [BsaType.RACK_DAQ_POINT_CREATE, '电池簇数据点配置'],
  [BsaType.RACK_DAQ_POINT_CREATED, '电池簇数据点配置'],
  [BsaType.RACK_DAQ_POINT_COMPLETED, '电池簇数据点配置'],
  [BsaType.PACK_EDGE_DEVICE_INIT, '电池组数据模板配置'],
  [BsaType.PACK_EDGE_DEVICE_TEMPLATE_APPLYING, '电池组数据模板配置'],
  [BsaType.PACK_EDGE_DEVICE_TEMPLATE_APPLIED, '电池组数据模板配置'],
  [BsaType.PACK_EDGE_DEVICE_COMPLETED, '电池组数据模板配置'],
  [BsaType.PACK_DAQ_POINT_CREATE, '电池组数据点配置'],
  [BsaType.PACK_DAQ_POINT_CREATED, '电池组数据点配置'],
  [BsaType.PACK_DAQ_POINT_COMPLETED, '电池组数据点配置'],
  [BsaType.CELL_DAQ_POINT_CREATE, '电芯数据点配置'],
  [BsaType.CELL_DAQ_POINT_CREATED, '电芯数据点配置'],
  [BsaType.CELL_DAQ_POINT_COMPLETED, '电芯数据点配置'],
]);

// export const BsaTypeByTitle: Record<
//   string,
//   BsaType
// > = {
//   "储能阵列基础信息": BsaType.ARRAY_INFO_INIT ,
//   "PCS设置": BsaType.PCS_INIT ,
//   "电池堆设置": BsaType.STACK_INIT ,
//   "电池簇设置": BsaType.RACK_DEVICE_CREATED ,
//   "电池组设置": BsaType.PACK_DEVICE_CREATED ,
//   "电芯设置": BsaType.CELL_DEVICE_CREATED ,
//   "电池全局数采配置": BsaType.ACQUISITION_CONFIG ,
//   "电池簇数据模板配置": BsaType.RACK_EDGE_DEVICE_TEMPLATE_CONFIG ,
//   "电池簇数据点配置": BsaType.RACK_DAQ_POINT_CREATED ,
//   "电池组数据模板配置": BsaType.PACK_EDGE_DEVICE_CONFIG ,
//   "电池组数据点配置": BsaType.PACK_DAQ_POINT_CREATED ,
// };

export const BsaTypeByTitle: Map<string, BsaType> = Array.from(BsaTitileByType.entries()).reduce(
  (accumulator: Map<string, BsaType>, currentValue: [BsaType, string]) => {
    accumulator.set(currentValue[1], currentValue[0]);
    return accumulator;
  },
  new Map()
);

export function getPrevious(foucsType: BsaType, typeList: BsaType[]) {
  const titleList: string[] = Array.from(new Set((typeList ?? []).map(i => BsaTitileByType.get(i)!)));
  const focusTitle = BsaTitileByType.get(foucsType);
  const index = titleList.findIndex(i => focusTitle === i);
  if (index > 0) {
    return BsaTypeByTitle.get(titleList[index - 1]!);
  } else {
    return BsaTypeByTitle.get(titleList[titleList.length - 1]!);
  }
}

export function getNext(foucsType: BsaType, typeList: BsaType[], currentType?: BsaType) {
  if (foucsType === currentType) {
    return undefined;
  }
  const titleList: string[] = Array.from(
    new Set([...(typeList ?? []), currentType].filter(i => i).map(i => BsaTitileByType.get(i!)!))
  );
  const focusTitle = BsaTitileByType.get(foucsType);
  const index = titleList.findIndex(i => focusTitle === i);
  if (index !== -1) {
    const type = BsaTypeByTitle.get(titleList[index + 1]);
    if (type) {
      return typeList.includes(type) ? type : currentType;
    }
  }
}
