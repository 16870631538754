import {
  ChargeGunLinkType,
  ChargingPileAndGunDtoList,
  ChargingStationByOuIdPageReq,
  ChargingStationListByOuIdPageRes,
  DetailInfoRes,
  getChargingGunByOuId,
  getChargingStationByOuId,
  getModel,
  ModalRes,
} from '@/api/chargingStationManagement';
import { DeviceTreeProps, getDeviceTypeTree } from '@/api/device';
import {
  Select,
  Table,
  Form,
  Input,
  Modal,
  Button,
  EllipsisSpan,
  Paging,
  usePaging,
  CustomFilter,
} from '@maxtropy/components';
import React, { Ref, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { ChargeDeviceLinkFormat, ChargeDeviceLinkType } from '../DeviceListForm';
import styles from './index.module.scss';

interface DeviceModalProps {
  selectedDeviceType?: ChargeDeviceLinkType;
  disabledItems?: ChargingPileAndGunDtoList[];
  visible?: boolean;
  onOk?: () => void;
  onCancel?: () => void;
  row?: ChargingPileAndGunDtoList;
  data?: DetailInfoRes;
}

export interface DeviceModalRef {
  selectedRows?: ChargingStationListByOuIdPageRes;
}

export interface FilterProps extends Omit<ChargingStationByOuIdPageReq, 'page' | 'size'> {}

const DeviceModal = React.forwardRef((props: DeviceModalProps, ref: Ref<DeviceModalRef>) => {
  const { selectedDeviceType, row, visible, onOk, onCancel, data } = props;
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [selectedRows, setSelectedRows] = useState<ChargingStationListByOuIdPageRes>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<FilterProps>();
  const [dataSource, setDataSource] = useState<ChargingStationListByOuIdPageRes[]>();
  const [deviceTypeList, setDeviceTypeList] = useState<DeviceTreeProps[]>();
  const [deviceType, setDeviceType] = useState<number>();

  useEffect(() => {
    if (selectedDeviceType) {
      getDeviceTypeTree().then(res => {
        const dataTypeList = formatTreeData(
          res,
          selectedDeviceType === ChargeDeviceLinkType.CHARGINGSTATION ? '充电桩' : '充电枪'
        );
        setDeviceTypeList(dataTypeList);
        setSearchParams({ chargingStationId: data?.id!, deviceType: dataTypeList.map(i => i.id) });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeviceType]);

  useEffect(() => {
    if (JSON.stringify(searchParams) !== '{}' && searchParams) {
      setIsLoading(true);
      if (selectedDeviceType && selectedDeviceType === ChargeDeviceLinkType.CHARGINGSTATION) {
        getChargingStationByOuId(data?.ouId!, {
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) setTotalCount(res.total);
          setDataSource(res.list);
          setIsLoading(false);
        });
      } else {
        getChargingGunByOuId(data?.ouId!, {
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) setTotalCount(res.total);
          setDataSource(res.list);
          setIsLoading(false);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, pageSize, searchParams, setTotalCount, data]);

  useEffect(() => {
    if (visible && row) {
      if (selectedDeviceType === ChargeDeviceLinkType.ACHARGINGGUN) {
        setSelectedRowKeys(row.chargingGunList?.find(i => i.chargingGunType === ChargeGunLinkType.ACHARGINGGUN)?.id);
      } else {
        setSelectedRowKeys(row.chargingGunList?.find(i => i.chargingGunType === ChargeGunLinkType.BCHARGINGGUN)?.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row, visible]);

  const columns = [
    {
      title: '设备编号',
      dataIndex: 'code',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '设备名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '所属类目',
      dataIndex: 'deviceType',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '物模型型号',
      dataIndex: 'modelName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '设备标签',
      dataIndex: 'deviceTags',
      ellipsis: { showTitle: true },
      render: (v: string[]) => <EllipsisSpan value={v && v.length > 0 ? v.join('、') : '--'} />,
    },
    {
      title: '操作',
      width: 120,
      render: (record: ChargingStationListByOuIdPageRes) => {
        return selectedRowKeys === record.id ? (
          <Button type="link" onClick={cancelSelect}>
            取消选择
          </Button>
        ) : (
          <Button type="link" onClick={() => onSelect(record)}>
            选择
          </Button>
        );
      },
    },
  ];

  const cancelSelect = () => {
    setSelectedRowKeys(undefined);
    setSelectedRows(undefined);
  };

  const onSelect = (record: ChargingStationListByOuIdPageRes) => {
    setSelectedRowKeys(record.id);
    setSelectedRows(record);
  };

  useImperativeHandle(ref, () => ({
    selectedRows,
  }));

  const onReset = () => {
    const params = {
      nameOrCode: undefined,
      deviceType: (deviceTypeList ?? []).map(i => i.id),
      tag: undefined,
      modelId: undefined,
      chargingStationId: data?.id!,
    };
    setSearchParams(params);
    setPageOffset(1);
  };

  const onFinish = (v: any) => {
    setSearchParams({
      ...v,
      deviceType: v.deviceType ? [v.deviceType] : (deviceTypeList ?? []).map(i => i.id),
      chargingStationId: data?.id!,
    });
    setPageOffset(1);
  };

  const [model, setModel] = useState<ModalRes[]>([]);

  useEffect(() => {
    if (deviceType !== undefined) {
      getModel({ deviceTypeIds: deviceType }).then(setModel);
    }
  }, [deviceType]);

  const options = useMemo(() => {
    return model.map(i => ({ label: i.modelNo, value: i.id }));
  }, [model]);

  const filters = (
    <CustomFilter onFinish={v => onFinish(v as FilterProps)} form={form} onReset={onReset} colSpan={8}>
      <Form.Item name="nameOrCode" label="编号/名称">
        <Input placeholder="请输入编号或名称查询" />
      </Form.Item>
      <Form.Item label="一级类目">
        <span>{selectedDeviceType === ChargeDeviceLinkType.CHARGINGSTATION ? '充电桩' : '充电枪'}</span>
      </Form.Item>
      <Form.Item label="二级类目" name="deviceType">
        <Select
          onChange={(e: number) => {
            setDeviceType(e);
          }}
          style={{ width: '100%' }}
          placeholder="请选择"
        >
          {deviceTypeList?.map(i => (
            <Select.Option key={i.id} value={i.id}>
              {i.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="物模型型号" name="modelId">
        <Select
          allowClear
          optionFilterProp="label"
          showSearch
          options={options}
          disabled={!form.getFieldValue('deviceType')}
          placeholder={form.getFieldValue('deviceType') ? '请选择' : '请先选择所属类目'}
        />
      </Form.Item>
      <Form.Item label="设备标签" name="tag">
        <Input placeholder="请输入" />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Modal
      open={visible}
      title={`${ChargeDeviceLinkFormat[selectedDeviceType as ChargeDeviceLinkType]}设备选择`}
      size="big"
      destroyOnClose
      forceRender
      onOk={() => {
        setPageOffset(1);
        onOk?.();
        form.resetFields();
      }}
      onCancel={() => {
        setPageOffset(1);
        onCancel?.();
        setSelectedRows(undefined);
        form.resetFields();
      }}
    >
      {filters}
      <div className={styles.table_text}>
        <span>
          点击前往
          <a rel="noreferrer" href={`${(window as any).IOTPLATFORMORIGIN}/device/manage/device/create`} target="_blank">
            添加设备
          </a>
        </span>
      </div>
      <Table
        loading={isLoading}
        sticky
        dataSource={dataSource}
        rowKey="id"
        scroll={{ y: 300 }}
        columns={columns}
        pagination={false}
      />
      <Paging pageSizeOptions={['5', '10', '20', '30', '50', '100']} pagingInfo={pagingInfo} />
    </Modal>
  );
});

export default DeviceModal;

export const formatTreeData = (tree: DeviceTreeProps[], typeName: string) => {
  let res: DeviceTreeProps[] = [];
  tree.forEach(i => {
    if (i.name === typeName) {
      res = res.concat(i.children ?? []);
    }
  });
  return res;
};
