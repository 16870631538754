import { FormInstance } from 'antd';
import React from 'react';
import EffectiveTimePicker, { EffectiveTimeMode } from './EffectiveTimePicker';
import { InfoCircleOutlined } from '@ant-design/icons';
import { MonitoringIndicatorProps } from '../../api/circuit';
import dayjs from 'dayjs';
import { checkEffectiveTime } from './BindForm';
import { Form } from '@maxtropy/components';

interface UnbindFormProps {
  row?: Partial<MonitoringIndicatorProps>;
  form: FormInstance;
}

const UnbindForm: React.FC<UnbindFormProps> = props => {
  const { form, row } = props;

  return (
    <Form form={form}>
      <Form.Item
        name="effectiveTime"
        required
        rules={[{ validator: checkEffectiveTime(EffectiveTimeMode.UNBIND, dayjs(row?.startTime!)) }]}
      >
        <EffectiveTimePicker mode={EffectiveTimeMode.UNBIND} />
      </Form.Item>

      <Form.Item>
        <>
          <InfoCircleOutlined style={{ color: 'var(--warning-color)', marginRight: 4 }}></InfoCircleOutlined>
          <span>请注意变更后数据存在一定的滞后性！</span>
        </>
      </Form.Item>
    </Form>
  );
};

export default UnbindForm;
