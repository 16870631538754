import React, { useContext } from 'react';
import { PvForecastContext } from '../..';
import styles from './index.module.scss';
import tittleDecoration from '../imgs/tittleDecoration.png';
import { useRequest } from 'ahooks';
import { apiV2BsaDataPredictRevenuePost } from '@maxtropy/dmes-apis-v2';
import { EllipsisSpan, Table } from '@maxtropy/components';
import dayjs from 'dayjs';
import { keepTwoOrLine } from '../../utils';

const columns = [
  {
    title: '日期',
    dataIndex: 'date',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD') : '--'} />,
  },
  {
    title: '总收益(元)',
    dataIndex: 'totalRevenue',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={<span style={{ color: '#FAAD14' }}>{keepTwoOrLine(v)}</span>} />,
  },
  {
    title: '上网收益(元)',
    dataIndex: 'netRevenue',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={keepTwoOrLine(v)} />,
  },
  {
    title: '消纳收益(元)',
    dataIndex: 'absorptionRevenue',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={keepTwoOrLine(v)} />,
  },
  {
    title: '补贴收益(元)',
    dataIndex: 'subsidyRevenue',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={keepTwoOrLine(v)} />,
  },
];

const IncomeForecast = () => {
  const { stationId, date } = useContext(PvForecastContext);

  // 收益预测
  const { data: incomeData, loading } = useRequest(
    () => {
      return apiV2BsaDataPredictRevenuePost({
        predictDate: date?.valueOf(),
        pvStationId: stationId,
      });
    },
    {
      ready: !!stationId && !!date,
      refreshDeps: [stationId, date],
    }
  );

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <img src={tittleDecoration} alt="pic" className={styles.titleBorder} />
        <span className={styles.title}>收益预测</span>
      </div>

      <div className={styles.content_box}>
        <Table
          rowKey="date"
          loading={loading}
          scroll={{ y: 500 }}
          dataSource={incomeData?.list}
          columns={columns}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default IncomeForecast;
