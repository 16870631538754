import { FC, useEffect, useMemo, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import ReactEcharts from 'echarts-for-react';
import { isNil } from 'lodash-es';
import {
  BsaType,
  dash2LegendIcon,
  dash3LegendIcon,
  DataType,
  socColorList,
  TabType,
  TimeGranularity,
} from '../../const';
import { apiV2NeoBsaRunningDataPost, V2NeoBsaRunningDataPostResponse } from '@maxtropy/dmes-apis-v2';
import styles from './index.module.scss';
import { pcsColorList } from '../../const';
import { Spin } from 'antd';
import { Chart_Grid_Right, getEndLabel } from '../OperatingPower';
import { Empty } from '@maxtropy/components';

type ResData = Exclude<V2NeoBsaRunningDataPostResponse['list'], undefined>[number];

interface Props {
  bsaId: number | undefined;
  bsaType: BsaType | undefined;
  date: Dayjs;
  timeGranularity: TimeGranularity;
  showPcs: boolean;
}

const Soc: FC<Props> = props => {
  const { bsaId, bsaType, date, timeGranularity, showPcs } = props;
  const [resData, setResData] = useState<ResData[]>([]);
  const [loading, setLoading] = useState(false);

  // 请求参数，防止快速切换时，接口返回速度慢导致数据显示错乱
  const cacheParams = useRef({ bsaId, date, timeGranularity });

  const fetchData = async () => {
    if (isNil(bsaId) || isNil(bsaType) || isNil(date) || isNil(timeGranularity)) return;
    cacheParams.current = { bsaId, date, timeGranularity };
    const res = await apiV2NeoBsaRunningDataPost({
      bsaId,
      bsaType,
      tabType: TabType.SOC,
      timeGranularity,
      time: date?.valueOf(),
    });
    if (
      cacheParams.current.bsaId !== bsaId ||
      cacheParams.current.date !== date ||
      cacheParams.current.timeGranularity !== timeGranularity
    ) {
      return;
    }
    res.list?.forEach(item => {
      item.bsaDataList?.forEach(dataItem => {
        if (!isNil(dataItem.value)) {
          dataItem.value = Number(dataItem.value.toFixed(4));
        }
      });
    });
    setResData(res.list ?? []);
  };

  const notEmpty = resData.some(item => item.bsaDataList?.length);

  useEffect(() => {
    setLoading(true);
    setResData([]);
    fetchData()
      .then()
      .finally(() => setLoading(false));
    if (date?.isSame(dayjs(), 'day')) {
      const timer = setInterval(() => {
        fetchData().then();
      }, 1000 * 60);
      return () => clearInterval(timer);
    }
  }, [bsaId, bsaType, date, timeGranularity]);

  const echartsOption = useMemo(() => {
    const socData = resData.filter(item => item.dataType === DataType.SOC);
    const pcsData = resData.filter(item => item.dataType === DataType.PCS);

    const option = {
      grid: {
        left: 50,
        right: Chart_Grid_Right + 10,
        top: 70,
        bottom: 80,
        containLabel: true,
      },
      xAxis: {
        type: 'time',
        axisLabel: {
          color: 'rgba(255,255,255,0.85)',
        },
        axisLine: {
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
      },
      yAxis: showPcs ? [getYAixs('SOC(%)'), getYAixs('PCS(kW)')] : [getYAixs('SOC(%)')],
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderColor: 'transparent',
        textStyle: {
          color: 'rgba(255,255,255,0.65)',
        },
        formatter(items: any[]) {
          const { axisValueLabel } = items[0];
          let str: string = axisValueLabel;

          items.forEach(item => {
            const { seriesName, data, marker } = item;
            const value = !isNil(data[1]) ? `${data[1]}` : '--';
            const unit = data[2];
            str += `<div style="display:flex;margin-top:4px;justify-content:space-between;gap:30px;"><span>${marker}${seriesName}</span> <span>${value}${unit}</span></div>`;
          });

          return str;
        },
      },
      legend: {
        show: true,
        type: 'scroll',
        right: 20,
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
          fontSize: '14px',
        },
        itemWidth: 16,
        itemHeight: 4,
        data: [],
      },
      dataZoom: [{ start: 0, end: 100 }],
      series: [],
    };

    let legendData = option.legend.data as any[];
    let series = option.series as any[];
    // soc
    socData.forEach((item, index) => {
      legendData.push({
        name: item.deviceName,
        icon: dash3LegendIcon,
        itemStyle: {
          color: socColorList[index % socColorList.length],
        },
      });
      series.push({
        name: item.deviceName,
        type: 'line',
        yAxisIndex: 0,
        showSymbol: false,
        itemStyle: {
          color: socColorList[index % socColorList.length],
        },
        lineStyle: {
          type: [2, 2],
        },

        data:
          item.bsaDataList?.map(dataItem => {
            return [dataItem.time, dataItem.value, '%'];
          }) ?? [],
        ...getEndLabel(
          item.bsaDataList?.map(dataItem => {
            return [dataItem.time, dataItem.value];
          }) as any[],
          '%'
        ),
      });
    });
    // pcs
    if (showPcs) {
      pcsData.forEach((item, index) => {
        legendData.push({
          name: item.deviceName,
          icon: dash2LegendIcon,
          itemStyle: {
            color: pcsColorList[index % pcsColorList.length],
          },
        });
        series.push({
          name: item.deviceName,

          yAxisIndex: 1,
          type: 'line',
          showSymbol: false,
          itemStyle: {
            color: pcsColorList[index % pcsColorList.length],
          },
          lineStyle: {
            type: [6, 6],
          },
          smooth: true,
          data:
            item.bsaDataList?.map(dataItem => {
              return [dataItem.time, dataItem.value, 'kW'];
            }) ?? [],
          ...getEndLabel(
            item.bsaDataList?.map(dataItem => {
              return [dataItem.time, dataItem.value];
            }) as any[]
          ),
        });
      });
    }

    return option;
  }, [showPcs, resData]);

  return (
    <Spin spinning={loading} wrapperClassName={styles.wrap}>
      {notEmpty ? (
        <ReactEcharts option={echartsOption} notMerge className={styles.chart}></ReactEcharts>
      ) : (
        <Empty className={styles.empty} />
      )}
    </Spin>
  );
};

const getYAixs = (name: string) => {
  return {
    name: name,
    nameTextStyle: {
      color: 'rgba(255,255,255,0.85)',
    },
    type: 'value',
    axisLabel: {
      color: 'rgba(255,255,255,0.85)',
    },
    splitLine: {
      lineStyle: {
        color: 'rgba(255,255,255,0.3)',
      },
    },
    scale: true,
    boundaryGap: ['5%', '5%'],
  };
};

export default Soc;
