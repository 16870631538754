import { fetch } from '@maxtropy/components';
import qs from 'qs';
import {
  FieldStrategy,
  PowerType,
  ProtectType,
  StrategyRunningStatus,
  StrategySection,
  StrategyTemplate,
  StrategyTemplateStatus,
  TimePeriodDimension,
} from '../pages/Strategy/types';
import { PageRequest, PageResponse } from './page';
import { BSA, BSADetail, Customer, FCSDetail, FCSRatedParams, Ou, UET } from '../types';
import { LocalCalendarStatus } from './type';
import { Key } from 'react';
import { BsaListProps } from './bsa';
import { PcsDevice } from './entity';

export interface StrategyTemplatePageQuery {
  code?: string;
  ifCancel?: boolean;
  status?: StrategyTemplateStatus;
  bsaId?: Key;
  name?: string; // 模板名称
  versionId?: string;
  version?: string;
}

export interface StrategyTemplateBody {
  id?: number;
  type: number;
  name: string;
  protectType: ProtectType;
  dodMax?: number;
  dodMin?: number;
  cellVoltageMax?: number;
  cellVoltageMin?: number;
  standbyPower: boolean;
  timeGranularity: TimePeriodDimension;
  powerType: PowerType;
  sysVersionId: number;
  sections: StrategySection[];
}

export interface Version {
  id: number;
  version: string;
  name: string;
}

export interface Organization {
  data: Customer;
  children: Organization[];
}

export interface FieldStrategyBody extends StrategyTemplateBody {
  version: number;
  templateId: number;
  executiveTimeFrom: string;
  executiveTimeTo: string;
  globalVersion: number;
}

export const fetchStrategyTemplatePage = (params: StrategyTemplatePageQuery & PageRequest) =>
  fetch<PageResponse<StrategyTemplate>>(
    `/api/energy-storage-strategy/page?${qs.stringify(params, {
      indices: false,
    })}`
  ).then(res => res);

export const fetchStrategyTemplateList = (version = '1') =>
  fetch<StrategyTemplate[]>(`/api/energy-storage-strategy/list?version=${version}`).then(res => res);

export const createStrategyTemplate = (data: StrategyTemplateBody) =>
  fetch<boolean>(`/api/energy-storage-strategy/insert`, {
    method: 'POST',
    body: JSON.stringify(data),
  }).then(res => res);

export const updateStrategyTemplate = (data: StrategyTemplateBody) =>
  fetch<boolean>(`/api/energy-storage-strategy/update`, {
    method: 'PUT',
    body: JSON.stringify(data),
  }).then(res => res);

export const fetchStrategyTemplateDetail = (id: number) =>
  fetch<StrategyTemplate>(`/api/energy-storage-strategy/detail/${id}`).then(res => res);

export const updateStrategyTemplateStatus = (id: number, status: StrategyTemplateStatus) =>
  fetch<boolean>(`/api/energy-storage-strategy/update-status/${id}?${qs.stringify({ status }, { indices: false })}`, {
    method: 'PUT',
  });

export const fetchVersionList = () =>
  fetch<Version[]>(`/api/energy-storage-strategy/list-version`, { method: 'POST' }).then(res => res);

export const fetchVersionListWithParams = (version: string) =>
  fetch<Version[]>(`/api/energy-storage-strategy/list-version?fcsSoftwareVersion=${version}`, { method: 'POST' }).then(
    res => res
  );

export const fetchCustomerList = () => fetch<Customer[]>(`/api/energy-storage-strategy/list-customer`).then(res => res);

export const fetchOuList = (mcid: string) =>
  fetch<Ou[]>(`/api/energy-storage-strategy/list-ou/${mcid}`).then(res => res);

export const fetchUETList = (ouId: number) =>
  fetch<UET[]>(`/api/energy-storage-strategy/list-uet/${ouId}`).then(res => res);

export const fetchBSAList = (uetId: number) =>
  fetch<BSA[]>(`/api/energy-storage-strategy/list-bsa/${uetId}`).then(res => res);
export interface FCSInfo {
  id: number;
  name: string;
  serialNumber?: string;
  gatewayId?: number;
  ouId?: number;
  remark?: string;
  updateByUserId?: string;
  softwareVersion?: string;
  softwareName: string;
  type?: number; // fcs类型
  linkType?: number; //  0表示主控，1表示从控，-1表示非主从结构
  selected?: boolean; // 自定义字段, 是否已被选择
}
// 根据bsa查询fcs列表
export const fetchFcsList = (bsaId: number, hardwareVersion: string) =>
  fetch<FCSInfo[]>(`/api/bsa/fcs-list-by-bsa?bsaId=${bsaId}&hardwareVersion=${hardwareVersion}`).then(res => res);

export const fetchBSADetail = (id: number) =>
  fetch<BSADetail>(`/api/energy-storage-strategy/bsa-link-info/${id}`).then(res => res);

export const fetchFieldStrategyList = (fcsId: number) =>
  fetch<FieldStrategy[]>(`/api/energy-storage-strategy/list-local/${fcsId}`).then(res => res);

export const updateFieldStrategy = (fcsId: number, data: FieldStrategyBody) =>
  fetch<{ result: boolean; message: string }>(`/api/energy-storage-strategy/local-update/${fcsId}`, {
    method: 'PUT',
    body: JSON.stringify(data),
  }).then(res => res);

export const fetchOrganizations = () =>
  fetch<Organization>(`/api/energy-storage-strategy/organization-with-code`).then(res => res);

export const fetchStrategyMcids = (id: number) =>
  fetch<string[]>(`/api/energy-storage-strategy/mcid-list-by-strategy/${id}`).then(res => res);

export const updateStrategyMcids = (data: { energyStorageStrategyId: number; mcidList: string[] }) =>
  fetch(`/api/energy-storage-strategy/update-mcid-binding-strategy`, {
    body: JSON.stringify(data),
    method: 'POST',
  }).then(res => res);

export interface FieldStrategyListQuery {
  beOverdue: boolean;
}

export const fetchFieldStrategies = (fcsId: number, query: FieldStrategyListQuery) =>
  fetch<{ list: FieldStrategy[] }>(
    `/api/energy-storage-strategy/list-local/${fcsId}?${qs.stringify(query, { indices: false })}`
  ).then(res => res);

export interface LaunchStrategyBody {
  id: number;
  name: string;
  executiveTimes: {
    day: string;
    version: number;
  }[];
}

export const launchStrategy = (data: LaunchStrategyBody) =>
  fetch<{ result: boolean; message: string }>(`/api/energy-storage-strategy/deliver`, {
    method: 'POST',
    body: JSON.stringify(data),
  });

export const fetchFieldStrategyDetail = (bsaId: number, id: number) =>
  fetch<FieldStrategy>(`/api/energy-storage-strategy/local-details/${bsaId}?id=${id}`).then(res => res);

export const fetchBSAInfo = (fcsId: number) =>
  fetch<FCSDetail>(`/api/energy-storage-strategy/bsa-info/${fcsId}`).then(res => res);

export const fetchFCSRatedParams = (fcsId: number) =>
  fetch<FCSRatedParams>(`/api/energy-storage-strategy/fcs-ratings/${fcsId}`).then(res => res);

export const updateFieldStatus = (
  bsaId: number,
  params: { strategyIds: number; status: StrategyRunningStatus; version: number; globalVersion: number }
) =>
  fetch<boolean>(
    `/api/energy-storage-strategy/local-status-update/${bsaId}?${qs.stringify(params, { indices: false })}`,
    { method: 'PUT' }
  ).then(res => res);

export interface LocalCalendarProps {
  id: number;
  day: string;
  name: string;
  code: string;
  globalVersion: number;
  status: LocalCalendarStatus;
  version: number;
  templateId: number;
}

export interface CalendarReq {
  bsaId: number;
  year: string;
  month: string;
}

export const fetchCalendar = (query: CalendarReq) =>
  fetch<LocalCalendarProps[]>(
    `/api/energy-storage-strategy/local-calendar/${query.bsaId}?${qs.stringify(query, { indices: false })}`
  );

export interface LocalDetailProps {
  bsaId: number;
  fcsId: number;
  globalVersion: number;
  id: number;
  mcid: string;
  name: string;
  ouId: number;
  protectType: number;
  sections: StrategySection[];
  timeGranularity: TimePeriodDimension;
  powerType: PowerType;
  uetId: number;
  version: number;
  sysVersionId: number;
}

export const fetchLocalDetail = (id: string, bsaId: Key) =>
  fetch<LocalDetailProps>(`/api/energy-storage-strategy/local-details/${bsaId}?id=${id}`);

export interface ChangeStatusReq {
  strategyIds: number | number[];
  status: LocalCalendarStatus;
  version: number;
  globalVersion: number;
}

export const changeStatus = (fcsId: number, query: ChangeStatusReq) =>
  fetch(`/api/energy-storage-strategy/local-status-update/${fcsId}?${qs.stringify(query, { indices: false })}`, {
    method: 'PUT',
  });

// 根据系统策略版本查询bsa列表

export const getBsaListBySystemVersion = (systemVersion?: string) => {
  let params = systemVersion ?? '';
  return fetch<BsaListProps[]>(`/api/bsa/list/simple?systemVersion=${params}`);
};

export interface PutRecordResponse {
  id: number;
  userAccount: string;
  templateId: number;
  deliverTime: string;
  deliverDates: string[];
  bsaId: number;
  bsaName: string;
  tenantMcid: string;
}
// 根据策略模板id查询投放记录
export const getPutRecordList = (templateId?: Key) => {
  return fetch<PutRecordResponse[]>(`/api/energy-storage-strategy/deliver/log/${templateId}`);
};
// 获取策略模板具体信息

export interface TemplateDetail {
  id: number;
  name: string;
  bsaId: number;
  bsaName: string;
  uetName: string;
  ouName: string;
  networkName: string;
  installedCapacity: number;
  fcsList: FCSInfo[];
  pcsList: PcsDevice[];
  protectType: number;
  timeGranularity: number;
  sysVersionId: number;
  additionalFields: AdditionalField;
  sections: Section[];
  status: number;
  code: string;
  version: string;
  netNo: string;
}
export interface AdditionalField {
  dodMax: number;
  dodMin: number;
  cellVoltageMax: number;
  cellVoltageMin: number;
}
export interface Section {
  beginTime: string;
  endTime: string;
  type: number;
  additionalFields: AdditionalField;
}

export const getTemplateDetail = (templateId?: Key) => {
  return fetch<TemplateDetail>(`/api/energy-storage-strategy/detail/${templateId}`);
};
// CF策略模板查询上端变压器模板列表
export interface TemplatePcsDeviceLinkRes {
  deviceName?: string;
  deviceId?: number;
}
export const getTemplatePcsDeviceLink = (bsaId: number) => {
  return fetch<{ list: TemplatePcsDeviceLinkRes[] }>(
    `/api/v2/energy-storage-strategy/pcs-device-link`,
    {
      method: 'POST',
      body: JSON.stringify({
        id: bsaId,
      }),
    },
    true
  ).then(res => res.list);
};

export enum StrategyVersion {
  v1 = 1,
  v2 = 2,
}
// fcs类型 0表示主控，1表示从控，-1表示非主从结构
export enum FCSType {
  NONE = -1,
  MAIN = 0,
  OTHER = 1,
}
