import { CloseCircleFilled } from '@ant-design/icons';
import { Modal } from '@maxtropy/components';
import { Space } from 'antd';
interface Iprops {
  callback?: () => void;
}
const ModalError = ({ callback }: Iprops) => {
  return Modal.confirm({
    icon: <></>,
    title: (
      <Space>
        <CloseCircleFilled style={{ color: 'red' }} />
        <span>批量创建设备失败, 是否重新创建</span>
      </Space>
    ),
    cancelText: '否',
    okText: '是',

    onOk: () => callback?.(),
  });
};

export default ModalError;
