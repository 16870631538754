import { Button, Checkbox, DatePicker, Radio } from '@maxtropy/components';
import { FC, useState } from 'react';
import styles from './index.module.scss';
import dayjs from 'dayjs';
import { DatePickerProps } from 'antd/es/date-picker';
import { Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { apiV2PvOutlinePvDataStatisticsPost } from '@maxtropy/dmes-apis-v2';
import ElecStatistics from './ElecStatistics';
import ElecChart from './ElecChart';
import { useNavigate } from 'react-router-dom';
import { ResolutionEnum } from '@/pages/PvIncomeReport';
import { TimePeriod } from '@/pages/BsaHourlyElecReport/const';

export enum Granularity {
  DAY,
  MONTH,
}

export enum TimeType {
  NONE, // 不分时
  SUMMIT, // 尖
  PEAK, // 峰
  PLAIN, // 平
  VALLEY, // 谷
}

export const TimeTypeLabel = {
  [TimeType.NONE]: '',
  [TimeType.SUMMIT]: '尖',
  [TimeType.PEAK]: '峰',
  [TimeType.PLAIN]: '平',
  [TimeType.VALLEY]: '谷',
};

export enum PowerTab {
  GENERATION,
  ONLINE,
  CONSUMPTION,
}

interface Props {
  pvId: number | undefined;
}

const BottomStatistics: FC<Props> = props => {
  const { pvId } = props;
  const navigate = useNavigate();

  const [granularity, setGranularity] = useState(Granularity.DAY);
  const [timeRange, setTimeRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    dayjs().subtract(32, 'd'),
    dayjs().subtract(1, 'd'),
  ]);
  const [showTimePeriod, setShowTimePeriod] = useState(false);
  const [timeType, setTimeType] = useState<TimeType>(TimeType.NONE);
  const [powerTab, setPowerTab] = useState<PowerTab>(PowerTab.GENERATION);

  const disabledDate: DatePickerProps['disabledDate'] = (current, { from }) => {
    if (granularity === Granularity.DAY) {
      if (current.startOf('day') >= dayjs().startOf('day')) return true;
    } else {
      if (current > dayjs().endOf('day')) return true;
    }

    if (from) {
      if (granularity === Granularity.MONTH) {
        return Math.abs(current.startOf('month').diff(from.startOf('month'), 'months')) > 11;
      } else {
        return Math.abs(current.startOf('day').diff(from.startOf('day'), 'days')) > 31;
      }
    }
    return false;
  };

  const { data } = useRequest(
    () => {
      return apiV2PvOutlinePvDataStatisticsPost({
        pvId: pvId,
        granularity: granularity,
        from: timeRange[0].valueOf(),
        to: timeRange[1].valueOf(),
        powerTab: powerTab,
        timeType: timeType,
      });
    },
    {
      ready: !!pvId,
      refreshDeps: [pvId, granularity, timeRange, timeType, powerTab],
    }
  );

  return (
    <div className={styles.wrap}>
      <div className={styles.topFilter}>
        <Radio.Group
          buttonStyle="solid"
          value={granularity}
          onChange={e => {
            setGranularity(e.target.value);
            if (e.target.value === Granularity.DAY) {
              setTimeRange([dayjs().subtract(32, 'd'), dayjs().subtract(1, 'd')]);
            } else {
              setTimeRange([dayjs().subtract(11, 'month'), dayjs()]);
            }
          }}
        >
          <Radio.Button value={Granularity.DAY}>按日</Radio.Button>
          <Radio.Button value={Granularity.MONTH}>按月</Radio.Button>
        </Radio.Group>
        <DatePicker.RangePicker
          allowClear={false}
          picker={granularity === Granularity.DAY ? 'date' : 'month'}
          value={timeRange}
          onChange={dates => {
            setTimeRange(dates as [dayjs.Dayjs, dayjs.Dayjs]);
          }}
          disabledDate={disabledDate}
        ></DatePicker.RangePicker>
        <Checkbox
          checked={showTimePeriod}
          onChange={e => {
            setShowTimePeriod(e.target.checked);
            if (e.target.checked) {
              setTimeType(TimeType.SUMMIT);
            } else {
              setTimeType(TimeType.NONE);
            }
          }}
        >
          分时统计
        </Checkbox>
        {showTimePeriod && (
          <Radio.Group buttonStyle="solid" value={timeType} onChange={e => setTimeType(e.target.value)}>
            <Radio.Button value={TimeType.SUMMIT}>尖</Radio.Button>
            <Radio.Button value={TimeType.PEAK}>峰</Radio.Button>
            <Radio.Button value={TimeType.PLAIN}>平</Radio.Button>
            <Radio.Button value={TimeType.VALLEY}>谷</Radio.Button>
          </Radio.Group>
        )}
        <Space size={5}>
          <InfoCircleOutlined
            style={{
              color: 'var(--warning-color)',
            }}
          ></InfoCircleOutlined>
          <div
            style={{
              color: 'var(--mx-text-desc-color)',
            }}
          >
            图表内数据不含当天
          </div>
        </Space>
        <Button
          type="primary"
          wrapStyle={{ marginLeft: 'auto' }}
          onClick={() => {
            navigate(`/pv/income/report?pvId=${pvId}`);
          }}
        >
          查看列表
        </Button>
      </div>

      <ElecStatistics data={data} timeType={timeType}></ElecStatistics>

      <ElecChart
        data={data}
        timeType={timeType}
        granularity={granularity}
        powerTab={powerTab}
        setPowerTab={setPowerTab}
      ></ElecChart>
    </div>
  );
};

export default BottomStatistics;
