import { apiV2PvOutlinePvSummaryDataPost } from '@maxtropy/dmes-apis-v2';
import { useRequest } from 'ahooks';

import React, { FC, useContext, useEffect, useState } from 'react';
import { PollingInterval, PvInfoContext } from '..';
import { keepTwoNull } from '../utils';
import styles from './index.module.scss';
import { getGisConfig } from '@/api/bsaMap';
import AMapLoader from '@amap/amap-jsapi-loader';

export interface ILeftTopInfo {
  setAlarmDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCurveDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  alarmCounts?: number;
  addressCode?: string;
}

const LeftTopInfo: FC<ILeftTopInfo> = props => {
  const { setAlarmDrawerOpen, setCurveDrawerOpen, alarmCounts, addressCode } = props;
  const { currentPvId } = useContext(PvInfoContext);
  const [hover, setHover] = useState<boolean>(false);
  const [alarmHover, setAlarmHover] = useState<boolean>(false);

  const [weather, setWeather] = useState<{
    temperature: string;
    weather: string;
    windDirection: string;
    windPower?: number;
  }>();
  const { data } = useRequest(() => apiV2PvOutlinePvSummaryDataPost({ id: currentPvId }), {
    refreshDeps: [currentPvId],
    ready: !!currentPvId,
    pollingInterval: PollingInterval,
    pollingWhenHidden: false,
  });

  useEffect(() => {
    if (!addressCode) return;
    getGisConfig().then((res: any) => {
      window._AMapSecurityConfig = {
        securityJsCode: res.securityJsCode, // 开发环境使用
      };
      AMapLoader.load({
        key: res.key,
        version: '2.0',
        plugins: ['AMap.MarkerClusterer', 'AMap.Size', 'AMap.Pixel', 'AMap.Weather'],
      })
        .then(AMap => {
          //加载天气查询插件
          AMap.plugin('AMap.Weather', function () {
            //创建天气查询实例
            var weather = new AMap.Weather();
            //执行实时天气信息查询
            weather.getLive(addressCode, function (err: any, data: any) {
              setWeather({
                weather: data.weather,
                temperature: data.temperature,
                windDirection: data.windDirection,
                windPower: data.windPower,
              });
            });
          });
        })
        .catch(_ => {});
    });

    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressCode]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.top_box}>
        <div className={styles.weather_box}>
          <span className={styles.weather}>{weather?.temperature ?? '--'}℃</span>
          <span className={styles.detail}>
            {weather?.weather ?? ''} {weather?.windDirection ?? ''}
            {weather?.windPower ? weather?.windPower + '级' : ''}
          </span>
        </div>
        {data?.isCumulativeTiltTotalRadiation ? (
          <div className={styles.radiation}>
            <span className={styles.label}>倾斜总辐射累计</span>
            <span className={styles.value}>{keepTwoNull(data?.cumulativeTiltTotalRadiation)}kWh/㎡</span>
          </div>
        ) : (
          <div className={styles.radiation} />
        )}
        {data?.isCumulativeTiltRadiation ? (
          <div className={styles.radiation}>
            <span className={styles.label}>倾斜总辐射</span>
            <span className={styles.value}>{keepTwoNull(data?.cumulativeTiltRadiation)}W/㎡</span>
          </div>
        ) : (
          <div className={styles.radiation} />
        )}
      </div>
      <div className={styles.h_divider} />
      <div className={styles.bottom_box}>
        <div
          className={styles.alarm_box}
          onMouseEnter={() => setAlarmHover(true)}
          onMouseLeave={() => setAlarmHover(false)}
          onClick={() => {
            setCurveDrawerOpen(false);
            setAlarmDrawerOpen(true);
          }}
        >
          <div
            className={styles.alarm_number}
            style={{ backgroundColor: alarmHover ? 'rgba(230,66,66,0.6)' : 'rgba(230,66,66,0.4)' }}
          >
            {alarmCounts ?? 0}
          </div>
          <span className={styles.label}>当前报警设备</span>
        </div>
        <div className={styles.v_divider} />
        <div
          className={styles.power_box}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={() => {
            setAlarmDrawerOpen(false);
            setCurveDrawerOpen(true);
          }}
        >
          <div className={styles.title}>
            <span className={styles.label}>总功率（kW）</span>
            <span className={styles.label}>发电量（kWh）</span>
          </div>
          <div className={styles.line_box} style={{ marginBottom: 4 }}>
            <span className={styles.label}>并网点</span>
            <span
              className={styles.total_value}
              style={{ backgroundColor: hover ? 'rgba(255,203,71,0.3)' : 'rgba(255,203,71,0.1)' }}
            >
              {keepTwoNull(data?.totalPowerOfGrid)}
            </span>
            <span
              className={styles.charge_value}
              style={{ backgroundColor: hover ? 'rgba(22,221,142,0.3)' : 'rgba(22,221,142,0.1)' }}
            >
              {keepTwoNull(data?.productionOfGrid)}
            </span>
          </div>
          <div className={styles.line_box}>
            <span className={styles.label}>逆变器</span>
            <span
              className={styles.total_value}
              style={{ backgroundColor: hover ? 'rgba(255,203,71,0.3)' : 'rgba(255,203,71,0.1)' }}
            >
              {keepTwoNull(data?.totalPowerOfInverter)}
            </span>
            <span
              className={styles.charge_value}
              style={{ backgroundColor: hover ? 'rgba(22,221,142,0.3)' : 'rgba(22,221,142,0.1)' }}
            >
              {keepTwoNull(data?.productionOfInverter)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftTopInfo;
