import { Key, useMemo, useRef, useState } from 'react';
import { Tabs } from '@maxtropy/components';
import styles from './index.module.scss';
import activeIcon from '../../icons/icon-energyStorage-selected@2x.png';
import unActiveIcon from '../../icons/icon-energyStorage@2x.png';
import { V2NeoBsaGetBmsTabPostResponse } from '@maxtropy/dmes-apis-v2';

interface TabTitleProps {
  active?: boolean;
  title?: string;
  iconType?: 'battery' | 'temper';
}
interface Iprops {
  activeKey?: string;
  onChange?: (key: string) => void;
  options?: V2NeoBsaGetBmsTabPostResponse['list'];
}
const TabTitle = ({ active, title, iconType }: TabTitleProps) => {
  return (
    <div className={styles.tab_title_box}>
      <div className={styles.tab_title_img}>
        <img src={active ? activeIcon : unActiveIcon} alt="" />
      </div>
      <span className={styles.tab_title_txt}>{title ?? '--'}</span>
    </div>
  );
};
const DeviceTabs = ({ activeKey, onChange, options }: Iprops) => {
  const outerRef = useRef<HTMLDivElement>();

  const tabList = useMemo(() => {
    return options?.map(item => ({
      key: String(item.objectId),
      label: <TabTitle active={String(item.objectId) === String(activeKey)} title={item.name} />,
    }));
  }, [options, activeKey]);
  return (
    <div
      ref={dom => {
        if (dom) {
          outerRef.current = dom;
        }
      }}
    >
      <div className={styles.bottomStyles}>
        <Tabs
          // getPopupContainer={() => outerRef.current as HTMLDivElement}
          activeKey={activeKey as string}
          onChange={v => {
            onChange?.(v);
          }}
          items={tabList}
        />
      </div>
    </div>
  );
};

export default DeviceTabs;
