import { PermissionsType } from '@/common/permissionsConst';
import BsaHourlyElecReport from './index';

const routes = [
  {
    path: '/energyStorage/hourlyElecConsumption/report',
    permission: PermissionsType.P_BSATOUINCOMEREPORT,
    element: <BsaHourlyElecReport />,
  },
];
export default routes;
