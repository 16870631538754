import styles from './index.module.scss';
import { FC } from 'react';
import { useRequest } from 'ahooks';
import { apiV2PvOutlinePerformanceStatisticsPost } from '@maxtropy/dmes-apis-v2';
import { customFixed } from '@/utils/utils';
import TargetCompletion from './TargetCompletion';
import ElecChart from '@/pages/PvRunningOverview/TodayData/ElecChart';
import { Divider, Progress } from 'antd';

interface Props {
  pvId: number | undefined;
}

const TodayData: FC<Props> = props => {
  const { pvId } = props;

  const { data } = useRequest(
    () => {
      return apiV2PvOutlinePerformanceStatisticsPost({ id: pvId });
    },
    {
      ready: !!pvId,
      refreshDeps: [pvId],
    }
  );

  return (
    <div className={styles.wrap}>
      <div className={styles.topStatistics}>
        <div className={styles.titleWrap}>
          <div className={styles.title}>今日数据</div>
        </div>
        <div className={styles.detailRow}>
          <div className={styles.detailItem}>
            <div className={styles.value}>{customFixed(data?.inverterPower)}kWh</div>
            <div className={styles.label}>逆变器发电量</div>
          </div>
          <Divider type="vertical" className={styles.divider} />
          <div className={styles.detailItem}>
            <div className={styles.value}>{customFixed(data?.gridConnectedPower)}kWh</div>
            <div className={styles.label}>并网点发电量</div>
          </div>
          <Divider type="vertical" className={styles.divider} />
          <div className={`${styles.detailItem} ${styles.more}`}>
            <div className={styles.row}>
              <div className={styles.rowLabel}>消纳电量</div>
              <div className={styles.value}>{customFixed(data?.chargeConsumption)}kWh</div>
            </div>
            <div className={styles.row}>
              <div className={styles.rowLabel}>消纳率</div>
              <div className={styles.progressContainer}>
                <Progress
                  strokeWidth={4}
                  strokeColor={{ from: '#51A1FF', to: '#51DBFF' }}
                  trailColor="rgba(74,144,226,0.2)"
                  className={styles.progress}
                  showInfo={false}
                  percent={Number(customFixed(data?.consumptionRate ?? 0, 2, true))}
                />
                <div style={{ color: 'var(--mx-text-base-color)' }}>
                  {Number(customFixed(data?.consumptionRate ?? 0, 2, true))}%
                </div>
              </div>
            </div>
          </div>
          <Divider type="vertical" className={styles.divider} />
          <div className={styles.detailItem}>
            <div className={styles.value}>{customFixed(data?.chargeOnGrid, 2, true)}kWh</div>
            <div className={styles.label}>上网电量</div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          gap: 16,
        }}
      >
        <div>
          <TargetCompletion
            title="年度绩效目标"
            value={data?.planPowerOfYear}
            percent={data?.accomplishmentRatioOfYear}
            style={{
              marginBottom: 16,
            }}
          ></TargetCompletion>
          <TargetCompletion
            title="月度绩效目标"
            value={data?.planPowerOfMonth}
            percent={data?.accomplishmentRatioOfMonth}
          ></TargetCompletion>
        </div>
        <div style={{ flex: 1 }}>
          <ElecChart data={data?.pvStationStatisticsValueList} />
        </div>
      </div>
    </div>
  );
};

export default TodayData;
