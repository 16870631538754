import React, { useEffect, useState } from 'react';
import { isNil } from 'lodash-es';
import classnames from 'classnames/bind';
import type { PanelDataType } from '@/api/energyGateway';
import styles from './index.module.scss';
import { Tooltip } from '@maxtropy/components';
interface Props {
  panelData: PanelDataType;
}

const cx = classnames.bind(styles);

const GateWayPanel: React.FC<Props> = ({ panelData }) => {
  const {
    bottom,
    left,
    borderColorValue,
    stationNum,
    devicePositonLeft,
    deviceNum,
    totalCapacity,
    totalCapacityPositonLeft,
    stationName,
    stationNameWrap,
    deviceName,
    capacityUnit,
    locationPicUrl,
    type,
    listData,
    deviceNameWrap,
    capacityUnitWrap,
  } = panelData;

  const frameStyle = {
    background: `
      linear-gradient(${borderColorValue}, ${borderColorValue}) left top,
      linear-gradient(${borderColorValue}, ${borderColorValue}) left top,
      linear-gradient(${borderColorValue}, ${borderColorValue}) right top,
      linear-gradient(${borderColorValue}, ${borderColorValue}) right top,
      linear-gradient(${borderColorValue}, ${borderColorValue}) left bottom,
      linear-gradient(${borderColorValue}, ${borderColorValue}) left bottom,
      linear-gradient(${borderColorValue}, ${borderColorValue}) right bottom,
      linear-gradient(${borderColorValue}, ${borderColorValue}) right bottom
    `,
    backgroundSize: '2px 12px, 12px 2px',
    backgroundRepeat: 'no-repeat',
  };

  const jumpToNewPage = (url: string) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'https://' + url;
    }

    window.open(url, '_blank');
  };

  const [currentRatio, setCurrentRatio] = useState(window.devicePixelRatio);

  useEffect(() => {
    const handleResize = () => {
      setCurrentRatio(window.devicePixelRatio);
    };

    window.addEventListener('resize', handleResize, { passive: true });

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {!isNil(stationNum) && !isNil(deviceNum) && !isNil(totalCapacity) && (
        <div
          className={cx('wrapper', type === 'leanManage' && currentRatio > 1 ? 'wrapper_box' : '')}
          style={{ bottom: `${bottom}%`, left: `${left}%` }}
        >
          <div className={styles.top}>
            <div className={styles.frame} style={frameStyle}>
              <div className={cx('frame_box', listData && listData.length > 0 ? 'hover_box' : '')}>
                <ul className={styles.block_shadow}></ul>
                {/* 内容区域 */}
                <div className={styles.content}>
                  <Tooltip title={<span>{stationNum}</span>} placement="topLeft">
                    <div className={styles.content_item}>
                      <div className={styles.stationNum}>{stationNum ?? '--'}</div>
                      <div className={styles.label_unit}>
                        <p className={styles.stationName}>{stationName}</p>
                        {stationNameWrap && (
                          <>
                            <div className={styles.stationNameWrap}>{stationNameWrap}</div>
                          </>
                        )}
                      </div>
                    </div>
                  </Tooltip>
                  <Tooltip title={<span>{deviceNum}</span>} placement="topLeft">
                    <div className={styles.content_item}>
                      <div className={styles.deviceNum} style={{ left: `${devicePositonLeft}px` }}>
                        {deviceNum ?? '--'}
                      </div>
                      <div className={styles.label_unit}>
                        <p className={styles.deviceName} style={{ left: `${devicePositonLeft}px` }}>
                          {deviceName}
                        </p>
                        {deviceNameWrap && <div className={styles.deviceNameWrap}>{deviceNameWrap}</div>}
                      </div>
                    </div>
                  </Tooltip>
                  <Tooltip title={<span>{totalCapacity}</span>} placement="topLeft">
                    <div className={styles.content_item}>
                      <div className={styles.totalCapacity} style={{ left: `${totalCapacityPositonLeft}px` }}>
                        {totalCapacity ?? '--'}
                      </div>
                      <div className={styles.label_unit}>
                        <p className={styles.capacityUnit} style={{ left: `${totalCapacityPositonLeft}px` }}>
                          {capacityUnit}
                        </p>
                        {capacityUnitWrap && <div className={styles.capacityUnitWrap}>{capacityUnitWrap}</div>}
                      </div>
                    </div>
                  </Tooltip>
                </div>

                {/* 按钮区域 */}
                <ul className={styles.buttonList}>
                  {listData &&
                    listData.length > 0 &&
                    listData.map(item => {
                      return (
                        <li key={item.id} onClick={() => jumpToNewPage(item.url as string)}>
                          {item.buttonName}
                        </li>
                      );
                    })}
                </ul>

                {/* 头部描边 */}
                <div className={styles.boardTop}></div>

                {/* 向下的箭头 有按钮才显示*/}
                {listData && listData.length > 0 && <div className={styles.arrow}></div>}

                {/* 底部三角 */}
                <div className={styles.triangle}></div>

                {/* 四周白点 */}
                <div className={styles.pointTL}></div>
                <div className={styles.pointTR}></div>
                <div className={styles.pointBL}></div>
                <div className={styles.pointBR}></div>
              </div>
            </div>
          </div>
          <div className={styles.bottom} style={{ backgroundImage: `url(${locationPicUrl})` }}></div>
        </div>
      )}
    </>
  );
};

export default GateWayPanel;
