import React, { FC, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  apiV2BsaSecondEditionInfoBsaSecondEditionPost,
  V2BsaSecondEditionInfoBsaSecondEditionPostResponse,
} from '@maxtropy/dmes-apis-v2';
import { Breadcrumb, useBreadcrumbRoutes } from '@maxtropy/components';
import BorderWrapper from '@/components/BorderWrapper';
import { BatteryType, BsaStatus, Step } from './const';
import GlobalConfig from './GlobalConfig';
import DataPointConfig from './DataPointConfig';
import styles from './index.module.scss';

interface Props {}

const BsaDataCollectionConfigNew: FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const routesContext = useBreadcrumbRoutes();
  const [searchParams, setSearchParams] = useSearchParams();

  const [bsaData, setBsaData] = useState<V2BsaSecondEditionInfoBsaSecondEditionPostResponse>();

  const currentStep: Step | undefined = searchParams?.get('step') ? Number(searchParams?.get('step')) : undefined;

  useEffect(() => {
    apiV2BsaSecondEditionInfoBsaSecondEditionPost({ id: +id }).then(res => {
      setBsaData(res);
      // 路由没有step，从其他地方跳转过来的，跳转到所在step
      if (searchParams.get('step')) return;
      if (res.actualStatus === BsaStatus.COMPLETE_ARRAY_STRUCT_CONFIG) setSearchParams({ step: String(Step.GLOBAL) });
      else if (res.actualStatus === BsaStatus.COMPLETE_GLOBAL_DATA_COLLECTION_CONFIG)
        setSearchParams({ step: String(Step.RACK) });
      else if (res.actualStatus === BsaStatus.COMPLETE_RACK_DATA_POINT_CONFIG)
        setSearchParams({ step: String(Step.PACK) });
      else if (res.actualStatus === BsaStatus.COMPLETE_PACK_DATA_POINT_CONFIG)
        setSearchParams({ step: String(Step.CELL) });
      else {
        setSearchParams({ step: String(Step.GLOBAL) });
      }
    });
  }, [searchParams.get('step')]);

  const ContentComponent = useMemo(() => {
    const map: Record<Step, JSX.Element> = {
      [Step.GLOBAL]: <GlobalConfig bsaData={bsaData} />,
      [Step.RACK]: <DataPointConfig bsaData={bsaData} batteryType={BatteryType.RACK} key="rack" />,
      [Step.PACK]: <DataPointConfig bsaData={bsaData} batteryType={BatteryType.PACK} key="pack" />,
      [Step.CELL]: <DataPointConfig bsaData={bsaData} batteryType={BatteryType.CELL} key="cell" />,
    };
    if (currentStep === undefined) return <></>;
    else return map[currentStep];
  }, [currentStep, bsaData]);

  return (
    <div className={styles.page_box}>
      <div className="page">
        <div className="page__header">
          <Breadcrumb routes={[...(routesContext?.routes ?? [])]} />
        </div>
        <BorderWrapper>
          <div className="page__content" style={{ paddingLeft: 20, paddingRight: 20 }}>
            {ContentComponent}
          </div>
        </BorderWrapper>
      </div>
    </div>
  );
};

export default BsaDataCollectionConfigNew;
