import { createContext } from 'react';

export enum Resolution {
  HOUR = 0,
  DAY,
}

export const ResolutionLabel = {
  [Resolution.HOUR]: '按日',
  [Resolution.DAY]: '按小时',
};

export enum ExcludeType {
  STRING = 0, // 组串
  COMPONENT, // 组件
}

export interface PvAreaContextProps {
  areaIdList?: number[];
  setAreaIdList?: (areaIdList: number[]) => void;
  pvStationName?: string;
  setPvStationName?: (pvStationName: string) => void;
  existComponents?: boolean;
  setExistComponents?: (existComponents: boolean) => void;
}

export const PvAreaContext = createContext<PvAreaContextProps>({});
