import {
  apiV2DeviceDataPropertyRecentPost,
  apiV2PvOutlineDataPropertyRecentPost,
  V2DeviceDataPropertyRecentPostRequest,
} from '@maxtropy/dmes-apis-v2';
import { useRequest } from 'ahooks';
import { Key, useEffect } from 'react';
import { dataPropertyMap, DeviceTypeEnum } from '../const';
export interface DataValuesIprops {
  isRequest: boolean;
  deviceType?: DeviceTypeEnum;
  deviceId?: Key;
}
export const useGetDataValues = ({ deviceId, deviceType, isRequest }: DataValuesIprops) => {
  const { data, loading, cancel, runAsync, mutate } = useRequest(
    params => {
      return apiV2PvOutlineDataPropertyRecentPost(params).then(res => res.list);
    },
    { manual: true, debounceWait: 300 }
  );

  useEffect(() => {
    if (typeof deviceType !== 'number' || !deviceId) return;
    if (isRequest) {
      mutate([]);
      let dataPropertyIds = dataPropertyMap[deviceType].map(item => item.propertyId);
      runAsync({
        deviceId,
        dataPropertyIds,
      });
    } else {
      cancel();
    }
  }, [deviceId, deviceType, isRequest]);

  return { dataValues: data, loading };
};
