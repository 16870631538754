import { Button, DatePicker, Form, InputNumber, Select, ShowInput } from '@maxtropy/components';
import { Col, FormInstance, Row } from 'antd';
import styles from './index.module.scss';
import dayjs, { Dayjs } from 'dayjs';
import { InfoCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ElectricityPriceType, GridConnectionStatus, GridConnectionStatusLabel } from './index';
import { FC, useEffect } from 'react';
import { Rule } from 'antd/es/form';

const formLayout = {
  labelCol: { flex: '140px' },
  wrapperCol: { flex: '340px' },
};

const findBeginLastDate = (leftDate: number, items: any, index: number) => {
  let interval = Infinity;
  let lastDate = null;
  for (let i = 0; i < items.length; i++) {
    if (index === i) continue;
    if (!items[i]) continue;

    if (items[i].beginDate && items[i].beginDate - leftDate < interval && items[i].beginDate - leftDate > 0) {
      lastDate = items[i].beginDate;
      interval = items[i].beginDate - leftDate;
    } else if (items[i].endDate && items[i].endDate - leftDate < interval && items[i].endDate - leftDate > 0) {
      lastDate = items[i].beginDate;
      interval = items[i].endDate - leftDate;
    }
  }
  return lastDate;
};

const findEndLastDate = (rightDate: number, items: any, index: number) => {
  let interval = Infinity;
  let lastDate = null;
  for (let i = 0; i < items.length; i++) {
    if (i === index) continue;
    if (!items[i]) continue;

    if (items[i].endDate && rightDate - items[i].endDate > 0 && rightDate - items[i].endDate < interval) {
      lastDate = items[i].endDate;
      interval = rightDate - items[i].endDate;
    } else if (items[i].beginDate && rightDate - items[i].beginDate > 0 && rightDate - items[i].beginDate < interval) {
      lastDate = items[i].beginDate;
      interval = rightDate - items[i].beginDate;
    }
  }
  return lastDate;
};

const checkDate = (items: any, current: Dayjs, index: number, type: ElectricityPriceType, isLeft?: boolean) => {
  if (!current) return true;

  if (type === ElectricityPriceType.Subsidy && current < dayjs().endOf('day')) return true;

  let isDisable = false;

  for (let i = 0; i < items.length; i++) {
    let item = items[i];
    // 不是当前选中的闭区间的先禁用
    if (item?.beginDate && item?.endDate && !isDisable && index !== i) {
      isDisable = current >= dayjs(item.beginDate).startOf('day') && current <= dayjs(item.endDate).endOf('day');
    }
    // 单侧当天的禁用
    else if (item?.beginDate && !isDisable) {
      isDisable = current.format('YYYY-MM-DD') === dayjs(item.beginDate).format('YYYY-MM-DD');
    } else if (item?.endDate && !isDisable) {
      isDisable = current.format('YYYY-MM-DD') === dayjs(item.endDate).format('YYYY-MM-DD');
    }
  }
  // 只有开始时间
  // 该开始日期之前不可用，以及距该开始日期最近的下一个开始日期或结束日期之后不可用
  if (items[index]?.beginDate && (!items[index]?.endDate || !isLeft) && !isDisable) {
    let lastDate = findBeginLastDate(items[index].beginDate, items, index);
    isDisable =
      current <= dayjs(items[index].beginDate).endOf('day') || (lastDate && current >= dayjs(lastDate).startOf('day'));
  }
  // 只有结束日期
  // 该结束日期之后不可用，以及距离该结束日期最近的前一个结束日期或开始日期之前不可用
  if (items[index]?.endDate && (!items[index]?.beginDate || isLeft) && !isDisable) {
    let lastDate = findEndLastDate(items[index].endDate, items, index);
    isDisable =
      current >= dayjs(items[index].endDate).startOf('day') || (lastDate && current <= dayjs(lastDate).endOf('day'));
  }

  return isDisable;
};

const getNumberRule = (min: number, max: number, precision: number, message: string): Rule => {
  return {
    message,
    validator: (rule, value) => {
      let flag = true;
      if (value && value > max) {
        flag = false;
      } else if (value && value < min) {
        flag = false;
      } else if (value && value.toString().includes('.') && value.toString().split('.')[1].length > precision) {
        flag = false;
      }
      return flag ? Promise.resolve() : Promise.reject('输入范围为0-9999，支持小数点后2位。');
    },
  };
};

interface Props {
  form: FormInstance;
}

const Edit: FC<Props> = props => {
  const { form } = props;
  const gridConnectionStatus = Form.useWatch('gridConnectionStatus', form);
  const feedInElectricityPrices = Form.useWatch('feedInElectricityPrices', form);
  const subsidyElectricityPrices = Form.useWatch('subsidyElectricityPrices', form);

  // 校验上网电价一行是否有内容，有则全部必填
  const getFeedInRowRequired = (index: number) => {
    if (!feedInElectricityPrices) return false; // 防止数据隐藏后报错
    const row = feedInElectricityPrices[index];
    return row?.electricityPrice || row?.beginDate || row?.endDate;
  };

  // 校验补贴电价一行是否有内容，有则全部必填
  const getSubsidyRowRequired = (index: number) => {
    if (!subsidyElectricityPrices) return false; // 防止数据隐藏后报错
    const row = subsidyElectricityPrices[index];
    return row?.electricityPrice || row?.beginDate || row?.endDate;
  };

  // 上网电价时间范围禁用
  const getFeedInRowDateDisable = (currentDate: Dayjs, index: number, isBegin: boolean) => {
    if (!feedInElectricityPrices) return false; // 防止数据隐藏后报错
    return checkDate(feedInElectricityPrices, currentDate, index, ElectricityPriceType.FeedIn, isBegin);
  };

  // 补贴电价时间范围禁用
  const getSubsidyRowDateDisable = (currentDate: Dayjs, index: number, isBegin: boolean) => {
    if (!subsidyElectricityPrices) return false; // 防止数据隐藏后报错
    return checkDate(subsidyElectricityPrices, currentDate, index, ElectricityPriceType.Subsidy, isBegin);
  };

  // 手动校验上网电价一行
  const validateFeedInRow = (index: number) => {
    form.validateFields([['feedInElectricityPrices', index, 'electricityPrice']]);
    form.validateFields([['feedInElectricityPrices', index, 'beginDate']]);
    form.validateFields([['feedInElectricityPrices', index, 'endDate']]);
  };

  // 手动校验补贴电价一行
  const validateSubsidyRow = (index: number) => {
    form.validateFields([['subsidyElectricityPrices', index, 'electricityPrice']]);
    form.validateFields([['subsidyElectricityPrices', index, 'beginDate']]);
    form.validateFields([['subsidyElectricityPrices', index, 'endDate']]);
  };

  return (
    <>
      <Form.Item
        label="并网模式"
        name="gridConnectionStatus"
        dependencies={['gridConnectionStatusLabel']}
        {...formLayout}
        rules={[
          {
            required: true,
            message: '请选择并网模式',
          },
        ]}
      >
        <Select
          options={Object.keys(GridConnectionStatusLabel).map(key => ({
            label: GridConnectionStatusLabel[Number(key) as GridConnectionStatus],
            value: Number(key),
          }))}
        ></Select>
      </Form.Item>

      <Row gutter={16}>
        <Col span={7}>
          <Form.Item label="度电成本：" className={styles.mb6}></Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="投建成本" className={styles.mb6}></Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="已回收成本" className={styles.mb6}></Form.Item>
        </Col>
      </Row>

      <Row gutter={16} className={styles.mb24}>
        <Col span={7}>
          <Form.Item
            name="costElectricityPrice"
            rules={[getNumberRule(0, 9999, 8, '输入范围为0-9999，支持小数点后8位。')]}
            className={styles.noStyleItem}
          >
            <InputNumber addonAfter="元/kWh" style={{ width: '100%' }}></InputNumber>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            name="investmentCost"
            rules={[getNumberRule(0, 9999, 8, '输入范围为0-9999，支持小数点后8位。')]}
            className={styles.noStyleItem}
          >
            <InputNumber addonAfter="万元" style={{ width: '100%' }}></InputNumber>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            name="recoveredCost"
            rules={[getNumberRule(0, 9999, 8, '输入范围为0-9999，支持小数点后8位。')]}
            className={styles.noStyleItem}
          >
            <InputNumber addonAfter="万元" style={{ width: '100%' }}></InputNumber>
          </Form.Item>
        </Col>
      </Row>

      {gridConnectionStatus !== GridConnectionStatus.ALL_DROP && (
        <div className={styles.mb24}>
          <Form.List name="feedInElectricityPrices" layout="vertical">
            {(fields, { add, remove }, { errors }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <Row key={index} gutter={16}>
                      <Col span={7}>
                        <Form.Item label={index === 0 ? '上网电价：' : ''} key={key}>
                          <Form.Item
                            {...restField}
                            label="上网电价"
                            name={[name, 'electricityPrice']}
                            noStyle
                            rules={[
                              {
                                required: getFeedInRowRequired(index),
                              },
                              getNumberRule(0, 9999, 8, '输入范围为0-9999，支持小数点后8位。'),
                            ]}
                          >
                            <InputNumber
                              addonAfter="元/kWh"
                              style={{ width: '100%' }}
                              onChange={() => validateFeedInRow(name)}
                            ></InputNumber>
                          </Form.Item>
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item label={index === 0 ? '起始日期：' : ''}>
                          <Form.Item
                            {...restField}
                            label="起始日期"
                            name={[name, 'beginDate']}
                            noStyle
                            rules={[
                              {
                                required: getFeedInRowRequired(index),
                              },
                            ]}
                          >
                            <DatePicker
                              style={{ width: '100%' }}
                              placeholder="请输入"
                              disabledDate={currentDate => getFeedInRowDateDisable(currentDate, index, true)}
                              onChange={() => validateFeedInRow(name)}
                            ></DatePicker>
                          </Form.Item>
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item label={index === 0 ? '结束日期：' : ''}>
                          <Form.Item
                            {...restField}
                            label="结束日期"
                            name={[name, 'endDate']}
                            noStyle
                            rules={[
                              {
                                required: getFeedInRowRequired(index),
                              },
                            ]}
                          >
                            <DatePicker
                              style={{ width: '100%' }}
                              placeholder="请输入"
                              disabledDate={currentDate => getFeedInRowDateDisable(currentDate, index, false)}
                              onChange={() => validateFeedInRow(name)}
                            ></DatePicker>
                          </Form.Item>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Col>
                    </Row>
                  ))}

                  <Row>
                    <Col span={21}>
                      <Button
                        type="dashed"
                        ghost
                        icon={<PlusOutlined />}
                        style={{ width: '100%' }}
                        wrapStyle={{ width: '100%' }}
                        onClick={() => add()}
                      >
                        新增上网电价
                      </Button>
                    </Col>
                  </Row>
                </>
              );
            }}
          </Form.List>
        </div>
      )}

      <div className={styles.mb24}>
        <Form.List name="subsidyElectricityPrices" layout="vertical">
          {(fields, { add, remove }, { errors }) => {
            return (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Row key={index} gutter={16}>
                    <Col span={7}>
                      <Form.Item label={index === 0 ? '补贴电价：' : ''}>
                        <Form.Item
                          {...restField}
                          label="补贴电价"
                          name={[name, 'electricityPrice']}
                          noStyle
                          rules={[
                            {
                              required: getSubsidyRowRequired(index),
                            },
                            getNumberRule(0, 9999, 8, '输入范围为0-9999，支持小数点后8位。'),
                          ]}
                        >
                          <InputNumber
                            addonAfter="元/kWh"
                            style={{ width: '100%' }}
                            onChange={() => validateSubsidyRow(name)}
                          ></InputNumber>
                        </Form.Item>
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item label={index === 0 ? '起始日期：' : ''}>
                        <Form.Item
                          {...restField}
                          label="起始日期"
                          name={[name, 'beginDate']}
                          noStyle
                          rules={[
                            {
                              required: getSubsidyRowRequired(index),
                            },
                          ]}
                        >
                          <DatePicker
                            style={{ width: '100%' }}
                            placeholder="请输入"
                            disabledDate={currentDate => getSubsidyRowDateDisable(currentDate, index, true)}
                            onChange={() => validateSubsidyRow(name)}
                          ></DatePicker>
                        </Form.Item>
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item label={index === 0 ? '结束日期：' : ''}>
                        <Form.Item
                          {...restField}
                          label="结束日期"
                          name={[name, 'endDate']}
                          noStyle
                          rules={[
                            {
                              required: getSubsidyRowRequired(index),
                            },
                          ]}
                        >
                          <DatePicker
                            style={{ width: '100%' }}
                            placeholder="请输入"
                            disabledDate={currentDate => getSubsidyRowDateDisable(currentDate, index, false)}
                            onChange={() => validateSubsidyRow(name)}
                          ></DatePicker>
                        </Form.Item>
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Col>
                  </Row>
                ))}

                <Row>
                  <Col span={21}>
                    <Button
                      type="dashed"
                      ghost
                      icon={<PlusOutlined />}
                      style={{ width: '100%' }}
                      wrapStyle={{ width: '100%' }}
                      onClick={() => add()}
                    >
                      新增补贴电价
                    </Button>
                  </Col>
                </Row>
              </>
            );
          }}
        </Form.List>
      </div>

      <Form.Item
        label="补贴折扣"
        name="subsidyDiscount"
        {...formLayout}
        rules={[{ required: true }, getNumberRule(0, 1, 8, '输入范围为0.00-1.00，支持小数点后八位。')]}
        extra={
          <div className={styles.desc}>
            <InfoCircleOutlined className={styles.infoIcon} />
            投资方给企业客户的补贴折扣,
            如果是自建光伏，无需与其他企业结算，此处输入0，如果企业客户享受1折分成，这里填写0.1
          </div>
        }
      >
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>

      {gridConnectionStatus !== GridConnectionStatus.ALL_CONNECTED && (
        <Form.Item
          label="用电折扣"
          name="electricityDiscount"
          {...formLayout}
          rules={[{ required: true }, getNumberRule(0, 1, 8, '输入范围为0.00-1.00，支持小数点后八位。')]}
          extra={
            <div className={styles.desc}>
              <InfoCircleOutlined className={styles.infoIcon} />
              投资方给企业客户的折扣,
              如果是自建光伏，无需与其他企业结算，此处输入0，如果企业客户享受8折电费，这里填写0.2
            </div>
          }
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
      )}

      {gridConnectionStatus !== GridConnectionStatus.ALL_DROP && (
        <Form.Item
          label="上网分成"
          name="internetRevenueSharing"
          {...formLayout}
          rules={[{ required: true }, getNumberRule(0, 1, 8, '输入范围为0.00-1.00，支持小数点后八位。')]}
          extra={
            <div className={styles.desc}>
              <InfoCircleOutlined className={styles.infoIcon} />
              投资方给企业客户上网分成比例,
              如果是自建光伏，无需与其他企业结算，此处输入0，如果企业客户享受1折分成，这里填写0.1
            </div>
          }
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
      )}
    </>
  );
};

export default Edit;
