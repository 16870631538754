import { PermissionsType } from '@/common/permissionsConst';
import BsaListNew from '@/pages/BsaListNew';
import SupportFacility from './SupportFacility';

const routes = [
  {
    path: '/energy-storage/basic/bsaNew',
    permission: PermissionsType.P_BSA_MANAGE_NEW,
    element: <BsaListNew />,
  },
  {
    path: '/energy-storage/basic/bsaNew/facilityDetail/:bsaId',
    permission: PermissionsType.P_BSA_MANAGE_NEW,
    element: <SupportFacility isDetail />,
  },
  {
    path: '/energy-storage/basic/bsaNew/facilityEdit/:bsaId',
    permission: PermissionsType.P_BSA_MANAGE_NEW,
    element: <SupportFacility />,
  },
];
export default routes;
