import React from 'react';
import styles from './index.module.scss';
import software_icon from '../../../../imgs/icon-softwareTesting.png';
import inverer_icon from '../../../../imgs/icon-invererTesting.png';
import PV_icon from '../../../../imgs/icon-PVTesting.png';
import line from '../../../../imgs/small_line.png';
import classnames from 'classnames/bind';
import { useRequest } from 'ahooks';
import { apiV2TestToolStatisticsPost } from '@maxtropy/dmes-apis-v2';
import { keepTwoOrLine } from '@/pages/PvDemonstrationBase/utils';
import { useCurrent } from '@maxtropy/components';

const cx = classnames.bind(styles);

const TestDesc = () => {
  const mcid = useCurrent()?.tenant?.mcid;

  const { data } = useRequest(
    () => {
      return apiV2TestToolStatisticsPost({
        tenantMcid: mcid,
      });
    },
    {
      ready: !!mcid,
    }
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.itemCard}>
        <div className={styles.top_title}>
          <img className={styles.icon} src={software_icon} alt="" />
          <div className={styles.title_label}>软件测试任务</div>
          <img className={styles.img_line} src={line} alt="" />
        </div>
        <div className={styles.bottom_content}>
          <div className={styles.item}>
            <div className={styles.item_title}>总数</div>
            <div className={cx('item_value', 'total_number')}>
              {keepTwoOrLine(data?.list?.filter(i => i.type === 1)?.[0]?.total)}个
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.item_title}>已完成</div>
            <div className={cx('item_value', 'finished_number')}>
              {keepTwoOrLine(data?.list?.filter(i => i.type === 1)?.[0]?.finished)}个
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.item_title}>进行中</div>
            <div className={cx('item_value', 'pedding_number')}>
              {keepTwoOrLine(data?.list?.filter(i => i.type === 1)?.[0]?.unfinished)}个
            </div>
          </div>
        </div>
      </div>
      <div className={styles.itemCard}>
        <div className={styles.top_title}>
          <img className={styles.icon} src={inverer_icon} alt="" />
          <div className={styles.title_label}>逆变器测试任务</div>
          <img className={styles.img_line} src={line} alt="" />
        </div>
        <div className={styles.bottom_content}>
          <div className={styles.item}>
            <div className={styles.item_title}>总数</div>
            <div className={cx('item_value', 'total_number')}>
              {keepTwoOrLine(data?.list?.filter(i => i.type === 3)?.[0]?.total)}个
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.item_title}>已完成</div>
            <div className={cx('item_value', 'finished_number')}>
              {keepTwoOrLine(data?.list?.filter(i => i.type === 3)?.[0]?.finished)}个
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.item_title}>进行中</div>
            <div className={cx('item_value', 'pedding_number')}>
              {keepTwoOrLine(data?.list?.filter(i => i.type === 3)?.[0]?.unfinished)}个
            </div>
          </div>
        </div>
      </div>
      <div className={styles.itemCard}>
        <div className={styles.top_title}>
          <img className={styles.icon} src={PV_icon} alt="" />
          <div className={styles.title_label}>组串测试任务</div>
          <img className={styles.img_line} src={line} alt="" />
        </div>
        <div className={styles.bottom_content}>
          <div className={styles.item}>
            <div className={styles.item_title}>总数</div>
            <div className={cx('item_value', 'total_number')}>
              {keepTwoOrLine(data?.list?.filter(i => i.type === 2)?.[0]?.total)}个
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.item_title}>已完成</div>
            <div className={cx('item_value', 'finished_number')}>
              {keepTwoOrLine(data?.list?.filter(i => i.type === 2)?.[0]?.finished)}个
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.item_title}>进行中</div>
            <div className={cx('item_value', 'pedding_number')}>
              {keepTwoOrLine(data?.list?.filter(i => i.type === 2)?.[0]?.unfinished)}个
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestDesc;
