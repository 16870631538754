import { Space } from 'antd';
import styles from './index.module.scss';
import electricityMeter from '../../icons/electricityMeter@2x.png';
import { Tag, Tooltip } from '@maxtropy/components';
import { BsaDeviceType, BsaDeviceTypeColor, BsaDeviceTypeDisplay } from '../../type';
import { goToDeviceHistoryPage } from '../utils';
import { Key } from 'react';
interface Iprops {
  title?: string;
  value?: number;
  type?: BsaDeviceType;
  deviceId?: Key;
}
const MeterTitle = ({ title, value, type, deviceId }: Iprops) => {
  return (
    <div className={styles.title_box}>
      <Space size={16}>
        <Space>
          <div className={styles.img_box}>
            <img src={electricityMeter} />
          </div>
          <Tooltip placement="top" title={title ?? '--'}>
            <div className={styles.main_title}>{title ?? '--'}</div>
          </Tooltip>

          <Tag border="solid" color={BsaDeviceTypeColor[type ?? BsaDeviceType.CONNECT]}>
            {BsaDeviceTypeDisplay[type ?? BsaDeviceType.CONNECT]}
          </Tag>
        </Space>
        <Space>
          <span className={styles.title_label}>倍率:</span>
          <span className={styles.title_value}>{value ?? '--'}</span>
        </Space>
      </Space>
      <Space>
        <span className={styles.right_label} onClick={() => goToDeviceHistoryPage(deviceId)}>
          历史数据
        </span>
      </Space>
    </div>
  );
};
export default MeterTitle;
