import classnames from 'classnames/bind';
import { Key, useMemo, useState } from 'react';
import { bsaAllots } from '../../../api/bsa';
import { BSAStatus } from '../../../api/type';
import styles from './index.module.scss';
import { Button, Modal } from '@maxtropy/components';
// 配置与阵列下发组件
const cx = classnames.bind(styles);
interface IProps {
  bsaDeliverStatus: boolean; // Bsa设备是否修改？是否需要重新下发
  bsaId: Key;
  bsaConfig: boolean; // bsaUI配置是否存在
  bsaUiUpdate: boolean; // bsaUi是否更改
  status: BSAStatus;
  onClick?: () => void;
}

const SetAndAllots: React.FC<IProps> = props => {
  let { bsaDeliverStatus, bsaConfig, bsaUiUpdate, status, bsaId, onClick } = props;
  const [loading, setLoading] = useState(false);
  // 按钮为灰色
  const isDisable = useMemo(() => {
    return status !== BSAStatus.ENABLE || !bsaConfig;
  }, [status, bsaConfig]);
  // 按钮文字为红色
  const isRed = useMemo(() => {
    return status === BSAStatus.ENABLE && bsaConfig && (bsaUiUpdate || (!bsaUiUpdate && bsaDeliverStatus));
  }, [status, bsaConfig, bsaUiUpdate, bsaDeliverStatus]);

  const clickBtn = async () => {
    // 只有启用状态可以点击
    if (isDisable) return;
    Modal.confirm({
      title: '确定下发?',
      onOk: () => {
        confirmBtn();
      },
    });
  };
  // 点击了确定下发
  const confirmBtn = async () => {
    try {
      setLoading(true);
      // 确定下发api
      let { result, reason } = await bsaAllots(bsaId);
      setLoading(false);
      if (!result) {
        // 下发失败
        Modal.error({
          title: reason,
        });
        return;
      }
      // 下发成功 显示知道了
      Modal.info({
        title: '下发成功',
        onOk: () => {
          onClick && onClick();
        },
      });
    } catch (error: any) {
      const { errorMessage } = error?.cause;
      setLoading(false);
      Modal.error({
        title: errorMessage,
      });
    }
  };
  return (
    <>
      <Button
        type="primary"
        style={{
          color: isRed ? 'red' : undefined,
        }}
        disabled={isDisable}
        onClick={clickBtn}
      >
        配置与阵列下发
      </Button>
      {/* <div className={cx('btn', isRed ? 'red' : '', isDisable ? 'disable' : '')} onClick={clickBtn}>
        配置与阵列下发
        <div className={cx('line', 'line--left')} />
        <div className={cx('line', 'line--right')} />
        <div className={cx('dot', 'dot--top-left')} />
        <div className={cx('dot', 'dot--bottom-left')} />
        <div className={cx('dot', 'dot--top-right')} />
        <div className={cx('dot', 'dot--bottom-right')} />
      </div> */}
      <Modal closable={false} open={loading} footer={null}>
        <div className={styles.setDoing}>下发中 ......</div>
      </Modal>
    </>
  );
};

export default SetAndAllots;
