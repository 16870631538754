import React, { Key, useState } from 'react';
import styles from './index.module.scss';
import { Empty, TenantType, Wrapper, useBreadcrumbRoutes, useCurrent } from '@maxtropy/components';
import { Layout, Spin } from 'antd';
import DragResize from '@/components/DragResize';
import { ClockCircleOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import BsaTree from './components/BsaTree';
import PeakCard from './components/PeakCard';
import IndicatorsTab, { IndicatorsType } from './components/IndicatorsTab';
import { useRequest } from 'ahooks';
import { apiV2BsaBatteryGetBatteryChipDataPost } from '@maxtropy/dmes-apis-v2';
import dayjs from 'dayjs';
import cellBlue from './imgs/cell-blue.png';
import cellRed from './imgs/cell-red.png';
import cellGreen from './imgs/cell-green.png';

const { Content, Sider } = Layout;

const BatteryThermalDiagram = () => {
  const isChannel = useCurrent()?.tenant?.tenantType === TenantType.CHANNEL; // 是否是渠道端
  const routesContext = useBreadcrumbRoutes();
  const [siderWidth, setSiderWidth] = useState(260); // 初始值
  const [dragStatus, setDragStatus] = useState(false); // 拖拽状态
  const [openSider, setOpenSider] = useState(true); // 左侧树形toggle
  const [activeKey, setActiveKey] = useState<string>(IndicatorsType.VOLTAGE); // 输入输出Tab选择
  const [clusterInfo, setClusterInfo] = useState<any>();
  const [currentTime, setCurrentTime] = useState<string>();

  const { data, loading } = useRequest(
    async () => {
      const res = await apiV2BsaBatteryGetBatteryChipDataPost({
        bsaId: Number(clusterInfo.id),
        bsaType: Number(clusterInfo.bsaType),
      });
      if (res) {
        setCurrentTime(dayjs().format('YYYY-MM-DD HH:mm:ss'));
      }
      return res;
    },
    {
      pollingInterval: 2 * 60000,
      ready: !!clusterInfo,
      refreshDeps: [clusterInfo],
    }
  );

  // 拖拽方法
  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  // 选择树回调
  const changeTreeSelect = (_?: Key, info?: any) => {
    if (info) {
      setClusterInfo(info);
    } else {
      setClusterInfo(undefined);
    }
  };

  return (
    <Wrapper routes={routesContext?.routes} className={styles.wrapper}>
      <Layout className={styles.layout}>
        <Sider
          width={openSider ? siderWidth : 0}
          style={{ transition: dragStatus ? 'none' : 'all 0.2s', background: '#232324' }}
        >
          <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
          <BsaTree style={{ width: '100%' }} changeTreeSelect={changeTreeSelect} />
        </Sider>

        <Content className={styles.content}>
          {data ? (
            !data.isConfig ? (
              <Empty description={'未配置电池组、电芯设备'} className={styles.empty} />
            ) : (
              <>
                <div
                  className={styles.toggleBtn}
                  style={{ left: openSider ? -30 : 0 }}
                  onClick={() => setOpenSider(!openSider)}
                >
                  {openSider ? <LeftOutlined /> : <RightOutlined />}
                </div>
                <div className={styles.top_box}>
                  <PeakCard data={data} clusterInfo={clusterInfo} isChannel={isChannel} />
                </div>

                <div className={styles.refreshTimeSty}>
                  <ClockCircleOutlined style={{ marginRight: 4 }} />
                  页面刷新时间：{currentTime}
                </div>

                <div className={styles.legendSty}>
                  {activeKey === IndicatorsType.VOLTAGE ? (
                    <>
                      <div className={styles.eachLegendSty}>
                        <img src={cellRed} className={styles.imgSty} alt="电芯" />
                        <span>电压≥{data.voltageUpperLimit ? data.voltageUpperLimit.toFixed(3) + 'V' : '--'}</span>
                      </div>
                      <div className={styles.eachLegendSty}>
                        <img src={cellBlue} className={styles.imgSty} alt="电芯" />
                        <span>
                          {data.voltageUpperLimit ? data.voltageUpperLimit.toFixed(3) + 'V' : '--'} ＞电压＞
                          {data.voltageLowerLimit ? data.voltageLowerLimit.toFixed(3) + 'V' : '--'}
                        </span>
                      </div>
                      <div className={styles.eachLegendSty}>
                        <img src={cellGreen} className={styles.imgSty} alt="电芯" />
                        <span>电压≤{data.voltageLowerLimit ? data.voltageLowerLimit.toFixed(3) + 'V' : '--'}</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={styles.eachLegendSty}>
                        <img src={cellRed} className={styles.imgSty} alt="电芯" />
                        <span>
                          温度≥{data.temperatureUpperLimit ? data.temperatureUpperLimit.toFixed(2) + '℃' : '--'}
                        </span>
                      </div>
                      <div className={styles.eachLegendSty}>
                        <img src={cellBlue} className={styles.imgSty} alt="电芯" />
                        <span>
                          {data.temperatureUpperLimit ? data.temperatureUpperLimit.toFixed(2) + '℃' : '--'} ＞温度＞
                          {data.temperatureLowerLimit ? data.temperatureLowerLimit.toFixed(2) + '℃' : '--'}
                        </span>
                      </div>
                      <div className={styles.eachLegendSty}>
                        <img src={cellGreen} className={styles.imgSty} alt="电芯" />
                        <span>
                          温度≤{data.temperatureLowerLimit ? data.temperatureLowerLimit.toFixed(2) + '℃' : '--'}
                        </span>
                      </div>
                    </>
                  )}
                </div>

                <Spin spinning={loading}>
                  <div className={styles.tabContent}>
                    <IndicatorsTab
                      onTabChange={v => setActiveKey(v)}
                      activeKey={activeKey}
                      data={data}
                      isChannel={isChannel}
                      clusterInfo={clusterInfo}
                    />
                  </div>
                </Spin>
              </>
            )
          ) : (
            <Empty description={'暂无数据'} className={styles.empty} />
          )}
        </Content>
      </Layout>
    </Wrapper>
  );
};

export default BatteryThermalDiagram;
