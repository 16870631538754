import {
  apiV2BsaSecondEditionForwardPost,
  apiV2NeoBsaDeviceCreateNeoBsaDeviceTemplatePost,
  apiV2NeoBsaDeviceGetCreateStatusPost,
  apiV2NeoBsaDeviceGetNeoBsaDeviceTemplatePost,
  V2NeoBsaDeviceGetCreateStatusPostResponse,
} from '@maxtropy/dmes-apis-v2';
import { useRequest } from 'ahooks';
import { FormInstance } from 'antd';
import { useEffect, useState } from 'react';
import ModalError from './components/ModalError';

import { CreateStatus, templateMap, TemplateType } from './const';

/**
 * 轮询堆簇组芯创建的状态
 * @param bsaId
 * @returns
 */
export const useGetCreateStatus = (type: TemplateType, bsaId?: number, callback?: () => void, refresh?: () => void) => {
  const [showModalLoading, setShowModalLoading] = useState(false);
  const [createStatus, setCreateStatus] = useState<V2NeoBsaDeviceGetCreateStatusPostResponse>();
  // 轮询用来生成设备后查询状态
  const {
    data: pollingCeateStatus,
    run: startPollingCreateStatus,
    cancel,
  } = useRequest(() => apiV2NeoBsaDeviceGetCreateStatusPost({ neoBsaId: bsaId }), {
    manual: true,
    ready: !!bsaId,
    refreshDeps: [bsaId],
    pollingInterval: 3 * 1000,
    pollingWhenHidden: false,
  });
  useEffect(() => {
    if (!pollingCeateStatus) return;
    let key = templateMap[type] as keyof V2NeoBsaDeviceGetCreateStatusPostResponse;
    switch (pollingCeateStatus?.[key]) {
      case CreateStatus.CREATING:
        setShowModalLoading(true);
        break;
      case CreateStatus.DONE:
        setShowModalLoading(false);
        // 轮询结束后重新查一次详情
        refresh?.();
        cancel();
        break;
      case CreateStatus.FAIL:
        setShowModalLoading(false);
        cancel();
        ModalError({ callback });
        break;
      default:
        setShowModalLoading(false);
        cancel();
        break;
    }
  }, [pollingCeateStatus]);

  // 进入页面先查一次
  const { data: firstCeateStatus } = useRequest(
    () =>
      apiV2NeoBsaDeviceGetCreateStatusPost({ neoBsaId: bsaId }).then(res => {
        let key = templateMap[type] as keyof V2NeoBsaDeviceGetCreateStatusPostResponse;
        // 如果是创建中的状态开启轮询
        if (res[key] === CreateStatus.CREATING) {
          startPollingCreateStatus();
        }
        // 创建失败
        if (res[key] === CreateStatus.FAIL) {
          ModalError({ callback });
        }
        return res;
      }),
    {
      ready: !!bsaId,
      refreshDeps: [bsaId],
    }
  );

  useEffect(() => {
    setCreateStatus(pollingCeateStatus ?? firstCeateStatus);
  }, [firstCeateStatus, pollingCeateStatus]);

  return { createStatus, startPollingCreateStatus, showModalLoading };
};

/**
 * 保存草稿api
 * @param params
 */
export const useSaveTemplateApi = () => {
  // 保存草稿api
  const { runAsync: saveTemplateApi } = useRequest(params => apiV2NeoBsaDeviceCreateNeoBsaDeviceTemplatePost(params), {
    manual: true,
  });

  return saveTemplateApi;
};
/**
 * 获取模板,并进行表单回显
 * @param params
 */
export const useGetTempateInfo = (
  type: TemplateType,
  bsaId?: number,
  status?: CreateStatus,
  form?: FormInstance<any>
) => {
  const { data: templateInfo } = useRequest(
    () =>
      apiV2NeoBsaDeviceGetNeoBsaDeviceTemplatePost({
        neoBsaId: bsaId!, // bsaId
        type,
      }),
    {
      ready: !!bsaId && status !== CreateStatus.DONE,
      refreshDeps: [bsaId, status],
    }
  );

  useEffect(() => {
    if (templateInfo) {
      form?.setFieldsValue(templateInfo);
    }
  }, [templateInfo]);

  return templateInfo;
};
/**
 * 更改状态机
 * @param actualStatus 当前状态机
 */
export const useChangeStatusApi = (bsaId: number, actualStatus: number) => {
  const { runAsync: changeStatus } = useRequest(
    () => apiV2BsaSecondEditionForwardPost({ id: bsaId, status: actualStatus }),
    {
      manual: true,
      ready: !!actualStatus && !!bsaId,
    }
  );
  return changeStatus;
};
