import { FC } from 'react';
import { ColumnStatusEnum } from '../const';

const ColumnStatusLabels = {
  [ColumnStatusEnum.NONE]: '无操作',
  [ColumnStatusEnum.EDIT]: '编辑',
  [ColumnStatusEnum.ADD]: '新增',
};

const ColumnStatusColors = {
  [ColumnStatusEnum.NONE]: { background: '#EEEEEE', color: 'rgba(0, 0, 0, 0.35)' },
  [ColumnStatusEnum.ADD]: { color: '#1890FF', background: '#E6F7FF' },
  [ColumnStatusEnum.EDIT]: { color: '#53C41C', background: '#EBF4E3' },
};

interface Props {
  columnStatus: ColumnStatusEnum;
}

const ActionStatusTag: FC<Props> = props => {
  const { columnStatus } = props;

  return (
    <div
      style={{
        width: 45,
        height: 20,
        textAlign: 'center',
        borderRadius: 10,
        fontSize: 12,
        color: ColumnStatusColors[columnStatus].color,
        background: ColumnStatusColors[columnStatus].background,
      }}
    >
      {ColumnStatusLabels[columnStatus]}
    </div>
  );
};

export default ActionStatusTag;
