import { useEffect, useMemo, useState, useContext, forwardRef, useImperativeHandle } from 'react';

import { combineURL, EllipsisSpan, Table } from '@maxtropy/components';

import { App, Form, Select, Space } from 'antd';

import styles from './index.module.scss';

import { StackDevice } from '@/api/entity';
import { useRequest } from 'ahooks';

import { DeviceStatus, DeviceStatusDisplay, DriveType, DriveTypeLabels } from '@/api/type';

import {
  apiV2NeoBsaDeviceBindNeoBsaStackPost,
  apiV2NeoBsaDeviceListNeoBsaPcsPost,
  apiV2NeoBsaDeviceListStackPost,
} from '@maxtropy/dmes-apis-v2';

import { DoubleRightOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { ChildDataRef, DataValueContext } from '../const';
import { isNil } from 'lodash-es';

const BsaStack = forwardRef<ChildDataRef>((props, ref) => {
  const { message } = App.useApp();
  const { bsaId, refresh, isCanEdit, isView } = useContext(DataValueContext);
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => ({
    saveForm: () => {},
    saveDraft: () => {},
  }));

  const { data: stackList } = useRequest(
    () =>
      apiV2NeoBsaDeviceListStackPost({
        neoBsaId: Number(bsaId),
      }).then(res => res.list),
    {
      ready: !!bsaId,
      refreshDeps: [bsaId],
    }
  );

  const { data: tableList, loading: tableLoading } = useRequest(
    () =>
      apiV2NeoBsaDeviceListNeoBsaPcsPost({
        neoBsaId: Number(bsaId),
      }).then(res => res.list),
    { ready: !!bsaId, refreshDeps: [bsaId] }
  );

  const [data, setData] = useState<Partial<StackDevice>[]>([]);

  useEffect(() => {
    if (tableList) {
      const list = tableList.map(i => {
        const stack = stackList?.find(stack => stack.deviceId === i.stackDeviceId);
        return {
          ...i,
          stackDeviceName: stack?.deviceName,
          stackDriveType: stack?.driveType,
          stackTypeName: stack?.typeName,
          stackModelName: stack?.modelName,
          stackDeviceCode: stack?.code,
          stackDeviceStatus: stack?.deviceStatus,
          stackRackNum: stack?.rackNum,
        };
      });

      setData(list);
    }
  }, [stackList, tableList]);

  // 所有的电池堆下拉列表, 回显时需要(已被绑定 + 未绑定)
  const allStackList = useMemo(() => {
    let hasBinds = tableList
      ?.filter(item => item.stackDeviceId)
      ?.map((item: any) => ({
        ...item,
        deviceId: item.stackDeviceId,
        deviceName: item.stackDeviceName,
      }));
    return [...(stackList ?? []), ...(hasBinds ?? [])];
  }, [stackList, tableList]);

  // 未被选择的电池堆
  const unSelectStackList = useMemo(() => {
    if (data.length > 0 && allStackList && allStackList.length > 0) {
      const selectedStackIds = data.filter((i: any) => i.stackDeviceId).map((i: any) => i.stackDeviceId);
      return allStackList.filter(i => !selectedStackIds.includes(i.deviceId));
    }
  }, [data, allStackList]);

  const onSeLectStack = (index: number, stackDeviceId?: number, record?: any) => {
    if (!record) return;
    if (stackDeviceId) {
      const selectStack = unSelectStackList?.find(i => i.deviceId === stackDeviceId);
      record.energy = selectStack?.energy!;
      record.stackDeviceId = selectStack?.deviceId;
      record.stackDeviceName = selectStack?.deviceName;
      record.stackDriveType = selectStack.driveType;
      record.stackTypeName = selectStack.typeName;
      record.stackModelName = selectStack.modelName;
      record.stackDeviceCode = selectStack.code;
      record.stackDeviceStatus = selectStack.deviceStatus;
      record.stackRackNum = selectStack.rackNum;
    } else {
      record.energy = undefined;
      record.stackDeviceId = undefined;
      record.stackDeviceName = undefined;
      record.stackDriveType = undefined;
      record.stackTypeName = undefined;
      record.stackModelName = undefined;
      record.stackDeviceCode = undefined;
      record.stackDeviceStatus = undefined;
      record.stackRackNum = undefined;
    }
    setData([...data]);
  };
  const { runAsync } = useRequest(params => apiV2NeoBsaDeviceBindNeoBsaStackPost(params), {
    manual: true,
  });

  // 判断驱动类型是否一致
  const driveTypeIsDiffrent = useMemo(() => {
    let exist = data?.map(item => item.driveType) ?? [];
    return exist.length > 1 && [...new Set(exist)].length > 1;
  }, [unSelectStackList]);
  // 电池堆回显
  useEffect(() => {
    let formList = data.map((item: any) => ({ stackDeviceId: item.stackDeviceId }));
    form.setFieldsValue({
      formList: formList,
    });
  }, [data, form]);
  const apiParams = useMemo(() => {
    return data.map((item: any, index) => {
      return {
        sequence: index,
        pcsId: item.id, // pcsid
        deviceId: item.stackDeviceId, // 电池堆设备id
      };
    });
  }, [data]);
  const saveForm = () => {
    if (driveTypeIsDiffrent) return;
    form.validateFields().then(() => {
      runAsync({
        action: 1,
        bsaId,
        stackList: apiParams,
      }).then(() => {
        refresh?.();
      });
    });
  };
  const saveDraft = () => {
    form.validateFields().then(() => {
      runAsync({
        action: 0,
        bsaId,
        stackList: apiParams,
      }).then(() => message.success('保存草稿成功!'));
    });
  };
  useImperativeHandle(ref, () => ({
    saveForm,
    saveDraft,
  }));

  const columns = [
    {
      title: '对应PCS',
      dataIndex: 'deviceName',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '电池堆',
      dataIndex: 'stackDeviceName',
      width: 260,
      render: (v: string, record: Partial<StackDevice>, index: number) => {
        if (isCanEdit && !isView) {
          return (
            <Form.Item
              name={['formList', index, 'stackDeviceId']}
              rules={[{ required: true, message: '请选择电池堆' }]}
              style={{ marginBottom: 0 }}
              label={<span></span>}
            >
              <Select
                style={{ width: 200 }}
                allowClear
                onChange={id => {
                  onSeLectStack(index, id, record);
                }}
              >
                {(allStackList ?? [])
                  ?.filter(i => !i.boundPCS)
                  .map(i => (
                    <Select.Option
                      disabled={!(unSelectStackList ?? []).map(i => i.deviceId).includes(i.deviceId)}
                      key={i.deviceId}
                      value={i.deviceId}
                    >{`${i.deviceName} ${i.typeName} ${i.modelName}`}</Select.Option>
                  ))}
              </Select>
            </Form.Item>
          );
        }
        return <EllipsisSpan value={v} />;
      },
    },
    {
      title: '驱动类型',
      dataIndex: 'stackDriveType',
      ellipsis: { showTitle: true },
      render: (v: DriveType) => {
        return <EllipsisSpan value={!isNil(v) ? DriveTypeLabels[v] : '--'} />;
      },
    },
    {
      title: '所属类目',
      dataIndex: 'stackTypeName',
      ellipsis: { showTitle: true },
      render: (v: string) => {
        return <EllipsisSpan value={v} />;
      },
    },
    {
      title: '型号',
      dataIndex: 'stackModelName',
      ellipsis: { showTitle: true },
      render: (v: string) => {
        return <EllipsisSpan value={v} />;
      },
    },
    {
      title: '簇数',
      dataIndex: 'stackRackNum',
      ellipsis: { showTitle: true },
      render: (v: string) => {
        return <EllipsisSpan value={v} />;
      },
    },
    {
      title: '电池堆能量',
      dataIndex: 'energy',
      ellipsis: { showTitle: true },
      render: (v: string, record: Partial<StackDevice>) => (
        <EllipsisSpan value={v ? `${v ?? ''} ${record.energyUnit ?? ''}` : undefined} />
      ),
    },
    {
      title: '设备编号',
      dataIndex: 'stackDeviceCode',
      ellipsis: { showTitle: true },
      render: (v: string) => {
        return <EllipsisSpan value={v} />;
      },
    },
    {
      title: '设备状态',
      dataIndex: 'stackDeviceStatus',
      ellipsis: { showTitle: true },
      render: (v: DeviceStatus) => {
        return <EllipsisSpan value={!isNil(v) ? DeviceStatusDisplay[v] : '--'} />;
      },
    },
    {
      title: '操作',
      width: 160,
      fixed: 'right' as const,
      render: (v: undefined, record: Partial<StackDevice>) => {
        return record.deviceId ? (
          <a
            target="_blank"
            href={combineURL(window.IOTPLATFORMORIGIN, `/device/manage/device/${record.deviceId}/detail`)}
            rel="noreferrer"
          >
            查看详情
          </a>
        ) : null;
      },
    },
  ];

  return (
    <div className={styles.stack_box}>
      <div className={styles.table_box}>
        <Space size={12}>
          <div className={styles.table_title}>电池堆列表</div>
          <div
            className={styles.primary_color}
            onClick={() => {
              window.open(combineURL(window.IOTPLATFORMORIGIN, `/device/manage/device/create`), '_blank');
            }}
          >
            <span>前往创建电池堆 &nbsp;</span>
            <DoubleRightOutlined style={{ fontSize: 12 }} />
          </div>
          {!isView && isCanEdit && driveTypeIsDiffrent && (
            <div className={styles.tips_text}>
              <InfoCircleOutlined />
              <span>&nbsp;当前所选电池堆驱动类型不一致，请保持一致</span>
            </div>
          )}
        </Space>
      </div>

      <Form form={form} colon={false}>
        <Table
          rowKey="deviceId"
          loading={tableLoading}
          columns={columns}
          dataSource={data}
          rowClassName={(record: Partial<StackDevice>) => {
            return record.deviceStatus === DeviceStatus.INVALID ? 'device-invalid' : '';
          }}
        />
      </Form>
    </div>
  );
});

export default BsaStack;
