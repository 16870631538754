import { PermissionsType } from '@/common/permissionsConst';
import CircuitConfigure from '@/pages/CircuitConfigure';

const routes = [
  {
    path: '/dmes/circuit/circuit/configure/:id',
    permission: PermissionsType.P_CIRCUIT_CONFIG,
    element: <CircuitConfigure />,
  },
];
export default routes;
