import { V2PvPerformanceAnalyseLossAnalysisPostResponse } from '@maxtropy/dmes-apis-v2';
import { keepTwoNull } from '../../utils';
import dayjs from 'dayjs';
import { ResolutionEnum } from '../..';

export const getChartOption = (
  chartData?: V2PvPerformanceAnalyseLossAnalysisPostResponse,
  resolution?: ResolutionEnum
) => {
  const formatDate = resolution === ResolutionEnum.YEAR ? 'YYYY-MM' : 'MM-DD';

  return {
    color: ['#16DD8E', '#FFCB47'],
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
      },
      formatter: (items: any) => {
        let { axisValue } = items[0];
        let firstStr = dayjs(axisValue).format(formatDate);
        let otherStr = '';
        let str = '';
        items.forEach((item: any) => {
          const { seriesName, data } = item;
          let unit = 'kWh';
          otherStr += `<div style="margin-top:8px"><span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${
            item.color
          };"></span>${seriesName} : &nbsp;${data[1] || data[1] === 0 ? data[1] + unit : '-'}</div>`;
          str = firstStr + otherStr;
        });
        return str;
      },
    },
    grid: {
      left: 50,
      right: 20,
      top: 60,
      bottom: 40,
      containLabel: true,
    },
    dataZoom: {
      type: 'slider',
      left: 20,
      right: 20,
      height: 20,
      bottom: 10,
      textStyle: {
        fontSize: 10,
      },
    },
    legend: [
      {
        right: 20,
        textStyle: {
          color: 'rgba(255, 255, 255, 0.85)',
        },
        inactiveColor: 'rgba(255, 255, 255, 0.45)',
        itemWidth: 12,
        itemHeight: 12,
        data: [
          {
            name: '站用电',
            icon: 'rect',
          },
          {
            name: '损耗电',
            icon: 'rect',
          },
        ],
      },
    ],
    xAxis: [
      {
        type: 'time',
        axisLabel: {
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
          formatter: function (e: number) {
            return dayjs(e, 'x').format(formatDate);
          },
        },
        minInterval: resolution === ResolutionEnum.YEAR ? 3600 * 1000 * 24 * 30 : 3600 * 1000 * 24,
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: '电量（kWh）',
        // splitNumber: 5, //优先级太低只能硬算
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        axisLabel: {
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
          formatter: '{value}',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#66686A',
          },
        },
      },
    ],
    series: [
      {
        name: '站用电',
        type: 'bar',
        stack: 'Ad',
        barMaxWidth: 60,
        data: chartData?.list?.map(item => [dayjs(item.time).valueOf(), keepTwoNull(item.stationElectricity)]),
      },
      {
        name: '损耗电',
        type: 'bar',
        stack: 'Ad',
        barMaxWidth: 60,
        data: chartData?.list?.map(item => [dayjs(item.time).valueOf(), keepTwoNull(item.lossElectricity)]),
      },
    ],
  };
};
