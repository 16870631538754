import React, { useMemo, useState } from 'react';
import {
  Button,
  PopConfirm,
  EllipsisSpan,
  FormTitle,
  Table,
  useBreadcrumbRoutes,
  Wrapper,
  Modal,
} from '@maxtropy/components';
import { Space } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  CabinetPayload,
  createCabinet,
  deleteAllCabinets,
  deleteCabinet,
  getCabinetList,
  PVCabinet,
  pvCabinetKeys,
  PVCabinetType,
  removeCabinetDevice,
  updateCabinetDevice,
} from '@/api/pv-cabinet';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { ColumnsType, ColumnType } from 'antd/es/table';
import PVCabinetFormModal from '@/pages/PvStation/Cabinet/FormModal';
import SelectDeviceModal, { PVType } from '@/components/SelectDeviceModal';
import qs from 'qs';
import { PVStatus } from '@/api/type';
import styles from './index.module.scss';

export interface EditCabinetProps {
  isEdit?: boolean;
}

const routes = [{ name: '配套设备' }];

const PVCabinetScreen: React.FC<EditCabinetProps> = ({ isEdit }) => {
  const routesContext = useBreadcrumbRoutes();
  const params = useParams<{ pvId: string }>();
  const { ouId, status } = qs.parse(window.location.search, { ignoreQueryPrefix: true }) as unknown as {
    ouId: number;
    status: PVStatus;
  };
  const pvId = +params.pvId;
  const isDraft = +status === PVStatus.DRAFT;
  const isDisable = +status === PVStatus.DISABLE;

  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(pvCabinetKeys.list({ pvId }), () => getCabinetList({ pvId }));
  const { mutate: removeDevice } = useMutation(removeCabinetDevice, {
    onSuccess: () => queryClient.invalidateQueries(pvCabinetKeys.list({ pvId })),
  });
  const { mutate: removeCabinet } = useMutation(deleteCabinet, {
    onSuccess: () => queryClient.invalidateQueries(pvCabinetKeys.list({ pvId })),
  });
  const { mutate: removeAllCabinets } = useMutation(deleteAllCabinets, {
    onSuccess: () => queryClient.invalidateQueries(pvCabinetKeys.list({ pvId })),
  });
  const { mutate: updateDevice } = useMutation(
    (data: { id: number; deviceId: number }) => updateCabinetDevice(data.id, data.deviceId),
    {
      onSuccess: () => queryClient.invalidateQueries(pvCabinetKeys.list({ pvId })),
    }
  );
  const { mutate: createNewCabinet } = useMutation(createCabinet, {
    onSuccess: () => queryClient.invalidateQueries(pvCabinetKeys.list({ pvId })),
  });

  const navigate = useNavigate();
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [selectDeviceModalVisible, setSelectDeviceModalVisible] = useState(false);
  const [row, setRow] = useState<PVCabinet>();
  const [creatingCabinet, setCreatingCabinet] = useState<CabinetPayload>();
  const actionColumn: ColumnType<PVCabinet> = useMemo(
    () => ({
      title: '操作',
      dataIndex: 'action',
      width: 250,
      fixed: 'right' as const,
      render: (_, record) => (
        <div className="action-buttons">
          <Button
            type="link"
            onClick={() => {
              setRow(record);
              setFormModalVisible(true);
            }}
          >
            编辑
          </Button>
          <PopConfirm
            disabled={!record?.deviceId}
            okText="继续"
            title={'请注意，移除后无法查询该设备数据\n你还要继续吗？'}
            onConfirm={() => removeDevice(record.id)}
          >
            <Button disabled={!record?.deviceId} type="link">
              移除设备
            </Button>
          </PopConfirm>
          <Button
            type="link"
            onClick={() => {
              setRow(record);
              setSelectDeviceModalVisible(true);
            }}
          >
            {record?.deviceId ? '更换设备' : '绑定设备'}
          </Button>
          {(isDraft || isDisable) && (
            <PopConfirm okText="确定" title={'确认删除高压设备吗？'} onConfirm={() => removeCabinet(record.id)}>
              <Button type="link">删除</Button>
            </PopConfirm>
          )}
        </div>
      ),
    }),
    [isDraft, isDisable, removeCabinet, removeDevice]
  );

  return (
    <>
      {formModalVisible && (
        <PVCabinetFormModal
          pvId={pvId}
          isDraft={isDraft}
          isDisable={isDisable}
          row={row}
          onCancel={() => {
            setRow(undefined);
            setFormModalVisible(false);
          }}
          onOk={() => {
            setRow(undefined);
            setFormModalVisible(false);
          }}
          onNextStep={cabinet => {
            setFormModalVisible(false);
            setCreatingCabinet(cabinet);
            setSelectDeviceModalVisible(true);
          }}
        />
      )}
      {selectDeviceModalVisible && (
        <SelectDeviceModal
          title="计量电表选择"
          ouId={ouId}
          pvType={PVType.CABINET}
          pvId={pvId}
          typeName={'电表'}
          initialDeviceId={row?.deviceId}
          onOk={deviceId => {
            if (creatingCabinet) {
              createNewCabinet(
                { ...creatingCabinet, deviceId },
                {
                  onSuccess: () => {
                    setCreatingCabinet(undefined);
                    setSelectDeviceModalVisible(false);
                  },
                }
              );
              return;
            }

            updateDevice(
              { id: row!.id, deviceId },
              {
                onSuccess: () => {
                  setRow(undefined);
                  setSelectDeviceModalVisible(false);
                },
              }
            );
          }}
          onCancel={() => {
            setRow(undefined);
            setSelectDeviceModalVisible(false);
          }}
        />
      )}

      <Wrapper className={styles.wrapper} routes={[...(routesContext?.routes ?? []), ...routes]}>
        <FormTitle
          title="配套设备"
          extraContent={<Button onClick={() => navigate('/pv-storage/basic/manage', { replace: true })}>返回</Button>}
        ></FormTitle>

        {isEdit && (isDraft || isDisable) && (
          <Space size={8}>
            <Button
              type="primary"
              onClick={() => {
                setFormModalVisible(true);
              }}
              icon={<PlusOutlined />}
            >
              添加配套设备
            </Button>
            <Button
              type="primary"
              icon={<DeleteOutlined />}
              onClick={() => {
                Modal.confirm({
                  title: '确认删除所有配套设备？',
                  onOk: () => removeAllCabinets(pvId),
                });
              }}
            >
              删除所有配套设备
            </Button>
          </Space>
        )}

        <Table
          style={{ marginTop: 10 }}
          sticky
          rowKey="id"
          scroll={{ x: 1500 }}
          columns={[...columns, ...(isEdit ? [actionColumn] : [])]}
          dataSource={data}
        />
      </Wrapper>
    </>
  );
};

const columns: ColumnsType<PVCabinet> = [
  {
    title: '序号',
    dataIndex: 'index',
    ellipsis: { showTitle: true },
    render: (_v, _record, index) => <EllipsisSpan value={index + 1} />,
  },
  {
    title: '配套设备名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '类型',
    dataIndex: 'type',
    ellipsis: { showTitle: true },
    render: (v: PVCabinetType) => <EllipsisSpan value={PVCabinetType.MAP[v]} />,
  },
  {
    title: '设备编号',
    dataIndex: 'deviceCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '关联并网点',
    dataIndex: 'gridConnectionPointName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export default PVCabinetScreen;
