import { FC, useMemo } from 'react';

import styles from './index.module.scss';
import { BsaType } from '../../../../api/constans';
import { BsaTitileByType } from '../../factory';
import { Steps } from '@maxtropy/components';

export interface StepProps {
  // style?: CSSProperties;
  typeList?: BsaType[];
  foucsType?: BsaType;
}

const BsaSteps: FC<StepProps> = props => {
  const { typeList, foucsType } = props;

  const stepTitles = useMemo(() => {
    if (foucsType) {
      const foucsTypeIndex = (typeList ?? []).findIndex(i => i === foucsType);
      if (foucsTypeIndex !== -1) {
        return Array.from(
          new Set<string>((typeList ?? []).slice(0, foucsTypeIndex + 1).map(i => BsaTitileByType.get(i) as string))
        );
      } else {
        return Array.from(
          new Set<string>([...(typeList ?? []), foucsType].slice(0).map(i => BsaTitileByType.get(i) as string))
        );
      }
    } else {
      return [];
    }
  }, [typeList, foucsType]);

  return (
    <Steps
      items={stepTitles.map(i => ({
        key: i,
        description: i,
      }))}
      current={stepTitles.length - 1}
      progressDot={true}
      className={styles.stepWrapper}
    />
  );
};

export default BsaSteps;
