import { PermissionsType } from '@/common/permissionsConst';
import PvRunningOverview from './index';

const routes = [
  {
    path: '/pv/running/overview',
    permission: PermissionsType.P_PVA_ASSET_OPS_SUMMARY,
    element: <PvRunningOverview />,
  },
];
export default routes;
