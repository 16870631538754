import { getCircuitMonitoringImportRecord, MonitoringImportRecordRes } from '@/api/circuit';
import { Modal, Button, EllipsisSpan, getRealUrl, Paging, Table, usePaging } from '@maxtropy/components';
import { Space } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
interface Iprops {
  visible?: boolean;
  setVisible?: (visible: boolean) => void;
}
const LogListModal: React.FC<Iprops> = ({ visible, setVisible }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState<MonitoringImportRecordRes[]>([]);
  const pagingInfo = usePaging(20);

  const { pageOffset, pageSize, setTotalCount } = pagingInfo;

  const buildReocrdscolumns = [
    {
      title: '批量类型',
      dataIndex: 'importTypeName',
      width: 150,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '批量导入状态',
      dataIndex: 'numSuccess',
      ellipsis: { showTitle: true },
      width: 160,
      render: (v: number, record: any) => (
        <EllipsisSpan
          value={
            <div>
              成功 : {v ?? 0} &nbsp;&nbsp;失败 : {record.numFail ?? 0}
            </div>
          }
        />
      ),
    },
    {
      title: '批量导入时间',
      dataIndex: 'createTime',
      ellipsis: { showTitle: true },
      width: 150,
      render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
    },
    {
      title: '操作人',
      dataIndex: 'staffName',
      width: 150,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
  ];
  const buildOpColumns = [
    {
      title: '操作',
      width: 300,
      fixed: 'right' as const,
      render: (record: MonitoringImportRecordRes) => {
        return (
          <Space size={16}>
            <Button type="link" onClick={() => downClick(record?.dataFail)} disabled={!record.numFail}>
              下载失败数据
            </Button>
            <Button type="link" onClick={() => downClick(record?.dataSuccess)} disabled={!record.numSuccess}>
              下载成功数据
            </Button>
          </Space>
        );
      },
    },
  ];
  const downClick = (key: string) => {
    window.open(getRealUrl(key), '_blank');
  };
  useEffect(() => {
    if (visible) {
      setIsLoading(true);
      getCircuitMonitoringImportRecord({ page: pageOffset, size: pageSize })
        .then(res => {
          setList(res.list);
          setTotalCount(res.total);
        })
        .finally(() => setIsLoading(false));
    }
  }, [visible, pageOffset, pageSize, setTotalCount]);

  return (
    <Modal
      open={visible}
      size="big"
      title={`批量操作日志`}
      maskClosable={false}
      onCancel={() => setVisible?.(false)}
      footer={<></>}
    >
      <Table
        sticky
        loading={isLoading}
        rowKey="id"
        scroll={{ y: 500 }}
        columns={[...buildReocrdscolumns, ...buildOpColumns]}
        dataSource={list}
      />
      <Paging pagingInfo={pagingInfo} />
    </Modal>
  );
};

export default LogListModal;
