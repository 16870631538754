import { ChargingStationPageRes, getChargingStationCostInfo, updateCostInfo } from '@/api/chargingStationManagement';
import { Form, InputNumber, Modal, Button, ShowInput } from '@maxtropy/components';
import { Spin } from 'antd';
import { FC, useEffect, useState } from 'react';
import { ActionType, ActionTypeDisplay } from '../..';
import { isNil } from 'lodash-es';

interface ICostModalForm {
  showModal: ActionType | undefined;
  changeShowModal: (v: ActionType | undefined) => void;
  updateFn: () => void;
  row?: ChargingStationPageRes;
}

const CostModalForm: FC<ICostModalForm> = props => {
  const { showModal, changeShowModal, updateFn, row } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (row && row?.id && showModal) {
      setLoading(true);
      getChargingStationCostInfo(row?.id)
        .then(res => {
          if (showModal === ActionType.UPDATE) {
            form.setFieldsValue({
              investmentCost: res.investmentCost,
              recoveredCost: res.recoveredCost,
            });
          } else {
            form.setFieldsValue({
              investmentCost: !isNil(res.investmentCost) ? res.investmentCost + '万元' : '--',
              recoveredCost: !isNil(res.recoveredCost) ? res.recoveredCost + '万元' : '--',
            });
          }
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row, showModal]);

  const onFinish = (val: any) => {
    if (showModal === ActionType.UPDATE && row) {
      updateCostInfo(row.id, val).then(() => {
        changeShowModal(undefined);
        updateFn();
      });
    }
  };

  return (
    <Modal
      destroyOnClose
      size="normal"
      open={!!showModal}
      onCancel={() => changeShowModal(undefined)}
      contentClassName="modal-form-content"
      footer={
        showModal === ActionType.UPDATE ? (
          [
            <Button key="back" style={{ marginRight: 12 }} onClick={() => changeShowModal(undefined)}>
              取消
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                form.submit();
              }}
            >
              确认
            </Button>,
          ]
        ) : (
          <Button key="back" type="primary" onClick={() => changeShowModal(undefined)}>
            好的
          </Button>
        )
      }
      title={`${ActionTypeDisplay[showModal as ActionType]}成本信息`}
    >
      <Spin spinning={loading}>
        <Form form={form} onFinish={(val: any) => onFinish(val)}>
          <Form.Item
            validateFirst
            name="investmentCost"
            label="投建成本"
            rules={[{ required: true, message: '请输入' }]}
          >
            {showModal === ActionType.DETAIL ? (
              <ShowInput />
            ) : (
              <InputNumber
                addonAfter="万元"
                min={0}
                max={9999}
                style={{ width: '100%' }}
                precision={2}
                placeholder="请输入"
              />
            )}
          </Form.Item>
          <Form.Item name="recoveredCost" label="已回收成本" rules={[{ required: true, message: '请选择' }]}>
            {showModal === ActionType.DETAIL ? (
              <ShowInput />
            ) : (
              <InputNumber
                addonAfter="万元"
                min={0}
                max={9999}
                style={{ width: '100%' }}
                precision={2}
                placeholder="请输入"
              />
            )}
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CostModalForm;
