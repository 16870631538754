import { EllipsisSpan, Table } from '@maxtropy/components';
import { useMemo } from 'react';
import { v4 } from 'uuid';

import { RealData } from '../../type';

import styles from './index.module.scss';
import MeterTitle from './MeterTitle';
import PcsPowerTitle from './PcsPowerTitle';
import { DataPropertyValuesItem, useGetTableColumns } from './utils';
interface Iprops {
  data?: Exclude<RealData, undefined>[number];
}
// pcs设备只显示10053, 10061
const needShowPcsIds = [
  { datapropertyName: 'PCS功率控制(交流侧)', datapropertyId: 10053 },
  { datapropertyName: '运行功率', datapropertyId: 10061 },
]; // 只展示并网点下pcs的设定功率和运行功率
const ConnectTable = ({ data }: Iprops) => {
  const connectData = useMemo(() => {
    return data?.dataPropertyValues;
  }, [data]);

  const flowPcsData = useMemo(() => {
    let pcsDevices = data?.nextLevelsRealTimeData ?? [];
    let doubles = pcsDevices.flatMap(item => {
      // 每个设备绑定2个数据属性
      return needShowPcsIds.map(a => {
        return {
          ...a,
          ...item,
          uuid: v4(),
        } as DataPropertyValuesItem;
      });
    });
    return doubles;
  }, [data]);

  // 并网点下的pcs
  const fallowPcsColumns = useGetTableColumns({
    tableList: flowPcsData,
    tableTitle: <PcsPowerTitle title="并网点下PCS功率数据" deviceId={data?.deviceId} />,
    type: 'pcs',
  });
  // 并网点
  const connectColumns = useGetTableColumns({
    tableList: connectData,
    tableTitle: <MeterTitle title={data?.deviceName ?? '--'} deviceId={data?.deviceId} value={data?.multiplier} />,
  });
  return (
    <div className={styles.connect_table}>
      <Table
        rowKey="datapropertyId"
        scroll={{ y: connectData && connectData?.length > 0 ? 245 : undefined }}
        columns={connectColumns}
        dataSource={connectData}
      />
      <Table
        rowKey="uuid"
        scroll={{ y: flowPcsData && flowPcsData.length > 0 ? 245 : undefined }}
        columns={fallowPcsColumns}
        dataSource={flowPcsData}
      />
    </div>
  );
};
export default ConnectTable;
