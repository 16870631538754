import { forwardRef, useImperativeHandle, useContext, useMemo } from 'react';

import { combineURL, EllipsisSpan, Paging, Table, usePaging } from '@maxtropy/components';

import Button from '@/components/Button';

import { Space, Select, Form, Row, Col, App } from 'antd';

import styles from './index.module.scss';

import { DeviceStatus, DeviceStatusDisplay } from '@/api/type';

import { padStart } from '@/pages/Bsa/utils';
import { ChildDataRef, CreateStatus, DataValueContext, TemplateType } from '../const';
import { useRequest } from 'ahooks';
import { apiV2NeoBsaDeviceCreateNeoBsaPackPost, apiV2NeoBsaDeviceListNeoBsaPackPost } from '@maxtropy/dmes-apis-v2';
import CreatingModal from '@/pages/BsaDataCollectionConfigNew/components/CreatingModal';
import { DoubleRightOutlined } from '@ant-design/icons';
import { useGetDeviceTypes, useGetModalsList } from '../selectListHooks';
import { useChangeStatusApi, useGetCreateStatus, useGetTempateInfo, useSaveTemplateApi } from '../commonApiHooks';
import DeviceModelSelect from './DeviceModelSelect';
const columns = [
  {
    title: '设备编号',
    dataIndex: 'deviceCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '对应PCS',
    dataIndex: 'pcsName',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '电池簇编号',
    dataIndex: 'rackSequence',
    render: (v: number) => <EllipsisSpan value={padStart(v)} />,
  },
  {
    title: '电池组编号',
    dataIndex: 'packSequence',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={padStart(v)} />,
  },
  {
    title: '类目',
    dataIndex: 'typeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '型号',
    dataIndex: 'modelName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '电池组能量',
    dataIndex: 'energy',
    ellipsis: { showTitle: true },
    render: (v: string, record: any) => (
      <EllipsisSpan value={v ? `${v ?? ''} ${record.energyUnit ?? ''}` : undefined} />
    ),
  },
  {
    title: '额定电压',
    dataIndex: 'voltage',
    ellipsis: { showTitle: true },
    render: (v: string, record: any) => (
      <EllipsisSpan value={v ? `${v ?? ''} ${record.voltageUnit ?? ''}` : undefined} />
    ),
  },
  {
    title: '设备状态',
    dataIndex: 'deviceStatus',
    ellipsis: { showTitle: true },
    render: (value: DeviceStatus) => {
      return <EllipsisSpan value={DeviceStatusDisplay[value]} />;
    },
  },
  {
    title: '操作',
    width: 110,
    fixed: 'right' as const,
    render: (v: undefined, record: any) => (
      <a
        target="_blank"
        href={combineURL(window.IOTPLATFORMORIGIN, `/device/manage/device/${record.deviceId}/detail`)}
        rel="noreferrer"
      >
        查看详情
      </a>
    ),
  },
];

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22 },
};

const PackForm = forwardRef<ChildDataRef>((props, ref) => {
  const { bsaId, refresh, data: detail, isView } = useContext(DataValueContext);

  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;

  const { message } = App.useApp();
  useImperativeHandle(ref, () => ({
    saveForm,
    saveDraft,
  }));
  const [form] = Form.useForm();

  const deviceTypeId = Form.useWatch('deviceTypeId', form);
  // 轮询创建的状态
  const { createStatus, startPollingCreateStatus, showModalLoading } = useGetCreateStatus(
    TemplateType.PACK,
    Number(bsaId),
    () => form.submit(),
    refresh
  );
  useGetTempateInfo(TemplateType.PACK, Number(bsaId), detail?.packDeviceCreateStatus, form);

  const saveTemplateApi = useSaveTemplateApi();
  // 更改状态机
  const changeStatus = useChangeStatusApi(Number(bsaId), Number(detail?.actualStatus));

  // 二级类目下拉列表
  const deviceTypes = useGetDeviceTypes(TemplateType.PACK, detail?.packDeviceCreateStatus);
  const { modelList, physicalModelList } = useGetModalsList(deviceTypeId);

  // 电池组表格
  const { data: tableList, loading: tableLoading } = useRequest(
    () =>
      apiV2NeoBsaDeviceListNeoBsaPackPost({ neoBsaId: Number(bsaId), page: pageOffset, size: pageSize }).then(res => {
        setTotalCount(res.total!);
        return res.list;
      }),
    {
      ready: createStatus?.packDeviceCreateStatus === CreateStatus.DONE && !!bsaId,
      refreshDeps: [pageOffset, pageSize, bsaId],
    }
  );

  // 生成电池组api
  const { runAsync: createPack, loading: createLoading } = useRequest(
    params => apiV2NeoBsaDeviceCreateNeoBsaPackPost(params),
    {
      manual: true,
    }
  );
  const onSubmit = (values: any) => {
    createPack({
      neoBsaId: Number(bsaId),
      ...values,
    }).then(() => {
      startPollingCreateStatus();
    });
  };
  const saveForm = () => {
    // 状态机流转
    if (createStatus?.packDeviceCreateStatus !== CreateStatus.DONE) {
      message.success('设备未创建完成!');
      return;
    }
    changeStatus().then(() => {
      refresh?.();
    });
  };
  // 保存簇模板
  const saveDraft = () => {
    if (createStatus?.packDeviceCreateStatus === CreateStatus.DONE) return;
    form.validateFields().then(values => {
      saveTemplateApi({
        neoBsaId: Number(bsaId),
        type: TemplateType.PACK, // PACK
        ...values,
      }).then(() => message.success('保存草稿成功!'));
    });
  };
  // 列表是否展示
  const showTableList = useMemo(() => {
    return createStatus?.packDeviceCreateStatus === CreateStatus.DONE || isView;
  }, [createStatus, isView]);
  return (
    <div className={styles.pack_box}>
      {!showTableList && (
        <div className={styles.form_box}>
          <div className={styles.title}>电池组型号</div>
          <Form form={form} layout="vertical" {...formLayout} onFinish={onSubmit}>
            <Row>
              <Col span={6}>
                <Form.Item
                  name="deviceTypeId"
                  label="所属二级类目"
                  rules={[{ required: true, message: '请选择所属类目' }]}
                >
                  <Select
                    placeholder="请选择"
                    disabled={isView}
                    onChange={() => {
                      form.setFieldsValue({
                        modelName: undefined,
                      });
                    }}
                  >
                    {(deviceTypes ?? []).map(i => (
                      <Select.Option key={i.id} value={i.id}>
                        {i.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="modelName" label="型号" rules={[{ required: true, message: '请选择所属型号' }]}>
                  <DeviceModelSelect
                    disabled={!deviceTypeId || isView}
                    options={modelList?.map(i => ({ label: i.name, value: i.name }))}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="physicalModelId" label="物模型">
                  <Select
                    disabled={!deviceTypeId || isView}
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                    placeholder="请输入"
                    optionFilterProp="label"
                    options={physicalModelList?.map(i => ({ label: i.modelNo, value: i.id }))}
                  />
                </Form.Item>
              </Col>
              {!isView && (
                <Col span={6}>
                  <Form.Item label={<span></span>}>
                    <Button loading={createLoading} type="primary" htmlType="submit">
                      批量生成电池组
                    </Button>
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Form>
        </div>
      )}

      {showTableList && (
        <div className={styles.table_box}>
          <Space size={12}>
            <div className={styles.table_title}>电池组列表</div>
            <div
              className={styles.primary_color}
              onClick={() => {
                window.open(combineURL(window.IOTPLATFORMORIGIN, `/device/manage/device`), '_blank');
              }}
            >
              <span>前往修改电池组信息 &nbsp;</span>
              <DoubleRightOutlined style={{ fontSize: 12 }} />
            </div>
          </Space>

          <Table
            loading={tableLoading}
            columns={columns}
            rowKey="deviceId"
            dataSource={tableList}
            rowClassName={(record: any) => {
              return record.deviceStatus === DeviceStatus.INVALID ? 'device-invalid' : '';
            }}
          />
          <Paging pagingInfo={pagingInfo} />
        </div>
      )}

      <CreatingModal loading={showModalLoading} content="电池组创建中" />
    </div>
  );
});

export default PackForm;
