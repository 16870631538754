import { Input, Modal, Select } from '@maxtropy/components';
import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  apiV2BsaSecondEditionCabientCreatePost,
  apiV2BsaSecondEditionCabientUpdatePost,
  apiV2BsaSecondEditionCabinetStackListPost,
} from '@maxtropy/dmes-apis-v2';
import { CabinetItem, CabinetType, CabinetTypeOptions, OptionItem } from '../const';
import { useParams } from 'react-router-dom';
import { isNil } from 'lodash-es';

interface Props {
  open: boolean;
  currentCabinet?: CabinetItem;
  setOpen: (open: boolean) => void;
  updateData?: () => void;
}

interface FormProps {
  name: string;
  type: number;
  stackIds: number[];
  sn?: string;
}

const CreateCabinetModal: React.FC<Props> = ({ open, setOpen, currentCabinet, updateData }) => {
  const [form] = Form.useForm();
  const { bsaId } = useParams<{ bsaId: string }>();
  const [stackList, setStackList] = useState<OptionItem[]>([]);
  const type = Form.useWatch('type', form);

  useEffect(() => {
    if (currentCabinet) {
      form.setFieldsValue({
        name: currentCabinet.name,
        type: currentCabinet.type,
        stackIds: currentCabinet?.stackList?.map(m => m.id),
        sn: currentCabinet.sn,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCabinet]);

  useEffect(() => {
    if (isNil(type)) return;
    apiV2BsaSecondEditionCabinetStackListPost({ bsaId: Number(bsaId), type }).then(res => {
      const stackList = (res?.list ?? []).map(m => ({
        label: m.name!,
        value: m.id!,
        disabled: !!m.binding && !currentCabinet?.stackList?.some(k => k.id === m.id),
      }));
      setStackList(stackList);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, bsaId, currentCabinet]);

  const onFinish = (v: FormProps) => {
    if (currentCabinet && currentCabinet?.id) {
      apiV2BsaSecondEditionCabientUpdatePost({ id: currentCabinet?.id, ...v, type: v.type as CabinetType }).then(() => {
        setOpen(false);
        updateData?.();
      });
    } else {
      apiV2BsaSecondEditionCabientCreatePost({ ...v, bsaId: Number(bsaId), type: v.type as CabinetType }).then(() => {
        setOpen(false);
        updateData?.();
      });
    }
  };

  return (
    <Modal
      title={currentCabinet ? '编辑柜体' : '创建柜体'}
      open={open}
      onOk={() => form.submit()}
      onCancel={() => setOpen(false)}
    >
      <Form
        form={form}
        onFinish={onFinish}
        style={{ padding: '0 60px' }}
        labelCol={{ flex: '110px' }}
        labelAlign="left"
      >
        <Form.Item
          label="柜体名称"
          name="name"
          rules={[
            {
              required: true,
              message: '请输入柜体名称',
            },
            { max: 30, message: '最多输入30个字符' },
          ]}
        >
          <Input placeholder="请输入名称" />
        </Form.Item>
        <Form.Item
          label="柜体类型"
          name="type"
          rules={[
            {
              required: true,
              message: '请选择',
            },
          ]}
        >
          <Select
            placeholder="请选择"
            onChange={() => form.setFieldValue('stackIds', undefined)}
            options={CabinetTypeOptions}
          />
        </Form.Item>
        <Form.Item
          label={`关联${type === CabinetType.BATTERY_CABINET ? '电池堆' : 'PCS'}`}
          rules={[
            {
              required: true,
              message: '请选择',
            },
          ]}
          name="stackIds"
        >
          <Select mode="multiple" placeholder="请选择" options={stackList} />
        </Form.Item>
        <Form.Item label="柜体编号" name="sn" rules={[{ max: 30, message: '最多输入30个字符' }]}>
          <Input placeholder="请输入" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateCabinetModal;
