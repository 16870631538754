import { PermissionsType } from '@/common/permissionsConst';
import PvIncomeReport from './index';

const routes = [
  {
    path: '/pv/income/report',
    permission: PermissionsType.P_PVA_TOU_INCOME_REPORT,
    element: <PvIncomeReport />,
  },
];

export default routes;
