import styles from './index.module.scss';
import packIcon from '../../icons/icon-batteryPack@2x.png';
import { FirstLevelDevice } from './CardItem';
import { Table } from '@maxtropy/components';
import { useMemo } from 'react';
import { useGetDataPropertyInfo } from '../../hooks';
import { getTsByPropertyId, getValueByPropertyId, goToDeviceHistoryPage } from '../utils';

export type NextLevelDeviceInfo = Exclude<FirstLevelDevice['nextLevelsRealTimeData'], undefined>[number];
interface Iprops {
  data?: NextLevelDeviceInfo;
}
const NextLevelDevice = ({ data }: Iprops) => {
  const { deviceIds, dataPropertyIds } = useMemo(() => {
    return {
      deviceIds: [data?.deviceId!].filter(Boolean),
      dataPropertyIds: data?.dataPropertyValues?.filter(Boolean)?.map(item => item.datapropertyId!),
    };
  }, [data]);
  const { data: valuesMap } = useGetDataPropertyInfo({ deviceIds: deviceIds as unknown as number[], dataPropertyIds });
  const columns = [
    {
      title: '名称',
      dataIndex: 'datapropertyName',
    },
    {
      title: '数据',
      dataIndex: 'data',
      render: (v: string, record: any) => {
        const { datapropertyId, deviceId, generalName } = record;
        return getValueByPropertyId({
          datapropertyId,
          deviceId,
          valuesMap,
          unit: generalName,
        });
      },
    },
    {
      title: '更新时间',
      dataIndex: 'time',
      render: (v: string, record: any) => {
        return getTsByPropertyId(record.datapropertyId, record.deviceId, valuesMap);
      },
    },
  ];
  return (
    <div className={styles.table_item}>
      <div className={styles.card_title}>
        <div className={styles.left}>
          <div className={styles.img_box}>
            <img src={packIcon} alt="" />
          </div>
          <span className={styles.img_title}>{data?.deviceName ?? '--'}</span>
        </div>
        {/* 5007 -----> 代表电池组类目 */}
        <div className={styles.right} onClick={() => goToDeviceHistoryPage(data?.deviceId, 5007)}>
          历史数据
        </div>
      </div>
      <Table
        columns={columns}
        rowKey="datapropertyId"
        dataSource={data?.dataPropertyValues}
        scroll={{ y: data?.dataPropertyValues && data?.dataPropertyValues.length > 0 ? 200 : undefined }}
      />
    </div>
  );
};

export default NextLevelDevice;
