export enum FormStatus {
  Create,
  Update,
  Display,
  Copy,
}

export namespace FormStatus {
  export const MAP = {
    [FormStatus.Create]: '新建',
    [FormStatus.Update]: '编辑',
    [FormStatus.Display]: '查看',
    [FormStatus.Copy]: '复制',
  };
}

export interface Customer {
  mcid: string;
  name: string;
}

export interface Ou {
  id: number;
  name: string;
  serialNumber: string;
}

export interface UET {
  id: number;
  name: string;
}

export interface BSA {
  fcsId: number;
  id: number;
  name: string;
  fcsName: string;
  fcsSerialNumber: string;
  hardwareVersion: string;
}

export interface BSADetail {
  id: number;
  name: string;
  fcsId: number;
  fcsName: string;
  loadTransformerName: string;
  node: string;
  capacity: number;
  targetLoad: number;
  unit: string;
}

export interface FCSDetail {
  capacity: number;
  unit: string;
  targetLoad: number;
  fcsId: number;
  deviceName: string;
  level: number;
  sequence: number;
  customerName: string;
  uetName: string;
  ouName: string;
  bsaName: string;
  fcsName: string;
}

export interface FCSRatedParams {
  eol: number;
  multiplyingPowerUpperLimit: number;
  cycles: number;
}

export enum ComputationalLogic {
  CONNECTION = 2,
  POWER_SUPPLY = 4,
}

export const computationalLogicDisplay = {
  [ComputationalLogic.CONNECTION]: '设备连接状态',
  [ComputationalLogic.POWER_SUPPLY]: '网关供电状态',
};
