import { CombinerBoxProps, getGridNetPointList, getPvAreaList, NetInfoProp, PvAreaInfo } from '@/api/pv';
import { CustomFilter, Form, Input, Select } from '@maxtropy/components';
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';

interface Iprops {
  pvId: any;
  onFinish?: (v?: CombinerBoxProps) => void;
  onReset?: () => void;
}
const SearchParams: React.FC<Iprops> = ({ pvId, onFinish, onReset }) => {
  const [form] = useForm();
  const [areaList, setAreaList] = useState<PvAreaInfo[]>([]);
  const [gridPointsList, setGridPointsList] = useState<NetInfoProp[]>([]);
  // 获取片区/并网点列表
  useEffect(() => {
    if (pvId) {
      Promise.all([
        getPvAreaList(pvId).then(setAreaList),
        getGridNetPointList(pvId, {
          size: 1000,
          page: 1,
        }).then(res => setGridPointsList(res.list || [])),
      ]);
    }
  }, [pvId]);
  return (
    <div style={{ margin: '16px 0' }}>
      <CustomFilter form={form} onFinish={v => onFinish?.(v as CombinerBoxProps)} onReset={() => onReset && onReset()}>
        <Form.Item name="code" label="汇流箱编号">
          <Input placeholder="请输入编号或名称查询"></Input>
        </Form.Item>
        <Form.Item name="gridConnectionPointId" label="关联并网点">
          <Select placeholder="请选择">
            {gridPointsList.map(v => (
              <Select.Option value={v.id} key={v.id}>
                {v.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="pvAreaId" label="所在片区名称">
          <Select placeholder="请选择">
            {areaList.map(v => (
              <Select.Option value={v.id} key={v.code}>
                {v.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </CustomFilter>
    </div>
  );
};
export default SearchParams;
