import { Divider, Space, Tag } from 'antd';
import { Button } from '@maxtropy/components';
import { FC } from 'react';
import styles from './index.module.scss';
import chargeImg from '../../../imgs/charge.png';
import disChargeImg from '../../../imgs/disCharge.png';
import standByImg from '../../../imgs/standBy.png';
import currentMonthInComingImg from '../../../imgs/currentMonthInComing.png';
import yesterdayInComingImg from '../../../imgs/yesterdayInComing.png';
import totalInComImg from '../../../imgs/totalInComing.png';
import OperateItemInfo from '@/pages/MapStation/RightInfo/components/OperateInfo/OperateItemInfo';
import {
  formateValuesPercent,
  formateValuesRmb,
  formateValuesSOCPercent,
  formateValuesToFix2,
  getValueUnit,
} from '@/pages/MapStation/utils';
import { BsaDataStaticsGisRes, StationList, StationStatus, StationStatusDisplay } from '@/api/bsaMap';
import { isNil } from 'lodash-es';

export interface IDetailInfo {
  changeIsDetail: (id: number | undefined, showPosition: boolean, showDetails: boolean) => void;
  divHeight?: number;
  data?: BsaDataStaticsGisRes;
  itemInfo?: StationList;
}

const DetailInfo: FC<IDetailInfo> = props => {
  const { changeIsDetail, divHeight, itemInfo } = props;

  return (
    <div style={{ height: divHeight ? divHeight - 104 : 'unset' }} className={styles.DetailInfo}>
      <div className={styles.DetailInfoBtnSty}>
        <Button
          style={{ padding: 0, fontSize: 12, height: 32 }}
          type="link"
          onClick={() => {
            changeIsDetail(undefined, true, false);
          }}
        >
          返回列表
        </Button>

        <Button
          style={{ padding: 0, fontSize: 12, height: 32 }}
          type="link"
          onClick={() => window.open(`${window.MSEDEVDATMIND}/#/energy/welcome?id=${itemInfo?.id}`, '_blank')}
        >
          站点详情
        </Button>
      </div>
      <div className={styles.DetailInfoTwoSty}>
        <div className={styles.DetailInfoTwoStyTitle}>
          <span style={{ fontSize: 16, fontWeight: 600, marginRight: 6, color: '#fff' }}>{itemInfo?.name}</span>
          {!isNil(itemInfo?.status) && (
            <Tag
              color="green"
              icon={
                <img
                  src={
                    itemInfo?.status === StationStatus.CHARGE
                      ? chargeImg
                      : itemInfo?.status === StationStatus.STANDBY
                      ? standByImg
                      : disChargeImg
                  }
                  alt=""
                />
              }
            >
              {StationStatusDisplay[itemInfo?.status as StationStatus]}
            </Tag>
          )}
        </div>
        <div className={styles.DetailInfoTwoItemSty}>
          <div>装机容量</div>
          <div className={styles.ItemInfoStyle}>{`${formateValuesToFix2(itemInfo?.ratedPower)}MW/${formateValuesToFix2(
            itemInfo?.installedCapacity
          )}MWh`}</div>
        </div>
        <div className={styles.DetailInfoTwoItemSty}>
          <div>各电池堆SOC范围</div>
          {itemInfo?.stackCount && itemInfo.stackCount === 1 ? (
            <span className={styles.ItemInfoStyle}>{`${formateValuesSOCPercent(itemInfo?.socUpper)}%`}</span>
          ) : (
            <span className={styles.ItemInfoStyle}>{`${formateValuesSOCPercent(
              itemInfo?.socLower
            )}%~${formateValuesSOCPercent(itemInfo?.socUpper)}%`}</span>
          )}
        </div>
        <div className={styles.DetailInfoTwoItemSty}>
          <div>月均系统效率</div>
          <div className={styles.ItemInfoStyle}>{formateValuesPercent(itemInfo?.monthSystemEfficiency)}%</div>
        </div>
      </div>

      <div>
        <OperateItemInfo
          title={'今日'}
          chargeValue={itemInfo?.thisDayCharge}
          disChargeValue={itemInfo?.thisDayDischarge}
        />
        <OperateItemInfo
          title={'当前'}
          secondTitle="可"
          chargeValue={itemInfo?.thisDayChargeCapacity}
          disChargeValue={itemInfo?.thisDayDischargeCapacity}
        />
        <OperateItemInfo
          title={'昨日'}
          chargeValue={itemInfo?.lastDayCharge}
          disChargeValue={itemInfo?.lastDayDischarge}
        />
        <OperateItemInfo title={'累计'} chargeValue={itemInfo?.totalCharge} disChargeValue={itemInfo?.totalDischarge} />
      </div>

      <div className={styles.DetailInComing}>
        <div className={styles.TopItemInfo}>
          <img style={{ width: 57, height: 50 }} src={yesterdayInComingImg} alt="" />
          <div>
            <div className={styles.TopLabel}>昨日收益({getValueUnit(itemInfo?.lastDayIncome)})</div>
            <div className={styles.TopValue}>{formateValuesRmb(itemInfo?.lastDayIncome)}</div>
          </div>
        </div>
        <div className={styles.TopItemInfo}>
          <img style={{ width: 57, height: 50 }} src={totalInComImg} alt="" />
          <div>
            <div className={styles.TopLabel}>累计收益({getValueUnit(itemInfo?.totalIncome)})</div>
            <div className={styles.TopValue}>{formateValuesRmb(itemInfo?.totalIncome)}</div>
          </div>
        </div>
        <div className={styles.TopItemInfo}>
          <img style={{ width: 57, height: 50 }} src={currentMonthInComingImg} alt="" />
          <div>
            <div className={styles.TopLabel}>本月收益({getValueUnit(itemInfo?.thisMonthIncome)})</div>
            <div className={styles.TopValue}>{formateValuesRmb(itemInfo?.thisMonthIncome)}</div>
          </div>
        </div>
        <div className={styles.inComingPercent}>
          <div className={styles.TopLabel}>本月收益环比</div>
          <div className={styles.percentValue}>{formateValuesPercent(itemInfo?.thisMonthIncomeRate)}%</div>
        </div>
      </div>

      <div>
        <Space size={0} split={<Divider type="vertical" />}>
          <Button
            style={{ width: 130, paddingLeft: 0, paddingRight: 0 }}
            type="link"
            onClick={() => window.open(`${window.MSEDEVDATMIND}/#/energy/profit?bsaId=${itemInfo?.id}`, '_blank')}
          >
            收益详细数据
          </Button>
          <Button
            style={{ width: 130, paddingLeft: 0, paddingRight: 0 }}
            type="link"
            onClick={() => window.open(`/dmes/energy-storage/charts?bsaId=${itemInfo?.id}`, '_blank')}
          >
            运行详细数据
          </Button>
        </Space>
      </div>
    </div>
  );
};

export default DetailInfo;
