import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Breadcrumb,
  Button,
  EllipsisSpan,
  Form,
  FormTitle,
  Modal,
  Table,
  useBreadcrumbRoutes,
} from '@maxtropy/components';
import BorderWrapper from '../../components/BorderWrapper';
import styles from './index.module.scss';
import { ControlOutlined, DatabaseOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import {
  batchBindMonitoringIndicator,
  bindMonitoringIndicator,
  getCircuitDetail,
  MonitoringIndicatorProps,
  queryMonitoringIndicatorList,
  unbindMonitoringIndicator,
} from '../../api/circuit';
import dayjs from 'dayjs';
import { ColumnsType } from 'antd/lib/table';
import { CircuitPhysicalQuantityType, CircuitPhysicalQuantityTypeDisplay } from '../../api/const';
import { useParams } from 'react-router-dom';
import BindForm from './BindForm';
import UnbindForm from './UnbindForm';
import BatchBindForm, { BatchBindFormRef } from './BatchBindForm';
import { useQuery } from 'react-query';
import { getEffectiveTime } from './EffectiveTimePicker';
import LogTable from './LogTable';
import DataLink from './DataLink';

enum ModalType {
  BIND,
  UNBIND,
  BATCH_BIND,
  LOG,
  TIME_LINE,
}

const routes = [{ name: `回路配置详情` }];

const columns: ColumnsType<MonitoringIndicatorProps> = [
  {
    title: '监控指标',
    dataIndex: 'circuitPhysicalQuantityName',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '监控指标单位',
    dataIndex: 'physicalUnitName',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '瞬时量/累积量',
    dataIndex: 'type',
    ellipsis: { showTitle: true },
    render: (v: CircuitPhysicalQuantityType) => <EllipsisSpan value={CircuitPhysicalQuantityTypeDisplay[v]} />,
  },
  {
    title: '所属设备',
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: (v, record) => (
      <EllipsisSpan
        value={
          v ? (
            <a
              rel="noreferrer"
              href={`${(window as any).IOTPLATFORMORIGIN}/device/manage/device/${record.deviceId}/detail`}
              target="_blank"
            >
              {v}
            </a>
          ) : (
            v
          )
        }
      />
    ),
  },
  {
    title: '数据属性',
    dataIndex: 'dataPropertyName',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '数据生效时间',
    dataIndex: 'startTime',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm') : v} />,
  },
];

const CircuitConfigure = () => {
  const [bindForm] = Form.useForm();
  const [unbindForm] = Form.useForm();
  const [batchBindForm] = Form.useForm();
  const [_] = Form.useForm();
  const [dataSource, setDataSource] = useState<MonitoringIndicatorProps[]>([]);
  const [row, setRow] = useState<MonitoringIndicatorProps>();
  const [modalType, setModalType] = useState<ModalType>();
  const batchFormRef = useRef<BatchBindFormRef>(null);
  const routesContext = useBreadcrumbRoutes();
  const { id } = useParams<{ id: string }>();
  const { data: detail } = useQuery(['circuitDetail', +id], async () => {
    const res = await getCircuitDetail(+id);
    return res;
  });

  useEffect(() => {
    if (id) refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const refresh = () => queryMonitoringIndicatorList(id).then(setDataSource);

  const actionColumn: ColumnsType<MonitoringIndicatorProps> = [
    {
      title: '操作',
      width: 220,
      fixed: 'right' as const,
      render: (v: any, record) => (
        <Space size={16}>
          <Button
            type="link"
            onClick={() => {
              setModalType(ModalType.UNBIND);
              setRow(record);
            }}
            disabled={!record.startTime || (!!record.startTime && !!record.endTime)}
          >
            解绑
          </Button>
          <Button
            type="link"
            onClick={() => {
              setModalType(ModalType.BIND);
              setRow(record);
            }}
          >
            编辑
          </Button>
          <Button
            type="link"
            onClick={() => {
              setModalType(ModalType.LOG);
              setRow(record);
            }}
          >
            日志
          </Button>
          <Button
            type="link"
            onClick={() => {
              setModalType(ModalType.TIME_LINE);
              setRow(record);
            }}
          >
            数据链路
          </Button>
        </Space>
      ),
    },
  ];

  const form = useMemo(() => {
    switch (modalType) {
      case ModalType.BATCH_BIND:
        return batchBindForm;
      case ModalType.UNBIND:
        return unbindForm;
      case ModalType.BIND:
        return bindForm;
      default:
        return _;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalType]);

  const submitConfirm = () => {
    const validate =
      modalType === ModalType.BATCH_BIND
        ? () => Promise.all([form.validateFields(), batchFormRef.current?.validate?.()])
        : form.validateFields;
    validate().then(() => {
      Modal.confirm({
        title: '确定要执行该操作么？',
        content: <div>回路数据将发生变更是否继续？</div>,
        onOk: () => {
          if (modalType === ModalType.BIND) {
            bind();
          } else if (modalType === ModalType.UNBIND) {
            unbind();
          } else if (modalType === ModalType.BATCH_BIND) {
            batchBind();
          }
        },
      });
    });
  };

  const bind = () => {
    const values = form.getFieldsValue();
    const body = {
      deviceId: values.deviceId,
      dataPropertyId: values.dataPropertyId,
      circuitId: row!.circuitId,
      circuitPhysicalQuantityId: row!.circuitPhysicalQuantityId,
      startTime: getEffectiveTime(values.effectiveTime),
    };
    return bindMonitoringIndicator(body).then(() => {
      reset();
      refresh();
    });
  };

  const unbind = () => {
    const values = form.getFieldsValue();
    const body = {
      deviceId: row!.deviceId,
      dataPropertyId: row!.dataPropertyId,
      circuitId: row!.circuitId,
      circuitPhysicalQuantityId: row!.circuitPhysicalQuantityId,
      endTime: getEffectiveTime(values.effectiveTime),
    };
    return unbindMonitoringIndicator(body).then(() => {
      reset();
      refresh();
    });
  };

  const batchBind = () => {
    const values = form.getFieldsValue();
    const mapRequests = batchFormRef
      .current!.dataSource.filter(item => item.dataPropertyId)
      .map(({ dataPropertyId, circuitPhysicalQuantityId, deviceId }) => ({
        dataPropertyId,
        circuitPhysicalQuantityId,
        deviceId,
      }));
    const body = {
      deviceId: values.deviceId,
      circuitId: detail!.id,
      startTime: getEffectiveTime(values.effectiveTime),
      mapRequests,
    };
    if (mapRequests.length > 0) {
      return batchBindMonitoringIndicator(body).then(() => {
        reset();
        refresh();
      });
    } else {
      reset();
      refresh();
    }
  };

  const reset = () => {
    form.resetFields();
    setModalType(undefined);
    setRow(undefined);
  };

  return (
    <div className="page">
      <div className="page__header">
        <Breadcrumb routes={[...(routesContext?.routes ?? []), ...routes]} />
      </div>
      <BorderWrapper>
        <div className="page__content">
          <FormTitle title="回路详情"></FormTitle>
          <div className={styles.flexRow}>
            <ItemDisplay icon={<ControlOutlined className={styles.icon} />} label={'回路'} value={detail?.name} />
            <ItemDisplay
              icon={<ThunderboltOutlined className={styles.icon} />}
              label={'配电房'}
              value={detail?.distributionCabinetName}
            />
            <ItemDisplay
              icon={<DatabaseOutlined className={styles.icon} />}
              label={'已绑定设备'}
              value={detail?.devices?.map((v: any) => v.deviceDto.name).join(',')}
            />
          </div>
          <div style={{ margin: '10px 0' }}>
            <Button
              type="primary"
              onClick={() => {
                setModalType(ModalType.BATCH_BIND);
              }}
            >
              批量绑定
            </Button>
          </div>

          <Table
            sticky
            rowKey="circuitPhysicalQuantityId"
            scroll={{ x: 1500 }}
            columns={[...columns, ...actionColumn]}
            dataSource={dataSource}
          />
        </div>
      </BorderWrapper>

      <Modal
        destroyOnClose
        contentClassName="modal-form-content"
        open={modalType === ModalType.BIND}
        title="编辑监控指标"
        size="large"
        maskClosable={false}
        onCancel={reset}
        footer={
          <Space>
            <Button onClick={reset}>取消</Button>
            <Button type="primary" onClick={submitConfirm}>
              保存
            </Button>
          </Space>
        }
      >
        <BindForm form={form} row={row} />
      </Modal>

      <Modal
        destroyOnClose
        contentClassName="modal-form-content"
        open={modalType === ModalType.UNBIND}
        title="解绑"
        size="large"
        maskClosable={false}
        onCancel={reset}
        footer={
          <Space>
            <Button onClick={reset}>取消</Button>
            <Button type="primary" onClick={submitConfirm}>
              保存
            </Button>
          </Space>
        }
      >
        <UnbindForm form={form} row={row} />
      </Modal>

      <Modal
        destroyOnClose
        open={modalType === ModalType.BATCH_BIND}
        title="批量绑定"
        size="large"
        contentClassName="modal-form-content"
        maskClosable={false}
        onCancel={reset}
        footer={
          <Space>
            <Button onClick={reset}>取消</Button>
            <Button type="primary" onClick={submitConfirm}>
              保存
            </Button>
          </Space>
        }
      >
        <BatchBindForm
          form={form}
          selectedRows={dataSource}
          ref={batchFormRef}
          visible={modalType === ModalType.BATCH_BIND}
        />
      </Modal>

      <Modal
        destroyOnClose
        open={modalType === ModalType.LOG}
        title="日志"
        size="big"
        maskClosable={false}
        onCancel={reset}
        footer={
          <Space>
            <Button onClick={reset}>取消</Button>
            <Button type="primary" onClick={reset}>
              确定
            </Button>
          </Space>
        }
      >
        <LogTable row={row} />
      </Modal>

      <Modal
        destroyOnClose
        open={modalType === ModalType.TIME_LINE}
        title="数据链路"
        size="large"
        maskClosable={false}
        onCancel={reset}
        footer={
          <Space>
            <Button onClick={reset}>取消</Button>
            <Button type="primary" onClick={reset}>
              确定
            </Button>
          </Space>
        }
      >
        <DataLink row={row} />
      </Modal>
    </div>
  );
};

interface ItemDisplayProps {
  icon?: React.ReactNode;
  label?: string;
  value?: string;
  style?: React.CSSProperties;
}

const ItemDisplay: React.FC<ItemDisplayProps> = props => {
  const { icon, label, value, style } = props;
  return (
    <Space style={style}>
      <div className={styles.hexagon}>{icon}</div>
      <span>
        {label}：<span className={styles.primary}>{value ?? '--'}</span>
      </span>
    </Space>
  );
};

export default CircuitConfigure;
