import { Button, EllipsisSpan, Input, Paging, Table, usePaging } from '@maxtropy/components';
import { Space } from 'antd';
import { useRequest } from 'ahooks';
import {
  apiV2PvDispersionRateAnalyzeConfigPagePost,
  V2PvDispersionRateAnalyzeConfigPagePostRequest,
  V2PvDispersionRateAnalyzeConfigPagePostResponse,
} from '@maxtropy/dmes-apis-v2';
import { useState } from 'react';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

type SearchParams = Omit<V2PvDispersionRateAnalyzeConfigPagePostRequest, 'timestamp' | 'page' | 'size'>;
type DataItem = Exclude<V2PvDispersionRateAnalyzeConfigPagePostResponse['list'], undefined>[number];

const AnalysisConfig = () => {
  const navigate = useNavigate();
  const paging = usePaging(20);
  const { pageOffset, pageSize, setPageOffset, setTotalCount } = paging;
  const [searchParams, setSearchParams] = useState<SearchParams>({});

  const { data, loading } = useRequest(
    async () => {
      const res = await apiV2PvDispersionRateAnalyzeConfigPagePost({
        page: pageOffset,
        size: pageSize,
        name: searchParams.name,
      });
      setTotalCount(res.total ?? 0);
      return res.list ?? [];
    },
    {
      refreshDeps: [searchParams, pageOffset, pageSize],
    }
  );

  const onSearch = (values: SearchParams) => {
    setSearchParams(values);
    setPageOffset(1);
  };

  const columns: ColumnType<DataItem>[] = [
    {
      title: '设置组名称',
      dataIndex: 'configName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '分析时间段',
      key: 'analysisTime',
      ellipsis: { showTitle: true },
      render: (_, record) => (
        <EllipsisSpan
          value={`${dayjs(record.analysisStartTime, 'HH:mm').format('HH:mm')} - ${dayjs(
            record.analysisEndTime,
            'HH:mm'
          ).format('HH:mm')}`}
        ></EllipsisSpan>
      ),
    },
    {
      title: '适用光伏站-片区',
      key: 'areaNameList',
      ellipsis: { showTitle: true },
      render: (_, record) => (
        <EllipsisSpan
          value={record.pvStationName! + '-' + record.areaNameList?.map(item => item.pvAreaName)?.join(',')}
        ></EllipsisSpan>
      ),
    },
    {
      title: '操作',
      key: 'action',
      width: 120,
      render: (_, record) => (
        <Space size={16}>
          <Button type="link" onClick={() => navigate(`/pv/dispersionRate/config/setting/detail/${record.id}`)}>
            查看
          </Button>
          <Button
            disabled={record.status !== 1}
            type="link"
            onClick={() => navigate(`/pv/dispersionRate/config/setting/edit/${record.id}`)}
          >
            编辑
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Space size={8} style={{ marginBottom: 10 }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => navigate(`/pv/dispersionRate/config/setting/create`)}
        >
          添加设置组
        </Button>
        <Input.Search
          placeholder="请输入设置组或光伏站名称"
          style={{ width: 250 }}
          allowClear
          onSearch={value => {
            onSearch({ name: value });
          }}
        ></Input.Search>
      </Space>

      <Table dataSource={data} columns={columns} loading={loading} rowKey="id"></Table>
      <Paging pagingInfo={paging}></Paging>
    </>
  );
};

export default AnalysisConfig;
