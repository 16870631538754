import { PermissionsType } from '@/common/permissionsConst';
import BsaRealTimeMonitor from '.';

const routes = [
  {
    path: '/energy-storage/realTime/monitor',
    permission: PermissionsType.P_REALTIME_STORAGE,
    element: <BsaRealTimeMonitor />,
  },
];
export default routes;
