export enum ThresholdDeviceType {
  PV = -1,
  INVERTER,
  STRING_AMPS,
  STRING_TEMP,
}

export const ThresholdDeviceTypeLabel = {
  [ThresholdDeviceType.PV]: '光伏站',
  [ThresholdDeviceType.INVERTER]: '逆变器',
  [ThresholdDeviceType.STRING_AMPS]: '组串(电流)',
  [ThresholdDeviceType.STRING_TEMP]: '组串(温度)',
};

export const ThresholdDeviceTypeList = [
  ThresholdDeviceType.PV,
  ThresholdDeviceType.INVERTER,
  ThresholdDeviceType.STRING_AMPS,
  ThresholdDeviceType.STRING_TEMP,
];

export enum PvThresholdType {
  BAD = 3,
  POOR = 2,
  GENERAL = 1,
  GOOD = 0,
}

export const PvThresholdTypeList = [
  PvThresholdType.BAD,
  PvThresholdType.POOR,
  PvThresholdType.GENERAL,
  PvThresholdType.GOOD,
];

export const PvThresholdTypeLabel = {
  [PvThresholdType.BAD]: '差',
  [PvThresholdType.POOR]: '较差',
  [PvThresholdType.GENERAL]: '一般',
  [PvThresholdType.GOOD]: '良好',
};

export const PvThresholdTypeColor = {
  [PvThresholdType.BAD]: '#E64242',
  [PvThresholdType.POOR]: '#FAAD14',
  [PvThresholdType.GENERAL]: '#4A90E2',
  [PvThresholdType.GOOD]: '#ABD335',
};

export enum InverterOrComponentThresholdType {
  ABNORMAL = 3,
  POOR = 2,
  GENERAL = 1,
  GOOD = 0,
}

export const InverterOrComponentThresholdTypeList = [
  InverterOrComponentThresholdType.ABNORMAL,
  InverterOrComponentThresholdType.POOR,
  InverterOrComponentThresholdType.GENERAL,
  InverterOrComponentThresholdType.GOOD,
];

export const InverterOrComponentThresholdTypeLabel = {
  [InverterOrComponentThresholdType.ABNORMAL]: '异常',
  [InverterOrComponentThresholdType.POOR]: '较差',
  [InverterOrComponentThresholdType.GENERAL]: '一般',
  [InverterOrComponentThresholdType.GOOD]: '优秀',
};

export const InverterOrComponentThresholdTypeColor = {
  [InverterOrComponentThresholdType.ABNORMAL]: '#E64242',
  [InverterOrComponentThresholdType.POOR]: '#FAAD14',
  [InverterOrComponentThresholdType.GENERAL]: '#4A90E2',
  [InverterOrComponentThresholdType.GOOD]: '#16DD8E',
};
