import { PermissionsType } from '@/common/permissionsConst';
import SitePerforAnalysisYear from '.';

const routes = [
  {
    path: '/site/performance/analysis',
    permission: PermissionsType.P_PVAPERFORMANCEANALYSIS,
    element: <SitePerforAnalysisYear />,
  },
];
export default routes;
