import { SyncOutlined } from '@ant-design/icons';
import { Button, Tabs } from '@maxtropy/components';
import { Space, TabsProps } from 'antd';
import { useContext, useMemo, useState } from 'react';
import { ShareContext } from '../..';
import { RealTimeDataType, RealTimeDataTypeDisplay } from '../../type';
import RemoteLogModal from './RemoteLogModal';
interface Iprops {
  onChange?: (key: RealTimeDataType) => void;
  refresh?: () => void;
}
const TopTabs = ({ onChange, refresh }: Iprops) => {
  const { setRealData } = useContext(ShareContext);
  const [visible, setVisible] = useState(false);
  const items: TabsProps['items'] = useMemo(() => {
    return Object.entries(RealTimeDataTypeDisplay).map(([key, label]) => {
      return {
        key,
        label,
      };
    });
  }, []);

  return (
    <>
      <Tabs
        tabBarExtraContent={
          <div style={{ marginRight: 16 }}>
            <Space size={8}>
              <Button
                onClick={() => {
                  setVisible(true);
                }}
              >
                远控日志
              </Button>
              <Button icon={<SyncOutlined />} onClick={() => refresh?.()}>
                刷新
              </Button>
            </Space>
          </div>
        }
        selectedLinePlacement="top"
        items={items}
        onChange={(key: string) => {
          setRealData?.([]);
          onChange?.(key as unknown as RealTimeDataType);
        }}
      />
      {visible && <RemoteLogModal onCancel={() => setVisible(false)} onConfirm={() => setVisible(false)} />}
    </>
  );
};
export default TopTabs;
