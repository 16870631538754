import { Checkbox, InputNumber } from '@maxtropy/components';
import { App, Form } from 'antd';
import { forwardRef, useContext, useEffect, useImperativeHandle } from 'react';
import styles from './index.module.scss';

import { useRequest } from 'ahooks';
import { apiV2BsaSecondEditionUpdateObsPost } from '@maxtropy/dmes-apis-v2';
import { PCSType } from '@/pages/BsaNewBasic';
import { BsaStructProps, ChildDataRef, DataValueContext, getStructureInfo } from '../const';

const BasicForm = forwardRef<ChildDataRef, BsaStructProps>((props, ref) => {
  const { refresh, setSelectStepList, bsaId, data, isCanEdit, isView } = useContext(DataValueContext);
  const { message } = App.useApp();
  const [form] = Form.useForm();
  // 当储能站硬件结构为单PCS类型时，PCS数默认设置为1且不可更改
  // const cellCheckedStatus = Form.useWatch('hasCell', form);
  // const packCheckedStatus = Form.useWatch('hasPack', form);

  useImperativeHandle(ref, () => ({
    saveForm: saveForm,
    saveDraft: saveDraft,
  }));
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
      });
      if (data.pcsStruct === PCSType.SIMPLE) {
        form.setFieldsValue({
          pcsCount: 1,
          stackCount: 1,
        });
      }
    }
  }, [data, form]);
  const { runAsync } = useRequest(params => apiV2BsaSecondEditionUpdateObsPost(params), { manual: true });
  const saveDraft = () => {
    form.validateFields().then(validValues => {
      runAsync({
        neoBsaId: bsaId,
        action: 0,
        ...validValues,
      }).then(() => message.success('保存草稿成功!'));
    });
  };
  const saveForm = () => {
    form.validateFields().then(res => {
      runAsync({
        neoBsaId: bsaId,
        action: 1,
        ...res,
      }).then(() => {
        refresh?.();
      });
    });
  };

  const rackCellPackChange = () => {
    let { hasRack, hasPack, hasCell } = form.getFieldsValue();
    let tempList = getStructureInfo(hasRack, hasPack, hasCell);
    setSelectStepList?.(tempList);
  };
  return (
    <>
      <Form form={form}>
        <div className={styles.basic_box}>
          <div className={styles.basic_box_title}>电池整体阵列结构</div>
          <div className={styles.form_item_box}>
            <div className={styles.check_box}>
              <div className={styles.line_box}></div>
              <Form.Item name="hasPcs" valuePropName="checked">
                <Checkbox disabled defaultChecked>
                  PCS
                </Checkbox>
              </Form.Item>
            </div>
            <Form.Item name="pcsCount" label="PCS个数" rules={[{ required: true }]}>
              <InputNumber
                disabled={data?.pcsStruct === PCSType.SIMPLE || !isCanEdit || isView}
                style={{ width: 240 }}
              />
            </Form.Item>
          </div>
          <div className={styles.form_item_box}>
            <div className={styles.check_box}>
              <div className={styles.line_box}></div>
              <Form.Item name="hasStack" valuePropName="checked">
                <Checkbox disabled defaultChecked>
                  电池堆
                </Checkbox>
              </Form.Item>
            </div>

            <Form.Item
              name="stackCount"
              label="电池堆数"
              dependencies={['pcsCount']}
              rules={[
                { required: true },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('pcsCount') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('电池堆数与PCS个数保持一致'));
                  },
                }),
              ]}
            >
              <InputNumber
                disabled={data?.pcsStruct === PCSType.SIMPLE || !isCanEdit || isView}
                style={{ width: 240 }}
              />
            </Form.Item>
          </div>
          <div className={styles.form_item_box}>
            <div className={styles.check_box}>
              <div className={styles.line_box}></div>
              <Form.Item name="hasRack" valuePropName="checked">
                <Checkbox
                  onChange={e => {
                    form.setFieldsValue({
                      rackCountInStack: undefined,
                    });
                    if (!e.target.checked) {
                      form.setFieldsValue({
                        hasPack: false,
                        hasCell: false,
                        packCountInRack: undefined,
                        cellCountInPack: undefined,
                      });
                    }
                    rackCellPackChange();
                  }}
                  disabled={!isCanEdit || isView}
                >
                  电池簇
                </Checkbox>
              </Form.Item>
            </div>
            <Form.Item noStyle dependencies={['hasRack']}>
              {({ getFieldValue }) => {
                let val = getFieldValue('hasRack');
                return (
                  <Form.Item name="rackCountInStack" label="堆内簇数" rules={[{ required: val }]}>
                    <InputNumber disabled={!val || !isCanEdit || isView} style={{ width: 240 }} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </div>
          <div className={styles.form_item_box}>
            <div className={styles.check_box}>
              <div className={styles.line_box}></div>

              <Form.Item name="hasPack" valuePropName="checked">
                <Checkbox
                  disabled={!isCanEdit || isView}
                  onChange={e => {
                    if (e.target.checked) {
                      form.setFieldsValue({
                        hasRack: e.target.checked,
                      });
                    } else {
                      form.setFieldsValue({
                        hasCell: false,
                        cellCountInPack: undefined,
                      });
                    }
                    form.setFieldsValue({
                      packCountInRack: undefined,
                    });

                    rackCellPackChange();
                  }}
                >
                  电池组
                </Checkbox>
              </Form.Item>
            </div>
            <Form.Item noStyle dependencies={['hasPack']}>
              {({ getFieldValue }) => {
                let val = getFieldValue('hasPack');
                return (
                  <Form.Item name="packCountInRack" label="簇内组数" rules={[{ required: val }]}>
                    <InputNumber disabled={!val || !isCanEdit || isView} style={{ width: 240 }} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </div>
          <div className={styles.form_item_box}>
            <div className={styles.check_box}>
              <Form.Item name="hasCell" valuePropName="checked">
                <Checkbox
                  disabled={!isCanEdit || isView}
                  onChange={e => {
                    if (e.target.checked) {
                      form.setFieldsValue({
                        hasPack: e.target.checked,
                        hasRack: e.target.checked,
                      });
                    }
                    form.setFieldsValue({
                      cellCountInPack: undefined,
                    });
                    rackCellPackChange();
                  }}
                >
                  电芯
                </Checkbox>
              </Form.Item>
            </div>
            <Form.Item noStyle dependencies={['hasCell']}>
              {({ getFieldValue }) => {
                let val = getFieldValue('hasCell');
                return (
                  <Form.Item name="cellCountInPack" label="组内芯数" rules={[{ required: val }]}>
                    <InputNumber disabled={!val || !isCanEdit || isView} style={{ width: 240 }} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </div>
        </div>
      </Form>
    </>
  );
});

export default BasicForm;
