import { FC } from 'react';
import { V2PvOutlinePerformanceStatisticsPostResponse } from '@maxtropy/dmes-apis-v2';
import styles from './index.module.scss';
import ReactEcharts from 'echarts-for-react';
import { cloneDeep, isNil } from 'lodash-es';
import { customFixed } from '@/utils/utils';
import dayjs from 'dayjs';

interface Props {
  data: V2PvOutlinePerformanceStatisticsPostResponse['pvStationStatisticsValueList'];
}

const ElecChart: FC<Props> = props => {
  const { data } = props;

  const newData = cloneDeep(data)?.map(item => {
    let overPower = (item.realPower ?? 0) - (item.planPower ?? 0);
    overPower = overPower > 0 ? overPower : 0;
    return {
      ...item,
      overPower,
    };
  });

  const chartOpt = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'line',
        lineStyle: {
          type: 'solid',
          width: 32,
          color: 'rgba(255,255,255,0.1)',
        },
      },
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      textStyle: {
        color: 'rgba(255,255,255,0.65)',
      },
      formatter: (items: any[]) => {
        const { axisValueLabel, dataIndex } = items[0];
        let str: string = dayjs(axisValueLabel).format('YYYY-MM');
        items.forEach((item, index) => {
          // 超额发电不显示
          if (index === 2) return;

          const { seriesName, marker } = item;
          let data: any;
          // 计划发电量
          if (index === 0) {
            data = item.data[1];
          }
          // 实际发电量
          if (index === 1) {
            data = newData?.[dataIndex]?.realPower;
          }
          const value = customFixed(data);
          str += `<div style="display:flex;margin-top:4px;justify-content:space-between;gap:30px;">
                    <span>${marker}${seriesName}</span>
                    <span>${value}kWh</span>
                  </div>`;
        });
        str += `<div style="display:flex;margin-top:4px;justify-content:space-between;gap:30px;">
                    <span>完成率</span>
                    <span>${customFixed(newData?.[dataIndex]?.accomplishmentRatio! * 100)}%</span>
                  </div>`;
        return str;
      },
    },
    legend: {
      right: 0,
      itemGap: 24,
      itemWidth: 12,
      itemHeight: 12,
      icon: 'rect',
      textStyle: {
        color: 'rgba(255,255, 255, 0.85)',
        fontSize: 14,
      },
    },
    grid: {
      left: '3%',
      right: '3%',
      bottom: '0',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'time',
        axisLabel: {
          color: 'rgba(255,255, 255, 0.85)',
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: '电量(kWh)',
        nameTextStyle: {
          color: 'rgba(255,255, 255, 0.85)',
          padding: [0, 0, 0, 30],
        },
        axisLabel: {
          color: 'rgba(255,255, 255, 0.85)',
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255, 255, 0.3)',
          },
        },
      },
    ],
    series: [
      {
        name: '计划发电量',
        type: 'bar',
        barMaxWidth: 12,
        itemStyle: {
          color: 'rgba(0,173,255,0.3)',
        },
        data: newData?.map(item => [item.time, item.planPower]),
      },
      {
        name: '实际发电量',
        stack: '1',
        type: 'bar',
        barGap: '-100%',
        barMaxWidth: 12,
        itemStyle: {
          color: '#00ADFF',
        },
        data: newData?.map(item => [item.time, !isNil(item.realPower) ? item.realPower - item.overPower : null]),
      },
      {
        name: '超额发电量',
        stack: '1',
        type: 'bar',
        barGap: '-100%',
        barMaxWidth: 12,
        itemStyle: {
          color: '#57FB8B',
        },
        data: newData?.map(item => [item.time, item.overPower]),
      },
    ],
  };

  return <ReactEcharts option={chartOpt} className={styles.elecChart}></ReactEcharts>;
};

export default ElecChart;
