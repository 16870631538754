import React from 'react';
const offline = () => (
  <svg
    width="1em"
    height="1em"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>离线</title>
    <g id="页面-1" stroke="none" stroke-width="1" fill="currentColor">
      <g id="储能整体运维看板-卡片模式" transform="translate(-477.000000, -582.000000)">
        <g id="卡片" transform="translate(50.000000, 360.000000)">
          <g id="看板卡片备份-5" transform="translate(338.000000, 210.000000)">
            <g id="离线" transform="translate(85.000000, 7.000000)">
              <g transform="translate(4.000000, 5.000000)">
                <rect
                  id="矩形"
                  fill="currentColor"
                  fill-rule="nonzero"
                  opacity="0"
                  x="0"
                  y="0"
                  width="13"
                  height="13"
                ></rect>
                <path
                  d="M11.8333333,4.96976875 C9.99851875,3.2309375 7.53220208,2.54326458 5.19791667,2.90677708"
                  id="路径"
                  stroke="currentColor"
                  stroke-width="1.08333333"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M10.2083333,6.8205625 C9.47345417,6.08565625 8.57832292,5.60866458 7.63541667,5.3895875"
                  id="路径"
                  stroke="currentColor"
                  stroke-width="1.08333333"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M2.625,6.8205625 C2.98455833,6.46100417 3.38249375,6.16316875 3.80558958,5.92708333"
                  id="路径"
                  stroke="currentColor"
                  stroke-width="1.08333333"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M4.25,8.5849875 C4.639675,8.1953125 5.1081625,7.93255 5.60416667,7.79667292"
                  id="路径"
                  stroke="currentColor"
                  stroke-width="1.08333333"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M6.41666667,10.6666667 C6.79060625,10.6666667 7.09375,10.3635229 7.09375,9.98958333 C7.09375,9.61564375 6.79060625,9.3125 6.41666667,9.3125 C6.04272708,9.3125 5.73958333,9.61564375 5.73958333,9.98958333 C5.73958333,10.3635229 6.04272708,10.6666667 6.41666667,10.6666667 Z"
                  id="路径"
                  fill="currentColor"
                ></path>
                <line
                  x1="10.75"
                  y1="10.6666667"
                  x2="2.08333333"
                  y2="2"
                  id="路径"
                  stroke="currentColor"
                  stroke-width="1.08333333"
                  stroke-linejoin="round"
                ></line>
                <path
                  d="M1,4.96974167 C1.15946667,4.81861667 1.32370542,4.67542708 1.49221521,4.5402 C1.64090542,4.42084375 1.79292146,4.30768958 1.94791396,4.20071042"
                  id="路径"
                  stroke="currentColor"
                  stroke-width="1.08333333"
                  stroke-linejoin="round"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default offline;
