import { InfoCircleFilled } from '@ant-design/icons';
import { Col, Divider, Row, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import classnames from 'classnames/bind';
import { useEffect } from 'react';
import {
  EPricePart,
  EPricePeakType,
  E_PRICE_PEAK_TYPE_COLORS_MAP,
  E_PRICE_PEAK_TYPE_MAP,
} from '../../api/electricityPrice';
import styles from './index.module.scss';
import EPricePeakItem from './PeakItem';
import { hasType, isFullDay } from './utils';
import { Checkbox, Form, InputNumber, Modal, Radio } from '@maxtropy/components';

const cx = classnames.bind(styles);

interface EPriceTimeFormModalProps {
  ePricePart?: EPricePart;
  onOk: (ePricePart: EPricePart) => void;
  onCancel: () => void;
  disabledMonths: number[];
}

const EPriceTimeFormModal: React.FC<EPriceTimeFormModalProps> = ({
  ePricePart = { months: [], ePriceTimes: [] },
  disabledMonths,
  onOk,
  onCancel,
}) => {
  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...ePricePart,
      months: [...ePricePart.months, ...disabledMonths],
    });
  }, [disabledMonths, ePricePart, form]);

  const onFinish = (values: any) => {
    onOk({
      ...ePricePart,
      ...values,
      months: values.months.filter((v: number) => !disabledMonths.includes(v)).sort((a: number, b: number) => a - b),
    });
  };

  return (
    <Modal open size="large" title="分时电价" maskClosable={false} onCancel={onCancel} onOk={() => form.submit()}>
      <div className={cx('modal-content')}>
        <Form form={form} onFinish={onFinish}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <Checkbox
                onChange={e => {
                  form.setFieldsValue({
                    months: e.target.checked ? [...new Array(12)].map((_, i) => i + 1) : disabledMonths,
                  });
                }}
                indeterminate={
                  getFieldValue('months')?.length > disabledMonths.length && getFieldValue('months').length < 12
                }
                style={{ marginBottom: 18 }}
                checked={getFieldValue('months')?.length === 12}
              >
                全年
              </Checkbox>
            )}
          </Form.Item>
          <Form.Item
            name="months"
            rules={[
              {
                validator: (_, value) => {
                  if (!value || value.length === 0) {
                    return Promise.reject('请选择月份');
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Checkbox.Group style={{ width: '100%' }}>
              <Row gutter={[0, 26]}>
                {[...new Array(12)].map((_, index) => (
                  <Col span={4} key={index}>
                    <Checkbox value={index + 1} disabled={disabledMonths.includes(index + 1)}>
                      {index + 1}月
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Divider />
          <Form.Item name="peakType">
            <Radio.Group>
              {Object.keys(E_PRICE_PEAK_TYPE_MAP).map(key => (
                <Radio key={key} value={key}>
                  {E_PRICE_PEAK_TYPE_MAP[key as unknown as EPricePeakType]}
                  <span
                    className={cx('radio-hint')}
                    style={{
                      background: E_PRICE_PEAK_TYPE_COLORS_MAP[key as unknown as EPricePeakType],
                    }}
                  />
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item noStyle dependencies={['peakType']}>
            {({ getFieldValue }) => (
              <Form.Item
                name="ePriceTimes"
                valuePropName="times"
                rules={[
                  {
                    validator: (_, value) => {
                      if (isFullDay(value || [])) {
                        return Promise.resolve();
                      }
                      return Promise.reject('请选满24小时');
                    },
                    validateTrigger: 'onBlur',
                  },
                ]}
              >
                <EPricePeakItem peakType={getFieldValue('peakType')} />
              </Form.Item>
            )}
          </Form.Item>
          <div className={cx('info')}>
            <InfoCircleFilled style={{ color: '#1890FF', marginRight: '5px' }} />
            {`先选中分段（尖峰平谷)，再在时间轴上分别单击选择起止时间块，直到无灰色时间块方可。`}
          </div>

          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) =>
              hasType(getFieldValue('ePriceTimes'), EPricePeakType.SUMMIT) && (
                <Space size={10}>
                  <Form.Item name="summitEnergyRate" label="尖峰电价" rules={[{ required: true }]}>
                    <InputNumber precision={4} min={0} max={99.9999} placeholder="0~99.9999" style={{ width: 260 }} />
                  </Form.Item>
                  <div className={cx('input-suffix')}>元</div>
                </Space>
              )
            }
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) =>
              hasType(getFieldValue('ePriceTimes'), EPricePeakType.PEAK) && (
                <Space size={10}>
                  <Form.Item name="peakEnergyRate" label="高峰电价" rules={[{ required: true }]}>
                    <InputNumber precision={4} min={0} max={99.9999} placeholder="0~99.9999" style={{ width: 260 }} />
                  </Form.Item>
                  <div className={cx('input-suffix')}>元</div>
                </Space>
              )
            }
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) =>
              hasType(getFieldValue('ePriceTimes'), EPricePeakType.PLAIN) && (
                <Space size={10}>
                  <Form.Item name="plainEnergyRate" label="平段电价" rules={[{ required: true }]}>
                    <InputNumber precision={4} min={0} max={99.9999} placeholder="0~99.9999" style={{ width: 260 }} />
                  </Form.Item>
                  <div className={cx('input-suffix')}>元</div>
                </Space>
              )
            }
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) =>
              hasType(getFieldValue('ePriceTimes'), EPricePeakType.VALLEY) && (
                <Space size={10}>
                  <Form.Item name="valleyEnergyRate" label="低谷电价" rules={[{ required: true }]}>
                    <InputNumber precision={4} min={0} max={99.9999} placeholder="0~99.9999" style={{ width: 260 }} />
                  </Form.Item>
                  <div className={cx('input-suffix')}>元</div>
                </Space>
              )
            }
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default EPriceTimeFormModal;
