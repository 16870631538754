export enum BsaStatus {
  DRAFT,

  //阵列结构配置
  //完成阵列基础信息配置
  COMPLETE_ARRAY_BSA_INFO_CONFIG,
  //完成pcs配置
  COMPLETE_PCS_CONFIG,
  //完成电池堆配置
  COMPLETE_STACK_CONFIG,
  //完成电池簇配置
  COMPLETE_RACK_CONFIG,
  //完成电池组配置
  COMPLETE_PACK_CONFIG,
  //已完成阵列结构配配置
  COMPLETE_ARRAY_STRUCT_CONFIG,

  //阵列数采配置
  //完成全局数采配置
  COMPLETE_GLOBAL_DATA_COLLECTION_CONFIG,
  //完成电池簇数据点配置
  COMPLETE_RACK_DATA_POINT_CONFIG,
  //完成电池组数据点配置
  COMPLETE_PACK_DATA_POINT_CONFIG,
  //完成阵列数采配置
  COMPLETE_ARRAY_DATA_COLLECTION_CONFIG,

  ENABLE,
  DISABLE,
  Invalidation,
}

export enum Step {
  GLOBAL,
  RACK,
  PACK,
  CELL,
}

export enum ConfirmType {
  DRAFT = 0, // 存草稿
  NEXT = 1, // 下一步
}

export enum BatteryType {
  // PCS = 1,
  // STACK = 2,
  RACK = 3,
  PACK = 4,
  CELL = 5,
}

export enum PointType {
  COLLECTION = 1,
  VIRTUAL = 2,
  STATIC = 3,
}

export const identifierValidatorBuilder = (usedIdentifier?: string[]) => (rule: any, value: string) => {
  if (!value) {
    return Promise.reject('请填写非建模采集点标识符');
  }
  if (value.length > 30) {
    return Promise.reject('长度不能超过30个字符');
  }
  if (!/^[a-zA-Z]/.test(value)) {
    return Promise.reject(new Error('开头必须是英文字母，请修改'));
  }
  if (!/^[a-zA-Z][a-zA-Z0-9]*$/.test(value)) {
    return Promise.reject(new Error('只能输入英文和数字'));
  }
  if (usedIdentifier?.includes(value)) {
    return Promise.reject(new Error('标识符重复，请修改'));
  }
  return Promise.resolve();
};

export enum ColumnStatusEnum {
  NONE,
  EDIT,
  ADD,
}
