import React, { useEffect, useState } from 'react';
import {
  Button,
  CustomFilter,
  EllipsisSpan,
  Form,
  Input,
  Modal,
  Paging,
  Select,
  Table,
  usePaging,
} from '@maxtropy/components';
import { App, Space } from 'antd';
import { DeviceTreeProps, getDeviceTypeTree, getPVDevicePage } from '@/api/device';
import { useQuery } from 'react-query';
import styles from './index.module.scss';

export interface SelectDeviceModalProps {
  title?: string;
  loading?: boolean;
  initialDeviceId?: number;
  ouId: number;
  typeName: string;
  pvId: number;
  pvType: PVType;
  onOk: (deviceId: number) => void;
  onCancel: () => void;
}

export enum PVType {
  GRID_CONNECTION_POINT,
  CABINET,
  COMBINER_BOX,
  CONVERTER,
  STRING,
  MODULE,
}

interface FilterProps {
  codeOrName?: string;
  deviceTag?: string;
  deviceTypes?: number;
}

const SelectDeviceModal: React.FC<SelectDeviceModalProps> = ({
  title = '设备选择',
  loading,
  pvType,
  pvId,
  ouId,
  typeName,
  onOk,
  onCancel,
  initialDeviceId,
}) => {
  const [form] = Form.useForm();
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<FilterProps>();
  const [selectedDeviceId, setSelectedDeviceId] = useState<number>();
  const { message } = App.useApp();
  useEffect(() => {
    setSelectedDeviceId(initialDeviceId);
  }, [initialDeviceId]);

  const { data: deviceTypeList } = useQuery(['deviceTypeList', typeName], async () => {
    const res = await getDeviceTypeTree();
    return formatTreeData(res, typeName);
  });

  const { data, isLoading, refetch } = useQuery(
    ['devicePage', pageOffset, pageSize, searchParams, setTotalCount, deviceTypeList],
    async () => {
      if (ouId) {
        const res = await getPVDevicePage({
          ...searchParams,
          pvType,
          pvId,
          deviceTypes: searchParams?.deviceTypes ? [searchParams.deviceTypes] : deviceTypeList?.map(i => i.id),
          page: pageOffset,
          size: pageSize,
          ouId,
        });
        if (!res) return [];
        setTotalCount(res.total);
        return res.list;
      } else {
        return [];
      }
    },
    {
      enabled: !!deviceTypeList,
    }
  );

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedDeviceId(selectedRowKeys[0] as number);
    },
    selectedRowKeys: selectedDeviceId ? [selectedDeviceId] : undefined,
  };

  const onFinish = (v: FilterProps) => {
    setSearchParams({ ...v });
    setPageOffset(1);
    // noinspection JSIgnoredPromiseFromCall
    refetch();
  };

  const onReset = () => {
    const params = {
      codeOrName: undefined,
      tag: undefined,
      typeIds: undefined,
    };
    setSearchParams(params);
    setPageOffset(1);
  };

  const filters = (
    <CustomFilter colSpan={8} onFinish={v => onFinish(v as FilterProps)} form={form} onReset={onReset}>
      <Form.Item name="codeOrName" label="编号/名称">
        <Input placeholder="请输入编号或名称查询" />
      </Form.Item>
      <Form.Item label="一级类目">
        <Input value={typeName} disabled placeholder="请输入编号或名称查询" />
      </Form.Item>
      <Form.Item label="二级类目" name="deviceTypes">
        <Select placeholder="请选择">
          {deviceTypeList?.map(i => (
            <Select.Option value={i.id}>{i.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="设备标签" name="deviceTag">
        <Input placeholder="请输入" />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Modal
      title={title}
      open
      size="big"
      maskClosable={false}
      onCancel={onCancel}
      footer={
        <Space>
          <Button onClick={onCancel}>取消</Button>
          <Button
            type="primary"
            loading={loading}
            onClick={() => {
              if (!selectedDeviceId) {
                return message.error('请选择');
              }
              onOk(selectedDeviceId);
            }}
          >
            确定
          </Button>
        </Space>
      }
    >
      {filters}
      <div className={styles.table_text}>
        <span>
          点击前往
          <a href={`${(window as any).IOTPLATFORMORIGIN}/device/manage/device/create`} target="_blank" rel="noreferrer">
            添加设备
          </a>
        </span>
      </div>
      <Table
        loading={isLoading}
        sticky
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
        dataSource={data}
        rowKey="id"
        scroll={{ y: 300 }}
        columns={columns}
      />
      <Paging pagingInfo={pagingInfo} />
    </Modal>
  );
};

const columns = [
  {
    title: '设备编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备类目',
    dataIndex: 'deviceType',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备标签',
    dataIndex: 'deviceTags',
    ellipsis: { showTitle: true },
    render: (v: string[]) => <EllipsisSpan value={v && v.length > 0 ? v.join('、') : '--'} />,
  },
  {
    title: '所属组织',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export const formatTreeData = (tree: DeviceTreeProps[], typeName: string) => {
  let res: DeviceTreeProps[] = [];
  tree.forEach(i => {
    if (i.name === typeName) {
      res = res.concat(i.children ?? []);
    }
  });
  return res;
};

export default SelectDeviceModal;
