export const monthList = [
  {
    month: 1,
    monthName: '一',
  },
  {
    month: 2,
    monthName: '二',
  },
  {
    month: 3,
    monthName: '三',
  },
  {
    month: 4,
    monthName: '四',
  },
  {
    month: 5,
    monthName: '五',
  },
  {
    month: 6,
    monthName: '六',
  },
  {
    month: 7,
    monthName: '七',
  },
  {
    month: 8,
    monthName: '八',
  },
  {
    month: 9,
    monthName: '九',
  },
  {
    month: 10,
    monthName: '十',
  },
  {
    month: 11,
    monthName: '十一',
  },
  {
    month: 12,
    monthName: '十二',
  },
];
