import styles from './index.module.scss';
const Page404: React.FC = () => {
  return (
    <>
      <div className={styles.box}>404, 您访问的页面不存在</div>
    </>
  );
};

export default Page404;
