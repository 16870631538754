import { FC, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Radio } from '@maxtropy/components';
import { RadioChangeEvent, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { BatteryType, PointType } from '../const';
import { StackPointWithUuid, DataProperty } from './index';
import { TopologicalType } from '@/api/type';
import CollectionTable, { CollectionTableRefProps } from './CollectionTable';
import VirtualTable, { VirtualTableRefProps } from './VirtualTable';
import StaticTable, { StaticTableRefProps } from './StaticTable';

export type DataRow = Exclude<StackPointWithUuid['templates'], undefined>[number];

interface Props {
  stackId: number;
  stackPointList: StackPointWithUuid[];
  setStackPointList: (stackPointList: StackPointWithUuid[]) => void;
  topologicalType: TopologicalType;
  batteryType: BatteryType;
  dataPropertiesAll: DataProperty[];
  hasCreated: boolean;
}

const DataTable: FC<Props> = props => {
  const { stackId, stackPointList, setStackPointList, topologicalType, batteryType, dataPropertiesAll, hasCreated } =
    props;

  const [searchParams] = useSearchParams();
  const showSingleStep = searchParams.get('showSingleStep') === 'true';

  const [tabKey, setTabKey] = useState<PointType>(PointType.COLLECTION);

  const collectionTableRef = useRef<CollectionTableRefProps>(null);
  const virtualTableRef = useRef<VirtualTableRefProps>(null);
  const staticTableRef = useRef<StaticTableRefProps>(null);

  const renderTable = () => {
    if (tabKey === PointType.COLLECTION) {
      return (
        <CollectionTable
          stackId={stackId}
          topologicalType={topologicalType}
          batteryType={batteryType}
          stackPointList={stackPointList}
          setStackPointList={setStackPointList}
          dataPropertiesAll={dataPropertiesAll}
          hasCreated={hasCreated}
          ref={collectionTableRef}
        />
      );
    } else if (tabKey === PointType.VIRTUAL) {
      return (
        <VirtualTable
          stackId={stackId}
          topologicalType={topologicalType}
          batteryType={batteryType}
          stackPointList={stackPointList}
          setStackPointList={setStackPointList}
          dataPropertiesAll={dataPropertiesAll}
          hasCreated={hasCreated}
          ref={virtualTableRef}
        />
      );
    } else if (tabKey === PointType.STATIC) {
      return (
        <StaticTable
          stackId={stackId}
          stackPointList={stackPointList}
          setStackPointList={setStackPointList}
          topologicalType={topologicalType}
          batteryType={batteryType}
          dataPropertiesAll={dataPropertiesAll}
          hasCreated={hasCreated}
          ref={staticTableRef}
        />
      );
    }
    return null;
  };

  return (
    <>
      <Space style={{ marginBottom: 16 }}>
        <Radio.Group buttonStyle="solid" value={tabKey} onChange={(e: RadioChangeEvent) => setTabKey(e.target.value)}>
          <Radio.Button value={PointType.COLLECTION}>采集点</Radio.Button>
          <Radio.Button value={PointType.VIRTUAL}>虚拟点</Radio.Button>
          <Radio.Button value={PointType.STATIC}>静态点</Radio.Button>
        </Radio.Group>

        {tabKey === PointType.COLLECTION && (!hasCreated || showSingleStep) && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              collectionTableRef.current?.setEditingRowIndex(-1);
              collectionTableRef.current?.setEditingRow({} as any);
            }}
          >
            添加采集点批量创建公式
          </Button>
        )}

        {tabKey === PointType.VIRTUAL && (!hasCreated || showSingleStep) && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              virtualTableRef.current?.setEditingRowIndex(-1);
              virtualTableRef.current?.setEditingRow({} as any);
            }}
          >
            添加虚拟点批量创建公式
          </Button>
        )}

        {tabKey === PointType.STATIC && (!hasCreated || showSingleStep) && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              staticTableRef.current?.setEditingRowIndex(-1);
              staticTableRef.current?.setEditingRow({} as any);
            }}
          >
            添加静态点批量创建公式
          </Button>
        )}
      </Space>

      {renderTable()}
    </>
  );
};

export default DataTable;
