import { FC } from 'react';
import styles from './index.module.scss';
import { GwhFormat } from '../../utils';

export interface CustomStaticProps {
  title: string;
  parallelVal?: number;
  inverterVal?: number;
}

const CustomStatic: FC<CustomStaticProps> = props => {
  const { title, parallelVal, inverterVal } = props;

  return (
    <div className={styles.customStatic}>
      <div className={styles.indexInfo}>
        <div className={styles.indexName}>
          {title}-并网点{`(${GwhFormat(parallelVal).unit})`}
        </div>
        <div className={styles.indexVal}>{GwhFormat(parallelVal).value}</div>
      </div>
      <div className={styles.indexInfo}>
        <div className={styles.indexName}>
          {title}-逆变器{`(${GwhFormat(inverterVal).unit})`}
        </div>
        <div className={styles.indexVal}>{GwhFormat(inverterVal).value}</div>
      </div>
    </div>
  );
};

export default CustomStatic;
