import React, { FC, Key, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { Empty, Input, TenantType, Tree, useCurrent } from '@maxtropy/components';
import { SearchOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useRequest } from 'ahooks';
import { apiV2PvStationPvTreePost } from '@maxtropy/dmes-apis-v2';
import { DataNode } from 'antd/es/tree';
import tenantIcon from '@/assets/images/bsa/tenant.svg';
import orgIcon from '@/assets/images/bsa/org.svg';
import pvIcon from '@/assets/images/pvIcon.png';

interface IPvTree {
  onSelect?: (pvId: number, pvName: string, tenantMcid: string) => void;
  defaultPvId?: number;
}

const PvTree: FC<IPvTree> = props => {
  const { onSelect, defaultPvId } = props;
  const [searchText, setSearchText] = useState('');
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);

  const { data: treeData, loading } = useRequest(async () => {
    const res = await apiV2PvStationPvTreePost({});
    const list = res.list ?? [];
    const expandedKeys: string[] = [];
    let firstPv: any;
    const treeData: DataNode[] = list.map(tenantItem => {
      const level1Key = 'L1-' + tenantItem.tenantMcid!;
      expandedKeys.push(level1Key);
      return {
        key: level1Key,
        title: tenantItem.tenantName,
        icon: <img src={tenantIcon} alt="" />,
        selectable: false,
        children: (tenantItem.pvCustomerTree ?? []).map(customerItem => {
          const level2key = 'L2-' + customerItem.mcid!;
          expandedKeys.push(level2key);
          return {
            key: level2key,
            title: customerItem.customerName,
            icon: <img src={orgIcon} alt="" />,
            selectable: false,
            children: (customerItem.pvTree ?? []).map(pvItem => {
              if (!firstPv)
                firstPv = {
                  ...pvItem,
                  tenantMcid: tenantItem.tenantMcid,
                };
              return {
                key: pvItem.pvId!,
                title: pvItem.pvName,
                icon: <img src={pvIcon} alt="" style={{ width: 16, height: 13 }} />,
                info: {
                  ...pvItem,
                  tenantMcid: tenantItem.tenantMcid,
                },
              };
            }),
          };
        }),
      };
    });
    setExpandedKeys(expandedKeys);
    // 传了defaultPvId，则默认选中
    if (defaultPvId) {
      setSelectedKeys([defaultPvId]);
    }
    // 默认选中第一个光伏站
    if (!defaultPvId && firstPv) {
      setSelectedKeys([firstPv.pvId]);
      onSelect?.(firstPv.pvId, firstPv.pvName, firstPv.tenantMcid);
    }
    return treeData;
  });

  const filteredTreeData = useMemo(() => {
    return treeData?.map(tenantItem => {
      return {
        ...tenantItem,
        children: tenantItem.children?.map(customerItem => {
          return {
            ...customerItem,
            children: customerItem.children?.filter((pvItem: any) => {
              return pvItem.info.pvName.includes(searchText);
            }),
          };
        }),
      };
    });
  }, [searchText, treeData]);

  return (
    <div className={styles.pvTreeWrap}>
      <Input
        className={styles.searchInput}
        placeholder="请输入光伏站名称"
        suffix={<SearchOutlined />}
        value={searchText}
        onChange={e => {
          setSearchText(e.target.value);
        }}
      />

      <div className={styles.divider}></div>

      <Spin spinning={loading}>
        {treeData?.length ? (
          <Tree
            className={styles.tree}
            showIcon
            expandedKeys={expandedKeys}
            onExpand={setExpandedKeys}
            selectedKeys={selectedKeys}
            onSelect={(selectedKeys, e: any) => {
              if (!e.selected) return;
              setSelectedKeys(selectedKeys);
              onSelect?.(e.node.info.pvId, e.node.info.pvName, e.node.info.tenantMcid);
            }}
            treeData={filteredTreeData}
          />
        ) : (
          <Empty />
        )}
      </Spin>
    </div>
  );
};

export default PvTree;
