import { FileSearchOutlined } from '@ant-design/icons';
import { Empty, Paging, usePaging } from '@maxtropy/components';
import { apiV2NeoBsaGetBmsTabPost, apiV2NeoBsaRealTimeDataPost } from '@maxtropy/dmes-apis-v2';
import { useRequest } from 'ahooks';
import { Pagination, Space } from 'antd';
import { Key, useContext, useRef, useState } from 'react';
import { ShareContext } from '../..';
import { goToDeviceHistoryPage } from '../utils';
import CardItem from './CardItem';
import DeviceTabs from './DeviceTabs';
import styles from './index.module.scss';

const Battery = () => {
  const [tabActiveKey, setTabActiveKey] = useState<string>();

  const pagingInfo = usePaging(5);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const { bsaId, bsaType, dataType } = useContext(ShareContext);

  const { data: tabsList } = useRequest(
    () =>
      apiV2NeoBsaGetBmsTabPost({ bsaId, bsaType }).then(res => {
        if (res.list && res.list.length > 0) {
          // 设置默认值时, 因为是异步的,
          setTabActiveKey(String(res.list[0].objectId));
        } else {
          setTabActiveKey(undefined);
        }
        return res.list;
      }),

    {
      ready: !!bsaId,
      refreshDeps: [bsaId, bsaType],
    }
  );

  const { data, loading } = useRequest(
    () => {
      return apiV2NeoBsaRealTimeDataPost({
        bsaId,
        bsaType,
        dataType,
        objectId: tabActiveKey,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setTotalCount(res.total ?? 0);
        return res.list;
      });
    },
    {
      ready: !!bsaId && !!dataType && !!tabActiveKey,

      refreshDeps: [tabActiveKey, pageOffset, pageSize],
    }
  );

  return (
    <div className={styles.page_box}>
      <div className={styles.tabs_box}>
        {tabsList && tabsList.length > 0 ? (
          <DeviceTabs options={tabsList} activeKey={tabActiveKey} onChange={setTabActiveKey} />
        ) : (
          <></>
        )}
      </div>
      <div className={styles.line_box}>
        <Space style={{ color: 'var(--primary-color)' }}>
          <FileSearchOutlined />
          <span style={{ cursor: 'pointer' }} onClick={() => goToDeviceHistoryPage(undefined, 5008)}>
            电芯实时电压、温度数据点击此处查看
          </span>
        </Space>
      </div>
      <div className={styles.scroll_area}>
        {data?.length === 0 && (
          <div className={styles.empty_box}>
            <Empty />
          </div>
        )}
        <div className={styles.scroll_box}>
          {data?.map(item => (
            <CardItem key={item.deviceId} data={item} />
          ))}
        </div>
      </div>
      <div className={styles.pagination}>
        <Paging pagingInfo={pagingInfo} pageSizeOptions={['3', '5']} />
      </div>
    </div>
  );
};

export default Battery;
