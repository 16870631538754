import { FC, useEffect, useMemo, useRef } from 'react';

import { Breadcrumb, Button, Form, Modal, Radio, useBreadcrumbRoutes } from '@maxtropy/components';
import BorderWrapper from '../../../../components/BorderWrapper';

import BsaSteps from '../BsaSteps';
import BsaPageHeader from '../BsaPageHeader';

// import styles from './index.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BsaDeviceTypeEnum,
  BSAStatus,
  DriveType,
  DriveTypeLabels,
  ProcessStatusEnum,
  TopologicalType,
  TopologicalTypeLabels,
} from '../../../../api/type';
import DividerContent from '../DividerContent';
import { BsaComponentsProps, getNext, getPrevious } from '../../factory';
import { BsaType } from '../../../../api/constans';
import { getGlobalEdgeConfig, packPointComplete } from '../../../../api/edgeDevice';
import { saveDraft } from '../../../../api/bsa';

import { SubmitButton } from '../UtilsButton';
import StackTabs, { StackTabsRef } from '../StackTabs';

const formLayout = {
  labelCol: { flex: '170px' },
};

const BsaPackEdgePoint: FC<BsaComponentsProps & { editable?: boolean }> = ({
  disabled = false,
  typeList,
  foucsType,
  currentType,
  isDraft,
  status,
  updateFn,
  changeType,
  editable = false,
}) => {
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const [templateForm] = Form.useForm();

  const routesContext = useBreadcrumbRoutes();

  const routes = [{ name: `电池组数据点配置` }];
  const stackTabsRef = useRef<StackTabsRef>(null);

  const isInit = useMemo(() => {
    return (typeList ?? []).includes(BsaType.PACK_DAQ_POINT_CREATE);
  }, [typeList]);

  const isComplete = useMemo(() => {
    return (typeList ?? []).includes(BsaType.PACK_DAQ_POINT_COMPLETED);
  }, [typeList]);

  useEffect(() => {
    if (id) {
      getGlobalEdgeConfig(id).then(res => {
        templateForm.setFieldsValue({
          topologicalType: res.topologicalType,
          driveType: res.driveType,
        });
      });
    }
  }, [id, templateForm]);

  const onPrevious = () => {
    if (Array.isArray(typeList) && foucsType) {
      const type = getPrevious(foucsType, typeList);
      if (type) {
        changeType?.(type);
      }
    }
  };

  const onNext = () => {
    const type = getNext(foucsType!, typeList ?? [], currentType);
    if (type) {
      changeType?.(type);
    }
  };

  const onEditFinish = () => {
    const state = stackTabsRef.current?.updateTemplateState;
    if (state === ProcessStatusEnum.SUCCESS) {
      navigate('/energy-storage/basic/bsa');
    } else if (state === ProcessStatusEnum.GENERATING) {
      Modal.warning({
        title: '请先等待采集点更新完成',
      });
    }
  };

  const onFinish = () => {
    if (disabled) {
      onNext();
    } else {
      if (isComplete) {
        onNext();
      } else {
        if (currentType === BsaType.PACK_DAQ_POINT_COMPLETED) {
          packPointComplete(id).then(() => {
            updateFn?.();
          });
        } else if (currentType === BsaType.PACK_DAQ_POINT_CREATE) {
          Modal.warning({
            title: '请先创建采集点',
          });
        } else {
          Modal.warning({
            title: '请先等待采集点创建完成',
          });
        }
      }
    }
  };

  const onDraft = () => {
    stackTabsRef.current?.getAllPointsOnStack?.().then(res => {
      if (res) {
        saveDraft({
          bsaId: Number(id),
          content: JSON.stringify(res),
        }).then(() => {
          Modal.success({
            content: '保存成功',
          });
        });
      }
    });
  };

  return (
    <>
      <div className="page">
        <div className="page__header">
          <Breadcrumb routes={[...(routesContext?.routes ?? []), ...routes]} />
        </div>
        <BorderWrapper>
          <div className="page__content" style={{ minHeight: 'calc(100vh - 128px)' }}>
            <BsaPageHeader
              title="电池组数据点配置"
              extraContent={
                editable ? (
                  <>
                    <Button type="primary" onClick={onEditFinish}>
                      完成
                    </Button>
                  </>
                ) : (
                  <>
                    <Button type="primary" onClick={onPrevious}>
                      上一步
                    </Button>
                    {!typeList?.includes(BsaType.CELL_DEVICE_CREATED) &&
                    (currentType === BsaType.BSA_COMPLETED || currentType === null) ? (
                      disabled || !isDraft ? null : (
                        <SubmitButton
                          onOk={() => {
                            navigate('/energy-storage/basic/bsa');
                          }}
                        />
                      )
                    ) : (
                      <Button type="primary" onClick={onFinish}>
                        下一步
                      </Button>
                    )}
                    {!isInit && status !== BSAStatus.INVALID && (
                      <Button type="primary" onClick={onDraft}>
                        存草稿
                      </Button>
                    )}
                  </>
                )
              }
            >
              {!editable && <BsaSteps typeList={typeList} foucsType={foucsType} />}
            </BsaPageHeader>
            <Form form={templateForm} labelAlign="left" labelWrap {...formLayout}>
              <DividerContent>
                <Form.Item label="拓扑构型" name="topologicalType">
                  <Radio.Group buttonStyle="solid" disabled>
                    <Radio.Button value={TopologicalType.PCS_Penetrate_BMS}>
                      {TopologicalTypeLabels[TopologicalType.PCS_Penetrate_BMS]}
                    </Radio.Button>
                    <Radio.Button value={TopologicalType.BMS_SIMULTANEOUSLY_PCS_AND_BAMS}>
                      {TopologicalTypeLabels[TopologicalType.BMS_SIMULTANEOUSLY_PCS_AND_BAMS]}
                    </Radio.Button>
                    <Radio.Button disabled value={3}>
                      EMS同时连接PCS和BCMU
                    </Radio.Button>
                    <Radio.Button disabled value={4}>
                      EMS同时连接PCS和BMU
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="驱动类型" name="driveType">
                  <Radio.Group buttonStyle="solid" disabled>
                    <Radio.Button value={DriveType.MODBUS_TCP}>{DriveTypeLabels[DriveType.MODBUS_TCP]}</Radio.Button>
                    <Radio.Button disabled value={DriveType.IEC104}>
                      {DriveTypeLabels[DriveType.IEC104]}
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </DividerContent>
            </Form>
            <StackTabs
              bsaId={id}
              ref={stackTabsRef}
              disabled={disabled}
              typeList={typeList}
              bsaDeviceType={BsaDeviceTypeEnum.PACK}
              bsaTypeCreate={BsaType.PACK_DAQ_POINT_CREATE}
              bsaTypeCreated={BsaType.PACK_DAQ_POINT_CREATED}
              currentType={currentType}
              updateFn={updateFn}
              editable={editable}
            />
          </div>
        </BorderWrapper>
      </div>
    </>
  );
};

export default BsaPackEdgePoint;
