import { Table } from '@maxtropy/components';
import { useContext, useMemo } from 'react';
import { ShareContext } from '../..';
import { BsaDeviceType } from '../../type';
import CommonTable from './CommonTable';
import ConnectTable from './ConnectTable';

import styles from './index.module.scss';

const Meter = () => {
  const { realData } = useContext(ShareContext);
  const data = useMemo(
    () => realData?.filter(item => (item.bsaDeviceType as number) === BsaDeviceType.CONNECT),
    [realData]
  );
  return (
    <div className={styles.content}>
      {/* 进线电表、变压器电表整合 */}
      <CommonTable />
      {/* 并网点电表 */}
      <div className={styles.connect_table_box}>
        {data?.map(item => (
          <ConnectTable key={item.deviceId} data={item} />
        ))}
      </div>
    </div>
  );
};
export default Meter;
