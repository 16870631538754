import { addPvArea, editPvArea, PvAreaInfo } from '@/api/pv';
import { PVStatus } from '@/api/type';
import { App, Spin } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import { PvInfoContext } from '../..';
import { Form, Input, Modal } from '@maxtropy/components';
interface Iprops {
  visible: boolean;
  opType: string;
  row?: PvAreaInfo;
  listData?: PvAreaInfo[];
  onCancel?: () => void;
  onConfirm?: (v?: any) => void;
}

const AreaModal: React.FC<Iprops> = ({ visible = false, opType = 'add', onCancel, onConfirm, listData, row }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { pvId, pvStatus } = useContext(PvInfoContext);
  // 停用状态禁用片区编号
  const codeDisable = useMemo(() => pvStatus === PVStatus.DISABLE, [pvStatus]);
  const { message } = App.useApp();
  const onFinish = async (v: PvAreaInfo) => {
    if (!pvId) return;
    if (!checkRowUnique(row?.id, v.code, v.name)) return;
    setLoading(true);
    if (opType === 'add') {
      addPvArea(pvId, v)
        .then(_ => {
          onConfirm && onConfirm();
        })
        .finally(() => setLoading(false));
    } else {
      editPvArea(row?.pvStationId!, row?.id!, v)
        .then(_ => {
          onConfirm && onConfirm();
        })
        .finally(() => setLoading(false));
    }
  };
  // 校验编号/名称是否重复
  const checkRowUnique = (id?: number | string, code?: string, name?: string): boolean => {
    if (!listData) return true;
    let listSource = [...listData];
    if (opType === 'edit') {
      listSource = listData?.filter(v => v.id !== id);
    }
    let nameUnqiue = listSource?.some(v => v.name === name);
    if (nameUnqiue) {
      message.error('名称不能重复');
      return false;
    }
    let codeUnqiue = listSource?.some(v => v.code === code);
    if (codeUnqiue) {
      message.error('编号不能重复');
      return false;
    }
    return true;
  };
  const formLayout = {
    labelCol: { flex: '120px' },
  };
  // 编辑时反填表单信息
  useEffect(() => {
    if (opType === 'edit' && visible) {
      form.setFieldsValue({ ...row });
    }
  }, [opType, row, form, visible]);
  const title = useMemo(() => (opType === 'add' ? '添加片区' : '编辑片区'), [opType]);
  return (
    <Modal
      title={title}
      open={visible}
      destroyOnClose
      contentClassName="modal-form-content"
      onCancel={() => {
        onCancel && onCancel();
        form.resetFields();
      }}
      onOk={() => form.submit()}
    >
      <Spin spinning={loading}>
        <Form {...formLayout} form={form} preserve={false} onFinish={onFinish}>
          <Form.Item
            label="片区编号"
            name="code"
            rules={[
              { required: true, message: '请输入片区编号' },
              { max: 20, message: '最多20个字符' },
              { pattern: /^[A-Za-z0-9\\-]{1,20}$/, message: '仅能够输入数字、英文大小写和英文状态下的-' },
            ]}
          >
            <Input disabled={codeDisable} placeholder="请输入" maxLength={20} />
          </Form.Item>
          <Form.Item
            label="片区名称"
            name="name"
            rules={[
              { required: true, message: '片区名称' },
              { max: 20, message: '最多20个字符' },
            ]}
          >
            <Input placeholder="请输入" maxLength={20} />
          </Form.Item>
          <Form.Item label="片区地址" name="address" rules={[{ max: 50, message: '最多50个字符' }]}>
            <Input placeholder="请输入" maxLength={50} />
          </Form.Item>
          <Form.Item label="备注" name="remark" rules={[{ max: 50, message: '最多50个字符' }]}>
            <Input placeholder="请输入" maxLength={50} />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AreaModal;
