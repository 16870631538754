import React, { useContext, useMemo } from 'react';
import { PvForecastContext } from '../..';
import { useRequest } from 'ahooks';
import { apiV2BsaDataPredictElectricPost, V2BsaDataPredictElectricPostResponse } from '@maxtropy/dmes-apis-v2';
import tittleDecoration from '../imgs/tittleDecoration.png';
import styles from './index.module.scss';
import { Spin } from 'antd';
import { Empty } from '@maxtropy/components';
import ReactEcharts from 'echarts-for-react';
import { getChartOption } from './chart';
import ItemCard from './ItemCard';

export type predictDaySummaryItemDataProps = Exclude<
  V2BsaDataPredictElectricPostResponse['predictDaySummaryData'],
  undefined
>[number];

const ElecForecast = () => {
  const { stationId, date } = useContext(PvForecastContext);

  // 电量预测
  const { data: chartData, loading } = useRequest(
    () => {
      return apiV2BsaDataPredictElectricPost({
        predictDate: date?.valueOf(),
        pvStationId: stationId,
      });
    },
    {
      ready: !!stationId && !!date,
      refreshDeps: [stationId, date],
    }
  );

  // 判断三者是否都为空，如果都为空则显示empty
  const isShowEmpty = useMemo(() => {
    if (
      chartData?.absorptionPredictDataList?.length ||
      chartData?.absorptionRatePredictDataList?.length ||
      chartData?.netPredictDataList?.length ||
      chartData?.netPredictDataList?.length
    ) {
      return false;
    }
    return true;
  }, [chartData]);

  // 图表
  const chartOption = useMemo(() => getChartOption(chartData), [chartData]);

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <img src={tittleDecoration} alt="pic" className={styles.titleBorder} />
        <span className={styles.title}>电量预测</span>
      </div>
      <div className={styles.content_box}>
        <div className={styles.chart_box}>
          {loading ? (
            <Spin spinning={true} />
          ) : isShowEmpty ? (
            <Empty className={styles.empty_box} />
          ) : (
            <ReactEcharts style={{ height: 300 }} option={chartOption} notMerge lazyUpdate={false} />
          )}
        </div>
        <div className={styles.calc_box}>
          <div className={styles.center_line_box}>
            <div className={styles.center_line_box_dash}></div>
            <div className={styles.center_line_box_text}>电量预测统计</div>
            <div className={styles.center_line_box_dash}></div>
          </div>
          <div className={styles.card_box}>
            {chartData?.predictDaySummaryData?.length ? (
              chartData?.predictDaySummaryData?.map(item => <ItemCard key={item.date} itemData={item} />)
            ) : (
              <Empty className={styles.empty_box} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElecForecast;
