import React, { useEffect, useMemo, useState } from 'react';
import {
  BsaDataConfig,
  BsaDataPropertyResponse,
  DataConfigItem,
  getBsaDataProperties,
  getBsaDeviceDataProperties,
} from '@/api/bsaConfig';
import { DataProperty } from '@/api/type';
import { useParams } from 'react-router-dom';
import { Modal, Form, Input, InputNumber, Select } from '@maxtropy/components';

interface DataFormModalProps {
  onCancel: () => void;
  onOK: (changed: Partial<BsaDataConfig>) => void;
  name: string;
  config?: BsaDataConfig;
  dataKey: string;
  row?: DataConfigItem;
  deviceId?: number;
}

const DataFormModal: React.FC<DataFormModalProps> = ({ deviceId, dataKey, onCancel, onOK, name, row, config }) => {
  const [form] = Form.useForm();
  const [options, setOptions] = useState<DataProperty[]>([]);
  const { bsaId } = useParams<{ bsaId: string }>();

  useEffect(() => {
    if (deviceId) {
      getBsaDeviceDataProperties(+bsaId).then(data => setOptions(data[deviceId]));
    } else {
      getBsaDataProperties().then(data =>
        setOptions(data[(dataKey + 'DataProperties') as keyof BsaDataPropertyResponse])
      );
    }
  }, [bsaId, dataKey, deviceId]);

  const disabledItems = useMemo(() => {
    if (deviceId) {
      return (
        config?.deviceItems
          ?.find(item => item.deviceId === deviceId)
          ?.items.filter(item => item.dataPropertyId !== row?.dataPropertyId) || []
      );
    } else {
      return config?.typeItems?.filter(item => item.dataPropertyId !== row?.dataPropertyId) || [];
    }
  }, [config?.deviceItems, config?.typeItems, deviceId, row?.dataPropertyId]);

  useEffect(() => {
    if (row) {
      form.setFieldsValue({
        ...row,
      });
    }
  }, [form, row]);

  return (
    <Modal
      open
      size="large"
      title={`${name}`}
      maskClosable={false}
      onCancel={onCancel}
      onOk={() => {
        form.submit();
      }}
      contentClassName="modal-form-content"
    >
      <Form
        form={form}
        onFinish={values => {
          const sort = (a: DataConfigItem, b: DataConfigItem) => a.weight - b.weight;
          const dataProperty = options.find(option => option.id === values.dataPropertyId)!;
          const newItem = { ...values, dataPropertyName: dataProperty.name };
          if (row) {
            if (deviceId) {
              const newDeviceItems = [...(config?.deviceItems || [])];
              const device = newDeviceItems.find(item => item.deviceId === deviceId)!;
              device.items.splice(device.items.indexOf(row), 1, newItem);
              device.items = device.items.sort(sort);
              return onOK({ deviceItems: newDeviceItems });
            }
            const newTypeItems = [...(config?.typeItems || [])];
            newTypeItems.splice(newTypeItems.indexOf(row), 1, newItem);
            onOK({ typeItems: newTypeItems.sort(sort) });
          } else {
            if (deviceId) {
              const newDeviceItems = [...(config?.deviceItems || [])];
              const device = newDeviceItems.find(item => item.deviceId === deviceId)!;
              device.items = [...device.items, newItem].sort(sort);
              return onOK({ deviceItems: newDeviceItems });
            }
            const newTypeItems = [...(config?.typeItems || []), newItem].sort(sort);
            onOK({ typeItems: newTypeItems });
          }
        }}
      >
        <Form.Item
          name={'name'}
          label={'数据名称'}
          rules={[
            { required: true },
            { max: 20 },
            {
              validator: async (_, value) => {
                if (value && disabledItems.find(item => item.name === value)) {
                  return Promise.reject('数据名称重复');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder={'请输入'} />
        </Form.Item>
        <Form.Item
          name={'weight'}
          label={'显示权重'}
          rules={[
            { required: true },
            {
              validator: async (_, value) => {
                if (value && disabledItems.find(item => item.weight === value)) {
                  return Promise.reject('权重值重复');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber style={{ width: '100%' }} min={1} max={999} precision={0} placeholder={'请输入'} />
        </Form.Item>
        <Form.Item
          name={'dataPropertyId'}
          label={'数据属性'}
          rules={[
            { required: true },
            {
              validator: async (_, value) => {
                if (value && disabledItems.find(item => item.dataPropertyId === value)) {
                  return Promise.reject('数据属性重复');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Select
            placeholder={'请选择'}
            showSearch
            filterOption={(inputValue, option) => {
              return ((option?.children ?? '') as string).toLowerCase().includes(inputValue.toLowerCase());
            }}
          >
            {options
              .filter(option => !disabledItems.some(item => item.dataPropertyId === option.id))
              .map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DataFormModal;
