import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash-es';
import { StatisticsPartition } from './components/DateSwitch';

export const getTs = (mode: StatisticsPartition, start: Dayjs, end: Dayjs) => {
  switch (mode) {
    case StatisticsPartition.DAY:
      return {
        startTime: dayjs(start, 'x').startOf('day').valueOf(),
        endTime: dayjs(end, 'x').endOf('day').valueOf(),
      };
    default:
      return {
        startTime: dayjs(start, 'x').startOf('month').valueOf(),
        endTime: dayjs(end, 'x').endOf('month').valueOf(),
      };
  }
};

/**
 * 获取默认时间
 */
export const getDefaultTime = (mode: StatisticsPartition, date: Dayjs) => {
  switch (mode) {
    case StatisticsPartition.DAY:
      return {
        startTime: dayjs(date.subtract(1, 'day'), 'x').subtract(30, 'days').startOf('day').valueOf(),
        endTime: dayjs(date.subtract(1, 'day'), 'x').endOf('day').valueOf(),
      };
    default:
      return {
        startTime: dayjs(date, 'x').subtract(11, 'month').startOf('month').valueOf(),
        endTime: dayjs(date, 'x').endOf('month').valueOf(),
      };
  }
};

// 格式化
export const formateValues = (value?: number) => {
  return !isNil(value) ? Number(value.toFixed(2)).toLocaleString() : '--';
};

// 万元转换元
export const formateValuesToWanYuan = (value?: number) => {
  return !isNil(value)
    ? Number(value / 10000)
        .toFixed(2)
        .toLocaleString()
    : '--';
};
