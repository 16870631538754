import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Layout, Space, Spin } from 'antd';
import {
  Breadcrumb,
  Button,
  Empty,
  Modal,
  combineURL,
  useBreadcrumbRoutes,
  useCurrent,
  useUpdate,
} from '@maxtropy/components';
import { isNil } from 'lodash-es';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { DataNameType, DataType } from '../../api/const';
import { EPricePeakType, EPriceTime } from '../../api/electricityPrice';
import {
  CircuitStatisticsResponse,
  CircuitType,
  DevicesProps,
  getCircuitDataTypeList,
  getCircuitEnergyConfig,
  getCircuitStatistics,
  getCircuitStatisticsByDayOrMonth,
  getDevices,
  getEpricesByElectricityAccount,
  SortingCustomizeProps,
  StatisticsPartition,
  updateSortingCustomize,
} from '../../api/circuit';
import BorderWrapper from '../../components/BorderWrapper';
import HistoryTab from './components/HistoryTab';
import CompareModal from './components/CompareModal';
import Card from './components/CardForData';
import Tree, { CircuitTreeLeafInfo2 } from './components/Tree';
import DateSwitch, { DatePickerType } from './components/DateSwitch';
import Chart from './components/Chart';
import EPriceTimesLegend from './components/EPriceTimesLegend';
import circuitBtns, { CircuitBtn, getCardData } from './config';
import { getAllDates, getDefaultTime, getEPriceTimesByDay, sum } from './utils';
import styles from './index.module.scss';
import qs from 'qs';
import DeviceModalList from './components/DeviceListModal';
import CircuitLineLossContent from '@/pages/Statistics/CircuitLineLoss';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import SortModalList, { SortModalListModalRef } from './components/SortModalList';
import { useHasPermission } from '@/utils/hooks';
import { PermissionsType } from '@/common/permissionsConst';
import DragResize from '@/components/DragResize';
import classnames from 'classnames/bind';
import { useLocation } from 'react-router-dom';
const cx = classnames.bind(styles);
const { Content, Sider } = Layout;

interface HistoryDataProps {}

export interface Query {
  dataType: DataType;
  btnType: DatePickerType;
  aggrby: StatisticsPartition;
  rangeDays?: number;
  name: DataNameType | DataNameType[];
  tsStart: number;
  tsEnd: number;
}

export interface ChartData extends CircuitStatisticsResponse {
  capacity?: number | null;
  circuitName?: string;
  name?: string;
}

const CIRCUIT_LINE_LOSS_ID = 99;

const HistoryData: React.FC<HistoryDataProps> = () => {
  const routesContext = useBreadcrumbRoutes();

  const [activeKey, setActiveKey] = useState<string>();
  const [query, setQuery] = useState<Query>();
  const [currentItem, setCurrentItem] = useState<CircuitBtn>();

  const [ePriceTimes, setEPriceTimes] = useState<EPriceTime[]>();
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState<ChartData[]>();
  const [cardData, setCardData] = useState<any>();
  const [deviceList, setDeviceList] = useState<DevicesProps[]>([]);

  const [compareModalVisible, setCompareModalVisible] = useState<boolean>(false);
  const [circuit, setCircuit] = useState<CircuitTreeLeafInfo2>();

  const [deviceListVisible, setDeviceListVisible] = useState<boolean>(false);
  const [sortModalOpen, setSortModalOpen] = useState<boolean>(false);
  const [updateState, updateFn] = useUpdate();
  const [currentCircuitHasNoOu, setCurrentCircuitHasNoOu] = useState<boolean>(false);
  const hasToPoPermission = useHasPermission(PermissionsType.B_CIRCUITTOPOLOGY);

  const staffId = useCurrent()?.staff?.id;

  const sortModalListModalRef = useRef<SortModalListModalRef>(null);
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const time = urlSearchParams.get('time');

  const [flag, setFlag] = useState(false); // 控制所选tab的key在第一次选择回路的时候选第一个指标，因为是异步请求tabData
  const [flag2, setFlag2] = useState(false); // 控制 在切换Tab的时候记住 时间选择与颗粒度选择

  const { data: tabData } = useQuery(['dataTypeList', circuit, updateState], async () => {
    if (circuit?.circuitId) {
      let id = circuit.circuitId;
      const res = await getCircuitDataTypeList(id);
      if (res && res.length > 0 && !flag) {
        setFlag(true);
        // res.push({ id: CIRCUIT_LINE_LOSS_ID, name: "线损" });
        setActiveKey(`${res[0].id}`);
      }
      return res;
    }
  });

  const circuitName = useMemo(() => {
    return circuit?.name;
  }, [circuit]);

  const { data: circuitEnergyConfig } = useQuery(['circuitEnergyConfigData', circuit], async () => {
    if (circuit && circuit.circuitId && (circuit.voltageLevel || circuit.voltageLevelIn)) {
      const res = await getCircuitEnergyConfig(circuit.circuitId);
      let finalRes = {
        ...res,
        lineVoltageUpper: res.lineVoltageUpper,
        lineVoltageLower: res.lineVoltageLower,
        phaseVoltageUpper: res.phaseVoltageUpper,
        phaseVoltageLower: res.phaseVoltageLower,
      };
      if (!finalRes) return {};
      return finalRes;
    }
  });

  const isLoadRateAndGridBillingCircuit = useMemo(() => {
    return (
      query && query.dataType === DataType.LOAD_RATE && circuit && circuit.type !== CircuitType.GRID_BILLING_CIRCUIT
    );
  }, [query, circuit]);

  useEffect(() => {
    // 回路数据页面设配992px
    const body = document.querySelector('body');
    body!.style.minWidth = '992px';
    return () => {
      body!.style.minWidth = '1366px';
    };
  }, []);

  useEffect(() => {
    const target = circuitBtns.find(item => item.id === Number(activeKey));
    if (target) {
      const { aggrby, btnType, rangeDays } = target.defaultSelectBtn;
      const defaultQuery = {
        dataType: Number(activeKey),
        btnType,
        aggrby,
        rangeDays,
        name: target.name,
        ...getDefaultTime(aggrby, dayjs(), time),
      };
      // 第一次进来的时候有个默认选择
      if (!flag2) {
        setQuery(defaultQuery);
        setFlag2(true);
      } else {
        // 之后都是这个选择（记住之前所选）,若切换的指标无相应颗粒度，则展示默认颗粒度和时间范围。
        if (query) {
          setQuery({
            dataType: Number(activeKey),
            btnType: target.dateBtn.map(i => i.btnType).includes(query.btnType) ? query.btnType : btnType,
            aggrby: target.dateBtn.map(i => i.aggrby).includes(query.aggrby) ? query.aggrby : aggrby,
            rangeDays,
            name: target.name,
            tsStart: target.dateBtn.map(i => i.aggrby).includes(query.aggrby)
              ? query.tsStart
              : getDefaultTime(aggrby, dayjs()).tsStart,
            tsEnd: target.dateBtn.map(i => i.aggrby).includes(query.aggrby)
              ? query.tsEnd
              : getDefaultTime(aggrby, dayjs()).tsEnd,
          });
        }
      }
      setCurrentItem(target);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey, time]);

  useEffect(() => {
    if (circuit && !isNil(circuit.circuitId) && query) {
      // getAllEprices(circuit.circuitId).then(res => {
      //   console.log('query', query);
      //   const dates = getAllDates(query.tsStart, query.tsEnd);
      //   console.log(getEPriceTimes(dates, res));
      //   setEPriceTimes(getEPriceTimes(dates, res));
      // });
      getEpricesByElectricityAccount({
        circuitId: circuit.circuitId,
        from: query.tsStart,
        to: query.tsEnd,
      }).then(res => {
        const dates = getAllDates(query.tsStart, query.tsEnd);
        setEPriceTimes(getEPriceTimesByDay(dates, res));
      });
    }
  }, [circuit, query]);

  useEffect(() => {
    (async () => {
      if (circuit && !isNil(circuit.circuitId) && query) {
        if (isLoadRateAndGridBillingCircuit) {
          return;
        }
        setLoading(true);
        const response = await getCircuitStatistics({
          circuitId: circuit.circuitId,
          dataType: query.dataType,
          timeResolution: query.aggrby,
          from: query.tsStart,
          to: query.tsEnd,
        });
        const data = response.map((item, i) => ({
          ...item,
          capacity: circuit?.capacity,
          id: query.dataType === DataType.LOAD_RATE ? DataNameType.LOAD_RATE : item.id,
          data: item.data.map(item => ({
            ...item,
            value: !isNil(item.value) ? (i === 1 && query.dataType === DataType.PE ? -item.value : +item.value) : null,
          })),
        }));
        setChartData(data);
        setLoading(false);
      }
    })().catch(e => console.error(e));
  }, [circuit, query, isLoadRateAndGridBillingCircuit]);

  // 电量按日按月计算尖峰平谷（新接口）
  useEffect(() => {
    if (query && circuit && chartData) {
      if (
        query.dataType === DataType.EP &&
        (query.btnType === DatePickerType.DAY || query.btnType === DatePickerType.MONTH)
      ) {
        getCircuitStatisticsByDayOrMonth({
          circuitId: circuit.circuitId,
          from: query.tsStart,
          to: query.tsEnd,
        }).then(res => {
          setCardData({
            totalEP: sum(chartData[0]?.data?.map(item => item.value)),
            [EPricePeakType.SUMMIT]: !isNil(res.summit) ? res.summit : '--',
            [EPricePeakType.PEAK]: !isNil(res.peak) ? res.peak : '--',
            [EPricePeakType.PLAIN]: !isNil(res.plain) ? res.plain : '--',
            [EPricePeakType.VALLEY]: !isNil(res.valley) ? res.valley : '--',
          });
        });
      }
    }
  }, [query, circuit, chartData]);

  useEffect(() => {
    if (currentItem && query && chartData) {
      //如果电量不是按日按月计算尖峰平谷，那就走原来逻辑
      if (
        !(
          query.dataType === DataType.EP &&
          (query.btnType === DatePickerType.DAY || query.btnType === DatePickerType.MONTH)
        )
      ) {
        const data = getCardData({ chartData, ePriceTimes, query, circuitEnergyConfig });
        setCardData(data);
      }
    }
  }, [chartData, currentItem, ePriceTimes, query, circuitEnergyConfig]);

  const changeTreeSelect = (value: React.Key[], info: CircuitTreeLeafInfo2) => {
    if (info.hasOu) {
      setCircuit(info);
      setCurrentCircuitHasNoOu(false);
    } else {
      setCircuit(undefined);
      setCurrentCircuitHasNoOu(true);
    }
  };

  const onChangeData = (value: Omit<Query, 'dataType' | 'name'>) => {
    if (query) {
      setQuery({
        dataType: query.dataType,
        name: query.name,
        ...value,
      });
    }
  };

  const exportAction = () => {
    if (circuit && query) {
      const params = {
        dataType: query.dataType,
        timeResolution: query.aggrby,
        circuitId: circuit.circuitId,
        from: query.tsStart,
        to: query.tsEnd,
      };
      window.open(`/api/circuit/data/export?${qs.stringify(params, { indices: false })}`);
    }
  };

  const onClickGranularity = () => {
    getDevices(circuit!.circuitId).then(data => {
      if (data.length > 1) {
        setDeviceList(data);
        setDeviceListVisible(true);
      } else {
        window.open(
          `${combineURL(
            window.IOTPLATFORMORIGIN,
            `/data/history/device?deviceId=${data[0].id}&dataType=${query?.dataType}`
          )}`,
          '_blank'
        );
      }
    });
  };

  const [openSider, setOpenSider] = useState(true);

  // 排序点击保存进行提交数据
  const onOk = () => {
    let formatterTabList: SortingCustomizeProps[] = [];
    sortModalListModalRef.current?.tabDataList.forEach((item, index) => {
      formatterTabList.push({
        dataType: item.id,
        sequenceNumber: index + 1,
      });
    });
    updateSortingCustomize(formatterTabList, staffId!)
      .then(_ => {
        setSortModalOpen(false);
        updateFn();
      })
      .finally(() => {
        setSortModalOpen(false);
      });
  };
  useEffect(() => {}, []);

  // 初始值
  const [siderWidth, setSiderWidth] = useState(260);
  // 拖拽状态
  const [dragStatus, setDragStatus] = useState(false);

  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };
  return (
    <div className="page">
      <Breadcrumb routes={routesContext?.routes} />
      <BorderWrapper>
        <Layout className={styles.layout}>
          <Sider
            theme="light"
            width={openSider ? siderWidth : 0}
            style={{ transition: dragStatus ? 'none' : 'all 0.2s' }}
          >
            <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
            <Tree style={{ width: '100%' }} changeTreeSelect={changeTreeSelect} />
          </Sider>

          <Content className={styles.content}>
            {!circuit && !currentCircuitHasNoOu ? (
              <Empty description={'暂无数据'} className={styles.empty} />
            ) : currentCircuitHasNoOu ? (
              <Empty description={'暂无数据权限'} className={styles.empty} />
            ) : (
              <>
                <div
                  className={styles.toggleBtn}
                  style={{ left: openSider ? -30 : 0 }}
                  onClick={() => setOpenSider(!openSider)}
                >
                  {openSider ? <LeftOutlined /> : <RightOutlined />}
                </div>
                <div className={styles.tab_box}>
                  <HistoryTab
                    tabData={tabData}
                    onTabChange={v => setActiveKey(v)}
                    activeKey={activeKey}
                    tabBarExtraContent={{
                      right: tabData ? (
                        <Button type="link" onClick={() => setSortModalOpen(true)}>
                          排序
                        </Button>
                      ) : null,
                    }}
                  />
                </div>
                {Number(activeKey) === CIRCUIT_LINE_LOSS_ID ? (
                  <CircuitLineLossContent circuit={circuit} />
                ) : !isNil(circuit?.circuitId) && !isLoadRateAndGridBillingCircuit ? (
                  <div className={styles.tabContent}>
                    <div className={styles.filter}>
                      {query && (
                        <DateSwitch
                          btnGroup={currentItem?.dateBtn}
                          selectBtn={query.btnType}
                          rangeDays={query.rangeDays}
                          aggrby={query.aggrby}
                          value={[dayjs(query.tsStart, 'x'), dayjs(query.tsEnd, 'x')]}
                          onQueryChange={onChangeData}
                        />
                      )}
                      {query && chartData && query.dataType !== DataType.LOAD_RATE && (
                        <span className={cx('spanCursor', 'hidden')} onClick={onClickGranularity}>
                          采集颗粒度
                        </span>
                      )}
                      <div className={styles.commonBtn}>
                        <Space>
                          <Button type="primary" onClick={exportAction}>
                            导出
                          </Button>
                          <Button type="primary" onClick={() => setCompareModalVisible(true)}>
                            对比
                          </Button>
                          {hasToPoPermission && (
                            <Button
                              type="primary"
                              onClick={() => window.open(`/dmes/circuit/topologicalGraph`, '_blank')}
                            >
                              拓扑图
                            </Button>
                          )}
                        </Space>
                      </div>
                    </div>
                    <Spin spinning={loading}>
                      <div
                        className={
                          (currentItem?.cardData ?? []).length > 3
                            ? styles.cardContainerMoreThanTree
                            : styles.cardContainer
                        }
                      >
                        {currentItem?.cardData.map(card => (
                          <div className={styles.cardItem} key={card.cardName}>
                            <Card name={card.cardName} value={cardData?.[card.key]} unit={card.unit} />
                          </div>
                        ))}
                      </div>

                      <div
                        className={
                          (currentItem?.cardData ?? []).length > 0 ? styles.chartContainer : styles.moreChartContainer
                        }
                      >
                        <EPriceTimesLegend
                          circuitEnergyConfig={circuitEnergyConfig}
                          query={query}
                          chartData={chartData}
                          ePriceTimes={ePriceTimes}
                        />
                        <div className={styles.circuitName}>当前回路名称: {circuitName}</div>

                        {query && chartData && (
                          <div className={styles.chart_box}>
                            <Chart
                              loading={loading}
                              chartData={chartData}
                              option={Object.assign(
                                {},
                                currentItem?.getChartOption({
                                  chartData,
                                  query,
                                  unit: currentItem?.unit,
                                  circuitEnergyConfig,
                                  ePriceTimes:
                                    (query?.btnType === DatePickerType.ORIGINAL ||
                                      query?.btnType === DatePickerType.THIRTYMINUTES ||
                                      query?.btnType === DatePickerType.ONEMINUTE) &&
                                    Array.isArray(ePriceTimes) &&
                                    ePriceTimes.length > 0
                                      ? ePriceTimes
                                      : undefined,
                                })
                              )}
                            />
                          </div>
                        )}
                      </div>
                    </Spin>
                  </div>
                ) : (
                  <div className={styles.position_center}>
                    <Empty
                      description={isLoadRateAndGridBillingCircuit ? '回路无变压器容量，暂无负载率曲线' : '暂无数据'}
                    />
                  </div>
                )}
              </>
            )}
          </Content>
        </Layout>
      </BorderWrapper>
      {compareModalVisible && (
        <CompareModal
          visible={compareModalVisible}
          onCancel={() => setCompareModalVisible(false)}
          tabData={tabData}
          defaultValue={{
            ...circuit,
            cabinetId: circuit?.distributionCabinetId,
            circuitName: circuit?.distributionCabinetName,
            dataType: query?.dataType,
          }}
        />
      )}
      {deviceListVisible && (
        <DeviceModalList
          visible={deviceListVisible}
          onCancel={() => setDeviceListVisible(false)}
          dataType={query?.dataType}
          list={deviceList}
        />
      )}
      {sortModalOpen && (
        <Modal
          size="normal"
          open={sortModalOpen}
          onCancel={() => setSortModalOpen(false)}
          onOk={onOk}
          okText="保存"
          title="排序"
        >
          <SortModalList ref={sortModalListModalRef} tabData={tabData} />
        </Modal>
      )}
    </div>
  );
};

export default HistoryData;
