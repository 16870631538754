import {
  ChargingStationPageReq,
  ChargingStationPageRes,
  ChargingStationStatus,
  chargingStationStatusFormat,
  getChargingStationPage,
  updateChargingStationStatus,
} from '@/api/chargingStationManagement';
import { fetchOuList, getOuId } from '@/api/ou';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/hooks';
import {
  Button,
  EllipsisSpan,
  Paging,
  useBreadcrumbRoutes,
  useSearchParams,
  useUpdate,
  Wrapper,
  Form,
  Input,
  PopConfirm,
  Select,
  Table,
  CustomFilter,
} from '@maxtropy/components';
import { Space } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import CostModalForm from './components/CostModalForm';
import { StationType } from '@/pages/SiteAccess';
import styles from './index.module.scss';

export enum ActionType {
  UPDATE = 'edit',
  DETAIL = 'detail',
}

export const ActionTypeDisplay = {
  [ActionType.UPDATE]: '编辑',
  [ActionType.DETAIL]: '查看',
};

export interface SearchParams extends Omit<ChargingStationPageReq, 'pageNum' | 'pageSize'> {}

const columns: Array<ColumnType<ChargingStationPageRes>> = [
  {
    title: '充电站编号',
    dataIndex: 'code',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '充电站名称',
    dataIndex: 'name',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '装机容量',
    dataIndex: 'installedCapacity',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={!isNil(v) ? Number(v).toFixed(3) + 'MW' : '--'} />,
  },
  {
    title: '运营单元',
    dataIndex: 'ouName',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: ChargingStationStatus) => <EllipsisSpan value={!isNil(v) ? chargingStationStatusFormat[v] : '--'} />,
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />,
  },
  {
    title: '创建人',
    dataIndex: 'createUsername',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后编辑时间',
    dataIndex: 'updateTime',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />,
  },
  {
    title: '最后编辑人',
    dataIndex: 'updateByUsername',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const ChargingStationManagement = () => {
  const hasBChargeViewCost = useHasPermission(PermissionsType.B_CHARGINGVIEWCOST);
  const hasBChargeEditCost = useHasPermission(PermissionsType.B_CHARGINGEDITCOST);
  const hasBChargeStatus = useHasPermission(PermissionsType.B_CHARGINGSTATUSCHANGE);
  const hasBChargeEdit = useHasPermission(PermissionsType.B_CHARGINGEDIT);
  const hasBChargeView = useHasPermission(PermissionsType.B_CHARGINGVIEW);
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [searchForm] = Form.useForm();
  const pagingInfo = useSearchParams<SearchParams>(20);
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ChargingStationPageRes[]>([]);
  const [updateState, updateFn] = useUpdate();
  const [row, setRow] = useState<ChargingStationPageRes>();
  const [showModal, setShowModal] = useState<ActionType | undefined>();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getChargingStationPage({
      ...searchParams,
      page: searchParams.pageOffset,
      size: searchParams.pageSize,
    })
      .then(res => {
        setData(res.list);
        setTotalCount(res.total);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, setTotalCount, updateState]);

  const { data: ouList } = useQuery('ouList', async () => {
    const ouType = (await getOuId())?.filter((i: any) => i.key === 'MICRONET')[0]?.id;
    if (!ouType) return [];
    return fetchOuList(ouType);
  });

  // ou下拉列表
  const ouOptions = useMemo(() => {
    if (ouList && ouList.length !== 0) {
      return ouList.map((i: any) => ({ label: i.name, value: i.id }));
    }
  }, [ouList]);

  const filters = (
    <CustomFilter form={searchForm} onFinish={(val: SearchParams) => finish({ ...val })} onReset={() => reset()}>
      <Form.Item name="ouId" label="运营单元">
        <Select placeholder="请选择" options={ouOptions} showSearch optionFilterProp="label" />
      </Form.Item>
      <Form.Item name="name" label="充电站名称">
        <Input placeholder=" 请输入" />
      </Form.Item>
    </CustomFilter>
  );

  const disOrEnableChargingStation = (status: ChargingStationStatus, id: number) => {
    updateChargingStationStatus(
      id,
      status === ChargingStationStatus.DISABLE ? ChargingStationStatus.ENABLE : ChargingStationStatus.DISABLE
    ).then(_ => {
      updateFn();
    });
  };
  const changeShowModal = (actionType?: ActionType) => {
    setShowModal(actionType);
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as const,
      width: 410,
      render: (_: any, record: ChargingStationPageRes) => (
        <Space size={16}>
          {hasBChargeViewCost && (
            <Button
              type="link"
              onClick={() => {
                setRow(record);
                setShowModal(ActionType.DETAIL);
              }}
            >
              查看成本信息
            </Button>
          )}
          {hasBChargeEditCost && (
            <Button
              type="link"
              disabled={record.status === ChargingStationStatus.ENABLE}
              onClick={() => {
                setRow(record);
                setShowModal(ActionType.UPDATE);
              }}
            >
              编辑成本信息
            </Button>
          )}
          {hasBChargeStatus && (
            <PopConfirm
              title={
                record.status === ChargingStationStatus.DISABLE
                  ? '启用后无法编辑，是否启用？'
                  : '停用后，充电站信息不再更新！你确认停用吗？'
              }
              onConfirm={() => disOrEnableChargingStation(record.status, record.id)}
              okText="确定"
              cancelText="取消"
            >
              <Button type="link">
                <span style={{ color: record.status === ChargingStationStatus.DISABLE ? 'unset' : 'red' }}>
                  {record.status === ChargingStationStatus.DISABLE
                    ? chargingStationStatusFormat[ChargingStationStatus.ENABLE]
                    : chargingStationStatusFormat[ChargingStationStatus.DISABLE]}
                </span>
              </Button>
            </PopConfirm>
          )}
          {hasBChargeView && (
            <Link to={`/device/chargingStation/management/detail/${record.id}`}>
              <Button type="link">查看</Button>
            </Link>
          )}
          {hasBChargeEdit && (
            <Link to={`/device/chargingStation/management/edit/${record.id}`}>
              <Button disabled={record.status === ChargingStationStatus.ENABLE} type="link">
                编辑
              </Button>
            </Link>
          )}
          <Button
            type="link"
            onClick={() => {
              navigate(
                `/device/chargingStation/management/startingTimeOfStorage?id=${record.id}&type=${StationType.CHARGING_STATION}`
              );
            }}
          >
            设置站点起算时间
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} filters={filters} className={styles.wrapper}>
      <Table
        rowKey="id"
        sticky
        scroll={{ x: 1300 }}
        loading={loading}
        columns={buildColumns}
        dataSource={data}
        pagination={false}
      />
      <Paging pagingInfo={pagingInfo} />
      <CostModalForm row={row} showModal={showModal} changeShowModal={changeShowModal} updateFn={updateFn} />
    </Wrapper>
  );
};

export default ChargingStationManagement;
