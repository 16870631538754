import BorderWrapper from '@/components/BorderWrapper';
import Tree, { CircuitTreeLeafInfo2 } from '../Tree';

import { FullscreenExitOutlined, FullscreenOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Breadcrumb, useBreadcrumbRoutes, Empty } from '@maxtropy/components';
import { Layout } from 'antd';
import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import { CircuitType } from '@/api/circuit';
import { enterFullScreen, exitFullScreen, initFullScreen } from './full';
import TopologicalGraph from '../TopologicalGraph';
import DetailDrawer from '@/pages/CircuitManage/components/CircuitTopologicalGraghTree/DetailDrawer';
import DragResize from '@/components/DragResize';

const { Content, Sider } = Layout;

export interface expandCircuitTreeLeafInfo2 extends CircuitTreeLeafInfo2 {
  children: expandCircuitTreeLeafInfo2[];
}

export interface graphsProps {
  id: string;
  value: {
    name: string;
    type: CircuitType;
    capacity?: number | null;
    hasOu?: boolean;
  };
  children: graphsProps[];
}

export const ColorTypeProps = {
  [CircuitType.GRID_BILLING_CIRCUIT]: '#FF0000',
  [CircuitType.OUTGOING_CIRCUIT]: '#FEFF01',
  [CircuitType.INCOMING_CIRCUIT]: '#00ADFF',
  [CircuitType.BUS_COUPLER_CIRCUIT]: '#028001',
  [CircuitType.TRANSFORMER_CIRCUIT]: '#FE45FF',
};
type Timer = NodeJS.Timeout | undefined;

const CircuitTopologicalGraghTree: FC = () => {
  const [openSider, setOpenSider] = useState<boolean>(true);
  const [siderWidth, setSiderWidth] = useState(260);
  const [dragStatus, setDragStatus] = useState(false);
  const [circuit, setCircuit] = useState<graphsProps>();
  const [selectedId, setSelectedId] = useState<number>();

  const [circuitDetailVisible, setCircuitDetailVisible] = useState<boolean>(false);
  const [currentCircuitInfo, setCurrentCircuitInfo] = useState<any>();

  const routesContext = useBreadcrumbRoutes();

  const elementWrapperRef = useRef<HTMLDivElement>(null);

  // canvas样式
  const ref = useRef<any>(null);

  // 全屏
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [flag, setFlag] = useState<boolean>(true);

  const timer = useRef<Timer>();

  const pageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  // 为了显示右侧拓扑图
  const cusTree = (tree?: expandCircuitTreeLeafInfo2[]): graphsProps[] => {
    if (!tree) return [];
    return tree.map(item => {
      return {
        id: String(item.id),
        value: {
          name: item.name,
          type: item.type,
          capacity: item.capacity,
          hasOu: item.hasOu,
        },
        children: item.children ? cusTree(item.children) : [],
      };
    });
  };

  const changeTreeSelect = (value: React.Key[], info: expandCircuitTreeLeafInfo2) => {
    setFlag(false);
    if (info) {
      setSelectedId(value[0] as number);
      let newInfo: any = {
        id: String(info.circuitId),
        value: {
          name: info.name,
          type: info.type,
          capacity: info.capacity,
          hasOu: info.hasOu,
        },
      };
      if (info.children && info.children.length) {
        const transFormData = cusTree(info.children);
        newInfo.children = transFormData;
      }
      setCircuit(newInfo);
      timer.current = setTimeout(() => {
        setFlag(true);
      }, 0);
    }
  };

  //全屏
  const handleFullScreen = () => {
    setFlag(false);
    if (isFullScreen) {
      exitFullScreen();
    } else {
      enterFullScreen(ref.current);
    }
    setIsFullScreen(!isFullScreen);
    timer.current = setTimeout(() => {
      setFlag(true);
    }, 0);
  };

  const screenChange = (isFull: boolean) => {
    setIsFullScreen(isFull);
  };

  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  useEffect(() => {
    //初始化
    initFullScreen(screenChange);
  }, []);

  return (
    <div className="page" ref={pageRef}>
      <DetailDrawer
        open={circuitDetailVisible}
        setOpen={setCircuitDetailVisible}
        currentCircuitInfo={currentCircuitInfo}
      />
      <div className="page__header">
        <Breadcrumb routes={[...(routesContext?.routes ?? [])]} />
      </div>
      <BorderWrapper>
        <Layout className={styles.layout}>
          <Sider
            theme="light"
            width={openSider ? siderWidth : 0}
            style={{ transition: dragStatus ? 'none' : 'all 0.2s', background: '#232324' }}
          >
            <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
            <Tree changeTreeSelect={changeTreeSelect} />
          </Sider>

          <Content className={styles.content}>
            <div
              className={styles.toggleBtn}
              style={{ left: openSider ? -30 : 0 }}
              onClick={() => setOpenSider(!openSider)}
            >
              {openSider ? <LeftOutlined /> : <RightOutlined />}
            </div>

            {circuit ? (
              <div
                ref={ref}
                style={{
                  position: 'relative',
                  height: '100%',
                  width: '100%',
                  backgroundColor: 'rgb(31, 31, 31)',
                  overflow: 'auto',
                }}
              >
                <div
                  ref={elementWrapperRef}
                  style={{
                    width: '100%',
                    height: 50,
                    backgroundColor: 'rgb(31, 31, 31)',
                  }}
                />
                <div onClick={handleFullScreen} className={styles.fullScreen}>
                  {isFullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                  {isFullScreen ? '退出全屏' : '全屏查看'}
                </div>

                {flag && (
                  <TopologicalGraph
                    circuit={circuit}
                    focusId={selectedId}
                    setCurrentCircuitInfo={setCurrentCircuitInfo}
                    setCircuitDetailVisible={setCircuitDetailVisible}
                  />
                )}
              </div>
            ) : (
              <Empty description={'暂无数据'} style={{ marginTop: 120 }} />
            )}
          </Content>
        </Layout>
      </BorderWrapper>
    </div>
  );
};

export default CircuitTopologicalGraghTree;
