import React, { useContext, useEffect, useRef, useImperativeHandle } from 'react';
import { FormInstance } from 'antd/lib/form';
import { Table, Form, Select, Checkbox, EllipsisSpan } from '@maxtropy/components';
import { MonitoringIndicatorProps } from '../../api/circuit';
import styles from './index.module.scss';

interface OptionType {
  value: number;
  label: string;
}

const EditableContext = React.createContext<{
  formInstanceMap: Map<number, FormInstance<any>>;
  dataProperties: OptionType[];
} | null>(null);
const EditableRowContext = React.createContext<FormInstance<any> | null>(null);

export type Item = Partial<MonitoringIndicatorProps> & { checked: boolean };

interface EditableRowProps {
  index: number;
  'data-row-key': number;
}

const EditableRow: React.FC<EditableRowProps> = ({ ...props }) => {
  const [form] = Form.useForm();
  const { formInstanceMap } = useContext(EditableContext)!;

  useEffect(() => {
    formInstanceMap.set(props['data-row-key'], form);
    return () => {
      formInstanceMap.delete(props['data-row-key']);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form form={form} component={false}>
      <EditableRowContext.Provider value={form}>
        <tr {...props} />
      </EditableRowContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  checkable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = props => {
  const { title, editable, checkable, children, dataIndex, record, handleSave, ...restProps } = props;
  const form = useContext(EditableRowContext)!;
  const { dataProperties } = useContext(EditableContext)!;

  useEffect(() => {
    if (dataIndex && record) {
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record, dataIndex]);

  const save = async () => {
    try {
      const values = await form.validateFields();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = (
      <Form.Item style={{ margin: 0 }} name={dataIndex} rules={[{ required: false, message: `请选择${title}` }]}>
        <Select
          placeholder={'请输入/选择要绑定的数据属性'}
          options={dataProperties}
          showSearch
          optionFilterProp="label"
          allowClear
          filterOption={(input, option) => option!.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onChange={save}
          onBlur={save}
        />
      </Form.Item>
    );
  }

  // console.log('checkable', checkable)
  if (checkable) {
    childNode = <Checkbox />;
  }

  return <td {...restProps}>{childNode}</td>;
};

export type EditableTableRef = {
  validate: () => Promise<any>;
};

type EditableTableProps = Parameters<typeof Table>[0] & {
  dataProperties: OptionType[];
  dataSource: Item[];
  setDataSource: (values: Item[]) => void;
};

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const _columns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string; checkable?: boolean })[] = [
  {
    title: '已选择监控指标',
    dataIndex: 'circuitPhysicalQuantityName',
    width: '30%',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '对应数据属性',
    dataIndex: 'dataPropertyId',
    editable: true,
  },
];

const EditableTable: React.ForwardRefRenderFunction<EditableTableRef, EditableTableProps> = (props, ref) => {
  const { dataProperties, dataSource, setDataSource } = props;
  const formInstanceMap = useRef<Map<number, FormInstance<any>>>(new Map());

  useImperativeHandle(ref, () => ({
    validate: () => Promise.all(Array.from(formInstanceMap.current.values()).map(form => form.validateFields())),
  }));

  const handleSave = (row: Item) => {
    const newData = [...dataSource];
    const index = newData.findIndex(item => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const columns = _columns.map(col => {
    if (!col.editable && !col.checkable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <div className={styles.editableTable}>
      <EditableContext.Provider value={{ formInstanceMap: formInstanceMap.current, dataProperties }}>
        <Table
          rowKey={'id'}
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={dataSource}
          columns={columns as ColumnTypes}
          pagination={false}
          scroll={{ y: 250 }}
        />
      </EditableContext.Provider>
    </div>
  );
};

export default React.forwardRef(EditableTable);
