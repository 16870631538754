import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, getRealUrl } from '@maxtropy/components';
import { Space, Image } from 'antd';
import Carousel, { CarouselRef } from 'antd/es/carousel';
import React, { useRef } from 'react';
import styles from './index.module.scss';
import { useRequest, useSize } from 'ahooks';
import { apiV2PvStationDetailPost } from '@maxtropy/dmes-apis-v2';

const FileView = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const pvId = urlSearchParams.get('pvId') || undefined;
  const carouselRef = useRef<CarouselRef>(null);
  const outerRef = useRef<HTMLDivElement>();
  const size = useSize(outerRef);

  const { data } = useRequest(
    () =>
      apiV2PvStationDetailPost({
        id: pvId,
      }).then(res => {
        return res.files;
      }),
    {
      ready: !!pvId,
      refreshDeps: [pvId],
    }
  );

  return (
    <div
      className={styles.wrapper}
      ref={dom => {
        if (dom) {
          outerRef.current = dom;
        }
      }}
    >
      <Space className={styles.spaceSty}>
        <Button
          wrapStyle={{ height: '100%' }}
          className={styles.link_sty}
          type="link"
          icon={<LeftOutlined />}
          onClick={() => {
            carouselRef.current?.prev();
          }}
        />
        <div className={styles.border} style={{ width: (size?.width ?? 0) - 100, height: size?.height }}>
          <Carousel dots={{ className: 'dotsStyle' }} autoplaySpeed={5000} autoplay ref={carouselRef}>
            {data?.map(item => (
              <div className={styles.img_border}>
                <Image height={(size?.height ?? 0) - 20} src={getRealUrl(item.fileKey)} alt="" />
              </div>
            ))}
          </Carousel>
        </div>
        <Button
          wrapStyle={{ height: '100%' }}
          className={styles.link_sty}
          type="link"
          icon={<RightOutlined />}
          onClick={() => {
            carouselRef.current?.next();
          }}
        />
      </Space>
    </div>
  );
};

export default FileView;
