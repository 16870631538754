import { FormTitle, useBreadcrumbRoutes, Wrapper } from '@maxtropy/components';
import styles from './index.module.scss';
import { FC } from 'react';
import Edit from './Edit';
import Detail from './Detail';

const pageName = '离散率分析设置';

interface Props {
  isEdit: boolean;
}

const AnalysisConfigSetting: FC<Props> = props => {
  const { isEdit } = props;
  const breadcrumbRoutes = useBreadcrumbRoutes();

  return (
    <Wrapper className={styles.wrapper} routes={[...(breadcrumbRoutes?.routes ?? []), { name: pageName }]}>
      <FormTitle title={pageName}></FormTitle>
      {isEdit ? <Edit /> : <Detail />}
    </Wrapper>
  );
};

export default AnalysisConfigSetting;
