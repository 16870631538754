import { isNil } from 'lodash-es';
import { DataType, E_PRICE_PEAK_TYPE_LABEL_MAP } from '../../../../api/const';
import {
  E_PRICE_PEAK_TYPE_COLORS_MAP,
  EPricePeakType,
  EPriceTime,
  E_PRICE_PEAK_TYPE_LABEL_MAP_EP,
  E_PRICE_PEAK_TYPE_COLORS_MAP_EP,
  EPricePeakTypeEP,
} from '../../../../api/electricityPrice';
import { ParamsOption } from '../../config';
import { Query } from '../../index';
import {
  formatterMarkPointLabel,
  getCommonMarkPint,
  getLegend,
  getMarkAreaData,
  getNormalAxis,
  getTooltip,
  judgeEPHasNone,
  maxValueSpan,
  translateName,
} from './helper';
import palette from './palette';
import { average, getMax, getMin } from '../../utils';
import { DatePickerType } from '../DateSwitch';
import { CircuitStatisticsType } from '../../../../api/circuit';

export const icons = [
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjYycHgiIGhlaWdodD0iMzdweCIgdmlld0JveD0iMCAwIDYyIDM3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA0NC4xICg0MTQ1NSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+R3JvdXA8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAiIGZpbGw9IiM4QkQ0RUEiPgogICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiB4PSIwIiB5PSIwIiB3aWR0aD0iNjIiIGhlaWdodD0iMjYiPjwvcmVjdD4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlRyaWFuZ2xlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyOS41MDAwMDAsIDMxLjAwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMjkuNTAwMDAwLCAtMzEuMDAwMDAwKSAiIHBvaW50cz0iMjkuNSAyNSA0MCAzNyAxOSAzNyI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+',
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjYycHgiIGhlaWdodD0iMzdweCIgdmlld0JveD0iMCAwIDYyIDM3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA0NC4xICg0MTQ1NSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+R3JvdXA8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAiIGZpbGw9IiNCNkU3N0YiPgogICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiB4PSIwIiB5PSIwIiB3aWR0aD0iNjIiIGhlaWdodD0iMjYiPjwvcmVjdD4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlRyaWFuZ2xlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyOS41MDAwMDAsIDMxLjAwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMjkuNTAwMDAwLCAtMzEuMDAwMDAwKSAiIHBvaW50cz0iMjkuNSAyNSA0MCAzNyAxOSAzNyI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+',
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjYycHgiIGhlaWdodD0iMzdweCIgdmlld0JveD0iMCAwIDYyIDM3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA0NC4xICg0MTQ1NSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+R3JvdXA8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAiIGZpbGw9IiNGRjk4NkEiPgogICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiB4PSIwIiB5PSIwIiB3aWR0aD0iNjIiIGhlaWdodD0iMjYiPjwvcmVjdD4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlRyaWFuZ2xlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyOS41MDAwMDAsIDMxLjAwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMjkuNTAwMDAwLCAtMzEuMDAwMDAwKSAiIHBvaW50cz0iMjkuNSAyNSA0MCAzNyAxOSAzNyI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+',
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjYycHgiIGhlaWdodD0iMzdweCIgdmlld0JveD0iMCAwIDYyIDM3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA0NC4xICg0MTQ1NSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+R3JvdXA8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAiIGZpbGw9IiNGRjZEMkMiPgogICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiB4PSIwIiB5PSIwIiB3aWR0aD0iNjIiIGhlaWdodD0iMjYiPjwvcmVjdD4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlRyaWFuZ2xlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyOS41MDAwMDAsIDMxLjAwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMjkuNTAwMDAwLCAtMzEuMDAwMDAwKSAiIHBvaW50cz0iMjkuNSAyNSA0MCAzNyAxOSAzNyI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+',
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjYycHgiIGhlaWdodD0iMzdweCIgdmlld0JveD0iMCAwIDYyIDM3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA0NC4xICg0MTQ1NSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+R3JvdXA8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAiIGZpbGw9IiM0QTkwRTIiPgogICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiB4PSIwIiB5PSIwIiB3aWR0aD0iNjIiIGhlaWdodD0iMjYiPjwvcmVjdD4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlRyaWFuZ2xlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyOS41MDAwMDAsIDMxLjAwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMjkuNTAwMDAwLCAtMzEuMDAwMDAwKSAiIHBvaW50cz0iMjkuNSAyNSA0MCAzNyAxOSAzNyI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+',
];

interface ChartOptionProps {
  unit?: string;
  query: Query;
  ePriceTimes?: EPriceTime[];
}

const markPoint = {
  label: {
    normal: {
      show: true,
      offset: [0, -4],
    },
  },
  emphasis: {
    show: true,
    offset: [0, -4],
  },
  symbolSize: [60, 26],
  symbolOffset: [0, -13],
  data: [
    {
      type: 'max',
      name: '最大值',
      symbol: `image://${icons[3]}`,
      itemStyle: {
        normal: {
          color: '#e16b31',
          opacity: 0.75,
        },
      },
    },
    {
      type: 'min',
      name: '最小值',
      symbol: `image://${icons[4]}`,
      itemStyle: {
        normal: {
          color: '#4a90e2',
          opacity: 0.75,
        },
      },
    },
  ],
  itemStyle: {
    normal: {
      color: '#d6a223',
    },
  },
};

export const getChartOption = ({ query, unit }: ChartOptionProps) => {
  const colors = palette[query.dataType];
  return {
    backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue('--component-background'),
    grid: {
      left: 80,
      right: 50,
      top: 95,
      bottom: 80,
    },
    dataZoom: [
      {
        type: 'slider',
        // start: 0,
        // end: 10,
        bottom: 15,
        height: 20,
        textStyle: {
          fontSize: 10,
        },
        maxValueSpan: maxValueSpan(query.aggrby),
        // startValue: dayjs().startOf('d').format('MM-DD[\n]HH:mm'),
      },
      // { type: 'inside' }
    ],
    color: colors,
    tooltip: getTooltip({
      unit,
      date: query.tsStart,
      mode: query.btnType,
    }),
    xAxis: getNormalAxis(query),
    yAxis: {
      type: 'value',
      name: unit,
      nameTextStyle: {
        fontSize: 14,
        align: 'right',
      },
      nameGap: 30,
      splitLine: {
        lineStyle: {
          color: '#FFFFFF30',
        },
      },
    },
  };
};

// 电量
export const getEPChart = ({ chartData = [], query, unit, ePriceTimes }: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
    ePriceTimes,
  });

  const { name } = query;
  const colors = palette[query.dataType];

  // 判断是否有尖峰平谷，还是选择的日期内有没有尖峰平谷的，也有有尖峰平谷的
  let hasNoneRes: boolean = judgeEPHasNone(chartData, query, ePriceTimes);

  return {
    ...option,
    legend: getLegend({
      show: !ePriceTimes,
      name,
      colors,
      chartData,
      right: 120,
    }),
    toolbox: {
      width: 200,
      height: 100,
      top: 45,
      right: 42,
      itemSize: 20, // 设置图标大小
      feature: {
        brush: {
          type: ['lineX', 'clear'], // 选择工具类型
          title: {
            lineX: '横向选择',
            clear: '清除',
          },
        },
      },
    },
    brush: {
      toolbox: ['lineX', 'clear'],
      xAxisIndex: 0,
    },
    visualMap: ePriceTimes
      ? {
          show: true,
          right: 120,
          top: 50,
          orient: 'horizontal',
          itemSymbol: 'rect',
          textStyle: {
            color: '#AFBCC4',
          },
          formatter: (value: EPricePeakTypeEP | EPricePeakType) => {
            return hasNoneRes
              ? `${E_PRICE_PEAK_TYPE_LABEL_MAP_EP[value]}`
              : `${E_PRICE_PEAK_TYPE_LABEL_MAP[value as EPricePeakType]}电量`;
          },
          categories: hasNoneRes
            ? Object.keys(E_PRICE_PEAK_TYPE_COLORS_MAP_EP)
            : Object.keys(E_PRICE_PEAK_TYPE_COLORS_MAP),
          inRange: {
            color: hasNoneRes
              ? Object.values(E_PRICE_PEAK_TYPE_COLORS_MAP_EP)
              : Object.values(E_PRICE_PEAK_TYPE_COLORS_MAP),
          },
        }
      : undefined,
    series: [
      ...chartData.map((item, index) => ({
        type: 'bar',
        color: colors[index],
        name: translateName(Array.isArray(name) ? name[index] : name, item.circuitName),
        unit: 'kWh',
        timeResolution: query.aggrby,
        data: item.data.map((a, index) => {
          const type = ePriceTimes?.find(
            e =>
              index >= (query.btnType === DatePickerType.ONEMINUTE ? e.intervalStart * 30 : e.intervalStart * 2) &&
              index <= (query.btnType === DatePickerType.ONEMINUTE ? e.intervalEnd * 30 + 29 : e.intervalEnd * 2 + 1)
          )?.type;
          return [a.time, a.value, type ?? EPricePeakTypeEP.NONE];
        }),
        markArea: {
          data: ePriceTimes ? getMarkAreaData(ePriceTimes, query) : [],
        },
      })),
    ],
  };
};

// 需量
export const getPdmdChart = ({ chartData = [], query, unit, circuitEnergyConfig }: ParamsOption) => {
  const { needControlTarget } = circuitEnergyConfig || {};
  const option = getChartOption({
    unit,
    query,
  });

  const { name } = query;
  const colors = palette[query.dataType];

  return {
    ...option,
    legend: getLegend({
      show: true,
      name,
      colors,
      chartData,
    }),
    series: [
      ...chartData.map((item, index) => ({
        type: 'bar',
        color: colors[index],
        name: translateName(Array.isArray(name) ? name[index] : name, item.circuitName),
        unit: 'kW',
        timeResolution: query.aggrby,
        data: item.data.map(a => [a.time, a.value]),
        markPoint: {
          label: {
            normal: {
              show: true,
              offset: [0, -4],
            },
          },
          emphasis: {
            show: true,
            offset: [0, -4],
          },
          symbolSize: [60, 26],
          symbolOffset: [0, -13],
          data: [
            {
              type: 'max',
              name: '最大值',
              symbol: `image://${icons[3]}`,
              itemStyle: {
                normal: {
                  color: '#e16b31',
                  opacity: 0.75,
                },
              },
              label: {
                ...formatterMarkPointLabel(query.btnType),
              },
            },
          ],
          itemStyle: {
            normal: {
              color: '#d6a223',
            },
          },
        },
        markLine: !isNil(needControlTarget)
          ? {
              silent: true,
              symbol: ['none', 'none'],
              lineStyle: {
                type: 'dotted',
                color: '#FF4D4F',
              },
              data: [
                {
                  label: {
                    formatter: `需量控制目标：${needControlTarget}${unit} `,
                    position: 'insideEndTop',
                  },
                  yAxis: needControlTarget,
                },
              ],
            }
          : undefined,
      })),
    ],
  };
};
// 负载率
export const getLoadRateChart = ({ chartData = [], query, unit, ePriceTimes, circuitEnergyConfig }: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
    // ePriceTimes
  });
  let { loadControlTarget } = circuitEnergyConfig || {};
  const { name } = query;
  const colors = palette[query.dataType];
  const newChartData = chartData.map(item => {
    const data = item.data.map(a => [
      a.time,
      !isNil(item.capacity) && !isNil(a.value) ? (a.value * 100) / item.capacity : null,
    ]);
    return {
      ...item,
      data,
    };
  });

  const max = getMax(newChartData);

  return {
    ...option,
    yAxis: {
      type: 'value',
      name: unit,
      nameTextStyle: {
        fontSize: 14,
        align: 'right',
      },
      nameGap: 30,
      splitLine: {
        lineStyle: {
          color: '#FFFFFF30',
        },
      },
      min: 0,
      max: Math.ceil(Math.max(max, 100, loadControlTarget ?? 0)),
    },
    legend: getLegend({
      show: true,
      name,
      colors,
      chartData,
    }),
    series: [
      ...newChartData.map((item, index) => {
        return {
          type: 'line',
          smooth: true,
          lineStyle: { normal: { width: 2 } },
          color: colors[index],
          name: translateName(Array.isArray(name) ? name[index] : name, item.circuitName),
          data: item.data,
          unit: '%',
          timeResolution: query.aggrby,
          markArea: {
            data: ePriceTimes && index === 0 ? getMarkAreaData(ePriceTimes, query) : [],
          },
          markPoint: getCommonMarkPint(query.btnType),
          markLine: {
            silent: true,
            symbol: ['none', 'none'],
            lineStyle: {
              type: 'dotted',
              color: '#FF4D4F',
            },
            data: !isNil(loadControlTarget)
              ? [
                  {
                    label: {
                      formatter: `负载控制目标：${loadControlTarget}${unit}  `,
                      position: 'insideEndTop',
                    },
                    yAxis: loadControlTarget,
                  },
                  {
                    type: 'average',
                    name: 'Avg',
                    label: { formatter: `平均负载率: {c}%`, position: 'insideEndBottom' },
                    lineStyle: {
                      type: 'solid',
                    },
                  },
                ]
              : [
                  {
                    type: 'average',
                    name: 'Avg',
                    label: { formatter: `平均负载率: {c}%`, position: 'insideEndBottom' },
                    lineStyle: {
                      type: 'solid',
                    },
                  },
                ],
          },
        };
      }),
    ],
  };
};

// 功率因数
export const getCOSQChart = ({ chartData = [], query, ePriceTimes, unit }: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
  });

  const { name } = query;
  const colors = palette[query.dataType];

  return {
    ...option,
    legend: getLegend({
      show: true,
      name,
      colors,
      chartData,
    }),
    toolbox: {
      width: 200,
      height: 100,
      right: 42,
      itemSize: 20, // 设置图标大小
      feature: {
        brush: {
          type: ['lineX', 'clear'], // 选择工具类型
          title: {
            lineX: '横向选择',
            clear: '清除',
          },
        },
      },
    },
    brush: {
      toolbox: ['lineX', 'clear'],
    },
    series: [
      ...chartData.map((item, index) => ({
        type: 'line',
        smooth: true,
        lineStyle: { normal: { width: 2 } },
        color: colors[index],
        name: translateName(Array.isArray(name) ? name[index] : name, item.circuitName),
        data: item.data.map(a => [a.time, a.value]),
        timeResolution: query.aggrby,
        markArea: {
          data: ePriceTimes && index === 0 ? getMarkAreaData(ePriceTimes, query) : [],
        },
        markPoint: getCommonMarkPint(query.btnType),
      })),
    ],
  };
};

//有功功率
export const getPChart = ({ chartData = [], query, unit, ePriceTimes }: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
  });

  const { name } = query;
  const colors = palette[query.dataType];

  const averageData = chartData.filter(i => i.type === CircuitStatisticsType.AVERAGE);
  const maxData = chartData.filter(i => i.type === CircuitStatisticsType.MAX);
  const minData = chartData.filter(i => i.type === CircuitStatisticsType.MIN);
  const totalData = averageData[0]?.data.map((item, index) => [
    item.time,
    Number(maxData[0]?.data[index].value?.toFixed(2)),
    Number(item.value?.toFixed(2)),
    Number(minData[0]?.data[index].value?.toFixed(2)),
  ]);

  const series =
    query.btnType === DatePickerType.ORIGINAL ||
    query.btnType === DatePickerType.THIRTYMINUTES ||
    query.btnType === DatePickerType.ONEMINUTE
      ? [
          ...(averageData ?? []).map((item, index) => ({
            type: 'line',
            smooth: true,
            lineStyle: { normal: { width: 2 } },
            color: colors[index],
            name: translateName(Array.isArray(name) ? name[index] : name, item.circuitName),
            // 四舍五入3位：防止数据过小, 如1.0e-10，导致分割线出问题；同时兼容1分钟的3位小数和15分钟的2位小数。
            data: item.data.map(a => [a.time, a.value ? Number(a.value.toFixed(3)) : a.value]),
            markArea: {
              data: ePriceTimes && index === 0 ? getMarkAreaData(ePriceTimes, query) : [],
            },
            markPoint: getCommonMarkPint(query.btnType),
            markLine: {
              silent: true,
              symbol: ['none', 'none'],
              lineStyle: {
                type: 'solid',
                color: '#FF4D4F',
              },
              data: [
                {
                  label: { formatter: `平均功率: {c}kW`, position: 'insideEndBottom' },
                  yAxis: average(averageData[0]?.data.map(i => i.value)),
                },
              ],
            },
          })),
        ]
      : [
          {
            name: translateName(Array.isArray(name) ? name[0] : name, averageData[0]?.circuitName),
            type: 'custom',
            dimensions: ['-', '最大值', '平均功率', '最小值'],
            itemStyle: {
              borderWidth: 1.5,
            },
            renderItem: function (params: any, api: any) {
              var xValue = api?.value(0);
              var highPoint = api?.coord([xValue, api.value(2)]);
              var lowPoint = api?.coord([xValue, api.value(1)]);
              var halfWidth = api?.size([1, 0])[0] * 0.1;
              var p = api?.coord([xValue, api.value(3)]);
              var style = api?.style({
                stroke: api?.visual('color'),
                fill: undefined,
              });
              return {
                type: 'group',
                children: [
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1: highPoint[0] - halfWidth,
                      y1: highPoint[1],
                      x2: highPoint[0] + halfWidth,
                      y2: highPoint[1],
                    },
                    style: style,
                  },
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1: highPoint[0],
                      y1: highPoint[1],
                      x2: p[0],
                      y2: p[1],
                    },
                    style: style,
                  },
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1: p[0] - halfWidth,
                      y1: p[1],
                      x2: p[0] + halfWidth,
                      y2: p[1],
                    },
                    style: style,
                  },
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1: p[0],
                      y1: p[1],
                      x2: lowPoint[0],
                      y2: lowPoint[1],
                    },
                    style: style,
                  },
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1: lowPoint[0] - halfWidth,
                      y1: lowPoint[1],
                      x2: lowPoint[0] + halfWidth,
                      y2: lowPoint[1],
                    },
                    style: style,
                  },
                ],
              };
            },
            encode: {
              x: 0,
              y: [1, 2, 3],
              tooltip: [1, 2, 3],
            },
            data: totalData,
            z: 100,
            markLine: {
              silent: true,
              symbol: ['none', 'none'],
              lineStyle: {
                type: 'solid',
                color: '#FF4D4F',
              },
              data: [
                {
                  label: { formatter: `平均功率: {c}kW`, position: 'insideEndBottom' },
                  yAxis: average(averageData[0]?.data.map(i => i.value)),
                },
              ],
            },
          },
        ];

  const options =
    query.btnType === DatePickerType.ORIGINAL ||
    query.btnType === DatePickerType.THIRTYMINUTES ||
    query.btnType === DatePickerType.ONEMINUTE
      ? option
      : {
          ...option,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
            },
            // position: function (pos: any, params: any, el: any, elRect: any, size: any) {
            //   var obj: any = { top: 10 };
            //   obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 30;
            //   return obj;
            // },
          },
        };

  return {
    ...options,
    legend: getLegend({
      show: true,
      name,
      colors,
      chartData,
    }),
    series,
  };
};

// 线电压 相电压 电流 线缆温度
export const getLineBoxplotChart = ({
  chartData = [],
  query,
  unit,
  ePriceTimes,
  circuitEnergyConfig,
}: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
  });

  const { name } = query;
  const colors = palette[query.dataType];
  // U = 6, // 相电压
  // LINE_VOLTAGE = 7, // 线电压
  const isShowLine = [DataType.LINE_VOLTAGE, DataType.U].includes(query.dataType);
  const upperData =
    isShowLine &&
    (query.dataType === DataType.LINE_VOLTAGE
      ? circuitEnergyConfig?.lineVoltageUpper
      : circuitEnergyConfig?.phaseVoltageUpper);
  const lowerData =
    isShowLine &&
    (query.dataType === DataType.LINE_VOLTAGE
      ? circuitEnergyConfig?.lineVoltageLower
      : circuitEnergyConfig?.phaseVoltageLower);
  const lineName = query.dataType === DataType.LINE_VOLTAGE ? '线电压' : '相电压';
  let data: any;
  if (!isNil(upperData) && !isNil(lowerData)) {
    data = [
      {
        label: {
          formatter: `${lineName}-越界上限: {c}V`,
          position: 'insideEndTop',
        },
        yAxis: upperData,
        lineStyle: {
          type: 'dotted',
          color: '#FF4D4F',
        },
      },
      {
        label: {
          formatter: `${lineName}-越界下限: {c}V`,
          position: 'insideEndBottom',
        },
        yAxis: lowerData,
        lineStyle: {
          type: 'dotted',
          color: '#1890FF',
        },
      },
    ];
  } else if (!isNil(upperData)) {
    data = [
      {
        label: {
          formatter: `${lineName}-越界上限: {c}V`,
          position: 'insideEndTop',
        },
        yAxis: upperData,
        lineStyle: {
          type: 'dotted',
          color: '#FF4D4F',
        },
      },
    ];
  } else if (!isNil(lowerData)) {
    data = [
      {
        label: {
          formatter: `${lineName}-越界下限: {c}V`,
          position: 'insideEndBottom',
        },
        yAxis: lowerData,
        lineStyle: {
          type: 'dotted',
          color: '#1890FF',
        },
      },
    ];
  } else {
    data = [];
  }

  const newChartData = chartData.map(item => {
    const data = item.data.map((a, index) => [index, !isNil(a.value) ? a.value : null]);
    return {
      ...item,
      data,
    };
  });

  const max = getMax(newChartData);
  const minValue = getMin(newChartData);

  return {
    ...option,
    yAxis: {
      ...option.yAxis,
      scale: true,
      min: lowerData ? Math.floor(Math.min(minValue, lowerData)) : Math.floor(Math.min(minValue)),
      max: upperData ? Math.ceil(Math.max(max, upperData)) : Math.ceil(Math.max(max)),
    },
    legend: getLegend({
      show: true,
      name,
      colors,
      chartData,
    }),
    series: [
      ...chartData
        .sort((a, b) => a.id - b.id)
        .map((item, index) => ({
          type: 'line',
          smooth: true,
          lineStyle: { normal: { width: 2 } },
          color: colors[index],
          name: translateName(Array.isArray(name) ? name[index] : name, item.circuitName),
          data: item.data.map(a => [a.time, a.value]),
          markArea: {
            data: ePriceTimes && index === 0 ? getMarkAreaData(ePriceTimes, query) : [],
          },
          markPoint: getCommonMarkPint(query.btnType),
          markLine: isShowLine
            ? {
                silent: true,
                symbol: ['none', 'none'],
                data: data,
              }
            : undefined,
        })),
    ],
  };
};

//频率
export const getFChart = ({ chartData = [], query, unit }: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
  });

  const { name } = query;
  const colors = palette[query.dataType];

  return {
    ...option,
    yAxis: {
      ...option.yAxis,
      scale: true,
    },
    legend: getLegend({
      show: true,
      name,
      colors,
      chartData,
    }),
    series: [
      ...chartData.map((item, index) => ({
        type: 'line',
        smooth: true,
        lineStyle: { normal: { width: 2 } },
        color: '#B620E0',
        name: translateName(Array.isArray(name) ? name[index] : name, item.circuitName),
        data: item.data.map(a => [a.time, a.value]),
        markArea: {
          data: [
            [
              {
                itemStyle: {
                  color: 'rgba(250, 107, 43, 0.1)',
                },
                label: {
                  formatter: '差',
                  position: 'insideTopLeft',
                  color: '#FF4D4F',
                },
              },
              {
                yAxis: 49.8,
              },
            ],
            [
              {
                yAxis: 50.2,
                itemStyle: {
                  color: 'rgba(250, 107, 43, 0.1)',
                },
                label: {
                  formatter: '差',
                  position: 'insideBottomLeft',
                  color: '#FF4D4F',
                },
              },
              {},
            ],
          ],
        },
        markPoint: getCommonMarkPint(query.btnType),
      })),
    ],
  };
};

// 有功电能
export const getPEChart = ({ chartData = [], query, unit, ePriceTimes }: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
  });

  const { name } = query;
  const colors = palette[query.dataType];

  return {
    ...option,
    legend: getLegend({
      show: true,
      name,
      colors,
      chartData,
      right: 120,
    }),
    toolbox: {
      width: 200,
      height: 100,
      top: 45,
      right: 42,
      itemSize: 20, // 设置图标大小
      feature: {
        brush: {
          type: ['lineX', 'clear'], // 选择工具类型
          title: {
            lineX: '横向选择',
            clear: '清除',
          },
        },
      },
    },
    brush: {
      toolbox: ['lineX', 'clear'],
      xAxisIndex: 0,
    },
    series: [
      ...chartData
        .sort((a, b) => a.id - b.id)
        .map((item, index) => ({
          type: 'bar',

          color: colors[index],
          name: translateName(Array.isArray(name) ? name[index] : name, item.circuitName),
          data: item.data.map(a => [a.time, a.value]),
          unit: 'kWh',
          timeResolution: query.aggrby,
          markArea:
            query.btnType === DatePickerType.ORIGINAL ||
            query.btnType === DatePickerType.THIRTYMINUTES ||
            query.btnType === DatePickerType.ONEMINUTE
              ? {
                  data: ePriceTimes ? getMarkAreaData(ePriceTimes, query) : [],
                }
              : undefined,
        })),
    ],
  };
};

// 三相电压不平衡度
export const getUVChart = ({ chartData = [], query, ePriceTimes, unit }: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
  });

  const { name } = query;
  const colors = palette[query.dataType];

  return {
    ...option,
    yAxis: {
      ...option.yAxis,
      scale: true,
    },
    legend: getLegend({
      show: true,
      name,
      colors,
      chartData,
    }),
    series: [
      ...chartData.map((item, index) => ({
        type: 'line',
        smooth: true,
        lineStyle: { normal: { width: 2 } },
        color: colors[index],
        name: translateName(Array.isArray(name) ? name[index] : name, item.circuitName),
        data: item.data.map(a => [a.time, a.value]),
        markPoint: getCommonMarkPint(query.btnType),
        markArea:
          query.btnType === DatePickerType.ORIGINAL ||
          query.btnType === DatePickerType.THIRTYMINUTES ||
          query.btnType === DatePickerType.ONEMINUTE
            ? {
                data: ePriceTimes && index === 0 ? getMarkAreaData(ePriceTimes, query) : [],
              }
            : undefined,
      })),
    ],
  };
};

// 三相电流不平衡度
export const getUAChart = ({ chartData = [], query, ePriceTimes, unit }: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
  });

  const { name } = query;
  const colors = palette[query.dataType];

  return {
    ...option,
    yAxis: {
      ...option.yAxis,
      scale: true,
    },
    legend: getLegend({
      show: true,
      name,
      colors,
      chartData,
    }),
    series: [
      ...chartData.map((item, index) => ({
        type: 'line',
        smooth: true,
        lineStyle: { normal: { width: 2 } },
        color: colors[index],
        name: translateName(Array.isArray(name) ? name[index] : name, item.circuitName),
        data: item.data.map(a => [a.time, a.value]),
        markPoint: getCommonMarkPint(query.btnType),
        markArea:
          query.btnType === DatePickerType.ORIGINAL ||
          query.btnType === DatePickerType.THIRTYMINUTES ||
          query.btnType === DatePickerType.ONEMINUTE
            ? {
                data: ePriceTimes && index === 0 ? getMarkAreaData(ePriceTimes, query) : [],
              }
            : undefined,
      })),
    ],
  };
};
// 剩余电流
export const getResidualAChart = ({ chartData = [], query, ePriceTimes, unit }: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
  });

  const { name } = query;
  const colors = palette[query.dataType];

  return {
    ...option,
    yAxis: {
      ...option.yAxis,
      scale: true,
    },
    legend: getLegend({
      show: true,
      name,
      colors,
      chartData,
    }),
    series: [
      ...chartData.map((item, index) => ({
        type: 'line',
        smooth: true,
        lineStyle: { normal: { width: 2 } },
        color: colors[index],
        name: translateName(Array.isArray(name) ? name[index] : name, item.circuitName),
        data: item.data.map(a => [a.time, a.value]),
        markArea: {
          data: ePriceTimes && index === 0 ? getMarkAreaData(ePriceTimes, query) : [],
        },
        markPoint: getCommonMarkPint(query.btnType),
      })),
    ],
  };
};
// 电流谐波
export const getLineCurrentAChart = ({
  chartData = [],
  query,
  unit,
  ePriceTimes,
  circuitEnergyConfig,
}: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
  });

  const { name } = query;
  const colors = palette[query.dataType];
  const upperData = circuitEnergyConfig?.currentHarmonicUpper;

  const newChartData = chartData.map(item => {
    const data = item.data.map((a, index) => [index, !isNil(a.value) ? a.value : null]);
    return {
      ...item,
      data,
    };
  });

  const max = getMax(newChartData);
  const minValue = getMin(newChartData);

  return {
    ...option,
    yAxis: {
      ...option.yAxis,
      scale: true,
      min: upperData ? Math.floor(Math.min(minValue, upperData)) : Math.floor(Math.min(minValue)),
      max: upperData ? Math.ceil(Math.max(max, upperData)) : Math.ceil(Math.max(max)),
    },
    legend: getLegend({
      show: true,
      name,
      colors,
      chartData,
    }),
    series: [
      ...chartData
        .sort((a, b) => a.id - b.id)
        .map((item, index) => ({
          type: 'line',
          smooth: true,
          lineStyle: { normal: { width: 2 } },
          color: colors[index],
          name: translateName(Array.isArray(name) ? name[index] : name, item.circuitName),
          data: item.data.map(a => [a.time, a.value]),
          markArea: {
            data: ePriceTimes && index === 0 ? getMarkAreaData(ePriceTimes, query) : [],
          },
          markLine: !isNil(upperData)
            ? {
                silent: true,
                symbol: ['none', 'none'],
                lineStyle: {
                  type: 'dotted',
                  color: '#59D744',
                },
                data: [
                  {
                    label: { formatter: `电流谐波上限: {c}%`, position: 'insideEndTop' },
                    yAxis: upperData,
                  },
                ],
              }
            : undefined,
          markPoint: getCommonMarkPint(query.btnType),
        })),
    ],
  };
};
// 电压谐波
export const getLineVChart = ({ chartData = [], query, unit, ePriceTimes, circuitEnergyConfig }: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
  });

  const { name } = query;
  const colors = palette[query.dataType];
  const upperData = circuitEnergyConfig?.voltageHarmonicUpper;

  const newChartData = chartData.map(item => {
    const data = item.data.map((a, index) => [index, !isNil(a.value) ? a.value : null]);
    return {
      ...item,
      data,
    };
  });

  const max = getMax(newChartData);
  const minValue = getMin(newChartData);

  return {
    ...option,
    yAxis: {
      ...option.yAxis,
      scale: true,
      min: upperData ? Math.floor(Math.min(minValue, upperData)) : Math.floor(Math.min(minValue)),
      max: upperData ? Math.ceil(Math.max(max, upperData)) : Math.ceil(Math.max(max)),
    },
    legend: getLegend({
      show: true,
      name,
      colors,
      chartData,
    }),
    series: [
      ...chartData
        .sort((a, b) => a.id - b.id)
        .map((item, index) => ({
          type: 'line',
          smooth: true,
          lineStyle: { normal: { width: 2 } },
          color: colors[index],
          name: translateName(Array.isArray(name) ? name[index] : name, item.circuitName),
          data: item.data.map(a => [a.time, a.value]),
          markArea: {
            data: ePriceTimes && index === 0 ? getMarkAreaData(ePriceTimes, query) : [],
          },
          markPoint: getCommonMarkPint(query.btnType),
          markLine: !isNil(upperData)
            ? {
                silent: true,
                symbol: ['none', 'none'],
                lineStyle: {
                  type: 'dotted',
                  color: '#59D744',
                },
                data: [
                  {
                    label: {
                      formatter: `电压谐波上限: {c}%`,
                      position: 'insideEndTop',
                    },
                    yAxis: upperData,
                  },
                ],
              }
            : undefined,
        })),
    ],
  };
};
