import { CloseOutlined, DoubleRightOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Empty, MXTabsProps, Tabs, Tag, Tooltip, useUpdate } from '@maxtropy/components';
import { Drawer, Space, Spin } from 'antd';
import styles from './index.module.scss';
import React, { FC, useMemo, useState } from 'react';
import { useRequest } from 'ahooks';
import {
  apiV2PvOutlineAlarmCountPost,
  apiV2PvOutlineAlarmDataPagePost,
  apiV2PvOutlineAttentionPost,
  V2PvOutlineAlarmDataPagePostResponse,
  V2PvStationDetailPostResponse,
} from '@maxtropy/dmes-apis-v2';
import { isNil } from 'lodash-es';
import dayjs from 'dayjs';
import { AlarmStatus, AlarmStatusColorDisplay, AlarmStatusDisplay, AlarmType } from './type';

export interface IAlarmDrawer {
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  drawerOpen: boolean;
  currentPvId?: number;
  data?: V2PvStationDetailPostResponse;
}

export type V2PvOutlineAlarmDataItemProps = Exclude<V2PvOutlineAlarmDataPagePostResponse['list'], undefined>[number];

const SceneType = 4; //场景类型	是4的时候是离散率报警，传0服务端报警，否则传1边缘报警

const AlarmDrawer: FC<IAlarmDrawer> = props => {
  const { setDrawerOpen, drawerOpen, currentPvId, data } = props;

  const [activeKey, setActiveKey] = useState<string>('all');
  const [update, setUpdate] = useUpdate();

  const { data: count } = useRequest(
    () =>
      apiV2PvOutlineAlarmCountPost({
        id: currentPvId,
      }).then(res => {
        return res;
      }),
    {
      pollingInterval: 2 * 60000,
      ready: !!currentPvId && !!activeKey && drawerOpen,
      refreshDeps: [currentPvId, update, activeKey, drawerOpen],
    }
  );

  const { data: eachlist, loading: eachLoading } = useRequest(
    () =>
      apiV2PvOutlineAlarmDataPagePost({
        pvId: currentPvId,
        level: activeKey === 'all' || activeKey === 'attention' ? undefined : Number(activeKey),
        page: 1,
        size: activeKey === 'attention' ? 20 : 100,
        attentionLog: activeKey === 'attention',
      }).then(res => {
        return res.list;
      }),
    {
      pollingInterval: 2 * 60000,
      ready: !!currentPvId && !!activeKey && drawerOpen,
      refreshDeps: [currentPvId, activeKey, update, drawerOpen],
    }
  );

  const items: MXTabsProps['items'] = useMemo(() => {
    return [
      {
        key: 'attention',
        label: <>关注({!isNil(count?.attentionAlarmNum) ? count?.attentionAlarmNum : '-'})</>,
      },
      {
        key: 'all',
        label: <>全部({!isNil(count?.allNum) ? count?.allNum : '-'})</>,
      },
      {
        key: '0',
        label: <>最高级({!isNil(count?.topHighAlarmNum) ? count?.topHighAlarmNum : '-'})</>,
      },
      {
        key: '1',
        label: <>高级({!isNil(count?.highAlarmNum) ? count?.highAlarmNum : '-'})</>,
      },
      {
        key: '2',
        label: <>中级({!isNil(count?.middleAlarmNum) ? count?.middleAlarmNum : '-'})</>,
      },
      {
        key: '3',
        label: <>低级({!isNil(count?.lowerAlarmNum) ? count?.lowerAlarmNum : '-'})</>,
      },
      {
        key: '4',
        label: <>最低级({!isNil(count?.earlyWarningAlarmNum) ? count?.earlyWarningAlarmNum : '-'})</>,
      },
    ];
  }, [count]);

  const handleConcern = (attention: boolean, ruleId?: number, deviceId?: number, scenceType?: number) => {
    apiV2PvOutlineAttentionPost({
      ruleId,
      deviceId,
      attention,
      id: currentPvId,
      type: scenceType === SceneType ? AlarmType.ServerAlarm : AlarmType.EdgeAlarm,
    }).then(_ => {
      setUpdate();
    });
  };

  // 跳转
  const navigateAlarm = (item?: V2PvOutlineAlarmDataItemProps) => {
    if (activeKey === 'attention') {
      window.open(
        `/assets/alarm/overview?deviceCode=${item?.deviceCode}&ruleCode=${item?.ruleCode ?? ''}&startTime=${dayjs(
          item?.attentionTime
        ).valueOf()}&endTime=${dayjs().valueOf()}`,
        '_blank'
      );
    } else {
      if (item?.sceneType === SceneType) {
        window.open(`/assets/alarm/overview/detail/${item?.alarmId}`, '_blank');
      } else {
        window.open(`${window.IOTPLATFORMORIGIN}/device/alarm/record/detail/${item?.alarmId}`, '_blank');
      }
    }
  };

  return (
    <Drawer
      getContainer={() => document.getElementById('full_screen_box') ?? document.body}
      className={styles.drawerStyle}
      title={
        <div className={styles.drawertitleStyle}>
          <div className={styles.drawertitleLeftStyle}>
            报警列表
            <Tooltip
              placement="right"
              title="报警列表展示当前设备未恢复的报警信息，可点击关注将设备报警规则添加到关注列表进行跟踪"
            >
              <InfoCircleOutlined style={{ color: 'var(--warning-color)', marginLeft: 6 }} />
            </Tooltip>
          </div>
          <Button
            type="link"
            onClick={() =>
              window.open(
                `/assets/alarm/overview?assetCode=${data?.code}&state=${1}&abnormalDiagnosis=${undefined}`,
                '_blank'
              )
            }
          >
            更多报警
            <DoubleRightOutlined className={styles.translationStyles} />
          </Button>
        </div>
      }
      placement="right"
      width={640}
      mask={false}
      closable={false}
      open={drawerOpen}
      extra={
        <Button
          type="link"
          style={{ color: 'rgba(255,255,255,0.45)' }}
          icon={<CloseOutlined />}
          onClick={() => {
            setDrawerOpen(false);
          }}
        />
      }
    >
      <div className={styles.top_box}>
        <Tabs
          size="small"
          className={styles.tabSty}
          onChange={v => setActiveKey(v)}
          activeKey={activeKey}
          items={items}
        />
        {activeKey && (
          <div className={styles.tab_content}>
            {eachlist && eachlist.length > 0 ? (
              <Spin spinning={eachLoading}>
                {(eachlist ?? []).map(item => (
                  <div className={styles.item_sty} key={item.alarmId}>
                    <div className={styles.item_wrapper_title}>
                      <Tag border="solid" color={AlarmStatusColorDisplay[item.level as AlarmStatus]}>
                        {AlarmStatusDisplay[item.level as AlarmStatus]}
                      </Tag>
                    </div>
                    <div className={styles.item_wrapper_box}>
                      <div className={styles.item_wrapper_box_top}>
                        <div className={styles.item_wrapper_deviceName}>
                          <Tooltip title={item.deviceName}>{item.deviceName ?? '--'}</Tooltip>
                        </div>
                        <div className={styles.item_wrapper_time}>
                          {activeKey === 'attention'
                            ? item.attentionTime
                              ? dayjs(item.attentionTime).format('YYYY-MM-DD HH:mm:ss')
                              : '--'
                            : item.alarmTime
                            ? dayjs(item.alarmTime).format('YYYY-MM-DD HH:mm:ss')
                            : '--'}
                        </div>
                      </div>
                      <div className={styles.item_wrapper_box_bottom}>
                        <div className={styles.item_wrapper_content}>
                          <Tooltip
                            // overlayStyle={{ minWidth: 300 }}
                            title={
                              activeKey === 'attention' ? (
                                <>
                                  <span className={styles.tooltip_label_sty}>设备名称：</span>
                                  <span className={styles.tooltip_value_sty}>{item.deviceName ?? '--'}</span>
                                  <br />
                                  <span className={styles.tooltip_label_sty}>报警信息：</span>
                                  <span className={styles.tooltip_value_sty}>
                                    {item.sceneType === SceneType ? item.alarmMsg ?? '--' : item.ruleName ?? '--'}
                                  </span>
                                  <br />
                                  <span className={styles.tooltip_label_sty}>关注时间：</span>
                                  <span className={styles.tooltip_value_sty}>
                                    {item.attentionTime
                                      ? dayjs(item.attentionTime).format('YYYY-MM-DD HH:mm:ss')
                                      : '--'}
                                  </span>
                                  <br />
                                  <span className={styles.tooltip_label_sty}>关注后报警次数：</span>
                                  <span className={styles.tooltip_value_sty}>
                                    {!isNil(item.attentionLogNum) ? item.attentionLogNum : '--'}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className={styles.tooltip_label_sty}>设备名称：</span>
                                  <span className={styles.tooltip_value_sty}>{item.deviceName ?? '--'}</span>
                                  <br />
                                  <span className={styles.tooltip_label_sty}>报警信息：</span>
                                  <span className={styles.tooltip_value_sty}>
                                    {item.sceneType === SceneType ? item.alarmMsg ?? '--' : item.ruleName ?? '--'}
                                  </span>
                                  <br />
                                  <span className={styles.tooltip_label_sty}>触发时间：</span>
                                  <span className={styles.tooltip_value_sty}>
                                    {item.alarmTime ? dayjs(item.alarmTime).format('YYYY-MM-DD HH:mm:ss') : '--'}
                                  </span>
                                </>
                              )
                            }
                          >
                            <div className={styles.attentionLogNum_wrapper}>
                              <div className={styles.attentionLogNumContent}>
                                {item.sceneType === SceneType ? item.alarmMsg ?? '--' : item.ruleName ?? '--'}
                              </div>
                              {activeKey === 'attention' && (
                                <div className={styles.attentionLogNumCircle}>
                                  <span style={{ color: '#ffffff' }}>{item.attentionLogNum}</span>
                                </div>
                              )}
                            </div>
                          </Tooltip>
                        </div>

                        <Space size={16}>
                          <Button style={{ padding: '4px 0' }} type="link" onClick={() => navigateAlarm(item)}>
                            查看
                          </Button>
                          {isNil(item.attentionLogNum) ? (
                            <Button
                              style={{ padding: '4px 0' }}
                              disabled={activeKey === 'attention'}
                              type="link"
                              onClick={() => handleConcern(true, item.ruleId, item.deviceId, item.sceneType)}
                            >
                              关注
                            </Button>
                          ) : activeKey !== 'attention' ? (
                            <Button style={{ padding: '4px 0' }} disabled={true} type="link">
                              关注
                            </Button>
                          ) : (
                            <Button
                              style={{ padding: '4px 0' }}
                              type="link"
                              onClick={() => handleConcern(false, item.ruleId, item.deviceId, item.sceneType)}
                            >
                              移除
                            </Button>
                          )}
                        </Space>
                      </div>
                    </div>
                  </div>
                ))}
              </Spin>
            ) : (
              <Empty style={{ marginTop: 60 }} />
            )}
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default AlarmDrawer;
