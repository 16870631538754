import { fetch } from '@maxtropy/components';
import { PortalDashboardHighlightDisplayDataPostResponse } from '@maxtropy/dmes-apis';

// staff数据来源
export enum StaffSourceTypeEnum {
  // 平台
  PLATFORM = 1,
  // 不配置
  NOT_CONFIG = 2,
}

// 平台数据来源
export enum PlatformSourceTypeEnum {
  // 平台
  PLATFORM = 1,
  // 配置
  CONFIG = 2,
  // 不配置
  NOT_CONFIG = 3,
}

export interface GetPvDataResType {
  sourceType?: number;
  num?: number;
  installedCapacity?: number;
  pvInverterNum?: number;
}

export interface GetBsaDataResType {
  sourceType?: number;
  num?: number;
  bsaCellNum?: number;
  installedCapacity?: number;
  ratedPower?: number;
}

export interface GetChargingDataResType {
  sourceType: number;
  num?: number;
  installedCapacity?: number;
  chargingPileNum?: number;
}

export interface GetLeanManageDataResType {
  type?: number;
  dayEnergyConsumption?: number;
  dayOutput?: number;
  lineTotal?: number;
  lineOn?: number;
}

export interface GetBasicManageResType {
  type?: number;
  circuitNum?: number;
  waterMonitorNum?: number;
  gasMonitorNum?: number;
}

export interface GetButtonListResType {
  id?: number;
  buttonName?: string;
  url?: string;
  remark?: string;
  type?: string;
}

export interface PanelDataType {
  bottom: number;
  left: number;
  stationNum: number | string | undefined | null;
  stationName: string;
  stationNameWrap?: string;
  deviceNum: number | string | undefined | null;
  deviceName: string;
  deviceNameWrap?: string;
  totalCapacity: any;
  capacityUnit: string;
  capacityUnitWrap?: string;
  panelPicUrl?: string;
  devicePicUrl?: string;
  locationPicUrl: string;
  totalCapacityPositonLeft?: number;
  devicePositonLeft?: number;
  borderColorValue?: string;
  type?: string;
  listData?: GetButtonListResType[];
}

export enum ButtonListTypeEnum {
  IMPORT_BUTTON = '1',
  PV_BUTTON = '2',
  BSA_BUTTON = '3',
  CHARGING_BUTTON = '4',
  LEAN_MANAGE_BUTTON = '5',
  BASE_MANAGE_BUTTON = '6',
}

export const getPvData = () => {
  return fetch<GetPvDataResType>('/api/portal/dashboard/pv');
};

export const getBsaData = () => {
  return fetch<GetBsaDataResType>('/api/portal/dashboard/bsa');
};

export const getChargingData = () => {
  return fetch<GetChargingDataResType>('/api/portal/dashboard/charging');
};
export const getLeanManageData = () => {
  return fetch<GetLeanManageDataResType>('/api/portal/dashboard/leanManage');
};
export const getBasicManage = () => {
  return fetch<GetBasicManageResType>('/api/portal/dashboard/basicManage');
};

export const getMajorData = () => {
  return fetch<PortalDashboardHighlightDisplayDataPostResponse>('/api/portal/dashboard/highlight-display-data');
};

export const getButtonList = () => {
  return fetch<GetButtonListResType[]>('/api/portal/dashboard/button/list');
};
