import { isNil } from 'lodash-es';

// 格式化
export const formateValues = (value?: number) => {
  return !isNil(value) ? Number(value.toFixed(2)).toLocaleString() : '--';
};

export const formateValuesRmb = (value?: number) => {
  return !isNil(value)
    ? Math.abs(Number(value)) >= 100000
      ? Number((Number(value) / 10000).toFixed(2))
      : Number(value.toFixed(2))
    : '--';
};

export const getValueUnit = (value?: number) => {
  return !isNil(value) ? (Math.abs(Number(value)) >= 100000 ? '万元' : '元') : '元';
};

export const formateValuesToFix2 = (value?: number) => {
  return !isNil(value) ? value.toFixed(2) : '--';
};

export const formateValuesPercent = (value?: number) => {
  return !isNil(value) ? Number((Number(value) * 100).toFixed(1)) : '--';
};

// soc百分比
export const formateValuesSOCPercent = (value?: number) => {
  return !isNil(value) ? Number(value).toFixed(1) : '--';
};

export const formateValuesProcessPercent = (value?: number) => {
  return !isNil(value) ? Number((Number(value) * 100).toFixed(2)) : undefined;
};

// Gwh
export const GwhFormat = (value?: number) => {
  if (undefined === value || null === value) return { value: '--', unit: '' };
  if (typeof value === 'string') value = Number(value);
  if (value > 1000 * 1000) {
    return {
      value: `${(value / (1000 * 1000)).toLocaleString('zh', {
        maximumFractionDigits: 1,
      })}`,
      unit: 'GWh',
    };
  }
  if (value > 1000) {
    return {
      value: `${(value / 1000).toLocaleString('zh', {
        maximumFractionDigits: 1,
      })}`,
      unit: 'MWh',
    };
  }
  return {
    value: `${value.toLocaleString('zh', { maximumFractionDigits: 1 })}`,
    unit: 'kWh',
  };
};

export function flexible(proportionalLayoutEl: any) {
  const designWidth = 1366 - 43; // 滚动条加上padding
  const designHeight = 744; // 滚动条+面包屑＋头部导航

  // const designWidth = proportionalLayoutEl.clientWidth;
  // const designHeight = proportionalLayoutEl.clientHeight;

  const baseProportion = parseFloat((16 / 9).toFixed(5));
  const currentRate = parseFloat((proportionalLayoutEl.clientWidth / proportionalLayoutEl.clientHeight).toFixed(5));

  function setScale() {
    if (window.innerWidth <= 1366 && window.innerWidth > 992) {
      if (baseProportion > currentRate) {
        const scaleWidth = (proportionalLayoutEl.clientWidth - 26) / designWidth;
        proportionalLayoutEl.children[1].style.width = `${designWidth}px`;
        proportionalLayoutEl.children[1].style.height = `${designHeight}px`;
        proportionalLayoutEl.children[1].style.transform = `scale(${scaleWidth}, ${scaleWidth}) translate(0px,${
          (proportionalLayoutEl.clientHeight - designHeight * scaleWidth) / 2 / scaleWidth
        }px)`;
        proportionalLayoutEl.children[1].style.transformOrigin = 'top left';
      } else {
        // 长宽比大于16比9,以高为基准
        const scaleHeight = (proportionalLayoutEl.clientHeight - 36) / designHeight;
        proportionalLayoutEl.children[1].style.width = `${designWidth}px`;
        proportionalLayoutEl.children[1].style.height = `${designHeight}px`;
        proportionalLayoutEl.children[1].style.transform = `scale(${scaleHeight}, ${scaleHeight}) translate(${
          (proportionalLayoutEl.clientWidth - designWidth * scaleHeight) / 2 / scaleHeight
        }px,0px)`;
        proportionalLayoutEl.children[1].style.transformOrigin = 'top left';
      }
    } else if (window.innerWidth > 1366) {
      // proportionalLayoutEl.children[0].style.width = `${window.innerWidth - 208 - 43}px`;
      proportionalLayoutEl.children[1].style.width = proportionalLayoutEl.style.width;
      proportionalLayoutEl.children[1].style.height = `${window.innerHeight - 103}px`;
      proportionalLayoutEl.children[1].style.transform = `scale(1,1)`;
      proportionalLayoutEl.children[1].style.transformOrigin = 'top left';
    }
  }

  setScale();

  // reset rem unit on page resize
  window.addEventListener('resize', setScale);

  return function unsubscribe() {
    proportionalLayoutEl.style.fontSize = 'unset';
    window.removeEventListener('resize', setScale);
  };
}
