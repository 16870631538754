import {
  Breadcrumb,
  Paging,
  useBreadcrumbRoutes,
  usePaging,
  useUpdate,
  Tag,
  Button,
  EllipsisSpan,
  message,
  Modal,
} from '@maxtropy/components';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Col, Divider, Dropdown, Form, Input, MenuProps, Select, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useLocation } from 'react-router-dom';
import BorderWrapper from '@/components/BorderWrapper';
import Filter from '@/components/Filter';
import { BsaPageReq } from '@/api/bsa';
import { getOuId, getOuListByMcid, Ou } from '@/api/ou';
import { getCustomerUserList } from '@/api/customer';
import {
  apiV2BsaSecondEditionBsaBindingFcsPost,
  apiV2BsaSecondEditionDisablePost,
  apiV2BsaSecondEditionDistributePost,
  apiV2BsaSecondEditionEnablePost,
  apiV2BsaSecondEditionInvalidationPost,
  apiV2BsaSecondEditionPageBsaSecondEditionPost,
  V2BsaSecondEditionPageBsaSecondEditionPostResponse,
} from '@maxtropy/dmes-apis-v2';
import { BSAStatus, bsaStatusFormat } from '@/api/type';
import { DownOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useHasPermission } from '@/utils/hooks';
import { PermissionsType } from '@/common/permissionsConst';
import { BsaStatus as BsaActualStatus } from '@/pages/BsaDataCollectionConfigNew/const';
import CostInfoModal from './CostInfoModal';
import styles from './index.module.scss';
import FcsSelectModal, { RowInfo } from './FcsSelectModal';
import ApplyLogModal from './ApplyLogModal';
import classNames from 'classnames';

type BsaListItem = Exclude<V2BsaSecondEditionPageBsaSecondEditionPostResponse['list'], undefined>[number];

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const formateUnitForPower = (num?: number) => {
  if (!num) return '--';
  return num < 0.5 ? `${1000 * num}kW` : `${num}MW`;
};
const formateUnitForCapacity = (num?: number) => {
  if (!num) return '--';
  return num < 1 ? `${1000 * num}kWh` : `${num}MWh`;
};

const statusColor: { [key in BSAStatus]: string } = {
  [BSAStatus.ENABLE]: 'success',
  [BSAStatus.DISABLE]: 'error',
  [BSAStatus.DRAFT]: 'warning',
  [BSAStatus.INVALID]: 'invalid',
};

const columns: ColumnsType<BsaListItem> = [
  {
    title: '储能站编号',
    dataIndex: 'serialNumber',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '储能站名称',
    dataIndex: 'name',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '装机容量',
    dataIndex: 'installedCapacity',
    render: (v: number) => <EllipsisSpan value={v ? formateUnitForCapacity(v) : '--'} />,
  },
  {
    title: '额定功率',
    dataIndex: 'ratedPower',
    render: (v: number) => <EllipsisSpan value={v ? formateUnitForPower(v) : '--'} />,
  },
  {
    title: '硬件版本',
    key: 'hardwareVersion',
    render: (_: string, record: BsaListItem) => (
      <EllipsisSpan value={`${record.hardwareVersion ?? ''}${record.hardwareName ? '-' + record.hardwareName : ''}`} />
    ),
  },
  {
    title: '所属组织',
    dataIndex: 'customerName',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '运营单元',
    dataIndex: 'ouName',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: 'UET',
    dataIndex: 'uetName',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '当前状态',
    dataIndex: 'status',
    render: (v: BSAStatus) => (
      <Tag border="solid" color={statusColor[v]}>
        {bsaStatusFormat[v]}
      </Tag>
    ),
  },
  {
    title: '上次操作时间',
    dataIndex: 'updateTime',
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format(DATE_FORMAT) : '--'} />,
  },
  {
    title: '上次操作人',
    dataIndex: 'lastUpdateUser',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const BsaListNew = () => {
  const routesContext = useBreadcrumbRoutes();
  const pagingInfo = usePaging();
  const { setTotalCount, pageSize, pageOffset, setPageOffset } = pagingInfo;
  const [form] = Form.useForm();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const bsaName = urlSearchParams.get('bsaName') || undefined;
  const [searchParams, setSearchParams] = useState<BsaPageReq>({ name: bsaName });
  const [ouList, setOuList] = useState<Ou[]>([]);
  const [data, setData] = useState<V2BsaSecondEditionPageBsaSecondEditionPostResponse['list']>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [costInfoRowId, setCostInfoRowId] = useState<number | undefined>();
  const [fcsVisible, setFcsVisible] = useState<boolean>(false);
  const [costInfoDisabled, setCostInfoDisabled] = useState<boolean>(false);
  const [bsaId, setBsaId] = useState<number>();
  const [fcsId, setFcsId] = useState<number>();
  const [applyLogVisible, setApplyLogVisible] = useState<boolean>(false);
  const timerRef = useRef<string | number | NodeJS.Timeout | undefined>();
  // 下迭代添加启用按钮再解开
  const [updateState, update] = useUpdate();

  const { data: customerUserList } = useQuery(['customerUserList'], () => getCustomerUserList());

  // 启用/禁用储能站按钮（新）
  const hasBsaEnableDisablePermission = useHasPermission(PermissionsType.B_BSA_ENABLE_DISABLE_NEW);
  // 编辑储能阵列按钮权限
  const hasBsaEditBasicPermission = useHasPermission(PermissionsType.B_BSA_INFO_EDIT_NEW);
  // 编辑储能阵列信息按钮（新）
  const hasBsaArrayEditPermission = useHasPermission(PermissionsType.B_BSA_ARRAY_EDIT_NEW);
  // 编辑储能数采信息按钮（新）
  const hasBsaDeviceEdgeInfoPermission = useHasPermission(PermissionsType.B_BSA_DEVICE_EDGE_INFO_NEW);
  // 编辑成本信息按钮（新）
  const hasBsaConstInfoEditPermission = useHasPermission(PermissionsType.B_BSA_COST_INFO_EDIT_NEW);
  // 查看成本信息按钮（新）
  const hasBsaConstInfoViewPermission = useHasPermission(PermissionsType.B_BSA_COST_INFO_VIEW_NEW);
  // 储能调度设置按钮（新）
  const hasUetSetPermission = useHasPermission(PermissionsType.B_UED_SET);
  // 批量编辑电池簇采集点按钮权限
  const hasBatchUpdateRackEdgeDevicePermission = useHasPermission(PermissionsType.B_BATCH_UPDATE_RACK_EDGE_DEVICE_NEW);
  // 批量编辑电池簇采集点按钮权限
  const hasBatchUpdatePackEdgeDevicePermission = useHasPermission(PermissionsType.B_BATCH_UPDATE_PACK_EDGE_DEVICE_NEW);
  // 批量编辑电芯采集点按钮权限
  const hasBatchUpdateCellEdgeDevicePermission = useHasPermission(PermissionsType.B_BATCH_UPDATE_CELL_EDGE_DEVICE_NEW);
  // 编辑储能配套设备按钮（新）
  const hasBsaSupportEquipEditPermission = useHasPermission(PermissionsType.B_BSA_SUPPORT_EQUIP_EDIT_NEW);

  // 启用
  const onEnableBsa = (id: number) => {
    setIsLoading(true);
    apiV2BsaSecondEditionEnablePost({ id: id })
      .then(() => {
        update();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // 停用
  const onDisableBsa = (id: number) => {
    apiV2BsaSecondEditionDisablePost({ id })
      .then(() => {
        update();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // 作废

  const onInvalidateBsa = (id: number) => {
    setIsLoading(true);
    apiV2BsaSecondEditionInvalidationPost({ id: id })
      .then(() => {
        update();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    apiV2BsaSecondEditionPageBsaSecondEditionPost({ ...searchParams, page: pageOffset, size: pageSize })
      .then(res => {
        setTotalCount(res.total ?? 0);
        setData(res?.list ?? []);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [searchParams, updateState, pageSize, pageOffset]);

  const onSelectMcid = async (v: string) => {
    form.setFieldsValue({
      ouId: undefined,
    });
    const ouType = (await getOuId())?.filter(i => i.key === 'MICRONET')[0]?.id;
    if (ouType) {
      getOuListByMcid(v, ouType).then(res => {
        setOuList(res);
      });
    }
  };

  // 绑定fcs
  const handleBindFcs = (fcsId?: number) => {
    // console.log('fcsId', fcsId);
    if (fcsId) {
      apiV2BsaSecondEditionBsaBindingFcsPost({
        neoBsaId: bsaId,
        fcsId: fcsId,
      })
        .then(_ => {
          message.success('绑定成功');
          update();
        })
        .finally(() => setFcsVisible(false));
    } else {
      Modal.warning({
        title: '请选择FCS',
      });
    }
  };

  const getBsaEditMenu = (record: BsaListItem): MenuProps['items'] => {
    return [
      {
        key: 'editBasicInfo',
        permission: hasBsaEditBasicPermission,
        label: (
          <Button
            disabled={record.actualStatus === BsaActualStatus.Invalidation}
            type="text"
            href={'/energy-storage/basic/bsaNew/basicEdit/' + record.id}
            target="_blank"
          >
            编辑基础信息
          </Button>
        ),
      },
      {
        key: 'editStruct',
        permission: hasBsaArrayEditPermission,
        label: (
          <Button
            type="text"
            disabled={
              record.actualStatus === BsaActualStatus.Invalidation ||
              !(record.actualStatus! < BsaActualStatus.COMPLETE_ARRAY_STRUCT_CONFIG)
            }
            href={'/energy-storage/basic/bsaNew/steps/edit/' + record.id}
            target="_blank"
          >
            阵列结构配置
          </Button>
        ),
      },
      {
        key: 'editDataCollection',
        hidden: !record.hasRack,
        permission: hasBsaDeviceEdgeInfoPermission,
        label: (
          <Button
            type="text"
            disabled={
              record.actualStatus === BsaActualStatus.Invalidation ||
              !(
                record.actualStatus! >= BsaActualStatus.COMPLETE_ARRAY_STRUCT_CONFIG &&
                record.actualStatus! < BsaActualStatus.COMPLETE_ARRAY_DATA_COLLECTION_CONFIG
              )
            }
            href={`/energy-storage/basic/bsaNew/editBsaDataCollectionConfigNew/${record.id}`}
            target="_blank"
          >
            阵列数采配置
          </Button>
        ),
      },
      {
        key: 'editMatchingDevice',
        permission: hasBsaSupportEquipEditPermission,
        label: (
          <Button
            type="text"
            disabled={
              record.actualStatus === BsaActualStatus.Invalidation ||
              !(record.actualStatus! >= BsaActualStatus.COMPLETE_ARRAY_DATA_COLLECTION_CONFIG)
            }
            href={`/energy-storage/basic/bsaNew/facilityEdit/${record.id}?bsaName=${record.name}`}
            target="_blank"
          >
            编辑配套设备
          </Button>
        ),
      },
      {
        key: 'editCostInfo',
        permission: hasBsaConstInfoEditPermission,
        label: (
          <Button
            type="text"
            disabled={record.actualStatus === BsaActualStatus.Invalidation}
            href=""
            onClick={e => {
              e.preventDefault();
              setCostInfoDisabled(false);
              setCostInfoRowId(record.id);
            }}
          >
            编辑成本信息
          </Button>
        ),
      },
      {
        key: 'energyManagement',
        permission: hasUetSetPermission,
        label: (
          <Button
            type="text"
            disabled={record.actualStatus === BsaActualStatus.Invalidation}
            href={
              window.IDSPLATFORMORIGIN + '/energyAssetConstruction/energyTopologyManagement/energyDispatchManagement'
            }
            target="_blank"
          >
            储能调度设置
          </Button>
        ),
      },
      {
        key: 'relationFcs',
        permission: hasUetSetPermission,
        label: (
          <Button
            type="text"
            href=""
            disabled={record.status === BSAStatus.INVALID || record.status === BSAStatus.DRAFT}
            onClick={e => {
              e.preventDefault();
              setFcsVisible(true);
              setBsaId(record.id);
              setFcsId(record.fcsId);
            }}
          >
            关联FCS
          </Button>
        ),
      },
    ]
      .filter(item => item.permission !== false)
      .filter(item => !item.hidden)
      .map(item => {
        return {
          key: item.key,
          label: item.label,
        };
      });
  };

  const getBsaViewMenu = (record: BsaListItem): MenuProps['items'] => {
    return [
      {
        key: 'viewBasicInfo',
        label: (
          <Button type="text" href={'/energy-storage/basic/bsaNew/basicDetail/' + record.id} target="_blank">
            查看基础信息
          </Button>
        ),
      },
      {
        key: 'viewStruct',
        label: (
          <Button
            type="text"
            disabled={!(record.actualStatus! >= BsaActualStatus.COMPLETE_ARRAY_STRUCT_CONFIG)}
            href={'/energy-storage/basic/bsaNew/steps/view/' + record.id}
            target="_blank"
          >
            查看阵列结构配置
          </Button>
        ),
      },
      {
        key: 'viewDataCollection',
        hidden: !record.hasRack,
        label: (
          <Button
            type="text"
            disabled={!(record.actualStatus! >= BsaActualStatus.COMPLETE_ARRAY_DATA_COLLECTION_CONFIG)}
            href={`/energy-storage/basic/bsaNew/viewBsaDataCollectionConfigNew/${record.id}?step=0`}
            target="_blank"
          >
            查看阵列数采配置
          </Button>
        ),
      },
      {
        key: 'viewMatchingDevice',
        label: (
          <Button
            type="text"
            href={`/energy-storage/basic/bsaNew/facilityDetail/${record.id}?bsaName=${record.name}`}
            target="_blank"
          >
            查看配套设备
          </Button>
        ),
      },
      {
        key: 'viewCostInfo',
        permission: hasBsaConstInfoViewPermission,
        label: (
          <Button
            type="text"
            onClick={() => {
              setCostInfoDisabled(true);
              setCostInfoRowId(record.id);
            }}
          >
            查看成本信息
          </Button>
        ),
      },
    ]
      .filter(item => item.permission !== false)
      .filter(item => !item.hidden)
      .map(item => {
        return { key: item.key, label: item.label };
      });
  };

  const [applying, setApplying] = useState<boolean>(false);
  const clickBtn = async (id?: number) => {
    setApplying(true);
    Modal.confirm({
      title: '确定下发?',
      onOk: () => {
        confirmBtn(id);
      },
    });
  };

  useEffect(() => {
    if (applying) {
      timerRef.current = setInterval(() => {
        setApplying(false);
      }, 30000);
    }
    return () => clearInterval(timerRef.current);
  }, [applying]);

  // 点击了确定下发
  const confirmBtn = (id?: number) => {
    if (id) {
      return apiV2BsaSecondEditionDistributePost({ id })
        .then(_ => {
          message.success({
            content: '配置正在下发，请在下发记录中查看下发结果。',
          });
          update();
        })
        .finally(() => setApplying(false));
    }
  };

  const [distributeRecord, setDistributeRecord] = useState<BsaListItem>();

  const getBsaDistributeMenu = (record: BsaListItem): MenuProps['items'] => {
    return [
      {
        key: 'configurationdistribution',
        label: (
          <Button
            type="text"
            disabled={
              !record.ouAuthority ||
              record.status === BSAStatus.INVALID ||
              record.status === BSAStatus.DRAFT ||
              applying
            }
            onClick={() => clickBtn(record.id)}
          >
            {applying ? (
              <div>配置正在下发</div>
            ) : (
              <div
                className={classNames({
                  [styles.danger]:
                    !record.ouAuthority ||
                    record.status === BSAStatus.INVALID ||
                    record.status === BSAStatus.DRAFT ||
                    applying
                      ? false
                      : record.needDistribute,
                })}
              >
                配置及阵列下发
              </div>
            )}
          </Button>
        ),
      },
      {
        key: 'distributionRecords',
        // permission: hasBsaConstInfoViewPermission,
        label: (
          <Button
            type="text"
            onClick={() => {
              setDistributeRecord(record);
              setApplyLogVisible(true);
            }}
          >
            下发记录
          </Button>
        ),
      },
    ].map(item => {
      return { key: item.key, label: item.label };
    });
  };

  const getDataCollectionEditMenu = (record: BsaListItem): MenuProps['items'] => {
    return [
      {
        key: 'rackDataCollection',
        permission: hasBatchUpdateRackEdgeDevicePermission,
        hidden: !record.hasRack,
        label: (
          <Button
            type="text"
            disabled={
              record.actualStatus === BsaActualStatus.Invalidation ||
              record.actualStatus! < BsaActualStatus.COMPLETE_RACK_DATA_POINT_CONFIG
            }
            href={`/energy-storage/basic/bsaNew/editBsaDataCollectionConfigNew/${record.id}?step=1&showSingleStep=true`}
            target="_blank"
          >
            批量编辑电池簇采集点
          </Button>
        ),
      },
      {
        key: 'packDataCollection',
        permission: hasBatchUpdatePackEdgeDevicePermission,
        hidden: !record.hasPack,
        label: (
          <Button
            type="text"
            disabled={
              record.actualStatus === BsaActualStatus.Invalidation ||
              record.actualStatus! < BsaActualStatus.COMPLETE_PACK_DATA_POINT_CONFIG
            }
            href={`/energy-storage/basic/bsaNew/editBsaDataCollectionConfigNew/${record.id}?step=2&showSingleStep=true`}
            target="_blank"
          >
            批量编辑电池组采集点
          </Button>
        ),
      },
      {
        key: 'cellDataCollection',
        permission: hasBatchUpdateCellEdgeDevicePermission,
        hidden: !record.hasCell,
        label: (
          <Button
            type="text"
            disabled={
              record.actualStatus === BsaActualStatus.Invalidation ||
              record.actualStatus! < BsaActualStatus.COMPLETE_ARRAY_DATA_COLLECTION_CONFIG
            }
            href={`/energy-storage/basic/bsaNew/editBsaDataCollectionConfigNew/${record.id}?step=3&showSingleStep=true`}
            target="_blank"
          >
            批量编辑电芯采集点
          </Button>
        ),
      },
    ]
      .filter(item => item.permission !== false)
      .filter(item => !item.hidden)
      .map(item => {
        return { key: item.key, label: item.label };
      });
  };

  const allColumns = columns.concat([
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      render: (_: any, record: BsaListItem) => (
        <>
          <Space split={<Divider type="vertical" />} className={styles.actionWrap}>
            <Dropdown
              overlayClassName={styles.actionDropdown}
              menu={{ items: getBsaEditMenu(record) }}
              disabled={!record.ouAuthority}
            >
              <a onClick={e => e.preventDefault()}>
                <Space size={6}>
                  <span>储能站编辑</span>
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>

            {!!getDataCollectionEditMenu(record)?.length && (
              <Dropdown
                overlayClassName={styles.actionDropdown}
                menu={{ items: getDataCollectionEditMenu(record) }}
                disabled={!record.ouAuthority}
              >
                <a onClick={e => e.preventDefault()}>
                  <Space>
                    <span>数采点编辑</span>
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            )}

            <Dropdown overlayClassName={styles.actionDropdown} menu={{ items: getBsaViewMenu(record) }}>
              <a onClick={e => e.preventDefault()}>
                <Space size={6}>
                  <span>查看</span>
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
            {hasBsaEnableDisablePermission &&
              (record.actualStatus === BsaActualStatus.COMPLETE_ARRAY_DATA_COLLECTION_CONFIG ||
                record.actualStatus === BsaActualStatus.DISABLE) && (
                <Button type="link" onClick={() => onEnableBsa(record.id!)} disabled={!record.ouAuthority}>
                  启用
                </Button>
              )}

            {hasBsaEnableDisablePermission && record.actualStatus === BsaActualStatus.ENABLE && (
              <Button type="link" onClick={() => onDisableBsa(record.id!)} disabled={!record.ouAuthority}>
                停用
              </Button>
            )}

            {hasBsaEnableDisablePermission &&
              record.actualStatus !== BsaActualStatus.ENABLE &&
              record.actualStatus !== BsaActualStatus.Invalidation && (
                <Button type="link" onClick={() => onInvalidateBsa(record.id!)} disabled={!record.ouAuthority}>
                  作废
                </Button>
              )}

            <Dropdown overlayClassName={styles.actionDropdown} menu={{ items: getBsaDistributeMenu(record) }}>
              <a onClick={e => e.preventDefault()}>
                <Space size={6}>
                  <span
                    className={classNames({
                      [styles.danger]:
                        !record.ouAuthority || record.status === BSAStatus.INVALID || record.status === BSAStatus.DRAFT
                          ? false
                          : record.needDistribute,
                    })}
                  >
                    下发
                  </span>
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </Space>
        </>
      ),
    },
  ]);

  return (
    <div className={styles.page_box}>
      <div className="page">
        <div className="page__header">
          <Breadcrumb routes={[...(routesContext?.routes ?? [])]} />
        </div>
        <BorderWrapper>
          <div className="page__filter">
            <Filter
              form={form}
              onFinish={values => {
                setSearchParams(values);
                setPageOffset(1);
              }}
              onReset={() => {
                setSearchParams({});
                setPageOffset(1);
              }}
            >
              <Col span={6}>
                <Form.Item label="组织名称" name="mcid">
                  <Select placeholder="请选择" onSelect={onSelectMcid}>
                    {customerUserList?.map(i => (
                      <Select.Option key={i.mcid} value={i.mcid}>
                        {i.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="运营单元" name="ouId">
                  <Select placeholder="请选择">
                    {ouList.map(i => (
                      <Select.Option key={i.id} value={i.id}>
                        {i.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="储能站名称" name="name">
                  <Input placeholder="请输入储能站名称" />
                </Form.Item>
              </Col>
            </Filter>
          </div>

          <div className="page__content">
            <Table
              rowKey="id"
              columns={allColumns}
              dataSource={data}
              loading={isLoading}
              scroll={{ x: 'max-content' }}
              pagination={false}
            ></Table>
            <Paging pagingInfo={pagingInfo}></Paging>
          </div>

          <CostInfoModal id={costInfoRowId} setId={setCostInfoRowId} disabled={costInfoDisabled}></CostInfoModal>
          {fcsVisible && (
            <FcsSelectModal
              bsaId={bsaId}
              bindFcsId={fcsId}
              onCancel={() => setFcsVisible(false)}
              onConfirm={fcsId => {
                handleBindFcs(fcsId);
              }}
            />
          )}
          <ApplyLogModal
            visible={applyLogVisible}
            onCancel={() => {
              setApplyLogVisible(false);
              setDistributeRecord(undefined);
            }}
            id={distributeRecord?.id}
          />
        </BorderWrapper>
      </div>
    </div>
  );
};

export default BsaListNew;
