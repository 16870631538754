import {
  Paging,
  TenantType,
  useBreadcrumbRoutes,
  useCurrent,
  useSearchParams,
  useUpdate,
  Wrapper,
  Select,
  Form,
  Radio,
} from '@maxtropy/components';
import { Col, Divider, RadioChangeEvent, Row, Statistic } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';
import CardList from './components/CardList';
import TableList from './components/TableList';
import { getOuId } from '@/api/ou';
import { formateValuesToFix1, GWFormat, GwhFormat } from './utils';
import { AppstoreOutlined, UnorderedListOutlined } from '@ant-design/icons';
import {
  apiV2DevopsPvStationDashboardPost,
  apiV2DevopsPvStationGetTenantListPost,
  apiV2DevopsPvStationPvStationCountPost,
  apiV2DevopsPvStationPvStationSelectListPost,
  apiV2OuListWithTenantPost,
  V2DevopsPvStationDashboardPostRequest,
  V2DevopsPvStationDashboardPostResponse,
  V2DevopsPvStationGetTenantListPostResponse,
  V2DevopsPvStationPvStationCountPostResponse,
  V2DevopsPvStationPvStationSelectListPostResponse,
} from '@maxtropy/dmes-apis-v2';
import { useRequest } from 'ahooks';
import CustomStatic from './components/CustomStatic';

const formLayout = {
  wrapperCol: { span: 18 },
};

export interface PVStationDashBoardPageSearchParams
  extends Omit<V2DevopsPvStationDashboardPostRequest, 'pageNum' | 'pageSize'> {}
export type PvStationItem = Exclude<V2DevopsPvStationDashboardPostResponse['list'], undefined>[number];
export type PvStationSelectItem = Exclude<V2DevopsPvStationPvStationSelectListPostResponse['list'], undefined>[number];
export type TenantItem = Exclude<V2DevopsPvStationGetTenantListPostResponse['list'], undefined>[number];

const PVOperationDashBoard = () => {
  const routesContext = useBreadcrumbRoutes();
  const [type, setType] = useState<string>('card');

  const [searchForm] = Form.useForm(); // 搜索的表单

  const pagingListInfo = useSearchParams<PVStationDashBoardPageSearchParams>(50);
  const pagingCardInfo = useSearchParams<PVStationDashBoardPageSearchParams>(12);
  const {
    pageOffset: pageListOffset,
    pageSize: pageListSize,
    setTotalCount: setListTotalCount,
    setPageOffset: setListPageOffset,
  } = pagingListInfo;
  const {
    pageOffset: pageCardOffset,
    pageSize: pageCardSize,
    setTotalCount: setCardTotalCount,
    setPageOffset: setCardPageOffset,
  } = pagingCardInfo;

  const [data, setData] = useState<PvStationItem[]>([]); // 后端返回数据
  const [isLoading, setIsLoading] = useState<boolean>(false); // 加载状态
  const [pvStationValue, setPVStationValue] = useState<number[]>([]);
  const [ouIds, setOuIds] = useState<number[]>([]);
  const [pVStationSelectList, setPVStationSelectList] = useState<PvStationSelectItem[]>([]);
  const [pVStationCountData, setPVStationCountData] = useState<V2DevopsPvStationPvStationCountPostResponse>();
  const [tenantOptions, setTenantOptions] = useState<{ label: string; value: string }[]>([]);
  const [tenantMcids, setTenantMcids] = useState<string[]>([]);

  const current = useCurrent();
  const isChannel = current?.tenant?.tenantType === TenantType.CHANNEL;

  const timer = useRef<number>();
  const [refreshState, refresh] = useUpdate();

  // 每隔1min执行一次请求
  useEffect(() => {
    if (!isLoading) {
      clearTimeout(timer.current);
      timer.current = window.setTimeout(() => {
        refresh();
      }, 60000);
      return () => clearTimeout(timer.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (type === 'list') {
      setIsLoading(true);
      // 储能站看板分页
      apiV2DevopsPvStationDashboardPost({
        tenantMcids: tenantMcids,
        ids: pvStationValue,
        ouIds: ouIds,
        pageNum: pageListOffset,
        pageSize: pageListSize,
      }).then(res => {
        setData(res?.list ?? []);
        setListTotalCount(res.total ?? 0);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantMcids, pvStationValue, type, pageListOffset, pageListSize, ouIds, refreshState]);

  useEffect(() => {
    if (type === 'card') {
      setIsLoading(true);
      // 储能站看板分页
      apiV2DevopsPvStationDashboardPost({
        tenantMcids: tenantMcids,
        ids: pvStationValue,
        ouIds: ouIds,
        pageNum: pageCardOffset,
        pageSize: pageCardSize,
      }).then(res => {
        if (res) {
          setData(res.list ?? []);
          setIsLoading(false);
          setCardTotalCount(res.total ?? 0);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantMcids, pvStationValue, type, pageCardOffset, pageCardSize, ouIds, refreshState]);

  useEffect(() => {
    if (current?.tenant?.tenantType === TenantType.TENANT) return;
    apiV2DevopsPvStationGetTenantListPost().then(res => {
      setTenantOptions((res.list ?? []).map(item => ({ label: item.tenantName!, value: item.tenantMcid! })));
    });
  }, [current]);

  // 光伏站数据总览
  useEffect(() => {
    apiV2DevopsPvStationPvStationCountPost({ tenantMcids: tenantMcids }).then(res => {
      setPVStationCountData(res);
    });
  }, [tenantMcids]);

  const { data: ouOptions } = useRequest(
    async () => {
      const ouType = (await getOuId())?.filter((i: any) => i.key === 'MICRONET')[0]?.id;
      if (!ouType) return [];
      const ouList = await apiV2OuListWithTenantPost({
        type: ouType,
        tenantMcids: isChannel ? tenantMcids : undefined,
      }).then(res => res.list ?? []);
      return ouList.map((i: any) => ({ label: i.name, value: i.id }));
    },
    {
      refreshDeps: [isChannel, tenantMcids],
    }
  );

  useEffect(() => {
    apiV2DevopsPvStationPvStationSelectListPost({ tenantMcids: isChannel ? tenantMcids : undefined }).then(res => {
      setPVStationSelectList(res?.list ?? []);
    });
  }, [isChannel, tenantMcids]);

  // pVStation下拉列表
  const pvStationOptions = useMemo(() => {
    if (pVStationSelectList && pVStationSelectList.length !== 0) {
      return pVStationSelectList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [pVStationSelectList]);

  const filters = (
    <Form isOrigin form={searchForm} layout="horizontal" labelAlign={'left'} {...formLayout}>
      <Row>
        {isChannel && (
          <Col span={6}>
            <Form.Item name="tenantMcids" label="租户">
              <Select
                placeholder="请选择"
                options={tenantOptions}
                mode="multiple"
                showSearch
                maxTagCount="responsive"
                optionFilterProp="label"
                allowClear
                onChange={(value: string[]) => setTenantMcids(value)}
                onClear={() => {
                  setTenantMcids([]);
                  setListPageOffset(1);
                  setCardPageOffset(1);
                }}
              />
            </Form.Item>
          </Col>
        )}

        <Col span={6}>
          <Form.Item name="ids" label="光伏站">
            <Select
              placeholder="请选择"
              options={pvStationOptions}
              mode="multiple"
              showSearch
              maxTagCount="responsive"
              optionFilterProp="label"
              allowClear
              onChange={(value: number[]) => setPVStationValue(value)}
              onClear={() => {
                setPVStationValue([]);
                setListPageOffset(1);
                setCardPageOffset(1);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="ouIds" label="运营单元">
            <Select
              placeholder="请选择"
              options={ouOptions}
              showSearch
              optionFilterProp="label"
              mode="multiple"
              maxTagCount="responsive"
              allowClear
              onChange={(value: number[]) => setOuIds(value)}
              onClear={() => {
                setOuIds([]);
                setListPageOffset(1);
                setCardPageOffset(1);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  return (
    <Wrapper routes={routesContext?.routes} className={styles.wrapper}>
      <Row className={styles.DashBoardStyle}>
        <Col className={styles.DashBoardColStyle} flex={1}>
          <Statistic
            title={`实时功率（${GWFormat(pVStationCountData?.realTimePower).unit}）`}
            value={GWFormat(pVStationCountData?.realTimePower!).value}
          />
        </Col>
        <Col className={styles.DashBoardColStyle} flex={1}>
          <CustomStatic
            title="今日发电量"
            parallelVal={pVStationCountData?.parallelChargeByDay}
            inverterVal={pVStationCountData?.inverterChargeByDay}
          />
        </Col>

        <Col className={styles.DashBoardColStyle} flex={1}>
          <CustomStatic
            title="本月发电量"
            parallelVal={pVStationCountData?.parallelChargeByMonth}
            inverterVal={pVStationCountData?.inverterChargeByMonth}
          />
        </Col>
        <Col className={styles.DashBoardColStyle} flex={1}>
          <CustomStatic
            title="本年发电量"
            parallelVal={pVStationCountData?.parallelChargeByYear}
            inverterVal={pVStationCountData?.inverterChargeByYear}
          />
        </Col>
        <Col className={styles.DashBoardColStyle} flex={1}>
          <Statistic
            title="本年二氧化碳排放（t）"
            value={formateValuesToFix1(pVStationCountData?.carbonReducedByYear)}
          />
        </Col>
      </Row>
      <div className={styles.typeChangeStyle}>
        <Radio.Group
          value={type}
          onChange={(e: RadioChangeEvent) => setType(e.target.value)}
          className={styles.typeChangeStyleRadioGroup}
        >
          <Radio.Button value="card" style={{ border: type === 'card' ? '1px solid' : '1px solid #ffffff60' }}>
            <AppstoreOutlined />
            <span style={{ marginLeft: 2 }}>卡片模式</span>
          </Radio.Button>
          <Radio.Button value="list" style={{ border: type === 'list' ? '1px solid' : '1px solid #ffffff60' }}>
            <UnorderedListOutlined />
            <span style={{ marginLeft: 2 }}>列表模式</span>
          </Radio.Button>
        </Radio.Group>
      </div>
      <div className={styles.pageFilter}>{filters}</div>
      <div className={styles.dividerSty}>
        <Divider />
      </div>
      {type === 'card' ? (
        <CardList isLoading={isLoading} data={data} isChannel={isChannel} />
      ) : (
        <TableList isLoading={isLoading} data={data} isChannel={isChannel} />
      )}
      {type === 'list' ? (
        <Paging pagingInfo={pagingListInfo} />
      ) : (
        <Paging pageSizeOptions={['12', '24', '48']} pagingInfo={pagingCardInfo} />
      )}
    </Wrapper>
  );
};

export default PVOperationDashBoard;
