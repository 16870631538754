import ShowInput from '@/components/ShowInput';
import { Form } from 'antd';
import { BaseInfoIprops } from '..';
import DividerContent from '@/components/DividerContent';
interface Iprops {
  detail?: BaseInfoIprops;
}
const BaseDetail: React.FC<Iprops> = ({ detail }) => {
  return (
    <>
      <DividerContent title="基础资料">
        <Form.Item label="光伏站名称" name="name">
          <ShowInput />
        </Form.Item>
        <Form.Item label="所属组织" name="customerName">
          <ShowInput />
        </Form.Item>
        <Form.Item label="运营单元" name="ouName">
          <ShowInput />
        </Form.Item>
        <Form.Item label="装机容量" name="installedCapacity">
          <ShowInput addonAfter="kW" />
        </Form.Item>
        <Form.Item label="投运日期" name="createStationTime">
          <ShowInput />
        </Form.Item>
        <Form.Item label="详细地址">{(detail?.location ?? '') + ' ' + (detail?.address ?? '')}</Form.Item>
        <Form.Item label="备注" name="remark">
          <ShowInput />
        </Form.Item>
      </DividerContent>
      <DividerContent title="参数设置">
        <Form.Item label="STC参考光强" name="referenceLighting">
          <ShowInput addonAfter={'W/㎡'} />
        </Form.Item>
        <Form.Item label="碳排因子" name="carbonEmissionFactor">
          <ShowInput addonAfter="kgCO2/(kWh)" />
        </Form.Item>
        <Form.Item label="太阳能板面积" name="solarPanelArea">
          <ShowInput addonAfter="㎡" />
        </Form.Item>
        <Form.Item label="转化效率" name="conversionEfficiency">
          <ShowInput addonAfter="%" />
        </Form.Item>
      </DividerContent>
    </>
  );
};
export default BaseDetail;
