import { FC, useEffect, useMemo, useState } from 'react';
import { Affix, Form, Space } from 'antd';
import { Button, message, Modal, MXTabsProps, Select, Tabs } from '@maxtropy/components';
import TopConfig from '../components/TopConfig';
import {
  apiV2BsaSecondEditionForwardPost,
  apiV2NeoBsaDataPointAllDataPropertyPost,
  apiV2NeoBsaDataPointCreatePointPost,
  apiV2NeoBsaDataPointGetConfTemplatePost,
  apiV2NeoBsaDataPointStackListPost,
  apiV2NeoBsaDataPointTemplateAllPost,
  apiV2NeoBsaDataPointTemplateInsertPost,
  apiV2NeoBsaDataPointVersionTemplateListPost,
  apiV2NeoBsaDataPointVersionTemplatePointPost,
  apiV2NeoBsaDeviceGetCreateStatusPost,
  V2BsaSecondEditionInfoBsaSecondEditionPostResponse,
  V2NeoBsaDataPointAllDataPropertyPostResponse,
  V2NeoBsaDataPointStackListPostResponse,
  V2NeoBsaDataPointTemplateInsertPostRequest,
  V2NeoBsaDataPointVersionTemplateListPostResponse,
} from '@maxtropy/dmes-apis-v2';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { BatteryType, BsaStatus, ColumnStatusEnum, PointType, Step } from '../const';
import DataTable from './DataTable';
import HeadStep from '../components/HeadStep';
import { cloneDeep, isNil } from 'lodash-es';
import { v4 } from 'uuid';
import CreatingModal from '@/pages/BsaDataCollectionConfigNew/components/CreatingModal';
import { CheckCircleOutlined, CloseCircleFilled } from '@ant-design/icons';

interface Props {
  batteryType: BatteryType;
  bsaData: V2BsaSecondEditionInfoBsaSecondEditionPostResponse | undefined;
}

type Template = Exclude<V2NeoBsaDataPointVersionTemplateListPostResponse['list'], undefined>[number];
type Stack = Exclude<V2NeoBsaDataPointStackListPostResponse['list'], undefined>[number];
type StackPoint = Exclude<
  V2NeoBsaDataPointTemplateInsertPostRequest['bsaEdgeDevicePointTemplateModbusTcps'],
  undefined
>[number];
export interface StackPointWithUuid extends StackPoint {
  templates: (Exclude<StackPoint['templates'], undefined>[number] & { uuid: string; status: ColumnStatusEnum })[];
}
export type DataProperty = Exclude<V2NeoBsaDataPointAllDataPropertyPostResponse['list'], undefined>[number];

// null-未创建，0-生成中，1-完成，2-失败
export type CreateStatus = null | 0 | 1 | 2;
export const CreateStatusEnum = {
  NOT: null,
  CREATING: 0,
  FINISH: 1,
  FAIL: 2,
};

const DataPointConfig: FC<Props> = props => {
  const { batteryType, bsaData } = props;
  const { id } = useParams<{ id: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  // showSingleStep：只显示单步，从批量编辑电池簇/组/芯跳过
  const showSingleStep = searchParams.get('showSingleStep') === 'true';
  const navigate = useNavigate();
  const [form] = useForm();

  const currentStep = Number(searchParams.get('step'));
  const [creatStatus, setCreatStatus] = useState<CreateStatus>();

  // 模板列表
  const [templateList, setTemplateList] = useState<Template[]>([]);
  // 电池堆列表
  const [stackList, setStackList] = useState<Stack[]>([]);
  // 电池数据点列表
  const [stackPointList, setStackPointList] = useState<StackPointWithUuid[]>([]);
  // 数据属性列表
  const [dataPropertiesAll, setDataPropertiesAll] = useState<DataProperty[]>([]);
  // 回显的模板id
  const [echoTemplateId, setEchoTemplateId] = useState<number>();

  // 是否完成创建
  const hasCreated = useMemo(() => {
    // 创建状态完成了，或者作废了，或者是查看状态
    return (
      creatStatus === CreateStatusEnum.FINISH ||
      bsaData?.actualStatus === BsaStatus.Invalidation ||
      window.location.pathname.includes('view')
    );
  }, [creatStatus, bsaData]);

  // 获取创建状态
  const fetchCreateStatus = () => {
    apiV2NeoBsaDeviceGetCreateStatusPost({ neoBsaId: +id }).then(res => {
      if (batteryType === BatteryType.RACK) {
        setCreatStatus(res.rackDataPointCreateStatus as CreateStatus);
      } else if (batteryType === BatteryType.PACK) {
        setCreatStatus(res.packDataPointCreateStatus as CreateStatus);
      } else if (batteryType === BatteryType.CELL) {
        setCreatStatus(res.cellDataPointCreateStatus as CreateStatus);
      }
    });
  };

  // 选择模板时，获取数据属性列表，和点列表
  const templateId = Form.useWatch('templateId', form);
  useEffect(() => {
    if (!id || !templateId || !batteryType) return;
    apiV2NeoBsaDataPointAllDataPropertyPost({ neoBsaId: +id, bsaDeviceType: batteryType }).then(res => {
      setDataPropertiesAll(res?.list?.filter(i => !i.used) || []);
    });
  }, [id, templateId, batteryType]);

  useEffect(() => {
    // 获取模板下拉列表
    apiV2NeoBsaDataPointVersionTemplateListPost({ neoBsaId: +id, bsaDeviceType: batteryType }).then(res => {
      setTemplateList(res.list || []);
    });
    // 获取电池堆列表
    apiV2NeoBsaDataPointStackListPost({ id: +id })
      .then(res => {
        setStackList(res.list || []);
        return res.list || [];
      })
      .then(stackList => {});
    // 获取顶部信息-拓扑结构、驱动类型、选择模板
    apiV2NeoBsaDataPointGetConfTemplatePost({ neoBsaId: +id, type: batteryType }).then(res => {
      form.setFieldsValue({
        topologicalType: res.topologicalType,
        driveType: res.driveType,
        templateId: res.templateId,
      });
      setEchoTemplateId(res.templateId);
    });

    // 创建状态
    fetchCreateStatus();
  }, []);

  // 数据点回显，在获取完电池堆和模板之后
  useEffect(() => {
    if (!stackList.length || !echoTemplateId) return;
    apiV2NeoBsaDataPointTemplateAllPost({
      neoBsaId: +id,
      type: batteryType,
    }).then(res => {
      const newStackPointList = cloneDeep(res?.list ?? []) as StackPointWithUuid[];
      // 如果采集点、虚拟点、静态点之一没有添加，后端就不返回，需要进行补全
      function fillStackPointList(stack: Stack, pointType: PointType) {
        const found = newStackPointList.find(item => item.stackId === stack.id && item.pointType === pointType);
        if (!found) {
          newStackPointList.push({
            bsaId: +id,
            pointType: pointType,
            type: batteryType,
            stackId: stack.id,
            stackSequence: stack.sequence,
            templates: [],
          });
        }
      }
      stackList.forEach(stack => {
        fillStackPointList(stack, PointType.COLLECTION);
        fillStackPointList(stack, PointType.VIRTUAL);
        fillStackPointList(stack, PointType.STATIC);
      });
      newStackPointList.forEach(stack => {
        stack.templates.forEach(template => {
          template.uuid = v4();
          template.status = ColumnStatusEnum.NONE;
        });
      });
      setStackPointList(newStackPointList);
    });
  }, [stackList, echoTemplateId]);

  // 轮询创建状态
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (creatStatus === CreateStatusEnum.CREATING) {
      interval = setInterval(fetchCreateStatus, 3000);
    }
    if (creatStatus === CreateStatusEnum.FAIL) {
      Modal.confirm({
        title: '批量创建数采失败，是否重新创建',
        icon: <CloseCircleFilled style={{ color: '#c93e3e' }} />,
        cancelText: '否',
        okText: '是',
        onOk: () => {
          onCreatePoints();
        },
      });
    }
    return () => clearInterval(interval);
  }, [creatStatus, form]);

  const tabItems: MXTabsProps['items'] = stackList.map(item => {
    return {
      key: String(item.id),
      label: '电池堆' + item.sequence!,
      children: (
        <DataTable
          stackId={item.id!}
          topologicalType={form.getFieldValue('topologicalType')}
          batteryType={batteryType}
          stackPointList={stackPointList}
          setStackPointList={setStackPointList}
          dataPropertiesAll={dataPropertiesAll}
          hasCreated={hasCreated}
        ></DataTable>
      ),
    };
  });

  // 选择模板，把数据插入表格中
  const onViewDataCollectionPoint = (templateId: number) => {
    if (!form.getFieldValue('templateId')) {
      message.warning('请选择模板');
      return;
    }
    apiV2NeoBsaDataPointVersionTemplatePointPost({ id: templateId }).then(res => {
      // 遍历电池堆，插入采集点、虚拟点、静态点
      const insertPointType = (pointType: PointType): StackPointWithUuid[] => {
        return stackList.map(item => {
          return {
            bsaId: +id,
            pointType: pointType,
            type: batteryType,
            stackId: item.id,
            stackSequence: item.sequence,
            templates: (res?.list || [])
              .filter((item: any) => item.pointType === pointType)
              .map(templatePoint => {
                return {
                  ...templatePoint,
                  ...(templatePoint as any).parameters,
                  c: undefined,
                  x: undefined,
                  y: undefined,
                  z: undefined,
                  w: undefined,
                  uuid: v4(),
                  status: ColumnStatusEnum.NONE,
                };
              }),
          };
        });
      };
      let stackPointList: StackPointWithUuid[] = [];
      stackPointList = stackPointList.concat(insertPointType(PointType.COLLECTION));
      stackPointList = stackPointList.concat(insertPointType(PointType.VIRTUAL));
      stackPointList = stackPointList.concat(insertPointType(PointType.STATIC));
      setStackPointList(stackPointList);
    });
  };

  const onPre = () => {
    setSearchParams({ step: String(currentStep - 1) });
  };

  const isFinalStep = useMemo(() => {
    if (!bsaData) return true;
    let totalStepCount = 1;
    if (bsaData.hasCell) totalStepCount = 4;
    else if (bsaData.hasPack) totalStepCount = 3;
    else if (bsaData.hasRack) totalStepCount = 2;
    return currentStep === totalStepCount - 1;
  }, [currentStep, bsaData]);

  const onNext = useMemo(() => {
    if (isFinalStep) return undefined; // 最后一步没有下一步
    return () => {
      let forwardPostFlag = true;
      if (currentStep === Step.RACK && bsaData?.actualStatus! >= BsaStatus.COMPLETE_RACK_DATA_POINT_CONFIG) {
        forwardPostFlag = false;
      }
      if (currentStep === Step.PACK && bsaData?.actualStatus! >= BsaStatus.COMPLETE_PACK_DATA_POINT_CONFIG) {
        forwardPostFlag = false;
      }
      if (forwardPostFlag) {
        apiV2BsaSecondEditionForwardPost({
          id: +id,
          status: bsaData?.actualStatus,
        }).then(() => {
          setSearchParams({ step: String(currentStep + 1) });
        });
      } else {
        setSearchParams({ step: String(currentStep + 1) });
      }
    };
  }, [currentStep, bsaData, isFinalStep, hasCreated]);

  const onSave = useMemo(() => {
    // 不是最后一步没有保存
    if (!isFinalStep) return undefined;
    // 配置完成了没有保存
    if (bsaData?.actualStatus! >= BsaStatus.COMPLETE_ARRAY_DATA_COLLECTION_CONFIG) return undefined;
    return () => {
      apiV2BsaSecondEditionForwardPost({
        id: +id,
        status: bsaData?.actualStatus,
      }).then(() => {
        message.success('创建完成');
        navigate('/energy-storage/basic/bsaNew');
      });
    };
  }, [currentStep, bsaData, isFinalStep]);

  const onDraft = useMemo(() => {
    if (creatStatus === undefined) return undefined; // 创建状态还没获取到的时候不显示
    if (hasCreated) return undefined; // 已经创建完成了不显示
    else
      return () => {
        form.validateFields().then(() => {
          apiV2NeoBsaDataPointTemplateInsertPost({
            neoBsaId: +id,
            templateId: templateId,
            type: batteryType,
            bsaEdgeDevicePointTemplateModbusTcps: stackPointList,
          }).then(() => {
            message.success('草稿保存成功');
          });
        });
      };
  }, [creatStatus, hasCreated, templateId, stackPointList]);

  const onCreatePoints = () => {
    let pass = true;
    stackPointList
      .filter(item => item.pointType === PointType.COLLECTION)
      .forEach(stackPoint => {
        stackPoint.templates.forEach(item => {
          if (isNil(item.c)) pass = false;
          if (isNil(item.objectType)) pass = false;
        });
      });
    stackPointList
      .filter(item => item.pointType === PointType.VIRTUAL)
      .forEach(stackPoint => {
        stackPoint.templates.forEach(item => {
          if (isNil(item.formula)) pass = false;
        });
      });
    if (!pass) {
      message.error('列表中数采信息不完整，请补充');
      return;
    }

    apiV2NeoBsaDataPointCreatePointPost({
      neoBsaId: +id,
      templateId: templateId,
      type: batteryType,
      bsaEdgeDevicePointTemplateModbusTcps: stackPointList,
    }).then(() => {
      setCreatStatus(0);
      fetchCreateStatus();
    });
  };

  return (
    <>
      {!showSingleStep && (
        <HeadStep
          title={bsaData?.name + '储能站阵列数采配置'}
          bsaData={bsaData}
          current={currentStep}
          onPre={onPre}
          onNext={onNext}
          onSave={onSave}
          onDraft={onDraft}
          nextDisabled={!hasCreated}
        ></HeadStep>
      )}

      <Form form={form} labelCol={{ flex: '140px' }} labelAlign="left">
        <TopConfig disabled={true}></TopConfig>
        <Form.Item label="选择模板" required>
          <Space>
            <Form.Item noStyle name="templateId" rules={[{ required: true, message: '请选择' }]}>
              <Select
                style={{ width: 464 }}
                placeholder="请选择"
                disabled={hasCreated}
                options={templateList.map(item => {
                  return {
                    value: item.id,
                    label: `${item.templateName}（版本号：${item.version}）`,
                  };
                })}
                onChange={value => {
                  onViewDataCollectionPoint(value);
                }}
              ></Select>
            </Form.Item>
          </Space>
        </Form.Item>
      </Form>

      {/*有数据点才显示*/}
      {!!stackPointList.length && <Tabs items={tabItems}></Tabs>}

      {/*没有创建完成，并且有数据点显示；或者从单步过来的*/}
      {((!hasCreated && !!stackPointList.length) || showSingleStep) && (
        <Affix offsetBottom={20}>
          <Button type="primary" onClick={onCreatePoints} wrapStyle={{ marginTop: 16 }}>
            批量创建数采点
          </Button>
        </Affix>
      )}

      {hasCreated && !window.location.pathname.includes('/view') && (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
          <CheckCircleOutlined size={16} style={{ color: '#52c41a', marginRight: 4 }} />
          <span>数采点创建完成</span>
        </div>
      )}

      <CreatingModal loading={creatStatus === CreateStatusEnum.CREATING} content="数采点创建中"></CreatingModal>
    </>
  );
};

export default DataPointConfig;
