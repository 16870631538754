import {
  Form,
  Select,
  EllipsisSpan,
  Paging,
  Table,
  useBreadcrumbRoutes,
  usePaging,
  Button,
  CustomFilter,
  Wrapper,
} from '@maxtropy/components';
import { Space } from 'antd';
import dayjs from 'dayjs';
import React, { useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '../../utils/utils';
import { getBsaRecordList } from '../../api/bsa';
import StatusTag from './StatusTag';
import styles from './index.module.scss';

const routes = [{ name: '下发记录' }];
const columns = [
  {
    title: '更新流水',
    dataIndex: 'id',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '更新时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '更新状态',
    dataIndex: 'result',
    ellipsis: { showTitle: true },
    render: (v: boolean) => <EllipsisSpan value={<StatusTag status={v} />} />,
  },
  {
    title: '失败原因',
    dataIndex: 'reason',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ?? '-'} />,
  },
];
interface SearchParamsProps {
  bsaId?: string;
  status?: string;
}

const BsaRecordList: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParamsProps>();
  const routesContext = useBreadcrumbRoutes();

  const navigate = useNavigate();

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        getBsaRecordList({
          ...searchParams,
          bsaId: id,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) setTotalCount(res.total);
          return res.list;
        }),
      [pageOffset, pageSize, searchParams, setTotalCount, id]
    )
  );

  const filter = (
    <CustomFilter
      form={form}
      onFinish={(v: any) => {
        setSearchParams(v);
        setPageOffset(1);
      }}
      onReset={() => {
        setSearchParams(undefined);
        setPageOffset(1);
      }}
    >
      <Form.Item name="result" label="状态">
        <Select placeholder="请选择">
          <Select.Option value={true}>成功</Select.Option>
          <Select.Option value={false}>失败</Select.Option>
        </Select>
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper routes={[...(routesContext?.routes ?? []), ...routes]} filters={filter} className={styles.wrapper}>
      <Table sticky rowKey="id" scroll={{ x: 600 }} columns={[...columns]} dataSource={data} loading={isLoading} />
      <Paging pagingInfo={pagingInfo} />
      <Space className="sticky-footer-left">
        <Button onClick={() => navigate(`/energy-storage/basic/bsa`)}>返回</Button>
      </Space>
    </Wrapper>
  );
};

export default BsaRecordList;
