import React, { FC, useMemo } from 'react';
import clusterIcon from '../../imgs/cluster.png';
import highestVoltage from '../../imgs/highestVoltage.png';
import lowestVoltage from '../../imgs/lowestVoltage.png';
import highestTemperature from '../../imgs/highestTemperature.png';
import lowestTemperature from '../../imgs/lowestTemperature.png';
import styles from './index.module.scss';
import { V2BsaBatteryGetBatteryChipDataPostResponse } from '@maxtropy/dmes-apis-v2';
import { isNil } from 'lodash';
import { Button, Tooltip } from '@maxtropy/components';
import { DoubleRightOutlined } from '@ant-design/icons';

export interface IPeakCard {
  data?: V2BsaBatteryGetBatteryChipDataPostResponse;
  clusterInfo?: any;
  isChannel: boolean;
}

const PeakCard: FC<IPeakCard> = props => {
  const { data, clusterInfo, isChannel } = props;

  const maxVol = useMemo(() => {
    const maxVolValue = data?.bsaBatteryPackNode
      ?.map(i => i.children?.filter(x => x.hasVoltagePoint).map(c => c.voltage))
      .flat()
      .filter(m => !isNil(m));
    if (maxVolValue && maxVolValue.length > 0) {
      return Math.max(...(maxVolValue as number[])).toFixed(3) + 'V';
    } else {
      return '--';
    }
  }, [data]);

  const minVol = useMemo(() => {
    const minVolValue = data?.bsaBatteryPackNode
      ?.map(i => i.children?.filter(x => x.hasVoltagePoint).map(c => c.voltage))
      .flat()
      .filter(m => !isNil(m));
    if (minVolValue && minVolValue.length > 0) {
      return Math.min(...(minVolValue as number[])).toFixed(3) + 'V';
    } else {
      return '--';
    }
  }, [data]);

  const maxTemp = useMemo(() => {
    const maxTempValue = data?.bsaBatteryPackNode
      ?.map(i => i.children?.filter(x => x.hasTemperaturePoint).map(c => c.temperature))
      .flat()
      .filter(m => !isNil(m));

    if (maxTempValue && maxTempValue.length > 0) {
      return Math.max(...(maxTempValue as number[])).toFixed(2) + '℃';
    } else {
      return '--';
    }
  }, [data]);

  const minTemp = useMemo(() => {
    const minTempValue = data?.bsaBatteryPackNode
      ?.map(i => i.children?.filter(x => x.hasTemperaturePoint).map(c => c.temperature))
      .flat()
      .filter(m => !isNil(m));

    if (minTempValue && minTempValue.length > 0) {
      return Math.min(...(minTempValue as number[])).toFixed(2) + '℃';
    } else {
      return '--';
    }
  }, [data]);

  // 跳转电池簇
  const showCluster = (deviceClusterId?: string) => {
    if (deviceClusterId) {
      window.open(`${window.IOTPLATFORMORIGIN}/device/manage/device/${deviceClusterId}/detail`, '_blank');
    }
  };

  // 最高最低查询
  const jumpHighAndLow = (deviceStackId?: string) => {
    if (deviceStackId) {
      window.open(`${window.IOTPLATFORMORIGIN}/device/manage/device/${deviceStackId}/detail`, '_blank');
    }
  };

  return (
    <div className={styles.topCardWrapper}>
      <div className={styles.topCard_nameSty}>
        <div style={{ display: 'flex' }}>
          <img src={clusterIcon} className={styles.imgSty} title="电池簇" alt="" />
          <Tooltip title={clusterInfo.name}>
            <span className={styles.nameSty}>{clusterInfo.name}</span>
          </Tooltip>
        </div>
        {!isChannel && (
          <div className={styles.showCluster}>
            <Button type="link" onClick={() => showCluster(clusterInfo.deviceClusterId)}>
              查看电池簇数采
              <DoubleRightOutlined />
            </Button>
          </div>
        )}
      </div>

      <div className={styles.topCard_rightSty}>
        <div className={styles.topCard_rightSty_topShow}>
          <div className={styles.topCard_volSty}>
            <div>
              <img src={highestVoltage} className={styles.imgSty} title="最高单体电压" alt="" />
              <span className={styles.titleSty}>最高单体电压</span>
              <span className={styles.valueSty}>{maxVol}</span>
            </div>
            <div className={styles.lowVSty}>
              <img src={lowestVoltage} className={styles.imgSty} title="最低单体电压" alt="" />
              <span className={styles.titleSty}>最低单体电压</span>
              <span className={styles.valueSty}>{minVol}</span>
            </div>
          </div>
          <div className={styles.topCard_temSty}>
            <div>
              <img src={highestTemperature} className={styles.imgSty} title="最高单体温度" alt="" />
              <span className={styles.titleSty}>最高单体温度</span>
              <span className={styles.valueSty}>{maxTemp}</span>
            </div>
            <div>
              <img src={lowestTemperature} className={styles.imgSty} title="最低单体温度" alt="" />
              <span className={styles.titleSty}>最低单体温度</span>
              <span className={styles.valueSty}>{minTemp}</span>
            </div>
          </div>
        </div>
        <div className={styles.jumpHighAndLowSty}>
          电池堆数采信息中的最高/最低电压和温度电池所在位置
          {!isChannel && (
            <Button type="link" onClick={() => jumpHighAndLow(clusterInfo.deviceStackId)}>
              点击此处查询
              <DoubleRightOutlined />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PeakCard;
