import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { MXTabsProps, Tabs, useBreadcrumbRoutes, Wrapper } from '@maxtropy/components';
import DragResize from '@/components/DragResize';
import BsaTree from '@/pages/BsaHistoryData/components/BsaTree';
import styles from './index.module.scss';
import { BsaType } from '@/pages/BsaHistoryData/const';
import ElecStatistics, { ElecStatisticsRef } from './components/ElecStatistics';
import dayjs, { Dayjs } from 'dayjs';
import ElecRecord from '@/pages/BsaHourlyElecReport/components/ElecRecord';
import { useHasPermission } from '@/utils/hooks';
import { PermissionsType } from '@/common/permissionsConst';

const { Sider, Content } = Layout;

const BsaHourlyElecReport = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [siderWidth, setSiderWidth] = useState(260);
  const [dragStatus, setDragStatus] = useState(false);
  const [openSider, setOpenSider] = useState(true);

  // 电量统计tab权限
  const hasPermissionTabElecStatistics = useHasPermission(PermissionsType.B_ELECTRICITY_STATISTICS_TAB);
  // 抄表记录tab权限
  const hasPermissionTabElecRecord = useHasPermission(PermissionsType.B_BSA_METER_READING_RECORD);

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const url_bsaId = urlSearchParams.get('bsaId') || undefined;
  const url_bsaType = urlSearchParams.get('bsaType') || undefined;
  const url_from = urlSearchParams.get('from') || undefined;
  const url_to = urlSearchParams.get('to') || undefined;
  const url_tab = urlSearchParams.get('tab') || undefined;

  const elecStatisticsRef = useRef<ElecStatisticsRef>(null);

  const [bsaId, setBsaId] = useState<number | undefined>(url_bsaId ? Number(url_bsaId) : undefined);
  const [bsaType, setBsaType] = useState<BsaType | undefined>(
    url_bsaType ? (Number(url_bsaType) as BsaType) : undefined
  );
  const [timeRange, setTimeRange] = useState<[Dayjs, Dayjs]>([
    url_from ? dayjs(Number(url_from)) : dayjs().subtract(31, 'days'),
    url_to ? dayjs(Number(url_to)) : dayjs(),
  ]);

  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  // 用于解决切换tab时，时间范围有变化，两个tab内都会请求数据的问题
  const [showTabItem, setShowTabItem] = useState(true);
  const [activeTabKey, setActiveTabKey] = useState(url_tab || 'elecStatistics');
  const tabItems: MXTabsProps['items'] = [
    {
      key: 'elecStatistics',
      label: '电量统计',
      show: hasPermissionTabElecStatistics,
      children: showTabItem && (
        <ElecStatistics
          ref={elecStatisticsRef}
          timeRange={timeRange}
          setTimeRange={setTimeRange}
          bsaId={bsaId}
          bsaType={bsaType}
        />
      ),
    },
    {
      key: 'elecRecord',
      label: '抄表记录',
      show: hasPermissionTabElecRecord,
      children: showTabItem && (
        <ElecRecord timeRange={timeRange} setTimeRange={setTimeRange} bsaId={bsaId} bsaType={bsaType} />
      ),
    },
  ]
    .filter(item => item.show)
    .map(item => {
      const { show, ...others } = item;
      return others;
    });

  return (
    <Wrapper routes={breadcrumbRoutes?.routes ?? []} className={styles.wrapper}>
      <Layout className={styles.layout}>
        <Sider
          theme="light"
          width={openSider ? siderWidth : 0}
          style={{ transition: dragStatus ? 'none' : 'all 0.2s', background: '#232324' }}
        >
          <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
          <BsaTree
            defaultBsaId={url_bsaId ? Number(url_bsaId) : undefined}
            defaultBsaType={bsaType}
            onSelect={(bsaId, bsaType) => {
              setBsaId(bsaId);
              setBsaType(bsaType);
            }}
          />
        </Sider>

        <Content className={styles.content}>
          <div
            className={styles.toggleBtn}
            style={{ left: openSider ? -30 : 0 }}
            onClick={() => setOpenSider(!openSider)}
          >
            {openSider ? <LeftOutlined /> : <RightOutlined />}
          </div>

          <Tabs
            activeKey={activeTabKey}
            items={tabItems}
            onChange={key => {
              setShowTabItem(false);
              // 如果在按月状态下，从电量统计切换到抄表记录，则重置时间范围
              if (key === 'elecRecord' && elecStatisticsRef.current?.timeResolution === 'M') {
                setTimeRange([dayjs().subtract(31, 'days'), dayjs()]);
              }
              // 如果从抄表记录换到电量统计，日期范围超过31天，则按末尾时间向前推31天
              if (key === 'elecStatistics' && timeRange[1].diff(timeRange[0], 'days') > 31) {
                setTimeRange([timeRange[1].subtract(31, 'days'), timeRange[1]]);
              }
              setActiveTabKey(key);
              setTimeout(() => {
                setShowTabItem(true);
              });
            }}
            selectedLinePlacement="top"
            bordered
            destroyInactiveTabPane
          ></Tabs>
        </Content>
      </Layout>
    </Wrapper>
  );
};

export default BsaHourlyElecReport;
