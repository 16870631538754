import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import { keepPercentTwoLine, keepTwoOrLine } from '../../utils';
import classnames from 'classnames/bind';
import meterIcon from '../../imgs/icon-meter.png';
import combinerIcon from '../../imgs/icon-combinerBox.png';
import inverterIcon from '../../imgs/icon-inverter.png';
import cleanerIcon from '../../imgs/icon-cleaner.png';
import bg from '../../imgs/bg.png';
import bg2 from '../../imgs/bg2.png';
import { useRequest, useSize } from 'ahooks';
import { apiV2PvNoticeboardDeviceInfoPost, apiV2PvOutlineDataPropertyRecentPost } from '@maxtropy/dmes-apis-v2';
import { Progress } from 'antd';
import { Tooltip } from '@maxtropy/components';
import { isNil } from 'lodash-es';
import {
  RobotDeviceStatus,
  RobotDeviceStatusDisplay,
  RobotPresenceColorDisplay,
  RobotPresenceStatus,
  RobotPresenceStatusDisplay,
  RobotSportStatus,
  RobotSportStatusDisplay,
  RobotWorkStatus,
  RobotWorkStatusDisplay,
} from '../../const';

const cx = classnames.bind(styles);

// 关口表 | 并网点
const meter = [
  10017, // 有功功率
  10025, // 无功功率
  10023, // 正向有功电能
  10024, // 反向有功电能
  10041, // A相温度
  10042, // B相温度
  10043, // C相温度
];

// 逆变器
const inverter = [
  10081, // 有功功率
  10082, // 无功功率
  10092, // 转换效率
  10089, // 日发电量
];

// 组串
const string = [
  10091, // 组串电流
  10090, // 组串电压
];

// 机器人
const robot = [
  12487, // 机器人在线状态
  12491, //机器人工作状态
  12492, //机器人运动状态
  12493, // 机器人SOC
  12494, // 当前机体温度
  12508, // 设备状态
  12507, // 倾斜角度
];

// 气象站
const weather = [
  10365, //总辐射
  10371, // 日辐射累计
  10352, //温度
  10353, // 湿度
  10363, //气压
  10359, //风速
  10358, //风向
  10362, //降雨量
];

const CenterGraph = () => {
  const [inverterId, setInverterId] = useState<number>();
  const [visible, setVisible] = useState<boolean>(true);
  const [deviceVisible, setDeviceVisible] = useState({
    meter: true,
    consolidation: true,
    robot: true,
  });

  const { data } = useRequest(() => {
    return apiV2PvNoticeboardDeviceInfoPost({});
  });

  useEffect(() => {
    if (data?.inverterInfoList && data?.inverterInfoList?.length) {
      setInverterId(data?.inverterInfoList[0].id);
    }
  }, [data]);

  // 关口表meter
  const { data: gatewayData } = useRequest(
    () => {
      return apiV2PvOutlineDataPropertyRecentPost({
        deviceId: data?.deviceInfoList?.find(i => i.type === 0)?.deviceId,
        dataPropertyIds: meter,
      });
    },
    {
      ready: !!data?.deviceInfoList?.find(i => i.type === 0)?.deviceId,
    }
  );

  // 并网点
  const { data: consolidationPointData } = useRequest(
    () => {
      return apiV2PvOutlineDataPropertyRecentPost({
        deviceId: data?.deviceInfoList?.find(i => i.type === 1)?.deviceId,
        dataPropertyIds: meter,
      });
    },
    {
      ready: !!data?.deviceInfoList?.find(i => i.type === 1)?.deviceId,
    }
  );

  // 机器人
  const { data: robotData } = useRequest(
    () => {
      return apiV2PvOutlineDataPropertyRecentPost({
        deviceId: data?.deviceInfoList?.find(i => i.type === 2)?.deviceId,
        dataPropertyIds: robot,
      });
    },
    {
      ready: !!data?.deviceInfoList?.find(i => i.type === 2)?.deviceId,
    }
  );

  // 气象站
  const { data: weatherData } = useRequest(
    () => {
      return apiV2PvOutlineDataPropertyRecentPost({
        deviceId: data?.deviceInfoList?.find(i => i.type === 3)?.deviceId,
        dataPropertyIds: weather,
      });
    },
    {
      ready: !!data?.deviceInfoList?.find(i => i.type === 3)?.deviceId,
    }
  );

  // 逆变器
  const { data: inverterData } = useRequest(
    () => {
      return apiV2PvOutlineDataPropertyRecentPost({
        deviceId: data?.inverterInfoList?.find(i => i.id === inverterId)?.deviceId,
        dataPropertyIds: inverter,
      });
    },
    {
      ready: !!inverterId && !!data?.inverterInfoList?.find(i => i.id === inverterId)?.deviceId,
      refreshDeps: [inverterId],
    }
  );

  // 组串
  const { data: stringData } = useRequest(
    () => {
      return apiV2PvOutlineDataPropertyRecentPost({
        deviceId: data?.inverterInfoList?.find(i => i.id === inverterId)?.stringList?.[0]?.deviceId,
        dataPropertyIds: string,
      });
    },
    {
      ready: !!inverterId && !!data?.inverterInfoList?.find(i => i.id === inverterId)?.stringList?.[0]?.deviceId,
      refreshDeps: [inverterId],
    }
  );

  // const imgRef = useRef<HTMLDivElement>();
  // const size = useSize(imgRef);

  // console.log('size', size);

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.img_wrapper}
        // ref={ref => {
        //   if (ref) {
        //     imgRef.current = ref;
        //   }
        // }}
      >
        {/* <img className={styles.bg_sty} src={bg2} alt="" /> */}
        {/* 按钮 */}
        <div
          className={cx('itemgraph_device', 'gateway_meter')}
          onClick={() => setDeviceVisible({ ...deviceVisible, meter: !deviceVisible.meter })}
          style={{ color: deviceVisible.meter ? '#52E7FF' : 'unset' }}
        >
          <img className={styles.img} src={meterIcon} alt="" />
          <div className={styles.label}>{data?.deviceInfoList?.find(i => i.type === 0)?.deviceName ?? '--'}</div>
        </div>
        <div
          className={cx('itemgraph_device', 'cabinet')}
          onClick={() => setDeviceVisible({ ...deviceVisible, consolidation: !deviceVisible.consolidation })}
          style={{ color: deviceVisible.consolidation ? '#52E7FF' : 'unset' }}
        >
          <img className={styles.img} src={meterIcon} alt="" />
          <div className={styles.label}>{data?.deviceInfoList?.find(i => i.type === 1)?.deviceName ?? '--'}</div>
        </div>
        <div className={cx('itemgraph_device', 'combiner')}>
          <img className={styles.img} src={combinerIcon} alt="" />
          <div className={styles.label}>汇流箱</div>
        </div>
        {data?.inverterInfoList?.slice(0, 10).map((item, index) => (
          <div
            onClick={() => {
              if (item.id !== inverterId) {
                setVisible(true);
                setInverterId(item.id);
              } else {
                setInverterId(undefined);
                setVisible(false);
              }
            }}
            key={item.deviceId}
            className={cx('itemgraph_device', `inverter${index + 1}`)}
            style={{ color: item.id === inverterId ? '#52E7FF' : 'unset' }}
          >
            <img className={styles.img} src={inverterIcon} alt="" />
            <Tooltip
              title={item.deviceName}
              getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
            >
              <div className={cx('label', 'labelExpand')}>{item.deviceName}</div>
            </Tooltip>
          </div>
        ))}
        {/* 清扫机器人 */}
        <div
          className={cx('itemgraph_device', 'robot')}
          onClick={() => setDeviceVisible({ ...deviceVisible, robot: !deviceVisible.robot })}
          style={{ color: deviceVisible.robot ? '#52E7FF' : 'unset' }}
        >
          <img className={styles.img} src={cleanerIcon} alt="" />
          <div className={styles.label}>{data?.deviceInfoList?.find(i => i.type === 2)?.deviceName ?? '--'}</div>
        </div>
      </div>

      <div className={styles.device}>
        {/* 关口表 */}
        {deviceVisible.meter && (
          <div className={styles.item_device}>
            <div className={styles.title_box}>
              <div className={cx('title_word')}>
                {data?.deviceInfoList?.find(i => i.type === 0)?.deviceName ?? '--'}
              </div>
            </div>
            <div className={styles.dataPropsBox}>
              {/* 有功功率 */}
              {gatewayData?.list?.find(i => i.propertyId === 10017) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>有功功率</div>
                  <div className={cx('value', 'yellow')}>
                    <Tooltip
                      title={keepTwoOrLine(gatewayData?.list?.find(i => i.propertyId === 10017)?.value) + 'kW'}
                      getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                    >
                      {keepTwoOrLine(gatewayData?.list?.find(i => i.propertyId === 10017)?.value)}kW
                    </Tooltip>
                  </div>
                </div>
              )}
              {/* 无功功率 */}
              {gatewayData?.list?.find(i => i.propertyId === 10025) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>无功功率</div>
                  <div className={cx('value', 'yellow')}>
                    <Tooltip
                      title={keepTwoOrLine(gatewayData?.list?.find(i => i.propertyId === 10025)?.value) + 'kVar'}
                      getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                    >
                      {keepTwoOrLine(gatewayData?.list?.find(i => i.propertyId === 10025)?.value)}kVar
                    </Tooltip>
                  </div>
                </div>
              )}
              {/* 正向有功电能 */}
              {gatewayData?.list?.find(i => i.propertyId === 10023) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>正向有功电能</div>
                  <div className={cx('value', 'blue')}>
                    <Tooltip
                      title={keepTwoOrLine(gatewayData?.list?.find(i => i.propertyId === 10023)?.value) + 'kWh'}
                      getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                    >
                      {keepTwoOrLine(gatewayData?.list?.find(i => i.propertyId === 10023)?.value)}kWh
                    </Tooltip>
                  </div>
                </div>
              )}
              {/* 反向有功电能 */}
              {gatewayData?.list?.find(i => i.propertyId === 10024) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>反向有功电能</div>
                  <div className={cx('value', 'blue')}>
                    <Tooltip
                      title={keepTwoOrLine(gatewayData?.list?.find(i => i.propertyId === 10024)?.value) + 'kWh'}
                      getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                    >
                      {keepTwoOrLine(gatewayData?.list?.find(i => i.propertyId === 10024)?.value)}kWh
                    </Tooltip>
                  </div>
                </div>
              )}
              {/* A相温度 */}
              {gatewayData?.list?.find(i => i.propertyId === 10041) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>A相温度</div>
                  <div className={cx('value')}>
                    <Tooltip
                      title={keepTwoOrLine(gatewayData?.list?.find(i => i.propertyId === 10041)?.value) + '℃'}
                      getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                    >
                      {keepTwoOrLine(gatewayData?.list?.find(i => i.propertyId === 10041)?.value)}℃
                    </Tooltip>
                  </div>
                </div>
              )}
              {/* B相温度 */}
              {gatewayData?.list?.find(i => i.propertyId === 10042) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>B相温度</div>
                  <div className={cx('value')}>
                    <Tooltip
                      title={keepTwoOrLine(gatewayData?.list?.find(i => i.propertyId === 10042)?.value) + '℃'}
                      getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                    >
                      {keepTwoOrLine(gatewayData?.list?.find(i => i.propertyId === 10042)?.value)}℃
                    </Tooltip>
                  </div>
                </div>
              )}
              {/* C相温度 */}
              {gatewayData?.list?.find(i => i.propertyId === 10043) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>C相温度</div>
                  <div className={cx('value')}>
                    <Tooltip
                      title={keepTwoOrLine(gatewayData?.list?.find(i => i.propertyId === 10043)?.value) + '℃'}
                      getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                    >
                      {keepTwoOrLine(gatewayData?.list?.find(i => i.propertyId === 10043)?.value)}℃
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {/* 并网点 */}
        {deviceVisible.consolidation && (
          <div className={styles.item_device}>
            <div className={styles.title_box}>
              <div className={styles.title_word}>
                {data?.deviceInfoList?.find(i => i.type === 1)?.deviceName ?? '--'}
              </div>
            </div>
            <div className={styles.dataPropsBox}>
              {/* 有功功率 */}
              {consolidationPointData?.list?.find(i => i.propertyId === 10017) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>有功功率</div>
                  <div className={cx('value', 'yellow')}>
                    <Tooltip
                      title={
                        keepTwoOrLine(consolidationPointData?.list?.find(i => i.propertyId === 10017)?.value) + 'kW'
                      }
                      getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                    >
                      {keepTwoOrLine(consolidationPointData?.list?.find(i => i.propertyId === 10017)?.value)}kW
                    </Tooltip>
                  </div>
                </div>
              )}
              {/* 无功功率 */}
              {consolidationPointData?.list?.find(i => i.propertyId === 10025) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>无功功率</div>
                  <div className={cx('value', 'yellow')}>
                    <Tooltip
                      title={
                        keepTwoOrLine(consolidationPointData?.list?.find(i => i.propertyId === 10025)?.value) + 'kVar'
                      }
                      getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                    >
                      {keepTwoOrLine(consolidationPointData?.list?.find(i => i.propertyId === 10025)?.value)}kVar
                    </Tooltip>
                  </div>
                </div>
              )}
              {/* 正向有功电能 */}
              {consolidationPointData?.list?.find(i => i.propertyId === 10023) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>正向有功电能</div>
                  <div className={cx('value', 'blue')}>
                    <Tooltip
                      title={
                        keepTwoOrLine(consolidationPointData?.list?.find(i => i.propertyId === 10023)?.value) + 'kWh'
                      }
                      getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                    >
                      {keepTwoOrLine(consolidationPointData?.list?.find(i => i.propertyId === 10023)?.value)}kWh
                    </Tooltip>
                  </div>
                </div>
              )}
              {/* 反向有功电能 */}
              {consolidationPointData?.list?.find(i => i.propertyId === 10024) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>反向有功电能</div>
                  <div className={cx('value', 'blue')}>
                    <Tooltip
                      title={
                        keepTwoOrLine(consolidationPointData?.list?.find(i => i.propertyId === 10024)?.value) + 'kWh'
                      }
                      getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                    >
                      {keepTwoOrLine(consolidationPointData?.list?.find(i => i.propertyId === 10024)?.value)}kWh
                    </Tooltip>
                  </div>
                </div>
              )}
              {/* A相温度 */}
              {consolidationPointData?.list?.find(i => i.propertyId === 10041) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>A相温度</div>
                  <div className={cx('value')}>
                    <Tooltip
                      title={
                        keepTwoOrLine(consolidationPointData?.list?.find(i => i.propertyId === 10041)?.value) + '℃'
                      }
                      getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                    >
                      {keepTwoOrLine(consolidationPointData?.list?.find(i => i.propertyId === 10041)?.value)}℃
                    </Tooltip>
                  </div>
                </div>
              )}
              {/* B相温度 */}
              {consolidationPointData?.list?.find(i => i.propertyId === 10042) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>B相温度</div>
                  <div className={cx('value')}>
                    <Tooltip
                      title={
                        keepTwoOrLine(consolidationPointData?.list?.find(i => i.propertyId === 10042)?.value) + '℃'
                      }
                      getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                    >
                      {keepTwoOrLine(consolidationPointData?.list?.find(i => i.propertyId === 10042)?.value)}℃
                    </Tooltip>
                  </div>
                </div>
              )}
              {/* C相温度 */}
              {consolidationPointData?.list?.find(i => i.propertyId === 10043) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>C相温度</div>
                  <div className={cx('value')}>
                    <Tooltip
                      title={
                        keepTwoOrLine(consolidationPointData?.list?.find(i => i.propertyId === 10043)?.value) + '℃'
                      }
                      getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                    >
                      {keepTwoOrLine(consolidationPointData?.list?.find(i => i.propertyId === 10043)?.value)}℃
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {/* 清扫机器人 */}
        {deviceVisible.robot && (
          <div className={styles.item_device}>
            <div className={styles.title_box}>
              <div className={styles.title_word}>
                {data?.deviceInfoList?.find(i => i.type === 2)?.deviceName ?? '--'}
              </div>
            </div>
            <div className={styles.dataPropsBox}>
              {/* 在线状态 */}
              {robotData?.list?.find(i => i.propertyId === 12487) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>在线状态</div>
                  <div
                    className={cx('value')}
                    style={{
                      color:
                        RobotPresenceColorDisplay[
                          robotData?.list?.find(i => i.propertyId === 12487)?.value as RobotPresenceStatus
                        ],
                    }}
                  >
                    {!isNil(robotData?.list?.find(i => i.propertyId === 12487)?.value)
                      ? RobotPresenceStatusDisplay[
                          robotData?.list?.find(i => i.propertyId === 12487)?.value as RobotPresenceStatus
                        ]
                      : '--'}
                  </div>
                </div>
              )}
              {/* 设备状态 */}
              {robotData?.list?.find(i => i.propertyId === 12508) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>设备状态</div>
                  <div
                    className={cx('value')}
                    style={{
                      color:
                        RobotDeviceStatusDisplay[
                          robotData?.list?.find(i => i.propertyId === 12508)?.value as RobotDeviceStatus
                        ],
                    }}
                  >
                    {!isNil(robotData?.list?.find(i => i.propertyId === 12508)?.value)
                      ? RobotDeviceStatusDisplay[
                          robotData?.list?.find(i => i.propertyId === 12508)?.value as RobotDeviceStatus
                        ]
                      : '--'}
                  </div>
                </div>
              )}
              {/* 工作状态 */}
              {robotData?.list?.find(i => i.propertyId === 12491) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>工作状态</div>
                  <div className={cx('value')}>
                    {!isNil(robotData?.list?.find(i => i.propertyId === 12491)?.value)
                      ? RobotWorkStatusDisplay[
                          robotData?.list?.find(i => i.propertyId === 12491)?.value as RobotWorkStatus
                        ]
                      : '--'}
                  </div>
                </div>
              )}
              {/* 运动状态 */}
              {robotData?.list?.find(i => i.propertyId === 12492) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>运动状态</div>
                  <div className={cx('value')}>
                    {!isNil(robotData?.list?.find(i => i.propertyId === 12492)?.value)
                      ? RobotSportStatusDisplay[
                          robotData?.list?.find(i => i.propertyId === 12492)?.value as RobotSportStatus
                        ]
                      : '--'}
                  </div>
                </div>
              )}

              {/* 机器人SOC */}
              {robotData?.list?.find(i => i.propertyId === 12493) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>机器人SOC</div>
                  <div className={cx('value')}>
                    <Progress
                      style={{ marginRight: 3 }}
                      type="circle"
                      strokeColor={{
                        '0%': '#51A1FF',
                        '100%': '#51DBFF',
                      }}
                      trailColor="#10223D"
                      percent={
                        !isNil(robotData?.list?.find(i => i.propertyId === 12493)?.value)
                          ? Number(robotData?.list?.find(i => i.propertyId === 12493)?.value?.toFixed(2))
                          : undefined
                      }
                      strokeWidth={20}
                      size={14}
                    />
                    <Tooltip
                      getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                      title={
                        (!isNil(robotData?.list?.find(i => i.propertyId === 12493)?.value)
                          ? Number(robotData?.list?.find(i => i.propertyId === 12493)?.value?.toFixed(2))
                          : '--') + '%'
                      }
                    >
                      {!isNil(robotData?.list?.find(i => i.propertyId === 12493)?.value)
                        ? Number(robotData?.list?.find(i => i.propertyId === 12493)?.value?.toFixed(2))
                        : '--'}
                      %
                    </Tooltip>
                  </div>
                </div>
              )}
              {/* 当前机体温度 */}
              {robotData?.list?.find(i => i.propertyId === 12494) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>当前机体温度</div>
                  <div className={cx('value')}>
                    <Tooltip
                      title={keepTwoOrLine(robotData?.list?.find(i => i.propertyId === 12494)?.value) + '℃'}
                      getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                    >
                      {keepTwoOrLine(robotData?.list?.find(i => i.propertyId === 12494)?.value)}℃
                    </Tooltip>
                  </div>
                </div>
              )}
              {/* 倾斜角度 */}
              {robotData?.list?.find(i => i.propertyId === 12507) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>倾斜角度</div>
                  <div className={cx('value')}>
                    <Tooltip
                      title={keepTwoOrLine(robotData?.list?.find(i => i.propertyId === 12507)?.value) + '°'}
                      getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                    >
                      {keepTwoOrLine(robotData?.list?.find(i => i.propertyId === 12507)?.value)}°
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className={styles.device}>
        {/* 气象站 */}
        <div className={styles.item_device} style={{ maxHeight: 224 }}>
          <div className={styles.title_box}>
            <div className={styles.title_word}>{data?.deviceInfoList?.find(i => i.type === 3)?.deviceName ?? '--'}</div>
          </div>
          <div className={styles.dataPropsBox}>
            {/* 总辐射 */}
            {weatherData?.list?.find(i => i.propertyId === 10365) && (
              <div className={styles.itemDataProp}>
                <div className={styles.label}>总辐射</div>
                <div className={cx('value')}>
                  <Tooltip
                    title={keepTwoOrLine(weatherData?.list?.find(i => i.propertyId === 10365)?.value) + 'W/㎡'}
                    getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                  >
                    {keepTwoOrLine(weatherData?.list?.find(i => i.propertyId === 10365)?.value)}W/㎡
                  </Tooltip>
                </div>
              </div>
            )}
            {/* 日辐射累计 */}
            {weatherData?.list?.find(i => i.propertyId === 10371) && (
              <div className={styles.itemDataProp}>
                <div className={styles.label}>日辐射累计</div>
                <div className={cx('value')}>
                  <Tooltip
                    title={keepTwoOrLine(weatherData?.list?.find(i => i.propertyId === 10371)?.value) + 'Wh/㎡'}
                    getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                  >
                    {keepTwoOrLine(weatherData?.list?.find(i => i.propertyId === 10371)?.value)}Wh/㎡
                  </Tooltip>
                </div>
              </div>
            )}
            {/* 温度 */}
            {weatherData?.list?.find(i => i.propertyId === 10352) && (
              <div className={styles.itemDataProp}>
                <div className={styles.label}>温度</div>
                <div className={cx('value')}>
                  <Tooltip
                    title={keepTwoOrLine(weatherData?.list?.find(i => i.propertyId === 10352)?.value) + '℃'}
                    getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                  >
                    {keepTwoOrLine(weatherData?.list?.find(i => i.propertyId === 10352)?.value)}℃
                  </Tooltip>
                </div>
              </div>
            )}
            {/* 湿度 */}
            {weatherData?.list?.find(i => i.propertyId === 10353) && (
              <div className={styles.itemDataProp}>
                <div className={styles.label}>湿度</div>
                <div className={cx('value')}>
                  <Tooltip
                    getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                    title={keepPercentTwoLine(weatherData?.list?.find(i => i.propertyId === 10353)?.value) + '%'}
                  >
                    {keepPercentTwoLine(weatherData?.list?.find(i => i.propertyId === 10353)?.value)}%
                  </Tooltip>
                </div>
              </div>
            )}
            {/* 气压 */}
            {weatherData?.list?.find(i => i.propertyId === 10363) && (
              <div className={styles.itemDataProp}>
                <div className={styles.label}>气压</div>
                <div className={cx('value')}>
                  <Tooltip
                    title={keepTwoOrLine(weatherData?.list?.find(i => i.propertyId === 10363)?.value) + 'kPa'}
                    getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                  >
                    {keepTwoOrLine(weatherData?.list?.find(i => i.propertyId === 10363)?.value)}kPa
                  </Tooltip>
                </div>
              </div>
            )}
            {/* 风速 */}
            {weatherData?.list?.find(i => i.propertyId === 10359) && (
              <div className={styles.itemDataProp}>
                <div className={styles.label}>风速</div>
                <div className={cx('value')}>
                  <Tooltip
                    title={keepTwoOrLine(weatherData?.list?.find(i => i.propertyId === 10359)?.value) + 'm/s'}
                    getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                  >
                    {keepTwoOrLine(weatherData?.list?.find(i => i.propertyId === 10359)?.value)}m/s
                  </Tooltip>
                </div>
              </div>
            )}
            {/* 风向 */}
            {weatherData?.list?.find(i => i.propertyId === 10358) && (
              <div className={styles.itemDataProp}>
                <div className={styles.label}>风向</div>
                <div className={cx('value')}>
                  {keepTwoOrLine(weatherData?.list?.find(i => i.propertyId === 10358)?.value)}
                </div>
              </div>
            )}
            {/* 降雨量 */}
            {weatherData?.list?.find(i => i.propertyId === 10362) && (
              <div className={styles.itemDataProp}>
                <div className={styles.label}>降雨量</div>
                <div className={cx('value')}>
                  <Tooltip
                    title={keepTwoOrLine(weatherData?.list?.find(i => i.propertyId === 10362)?.value) + 'mm'}
                    getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                  >
                    {keepTwoOrLine(weatherData?.list?.find(i => i.propertyId === 10362)?.value)}mm
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* 逆变器 */}
        {visible && (
          <div className={styles.item_device} style={{ maxHeight: 210 }}>
            <div className={styles.title_box}>
              <div className={styles.title_word}>
                {data?.inverterInfoList?.find(i => i.id === inverterId)?.code ?? '--'} |{' '}
                {data?.inverterInfoList?.find(i => i.id === inverterId)?.deviceName ?? '--'}
              </div>
            </div>
            <div className={styles.dataPropsBox}>
              {/* 有功功率 */}
              {inverterData?.list?.find(i => i.propertyId === 10081) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>有功功率</div>
                  <div className={cx('value', 'yellow')}>
                    <Tooltip
                      title={keepTwoOrLine(inverterData?.list?.find(i => i.propertyId === 10081)?.value) + 'kW'}
                      getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                    >
                      {keepTwoOrLine(inverterData?.list?.find(i => i.propertyId === 10081)?.value)}kW
                    </Tooltip>
                  </div>
                </div>
              )}
              {/* 无功功率 */}
              {inverterData?.list?.find(i => i.propertyId === 10082) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>无功功率</div>
                  <div className={cx('value', 'yellow')}>
                    <Tooltip
                      title={keepTwoOrLine(inverterData?.list?.find(i => i.propertyId === 10082)?.value) + 'kar'}
                      getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                    >
                      {keepTwoOrLine(inverterData?.list?.find(i => i.propertyId === 10082)?.value)}kar
                    </Tooltip>
                  </div>
                </div>
              )}
              {/* 转换效率 */}
              {inverterData?.list?.find(i => i.propertyId === 10092) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>转换效率</div>
                  <div className={cx('value')}>
                    <Progress
                      style={{ marginRight: 3 }}
                      type="circle"
                      strokeColor={{
                        '0%': '#51A1FF',
                        '100%': '#51DBFF',
                      }}
                      trailColor="#10223D"
                      percent={
                        !isNil(inverterData?.list?.find(i => i.propertyId === 10092)?.value)
                          ? Number(inverterData?.list?.find(i => i.propertyId === 10092)?.value?.toFixed(2))
                          : undefined
                      }
                      strokeWidth={20}
                      size={14}
                    />
                    <Tooltip
                      getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                      title={
                        (!isNil(inverterData?.list?.find(i => i.propertyId === 10092)?.value)
                          ? Number(inverterData?.list?.find(i => i.propertyId === 10092)?.value?.toFixed(2))
                          : '--') + '%'
                      }
                    >
                      {!isNil(inverterData?.list?.find(i => i.propertyId === 10092)?.value)
                        ? Number(inverterData?.list?.find(i => i.propertyId === 10092)?.value?.toFixed(2))
                        : '--'}
                      %
                    </Tooltip>
                  </div>
                </div>
              )}
              {/* 日发电量 */}
              {inverterData?.list?.find(i => i.propertyId === 10089) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>日发电量</div>
                  <div className={cx('value', 'blue')}>
                    <Tooltip
                      title={keepTwoOrLine(inverterData?.list?.find(i => i.propertyId === 10089)?.value) + 'kWh'}
                      getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                    >
                      {keepTwoOrLine(inverterData?.list?.find(i => i.propertyId === 10089)?.value)}kWh
                    </Tooltip>
                  </div>
                </div>
              )}
              {/* 组串 */}
              <div className={styles.title_box}>
                <div className={styles.title_word}>
                  {data?.inverterInfoList?.find(i => i.id === inverterId)?.stringList?.[0]?.code ?? '--'} |{' '}
                  {data?.inverterInfoList?.find(i => i.id === inverterId)?.stringList?.[0]?.deviceName ?? '--'}
                </div>
              </div>
              {/* 组串电流 */}
              {stringData?.list?.find(i => i.propertyId === 10091) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>组串电流</div>
                  <div className={cx('value')}>
                    <Tooltip
                      title={keepTwoOrLine(stringData?.list?.find(i => i.propertyId === 10091)?.value) + 'A'}
                      getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                    >
                      {keepTwoOrLine(stringData?.list?.find(i => i.propertyId === 10091)?.value)}A
                    </Tooltip>
                  </div>
                </div>
              )}
              {/* 组串电压 */}
              {stringData?.list?.find(i => i.propertyId === 10090) && (
                <div className={styles.itemDataProp}>
                  <div className={styles.label}>组串电压</div>
                  <div className={cx('value')}>
                    <Tooltip
                      title={keepTwoOrLine(stringData?.list?.find(i => i.propertyId === 10090)?.value) + 'V'}
                      getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
                    >
                      {keepTwoOrLine(stringData?.list?.find(i => i.propertyId === 10090)?.value)}V
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CenterGraph;
