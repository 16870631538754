import React, { useEffect } from 'react';
import { Space } from 'antd';

import { Button, Form, Input, Modal, Select } from '@maxtropy/components';
import { V2ModulePagePostResponseItem } from '.';
import { apiV2ModuleCreatePost, apiV2ModuleUpdatePost, V2ModuleCreatePostRequest } from '@maxtropy/dmes-apis-v2';
import { useRequest } from 'ahooks';
import { getStringList } from '@/api/pv-string';

export interface FormModalProps {
  pvId: number;
  row?: V2ModulePagePostResponseItem;
  isDraft: boolean;
  isDisable: boolean;
  onCancel: () => void;
  onOk: () => void;
  onNextStep: (payload: V2ModuleCreatePostRequest) => void;
}

const FormModal: React.FC<FormModalProps> = ({ row, onCancel, isDraft, isDisable, pvId, onNextStep, onOk }) => {
  const [form] = Form.useForm();

  const { runAsync: createOrUpdate, loading: isLoading } = useRequest(
    row ? apiV2ModuleUpdatePost : apiV2ModuleCreatePost,
    { manual: true }
  );
  const { data } = useRequest(() => getStringList({ pvId }), {
    refreshDeps: [pvId],
    ready: !!pvId,
  });

  useEffect(() => {
    if (row) {
      form.setFieldsValue({
        ...row,
      });
    }
  }, [form, row]);

  return (
    <Modal
      open
      title={`${!!row ? '编辑' : '添加'}组件`}
      maskClosable={false}
      onCancel={onCancel}
      contentStyle={{ padding: '32px 72px 8px' }}
      footer={
        <Space>
          <Button onClick={onCancel}>取消</Button>
          {!row && (isDraft || isDisable) && (
            <Button
              loading={isLoading}
              type={'primary'}
              onClick={() => form.validateFields().then(values => onNextStep({ ...values, pvId }))}
            >
              下一步
            </Button>
          )}
          <Button
            loading={isLoading}
            type="primary"
            onClick={() => form.validateFields().then(values => createOrUpdate({ ...row, ...values, pvId }).then(onOk))}
          >
            确定
          </Button>
        </Space>
      }
    >
      <Form form={form}>
        <Form.Item
          name={'code'}
          label={'组件编号'}
          rules={[
            { required: true },
            { max: 20, message: '最多20个字符' },
            { pattern: /^[A-Za-z0-9\\-]{1,20}$/, message: '仅能够输入数字、英文大小写和英文状态下的-' },
          ]}
        >
          <Input placeholder={'请输入'} disabled={!(isDraft || isDisable)} />
        </Form.Item>
        <Form.Item name={'pvStringId'} label={'关联组串'} rules={[{ required: true, message: '请选择关联组串' }]}>
          <Select placeholder={'请选择'} options={data?.map(item => ({ label: item.code, value: item.id }))} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FormModal;
