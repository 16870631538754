import { Row } from 'antd';
import { FC } from 'react';
import { BsaDeviceTypeEnum } from '../../../../api/type';

import styles from './index.module.scss';
import { Form, InputNumber } from '@maxtropy/components';

export interface FormulaFormItemProps {
  bsaDeviceType: BsaDeviceTypeEnum;
}

const FormulaFormItem: FC<FormulaFormItemProps> = ({ bsaDeviceType }) => {
  return (
    <div style={{ display: 'flex', paddingTop: 6 }}>
      <Form.Item label="C" required={false}>
        <div style={{ display: 'flex' }}>
          <Form.Item name="c" rules={[{ required: true }]} noStyle>
            <InputNumber className={styles.input} min={0} precision={0} />
          </Form.Item>
          <span className={styles.operate}> + </span>
        </div>
      </Form.Item>
      <Form.Item label="K" required={false}>
        <div style={{ display: 'flex' }}>
          <Form.Item name="x" rules={[{ required: true }]} noStyle>
            <InputNumber className={styles.input} addonAfter={'x'} min={0} precision={0} />
          </Form.Item>
          <span className={styles.operate}> + </span>
        </div>
      </Form.Item>
      <Form.Item label="K" required={false}>
        <div style={{ display: 'flex' }}>
          <Form.Item name="y" rules={[{ required: true }]} noStyle>
            <InputNumber className={styles.input} addonAfter={'y'} min={0} precision={0} />
          </Form.Item>
          {(bsaDeviceType === BsaDeviceTypeEnum.PACK || bsaDeviceType === BsaDeviceTypeEnum.CELL) && (
            <span className={styles.operate}>+</span>
          )}
        </div>
      </Form.Item>
      {(bsaDeviceType === BsaDeviceTypeEnum.PACK || bsaDeviceType === BsaDeviceTypeEnum.CELL) && (
        <Form.Item name="z" label="K" rules={[{ required: true }]} required={false}>
          <InputNumber className={styles.input} addonAfter={'z'} min={0} precision={0} />
        </Form.Item>
      )}
      {bsaDeviceType === BsaDeviceTypeEnum.CELL && (
        <Form.Item name="w" label="+K" rules={[{ required: true }]} required={false}>
          <InputNumber className={styles.input} addonAfter={'w'} min={0} precision={0} />
        </Form.Item>
      )}
    </div>
  );
};

export function showFormulaFormItem(
  bsaDeviceType: BsaDeviceTypeEnum,
  option: { c?: number; x?: number; y?: number; z?: number; w?: number }
) {
  let commonShow = `${option.c ?? 0} + ${option.x ?? 0}x  + ${option.y ?? 0}y`;
  let zShow =
    bsaDeviceType === BsaDeviceTypeEnum.PACK || bsaDeviceType === BsaDeviceTypeEnum.CELL ? ` + ${option.z ?? 0}z` : '';
  let wShow = bsaDeviceType === BsaDeviceTypeEnum.CELL ? ` + ${option.w ?? 0}w` : '';
  return commonShow + zShow + wShow;
}

export default FormulaFormItem;
