import { getEnergyAssetTotalIncomeInfo, OuList, TotalIncomeInfoRes } from '@/api/energyAssetOverview';
import { Table, Tooltip, Button, EllipsisSpan } from '@maxtropy/components';
import { Col, Row, Spin } from 'antd';
import { isNil } from 'lodash-es';
import { FC, useEffect, useState } from 'react';
import HeadTitle from '../HeadTitle/HeadTitle';
import styles from './index.module.scss';
import ReactEcharts from 'echarts-for-react';
import pieBg from '../../imgs/pieBg.png';
import { formateValuesToWanYuan } from '../../utils';

export interface ISiteRevenue {
  ouInfo: OuList;
  height: string | undefined;
}

const PVNAME = '光伏站';
const BSANAME = '储能站';

const columns = [
  {
    title: '站点名称',
    dataIndex: 'stationName',
    width: 60,
    ellipsis: { showTitle: true },
    render: (v: string, record: TotalIncomeInfoRes) => (
      <Button
        className={styles.stationNameBtn}
        type="link"
        onClick={() =>
          window.open(
            record.type === PVNAME
              ? `${window.SEVENNINENINESEVEN}/operate-overview?stationId=${record.id}`
              : record.type === BSANAME
              ? `${window.MSEDEVDATMIND}/#/energy/welcome?id=${record.id}`
              : `${window.EVCHARGING}/csm/operationBoard?id=${record.id}`,
            '_blank'
          )
        }
      >
        <Tooltip title={v}>
          <span className={styles.stationNameSty}>{v}</span>
        </Tooltip>
      </Button>
    ),
  },
  {
    title: '站点类型',
    dataIndex: 'type',
    width: 60,
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v} />,
  },
  {
    title: '累计收益',
    dataIndex: 'cumulativeGain',
    width: 60,
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={`${!isNil(v) ? formateValuesToWanYuan(v) + '万元' : '--'}`} />,
  },
  {
    title: '总收益占比	',
    dataIndex: 'revenueProportion',
    width: 60,
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={`${!isNil(v) ? (Number(v) * 100).toFixed(2) + '%' : '--'}`} />,
  },
];

const SiteRevenue: FC<ISiteRevenue> = props => {
  const { ouInfo, height } = props;

  const [totalIncomeInfo, setEnergyAssetTatalIncomeInfo] = useState<TotalIncomeInfoRes[]>();
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    if (ouInfo.id) {
      setDataLoading(true);
      // 累计收益信息
      getEnergyAssetTotalIncomeInfo({ ouId: ouInfo.id }).then(res => {
        setEnergyAssetTatalIncomeInfo(res);
        setDataLoading(false);
      });
    }
  }, [ouInfo]);

  const option = {
    tooltip: {
      trigger: 'item',
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      textStyle: {
        color: '#fff',
      },
      formatter: function (params: any) {
        let str =
          `<span style='display:inline-block;border-radius:10px;'>${
            !isNil(params.data.name) ? params.data.name : '--'
          }</span>` +
          '<br>' +
          `收益：<span style='display:inline-block;border-radius:10px;color:#00ADFF;'>${
            !isNil(params.data.value)
              ? Number(params.data.rate) > 0
                ? formateValuesToWanYuan(params.data.value)
                : `-${formateValuesToWanYuan(params.data.value)}`
              : '--'
          }万元</span>` +
          '<br>' +
          `占比：<span style='display:inline-block;border-radius:10px;color:#FCB41D;'>${
            !isNil(params.data.rate) ? (Number(params.data.rate) * 100).toFixed(2) : '--'
          }%</span>`;
        return str;
      },
    },
    title: {
      text: `${
        totalIncomeInfo && totalIncomeInfo.length
          ? totalIncomeInfo
              ?.map(i => i.cumulativeGain ?? 0)
              ?.reduce((pre, cur) => {
                return pre + cur;
              })
            ? formateValuesToWanYuan(
                totalIncomeInfo
                  ?.map(i => i.cumulativeGain)
                  .reduce((pre, cur) => {
                    return pre + cur;
                  })
              )
            : '--'
          : '--'
      }`,
      subtext: '累计收益(万元)',
      left: 'center',
      top: '37%',
      textStyle: {
        // width: 100,
        // overflow: 'truncate',
        // ellipsis: '...',
        fontWeight: 500,
        color: '#fff',
        fontSize: 16,
        align: 'center',
      },
      subtextStyle: {
        fontWeight: 400,
        fontSize: 11,
        color: '#ffffff80',
        align: 'center',
      },
    },
    graphic: {
      // 这个属性可以在饼图内部填充图片,文字等
      elements: [
        {
          type: 'image', //需要填充图片,配置image,如果不需要图片可以配置其他的, text, circle, sector, ring, polygon, polyline, rect, line, bezierCurve, arc, group,
          style: {
            image: pieBg, //这里添加图片地址
            width: 150,
            height: 150,
          },
          left: 'center', //
          top: 'middle', //配置图片居中
        },
      ],
    },
    series: [
      {
        type: 'pie',
        radius: ['70%', '88%'],
        // center: ['20%', '50%'],
        // avoidLabelOverlap: false, // 是否启用防止标签重叠策略，默认开启，在标签拥挤重叠的情况下会挪动各个标签的位置，防止标签间的重叠。
        labelLine: {
          // 标签的视觉引导线配置。在 label 位置 设置为'outside'的时候会显示视觉引导线。
          show: false,
        },
        label: {
          show: false,
          position: 'center',
        },
        data: totalIncomeInfo?.map(i => {
          return {
            name: i.stationName,
            value: Math.abs(i.cumulativeGain),
            rate: i.revenueProportion,
          };
        }),
        minAngle: 2,
        emphasis: {
          label: {
            show: false,
            fontSize: 10,
            fontWeight: 'bold',
          },
        },
        emptyCircleStyle: {
          color: '#3F3F3F',
        },
      },
    ],
  };

  return (
    <div
      className={styles.ColSty}
      style={{
        height: height,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <HeadTitle title="站点收益" />
      <Spin spinning={dataLoading}>
        <Row style={{ marginTop: 12 }}>
          <Col span={14}>
            <Table
              size="small"
              sticky
              rowKey="id"
              className={styles.TableSty}
              // scroll={{ y: 200 }}
              columns={columns}
              dataSource={totalIncomeInfo}
              loading={dataLoading}
              pagination={false}
            />
          </Col>
          <Col span={10}>
            <ReactEcharts
              option={option}
              notMerge
              lazyUpdate={false}
              style={{ height: 150 }} // 样式的设置width与height
            />
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default SiteRevenue;
