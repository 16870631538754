import { CircuitDataType } from '@/api/circuit';
import { InfoCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';
import React, { Ref, useImperativeHandle } from 'react';
import { useEffect, useState } from 'react';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';
import styles from './index.module.scss';

interface SortModalListProps {
  tabData?: CircuitDataType[];
}

export interface SortModalListModalRef {
  tabDataList: CircuitDataType[];
}

const SortModalList = React.forwardRef((props: SortModalListProps, ref: Ref<SortModalListModalRef>) => {
  const { tabData } = props;
  const [tabDataList, setTabDataList] = useState<CircuitDataType[]>([]);

  const SortableItem = SortableElement<{ value: string; sortIndex: number }>(
    ({ value }: { value: string; sortIndex: number }) => (
      <li className={styles.liStyle} style={{ marginRight: 10 }}>
        <UnorderedListOutlined style={{ marginRight: '10px' }} />
        {value}
      </li>
    )
  );

  useEffect(() => {
    if (tabData) {
      setTabDataList(tabData);
    }
  }, [tabData]);

  const SortableList = SortableContainer<{ items: CircuitDataType[] }>(({ items }: { items: CircuitDataType[] }) => {
    return (
      <ul style={{ padding: 0, height: '450px', overflow: 'auto', cursor: 'move' }}>
        {items.map((item, index) => (
          <SortableItem key={`item-${item.id}`} sortIndex={index} index={index} value={item.name} />
        ))}
      </ul>
    );
  });

  // 排序结束时调用的回调
  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: any; newIndex: any }) => {
    let newData = arrayMove(tabDataList, oldIndex, newIndex);
    setTabDataList(newData);
  };

  useImperativeHandle(ref, () => ({ tabDataList }));

  return (
    <>
      <p style={{ backgroundColor: '#59421450', padding: '12px', borderRadius: '5px' }}>
        <InfoCircleOutlined style={{ color: 'var(--warning-color)', marginRight: 4 }}></InfoCircleOutlined>
        可通过拖拽配置属性的展示排序
      </p>
      <p style={{ color: '#fff' }}>属性名称</p>
      <SortableList items={tabDataList} onSortEnd={onSortEnd} helperClass={styles.helperClass} />
    </>
  );
});

export default SortModalList;
