import { Customer, getPvStationList, PvListItem } from '@/api/pv';
import { PVStatus, pvStatusFormat } from '@/api/type';
import {
  EllipsisSpan,
  Paging,
  Table,
  useBreadcrumbRoutes,
  usePaging,
  Form,
  Input,
  Select,
  Wrapper,
  CustomFilter,
} from '@maxtropy/components';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { fetchOuList, getOuId } from '../../api/ou';
import ListActionBtns from './ListActionBtns';
import styles from './index.module.scss';

const columns = [
  {
    title: '光伏站编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '光伏站名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '装机容量',
    dataIndex: 'installedCapacity',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属组织',
    dataIndex: 'customer',
    ellipsis: { showTitle: true },
    render: (v: Customer) => <EllipsisSpan value={v.name} />,
  },
  {
    title: '运营单元',
    key: 'ouName',
    ellipsis: { showTitle: true },
    render: (_: any, record: any) => <EllipsisSpan value={record.ou.name} />,
  },
  {
    title: '当前状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: PVStatus) => <EllipsisSpan value={pvStatusFormat[v]} />,
  },
  {
    title: '上次操作时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '操作人',
    dataIndex: 'updateByUsername',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface SearchParamsProps {
  nameOrCode?: string;
  ouId?: string;
}

const PvList: React.FC = () => {
  const routesContext = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParamsProps>();

  const { data, isLoading, refetch } = useQuery(
    ['pvStationPage', pageOffset, pageSize, searchParams, setTotalCount],
    async () => {
      const res = await getPvStationList({ ...searchParams, page: pageOffset, size: pageSize });
      if (!res) return [];
      setTotalCount(res.total);
      return res.list;
    }
  );

  const { data: ouList } = useQuery('ouList', async () => {
    const ouType = (await getOuId())?.filter(i => i.key === 'MICRONET')[0]?.id;
    if (!ouType) return [];
    return fetchOuList(ouType);
  });

  const actionColumn = {
    title: '操作',
    width: 300,
    fixed: 'right' as const,
    render: (v: PvListItem) => <ListActionBtns {...v} update={() => refetch()} />,
  };

  return (
    <Wrapper
      className={styles.wrapper}
      routes={routesContext?.routes}
      filters={
        <CustomFilter
          form={form}
          onFinish={(values: any) => {
            setSearchParams(values);
            setPageOffset(1);
            refetch();
          }}
          onReset={() => {
            setSearchParams(undefined);
            setPageOffset(1);
            refetch();
          }}
        >
          <Form.Item name="nameOrCode" label="光伏站编号/名称">
            <Input placeholder={'请输入编号或名称查询'} />
          </Form.Item>
          <Form.Item name="ouId" label="运营单元">
            <Select placeholder="请选择">
              {ouList?.map(i => (
                <Select.Option key={i.id} value={i.id}>
                  {i.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </CustomFilter>
      }
    >
      <Table
        sticky
        rowKey="code"
        scroll={{ x: 1500 }}
        columns={[...columns, actionColumn]}
        dataSource={data}
        loading={isLoading}
      />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default PvList;
