import { Table, useUpdate } from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import styles from './index.module.scss';
import rackIcon from '../../icons/icon-equipment@2x.png';

import { FirstLevelDevice, MapValueObj, RealData } from '../../type';

import { useGetTemperTableDataSplit } from '../../hooks';
import YcTable from './YcTable';
import YxTable from './YxTable';
import { rowType } from '../PCS';
import { useState } from 'react';
import RemoteControlModal from '../PCS/RemoteControlModal';
import SettingModal from '../PCS/SettingModal';
import { goToDeviceHistoryPage } from '../utils';

interface Iprops {
  data?: FirstLevelDevice;
}
const CardItem = ({ data }: Iprops) => {
  const [update, setUpdate] = useUpdate();
  const { ycDatas, alarmDatas, statusDatas } = useGetTemperTableDataSplit({ update, data });
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [remoteVisible, setRemoteVisible] = useState<boolean>(false);
  const [currentRemoteData, setCurrentRemoteData] = useState<any>({});
  const [modalType, setModalType] = useState<number>();
  const tableOp = (record: any) => {
    setRemoteVisible(true);
    setCurrentRemoteData(record);
  };
  const setOpBtn = (v: number) => {
    setModalType(v);
    setModalVisible(true);
  };
  return (
    <div className={styles.card_box}>
      <div className={styles.card_title}>
        <div className={styles.left}>
          <div className={styles.img_box}>
            <img src={rackIcon} alt="" />
          </div>
          <span className={styles.img_title}>{data?.deviceName ?? '--'}</span>
        </div>
        <div className={styles.right} onClick={() => goToDeviceHistoryPage(data?.deviceId)}>
          历史数据
        </div>
      </div>

      <div className={styles.table}>
        <Row gutter={[20, 20]}>
          <Col xxl={8} lg={24}>
            <YcTable tableList={ycDatas} remoteOp={tableOp} />
          </Col>
          <Col xxl={8} lg={24}>
            <YxTable type={rowType.alarm} remoteOp={tableOp} setOp={setOpBtn} tableList={alarmDatas} />
          </Col>
          <Col xxl={8} lg={24}>
            <YxTable type={rowType.status} remoteOp={tableOp} setOp={setOpBtn} tableList={statusDatas} />
          </Col>
        </Row>
      </div>

      {modalVisible && (
        <SettingModal
          modalType={modalType}
          realData={[data] as RealData}
          onConfirm={() => {
            setUpdate();
            setModalVisible(false);
          }}
          onCancel={() => setModalVisible(false)}
        />
      )}
      {remoteVisible && (
        <RemoteControlModal
          {...currentRemoteData}
          onConfirm={() => {
            setUpdate();
            setRemoteVisible(false);
          }}
          onCancel={() => setRemoteVisible(false)}
        />
      )}
    </div>
  );
};

export default CardItem;
