import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';
import CardBox from '../../../CardBox';
import { Space, Spin } from 'antd';
import { DatePicker } from '@maxtropy/components';
import { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import { ShareDataContext } from '@/pages/PvDemonstrationBase';
import ReactEcharts from 'echarts-for-react';
import { getChartOption } from './chart';
import { useRequest } from 'ahooks';
import { apiV2PvNoticeboardPvStringCurvePost } from '@maxtropy/dmes-apis-v2';

const StringPower = () => {
  const { baseDivRef } = useContext(ShareDataContext);
  const [date, setDate] = useState<Dayjs>(dayjs()); // 时间选择
  const chartRef = useRef<ReactEcharts>();

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > dayjs().endOf('day');
  };

  const onDateChange: DatePickerProps['onChange'] = date => {
    if (date) {
      setDate?.(date);
    }
  };

  // 组串功率曲线
  const { data: chartsData, loading } = useRequest(
    () => {
      return apiV2PvNoticeboardPvStringCurvePost({
        time: date?.valueOf(),
      });
    },
    {
      pollingInterval: 2 * 60000,
      ready: !!date,
      refreshDeps: [date],
    }
  );

  // 查询图表
  const chartOption = useMemo(() => getChartOption(chartsData), [chartsData]);

  const handleResize = () => {
    chartRef.current?.getEchartsInstance().resize();
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <CardBox
        style={{ width: '100%' }}
        className={styles.cardBoxSty}
        title={
          <>
            <Space size={23}>
              <span>组串功率</span>
              <DatePicker
                style={{ width: 160, height: 24 }}
                allowClear={false}
                disabledDate={disabledDate}
                onChange={onDateChange}
                picker="date"
                value={date}
                getPopupContainer={node => baseDivRef ?? node}
              />
            </Space>
          </>
        }
      >
        {loading ? (
          <Spin spinning={true} />
        ) : (
          <ReactEcharts
            style={{ height: '100%' }}
            ref={d => {
              if (d) {
                chartRef.current = d;
              }
            }}
            option={chartOption}
            notMerge
            lazyUpdate={false}
          />
        )}
      </CardBox>
    </>
  );
};

export default StringPower;
