import React, { useState } from 'react';
import { Breadcrumb, useBreadcrumbRoutes, Empty } from '@maxtropy/components';
import styles from './index.module.scss';
import Tree, { CircuitTreeLeafInfo } from './Tree';
import { Layout } from 'antd';
import BorderWrapper from '../../components/BorderWrapper';
import Analysis from './Analysis';
import Composition from './Composition';

const { Content, Sider } = Layout;

export interface ElectricityConsumptionAnalysisProps {}

const ElectricityConsumptionAnalysis: React.FC<ElectricityConsumptionAnalysisProps> = () => {
  const routesContext = useBreadcrumbRoutes();

  const [circuit, setCircuit] = useState<CircuitTreeLeafInfo>();
  const changeTreeSelect = (value: React.Key[], info: CircuitTreeLeafInfo) => {
    if (info) {
      setCircuit(info);
    }
  };

  return (
    <div className="page">
      <Breadcrumb routes={routesContext?.routes} />
      <BorderWrapper>
        <Layout className={styles.layout}>
          <Sider theme="light" width={260} className={styles.sider}>
            <Tree changeTreeSelect={changeTreeSelect} />
          </Sider>
          <Content className={styles.content}>
            {!!circuit?.circuitId ? (
              <>
                <div className={styles.sectionTitle}>电量分析</div>
                <Analysis circuit={circuit} />
                <div className={styles.sectionTitle} style={{ marginTop: 30 }}>
                  用电构成
                </div>
                <Composition circuit={circuit} />
              </>
            ) : (
              <Empty style={{ marginTop: 80 }} description={'暂无数据'} />
            )}
          </Content>
        </Layout>
      </BorderWrapper>
    </div>
  );
};

export default ElectricityConsumptionAnalysis;
