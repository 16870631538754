import React, { Suspense } from 'react';
import { PermissionsType } from '@/common/permissionsConst';

const Overview = React.lazy(() => import(/* webpackChunkName: "Overview" */ `./index`));
const routes = [
  {
    path: '/energy-storage/revenue/overview',
    permission: PermissionsType.P_STORAGE_OVERVIEW,
    element: (
      <Suspense fallback={null}>
        <Overview />
      </Suspense>
    ),
  },
];

export default routes;
