import { PermissionsType } from '@/common/permissionsConst';

import StatisticsCircuit from '@/pages/Statistics';

const routes = [
  {
    path: '/dmes/statistics/circuit',
    permission: PermissionsType.P_CIRCUIT_DATA,
    element: <StatisticsCircuit />,
  },
];

export default routes;
