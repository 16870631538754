import {
  ChargeGunLinkType,
  ChargingPileAndGunDtoList,
  createChargeStation,
  deleteChargingStation,
  DetailInfoRes,
  getChargingStationListInfo,
  updateChargingGun,
} from '@/api/chargingStationManagement';
import { PopConfirm, Button, EllipsisSpan, useUpdate, Table } from '@maxtropy/components';
import { Space } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useRef, useState } from 'react';
import DeviceModal, { DeviceModalRef } from '../DeviceModal';

export enum ChargeDeviceLinkType {
  CHARGINGSTATION = 1,
  ACHARGINGGUN = 2,
  BCHARGINGGUN = 3,
}

export const ChargeDeviceLinkFormat = {
  [ChargeDeviceLinkType.CHARGINGSTATION]: '充电桩',
  [ChargeDeviceLinkType.ACHARGINGGUN]: 'A充电枪',
  [ChargeDeviceLinkType.BCHARGINGGUN]: 'B充电枪',
};

interface DeviceListFormProps {
  isEdit?: boolean;
  data?: DetailInfoRes;
}

export const deviceColumns = [
  {
    title: '充电桩序号',
    dataIndex: 'sortNum',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '充电桩名称',
    dataIndex: 'chargingPileName',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '充电桩类型',
    dataIndex: 'chargingPileType',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '充电桩设备编号',
    dataIndex: 'chargingPileDeviceCode',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: 'A充电枪设备编号',
    dataIndex: 'AChargingGunCode',
    width: 180,
    ellipsis: { showTitle: true },
    render: (_: undefined, record: ChargingPileAndGunDtoList) => (
      <EllipsisSpan
        value={
          (record.chargingGunList ?? []).find(i => i.chargingGunType === ChargeGunLinkType.ACHARGINGGUN)
            ?.chargingGunDeviceCode ?? '--'
        }
      />
    ),
  },
  {
    title: 'A充电枪设备名称',
    dataIndex: 'AChargingGunName',
    width: 180,
    ellipsis: { showTitle: true },
    render: (_: undefined, record: ChargingPileAndGunDtoList) => (
      <EllipsisSpan
        value={
          (record.chargingGunList ?? []).find(i => i.chargingGunType === ChargeGunLinkType.ACHARGINGGUN)
            ?.chargingGunName ?? '--'
        }
      />
    ),
  },
  {
    title: 'B充电枪设备编号',
    dataIndex: 'BChargingGunCode',
    width: 180,
    ellipsis: { showTitle: true },
    render: (_: undefined, record: ChargingPileAndGunDtoList) => (
      <EllipsisSpan
        value={
          (record.chargingGunList ?? []).find(i => i.chargingGunType === ChargeGunLinkType.BCHARGINGGUN)
            ?.chargingGunDeviceCode ?? '--'
        }
      />
    ),
  },
  {
    title: 'B充电枪设备名称',
    dataIndex: 'BChargingGunName',
    width: 180,
    ellipsis: { showTitle: true },
    render: (_: undefined, record: ChargingPileAndGunDtoList) => (
      <EllipsisSpan
        value={
          (record.chargingGunList ?? []).find(i => i.chargingGunType === ChargeGunLinkType.BCHARGINGGUN)
            ?.chargingGunName ?? '--'
        }
      />
    ),
  },
  {
    title: '充电桩创建时间',
    dataIndex: 'createTime',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />,
  },
];

const DeviceListForm: FC<DeviceListFormProps> = props => {
  const { isEdit, data } = props;
  const addDeviceRef = useRef<DeviceModalRef>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<ChargingPileAndGunDtoList[]>([]);
  const [selectedDeviceType, setSelectedDeviceType] = useState<ChargeDeviceLinkType>();
  const [updateState, updateFn] = useUpdate();
  const [row, setRow] = useState<ChargingPileAndGunDtoList>();

  useEffect(() => {
    if (data && data.id) {
      getChargingStationListInfo(data.id).then(res => {
        setDataSource(
          res.map((i, index) => {
            return {
              ...i,
              sortNum: fourNum(index + 1),
            };
          })
        );
      });
    }
  }, [data, updateState]);

  const removeChargingStation = (id: number) => {
    deleteChargingStation(id).then(_ => {
      updateFn();
    });
  };

  const columns = {
    title: '操作',
    width: 360,
    fixed: 'right' as const,
    render: (v: any, record: ChargingPileAndGunDtoList) => (
      <>
        <Space size={16}>
          <PopConfirm
            title="是否移除该充电桩，移除后将无法恢复"
            onConfirm={() => removeChargingStation(record.id)}
            okText="确认"
            cancelText="取消"
          >
            <Button type="link">移除充电桩</Button>
          </PopConfirm>

          <Button
            type="link"
            onClick={() => {
              setVisible(true);
              setSelectedDeviceType(ChargeDeviceLinkType.ACHARGINGGUN);
              setRow(record);
            }}
          >
            编辑A充电枪设备
          </Button>
          <Button
            type="link"
            onClick={() => {
              setVisible(true);
              setSelectedDeviceType(ChargeDeviceLinkType.BCHARGINGGUN);
              setRow(record);
            }}
          >
            编辑B充电枪设备
          </Button>
        </Space>
      </>
    ),
  };

  const onOk = () => {
    if (addDeviceRef.current?.selectedRows) {
      if (selectedDeviceType === ChargeDeviceLinkType.CHARGINGSTATION) {
        createChargeStation({
          chargingStationId: data!.id,
          deviceId: addDeviceRef.current?.selectedRows.id,
        }).then(_ => {
          updateFn();
        });
      } else {
        updateChargingGun({
          chargingStationId: data?.id!,
          chargingPileId: row?.id!,
          deviceId: addDeviceRef.current?.selectedRows.id,
          type:
            selectedDeviceType === ChargeDeviceLinkType.ACHARGINGGUN
              ? ChargeGunLinkType.ACHARGINGGUN
              : ChargeGunLinkType.BCHARGINGGUN,
        }).then(_ => {
          updateFn();
        });
      }
    }
    setVisible(false);
    setRow(undefined);
    setSelectedDeviceType(undefined);
  };

  return (
    <>
      {isEdit && (
        <div style={{ marginBottom: 10 }}>
          <Button
            type="primary"
            onClick={() => {
              setVisible(true);
              setRow(undefined);
              setSelectedDeviceType(ChargeDeviceLinkType.CHARGINGSTATION);
            }}
          >
            新建充电桩
          </Button>
        </div>
      )}

      <Table
        rowKey="chargingPileDeviceId"
        sticky
        scroll={{ x: 1200 }}
        columns={isEdit ? [...deviceColumns, columns] : [...deviceColumns]}
        dataSource={dataSource}
        pagination={false}
      />
      {visible && (
        <DeviceModal
          ref={addDeviceRef}
          visible={visible}
          onOk={onOk}
          onCancel={() => {
            setVisible(false);
            setRow(undefined);
            setSelectedDeviceType(undefined);
          }}
          selectedDeviceType={selectedDeviceType}
          row={row}
          data={data}
        />
      )}
    </>
  );
};

export default DeviceListForm;

const fourNum = (num: number) => {
  let tmp = '';
  for (var i = 0; i < 4 - num.toString().length; i++) {
    tmp += '0';
  }
  return tmp + num;
};
