import BsaNewSteps from '@/pages/BsaNewSteps';

const routes = [
  {
    path: '/energy-storage/basic/bsaNew/steps/edit/:id',
    permission: '',
    element: <BsaNewSteps />,
  },
  {
    path: '/energy-storage/basic/bsaNew/steps/view/:id',
    permission: '',
    element: <BsaNewSteps isView />,
  },
];
export default routes;
