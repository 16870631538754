// import '@maxtropy/strategy-core-ui/index.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RouterProvider } from 'react-router-dom';
import zhCN from 'antd/es/locale/zh_CN';
import './App.scss';

import routers from '@/routes';

import { CustomApp, useThemeContext } from '@maxtropy/components';
import { ConfigProvider } from 'antd';
import { init } from '@maxtropy/web-monitor';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});
// 监控sdk
init({
  remoteUrl: `${window.LOGCOLLECT}/api/log-collect`,
  token: 'cIjVVwDJ0UbVXjZ',
  appName: 'ds',
  open: true,
});
function App() {
  const theme = useThemeContext();
  return (
    <QueryClientProvider contextSharing client={queryClient}>
      <div className="App">
        <ConfigProvider locale={zhCN} theme={theme}>
          <CustomApp style={{ height: '100%' }}>
            <RouterProvider router={routers} />
          </CustomApp>
        </ConfigProvider>
      </div>
    </QueryClientProvider>
  );
}

export default App;
