import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { bsaFinish } from '../../../../api/bsa';
import { Button } from '@maxtropy/components';

export interface SubmitButtonProps {
  onOk?: () => void;
}

export const SubmitButton: FC<SubmitButtonProps> = ({ onOk }) => {
  const { id } = useParams<{ id: string }>();
  const [submitting, setSubmitting] = useState(false);

  const onFinish = () => {
    setSubmitting(true);
    if (id) {
      bsaFinish(id)
        .then(() => {
          onOk?.();
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return (
    <Button loading={submitting} type="primary" onClick={onFinish}>
      提交表单
    </Button>
  );
};

export const CancelButton: FC = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/energy-storage/basic/bsa');
  };

  return <Button onClick={onClick}>取消</Button>;
};
