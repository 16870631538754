import ShowInput from '@/components/ShowInput';
import { Button, FormTitle, SubContent, Wrapper, useBreadcrumbRoutes, useUpdate } from '@maxtropy/components';
import { Col, Form, Row } from 'antd';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { useRequest } from 'ahooks';
import { apiV2UetGetStartCalculateTimePost } from '@maxtropy/dmes-apis-v2';
import dayjs from 'dayjs';
import StartingTimeModal from '@/pages/SiteAccess/StartingTimeModal';
import styles from './index.module.scss';

export const TIME_FORMAT = 'YYYY-MM-DD';

export enum StationType {
  // 1. 储能 2.光伏 4.充电站
  BSA = 1,
  PV_STATION = 2,
  CHARGING_STATION = 4,
}

const routes = [{ name: '站点起算时间设置' }];

interface Props {}
const Storage: React.FC<Props> = () => {
  const [form] = Form.useForm();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const { search } = useLocation();
  const query = qs.parse(search, { ignoreQueryPrefix: true });

  const [state, forceUpdate] = useUpdate();
  const [modalVisible, setModalVisible] = React.useState(false);
  const [isShowWarningInfo, setIsShowWarningInfo] = useState<boolean>(false);

  const { data: startCalculateTime } = useRequest(
    async () => {
      if (!query?.id || !query?.type) return;
      const res = await apiV2UetGetStartCalculateTimePost({
        id: +query?.id,
        type: +query?.type as StationType,
      });

      if (res.startCalculateTime) {
        form.setFieldsValue({ time: dayjs(res.startCalculateTime).format(TIME_FORMAT) });
        return res.startCalculateTime;
      } else {
        setIsShowWarningInfo(true);
      }
    },
    {
      refreshDeps: [query?.id, query?.type, state],
    }
  );

  return (
    <>
      <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapperPadding}>
        <FormTitle title="站点起算时间设置" />
        <SubContent>
          <Form form={form}>
            <Row>
              <Col>
                <Form.Item
                  name="time"
                  label="起算日期"
                  extra={
                    isShowWarningInfo && (
                      <p
                        style={{
                          color: 'var(--error-color)',
                        }}
                      >
                        请先配置站点基础信息
                      </p>
                    )
                  }
                >
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col>
                <Button type="link" disabled={isShowWarningInfo} onClick={() => setModalVisible(true)}>
                  设置时间
                </Button>
              </Col>
            </Row>

            <Button
              onClick={() => {
                window.history.go(-1);
              }}
            >
              返回
            </Button>
          </Form>
        </SubContent>
      </Wrapper>

      <StartingTimeModal
        id={+query?.id!}
        type={+query?.type! as StationType}
        open={modalVisible}
        cancel={() => setModalVisible(false)}
        startCalculateTime={startCalculateTime}
        forceUpdate={() => forceUpdate()}
      />
    </>
  );
};

export default Storage;
