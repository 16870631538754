import React, { useMemo, useState } from 'react';
import { Button, EllipsisSpan, Form, Input, Modal, Paging, PopConfirm, Table, usePaging } from '@maxtropy/components';
import { Col, Space, Spin } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  createString,
  deleteAllStrings,
  deleteString,
  exportStrings,
  getStringPage,
  PVString,
  pvStringKeys,
  removeStringDevice,
  StringPayload,
  StringQuery,
  updateStringDevice,
} from '@/api/pv-string';
import { DeleteOutlined, ExportOutlined, ImportOutlined, PlusOutlined } from '@ant-design/icons';
import { ColumnsType, ColumnType } from 'antd/es/table';
import PVStringFormModal from './FormModal';
import SelectDeviceModal, { PVType } from '@/components/SelectDeviceModal';
import qs from 'qs';
import { PVStatus } from '@/api/type';
import styles from './index.module.scss';
import Filter from '@/components/Filter';
import { useForm } from 'antd/es/form/Form';
import ImportModal from './ImportModal';

export interface EditStringProps {
  isEdit?: boolean;
}

const PVStringScreen: React.FC<EditStringProps> = ({ isEdit }) => {
  const { ouId, status, id } = qs.parse(window.location.search, { ignoreQueryPrefix: true }) as unknown as {
    ouId: number;
    status: PVStatus;
    id: number;
    opType: string;
  };
  const pvId = +id;
  const isDraft = +status === PVStatus.DRAFT;
  const isDisable = +status === PVStatus.DISABLE;

  const [form] = useForm();
  const [searchParams, setSearchParams] = useState<StringQuery>({ pvId });
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset, totalCount } = pagingInfo;

  const queryClient = useQueryClient();
  // const { data: inverters } = useQuery(pvInverterKeys.list(pvId), () => getInverterList(pvId));
  const { data, isLoading, refetch } = useQuery(
    pvStringKeys.page({ ...searchParams, page: pageOffset, size: pageSize }),
    () =>
      getStringPage({ ...searchParams, page: pageOffset, size: pageSize }).then(res => {
        setTotalCount(res.total);
        return res;
      })
  );
  const { mutate: removeDevice } = useMutation(removeStringDevice, {
    onSuccess: () => queryClient.invalidateQueries(pvStringKeys.all),
  });
  const { mutate: removeString } = useMutation(deleteString, {
    onSuccess: () => queryClient.invalidateQueries(pvStringKeys.all),
  });
  const { mutate: removeAllStrings } = useMutation(deleteAllStrings, {
    onSuccess: () => queryClient.invalidateQueries(pvStringKeys.all),
  });
  const { mutate: updateDevice } = useMutation(
    (data: { id: number; deviceId: number }) => updateStringDevice(data.id, data.deviceId),
    {
      onSuccess: () => queryClient.invalidateQueries(pvStringKeys.all),
    }
  );
  const { mutate: createNewString } = useMutation(createString, {
    onSuccess: () => queryClient.invalidateQueries(pvStringKeys.all),
  });

  const [formModalVisible, setFormModalVisible] = useState(false);
  const [selectDeviceModalVisible, setSelectDeviceModalVisible] = useState(false);
  const [row, setRow] = useState<PVString>();
  const [creatingString, setCreatingString] = useState<StringPayload>();
  const [importModalVisible, setImportModalVisible] = useState(false);
  const actionColumn: ColumnType<PVString> = useMemo(
    () => ({
      title: '操作',
      dataIndex: 'action',
      width: 250,
      fixed: 'right' as const,
      render: (_, record) => (
        <div className="action-buttons">
          <Button
            type="link"
            onClick={() => {
              setRow(record);
              setFormModalVisible(true);
            }}
          >
            编辑
          </Button>
          <PopConfirm
            disabled={!record?.deviceId}
            okText="继续"
            title={'请注意，移除后无法查询该设备数据\n你还要继续吗？'}
            onConfirm={() => removeDevice(record.id)}
          >
            <Button disabled={!record?.deviceId} type="link">
              移除设备
            </Button>
          </PopConfirm>
          <Button
            type="link"
            onClick={() => {
              setRow(record);
              setSelectDeviceModalVisible(true);
            }}
          >
            {record?.deviceId ? '更换设备' : '绑定设备'}
          </Button>
          {(isDraft || isDisable) && (
            <PopConfirm okText="确定" title={'确认删除组串吗？'} onConfirm={() => removeString(record.id)}>
              <Button type="link">删除</Button>
            </PopConfirm>
          )}
        </div>
      ),
    }),
    [isDraft, isDisable, removeDevice, removeString]
  );

  return (
    <>
      {importModalVisible && (
        <ImportModal
          pvId={pvId}
          onCancel={() => setImportModalVisible(false)}
          onOk={() => setImportModalVisible(false)}
        />
      )}
      {formModalVisible && (
        <PVStringFormModal
          pvId={pvId}
          isDraft={isDraft}
          isDisable={isDisable}
          row={row}
          onCancel={() => {
            setRow(undefined);
            setFormModalVisible(false);
          }}
          onOk={() => {
            setRow(undefined);
            setFormModalVisible(false);
          }}
          onNextStep={payload => {
            setFormModalVisible(false);
            setCreatingString(payload);
            setSelectDeviceModalVisible(true);
          }}
        />
      )}
      {selectDeviceModalVisible && (
        <SelectDeviceModal
          pvId={pvId}
          pvType={PVType.STRING}
          ouId={ouId}
          typeName={'组串'}
          initialDeviceId={row?.deviceId}
          onOk={deviceId => {
            if (creatingString) {
              createNewString(
                { ...creatingString, deviceId },
                {
                  onSuccess: () => {
                    setCreatingString(undefined);
                    setSelectDeviceModalVisible(false);
                  },
                }
              );
              return;
            }

            updateDevice(
              { id: row!.id, deviceId },
              {
                onSuccess: () => {
                  setRow(undefined);
                  setSelectDeviceModalVisible(false);
                },
              }
            );
          }}
          onCancel={() => {
            setRow(undefined);
            setSelectDeviceModalVisible(false);
          }}
        />
      )}

      <Spin spinning={isLoading}>
        <div className={styles.string}>
          <div className={styles.title}>{'组串'}</div>
          {isEdit ? (
            <div className={styles.header}>
              <Space>
                {(isDraft || isDisable) && (
                  <>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        setFormModalVisible(true);
                      }}
                    >
                      添加组串
                    </Button>
                    <Button
                      type="primary"
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        Modal.confirm({
                          title: '确认删除所有组串？',
                          onOk: () => removeAllStrings(pvId),
                        });
                      }}
                    >
                      删除所有组串
                    </Button>
                    <Button type="primary" icon={<ImportOutlined />} onClick={() => setImportModalVisible(true)}>
                      导入组串
                    </Button>
                  </>
                )}
                <Button type="primary" icon={<ExportOutlined />} onClick={() => exportStrings(pvId)}>
                  导出组串
                </Button>
              </Space>
              <div className={styles.count}>
                当前数量：<span style={{ color: '#F5222D' }}>{totalCount ?? 0}</span> 个
              </div>
            </div>
          ) : (
            <>
              <Filter
                form={form}
                onFinish={values => {
                  setSearchParams({ pvId, ...values });
                  setPageOffset(1);
                  refetch();
                }}
                onReset={() => {
                  setSearchParams({ pvId });
                  setPageOffset(1);
                }}
              >
                <>
                  <Col span={8}>
                    <Form.Item name="pvStringCode" label="组串编号">
                      <Input placeholder={'请输入'} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="pvInverterCode" label={'关联逆变器'}>
                      <Input placeholder={'请输入'} />
                    </Form.Item>
                  </Col>
                </>
              </Filter>
              <Button type="primary" icon={<ExportOutlined />} onClick={() => exportStrings(pvId)}>
                导出组串
              </Button>
            </>
          )}

          <Table
            style={{ marginTop: 18 }}
            sticky
            rowKey="id"
            scroll={{ x: 1500 }}
            columns={[...columns, ...(isEdit ? [actionColumn] : [])]}
            dataSource={data?.list}
          />

          <Paging pagingInfo={pagingInfo} />
        </div>
      </Spin>
    </>
  );
};

const columns: ColumnsType<PVString> = [
  {
    title: '序号',
    dataIndex: 'index',
    ellipsis: { showTitle: true },
    render: (_v, _record, index) => <EllipsisSpan value={index + 1} />,
  },
  {
    title: '组串编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备编号',
    dataIndex: 'deviceCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '关联逆变器',
    dataIndex: 'pvInverterCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export default PVStringScreen;
