import { isNil } from 'lodash-es';

export const keepTwoNull = (data?: number) => {
  if (!isNil(data)) {
    return toFixedTwo(data);
  } else {
    return null;
  }
};

export const keepTwoOrLine = (data?: number) => {
  if (!isNil(data)) {
    return toFixedTwo(data);
  }
  return '--';
};

// 保留两位小数（截取）
export const toFixedTwo = (num: number) => {
  return Math.floor(num * 100) / 100;
};
