import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import TopTabs from './TabContents/Components/TopTabs';
import { CurrentComponentByType } from './factory';

import styles from './index.module.scss';
import { useRequest } from 'ahooks';
import { ShareContext } from '.';
import { apiV2NeoBsaRealTimeDataPost } from '@maxtropy/dmes-apis-v2';
import { Skeleton } from 'antd';
import { RealTimeDataType } from './type';

const MainOut = () => {
  const { bsaId, bsaType, setDataType, dataType, setRealData } = useContext(ShareContext);

  const [updateDom, setUpdateDom] = useState(true);
  const CurrentEle = useMemo(() => {
    if (dataType) {
      return CurrentComponentByType[dataType];
    }
  }, [dataType]);

  const { data, loading } = useRequest(
    () => {
      return apiV2NeoBsaRealTimeDataPost({
        bsaId,
        bsaType,
        dataType,
      })
        .then(res => res?.list ?? [])
        .catch(_ => {
          return [];
        });
    },
    {
      ready: !!bsaId && !!dataType && ![RealTimeDataType.TEMPER, RealTimeDataType.BATTERY].includes(+dataType),
      refreshDeps: [bsaId, bsaType, dataType],
    }
  );

  useEffect(() => {
    setRealData?.(data);
  }, [data]);
  const timerRef = useRef<any>();
  const refresh = () => {
    if (loading || timerRef.current || !updateDom) return;
    setUpdateDom(false);
    timerRef.current = setTimeout(() => {
      setUpdateDom(true);
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }, 300);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);
  return (
    <>
      {/* 顶部tab区域 */}
      <TopTabs onChange={v => setDataType?.(v)} refresh={refresh} />
      {/* 内容区域 */}
      <div className={styles.main_box}>
        {loading || !updateDom ? (
          <div style={{ margin: '100px 20px 0' }}>
            <Skeleton />
          </div>
        ) : CurrentEle ? (
          <CurrentEle deviceList={data} />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default MainOut;
