import { useUpdate } from '@maxtropy/components';
import { Spin } from 'antd';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getBsa, getFsm } from '../../api/bsa';
import { BsaType } from '../../api/constans';
import { BSAStatus } from '../../api/type';
import { PCSType } from './components/BaseInfo';
import { BsaComponentByType } from './factory';

const Bsa: FC<{ disabled?: boolean }> = ({ disabled = false }) => {
  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState<boolean>(false);

  const [updateState, updateFn] = useUpdate();

  const [typeList, setTypeList] = useState<BsaType[]>();
  const [currentType, setCurrentType] = useState<BsaType>();
  const [foucsType, setFoucsType] = useState<BsaType>();

  const [pcsStruct, setPcsStruct] = useState<PCSType>();
  const [hardwareVersion, setHardwareVersion] = useState<string>();
  const [isDrft, setIsDraft] = useState<boolean>(true);
  const [bsaStatus, setBsaStatus] = useState<BSAStatus>();

  useEffect(() => {
    if (id) {
      getBsa(id).then(res => {
        setPcsStruct(res.pcsStruct);
        setHardwareVersion(res.hardwareVersion);
        setIsDraft(res.status === BSAStatus.DRAFT);
        setBsaStatus(res.status);
      });
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getFsm(id).then(res => {
        setLoading(false);
        const typeList = res.fsmList.filter(i => i.executed).map(i => i.fsmType);
        setCurrentType(res.currentFsm);
        if (res.currentFsm) {
          if (res.currentFsm === BsaType.BSA_COMPLETED) {
            setFoucsType(typeList[typeList.length - 1]);
          } else {
            setFoucsType(res.currentFsm);
          }
        } else {
          setFoucsType(BsaType.ARRAY_INFO_INIT);
        }
        setTypeList(typeList.filter(i => i !== BsaType.BSA_COMPLETED));
      });
    }
  }, [id, updateState]);

  const updateFsm = useCallback(
    (update: boolean = false, type?: BsaType) => {
      if (update && id) {
        getFsm(id).then(res => {
          setLoading(false);
          const typeList = res.fsmList.filter(i => i.executed).map(i => i.fsmType);
          setCurrentType(res.currentFsm);
          setTypeList(typeList.filter(i => i !== BsaType.BSA_COMPLETED));
          if (type) {
            setFoucsType(type);
          }
        });
      } else {
        updateFn();
      }
    },
    [updateFn, id]
  );

  const renderComponent = useMemo(() => {
    if (foucsType) {
      const Component = BsaComponentByType[foucsType];
      return Component ? (
        <Component
          typeList={typeList}
          currentType={currentType}
          foucsType={foucsType}
          changeType={setFoucsType}
          pcsStruct={pcsStruct}
          hardwareVersion={hardwareVersion}
          updateFn={updateFsm}
          disabled={disabled}
          status={bsaStatus}
          isDraft={isDrft}
        />
      ) : null;
    } else {
      return null;
    }
  }, [typeList, currentType, foucsType, updateFsm, disabled, isDrft, hardwareVersion, pcsStruct, bsaStatus]);

  return (
    <Spin spinning={loading} style={{ minHeight: 300 }}>
      {renderComponent}
    </Spin>
  );
};

export default Bsa;
