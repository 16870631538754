import { FC, ReactNode, useEffect, useState } from 'react';
import styles from './index.module.scss';
import { DatePicker, Radio } from '@maxtropy/components';
import dayjs, { Dayjs } from 'dayjs';
import { TimeGranularity } from '../../const';

interface Props {
  onChange: (date: Dayjs, timeGranularity: TimeGranularity) => void;
  extend?: ReactNode;
}

const TopFilter: FC<Props> = props => {
  const { onChange } = props;

  const [date, setDate] = useState<Dayjs>(dayjs());
  const [timeGranularity, setTimeGranularity] = useState<TimeGranularity>(TimeGranularity.ORIGINAL);

  useEffect(() => {
    onChange(date, timeGranularity);
  }, [date, timeGranularity]);

  return (
    <div className={styles.topFilter}>
      <DatePicker value={date} onChange={setDate} allowClear={false} maxDate={dayjs()}></DatePicker>
      <Radio.Group
        buttonStyle="solid"
        value={timeGranularity}
        onChange={e => setTimeGranularity(e.target.value)}
        style={{ marginLeft: 16 }}
      >
        <Radio.Button value={TimeGranularity.ORIGINAL}>原始数据</Radio.Button>
        <Radio.Button value={TimeGranularity.ONE_MINUTE}>1分钟</Radio.Button>
        <Radio.Button value={TimeGranularity.FIFTEEN_MINUTE}>15分钟</Radio.Button>
      </Radio.Group>
      {props.extend}
    </div>
  );
};

export default TopFilter;
