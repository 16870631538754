import { EllipsisSpan, Table } from '@maxtropy/components';
import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { IndicatorLogProps, MonitoringIndicatorProps, queryIndicatorLogList } from '../../api/circuit';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

interface LogTableProps {
  row?: Partial<MonitoringIndicatorProps>;
}

const columns: ColumnsType<IndicatorLogProps> = [
  {
    title: '原设备',
    dataIndex: 'originalDeviceName',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '原数据属性',
    dataIndex: 'originalDataPropertyName',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '变更后的设备',
    dataIndex: 'modifiedDeviceName',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '变更后的数据属性',
    dataIndex: 'modifiedDataPropertyName',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '变更的数据生效时间',
    dataIndex: 'editTime',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm') : v} />,
  },
  {
    title: '解绑的数据生效时间',
    dataIndex: 'cancelTime',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm') : v} />,
  },
  {
    title: '操作时间',
    dataIndex: 'createTime',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm') : v} />,
  },
];

const LogTable: React.FC<LogTableProps> = props => {
  const { row } = props;
  const { data: dataSource } = useQuery(
    ['indicatorLogList', row?.circuitId, row?.circuitPhysicalQuantityId],
    async () => {
      if (!row?.circuitId) return [];
      const res = await queryIndicatorLogList({
        circuitId: row.circuitId,
        circuitPhysicalQuantityId: row.circuitPhysicalQuantityId!,
      });
      return res;
    }
  );

  return <Table sticky rowKey="id" columns={columns} dataSource={dataSource} />;
};

export default LogTable;
