import { PermissionsType } from '@/common/permissionsConst';
import BatteryThermalDiagram from '.';

const routes = [
  {
    path: '/battery/thermal/diagram',
    permission: PermissionsType.P_ENERGYSTORAGEHEATMAP,
    element: <BatteryThermalDiagram />,
  },
];
export default routes;
