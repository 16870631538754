import { PermissionsType } from '@/common/permissionsConst';
import BsaOperationDashBoard from '.';

const routes = [
  {
    path: '/operation/bsa/dashboard',
    permission: PermissionsType.P_BSADASHBOARD,
    element: <BsaOperationDashBoard />,
  },
];
export default routes;
