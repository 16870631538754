import { Form, Breadcrumb, useBreadcrumbRoutes, Wrapper, Button, SubContent, FormTitle } from '@maxtropy/components';
import { Space, Spin } from 'antd';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  E_PRICE_CHARGE_TYPE_MAP,
  E_PRICE_SCOPE_MAP,
  E_PRICE_TIMING_TYPE_MAP,
  EPriceChargeType,
  EPriceScope,
  EPriceTimingType,
  fetchEPrice,
} from '../../api/electricityPrice';
import EPriceTimeTable from '../../components/EPriceTime/Table';
import styles from './index.module.scss';

interface ElectricityPriceDetailProps {}

const routes = [{ name: `查看电价` }];

const ElectricityPriceDetail: React.FC<ElectricityPriceDetailProps> = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const routesContext = useBreadcrumbRoutes();

  const { data, isLoading } = useQuery(['ePrice', +id], () => fetchEPrice(+id));

  return (
    <Wrapper className={styles.wrapperPadding} routes={[...(routesContext?.routes ?? []), ...routes]}>
      <Spin spinning={isLoading}>
        <Form>
          <FormTitle title="查看电价" />
          <SubContent>
            <Form.Item label="电价名称">{data?.name}</Form.Item>
            <Form.Item label="所属运营单元">{data?.ou?.name}</Form.Item>
            <Form.Item label="电价范围">{data?.scopeType && E_PRICE_SCOPE_MAP[data?.scopeType]}</Form.Item>
            {data?.scopeType === EPriceScope.POWER_GRID && (
              <Form.Item label="电价类型">{data?.chargeType && E_PRICE_CHARGE_TYPE_MAP[data.chargeType]}</Form.Item>
            )}
            {data?.chargeType === EPriceChargeType.DOUBLE && (
              <>
                <Form.Item label="需量电价">{data?.demandRate?.toFixed(4)} 元/kWh</Form.Item>

                <Form.Item label="容量电价">{data?.capacityRate?.toFixed(4)} 元/kWh</Form.Item>
              </>
            )}
            <Form.Item label="电度电费">{data?.timingType && E_PRICE_TIMING_TYPE_MAP[data.timingType]}</Form.Item>
            {data?.timingType === EPriceTimingType.NO_TIMING ? (
              <Form.Item label="电度电价">{data?.fixedEnergyRate?.toFixed(4)} 元/kWh</Form.Item>
            ) : (
              <>
                <Form.Item label="分时电价"></Form.Item>

                <EPriceTimeTable dataSource={data?.ePriceParts || []} disabled />
              </>
            )}
          </SubContent>
        </Form>
        <Space
          style={{
            marginLeft: 32,
            marginTop: -12,
          }}
          size={8}
          className={'sticky-footer-left'}
        >
          <Button onClick={() => navigate('/dmes/circuit/e-price')}>返回</Button>
        </Space>
      </Spin>
    </Wrapper>
  );
};

export default ElectricityPriceDetail;
