import React, { useMemo, useContext } from 'react';
import { App, Space, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import ReactEcharts from 'echarts-for-react';
import { useQuery } from 'react-query';
import { getBsaBindingPcsList, Stack } from '@/api/bsa';
import {
  DeviceData,
  getDeviceDataInstantaneous,
  getDeviceDataInstantaneousFor1m,
  getDeviceDataInstantaneousForRaw,
  GrainChoice,
} from '@/api/device';
import { isNil } from 'lodash-es';
import { timeGapMap } from './BatteryTemper';
import { StorageContext } from '../StorageContext';
import { Checkbox, DatePicker, Empty, Radio, Select } from '@maxtropy/components';

const { RangePicker } = DatePicker;

export interface CurrentTabProps {
  bsaId: number;
}

enum TimeChoice {
  TODAY,
  LAST_24_HOURS,
  CUSTOM,
}

const STACK_CURRENT_ID = 10330;
const POWER_PROPERTY_ID = 10061;
const ALL_OPTION_VALUE = -1;

const MaxselectNums = 10;
const TipsMsg = `最多选择${MaxselectNums}个`;
const SelectMsg = `全选（前${MaxselectNums}个）`;

const CurrentTabContent: React.FC<CurrentTabProps> = props => {
  const { bsaId } = props;
  const StorageContextValue = useContext(StorageContext);
  const { message } = App.useApp();
  const disabledDate = (current: Dayjs) => {
    return current && current.valueOf() >= dayjs().endOf('day').valueOf();
  };

  // 当前颗粒度

  const [from, to] = useMemo(() => {
    if (StorageContextValue?.timeChoice === TimeChoice.TODAY) {
      return [dayjs().startOf('day').valueOf(), dayjs().endOf('day').valueOf()];
    } else if (StorageContextValue?.timeChoice === TimeChoice.LAST_24_HOURS) {
      return [dayjs().subtract(24, 'hours').valueOf(), dayjs().valueOf()];
    } else if (StorageContextValue?.customDateRange) {
      return [
        StorageContextValue?.customDateRange[0].startOf('day').valueOf(),
        StorageContextValue?.customDateRange[1].endOf('day').valueOf(),
      ];
    }
    return [undefined, undefined];
  }, [StorageContextValue?.customDateRange, StorageContextValue?.timeChoice]);

  const { data: pcsList } = useQuery(['pcsList', bsaId], () => getBsaBindingPcsList(bsaId!), {
    enabled: !!bsaId,
  });

  const stacks = StorageContextValue?.stacks;

  // 设备ids
  const deviceIds = useMemo(() => {
    if (stacks && stacks.length !== 0) {
      if ((stacks as any).includes(undefined)) {
        return '';
      }
      const newStacks = StorageContextValue?.selectedStacks.map(id => stacks.find(item => id === item.stackId)!);
      return newStacks.map(item => item.deviceId).join(',');
    } else {
      return '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [StorageContextValue?.selectedStacks]);

  const requestApi = useMemo(() => {
    return StorageContextValue?.grainChoice === GrainChoice.ORIGINAL
      ? getDeviceDataInstantaneousForRaw
      : StorageContextValue?.grainChoice === GrainChoice.MINUTE_1
      ? getDeviceDataInstantaneousFor1m
      : getDeviceDataInstantaneous;
  }, [StorageContextValue?.grainChoice]);

  const { data: chartData, isLoading: isChartDataLoading } = useQuery(
    ['chartData', deviceIds, from, to, bsaId, StorageContextValue?.grainChoice],
    () =>
      requestApi({
        deviceIds: deviceIds!,
        propertyIds: String(STACK_CURRENT_ID),
        startTime: from!,
        endTime: to!,
      }),
    {
      enabled: !!from && !!to && !!deviceIds && !!bsaId,
    }
  );

  const pcs = pcsList?.find(item => item.id === stacks?.[0]?.pcsId);
  const pcsDeviceId = pcs?.deviceId;
  const { data: pcsPowerData, isLoading: isPowerLoading } = useQuery(
    ['power', pcsDeviceId, from, to, StorageContextValue?.comparePcs, StorageContextValue?.grainChoice],
    () =>
      requestApi({
        deviceIds: String(pcsDeviceId!),
        propertyIds: String(POWER_PROPERTY_ID),
        startTime: from!,
        endTime: to!,
      }),
    {
      enabled: !!bsaId && StorageContextValue?.comparePcs && !!from && !!to && !!pcsDeviceId,
    }
  );

  // 下拉选项
  const options = stacks?.map(item => ({
    value: item.stackId,
    label: `电池堆${item.stackSequence + 1}`,
  }));

  const selectOptions =
    !options || options.length === 0 ? [] : [{ value: ALL_OPTION_VALUE, label: SelectMsg }, ...options];

  const isDataEmpty = useMemo(() => {
    return (
      !chartData?.some(item => item.values.some(v => !isNil(v.value))) &&
      !pcsPowerData?.some(item => item.values.some(v => !isNil(v.value)))
    );
  }, [pcsPowerData, chartData]);

  return (
    <div>
      <Space wrap size={20}>
        <Space size={20}>
          <Radio.Group
            buttonStyle={'solid'}
            value={StorageContextValue?.timeChoice}
            onChange={e => {
              StorageContextValue?.setTimeChoice(e.target.value);
            }}
          >
            <Radio.Button value={TimeChoice.TODAY}>当日</Radio.Button>
            <Radio.Button value={TimeChoice.LAST_24_HOURS}>最近24小时</Radio.Button>
            <Radio.Button value={TimeChoice.CUSTOM}>其他</Radio.Button>
          </Radio.Group>
          <Space>
            颗粒度:
            <Radio.Group
              buttonStyle={'solid'}
              value={StorageContextValue?.grainChoice}
              onChange={e => {
                StorageContextValue?.setGrainChoice(e.target.value);
              }}
            >
              <Radio.Button value={GrainChoice.ORIGINAL}>原始数据</Radio.Button>
              <Radio.Button value={GrainChoice.MINUTE_1}>1分钟</Radio.Button>
              <Radio.Button value={GrainChoice.MINUTE_15}>15分钟</Radio.Button>
            </Radio.Group>
          </Space>
          {StorageContextValue?.timeChoice === TimeChoice.CUSTOM && (
            <Space>
              选择日期:
              <RangePicker
                allowClear={false}
                value={StorageContextValue?.customDateRange}
                disabledDate={disabledDate}
                onChange={e => {
                  const value = e as [dayjs.Dayjs, dayjs.Dayjs];
                  const timeGap = value[1].endOf('day').diff(value[0].startOf('day'), 'days', true);
                  if (timeGap > timeGapMap[StorageContextValue?.grainChoice]) {
                    return message.error(`最大跨度为${timeGapMap[StorageContextValue?.grainChoice]}天`);
                  }
                  StorageContextValue?.setCustomDateRange(value);
                }}
              />
            </Space>
          )}
        </Space>
        <Space size={20}>
          选择电池堆:
          <Select
            mode={'multiple'}
            allowClear
            placeholder={'请选择'}
            style={{ width: 300 }}
            maxTagCount={2}
            value={StorageContextValue?.selectedStacks}
            options={selectOptions}
            onChange={values => {
              if (values.includes(ALL_OPTION_VALUE)) {
                StorageContextValue?.setSelectedStacks(stacks!.map(item => item.stackId).slice(0, MaxselectNums));
              } else {
                if (values.length > MaxselectNums) {
                  return message.error(TipsMsg);
                }
                StorageContextValue?.setSelectedStacks(values);
              }
            }}
          />
          <Checkbox
            checked={StorageContextValue?.comparePcs}
            onChange={e => {
              StorageContextValue?.setComparePcs(e.target.checked);
            }}
          >
            与PCS功率对比
          </Checkbox>
        </Space>
      </Space>
      <Spin spinning={isPowerLoading || isChartDataLoading}>
        <div style={{ marginTop: 30, height: 'calc(100vh - 303px)' }}>
          {isDataEmpty && !isPowerLoading && !isChartDataLoading ? (
            <Empty style={{ paddingTop: 120 }} />
          ) : (
            <ReactEcharts
              option={getOption(
                from!,
                to!,
                !!(stacks?.length && stacks.length > 1),
                chartData,
                stacks,
                pcsPowerData,
                StorageContextValue?.grainChoice
              )}
              theme={'dark'}
              style={{ height: '100%' }}
              notMerge
              lazyUpdate={false}
            />
          )}
        </div>
      </Spin>
    </div>
  );
};

const getOption = (
  from: number,
  to: number,
  showStackName: boolean,
  chartData?: DeviceData[],
  stacks?: Stack[],
  pcsPowerData?: DeviceData[],
  grainChoice?: GrainChoice
) => {
  const currentSeries =
    chartData?.map(item => {
      let name;
      if (item.deviceId === -1) {
        name = '电池堆总电流';
      } else {
        const device = stacks?.find(d => d.deviceId === item.deviceId)!;
        name = `电池堆${device?.stackSequence + 1}总电流`;
      }

      const data = item.values.map(v => [v.time, v.value]);
      return {
        connectNulls: true,
        name,
        type: 'line',
        data,
        smooth: true,
        yAxisIndex: 0,
      };
    }) || [];

  const powerSeries =
    pcsPowerData?.map(item => {
      return {
        connectNulls: true,
        name: 'PCS功率',
        type: 'line',
        data: item.values.map(v => [v.time, v.value]),
        smooth: true,
        yAxisIndex: 1,
      };
    }) || [];

  const currentYAxis = {
    name: 'A',
    type: 'value',
    nameTextStyle: { align: 'right', padding: [5, 10] },
    scale: true,
    splitLine: {
      lineStyle: {
        color: 'rgba(255,255,255,0.35)',
      },
    },
  };

  const powerYAxis = {
    name: 'kW',
    type: 'value',
    nameTextStyle: { align: 'left', padding: [5, 10] },
    scale: true,
    splitLine: {
      lineStyle: {
        color: 'rgba(255,255,255,0.35)',
      },
    },
  };

  return {
    backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue('--component-background'),
    grid: { left: 80, right: 80 },
    legend: {
      show: true,
      right: 100,
      top: 0,
      icon: 'rect',
      textStyle: {
        color: '#AFBCC4',
      },
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params: any[]) => {
        return params.reduce(
          (acc, curr) =>
            acc +
            `<span >${curr.marker} ${curr.seriesName}：</span> ${curr.data[1]?.toFixed(1) || '--'}${
              curr.seriesName === 'PCS功率' ? 'kW' : 'A'
            }<br />`,
          `${params[0].axisValueLabel}<br />`
        );
      },
    },
    dataZoom: [
      {
        type: 'slider',
        start: grainChoice === GrainChoice.ORIGINAL ? 98 : grainChoice === GrainChoice.MINUTE_1 ? 90 : 0,
        end: 100,
        bottom: 15,
        height: 20,
        textStyle: {
          fontSize: 10,
        },
      },
      { type: 'inside' },
    ],
    xAxis: {
      // min: from,
      // max: to,
      // interval: 3600 * 1000,
      minInterval: 60 * 1000,
      type: 'time',
      axisLabel: {
        color: 'rgba(255,255,255,0.6)',
        fontSize: 12,
        formatter: (val: number) => dayjs(val, 'x').format('MM-DD[\n]HH:mm'),
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: powerSeries.length ? [currentYAxis, powerYAxis] : currentYAxis,
    series: [...currentSeries, ...powerSeries],
  };
};

export default CurrentTabContent;
