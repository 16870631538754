import { V2PvOutlinePvPowerStatisticsPostResponse } from '@maxtropy/dmes-apis-v2';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';

export const getCurveChartOption = (chartData?: V2PvOutlinePvPowerStatisticsPostResponse) => {
  let totalIncomingPower = (chartData?.pvPowerValueStatisticList ?? []).map(item => [
    dayjs(item.time).valueOf(),
    keepTwoNull(item.totalIncomingPower),
  ]);
  let gridConnectedTotalPower = (chartData?.pvPowerValueStatisticList ?? []).map(item => [
    dayjs(item.time).valueOf(),
    keepTwoNull(item.gridConnectedTotalPower),
  ]);

  const incomingPowerData = (chartData?.pvPowerValueStatisticList ?? []).map(item => item.totalIncomingPower ?? 0);
  const gridConnectedTotalPowerData = (chartData?.pvPowerValueStatisticList ?? []).map(
    item => item.gridConnectedTotalPower ?? 0
  );
  const maxIncomingPowerData = Math.ceil(Math.max(...incomingPowerData));
  const minIncomingPowerData = Math.floor(Math.min(...incomingPowerData));
  const maxGridConnectedTotalPowerData = Math.ceil(Math.max(...gridConnectedTotalPowerData));
  const minGridConnectedTotalPowerData = Math.floor(Math.min(...gridConnectedTotalPowerData));
  const max = Math.ceil(Math.max(maxIncomingPowerData, maxGridConnectedTotalPowerData, chartData?.capacity ?? 0));
  const min = Math.floor(Math.min(minIncomingPowerData, minGridConnectedTotalPowerData, chartData?.capacity ?? 0));

  return {
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.9)',
      borderColor: 'transparent',
      textStyle: {
        color: '#ffffff85',
      },
      formatter: (items: any) => {
        let { axisValue } = items[0];
        let firstStr = dayjs(axisValue).format('YYYY-MM-DD HH:mm:ss');
        let otherStr = '';
        items.forEach((item: any) => {
          const { seriesName, data, marker } = item;
          const unit = 'kW';
          otherStr += `<br>${marker}${seriesName} : &nbsp;${data[1] || data[1] === 0 ? data[1] + unit : '-'}`;
        });
        return firstStr + otherStr;
      },
    },
    grid: {
      left: 20,
      right: 20,
      top: 60,
      bottom: 40,
      containLabel: true,
    },
    dataZoom: {
      type: 'slider',
      left: 20,
      right: 20,
      height: 20,
      bottom: 10,
      textStyle: {
        fontSize: 10,
      },
      labelFormatter: (value: string) => {
        return dayjs(value).format('HH:mm');
      },
    },

    // color: ['#16DD8E', '#FFCB47'],
    legend: {
      right: 10,
      textStyle: {
        color: '#ffffff85',
      },
      inactiveColor: '#ffffff45',
      itemWidth: 16,
      itemHeight: 4,
      data: [
        {
          name: '总进线功率',
          icon: 'path://M0 426.666667h1024v170.666666H0z',
        },
        {
          name: '并网点总功率',
          icon: 'path://M0 426.666667h1024v170.666666H0z',
        },
      ],
    },
    xAxis: [
      {
        type: 'time',
        axisLabel: {
          formatter: '{HH}:{mm}',
        },
        axisPointer: {
          type: 'shadow',
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: '功率（kW）',
        axisLabel: {
          formatter: '{value}',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#66686A',
          },
        },
        max: max,
        min: min,
      },
    ],
    series: [
      {
        name: '',
        type: 'line',
        markLine: {
          silent: true,
          symbol: ['none', 'none'],
          lineStyle: {
            type: 'dashed',
            color: '#FFC53D',
          },
          data: [
            {
              label: {
                formatter: `装机容量: {c}kW`,
                position: 'insideStartTop',
                color: '#FFC53D',
              },
              yAxis: keepTwoNull(chartData?.capacity),
            },
          ],
        },
      },
      {
        name: '总进线功率',
        type: 'line',
        color: '#16DD8E',
        data: totalIncomingPower,
        symbol: 'circle',
        symbolSize: 2,
        showSymbol: false, // 只有在 tooltip hover 的时候显示symbol
        itemStyle: {
          // symbol样式
          normal: {
            borderColor: 'rgba(255, 255, 255, 0.2)', // symbol边框颜色
            borderWidth: 6, // symbol边框宽度
          },
        },
      },

      {
        name: '并网点总功率',
        type: 'line',
        color: '#2D8DFF',
        data: gridConnectedTotalPower,
        symbol: 'circle',
        symbolSize: 2,
        showSymbol: false, // 只有在 tooltip hover 的时候显示symbol
        itemStyle: {
          // symbol样式
          normal: {
            borderColor: 'rgba(255, 255, 255, 0.2)', // symbol边框颜色
            borderWidth: 6, // symbol边框宽度
          },
        },
      },
    ],
  };
};

export const keepTwoNull = (data?: number) => {
  if (!isNil(data)) {
    return Number(data.toFixed(2));
  } else {
    return null;
  }
};
