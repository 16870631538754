import { FC, useEffect, useMemo, useState } from 'react';
import { Form, Radio, InputNumber, Breadcrumb, useBreadcrumbRoutes, Modal, Button } from '@maxtropy/components';
import BorderWrapper from '../../../../components/BorderWrapper';

import BsaSteps from '../BsaSteps';
import BsaPageHeader from '../BsaPageHeader';
import DividerContent from '../DividerContent';

import { Space, Divider } from 'antd';

import styles from './index.module.scss';

import {
  AddrOffsetLabels,
  ADDR_OFFSET,
  BSAStatus,
  DriveType,
  DriveTypeLabels,
  TopologicalType,
  TopologicalTypeLabels,
} from '../../../../api/type';
import { BsaComponentsProps, getNext, getPrevious } from '../../factory';
import { BsaType } from '../../../../api/constans';
import {
  BsaEdgeDeviceConfigPreview,
  createGlobalEdgeConfig,
  getBsaEdgeDeviceConfigPreview,
  getGlobalEdgeConfig,
  GlobalEdgeRequest,
} from '../../../../api/edgeDevice';
import { useParams } from 'react-router-dom';
import { getDraft, saveDraft } from '../../../../api/bsa';
import IpAddress from '../../../../components/IpAddress';
import { padStart } from '../../utils';

const formLayout = {
  labelCol: { flex: '200px' },
  // wrapperCol: { flex: "340px" },
};

const BsaGlobalEdge: FC<BsaComponentsProps> = ({
  disabled = false,
  typeList,
  foucsType,
  currentType,
  hardwareVersion,
  updateFn,
  status,
  changeType,
}) => {
  const { id } = useParams<{ id: string }>();

  const [form] = Form.useForm();

  const routesContext = useBreadcrumbRoutes();

  const routes = [{ name: `电池全局数采配置` }];

  const isComplete = useMemo(() => {
    return (typeList ?? []).includes(BsaType.ACQUISITION_CONFIG);
  }, [typeList]);

  // const [data, setData] = useState<GlobalEdgeRequest>();

  const [topologicalType, setTopologicalType] = useState<TopologicalType>();

  const [bsaEdgeDeviceConfigPreview, setBsaEdgeDeviceConfigPreview] = useState<BsaEdgeDeviceConfigPreview>();

  useEffect(() => {
    if (id && topologicalType) {
      getBsaEdgeDeviceConfigPreview({
        bsaId: id,
        topologicalType,
      }).then(setBsaEdgeDeviceConfigPreview);
    }
  }, [id, topologicalType]);

  useEffect(() => {
    if (id) {
      if (isComplete) {
        getGlobalEdgeConfig(id).then(res => {
          setTopologicalType(res.topologicalType);
          form.setFieldsValue({
            ...res,
          });
        });
      } else {
        // 未完成，查看是否有草稿
        getDraft(id).then(draft => {
          const content: Partial<Omit<GlobalEdgeRequest, 'bsaId'>> = draft.content
            ? JSON.parse(draft.content)
            : undefined;
          if (content) {
            setTopologicalType(content.topologicalType);
            form.setFieldsValue({
              ...content,
            });
          }
        });
      }
    }
  }, [id, isComplete, form]);

  const onPrevious = () => {
    if (Array.isArray(typeList) && foucsType) {
      const type = getPrevious(foucsType, typeList);
      if (type) {
        changeType?.(type);
      }
    }
  };

  const onNext = () => {
    const type = getNext(foucsType!, typeList ?? [], currentType);
    if (type) {
      changeType?.(type);
    }
  };

  const onFinish = () => {
    if (id) {
      if (disabled) {
        onNext();
      } else {
        if (isComplete) {
          onNext();
        } else {
          form.validateFields().then((res: Omit<GlobalEdgeRequest, 'bsaId'>) => {
            createGlobalEdgeConfig({
              bsaId: Number(id),
              ...res,
            }).then(() => {
              updateFn?.();
            });
          });
        }
      }
    }
  };

  const onDraft = () => {
    const value: Partial<Omit<GlobalEdgeRequest, 'bsaId'>> = form.getFieldsValue();
    saveDraft({
      bsaId: Number(id),
      content: JSON.stringify(value),
    }).then(() => {
      Modal.success({
        content: '保存成功',
      });
    });
  };
  // MP1硬件结构版本的阵列，只可以选择EMS同时连接PCS和BAMS
  const isMP1 = useMemo(() => {
    return hardwareVersion === 'MP1';
  }, [hardwareVersion]);

  // 默认选中
  useEffect(() => {
    isMP1 && setTopologicalType(TopologicalType.BMS_SIMULTANEOUSLY_PCS_AND_BAMS);
  }, [isMP1]);
  return (
    <>
      <div className="page">
        <div className="page__header">
          <Breadcrumb routes={[...(routesContext?.routes ?? []), ...routes]} />
        </div>
        <BorderWrapper>
          <div className="page__content" style={{ minHeight: 'calc(100vh - 128px)' }}>
            <BsaPageHeader
              title="电池全局数采配置"
              extraContent={
                <Space>
                  <Button type="primary" onClick={onPrevious}>
                    上一步
                  </Button>
                  <Button type="primary" onClick={onFinish}>
                    下一步
                  </Button>
                  {!isComplete && status !== BSAStatus.INVALID && (
                    <Button type="primary" onClick={onDraft}>
                      存草稿
                    </Button>
                  )}
                </Space>
              }
            >
              <BsaSteps typeList={typeList} foucsType={foucsType} />
            </BsaPageHeader>
            <Form form={form} labelAlign="left" labelWrap {...formLayout}>
              <DividerContent>
                <Form.Item label="拓扑构型" name="topologicalType" rules={[{ required: true, message: '请选择' }]}>
                  <Radio.Group
                    buttonStyle="solid"
                    onChange={e => {
                      setTopologicalType(e.target.value);
                    }}
                    disabled={disabled || isComplete}
                  >
                    <Radio.Button disabled={isMP1} value={TopologicalType.PCS_Penetrate_BMS}>
                      {TopologicalTypeLabels[TopologicalType.PCS_Penetrate_BMS]}
                    </Radio.Button>
                    <Radio.Button value={TopologicalType.BMS_SIMULTANEOUSLY_PCS_AND_BAMS}>
                      {TopologicalTypeLabels[TopologicalType.BMS_SIMULTANEOUSLY_PCS_AND_BAMS]}
                    </Radio.Button>
                    {(typeList ?? [])?.includes(BsaType.RACK_DEVICE_CREATED) && (
                      <Radio.Button disabled value={3}>
                        EMS同时连接PCS和BCMU
                      </Radio.Button>
                    )}
                    {(typeList ?? [])?.includes(BsaType.PACK_DEVICE_CREATED) && (
                      <Radio.Button disabled value={4}>
                        EMS同时连接PCS和BMU
                      </Radio.Button>
                    )}
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="驱动类型" name="driveType" rules={[{ required: true, message: '请选择' }]}>
                  <Radio.Group buttonStyle="solid" disabled={disabled || isComplete}>
                    <Radio.Button value={DriveType.MODBUS_TCP}>{DriveTypeLabels[DriveType.MODBUS_TCP]}</Radio.Button>
                    <Radio.Button disabled value={DriveType.IEC104}>
                      {DriveTypeLabels[DriveType.IEC104]}
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </DividerContent>
              <DividerContent title="全局数采配置">
                <Form.Item
                  label="单次最多可请求寄存器字节数"
                  name="groupWords"
                  rules={[{ required: true, message: '请输入' }]}
                  initialValue={60}
                >
                  <InputNumber min={1} max={60} precision={0} disabled={disabled || isComplete} />
                </Form.Item>
                <Form.Item
                  label="采集间隔(毫秒)"
                  name="samplingInterval"
                  rules={[{ required: true, message: '请输入' }]}
                  initialValue={25}
                >
                  <InputNumber min={1} max={99999999} precision={0} addonAfter="ms" disabled={disabled || isComplete} />
                </Form.Item>
                <Form.Item
                  label="采集超时(毫秒)"
                  name="samplingTimeout"
                  rules={[{ required: true, message: '请输入' }]}
                  initialValue={1000}
                >
                  <InputNumber min={1} max={25000} precision={0} addonAfter="ms" disabled={disabled || isComplete} />
                </Form.Item>
                <Form.Item
                  label="采集重试次数"
                  name="samplingRetry"
                  rules={[{ required: true, message: '请输入' }]}
                  initialValue={3}
                >
                  <InputNumber min={1} max={9} precision={0} disabled={disabled || isComplete} />
                </Form.Item>
                <Form.Item
                  label="首地址偏移"
                  name="addrOffset"
                  rules={[{ required: true, message: '请选择' }]}
                  initialValue={ADDR_OFFSET.ZERO}
                >
                  <Radio.Group buttonStyle="solid" disabled={disabled || isComplete}>
                    <Radio.Button value={ADDR_OFFSET.ZERO}>{AddrOffsetLabels[ADDR_OFFSET.ZERO]}</Radio.Button>
                    <Radio.Button value={ADDR_OFFSET.ONE}>{AddrOffsetLabels[ADDR_OFFSET.ONE]}</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </DividerContent>
            </Form>
            {topologicalType !== undefined && (
              <DividerContent title="对接颗粒度批量配置项">
                {topologicalType === TopologicalType.PCS_Penetrate_BMS &&
                  (bsaEdgeDeviceConfigPreview?.pcs ?? []).map(i => (
                    <div key={i.id}>
                      <div className={styles.edgeDeviceConfigPreviewTitle}>
                        PCS{padStart(i.sequence + 1)}数采相关配置
                      </div>
                      <Form labelAlign="left" labelWrap {...formLayout}>
                        <Form.Item label="Ip地址">
                          <IpAddress value={i.ip} disabled />
                        </Form.Item>
                        <Form.Item label="端口">
                          <InputNumber value={i.port} disabled />
                        </Form.Item>
                        <Form.Item label="单元标识符">
                          <InputNumber value={i.stationNum} disabled />
                        </Form.Item>
                      </Form>
                      <Divider dashed />
                    </div>
                  ))}
                {topologicalType === TopologicalType.BMS_SIMULTANEOUSLY_PCS_AND_BAMS &&
                  (bsaEdgeDeviceConfigPreview?.stack ?? []).map(i => (
                    <div key={i.id}>
                      <div className={styles.edgeDeviceConfigPreviewTitle}>
                        电池堆{padStart(i.sequence + 1)}的下级电池簇/组/芯的采集网络地址信息
                      </div>
                      <Form labelAlign="left" labelWrap {...formLayout}>
                        <Form.Item label="Ip地址">
                          <IpAddress value={i.ip} disabled />
                        </Form.Item>
                        <Form.Item label="端口">
                          <InputNumber value={i.port} disabled />
                        </Form.Item>
                        <Form.Item label="单元标识符">
                          <InputNumber value={i.stationNum} disabled />
                        </Form.Item>
                      </Form>
                      <Divider dashed />
                    </div>
                  ))}
              </DividerContent>
            )}
          </div>
        </BorderWrapper>
      </div>
    </>
  );
};

export default BsaGlobalEdge;
