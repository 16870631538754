import { Space, Timeline } from 'antd';
import React from 'react';
import { MonitoringIndicatorProps, queryDataLinkList } from '../../api/circuit';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

interface DataLinkProps {
  row?: Partial<MonitoringIndicatorProps>;
}

const DataLink: React.FC<DataLinkProps> = props => {
  const { row } = props;
  const { data: list = [] } = useQuery(['dataLinkList', row?.circuitId, row?.circuitPhysicalQuantityId], async () => {
    if (!row?.circuitId) return [];
    const res = await queryDataLinkList({
      circuitId: row.circuitId,
      circuitPhysicalQuantityId: row.circuitPhysicalQuantityId!,
    });
    return res;
  });

  return (
    <div style={{ marginLeft: 60 }}>
      <h3 style={{ margin: '20px 0' }}>{row?.circuitPhysicalQuantityName}</h3>
      {list.length > 0 && (
        <Timeline>
          {list.map(item => (
            <Timeline.Item>
              <p>
                <Space size={20}>
                  <span>{`${item.deviceName}-${item.dataPropertyName}`}</span>
                  <span>
                    <Space size={10}>
                      <span style={{ color: '#63BC7F' }}>{`${
                        item.startTime ? dayjs(item.startTime).format('YYYY-MM-DD HH:mm') : '--'
                      }`}</span>
                      <span>~</span>
                      <span style={{ color: '#4A90E2' }}>{`${
                        item.endTime ? dayjs(item.endTime).format('YYYY-MM-DD HH:mm') : '∞'
                      }`}</span>
                    </Space>
                  </span>
                </Space>
              </p>
            </Timeline.Item>
          ))}
        </Timeline>
      )}
    </div>
  );
};

export default DataLink;
