import { FC, useEffect, useState } from 'react';
import { Form, InputNumber, Modal, Table } from '@maxtropy/components';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Row, Space, TableColumnsType } from 'antd';
import { DataItem as PropData } from './InverterOrComponentTable';
import {
  InverterOrComponentThresholdType,
  InverterOrComponentThresholdTypeColor,
  InverterOrComponentThresholdTypeLabel,
  InverterOrComponentThresholdTypeList,
  ThresholdDeviceType,
} from './const';
import styles from './index.module.scss';
import { Rule } from 'antd/es/form';
import type { NamePath } from 'rc-field-form/lib/interface';
import { apiV2PvDispersionRateThresholdSetUpPost } from '@maxtropy/dmes-apis-v2';

const getFormItemDependencies = (record: TableDataItem, name: string): NamePath[] => {
  return InverterOrComponentThresholdTypeList.filter(item => item !== record.id).map(type => [type, name]);
};

const getFormItemRules = (record: TableDataItem, name: string): Rule[] => {
  return [
    {
      required: true,
      message: '请输入',
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (value === 100) return Promise.reject('必须小于前一项');
        if (value === 0) return Promise.reject('必须大于0');
        if (record.id === InverterOrComponentThresholdTypeList[0]) return Promise.resolve();
        if (getFieldValue([record.id + 1, name]) <= value) return Promise.reject('必须小于前一项');
        return Promise.resolve();
      },
    }),
  ];
};

interface TableDataItem {
  id: InverterOrComponentThresholdType;
}

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  data: PropData | undefined;
  type: ThresholdDeviceType;
  onOk: () => void;
}

const InverterOrComponentModal: FC<Props> = props => {
  const { open, setOpen, data, type } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState<TableDataItem[]>(
    InverterOrComponentThresholdTypeList.map(type => ({
      id: type,
    }))
  );

  useEffect(() => {
    if (!open) form.resetFields();
  }, [open]);

  useEffect(() => {
    if (!data || !open) {
      form.resetFields();
      return;
    }
    let formData: any = {};
    data?.thresholdValueResponseList?.forEach(item => {
      formData[item.type!] = { ...item };
    });
    Object.assign(formData, {
      [InverterOrComponentThresholdType.GOOD]: {
        lowerLimit: 0,
      },
    });
    form.setFieldsValue(formData);
  }, [data, open]);

  const columns: TableColumnsType<TableDataItem> = [
    {
      title: '离散率等级',
      dataIndex: 'id',
      width: 150,
      render: (v: InverterOrComponentThresholdType) => (
        <div className={styles.thresholdTitle} style={{ color: InverterOrComponentThresholdTypeColor[v] }}>
          <div className={styles.thresholdIcon} style={{ background: InverterOrComponentThresholdTypeColor[v] }}></div>
          {InverterOrComponentThresholdTypeLabel[v]}
        </div>
      ),
    },
    {
      title: '逆变器离散率等级占比上限',
      key: 'threshold',
      render: (_, record) => (
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item className={styles.mb0} label="上限" shouldUpdate>
              {({ getFieldValue }) => (
                <InputNumber
                  addonAfter="%"
                  disabled
                  value={
                    record.id === InverterOrComponentThresholdType.ABNORMAL
                      ? 100
                      : getFieldValue([record.id + 1, 'lowerLimit'])
                  }
                ></InputNumber>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className={styles.mb0}
              label="下限"
              name={[record.id, 'lowerLimit']}
              dependencies={getFormItemDependencies(record, 'lowerLimit')}
              required
              rules={
                record.id !== InverterOrComponentThresholdType.GOOD ? getFormItemRules(record, 'lowerLimit') : undefined
              }
            >
              <InputNumber
                addonAfter="%"
                precision={2}
                min={0}
                max={100}
                disabled={record.id === InverterOrComponentThresholdType.GOOD}
              ></InputNumber>
            </Form.Item>
          </Col>
        </Row>
      ),
    },
  ];

  const onOk = () => {
    form.validateFields().then(values => {
      setLoading(true);
      apiV2PvDispersionRateThresholdSetUpPost({
        type: type,
        objectId: data?.objectId,
        thresholdValueList: Object.keys(values).map(key => {
          return {
            type: Number(key),
            upperLimit:
              Number(key) === InverterOrComponentThresholdType.ABNORMAL ? 100 : values[Number(key) + 1].lowerLimit,
            lowerLimit: values[key].lowerLimit,
          };
        }),
      })
        .then(() => {
          setOpen(false);
          props.onOk();
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <Modal
      title="离散率阈值设置"
      size="large"
      open={open}
      onCancel={() => {
        setOpen(false);
      }}
      onOk={onOk}
      confirmLoading={loading}
    >
      <Space size={5} align="start" style={{ marginBottom: 16 }}>
        <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
        <div style={{ color: `var(--mx-text-desc-color)` }}>
          <div>各级别阈值设置为左开右闭，阈值请从上到下递减设置。</div>
          <div>
            默认设置：
            <span></span>
          </div>
        </div>
      </Space>

      <Form isOrigin form={form}>
        <Table rowKey="id" dataSource={tableData} columns={columns}></Table>
      </Form>
    </Modal>
  );
};

export default InverterOrComponentModal;
