import React, { useEffect, useState } from 'react';
import { FileExcelOutlined, LockOutlined } from '@ant-design/icons';
import { Button, EllipsisSpan, Paging, PopConfirm, Table, Tabs, usePaging } from '@maxtropy/components';
import { ColumnType } from 'antd/es/table';
import { AlarmRule, AlarmRuleStatus, AlarmRuleType, AlarmType, BsaConfig } from '../../api/bsaConfig';
import AlarmRulesSelectModal from './AlarmRulesSelectModal';
import { useParams } from 'react-router-dom';
import { chunk } from 'lodash-es';

const { TabPane } = Tabs;

export interface AlarmRulesConfigProps {
  config?: BsaConfig;
  onChange: (config: BsaConfig) => void;
}

const AlarmRulesConfig: React.FC<AlarmRulesConfigProps> = ({ config, onChange }) => {
  return (
    <Tabs>
      <TabPane
        tab={
          <span>
            <LockOutlined style={{ marginRight: 4 }} />
            触发保护
          </span>
        }
        key="1"
      >
        <TabContent
          data={config?.triggerProtectionRules}
          name={'触发保护'}
          onChange={changed => {
            onChange({ ...config!, triggerProtectionRules: changed });
          }}
        />
      </TabPane>
      <TabPane
        tab={
          <span>
            <FileExcelOutlined style={{ marginRight: 4 }} />
            触发自检中止
          </span>
        }
        key="2"
      >
        <TabContent
          data={config?.triggerStopRules}
          name={'触发自检中止'}
          onChange={changed => {
            onChange({ ...config!, triggerStopRules: changed });
          }}
        />
      </TabPane>
    </Tabs>
  );
};

interface TabContentProps {
  data?: AlarmRule[];
  name: string;
  onChange: (changed: AlarmRule[]) => void;
}

const TabContent: React.FC<TabContentProps> = ({ data, name, onChange }) => {
  const [showSelectModal, setShowSelectModal] = useState(false);
  const { bsaId } = useParams<{ bsaId: string }>();

  const actionColumn: ColumnType<AlarmRule> = {
    title: '操作',
    dataIndex: 'action',
    width: 250,
    fixed: 'right' as const,
    render: (_, record) => (
      <div className="action-buttons">
        <Button
          type="link"
          onClick={() => {
            window.open(`${window.IOTPLATFORMORIGIN}/device/rule/list/detail/${record.id}`, '__blank');
          }}
        >
          查看
        </Button>
        <PopConfirm
          okText="继续"
          title={'确定移除'}
          onConfirm={() => {
            const newRules = [...(data || [])];
            newRules.splice(newRules.indexOf(record), 1);
            onChange(newRules);
          }}
        >
          <Button type="link">移除</Button>
        </PopConfirm>
      </div>
    ),
  };

  const pagingInfo = usePaging(10);
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;
  const paginatedData = chunk(data, pageSize);

  useEffect(() => {
    setTotalCount(data?.length ?? 0);
  }, [data, setTotalCount]);

  return (
    <>
      {showSelectModal && (
        <AlarmRulesSelectModal
          bsaId={+bsaId}
          onOK={changed => {
            onChange(changed);
            setShowSelectModal(false);
          }}
          onCancel={() => setShowSelectModal(false)}
          name={name}
          selectedRules={data || []}
        />
      )}
      <div>
        <Button type={'primary'} onClick={() => setShowSelectModal(true)}>
          添加
        </Button>
        <Table
          style={{ marginTop: 10 }}
          sticky
          rowKey="id"
          scroll={{ x: 1500 }}
          columns={[...getColumns(name), actionColumn]}
          dataSource={paginatedData[pageOffset - 1]}
        />
        <Paging pagingInfo={pagingInfo} />
      </div>
    </>
  );
};

const getColumns = (tag: string) => [
  {
    title: '报警规则编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警规则名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警类型',
    dataIndex: 'type',
    ellipsis: { showTitle: true },
    render: (v: AlarmRuleType) => <EllipsisSpan value={AlarmRuleType.MAP[v]} />,
  },
  {
    title: '报警等级',
    dataIndex: 'alarmType',
    ellipsis: { showTitle: true },
    render: (v: AlarmType) => <EllipsisSpan value={AlarmType.MAP[v]} />,
  },
  {
    title: '报警信息',
    dataIndex: 'alarmName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: AlarmRuleStatus) => <EllipsisSpan value={AlarmRuleStatus.MAP[v]} />,
  },
  {
    title: '标签',
    dataIndex: 'tag',
    ellipsis: { showTitle: true },
    render: () => <EllipsisSpan value={tag} />,
  },
];

export default AlarmRulesConfig;
