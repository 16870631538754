import { forwardRef, useImperativeHandle } from 'react';
import { Form } from '@maxtropy/components';
import DividerContent from '@/components/DividerContent';
import { useForm } from 'antd/es/form/Form';
import dayjs, { Dayjs } from 'dayjs';
import Detail from './Detail';
import Edit from './Edit';
import { UploadFileStatus } from 'antd/es/upload/interface';

// 电价类型
export enum ElectricityPriceType {
  // 上网电价
  FeedIn = 1,
  // 补贴电价
  Subsidy = 2,
}

// 并网模式
export enum GridConnectionStatus {
  ALL_CONNECTED = 0, // 全额上网
  REMAINING_CONNECTED, // 余电上网
  ALL_DROP, // 余电弃光
}

export const GridConnectionStatusLabel = {
  [GridConnectionStatus.ALL_CONNECTED]: '全额上网',
  [GridConnectionStatus.REMAINING_CONNECTED]: '余电上网',
  [GridConnectionStatus.ALL_DROP]: '余电弃光',
};

export interface CostInfoRef {
  getData: () => Promise<any>;
  setData: (data: any) => void;
  uploadStatus?: UploadFileStatus;
}

export const formLayout = {
  labelCol: { flex: '140px' },
  wrapperCol: { flex: '340px' },
};

const findBeginLastDate = (leftDate: number, items: any, index: number) => {
  let interval = Infinity;
  let lastDate = null;
  for (let i = 0; i < items.length; i++) {
    if (index === i) continue;
    if (!items[i]) continue;

    if (items[i].beginDate && items[i].beginDate - leftDate < interval && items[i].beginDate - leftDate > 0) {
      lastDate = items[i].beginDate;
      interval = items[i].beginDate - leftDate;
    } else if (items[i].endDate && items[i].endDate - leftDate < interval && items[i].endDate - leftDate > 0) {
      lastDate = items[i].beginDate;
      interval = items[i].endDate - leftDate;
    }
  }
  return lastDate;
};

const findEndLastDate = (rightDate: number, items: any, index: number) => {
  let interval = Infinity;
  let lastDate = null;
  for (let i = 0; i < items.length; i++) {
    if (i === index) continue;
    if (!items[i]) continue;

    if (items[i].endDate && rightDate - items[i].endDate > 0 && rightDate - items[i].endDate < interval) {
      lastDate = items[i].endDate;
      interval = rightDate - items[i].endDate;
    } else if (items[i].beginDate && rightDate - items[i].beginDate > 0 && rightDate - items[i].beginDate < interval) {
      lastDate = items[i].beginDate;
      interval = rightDate - items[i].beginDate;
    }
  }
  return lastDate;
};

const checkDate = (items: any, current: Dayjs, index: number, type: ElectricityPriceType, isLeft?: boolean) => {
  if (!current) return true;

  if (type === ElectricityPriceType.Subsidy && current < dayjs().endOf('day')) return true;

  let isDisable = false;

  for (let i = 0; i < items.length; i++) {
    let item = items[i];
    // 不是当前选中的闭区间的先禁用
    if (item?.beginDate && item?.endDate && !isDisable && index !== i) {
      isDisable = current >= dayjs(item.beginDate).startOf('day') && current <= dayjs(item.endDate).endOf('day');
    }
    // 单侧当天的禁用
    else if (item?.beginDate && !isDisable) {
      isDisable = current.format('YYYY-MM-DD') === dayjs(item.beginDate).format('YYYY-MM-DD');
    } else if (item?.endDate && !isDisable) {
      isDisable = current.format('YYYY-MM-DD') === dayjs(item.endDate).format('YYYY-MM-DD');
    }
  }
  // 只有开始时间
  // 该开始日期之前不可用，以及距该开始日期最近的下一个开始日期或结束日期之后不可用
  if (items[index]?.beginDate && (!items[index]?.endDate || !isLeft) && !isDisable) {
    let lastDate = findBeginLastDate(items[index].beginDate, items, index);
    isDisable =
      current <= dayjs(items[index].beginDate).endOf('day') || (lastDate && current >= dayjs(lastDate).startOf('day'));
  }
  // 只有结束日期
  // 该结束日期之后不可用，以及距离该结束日期最近的前一个结束日期或开始日期之前不可用
  if (items[index]?.endDate && (!items[index]?.beginDate || isLeft) && !isDisable) {
    let lastDate = findEndLastDate(items[index].endDate, items, index);
    isDisable =
      current >= dayjs(items[index].endDate).startOf('day') || (lastDate && current <= dayjs(lastDate).endOf('day'));
  }

  return isDisable;
};

interface Props {
  isView?: boolean;
}

const CostInfo = forwardRef<CostInfoRef, Props>((props, ref) => {
  const { isView } = props;

  const [form] = useForm();

  useImperativeHandle(ref, () => {
    return {
      async getData() {
        const res = await form.validateFields();
        res.feedInElectricityPrices =
          res?.feedInElectricityPrices?.filter((item: any) => {
            return item.beginDate && item.endDate;
          }) ?? [];
        res.subsidyElectricityPrices =
          res?.subsidyElectricityPrices?.filter((item: any) => {
            return item.beginDate && item.endDate;
          }) ?? [];
        res?.feedInElectricityPrices?.forEach((item: any) => {
          if (item.beginDate) item.beginDate = dayjs(item.beginDate).format('YYYY-MM-DD');
          if (item.endDate) item.endDate = dayjs(item.endDate).format('YYYY-MM-DD');
        });
        res?.subsidyElectricityPrices?.forEach((item: any) => {
          if (item.beginDate) item.beginDate = dayjs(item.beginDate).format('YYYY-MM-DD');
          if (item.endDate) item.endDate = dayjs(item.endDate).format('YYYY-MM-DD');
        });
        return res;
      },
      setData(data: any) {
        form.setFieldsValue({
          ...data,
          feedInElectricityPrices: data.feedInElectricityPrices?.map((item: any) => {
            return {
              ...item,
              beginDate: item.beginDate && dayjs(item.beginDate),
              endDate: item.endDate && dayjs(item.endDate),
            };
          }),
          subsidyElectricityPrices: data.subsidyElectricityPrices?.map((item: any) => {
            return {
              ...item,
              beginDate: item.beginDate && dayjs(item.beginDate),
              endDate: item.endDate && dayjs(item.endDate),
            };
          }),
        });
      },
    };
  });

  return (
    <DividerContent title="成本信息">
      <Form
        form={form}
        initialValues={{
          gridConnectionStatus: GridConnectionStatus.ALL_CONNECTED,
          feedInElectricityPrices: [],
          subsidyElectricityPrices: [],
        }}
      >
        {isView ? <Detail form={form} /> : <Edit form={form} />}
      </Form>
    </DividerContent>
  );
});

export default CostInfo;
