import React, { FC, useEffect, useState } from 'react';
import { Divider, Spin } from 'antd';
import {
  Breadcrumb,
  Button,
  EllipsisSpan,
  Table,
  Tag,
  Tooltip,
  useBreadcrumbRoutes,
  Modal,
  FormTitle,
  useUpdate,
  Empty,
} from '@maxtropy/components';
import BorderWrapper from '@/components/BorderWrapper';
import { PlusOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import SupportDeviceModal from './SupportDeviceModal';
import CreateCabinetModal from './CreateCabinetModal';
import {
  apiV2BsaSecondEditionCabientDeletePost,
  apiV2BsaSecondEditionCabientListPost,
  apiV2BsaSecondEditionCabinetLinkDeletePost,
  apiV2BsaSecondEditionCabinetLinkListPost,
} from '@maxtropy/dmes-apis-v2';
import { CabinetItem, CabinetType, CabinetTypeMap, DeviceItem } from './const';
import { isNil } from 'lodash-es';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';

interface SupportFacilityProps {
  isDetail?: boolean;
}

const columns: ColumnsType<DeviceItem> = [
  {
    title: '设备编号',
    dataIndex: 'code',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备类型',
    dataIndex: 'deviceTypeName',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const SupportFacility: FC<SupportFacilityProps> = ({ isDetail = false }) => {
  const { bsaId } = useParams<{ bsaId: string }>();
  const routesContext = useBreadcrumbRoutes();
  const routes = [{ name: `储能配套设备配置` }];
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const bsaName = urlSearchParams.get('bsaName') || '';
  const [loading] = useState(false);
  const [updateState, updateFn] = useUpdate();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [cabinetModalOpen, setCabinetModalOpen] = useState<boolean>(false);
  const [currentCabinet, setCurrentCabinet] = useState<CabinetItem>();
  const [cabinetList, setCabinetList] = useState<CabinetItem[]>([]);

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as const,
      width: 80,
      render: (record: DeviceItem) => (
        <Button style={{ padding: 0 }} type="link" onClick={() => removeDevice(record)}>
          移除
        </Button>
      ),
    },
  ];

  useEffect(() => {
    apiV2BsaSecondEditionCabientListPost({ id: Number(bsaId) }).then(res => {
      setCabinetList(
        (res?.list ?? []).map(k => ({
          ...k,
          deviceList: k?.deviceList?.map(v => ({
            ...v,
            cabinetId: k.id,
          })),
        }))
      );
    });
  }, [updateState, bsaId]);

  // 删除柜体
  const deleteCabinet = (id?: number) => {
    if (isNil(id)) return;
    Modal.confirm({
      title: '删除柜体',
      okText: '确定',
      content:
        '删除柜体将自动清除能源拓扑图中储能站下此柜体设备信息，同时需要重新配置就地控制器相关配置并重新下发，确定要移除吗？',
      onOk: () => {
        apiV2BsaSecondEditionCabientDeletePost({ id }).then(() => {
          updateFn();
        });
      },
    });
  };

  // 移除设备
  const removeDevice = (item: DeviceItem) => {
    Modal.confirm({
      title: '移除设备',
      okText: '确定',
      content:
        '移除设备将自动清除能源拓扑图中储能站下此设备信息，同时需要重新配置就地控制器相关配置并重新下发，确定要移除吗？',
      onOk: () => {
        apiV2BsaSecondEditionCabinetLinkDeletePost({
          cabinetId: item.cabinetId,
          deviceId: item.deviceId,
        }).then(() => {
          updateFn();
        });
      },
    });
  };

  // 按照类型排序
  const sortByType = (id?: number) => {
    if (isNil(id)) return;
    apiV2BsaSecondEditionCabinetLinkListPost({ cabinetId: id, deviceTypeSort: true }).then(res => {
      setCabinetList(
        cabinetList.map(k =>
          k.id === id ? { ...k, deviceList: (res?.list ?? []).map(m => ({ ...m, cabinetId: id })) } : k
        )
      );
    });
  };

  return (
    <div className="page">
      <div className="page__header">
        <Breadcrumb routes={[...(routesContext?.routes ?? []), ...routes]} />
      </div>
      <BorderWrapper>
        <div className="page__content" style={{ padding: 20, minHeight: 'calc(100vh - 128px)' }}>
          <div className={styles.contentHeader}>
            <FormTitle title={`${bsaName}储能站配套设备`} style={{ marginBottom: 16 }}></FormTitle>
            <Button onClick={() => navigate(`/energy-storage/basic/bsaNew`)}>返回</Button>
          </div>
          <Spin spinning={loading}>
            {!isDetail && (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setCurrentCabinet(undefined);
                  setCabinetModalOpen(true);
                }}
              >
                添加柜体
              </Button>
            )}
            {cabinetList.length ? (
              <div className={styles.cabinetList}>
                {cabinetList.map(item => (
                  <div className={styles.cabinetBox} key={item.id}>
                    <div className={styles.header}>
                      <div className={styles.leftName}>{item.name}</div>
                      <div className={styles.middleTag}>
                        <Tag border="solid" color="#52E7FF">
                          {CabinetTypeMap[item.type as CabinetType]}
                        </Tag>
                        <Tag border="solid" color="#16DD8E">
                          {item.sn}
                        </Tag>
                      </div>
                      {!isDetail && (
                        <div className={styles.rightOpt}>
                          <Button
                            type="link"
                            className={styles.editLink}
                            onClick={() => {
                              setCurrentCabinet(item);
                              setCabinetModalOpen(true);
                            }}
                          >
                            编辑
                          </Button>
                          <Button type="link" className={styles.delLink} onClick={() => deleteCabinet(item.id)}>
                            删除
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className={styles.middle}>
                      <Tooltip placement="top" title={item?.stackList?.map(k => k.name).join('、')}>
                        <div className={styles.desc}>{item?.stackList?.map(k => k.name).join('、')}</div>
                      </Tooltip>
                      <Button type="link" onClick={() => sortByType(item.id)} className={styles.sortBtn}>
                        按类型排序
                      </Button>
                    </div>

                    <Divider className={styles.divider} />
                    <Table
                      className={styles.table}
                      rowKey="id"
                      columns={isDetail ? columns : buildColumns}
                      sticky
                      scroll={{ y: 160 }}
                      size="small"
                      dataSource={item.deviceList}
                      pagination={false}
                    ></Table>
                    {!isDetail && (
                      <Button
                        type="dashed"
                        ghost
                        wrapClassName={styles.btnWrap}
                        icon={<PlusOutlined />}
                        className={styles.addBtn}
                        onClick={() => {
                          setCurrentCabinet(item);
                          setModalOpen(true);
                        }}
                      >
                        添加配套设备
                      </Button>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <Empty style={{ marginTop: '140px' }} description="暂无数据" />
            )}
          </Spin>
        </div>
      </BorderWrapper>
      {modalOpen && (
        <SupportDeviceModal
          setOpen={setModalOpen}
          currentCabinet={currentCabinet}
          updateData={updateFn}
          open={modalOpen}
        ></SupportDeviceModal>
      )}
      {cabinetModalOpen && (
        <CreateCabinetModal
          currentCabinet={currentCabinet}
          setOpen={setCabinetModalOpen}
          updateData={updateFn}
          open={cabinetModalOpen}
        ></CreateCabinetModal>
      )}
    </div>
  );
};

export default SupportFacility;
