import { BsaType, TimeGranularity } from '@/pages/BsaHistoryData/const';
import { Dayjs } from 'dayjs';
import { FC } from 'react';
import OriginalChart from './OriginalChart';
import MinuteChart from '@/pages/BsaHistoryData/components/OperatingPower/MinuteChart';

export const Chart_Grid_Right = 130; // 右边偏移量

export const getEndLabel = (data: (number | null)[][] = [], unit = 'kW') => {
  let isShow = data?.some(item => typeof item[1] === 'number');
  return {
    endLabel: {
      show: isShow,
      color: 'inherit',
      formatter: function (params: any) {
        return `${params?.seriesName ? params?.seriesName + ': ' : ''}${
          typeof params?.value?.[1] === 'number' ? params?.value?.[1].toFixed(4) + unit : ''
        }`;
      },
      width: Chart_Grid_Right,
      overflow: 'breakAll',
    },
    labelLayout: {
      moveOverlap: 'shiftY',
    },
  };
};

interface Props {
  bsaId: number | undefined;
  bsaType: BsaType | undefined;
  date: Dayjs;
  timeGranularity: TimeGranularity;
}

const OperatingPower: FC<Props> = props => {
  const { bsaId, bsaType, date, timeGranularity } = props;

  return timeGranularity === TimeGranularity.ORIGINAL ? (
    <OriginalChart bsaId={bsaId} bsaType={bsaType} date={date} timeGranularity={timeGranularity} />
  ) : (
    <MinuteChart bsaId={bsaId} bsaType={bsaType} date={date} timeGranularity={timeGranularity}></MinuteChart>
  );
};

export default OperatingPower;
