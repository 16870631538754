import { useContext } from 'react';
import { RightValue } from '../..';
import styles from './index.module.scss';
import OperateItemInfo from './OperateItemInfo';

const OperateInfo = () => {
  const rightValue = useContext(RightValue);

  return (
    <div>
      <div className={styles.RightInfoTitle}>运营信息</div>
      <OperateItemInfo
        title={'本月'}
        chargeValue={rightValue?.thisMonthCharge}
        disChargeValue={rightValue?.thisMonthDischarge}
      />
      <OperateItemInfo
        title={'本年'}
        chargeValue={rightValue?.thisYearCharge}
        disChargeValue={rightValue?.thisYearDischarge}
      />
      <OperateItemInfo
        title={'累计'}
        chargeValue={rightValue?.totalCharge}
        disChargeValue={rightValue?.totalDischarge}
      />
    </div>
  );
};

export default OperateInfo;
