import { Graph, Node } from '@antv/x6';
import { useContext, useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import classname from 'classnames/bind';
import { GraphContext } from '..';
import { changeIncomingEdges, changeOutEdges, getPropertyInfo, keepTwoNull } from '../utils';
import { DeviceTypeEnum, deviceTypeNodeIcon, EdgeColor, NodeTreeData } from '../const';
import { AlertFilled, AlertOutlined } from '@ant-design/icons';
import CustomTooltip from '../CustomTooltip';

import { DataValuesIprops, useGetDataValues } from './hooks';
import { Spin } from 'antd';
const cx = classname.bind(styles);
interface Iprops {
  node: Node;
  graph: Graph;
  deviceType: DeviceTypeEnum;
}
const ConnectPoints = ({ node, graph, deviceType }: Iprops) => {
  const { currentNodeInfo, setCurrentNodeInfo, alarmDeviceList } = useContext(GraphContext);

  // 当前节点的数据
  const data = node?.getData() as NodeTreeData;

  const nodeClick = () => {
    if (data.type === DeviceTypeEnum.INVERTER) {
      setCurrentNodeInfo?.(data);
      // 修改逆变器输出边的颜色
      changeOutEdges(graph, node);
    }
  };

  const icon = useMemo(() => {
    return deviceTypeNodeIcon[deviceType];
  }, [deviceType]);

  const [searchParams, setSearchParams] = useState<DataValuesIprops>({ isRequest: false });

  const { dataValues, loading } = useGetDataValues(searchParams);

  const handleToggleCollapse = () => {
    graph.trigger('node:collapse', { node });
  };

  // 汇流箱或者是并网点的子节点是逆变器的话显示展开收起按钮
  const showCollapse = useMemo(() => {
    return (
      (data?.type === DeviceTypeEnum.COMBINER || data?.type === DeviceTypeEnum.CONNECT) &&
      data.children?.some(item => item.type === DeviceTypeEnum.INVERTER)
    );
  }, [data]);

  const isAlarm = useMemo(() => {
    if (!data.deviceId) return false;
    return alarmDeviceList?.includes(data.deviceId);
  }, [alarmDeviceList, data]);

  useEffect(() => {
    if (isAlarm) {
      changeIncomingEdges(graph, node, EdgeColor.ALARM);
    }
  }, [isAlarm]);

  const onOpenChange = (visible: boolean) => {
    setSearchParams({
      isRequest: visible,
      deviceType: data.type,
      deviceId: data.deviceId,
    });
  };
  return (
    <div className={styles.node_box_outer}>
      <CustomTooltip
        show={data.type !== DeviceTypeEnum.COMBINER}
        overlayClassName={styles.graph_inner_tooltip}
        title={
          <Spin spinning={loading}>
            <div className={styles.tooltip_box} style={{ minWidth: 200 }}>
              {data?.objectName && <div className={styles.tooltip_title}>{data.objectName}</div>}

              {data?.deviceName && <div className={styles.tooltip_title}>{data.deviceName}</div>}
              {dataValues?.map(d => {
                return (
                  <div className={styles.tooltip_content}>
                    <span className={styles.tooltip_content_left}>
                      {getPropertyInfo(d.propertyId)?.propertyName ?? '--'}
                    </span>
                    <span className={styles.tooltip_content_right}>{`${keepTwoNull(d.value)}${
                      getPropertyInfo(d.propertyId)?.unit ?? ''
                    }`}</span>
                  </div>
                );
              })}
            </div>
          </Spin>
        }
        onOpenChange={onOpenChange}
      >
        <div className={cx({ node_box: true, selected: currentNodeInfo?.id === data?.id })} onClick={nodeClick}>
          <div className={styles.icon}>
            <img src={icon} alt={'no'} />
          </div>
          <div className={styles.label}>{data?.objectName ?? '--'}</div>
          {isAlarm && (
            <div className={styles.alarm_box}>
              <AlertFilled />
            </div>
          )}
        </div>
      </CustomTooltip>

      {showCollapse && (
        <div className={styles.collapse_box} onClick={handleToggleCollapse}>
          {data?.isCollapsed ? (
            <div className={styles.collapse_close}>{data?.children?.length}</div>
          ) : (
            <div className={styles.collapse_open} />
          )}
        </div>
      )}
    </div>
  );
};

export default ConnectPoints;
