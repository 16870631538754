import { Space } from 'antd';
import { Key } from 'react';
import { goToDeviceHistoryPage } from '../utils';
import styles from './index.module.scss';

interface Iprops {
  title?: string;
  value?: number;
  deviceId?: Key;
}
const PcsPowerTitle = ({ title, value, deviceId }: Iprops) => {
  return (
    <div className={styles.title_box}>
      <Space size={16}>
        <Space>{title ?? '--'}</Space>
      </Space>
      <Space>
        <span className={styles.right_label} onClick={() => goToDeviceHistoryPage(deviceId)}>
          历史数据
        </span>
      </Space>
    </div>
  );
};
export default PcsPowerTitle;
