import React from 'react';
import styles from './index.module.scss';
import StringPower from './components/StringPower';
import StringDetails from './components/StringDetails';

const BottomBox = () => {
  return (
    <>
      <div className={styles.inner_bottom_left}>
        <StringPower />
      </div>
      <div className={styles.inner_bottom_right}>
        <StringDetails />
      </div>
    </>
  );
};

export default BottomBox;
