import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash-es';
import { StatisticsPartition, CircuitStatisticsDataType, AllEPriceTime } from '../../api/circuit';
import { ChartData } from './index';
import { EPriceTime } from '../../api/electricityPrice';

export type NumberLikeValue = string | number;

/**
 * 是否是有效的数字
 */
export const isNumber = (value: NumberLikeValue) => !isNaN(+value) && isFinite(+value);

/**
 * 值是否相等？？
 */
export const overLimit = (value: NumberLikeValue, limit: number) => +value === limit;

/**
 * 过滤掉非有效数字
 */
export const filterNumber = (dataset: any): number[] =>
  Array.isArray(dataset)
    ? dataset
        .filter((value: any) => !isNil(value))
        .filter((value: NumberLikeValue) => isNumber(value))
        .map(item => +item)
    : [];

/**
 * f0(f1(f2(value))) ...
 */
export const compose =
  (...fns: any[]) =>
  (value: any) =>
    [...fns].reverse().reduce((prev, fn) => fn(prev), value);

/**
 * 判断参数是否为有效数字
 */
const validateNumbers = (fn: (...values: number[]) => number, ...args: NumberLikeValue[]) => {
  if (!args.every(arg => isNumber(arg))) return NaN;
  return fn(...args.map(item => +item));
};

export const add = (a: NumberLikeValue, b: NumberLikeValue) => validateNumbers((a, b) => a + b, a, b);

const minus = (a: NumberLikeValue, b: NumberLikeValue) => validateNumbers((a, b) => a - b, a, b);

export const multiply = (a: NumberLikeValue, b: NumberLikeValue) => validateNumbers((a, b) => a * b, a, b);

const divide = (a: NumberLikeValue, b: NumberLikeValue) => validateNumbers((a, b) => (b !== 0 ? a / b : NaN), a, b);

const abs = (a: NumberLikeValue) => validateNumbers(a => Math.abs(a), a);

export const sqrt = (a: NumberLikeValue) => validateNumbers(a => (a >= 0 ? Math.sqrt(a) : NaN), a);

const percent = (a: NumberLikeValue, b: NumberLikeValue) =>
  validateNumbers((a, b) => (b !== 0 ? divide(a, b) * 100 : NaN), a, b);

/**
 * 数组求和
 */
export const sum = (set: any) => {
  const numbers = filterNumber(set);
  return numbers.length ? numbers.reduce((acc, curr) => acc + curr, 0) : NaN;
};

/**
 * 数组求平均数
 */
export const average = (set: any) => {
  const numbers = filterNumber(set);
  return numbers.length ? divide(sum(numbers), numbers.length) : NaN;
};

/**
 * (a - b) / [b] * 100
 */
export const monthOnMonth = (a: NumberLikeValue, b: NumberLikeValue) => percent(minus(a, b), abs(b));

/**
 * 求数组中的最大值
 */
export const max = (set: any) => {
  const numbers = filterNumber(set);
  return Math.max(...numbers);
};

/**
 * 求数组中的最小值
 */
export const min = (set: any) => Math.min(...filterNumber(set));

export const getMax = (chartData: any[]) => {
  const arr = chartData
    .map(item => item.data)
    .flat()
    .map(item => item[1]);
  return max(arr);
};

export const getMin = (chartData: any[]) => {
  const arr = chartData
    .map(item => item.data)
    .flat()
    .map(item => item[1]);
  return min(arr);
};

/**
 * 获取默认时间
 */
export const getDefaultTime = (mode: StatisticsPartition, date: Dayjs, time?: string | null) => {
  switch (mode) {
    case StatisticsPartition.MINUTE_1:
    case StatisticsPartition.MINUTE_15:
    case StatisticsPartition.MINUTE_30:
      if (time) {
        return {
          tsStart: dayjs(time, 'x').startOf('day').valueOf(),
          tsEnd: dayjs(time, 'x').endOf('day').valueOf(),
        };
      }
      return {
        tsStart: dayjs(date, 'x').startOf('day').valueOf(),
        tsEnd: dayjs(date, 'x').endOf('day').valueOf(),
      };

    case StatisticsPartition.DAY:
      return {
        tsStart: dayjs(date, 'x').subtract(30, 'days').startOf('day').valueOf(),
        tsEnd: dayjs(date, 'x').endOf('day').valueOf(),
      };
    case StatisticsPartition.MONTH:
      return {
        tsStart: dayjs(date, 'x').subtract(11, 'month').startOf('month').valueOf(),
        tsEnd: dayjs(date, 'x').endOf('month').valueOf(),
      };
    // 原始1分钟颗粒度是3天，改成一天，先注释
    // case StatisticsPartition.MINUTE_1:
    //   return {
    //     tsStart: dayjs(date, 'x')
    //       .subtract(2, 'days')
    //       .startOf('day')
    //       .valueOf(),
    //     tsEnd: dayjs(date, 'x')
    //       .endOf('day')
    //       .valueOf()
    //   };
    default:
      return {
        tsStart: dayjs(date, 'x').startOf('day').valueOf(),
        tsEnd: dayjs(date, 'x').endOf('day').valueOf(),
      };
  }
};

export const getTs = (mode: StatisticsPartition, start: Dayjs, end: Dayjs) => {
  switch (mode) {
    case StatisticsPartition.MINUTE_15:
    case StatisticsPartition.MINUTE_30:
    case StatisticsPartition.DAY:
      return {
        tsStart: dayjs(start, 'x').startOf('day').valueOf(),
        tsEnd: dayjs(end, 'x').endOf('day').valueOf(),
      };
    case StatisticsPartition.MONTH:
      return {
        tsStart: dayjs(start, 'x').startOf('month').valueOf(),
        tsEnd: dayjs(end, 'x').endOf('month').valueOf(),
      };
    default:
      return {
        tsStart: dayjs(start, 'x').startOf('day').valueOf(),
        tsEnd: dayjs(end, 'x').endOf('day').valueOf(),
      };
  }
};

// 校验图非空
export const notEmpty = (arr: CircuitStatisticsDataType[]) => arr.filter(item => !isNil(item.value)).length > 0;

export const notEmptyForChart = (arr: ChartData[]) => arr.some(i => i.id && notEmpty(i.data));

//获取两个时间之前的所有日期
export const getAllDates = (startTime: number, endTime: number) => {
  const diff = dayjs(endTime).diff(dayjs(startTime), 'd');
  let dates = [startTime];
  for (let i = 1; i <= diff; i++) {
    dates.push(dayjs(startTime).add(i, 'd').valueOf());
  }
  return dates;
};

//获取EPriceTimes（后端直接返回key为月份）
export const getEPriceTimes = (dates: number[], allEprices: AllEPriceTime) => {
  let times: EPriceTime[] = [];
  if (Object.keys(allEprices).length !== 0) {
    dates.forEach((d, i) => {
      const value = allEprices[dayjs(d).month() + 1];
      value?.forEach(f => {
        times.push({
          type: f.type,
          intervalStart: f.intervalStart + i * 48,
          intervalEnd: f.intervalEnd + i * 48,
        });
      });
    });
  }
  return times;
};

// 获取EPriceTimes（后端直接返回key为日期的时间戳）
export const getEPriceTimesByDay = (dates: number[], allEprices: AllEPriceTime) => {
  let times: EPriceTime[] = [];
  if (Object.keys(allEprices).length !== 0) {
    dates.forEach((d, i) => {
      const value = allEprices[dayjs(d).valueOf()];
      value?.forEach(f => {
        times.push({
          type: f.type,
          intervalStart: f.intervalStart + i * 48,
          intervalEnd: f.intervalEnd + i * 48,
        });
      });
    });
  }
  return times;
};
