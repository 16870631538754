import { getBsa, getBsaBindingPcsList, getBsaListWithOu, getCells, getPacks, getRacks, getStacks } from '@/api/bsa';
import { getDeviceDataRecent, LinkStatus as LinkStatusType } from '@/api/device';
import { BSAStatus } from '@/api/type';
import BorderWrapper from '@/components/BorderWrapper';
import { Breadcrumb, Empty, useBreadcrumbRoutes, useUpdate, Form, Select } from '@maxtropy/components';
import { Col, Row, Spin } from 'antd';
import { useEffect, useMemo, useRef } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import BlockItem from './BlockItem';
import styles from './index.module.scss';

const propertyIds = [10393, 10395, 10397];
const linkStatus = 10051;
const TemperRealTimeData: React.FC = () => {
  const routesContext = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const [updateState, updateFn] = useUpdate();
  const timer = useRef<number>();

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const url_bsaId = urlSearchParams.get('bsaId') || undefined;

  // 选择的当前值
  const { currentBsaId, currentTemperLevel, currentPcsId, currentStackId, currentRackId, currentPackId } =
    useMemo(() => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      return form.getFieldsValue();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, updateState]);

  // 阵列下拉列表
  const { data: bsaList } = useQuery(['bsaList'], () => getBsaListWithOu());
  // 当前阵列详情
  const { data: bsaDetail } = useQuery(['bsaDetail', currentBsaId], () => getBsa(currentBsaId), {
    enabled: !!currentBsaId,
  });
  // pcs下拉列表
  const { data: pcsList } = useQuery(['pcsList', currentBsaId], () => getBsaBindingPcsList(currentBsaId!), {
    enabled: !!currentBsaId,
  });
  // 堆
  const { data: stackList } = useQuery(
    ['stacks', currentPcsId],
    () => {
      return getStacks(currentBsaId!, currentPcsId);
    },
    { enabled: !!currentBsaId && !!currentPcsId && currentTemperLevel >= 0 }
  );
  // 簇
  const { data: rackList } = useQuery(['racks', currentStackId], () => getRacks(currentBsaId!, currentStackId), {
    enabled: !!currentBsaId && !!currentStackId && currentTemperLevel >= 1,
  });
  // 组
  const { data: packList } = useQuery(['packs', currentRackId], () => getPacks(currentBsaId!, currentRackId), {
    enabled: !!currentBsaId && !!currentRackId && currentTemperLevel >= 2,
  });
  // pcs默认选择第一个
  useEffect(() => {
    form.setFieldsValue({
      currentPcsId: pcsList?.[0]?.id,
    });
    updateFn();
  }, [pcsList, form, currentBsaId, updateFn]);
  // 电池堆默认选择第一个
  useEffect(() => {
    form.setFieldsValue({
      currentStackId: stackList?.[0]?.stackId,
    });
    updateFn();
  }, [stackList, form, updateFn]);
  // 电池簇默认选择第一个
  useEffect(() => {
    form.setFieldsValue({
      currentRackId: rackList?.[0]?.rackId,
    });
    updateFn();
  }, [rackList, form, updateFn]);
  // 电池组默认选择第一个
  useEffect(() => {
    form.setFieldsValue({
      currentPackId: packList?.[0]?.packId,
    });
    updateFn();
  }, [packList, form, updateFn]);
  // 过滤启用状态的储能站
  const enableBsaList = useMemo(() => {
    return bsaList?.filter(item => item.status === BSAStatus.ENABLE);
  }, [bsaList]);
  // 当前储能阵列是否包含堆簇组
  const { hasPack, hasRack } = useMemo(() => {
    let temps = enableBsaList?.find(item => item.id === currentBsaId);
    if (!temps) {
      return {
        hasCell: false,
        hasPack: false,
        hasRack: false,
      };
    }
    return temps;
  }, [currentBsaId, enableBsaList]);

  // 储能站下拉选项改变, 重置pcs以外其他表单
  useEffect(() => {
    form.setFieldsValue({
      currentTemperLevel: hasPack ? 2 : hasRack ? 1 : 0,
      currentStackId: undefined,
      currentPackId: undefined,
      currentRackId: undefined,
    });
    updateFn();
  }, [currentBsaId, form, updateFn, hasPack, hasRack]);

  // 当前pcs/堆/簇/组id  及获取下一级设备方法
  const [currentId, getDeviceIds, blockName] = useMemo(() => {
    if (currentTemperLevel === 0) {
      if (!currentStackId) return [currentPcsId, getStacks, '电池堆'];
      return [currentStackId, getRacks, '电池簇'];
    }
    if (currentTemperLevel === 1) {
      return [currentRackId, getPacks, '电池组'];
    }
    if (currentTemperLevel === 2) {
      return [currentPackId, getCells, '电芯'];
    }
    return [currentStackId, getStacks, '电池堆'];
  }, [currentTemperLevel, currentStackId, currentRackId, currentPackId, currentPcsId]);

  const { data: deviceData } = useQuery(
    ['deviceData', currentBsaId, currentId],
    () => {
      return getDeviceIds(currentBsaId!, currentId);
    },
    { enabled: !!currentBsaId && !!currentId }
  );
  // 下一级所都的设备ids
  const deviceIds = useMemo(() => {
    return deviceData?.map(item => item.deviceId).join(',');
  }, [deviceData]);

  const currentProperId = useMemo(() => {
    return [propertyIds[currentTemperLevel], linkStatus];
  }, [currentTemperLevel]);

  // 默认选中第一个bsa
  useEffect(() => {
    if (url_bsaId) {
      let hasData = enableBsaList?.find(item => item.id.toString() === url_bsaId);
      if (hasData) {
        form.setFieldsValue({
          currentBsaId: hasData.id,
        });
        updateFn();
        return;
      }
    }
    if (enableBsaList && enableBsaList.length > 0) {
      form.setFieldsValue({
        currentBsaId: enableBsaList[0].id,
      });
      updateFn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableBsaList, url_bsaId, form]);

  // 返回连接状态和温度数据
  const {
    data: totalData,
    isLoading,
    refetch,
  } = useQuery(
    ['totalData', deviceIds],
    () =>
      getDeviceDataRecent({
        deviceIds: String(deviceIds!),
        propertyIds: String(currentProperId),
      }).then(res => {
        // 开启定时器
        clearTimeout(timer.current);
        timer.current = window.setTimeout(refetch, 60000);
        return res;
      }),
    { enabled: !!deviceIds && !!currentProperId }
  );
  // 温度数据
  const temperData = useMemo(() => {
    let temper = totalData?.filter(item => item.propertyId !== linkStatus);
    let status = totalData?.filter(item => item.propertyId === linkStatus);
    return temper?.map(item => {
      let temp = status?.find(total => total.deviceId === item.deviceId);
      // 时间超过15分钟的，都算作离线
      let itemStatus = temp?.value;
      if (dayjs().diff(item.ts, 'minute') > 15) {
        itemStatus = LinkStatusType.OFFLINE;
      }
      return {
        ...item,
        status: itemStatus,
      };
    });
  }, [totalData]);

  // 清除定时器
  useEffect(() => {
    return () => clearTimeout(timer.current);
  }, []);

  return (
    <>
      <div className="page">
        <Breadcrumb routes={routesContext?.routes} />
        <BorderWrapper>
          <div className="page__filter">
            <Form
              isOrigin={true}
              initialValues={{
                currentTemperLevel: 0,
              }}
              form={form}
            >
              <Row gutter={20}>
                <Col span={8}>
                  <Form.Item label="储能站" name="currentBsaId">
                    <Select placeholder="请选择" onChange={updateFn}>
                      {enableBsaList?.map(i => (
                        <Select.Option key={i.id} value={i.id}>
                          {i.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="温度级别" name="currentTemperLevel">
                    <Select placeholder="请选择" onChange={updateFn}>
                      <Select.Option value={0}>电池堆级</Select.Option>
                      {hasRack && <Select.Option value={1}>电池簇级</Select.Option>}
                      {hasPack && <Select.Option value={2}>电池组级</Select.Option>}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="pcs" name="currentPcsId">
                    <Select placeholder="请选择" onChange={updateFn}>
                      {pcsList?.map(i => (
                        <Select.Option key={i.id} value={i.id}>
                          {i.deviceName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {currentTemperLevel >= 0 && (
                  <Col span={8}>
                    <Form.Item label="电池堆" name="currentStackId">
                      <Select placeholder="请选择" onChange={updateFn}>
                        {stackList?.map(i => (
                          <Select.Option key={i.stackId} value={i.stackId}>
                            {i.deviceName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {currentTemperLevel >= 1 && hasRack && (
                  <Col span={8}>
                    <Form.Item label="电池簇" name="currentRackId">
                      <Select placeholder="请选择" onChange={updateFn}>
                        {rackList?.map(i => (
                          <Select.Option key={i.rackId} value={i.rackId}>
                            {i.deviceName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {currentTemperLevel >= 2 && hasPack && (
                  <Col span={8}>
                    <Form.Item label="电池组" name="currentPackId">
                      <Select placeholder="请选择" onChange={updateFn}>
                        {packList?.map(i => (
                          <Select.Option key={i.packId} value={i.packId}>
                            {i.deviceName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Form>
          </div>
          <div className="page__content" style={{ padding: 0, minHeight: 'calc(100vh - 235px)' }}>
            <Spin spinning={isLoading}>
              <div>
                {(!temperData || temperData.length === 0) && !isLoading ? (
                  <Empty style={{ paddingTop: 120 }} />
                ) : (
                  <div className={styles.container}>
                    {temperData?.map((item, index) => {
                      return (
                        <BlockItem
                          label={blockName + (index + 1)}
                          bsaDetail={bsaDetail}
                          key={item.deviceId + item.propertyId}
                          value={item.value}
                          status={item.status}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            </Spin>
          </div>
        </BorderWrapper>
      </div>
    </>
  );
};

export default TemperRealTimeData;
