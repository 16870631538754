import { getAddressTips, getDistrict, LocationResponse } from '@/api/pv';
import { Cascader, FormInstance } from 'antd';
import { useEffect, useState, useMemo } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Form, Select, DatePicker, Tooltip } from '@maxtropy/components';
const { Option } = Select;
interface Iprops {
  getLngLat?: (laglat: string, adcode: string) => void;
  form?: FormInstance;
  adcode?: string;
  disabled?: boolean;
  createStationTime?: string;
}
interface Tips {
  address?: string;
  district?: string;
  location?: string;
  id?: string;
  name?: string;
  typecode?: string;
  adcode?: string;
}
/* 递归处理末尾项district为0的空项 */
const getTreeData = (data: any) => {
  // 循环遍历返回的数据
  for (var i = 0; i < data.length; i++) {
    if (data[i].districts.length < 1) {
      // districts若为空数组，则将districts设为undefined
      data[i].districts = undefined;
    } else {
      // districts若不为空数组，则继续 递归调用 本方法
      getTreeData(data[i].districts);
    }
  }
  return data;
};
const fieldNames = {
  value: 'name',
  label: 'name',
  children: 'districts',
};
let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;
let currentAdcode: string; // 当前区域code
const AddressCascader: React.FC<Iprops> = ({ getLngLat, form, adcode, disabled = false }) => {
  const [options, setOptions] = useState<LocationResponse[]>([]);
  const [data, setData] = useState<any[]>([]);

  const isPvStorage = useMemo(() => window.location.pathname.includes('/pv-storage/basic/manage/baseInfo/edit'), []);

  // 所在地区
  const areaChange = (value: any, selectedOptions: any) => {
    if (Array.isArray(selectedOptions)) {
      let cityRow = selectedOptions.find(v => ['district', 'street'].includes(v.level));
      if (cityRow) {
        currentAdcode = cityRow.adcode;
      }
    }
    form?.setFieldsValue({
      address: '',
    });
    setData([]);
  };
  // 选择详细地址
  const selectAddress = (value: string) => {
    if (value) {
      let arrTemp = value.split('$');
      form?.setFieldsValue({
        address: arrTemp[0],
      });
      let locationStr = arrTemp[1];
      getLngLat && getLngLat(locationStr, currentAdcode);
    }
  };
  // 获取省市区
  useEffect(() => {
    getDistrict({ keywords: '', subdistrict: '3' }).then(res => {
      let data = getTreeData(res.districts);
      setOptions(data);
    });
  }, []);
  useEffect(() => {
    if (adcode) {
      currentAdcode = adcode;
    }
  }, [adcode]);
  // 防抖处理
  const fetchData = (value: string, callback: (data: Tips[]) => void) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;
    const fake = () => {
      getAddressTips({ keywords: value, city: currentAdcode, cityLimit: true }).then(res => {
        if (currentValue === value) {
          // 过滤掉无效数据
          if (res.tips) {
            let sugs = res.tips.filter(item => typeof item.name === 'string' && typeof item.address === 'string');
            callback(sugs);
          }
        }
      });
    };
    timeout = setTimeout(fake, 300);
  };
  // 根据关键字/adcode搜索
  const handleSearch = (newValue: string) => {
    if (newValue) {
      fetchData(newValue, setData);
    } else {
      setData([]);
    }
  };
  const selectOptions = data.map(d => (
    <Option value={d.name + '$' + d.location} key={d.name}>
      {d.name + '-' + d.address}
    </Option>
  ));

  return (
    <>
      <Form.Item label="所在地区" name="location" rules={[{ required: true, message: '请选择所在地区' }]}>
        <Cascader
          fieldNames={fieldNames}
          options={options}
          onChange={areaChange}
          placeholder="请选择"
          disabled={disabled}
        />
      </Form.Item>
      <Form.Item noStyle dependencies={['location']}>
        {({ getFieldValue }) => (
          <Form.Item label="详细地址" name="address" rules={[{ required: true, message: '请输入并选择详细地址' }]}>
            <Select
              disabled={!getFieldValue('location') || disabled}
              showArrow={false}
              onSelect={selectAddress}
              defaultActiveFirstOption={false}
              filterOption={false}
              notFoundContent={null}
              onSearch={handleSearch}
              showSearch
            >
              {selectOptions}
            </Select>
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item
        label={
          <span>
            投运日期
            {isPvStorage && (
              <span style={{ marginLeft: 4 }}>
                <Tooltip title={'请选择站点投运时间，系统将根据投运日期评估运行时长。'}>
                  <ExclamationCircleOutlined style={{ color: '#B59452' }} />
                </Tooltip>
              </span>
            )}
          </span>
        }
        name="createStationTime"
        rules={[{ required: true, message: '请选择投运日期' }]}
      >
        <DatePicker style={{ width: '100%' }} disabled={disabled} />
      </Form.Item>
    </>
  );
};

export default AddressCascader;
