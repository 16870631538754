import BsaDataCollectionConfigNew from './index';
import { PermissionsType } from '@/common/permissionsConst';

const routes = [
  {
    path: '/energy-storage/basic/bsaNew/editBsaDataCollectionConfigNew/:id',
    permission: PermissionsType.P_BSA_DEVICE_Edge_INFO_NEW,
    element: <BsaDataCollectionConfigNew />,
  },
  {
    path: '/energy-storage/basic/bsaNew/viewBsaDataCollectionConfigNew/:id',
    permission: PermissionsType.P_BSA_DEVICE_Edge_INFO_VIEW_NEW,
    element: <BsaDataCollectionConfigNew />,
  },
];
export default routes;
