import AlarmRecordList from './List';
import AlarmRecordDetail from './Detail';
import { PermissionsType } from '@/common/permissionsConst';

const routes = [
  {
    path: '/dmes/alarm/record',
    element: <AlarmRecordList />,
    permission: PermissionsType.P_ALARM_RECORD,
  },
  {
    path: '/dmes/alarm/record/detail/:id',
    element: <AlarmRecordDetail />,
    permission: PermissionsType.P_ALARM_RECORD_DETAIL,
  },
];

export default routes;
