import { v2GetCircuitStatistics, StatisticsPartition } from '@/api/circuit';
import { DataType } from '@/api/const';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { FC, useEffect, useState } from 'react';
import powerFactor from './imgs/powerFactor.png';
import tariffcoefficient from './imgs/tariffcoefficient.png';

interface IPowerFactor {
  currentCircuit?: number;
  openTimer: (isOver: boolean) => void;
  timerState?: number;
}

// 功率因数的计算
export const billFactor = (cosq: number) => {
  if (cosq >= 0.95 && cosq <= 1.0) {
    return -0.75;
  } else if (cosq >= 0.9 && cosq < 0.95) {
    return (0.9 - cosq) * 15;
  } else if (cosq >= 0.7 && cosq < 0.9) {
    return (0.9 - cosq) * 50;
  } else if (cosq < 0.7 && cosq >= 0.65) {
    return 10 + (0.7 - cosq) * 100;
  } else {
    return 15 + (0.65 - cosq) * 200;
  }
};
const PowerFactor: FC<IPowerFactor> = props => {
  const { currentCircuit, openTimer, timerState } = props;
  const [currentMonthPowerFactor, setCurrentMonthPowerFactor] = useState<string>();
  const [tariffcoefficientData, setTariffcoefficientData] = useState<string | number>();

  // 功率因数
  useEffect(() => {
    if (currentCircuit) {
      openTimer?.(false);
      v2GetCircuitStatistics({
        circuitId: currentCircuit,
        dataType: DataType.COSQ,
        timeResolution: StatisticsPartition.MONTH,
        from: dayjs().startOf('month').valueOf(),
        to: dayjs().endOf('month').valueOf(),
      })
        .then(res => {
          if (!isNil(res[0]?.data[0]?.value)) {
            setCurrentMonthPowerFactor(res[0]?.data[0]?.value.toFixed(2));
            let tariffcoefficientdata = billFactor(res[0]?.data[0]?.value);
            setTariffcoefficientData(tariffcoefficientdata.toFixed(2) + '%');
          } else {
            setCurrentMonthPowerFactor('--');
            setTariffcoefficientData('--');
          }
        })
        .finally(() => openTimer?.(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCircuit, timerState]);

  return (
    <>
      <Row>
        <Col
          span={12}
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
        >
          <img src={powerFactor} alt="" />
        </Col>
        <Col
          span={12}
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
        >
          <span style={{ fontSize: '17px' }}>功率因数</span>
          <p style={{ color: '#14D0E4', fontSize: '22px', fontWeight: 700, margin: 0 }}>
            {currentMonthPowerFactor ?? '--'}
          </p>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
        >
          <img src={tariffcoefficient} alt="" />
        </Col>
        <Col
          span={12}
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
        >
          <span style={{ fontSize: '17px' }}>电费系数</span>
          <p style={{ color: '#14D0E4', fontSize: '22px', fontWeight: 700, margin: 0 }}>
            {tariffcoefficientData ?? '--'}
          </p>
        </Col>
      </Row>
    </>
  );
};

export default PowerFactor;
