import React, { forwardRef, useImperativeHandle, useEffect, useState, useMemo, useContext } from 'react';
import { Button, Modal, combineURL } from '@maxtropy/components';

import { Col, Descriptions, Row } from 'antd';
import classNames from 'classnames';
import styles from './index.module.scss';
import { DeviceProps } from '@/api/bsa';
import SelectModal from './SelectModal';
import { ChildDataRef, DeviceType, DeviceContext } from './index';
import { BSAStatus } from '@/api/type';
interface Iprops {
  list?: DeviceProps[];
  show?: boolean;
  deviceType?: DeviceType | undefined;
  colLabelFirst?: string;
  colLabelSecond?: string;
  disabled?: boolean;
}

const EnergyListItem = forwardRef<ChildDataRef, Iprops>((props, childRef) => {
  const { status } = useContext(DeviceContext);
  const [visible, setVisible] = useState<boolean>(false);
  const [opType, setOpType] = useState<string>('add');
  const [currentIndex, setCurrentIndex] = useState<number>();
  const [listData, setListData] = useState<DeviceProps[]>([]);
  // 打开弹框
  const openModal = (opType: string, i?: number) => {
    setVisible(true);
    setOpType(opType);
    if (opType === 'edit') {
      setCurrentIndex(i);
    }
  };
  // 获取弹框选择的数据
  const getRowData = (rows: DeviceProps[]) => {
    if (opType === 'add') {
      setListData(listData.concat(rows));
    } else {
      listData.splice(currentIndex!, 1, rows[0]);
      setListData([...listData]);
    }
  };
  // 移除
  const removeItem = (index: number) => {
    if (status === BSAStatus.ENABLE) {
      Modal.confirm({
        title: `更新设备信息`,
        content: `更新设备信息,会造成需要重新配置FCS相关配置并重新下发,确定要更新吗?`,
        onOk: () => {
          listData.splice(index, 1);
          setListData([...listData]);
        },
      });
    } else {
      listData.splice(index, 1);
      setListData([...listData]);
    }
  };
  useEffect(() => {
    setListData(props.list ?? []);
  }, [props.list]);
  // 给父组件暴露值
  useImperativeHandle(childRef, () => ({
    deviceData: listData,
  }));
  const buttonTitle = useMemo(() => {
    if (props.deviceType === DeviceType.AIR) {
      return opType === 'add' ? '增加空调' : '更改空调';
    } else if (props.deviceType === DeviceType.FIRE) {
      return opType === 'add' ? '增加消防设备' : '更改消防设备';
    } else if (props.deviceType === DeviceType.TEMPERATURE) {
      return opType === 'add' ? '增加温湿度计' : '更改温湿度计';
    } else {
      return opType === 'add' ? '增加烟感设备' : '更改烟感设备';
    }
  }, [props.deviceType, opType]);
  // 组件隐藏,清空已选择的值
  useEffect(() => {
    if (!props.show) {
      setListData([]);
    }
  }, [props.show]);
  return (
    <>
      {props.show ? (
        <div>
          <Row style={{ marginBottom: '15px' }}>
            <Button type="primary" disabled={props.disabled} onClick={() => openModal('add')}>
              {buttonTitle}
            </Button>
          </Row>
          <Row>
            <div className={classNames(styles.desc, styles.background)}>
              <Descriptions colon={false} layout="vertical" column={3}>
                <Descriptions.Item label={props.colLabelFirst ?? '-'}>
                  <Row>
                    {listData.map(i => (
                      <Col span={24} key={i.deviceId}>
                        {i.deviceName}
                      </Col>
                    ))}
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item label={props.colLabelSecond ?? '-'}>
                  <Row>
                    {listData.map(i => (
                      <Col span={24} key={i.deviceId}>
                        {i.deviceCode}
                      </Col>
                    ))}
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <div
                      className={styles.setIot}
                      onClick={() =>
                        window.open(combineURL(window.IOTPLATFORMORIGIN, `/device/manage/device`), '_blank')
                      }
                    >
                      前往查看IOT配置
                    </div>
                  }
                >
                  <Row>
                    {!props.disabled &&
                      listData.map((v, i) => (
                        <Col span={24} key={v.deviceId}>
                          <span onClick={() => openModal!('edit', i)} className={styles.optionsBtn}>
                            更改
                          </span>
                          <span onClick={() => removeItem(i)} className={styles.optionsBtn}>
                            移除
                          </span>
                        </Col>
                      ))}
                  </Row>
                </Descriptions.Item>
              </Descriptions>
            </div>
          </Row>
        </div>
      ) : (
        ''
      )}
      {/* 选择设备弹框 */}
      <SelectModal
        opType={opType}
        onConfirm={getRowData}
        exsitData={listData}
        deviceType={props.deviceType}
        openModal={status => setVisible(status)}
        visible={visible}
      >
        {buttonTitle}
      </SelectModal>
    </>
  );
});
export default EnergyListItem;
