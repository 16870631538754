import { Checkbox, MXTabsProps, Tabs, useBreadcrumbRoutes, Wrapper } from '@maxtropy/components';
import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import BsaTree from './components/BsaTree';
import DragResize from '@/components/DragResize';
import styles from './index.module.scss';
import OperatingPower from './components/OperatingPower';
import Soc from './components/Soc';
import { BsaType, TabType, TimeGranularity } from './const';
import TopFilter from './components/TopFilter';
import dayjs, { Dayjs } from 'dayjs';
import { useLocation } from 'react-router-dom';

const { Sider, Content } = Layout;

const BsaHistoryData = () => {
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const url_bsaId = urlSearchParams.get('bsaId') || undefined;
  const url_bsaType = urlSearchParams.get('bsaType') || undefined;

  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [siderWidth, setSiderWidth] = useState(260);
  const [dragStatus, setDragStatus] = useState(false);
  const [openSider, setOpenSider] = useState(true);

  const [tabType, setTabType] = useState<TabType>(TabType.OPERATING_POWER);
  const [bsaId, setBsaId] = useState<number | undefined>(url_bsaId ? Number(url_bsaId) : undefined);
  const [bsaType, setBsaType] = useState<BsaType | undefined>(
    url_bsaType ? (Number(url_bsaType) as BsaType) : undefined
  );
  const [date, setDate] = useState<Dayjs>(dayjs());
  const [timeGranularity, setTimeGranularity] = useState<TimeGranularity>(TimeGranularity.ORIGINAL);
  const [showPcs, setShowPcs] = useState(false);

  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  const tabItems: MXTabsProps['items'] = [
    {
      key: TabType.OPERATING_POWER,
      label: '运行功率',
    },
    {
      key: TabType.SOC,
      label: 'SOC',
    },
  ];

  return (
    <Wrapper routes={breadcrumbRoutes?.routes ?? []} className={styles.wrapper}>
      <Layout className={styles.layout}>
        <Sider
          theme="light"
          width={openSider ? siderWidth : 0}
          style={{ transition: dragStatus ? 'none' : 'all 0.2s', background: '#232324' }}
        >
          <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
          <BsaTree
            defaultBsaId={bsaId}
            defaultBsaType={bsaType}
            onSelect={(bsaId, bsaType) => {
              setBsaId(bsaId);
              setBsaType(bsaType);
            }}
          />
        </Sider>

        <Content className={styles.content}>
          <div
            className={styles.toggleBtn}
            style={{ left: openSider ? -30 : 0 }}
            onClick={() => setOpenSider(!openSider)}
          >
            {openSider ? <LeftOutlined /> : <RightOutlined />}
          </div>

          <Tabs
            selectedLinePlacement="top"
            bordered
            items={tabItems}
            activeKey={tabType}
            onChange={key => setTabType(key as TabType)}
          ></Tabs>

          <TopFilter
            onChange={(date, timeGranularity) => {
              setDate(date);
              setTimeGranularity(timeGranularity);
            }}
            extend={
              tabType === TabType.SOC && (
                <Checkbox
                  style={{ marginLeft: 14 }}
                  defaultChecked={showPcs}
                  value={showPcs}
                  onChange={e => setShowPcs(e.target.checked)}
                >
                  对比PCS功率
                </Checkbox>
              )
            }
          />

          <div className={styles.contentInner}>
            {tabType === TabType.OPERATING_POWER ? (
              <OperatingPower bsaId={bsaId} bsaType={bsaType} date={date} timeGranularity={timeGranularity} />
            ) : (
              <Soc bsaId={bsaId} bsaType={bsaType} date={date} timeGranularity={timeGranularity} showPcs={showPcs} />
            )}
          </div>
        </Content>
      </Layout>
    </Wrapper>
  );
};

export default BsaHistoryData;
