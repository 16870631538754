import { Button as AntButton, ButtonProps } from 'antd';
import classnames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

const Button: React.FC<ButtonProps> = props => {
  return (
    <div className={cx('btn')}>
      <AntButton {...props} />
      {props.type === 'primary' && (
        <>
          <div className={cx('line', 'line--left')} />
          <div className={cx('line', 'line--right')} />
          <div className={cx('dot', 'dot--top-left')} />
          <div className={cx('dot', 'dot--bottom-left')} />
          <div className={cx('dot', 'dot--top-right')} />
          <div className={cx('dot', 'dot--bottom-right')} />
        </>
      )}
    </div>
  );
};

export default Button;
