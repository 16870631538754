import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { EPrice, EPriceTime } from './electricityPrice';
import { PageRequest, PageResponse } from './page';
import { CircuitPhysicalQuantityType, CircuitTreeData, CircuitTreeData2, DataNameType, DataType } from './const';

export enum CircuitType {
  GRID_BILLING_CIRCUIT = 1,
  OUTGOING_CIRCUIT = 2,
  INCOMING_CIRCUIT = 3,
  BUS_COUPLER_CIRCUIT = 4,
  TRANSFORMER_CIRCUIT = 5,
}

export const CircuitTypeFormat = {
  [CircuitType.GRID_BILLING_CIRCUIT]: '电网计费回路',
  [CircuitType.OUTGOING_CIRCUIT]: '出线回路',
  [CircuitType.INCOMING_CIRCUIT]: '进线回路',
  [CircuitType.BUS_COUPLER_CIRCUIT]: '母联回路',
  [CircuitType.TRANSFORMER_CIRCUIT]: '变压器回路',
};

export enum VoltageLevel {
  ZERO_POINT_FOUR = 1,
  TEN = 2,
  TWENTY = 3,
  THIRTY_FIVE = 4,
  SIXTY_SIX = 5,
  ONE_HUNDRED_TEN = 6,
  TWO_HUNDRED_AND_TWO = 7,
}

export const VoltageLevelFormat = {
  [VoltageLevel.ZERO_POINT_FOUR]: '0.4',
  [VoltageLevel.TEN]: '10',
  [VoltageLevel.TWENTY]: '20',
  [VoltageLevel.THIRTY_FIVE]: '35',
  [VoltageLevel.SIXTY_SIX]: '66',
  [VoltageLevel.ONE_HUNDRED_TEN]: '110',
  [VoltageLevel.TWO_HUNDRED_AND_TWO]: '220',
};

export enum SwitchStatus {
  CLOSE,
  OPEN,
}

export const SwitchStatusFormat = {
  [SwitchStatus.CLOSE]: '断开',
  [SwitchStatus.OPEN]: '闭合',
};

export enum BusTieStatus {
  DISCONNECT,
  LEFT_CONNECT,
  RIGHT_CONNECT,
}

export const BusTieStatusFormat = {
  [BusTieStatus.DISCONNECT]: '断开',
  [BusTieStatus.LEFT_CONNECT]: '从左上游回路得电',
  [BusTieStatus.RIGHT_CONNECT]: '从右上游回路得电',
};

interface CircuitPageReq extends PageRequest {
  codeOrName?: string;
  type?: CircuitType;
  distributionCabinetId?: number;
  ouId?: number;
}

interface DeviceProps {
  id: number;
  name: string;
  typeName: string;
  tags: string[];
  customerName: string;
  deviceDto: {
    id: number;
    name: string;
  };
}

export interface CircuitPageProps {
  circuitLinkType?: CircuitRelationType;
  id: number;
  busTieStatus?: BusTieStatus;
  capacity?: number;
  code: string;
  configuredEPrice?: boolean;
  devices: DeviceProps[];
  // distributionCabinet: DistributionCabinetProps;
  ePriceId: number;
  householdNo: string;
  name: string;
  // ou: Ou;
  type: CircuitType;
  updateByStaffId: number;
  updateByUsername: string;
  updateTime: string;
  // upstreamCircuit?: CircuitProps;
  // upstreamCircuitOu?: Ou;
  // upstreamCircuitRight?: CircuitProps;
  // upstreamCircuitRightOu?: Ou;
  voltageLevel?: VoltageLevel;
  voltageOutLevel?: VoltageLevel;
  switchStatus?: SwitchStatus;
  remark?: string;
  ePrice?: EPrice;
  downstreamCircuits?: CircuitProps[];
  isAdmin: boolean;
  needControlTarget?: number;
  loadControlTarget?: number;
  hasLineLossConfig?: boolean;
  voltageHarmonicUpper?: number; // 电压谐波上限
  currentHarmonicUpper?: number; // 电流谐波上限
  lineVoltageUpper?: number; // 线电压上限
  lineVoltageLower?: number; // 线电压下限
  phaseVoltageUpper?: number; // 相电压上限
  phaseVoltageLower?: number; // 相电压下限
  transformerCapacity?: number; // 变压器容量
  distributionCabinetId?: number;
  distributionCabinetName?: string;
  upstreamCircuitId?: number;
  upstreamCircuitLeftId?: number;
  upstreamCircuitLeftName?: string;
  upstreamCircuitLeftOuId?: number;
  upstreamCircuitLeftOuName?: string;
  upstreamCircuitName?: string;
  upstreamCircuitOuId?: number;
  upstreamCircuitOuName?: string;
  upstreamCircuitRightId?: number;
  upstreamCircuitRightName?: string;
  upstreamCircuitRightOuId?: number;
  upstreamCircuitRightOuName?: string;
  ouId?: number;
  ouName?: string;
  uetId?: number;
  uetName?: string;
  electricityAccountNumber?: string; // 户号
}

export const getCircuitPage = (query: CircuitPageReq) =>
  fetch<PageResponse<CircuitPageProps>>(`/api/circuit/page?${qs.stringify(query, { indices: false })}`);

export interface CreateCircuitReq {
  type: CircuitType;
  name: string;
  ouId: number;
  capacity?: number;
  configuredEPrice?: boolean;
  ePriceId?: number;
  isCustomEPrice?: boolean;
  distributionCabinetName?: string;
  voltageLevel?: VoltageLevel;
  voltageOutLevel?: VoltageLevel;
  code?: string;
  householdNo?: string;
  upstreamCircuitId?: number;
  upstreamCircuitLeftId?: number;
  upstreamCircuitRightId?: number;
  devices?: number[];
  ePrice?: Omit<EPrice, 'id' | 'name' | 'code'>;
  remark?: string;
}

export const createCircuit = (body: CreateCircuitReq) =>
  fetch(`/api/circuit`, {
    method: 'POST',
    body: JSON.stringify(body),
  });

export interface CircuitListReq {
  ouId?: number;
  type?: CircuitType;
  name?: string;
  distributionCabinetId?: number;
}

export interface CircuitProps {
  voltageLevel?: VoltageLevel;
  voltageLevelIn?: VoltageLevel;
  id: number;
  name: string;
  ouId: number;
  type: CircuitType;
  capacity?: number;
  createTime?: string;
  hasLineLoss?: string;
  distributionCabinetId?: number;
  distributionCabinetName?: string;
}

export const getCircuitList = (query?: CircuitListReq) =>
  fetch<CircuitProps[]>(`/api/circuit/list?${qs.stringify(query, { indices: false })}`);

export const deleteCircuit = (id: number) =>
  fetch(`/api/circuit/${id}`, {
    method: 'DELETE',
  });

export const getCircuitDetail = (id: number) => fetch<CircuitPageProps>(`/api/circuit/${id}`);

export const getCircuitDetail2 = () => {
  return Promise.resolve(10);
};

export const updateCircuit = (body: CreateCircuitReq, id: number) =>
  fetch(`/api/circuit/${id}`, {
    method: 'PUT',
    body: JSON.stringify(body),
  });

export interface MonitoringIndicatorProps {
  id: number;
  circuitId: number;
  circuitPhysicalQuantityId: number;
  circuitPhysicalQuantityName: string;
  physicalUnitId: number;
  physicalUnitName: string;
  type: CircuitPhysicalQuantityType;
  deviceId: number;
  deviceName: string;
  dataPropertyId: number;
  dataPropertyName: string;
  startTime: string;
  endTime: string;
  circuitCreateTime: string;
}

export const queryMonitoringIndicatorList = (id: string) =>
  fetch<MonitoringIndicatorProps[]>(
    `/api/circuit/monitoring/indicator/config/list/?${qs.stringify({ circuitId: id }, { indices: false })}`
  );

interface MonitoringIndicatorBody {
  deviceId: number;
  dataPropertyId: number;
  circuitId: number;
  circuitPhysicalQuantityId: number;
  startTime?: number;
  endTime?: number;
}

export const bindMonitoringIndicator = (body: MonitoringIndicatorBody) =>
  fetch(`/api/circuit/monitoring/indicator/config/edit`, {
    method: 'POST',
    body: JSON.stringify(body),
  });

export const unbindMonitoringIndicator = (body: MonitoringIndicatorBody) =>
  fetch(`/api/circuit/monitoring/indicator/config/cancel`, {
    method: 'POST',
    body: JSON.stringify(body),
  });

interface BatchMonitoringIndicatorBody {
  deviceId: number;
  circuitId: number;
  startTime: number;
  mapRequests: { dataPropertyId?: number; circuitPhysicalQuantityId?: number }[];
}

export const batchBindMonitoringIndicator = (body: BatchMonitoringIndicatorBody) =>
  fetch(`/api/circuit/monitoring/indicator/config/batch/edit`, {
    method: 'POST',
    body: JSON.stringify(body),
  });

export interface DataLinkProps extends MonitoringIndicatorProps {}

export const queryDataLinkList = (query: { circuitId: number; circuitPhysicalQuantityId: number }) =>
  fetch<DataLinkProps[]>(
    `/api/circuit/monitoring/indicator/config/data-link?${qs.stringify(query, { indices: false })}`
  );

export interface IndicatorLogProps {
  id: number;
  originalDeviceId: number;
  originalDeviceName: string;
  originalDataPropertyId: number;
  originalDataPropertyName: string;
  modifiedDeviceId: number;
  modifiedDeviceName: string;
  modifiedDataPropertyId: number;
  modifiedDataPropertyName: string;
  editTime: string;
  cancelTime: string;
}

export const queryIndicatorLogList = (query: { circuitId: number; circuitPhysicalQuantityId: number }) =>
  fetch<IndicatorLogProps[]>(
    `/api/circuit/monitoring/indicator/config/log/list?${qs.stringify(query, { indices: false })}`
  );

export interface CircuitTreeRequest {
  name?: string;
  type?: CircuitType;
  bindDevice?: boolean;
}

export const getCircuitTree = (query: CircuitTreeRequest) =>
  fetch<CircuitTreeData[]>(`/api/circuit/tree?${qs.stringify(query, { indices: false })}`);

export const getCircuitTree2 = (query: CircuitTreeRequest) =>
  fetch<CircuitTreeData2[]>(`/api/circuit/tree2?${qs.stringify(query, { indices: false })}`);

export const getCircuitTree4 = (query: CircuitTreeRequest) =>
  fetch<CircuitTreeData2[]>(`/api/circuit/tree4?${qs.stringify(query, { indices: false })}`);

export interface CircuitTreeData3 {
  hasOu: boolean;
  id: number;
  name: string;
  type: string;
  children?: CircuitTreeData3[];
}

export const getCircuitTree3 = (circuitId: number) =>
  fetch<CircuitTreeData3[]>(`/api/circuit/tree3?circuitId=${circuitId}`);

export const v2GetCircuitTree3 = (circuitId: number) =>
  fetch<{ list: CircuitTreeData3[] }>(
    `/api/v2/circuit/tree3`,
    {
      method: 'POST',
      body: JSON.stringify({ id: circuitId }),
    },
    true
  ).then(res => res.list);

export enum StatisticsPartition {
  MINUTE_1 = '1M',
  MINUTE_15 = '15M',
  MINUTE_30 = '30M',
  DAY = 'D',
  MONTH = 'M',
}

export const DatePickerTypeDisplay = {
  [StatisticsPartition.MINUTE_1]: '1分钟',
  [StatisticsPartition.MINUTE_15]: '15分钟',
  [StatisticsPartition.DAY]: '按日',
  [StatisticsPartition.MONTH]: '按月',
};

export const StatisticsPartitionTime = {
  [StatisticsPartition.MINUTE_1]: 'MM-DD HH:mm',
  [StatisticsPartition.MINUTE_15]: 'MM-DD HH:mm',
  [StatisticsPartition.MINUTE_30]: 'MM-DD HH:mm',
  [StatisticsPartition.DAY]: 'MM-DD',
  [StatisticsPartition.MONTH]: 'YYYY-MM',
};

export interface CircuitStatisticsRequest {
  dataType?: DataType;
  dataTypes?: DataType[];
  circuitId?: number | string;
  circuitIds?: string;
  timeResolution?: StatisticsPartition;
  from: number;
  to: number;
}

export interface CircuitStatisticsDataType {
  time: number;
  value: number | null;
}

export enum CircuitStatisticsType {
  AVERAGE = 1,
  MAX = 2,
  MIN = 3,
}

export interface CircuitStatisticsResponse {
  id: DataNameType;
  data: CircuitStatisticsDataType[];
  type?: CircuitStatisticsType;
  name?: string;
}
// 按分钟取值
export const getCircuitStatistics = (query: CircuitStatisticsRequest) =>
  fetch<CircuitStatisticsResponse[]>(`/api/circuit/data/statistics?${qs.stringify(query, { indices: false })}`);

// 按分钟取值v2
export const v2GetCircuitStatistics = (query: CircuitStatisticsRequest) =>
  fetch<{ list: CircuitStatisticsResponse[] }>(
    `/api/v2/circuit/data/statistics`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  ).then(res => res.list);

export interface CircuitEPStatisticsRes {
  summit?: number;
  peak?: number;
  plain?: number;
  valley?: number;
}

// 电量按日，月取值（S58迭代）
export const getCircuitStatisticsByDayOrMonth = (
  query: Omit<CircuitStatisticsRequest, 'circuitIds' | 'dataType' | 'timeResolution'>
) =>
  fetch<CircuitEPStatisticsRes>(`/api/circuit/time/electricity/statics/sum?${qs.stringify(query, { indices: false })}`);

// 批量获取回路数据

export interface BathCircuitStatisticsResponse {
  circuitId: number;
  circuitPhysicalQuantityId: number;
  sum: number;
  // data: { time: number; sum: number }[];
}
// export const getCircuitStatisticsBatch = (query: CircuitStatisticsRequest) =>
//   fetch<BathCircuitStatisticsResponse[]>(
//     `/api/circuit/data/statisticsBatchWithCache?${qs.stringify(query, { indices: false })}`
//   );
export const getCircuitStatisticsBatch = (query: CircuitStatisticsRequest) =>
  fetch<BathCircuitStatisticsResponse[]>(`/api/circuit/data/aggrBatch?${qs.stringify(query, { indices: false })}`);

export const v2GetCircuitStatisticsBatch = (query: CircuitStatisticsRequest) =>
  fetch<{ list: BathCircuitStatisticsResponse[] }>(
    `/api/v2/circuit/data/aggrBatch`,
    {
      method: 'POST',
      body: JSON.stringify({ ...query, circuitIds: query?.circuitIds?.split(',') }),
    },
    true
  ).then(res => res.list);

export interface CircuitEnergyConfig {
  needControlTarget?: number | null; // 需量控制目标
  loadControlTarget?: number | null; // 负载控制目标
  currentHarmonicUpper?: number | null; // 电流谐波上限
  lineVoltageLower?: number | null; // 线电压下限
  lineVoltageUpper?: number | null; // 线电压上限
  phaseVoltageLower?: number | null; // 相电压下限
  phaseVoltageUpper?: number | null; // 相电压上限
  voltageHarmonicUpper?: number | null; // 电压谐波上限
}

// 默认上下限与控制目标
export enum CircuitUpperLower {
  ZERO_POINT_FOUR = 1, // 0.4kV
  TEN = 2, // 10kV
  TWENTY = 3, // 20kV
  THIRTY_FIVE = 4, // 35kV
  SIXTY_SIX = 5, // 66kV
  ONE_HUNDRED_TEN = 6, // 110kV
  TWO_HUNDRED_AND_TWO = 7, //220kV
}
// 配置的线电压，相电压的国标值
export const CircuitUpperLowerValue = {
  [CircuitUpperLower.ZERO_POINT_FOUR]: [
    [353.4, 406.6],
    [198, 235.4],
  ],
  [CircuitUpperLower.TEN]: [[9.3, 10.7]],
  [CircuitUpperLower.TWENTY]: [[18.6, 21.4]],
  [CircuitUpperLower.THIRTY_FIVE]: [[33.25, 36.75]],
  [CircuitUpperLower.SIXTY_SIX]: [[33.25, 36.75]],
  [CircuitUpperLower.ONE_HUNDRED_TEN]: [[104.5, 115.5]],
  [CircuitUpperLower.TWO_HUNDRED_AND_TWO]: [[209, 231]],
};

export const updateCircuitEnergyConfig = (body: CircuitEnergyConfig, id: number) =>
  fetch(`/api/circuit/${id}/energyConfig`, {
    method: 'PUT',
    body: JSON.stringify(body),
  });

export const getCircuitEnergyConfig = (id: number) => fetch<CircuitEnergyConfig>(`/api/circuit/${id}/energyConfig`);

export interface CircuitDataType {
  id: DataType;
  name: string;
  physicalQuantityNames?: string[];
}

export const getCircuitDataTypeList = (id: number) => fetch<CircuitDataType[]>(`/api/circuit/dataTypeList/${id}`);

interface CircuitEpriceRequest {
  circuitId: number;
  time: number;
}

export const getCircuitEprice = (query: CircuitEpriceRequest) =>
  fetch<EPriceTime[]>(`/api/circuit/eprice?${qs.stringify(query, { indices: false })}`);

export interface AllEPriceTime {
  [key: number]: EPriceTime[];
}

export const getAllEprices = (circuitId: number) => fetch<AllEPriceTime>(`/api/circuit/eprices?circuitId=${circuitId}`);

export interface EpricesByElectricityAccountProps {
  circuitId: number;
  from: number;
  to: number;
}

// 通过回路配置的户号来获取回路的所有电价区间
export const getEpricesByElectricityAccount = (params: EpricesByElectricityAccountProps) =>
  fetch<AllEPriceTime>(`/api/circuit/electricityAccount/eprices?${qs.stringify(params, { indices: false })}`);

export interface DevicesProps {
  id: number;
  name: string;
}

export const getDevices = (circuitId: number) =>
  fetch<DevicesProps[]>(`/api/circuit/device/query?circuitId=${circuitId}`);

export interface CircuitDataQuery {
  circuitId: number;
  dataType: number;
  type: number;
  from: number;
  to: number;
}

export interface CircuitData {
  id: number;
  name: string;
  data: CircuitStatisticsDataType[];
}

export const getCircuitData = (query: CircuitDataQuery) =>
  fetch<CircuitData[]>(`/api/circuit/data/aggregate?${qs.stringify(query, { indices: false })}`);

export interface CircuitNowData {
  id: number;
  name: string;
  value: number;
}
export const getCircuitDataNow = (circuitId: string) => fetch<CircuitNowData[]>(`/api/circuit/${circuitId}/data/now`);
export interface unboundListRes {
  id: number;
  circuitId: number; // 回路id
  circuitPhysicalQuantityId: number; // 回路物理量id
  circuitPhysicalQuantityName: string; // 回路物理量名称
  physicalUnitId: number; //物理单位id
  physicalUnitName: string; // 物理单位名称
  type: number; // 类型(1. 累计量;2. 瞬时量)
  deviceId: number; // 设备id
  deviceName: string; // 设备名称
  dataPropertyId: number; // 数据属性id
  dataPropertyName: string; // 数据属性名称
  startTime: string; // 数据生效时间
  endTime: string; // 结束时间
  sequence: number; // 次序
  circuitCreateTime: string; // 回路创建时间
}

export interface unboundListQu {
  circuitId: number; // 回路id
  deviceId?: number; // 设备id
}

export function getCircuitMonitoringIndicatorConfigUnboundList(query: unboundListQu) {
  return fetch<unboundListRes[]>(
    `/api/circuit/monitoring/indicator/config/unbound-list?${qs.stringify(query, { indices: false })}`
  );
}

export interface SortingCustomizeProps {
  dataType: DataType; // 数据属性id
  sequenceNumber: number; // 顺序
}

// 更新员工数据类型自定义顺序
export const updateSortingCustomize = (body: SortingCustomizeProps[], staffId: number) =>
  fetch(`/api/staff/data-type/sorting/customize/update/${staffId}`, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
// 分页查询回路节能配置导入记录 请求数据
export interface BatchImportPageReq extends PageRequest {}

// 分页查询回路节能配置导入记录 返回数据
export interface BatchImportPageResponse {
  staffName?: string; // 导入人名称
  staffId?: number; // staff id
  dataAll?: string; // 导入excel在文件系统的key
  dataSuccess?: string; //导入excel成功数据生成的文件文件系统的key
  dataFail?: string; //导入excel失败数据生成的文件文件系统的key
  numAll?: number; //导入excel的行数
  numSuccess?: number; //导入excel成功的行数
  numFail?: number; //导入excel失败的行数
  createTime?: string;
  updateTime?: string;
  endTime?: string; //导入完全结束的时间
}

// 分页查询回路节能配置导入记录 请求接口
export function getCircuitEnergyConfigImportRecord(params: BatchImportPageReq) {
  return fetch<PageResponse<BatchImportPageResponse>>(
    `/api/circuit/energyConfig/importRecord/page?${qs.stringify(params, { indices: false })}`
  );
}
export interface MonitoringImportRecordRes extends BatchImportPageResponse {
  dataSuccess: string;
  dataFail: string;
}
// 批量操作日志列表
export function getCircuitMonitoringImportRecord(params: BatchImportPageReq) {
  return fetch<PageResponse<MonitoringImportRecordRes>>(
    `/api/circuit/monitoring/indicator/config/importRecord/page?${qs.stringify(params, { indices: false })}`
  );
}
// 导出回路列表
export function exportCircuitList(params?: Omit<CircuitPageReq, 'page' | 'size'>): string {
  return `/api/circuit/export?${qs.stringify(params, { indices: false })}`;
}
// 下载回路监控指标配置导入模板
export function downLoadModuleForMonitoring() {
  window.open(`/api/circuit/monitoring/indicator/config/download`, '_blank');
}

// 导入回路监控指标
export const importMonitoring = (): string => {
  return '/api/circuit/monitoring/indicator/config/import';
};
// 批量新建回路模板下载
export function downLoadModuleForCircuit() {
  window.open(`/api/circuit/download`, '_blank');
}

export const importCircuit = (): string => {
  return '/api/circuit/import';
};
export interface SimpleCircuitInfo {
  id: number;
  name: string;
}
export interface CabinetWithCircuitResponse extends SimpleCircuitInfo {
  circuitList: SimpleCircuitInfo[];
}
// 获取配电柜带回路列表
export function getCabinetWithCircuitList() {
  return fetch<CabinetWithCircuitResponse[]>(`/api/distribution-cabinet/listWithCircuit`);
}

// 新建配电柜
export function addCabinet(name: string) {
  return fetch(`/api/distribution-cabinet`, {
    method: 'POST',
    body: JSON.stringify({
      name,
    }),
  });
}
// 修改配电柜
export function editCabinet(id: number, name: string) {
  return fetch(`/api/distribution-cabinet/${id}`, {
    method: 'PUT',
    body: JSON.stringify({
      name,
    }),
  });
}

// 删除配电柜
export function deleteCabinet(id: number) {
  return fetch(`/api/distribution-cabinet/${id}`, {
    method: 'DELETE',
  });
}

// 所属UET列表
export interface UETListResponse {
  id: number;
  name?: string; // 名称
  serialNumber?: string; // 编号
  mcid?: string; // 所属组织
  customerName?: string; //组织简称
  customerFullName?: string; // 组织全称
  customerCode?: string; // 组织编号
  tenantMcid?: string; // 所属租户
  status?: number; // 状态(0-停用,1-启用,2-作废,3-草稿)
  createMuid?: string; // 创建者muid
  createCustomerUserName?: string;
  updateMuid?: string; // 修改者muid
  updateCustomerUserName?: string;
  createTime?: string;
  updateTime?: string;
  baseComplete?: boolean; // 是否填写完基础设定
  businessUnits?: BusinessUnitsType[]; // 包含的业务单元类型
  ous?: UETListousType[];
}

export interface UETListousType {
  id: number;
  name?: string; //名称
  serialNumber?: string; // 序列号
  ouTypeId?: number; //类型id
  status?: number; //0,禁用;1,启用;2,作废(原本作废用的是0,修改为2,并且OU状态值目前只有启用和作废)
  tenantMcid?: string; //租户mcid
  mcid?: string; //组织mcid
  directorName?: string; //负责人姓名
  directorPhone?: string; // 负责人电话
  remark?: string; //备注
  createTime?: string; //创建时间
  updateTime?: string; // 更新时间
  updateByUsername?: string; //操作人
  updateByUserId?: string; //操作人id
  updateSource?: number; //操作端
  manageUpdateByUsername?: string; //操作人(实施)
  manageUpdateByUserId?: string; //操作人id(实施)
  homePage?: string; //首页地址
  manageUpdateTime?: string; //更新时间(实施)
}

export interface BusinessUnitsType {
  id: number;
  uetId?: number;
  businessUnitType?: number; //业务单元类型id
  complete?: boolean; // 是否完成填写
  createTime?: string;
  updateTime?: string;
}

export enum BusinessUnitTypeType {
  PROD_LOAD = 0, // 生产/负载线
  BSA = 1, // 储能阵列
  PV_STATION = 2, // 光伏站
  LINE_PIPE = 3, // 管线
  MICROGRID_POWER_TOPOLOGY = 4, // 微网电能拓扑
}

// 回路中查询UET列表
export const getUETList = (query?: { businessUnitType: BusinessUnitTypeType }) =>
  fetch<UETListResponse[]>(`/api/uet/listByBusinessUnitType?${qs.stringify(query, { indices: false })}`);

// 编辑回路-回路关联
export enum CircuitRelationType {
  // ENERGY_STORAGE_LOAD = 1, // 储能负载
  PV_NETWORK_CONNECTION = 2, // 光伏并网点
}
export const CircuitRelationTypeFormat = {
  // [CircuitRelationType.ENERGY_STORAGE_LOAD]: '储能负载',
  [CircuitRelationType.PV_NETWORK_CONNECTION]: '光伏并网点',
};

// 回路关联关系
export interface CircuitLinkObj {
  id: number; //id
  name?: string; //名称
  children?: CircuitLinkObj[]; //子列表
}

// 回路关联类型枚举。 2：光伏站/并网点
export enum CircuitLinkType {
  PV_NETWORK_CONNECTION = 2, // 光伏站/并网点
}

// 专门用于回路新建和修改时，查询关联对象的列表
export const getCircuitLink = (params: { circuitLinkType: CircuitLinkType }) => {
  return fetch<CircuitLinkObj[]>(`/api/circuit/link?${qs.stringify(params, { indices: false })}`);
  // return fetch<CircuitLinkObj[]>(
  //   `http://10.50.16.213:40001/mock/255/api/circuit/link?${qs.stringify(params, { indices: false })}`
  // );
};
