import { LossAlarmRulesRes } from '@/api/lineLoss';
import {
  DatePicker,
  Form,
  Input,
  Select,
  Checkbox,
  EllipsisSpan,
  Paging,
  Table,
  useBreadcrumbRoutes,
  usePaging,
  Button,
  Wrapper,
  CustomFilter,
} from '@maxtropy/components';
import { Divider } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { fetchOuList, getOuId } from '@/api/ou';
import { useDefaultRangeTime } from '@/utils/hooks';
import { apiV2LineLossAlarmLogPagePost } from '@maxtropy/dmes-apis-v2';
import styles from './index.module.scss';

const { RangePicker } = DatePicker;

const columns = [
  {
    title: '报警回路名称',
    dataIndex: 'circuitName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警等级',
    dataIndex: 'alarmLevel',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={AlarmLevelDisplay[v as keyof typeof AlarmLevelDisplay]} />,
  },
  {
    title: '报警规则编号',
    dataIndex: 'ruleCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警规则名称',
    dataIndex: 'ruleName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警时间',
    dataIndex: 'alarmTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
];
interface SearchParamsProps {
  ouIds?: string[] | number[];
  circuitName?: string;
  alarmStartTime?: any;
  alarmEndTime?: any;
  times?: RangeValue;
}

export enum AlarmLevel {
  HIGHEST,
  HIGH,
  MIDDLE,
  LOW,
  LOWEST,
}

export const AlarmLevelDisplay = {
  [AlarmLevel.HIGHEST]: '最高级',
  [AlarmLevel.HIGH]: '高级',
  [AlarmLevel.MIDDLE]: '中级',
  [AlarmLevel.LOW]: '低级',
  [AlarmLevel.LOWEST]: '最低级',
};
type RangeValue = [Dayjs | null, Dayjs | null] | null;
const RangeDays: number = 180;
const RelogList: React.FC = () => {
  const routesContext = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const { startTime, endTime } = useDefaultRangeTime('week'); // 默认一周;
  const [searchParams, setSearchParams] = useState<any>({
    alarmStartTime: startTime,
    alarmEndTime: endTime,
  });

  const { data, isLoading, refetch } = useQuery(
    ['alarmLogPage', pageOffset, pageSize, searchParams, setTotalCount],
    async () => {
      if (!searchParams?.alarmStartTime || !searchParams?.alarmEndTime) return [];
      const res = await apiV2LineLossAlarmLogPagePost({ ...searchParams, page: pageOffset, size: pageSize });
      if (!res) return [];
      setTotalCount(res.total ?? 0);
      return res.list;
    }
  );

  const { data: ouList } = useQuery('ouList', async () => {
    const ouType = (await getOuId())?.filter(i => i.key === 'MICRONET')[0]?.id;
    if (!ouType) return [];
    return fetchOuList(ouType);
  });
  const ouOptions = useMemo(() => {
    if (ouList && ouList.length !== 0) {
      return ouList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [ouList]);
  const [dates, setDates] = useState<RangeValue>(null);
  const [hackValue, setHackValue] = useState<RangeValue>(null);
  const [value, setValue] = useState<RangeValue>([dayjs(startTime), dayjs(endTime)]);
  const [ouSearchValue, setOuSearchValue] = useState<string>('');
  const ouIds = Form.useWatch('ouIds', form);
  const allOuSelected = useMemo(() => ouIds?.length === ouList?.length, [ouList?.length, ouIds?.length]);

  useEffect(() => {
    form.setFieldsValue({
      times: value || hackValue,
    });
  }, [hackValue, value, form]);

  // 日期范围180天
  const disabledDate = (current: Dayjs) => {
    // 不能大于当天
    const temp = current && current > dayjs().endOf('day');
    if (!dates) {
      return false;
    }
    const tooLate = (dates[0] && current.diff(dates[0], 'days') > RangeDays) || temp;
    const tooEarly = (dates[1] && dates[1].diff(current, 'days') > RangeDays) || temp;
    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setHackValue([null, null]);
      setDates([null, null]);
    } else {
      setHackValue(null);
    }
  };

  const actionColumn = {
    title: '操作',
    width: 200,
    fixed: 'right' as const,
    render: (v: LossAlarmRulesRes) => (
      <Button
        type="link"
        onClick={() => {
          window.open(`/dmes/lineLoss/alarmLogs/${v.id}/view`, '_self');
        }}
      >
        查看报警详情
      </Button>
    ),
  };

  const filters = (
    <CustomFilter
      form={form}
      onFinish={(values: SearchParamsProps) => {
        let rangeTime = values.times;
        if (rangeTime) {
          values.alarmStartTime = dayjs(rangeTime[0]).valueOf();
          values.alarmEndTime = dayjs(rangeTime[1]).valueOf();
        }
        delete values.times;
        setSearchParams(values);
        setPageOffset(1);
        refetch();
      }}
      onReset={() => {
        setSearchParams({});
        setPageOffset(1);
        setValue([dayjs(startTime), dayjs(endTime)]);
        refetch();
      }}
    >
      <Form.Item name="alarmLevel" label="报警等级">
        <Select placeholder="请选择" mode="multiple">
          <Select.Option value={AlarmLevel.HIGHEST}>{AlarmLevelDisplay[AlarmLevel.HIGHEST]}</Select.Option>
          <Select.Option value={AlarmLevel.HIGH}>{AlarmLevelDisplay[AlarmLevel.HIGH]}</Select.Option>
          <Select.Option value={AlarmLevel.MIDDLE}>{AlarmLevelDisplay[AlarmLevel.MIDDLE]}</Select.Option>
          <Select.Option value={AlarmLevel.LOW}>{AlarmLevelDisplay[AlarmLevel.LOW]}</Select.Option>
          <Select.Option value={AlarmLevel.LOWEST}>{AlarmLevelDisplay[AlarmLevel.LOWEST]}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="ouIds" label="所属运营单元">
        <Select
          maxTagCount={2}
          mode="multiple"
          placeholder="全部"
          options={ouOptions}
          showSearch
          optionFilterProp="label"
          searchValue={ouSearchValue}
          onChange={() => {
            setOuSearchValue('');
          }}
          onSearch={value => {
            setOuSearchValue(value);
          }}
          dropdownRender={menu => (
            <>
              {!ouSearchValue.length && ouList?.length ? (
                <div style={{ padding: '4px 8px 8px 12px', cursor: 'pointer' }}>
                  <Checkbox
                    indeterminate={ouIds?.length && ouIds?.length < (ouList?.length ?? 0)}
                    checked={allOuSelected}
                    onChange={e => {
                      if (e.target.checked) {
                        // 全选
                        form.setFieldValue(
                          'ouIds',
                          ouList?.map(v => v.id)
                        );
                      } else {
                        // 反选
                        form.setFieldValue('ouIds', []);
                      }
                    }}
                  >
                    全选
                  </Checkbox>
                </div>
              ) : null}
              <Divider style={{ margin: 0 }} />
              {menu}
            </>
          )}
        />
      </Form.Item>
      <Form.Item name="circuitName" label="回路名称">
        <Input placeholder="请输入回路名称" />
      </Form.Item>
      <Form.Item name="times" label="报警日期" rules={[{ required: true, message: '请选择日期' }]}>
        <RangePicker
          disabledDate={disabledDate}
          onCalendarChange={val => setDates(val)}
          onChange={val => setValue(val)}
          onOpenChange={onOpenChange}
          showTime
          format={'YYYY-MM-DD HH:mm:ss'}
        />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper routes={routesContext?.routes} filters={filters} className={styles.wrapper}>
      <Table
        sticky
        rowKey="id"
        scroll={{ x: 1500 }}
        columns={[...columns, actionColumn]}
        dataSource={data}
        loading={isLoading}
      />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default RelogList;
