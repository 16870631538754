import { EllipsisSpan, Table } from '@maxtropy/components';
import { FC, useMemo } from 'react';
import { useRequest } from 'ahooks';
import { apiV2PvOutlineIndicatorListPost, V2PvOutlineIndicatorListPostResponse } from '@maxtropy/dmes-apis-v2';
import { ColumnsType } from 'antd/es/table';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import styles from './index.module.scss';
import settingIcon from '../images/setting.svg';
import safetyIcon from '../images/safetyOperation.svg';
import { customFixed } from '@/utils/utils';
import { v4 } from 'uuid';

interface Props {
  pvId: number | undefined;
}

type ResIndicatorListItem = Exclude<
  V2PvOutlineIndicatorListPostResponse['pvStationIndicatorListResponseList'],
  undefined
>[number];

enum DataType {
  yesterday,
  currentMonth,
  currentYear,
  total,
}

const IndicatorNameList = [
  {
    name: '逆变器发电量(kWh)',
    key: 'inverterPower',
  },
  {
    name: '并网点发电量(kWh)',
    key: 'gridConnectedPower',
  },
  {
    name: '收益(元)',
    key: 'income',
  },
  {
    name: '站用电(kWh)',
    key: 'stationElectricity',
  },
  {
    name: '可用小时数(h)',
    key: 'availableHour',
  },
  {
    name: '二氧化碳减排量(t)',
    key: 'emissionReductions',
  },
  {
    name: '节约标准煤(t)',
    key: 'standardCoal',
  },
];

const IndicatorsStatistics: FC<Props> = props => {
  const { pvId } = props;

  const { data } = useRequest(
    () => {
      return apiV2PvOutlineIndicatorListPost({ id: pvId });
    },
    {
      ready: !!pvId,
      refreshDeps: [pvId],
    }
  );

  const tableData = useMemo(() => {
    const dataList = data?.pvStationIndicatorListResponseList;
    let list = IndicatorNameList.map(item => {
      return {
        indicatorName: item.name,
        yesterday: dataList?.find(dataItem => dataItem.type === DataType.yesterday)?.[
          item.key as keyof ResIndicatorListItem
        ],
        currentMonth: dataList?.find(dataItem => dataItem.type === DataType.currentMonth)?.[
          item.key as keyof ResIndicatorListItem
        ],
        currentYear: dataList?.find(dataItem => dataItem.type === DataType.currentYear)?.[
          item.key as keyof ResIndicatorListItem
        ],
        total: dataList?.find(dataItem => dataItem.type === DataType.total)?.[item.key as keyof ResIndicatorListItem],
      };
    });
    return list;
  }, [data]);

  const columns: ColumnsType<any> = [
    {
      title: '指标',
      dataIndex: 'indicatorName',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v}></EllipsisSpan>,
    },
    {
      title: '昨日',
      dataIndex: 'yesterday',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
    },
    {
      title: '本月',
      dataIndex: 'currentMonth',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
    },
    {
      title: '本年',
      dataIndex: 'currentYear',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={customFixed(v)}></EllipsisSpan>,
    },
    {
      title: '累计',
      dataIndex: 'total',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={customFixed(v)} className={styles.warningColor}></EllipsisSpan>,
    },
  ];

  return (
    <div className={styles.wrap}>
      <Table rowKey={() => v4()} dataSource={tableData} columns={columns} style={{ marginBottom: 8 }}></Table>
      <Space size={5}>
        <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
        <span style={{ color: 'var(--mx-text-desc-color)' }}>列表内数据不含当天</span>
      </Space>

      <div className={styles.bottomWrap}>
        <div className={styles.bottomCard}>
          <img src={settingIcon} className={styles.icon} />
          <div>装机容量</div>
          <div className={styles.value} style={{ color: '#00ADFF' }}>
            {customFixed(data?.installedCapacity)}kW
          </div>
        </div>
        <div className={styles.bottomCard}>
          <img src={safetyIcon} className={styles.icon} />
          <div>安全运行天数</div>
          <div className={styles.value} style={{ color: '#16DD8E' }}>
            {customFixed(data?.runningDay)}天
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndicatorsStatistics;
