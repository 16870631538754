import React, { useEffect } from 'react';
import { Space } from 'antd';
import { Form, Input, Modal, Select, Button } from '@maxtropy/components';
import {
  CabinetPayload,
  createCabinet,
  PVCabinet,
  pvCabinetKeys,
  PVCabinetType,
  updateCabinet,
} from '@/api/pv-cabinet';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getGridConnectionPointList, gridConnectionPointKeys } from '@/api/pv';

export interface PVCabinetFormModalProps {
  pvId: number;
  row?: PVCabinet;
  isDraft: boolean;
  isDisable: boolean;
  onCancel: () => void;
  onOk: () => void;
  onNextStep: (payload: CabinetPayload) => void;
}

const PVCabinetFormModal: React.FC<PVCabinetFormModalProps> = ({
  row,
  onCancel,
  isDraft,
  isDisable,
  pvId,
  onNextStep,
  onOk,
}) => {
  const [form] = Form.useForm();

  const queryClient = useQueryClient();
  const { mutate: createOrUpdate, isLoading } = useMutation(row ? updateCabinet : createCabinet, {
    onSuccess: () => queryClient.invalidateQueries(pvCabinetKeys.list({ pvId })),
  });
  const { data: gridConnectionPoints } = useQuery(gridConnectionPointKeys.list(pvId), () =>
    getGridConnectionPointList(pvId)
  );

  useEffect(() => {
    if (row) {
      form.setFieldsValue({
        ...row,
      });
    }
  }, [form, row]);

  return (
    <Modal
      open
      size="normal"
      contentClassName="modal-form-content"
      title={`${!!row ? '编辑' : '添加'}配套设备`}
      maskClosable={false}
      onCancel={onCancel}
      footer={
        <Space size={8}>
          <Button onClick={onCancel}>取消</Button>
          {!row && (isDraft || isDisable) && (
            <Button
              loading={isLoading}
              type={'primary'}
              onClick={() => form.validateFields().then(values => onNextStep({ ...values, pvId }))}
            >
              下一步
            </Button>
          )}
          <Button
            loading={isLoading}
            type="primary"
            onClick={() =>
              form.validateFields().then(values => createOrUpdate({ ...row, ...values, pvId }, { onSuccess: onOk }))
            }
          >
            确定
          </Button>
        </Space>
      }
    >
      <div>
        <Form form={form}>
          <Form.Item
            name={'name'}
            label={'设备名称'}
            rules={[{ required: true }, { max: 20, message: '最多20个字符' }]}
          >
            <Input placeholder={'请输入'} />
          </Form.Item>
          <Form.Item name={'type'} label={'类型'} rules={[{ required: true, message: '请选择类型' }]}>
            <Select placeholder={'请选择'} disabled={!isDraft && !isDisable}>
              {Object.entries(PVCabinetType.MAP).map(([k, v]) => (
                <Select.Option key={k} value={+k}>
                  {v}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={'gridConnectionPointId'}
            label={'关联并网点'}
            rules={[{ required: true, message: '请选择关联并网点' }]}
          >
            <Select placeholder={'请选择'} disabled={!isDraft && !isDisable}>
              {gridConnectionPoints?.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default PVCabinetFormModal;
