import { BsaStatus } from '@/pages/BsaDataCollectionConfigNew/const';
import { Button } from '@maxtropy/components';
import { V2BsaSecondEditionInfoBsaSecondEditionPostResponse } from '@maxtropy/dmes-apis-v2';
import { Space } from 'antd';
import { useMemo } from 'react';
import { CreateStatus, StructStep } from '../const';

interface Iprops {
  isView?: boolean;
  saveLoading?: boolean;
  localCurrentStep: number;
  detail?: V2BsaSecondEditionInfoBsaSecondEditionPostResponse;
  remoteStep: number;
  isCanEdit?: boolean;
  currentStepTileList: any;
  prevBtn: () => void;
  nextStep: () => void;
  nextBtn: () => void;
  saveDraft: () => void;
  backBtn: () => void;
}
const TopOpButtons = ({
  isView,
  saveLoading,
  localCurrentStep,
  detail,
  remoteStep,
  isCanEdit,
  currentStepTileList,
  prevBtn,
  nextStep,
  nextBtn,
  saveDraft,
  backBtn,
}: Iprops) => {
  // 是否是作废状态
  const isValid = useMemo(() => {
    return detail?.actualStatus === BsaStatus.Invalidation;
  }, [detail]);
  // 判断是否是最后一步
  const isLastStep = useMemo(() => {
    return localCurrentStep === currentStepTileList.length - 1;
  }, [localCurrentStep, currentStepTileList]);
  /**
   * 基础信息, pcs, 电池堆的1,2,3步草稿按钮是否显示
   */
  const showDraftBtnBeforeRackCreate = useMemo(() => {
    return !isValid && !isView && localCurrentStep <= 2 && detail?.rackDeviceCreateStatus !== CreateStatus.DONE;
  }, [detail, isView, localCurrentStep, isValid]);
  /**
   * 后面几步存草稿显示逻辑
   * 1. 详情不显示
   * 2. 在电池堆创建完成之后的状态机, 不显示
   * 3. 簇或组或芯生成后, 不显示
   */

  const showDraftBtn = useMemo(() => {
    return (
      !isValid &&
      !isView &&
      localCurrentStep > 2 &&
      ((localCurrentStep === StructStep.COMPLETE_STACK_CONFIG &&
        detail?.rackDeviceCreateStatus !== CreateStatus.DONE) ||
        (localCurrentStep === StructStep.COMPLETE_RACK_CONFIG &&
          detail?.packDeviceCreateStatus !== CreateStatus.DONE) ||
        (localCurrentStep === StructStep.COMPLETE_PACK_CONFIG && detail?.cellDeviceCreateStatus !== CreateStatus.DONE))
    );
  }, [isView, localCurrentStep, detail, isValid]);

  // 上一步按钮是否显示
  const showPrevBtn = useMemo(() => {
    return !isValid && localCurrentStep > 0;
  }, [localCurrentStep, detail, isValid]);

  // 下一步跳转表单按钮是否显示
  const showNextFormBtn = useMemo(() => {
    return !isValid && ((localCurrentStep < remoteStep && !isCanEdit) || isView);
  }, [localCurrentStep, isCanEdit, isView, isValid]);

  // 下一步保存按钮是否显示
  const showNextSaveBtn = useMemo(() => {
    return !showNextFormBtn && !isValid;
  }, [showNextFormBtn, isValid]);
  return (
    <Space>
      {showPrevBtn && (
        <Button type="primary" onClick={prevBtn}>
          上一步
        </Button>
      )}
      {showNextFormBtn &&
        // 下一步跳转表单功能
        !isLastStep && (
          <Button type="primary" onClick={nextStep}>
            下一步
          </Button>
        )}
      {showNextSaveBtn && (
        // 下一步保存按钮
        <Button loading={saveLoading} type="primary" onClick={nextBtn}>
          {isLastStep ? '完成' : '下一步'}
        </Button>
      )}
      {showDraftBtnBeforeRackCreate && (
        <Button type="primary" onClick={saveDraft}>
          存草稿
        </Button>
      )}
      {/* 存草稿是否显示 */}
      {showDraftBtn && (
        <Button type="primary" onClick={saveDraft}>
          存草稿
        </Button>
      )}

      <Button onClick={backBtn}>取消</Button>
    </Space>
  );
};

export default TopOpButtons;
