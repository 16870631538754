import { V2PvNoticeboardAlarmWeekPostResponse } from '@maxtropy/dmes-apis-v2';
import { isNil } from 'lodash-es';

export const setFitScale = (
  targetContainer?: HTMLDivElement,
  currentClientWidth?: number,
  currentClientHeight?: number
) => {
  if (!targetContainer || !currentClientWidth || !currentClientHeight) return;
  const designWidth = 1920;

  let scale = currentClientWidth / designWidth;

  // 实际容器的高度(减去页面顶部导航栏的高度)
  let containerHeight = document.documentElement.clientHeight - 50;

  /**
   * 1. 当前容器的宽度>=设计稿的宽度时，才进行缩放
   * 2. 先等比缩放, 只考虑宽度, 高度未撑满的话, 取容器高度, 然后再进行flex布局
   *
   */

  if (currentClientWidth >= designWidth) {
    // let currentH = containerHeight >= designHeight ? containerHeight : designHeight;
    let currentH = containerHeight;

    targetContainer.style.width = `${designWidth}px`;

    targetContainer.style.height = `${currentH / scale}px`;
    targetContainer.style.transform = `scale3d(${scale}, ${scale}, ${scale})`;
    targetContainer.style.transformOrigin = 'top left';

    return;
  }
  targetContainer.style.width = '';
  targetContainer.style.height = '';
  targetContainer.style.transform = '';
  targetContainer.style.transformOrigin = '';
};

export function goFullscreen(element: HTMLDivElement) {
  if (document.fullscreenElement) {
    return Promise.reject(new Error('全屏模式被禁用'));
  }
  let result = null;
  if (element.requestFullscreen) {
    result = element.requestFullscreen();
  }
  return result || Promise.reject(new Error('不支持全屏'));
}
export function cancelFullscreen() {
  document.exitFullscreen?.();
}

export const keepTwoNull = (data?: number) => {
  if (!isNil(data)) {
    return Number(data.toFixed(2));
  } else {
    return null;
  }
};

export const keepPercentTwoNull = (data?: number): number | null => {
  if (!isNil(data)) {
    return Number((data * 100).toFixed(2));
  } else {
    return null;
  }
};
export const keepPercentTwoLine = (data?: number): number | string => {
  if (!isNil(data)) {
    return Number((data * 100).toFixed(2));
  } else {
    return '--';
  }
};

export const keepTwoOrLine = (data?: number) => {
  if (!isNil(data)) {
    return Number(data.toFixed(2));
  }
  return '--';
};

export const formatDeviceDesc = (alarmDeviceList?: V2PvNoticeboardAlarmWeekPostResponse['alarmDeviceList']) => {
  if (alarmDeviceList?.length) {
    let str = '';
    alarmDeviceList.forEach((area, index) => {
      if (index === alarmDeviceList.length - 1) {
        str += area.stringValue;
      } else {
        str += area.stringValue + '、';
      }
    });
    return str;
  }
  return '-';
};

// 判断所有元素是否为null
export const judgeAllNull = (arr: any[]) => {
  return arr.every(element => element === null);
};
