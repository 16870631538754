// 数据类型
export enum TabType {
  OPERATING_POWER = '1',
  SOC = '2', // SOC
}

// 时间颗粒度
export enum TimeGranularity {
  ORIGINAL = 0,
  ONE_MINUTE,
  FIFTEEN_MINUTE,
}

// 储能站类型-新旧
export enum BsaType {
  OLD,
  NEW,
}

// 数据类型
export enum DataType {
  PCS = 1,
  ANTI_CURRENT, // 防逆流电表功率(储能总计量表)
  UP_TRANSFORMER, //  上端变压器功率
  BSA_MEASUREMENT, //  储能计量电表
  SOC,
}

export const pcsColorList = [
  '#FF6D2C',
  '#0DB6D9',
  '#CE90D1',
  '#63BC7F',
  '#FFCB47',
  '#00ADFF',
  '#B57CFC',
  '#16DD8E',
  '#2D8DFF',
  '#FF9247',
  '#ABD335',
  '#854ECA',
];

export const socColorList = [
  '#16DD8E',
  '#2D8DFF',
  '#FF9247',
  '#FF9247',
  '#854ECA',
  '#FF6D2C',
  '#0DB6D9',
  '#CE90D1',
  '#63BC7F',
  '#FFCB47',
  '#00ADFF',
  '#B57CFC',
];

export const ammeterColorList = [
  '#B57CFC',
  '#00ADFF',
  '#63BC7F',
  '#CE90D1',
  '#0DB6D9',
  '#FF6D2C',
  '#854ECA',
  '#ABD335',
  '#FF9247',
  '#2D8DFF',
  '#16DD8E',
];

export const upperTransformerMeterPowerChartsColorList = [...ammeterColorList].reverse();

export const bsaColor = '#52E7FF';

export const inPowerWithoutBsaColor = '#FFCB47';

export const inPowerColor = '#FF477B';

export const dash3LegendIcon = 'path://M0 4 h4 v-4 h-4 zM6 4 h4 v-4 h-4 zM12 4 h4 v-4 h-4 z';

export const dash2LegendIcon = 'path://M0 4 h7 v-4 h-7 zM9 4 h7 v-4 h-7 z';

export const solidLegendIcon = 'path://M0 4 h16 v-4 h-16 z';
