import React from 'react';
import styles from './index.module.scss';
import AlarmList from './components/AlarmList';
import DiscreteAnalysis from './components/DiscreteAnalysis';

const CenterRightBox = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <AlarmList />
      </div>
      <div className={styles.bottom}>
        <DiscreteAnalysis />
      </div>
    </div>
  );
};

export default CenterRightBox;
