import BorderWrapper from '@/components/BorderWrapper';
import { PlusOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, FormContent, Tabs, useBreadcrumbRoutes } from '@maxtropy/components';
import { TabsProps } from 'antd';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import OpTableList from './OpTableList';
import OrderTableList from './OrderTableList';
import { useRequest } from 'ahooks';
import { getBsa } from '@/api/bsa';
const routes = [{ name: `储能站控制指令配置` }];
const tabItems: TabsProps['items'] = [
  {
    key: '1',
    label: '指令配置',
  },
  {
    key: '2',
    label: '操作记录',
  },
];
const StorageOrderConfig = () => {
  const routesContext = useBreadcrumbRoutes();
  const [currentTab, setCurrentTab] = useState('1');
  const navigate = useNavigate();
  const { bsaId } = useParams<{ bsaId: string }>();
  const { data: bsaInfo } = useRequest(() => getBsa(bsaId), { ready: !!bsaId });

  return (
    <>
      <div className={styles.page_box}>
        <div className="page">
          <div className="page__header">
            <Breadcrumb routes={[...(routesContext?.routes ?? []), ...routes]} />
          </div>
          <BorderWrapper>
            <div className="page__content">
              <FormContent
                style={{ paddingTop: 5, paddingLeft: 10, paddingRight: 10 }}
                title={`${bsaInfo?.name ?? ''}控制指令配置`}
                extraContent={
                  <Button onClick={() => navigate('/energy-storage/basic/bsa', { replace: true })}>返回</Button>
                }
              >
                <Tabs
                  items={tabItems}
                  activeKey={currentTab}
                  selectedLinePlacement="bottom"
                  bordered
                  onChange={setCurrentTab}
                />

                <div className={styles.order_area}>
                  {currentTab === '1' && <OrderTableList bsaId={bsaId} status={bsaInfo?.status} />}
                </div>
                <div className={styles.op_record}>{currentTab === '2' && <OpTableList bsaId={bsaId} />}</div>
              </FormContent>
            </div>
          </BorderWrapper>
        </div>
      </div>
    </>
  );
};

export default StorageOrderConfig;
