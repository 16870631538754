import { CircuitLossRes, createCircuitLoss, getCircuitLossDetail, updateCircuitLoss } from '@/api/lineLoss';
import { Button, FormTitle, SubContent, Wrapper, useBreadcrumbRoutes } from '@maxtropy/components';
import { Space } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LossBaseInfo from './components/LossBaseInfo';
import LossComputerConfig from './components/LossComputerConfig';
import styles from './index.module.scss';

export interface LossComputerRefProp {
  getFormValues: () => Promise<any>;
}
const LineLoss: React.FC<{ isEdit?: boolean }> = ({ isEdit }) => {
  const routesContext = useBreadcrumbRoutes();
  const routes = [{ name: '线损配置' }];
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const lossComputerRef = useRef<LossComputerRefProp>(null);
  const [formDetail, setFormDetail] = useState<CircuitLossRes>();
  const saveBtn = () => {
    lossComputerRef?.current?.getFormValues().then(res => {
      let time = res.computationalTime;
      res.computationalTime = dayjs(time).valueOf();
      let saveApi = isEdit ? updateCircuitLoss : createCircuitLoss;
      saveApi({
        ...res,
        circuitId: id,
      }).then(res => {
        if (res) {
          navigate(`/dmes/circuit/circuit`, { replace: true });
        }
      });
    });
  };
  // 查询详情
  useEffect(() => {
    if (id) {
      getCircuitLossDetail(id).then(res => {
        setFormDetail(res);
      });
    }
  }, [id]);

  return (
    <Wrapper className={styles.wrapperPadding} routes={[...(routesContext?.routes ?? []), ...routes]}>
      <FormTitle title="线损配置" />
      <SubContent title="基础信息">
        <LossBaseInfo data={formDetail} />
      </SubContent>
      <SubContent title="线损计算配置">
        <LossComputerConfig data={formDetail} isEdit={isEdit} ref={lossComputerRef} />
        <Space size={8} className={'sticky-footer-left'}>
          <Button type="primary" onClick={saveBtn}>
            保存
          </Button>
          <Button onClick={() => navigate(`/dmes/circuit/circuit`)}>取消</Button>
        </Space>
      </SubContent>
    </Wrapper>
  );
};

export default LineLoss;
