import { V2BsaDataPredictPowerPostResponse } from '@maxtropy/dmes-apis-v2';
import { keepTwoNull } from '../../utils';
import dayjs from 'dayjs';
import * as echarts from 'echarts';

export const getChartOption = (chartData?: V2BsaDataPredictPowerPostResponse) => {
  // 预测发电功率
  let powerPredictData = (chartData?.powerPredictDataList ?? []).map(item => [
    dayjs(item.ts).valueOf(),
    keepTwoNull(item.value),
  ]);
  // 逆变器实际发电功率
  let inverterActualData = (chartData?.inverterActualDataList ?? []).map(item => [
    dayjs(item.ts).valueOf(),
    keepTwoNull(item.value),
  ]);
  // 逆变器实际发电功率
  let pccActualData = (chartData?.pccActualDataList ?? []).map(item => [
    dayjs(item.ts).valueOf(),
    keepTwoNull(item.value),
  ]);

  return {
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
      },
      formatter: (items: any) => {
        let { axisValue } = items[0];
        let firstStr = dayjs(axisValue).format('YYYY-MM-DD HH:mm:ss');
        let otherStr = '';
        items.forEach((item: any) => {
          const { seriesName, data } = item;
          const unit = 'kW';
          otherStr += `<div style="margin-top:8px"><span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${
            item.color
          };"></span>${seriesName} : &nbsp;${data[1] || data[1] === 0 ? data[1] + unit : '-'}</div>`;
        });
        return firstStr + otherStr;
      },
    },
    grid: {
      left: 50,
      right: 20,
      top: 60,
      bottom: 40,
      containLabel: true,
    },
    color: ['#00ADFF', '#16DD8E', '#FFCB47'],
    dataZoom: {
      type: 'slider',
      left: 20,
      right: 20,
      height: 20,
      bottom: 10,
      textStyle: {
        fontSize: 10,
      },
    },
    legend: {
      right: 10,
      textStyle: {
        color: 'rgba(255, 255, 255, 0.85)',
      },
      inactiveColor: 'rgba(255, 255, 255, 0.45)',
      itemWidth: 16,
      itemHeight: 4,
      data: [
        {
          name: '预测发电功率',
          icon: 'path://M8.28272133 444.58999537h473.99318214v134.82000926H8.28272133V444.58999537z m533.4413752 0h473.99318214v134.82000926h-473.99318214V444.58999537z',
        },
        {
          name: '逆变器实际发电功率',
          icon: 'path://M0 426.666667h1024v170.666666H0z',
        },
        {
          name: '并网点实际发电功率',
          icon: 'path://M0 426.666667h1024v170.666666H0z',
        },
      ],
    },
    xAxis: [
      {
        type: 'time',
        axisLabel: {
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
          formatter: function (e: number) {
            return dayjs(e, 'x').format('YYYY-MM-DD[\n]HH:mm');
          },
        },
        axisPointer: {
          type: 'shadow',
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: '功率（kW）',
        axisLabel: {
          formatter: '{value}',
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
        },
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#66686A',
          },
        },
      },
    ],
    series: [
      {
        name: '预测发电功率',
        type: 'line',
        data: powerPredictData,
        connectNulls: true,
        symbol: 'circle',
        symbolSize: 2,
        showSymbol: false, // 只有在 tooltip hover 的时候显示symbol
        itemStyle: {
          // symbol样式
          normal: {
            borderColor: 'rgba(255, 255, 255, 0.2)', // symbol边框颜色
            borderWidth: 6, // symbol边框宽度
          },
        },
        lineStyle: {
          width: 2,
          type: 'dashed', //'dotted'点型虚线 'solid'实线 'dashed'线性虚线
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: 'rgba(0,173,255,0.1)' },
            { offset: 1, color: 'rgba(0,173,255,0)' },
          ]),
        },
      },
      {
        name: '逆变器实际发电功率',
        type: 'line',
        data: inverterActualData,
        connectNulls: true,
        symbol: 'circle',
        symbolSize: 2,
        showSymbol: false, // 只有在 tooltip hover 的时候显示symbol
        itemStyle: {
          // symbol样式
          normal: {
            borderColor: 'rgba(255, 255, 255, 0.2)', // symbol边框颜色
            borderWidth: 6, // symbol边框宽度
          },
        },
      },
      {
        name: '并网点实际发电功率',
        type: 'line',
        data: pccActualData,
        connectNulls: true,
        symbol: 'circle',
        symbolSize: 2,
        showSymbol: false, // 只有在 tooltip hover 的时候显示symbol
        itemStyle: {
          // symbol样式
          normal: {
            borderColor: 'rgba(255, 255, 255, 0.2)', // symbol边框颜色
            borderWidth: 6, // symbol边框宽度
          },
        },
      },
    ],
  };
};
