import { BsaTitileByType } from '@/pages/Bsa/factory';
import { BsaType } from '@/pages/BsaHistoryData/const';
import { FileSearchOutlined } from '@ant-design/icons';
import { Empty, Paging, usePaging, useUpdate } from '@maxtropy/components';
import {
  apiV2NeoBsaGetBmsTabPost,
  apiV2NeoBsaRealTimeDataPost,
  V2NeoBsaGetBmsTabPostResponse,
} from '@maxtropy/dmes-apis-v2';
import { useRequest } from 'ahooks';
import { Pagination } from 'antd';
import { chunk } from 'lodash-es';

import { Key, useContext, useEffect, useMemo, useState } from 'react';
import { ShareContext } from '../..';
import { RealData } from '../../type';

import DeviceTabs from '../Battery/DeviceTabs';

import CardItem from './CardItem';

import styles from './index.module.scss';
type TabItem = Exclude<V2NeoBsaGetBmsTabPostResponse['list'], undefined>[number];
const Temper = () => {
  const [tabActiveKey, setTabActiveKey] = useState<string>();

  const { bsaId, bsaType, dataType } = useContext(ShareContext);
  // 后端不支持分页, 前端处理
  const pagingInfo = usePaging(3);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [realData, setRealData] = useState<RealData>();
  const [tabsList, setTabsList] = useState<TabItem[]>();
  useEffect(() => {
    if (!bsaId || !dataType) return;
    if (bsaType === BsaType.NEW) {
      apiV2NeoBsaGetBmsTabPost({ bsaId, bsaType }).then(({ list }) => {
        setTabsList(list);
        if (list && list.length) {
          setTabActiveKey(String(list[0].objectId));
          return;
        }
        setTabActiveKey(undefined);
      });
    }
  }, [bsaId, bsaType, dataType]);

  useEffect(() => {
    if (!bsaId || !dataType) return;
    if (bsaType === BsaType.OLD) {
      apiV2NeoBsaRealTimeDataPost({
        bsaId,
        bsaType,
        dataType,
      }).then(res => {
        setTotalCount(res.list?.length ?? 0);
        setRealData(res.list ?? []);
      });
    }
  }, [bsaId, bsaType, dataType]);

  useEffect(() => {
    if (tabActiveKey) {
      apiV2NeoBsaRealTimeDataPost({
        bsaId,
        bsaType,
        dataType,
        objectId: tabActiveKey,
      }).then(res => {
        setTotalCount(res.list?.length ?? 0);
        setRealData(res.list ?? []);
      });
    } else {
      setRealData([]);
    }
  }, [tabActiveKey]);

  const currentPageData = useMemo(() => {
    let temp = chunk(realData, pageSize);
    return temp[pageOffset - 1];
  }, [pageOffset, pageSize, realData]);

  return (
    <div className={styles.page_box}>
      <div className={styles.tabs_box}>
        {bsaType === BsaType.NEW && tabsList && tabsList?.length > 0 ? (
          <DeviceTabs options={tabsList} activeKey={tabActiveKey} onChange={setTabActiveKey} />
        ) : (
          <></>
        )}
      </div>

      <div className={styles.scroll_area}>
        {realData?.length === 0 && (
          <div className={styles.empty_box}>
            <Empty />
          </div>
        )}

        <div className={styles.scroll_box}>
          {currentPageData?.map(item => (
            <CardItem data={item} key={item.deviceId} />
          ))}
        </div>
      </div>
      <div className={styles.pagination}>
        <Paging pagingInfo={pagingInfo} pageSizeOptions={['3', '5']} />
      </div>
    </div>
  );
};

export default Temper;
