export enum ElecType {
  Inverter = 1,
  Consolidation_point = 2,
  Consumption = 3,
  Internet = 4,
}

export const ElecTypeDisplay = {
  [ElecType.Inverter]: '逆变器总发电量',
  [ElecType.Consolidation_point]: '并网点总发电量',
  [ElecType.Consumption]: '消纳电量',
  [ElecType.Internet]: '上网电量',
};
