import classnames from 'classnames/bind';
import styles from './index.module.scss';
import { Spin } from 'antd';

const cx = classnames.bind(styles);

interface BorderWrapperProps {
  isLoading?: boolean;
  children?: React.ReactNode;
}

const BorderWrapper: React.FC<BorderWrapperProps> = ({ isLoading = false, children }) => {
  return (
    <Spin spinning={isLoading}>
      <div className={cx('border-wrapper')}>
        <div className={cx('border-wrapper-inner')}>{children}</div>
      </div>
    </Spin>
  );
};

export default BorderWrapper;
