import React from 'react';
import { App } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import styles from './index.module.scss';
import { CircuitLineLossDataQuery, CircuitLineLossDataType } from '@/api/circuit-line-loss';
import { DatePicker, Radio } from '@maxtropy/components';

const { RangePicker } = DatePicker;

const DATA_TYPE_DISPLAY = {
  [CircuitLineLossDataType.HOUR]: '按日统计',
  [CircuitLineLossDataType.DAY]: '按月统计',
  [CircuitLineLossDataType.MONTH]: '按年统计',
};

export interface PickerProps extends CircuitLineLossDataQuery {
  onChange: (params: Partial<CircuitLineLossDataQuery>) => void;
}

const Picker: React.FC<PickerProps> = ({ dataType, from, to, onChange }) => {
  const disabledDate = (current: Dayjs) => {
    return current && current.valueOf() >= dayjs().endOf('day').valueOf();
    // || current.valueOf() < dayjs(start).startOf('day').valueOf());
  };
  const { message } = App.useApp();

  return (
    <div className={styles.picker}>
      <Radio.Group
        buttonStyle="solid"
        value={dataType}
        onChange={e => {
          let from = 0;
          let to = 0;
          if (e.target.value === CircuitLineLossDataType.HOUR) {
            from = dayjs().subtract(6, 'days').startOf('day').valueOf();
            to = dayjs().endOf('day').valueOf();
          } else if (e.target.value === CircuitLineLossDataType.DAY) {
            from = dayjs().startOf('month').valueOf();
            to = dayjs().endOf('month').valueOf();
          } else {
            from = dayjs().startOf('year').valueOf();
            to = dayjs().endOf('year').valueOf();
          }
          onChange({ dataType: e.target.value, from, to });
        }}
      >
        {Object.entries(DATA_TYPE_DISPLAY).map(([k, v]) => (
          <Radio.Button key={k} value={+k}>
            {v}
          </Radio.Button>
        ))}
      </Radio.Group>

      <div className={styles.datePickerArea}>
        <span className={styles.label}>选择日期</span>
        {dataType === CircuitLineLossDataType.HOUR ? (
          <RangePicker
            value={[dayjs(from), dayjs(to)]}
            disabledDate={disabledDate}
            onChange={e => {
              const value = e as [dayjs.Dayjs, dayjs.Dayjs];
              let from = 0;
              let to = 0;
              if (dataType === CircuitLineLossDataType.HOUR) {
                if (value[1].endOf('day').diff(value[0].startOf('day'), 'days', true) > 31) {
                  return message.error('最大跨度为31天');
                }
                from = value[0].startOf('day').valueOf();
                to = value[1].endOf('day').valueOf();
              } else if (dataType === CircuitLineLossDataType.DAY) {
                if (value[1].endOf('day').diff(value[0].startOf('day'), 'days', true) > 31) {
                  return message.error('最大跨度为1个月');
                }
                from = value[0].startOf('day').valueOf();
                to = value[1].endOf('day').valueOf();
              } else {
                if (value[1].endOf('month').diff(value[0].startOf('month'), 'years', true) > 1) {
                  return message.error('最大跨度为1年');
                }
                from = value[0].startOf('month').valueOf();
                to = value[1].endOf('month').valueOf();
              }
              onChange({ from, to });
            }}
            picker={'date'}
            allowClear={false}
          />
        ) : dataType === CircuitLineLossDataType.DAY ? (
          <DatePicker
            value={dayjs(to)}
            disabledDate={disabledDate}
            picker={'month'}
            allowClear={false}
            onChange={value => {
              const from = value!.startOf('month').valueOf();
              const to = value!.endOf('month').valueOf();
              onChange({ from, to });
            }}
          />
        ) : (
          <DatePicker
            value={dayjs(to)}
            disabledDate={disabledDate}
            picker={'year'}
            allowClear={false}
            onChange={value => {
              const from = value!.startOf('year').valueOf();
              const to = value!.endOf('year').valueOf();
              onChange({ from, to });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Picker;
