import { BsaType } from '@/pages/BsaHistoryData/const';
import { fetch } from '@maxtropy/components';

export interface StationInfo {
  id: number;
  name: string;
  value: number;
}

export interface BsaDataStaticsGisRes {
  num?: number; //站点总数
  installedCapacity?: number; //总装机容量
  ratedPower?: number; //总额定功率
  thisMonthIncome?: number; //本月收益
  thisMonthIncomeRate?: number; //本月收益环比
  thisYearIncome?: number; //本年收益
  thisYearIncomeRate?: number; //本年收益环比
  totalIncome?: number; //总收益
  lastDaySystemEfficiency?: number; //昨日系统效率
  thisMonthSystemEfficiency?: number; //当月系统效率
  thisYearSystemEfficiency?: number; //当年系统效率
  thisMonthCharge?: number;
  thisMonthDischarge?: number; //本月放电量
  thisYearCharge?: number; //本年充电量
  thisYearDischarge?: number; //本年放电量
  totalCharge?: number; //累计充电量
  totalDischarge?: number; //累计放电量
  monthIncomeSort?: MonthIncomeSort[]; //月度收益排名
  list?: StationList[];
  thisMonthMax: StationInfo; //本月最高站点
  thisMonthMin: StationInfo; //本月最低站点
  lastDayMax: StationInfo; //昨日最高站点
  lastDayMin: StationInfo; //昨日最低站点
}

export interface MonthIncomeSort {
  id: number;
  rankIndex: JSX.Element;
  name?: string;
  income?: number;
}

export enum StationStatus {
  DISCHARGE = -1,
  STANDBY = 0,
  CHARGE = 1,
}

export const StationStatusDisplay = {
  [StationStatus.DISCHARGE]: '放电',
  [StationStatus.STANDBY]: '待机',
  [StationStatus.CHARGE]: '充电',
};

export interface StationList {
  id: number;
  name?: string; //储能站名称
  status?: StationStatus; //储能站状态。0:待机，-1：放电；1：充电,null:不显示
  installedCapacity?: number; //装机容量
  ratedPower?: number; //额定功率
  location?: string; //区域编码
  address?: string; //详细地址
  adcode?: string; //高德地图区域编码
  latitude?: number;
  longitude?: number;
  socUpper?: number; //SOC上限
  socLower?: number; //SOC下限
  monthSystemEfficiency?: number; //月均系统效率
  thisDayChargeCapacity?: number; //今日可充电量
  thisDayDischargeCapacity?: number; //今日可放电量
  thisDayCharge?: number; //今日充电量
  thisDayDischarge?: number; //今日放电量
  lastDayCharge?: number; //昨日充电量
  lastDayDischarge?: number; //昨日放电量
  lastDayIncome?: number; //昨日收益
  thisMonthIncome?: number; //本月收益
  totalIncome?: number; //总收益
  thisMonthIncomeRate?: number; //本月收益环比
  totalCharge?: number; //总充电量
  totalDischarge?: number; //总放电量
  lnglat?: any[]; //经纬度
  stackCount?: number; // 电池堆soc数量
  type?: BsaType;
}

export const getBsaDataStaticsGis = () =>
  fetch<BsaDataStaticsGisRes>(
    `/api/bsaData/statics/gis`
    // `http://10.50.16.213:40001/mock/283/api/bsaData/statics/gis`
  );

export interface GisConfigRes {
  key: string;
  securityJsCode: string;
  mapStyle: string;
}

export const getGisConfig = () => fetch<GisConfigRes>(`/api/gis/config`);
