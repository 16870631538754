import {
  AlarmRuleStatus,
  changeAlarmRuleStatus,
  formateAlarmRuleStatus,
  getLossAlarmRulePage,
  LossAlarmRulesRes,
} from '@/api/lineLoss';
import {
  Form,
  Input,
  PopConfirm,
  Select,
  EllipsisSpan,
  Paging,
  Table,
  useBreadcrumbRoutes,
  usePaging,
  Wrapper,
  CustomFilter,
  Button,
} from '@maxtropy/components';
import { Space } from 'antd';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { fetchOuList, getOuId } from '@/api/ou';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/hooks';
import styles from './index.module.scss';
import { PlusOutlined } from '@ant-design/icons';

const columns = [
  {
    title: '规则编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '回路',
    dataIndex: 'circuitName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ?? <span className={styles.red}>回路被删除</span>} />,
  },
  {
    title: '规则名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属运营单元',
    dataIndex: 'ouName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: AlarmRuleStatus) => <EllipsisSpan value={formateAlarmRuleStatus[v]} />,
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'updateByUsername',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];
interface SearchParamsProps {
  ouId?: number | string;
  ruleName?: string;
  circuitName?: string;
  status?: AlarmRuleStatus;
}

const AlarmList: React.FC = () => {
  const routesContext = useBreadcrumbRoutes();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParamsProps>();

  const { data, isLoading, refetch } = useQuery(
    ['alarmRulePage', pageOffset, pageSize, searchParams, setTotalCount],
    async () => {
      const res = await getLossAlarmRulePage({ ...searchParams, page: pageOffset, size: pageSize });
      if (!res) return [];
      setTotalCount(res.total);
      return res.list;
    }
  );

  const { data: ouList } = useQuery('ouList', async () => {
    const ouType = (await getOuId())?.filter(i => i.key === 'MICRONET')[0]?.id;
    if (!ouType) return [];
    return fetchOuList(ouType);
  });
  const ouOptions = useMemo(() => {
    if (ouList && ouList.length !== 0) {
      return ouList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [ouList]);

  const hasCreatePermission = useHasPermission(PermissionsType.B_LINE_LOSS_ALARM_RULE_ADD);
  const hasCreateMorePermission = useHasPermission(PermissionsType.B_LINE_LOSS_ALARM_RULE_ADD_MORE);
  const hasEnablePermission = useHasPermission(PermissionsType.B_LINE_LOSS_ALARM_ENABLE);
  const hasDeletePermission = useHasPermission(PermissionsType.B_LINE_LOSS_ALARM_REMOVE);
  const hasEditPermission = useHasPermission(PermissionsType.B_LINE_LOSS_ALARM_EDIT);

  const actionColumn = {
    title: '操作',
    width: 200,
    fixed: 'right' as const,
    render: (v: LossAlarmRulesRes) => (
      <Space size={16}>
        {hasDeletePermission && (
          <PopConfirm
            title={
              <>
                <div>确定删除？</div>
                <div>
                  <span className={styles.red}>删除后不可恢复</span>, 你还要继续吗?
                </div>
              </>
            }
            onConfirm={() => {
              changeAlarmRuleStatus(v.id, AlarmRuleStatus.DELETE).then(() => {
                queryClient.invalidateQueries('alarmRulePage');
              });
            }}
            okText="确定"
            cancelText="取消"
            disabled={v.status === AlarmRuleStatus.ENABLE}
          >
            <Button type="link" disabled={v.status === AlarmRuleStatus.ENABLE}>
              删除
            </Button>
          </PopConfirm>
        )}
        {hasEnablePermission && v.status === AlarmRuleStatus.ENABLE && (
          <PopConfirm
            title={
              <>
                <div>确定禁用？</div>
                <div>
                  <span className={styles.red}>冻结后该报警规则不再生效</span>, 你还要继续吗？
                </div>
              </>
            }
            onConfirm={() => {
              changeAlarmRuleStatus(v.id, AlarmRuleStatus.DISBALE).then(() => {
                queryClient.invalidateQueries('alarmRulePage');
              });
            }}
            okText="确定"
            cancelText="取消"
          >
            <Button type="link">禁用</Button>
          </PopConfirm>
        )}
        {hasEnablePermission && v.status === AlarmRuleStatus.DISBALE && (
          <Button
            type="link"
            onClick={() => {
              changeAlarmRuleStatus(v.id, AlarmRuleStatus.ENABLE).then(() => {
                queryClient.invalidateQueries('alarmRulePage');
              });
            }}
          >
            启用
          </Button>
        )}
        {hasEditPermission && (
          <Button
            type="link"
            onClick={() => {
              window.open(`/dmes/lineLoss/alarmRules/${v.id}/edit`, '_self');
            }}
          >
            编辑
          </Button>
        )}
        <Button
          type="link"
          onClick={() => {
            window.open(`/dmes/lineLoss/alarmRules/${v.id}/view`, '_self');
          }}
        >
          查看
        </Button>
      </Space>
    ),
  };

  const filters = (
    <CustomFilter
      form={form}
      onFinish={(values: any) => {
        setSearchParams(values);
        setPageOffset(1);
        refetch();
      }}
      onReset={() => {
        setSearchParams(undefined);
        setPageOffset(1);
        refetch();
      }}
    >
      <Form.Item name="ruleName" label="规则名称">
        <Input placeholder="请输入规则名称" />
      </Form.Item>
      <Form.Item name="circuitName" label="回路名称">
        <Input placeholder="请输入回路名称" />
      </Form.Item>
      <Form.Item name="status" label="状态">
        <Select placeholder="请选择">
          <Select.Option value={AlarmRuleStatus.DISBALE}>
            {formateAlarmRuleStatus[AlarmRuleStatus.DISBALE]}
          </Select.Option>
          <Select.Option value={AlarmRuleStatus.ENABLE}>{formateAlarmRuleStatus[AlarmRuleStatus.ENABLE]}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="ouId" label="所属运营单元">
        <Select placeholder="请选择" options={ouOptions} showSearch optionFilterProp="label" />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper routes={routesContext?.routes} filters={filters}>
      <Space style={{ marginBottom: 10 }} size={8}>
        {hasCreatePermission && (
          <Button
            type="primary"
            onClick={() => {
              window.open(`/dmes/lineLoss/alarmRules/create`, '_self');
            }}
          >
            <PlusOutlined />
            新建规则
          </Button>
        )}
        {hasCreateMorePermission && (
          <Button
            type="primary"
            onClick={() => {
              window.open(`/dmes/lineLoss/alarmRules/createMore`, '_self');
            }}
          >
            <PlusOutlined />
            批量新建规则
          </Button>
        )}
      </Space>
      <Table
        sticky
        rowKey="id"
        scroll={{ x: 1500 }}
        columns={[...columns, actionColumn]}
        dataSource={data}
        loading={isLoading}
      />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default AlarmList;
