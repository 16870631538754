import React, { FC } from 'react';
import CardBox from '../../../CardBox';
import styles from './index.module.scss';
import { Progress } from 'antd';
import file from '../../../../imgs/file.png';
import { V2PvNoticeboardIndicatorPostResponse } from '@maxtropy/dmes-apis-v2';
import { keepPercentTwoNull, keepTwoOrLine } from '@/pages/PvDemonstrationBase/utils';
import { Tooltip } from '@maxtropy/components';
import { isNil } from 'lodash-es';

export interface IEfficiencyAnalysis {
  data?: V2PvNoticeboardIndicatorPostResponse;
}

const EfficiencyAnalysis: FC<IEfficiencyAnalysis> = props => {
  const { data } = props;
  return (
    <CardBox title="效率分析">
      <div className={styles.top}>
        <div className={styles.top_each}>
          <div className={styles.process}>
            <Progress
              status="active"
              percent={
                !isNil(data?.avgConversionEfficiencyOfInverter)
                  ? Number(data?.avgConversionEfficiencyOfInverter?.toFixed(2))
                  : undefined
              }
              strokeColor={{ '0%': '#51DBFF', '100%': '#51A1FF' }}
              trailColor="#214172"
              showInfo={false}
            />
          </div>
          <div className={styles.label_value}>
            <span className={styles.label}>逆变器平均转换效率:</span>
            <Tooltip
              getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
              title={
                (!isNil(data?.avgConversionEfficiencyOfInverter)
                  ? Number(data?.avgConversionEfficiencyOfInverter?.toFixed(2))
                  : '--') + '%'
              }
            >
              <span className={styles.value}>
                {!isNil(data?.avgConversionEfficiencyOfInverter)
                  ? Number(data?.avgConversionEfficiencyOfInverter?.toFixed(2))
                  : '--'}
                %
              </span>
            </Tooltip>
          </div>
        </div>
        <div className={styles.top_each}>
          <div className={styles.process}>
            <Progress
              status="active"
              percent={keepPercentTwoNull(data?.gridConnectionEfficiency) ?? undefined}
              strokeColor={{ '0%': '#51DBFF', '100%': '#51A1FF' }}
              trailColor="#214172"
              showInfo={false}
            />
          </div>
          <div className={styles.label_value}>
            <span className={styles.label}>并网效率:</span>
            <Tooltip
              title={(keepPercentTwoNull(data?.gridConnectionEfficiency) ?? '--') + '%'}
              getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
            >
              <span className={styles.value} style={{ width: '50%' }}>
                {keepPercentTwoNull(data?.gridConnectionEfficiency) ?? '--'}%
              </span>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.authCharge_box}>
          <div className={styles.label}>
            <img className={styles.icon} src={file} alt="" />
            <span>理论发电量</span>
          </div>
          <Tooltip
            title={(keepTwoOrLine(data?.theoreticalPowerGeneration) ?? '--') + 'kWh'}
            getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
          >
            <div className={styles.value}>{keepTwoOrLine(data?.theoreticalPowerGeneration)}kWh</div>
          </Tooltip>
        </div>
        <div className={styles.bottom_percent_box}>
          <div className={styles.top_each}>
            <div className={styles.process}>
              <Progress
                status="active"
                percent={keepPercentTwoNull(data?.arraySystemEfficiency) ?? undefined}
                strokeColor={{ '0%': '#51DBFF', '100%': '#51A1FF' }}
                trailColor="#214172"
                showInfo={false}
              />
            </div>
            <div className={styles.label_value}>
              <span className={styles.label}>阵列系统效率:</span>
              <Tooltip
                title={(keepPercentTwoNull(data?.arraySystemEfficiency) ?? '--') + '%'}
                getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
              >
                <span className={styles.value}>{keepPercentTwoNull(data?.arraySystemEfficiency) ?? '--'}%</span>
              </Tooltip>
            </div>
          </div>
          <div className={styles.top_each}>
            <div className={styles.process}>
              <Progress
                status="active"
                percent={keepPercentTwoNull(data?.allPowerGenerationEfficiency) ?? undefined}
                strokeColor={{ '0%': '#51DBFF', '100%': '#51A1FF' }}
                trailColor="#214172"
                showInfo={false}
              />
            </div>
            <div className={styles.label_value}>
              <span className={styles.label}>整体发电效率:</span>
              <Tooltip
                title={(keepPercentTwoNull(data?.allPowerGenerationEfficiency) ?? '--') + '%'}
                getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
              >
                <span className={styles.value}>{keepPercentTwoNull(data?.allPowerGenerationEfficiency) ?? '--'}%</span>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </CardBox>
  );
};

export default EfficiencyAnalysis;
