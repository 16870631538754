import React, { useContext, useEffect, useMemo, useState } from 'react';
import { PvForecastContext } from '../..';
import { Space } from 'antd';
import { DatePicker, Form, Select, TenantType, useCurrent } from '@maxtropy/components';
import { useRequest } from 'ahooks';
import { apiV2PvStationPvTreePost } from '@maxtropy/dmes-apis-v2';
import dayjs from 'dayjs';
import { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';

const TopFilter = () => {
  const pvContext = useContext(PvForecastContext);
  const isChannel = useCurrent()?.tenant?.tenantType === TenantType.CHANNEL;
  const [tenantId, setTenantId] = useState<string>();

  // 租户列表
  const { data: pvTree } = useRequest(async () => {
    const res = await apiV2PvStationPvTreePost({});
    const list = res.list ?? [];
    // 三级结构重组为二级结构
    const newList = list.map(item => {
      let pvList: { pvId?: number; pvName?: string; createTime?: string }[] = [];
      item.pvCustomerTree?.forEach(customer => {
        pvList = pvList.concat(customer.pvTree ?? []);
      });
      pvList = pvList.sort((a, b) => {
        return dayjs(b.createTime).valueOf() - dayjs(a.createTime).valueOf();
      });
      return {
        ...item,
        pvList: pvList,
      };
    });
    // 设置默认值
    if (newList[0]?.pvList?.[0]) {
      setTenantId(newList[0].tenantMcid);
      pvContext.setStationId?.(newList[0].pvList[0].pvId);
    }
    return newList;
  });

  // 租户选择
  const onTenantChange = (value: string) => {
    setTenantId(value);
    const found = pvTree?.find(item => item.tenantMcid === value);
    for (const customer of found?.pvCustomerTree ?? []) {
      const pvList = customer.pvTree ?? [];
      if (pvList.length > 0) {
        pvContext.setStationId?.(pvList[0].pvId);
        return;
      }
    }
  };

  // 光伏站列表
  const pvList = useMemo(() => {
    const found = pvTree?.find(item => item.tenantMcid === tenantId);
    return found?.pvList;
  }, [tenantId, pvTree]);

  // 不可选日期
  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current >= dayjs().startOf('day');
  };

  // 日期选择
  const onDateChange: DatePickerProps['onChange'] = date => {
    if (date) {
      pvContext.setDate?.(date);
    }
  };

  return (
    <Space>
      <Form isOrigin layout="inline" style={{ marginBottom: 10 }}>
        {isChannel && (
          <Form.Item label="租户" style={{ marginRight: 24 }}>
            <Select
              style={{ width: 200 }}
              options={pvTree?.map(item => ({
                label: item.tenantName,
                value: item.tenantMcid,
              }))}
              value={tenantId}
              onChange={onTenantChange}
            />
          </Form.Item>
        )}
        <Form.Item label="光伏站">
          <Select
            style={{ width: 200 }}
            options={pvList?.map(item => ({
              label: item.pvName,
              value: item.pvId,
            }))}
            value={pvContext.stationId}
            onChange={value => pvContext.setStationId?.(value)}
          />
        </Form.Item>
        <Form.Item label="预测日期">
          <DatePicker
            style={{ width: 320 }}
            disabledDate={disabledDate}
            onChange={onDateChange}
            picker="date"
            value={pvContext.date}
          />
        </Form.Item>
      </Form>
    </Space>
  );
};

export default TopFilter;
