import { Modal } from '@maxtropy/components';
import { apiV2BsaSecondEditionDistributeLogListPost } from '@maxtropy/dmes-apis-v2';
import { useRequest } from 'ahooks';
import React, { useCallback } from 'react';
import styles from './index.module.scss';
import { List, Typography } from 'antd';
import dayjs from 'dayjs';

export interface ApplyLogModalProps {
  id?: number;
  visible: boolean;
  onCancel: () => void;
}

const { Text } = Typography;

const ApplyLogModal: React.FC<ApplyLogModalProps> = ({ id, visible, onCancel }) => {
  const { data, loading: isLoading } = useRequest(
    () =>
      apiV2BsaSecondEditionDistributeLogListPost({
        id,
      }).then(res => {
        return res.list;
      }),
    {
      ready: !!id && visible,
      refreshDeps: [id, visible],
    }
  );

  return (
    <Modal destroyOnClose title="数采下发记录" open={visible} footer={null} onCancel={onCancel}>
      <div className={styles.applyLog}>
        <List
          loading={isLoading}
          dataSource={data}
          renderItem={item => (
            <List.Item>
              <span>【{item.operator}】</span>
              在&nbsp;&nbsp;<span>{dayjs(item.createTime).format('YYYY-MM-DD HH:mm:ss')}</span>&nbsp;&nbsp;下发了配置，
              下发状态：<Text type={item.result ? 'success' : 'danger'}>{item.result ? '成功' : '失败'}</Text>
            </List.Item>
          )}
        />
      </div>
    </Modal>
  );
};

export default ApplyLogModal;
