import { isNil } from 'lodash-es';

// 格式化
export const formateValuesFixTwo = (value: number | undefined | null) => {
  return !isNil(value) ? Number(value.toFixed(2)) : '--';
};

export const formateValuesFixTwoPerent = (value: number | undefined | null) => {
  return !isNil(value) ? (Number(value) * 100).toFixed(2) : '--';
};

export const formateValuesFixOne = (value: number | undefined | null) => {
  return !isNil(value) ? Number(value.toFixed(1)) : '--';
};

// Gwh
export const GwhFormat = (value: number | undefined | null) => {
  if (undefined === value || null === value) return { value: '--', unit: '--' };
  if (typeof value === 'string') value = Number(value);
  if (value > 1000 * 1000) {
    return {
      value: `${(value / (1000 * 1000)).toLocaleString('zh', {
        maximumFractionDigits: 1,
      })}`,
      unit: 'GWh',
    };
  }
  if (value > 1000) {
    return {
      value: `${(value / 1000).toLocaleString('zh', {
        maximumFractionDigits: 1,
      })}`,
      unit: 'MWh',
    };
  }
  return {
    value: `${value.toLocaleString('zh', { maximumFractionDigits: 1 })}`,
    unit: 'kWh',
  };
};

// GW
export const GWFormat = (value: number | undefined | null) => {
  if (undefined === value || null === value) return { value: '--', unit: '--' };
  if (typeof value === 'string') value = Number(value);
  if (value > 1000 * 1000) {
    return {
      value: `${(value / (1000 * 1000)).toLocaleString('zh', {
        maximumFractionDigits: 1,
      })}`,
      unit: 'GW',
    };
  }
  if (value > 1000) {
    return {
      value: `${(value / 1000).toLocaleString('zh', {
        maximumFractionDigits: 1,
      })}`,
      unit: 'MW',
    };
  }
  return {
    value: `${value.toLocaleString('zh', { maximumFractionDigits: 1 })}`,
    unit: 'kW',
  };
};
