import { getOuIncomeInfo, OuIncomeInfoReq, OuIncomeInfoRes } from '@/api/EnergyAssetProjectIncome';
import { OuList } from '@/api/ou';
import BorderWrapper from '@/components/BorderWrapper';
import DragResize from '@/components/DragResize';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Breadcrumb, Empty, useBreadcrumbRoutes } from '@maxtropy/components';
import { Col, Layout, Row, Spin } from 'antd';
import dayjs from 'dayjs';
import { createContext, Key, useEffect, useMemo, useState } from 'react';
import DateSwitch, { StatisticsPartition } from './components/DateSwitch';
import OrgOuTree from './components/OrgOuTree';
import styles from './index.module.scss';
import projectTotalIncomeImg from './imgs/projectTotalIncome.png';
import projectDayIncomeImg from './imgs/projectDayIncome.png';
import dayMaxIncomeImg from './imgs/dayMaxIncome.png';
import dayMinIncomeImg from './imgs/dayMinIncome.png';
import { formateValues } from './utils';
import ProjectTotalIncome from './components/ProjectTotalIncome';
import IncomeRate from './components/IncomeRate';
import { getEnergyAssetStationCount, StationCountRes } from '@/api/energyAssetOverview';
import userOutlined from './imgs/user-outlined.png';
import locationOutlined from './imgs/location-outlined.png';

const { Content, Sider } = Layout;

export const EnergyAssetIncome = createContext({} as OuIncomeInfoReq | undefined);

const EnergyAssetProjectIncome = () => {
  const routesContext = useBreadcrumbRoutes();
  const [openSider, setOpenSider] = useState(true); // 左侧树形toggle
  const [siderWidth, setSiderWidth] = useState(260); // 初始值
  const [dragStatus, setDragStatus] = useState(false); // 拖拽状态
  const [ouInfo, setOuInfo] = useState<OuList>(); // 树选择信息
  const [dateChangeLoading, setDateChangeLoading] = useState<boolean>(false);
  const [query, setQuery] = useState<OuIncomeInfoReq>();
  const [projectIncomeInfo, setProjectIncomeInfo] = useState<OuIncomeInfoRes>();
  const [stationHasCount, setStationHasCount] = useState<StationCountRes>();
  const [stationNumber, setStationNumber] = useState<number>();

  // 获取光储充的数量状态
  useEffect(() => {
    if (ouInfo && ouInfo.id) {
      getEnergyAssetStationCount({ ouId: ouInfo.id }).then(res => {
        setStationHasCount(res);
      });
    }
  }, [ouInfo]);
  useEffect(() => {
    if (ouInfo) {
      setQuery({
        ouId: ouInfo.id,
        resolution: query?.resolution || StatisticsPartition.DAY,
        startTime:
          query?.startTime || dayjs(dayjs().subtract(1, 'day'), 'x').subtract(30, 'days').startOf('day').valueOf(),
        endTime: query?.endTime || dayjs(dayjs().subtract(1, 'day'), 'x').endOf('day').valueOf(),
      });
    }
  }, [ouInfo]);

  useEffect(() => {
    if (ouInfo && query) {
      setDateChangeLoading(false);
      getOuIncomeInfo({
        ouId: ouInfo.id,
        resolution: query.resolution,
        startTime: query.startTime,
        endTime: query?.endTime,
      }).then(res => {
        setProjectIncomeInfo(res);
        setDateChangeLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  // 拖拽方法
  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  // 选择树回调
  const changeTreeSelect = (_: Key[], info: any) => {
    if (info) {
      setOuInfo(info);
    } else {
      setOuInfo(undefined);
    }
  };

  const onChangeData = (value: any) => {
    if (query) {
      setDateChangeLoading(true);
      setQuery({
        ...query,
        ...value,
      });
    }
  };

  const getStationNumber = (num: number) => {
    setStationNumber(num);
  };

  const judgeDayoRMonth = useMemo(() => {
    return query?.resolution === StatisticsPartition.MONTH ? '月' : '日';
  }, [query]);

  return (
    <div className={styles.page}>
      <div className={styles.breadcrumb}>
        <Breadcrumb routes={routesContext?.routes} />
      </div>

      <BorderWrapper>
        <Layout className={styles.layout}>
          <Sider
            theme="light"
            width={openSider ? siderWidth : 0}
            style={{
              transition: dragStatus ? 'none' : 'all 0.2s',
              background: '#232324',
            }}
          >
            <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
            <OrgOuTree style={{ width: '100%' }} changeTreeSelect={changeTreeSelect} />
          </Sider>
          <Content className={styles.content}>
            <div
              className={styles.toggleBtn}
              style={{ left: openSider ? -30 : 0 }}
              onClick={() => setOpenSider(!openSider)}
            >
              {openSider ? <LeftOutlined /> : <RightOutlined />}
            </div>
            {ouInfo ? (
              <Spin spinning={dateChangeLoading}>
                <div className={styles.selectedOuTitle}>
                  <div className={styles.titleDetails}>
                    <div>项目名称：</div>
                    <span className={styles.ouNameSty}>{projectIncomeInfo?.projectName ?? '--'}</span>
                  </div>
                  <div className={styles.titleDetails}>
                    <img className={styles.imgSty} src={userOutlined} alt="" />
                    <div>负责人：</div>
                    <span className={styles.ouNameSty}>
                      {projectIncomeInfo?.directorName === '' ? '--' : projectIncomeInfo?.directorName ?? '--'}
                    </span>
                  </div>
                  <div className={styles.titleDetails}>
                    <img className={styles.imgSty} src={locationOutlined} alt="" />
                    <div>所在地址：</div>
                    <span className={styles.ouNameSty}>
                      {projectIncomeInfo?.projectAddress === '' ? '--' : projectIncomeInfo?.projectAddress ?? '--'}
                    </span>
                  </div>
                </div>
                <div className={styles.filter}>
                  <DateSwitch
                    selectBtn={query?.resolution!}
                    value={[dayjs(query?.startTime, 'x'), dayjs(query?.endTime, 'x')]}
                    onQueryChange={onChangeData}
                  />
                </div>
                <div className={styles.Info}>
                  <Row className={styles.DashBoardStyle}>
                    <Col className={styles.TopItemInfo} span={6}>
                      <img className={styles.ItemInfoImg} src={projectTotalIncomeImg} alt="" />
                      <div>
                        <div className={styles.TopLabel}>项目总收益（元）</div>
                        <div className={styles.TopValue}>{formateValues(projectIncomeInfo?.projectTotalIncome)}</div>
                      </div>
                    </Col>
                    <Col className={styles.TopItemInfo} span={6}>
                      <img className={styles.ItemInfoImg} src={projectDayIncomeImg} alt="" />
                      <div>
                        <div className={styles.TopLabel}>{judgeDayoRMonth}均收益（元）</div>
                        <div className={styles.TopValue}>{formateValues(projectIncomeInfo?.averageIncomeByDay)}</div>
                      </div>
                    </Col>
                    <Col className={styles.TopItemInfo} span={6}>
                      <img className={styles.ItemInfoImg} src={dayMaxIncomeImg} alt="" />
                      <div>
                        <div className={styles.TopLabel}>单{judgeDayoRMonth}最高收益（元）</div>
                        <div className={styles.TopValue}>{formateValues(projectIncomeInfo?.highestIncomeByDay)}</div>
                      </div>
                    </Col>
                    <Col className={styles.TopItemInfo} span={6}>
                      <img className={styles.ItemInfoImg} src={dayMinIncomeImg} alt="" />
                      <div>
                        <div className={styles.TopLabel}>单{judgeDayoRMonth}最低收益（元）</div>
                        <div className={styles.TopValue}>{formateValues(projectIncomeInfo?.lowestIncomeByDay)}</div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className={styles.bottomContent}>
                  <EnergyAssetIncome.Provider value={query}>
                    <Row gutter={[8, 8]}>
                      <Col
                        span={stationNumber === 1 ? 24 : 15}
                        xl={24}
                        lg={24}
                        md={24}
                        sm={24}
                        xxl={stationNumber === 1 ? 24 : 15}
                      >
                        <ProjectTotalIncome stationHasCount={stationHasCount} />
                      </Col>

                      <Col span={9} xl={24} lg={24} md={24} sm={24} xxl={9}>
                        <IncomeRate getStationNumber={getStationNumber} />
                      </Col>
                    </Row>
                  </EnergyAssetIncome.Provider>
                </div>
              </Spin>
            ) : (
              <div style={{ position: 'relative', height: '89vh', backgroundColor: '#232324' }}>
                <Empty description={'暂无数据'} className={styles.empty} />
              </div>
            )}
          </Content>
        </Layout>
      </BorderWrapper>
    </div>
  );
};

export default EnergyAssetProjectIncome;
