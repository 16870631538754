import { fetch } from '@maxtropy/components';

export interface CustomerUserListProps {
  adCode?: string;
  address?: string;
  code: string;
  createTime: string;
  disabled: boolean;
  fullName: string;
  logoUrl: string;
  mcid: string;
  name: string;
  phone?: string;
  updateTime: string;
}

export const getCustomerUserList = () => fetch<CustomerUserListProps[]>(`/api/customer-user/list`);
