import { Form, Select, TenantType, useBreadcrumbRoutes, useCurrent, Wrapper } from '@maxtropy/components';
import styles from './index.module.scss';
import { useRequest } from 'ahooks';
import { apiV2PvStationPvTreePost } from '@maxtropy/dmes-apis-v2';
import { useMemo, useState } from 'react';
import IndicatorsStatistics from './IndicatorsStatistics';
import TodayData from './TodayData';
import BottomStatistics from './BottomStatistics';
import dayjs from 'dayjs';

const PvRunningOverview = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const isChannel = useCurrent()?.tenant?.tenantType === TenantType.CHANNEL;
  const [tenantId, setTenantId] = useState<string>();
  const [pvId, setPvId] = useState<number>();

  const { data: pvTree } = useRequest(async () => {
    const res = await apiV2PvStationPvTreePost({});
    const list = res.list ?? [];
    // 三级结构重组为二级结构
    const newList = list.map(item => {
      let pvList: { pvId?: number; pvName?: string; createTime?: string }[] = [];
      item.pvCustomerTree?.forEach(customer => {
        pvList = pvList.concat(customer.pvTree ?? []);
      });
      pvList = pvList.sort((a, b) => {
        return dayjs(b.createTime).valueOf() - dayjs(a.createTime).valueOf();
      });
      return {
        ...item,
        pvList: pvList,
      };
    });
    // 设置默认值
    if (newList[0]?.pvList?.[0]) {
      setTenantId(newList[0].tenantMcid);
      setPvId(newList[0].pvList[0].pvId);
    }
    return newList;
  });

  const pvList = useMemo(() => {
    const found = pvTree?.find(item => item.tenantMcid === tenantId);
    return found?.pvList;
  }, [tenantId, pvTree]);

  const onTenantChange = (value: string) => {
    setTenantId(value);
    const found = pvTree?.find(item => item.tenantMcid === value);
    for (const customer of found?.pvCustomerTree ?? []) {
      const pvList = customer.pvTree ?? [];
      if (pvList.length > 0) {
        setPvId(pvList[0].pvId);
        return;
      }
    }
  };

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.wrapper}>
      <Form isOrigin layout="inline" style={{ marginBottom: 16 }}>
        {isChannel && (
          <Form.Item label="租户" style={{ marginRight: 24 }}>
            <Select
              style={{ width: 200 }}
              options={pvTree?.map(item => ({
                label: item.tenantName,
                value: item.tenantMcid,
              }))}
              value={tenantId}
              onChange={onTenantChange}
            ></Select>
          </Form.Item>
        )}
        <Form.Item label="光伏站">
          <Select
            style={{ width: 200 }}
            options={pvList?.map(item => ({
              label: item.pvName,
              value: item.pvId,
            }))}
            value={pvId}
            onChange={value => setPvId(value)}
          ></Select>
        </Form.Item>
      </Form>

      <div
        style={{
          display: 'flex',
          gap: 20,
        }}
      >
        <IndicatorsStatistics pvId={pvId} />
        <TodayData pvId={pvId} />
      </div>

      <div className={styles.splitLine}></div>

      <BottomStatistics pvId={pvId} />
    </Wrapper>
  );
};

export default PvRunningOverview;
