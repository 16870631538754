import { useBreadcrumbRoutes, Wrapper } from '@maxtropy/components';
import React, { createContext, useState } from 'react';
import styles from './index.module.scss';
import TopFilter from './components/TopFilter';
import dayjs, { Dayjs } from 'dayjs';
import PowerForecast from './components/PowerForecast';
import ElecForecast from './components/ElecForecast';
import IncomeForecast from './components/IncomeForecast';

interface PvForecastContextProps {
  stationId?: number;
  setStationId?: React.Dispatch<React.SetStateAction<number | undefined>>;
  setDate?: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>;
  date?: dayjs.Dayjs;
}

export const PvForecastContext = createContext<PvForecastContextProps>({});

const PVPowerForecast = () => {
  const routesContext = useBreadcrumbRoutes();
  const [stationId, setStationId] = useState<number>();
  const [date, setDate] = useState<Dayjs>(dayjs().subtract(1, 'day')); // 时间选择

  return (
    <Wrapper className={styles.wrapper} routes={routesContext?.routes}>
      <PvForecastContext.Provider value={{ stationId, setStationId, date, setDate }}>
        <TopFilter />
        <div className={styles.power_forecast}>
          <PowerForecast />
        </div>
        <div className={styles.elec_forecast}>
          <ElecForecast />
        </div>
        <div className={styles.income_forecast}>
          <IncomeForecast />
        </div>
      </PvForecastContext.Provider>
    </Wrapper>
  );
};

export default PVPowerForecast;
