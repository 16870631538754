import { Space } from 'antd';
import { Input, Paging, Radio, usePaging } from '@maxtropy/components';
import { ThresholdDeviceType, ThresholdDeviceTypeLabel, ThresholdDeviceTypeList } from './const';
import { useState } from 'react';
import PvTable from './PvTable';
import InverterOrComponentTable from './InverterOrComponentTable';

const ThresholdConfig = () => {
  const [type, setType] = useState(ThresholdDeviceType.PV);
  const [objectName, setObjectName] = useState('');
  const pagingInfo = usePaging(20);
  const { setPageOffset } = pagingInfo;

  return (
    <>
      <Space size={8} style={{ marginBottom: 10 }}>
        <Radio.Group
          buttonStyle="solid"
          value={type}
          onChange={e => {
            setType(e.target.value);
            setPageOffset(1);
          }}
        >
          {ThresholdDeviceTypeList.map(key => (
            <Radio.Button key={key} value={key}>
              {ThresholdDeviceTypeLabel[key as ThresholdDeviceType]}
            </Radio.Button>
          ))}
        </Radio.Group>
        <Input.Search
          placeholder={
            type === ThresholdDeviceType.PV
              ? '请输入光伏站名称'
              : type === ThresholdDeviceType.INVERTER
              ? '请输入光伏站或逆变器名称'
              : '请输入逆变器或组串名称'
          }
          allowClear
          style={{ width: 250 }}
          onSearch={value => {
            setObjectName(value);
            setPageOffset(1);
          }}
        />
      </Space>

      {type === ThresholdDeviceType.PV ? (
        <PvTable objectName={objectName} pagingInfo={pagingInfo} />
      ) : (
        <InverterOrComponentTable objectName={objectName} pagingInfo={pagingInfo} type={type} />
      )}

      <Paging pagingInfo={pagingInfo}></Paging>
    </>
  );
};

export default ThresholdConfig;
