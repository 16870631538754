import { V2BsaSecondEditionInfoBsaSecondEditionPostResponse } from '@maxtropy/dmes-apis-v2';
import { createContext, Key } from 'react';
import BasicForm from './components/BasicForm';
import CellForm from './components/CellForm';
import PackForm from './components/PackForm';

import PcsForm from './components/PcsForm';
import RackForm from './components/RackForm';

import StackForm from './components/StackForm';

export enum CreateStatus {
  CREATING, // 生成中
  DONE, // 完成,
  FAIL, // 失败
}
export interface ChildDataRef {
  saveForm: (callback?: () => void) => void;
  saveDraft?: (callback?: () => void) => void;
}
export interface BsaStructProps {}
export enum StructStep {
  DRAFT, // 阵列结构配置
  COMPLETE_ARRAY_BSA_INFO_CONFIG, // 完成阵列基础信息配置
  COMPLETE_PCS_CONFIG, // 完成pcs配置
  COMPLETE_STACK_CONFIG, // 完成电池堆配置
  COMPLETE_RACK_CONFIG, // 完成电池簇配置
  COMPLETE_PACK_CONFIG, // 完成电池组配置
  COMPLETE_ARRAY_STRUCT_CONFIG, // 已完成阵列结构配配置
}
export enum TemplateType {
  RACK = 3,
  PACK = 4,
  CELL = 5,
}
export const templateMap: Record<TemplateType, string> = {
  [TemplateType.RACK]: 'rackDeviceCreateStatus',
  [TemplateType.PACK]: 'packDeviceCreateStatus',
  [TemplateType.CELL]: 'cellDeviceCreateStatus',
};
export const templateNameMap: Record<TemplateType, string> = {
  [TemplateType.RACK]: '电池簇',
  [TemplateType.PACK]: '电池组',
  [TemplateType.CELL]: '电芯',
};
export const DataValueContext = createContext<{
  data?: V2BsaSecondEditionInfoBsaSecondEditionPostResponse;
  isCanEdit?: boolean;
  isView?: boolean;
  nextStep?: () => void;
  prevStep?: () => void;
  saveForm?: () => void;
  saveDraft?: () => void;
  selectStepList?: string[];
  setSelectStepList?: React.Dispatch<React.SetStateAction<string[]>>;
  saveLoading?: boolean;
  setSaveLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  bsaId?: Key;
  refresh?: () => void;
}>({});
// 步骤title
export const stepTileListAll = [
  { title: '阵列基础信息', key: 0 },
  { title: 'PCS', key: 1 },
  { title: '电池堆', key: 2 },
  { title: '电池簇', key: 3, prop: 'hasRack' },
  { title: '电池组', key: 4, prop: 'hasPack' },
  { title: '电芯', key: 5, prop: 'hasCell' },
];
export const getStructureInfo = (hasRack?: boolean, hasPack?: boolean, hasCell?: boolean) => {
  return [
    { prop: 'hasRack', selected: hasRack },
    { prop: 'hasPack', selected: hasPack },
    { prop: 'hasCell', selected: hasCell },
  ]
    .filter(item => item.selected)
    .map(item => item.prop);
};
// 获取顶部步骤条
export const getCurrentStepTileList = (selectStepList: string[]) => {
  return stepTileListAll.filter(item => !item.prop || selectStepList.includes(item.prop));
};
// 每个步骤对应的表单
export const formStep = [BasicForm, PcsForm, StackForm, RackForm, PackForm, CellForm];

// 状态机对应要显示的步骤表单
export const ComponentShow: Partial<
  Record<StructStep, React.ForwardRefExoticComponent<BsaStructProps & React.RefAttributes<ChildDataRef>>>
> = {
  [StructStep.DRAFT]: formStep[0],
  [StructStep.COMPLETE_ARRAY_BSA_INFO_CONFIG]: formStep[1],
  [StructStep.COMPLETE_PCS_CONFIG]: formStep[2],
  [StructStep.COMPLETE_STACK_CONFIG]: formStep[3],
  [StructStep.COMPLETE_RACK_CONFIG]: formStep[4],
  [StructStep.COMPLETE_PACK_CONFIG]: formStep[5],
};
