import { V2PvOutlineAreaStringListPostResponse, V2PvOutlinePvDeviceTreePostResponse } from '@maxtropy/dmes-apis-v2';
import { Node } from '@antv/x6';
import inverterIcon from './Nodes/icons/inverter.png';
import gridIcon from './Nodes/icons/grid.png';
import combinerIcon from './Nodes/icons/conbiner.png';
import connectIcon from './Nodes/icons/connect.png';

// graph连接线的颜色
export enum EdgeColor {
  INITIAL = '#979797',
  SELECTED = '#4A90E2',
  ALARM = '#E64242',
}

// 光伏设备树api中树形结构

export type ApiTreeData = Exclude<V2PvOutlinePvDeviceTreePostResponse['list'], undefined>[number] & {
  extral?: AreaStringItem;
  invertNodeIds?: string[]; // 存储片区关联逆变器节点的id
  children?: ApiTreeData[];
};
export type AreaStringItem = Exclude<V2PvOutlineAreaStringListPostResponse['list'], undefined>[number];
// 画布节点树形结构
export type NodeTreeData = Omit<ApiTreeData, 'childrenList'> & {
  children?: NodeTreeData[];
  id?: string;
  isCollapsed?: boolean;
  extral?: AreaStringItem;
};
// 设备类型
export enum DeviceTypeEnum {
  GRID = 0,
  CONNECT = 1,
  COMBINER = 2,
  INVERTER,
}
// 片区设备类型(自定义的)
export enum CustomDeviceTypeEnum {
  AREA = 999,
  ROOT = 10001,
  VIRTUALGRID = 10002,
}
export const deviceTypeEnumDisplay = {
  [DeviceTypeEnum.GRID]: '电网计费回路',
  [DeviceTypeEnum.CONNECT]: '并网点',
  [DeviceTypeEnum.COMBINER]: '汇流箱',
  [DeviceTypeEnum.INVERTER]: '逆变器',
};

export const deviceTypeNodeShape = {
  [DeviceTypeEnum.GRID]: 'powerGrid',
  [DeviceTypeEnum.CONNECT]: 'connectPoints',
  [DeviceTypeEnum.COMBINER]: 'combinerBox',
  [DeviceTypeEnum.INVERTER]: 'inverter',
  [CustomDeviceTypeEnum.ROOT]: 'virtualRoot',
  [CustomDeviceTypeEnum.VIRTUALGRID]: 'virtualGrid',
};

export const deviceTypeNodeIcon = {
  [DeviceTypeEnum.GRID]: gridIcon,
  [DeviceTypeEnum.CONNECT]: connectIcon,
  [DeviceTypeEnum.COMBINER]: combinerIcon,
  [DeviceTypeEnum.INVERTER]: inverterIcon,
};

export enum NodeSizeEnum {
  COMMON_W = 160,
  COMMON_H = 40,
  AREA_W = 800, // 片区节点的宽
  AREA_H = 184, // 片区节点的高
  V_GAP = 20, // 节点之间垂直距离
  H_GAP = 80, // 节点之间水平距离
}
export interface DataPropertyItem {
  propertyId: number;
  propertyName: string;
  unit: string;
}

export const dataPropertyMap: Record<number, DataPropertyItem[]> = {
  [DeviceTypeEnum.GRID]: [
    {
      propertyId: 10017,
      propertyName: '有功功率',
      unit: 'kW',
    },
    {
      propertyId: 10025,
      propertyName: '无功功率',
      unit: 'kVar',
    },
    {
      propertyId: 10023,
      propertyName: '正向有功电能',
      unit: 'kWh',
    },
    {
      propertyId: 10024,
      propertyName: '反向有功电能',
      unit: 'kWh',
    },
  ],
  [DeviceTypeEnum.CONNECT]: [
    {
      propertyId: 10017,
      propertyName: '有功功率',
      unit: 'kWh',
    },
    {
      propertyId: 10025,
      propertyName: '无功功率',
      unit: 'kVar',
    },
    {
      propertyId: 10023,
      propertyName: '正向有功电能',
      unit: 'kWh',
    },
    {
      propertyId: 10024,
      propertyName: '反向有功电能',
      unit: 'kWh',
    },
  ],
  [DeviceTypeEnum.INVERTER]: [
    {
      propertyId: 10081,
      propertyName: '有功功率',
      unit: 'kW',
    },
    {
      propertyId: 10082,
      propertyName: '无功功率',
      unit: 'kar',
    },
    {
      propertyId: 10089,
      propertyName: '日发电量',
      unit: 'kWh',
    },
  ],
  [DeviceTypeEnum.COMBINER]: [],
  [CustomDeviceTypeEnum.AREA]: [
    {
      propertyId: 10090,
      propertyName: '电压',
      unit: 'V',
    },
    {
      propertyId: 10091,
      propertyName: '电流',
      unit: 'A',
    },
  ],
};
export interface LayoutTreeModel {
  data?: ApiTreeData;
  children?: LayoutTreeModel[];
  height?: number;
  id?: string;
  width?: number;
  x: number;
  y: number;
  preH?: number;
  preV?: number;
  vgap: number;
  hgap: number;
}
