import { BaseInfoContent, Button, Form, ShowInput, SubContent, Tag } from '@maxtropy/components';
import { useRequest } from 'ahooks';
import { apiV2PvDispersionRateGetAnalysisConfigPost } from '@maxtropy/dmes-apis-v2';
import { useNavigate, useParams } from 'react-router-dom';
import { ExcludeType, PvAreaContext, Resolution, ResolutionLabel } from '../const';
import ExcludeTable from '../components/ExcludeTable';
import { Space, Spin } from 'antd';
import { useState } from 'react';

const Detail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [areaIdList, setAreaIdList] = useState<number[]>([]);

  const { data, loading } = useRequest(
    async () => {
      const res = await apiV2PvDispersionRateGetAnalysisConfigPost({ id: id });
      setAreaIdList(res.areaNameList?.map(item => item.areaId!) ?? []);
      return res;
    },
    {
      ready: !!id,
    }
  );

  return (
    <PvAreaContext.Provider
      value={{
        areaIdList: areaIdList,
      }}
    >
      <Spin spinning={loading}>
        <Form>
          <SubContent title="时间段设置">
            <BaseInfoContent layout="horizontal">
              <Form.Item label="设置组名称">
                <ShowInput value={data?.configName} />
              </Form.Item>
              <Form.Item label="分析时段">
                <ShowInput value={data?.analysisStartTime + '~' + data?.analysisEndTime} />
              </Form.Item>
              <Form.Item label="分析颗粒度">
                <ShowInput value={ResolutionLabel[data?.resolution as Resolution]} />
              </Form.Item>
            </BaseInfoContent>
          </SubContent>

          <SubContent title="光伏站-片区设置">
            <Form.Item label="适用光伏站-片区">
              <Space size={8} wrap>
                {data?.areaNameList?.map((item, index) => (
                  <Tag key={index}>
                    {data?.pvStationName}/{item.pvAreaName}
                  </Tag>
                ))}
              </Space>
            </Form.Item>
          </SubContent>
        </Form>

        <SubContent title="排除组串设置">
          <ExcludeTable isEdit={false} excludeType={ExcludeType.STRING} id={+id} />
        </SubContent>

        {!!data?.existComponents && (
          <SubContent title="排除组件设置">
            <ExcludeTable isEdit={false} excludeType={ExcludeType.COMPONENT} id={+id} />
          </SubContent>
        )}
      </Spin>

      <div className="sticky-footer-left" style={{ marginLeft: 32 }}>
        <Button onClick={() => navigate('/pv/dispersionRate/config')}>返回</Button>
      </div>
    </PvAreaContext.Provider>
  );
};

export default Detail;
