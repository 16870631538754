import { FC, useEffect, useMemo } from 'react';

import { Button, Form, Radio, Input, Select, Breadcrumb, useBreadcrumbRoutes, Modal } from '@maxtropy/components';
import BorderWrapper from '../../../../components/BorderWrapper';

import BsaSteps from '../BsaSteps';
import BsaPageHeader from '../BsaPageHeader';

import { Space } from 'antd';

import { useParams } from 'react-router-dom';
import {
  BsaDeviceTypeEnum,
  BSAStatus,
  CrcOrderLabels,
  CRC_ORDER,
  DriveType,
  DriveTypeLabels,
  ProcessStatusEnum,
  TopologicalType,
  TopologicalTypeLabels,
} from '../../../../api/type';
import DividerContent from '../DividerContent';
import ShowInput from '../../../../components/ShowInput';
import { BsaComponentsProps, getNext, getPrevious } from '../../factory';
import { BsaType } from '../../../../api/constans';
import {
  applyEdgeDevicePointTemplate,
  createEdgeDevicetemplate,
  CreateEdgeDevicetemplateRequest,
  getEdgeDevicetemplate,
  getGlobalEdgeConfig,
  cellTemplateStatusCheck,
  submitCellTemplete,
} from '../../../../api/edgeDevice';
import { getDraft, saveDraft } from '../../../../api/bsa';
import { InfoCircleOutlined } from '@ant-design/icons';

const formLayout = {
  labelCol: { flex: '170px' },
  // wrapperCol: { flex: "340px" },
};

// const text = "我确认已经配置完毕本数采模板的虚拟点和静态点"

const BsaCellEdgeTemplate: FC<BsaComponentsProps> = ({
  disabled = false,
  typeList,
  foucsType,
  currentType,
  status,
  changeType,
  updateFn,
}) => {
  const { id } = useParams<{ id: string }>();
  const [templateForm] = Form.useForm();
  const [form] = Form.useForm();

  const routesContext = useBreadcrumbRoutes();

  const routes = [{ name: `电芯数据模板配置` }];

  const isInit = useMemo(() => {
    return (typeList ?? []).includes(BsaType.CELL_EDGE_DEVICE_INIT);
  }, [typeList]);

  const isComplete = useMemo(() => {
    return (typeList ?? []).includes(BsaType.CELL_EDGE_DEVICE_COMPLETED);
  }, [typeList]);

  useEffect(() => {
    if (id) {
      getGlobalEdgeConfig(id).then(res => {
        templateForm.setFieldsValue({
          topologicalType: res.topologicalType,
          driveType: res.driveType,
        });
      });
    }
  }, [id, templateForm]);

  useEffect(() => {
    if (id) {
      getEdgeDevicetemplate({
        bsaId: Number(id),
        type: BsaDeviceTypeEnum.CELL,
      }).then(res => {
        form.setFieldsValue({
          deviceTypeName: res.deviceTypeName,
          modelName: res.modelName,
          driveType: DriveTypeLabels[res.driveType],
        });
        if (isInit) {
          form.setFieldsValue({
            name: res.name,
            crcOrder: CrcOrderLabels[res.crcOrder],
            remark: res.remark,
            code: res.code,
          });
        }
      });
    }
  }, [id, isInit, form]);

  useEffect(() => {
    if (id) {
      if (!isInit && Array.isArray(typeList)) {
        // 未完成，查看是否有草稿
        getDraft(id).then(draft => {
          const content: Partial<Pick<CreateEdgeDevicetemplateRequest, 'name' | 'remark' | 'crcOrder'>> = draft.content
            ? JSON.parse(draft.content)
            : undefined;
          if (content) {
            form.setFieldsValue({
              name: content.name,
              crcOrder: content.crcOrder,
              remark: content.remark,
            });
          }
        });
      }
    }
  }, [id, isInit, form, typeList]);

  const onSubmit = (value: Pick<CreateEdgeDevicetemplateRequest, 'name' | 'remark' | 'crcOrder'>) => {
    if (id) {
      createEdgeDevicetemplate({
        bsaId: Number(id),
        type: BsaDeviceTypeEnum.CELL,
        name: value.name,
        crcOrder: value.crcOrder,
        remark: value.remark,
      }).then(() => {
        // 更新
        updateFn?.();
      });
    }
  };

  const onDraft = () => {
    const value: Partial<Pick<CreateEdgeDevicetemplateRequest, 'name' | 'remark' | 'crcOrder'>> = form.getFieldsValue();
    saveDraft({
      bsaId: Number(id),
      content: JSON.stringify({
        name: value.name,
        crcOrder: value.crcOrder,
        remark: value.remark,
      }),
    }).then(() => {
      Modal.success({
        content: '保存成功',
      });
    });
  };

  // const [modalVisible, setModalVisible] = useState<boolean>(false);
  // const [documentChanged, setDocumentChanged] = useState<boolean>(false);

  // const [textValue, setTextValue] = useState<string>('');

  // useEffect(() => {
  //   const visibleChange = () => {
  //     if (modalVisible) {
  //       if (!document.hidden) {
  //         setDocumentChanged(true);
  //       }
  //     }
  //   }
  //   if (isInit && !isComplete && modalVisible) {
  //     document.addEventListener('visibilitychange', visibleChange)
  //     return () => {
  //       document.removeEventListener('visibilitychange', visibleChange)
  //     }
  //   }

  // }, [modalVisible, isInit, isComplete])

  // useEffect(() => {
  //   if (!modalVisible) {
  //     setDocumentChanged(false);
  //     setTextValue("");
  //   }
  // }, [modalVisible])

  const onPrevious = () => {
    if (Array.isArray(typeList) && foucsType) {
      const type = getPrevious(foucsType, typeList);
      if (type) {
        changeType?.(type);
      }
    }
  };

  const onNext = () => {
    const type = getNext(foucsType!, typeList ?? [], currentType);
    if (type) {
      changeType?.(type);
    }
  };

  const applyTemplate = () => {
    if (id) {
      applyEdgeDevicePointTemplate({
        bsaId: Number(id),
        type: BsaDeviceTypeEnum.CELL,
      }).then(_ => {
        updateFn?.();
      });
    }
  };

  const onRefresh = () => {
    if (id) {
      cellTemplateStatusCheck(id).then(res => {
        if (res.status !== ProcessStatusEnum.GENERATING) {
          updateFn?.();
        }
      });
    }
  };

  const onFinish = () => {
    if (disabled) {
      onNext();
    } else {
      if (currentType === BsaType.CELL_EDGE_DEVICE_COMPLETED) {
        // setModalVisible(true);
        nextFn();
      } else {
        if (isComplete) {
          // 直接下一步
          onNext();
        }
      }
    }
  };

  const nextFn = () => {
    if (id) {
      submitCellTemplete(id).then(() => {
        updateFn?.();
        // setModalVisible(false);
      });
    }
  };

  return (
    <>
      <div className="page">
        <div className="page__header">
          <Breadcrumb routes={[...(routesContext?.routes ?? []), ...routes]} />
        </div>
        <BorderWrapper>
          <div className="page__content" style={{ minHeight: 'calc(100vh - 128px)' }}>
            <BsaPageHeader
              title="电芯数据模板配置"
              extraContent={
                <>
                  <Button type="primary" onClick={onPrevious}>
                    上一步
                  </Button>
                  <Button type="primary" onClick={onFinish}>
                    下一步
                  </Button>
                  {!isInit && status !== BSAStatus.INVALID && (
                    <Button type="primary" onClick={onDraft}>
                      存草稿
                    </Button>
                  )}
                </>
              }
            >
              <BsaSteps typeList={typeList} foucsType={foucsType} />
            </BsaPageHeader>
            <Form form={templateForm} labelAlign="left" labelWrap {...formLayout}>
              <DividerContent>
                <Form.Item label="拓扑构型" name="topologicalType">
                  <Radio.Group buttonStyle="solid" disabled>
                    <Radio.Button value={TopologicalType.PCS_Penetrate_BMS}>
                      {TopologicalTypeLabels[TopologicalType.PCS_Penetrate_BMS]}
                    </Radio.Button>
                    <Radio.Button value={TopologicalType.BMS_SIMULTANEOUSLY_PCS_AND_BAMS}>
                      {TopologicalTypeLabels[TopologicalType.BMS_SIMULTANEOUSLY_PCS_AND_BAMS]}
                    </Radio.Button>
                    <Radio.Button disabled value={3}>
                      EMS同时连接PCS和BCMU
                    </Radio.Button>
                    <Radio.Button disabled value={4}>
                      EMS同时连接PCS和BMU
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="驱动类型" name="driveType">
                  <Radio.Group buttonStyle="solid" disabled>
                    <Radio.Button value={DriveType.MODBUS_TCP}>{DriveTypeLabels[DriveType.MODBUS_TCP]}</Radio.Button>
                    <Radio.Button disabled value={DriveType.IEC104}>
                      {DriveTypeLabels[DriveType.IEC104]}
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </DividerContent>
            </Form>
            <DividerContent title="电芯采集模板基础信息">
              <Form form={form} labelAlign="left" onFinish={onSubmit} labelWrap {...formLayout}>
                <Form.Item name="name" label="模板名称" rules={!isInit ? [{ required: true }, { max: 20 }] : []}>
                  {isInit ? <ShowInput /> : <Input style={{ width: 300 }} placeholder="请输入" />}
                </Form.Item>
                {isInit && (
                  <Form.Item name="code" label="模板编号">
                    <ShowInput />
                  </Form.Item>
                )}
                <Form.Item name="deviceTypeName" label="所属类目">
                  <ShowInput />
                </Form.Item>
                <Form.Item name="modelName" label="所属型号">
                  <ShowInput />
                </Form.Item>
                <Form.Item name="iotProtocol" label="物联层协议">
                  极熵
                </Form.Item>
                <Form.Item name="driveType" label="驱动类型">
                  <ShowInput />
                </Form.Item>
                <Form.Item
                  name="crcOrder"
                  label="CRC字节序"
                  rules={!isInit ? [{ required: true, message: '请选择CRC字节序' }] : []}
                >
                  {isInit ? (
                    <ShowInput />
                  ) : (
                    <Select placeholder="请选择" style={{ width: 300 }}>
                      <Select.Option value={CRC_ORDER.SMALL_END}>{CrcOrderLabels[CRC_ORDER.SMALL_END]}</Select.Option>
                      <Select.Option value={CRC_ORDER.BIG_END}>{CrcOrderLabels[CRC_ORDER.BIG_END]}</Select.Option>
                    </Select>
                  )}
                </Form.Item>
                <Form.Item name="remark" label="模板备注" rules={[{ max: 50 }]}>
                  {isInit ? <ShowInput /> : <Input style={{ width: 300 }} placeholder="请输入" />}
                </Form.Item>
                {!isInit && (
                  <Form.Item>
                    <Button type="primary" disabled={status === BSAStatus.INVALID} htmlType="submit">
                      保存
                    </Button>
                  </Form.Item>
                )}
                {currentType === BsaType.CELL_EDGE_DEVICE_TEMPLATE_APPLYING && (
                  <Form.Item
                    extra={
                      <div style={{ marginTop: 7 }}>
                        <Space>
                          <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
                          <span>请务必前往创建该数采模板的虚拟点和数采点，再进行应用模板操作</span>
                        </Space>
                      </div>
                    }
                  >
                    <Button type="primary" disabled={status === BSAStatus.INVALID} onClick={applyTemplate}>
                      应用模板
                    </Button>
                  </Form.Item>
                )}
                {currentType === BsaType.CELL_EDGE_DEVICE_TEMPLATE_APPLIED && status !== BSAStatus.INVALID && (
                  <Form.Item>
                    <Button type="primary" onClick={onRefresh}>
                      刷新
                    </Button>
                  </Form.Item>
                )}
              </Form>
            </DividerContent>
          </div>
        </BorderWrapper>
        {/* <Modal title={documentChanged ? '请在下方的文本框输入以下蓝色文字' : '创建电池簇数采模板成功'} visible={modalVisible} footer={null} onCancel={() => {setModalVisible(false)}}>
          {
            documentChanged ? (
              <Space direction="vertical" style={{width: '100%'}}>
                <p className={styles.inputText}>{text}</p>
                <Input placeholder='请输入上面蓝色的文字，否则不能点击下一步按钮' value={textValue} onChange={(e) => {setTextValue(e.target.value)}}/>
                <Space className={styles.buttonWrapper}>
                  <Button type='primary'>
                    <a target='_blank' href={combineURL(window.OPSIOTPLATFORMORIGIN, `/device/edge/template/${template?.edgeDeviceTemplateId}/point`)} >前往配置该数采模板的虚拟点和静态点</a>
                  </Button>
                  <Button disabled={!(textValue === text)} onClick={nextFn}>下一步</Button>
                </Space>
              </Space>
            ) : (
              <Space direction="vertical" align='center'>
                <p>请<span className={styles.red}>务必</span>前往创建该数采模板的虚拟点和数采点，再进行<span className={styles.red}>下一步</span>操作</p>
                <Button type='primary'>
                  <a target='_blank' href={combineURL(window.OPSIOTPLATFORMORIGIN, `/device/edge/template/${template?.edgeDeviceTemplateId}/point`)} >前往配置该数采模板的虚拟点和静态点</a>
                </Button>
              </Space>
            )
          }
        </Modal> */}
      </div>
    </>
  );
};

export default BsaCellEdgeTemplate;
