import { CircuitProps, CircuitType, getCircuitList } from '@/api/circuit';
import { PermissionsType } from '@/common/permissionsConst';
import BorderWrapper from '@/components/BorderWrapper';
import { useHasPermission } from '@/utils/hooks';
import { Breadcrumb, Button, useBreadcrumbRoutes, useUpdate, Select, message } from '@maxtropy/components';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { createContext, FC, useEffect, useMemo, useRef, useState } from 'react';
import ComparisonOfDailyPowerCons from './components/ComparisonOfDailyPowerCons';
import CurrentMonthElecConsumption from './components/CurrentMonthElecConsumption';
import CurrentMonthMaxDemand from './components/CurrentMonthMaxDemand';
import DailyPowerCurve from './components/DailyPowerCurve';
import ElectricityAnalysisTopology from './components/ElectricityAnalysisTopology';
import HeadTitle from './components/HeadTitle';
import PeakValleyAnalysis from './components/PeakValleyAnalysis';
import PowerFactor from './components/PowerFactor';
import styles from './index.module.scss';
import { flexible } from './utils';
import { SettingOutlined } from '@ant-design/icons';
import SettingModal, { TypeAction } from './components/SettingModal';
const flagList = new Array(7).fill(false);

export const ScaleContext = createContext<number>(1);

const InComingOverview: FC = () => {
  const routesContext = useBreadcrumbRoutes();
  const [circuitList, setCircuitList] = useState<CircuitProps[]>([]);
  const [currentCircuit, setCurrentCircuit] = useState<number>();
  const hasShowCircuitDataPermission = useHasPermission(PermissionsType.B_INCOMINGLINEDATA);
  const hasLookGraphPermission = useHasPermission(PermissionsType.B_INCOMINGLINETOPOLOGY);

  const outerRef = useRef<HTMLDivElement>();

  const [overNormalScreen, setOverNormalScreen] = useState<boolean>(false);
  const [flexHeight, setFlexHeight] = useState<number>();
  const [zoom, setZoom] = useState<number>();
  const [settingVisible, setSettingVisible] = useState<boolean>(false); // 峰谷分析配置弹窗
  const [settingType, setSettingType] = useState<TypeAction>();

  // 自适应屏幕缩放
  const handleResize = () => {
    setFlexHeight(document.documentElement.clientHeight);
    if (document.documentElement.clientWidth > 1920) {
      setOverNormalScreen(true);
      flexible(outerRef.current);
      const scale = document.documentElement.clientWidth / 1920;
      setZoom(scale);
    }
  };

  // 自适应窗口变化
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 请求电网计费回路接口
  useEffect(() => {
    getCircuitList({ type: CircuitType.GRID_BILLING_CIRCUIT }).then(res => {
      if (res) {
        setCircuitList(res);
      }
    });
  }, []);

  // 进线数据查看
  const showInComingData = () => {
    if (currentCircuit) {
      window.open(`/dmes/statistics/circuit?id=${currentCircuit}`, '_blank');
    } else {
      message.warning('请选择电网计费回路');
    }
  };

  // 电网计费回路的options
  const options = useMemo(() => {
    let circuitListSort = circuitList.sort((a, b) => {
      return dayjs(b.createTime).valueOf() - dayjs(a.createTime).valueOf();
    });
    if (circuitListSort.length !== 0) {
      setCurrentCircuit(circuitListSort[0].id);
    }
    return circuitListSort.map(i => ({ label: i.name, value: i.id }));
  }, [circuitList]);

  // 跳转到拓扑页面
  const routeTo = () => {
    if (!currentCircuit) return;
    window.open(`/dmes/circuit/topologicalGraph?circuitId=${currentCircuit}`, '_blank');
  };
  const tempOverList = useRef(flagList);
  const [isOverList, setIsOverList] = useState<boolean[]>(flagList);

  const changeOverList = (index: number, flag: boolean) => {
    tempOverList.current.splice(index, 1, flag);
    setIsOverList([...tempOverList.current]);
  };
  const [timerState, setTimerState] = useUpdate();
  const timer = useRef<number>();
  useEffect(() => {
    if (isOverList.every(k => k)) {
      clearTimeout(timer.current);
      timer.current = window.setTimeout(() => {
        setTimerState();
      }, 900000);
    }
    return () => clearTimeout(timer.current);
  }, [isOverList, setTimerState]);

  return (
    <ScaleContext.Provider value={zoom ?? 1}>
      <div
        className={styles.page}
        ref={dom => {
          if (dom) {
            outerRef.current = dom;
          }
        }}
      >
        <Breadcrumb routes={routesContext?.routes} />
        <BorderWrapper>
          <div className={styles.titleOutDivSty}>
            <div>
              <span>电网计费回路：</span>
              <Select
                style={{ width: 200 }}
                showSearch
                placeholder="请选择"
                optionFilterProp="label"
                onChange={(id: number) => {
                  if (timer.current) {
                    clearTimeout(timer.current);
                    timer.current = undefined;
                  }
                  setCurrentCircuit(id);
                }}
                dropdownStyle={{ zoom: zoom }}
                options={options}
                value={currentCircuit}
              />
            </div>
            <div>
              {hasShowCircuitDataPermission ? (
                <Button type="primary" onClick={showInComingData}>
                  进线数据查看
                </Button>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div>
            <Row gutter={10}>
              <Col span={6} className={styles.firstRowSty}>
                <div className={styles.func_area}>
                  <CurrentMonthElecConsumption
                    timerState={timerState}
                    openTimer={flag => changeOverList(0, flag)}
                    currentCircuit={currentCircuit}
                  />
                </div>
              </Col>
              <Col span={13} className={styles.firstRowSty}>
                <div className={styles.func_area}>
                  <CurrentMonthMaxDemand
                    timerState={timerState}
                    openTimer={flag => changeOverList(1, flag)}
                    currentCircuit={currentCircuit}
                    circuitList={circuitList}
                  />
                </div>
              </Col>
              <Col span={5} className={styles.firstRowSty}>
                <div className={styles.func_area}>
                  <PowerFactor
                    timerState={timerState}
                    openTimer={flag => changeOverList(2, flag)}
                    currentCircuit={currentCircuit}
                  />
                </div>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col
                xl={24}
                xxl={15}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                className={styles.secondRowSty}
                style={{ minHeight: overNormalScreen ? 336 : flexHeight && (flexHeight / 20) * 7 }}
              >
                <div className={styles.func_area}>
                  <div className={styles.title_line}>
                    <HeadTitle title="用电分析拓扑" />
                    {hasLookGraphPermission && (
                      <Button type="link" onClick={routeTo} disabled={!currentCircuit}>
                        查看拓扑
                      </Button>
                    )}
                  </div>
                  <ElectricityAnalysisTopology
                    timerState={timerState}
                    openTimer={flag => changeOverList(3, flag)}
                    id={currentCircuit}
                    height={overNormalScreen ? 336 : flexHeight && (flexHeight / 20) * 7}
                  />
                </div>
              </Col>
              <Col
                xl={24}
                xxl={9}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                className={styles.secondRowSty}
                style={{ minHeight: overNormalScreen ? 336 : flexHeight && (flexHeight / 20) * 7 }}
              >
                <div className={styles.func_area}>
                  <div className={styles.title_line}>
                    <HeadTitle title="峰谷分析" />
                    <div className={styles.settingSty} onClick={() => setSettingVisible(true)}>
                      <SettingOutlined color="#4A90E2" />
                      <span style={{ paddingLeft: 4 }}>配置</span>
                    </div>
                  </div>
                  <SettingModal
                    currentCircuitId={currentCircuit}
                    circuitList={circuitList}
                    settingVisible={settingVisible}
                    onCancel={() => setSettingVisible(false)}
                    setSettingType={setSettingType}
                  />
                  <PeakValleyAnalysis
                    settingType={settingType}
                    timerState={timerState}
                    openTimer={flag => changeOverList(4, flag)}
                    currentCircuit={currentCircuit}
                  />
                </div>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col
                xl={24}
                xxl={15}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                className={styles.thirdRowSty}
                style={{ minHeight: overNormalScreen ? 382 : flexHeight && (flexHeight / 5) * 2 }}
              >
                <div className={styles.func_area}>
                  <HeadTitle title="本月日用电量对比" />
                  <ComparisonOfDailyPowerCons
                    timerState={timerState}
                    openTimer={flag => changeOverList(5, flag)}
                    currentCircuit={currentCircuit}
                  />
                </div>
              </Col>
              <Col
                xl={24}
                xxl={9}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                className={styles.thirdRowSty}
                style={{ minHeight: overNormalScreen ? 382 : flexHeight && (flexHeight / 5) * 2 }}
              >
                <div className={styles.func_area}>
                  <HeadTitle title="日度功率曲线" />
                  <DailyPowerCurve
                    timerState={timerState}
                    openTimer={flag => changeOverList(6, flag)}
                    currentCircuit={currentCircuit}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </BorderWrapper>
      </div>
    </ScaleContext.Provider>
  );
};

export default InComingOverview;
