import { PermissionsType } from '@/common/permissionsConst';
import MapStation from '.';

const routes = [
  {
    path: '/bsaStation/asset/GISdashBoard',
    permission: PermissionsType.P_ENERGYGISSUMMARY,
    element: <MapStation />,
  },
];
export default routes;
