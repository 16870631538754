import React, { useMemo, useContext } from 'react';
import { App, Row, Space, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import ReactEcharts from 'echarts-for-react';
import { useQuery } from 'react-query';
import { DeviceProps, getBsa } from '@/api/bsa';
import {
  DeviceData,
  getDeviceDataInstantaneous,
  getDeviceDataInstantaneousFor1m,
  getDeviceDataInstantaneousForRaw,
} from '@/api/device';
import { isNil } from 'lodash-es';
import { useYaxisMarkArea } from './useYaxisMarkArea';
import { StorageContext } from '../StorageContext';
import { DatePicker, Empty, Radio } from '@maxtropy/components';

const { RangePicker } = DatePicker;

export interface Iprops {
  stack_h_property_id: number;
  stack_l_property_id: number;
  rack_h_property_id: number;
  rack_l_property_id: number;
  pack_h_property_id: number;
  pack_l_property_id: number;
  unit: string;
  type: string;
}

enum TimeChoice {
  TODAY,
  LAST_24_HOURS,
  CUSTOM,
}

const PROPERTY_ID = 10385;

// 颗粒度枚举
export enum GrainChoice {
  ORIGINAL = 'original',
  MINUTE_1 = '1M',
  MINUTE_15 = '15M',
}

export const timeGapMap = {
  [GrainChoice.ORIGINAL]: 1,
  [GrainChoice.MINUTE_1]: 3,
  [GrainChoice.MINUTE_15]: 30,
};
interface EnvTemperProps {
  bsaId: number;
}

const EnvTemper: React.FC<EnvTemperProps> = props => {
  // const { id } = useParams<{ id: string }>();
  // const bsaId = +id;
  const { bsaId } = props;
  const StorageContextValue = useContext(StorageContext);
  const { message } = App.useApp();
  const disabledDate = (current: Dayjs) => {
    return current && current.valueOf() >= dayjs().endOf('day').valueOf();
  };
  const [from, to] = useMemo(() => {
    if (StorageContextValue?.timeChoice === TimeChoice.TODAY) {
      return [dayjs().startOf('day').valueOf(), dayjs().endOf('day').valueOf()];
    } else if (StorageContextValue?.timeChoice === TimeChoice.LAST_24_HOURS) {
      return [dayjs().subtract(24, 'hours').valueOf(), dayjs().valueOf()];
    } else if (StorageContextValue?.customDateRange) {
      return [
        StorageContextValue?.customDateRange[0].startOf('day').valueOf(),
        StorageContextValue?.customDateRange[1].endOf('day').valueOf(),
      ];
    }
    return [undefined, undefined];
  }, [StorageContextValue?.customDateRange, StorageContextValue?.timeChoice]);

  const { data: bsaDetail } = useQuery(['bsaDetail', bsaId], () => getBsa(bsaId), {
    enabled: !!bsaId,
  });

  // 温湿度计设备
  const deviceIds = useMemo(() => {
    return bsaDetail?.temperatureAndHumidityMeterList?.map(item => item.deviceId!)?.join(',');
  }, [bsaDetail]);

  // y轴区域
  const markAreaData = useYaxisMarkArea('env', bsaDetail);

  const requestApi = useMemo(() => {
    return StorageContextValue?.grainChoice === GrainChoice.ORIGINAL
      ? getDeviceDataInstantaneousForRaw
      : StorageContextValue?.grainChoice === GrainChoice.MINUTE_1
      ? getDeviceDataInstantaneousFor1m
      : getDeviceDataInstantaneous;
  }, [StorageContextValue?.grainChoice]);

  const { data: chartData, isLoading: isDataLoading } = useQuery(
    ['chartData', from, to, StorageContextValue?.grainChoice, deviceIds],
    () =>
      requestApi({
        deviceIds: deviceIds!,
        propertyIds: String(PROPERTY_ID),
        startTime: from!,
        endTime: to!,
      }),
    {
      enabled: !!from && !!to && !!deviceIds,
    }
  );

  const isDataEmpty = useMemo(() => {
    return !chartData?.some(item => item.values.some(v => !isNil(v.value)));
  }, [chartData]);

  return (
    <div>
      <Row style={{ padding: '0 20px' }} gutter={30} align={'middle'}>
        <Space size={20}>
          <Radio.Group
            buttonStyle={'solid'}
            value={StorageContextValue?.timeChoice}
            onChange={e => {
              StorageContextValue?.setTimeChoice(e.target.value);
            }}
          >
            <Radio.Button value={TimeChoice.TODAY}>当日</Radio.Button>
            <Radio.Button value={TimeChoice.LAST_24_HOURS}>最近24小时</Radio.Button>
            <Radio.Button value={TimeChoice.CUSTOM}>其他</Radio.Button>
          </Radio.Group>
          <Space>
            颗粒度:
            <Radio.Group
              buttonStyle={'solid'}
              value={StorageContextValue?.grainChoice}
              onChange={e => {
                StorageContextValue?.setGrainChoice(e.target.value);
              }}
            >
              <Radio.Button value={GrainChoice.ORIGINAL}>原始数据</Radio.Button>
              <Radio.Button value={GrainChoice.MINUTE_1}>1分钟</Radio.Button>
              <Radio.Button value={GrainChoice.MINUTE_15}>15分钟</Radio.Button>
            </Radio.Group>
          </Space>
          {StorageContextValue?.timeChoice === TimeChoice.CUSTOM && (
            <Space>
              选择日期:
              <RangePicker
                allowClear={false}
                value={StorageContextValue?.customDateRange}
                disabledDate={disabledDate}
                onChange={e => {
                  const value = e as [dayjs.Dayjs, dayjs.Dayjs];
                  const timeGap = value[1].endOf('day').diff(value[0].startOf('day'), 'days', true);
                  if (timeGap > timeGapMap[StorageContextValue?.grainChoice]) {
                    return message.error(`最大跨度为${timeGapMap[StorageContextValue?.grainChoice]}天`);
                  }
                  StorageContextValue?.setCustomDateRange(value);
                }}
              />
            </Space>
          )}
        </Space>
      </Row>

      <Spin spinning={isDataLoading}>
        <div style={{ marginTop: 30, height: 'calc(100vh - 303px)' }}>
          {isDataEmpty && !isDataLoading ? (
            <Empty style={{ paddingTop: 120 }} />
          ) : (
            <ReactEcharts
              option={getOption(
                markAreaData,
                from!,
                to!,
                chartData,
                bsaDetail?.temperatureAndHumidityMeterList,
                StorageContextValue?.grainChoice
              )}
              theme={'dark'}
              style={{ height: '100%' }}
              notMerge
              lazyUpdate={false}
            />
          )}
        </div>
      </Spin>
    </div>
  );
};

const getOption = (
  markAreaData: { yAxis: number | null | undefined }[][],
  from: number,
  to: number,
  chartData?: DeviceData[],
  deviceList?: DeviceProps[],
  grainChoice?: GrainChoice
) => {
  // const timeGap = to - from;
  let minVal: number = 0,
    maxVal: number = 50;
  let showMin: boolean = false,
    showMax: boolean = false;

  const chartDataSeries =
    chartData?.map((item, index) => {
      let temp = deviceList?.find(d => d.deviceId === item.deviceId)?.deviceName;
      const data = item.values.map(v => [v.time, v.value]);
      showMin = item.values.every(v => v.value > minVal);
      showMax = item.values.every(v => v.value < maxVal);

      return {
        connectNulls: true,
        name: `${temp ?? '设备' + index + '温度'}`,
        type: 'line',
        data,
        markArea: index === 0 && { data: markAreaData },
        smooth: true,
        yAxisIndex: 0,
      };
    }) || [];

  const chartDataYAxis = {
    name: '℃',
    type: 'value',
    nameTextStyle: { align: 'right', padding: [5, 10] },
    scale: true,
    min: showMin ? minVal : null,
    max: showMax ? maxVal : null,
    splitLine: {
      lineStyle: {
        type: 'dotted',
        color: 'rgba(255,255,255,0.35)',
      },
    },
  };

  return {
    backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue('--component-background'),
    grid: { left: 80, right: 80 },
    legend: {
      show: true,
      right: 100,
      top: 0,
      icon: 'rect',
      textStyle: {
        color: '#AFBCC4',
      },
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params: any[]) => {
        return params.reduce(
          (acc, curr) =>
            acc + `<span >${curr.marker} ${curr.seriesName}：</span> ${curr.data[1]?.toFixed(1) || '--'}℃<br />`,
          `${params[0].axisValueLabel}<br />`
        );
      },
    },
    dataZoom: [
      {
        type: 'slider',
        start: grainChoice === GrainChoice.ORIGINAL ? 98 : grainChoice === GrainChoice.MINUTE_1 ? 90 : 0,
        end: 100,
        bottom: 15,
        height: 20,
        textStyle: {
          fontSize: 10,
        },
      },
      { type: 'inside' },
    ],
    xAxis: {
      // min: from,
      // max: to,
      // interval: 3600 * 1000,
      minInterval: 60 * 1000,
      type: 'time',
      axisLabel: {
        color: 'rgba(255,255,255,0.6)',
        fontSize: 12,
        formatter: (val: number) => dayjs(val, 'x').format('MM-DD[\n]HH:mm'),
        // formatter: (val: number) => {
        //   // 时间范围跨天但在3日以内时，X轴显示为DDHH 否则日为单位
        //   let format = timeGap > 3 * 24 * 3600 * 1000 ? 'D日' : 'D日H点';
        //   return dayjs(val).format(format);
        // },
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: chartDataYAxis,
    series: [...chartDataSeries],
  };
};

export default EnvTemper;
