import { FC, useContext, useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { v2GetCircuitStatistics, StatisticsPartition } from '@/api/circuit';
import { DataType } from '@/api/const';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { Empty } from '@maxtropy/components';
import { ScaleContext } from '@/pages/InComingOverview';

interface Iprops {
  currentCircuit?: number; // 回路id
  openTimer: (isOver: boolean) => void;
  timerState?: number;
}

interface BarChartData {
  xAxisData: string[];
  prevMonthVal: (number | null)[];
  currentMonthVal: (number | null)[];
}

const ComparisonOfDailyPowerCons: FC<Iprops> = ({ currentCircuit, openTimer, timerState }) => {
  const scale = useContext(ScaleContext);
  const [chartData, setChartData] = useState<BarChartData>();

  useEffect(() => {
    (async () => {
      if (currentCircuit) {
        openTimer?.(false);
        const response = await v2GetCircuitStatistics({
          circuitId: currentCircuit,
          dataType: DataType.EP,
          timeResolution: StatisticsPartition.DAY,
          from: dayjs().subtract(1, 'month').startOf('month').valueOf(),
          to: dayjs().endOf('month').valueOf(),
        })
          .then(res => res)
          .catch(_ => []);
        let data = (response?.[0]?.data ?? []).map(m => {
          return {
            time: m.time,
            date: dayjs(m.time).format('D'),
            value: !isNil(m.value) ? Number(m.value.toFixed(2)) : null,
          };
        });
        let barData = {
          xAxisData: [...new Set(data.map(v => v.date))],
          prevMonthVal: data.filter(m => m.time < dayjs().startOf('month').valueOf()).map(k => k.value),
          currentMonthVal: data.filter(m => m.time >= dayjs().startOf('month').valueOf()).map(k => k.value),
        };
        setChartData(barData);
        openTimer?.(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCircuit, timerState]);

  const getChartOption = () => {
    return {
      grid: {
        left: 50,
        right: 20,
        top: 50,
        bottom: 20,
      },
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        type: 'category',
        data: chartData?.xAxisData,
        axisLabel: {},
      },
      yAxis: {
        type: 'value',
        name: 'kWh',
        nameTextStyle: {
          fontSize: 14,
          align: 'right',
        },
        nameGap: 30,
        splitLine: {
          lineStyle: {
            color: '#FFFFFF30',
          },
        },
      },
      legend: {
        show: true,
        right: 20,
        top: 10,
        textStyle: {
          color: '#AFBCC4',
        },
        icon: 'rect',
        itemGap: 18,
        itemWidth: 8,
        itemHeight: 8,
      },
      series: [
        {
          name: '上月',
          type: 'bar',
          barGap: 0,
          barWidth: 6,
          color: '#FFD100',
          emphasis: {
            focus: 'series',
          },
          data: chartData?.prevMonthVal,
        },
        {
          name: '本月',
          type: 'bar',
          barWidth: 6,
          color: '#1ACFF5',
          emphasis: {
            focus: 'series',
          },
          data: chartData?.currentMonthVal,
        },
      ],
    };
  };

  return (
    <div style={{ height: '90%' }}>
      {chartData?.xAxisData?.length ? (
        <ReactEcharts
          style={{ height: '98%', zoom: 1 / scale }}
          option={getChartOption()}
          notMerge
          lazyUpdate={false}
        />
      ) : (
        <Empty style={{ marginTop: 60 }} description={'暂无数据'} />
      )}
    </div>
  );
};

export default ComparisonOfDailyPowerCons;
