import { DatePicker, FormTitle, Radio, Tag, useBreadcrumbRoutes, useCurrent, Wrapper } from '@maxtropy/components';
import React, { createContext, useState } from 'react';
import styles from './index.module.scss';
import { Layout } from 'antd';
import DragResize from '@/components/DragResize';
import PvTree from './components/PvTree';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import TopCard from './components/TopCard';
import ElecForecast from './components/ElecForecast';
import AreaComparison from './components/AreaComparison';
import InverterPerformance from './components/InverterPerformance';
import LossAnalysis from './components/LossAnalysis';
import { keepTwoOrLine } from './utils';

const { Content, Sider } = Layout;

export enum ResolutionEnum {
  YEAR = 'Y',
  MONTH = 'M',
}

export const ResolutionEnumDisplay = {
  [ResolutionEnum.YEAR]: '年度',
  [ResolutionEnum.MONTH]: '月度',
};

export const SitePerforAnalysisYearContext = createContext<{
  pvId?: number;
  pvName?: string;
  resolution?: ResolutionEnum;
  date?: Dayjs;
}>({});

const SitePerforAnalysisYear = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [siderWidth, setSiderWidth] = useState(260); // 初始值
  const [dragStatus, setDragStatus] = useState(false); // 拖拽状态
  const [openSider, setOpenSider] = useState(true); // 左侧树形toggle
  const [resolution, setResolution] = useState<ResolutionEnum>(ResolutionEnum.MONTH);
  const [date, setDate] = useState<Dayjs>(dayjs());
  const [pvInfo, setPvInfo] = useState<{ pvId: number; pvName: string; tenantMcid: string }>();
  const [installedCapacity, setInstalledCapacity] = useState<number>();
  const mcid = useCurrent()?.tenant?.mcid;

  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  // 装机容量
  const getInstalledCapacity = (value?: number) => {
    setInstalledCapacity(value);
  };

  return (
    <Wrapper routes={breadcrumbRoutes?.routes ?? []} className={styles.wrapper}>
      <Layout className={styles.layout}>
        <Sider
          theme="light"
          width={openSider ? siderWidth : 0}
          style={{ transition: dragStatus ? 'none' : 'all 0.2s', background: '#232324' }}
        >
          <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
          <PvTree
            onSelect={(pvId, pvName, tenantMcid) => {
              setPvInfo({ pvId, pvName, tenantMcid });
            }}
          />
        </Sider>

        <Content className={styles.content}>
          <div
            className={styles.toggleBtn}
            style={{ left: openSider ? -30 : 0 }}
            onClick={() => setOpenSider(!openSider)}
          >
            {openSider ? <LeftOutlined /> : <RightOutlined />}
          </div>

          <div className={styles.contentInner}>
            <FormTitle
              title={
                <>
                  <span style={{ marginRight: 8 }}>{pvInfo?.pvName ?? '--'}</span>{' '}
                  <Tag color="default">
                    <span className={styles.tag_label}>装机容量：</span>
                    <span className={styles.tag_value}>{keepTwoOrLine(installedCapacity)}kW</span>
                  </Tag>
                </>
              }
              style={{ marginBottom: 16 }}
              extraContent={
                <div className={styles.time_box}>
                  <Radio.Group
                    buttonStyle="solid"
                    value={resolution}
                    onChange={e => {
                      setResolution(e.target.value);
                      setDate(dayjs());
                    }}
                  >
                    <Radio.Button value={ResolutionEnum.MONTH}>
                      {ResolutionEnumDisplay[ResolutionEnum.MONTH]}
                    </Radio.Button>
                    <Radio.Button value={ResolutionEnum.YEAR}>
                      {ResolutionEnumDisplay[ResolutionEnum.YEAR]}
                    </Radio.Button>
                  </Radio.Group>
                  <DatePicker
                    style={{ width: 240 }}
                    allowClear={false}
                    picker={resolution === ResolutionEnum.YEAR ? 'year' : 'month'}
                    value={date}
                    onChange={date => setDate(date)}
                    disabledDate={date => {
                      return dayjs().isBefore(date);
                    }}
                  />
                </div>
              }
            />
            <SitePerforAnalysisYearContext.Provider
              value={{ pvId: pvInfo?.pvId, pvName: pvInfo?.pvName, resolution, date }}
            >
              <div className={styles.content_box}>
                <div className={styles.top_card_box}>
                  <TopCard getInstalledCapacity={getInstalledCapacity} />
                </div>
                <div className={styles.elec_forecast}>
                  <ElecForecast />
                </div>
                <div className={styles.compare_box}>
                  <AreaComparison />
                </div>
                <div className={styles.inverterPerformance_box}>
                  <InverterPerformance />
                </div>
                <div className={styles.lossAnalysis_box}>
                  <LossAnalysis isShowMore={mcid === pvInfo?.tenantMcid} />
                </div>
              </div>
            </SitePerforAnalysisYearContext.Provider>
          </div>
        </Content>
      </Layout>
    </Wrapper>
  );
};

export default SitePerforAnalysisYear;
