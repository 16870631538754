import React, { ReactNode, useMemo } from 'react';
import { TabsProps } from 'antd/lib/tabs';
import classNames from 'classnames';
import { CircuitDataType } from '../../../../api/circuit';
import styles from './index.module.scss';
import { Tabs, Tooltip } from '@maxtropy/components';

export interface HistoryTabProps extends Partial<TabsProps> {
  onTabChange?: (tabTile: string) => void;
  tabData?: CircuitDataType[];
  tabBarExtraContent?: ReactNode | { left?: ReactNode; right?: ReactNode };
}

const HistoryTab: React.FC<HistoryTabProps> = props => {
  const { onTabChange, tabData = [], activeKey, className, tabBarExtraContent } = props;
  const items: TabsProps['items'] = useMemo(() => {
    return tabData?.map(item => ({
      key: item.id.toString(),
      label: (
        <Tooltip
          placement="top"
          title={
            item.name !== '线损' && item.physicalQuantityNames?.length ? (
              <div className={styles.tabDesc}>{item.physicalQuantityNames.join('、')}</div>
            ) : undefined
          }
        >
          <div className={styles.tab}>
            <div style={{ color: activeKey === String(item.id) ? '#4a90e2' : 'rgba(255, 255, 255, 0.65)' }}>
              {item.name}
            </div>
          </div>
        </Tooltip>
      ),
    }));
  }, [tabData, activeKey]);
  return (
    <div className={classNames(styles.tabs, className)}>
      <Tabs
        tabPosition="bottom"
        onChange={onTabChange}
        activeKey={activeKey}
        items={items}
        tabBarExtraContent={tabBarExtraContent}
      ></Tabs>
    </div>
  );
};

export default HistoryTab;
