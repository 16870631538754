import { PageResponse } from '@/api/page';
import { getGridNetPointList, LoadTransformersProps, NetInfoProp } from '@/api/pv';
import { EllipsisSpan, Paging, Table, usePaging } from '@maxtropy/components';
import { Col, Row } from 'antd';
import qs from 'qs';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';

export const levelList = ['0.4kV', '10kV', '20kV', '6kV', '35kV', '66kV', '110kV', '220kV'];

const GridPointInfo: React.FC = () => {
  const { id } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<PageResponse<NetInfoProp>>();
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;
  useEffect(() => {
    setIsLoading(true);
    getGridNetPointList(id, {
      page: pageOffset,
      size: pageSize,
    })
      .then(setData)
      .finally(() => setIsLoading(false));
  }, [pageOffset, pageSize, id]);
  useEffect(() => {
    if (data) {
      setTotalCount(data.total);
    }
  }, [data, setTotalCount]);
  const columns = [
    {
      title: '序号',
      dataIndex: 'order',
      ellipsis: { showTitle: true },
      render: (v: string, rows: any, index: number) => <EllipsisSpan value={(pageOffset - 1) * pageSize + index + 1} />,
    },
    {
      title: '并网点名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '并网点地址',
      dataIndex: 'address',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '并网等级',
      dataIndex: 'level',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={levelList[Number(v)]} />,
    },
  ];
  return (
    <div className={styles.gridPoint}>
      <div className={styles.headerBox}>
        <Row>
          <Col flex={1}>并网点信息</Col>
          <Col>
            当前数量<span className={styles.currentNum}>{data?.total || 0}</span>个
          </Col>
        </Row>
      </div>
      <div className={styles.tableBox}>
        <Table
          sticky
          rowKey="code"
          scroll={{ x: 1500 }}
          columns={[...columns]}
          dataSource={data?.list}
          loading={isLoading}
        />
        <Paging pagingInfo={pagingInfo} />
      </div>
    </div>
  );
};
export default GridPointInfo;
