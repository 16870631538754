import { V2PvPerformanceAnalysePowerContrastPostResponse } from '@maxtropy/dmes-apis-v2';
import circleImg from '../../imgs/circle@2x.png';
import circleBgImg from '../../imgs/circlebg-big@2x.png';
import { allNull, keepTwoOrLine } from '../../utils';

export const getChartOption = (
  chartData: V2PvPerformanceAnalysePowerContrastPostResponse['areaPowerList'],
  middleScreen?: boolean
) => {
  const isAllNull = allNull(chartData?.map(i => i.power));
  const totalPower = chartData?.reduce((pre, cur) => (cur.power || 0) + pre, 0);
  return {
    // color: ['#FFCB47', '#16DD8E', '#2D8DFF', '#556583'],
    legend: {
      left: '40%',
      top: 'center',
      orient: 'vertical',
      icon: 'circle',
      itemGap: 8,
      itemHeight: 7,
      itemWidth: 7,
      textStyle: {
        color: 'rgba(255,255,255,0.65)',
        rich: {
          name: {
            fontSize: 14,
            lineHeight: 20,
            width: 170,
          },
          percent: {
            fontSize: 14,
            width: 100,
          },
          value: {
            fontSize: 14,
            width: 120,
          },
        },
      },
      formatter: (name: string) => {
        const item = chartData?.find(item => item.areaName === name);
        let areaName = (item?.areaName ?? '').length > 15 ? item?.areaName?.slice(0, 15) + '...' : item?.areaName;
        const percent = totalPower ? (((item?.power ?? 0) / totalPower) * 100).toFixed(2) + '%' : '-';
        return `{name| ${areaName}}{percent| ${percent}}{value|${keepTwoOrLine(item?.power) + 'kWh'}}`;
      },
    },
    title: {
      text: isAllNull
        ? '-'
        : (chartData ?? []).length
        ? chartData?.reduce((pre, cur) => (cur.power || 0) + pre, 0).toFixed(2)
        : '-',
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
        fontSize: 14,
        lineHeight: 22,
      },
      subtext: '发电量(kWh)',
      subtextStyle: {
        color: 'rgba(255,255,255,0.65)',
        fontSize: 12,
      },
      left: middleScreen ? 108 : 208,
      top: 98,
      itemGap: 8,
      textAlign: 'center',
    },
    graphic: {
      elements: [
        {
          type: 'image',
          style: {
            image: circleImg,
            x: middleScreen ? 56 : 156,
            y: 66,
            width: 112,
            height: 112,
          },
        },
        {
          type: 'image',
          style: {
            image: circleBgImg,
            x: middleScreen ? 36 : 136,
            y: 46,
            width: 152,
            height: 152,
            opacity: allNull(chartData?.map(i => i.power)) ? 1 : 0,
          },
        },
      ],
    },
    series: [
      {
        type: 'pie',
        name: '发电量(kWh)',
        percentPrecision: 5,
        minAngle: 2,
        padAngle: 1,
        radius: [58, 72],
        left: middleScreen ? 50 : 150,
        top: 60,
        width: 124,
        height: 124,
        label: {
          show: false,
        },
        data: chartData?.map(item => ({
          name: item.areaName,
          value: keepTwoOrLine(item.power),
        })),
        showEmptyCircle: false,
      },
      {
        showEmptyCircle: false,
        data: (chartData ?? []).map(item => ({
          name: item.areaName,
          value: keepTwoOrLine(item.power),
        })),
        type: 'pie',
        percentPrecision: 5,
        padAngle: 1,
        minAngle: 2,
        left: middleScreen ? 50 : 150,
        top: 60,
        name: '发电量(kWh)',
        radius: [66, 86],
        width: 124,
        height: 124,
        emphasis: {
          disabled: true,
        },
        label: {
          show: false,
        },
        itemStyle: {
          opacity: 0.2,
        },
      },
    ],
  };
};
