import PvOperationCard from '@/components/PvOperationCard';
import { Empty } from '@maxtropy/components';
import { Col, Row, Spin } from 'antd';
import { FC } from 'react';
import styles from './index.module.scss';
import { PvStationItem } from '../..';

export interface ICardList {
  isLoading: boolean;
  data: PvStationItem[];
  isChannel?: boolean;
}

const CardList: FC<ICardList> = props => {
  const { isLoading, data, isChannel = false } = props;

  return (
    <div className={styles.cardListStyle}>
      {!data || data.length === 0 ? (
        <Empty />
      ) : (
        <Spin spinning={isLoading}>
          <Row gutter={12}>
            {data.map(item => (
              <Col key={item.id} xl={6} lg={8} md={8} sm={8} xs={8}>
                <div className={styles.cardListColStyle}>
                  <PvOperationCard item={item} isChannel={isChannel} />
                </div>
              </Col>
            ))}
          </Row>
        </Spin>
      )}
    </div>
  );
};

export default CardList;
