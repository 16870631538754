import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { Key } from 'react';
import { ModbusPointAttributes } from './entity';
import { ADDR_OFFSET, BsaDeviceTypeEnum, CRC_ORDER, DriveType, ProcessStatus, TopologicalType } from './type';

export interface GlobalEdgeRequest {
  topologicalType: TopologicalType;
  driveType: DriveType;
  bsaId: number;
  // 首地址偏移
  addrOffset: ADDR_OFFSET;
  // 采集间隔（毫秒）
  samplingInterval: number;
  // 采集重试次数
  samplingRetry: number;
  // 采集超时（毫秒）
  samplingTimeout: number;
  // 单次最多可请求寄存器字节数
  groupWords: number;
}
// 根据储能阵列id和拓扑类型，查询全局数采
export function createGlobalEdgeConfig(request: GlobalEdgeRequest) {
  return fetch<void>(`/api/edge-device/bsa/config`, {
    method: 'POST',
    body: JSON.stringify(request),
  });
}

export function getGlobalEdgeConfig(id: Key) {
  return fetch<GlobalEdgeRequest>(`/api/edge-device/bsa/config?bsaId=${id}`);
}

export interface EdgeDeviceConfig {
  id: number;
  deviceId: number;
  sequence: number;
  ip: string;
  port: number;
  stationNum: number;
}
export interface BsaEdgeDeviceConfigPreview {
  pcs?: EdgeDeviceConfig[];
  stack?: EdgeDeviceConfig[];
}

export interface BsaEdgeDeviceConfigPreviewRequest {
  bsaId: Key;
  topologicalType: TopologicalType;
}

export function getBsaEdgeDeviceConfigPreview(query: BsaEdgeDeviceConfigPreviewRequest) {
  return fetch<BsaEdgeDeviceConfigPreview>(`/api/edge-device/bsa/preview?${qs.stringify(query, { indices: false })}`);
}

export interface CreateEdgeDevicetemplateRequest {
  name: string;
  remark?: string;
  bsaId: number;
  // crc字节序
  crcOrder: CRC_ORDER;
  type: BsaDeviceTypeEnum;
}
// 数采模板设置
export function createEdgeDevicetemplate(request: CreateEdgeDevicetemplateRequest) {
  return fetch<void>(`/api/edge-device/bsa/template`, {
    method: 'POST',
    body: JSON.stringify(request),
  });
}

export interface EdgeDevicetemplateRequest
  extends Omit<CreateEdgeDevicetemplateRequest, 'name' | 'remark' | 'crcOrder'> {}

export interface BsaEdgeDeviceTemplateResponse {
  id: number;
  bsaId: number;
  type: BsaDeviceTypeEnum.RACK | BsaDeviceTypeEnum.PACK;
  name: string;
  // crc字节序
  crcOrder: CRC_ORDER;
  remark?: string;
  deviceTypeId: number;
  deviceTypeName: string;
  deviceModelId: number;
  edgeDeviceTemplateId: number;
  edgeDeviceTemplateVersionId: number;
  modelName: string;
  driveType: DriveType;
  code?: string;
}
// 根据储能id和设备类型获取数采模板
export function getEdgeDevicetemplate(query: EdgeDevicetemplateRequest) {
  return fetch<BsaEdgeDeviceTemplateResponse>(
    `/api/edge-device/bsa/template?${qs.stringify(query, { indices: false })}`
  );
}

export function submitRackTemplete(bsaId: Key) {
  return fetch<void>(`/api/edge-device/bsa/rack-template/created?bsaId=${bsaId}`, {
    method: 'POST',
  });
}

export function submitPackTemplete(bsaId: Key) {
  return fetch<void>(`/api/edge-device/bsa/pack-template/created?bsaId=${bsaId}`, {
    method: 'POST',
  });
}
// 完成电池芯设备数采模板
export function submitCellTemplete(bsaId: Key) {
  return fetch<void>(`/api/edge-device/bsa/cell-template/created?bsaId=${bsaId}`, {
    method: 'POST',
  });
}
// 应用模板
export function applyEdgeDevicePointTemplate(query: EdgeDevicetemplateRequest) {
  return fetch<void>(`/api/edge-device/bsa/template/apply?${qs.stringify(query, { indices: false })}`, {
    method: 'POST',
  });
}

export function rackTemplateStatusCheck(bsaId: Key) {
  return fetch<ProcessStatus>(`/api/edge-device/bsa/template/rack/status-check?bsaId=${bsaId}`);
}

export function packTemplateStatusCheck(bsaId: Key) {
  return fetch<ProcessStatus>(`/api/edge-device/bsa/template/pack/status-check?bsaId=${bsaId}`);
}
// 电芯模板应用状态
export function cellTemplateStatusCheck(bsaId: Key) {
  return fetch<ProcessStatus>(`/api/edge-device/bsa/template/cell/status-check?bsaId=${bsaId}`);
}

export interface EdgeDevicePointTemplateRequest {
  // 储能阵列id
  bsaId: number;
  // 设备类型
  type: BsaDeviceTypeEnum;
  templates: ModbusPointAttributes[];
}

// 获取数据采集点模板列表
export function getEdgeDevicePointTemplate(query: EdgeDevicetemplateRequest) {
  return fetch<EdgeDevicePointTemplateRequest>(
    `/api/edge-device/bsa/point/template?${qs.stringify(query, { indices: false })}`
  );
}
// 生成采集点
export function createEdgeDevicePointTemplate(request: EdgeDevicePointTemplateRequest) {
  return fetch<void>(`/api/edge-device/bsa/point/template`, {
    method: 'POST',
    body: JSON.stringify(request),
  });
}
// 更新生成采集点
export function updateEdgeDevicePointTemplate(request: EdgeDevicePointTemplateRequest) {
  return fetch<void>(`/api/edge-device/bsa/point/template/${request.bsaId}`, {
    method: 'PUT',
    body: JSON.stringify(request),
  });
}

export function rackPointComplete(bsaId: Key) {
  return fetch<void>(`/api/edge-device/bsa/point/rack/complete?bsaId=${bsaId}`, {
    method: 'POST',
  });
}

export function packPointComplete(bsaId: Key) {
  return fetch<void>(`/api/edge-device/bsa/point/pack/complete?bsaId=${bsaId}`, {
    method: 'POST',
  });
}

export function rackPointStatusCheck(bsaId: Key) {
  return fetch<ProcessStatus>(`/api/edge-device/bsa/point/rack/status-check?bsaId=${bsaId}`);
}

export function packPointStatusCheck(bsaId: Key) {
  return fetch<ProcessStatus>(`/api/edge-device/bsa/point/pack/status-check?bsaId=${bsaId}`);
}
// 电池芯采集点完成
export function cellPointComplete(bsaId: Key) {
  return fetch<void>(`/api/edge-device/bsa/point/cell/complete?bsaId=${bsaId}`, {
    method: 'POST',
  });
}
// 电芯采集点生成状态
export function cellPointStatusCheck(bsaId: Key) {
  return fetch<ProcessStatus>(`/api/edge-device/bsa/point/cell/status-check?bsaId=${bsaId}`);
}

export function pointStatusCheck(bsaId: Key, type: BsaDeviceTypeEnum) {
  return type === BsaDeviceTypeEnum.PACK
    ? packPointStatusCheck(bsaId)
    : type === BsaDeviceTypeEnum.CELL
    ? cellPointStatusCheck(bsaId)
    : rackPointStatusCheck(bsaId);
}
