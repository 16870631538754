import { PermissionsType } from '@/common/permissionsConst';
import PVOperationDashBoard from '.';

const routes = [
  {
    path: '/operation/pv/dashboard',
    permission: PermissionsType.P_PVSTATIONDASHBOARD,
    element: <PVOperationDashBoard />,
  },
];
export default routes;
