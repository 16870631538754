import { Tabs, Upload } from '@maxtropy/components';
import { Image } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import wiringDiagram from '@/assets/images/wiringDiagram.png';
import { BsaFcsList } from '@/api/bsaConfig';
interface Props {
  onChange: (value: BsaFcsList[]) => void;
  picValues: BsaFcsList[];
}

const WiringDiagram: React.FC<Props> = ({ picValues, onChange }) => {
  const tabsItems = useMemo(() => {
    if (picValues && picValues.length > 0) {
      return picValues.map((i, index) => ({
        label: <span>{i.fcsName}</span>,
        key: i.fcsId as string,
        children: (
          <TabContent
            onChange={(value: BsaFcsList) => {
              picValues[index] = value;
              onChange(picValues);
            }}
            picValue={i}
          />
        ),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picValues]);

  return <Tabs items={tabsItems} />;
};

interface TabContentProps {
  picValue: BsaFcsList;
  onChange: (value: BsaFcsList) => void;
}

const TabContent: React.FC<TabContentProps> = ({ onChange, picValue }) => {
  const [uploadPicValue, setUploadPicValue] = useState<string>();

  useEffect(() => {
    if (picValue) {
      setUploadPicValue(picValue.wiringLogo);
    }
  }, [picValue]);

  const onPicChange = (value?: string | string[]) => {
    if (value) {
      setUploadPicValue(value as string);
      onChange({
        ...picValue,
        wiringLogo: value as string,
      });
    }
  };

  return (
    <div className={styles.imgDefault}>
      <Upload
        onRemove={() => {
          setUploadPicValue(undefined);
          onChange({
            ...picValue,
            wiringLogo: undefined,
          });
          return true;
        }}
        onChange={onPicChange}
        value={uploadPicValue}
        fileSize={1}
        accept={['.png', '.jpg', '.jpeg']}
        tip={'支持.jpg，.jpeg，.png格式，大小不超过1MB，若不上传，则显示为默认logo。'}
      />
      <div className={styles.wiringDiagram}>
        <Image preview={false} src={wiringDiagram} />
      </div>
    </div>
  );
};
export default WiringDiagram;
