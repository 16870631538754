import React, { useMemo, useState } from 'react';
import {
  Button,
  CustomFilter,
  EllipsisSpan,
  Form,
  Input,
  Modal,
  Paging,
  PopConfirm,
  Select,
  Table,
  usePaging,
} from '@maxtropy/components';
import { Col, Space, Spin } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  createInverter,
  deleteAllInverters,
  deleteInverter,
  exportInverters,
  getInverterPage,
  InverterPayload,
  InverterQuery,
  PVInverter,
  pvInverterKeys,
  removeInverterDevice,
  updateInverterDevice,
} from '@/api/pv-inverter';
import { DeleteOutlined, ExportOutlined, ImportOutlined, PlusOutlined } from '@ant-design/icons';
import { ColumnsType, ColumnType } from 'antd/es/table';
import PVInverterFormModal from './FormModal';
import SelectDeviceModal, { PVType } from '@/components/SelectDeviceModal';
import qs from 'qs';
import { PVStatus } from '@/api/type';
import styles from './index.module.scss';
import { useForm } from 'antd/es/form/Form';
import { combinerBoxKeys, getCombinerBoxes } from '@/api/pv';
import ImportModal from '@/pages/PvStation/Bsa/Inverter/ImportModal';

export interface EditInverterProps {
  isEdit?: boolean;
}

const PVInverterScreen: React.FC<EditInverterProps> = ({ isEdit }) => {
  const { ouId, status, id } = qs.parse(window.location.search, { ignoreQueryPrefix: true }) as unknown as {
    ouId: number;
    status: PVStatus;
    id: number;
    opType: string;
  };
  const pvId = +id;
  const isDraft = +status === PVStatus.DRAFT;
  const isDisable = +status === PVStatus.DISABLE;

  const [form] = useForm();
  const [searchParams, setSearchParams] = useState<InverterQuery>({ pvId });
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset, totalCount } = pagingInfo;
  const queryClient = useQueryClient();
  const { data: combinerBoxes } = useQuery(combinerBoxKeys.list(pvId), () => getCombinerBoxes(pvId));
  const { data, isLoading, refetch } = useQuery(
    pvInverterKeys.page({ ...searchParams, page: pageOffset, size: pageSize }),
    () =>
      getInverterPage({ ...searchParams, page: pageOffset, size: pageSize }).then(res => {
        setTotalCount(res.total);
        return res;
      })
  );
  const { mutate: removeDevice } = useMutation(removeInverterDevice, {
    onSuccess: () => queryClient.invalidateQueries(pvInverterKeys.all),
  });
  const { mutate: removeInverter } = useMutation(deleteInverter, {
    onSuccess: () => queryClient.invalidateQueries(pvInverterKeys.all),
  });
  const { mutate: removeAllInverters } = useMutation(deleteAllInverters, {
    onSuccess: () => queryClient.invalidateQueries(pvInverterKeys.all),
  });
  const { mutate: updateDevice } = useMutation(
    (data: { id: number; deviceId: number }) => updateInverterDevice(data.id, data.deviceId),
    {
      onSuccess: () => queryClient.invalidateQueries(pvInverterKeys.all),
    }
  );
  const { mutate: createNewInverter } = useMutation(createInverter, {
    onSuccess: () => queryClient.invalidateQueries(pvInverterKeys.all),
  });

  const [formModalVisible, setFormModalVisible] = useState(false);
  const [selectDeviceModalVisible, setSelectDeviceModalVisible] = useState(false);
  const [row, setRow] = useState<PVInverter>();
  const [creatingInverter, setCreatingInverter] = useState<InverterPayload>();
  const [importModalVisible, setImportModalVisible] = useState(false);

  const actionColumn: ColumnType<PVInverter> = useMemo(
    () => ({
      title: '操作',
      dataIndex: 'action',
      width: 250,
      fixed: 'right' as const,
      render: (_, record) => (
        <div className="action-buttons">
          <Button
            type="link"
            onClick={() => {
              setRow(record);
              setFormModalVisible(true);
            }}
          >
            编辑
          </Button>
          <PopConfirm
            disabled={!record?.deviceId}
            okText="继续"
            title={'请注意，移除后无法查询该设备数据\n你还要继续吗？'}
            onConfirm={() => removeDevice(record.id)}
          >
            <Button disabled={!record?.deviceId} type="link">
              移除设备
            </Button>
          </PopConfirm>
          <Button
            type="link"
            onClick={() => {
              setRow(record);
              setSelectDeviceModalVisible(true);
            }}
          >
            {record?.deviceId ? '更换设备' : '绑定设备'}
          </Button>
          {(isDraft || isDisable) && (
            <PopConfirm okText="确定" title={'确认删除逆变器吗？'} onConfirm={() => removeInverter(record.id)}>
              <Button type="link">删除</Button>
            </PopConfirm>
          )}
        </div>
      ),
    }),
    [isDraft, isDisable, removeInverter, removeDevice]
  );

  return (
    <>
      {importModalVisible && (
        <ImportModal
          pvId={pvId}
          onCancel={() => setImportModalVisible(false)}
          onOk={() => setImportModalVisible(false)}
        />
      )}
      {formModalVisible && (
        <PVInverterFormModal
          pvId={pvId}
          isDraft={isDraft}
          isDisable={isDisable}
          row={row}
          onCancel={() => {
            setRow(undefined);
            setFormModalVisible(false);
          }}
          onOk={() => {
            setRow(undefined);
            setFormModalVisible(false);
          }}
          onNextStep={payload => {
            setFormModalVisible(false);
            setCreatingInverter(payload);
            setSelectDeviceModalVisible(true);
          }}
        />
      )}
      {selectDeviceModalVisible && (
        <SelectDeviceModal
          pvId={pvId}
          pvType={PVType.CONVERTER}
          ouId={ouId}
          typeName={'逆变器'}
          initialDeviceId={row?.deviceId}
          onOk={deviceId => {
            if (creatingInverter) {
              createNewInverter(
                { ...creatingInverter, deviceId },
                {
                  onSuccess: () => {
                    setCreatingInverter(undefined);
                    setSelectDeviceModalVisible(false);
                  },
                }
              );
              return;
            }

            updateDevice(
              { id: row!.id, deviceId },
              {
                onSuccess: () => {
                  setRow(undefined);
                  setSelectDeviceModalVisible(false);
                },
              }
            );
          }}
          onCancel={() => {
            setRow(undefined);
            setSelectDeviceModalVisible(false);
          }}
        />
      )}
      <Spin spinning={isLoading}>
        <div className={styles.inverter}>
          <div className={styles.title}>{'逆变器'}</div>

          {isEdit ? (
            <div className={styles.header}>
              <Space>
                {(isDraft || isDisable) && (
                  <>
                    <Button
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={() => {
                        setFormModalVisible(true);
                      }}
                    >
                      添加逆变器
                    </Button>
                    <Button
                      type="primary"
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        Modal.confirm({
                          title: '确认删除所有逆变器？',
                          onOk: () => removeAllInverters(pvId),
                        });
                      }}
                    >
                      删除所有逆变器
                    </Button>
                    <Button type="primary" icon={<ImportOutlined />} onClick={() => setImportModalVisible(true)}>
                      导入逆变器
                    </Button>
                  </>
                )}
                <Button type="primary" icon={<ExportOutlined />} onClick={() => exportInverters(pvId)}>
                  导出逆变器
                </Button>
              </Space>
              <div className={styles.count}>
                当前数量：<span style={{ color: '#F5222D' }}>{totalCount ?? 0}</span> 个
              </div>
            </div>
          ) : (
            <>
              <CustomFilter
                colSpan={6}
                form={form}
                onFinish={(values: any) => {
                  setSearchParams({ pvId, ...values });
                  setPageOffset(1);
                  refetch();
                }}
                onReset={() => {
                  setSearchParams({ pvId });
                  setPageOffset(1);
                }}
              >
                <Form.Item name="code" label="逆变器编号">
                  <Input placeholder={'请输入'} />
                </Form.Item>

                <Form.Item name="combinerBoxId" label={'关联汇流箱'}>
                  <Select placeholder={'请选择'}>
                    {combinerBoxes?.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {/*<Row gutter={10}>*/}
                        {/*  <Col span={8} style={{ overflow: "hidden", textOverflow: "ellipsis" }}>*/}
                        {/*    {item.code}*/}
                        {/*  </Col>*/}
                        {/*  <Col span={8} style={{ overflow: "hidden", textOverflow: "ellipsis" }}>*/}
                        {/*    {item.deviceCode ?? "--"}*/}
                        {/*  </Col>*/}
                        {/*  <Col span={8} style={{ overflow: "hidden", textOverflow: "ellipsis" }}>*/}
                        {/*    {item.deviceName ?? "--"}*/}
                        {/*  </Col>*/}
                        {/*</Row>*/}
                        {item.code}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </CustomFilter>
              <Button type="primary" icon={<ExportOutlined />} onClick={() => exportInverters(pvId)}>
                导出逆变器
              </Button>
            </>
          )}

          <Table
            style={{ marginTop: 18 }}
            sticky
            rowKey="id"
            scroll={{ x: 1500 }}
            columns={[...columns, ...(isEdit ? [actionColumn] : [])]}
            dataSource={data?.list}
          />

          <Paging pagingInfo={pagingInfo} />
        </div>
      </Spin>
    </>
  );
};

const columns: ColumnsType<PVInverter> = [
  {
    title: '序号',
    dataIndex: 'index',
    ellipsis: { showTitle: true },
    render: (_v, _record, index) => <EllipsisSpan value={index + 1} />,
  },
  {
    title: '逆变器编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备编号',
    dataIndex: 'deviceCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '关联汇流箱编号',
    dataIndex: 'combinerBoxCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export default PVInverterScreen;
