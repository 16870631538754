import Bsa from '@/pages/Bsa';
import EditRackEdgePoint from '@/pages/Bsa/EditRackEdgePoint';
import EditPackEdgePoint from '@/pages/Bsa/EditPackEdgePoint';
import { PermissionsType } from '@/common/permissionsConst';
import EditCellEdgePoint from './EditCellEdgePoint';

const routes = [
  {
    path: '/energy-storage/basic/bsa/edit/:id',
    permission: PermissionsType.P_UPDATE_BSA,
    element: <Bsa />,
  },
  {
    path: '/energy-storage/basic/bsa/detail/:id',
    permission: PermissionsType.P_BSA_INFO,
    element: <Bsa disabled />,
  },
  {
    path: '/energy-storage/basic/bsa/rack-point/:id',
    permission: PermissionsType.P_BATCH_UPDATE_RACK_EDGE_DEVICE,
    element: <EditRackEdgePoint />,
  },
  {
    path: '/energy-storage/basic/bsa/pack-point/:id',
    permission: PermissionsType.P_BATCH_UPDATE_PACK_EDGE_DEVICE,
    element: <EditPackEdgePoint />,
  },
  {
    path: '/energy-storage/basic/bsa/cell-point/:id',
    element: <EditCellEdgePoint />,
  },
];
export default routes;
