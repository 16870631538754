import { getBsaCostInfo, updateBsaCostInfo } from '@/api/bsa';
import { CostInfo, getPvCostInfo, updatePvCostInfo } from '@/api/pv';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Input, Modal, Button, Radio } from '@maxtropy/components';
import { Space } from 'antd';
import { useEffect, useMemo, useState } from 'react';
interface Iprops {
  cancel?: () => void;
  confirm?: () => void;
  id?: number;
  source?: string; // bsa, pv
}

const EditCostModal: React.FC<Iprops> = props => {
  const rules = [
    {
      pattern: /^(\d){1,3}[0-8]?(\.(\d){1,2})?$|^9999(\.0{1,2})?$/,
      message: '输入范围为0-9999，支持小数点后2位。',
    },
  ];
  const sigleRules = {
    pattern: /^([0](\.(\d){1,2}))$|^[0-1]{1}(\.0{1,2})?$/,
    message: '输入范围为0.00-1.00，支持小数点后两位。',
  };
  const [form] = Form.useForm<CostInfo>();
  const [loading, setLoading] = useState(false);
  const getApi = useMemo(() => {
    return props.source === 'pv' ? getPvCostInfo : getBsaCostInfo;
  }, [props.source]);
  const updateApi = useMemo(() => {
    return props.source === 'pv' ? updatePvCostInfo : updateBsaCostInfo;
  }, [props.source]);
  useEffect(() => {
    if (props.id) {
      getApi(props.id).then(res => {
        form.setFieldsValue(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id, form]);

  const confirmBtn = () => {
    form.validateFields().then(res => {
      setLoading(true);
      updateApi(props.id!, res)
        .then(_ => {
          props.confirm?.();
        })
        .finally(() => setLoading(false));
    });
  };
  const cancelBtn = () => {
    props.cancel?.();
  };
  return (
    <Modal
      open
      title="编辑成本信息"
      size="normal"
      onCancel={cancelBtn}
      contentClassName="modal-form-content"
      footer={
        <Space>
          <Button onClick={cancelBtn}>取消</Button>
          <Button loading={loading} type="primary" onClick={confirmBtn}>
            确定
          </Button>
        </Space>
      }
    >
      <Form form={form} labelCol={{ style: { width: 110 } }}>
        <Form.Item name="investmentCost" label="投建成本" rules={rules}>
          <Input addonAfter="万元"></Input>
        </Form.Item>
        <Form.Item name="recoveredCost" label="已回收成本" rules={rules}>
          <Input addonAfter="万元"></Input>
        </Form.Item>
        <Form.Item
          name="electricityDiscount"
          label="折扣"
          rules={[{ required: true, message: '请输入折扣' }, { ...sigleRules }]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item label={<span></span>} colon={false}>
          <>
            <InfoCircleOutlined style={{ color: 'var(--warning-color)', marginRight: 4 }}></InfoCircleOutlined>
            <span>
              {props.source === 'bsa'
                ? '投资方给企业客户的折扣, 如果是自建储能，无需与其他企业结算，此处输入0，例如分成比例是9折，这里填写0.1'
                : '投资方给企业客户的折扣, 如果是自建光伏，无需与其他企业结算，此处输入0，例如分成比例是9折，这里填写0.1'}
            </span>
          </>
        </Form.Item>

        {props.source === 'pv' && (
          <>
            <Form.Item
              name="internetRevenueSharing"
              label="上网分成比例"
              rules={[{ required: true, message: '请输入上网分成比例' }, { ...sigleRules }]}
            >
              <Input></Input>
            </Form.Item>
            <Form.Item label={<span></span>} colon={false}>
              <>
                <InfoCircleOutlined style={{ color: 'var(--warning-color)', marginRight: 4 }}></InfoCircleOutlined>
                <span>投资方给企业客户上网分成比例, 如果是自建光伏，无需与其他企业结算，此处输入0</span>
              </>
            </Form.Item>
          </>
        )}

        {props.source === 'bsa' && (
          <Form.Item name="settlementWay" label="结算方式" rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={1}>用电折扣</Radio>
              <Radio value={2}>收益分成</Radio>
            </Radio.Group>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default EditCostModal;
