import React, { useMemo, useState } from 'react';
import { Button, EllipsisSpan, Form, Input, Modal, Paging, PopConfirm, Table, usePaging } from '@maxtropy/components';
import { Col, Space, Spin } from 'antd';

import { CompsQuery, exportComps } from '@/api/pv-string';
import { DeleteOutlined, ExportOutlined, ImportOutlined, PlusOutlined } from '@ant-design/icons';

import FormModal from './FormModal';
import SelectDeviceModal, { PVType } from '@/components/SelectDeviceModal';
import qs from 'qs';
import { PVStatus } from '@/api/type';
import styles from './index.module.scss';
import Filter from '@/components/Filter';
import { useForm } from 'antd/es/form/Form';
import ImportModal from './ImportModal';
import { useRequest } from 'ahooks';
import {
  apiV2ModuleCreatePost,
  apiV2ModuleDeleteAllPost,
  apiV2ModuleDeletePost,
  apiV2ModulePagePost,
  apiV2ModuleRemoveDevicePost,
  apiV2ModuleUpdateDevicePost,
  V2ModuleCreatePostRequest,
  V2ModulePagePostResponse,
} from '@maxtropy/dmes-apis-v2';
export type V2ModulePagePostResponseItem = Exclude<V2ModulePagePostResponse['list'], undefined>[number];
export interface EditStringProps {
  isEdit?: boolean;
}

const PVComp: React.FC<EditStringProps> = ({ isEdit }) => {
  const { ouId, status, id } = qs.parse(window.location.search, { ignoreQueryPrefix: true }) as unknown as {
    ouId: number;
    status: PVStatus;
    id: number;
    opType: string;
  };
  const pvId = +id;
  const isDraft = +status === PVStatus.DRAFT;
  const isDisable = +status === PVStatus.DISABLE;

  const [form] = useForm();
  const [searchParams, setSearchParams] = useState<CompsQuery>({ pvId });
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset, totalCount } = pagingInfo;

  const {
    data,
    loading: isLoading,
    refresh,
  } = useRequest(
    () =>
      apiV2ModulePagePost({ ...searchParams, pageNum: pageOffset, pageSize }).then(res => {
        setTotalCount(res.total ?? 0);
        return res.list;
      }),
    {
      refreshDeps: [searchParams],
    }
  );
  const { runAsync: removeDevice } = useRequest(apiV2ModuleRemoveDevicePost, {
    manual: true,
    onSuccess: refresh,
  });
  const { runAsync: removeComp } = useRequest(apiV2ModuleDeletePost, {
    manual: true,
    onSuccess: refresh,
  });
  const { runAsync: removeAllComps } = useRequest(apiV2ModuleDeleteAllPost, {
    manual: true,
    onSuccess: refresh,
  });
  const { runAsync: updateDevice } = useRequest(apiV2ModuleUpdateDevicePost, {
    manual: true,
    onSuccess: refresh,
  });
  const { runAsync: createNewComp } = useRequest(apiV2ModuleCreatePost, {
    manual: true,
    onSuccess: refresh,
  });

  const [formModalVisible, setFormModalVisible] = useState(false);
  const [selectDeviceModalVisible, setSelectDeviceModalVisible] = useState(false);
  const [row, setRow] = useState<V2ModulePagePostResponseItem>();
  const [creatingComp, setCreatingComp] = useState<V2ModuleCreatePostRequest>();
  const [importModalVisible, setImportModalVisible] = useState(false);
  const actionColumn = useMemo(
    () => ({
      title: '操作',
      dataIndex: 'action',
      width: 250,
      fixed: 'right' as const,
      render: (_: any, record: V2ModulePagePostResponseItem) => (
        <Space size={16}>
          <Button
            type="link"
            onClick={() => {
              setRow(record);
              setFormModalVisible(true);
            }}
          >
            编辑
          </Button>
          <PopConfirm
            disabled={!record?.deviceId}
            okText="继续"
            title={'请注意，移除后无法查询该设备数据\n你还要继续吗？'}
            onConfirm={() => removeDevice({ id: record.id })}
          >
            <Button disabled={!record?.deviceId} type="link">
              移除设备
            </Button>
          </PopConfirm>
          <Button
            type="link"
            onClick={() => {
              setRow(record);
              setSelectDeviceModalVisible(true);
            }}
          >
            {record?.deviceId ? '更换设备' : '绑定设备'}
          </Button>
          {(isDraft || isDisable) && (
            <PopConfirm okText="确定" title={'确认删除组件吗？'} onConfirm={() => removeComp({ id: record.id })}>
              <Button type="link">删除</Button>
            </PopConfirm>
          )}
        </Space>
      ),
    }),
    [isDraft, isDisable, removeDevice, removeComp]
  );

  return (
    <>
      {importModalVisible && (
        <ImportModal
          pvId={pvId}
          onCancel={() => setImportModalVisible(false)}
          onOk={() => {
            setImportModalVisible(false);
            refresh();
          }}
        />
      )}
      {formModalVisible && (
        <FormModal
          pvId={pvId}
          isDraft={isDraft}
          isDisable={isDisable}
          row={row}
          onCancel={() => {
            setRow(undefined);
            setFormModalVisible(false);
          }}
          onOk={() => {
            setRow(undefined);
            setFormModalVisible(false);
            refresh();
          }}
          onNextStep={payload => {
            setFormModalVisible(false);
            setCreatingComp(payload);
            setSelectDeviceModalVisible(true);
          }}
        />
      )}
      {selectDeviceModalVisible && (
        <SelectDeviceModal
          pvId={pvId}
          pvType={PVType.MODULE}
          ouId={ouId}
          typeName={'组件'}
          initialDeviceId={row?.deviceId}
          onOk={deviceId => {
            if (creatingComp) {
              createNewComp({ ...creatingComp, deviceId }).then(() => {
                setCreatingComp(undefined);
                setSelectDeviceModalVisible(false);
              });
              return;
            }

            updateDevice({ id: row?.id, deviceId }).then(() => {
              setRow(undefined);
              setSelectDeviceModalVisible(false);
            });
          }}
          onCancel={() => {
            setRow(undefined);
            setSelectDeviceModalVisible(false);
          }}
        />
      )}

      <Spin spinning={isLoading}>
        <div className={styles.string}>
          <div className={styles.title}>{'组件'}</div>
          {isEdit ? (
            <div className={styles.header}>
              <Space>
                {(isDraft || isDisable) && (
                  <>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        setFormModalVisible(true);
                      }}
                    >
                      添加组件
                    </Button>
                    <Button
                      type="primary"
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        Modal.confirm({
                          title: '确认删除所有组件？',
                          onOk: () => removeAllComps({ id: pvId }),
                        });
                      }}
                    >
                      删除所有组件
                    </Button>
                    <Button type="primary" icon={<ImportOutlined />} onClick={() => setImportModalVisible(true)}>
                      导入组件
                    </Button>
                  </>
                )}
                <Button type="primary" icon={<ExportOutlined />} onClick={() => exportComps(pvId)}>
                  导出组件
                </Button>
              </Space>
              <div className={styles.count}>
                当前数量：<span style={{ color: '#F5222D' }}>{totalCount ?? 0}</span> 个
              </div>
            </div>
          ) : (
            <>
              <Filter
                form={form}
                onFinish={values => {
                  setSearchParams({ pvId, ...values });
                  setPageOffset(1);
                }}
                onReset={() => {
                  setSearchParams({ pvId });
                  setPageOffset(1);
                }}
              >
                <>
                  <Col span={8}>
                    <Form.Item name="pvModuleCode" label="组件编号">
                      <Input placeholder={'请输入'} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="pvStringCode" label={'关联组串'}>
                      <Input placeholder={'请输入'} />
                    </Form.Item>
                  </Col>
                </>
              </Filter>
              <Button type="primary" icon={<ExportOutlined />} onClick={() => exportComps(pvId)}>
                导出组件
              </Button>
            </>
          )}

          <Table
            style={{ marginTop: 18 }}
            sticky
            rowKey="id"
            scroll={{ x: 'max-content' }}
            columns={[...columns, ...(isEdit ? [actionColumn] : [])]}
            dataSource={data}
          />

          <Paging pagingInfo={pagingInfo} />
        </div>
      </Spin>
    </>
  );
};

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    ellipsis: { showTitle: true },
    render: (_v: any, _record: V2ModulePagePostResponseItem, index: number) => <EllipsisSpan value={index + 1} />,
  },
  {
    title: '组件编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备编号',
    dataIndex: 'deviceCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '关联组串',
    dataIndex: 'pvStringCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export default PVComp;
