import { Drawer, Spin } from 'antd';
import { CSSProperties, FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { Table, Button, EllipsisSpan, Tag, Tooltip } from '@maxtropy/components';
import { DoubleRightOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { CircuitPageProps, CircuitType, CircuitTypeFormat, getCircuitDetail } from '@/api/circuit';
import { apiV2CircuitDataRangePost, V2CircuitDataRangePostResponse } from '@maxtropy/dmes-apis-v2';
import { CircuitPhysicalQuantityType, CircuitPhysicalQuantityTypeDisplay, DataNameType } from '@/api/const';
import styles from './DetailDrawer.module.scss';

interface DetailDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  currentCircuitInfo: any;
}

type IndicatorItem = Exclude<V2CircuitDataRangePostResponse['list'], undefined>[number];

const indicatorColumns = [
  {
    title: '监控指标',
    dataIndex: 'name',
    width: '25%',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: (
      <>
        <span>指标</span>
        <Tooltip title="累积量为本日累计，瞬时量为当天最新数据，瞬时量数据时效30min将标红显示。">
          <InfoCircleOutlined style={{ marginLeft: 5, color: 'rgb(250,173,20)' }} />
        </Tooltip>
      </>
    ),
    dataIndex: 'type',
    width: '25%',
    render: (v: CircuitPhysicalQuantityType) => <EllipsisSpan value={CircuitPhysicalQuantityTypeDisplay[v]} />,
  },
  {
    title: '数值',
    dataIndex: 'value',
    width: '25%',
    render: (v: string, record: IndicatorItem) => (
      <EllipsisSpan
        value={
          <span className={Math.abs(dayjs(record.ts).diff(dayjs(), 'minute', true)) > 30 ? styles.colorRed : undefined}>
            {isNil(v) ? '--' : Number(v).toFixed(2)}
            {record?.unitName}
          </span>
        }
      />
    ),
  },
  {
    title: '数据时间',
    dataIndex: 'ts',
    width: '25%',
    render: (v: string, record: IndicatorItem) => (
      <EllipsisSpan
        value={
          <span className={Math.abs(dayjs(record.ts).diff(dayjs(), 'm', true)) > 30 ? styles.colorRed : undefined}>
            {v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'}
          </span>
        }
      />
    ),
  },
];

const tagColorMap = {
  [CircuitType.GRID_BILLING_CIRCUIT]: 'warning',
  [CircuitType.OUTGOING_CIRCUIT]: 'success',
  [CircuitType.INCOMING_CIRCUIT]: 'info',
  [CircuitType.BUS_COUPLER_CIRCUIT]: '#CE90D1',
  [CircuitType.TRANSFORMER_CIRCUIT]: '#52E7FF',
};

const breakWordsStyle: CSSProperties = {
  whiteSpace: 'normal',
  wordBreak: 'break-all',
  textAlign: 'left',
};

const DetailDrawer: FC<DetailDrawerProps> = props => {
  const { open, setOpen, currentCircuitInfo } = props;
  const [baseLoading, setBaseLoading] = useState<boolean>(false);
  const [baseData, setBaseData] = useState<CircuitPageProps>();
  const [indicatorLoading, setIndicatorLoading] = useState<boolean>(false);
  const [indicatorData, setIndicatorData] = useState<V2CircuitDataRangePostResponse['list']>([]);

  useEffect(() => {
    if (!currentCircuitInfo) return;
    setBaseLoading(true);
    setIndicatorLoading(true);
    getCircuitDetail(currentCircuitInfo.id)
      .then(res => {
        setBaseData(res);
      })
      .finally(() => {
        setBaseLoading(false);
      });

    const showIdList = [
      DataNameType.EP,
      DataNameType.FPE,
      DataNameType.BPE,
      DataNameType.P,
      DataNameType.IA,
      DataNameType.IB,
      DataNameType.IC,
    ];
    setIndicatorData([]);
    apiV2CircuitDataRangePost({ id: currentCircuitInfo.id })
      .then(res => {
        const data = res.list?.filter(item => showIdList.includes(item.id!)) || [];
        const pItem = res.list?.find(item => item.id === DataNameType.P);
        if (pItem) {
          const loadRate = isNil(pItem.value) ? null : (pItem.value / currentCircuitInfo.value.capacity) * 100;
          data.push({
            name: '负载率',
            value: loadRate as number,
            unitName: '%',
            type: pItem.type,
            ts: pItem.ts,
          });
        }
        setIndicatorData(data);
      })
      .finally(() => {
        setIndicatorLoading(false);
      });
  }, [currentCircuitInfo]);

  const title = (
    <div className={styles.drawerTitle}>
      <EllipsisSpan className={styles.titleName} value={currentCircuitInfo?.value?.name}></EllipsisSpan>
      <Tag border="solid" color={tagColorMap[currentCircuitInfo?.value?.type as CircuitType]} className={styles.tag}>
        {CircuitTypeFormat[currentCircuitInfo?.value?.type as CircuitType]}
      </Tag>
    </div>
  );

  return (
    <Drawer
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      width={400}
      getContainer={false}
      mask={false}
      placement="right"
      title={title}
      className={styles.detailDrawer}
    >
      <div className={styles.cardTitle}>
        <span className={styles.titleTxt}>基础信息</span>
        <Button
          type="link"
          href={window.IOTPLATFORMORIGIN + '/dc/circuit/circuitManage/detail/' + currentCircuitInfo?.id}
          target="_blank"
          style={{
            paddingRight: 0,
          }}
        >
          <span>查看详情</span>
          <DoubleRightOutlined />
        </Button>
      </div>

      <Spin spinning={baseLoading}>
        <div className={styles.card}>
          <div className={styles.cardRow}>
            <div className={styles.label}>绑定设备：</div>
            <div className={styles.value}>
              {baseData?.devices?.map((item, index) => (
                <div className={styles.bindDevice} key={index}>
                  <Button
                    type="link"
                    style={{ padding: 0, ...breakWordsStyle }}
                    href={window.IOTPLATFORMORIGIN + '/data/history/device?deviceId=' + item.deviceDto.id}
                    target="_blank"
                  >
                    {item.deviceDto.name}
                  </Button>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.cardRow}>
            <div className={styles.label}>所属配电柜：</div>
            <div className={styles.value}>
              <span style={breakWordsStyle}>{baseData?.distributionCabinetName}</span>
            </div>
          </div>
          <div className={styles.cardRow}>
            <div className={styles.label}>所属运营单元：</div>
            <div className={styles.value}>
              <span style={breakWordsStyle}>{baseData?.ouName}</span>
            </div>
          </div>
          <div className={styles.cardRow}>
            <div className={styles.label}>所属UET：</div>
            <div className={styles.value}>
              <Button
                style={{ padding: 0, ...breakWordsStyle }}
                type="link"
                href={window.IOTPLATFORMORIGIN + `/energy/basic/uet/detail/${baseData?.uetId}/microgridPowerTopo`}
                target="_blank"
              >
                {baseData?.uetName}
              </Button>
            </div>
          </div>
        </div>
      </Spin>

      <div className={styles.cardTitle} style={{ marginTop: 24 }}>
        <span className={styles.titleTxt}>指标数据</span>
        <Button
          type="link"
          href={'/dmes/statistics/circuit?id=' + baseData?.id}
          target="_blank"
          style={{ paddingRight: 0 }}
        >
          <span>查看详情</span>
          <DoubleRightOutlined />
        </Button>
      </div>

      <Table
        rowKey="id"
        columns={indicatorColumns}
        dataSource={indicatorData}
        loading={indicatorLoading}
        pagination={false}
      ></Table>
    </Drawer>
  );
};

export default DetailDrawer;
