import { Layout } from 'antd';
import DragResize from '@/components/DragResize';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { MXTabsProps, Tabs, useBreadcrumbRoutes, Wrapper } from '@maxtropy/components';
import React, { useRef, useState } from 'react';
import styles from './index.module.scss';
import PvTree from './components/PvTree';
import dayjs, { Dayjs } from 'dayjs';
import { useLocation } from 'react-router-dom';
import { useHasPermission } from '@/utils/hooks';
import { PermissionsType } from '@/common/permissionsConst';
import ElecStatistics, { ElecStatisticsRef } from './components/ElecStatistics';
import ElecRecord from './components/ElecRecord';

const { Sider, Content } = Layout;

export enum ResolutionEnum {
  DAY = 'D',
  MONTH = 'M',
}

const PvIncomeReport = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [siderWidth, setSiderWidth] = useState(260);
  const [dragStatus, setDragStatus] = useState(false);
  const [openSider, setOpenSider] = useState(true);

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const url_pvId = urlSearchParams.get('pvId') || undefined;
  const [pvId, setPvId] = useState<number | undefined>(url_pvId ? Number(url_pvId) : undefined);
  const [timeRange, setTimeRange] = useState<[Dayjs, Dayjs]>([dayjs().subtract(31, 'days'), dayjs()]);

  // 电量统计tab权限
  const hasPermissionTabElecStatistics = useHasPermission(PermissionsType.B_PVELECTRICITYSTATISTICSTAB);
  // 抄表记录tab权限
  const hasPermissionTabElecRecord = useHasPermission(PermissionsType.B_PVMETERREADINGRECORD);

  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  // 用于解决切换tab时，时间范围有变化，两个tab内都会请求数据的问题
  const [showTabItem, setShowTabItem] = useState(true);
  const [activeTabKey, setActiveTabKey] = useState('elecStatistics');
  const elecStatisticsRef = useRef<ElecStatisticsRef>(null);

  const tabItems: MXTabsProps['items'] = [
    {
      key: 'elecStatistics',
      label: '电量统计',
      show: hasPermissionTabElecStatistics,
      children: showTabItem && (
        <ElecStatistics ref={elecStatisticsRef} timeRange={timeRange} setTimeRange={setTimeRange} pvId={pvId} />
      ),
    },
    {
      key: 'elecRecord',
      label: '抄表记录',
      show: hasPermissionTabElecRecord,
      children: showTabItem && <ElecRecord timeRange={timeRange} setTimeRange={setTimeRange} pvId={pvId} />,
    },
  ]
    .filter(item => item.show)
    .map(item => {
      const { show, ...others } = item;
      return others;
    });

  return (
    <Wrapper routes={breadcrumbRoutes?.routes ?? []} className={styles.wrapper}>
      <Layout className={styles.layout}>
        <Sider
          theme="light"
          width={openSider ? siderWidth : 0}
          style={{ transition: dragStatus ? 'none' : 'all 0.2s', background: '#232324' }}
        >
          <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
          <PvTree
            defaultPvId={url_pvId ? Number(url_pvId) : undefined}
            onSelect={pvId => {
              setPvId(pvId);
            }}
          />
        </Sider>

        <Content className={styles.content}>
          <div
            className={styles.toggleBtn}
            style={{ left: openSider ? -30 : 0 }}
            onClick={() => setOpenSider(!openSider)}
          >
            {openSider ? <LeftOutlined /> : <RightOutlined />}
          </div>

          <Tabs
            activeKey={activeTabKey}
            items={tabItems}
            onChange={key => {
              setShowTabItem(false);
              // 如果在按月状态下，从电量统计切换到抄表记录，则重置时间范围
              if (key === 'elecRecord' && elecStatisticsRef.current?.timeResolution === 'M') {
                setTimeRange([dayjs().subtract(31, 'days'), dayjs()]);
              }
              // 如果从抄表记录换到电量统计，日期范围超过31天，则按末尾时间向前推31天
              if (key === 'elecStatistics' && timeRange[1].diff(timeRange[0], 'days') > 31) {
                setTimeRange([timeRange[1].subtract(31, 'days'), timeRange[1]]);
              }
              setActiveTabKey(key);
              setTimeout(() => {
                setShowTabItem(true);
              });
            }}
            selectedLinePlacement="top"
            bordered
            destroyInactiveTabPane
          />
        </Content>
      </Layout>
    </Wrapper>
  );
};

export default PvIncomeReport;
