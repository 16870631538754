import { PermissionsType } from '@/common/permissionsConst';
import PVPowerForecast from '.';

const routes = [
  {
    path: '/pv/powerAndIncome/forecast',
    permission: PermissionsType.P_POWERGENERATIONFORECAST,
    element: <PVPowerForecast />,
  },
];
export default routes;
