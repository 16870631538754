import { PermissionsType } from '@/common/permissionsConst';
import PvDispersionRateConfig from './index';
import AnalysisConfigSetting from './AnalysisConfigSetting';

const routes = [
  {
    path: '/pv/dispersionRate/config',
    permission: PermissionsType.P_PVA_DISPERSION_SET,
    element: <PvDispersionRateConfig />,
  },
  {
    path: '/pv/dispersionRate/config/setting/create',
    element: <AnalysisConfigSetting isEdit={true} />,
  },
  {
    path: '/pv/dispersionRate/config/setting/detail/:id',
    element: <AnalysisConfigSetting isEdit={false} />,
  },
  {
    path: '/pv/dispersionRate/config/setting/edit/:id',
    element: <AnalysisConfigSetting isEdit={true} />,
  },
];
export default routes;
