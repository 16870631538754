import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'antd';
import { isNil } from 'lodash-es';
import {
  BIT_MODE,
  BIT_NUM,
  BYTE_NUM,
  DataProperty,
  DataPropertyType,
  LENGTH,
  OBJECT_TYPE,
  VALUE_TYPE,
  bitModeFormatter,
  byteNumFormatter,
  bitNumFormatter,
  lengthFormatter,
  objectFormatter,
  BsaDeviceTypeEnum,
} from '../../../../../api/type';
import FormSubmit from '../FormSubmit';
import { checkNum, getHandleModeMapping, getValueTypeMapping, kOrbDisabled } from './utils';
import { EdgeDeviceTemplatePoint } from '.';
import { useDataProperties } from '../hooks';
import FormulaFormItem from '../../FormulaFormItem';
import { useWatch } from 'antd/es/form/Form';
import { Select, Switch, InputNumber, Form, Input, Modal, Radio } from '@maxtropy/components';
import { useParams } from 'react-router-dom';
import { getBsa } from '@/api/bsa';
import styles from './index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

export function getDefaultHandleModeAndValueType(
  length: LENGTH | undefined,
  bitMode?: BIT_MODE,
  byteNum?: BYTE_NUM,
  bitNum?: BIT_NUM
) {
  if (length === LENGTH.ONE) {
    if (!isNil(bitMode)) {
      if (bitMode === BIT_MODE.NULL) {
        return {
          handleMode: 1, // 2,1
          valueType: VALUE_TYPE.UNSIGNEDINTERGER, // 无符号整型数
        };
      } else if (bitMode === BIT_MODE.BIT) {
        return {
          bitNum: BIT_NUM.BIT_0, // 2,1
          valueType: VALUE_TYPE.BOOLEAN, // 无符号整型数
        };
      } else {
        return {
          byteNum: BYTE_NUM.HIGHBYTE, // 2,1
          valueType: VALUE_TYPE.UNSIGNEDINTERGER, // 无符号整型数
        };
      }
    }

    if (!isNil(byteNum)) {
      return {
        valueType: VALUE_TYPE.UNSIGNEDINTERGER,
      };
    }

    if (!isNil(bitNum)) {
      return {
        valueType: VALUE_TYPE.BOOLEAN,
      };
    }
  }
  if (length === LENGTH.TWO) {
    // 此时mode只会是无
    return {
      handleMode: 3, // 4,3,2,1
      valueType: VALUE_TYPE.FLOAT, // 单精度浮点数
    };
  }
  if (length === LENGTH.FOUR) {
    // 此时mode只会是无
    return {
      handleMode: 7, // 8,7,6,5,4,3,2,1
      valueType: VALUE_TYPE.DOBULE, // 双精度浮点数
    };
  }
  return {};
}

export interface PointFormDialogProps {
  row?: Partial<EdgeDeviceTemplatePoint>;
  dataPropertiesAll?: DataProperty[];
  usedProperties?: number[];
  usedIdentifier?: string[];
  onCancel: () => void;
  onOk: (values: any, shouldContinue?: boolean) => void;
  bsaDeviceType: BsaDeviceTypeEnum;
}

const PointFormDialog: React.FC<PointFormDialogProps> = ({
  row,
  dataPropertiesAll,
  usedProperties,
  usedIdentifier,
  onCancel,
  onOk,
  bsaDeviceType,
}) => {
  const { dataProperties, setDataPropertyId, units, dataPropertyType } = useDataProperties(
    dataPropertiesAll,
    usedProperties
  );

  const { id } = useParams<{ id: string }>();

  const [form] = Form.useForm();
  const { setFieldsValue, getFieldValue } = form;

  const cellQuantitativeLimit = useWatch('cellQuantitativeLimit', form);
  const [cellQuantitativeLimitNumMax, setCellQuantitativeLimitNumMax] = useState(Infinity);
  useEffect(() => {
    setCellQuantitativeLimitNumMax(Infinity);
    getBsa(id).then(res => {
      setCellQuantitativeLimitNumMax(res.cellCountInPack);
    });
  }, [id]);

  const initStatusRef = useRef(true);

  const initFormValues = useCallback(
    (record: Partial<EdgeDeviceTemplatePoint>) => {
      const { objectType, length, bitMode, handleMode, bitNum, byteNum, valueType, ...rest } = record;
      Promise.resolve()
        .then(() => setFieldsValue({ objectType, valueType }))
        .then(() => {
          setFieldsValue({ length: length ?? LENGTH.ONE });
        })
        .then(() => {
          setFieldsValue({ bitMode: bitMode ?? BIT_MODE.NULL });
        })
        .then(() => {
          setFieldsValue({ handleMode: handleMode ?? 0 });
        })
        .then(() => {
          setFieldsValue({ bitNum: bitNum ?? BIT_NUM.BIT_0 });
        })
        .then(() => {
          setFieldsValue({ byteNum: byteNum ?? BYTE_NUM.HIGHBYTE });
        })
        .then(() => setFieldsValue({ ...rest }))
        .finally(() => {
          initStatusRef.current = false;
        });
    },
    [setFieldsValue]
  );

  // 初始化
  useEffect(() => {
    if (row) {
      // 编辑 和 保存并继续添加
      initFormValues(row);
    }
  }, [initFormValues, row]);

  // 改变objectType
  const onObjectTypeChange = (objectType: OBJECT_TYPE) => {
    if (initStatusRef.current) return;
    if (objectType === OBJECT_TYPE.COILSTATUS || objectType === OBJECT_TYPE.INPUTSTATUS) {
      setFieldsValue({
        valueType: VALUE_TYPE.BOOLEAN,
      });
    }
    if (objectType === OBJECT_TYPE.SAVEREGISTER || objectType === OBJECT_TYPE.INPUTREGISTER) {
      Promise.resolve()
        .then(() =>
          setFieldsValue({
            length: LENGTH.ONE,
            bitMode: BIT_MODE.NULL,
          })
        )
        .then(() => setFieldsValue(getDefaultHandleModeAndValueType(LENGTH.ONE, BIT_MODE.NULL)));
    }
  };

  useEffect(() => {
    if (units.length === 1) {
      form.setFieldsValue({
        physicalUnitId: units[0].id,
        physicalUnitGeneralName: units[0]?.generalName,
      });
    } else {
      form.setFieldsValue({
        physicalUnitId: undefined,
        physicalUnitGeneralName: '',
      });
    }
  }, [units, form]);

  // 改变length
  const onLengthChange = (length: LENGTH) => {
    if (initStatusRef.current) return;
    // form.setFieldsValue({
    //   valueType: undefined
    // })
    Promise.resolve()
      .then(() =>
        setFieldsValue({
          bitMode: BIT_MODE.NULL,
        })
      )
      .then(() => {
        setFieldsValue(getDefaultHandleModeAndValueType(length, BIT_MODE.NULL));
      });
  };

  // 改变bitMode
  const onBitModeChange = (bitMode: BIT_MODE) => {
    if (initStatusRef.current) return;
    form.setFieldsValue({
      handleMode: undefined,
      byteNum: undefined,
      bitNum: undefined,
    });
    setFieldsValue(getDefaultHandleModeAndValueType(getFieldValue('length'), bitMode));
  };

  // 改变byteNum
  const onByteNumChange = (byteNum: BYTE_NUM) => {
    if (initStatusRef.current) return;
    form.setFieldsValue({
      valueType: undefined,
    });
    setFieldsValue(getDefaultHandleModeAndValueType(getFieldValue('length'), undefined, byteNum));
  };

  // 改变byteNum
  const onBitNumChange = (bitNum: BIT_NUM) => {
    if (initStatusRef.current) return;
    form.setFieldsValue({
      valueType: undefined,
    });
    setFieldsValue(getDefaultHandleModeAndValueType(getFieldValue('length'), undefined, undefined, bitNum));
  };

  const onValuesChange = (changedValues: Partial<EdgeDeviceTemplatePoint>) => {
    const { objectType, length, bitMode, byteNum, bitNum } = changedValues;

    if (!isNil(objectType)) {
      onObjectTypeChange(objectType);
    }
    if (!isNil(length)) {
      onLengthChange(length);
    }
    if (!isNil(bitMode)) {
      onBitModeChange(bitMode);
    }
    if (!isNil(byteNum)) {
      onByteNumChange(byteNum);
    }
    if (!isNil(bitNum)) {
      onBitNumChange(bitNum);
    }
  };

  useEffect(() => {
    if (row) {
      setDataPropertyId(row.dataPropertyId);
    }
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row, form]);

  const submit = (shouldContinue?: boolean) => {
    form.validateFields().then(values => {
      if (shouldContinue) {
        onOk(values, shouldContinue);
        form.resetFields();
      } else {
        onOk(values, shouldContinue);
        onCancel();
      }
    });
  };

  return (
    <Modal
      size="big"
      contentClassName="modal-form-content"
      open={row !== undefined}
      onCancel={onCancel}
      title={`${row?.id ? '编辑' : '添加'}采集点创建公式`}
      centered={true}
      maskClosable={false}
      footer={<FormSubmit submit={submit} cancel={onCancel} />}
    >
      <div className={styles.formItemFlex}>
        <Form form={form} onValuesChange={onValuesChange} labelWrap>
          <Form.Item noStyle name="dataPropertyName" />
          <Form.Item noStyle name="physicalUnitGeneralName" />
          <Form.Item
            name="hasProperty"
            label="采集点类型"
            initialValue={true}
            rules={[{ required: true, message: '请选择' }]}
            shouldUpdate
          >
            <Radio.Group buttonStyle="solid" disabled={!isNil(row?.id)}>
              <Radio.Button value={true}>建模采集点</Radio.Button>
              <Radio.Button value={false}>非建模采集点</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            noStyle
            // dependencies={['hasProperty']}
            shouldUpdate={(prevValues, curValues) => prevValues.hasProperty !== curValues.hasProperty}
          >
            {({ getFieldValue }) =>
              getFieldValue('hasProperty') ? (
                <>
                  <Form.Item
                    name="dataPropertyId"
                    label="数据属性"
                    rules={[{ required: true, message: '请选择数据属性' }]}
                  >
                    <Select
                      onChange={value => {
                        form.setFieldsValue({
                          dataPropertyName: dataProperties?.find(item => item.id === value)?.name,
                        });
                        setDataPropertyId(value as number);
                        // form.setFieldsValue({
                        //   physicalUnitId: undefined,
                        //   physicalUnitGeneralName: "",
                        // });
                      }}
                      placeholder="请输入/选择"
                      showSearch
                      filterOption={(input, option) => option!.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      options={(dataProperties ?? []).map(i => ({ label: i.name, value: i.id }))}
                      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
                    />
                  </Form.Item>
                  {dataPropertyType === DataPropertyType.YC && (
                    <Form.Item name="physicalUnitId" label="单位" rules={[{ required: true, message: '请选择单位' }]}>
                      <Select
                        onChange={value => {
                          form.setFieldsValue({
                            physicalUnitGeneralName: units?.find(item => item.id === value)?.generalName,
                          });
                        }}
                        placeholder="请选择"
                      >
                        {units?.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.generalName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </>
              ) : (
                <Form.Item
                  required
                  name="identifier"
                  label="非建模采集点标识符"
                  rules={[
                    {
                      validator: async (rule, value) => {
                        if (!value) {
                          return Promise.reject('请填写非建模采集点标识符');
                        }
                        if (value.length > 30) {
                          return Promise.reject('长度不能超过30个字符');
                        }
                        if (!/^[a-zA-Z]/.test(value)) {
                          return Promise.reject(new Error('开头必须是英文字母，请修改'));
                        }
                        if (!/^[a-zA-Z][a-zA-Z0-9]*$/.test(value)) {
                          return Promise.reject(new Error('只能输入英文和数字'));
                        }
                        if (usedIdentifier?.includes(value)) {
                          return Promise.reject(new Error('标识符重复，请修改'));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input placeholder="请输入" />
                </Form.Item>
              )
            }
          </Form.Item>
          <Form.Item
            name="writable"
            label="是否可写"
            initialValue={true}
            valuePropName="checked"
            rules={[{ required: true, message: '请选择' }]}
          >
            <Switch />
          </Form.Item>
          {/* 电芯额外添加“非全部设备添加输入” */}
          {bsaDeviceType === BsaDeviceTypeEnum.CELL && (
            <Row>
              <Col span={8}>
                <Form.Item required valuePropName="checked" name="cellQuantitativeLimit" label="非全部设备添加">
                  <Switch />
                </Form.Item>
              </Col>
              {cellQuantitativeLimit && (
                <Col span={16}>
                  <Form.Item
                    name="cellQuantitativeLimitNum"
                    label="各组应用电芯数"
                    rules={[
                      {
                        required: true,
                        message: `请输入1~${cellQuantitativeLimitNumMax}间的整数`,
                      },
                      {
                        type: 'integer',
                        min: 1,
                        max: cellQuantitativeLimitNumMax,
                        message: `请输入1~${cellQuantitativeLimitNumMax}间的整数`,
                      },
                    ]}
                  >
                    <InputNumber style={{ width: '100%' }}></InputNumber>
                  </Form.Item>
                </Col>
              )}
            </Row>
          )}
          <Form.Item required label="起始地址">
            <FormulaFormItem bsaDeviceType={bsaDeviceType} />
          </Form.Item>
          <Form.Item name="objectType" label="功能码" rules={[{ required: true, message: '请选择' }]}>
            <Radio.Group buttonStyle="solid">
              <Radio.Button value={OBJECT_TYPE.COILSTATUS}>{objectFormatter[OBJECT_TYPE.COILSTATUS]}</Radio.Button>
              <Radio.Button value={OBJECT_TYPE.INPUTSTATUS}>{objectFormatter[OBJECT_TYPE.INPUTSTATUS]}</Radio.Button>
              <Radio.Button value={OBJECT_TYPE.SAVEREGISTER}>{objectFormatter[OBJECT_TYPE.SAVEREGISTER]}</Radio.Button>
              <Radio.Button value={OBJECT_TYPE.INPUTREGISTER}>
                {objectFormatter[OBJECT_TYPE.INPUTREGISTER]}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item noStyle dependencies={['objectType']} shouldUpdate>
            {({ getFieldValue }) => {
              const objectType = getFieldValue('objectType');
              return (
                (objectType === OBJECT_TYPE.SAVEREGISTER || objectType === OBJECT_TYPE.INPUTREGISTER) && (
                  <Form.Item name="length" label="长度" rules={[{ required: true, message: '请选择' }]}>
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value={LENGTH.ONE}>{lengthFormatter[LENGTH.ONE]}</Radio.Button>
                      <Radio.Button value={LENGTH.TWO}>{lengthFormatter[LENGTH.TWO]}</Radio.Button>
                      <Radio.Button value={LENGTH.FOUR}>{lengthFormatter[LENGTH.FOUR]}</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                )
              );
            }}
          </Form.Item>
          <Form.Item noStyle dependencies={['objectType', 'length']} shouldUpdate>
            {({ getFieldValue }) => {
              const objectType = getFieldValue('objectType');
              const length = getFieldValue('length');
              return (
                (objectType === OBJECT_TYPE.SAVEREGISTER || objectType === OBJECT_TYPE.INPUTREGISTER) && (
                  <Form.Item name="bitMode" label="位操作模式" rules={[{ required: true, message: '请选择' }]}>
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value={BIT_MODE.NULL}>{bitModeFormatter[BIT_MODE.NULL]}</Radio.Button>
                      {length === LENGTH.ONE && (
                        <>
                          <Radio.Button value={BIT_MODE.BIT}>{bitModeFormatter[BIT_MODE.BIT]}</Radio.Button>
                          <Radio.Button value={BIT_MODE.BYTE}>{bitModeFormatter[BIT_MODE.BYTE]}</Radio.Button>
                        </>
                      )}
                    </Radio.Group>
                  </Form.Item>
                )
              );
            }}
          </Form.Item>
          <Form.Item noStyle dependencies={['objectType', 'length', 'bitMode']} shouldUpdate>
            {({ getFieldValue }) => {
              const objectType = getFieldValue('objectType');
              const length = getFieldValue('length');
              const bitMode = getFieldValue('bitMode');
              const formatter = getHandleModeMapping(objectType, length);
              const visible =
                (objectType === OBJECT_TYPE.SAVEREGISTER || objectType === OBJECT_TYPE.INPUTREGISTER) &&
                length !== undefined &&
                (length !== LENGTH.ONE || bitMode === BIT_MODE.NULL);

              return (
                visible && (
                  <Form.Item name="handleMode" label="处理方式" rules={[{ required: true, message: '请选择' }]}>
                    <Select
                      placeholder="请选择"
                      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
                    >
                      {Object.entries(formatter || {}).map(([value, key]) => {
                        return (
                          <Option key={value} value={Number(value)}>
                            {key}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )
              );
            }}
          </Form.Item>
          <Form.Item noStyle dependencies={['objectType', 'length', 'bitMode']} shouldUpdate>
            {({ getFieldValue }) => {
              const objectType = getFieldValue('objectType');
              // const length = getFieldValue('length');
              const bitMode = getFieldValue('bitMode');
              // const formatter = getHandleModeMapping(objectType, length);
              const visible =
                (objectType === OBJECT_TYPE.SAVEREGISTER || objectType === OBJECT_TYPE.INPUTREGISTER) &&
                bitMode === BIT_MODE.BIT;

              return (
                visible && (
                  <Form.Item name="bitNum" label="读取位号" rules={[{ required: true, message: '请选择' }]}>
                    <Select
                      placeholder="请选择"
                      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
                    >
                      {Object.entries(bitNumFormatter || {}).map(([value, key]) => {
                        return (
                          <Option key={value} value={Number(value)}>
                            {key}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )
              );
            }}
          </Form.Item>
          <Form.Item noStyle dependencies={['objectType', 'length', 'bitMode']} shouldUpdate>
            {({ getFieldValue }) => {
              const objectType = getFieldValue('objectType');
              // const length = getFieldValue('length');
              const bitMode = getFieldValue('bitMode');
              // const formatter = getHandleModeMapping(objectType, length);
              const visible =
                (objectType === OBJECT_TYPE.SAVEREGISTER || objectType === OBJECT_TYPE.INPUTREGISTER) &&
                bitMode === BIT_MODE.BYTE;

              return (
                visible && (
                  <Form.Item
                    name="byteNum"
                    label="读取字节"
                    rules={[{ required: true, message: '请选择' }]}
                    initialValue={BYTE_NUM.HIGHBYTE}
                  >
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value={BYTE_NUM.LOWBYTE}>{byteNumFormatter[BYTE_NUM.LOWBYTE]}</Radio.Button>
                      <Radio.Button value={BYTE_NUM.HIGHBYTE}>{byteNumFormatter[BYTE_NUM.HIGHBYTE]}</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                )
              );
            }}
          </Form.Item>
          <Form.Item noStyle dependencies={['objectType', 'length', 'bitMode']} shouldUpdate>
            {({ getFieldValue }) => {
              const objectType = getFieldValue('objectType');
              const length = getFieldValue('length');
              const bitMode = getFieldValue('bitMode');
              const formatter = getValueTypeMapping(objectType, length, bitMode);
              return (
                <Form.Item name="valueType" label="解码类型" rules={[{ required: true, message: '请选择' }]}>
                  <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}>
                    {Object.entries(formatter || {}).map(([value, key]) => {
                      return (
                        <Option key={value} value={Number(value)}>
                          {key}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item noStyle dependencies={['objectType']} shouldUpdate>
            {({ getFieldValue }) => {
              const objectType = getFieldValue('objectType');
              const disabled = kOrbDisabled(objectType);
              return (
                <>
                  <Form.Item
                    name="k"
                    label="乘积系数k"
                    tooltip={{
                      title: 'y=kx+b，x为直采数据，y为输出数据，k为乘积系数，b为偏移系数',
                      icon: <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />,
                    }}
                    initialValue={1}
                    rules={[{ required: true, message: '请输入' }, { validator: checkNum }]}
                  >
                    <Input placeholder="请输入-999999999~999999999的数字" disabled={disabled} />
                  </Form.Item>
                  <Form.Item
                    name="b"
                    label="偏移系数b"
                    tooltip={{
                      title: 'y=kx+b，x为直采数据，y为输出数据，k为乘积系数，b为偏移系数',
                      icon: <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />,
                    }}
                    initialValue={0}
                    rules={[{ required: true, message: '请输入' }, { validator: checkNum }]}
                  >
                    <Input placeholder="请输入-999999999~999999999的数字" disabled={disabled} />
                  </Form.Item>
                </>
              );
            }}
          </Form.Item>
          <Form.Item
            label="数值合理区间"
            tooltip={{
              title: '区间外数值将被直接滤除，视为无效数值',
              icon: <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />,
            }}
          >
            <Row gutter={8}>
              <Col span={11}>
                <Form.Item name="valueFloor" noStyle rules={[{ validator: checkNum }]}>
                  <Input placeholder="请输入" />
                </Form.Item>
              </Col>
              <Col span={2} style={{ textAlign: 'center', paddingTop: 5 }}>
                ~
              </Col>
              <Col span={11}>
                <Form.Item name="valueCeiling" noStyle rules={[{ validator: checkNum }]}>
                  <Input placeholder="请输入" />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item label="备注" name="remark" rules={[{ max: 50, message: '长度不能超过50个字符' }]}>
            <Input placeholder="请输入备注" />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default PointFormDialog;
