import { Form, Input, Modal, Radio, Select } from '@maxtropy/components';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DataRow } from './DataTable';
import { DataPropertyType } from '@/api/type';
import FormSubmit from '@/pages/Bsa/components/Point/FormSubmit';
import { isNil } from 'lodash-es';
import { useQuery } from '@/utils/utils';
import { getPhysicalUnits } from '@/api/physicalUnit';
import ShowInput from '@/components/ShowInput';
import { identifierValidatorBuilder } from '../const';
import { DataProperty } from './index';
import FormulaInput, { formatOnSave, formulaValidator } from '@/components/FormulaInput';
import NumberRange from '../components/NumberRange';

const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 13 },
};

interface VirtualDialogProps {
  row: DataRow | undefined;
  onCancel: () => void;
  onOk: (values: any, shouldContinue?: boolean) => void;
  dataPropertiesAll: DataProperty[];
  usedProperties: number[];
  usedIdentifier: string[];
  alterMode?: boolean;
}

const VirtualDialog: FC<VirtualDialogProps> = props => {
  const { row, onOk, onCancel, usedIdentifier, usedProperties, dataPropertiesAll, alterMode = false } = props;

  const [form] = Form.useForm();
  const [dataPropertyId, setDataPropertyId] = useState<number>();

  useEffect(() => {
    form.resetFields();
    if (row) {
      setDataPropertyId(row.dataPropertyId);
      form.setFieldsValue(row);
    }
  }, [row]);

  const { data: units } = useQuery(
    useCallback(() => (dataPropertyId ? getPhysicalUnits(dataPropertyId) : Promise.resolve([])), [dataPropertyId])
  );

  const dataProperties = useMemo(
    () => dataPropertiesAll?.filter(item => !usedProperties.includes(item.id!)),
    [dataPropertiesAll, usedProperties]
  );

  const dataPropertyType = useMemo(
    () => dataProperties?.find(item => item.id === dataPropertyId)?.type,
    [dataProperties, dataPropertyId]
  );

  const submit = async (shouldContinue?: boolean) => {
    const values = await form.validateFields();
    const { valueRange, formula, ...rest } = values;
    const _values = {
      ...rest,
      valueCeiling: valueRange?.[1],
      valueFloor: valueRange?.[0],
      formula: formatOnSave(formula),
    };
    if (shouldContinue) {
      onOk(_values, true);
    } else {
      onOk(_values, false);
      onCancel();
    }
    form.resetFields();
  };

  return (
    <Modal
      open={row !== undefined}
      onCancel={onCancel}
      title={`${row?.id ? '编辑' : '添加'}虚拟点`}
      centered={true}
      maskClosable={false}
      footer={<FormSubmit submit={submit} cancel={onCancel} />}
    >
      <Form form={form} initialValues={row} {...formLayout}>
        <Form.Item noStyle name="pointType" />
        {alterMode ? <Form.Item noStyle name="dataPropertyId" /> : <Form.Item noStyle name="dataPropertyName" />}
        <Form.Item noStyle name="physicalUnitGeneralName" />
        {alterMode ? (
          <Form.Item noStyle name="hasProperty" />
        ) : (
          <Form.Item
            name="hasProperty"
            label="虚拟点类型"
            rules={[{ required: true, message: '请选择虚拟点类型' }]}
            initialValue={true}
          >
            <Radio.Group
              disabled={!isNil(row?.id)}
              onChange={() => {
                form.setFieldsValue({
                  dataPropertyId: undefined,
                  dataPropertyName: '',
                  physicalUnitId: undefined,
                  physicalUnitGeneralName: '',
                  identifier: '',
                });
              }}
            >
              <Radio value={true}>建模虚拟点</Radio>
              <Radio value={false}>非建模虚拟点</Radio>
            </Radio.Group>
          </Form.Item>
        )}

        {alterMode ? (
          <>
            <Form.Item
              name={row?.hasProperty ? 'dataPropertyName' : 'identifier'}
              label={row?.hasProperty ? '数据属性' : '非建模点标识符'}
            >
              <ShowInput />
            </Form.Item>
            {dataPropertyType === DataPropertyType.YC && (
              <Form.Item name="physicalUnitId" label="单位" rules={[{ required: true, message: '请选择单位' }]}>
                <Select
                  placeholder="请输入/选择"
                  showSearch
                  onSearch={value => units?.filter(item => item.generalName.includes(value))}
                  onChange={value => {
                    form.setFieldsValue({
                      physicalUnitGeneralName: units?.find(item => item.id === value)?.generalName,
                    });
                  }}
                >
                  {units?.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.generalName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </>
        ) : (
          <Form.Item
            noStyle
            shouldUpdate={(prevValues: any, curValues: any) => prevValues.hasProperty !== curValues.hasProperty}
          >
            {/*@ts-ignore*/}
            {({ getFieldsValue }) => {
              const { hasProperty } = getFieldsValue();
              return hasProperty ? (
                <>
                  <Form.Item
                    name="dataPropertyId"
                    label="数据属性"
                    rules={[{ required: true, message: '请选择数据属性' }]}
                  >
                    <Select
                      onChange={value => {
                        form.setFieldsValue({
                          dataPropertyName: dataProperties?.find(item => item.id === value)?.name,
                        });
                        setDataPropertyId(value as number);
                        form.setFieldsValue({
                          physicalUnitId: undefined,
                          physicalUnitGeneralName: '',
                        });
                      }}
                      placeholder="请输入/选择"
                      showSearch
                      filterOption={(input, option) =>
                        (option!.children as unknown as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dataProperties?.map(item => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  {dataPropertyType === DataPropertyType.YC && (
                    <Form.Item name="physicalUnitId" label="单位" rules={[{ required: true, message: '请选择单位' }]}>
                      <Select
                        placeholder="请输入/选择"
                        showSearch
                        onSearch={value => units?.filter(item => item.generalName.includes(value))}
                        onChange={value => {
                          form.setFieldsValue({
                            physicalUnitGeneralName: units?.find(item => item.id === value)?.generalName,
                          });
                        }}
                      >
                        {units?.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.generalName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </>
              ) : (
                <Form.Item
                  name="identifier"
                  label="非建模虚拟点标识符"
                  validateFirst
                  required
                  rules={[
                    {
                      validator: identifierValidatorBuilder(usedIdentifier),
                    },
                  ]}
                >
                  <Input placeholder="请输入" />
                </Form.Item>
              );
            }}
          </Form.Item>
        )}

        <Form.Item
          name="writable"
          label="是否可写"
          rules={[{ required: true, message: '请选择是否可写' }]}
          initialValue={false}
        >
          <Radio.Group>
            <Radio value={true}>是</Radio>
            <Radio value={false}>否</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="formula"
          label="公式"
          validateFirst
          rules={[{ required: true, message: '请填写公式' }, { validator: formulaValidator }]}
        >
          <FormulaInput
            identifier={usedIdentifier.map(value => ({
              id: `x000${value}()`,
              display: value,
              description: '非建模',
            }))}
            dataProperties={dataPropertiesAll.map(item => ({
              id: `x_${item.id}`,
              display: item.name!,
              description: '建模',
            }))}
          />
        </Form.Item>
        <Form.Item name="valueRange" label="数值合理区间">
          <NumberRange />
        </Form.Item>

        {!alterMode && (
          <Form.Item name="remark" label="备注">
            <Input />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default VirtualDialog;
