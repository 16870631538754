import { fetch, Key } from '@maxtropy/components';
import qs from 'qs';
import { HardWareVersion } from './bsa';
import { PageRequest, PageResponse } from './page';
import { DataProperty } from './type';

export enum BsaDataConfigType {
  BY_TYPE = 1,
  BY_DEVICE,
}

export namespace BsaDataConfigType {
  export const MAP = {
    [BsaDataConfigType.BY_TYPE]: '按类型',
    [BsaDataConfigType.BY_DEVICE]: '按设备',
  };
}

export interface DataConfigItem {
  name: string;
  weight: number;
  dataPropertyId: number;
  dataPropertyName: string;
  updatedAt: string;
}

export interface DeviceDataConfigItem {
  deviceId: number;
  deviceName: string;
  display: boolean;
  items: DataConfigItem[];
}

export interface BsaDataConfig {
  display?: boolean;
  name?: string;
  configType?: BsaDataConfigType;
  typeItems?: DataConfigItem[];
  deviceItems?: DeviceDataConfigItem[];
}

export interface BsaFcsConfig {
  fcsId: Key;
  wiringLogo: string; // 接线图
}

export enum BsaFcsType {
  MASTER_CONTROL = 0,
  SLAVE_CONTROL = 1,
  NO_MASTER_SLAVE = -1,
}
export interface BsaFcsList {
  fcsId: Key;
  fcsName: string;
  wiringLogo?: string; // 接线图
  bsaFcsType: BsaFcsType; //	类型 0 主控 1从控 -1 非主从结构
}
export interface BsaConfig {
  bsaId: number;
  bsaName?: string;
  gatewayId?: number;
  logoIcon?: string;
  powerOnIcon?: string;
  triggerProtectionRules?: AlarmRule[];
  triggerStopRules?: AlarmRule[];
  airConditionConfig?: BsaDataConfig;
  fireControlConfig?: BsaDataConfig;
  pcsConfig?: BsaDataConfig;
  stackConfig?: BsaDataConfig;
  rackConfig?: BsaDataConfig;
  packConfig?: BsaDataConfig;
  fcsCode?: string;
  fcsName?: string;
  gatewayName?: string;
  deviceName?: string;
  bsaFcsConfig?: BsaFcsConfig[];
  bsaFcsList?: BsaFcsList[];
  hardwareVersion?: HardWareVersion;
  fcsSoftwareVersion?: string;
}

export interface BsaConfigPayload extends Omit<BsaConfig, 'triggerProtectionRules' | 'triggerStopRules'> {
  triggerProtectionRules?: number[];
  triggerStopRules?: number[];
}

export interface BsaAlarmRulesQuery {
  bsaId: number;
  ruleName?: string;
}

export enum AlarmRuleStatus {
  ENABLE,
  DISABLE,
}
export namespace AlarmRuleStatus {
  export const MAP = {
    [AlarmRuleStatus.ENABLE]: '启用',
    [AlarmRuleStatus.DISABLE]: '禁用',
  };
}
export enum AlarmRuleType {
  SERVER,
  EDGE,
}
export namespace AlarmRuleType {
  export const MAP = {
    [AlarmRuleType.SERVER]: '服务端报警',
    [AlarmRuleType.EDGE]: '边缘报警',
  };
}
export enum AlarmType {
  TOP,
  HIGH,
  MIDDLE,
  LOW,
  WARNING,
}
export namespace AlarmType {
  export const MAP = {
    [AlarmType.TOP]: '最高级',
    [AlarmType.HIGH]: '高级',
    [AlarmType.MIDDLE]: '中级',
    [AlarmType.LOW]: '低级',
    [AlarmType.WARNING]: '预警',
  };
}
export interface AlarmRule {
  id: number;
  code: string;
  name: string;
  status: AlarmRuleStatus;
  type: AlarmRuleType;
  alarmName: string;
  alarmType: AlarmType;
}

export interface Device {
  id: number;
  name: string;
  sn: string;
  code: string;
  typeId: number;
  typeName: string;
}

export interface BsaDeviceResponse {
  airConditionDevices: Device[];
  fireControlDevices: Device[];
  pcsDevices: Device[];
  stackDevices: Device[];
  rackDevices: Device[];
  packDevices: Device[];
}

export interface BsaDataPropertyResponse {
  airConditionDataProperties: DataProperty[];
  fireControlDataProperties: DataProperty[];
  pcsDataProperties: DataProperty[];
  stackDataProperties: DataProperty[];
  rackDataProperties: DataProperty[];
  packDataProperties: DataProperty[];
}

export const getBsaConfig = (bsaId: number) =>
  fetch<BsaConfig>(`/api/bsaConfig?${qs.stringify({ bsaId }, { indices: false })}`);

export const getBsaAlarmRules = (params: BsaAlarmRulesQuery & PageRequest) =>
  fetch<PageResponse<AlarmRule>>(`/api/bsaConfig/rules?${qs.stringify(params, { indices: false })}`);

export const saveBsaConfig = (data: BsaConfigPayload) =>
  fetch<void>(`/api/bsaConfig`, { method: 'POST', body: JSON.stringify(data) });

export const getBsaDevices = (bsaId: number) =>
  fetch<BsaDeviceResponse>(`/api/bsaConfig/device?${qs.stringify({ bsaId }, { indices: false })}`);

export const getBsaDataProperties = () => fetch<BsaDataPropertyResponse>(`/api/bsaConfig/dataProperty/type`);

export const getBsaDeviceDataProperties = (bsaId: number) =>
  fetch<Record<number, DataProperty[]>>(
    `/api/bsaConfig/dataProperty/device?${qs.stringify({ bsaId }, { indices: false })}`
  );
