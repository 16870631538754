import styles from './index.module.scss';

import { Empty, Table } from '@maxtropy/components';

import { ConfigProvider, Space, Empty as AntDEmpty } from 'antd';

import { DataPropertyValues } from '../../type';
import { DataPropertyValuesItem, useGetTableColumns } from './utils';
import { rowType } from '../PCS';
import { useMemo } from 'react';

interface Iprops {
  tableList?: DataPropertyValues;
  remoteOp?: (record: DataPropertyValuesItem) => void;
}
const YcTable = ({ tableList, remoteOp }: Iprops) => {
  const columns = useGetTableColumns({ tableList, remoteOp, type: rowType.yc });
  const hasData = useMemo(() => tableList && tableList?.length > 0, [tableList]);
  return (
    <div className={styles.table_item}>
      <div className={styles.card_title}>
        <Space size={8}>遥测</Space>
      </div>
      <ConfigProvider
        renderEmpty={() => <Empty image={AntDEmpty.PRESENTED_IMAGE_SIMPLE} description={<>暂无配置遥测</>}></Empty>}
      >
        <Table
          columns={columns}
          rowKey="datapropertyId"
          dataSource={tableList}
          scroll={{ y: hasData ? 245 : undefined }}
        />
      </ConfigProvider>
    </div>
  );
};

export default YcTable;
