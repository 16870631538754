import { FC } from 'react';
import { Button, Modal } from '@maxtropy/components';
import flowImg from '@/assets/images/PvDispersionRateConfigFlow.svg';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const PvFlowModal: FC<Props> = props => {
  const { open, setOpen } = props;

  return (
    <Modal
      title="查看流程图"
      bodyScroll
      size="normal"
      open={open}
      onCancel={() => setOpen(false)}
      footer={
        <Button type="primary" onClick={() => setOpen(false)}>
          知道了
        </Button>
      }
    >
      <img src={flowImg} style={{ width: '100%' }} />
    </Modal>
  );
};

export default PvFlowModal;
