import { Col, Row, Space } from 'antd';
import { FC, useEffect, useState } from 'react';
import styles from './index.module.scss';
import chargeImg from '../../../imgs/charge.png';
import disChargeImg from '../../../imgs/disCharge.png';
import standByImg from '../../../imgs/standBy.png';
import { BsaDataStaticsGisRes, StationList, StationStatus, StationStatusDisplay } from '@/api/bsaMap';
import { Empty, Button, Input, Tag, Form } from '@maxtropy/components';
import {
  formateValuesPercent,
  formateValuesSOCPercent,
  formateValuesToFix2,
  GwhFormat,
} from '@/pages/MapStation/utils';
import { isNil } from 'lodash-es';

const formLayout = {
  wrapperCol: { span: 24 },
};

export interface IListInfo {
  changeIsDetail: (id: number, showPosition: boolean, showDetails: boolean) => void;
  divHeight?: number;
  data?: BsaDataStaticsGisRes;
  itemInfo?: StationList;
}

const ListInfo: FC<IListInfo> = props => {
  const { changeIsDetail, divHeight, data, itemInfo } = props;
  const [stationName, setStationName] = useState<string>();
  const [list, setList] = useState<StationList[]>();

  useEffect(() => {
    if (data) {
      if (stationName) {
        setList(list => {
          const newList: StationList[] = JSON.parse(JSON.stringify(list));
          return newList.filter(i => i.name?.includes(stationName));
        });
      } else {
        setList(data.list);
      }
    }
  }, [data, stationName]);

  const filters = (
    <div style={{ padding: '0px 12px' }}>
      <Form layout="horizontal" labelAlign={'left'} {...formLayout}>
        <Row>
          <Col span={24}>
            <Form.Item noStyle>
              <Input.Search
                className={styles.search}
                allowClear
                placeholder="请输入储能站名称查询"
                onChange={e => {
                  if (e.type === 'click') {
                    setStationName(undefined);
                  }
                }}
                onSearch={v => setStationName(v)}
                onPressEnter={(e: any) => setStationName(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );

  return (
    <div>
      {filters}
      <div style={{ height: divHeight ? divHeight - 134 : 'unset' }} className={styles.ListInfo}>
        {!list || list.length === 0 ? (
          <Empty />
        ) : (
          list.map(item => (
            <div
              key={item.id}
              className={`${styles.Item} ${itemInfo?.id === item.id ? styles.active : ''}`}
              onClick={() => {
                changeIsDetail(item.id, true, true);
              }}
            >
              <Row align="middle" justify="space-between" wrap={false}>
                <Col className={styles.ItemNameStyle}>{item.name}</Col>
                <Col>
                  <Space>
                    <Button
                      style={{ padding: 0, fontSize: 12, height: 0 }}
                      type="link"
                      onClick={() =>
                        window.open(
                          `/energyStorage/stationOperation/overview?id=${item.id}&bsaType=${item.type}`,
                          '_blank'
                        )
                      }
                    >
                      站点概览
                    </Button>
                  </Space>
                </Col>
              </Row>
              <Row align="middle" justify="space-between" wrap={false}>
                <Col style={{ fontSize: 12 }}>
                  装机容量：
                  <span className={styles.ItemInfoStyle}>{`${formateValuesToFix2(
                    item.ratedPower
                  )}MW/${formateValuesToFix2(item.installedCapacity)}MWh`}</span>
                </Col>
                <Col>
                  {!isNil(item.status) && (
                    <Space>
                      <img
                        src={
                          item.status === StationStatus.CHARGE
                            ? chargeImg
                            : item.status === StationStatus.STANDBY
                            ? standByImg
                            : disChargeImg
                        }
                        alt=""
                      />
                      <span className={styles.ItemInfoStyle}>{StationStatusDisplay[item.status as StationStatus]}</span>
                    </Space>
                  )}
                </Col>
              </Row>
              <Row align="middle" justify="space-between" wrap={false}>
                <Col flex={1} style={{ fontSize: 12 }}>
                  电池堆SOC：
                  {item.stackCount && item.stackCount === 1 ? (
                    <span className={styles.ItemInfoStyle}>{`${formateValuesSOCPercent(item?.socUpper)}%`}</span>
                  ) : (
                    <span className={styles.ItemInfoStyle}>{`${formateValuesSOCPercent(
                      item?.socLower
                    )}%~${formateValuesSOCPercent(item?.socUpper)}%`}</span>
                  )}
                </Col>
                <Col flex={1} style={{ fontSize: 12 }}>
                  月均系统效率：
                  <span className={styles.ItemInfoStyle}>{formateValuesPercent(item?.monthSystemEfficiency)}%</span>
                </Col>
              </Row>
              <Row align="middle" justify="space-between" wrap={false}>
                <Col style={{ marginRight: 4, marginLeft: -5 }} flex={1}>
                  <Tag style={{ width: 143, color: '#57FB8B', borderColor: '#57FB8B30', backgroundColor: '#162312' }}>
                    今日充电量：
                    {GwhFormat(item.thisDayCharge).value + GwhFormat(item.thisDayCharge).unit}
                  </Tag>
                </Col>
                <Col style={{ marginRight: -5 }} flex={1}>
                  <Tag style={{ width: 143, color: '#12C6FF', borderColor: '#12C6FF30', backgroundColor: '#0E2528' }}>
                    今日放电量：
                    {GwhFormat(item.thisDayDischarge).value + GwhFormat(item.thisDayDischarge).unit}
                  </Tag>
                </Col>
              </Row>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ListInfo;
