import {
  Button,
  CustomFilter,
  DatePicker,
  EllipsisSpan,
  Form,
  Paging,
  Select,
  Table,
  TenantType,
  useBreadcrumbRoutes,
  useCurrent,
  usePaging,
  useUpdate,
  Wrapper,
} from '@maxtropy/components';
import dayjs, { Dayjs } from 'dayjs';
import { useRequest } from 'ahooks';
import {
  apiV2PvPerformanceConfigPagePost,
  apiV2PvStationPvListPost,
  apiV2PvStationPvTreePost,
  V2PvPerformanceConfigPagePostRequest,
  V2PvPerformanceConfigPagePostResponse,
} from '@maxtropy/dmes-apis-v2';
import { useMemo, useState } from 'react';
import styles from './index.module.scss';
import { TableColumnsType } from 'antd';
import ConfigModal from './ConfigModal';
import { monthList } from './const';
import { customFixed } from '../const';

type SearchParams = Omit<V2PvPerformanceConfigPagePostRequest, 'timestamp' | 'page' | 'size'>;
export type DataItem = Exclude<V2PvPerformanceConfigPagePostResponse['list'], undefined>[number];

const getMonthColumns = (): TableColumnsType<DataItem> => {
  return monthList.map(item => {
    return {
      title: `${item.monthName}月(kWh)`,
      key: `month${item.month}`,
      ellipsis: { showTitle: true },
      render: (_, record) => (
        <EllipsisSpan value={customFixed(record.configValueList?.find(config => config.month === item.month)?.value)} />
      ),
    };
  });
};

const PvPerformanceConfig = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const pagingInfo = usePaging(10);
  const { pageSize, pageOffset, setPageOffset, setTotalCount } = pagingInfo;
  const [searchForm] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    year: dayjs().year(),
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState<DataItem | undefined>();
  const [updateState, updateFn] = useUpdate();
  const isChannel = useCurrent()?.tenant?.tenantType === TenantType.CHANNEL;
  const tenantMcid = Form.useWatch('tenantMcid', searchForm);

  const { data: pvTree } = useRequest(async () => {
    const res = await apiV2PvStationPvTreePost({});
    return res.list?.map(item => {
      let pvList: { pvId?: number; pvName?: string; createTime?: string }[] = [];
      item.pvCustomerTree?.forEach(customer => {
        pvList = pvList.concat(customer.pvTree ?? []);
      });
      pvList = pvList.sort((a, b) => {
        return dayjs(b.createTime).valueOf() - dayjs(a.createTime).valueOf();
      });
      return {
        ...item,
        pvList: pvList,
      };
    });
  });

  const pvList = useMemo(() => {
    // 非渠道端：返回第一级下的所有光伏站
    if (!isChannel) {
      return pvTree?.[0]?.pvList;
    }
    // 渠道端：未选租户时，返回全部；选择租户时，返回对应租户下的光伏站
    return tenantMcid
      ? pvTree?.find(item => item.tenantMcid === tenantMcid)?.pvList
      : pvTree?.reduce((acc, cur) => acc.concat(cur.pvList), [] as typeof pvTree[0]['pvList']);
  }, [pvTree, tenantMcid]);

  const { data, loading } = useRequest(
    async () => {
      const res = await apiV2PvPerformanceConfigPagePost({
        size: pageSize,
        page: pageOffset,
        ...searchParams,
      });
      setTotalCount(res.total ?? 0);
      return res.list;
    },
    { refreshDeps: [pageSize, pageOffset, searchParams, updateState] }
  );

  const filters = (
    <CustomFilter
      form={searchForm}
      onFinish={(val: { year: Dayjs; tenantMcid: string; pvIdList: number[] }) => {
        setSearchParams({
          year: val.year.year(),
          tenantMcid: val.tenantMcid,
          pvIdList: val.pvIdList,
        });
        setPageOffset(1);
      }}
      onReset={() => {
        setSearchParams({
          year: dayjs().year(),
        });
        setPageOffset(1);
      }}
    >
      <Form.Item label="年份" name="year" initialValue={dayjs()}>
        <DatePicker allowClear={false} picker="year" style={{ width: '100%' }}></DatePicker>
      </Form.Item>
      {isChannel && (
        <Form.Item label="租户" name="tenantMcid">
          <Select
            allowClear
            options={pvTree?.map(item => ({
              label: item.tenantName,
              value: item.tenantMcid,
            }))}
            onChange={() => {
              searchForm.setFieldsValue({ pvIdList: [] });
            }}
          ></Select>
        </Form.Item>
      )}
      <Form.Item label="光伏站" name="pvIdList">
        <Select
          mode="multiple"
          allowClear
          options={pvList?.map(item => ({
            value: item.pvId,
            label: item.pvName,
          }))}
          showSearch
          filterOption={(inputValue, option) => {
            return option!.label!.toUpperCase().includes(inputValue.toUpperCase());
          }}
        ></Select>
      </Form.Item>
    </CustomFilter>
  );

  const columns: TableColumnsType<DataItem> = [
    {
      title: '光伏站编号',
      dataIndex: 'pvCode',
      fixed: 'left',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v} />,
    },
    {
      title: '光伏站名称',
      dataIndex: 'pvName',
      fixed: 'left',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v} />,
    },
    {
      title: '装机容量(kW)',
      dataIndex: 'capacity',
      fixed: 'left',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={customFixed(v)} />,
    },
    {
      title: '年度总目标(kWh)',
      dataIndex: 'powerOfYear',
      fixed: 'left',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan className={styles.warningColor} value={customFixed(v)} />,
    },
    ...getMonthColumns(),
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => {
            setSelectedData(record);
            setModalOpen(true);
          }}
        >
          编辑
        </Button>
      ),
    },
  ];

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.wrapper} filters={filters}>
      <Table rowKey="pvId" loading={loading} scroll={{ x: 'max-content' }} dataSource={data} columns={columns}></Table>
      <Paging pagingInfo={pagingInfo}></Paging>

      <ConfigModal
        open={modalOpen}
        setOpen={setModalOpen}
        data={selectedData}
        year={searchParams.year!}
        onOk={updateFn}
      ></ConfigModal>
    </Wrapper>
  );
};

export default PvPerformanceConfig;
