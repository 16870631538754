import { PermissionsType } from '@/common/permissionsConst';
import PvRunningDashbord from '.';
import FileView from './FileView';

const routes = [
  {
    path: '/pv/running/dashbord',
    permission: PermissionsType.P_STATION_DASHBOARD,
    element: <PvRunningDashbord />,
  },
  {
    path: '/pv/running/dashbord/fileView',
    permission: '',
    element: <FileView />,
  },
];
export default routes;
