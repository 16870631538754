import { BsaDataStaticsGisRes } from '@/api/bsaMap';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import { createContext, FC } from 'react';
import CurrentScale from './components/CurrentScale';
import MonthIncComingRank from './components/MonthIncComingRank';
import OperateInfo from './components/OperateInfo';
import SystemEfficiency from './components/SystemEfficiency';
import styles from './index.module.scss';

const { Panel } = Collapse;

export interface IRightInfo {
  divHeight?: number;
  data?: BsaDataStaticsGisRes;
}

export const RightValue = createContext({} as BsaDataStaticsGisRes | undefined);

const RightInfo: FC<IRightInfo> = props => {
  const { divHeight, data } = props;
  return (
    <div className={styles.CollapseStyle}>
      <Collapse
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? -90 : 90} />}
      >
        <Panel header="概览数据" key="1">
          <div style={{ height: divHeight ? divHeight - 104 : 'unset' }} className={styles.RightInfoStyle}>
            <RightValue.Provider value={data}>
              <OperateInfo />
              <SystemEfficiency />
              <MonthIncComingRank />
              <CurrentScale />
            </RightValue.Provider>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default RightInfo;
