import { Button } from '@maxtropy/components';
import { Pagination, PaginationProps, Space } from 'antd';
import React, { FC, useEffect } from 'react';
import styles from './index.module.scss';

export interface IRecordPagination {
  totalCount: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  pageSize: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  setIsSortValue: React.Dispatch<React.SetStateAction<boolean>>;
}

const RecordPagination: FC<IRecordPagination> = props => {
  const { totalCount, setPageSize, pageSize, setCurrentPage, currentPage, setIsSortValue } = props;

  // 当pageSize发生变化的时候将页码重置为第一条（因为要重新计算页面乘以当前页是否大于10000）
  useEffect(() => {
    if (pageSize) {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize]);

  const PaginationOnChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev') {
      return (
        <Button
          onClick={() => {
            setIsSortValue(true);
            setCurrentPage(currentPage - 1);
          }}
        >
          上一页
        </Button>
      );
    }
    if (type === 'next') {
      return (
        <Button
          onClick={() => {
            setIsSortValue(true);
            setCurrentPage(currentPage + 1);
          }}
        >
          下一页
        </Button>
      );
    }
    return originalElement;
  };

  return (
    <div className={styles.pagination}>
      <Pagination
        showTotal={_ => (
          <Space size={10}>
            <div className={styles.total}>{`共${totalCount}条`}</div>
            {currentPage * pageSize >= 10000 ? <Button onClick={() => setCurrentPage(1)}>返回第一页</Button> : null}
          </Space>
        )}
        showQuickJumper
        disabled={currentPage * pageSize >= 10000 ? true : false}
        itemRender={itemRender}
        showSizeChanger
        current={currentPage}
        onChange={PaginationOnChange}
        pageSize={pageSize}
        total={totalCount < 10000 ? totalCount : currentPage * pageSize >= 10000 ? totalCount : 10000}
        defaultPageSize={50}
        pageSizeOptions={['10', '20', '30', '50', '100', '500']}
      />
    </div>
  );
};

export default RecordPagination;
