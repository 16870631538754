import { judgeAllNull, keepPercentTwoNull } from '@/pages/PvDemonstrationBase/utils';
import { V2PvNoticeboardDispersionRateCurvePostResponse } from '@maxtropy/dmes-apis-v2';
import dayjs from 'dayjs';

export const getChartOption = (data?: V2PvNoticeboardDispersionRateCurvePostResponse) => {
  const discreteData = (data?.list ?? []).map(item => {
    let data = (item.valueList ?? []).map(i => {
      return [dayjs(i.time).valueOf(), keepPercentTwoNull(i.value), item.deviceName];
    });
    return {
      name: item.deviceName ?? '--',
      type: 'line',
      data: data,
      symbol: 'circle',
      symbolSize: 2,
      showSymbol: false, // 只有在 tooltip hover 的时候显示symbol
      itemStyle: {
        // symbol样式
        normal: {
          borderColor: 'rgba(255, 255, 255, 0.2)', // symbol边框颜色
          borderWidth: 6, // symbol边框宽度
        },
      },
    };
  });

  const isAllNull = judgeAllNull(
    (discreteData ?? [])
      .map(c => c?.data)
      .flat()
      .map((i: any) => i?.[1])
  );

  const discreteColors = [
    '#16DD8E',
    '#FFCB47',
    '#52E7FF',
    '#FF477B',
    '#CE90D1',
    '#2D8DFF',
    '#FF9247',
    '#ABD335',
    '#854ECA',
    '#63BC7F',
    '#00ADFF',
  ];

  const discreteColorMap = discreteData?.map((_, index) => {
    return discreteColors[index % discreteColors.length];
  });

  // 细分Y段数
  const maxData = Math.max(
    ...(discreteData ?? [])
      .map(c => c.data)
      .flat()
      .map((i: any) => i[1] ?? 0)
  );
  const minData = Math.min(
    ...(discreteData ?? [])
      .map(c => c.data)
      .flat()
      .map((i: any) => i[1] ?? 0)
  );
  const max = Math.ceil(Math.max(maxData));
  const min = Math.floor(Math.min(minData));
  const interVal = Number(((max - min) / 4).toFixed(0));

  return {
    backgroundColor: 'transparent',
    loadingOption: {
      text: '数据加载中...',
      color: '#4ea397',
      textColor: '#000',
      maskColor: 'rgba(255, 255, 255, 0.8)',
      zlevel: 0,
      effectOption: {
        effect: {
          period: 12, // 动画时间，单位为秒
          trailLength: 0.7, // 流星的长度
          minTrailLength: 0.2, // 流星的最小长度
          maxTrailLength: 1, // 流星的最大长度
          type: 'bubble', // 流星类型
        },
        global: {
          speed: 5, // 流星速度
        },
      },
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.9)',
      borderColor: 'transparent',
      textStyle: {
        color: '#ffffff85',
      },
      formatter: (items: any) => {
        let { axisValue } = items[0];
        let firstStr = dayjs(axisValue).format('YYYY-MM-DD');
        let otherStr = '';
        let str = '';

        items.forEach((item: any) => {
          const { seriesName, data } = item;
          let unit = '%';
          otherStr += `<div style="margin-top:8px"><span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${
            item.color
          };"></span>${seriesName} : &nbsp;${data[1] || data[1] === 0 ? data[1] + unit : '-'}</div>`;
          str = firstStr + otherStr;
        });

        return str;
      },
    },
    grid: {
      left: isAllNull ? 40 : 20,
      right: 10,
      top: 50,
      bottom: 5,
      containLabel: true,
    },
    color: [...(discreteColorMap ?? [])],
    // dataZoom: {
    //   type: 'slider',
    //   bottom: 15,
    //   left: 40,
    //   right: 40,
    //   height: 20,
    //   textStyle: {
    //     fontSize: 10,
    //   },
    //   labelFormatter: (value: string) => {
    //     return dayjs(value).format('HH:mm');
    //   },
    // },

    legend: {
      type: 'scroll',
      right: 10,
      textStyle: {
        color: '#ffffff85',
      },
      inactiveColor: '#ffffff45',
      itemWidth: 16,
      itemHeight: 4,
      data: [
        ...(discreteData ?? []).map(i => ({
          name: i.name,
          icon: 'path://M0 426.666667h1024v170.666666H0z',
        })),
      ],
    },
    xAxis: [
      {
        type: 'time',
        axisLabel: {
          formatter: function (e: number) {
            return dayjs(e, 'x').format('MM-DD');
          },
          color: 'rgba(255,255,255,0.85)',
        },
      },
    ],
    yAxis: [
      {
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        type: 'value',
        axisLabel: {
          color: 'rgba(255,255,255,0.85)',
        },
        name: '离散率(%)',
        max: max,
        min: min,
        interval: interVal,
        splitLine: {
          show: true,
          lineStyle: {
            color: '#66686A',
          },
        },
      },
    ],
    series: discreteData,
  };
};
