import { StationType, TIME_FORMAT } from '@/pages/SiteAccess';
import { Modal, DatePicker, Form } from '@maxtropy/components';
import { apiV2UetUpsertStartCalculateTimePost } from '@maxtropy/dmes-apis-v2';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import React, { useEffect } from 'react';

interface Props {
  id?: number;
  type?: StationType;
  open?: boolean;
  startCalculateTime?: string;
  cancel?: () => void;
  forceUpdate: () => void;
}
const StartingTimeModal: React.FC<Props> = ({ id, type, open = false, cancel, startCalculateTime, forceUpdate }) => {
  const [form] = Form.useForm();

  const onOk = () => {
    form.validateFields().then(values => {
      const { startCalculateTime } = values;
      if (isNil(id) || isNil(type)) return;

      apiV2UetUpsertStartCalculateTimePost({
        id,
        type,
        startCalculateTime: dayjs(startCalculateTime).format(TIME_FORMAT),
      }).then(() => {
        form.resetFields();
        forceUpdate();
        cancel?.();
      });
    });
  };

  useEffect(() => {
    if (startCalculateTime && open) {
      form.setFieldsValue({ startCalculateTime: dayjs(startCalculateTime) });
    }
  }, [form, open, startCalculateTime]);

  return (
    <>
      <Modal
        contentClassName="modal-form-content"
        title="设置站点起算时间"
        open={open}
        onCancel={() => cancel?.()}
        onOk={onOk}
        destroyOnClose
      >
        <Form form={form}>
          <Form.Item name="startCalculateTime" label="起算日期">
            <DatePicker style={{ width: '100%' }} format={'YYYY-MM-DD'} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default StartingTimeModal;
