import { PermissionsType } from '@/common/permissionsConst';
import PvPerformanceOverview from './PvPerformanceOverview';
import PvPerformanceConfig from './PvPerformanceConfig';

const routes = [
  {
    path: '/pv/performance/overview',
    permission: PermissionsType.P_PVA_PERFORMANCE_OVERVIEW,
    element: <PvPerformanceOverview />,
  },
  {
    path: '/pv/performance/config',
    permission: PermissionsType.P_PVA_PERFORMANCE_SET,
    element: <PvPerformanceConfig />,
  },
];
export default routes;
