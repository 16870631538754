import PvList from '@/pages/PvStation';
import BaseInfo from '@/pages/PvStation/BaseInfo';
import ArrayInfo from '@/pages/PvStation/ArrayInfo';
import PVCabinetScreen from '@/pages/PvStation/Cabinet';
import { PermissionsType } from '@/common/permissionsConst';

const routes = [
  {
    path: '/pv-storage/basic/manage',
    permission: PermissionsType.P_PV_STATION_MANAGE,
    element: <PvList />,
  },
  {
    path: '/pv-storage/basic/manage/:pvId/edit/cabinet',
    element: <PVCabinetScreen isEdit />,
  },
  {
    path: '/pv-storage/basic/manage/:pvId/cabinet',
    permission: PermissionsType.B_PV_HIGH_VOLTAGE_EDIT,
    element: <PVCabinetScreen />,
  },

  {
    path: '/pv-storage/basic/manage/baseInfo/view',
    element: <BaseInfo />,
  },
  {
    path: '/pv-storage/basic/manage/baseInfo/edit',
    permission: PermissionsType.B_PV_BASIC_EDIT,
    element: <BaseInfo isEdit />,
  },
  {
    path: '/pv-storage/basic/manage/arrayInfo/view',
    element: <ArrayInfo />,
  },
  {
    path: '/pv-storage/basic/manage/arrayInfo/edit',
    permission: PermissionsType.B_PV_ARRAY_EDIT,
    element: <ArrayInfo isEdit />,
  },
];
export default routes;
