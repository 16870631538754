import { Form, Modal, Paging, Radio, usePaging } from '@maxtropy/components';
import { apiV2PvStationLogIncomeCalculateWayPost } from '@maxtropy/dmes-apis-v2';
import { useRequest } from 'ahooks';
import { List, Space } from 'antd';
import dayjs from 'dayjs';
import { resolveSoa } from 'dns';
import { useState } from 'react';
import styles from './index.module.scss';
import { CalculateWay, CalculateWayDisplay } from './ModeModal';
interface Iprops {
  onCancel?: () => void;
  onConfirm?: () => void;
  pvStationId?: number;
}
const LogModal = ({ onCancel, onConfirm, pvStationId }: Iprops) => {
  const [form] = Form.useForm();

  const pagingInfo = usePaging(20);
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;

  const { data: list, loading } = useRequest(
    () => {
      return apiV2PvStationLogIncomeCalculateWayPost({
        pvStationId,
        page: pageOffset,
        pageSize,
      }).then(res => {
        setTotalCount(res.total ?? 0);
        return res.list;
      });
    },
    {
      ready: !!pvStationId,
      refreshDeps: [pageOffset, pageSize, pvStationId],
    }
  );

  return (
    <Modal
      bodyScroll
      open
      size="big"
      title="并网点电量来源修改记录"
      onOk={() => onConfirm?.()}
      onCancel={() => onCancel?.()}
    >
      <div className={styles.log_box}>
        <List
          loading={loading}
          dataSource={list}
          renderItem={item => (
            <List.Item>
              <span>【{item.staffName}】</span>
              在&nbsp;&nbsp;<span>{dayjs(item.createTime).format('YYYY-MM-DD HH:mm:ss')}</span>&nbsp;&nbsp;修改模式为：
              <span>{CalculateWayDisplay[item.incomeCalculateWay as CalculateWay]}</span>
            </List.Item>
          )}
        />
      </div>
      <div className={styles.pagination_box}>
        <Paging pagingInfo={pagingInfo} />
      </div>
    </Modal>
  );
};
export default LogModal;
