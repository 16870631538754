import { Form, InputNumber, Modal, Select } from '@maxtropy/components';
import { apiV2NeoBsaRemoteControlPost } from '@maxtropy/dmes-apis-v2';
import { Col, Row } from 'antd';

import { useContext, useEffect, useMemo, useState } from 'react';
import { ShareContext } from '../..';
import { DatapropertyType } from '../../type';

interface Iprops {
  datapropertyType?: number; // 故障告警, 还是状态
  datapropertyId?: number;
  enumMap?: Record<number, string>;
  deviceId?: number;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const RemoteControlModal = ({ datapropertyType, deviceId, datapropertyId, enumMap, onCancel, onConfirm }: Iprops) => {
  const { bsaId, bsaType } = useContext(ShareContext);

  const [form] = Form.useForm();

  const value = Form.useWatch('value', form);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const onConfirmBtn = async () => {
    let valid = await form
      .validateFields()
      .then(_ => true)
      .catch(_ => false);
    if (confirmLoading || !valid) return;
    setConfirmLoading(true);
    apiV2NeoBsaRemoteControlPost({
      bsaId,
      bsaType,
      deviceId,
      dataPropertyId: datapropertyId,
      value,
    })
      .then(res => {
        onConfirm?.();
      })
      .finally(() => setConfirmLoading(false));
  };
  return (
    <>
      <Modal
        bodyScroll
        open
        contentClassName="modal-form-content"
        confirmLoading={confirmLoading}
        title="远控"
        onCancel={() => onCancel?.()}
        onOk={onConfirmBtn}
      >
        <Form form={form} wrapperCol={{ flex: 1 }}>
          <Row>
            <Col span={24}>
              <Form.Item label="远控值" name="value" rules={[{ required: true }]}>
                {datapropertyType === DatapropertyType.YC ? (
                  <InputNumber placeholder="请输入" maxLength={20} style={{ width: '100%' }} />
                ) : (
                  <Select
                    style={{ width: '100%' }}
                    placeholder="请选择"
                    options={Object.entries(enumMap ?? {}).map(([key, value]) => ({ label: value, value: key }))}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default RemoteControlModal;
