import {
  Button,
  CustomFilter,
  DatePicker,
  EllipsisSpan,
  Form,
  Paging,
  Select,
  Table,
  TenantType,
  useBreadcrumbRoutes,
  useCurrent,
  usePaging,
  Wrapper,
} from '@maxtropy/components';
import styles from './index.module.scss';
import { useRequest } from 'ahooks';
import dayjs, { Dayjs } from 'dayjs';
import {
  apiV2PvPerformanceOutlinePagePost,
  apiV2PvStationPvTreePost,
  V2PvPerformanceOutlinePagePostRequest,
  V2PvPerformanceOutlinePagePostResponse,
} from '@maxtropy/dmes-apis-v2';
import { useMemo, useState } from 'react';
import { TableColumnsType } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import { customFixed } from '../const';

type SearchParams = Omit<V2PvPerformanceOutlinePagePostRequest, 'timestamp' | 'page' | 'size'>;
type DataItem = Exclude<V2PvPerformanceOutlinePagePostResponse['list'], undefined>[number];

const PvPerformanceOverview = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const pagingInfo = usePaging(10);
  const { pageSize, pageOffset, setPageOffset, setTotalCount } = pagingInfo;
  const [searchForm] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    year: dayjs().year(),
    month: dayjs().month() + 1,
  });
  const isChannel = useCurrent()?.tenant?.tenantType === TenantType.CHANNEL;
  const tenantMcid = Form.useWatch('tenantMcid', searchForm);

  const { data: pvTree } = useRequest(async () => {
    const res = await apiV2PvStationPvTreePost({});
    return res.list?.map(item => {
      let pvList: { pvId?: number; pvName?: string; createTime?: string }[] = [];
      item.pvCustomerTree?.forEach(customer => {
        pvList = pvList.concat(customer.pvTree ?? []);
      });
      pvList = pvList.sort((a, b) => {
        return dayjs(b.createTime).valueOf() - dayjs(a.createTime).valueOf();
      });
      return {
        ...item,
        pvList: pvList,
      };
    });
  });

  const pvList = useMemo(() => {
    // 非渠道端：返回第一级下的所有光伏站
    if (!isChannel) {
      return pvTree?.[0]?.pvList;
    }
    // 渠道端：未选租户时，返回全部；选择租户时，返回对应租户下的光伏站
    return tenantMcid
      ? pvTree?.find(item => item.tenantMcid === tenantMcid)?.pvList
      : pvTree?.reduce((acc, cur) => acc.concat(cur.pvList), [] as typeof pvTree[0]['pvList']);
  }, [pvTree, tenantMcid]);

  const { data, loading } = useRequest(
    async () => {
      const res = await apiV2PvPerformanceOutlinePagePost({
        page: pageOffset,
        size: pageSize,
        ...searchParams,
      });
      setTotalCount(res.total ?? 0);
      return res.list;
    },
    {
      refreshDeps: [pageOffset, pageSize, searchParams],
    }
  );

  const filters = (
    <CustomFilter
      form={searchForm}
      onFinish={(val: { time: Dayjs; tenantMcid: string; pvIdList: number[] }) => {
        setSearchParams({
          year: val.time.year(),
          month: val.time.month() + 1,
          tenantMcid: val.tenantMcid,
          pvIdList: val.pvIdList,
        });
        setPageOffset(1);
      }}
      onReset={() => {
        setSearchParams({
          year: dayjs().year(),
          month: dayjs().month() + 1,
        });
        setPageOffset(1);
      }}
    >
      <Form.Item label="月份" name="time" initialValue={dayjs()}>
        <DatePicker allowClear={false} picker="month" style={{ width: '100%' }}></DatePicker>
      </Form.Item>
      {isChannel && (
        <Form.Item label="租户" name="tenantMcid">
          <Select
            allowClear
            options={pvTree?.map(item => ({
              label: item.tenantName,
              value: item.tenantMcid,
            }))}
            onChange={() => {
              searchForm.setFieldsValue({ pvIdList: [] });
            }}
          ></Select>
        </Form.Item>
      )}
      <Form.Item label="光伏站" name="pvIdList">
        <Select
          mode="multiple"
          allowClear
          options={pvList?.map(item => ({
            value: item.pvId,
            label: item.pvName,
          }))}
          showSearch
          filterOption={(inputValue, option) => {
            return option!.label!.toUpperCase().includes(inputValue.toUpperCase());
          }}
        ></Select>
      </Form.Item>
    </CustomFilter>
  );

  const columns: TableColumnsType<DataItem> = [
    {
      title: '光伏站编号',
      dataIndex: 'pvCode',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v} />,
    },
    {
      title: '光伏站名称',
      dataIndex: 'pvName',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v} />,
    },
    {
      title: '装机容量(kW)',
      dataIndex: 'capacity',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={customFixed(v)} />,
    },
    {
      title: '年度计划发电量(kWh)',
      dataIndex: 'planPowerOfYear',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={customFixed(v)} />,
    },
    {
      title: '年度实际发电量(kWh)',
      dataIndex: 'realPowerOfYear',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={customFixed(v)} />,
    },
    {
      title: '年度完成率',
      dataIndex: 'accomplishmentRatioOfYear',
      ellipsis: { showTitle: true },
      render: v => (
        <EllipsisSpan
          value={
            <span style={{ color: 'var(--warning-color)' }}>
              {customFixed(v * 100) ? customFixed(v * 100) + '%' : '--'}
            </span>
          }
        />
      ),
    },
    {
      title: '月度计划发电量(kWh)',
      dataIndex: 'planPowerOfMonth',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={customFixed(v)} />,
    },
    {
      title: '月度实际发电量(kWh)',
      dataIndex: 'realPowerOfMonth',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={customFixed(v)} />,
    },
    {
      title: '月度完成率',
      dataIndex: 'accomplishmentRatioOfMonth',
      ellipsis: { showTitle: true },
      render: v => (
        <EllipsisSpan
          value={
            <span style={{ color: 'var(--warning-color)' }}>
              {customFixed(v * 100) ? customFixed(v * 100) + '%' : '--'}
            </span>
          }
        />
      ),
    },
  ];

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.wrapper} filters={filters}>
      <div className={styles.topLine}>
        <Button type="link" onClick={() => window.open('/pv/performance/config')}>
          绩效配置 <DoubleRightOutlined style={{ marginLeft: 6 }} />
        </Button>
      </div>
      <Table rowKey="pvId" loading={loading} dataSource={data} columns={columns}></Table>
      <Paging pagingInfo={pagingInfo}></Paging>
    </Wrapper>
  );
};

export default PvPerformanceOverview;
