import { DataType } from '../../../../api/const';

const Three = ['#ffd61a', '#2fc25b', '#ff4a4a', '#F3E6A7', '#A0EAB1', '#FD6A6A'];
const LoadRateColors = ['#009FA8', '#53EDC1'];
const UnbalancedColors = ['#1890FF', '#FAAD14'];

const palette = {
  [DataType.EP]: ['#1890ff', '#4fc7ec'],
  [DataType.PDMD]: ['#3DC25B', '#b0f391'],
  [DataType.LOAD_RATE]: LoadRateColors,
  [DataType.COSQ]: LoadRateColors,
  [DataType.P]: LoadRateColors,
  [DataType.U]: Three,
  [DataType.LINE_VOLTAGE]: Three,
  [DataType.I]: Three,
  [DataType.T]: Three,
  [DataType.PE]: ['#00ADFF', '#FF4D4F', '#6af1fd', '#FD6A6A'],
  [DataType.F]: Three,
  [DataType.UNBALANCED_V]: UnbalancedColors,
  [DataType.UNBALANCED_A]: UnbalancedColors,
  [DataType.RESIDUAL_A]: ['#3DC25B', '#b0f391'],
  [DataType.VOLTAGE_H]: Three,
  [DataType.CURRENT_H]: Three,
};

export default palette;
