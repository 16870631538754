import qs from 'qs';
import { fetch } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import { CircuitPageProps, CircuitType } from './circuit';
export interface NextCircuitPageReq extends PageRequest {
  circuitId?: string;
}
export interface NextCircuitPageProps {
  circuitId?: number;
  circuitCode?: string;
  circuitName?: string;
  circuitType?: CircuitType;
  distributionCabinetName?: string;
  positiveActivePowerConfig?: boolean;
  reverseActivePowerConfig?: boolean;
}
// 查询下级回路列表
export const getNextCircuitPage = (query: NextCircuitPageReq) =>
  fetch<PageResponse<NextCircuitPageProps>>(`/api/circuit-line-loss/page?${qs.stringify(query, { indices: false })}`);

// 查询回路下拉列表
export const getNextCircuitOptions = (id: string) =>
  fetch<NextCircuitPageProps[]>(`/api/circuit-line-loss/list?circuitId=${id}`);

// 创建回路线损配置
export interface createCircuitLossReq {
  computationalLogic?: number;
  circuitId?: number | string;
  computationalTime?: any;
  downstreamCircuitIds?: (number | string)[];
}
export const createCircuitLoss = (params: createCircuitLossReq) =>
  fetch<boolean>(`/api/circuit-line-loss/create`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
// 修改回路线损配置
export const updateCircuitLoss = (params: createCircuitLossReq) =>
  fetch<boolean>(`/api/circuit-line-loss/update`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
// 删除回路线损配置
export const deleteCircuitLoss = (id: string | number) =>
  fetch<boolean>(`/api/circuit-line-loss/delete/${id}`, {
    method: 'DELETE',
  });

// 查询回路线损配置详情
export interface CircuitLossRes {
  circuitId?: number;
  circuitName?: string;
  circuitConfig?: boolean;
  circuitType?: CircuitType;
  downstreamCircuitCount?: number;
  downstreamCircuitConfig?: boolean;
  computationalTime?: any;
  computationalLogic?: number;
}
export const getCircuitLossDetail = (id: string) => fetch<CircuitLossRes>(`/api/circuit-line-loss/detail/${id}`);

// 线损报警记录
export interface LossAlarmLogsReq extends PageRequest {
  ouIds?: number[] | string[];
  circuitName?: string;
  alarmStartTime?: any;
  alarmEndTime?: any;
}
export interface LossAlarmLogsRes {
  id?: number;
  circuitId?: number;
  circuitName?: string;
  ruleId?: number;
  ruleCode?: string;
  ruleName?: string;
  lossElectricity?: number;
  lossRate?: number;
  circuitChildrenElectricity?: number;
  circuitTotalElectricity?: number;
  alarmTime?: any;
  circuitCode?: string;
  interval?: string;
  ruleDelete?: boolean;
}
export const getLossAlarmLogPage = (query: LossAlarmLogsReq) =>
  fetch<PageResponse<LossAlarmLogsRes>>(`/api/line-loss/alarm/log/page?${qs.stringify(query, { indices: false })}`);
export const getLossAlarmLogDetail = (id: any) => fetch<LossAlarmLogsRes>(`/api/line-loss/alarm/log/${id}`);

// 线损报警规则列表0-禁用，1-启用，2-删除
export enum AlarmRuleStatus {
  DISBALE,
  ENABLE,
  DELETE,
}
export const formateAlarmRuleStatus = {
  [AlarmRuleStatus.DISBALE]: '禁用',
  [AlarmRuleStatus.ENABLE]: '启用',
  [AlarmRuleStatus.DELETE]: '删除',
};
export interface LossAlarmRulesReq extends PageRequest {
  ouId?: number | string;
  ruleName?: string;
  circuitName?: string;
  status?: AlarmRuleStatus;
}
export interface LossAlarmRulesRes {
  id?: number;
  circuitId?: number;
  circuitIdList?: number[];
  circuitName?: string;
  ruleId?: number;
  code?: string;
  circuitDeleted?: boolean;
  alarmLogic?: number;
  name?: string;
  ouName?: string;
  ouId?: number;
  status?: number;
  cycle?: number;
  loss?: number;
  lossRate?: number;
  createTime?: any;
  updateTime?: any;
  updateByUserId?: string;
  updateByUsername?: string;
}
export const getLossAlarmRulePage = (query: LossAlarmRulesReq) =>
  fetch<PageResponse<LossAlarmRulesRes>>(`/api/line-loss/alarm/rule/page?${qs.stringify(query, { indices: false })}`);

// 批量创建报警规则
export interface createAlarmRuleReq {
  circuitIdList: number[];
  circuitId?: number;
  alarmLogic: number;
  name: string;
  ouId: number;
  cycle: number;
  loss: number;
  lossRate: number;
  updateByUserId?: string;
  updateByUsername?: string;
}
export const createAlarmRule = (params: createAlarmRuleReq) =>
  fetch(`/api/line-loss/alarm/rule/create`, {
    method: 'POST',
    body: JSON.stringify(params),
  });

// 查询线损报警详情
export const getLossAlarmRuleDetail = (id: any) => fetch<LossAlarmRulesRes>(`/api/line-loss/alarm/rule/${id}`);

// 编辑线损报警
export const updateAlarmRule = (id: any, params: createAlarmRuleReq) =>
  fetch<LossAlarmRulesRes>(`/api/line-loss/alarm/rule/${id}`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });
// 更新线损报警规则状态/启用/禁用/删除状态
export const changeAlarmRuleStatus = (id: any, status: AlarmRuleStatus) =>
  fetch(`/api/line-loss/alarm/rule/${id}/status?status=${status}`, {
    method: 'PUT',
  });

// 获取弹框中 回路列表
export interface CircuitPageReq extends PageRequest {
  codeOrName?: string;
  type?: CircuitType;
  ouId?: number;
  distributionCabinetId?: string;
}
export const getLineLossCircuitPage = (query: CircuitPageReq) =>
  fetch<PageResponse<CircuitPageProps>>(`/api/circuit/page?${qs.stringify(query, { indices: false })}`);
