import { Button } from '@maxtropy/components';
import { useContext, useMemo } from 'react';
import { ShareContext } from '../..';
import { useGetDataPropertyInfo } from '../../hooks';
import { DataPropertyValues } from '../../type';
import { getTsByPropertyId, getValueByPropertyId } from '../utils';

export type DataPropertyValuesItem = Exclude<DataPropertyValues, undefined>[number];
interface Iprops {
  tableList?: DataPropertyValues;
  remoteOp?: (record: DataPropertyValuesItem) => void;
  type?: number;
}

export const useGetTableColumns = ({ tableList, remoteOp, type }: Iprops) => {
  const { hasRemoteBtn } = useContext(ShareContext);
  const { deviceIds, dataPropertyIds } = useMemo(() => {
    return {
      deviceIds: [tableList?.[0]?.deviceId].filter(Boolean),
      dataPropertyIds: tableList?.filter(Boolean)?.map(item => item.datapropertyId!),
    };
  }, [tableList]);

  const { data: valuesMap } = useGetDataPropertyInfo({ deviceIds: deviceIds as unknown as number[], dataPropertyIds });
  const columns = [
    {
      title: '名称',
      dataIndex: 'datapropertyName',
      ellipsis: { showTitle: true },
      fixed: 'left' as const,
    },
    {
      title: '数据',
      dataIndex: 'data',
      className: 'self_color',
      render: (v: string, record: any) => {
        const { datapropertyId, deviceId, generalName, enumMap } = record;
        return getValueByPropertyId({
          datapropertyId,
          deviceId,
          valuesMap,
          unit: generalName,
          enumMap,
          type,
        });
      },
    },
    {
      title: '更新时间',
      dataIndex: 'time',
      ellipsis: { showTitle: true },
      render: (v: string, record: any) => {
        return getTsByPropertyId(record.datapropertyId, record.deviceId, valuesMap);
      },
    },
    {
      title: '操作',
      dataIndex: 'op',
      width: 80,
      render: (v: string, record: any) => {
        if (record.isWriteable && hasRemoteBtn) {
          return (
            <Button type="link" onClick={() => remoteOp?.({ ...record })}>
              远控
            </Button>
          );
        }
        return '--';
      },
    },
  ];

  return columns;
};
