import { DataNode } from 'antd/es/tree';
import { Key } from 'react';
import { BsaType } from './components/BsaTree';
import { isNil } from 'lodash';
import { IndicatorsType } from './components/IndicatorsTab';
import { V2BsaBatteryGetBatteryChipDataPostResponse } from '@maxtropy/dmes-apis-v2';

// 平铺所有树结构
export const flattenMap = (data: DataNode[], map: Map<Key, DataNode & { info?: any }> = new Map()) => {
  data.forEach(i => {
    map.set(i.key, i);
    if (i.children) {
      flattenMap(i.children, map);
    }
  });
  return map;
};

export const flattenArray = (data: (DataNode & { info?: any })[], arr: { key: string; title: string }[] = []) => {
  data.forEach(i => {
    arr.push({ key: i.key as string, title: i.info.name });
    if (i.children) {
      flattenArray(i.children, arr);
    }
  });
  return arr;
};

// 根据节点的Id获取所有父节点
export const getPathByNodeId = (data: DataNode[], key: React.Key, path: string[] = []): string[] => {
  for (const node of data) {
    path.push(node.key as string);
    if (node.key === key) return path;
    if (node.children) {
      const findChildren = getPathByNodeId(node.children, key, path);
      if (findChildren?.length) return findChildren;
    }
    path.pop();
  }
  return [];
};

// 寻找第一个key
export function selectDefaltKey(treeData: (DataNode & { info?: any })[]) {
  const res: Key[] = [];
  function loop(array: (DataNode & { info?: any })[], result: Key[]) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].info.type === BsaType.CLUSTER) {
        result.push(array[i].key);
      } else {
        if (array[i].children && array[i].children!.length > 0) {
          loop(array[i].children!, result);
        }
      }
    }
  }
  loop(treeData, res);
  return res[0];
}

export function formatData(activeKey: string, data?: number, unit?: string) {
  return !isNil(data) ? (activeKey === IndicatorsType.VOLTAGE ? data.toFixed(3) + unit : data.toFixed(2) + unit) : '--';
}

export function getPeakData(
  peak: string,
  activeKey?: string,
  bsaBatteryPackNode?: V2BsaBatteryGetBatteryChipDataPostResponse['bsaBatteryPackNode']
) {
  if (activeKey) {
    if (activeKey === IndicatorsType.VOLTAGE) {
      const allv = (bsaBatteryPackNode ?? [])
        .map(item => {
          return (item.children ?? []).map(c => c.voltage as unknown as number);
        })
        .flat()
        .filter(x => !isNil(x));

      if (allv && allv.length > 0) {
        if (peak === 'max') {
          return Math.max(...allv);
        } else {
          return Math.min(...allv);
        }
      } else {
        return null;
      }
    } else {
      const allt = (bsaBatteryPackNode ?? [])
        .map(item => {
          return (item.children ?? []).map(c => c.temperature as unknown as number);
        })
        .flat()
        .filter(x => !isNil(x));

      if (allt && allt.length > 0) {
        if (peak === 'max') {
          return Math.max(...allt);
        } else {
          return Math.min(...allt);
        }
      } else {
        return null;
      }
    }
  }
}
