import { getWeatherStations, PvAreaInfo, PvListItem } from '@/api/pv';
import { PVStatus } from '@/api/type';
import BorderWrapper from '@/components/BorderWrapper';
import { Breadcrumb, Form, message, Select, useBreadcrumbRoutes, useUpdate } from '@maxtropy/components';
import { Space, Spin } from 'antd';
import qs from 'qs';
import { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DmesPageHeader from '../PageHeader';
import Area from './Area';
import Base from './Base';
import DividerContent from '@/components/DividerContent';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import CustomCheckBox from './CustomCheckBox';
import CostInfo, { CostInfoRef } from './CostInfo';
import { apiV2PvStationBasicInfoPost, apiV2PvStationDetailPost } from '@maxtropy/dmes-apis-v2';
import FileInfo from './FileInfo';

export interface BaseRefProps {
  checkForm: () => Promise<any>;
  exportsInfo: React.MutableRefObject<{ lngLats: string; adcode: string }>;
}
export interface AreaRefProps {
  listData: any[];
}

const routes = [{ name: '基础信息' }];
export interface BaseInfoIprops extends PvListItem {
  customerName?: string;
  ouName?: string;
}
export const PvInfoContext = createContext<{
  pvId?: string;
  isView?: boolean;
  pvStatus?: PVStatus;
  areaList?: PvAreaInfo[];
}>({});
const BaseInfo: React.FC<{ isEdit?: boolean }> = ({ isEdit }) => {
  const routesContext = useBreadcrumbRoutes();
  const { id, status } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const pvId = id as string;
  const pvStatus = Number(status) as PVStatus;
  const navigate = useNavigate();
  const [baseInfo, setBaseInfo] = useState<BaseInfoIprops>();
  const [loading, setLoading] = useState(false);
  const baseRef = useRef<BaseRefProps>(null);
  const areaRef = useRef<AreaRefProps>(null);
  const costInfoRef = useRef<CostInfoRef>(null);
  const fileInfoRef = useRef<CostInfoRef>(null);
  const isView = useMemo(() => !isEdit, [isEdit]);
  const [rand] = useUpdate();

  const formLayout = {
    labelCol: { flex: '140px' },
    wrapperCol: { flex: '340px' },
  };

  const [form] = Form.useForm();

  const { data: weatherStations } = useQuery(['weatherStations', pvId], () => getWeatherStations(+pvId));

  // 获取光伏站详情
  useEffect(() => {
    setLoading(true);
    if (!pvId) return;
    apiV2PvStationDetailPost({
      id: pvId,
    })
      .then(res => {
        let tempInfo: BaseInfoIprops = {};
        tempInfo.customerName = res.customer?.name;
        tempInfo.ouName = res.ou?.name;
        setBaseInfo({ ...(res as BaseInfoIprops), ...tempInfo, location: res.location?.split(',') });
        form.setFieldsValue({
          weatherStationId: res.weatherStationId,
          existCombinerBox: res.existCombinerBox,
          existComponents: res.existComponents,
        });
        costInfoRef?.current?.setData(res);
        fileInfoRef?.current?.setData(res);
      })
      .finally(() => setLoading(false));
  }, [pvId, rand, form]);

  const saveBtn = async () => {
    const [baseInfo, costInfo, fileInfo] = await Promise.all([
      baseRef?.current?.checkForm(),
      costInfoRef?.current?.getData(),
      fileInfoRef?.current?.getData(),
    ]);

    // 至少存在一个片区
    let list = areaRef?.current?.listData;
    if (!list || list.length === 0) {
      message.warning('至少存在一个片区');
      return;
    }
    // 获取经纬度
    let { lngLats, adcode } = baseRef?.current?.exportsInfo?.current || {};
    if (!lngLats || !adcode) {
      message.error('经纬度或区域编码信息缺失');
      return;
    }

    if (fileInfoRef?.current?.uploadStatus === 'uploading') {
      message.error('请确认站点布局图状态');
      return;
    }

    let tempArr = lngLats.split(',');
    apiV2PvStationBasicInfoPost({
      id: pvId,
      ...baseInfo,
      adcode,
      createStationTime: dayjs(baseInfo.createStationTime).format('YYYY-MM-DD'),
      location: baseInfo.location.toString(),
      weatherStationId: form.getFieldValue('weatherStationId'),
      existCombinerBox: form.getFieldValue('existCombinerBox'),
      existComponents: form.getFieldValue('existComponents'),
      longitude: tempArr[0],
      latitude: tempArr[1],
      ...costInfo,
      ...fileInfo,
    }).then(() => {
      // forceUpdate();
      backTo();
    });
  };
  const backTo = () => {
    navigate(`/pv-storage/basic/manage`, { replace: true });
  };

  return (
    <>
      <div className="page">
        <div className="page__header">
          <Breadcrumb routes={[...(routesContext?.routes ?? []), ...routes]} />
        </div>
        <Spin spinning={loading}>
          <BorderWrapper>
            {isView ? (
              <DmesPageHeader onCancel={backTo} showOk={false} cancelText="返回" title="基础信息" />
            ) : (
              <DmesPageHeader onOk={saveBtn} onCancel={backTo} title="基础信息" />
            )}
            <PvInfoContext.Provider value={{ pvId, isView, pvStatus }}>
              <Base {...baseInfo} ref={baseRef}></Base>
              <Form labelAlign="left" labelWrap form={form} {...formLayout}>
                <DividerContent title="阵列结构">
                  <Space>
                    <Form.Item label="阵列设置" />
                    <Space size={10}>
                      <Form.Item label="" name="existCombinerBox">
                        <CustomCheckBox disabled={isView}>存在汇流箱</CustomCheckBox>
                      </Form.Item>
                      <Form.Item label="" name="existComponents">
                        <CustomCheckBox disabled={isView}>存在组件</CustomCheckBox>
                      </Form.Item>
                    </Space>
                  </Space>
                </DividerContent>
                <DividerContent title="气象信息">
                  <Form.Item label="绑定气象站" name="weatherStationId">
                    {isView ? (
                      baseInfo?.weatherStation ? (
                        baseInfo?.weatherStation?.code + ' ' + baseInfo?.weatherStation?.name
                      ) : (
                        '--'
                      )
                    ) : (
                      <Select placeholder={'请选择'}>
                        {weatherStations?.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.code} {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </DividerContent>
              </Form>
              <Area ref={areaRef}></Area>
              <CostInfo ref={costInfoRef} isView={isView} />
              <FileInfo ref={fileInfoRef} isView={isView} />
            </PvInfoContext.Provider>
          </BorderWrapper>
        </Spin>
      </div>
    </>
  );
};
export default BaseInfo;
