import React, { FC } from 'react';
import CardBox from '../../../CardBox';
import styles from './index.module.scss';
import line from '../../../../imgs/small_line.png';
import powerIcon from '../../../../imgs/power-icon.png';
import yellowCircle from '../../../../imgs/yellow-circle.png';
import chargeIcon from '../../../../imgs/charge-icon.png';
import circleBlue from '../../../../imgs/circle-blue.png';
import { V2PvNoticeboardIndicatorPostResponse } from '@maxtropy/dmes-apis-v2';
import { keepTwoOrLine } from '@/pages/PvDemonstrationBase/utils';
import { Tooltip } from '@maxtropy/components';

export interface ITodayRunning {
  data?: V2PvNoticeboardIndicatorPostResponse;
}

const TodayRunning: FC<ITodayRunning> = props => {
  const { data } = props;
  return (
    <CardBox title="今日运行">
      <div className={styles.top}>
        <div className={styles.item_box}>
          <Tooltip
            title={keepTwoOrLine(data?.gridConnectedPower)}
            getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
          >
            <div className={styles.value}>{keepTwoOrLine(data?.gridConnectedPower)}</div>
          </Tooltip>
          <div className={styles.label}>并网发电(kWh)</div>
          <img className={styles.img_line} src={line} alt="" />
        </div>
        <div className={styles.item_box}>
          <Tooltip
            title={keepTwoOrLine(data?.chargeConsumption)}
            getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
          >
            <div className={styles.value}>{keepTwoOrLine(data?.chargeConsumption)}</div>
          </Tooltip>
          <div className={styles.label}>消纳电量 (kWh)</div>
          <img className={styles.img_line} src={line} alt="" />
        </div>
        <div className={styles.item_box}>
          <Tooltip
            title={keepTwoOrLine(data?.chargeOnGrid)}
            getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
          >
            <div className={styles.value}>{keepTwoOrLine(data?.chargeOnGrid)}</div>
          </Tooltip>
          <div className={styles.label}>上网电量 (kWh)</div>
          <img className={styles.img_line} src={line} alt="" />
        </div>
      </div>
      <div className={styles.line}>
        <div className={styles.line_dash}></div>
        <div className={styles.line_text}>逆变器</div>
        <div className={styles.line_dash}></div>
      </div>

      <div className={styles.power_box}>
        <div className={styles.power}>
          <div className={styles.label}>
            <img className={styles.yellow_circle} src={yellowCircle} alt="" />
            <img className={styles.power_icon} src={powerIcon} alt="" />
            <span className={styles.desc}>总功率</span>
          </div>
          <Tooltip
            title={keepTwoOrLine(data?.totalPowerOfInverter) + 'kWh'}
            getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
          >
            <div className={styles.value}>{keepTwoOrLine(data?.totalPowerOfInverter)}kWh</div>
          </Tooltip>
        </div>
        <div className={styles.charge}>
          <div className={styles.label}>
            <img className={styles.yellow_circle} src={circleBlue} alt="" />
            <img className={styles.power_icon} src={chargeIcon} alt="" />
            <span className={styles.desc}>总发电量</span>
          </div>
          <Tooltip
            title={keepTwoOrLine(data?.inverterPower) + 'kWh'}
            getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
          >
            <div className={styles.value}>{keepTwoOrLine(data?.inverterPower)}kWh</div>
          </Tooltip>
        </div>
      </div>
      <div className={styles.area_box}>
        {data?.areaPowerList?.map(item => (
          <div key={item.id} className={styles.each_box}>
            <Tooltip
              title={item.name}
              getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
            >
              <div className={styles.bottom_top}>{item.name}</div>
            </Tooltip>
            <div className={styles.bottom_bottom}>
              <div className={styles.label}>功率 (kW)</div>
              <Tooltip
                title={keepTwoOrLine(item.totalPowerOfInverter)}
                getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
              >
                <div className={styles.power_value}>{keepTwoOrLine(item.totalPowerOfInverter)}</div>
              </Tooltip>
              <div className={styles.label}>发电量 (kWh)</div>
              <Tooltip
                title={keepTwoOrLine(item.inverterPower)}
                getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
              >
                <div className={styles.charge_value}>{keepTwoOrLine(item.inverterPower)}</div>
              </Tooltip>
            </div>
          </div>
        ))}
      </div>
    </CardBox>
  );
};

export default TodayRunning;
