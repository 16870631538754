import { PermissionsType } from '@/common/permissionsConst';
import { FormStatus } from '@/types';
import FieldStrategyPage from '@/pages/Strategy/FieldStrategyPage';
import LaunchStrategy from '@/pages/Strategy/LaunchStrategy';
import StrategyTemplateList from '@/pages/Strategy/StrategyTemplateList';
import StrategyTemplatePage from '@/pages/Strategy/StrategyTemplatePage';
import StrategyCalendarList from '@/pages/Strategy/StrategyCalendarList';

const routes = [
  {
    path: '/dmes/energy-storage/strategy/field/:id',
    permission: PermissionsType.P_CBESS_STRATEGY_INFO,
    element: <FieldStrategyPage formStatus={FormStatus.Display} />,
  },
  {
    path: '/dmes/energy-storage/strategy/field/:id/edit',
    permission: PermissionsType.P_UPDATE_CBESS_STRATEGY,
    element: <FieldStrategyPage formStatus={FormStatus.Update} />,
  },
  {
    path: '/dmes/energy-storage/strategy',
    permission: PermissionsType.P_STRATEGY_TEMPLATE,
    element: <StrategyTemplateList />,
  },
  {
    path: '/dmes/energy-storage/strategy/calendar',
    permission: PermissionsType.P_STRATEGY_TEMPLATE,
    element: <StrategyCalendarList />,
  },
  {
    path: '/dmes/energy-storage/strategy/new',
    permission: PermissionsType.P_CREATE_STRATEGY_TEMPLATE,
    element: <StrategyTemplatePage formStatus={FormStatus.Create} />,
  },
  {
    path: '/dmes/energy-storage/strategy/:id/copy',
    permission: PermissionsType.P_CREATE_STRATEGY_TEMPLATE,
    element: <StrategyTemplatePage formStatus={FormStatus.Copy} />,
  },
  {
    path: '/dmes/energy-storage/strategy/:id/launch/:bsaId',
    permission: PermissionsType.P_CREATE_CBESS_STRATEGY,
    element: <LaunchStrategy />,
  },
  {
    path: '/dmes/energy-storage/strategy/:id/launch/copy/:bsaId/:localId',
    permission: PermissionsType.P_CREATE_CBESS_STRATEGY,
    element: <LaunchStrategy isCopy />,
  },
  {
    path: '/dmes/energy-storage/strategy/:id/edit',
    permission: PermissionsType.P_UPDATE_STRATEGY_TEMPLATE,
    element: <StrategyTemplatePage formStatus={FormStatus.Update} />,
  },
];

export default routes;
