import { FC, useEffect, useMemo, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import ReactEcharts from 'echarts-for-react';
import { isNil } from 'lodash-es';
import { BsaType, dash2LegendIcon, DataType, solidLegendIcon, TabType, TimeGranularity } from '../../const';
import { apiV2NeoBsaRunningDataPost, V2NeoBsaRunningDataPostResponse } from '@maxtropy/dmes-apis-v2';
import styles from './index.module.scss';
import { ammeterColorList, bsaColor, inPowerColor, inPowerWithoutBsaColor, pcsColorList } from '../../const';
import { Spin } from 'antd';
import { Empty } from '@maxtropy/components';
import { Chart_Grid_Right, getEndLabel } from '.';

type ResData = Exclude<V2NeoBsaRunningDataPostResponse['list'], undefined>[number];

interface Props {
  bsaId: number | undefined;
  bsaType: BsaType | undefined;
  date: Dayjs;
  timeGranularity: TimeGranularity;
}

const OriginalChart: FC<Props> = props => {
  const { bsaId, bsaType, date, timeGranularity } = props;

  const [resData, setResData] = useState<ResData[]>([]);
  const [loading, setLoading] = useState(false);

  // 请求参数，防止快速切换时，接口返回速度慢导致数据显示错乱
  const cacheParams = useRef({ bsaId, date, timeGranularity });

  const fetchData = async () => {
    if (isNil(bsaId) || isNil(bsaType) || isNil(date) || isNil(timeGranularity)) return;
    cacheParams.current = { bsaId, date, timeGranularity };
    const res = await apiV2NeoBsaRunningDataPost({
      bsaId,
      bsaType,
      tabType: TabType.OPERATING_POWER,
      timeGranularity,
      time: date?.valueOf(),
    });
    if (
      cacheParams.current.bsaId !== bsaId ||
      cacheParams.current.date !== date ||
      cacheParams.current.timeGranularity !== timeGranularity
    ) {
      return;
    }
    res.list?.forEach(item => {
      item.bsaDataList?.forEach(dataItem => {
        if (!isNil(dataItem.value)) {
          dataItem.value = Number(dataItem.value.toFixed(4));
        }
      });
    });
    setResData(res.list ?? []);
  };

  const notEmpty = resData.some(item => item.bsaDataList?.length);

  useEffect(() => {
    setLoading(true);
    setResData([]);
    fetchData()
      .then()
      .finally(() => setLoading(false));
    if (date?.isSame(dayjs(), 'day')) {
      const timer = setInterval(() => {
        fetchData().then();
      }, 1000 * 60);
      return () => clearInterval(timer);
    }
  }, [bsaId, bsaType, date, timeGranularity]);

  const calcedData = useMemo(() => {
    // pcs数据
    const pcsList = resData.filter(item => item.dataType === DataType.PCS);
    // 上端变压器功率
    const upTransformerList = resData.filter(item => item.dataType === DataType.UP_TRANSFORMER);
    // 防逆流电表功率(储能总计量表)
    const antiCurrentData = resData.find(item => item.dataType === DataType.ANTI_CURRENT);
    // 储能总计量表
    const bsaTotalData = antiCurrentData;
    // 储能计量电表功率
    const bsaMeasurementData = resData.find(item => item.dataType === DataType.BSA_MEASUREMENT);

    // 所有的pcs时间
    const allPcsTimeList = getTimeListOfDataType(resData, DataType.PCS);
    // 所有的上端变压器时间
    const allUpTransformerTimeList = getTimeListOfDataType(resData, DataType.UP_TRANSFORMER);
    // 所有的防逆流电表时间
    const allAntCurrentTimeList = getTimeListOfDataType(resData, DataType.ANTI_CURRENT);
    // 所有的储能总计量表时间
    const allBsaTotalTimeList = allAntCurrentTimeList;
    // 所有的储能计量电表时间
    const allBsaMeasurementTimeList = getTimeListOfDataType(resData, DataType.BSA_MEASUREMENT);

    // pcs功率之和
    const pcsTotalData = allPcsTimeList.map(time => {
      let total = 0;
      pcsList.forEach(item => {
        const dataItem = item.bsaDataList?.find(dataItem => dataItem.time === time);
        if (dataItem?.value) {
          total += dataItem.value;
        }
      });
      return { time: time, value: Number(total.toFixed(4)) };
    });

    // 储能功率
    // 新版，各pcs功率之和
    // 老版，储能计量电表功率
    const bsaPowerData = bsaType === BsaType.NEW ? pcsTotalData : bsaMeasurementData?.bsaDataList ?? [];

    // 生产负载(不含储能的进线功率)
    // 新版储能站，防逆流电表功率-PCS功率之和
    // 老版储能站，总计量电表功率-储能计量电表功率
    let inPowerWithoutBsaData;
    if (bsaType === BsaType.NEW) {
      const allTime = getUniqueTimeList([allPcsTimeList, allAntCurrentTimeList]);
      inPowerWithoutBsaData = allTime.map(time => {
        const antiCurrentValue = antiCurrentData?.bsaDataList?.find(dataItem => dataItem.time === time)?.value;
        const pcsTotalValue = pcsTotalData.find(item => item.time === time)?.value;
        if (isNil(antiCurrentValue) || isNil(pcsTotalValue)) return { time, value: null };
        return { time, value: Number((antiCurrentValue - pcsTotalValue).toFixed(4)) };
      });
    } else {
      const allTime = getUniqueTimeList([allBsaTotalTimeList, allBsaMeasurementTimeList]);
      inPowerWithoutBsaData = allTime.map(time => {
        const bsaTotalValue = bsaTotalData?.bsaDataList?.find(dataItem => dataItem.time === time)?.value;
        const bsaMeasurementValue = bsaMeasurementData?.bsaDataList?.find(dataItem => dataItem.time === time)?.value;
        if (isNil(bsaTotalValue) || isNil(bsaMeasurementValue)) return { time, value: null };
        return { time, value: Number((bsaTotalValue - bsaMeasurementValue).toFixed(4)) };
      });
    }

    // 进线功率
    // 新版，防逆流电表功率
    // 老版，储能站总计量电表功率
    let inPowerData;
    if (bsaType === BsaType.NEW) {
      inPowerData = antiCurrentData?.bsaDataList ?? [];
    } else {
      inPowerData = bsaTotalData?.bsaDataList ?? [];
    }

    return {
      bsaPowerData: bsaPowerData, // 储能功率
      inPowerWithoutBsaData: inPowerWithoutBsaData, // 生产负载(不含储能的进线功率)
      inPowerData: inPowerData, // 进线功率
      pcsList: pcsList, // 各pcs功率
      upTransformerList: upTransformerList, // 上端变压器功率
    };
  }, [resData, bsaType]);

  const echartsOption = useMemo(() => {
    const option = {
      grid: {
        left: 50,
        right: Chart_Grid_Right + 10,
        top: 70,
        bottom: 80,
        containLabel: true,
      },
      xAxis: {
        type: 'time',
        axisLabel: {
          color: 'rgba(255,255,255,0.85)',
        },
        axisLine: {
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
      },
      yAxis: {
        name: '功率(kW)',
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        type: 'value',
        axisLabel: {
          color: 'rgba(255,255,255,0.85)',
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
        scale: true,
        boundaryGap: ['5%', '5%'],
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderColor: 'transparent',
        textStyle: {
          color: 'rgba(255,255,255,0.65)',
        },
        formatter(items: any[]) {
          const { axisValueLabel } = items[0];
          let str: string = axisValueLabel;

          items.forEach(item => {
            const { seriesName, data, marker } = item;
            const value = !isNil(data[1]) ? `${data[1]}` : '--';
            str += `<div style="display:flex;margin-top:4px;justify-content:space-between;gap:30px;"><span>${marker}${seriesName}</span> <span>${value}kW</span></div>`;
          });

          return str;
        },
      },
      legend: {
        show: true,
        type: 'scroll',
        right: 20,
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
          fontSize: '14px',
        },
        itemWidth: 16,
        itemHeight: 4,
        data: [],
      },
      dataZoom: [{ start: 0, end: 100 }],
      series: [],
    };

    let legendData = option.legend.data as any[];
    let series = option.series as any[];

    // 储能功率，原始数据，且是新版储能时不展示
    if (timeGranularity === TimeGranularity.ORIGINAL && bsaType === BsaType.NEW) {
    } else {
      legendData.push({
        name: '储能功率',
        icon: solidLegendIcon,
        itemStyle: {
          color: bsaColor,
        },
      });
      series.push({
        name: '储能功率',
        type: 'line',
        showSymbol: false,
        itemStyle: {
          color: bsaColor,
        },
        data: calcedData.bsaPowerData.map(item => [item.time, item.value]),
        ...getEndLabel(calcedData.bsaPowerData.map(item => [item.time, item.value]) as any[]),
      });
    }

    // 生产负载(不含储能的进线功率)
    legendData.push({
      name: '不含储能的进线功率',
      icon: solidLegendIcon,
      itemStyle: {
        color: inPowerWithoutBsaColor,
      },
    });
    series.push({
      name: '不含储能的进线功率',
      type: 'line',
      showSymbol: false,
      itemStyle: {
        color: inPowerWithoutBsaColor,
      },
      data: calcedData.inPowerWithoutBsaData.map(item => [item.time, item.value]),
      ...getEndLabel(calcedData.inPowerWithoutBsaData.map(item => [item.time, item.value]) as any[]),
    });

    // 进线功率
    legendData.push({
      name: '进线功率',
      icon: solidLegendIcon,
      itemStyle: {
        color: inPowerColor,
      },
    });
    series.push({
      name: '进线功率',
      type: 'line',
      showSymbol: false,
      itemStyle: {
        color: inPowerColor,
      },
      data: calcedData.inPowerData.map(item => [item.time, item.value]),
      ...getEndLabel(calcedData.inPowerData.map(item => [item.time, item.value]) as any[]),
    });

    // 上端变压器功率
    calcedData.upTransformerList.forEach((item, index) => {
      legendData.push({
        name: item.deviceName,
        icon: solidLegendIcon,
        itemStyle: {
          color: ammeterColorList[index % ammeterColorList.length],
        },
      });
      series.push({
        name: item.deviceName,
        type: 'line',
        showSymbol: false,
        itemStyle: {
          color: ammeterColorList[index % ammeterColorList.length],
        },
        data: item.bsaDataList?.map(dataItem => [dataItem.time, dataItem.value]) ?? [],
        ...getEndLabel(item.bsaDataList?.map(dataItem => [dataItem.time, dataItem.value]) as any[]),
      });
    });

    // 各pcs功率
    calcedData.pcsList.forEach((item, index) => {
      legendData.push({
        name: item.deviceName,
        icon: dash2LegendIcon,
        itemStyle: {
          color: pcsColorList[index % pcsColorList.length],
        },
      });
      series.push({
        name: item.deviceName,
        type: 'line',
        showSymbol: false,
        itemStyle: {
          color: pcsColorList[index % pcsColorList.length],
        },
        lineStyle: {
          type: [6, 6],
        },
        smooth: true,
        data: item.bsaDataList?.map(dataItem => [dataItem.time, dataItem.value]) ?? [],
        ...getEndLabel(item.bsaDataList?.map(dataItem => [dataItem.time, dataItem.value]) as any[]),
      });
    });

    return option;
  }, [calcedData, bsaType]);

  return (
    <Spin spinning={loading} wrapperClassName={styles.wrap}>
      {notEmpty ? (
        <ReactEcharts option={echartsOption} notMerge className={styles.chart}></ReactEcharts>
      ) : (
        <Empty className={styles.empty} />
      )}
    </Spin>
  );
};

const getUniqueTimeList = (timeList: number[] | number[][]) => {
  return timeList
    .flat()
    .filter((item, index, arr) => {
      return arr.indexOf(item) === index;
    })
    .sort((a, b) => a - b);
};

const getTimeListOfDataType = (resData: ResData[], dataType: DataType) => {
  const list = resData
    .filter(item => item.dataType === dataType)
    .map(item => {
      return (item.bsaDataList ?? []).map(dataItem => {
        return dataItem.time!;
      });
    });
  return getUniqueTimeList(list);
};

export default OriginalChart;
