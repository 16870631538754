import React from 'react';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { ActionType, BsaDeviceTypeEnum, DataProperty, TopologicalType } from '../../../../../api/type';
import CreateForm from './CreateForm';
import { ModbusPointAttributes } from '../../../../../api/entity';

import { getColumns } from './columns';

import styles from './index.module.scss';
import { Table } from '@maxtropy/components';

export type EdgeDeviceTemplatePoint = Omit<ModbusPointAttributes, 'bsaId'> & { actionType?: ActionType };

interface ModbusPointProps {
  fixed?: boolean;
  loading?: boolean;
  editColumns?: ColumnsType<EdgeDeviceTemplatePoint>;
  onUpdate?: (value: EdgeDeviceTemplatePoint) => void;
  dataPropertiesAll?: DataProperty[];
  usedProperties?: number[];
  usedIdentifier?: string[];
  // editable?: boolean;
  row?: Partial<EdgeDeviceTemplatePoint>;
  setRow?: (value?: Partial<EdgeDeviceTemplatePoint>) => void;
  dataSource?: EdgeDeviceTemplatePoint[];
  bsaDeviceType: BsaDeviceTypeEnum;
  topologicalType: TopologicalType;
}

export const getIndexColumn = (fixed?: boolean): ColumnType<EdgeDeviceTemplatePoint> => {
  return {
    title: '序号',
    width: 70,
    fixed: fixed ? 'left' : false,
    render: (_, record, index) => index + 1,
  };
};

const ModbusPointCom: React.FC<ModbusPointProps> = props => {
  const {
    fixed,
    loading,
    editColumns,
    // editable=true,
    onUpdate,
    dataPropertiesAll,
    usedProperties,
    usedIdentifier,
    row,
    setRow,
    dataSource,
    bsaDeviceType,
    topologicalType,
  } = props;

  const onOk = (value: EdgeDeviceTemplatePoint) => {
    if (onUpdate) {
      onUpdate(value);
    }
  };

  return (
    <>
      <div className={styles.table}>
        <Table
          sticky
          loading={loading}
          scroll={{ x: 1900, y: 500 }}
          rowKey="id"
          dataSource={dataSource ?? []}
          columns={[
            getIndexColumn(fixed),
            ...getColumns(topologicalType!, bsaDeviceType, fixed),
            ...(editColumns || []),
          ]}
        />
      </div>
      <CreateForm
        onCancel={() => setRow?.(undefined)}
        row={row}
        key="id"
        dataPropertiesAll={dataPropertiesAll}
        usedProperties={usedProperties}
        usedIdentifier={usedIdentifier}
        onOk={onOk}
        bsaDeviceType={bsaDeviceType}
      />
    </>
  );
};

export default ModbusPointCom;
