import { PermissionsType } from '@/common/permissionsConst';
import StorageOrderConfig from '.';

const routes = [
  {
    path: '/energy-storage/basic/bsa/order/:bsaId',
    permission: PermissionsType.P_REMOTE_CONTROL_SET,
    element: <StorageOrderConfig />,
  },
];
export default routes;
