import { Bsa } from '@/api/bsa';
import { useMemo } from 'react';

// 超高温, 高温, 低温, 超低温颜色
const colors = ['#FA500030', '#FC1D1D40', '#00B3FF40', '#007CFF40'];
/**
 *
 * @param type temper 电池温度, vol 单体电压, env环境温度
 * @param bsaDetail
 * @returns
 */
export const useYaxisMarkArea = (type: string, bsaDetail?: Bsa) => {
  return useMemo(() => {
    if (!bsaDetail) return [];
    let upperOne =
        type === 'temper'
          ? bsaDetail?.monomerTemperatureUpperOne
          : type === 'vol'
          ? bsaDetail?.monomerVoltageUpperOne
          : bsaDetail?.ambientTemperatureUpperOne,
      upperTwo =
        type === 'temper'
          ? bsaDetail?.monomerTemperatureUpperTwo
          : type === 'vol'
          ? bsaDetail?.monomerVoltageUpperTwo
          : bsaDetail?.ambientTemperatureUpperTwo,
      lowOne =
        type === 'temper'
          ? bsaDetail?.monomerTemperatureLowerOne
          : type === 'vol'
          ? bsaDetail?.monomerVoltageLowerOne
          : bsaDetail?.ambientTemperatureLowerOne,
      lowTwo =
        type === 'temper'
          ? bsaDetail?.monomerTemperatureLowerTwo
          : type === 'vol'
          ? bsaDetail?.monomerVoltageLowerTwo
          : bsaDetail?.ambientTemperatureLowerTwo;
    if (upperOne && upperTwo && lowOne && lowTwo) {
      return [
        [{ yAxis: upperTwo, itemStyle: { color: colors[0] } }, { yAxis: upperOne }],
        [{ yAxis: Math.max(upperTwo, upperOne), itemStyle: { color: colors[1] } }, { yAxis: null }],
        [{ yAxis: lowTwo, itemStyle: { color: colors[2] } }, { yAxis: lowOne }],
        [{ yAxis: null, itemStyle: { color: colors[3] } }, { yAxis: Math.min(lowTwo, lowOne) }],
      ];
    }
    if (upperOne && upperTwo) {
      return [
        [{ yAxis: upperTwo, itemStyle: { color: colors[0] } }, { yAxis: upperOne }],
        [{ yAxis: Math.max(upperTwo, upperOne), itemStyle: { color: colors[1] } }, { yAxis: null }],
      ];
    }
    if (lowOne && lowTwo) {
      return [
        [{ yAxis: lowTwo, itemStyle: { color: colors[2] } }, { yAxis: lowOne }],
        [{ yAxis: null, itemStyle: { color: colors[3] } }, { yAxis: Math.min(lowTwo, lowOne) }],
      ];
    }
    if ((upperOne || upperTwo) && (lowOne || lowTwo)) {
      let upper = upperOne || upperTwo;
      let lower = lowOne || lowTwo;
      return [
        [{ yAxis: upper, itemStyle: { color: colors[0] } }, { yAxis: null }],
        [{ yAxis: null, itemStyle: { color: colors[2] } }, { yAxis: lower }],
      ];
    }
    return [];
  }, [bsaDetail, type]);
};
