import { Space } from 'antd';
import { FC, CSSProperties, ReactNode } from 'react';
import { CancelButton } from '../UtilsButton';

import styles from './index.module.scss';

export interface StepProps {
  title: ReactNode;
  style?: CSSProperties;
  children?: React.ReactNode;
  extraContent?: ReactNode;
}

const BsaPageHeader: FC<StepProps> = props => {
  const { title, style, children, extraContent } = props;

  return (
    <>
      <div className={styles.pageHeaderWrapper} style={style}>
        <div className={styles.titleWrapper}>
          <p className={styles.formTitle}>
            <span className={styles.titleBorder} />
            {title}
          </p>
          <div>
            <Space>
              {extraContent}
              <CancelButton />
            </Space>
          </div>
        </div>
        {children && <div className={styles.content}>{children}</div>}
      </div>
    </>
  );
};

export default BsaPageHeader;
