import ShowInput from '@/components/ShowInput';
import { EllipsisSpan, FormContent, Table, useBreadcrumbRoutes, Wrapper } from '@maxtropy/components';
import {
  AlarmFaultSignboardInverterRecordDetailPostResponse,
  apiAlarmFaultSignboardInverterRecordDetailPost,
} from '@maxtropy/dmes-apis';
import { Button, Col, Form, Row, Tag } from 'antd';
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import { alarmInverterDeviceDtoList } from './types';
import { AlarmLevel, AlarmLevelColorDisplay, AlarmLevelDisplay } from '@/api/alarm';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

interface Props {}
const Detail: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const [loading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<alarmInverterDeviceDtoList[]>();
  const [pageInfo, setPageInfo] = useState<AlarmFaultSignboardInverterRecordDetailPostResponse>();

  const [form] = Form.useForm();

  useEffect(() => {
    apiAlarmFaultSignboardInverterRecordDetailPost({ id }).then(res => {
      setLoading(true);
      if (res) {
        setPageInfo(res);
        setList(res.alarmInverterDeviceDtoList as alarmInverterDeviceDtoList[]);
        form.setFieldsValue({
          ...res,
          alarmTime: (res as any).alarmTime && dayjs((res as any).alarmTime).format('YYYY-MM-DD HH:mm:ss'),
        });
        setLoading(false);
      }
    });
  }, [id, form]);

  const columns = [
    {
      title: '逆变器编号',
      dataIndex: 'code',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '逆变器名称',
      dataIndex: 'name',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '所属片区',
      dataIndex: 'pvAreaName',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '离散率',
      dataIndex: 'rate',
      render: (v: string) => <EllipsisSpan value={v + '%'} />,
    },

    {
      title: '操作',
      fixed: 'right' as const,
      render: (_record: alarmInverterDeviceDtoList) => (
        <Button
          type="link"
          onClick={() => {
            window.location.href = `${window.PVANALYSIS}/string-dispersion?stationId=${pageInfo?.pvId}`;
          }}
        >
          查看
        </Button>
      ),
    },
  ];

  const routes = [{ name: '记录详情' }];
  return (
    <>
      <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
        <Form layout="vertical" {...formLayout} form={form}>
          <FormContent>
            <Row>
              <Col span={8} className={styles.col} style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Item
                  name="name"
                  className={styles.ruleCol_width}
                  label={
                    <span>
                      规则名称
                      <Button
                        type="link"
                        onClick={() => {
                          window.open(
                            `${window.IOTPLATFORMORIGIN}/pvOps/server/alarm/detail/${(pageInfo as any).id}`,
                            '_blank'
                          );
                        }}
                      >
                        查看
                      </Button>
                    </span>
                  }
                >
                  <ShowInput />
                </Form.Item>
              </Col>

              <Col span={8} className={styles.col}>
                <Form.Item name="code" label="规则编号">
                  <ShowInput />
                </Form.Item>
              </Col>

              <Col span={8} className={styles.col}>
                <Form.Item label="报警等级">
                  {pageInfo ? (
                    <Tag color={AlarmLevelColorDisplay[pageInfo?.alarmLevel as AlarmLevel]}>
                      {AlarmLevelDisplay[pageInfo?.alarmLevel as AlarmLevel]}
                    </Tag>
                  ) : (
                    '--'
                  )}
                </Form.Item>
              </Col>

              <Col span={8} className={styles.col}>
                <Form.Item name="alarmTime" label="报警时间">
                  <ShowInput />
                </Form.Item>
              </Col>

              <Col span={8} className={styles.col} style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Item
                  name="pvCode"
                  label={
                    <span>
                      光伏站编号
                      <Button
                        type="link"
                        onClick={() => {
                          window.location.href = `${window.PVANALYSIS}/string-dispersion?stationId=${pageInfo?.pvId}`;
                        }}
                      >
                        查看
                      </Button>
                    </span>
                  }
                >
                  <ShowInput />
                </Form.Item>
              </Col>

              <Col span={8} className={styles.col}>
                <Form.Item name="pvName" label="光伏站名称">
                  <ShowInput />
                </Form.Item>
              </Col>
            </Row>

            <Col span={24} className={styles.col}>
              <Table
                rowKey="id"
                sticky
                columns={columns}
                loading={loading}
                dataSource={list}
                pagination={false}
              ></Table>
            </Col>
          </FormContent>

          <div className={'page__content__footer'} style={{ marginLeft: 40 }}>
            <Button type="default">
              <Link to="/assets/alarm/overview/">返回</Link>
            </Button>
          </div>
        </Form>
      </Wrapper>
    </>
  );
};

export default Detail;
