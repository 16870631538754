import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  Form,
  useBreadcrumbRoutes,
  useUpdate,
  EllipsisSpan,
  SubContent,
  Empty,
  Modal,
  Table,
  Select,
  Wrapper,
  Button,
  FormTitle,
} from '@maxtropy/components';
import ShowInput from '@/components/ShowInput';
import { Row, Col, Space, Typography, TableColumnsType } from 'antd';
import {
  AlarmLogDetailResponse,
  getAlarmLogDetail,
  readAlarmLog,
  AlarmLogRuleRelatedPropertiesResponse,
  RelatedPropertyDetail,
  AlarmLogPointSTFHData,
  getAlarmLogPointSTFHList,
  getAlarmLogRuleRelatedProperties,
  getRuleRelatedPropertyList,
} from '@/api/alarm';
import dayjs from 'dayjs';
import { useHasPermission } from '@/utils/hooks';
import { PermissionsType } from '@/common/permissionsConst';
import { qingflowWorkDetailPath } from './const';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  DataZoomComponent,
  ToolboxComponent,
  LegendComponent,
  MarkLineComponent,
} from 'echarts/components';
import {
  ComposeOption,
  DataZoomComponentOption,
  LineSeriesOption,
  TitleComponentOption,
  ToolboxComponentOption,
  TooltipComponentOption,
  XAXisComponentOption,
  YAXisComponentOption,
  LegendComponentOption,
  MarkLineComponentOption,
} from 'echarts';
import qs from 'qs';
import { isEmpty } from 'lodash-es';
import { Link } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';
import WorkOrderModal from './WorkOrderModal';
import styles from './index.module.scss';

type RelatedPropertyTableData = {
  key: React.Key;
  dataPropertyNameWithUnit: string;
  alarmValue: number;
  recoverValue: number;
  realtimeValue: number;
};

echarts.use([
  GridComponent,
  TooltipComponent,
  TitleComponent,
  DataZoomComponent,
  ToolboxComponent,
  LegendComponent,
  MarkLineComponent,
  LineChart,
  CanvasRenderer,
]);

export default function AlarmRecordDetail() {
  const { id } = useParams<{ id: string }>();
  const [updateState, forceUpdate] = useUpdate();
  const routesContext = useBreadcrumbRoutes();
  const hasCreateWorkOrderPermission = useHasPermission(PermissionsType.B_WORKER_ORDER_CREATE);
  const [alarmLogDetail, setAlarmLogDetail] = useState<AlarmLogDetailResponse>({} as AlarmLogDetailResponse);
  const [showHighFrequencyRecorderModal, setShowHighFrequencyRecorderModal] = useState<boolean>(false);
  const [relatedProperties, setRelatedProperties] = useState<AlarmLogRuleRelatedPropertiesResponse[]>([]);
  const relatedPropertyTableData: RelatedPropertyTableData[] = useMemo(() => {
    return relatedProperties.map(v => ({
      key: v.dataPropertyId,
      dataPropertyNameWithUnit: v.generalName
        ? `${v.dataPropertyName}（${v.generalName}）`
        : `${v.dataPropertyName}（--）`,
      alarmValue: v.alarmValue,
      recoverValue: v.recoverValue,
      realtimeValue: v.realtimeValue,
    }));
  }, [relatedProperties]);
  // 高频录波多选框
  const [ruleRelatedPropertyList, setRuleRelatedPropertyList] = useState<RelatedPropertyDetail[]>([]);
  const [selectedRelatedPropertyId, setSelectedRelatedPropertyId] = useState<string>('');
  // 高频录波数据
  const [selectedRelatedPropertySTFHData, setSelectedRelatedPropertySTFHData] = useState<AlarmLogPointSTFHData[]>([]);
  const timerRef = useRef<number>();
  const mountedRef = useRef(false);
  const [renderLoading, setRenderLoading] = useState(false);
  // 工单模态框
  const [showWorkOrderModal, setShowWorkOrderModal] = useState(false);

  const relatedPropertyTableColumns: TableColumnsType<RelatedPropertyTableData> = [
    {
      title: '数据属性',
      dataIndex: 'dataPropertyNameWithUnit',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '触发时',
      dataIndex: 'alarmValue',
      ellipsis: { showTitle: true },
      render: (v: number) => (v ? <EllipsisSpan value={v.toString()} /> : '--'),
    },
    {
      title: '恢复时',
      dataIndex: 'recoverValue',
      ellipsis: { showTitle: true },
      render: (v: number) => (v ? <EllipsisSpan value={v.toString()} /> : '--'),
    },
    {
      title: '实时值',
      dataIndex: 'realtimeValue',
      ellipsis: { showTitle: true },
      render: (v: number) => (v ? <EllipsisSpan value={v.toString()} /> : '--'),
    },
  ];

  const option: ComposeOption<
    | DataZoomComponentOption
    | LineSeriesOption
    | TitleComponentOption
    | ToolboxComponentOption
    | TooltipComponentOption
    | XAXisComponentOption
    | YAXisComponentOption
    | LegendComponentOption
    | MarkLineComponentOption
  > = {
    backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue('--component-background'),
    title: {},
    legend: {},
    animation: false,
    tooltip: {
      show: true,
      formatter: (params: any) => {
        const { data } = params;
        const [time, value] = data as [number, number];
        return `
          <div>
            <div>时间: ${dayjs(time).format('YYYY-MM-DD HH:mm:ss')}</div>
            <div>数值: <strong>${value}</strong></div>
          </div>
        `;
      },
    },
    xAxis: {
      type: 'time',
      axisLabel: {
        formatter: '{HH}:{mm}',
      },
    },
    yAxis: {
      // 单位配置
      name: ruleRelatedPropertyList.find(v => selectedRelatedPropertyId === v.id.toString())?.generalName,
      nameTextStyle: {
        align: 'left',
        padding: [0, 0, 0, -15],
      },
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: 'none',
        },
        restore: {},
        saveAsImage: {},
      },
    },
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100,
      },
      {
        start: 0,
        end: 100,
      },
    ],
    series: [
      {
        name: '',
        sampling: 'lttb',
        type: 'line',
        smooth: true,
        data: selectedRelatedPropertySTFHData.map(v => [v.ts, v.value]),
        markLine: {
          silent: true,
          symbol: 'none',
          label: {
            show: true,
            formatter: '报警时间',
          },
          lineStyle: {
            color: 'red',
            type: 'solid',
          },
          data: [{ xAxis: dayjs(alarmLogDetail?.alarmTime).valueOf() }],
        },
      },
    ],
  };

  const readLog = () => {
    if (id) {
      readAlarmLog(id).then(res => {
        if (res) {
          Modal.success({
            title: '已读！',
            okText: '确定',
            onOk() {
              forceUpdate();
            },
            onCancel() {
              forceUpdate();
            },
          });
        }
      });
    }
  };

  useEffect(() => {
    if (id) {
      getAlarmLogDetail(id).then(res => {
        setAlarmLogDetail(res);
      });
    }
    // 依赖需要加上 updateState 保证底部已读点击后触发, 重新渲染一次
  }, [id, updateState]);

  useEffect(() => {
    if (id && !isEmpty(selectedRelatedPropertyId)) {
      getAlarmLogPointSTFHList(id, selectedRelatedPropertyId!.toString()).then(res =>
        setSelectedRelatedPropertySTFHData(res)
      );
    }
  }, [id, selectedRelatedPropertyId]);

  const fetchRelatedProperties = useCallback(() => {
    if (id) {
      getAlarmLogRuleRelatedProperties(id).then(res => {
        setRelatedProperties(res);
      });
    }
  }, [id]);

  const longPollRelatedProperties = useCallback(async () => {
    await fetchRelatedProperties();
    if (mountedRef.current) {
      // 1 分钟请求一次
      timerRef.current = window.setTimeout(longPollRelatedProperties, 1000 * 60);
    }
  }, [fetchRelatedProperties]);

  useEffect(() => {
    if (alarmLogDetail?.state === 0) {
      // 已恢复不进行轮询
      fetchRelatedProperties();
    } else {
      // 未恢复的报警进行轮询
      mountedRef.current = true;
      longPollRelatedProperties();
    }

    return () => {
      mountedRef.current = false;
      window.clearTimeout(timerRef.current);
    };
  }, [alarmLogDetail?.state, fetchRelatedProperties, id, longPollRelatedProperties]);

  useEffect(() => {
    if (id) {
      getRuleRelatedPropertyList(id).then(res => {
        if (!isEmpty(res)) {
          setRuleRelatedPropertyList(res);
          setSelectedRelatedPropertyId(res[0].id.toString());
        }
      });
    }
  }, [id]);

  const renderChart = () => {
    if (isEmpty(selectedRelatedPropertySTFHData)) {
      return (
        <Empty
          description={`暂无${
            ruleRelatedPropertyList.find(v => v.id.toString() === selectedRelatedPropertyId)?.name
          }的高频录波数据`}
        />
      );
    } else {
      return (
        <ReactEChartsCore
          style={{ border: '1px solid #f0f0f0', marginTop: '16px' }}
          echarts={echarts}
          theme={'dark'}
          notMerge
          option={option}
          loadingOption={{
            text: '加载中',
          }}
          showLoading={renderLoading}
          onEvents={{
            rendered: () => {
              setRenderLoading(false);
            },
            finished: () => {
              setRenderLoading(false);
            },
          }}
        />
      );
    }
  };

  return (
    <Wrapper className={styles.wrapperPadding} routes={routesContext?.routes ?? []}>
      <Form layout="vertical">
        <FormTitle title="查看" />
        <SubContent title="回路信息">
          <Row>
            <Col span={8}>
              <Form.Item label="回路编号">
                <ShowInput value={alarmLogDetail?.circuitCode} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="回路名称">
                <ShowInput value={alarmLogDetail?.circuitName} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="所属配电柜">
                <ShowInput value={alarmLogDetail?.distributionCabinetName} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="所属运营单元">
                <ShowInput value={alarmLogDetail?.ouName} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="设备编号">
                {alarmLogDetail?.deviceName ? (
                  <Typography.Link
                    href={`${window.IOTPLATFORMORIGIN}/device/manage/device/${alarmLogDetail?.deviceId}/detail`}
                    target="_blank"
                  >
                    <ShowInput value={alarmLogDetail?.deviceCode} />
                  </Typography.Link>
                ) : (
                  '--'
                )}
              </Form.Item>
            </Col>
          </Row>
        </SubContent>

        <SubContent title="报警信息">
          <Row>
            <Col span={8}>
              <Form.Item label="报警信息">
                <ShowInput value={alarmLogDetail?.alarmName} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="报警时间">
                <ShowInput value={dayjs(alarmLogDetail?.alarmTime).format('YYYY/MM/DD HH:mm:ss')} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="恢复时间">
                <ShowInput
                  value={
                    alarmLogDetail?.recoveryTime
                      ? dayjs(alarmLogDetail?.recoveryTime).format('YYYY/MM/DD HH:mm:ss')
                      : '未恢复'
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="报警规则">
                {alarmLogDetail?.ruleName ? (
                  <Typography.Link
                    href={`${window.IOTPLATFORMORIGIN}/device/rule/list/detail/${alarmLogDetail?.ruleId}`}
                    target="_blank"
                  >
                    <ShowInput value={alarmLogDetail?.ruleName} />
                  </Typography.Link>
                ) : (
                  '--'
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="已读时间">
                <ShowInput
                  value={
                    alarmLogDetail?.readTime ? dayjs(alarmLogDetail?.readTime).format('YYYY/MM/DD HH:mm:ss') : undefined
                  }
                />
              </Form.Item>
            </Col>

            {alarmLogDetail?.edgeGatewayNo ? null : (
              <Col span={20}>
                <Form.Item
                  label="关联属性"
                  tooltip={{
                    title: '根据时间，前后3分钟范围内无数据则不显示。实时值每分钟更新。',
                    icon: <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />,
                  }}
                >
                  {isEmpty(relatedProperties) ? (
                    <Empty description="报警规则未配置关联属性" />
                  ) : (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginTop: '16px',
                          marginBottom: '16px',
                        }}
                      >
                        {/* 关联属性存在非录波数据, 显示下面文字 */}
                        {relatedProperties.every(v => v.isSthf) ? (
                          <div />
                        ) : (
                          <Typography.Text type="secondary">
                            未采集高频录波数据，数据与实际触发可能不一致。
                          </Typography.Text>
                        )}
                        <Typography.Link
                          onClick={() => {
                            setShowHighFrequencyRecorderModal(true);
                            setRenderLoading(true);
                          }}
                        >
                          高频录波
                        </Typography.Link>
                      </div>

                      <Table
                        bordered
                        // 如果为 0 说明报警已恢复, 无需展示实时值
                        columns={
                          alarmLogDetail?.state === 0
                            ? relatedPropertyTableColumns.slice(0, relatedPropertyTableColumns.length - 1)
                            : relatedPropertyTableColumns
                        }
                        dataSource={relatedPropertyTableData}
                        pagination={false}
                      />
                    </>
                  )}
                </Form.Item>
              </Col>
            )}
          </Row>
          <Space className="sticky-footer-left" size={8}>
            <Button
              type="primary"
              onClick={() => {
                window.open(
                  `${window.ALARMWORKORDER}/workOrder/repairWorkOrder/workOrderList/workOrderAdd?${qs.stringify(
                    {
                      problemSource: 10,
                      operationUnitCode: [alarmLogDetail?.ouId],
                      alarmId: alarmLogDetail?.id,
                      alarmTypeCode: -1,
                    },
                    { indices: false }
                  )}`,
                  '_self'
                );
              }}
            >
              发起工单
            </Button>
            <Button>
              <Link
                to={`/dmes/statistics/circuit?id=${alarmLogDetail?.circuitId}&time=${dayjs(
                  alarmLogDetail.alarmTime
                ).valueOf()}`}
                target="_blank"
              >
                查看回路数据
              </Link>
            </Button>
            {hasCreateWorkOrderPermission ? (
              alarmLogDetail?.ruleLogWorkOrderId ? (
                <Button onClick={() => window.open(qingflowWorkDetailPath, '_blank')}>查看工单</Button>
              ) : (
                <Button
                  onClick={() => {
                    setShowWorkOrderModal(true);
                  }}
                >
                  创建工单
                </Button>
              )
            ) : null}

            {alarmLogDetail?.readTime ? null : (
              <Button
                disabled={!!alarmLogDetail?.readTime}
                type="primary"
                onClick={() => {
                  readLog();
                }}
              >
                确认已读
              </Button>
            )}
          </Space>
        </SubContent>
      </Form>

      <Modal
        size="large"
        title="高频录波"
        open={showHighFrequencyRecorderModal}
        onCancel={() => {
          setShowHighFrequencyRecorderModal(false);
          setRenderLoading(true);
        }}
        footer={
          <Button
            type="primary"
            onClick={() => {
              setShowHighFrequencyRecorderModal(false);
            }}
          >
            确定
          </Button>
        }
      >
        <Row justify="space-between">
          <Col span={12}>
            <span>数据属性 </span>
            <Select
              optionFilterProp="label"
              value={selectedRelatedPropertyId}
              options={ruleRelatedPropertyList.map(v => ({ value: v.id.toString(), label: v.name }))}
              onChange={(value: string) => {
                setSelectedRelatedPropertyId(value);
              }}
              style={{ display: 'inline-block', width: '120px' }}
            />
          </Col>
          {isEmpty(selectedRelatedPropertySTFHData) ? (
            <div />
          ) : (
            <Col span={6}>
              <Button
                onClick={() => {
                  window.open(`/api/rule/device/alarm/log/point-sthf-list/export/${id}`);
                }}
                style={{ float: 'right' }}
              >
                导出数据
              </Button>
            </Col>
          )}
        </Row>
        {renderChart()}
      </Modal>

      <WorkOrderModal
        id={id}
        open={showWorkOrderModal}
        forceUpdate={forceUpdate}
        closeWorkOrderModal={() => {
          setShowWorkOrderModal(false);
        }}
      />
    </Wrapper>
  );
}
