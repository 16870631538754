import { FC, ReactNode } from 'react';

const ShowInput: FC<{ value?: ReactNode; addonAfter?: string; children?: React.ReactNode }> = ({
  value,
  addonAfter,
  children,
}) => (
  <div>
    {(value ?? '--') + ' ' + (addonAfter ?? '')}
    {children}
  </div>
);

export default ShowInput;
