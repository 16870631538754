import BorderWrapper from '@/components/BorderWrapper';
import { Breadcrumb, FormContent, Steps, useBreadcrumbRoutes } from '@maxtropy/components';
import { useRequest } from 'ahooks';
import { Spin } from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './index.module.scss';
import { apiV2BsaSecondEditionInfoBsaSecondEditionPost } from '@maxtropy/dmes-apis-v2';
import EmptyForm from './components/EmptyForm';
import {
  ChildDataRef,
  ComponentShow,
  CreateStatus,
  DataValueContext,
  getCurrentStepTileList,
  getStructureInfo,
  stepTileListAll,
  StructStep,
} from './const';
import TopOpButtons from './components/TopOpButtons';

interface Iprops {
  isView?: boolean;
}
const BsaNewSteps = ({ isView }: Iprops) => {
  const routesContext = useBreadcrumbRoutes();
  const formRef = useRef<ChildDataRef>(null);
  const navigate = useNavigate();
  const routes = [{ name: `阵列结构配置` }];
  const { id } = useParams<{ id: string }>();
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  // 当前步骤
  const [localCurrentStep, setLocalCurrentStep] = useState<StructStep>(-1);
  const remoteStep = useRef<number>(0);
  // 当前选择的阵列
  const [selectStepList, setSelectStepList] = useState<string[]>([]);

  // 数据值详情
  const {
    data: detail,
    loading,
    refresh: refreshDetail,
  } = useRequest(
    () =>
      apiV2BsaSecondEditionInfoBsaSecondEditionPost({ id: Number(id) }).then(res => {
        let { hasRack, hasPack, hasCell } = res;
        let tempList = getStructureInfo(hasRack, hasPack, hasCell);
        let tempCurrentStepTitle = getCurrentStepTileList(tempList);
        // 点击完成后返回列表页
        if (!isView && typeof res.actualStatus === 'number' && res.actualStatus >= tempCurrentStepTitle.length) {
          backList();
          return;
        }
        setSelectStepList(tempList);
        return res;
      }),
    {
      ready: !!id,
      refreshDeps: [id, isView],
    }
  );
  /** 判断1,2,3步的表单是否可以编辑
   * 1. 在只要是簇未生成之前都可以编辑
   *
   * */
  const isCanEdit = useMemo(() => {
    return detail?.rackDeviceCreateStatus !== CreateStatus.DONE;
  }, [detail]);

  // 显示的步骤条
  const currentStepTileList = useMemo(() => {
    return stepTileListAll.filter(item => !item.prop || selectStepList.includes(item.prop));
  }, [selectStepList]);

  useEffect(() => {
    let actualStatus = detail?.actualStatus ?? -1;
    remoteStep.current = actualStatus;
    if (isView) {
      setLocalCurrentStep(0);
      return;
    }
    setLocalCurrentStep(actualStatus);
  }, [detail, isView]);

  // 当前步骤要显示的表单
  const CurrentFormItem = useMemo(() => {
    return ComponentShow[localCurrentStep] ?? EmptyForm;
  }, [localCurrentStep]);

  const saveForm = () => {
    formRef.current?.saveForm();
  };
  const saveDraft = () => {
    formRef.current?.saveDraft?.();
  };
  // 重新查询详情
  const refresh = () => {
    refreshDetail();
  };
  const nextBtn = () => {
    saveForm();
  };
  const prevBtn = () => {
    prevStep();
  };

  const nextStep = useCallback(() => {
    setLocalCurrentStep(current => current + 1);
  }, []);
  const prevStep = useCallback(() => {
    setLocalCurrentStep(current => current - 1);
  }, []);

  // contextValue 注入的值
  const contextValue = useMemo(() => {
    return {
      data: detail,
      isCanEdit: isCanEdit,
      isView,
      nextStep,
      prevStep,
      saveForm,
      selectStepList,
      setSelectStepList,
      saveLoading,
      setSaveLoading,
      bsaId: id,
      refresh,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail, nextStep, prevStep, selectStepList, saveLoading, id, isCanEdit]);

  // 返回列表页
  const backList = () => {
    navigate('/energy-storage/basic/bsaNew', { replace: true });
  };

  return (
    <div className={styles.page_box}>
      <div className="page">
        <div className="page__header">
          <Breadcrumb routes={[...(routesContext?.routes ?? []), ...routes]} />
        </div>
        <BorderWrapper>
          <div className="page__content">
            <FormContent
              style={{ paddingTop: 5, paddingLeft: 10, paddingRight: 10 }}
              title={`${detail?.name ?? ''}储能站阵列表单`}
              extraContent={
                <TopOpButtons
                  currentStepTileList={currentStepTileList}
                  isView={isView}
                  localCurrentStep={localCurrentStep}
                  detail={detail}
                  remoteStep={remoteStep.current}
                  isCanEdit={isCanEdit}
                  saveLoading={saveLoading}
                  prevBtn={prevBtn}
                  nextStep={nextStep}
                  nextBtn={nextBtn}
                  saveDraft={saveDraft}
                  backBtn={backList}
                />
              }
            >
              <div className={styles.steps_box}>
                <Steps current={localCurrentStep} items={currentStepTileList} />
              </div>
              <div className={styles.form_content}>
                <Spin spinning={loading}>
                  <DataValueContext.Provider value={contextValue}>
                    <CurrentFormItem ref={formRef} />
                  </DataValueContext.Provider>
                </Spin>
              </div>
            </FormContent>
          </div>
        </BorderWrapper>
      </div>
    </div>
  );
};

export default BsaNewSteps;
