import React from 'react';
import { Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, EllipsisSpan, Modal, Table, message } from '@maxtropy/components';
import { ColumnsType } from 'antd/es/table';
import { ModuleImportError } from '@/api/pv-string';

export interface ImportErrorModalProps {
  onCancel: () => void;
  errors: ModuleImportError[];
}

const ImportErrorModal: React.FC<ImportErrorModalProps> = ({ onCancel, errors }) => {
  return (
    <Modal
      open
      title={`导入`}
      maskClosable={false}
      onCancel={onCancel}
      footer={
        <Space>
          <Button
            onClick={() => {
              navigator.clipboard
                .writeText(
                  errors.reduce((acc, curr) => {
                    return (
                      acc +
                      '\n' +
                      `${curr.pvModuleCode ?? '--'},${curr.deviceCode ?? '--'},${curr.pvModuleCode ?? '--'},${
                        curr.errorMessage ?? '--'
                      }`
                    );
                  }, '组件编号,绑定设备编号,关联组串编号,问题描述')
                )
                .then(() => {
                  message.success('复制成功！');
                  onCancel();
                });
            }}
          >
            复制错误信息
          </Button>
          <Button type="primary" onClick={onCancel}>
            知道了
          </Button>
        </Space>
      }
    >
      <div style={{ padding: 20 }}>
        <div style={{ display: 'flex' }}>
          <ExclamationCircleOutlined style={{ color: '#D89614', fontSize: 16, marginTop: 6 }} />
          <div style={{ marginLeft: 20, fontSize: 16, fontWeight: 600 }}>
            <div>导入结束！</div>
            <div style={{ marginTop: 6, fontSize: 14, fontWeight: 'normal' }}>请先复制问题清单，并进行修改！</div>
          </div>
        </div>

        <Table style={{ marginTop: 30 }} rowKey="index" columns={columns} dataSource={errors} />
      </div>
    </Modal>
  );
};

const columns: ColumnsType<ModuleImportError> = [
  {
    title: '组件编号',
    dataIndex: 'pvModuleCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '绑定设备编号',
    dataIndex: 'deviceCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '关联组串编号',
    dataIndex: 'pvStringCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '问题描述',
    dataIndex: 'errorMessage',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export default ImportErrorModal;
