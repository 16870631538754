import { PermissionsType } from '@/common/permissionsConst';
import ElectricityConsumptionAnalysis from '@/pages/ElectricityConsumptionAnalysis';

const routes = [
  {
    path: '/dmes/statistics/e-consumption',
    permission: PermissionsType.P_ELECTRICITY_CONSUMPTION_ANALYSIS,
    element: <ElectricityConsumptionAnalysis />,
  },
];

export default routes;
