import { StatisticsPartition } from '@/pages/EnergyAssetProjectIncome/components/DateSwitch';
import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { Key } from 'react';

export interface OrgOuTreeReq {
  ouName?: string;
}

export interface OuList {
  id: number;
  name: string;
  serialNumber: string;
}

export interface OrgOuTreeRes {
  customerName: string;
  mcid: number;
  ouList: OuList[];
}

// 组织-ou树
export function getcustomerOuTree(params: OrgOuTreeReq) {
  return fetch<OrgOuTreeRes[]>(
    `/api/energy-asset-project/customer-ou-tree?${qs.stringify(params, { indices: false })}`
  );
}

export interface OuIncomeInfoReq {
  ouId: Key; //ouId
  resolution: StatisticsPartition; //时间颗粒度。支持：D(日),M(月)
  startTime: Key; //开始时间
  endTime: Key; //结束时间
}

export interface OuIncomeInfoRes {
  projectName: string;
  directorName: string;
  projectAddress: string;
  projectTotalIncome: number;
  averageIncomeByDay: number;
  highestIncomeByDay: number;
  lowestIncomeByDay: number;
}

// ou-收益 相关信息
export function getOuIncomeInfo(params: OuIncomeInfoReq) {
  return fetch<OuIncomeInfoRes>(`/api/energy-asset-project/ou-income-info?${qs.stringify(params, { indices: false })}`);
  // return fetch<OuIncomeInfoRes>(
  //   `http://10.50.16.213:40001/mock/295/api/energy-asset-project/ou-income-info?${qs.stringify(params, {
  //     indices: false,
  //   })}`
  // );
}

export interface PvIncome {
  income: number;
  incomeRate: number;
}

export interface BsaIncome {
  income: number;
  incomeRate: number;
}

export interface EvIncome {
  income: number;
  incomeRate: number;
}

export interface ProjectTotalIncomeRes {
  incomeDate: number;
  incomeCount: number;
  pvIncome: PvIncome;
  bsaIncome: BsaIncome;
  evIncome: EvIncome;
}

// 项目总收益
export function getProjectTotalIncome(params: OuIncomeInfoReq) {
  return fetch<ProjectTotalIncomeRes[]>(
    `/api/energy-asset-project/project-total-income?${qs.stringify(params, { indices: false })}`
  );
  // return fetch<ProjectTotalIncomeRes[]>(
  //   `http://10.50.16.213:40001/mock/295/api/energy-asset-project/project-total-income?${qs.stringify(params, {
  //     indices: false,
  //   })}`
  // );
}

export interface ProjectIncomeProportionRes {
  id: number;
  stationName: string;
  type: string;
  cumulativeGain: number;
  revenueProportion: number;
}

// 收益占比饼图
export function getProjectIncomeProportion(params: OuIncomeInfoReq) {
  return fetch<ProjectIncomeProportionRes[]>(
    `/api/energy-asset-project/income-proportion?${qs.stringify(params, { indices: false })}`
  );
  // return fetch<ProjectIncomeProportionRes[]>(
  //   `http://10.50.16.213:40001/mock/295/api/energy-asset-project/income-proportion?${qs.stringify(params, {
  //     indices: false,
  //   })}`
  // );
}
