export enum OperationType {
  ADD = 1,
  EDIT,
  DELETE,
}
export const formateOperationTypeDisplay = {
  [OperationType.ADD]: '新增',
  [OperationType.EDIT]: '编辑',
  [OperationType.DELETE]: '删除',
};

export const formateOperationTypeColor = {
  [OperationType.ADD]: 'success',
  [OperationType.EDIT]: 'info',
  [OperationType.DELETE]: 'warning',
};
export function isJSON(str?: string) {
  if (!str) return false;
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}
