import G6 from '@antv/g6';
import { graphsProps } from '@/pages/CircuitManage/components/CircuitTopologicalGraghTree';
import iconImg from '@/assets/images/circuit/topoIcon.svg';

export type GraphPropsValue = graphsProps['value'];

export const nodeSize = [200, 40];

export const nodeGap = [100, 50];

export function registerG6() {
  // 自定义连接线
  G6.registerEdge('customLine', {
    draw(cfg, group) {
      const startPoint = cfg?.startPoint!;
      const endPoint = cfg?.endPoint!;
      const shape = group!.addShape('path', {
        attrs: {
          stroke: '#4A90E2',
          path: [
            ['M', startPoint.x + nodeSize[0] / 2, startPoint.y],
            ['L', endPoint.x / 2 + (1 / 2) * startPoint.x, startPoint.y], // 三分之一处
            ['L', endPoint.x / 2 + (1 / 2) * startPoint.x, endPoint.y], // 三分之二处
            ['L', endPoint.x - nodeSize[0] / 2, endPoint.y],
          ],
        },
        name: 'path-shape',
      });
      return shape;
    },
  });

  // 自定义节点
  G6.registerNode('customNode', {
    draw: function drawShape(cfg, group) {
      const r = 2;
      const w = nodeSize[0];
      let h = nodeSize[1];
      const value = cfg?.value as GraphPropsValue;

      // 名字换行
      const { text: name, lineCount } = wrapText(value.name, 120);
      const extendH = lineCount - 2 > 0 ? (lineCount - 2) * 14 : 0;
      h += extendH;

      // 主体框
      const shape = group!.addShape('rect', {
        attrs: {
          x: -w / 2,
          y: -h / 2,
          width: w,
          height: h,
          stroke: 'rgba(255,255,255,0.2)',
          radius: r,
          fill: 'rgba(255,255,255,0.1)',
          cursor: 'pointer',
        },
        name: 'main-box',
      });

      // 图标
      group!.addShape('image', {
        attrs: {
          x: -w / 2 + 10,
          y: -h / 2 + 10,
          width: 20,
          height: 20,
          img: iconImg,
        },
      });

      // 两侧装饰边
      group!.addShape('rect', {
        attrs: {
          x: -w / 2,
          y: -h / 2,
          width: 2,
          height: h,
          radius: [2, 0, 0, 2],
          fill: '#4A90E2',
        },
      });
      group!.addShape('rect', {
        attrs: {
          x: w / 2 - 2,
          y: -h / 2,
          width: 2,
          height: h,
          radius: [0, 2, 2, 0],
          fill: '#4A90E2',
        },
      });

      // 名字
      group!.addShape('text', {
        attrs: {
          x: -w / 2 + 38,
          textBaseline: 'middle',
          text: name,
          fill: 'rgba(255,255,255,0.85)',
          cursor: 'pointer',
        },
        name: 'name',
      });

      // 有子节点才做展开收起
      if ((cfg?.children as any)?.length) {
        // 节点外层圆
        group.addShape('circle', {
          attrs: {
            fill: '#3570BD',
            x: w / 2 + 20,
            y: 0,
            r: 10,
            stroke: '#4A90E2',
            lineWidth: 1,
            cursor: 'pointer',
          },
          name: 'collapse-circle',
        });
        // 节点中心文字
        group.addShape('text', {
          attrs: {
            x: w / 2 + 20,
            y: 0,
            zIndex: 10,
            fill: '#fff',
            text: cfg.collapsed ? getAllChildrenCount(cfg.children as any) : '—',
            textBaseline: 'middle',
            textAlign: 'center',
            cursor: 'pointer',
          },
          name: 'collapse-text',
        });
        // 节点与主框体之间的连线
        group.addShape('rect', {
          attrs: {
            x: w / 2,
            y: -0.5,
            width: 10,
            height: 1,
            fill: '#4A90E2',
          },
          name: 'collapse-text',
        });
      }

      return shape;
    },

    setState(name, value, item) {
      const data = item?.getModel();
      // 高亮主体边框
      const changeMainBox = () => {
        const highLight = data?.isHover || data?.isSelected;
        const shape = item!.get('group').find((ele: any) => ele.get('name') === 'main-box');
        shape.attr('stroke', highLight ? '#fff' : 'rgba(255,255,255,0.2)');
      };

      if (name === 'collapsed') {
        const count = getAllChildrenCount(item?.getModel().children as any);
        const textShape = item!.get('group').find((ele: any) => ele.get('name') === 'collapse-text');
        textShape.attr('text', value ? count : '—');
      }

      if (name === 'hover') {
        data!.isHover = value;
        // 修改主体边框
        changeMainBox();

        // 展开收起按钮边框
        const collapseCircle = item!.get('group').find((ele: any) => ele.get('name') === 'collapse-circle');
        collapseCircle && collapseCircle.attr('stroke', value ? '#fff' : '#4A90E2');
      }

      if (name === 'isSelected') {
        data!.isSelected = value;
        // 修改主体边框
        changeMainBox();
      }
    },
  });
}

function getAllChildrenCount(list: any[]): number {
  let count = 0;
  const traverse = (list: any[]) => {
    count += list.length;
    list.forEach(item => {
      item.children && traverse(item.children);
    });
  };
  traverse(list);
  return count;
}

// 文字指定宽度换行
function wrapText(text: string, maxWidth: number) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  const words = text.split('');
  const lines = [];
  let currentLine = words[0];
  for (let i = 1; i < words.length; i++) {
    const word = words[i];
    const width = context!.measureText(currentLine + word).width;
    if (width > maxWidth) {
      lines.push(currentLine);
      currentLine = word;
    } else {
      currentLine += word;
    }
  }
  lines.push(currentLine);
  return { text: lines.join('\n'), lineCount: lines.length };
}
