export enum DataPropertyType {
  YC = 1,
  ENUM,
  STRING,
  YK,
  YT,
}

export interface DataProperty {
  id: number;
  name: string;
  /**
   * 1.遥测2.枚举值.字符串4.遥控5.遥调
   */
  type: DataPropertyType;
  /**
   * 类型：1.累计值;2.瞬时值
   */
  measurementType: number;
  used: boolean;
}

export interface LabelValue<V extends string | number> {
  label: string;
  value: V;
}

export enum SerialPort {
  COM1 = 1,
  COM2,
  COM3,
  COM4,
}

export const SerialPortLabels = {
  [SerialPort.COM1]: 'COM1',
  [SerialPort.COM2]: 'COM2',
  [SerialPort.COM3]: 'COM3',
  [SerialPort.COM4]: 'COM4',
};

export const BuadRateOptions: LabelValue<number>[] = [
  { label: '110', value: 110 },
  { label: '300', value: 300 },
  { label: '600', value: 600 },
  { label: '1200', value: 1200 },
  { label: '2400', value: 2400 },
  { label: '4800', value: 4800 },
  { label: '9600', value: 9600 },
  { label: '14400', value: 14400 },
  { label: '19200', value: 19200 },
  { label: '38400', value: 38400 },
  { label: '57600', value: 57600 },
  { label: '115200', value: 115200 },
];

export enum CheckType {
  NULL = 0,
  ODD = 1,
  EVEN = 2,
  MARK = 3,
  SPACE = 4,
}

export const CheckTypeLabels = {
  [CheckType.NULL]: '无校验',
  [CheckType.ODD]: '奇校验',
  [CheckType.EVEN]: '偶校验',
  [CheckType.MARK]: 'Mark',
  [CheckType.SPACE]: 'Space',
};

export const DataBitOptions: LabelValue<number>[] = [
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
];

export const StopBitOptions: LabelValue<number>[] = [
  { label: '1', value: 1 },
  { label: '1.5', value: 3 },
  { label: '2', value: 2 },
];

export enum CRC_ORDER {
  BIG_END,
  SMALL_END,
}

export const CrcOrderLabels = {
  [CRC_ORDER.BIG_END]: '大端',
  [CRC_ORDER.SMALL_END]: '小端',
};

// export const AddrOffsetOptions: LabelValue<number>[] = [
//   { label: '0', value: 0 },
//   { label: '1', value: 1 }
// ];

export enum OBJECT_TYPE {
  COILSTATUS = 1,
  INPUTSTATUS = 2,
  SAVEREGISTER = 3,
  INPUTREGISTER = 4,
}

export enum ADDR_OFFSET {
  ZERO = 0,
  ONE = 1,
}

export const AddrOffsetLabels = {
  [ADDR_OFFSET.ZERO]: '0',
  [ADDR_OFFSET.ONE]: '1',
};

export enum LENGTH {
  ONE = 1,
  TWO = 2,
  FOUR = 4,
}

export enum FREQUENCY {
  LOW = 0,
  HIGH = 1,
}

// export enum CRC_ORDER {
//   BIG = 0,
//   SMALL = 1,
// }

export enum BIT_MODE {
  NULL,
  BIT,
  BYTE,
}

export enum BYTE_NUM {
  LOWBYTE,
  HIGHBYTE,
}

export enum BIT_NUM {
  BIT_0,
  BIT_1,
  BIT_2,
  BIT_3,
  BIT_4,
  BIT_5,
  BIT_6,
  BIT_7,
  BIT_8,
  BIT_9,
  BIT_10,
  BIT_11,
  BIT_12,
  BIT_13,
  BIT_14,
  BIT_15,
}

export type HANDLE_MODE = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;

export enum VALUE_TYPE {
  BOOLEAN = 0,
  COMPLEMENTINTERGER = 1,
  UNSIGNEDINTERGER = 2,
  FLOAT = 3,
  DOBULE = 4,
  INTERGER = 5,
}

export enum ActionType {
  NONE = 1,
  ADD,
  EDIT,
  DELETE,
}

export const ActionTypeDisplay = {
  [ActionType.NONE]: '无操作',
  [ActionType.ADD]: '新增',
  [ActionType.EDIT]: '编辑',
  [ActionType.DELETE]: '删除',
};

export const ActionTypeColors = {
  [ActionType.NONE]: { background: '#EEEEEE', color: 'rgba(0, 0, 0, 0.35)' },
  [ActionType.ADD]: { color: '#1890FF', background: '#E6F7FF' },
  [ActionType.EDIT]: { color: '#53C41C', background: '#EBF4E3' },
  [ActionType.DELETE]: { color: '#53C41C', background: '#EBF4E3' },
};

export enum DataPointType {
  // 采集点
  BASE_POINT = 1,
  // 虚拟点
  VIRTUAL_POINT = 2,
  // 静态点
  STATIC_POINT,
}

export enum UploadTypeEnum {
  TIMING = 1,
  CHANGE,
}

export const UploadTypeEnumDisplay = {
  [UploadTypeEnum.TIMING]: '定时上传',
  [UploadTypeEnum.CHANGE]: '变化上传',
};

export enum IotProtocolType {
  // ESTUN = 1,
  MOCKINGBIRD = 2,
}

export enum DriveType {
  // MODBUS_RTU = 0,
  MODBUS_TCP = 1,
  // DLT645_1997 = 16,
  // DLT645_2007 = 17,
  IEC104 = 64,
}

export const DriveTypeLabels = {
  // [DriveType.MODBUS_RTU]: 'Modbus_RTU',
  [DriveType.MODBUS_TCP]: 'Modbus_TCP',
  // [DriveType.DLT645_1997]: 'DL/T645-1997',
  // [DriveType.DLT645_2007]: 'DL/T645-2007',
  [DriveType.IEC104]: 'IEC104',
};

export enum TopologicalType {
  PCS_Penetrate_BMS = 1,
  BMS_SIMULTANEOUSLY_PCS_AND_BAMS = 2,
}

export const TopologicalTypeLabels = {
  [TopologicalType.PCS_Penetrate_BMS]: 'PCS透传BMS模式',
  [TopologicalType.BMS_SIMULTANEOUSLY_PCS_AND_BAMS]: 'EMS同时连接PCS和BAMS',
};

export enum BsaDeviceTypeEnum {
  PCS = 1,
  STACK,
  RACK,
  PACK,
  CELL,
}

export enum DeviceStatus {
  // 在用
  USING,
  // 维修
  REPAIRING,
  // 停用
  DEACTIVATING,
  // 调出
  CALLOUT,
  // 售出
  SOLD,
  // 报废
  SCRAPPED,
  // 作废
  INVALID,
}

export const DeviceStatusDisplay = {
  [DeviceStatus.USING]: '在用',
  [DeviceStatus.REPAIRING]: '维修',
  [DeviceStatus.DEACTIVATING]: '停用',
  [DeviceStatus.CALLOUT]: '调出',
  [DeviceStatus.SOLD]: '售出',
  [DeviceStatus.SCRAPPED]: '报废',
  [DeviceStatus.INVALID]: '作废',
};

export const objectFormatter = {
  [OBJECT_TYPE.COILSTATUS]: '线圈状态[01]',
  [OBJECT_TYPE.INPUTSTATUS]: '输入状态[02]',
  [OBJECT_TYPE.SAVEREGISTER]: '保持寄存器[03]',
  [OBJECT_TYPE.INPUTREGISTER]: '输入寄存器[04]',
};

export const lengthFormatter = {
  [LENGTH.ONE]: '1',
  [LENGTH.TWO]: '2',
  [LENGTH.FOUR]: '4',
};

export const bitModeFormatter = {
  [BIT_MODE.NULL]: '无',
  [BIT_MODE.BIT]: '按位读取',
  [BIT_MODE.BYTE]: '按字节读取',
};

export const byteNumFormatter = {
  [BYTE_NUM.LOWBYTE]: '低字节',
  [BYTE_NUM.HIGHBYTE]: '高字节',
};

export const bitNumFormatter = {
  [BIT_NUM.BIT_0]: '操作位-0',
  [BIT_NUM.BIT_1]: '操作位-1',
  [BIT_NUM.BIT_2]: '操作位-2',
  [BIT_NUM.BIT_3]: '操作位-3',
  [BIT_NUM.BIT_4]: '操作位-4',
  [BIT_NUM.BIT_5]: '操作位-5',
  [BIT_NUM.BIT_6]: '操作位-6',
  [BIT_NUM.BIT_7]: '操作位-7',
  [BIT_NUM.BIT_8]: '操作位-8',
  [BIT_NUM.BIT_9]: '操作位-9',
  [BIT_NUM.BIT_10]: '操作位-10',
  [BIT_NUM.BIT_11]: '操作位-11',
  [BIT_NUM.BIT_12]: '操作位-12',
  [BIT_NUM.BIT_13]: '操作位-13',
  [BIT_NUM.BIT_14]: '操作位-14',
  [BIT_NUM.BIT_15]: '操作位-15',
};

export const valueTypeFormatter = {
  [VALUE_TYPE.BOOLEAN]: '布尔',
  [VALUE_TYPE.INTERGER]: '有符号整型数-原码',
  [VALUE_TYPE.COMPLEMENTINTERGER]: '有符号整型数-补码',
  [VALUE_TYPE.UNSIGNEDINTERGER]: '无符号整型数',
  [VALUE_TYPE.FLOAT]: '单精度浮点数',
  [VALUE_TYPE.DOBULE]: '双精度浮点数',
};

export const valueTypeFormatter1 = {
  [VALUE_TYPE.BOOLEAN]: valueTypeFormatter[VALUE_TYPE.BOOLEAN],
};

export const valueTypeFormatter2 = {
  [VALUE_TYPE.INTERGER]: valueTypeFormatter[VALUE_TYPE.INTERGER],
  [VALUE_TYPE.COMPLEMENTINTERGER]: valueTypeFormatter[VALUE_TYPE.COMPLEMENTINTERGER],
  [VALUE_TYPE.UNSIGNEDINTERGER]: valueTypeFormatter[VALUE_TYPE.UNSIGNEDINTERGER],
};

export const valueTypeFormatter3 = {
  [VALUE_TYPE.INTERGER]: valueTypeFormatter[VALUE_TYPE.INTERGER],
  [VALUE_TYPE.COMPLEMENTINTERGER]: valueTypeFormatter[VALUE_TYPE.COMPLEMENTINTERGER],
  [VALUE_TYPE.UNSIGNEDINTERGER]: valueTypeFormatter[VALUE_TYPE.UNSIGNEDINTERGER],
  [VALUE_TYPE.FLOAT]: valueTypeFormatter[VALUE_TYPE.FLOAT],
};

export const valueTypeFormatter4 = {
  [VALUE_TYPE.INTERGER]: valueTypeFormatter[VALUE_TYPE.INTERGER],
  [VALUE_TYPE.COMPLEMENTINTERGER]: valueTypeFormatter[VALUE_TYPE.COMPLEMENTINTERGER],
  [VALUE_TYPE.UNSIGNEDINTERGER]: valueTypeFormatter[VALUE_TYPE.UNSIGNEDINTERGER],
  [VALUE_TYPE.FLOAT]: valueTypeFormatter[VALUE_TYPE.FLOAT],
  [VALUE_TYPE.DOBULE]: valueTypeFormatter[VALUE_TYPE.DOBULE],
};

export const handleModeFormatter1 = {
  0: '1,2',
  1: '2,1',
};

export const handleModeFormatter2 = {
  0: '1,2,3,4',
  1: '2,1,4,3',
  2: '3,4,1,2',
  3: '4,3,2,1',
};

export const handleModeFormatter3 = {
  0: '1,2,3,4,5,6,7,8',
  1: '2,1,4,3,6,5,8,7',
  2: '3,4,1,2,7,8,5,6',
  3: '4,3,2,1,8,7,6,5',
  4: '5,6,7,8,1,2,3,4',
  5: '6,5,8,7,2,1,4,3',
  6: '7,8,5,6,3,4,1,2',
  7: '8,7,6,5,4,3,2,1',
};

export interface PhysicalUnit {
  id: number;
  generalName: string;
  chineseName: string;
  k: number;
  b: number;
  isBasic: boolean;
}
// 状态 0 禁用 1 启用 2 作废 3 草稿
export enum BSAStatus {
  DISABLE,
  ENABLE,
  INVALID,
  DRAFT,
}

export const bsaStatusFormat = {
  [BSAStatus.DISABLE]: '停用',
  [BSAStatus.ENABLE]: '启用',
  [BSAStatus.INVALID]: '作废',
  [BSAStatus.DRAFT]: '草稿',
};
// 暂时没有作废状态, 先写上占位
export enum PVStatus {
  DISABLE,
  ENABLE,
  INVALID,
  DRAFT,
}

export const pvStatusFormat = {
  [BSAStatus.DISABLE]: '停用',
  [BSAStatus.ENABLE]: '启用',
  [BSAStatus.INVALID]: '作废',
  [BSAStatus.DRAFT]: '草稿',
};

export enum ProcessStatusEnum {
  GENERATING = 1,
  SUCCESS = 2,
  FAIL = 3,
}

export interface ProcessStatus {
  status: ProcessStatusEnum;
}

export enum LocalCalendarStatus {
  OUTAGE, //停运
  COMMISSIONING, //投运
  INVALID, //作废
}

export const localCalendarStatusFormat = {
  [LocalCalendarStatus.OUTAGE]: '停运',
  [LocalCalendarStatus.COMMISSIONING]: '运行中',
  [LocalCalendarStatus.INVALID]: '待机',
};

export const localCalendarStatusColor = {
  [LocalCalendarStatus.OUTAGE]: 'red',
  [LocalCalendarStatus.COMMISSIONING]: 'blue',
  [LocalCalendarStatus.INVALID]: 'default',
};
