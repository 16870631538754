import { OuIncomeInfoReq } from '@/api/EnergyAssetProjectIncome';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { RadioChangeEvent } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { FC } from 'react';
import { getDefaultTime, getTs } from '../../utils';
import styles from './index.module.scss';
import { DatePicker, Button, Radio, message } from '@maxtropy/components';

const { RangePicker } = DatePicker;

export interface IDateSwitch {
  selectBtn: StatisticsPartition;
  value: any;
  style?: React.CSSProperties;
  onQueryChange: (value: Partial<OuIncomeInfoReq>) => void;
}

export enum StatisticsPartition {
  DAY = 'D',
  MONTH = 'M',
}

const datePickerTypeDisplay = {
  [StatisticsPartition.DAY]: '按日',
  [StatisticsPartition.MONTH]: '按月',
};

const btnGroup = [{ resolution: StatisticsPartition.DAY }, { resolution: StatisticsPartition.MONTH }];

const DateSwitch: FC<IDateSwitch> = props => {
  const { selectBtn, style, value, onQueryChange } = props;

  const onRadioChange = (e: RadioChangeEvent) => {
    onQueryChange({
      resolution: e.target.value,
      ...getDefaultTime(e.target.value, dayjs()),
    });
  };

  const disabledDate = (current: Dayjs) => {
    return current && current >= dayjs().subtract(1, 'day');
  };

  // 比对
  const diffDays = dayjs(value[1]).diff(dayjs(value[0]), 'd');
  const diffMonths = dayjs(value[1]).diff(dayjs(value[0]), 'months');

  const onChangeRangePicker = (range: any) => {
    if (range) {
      const [beginDate, endDate] = range;
      if (selectBtn === StatisticsPartition.DAY) {
        const diff = dayjs(endDate).diff(dayjs(beginDate), 'day');
        const mostDays = 31;
        if (diff >= mostDays) {
          return message.warning(`最大上限为${mostDays}天`);
        }
      } else {
        const diff = dayjs(endDate).diff(dayjs(beginDate), 'month');
        if (diff >= 12) {
          return message.warning('最大上限为12个月');
        }
      }
      onQueryChange({
        resolution: selectBtn,
        ...getTs(selectBtn, dayjs(beginDate), dayjs(endDate)),
      });
    }
  };

  return (
    <div className={styles.dateSwitch} style={style}>
      <div className={styles.switchBtn}>
        <Radio.Group onChange={onRadioChange} value={selectBtn} buttonStyle="solid">
          {btnGroup?.map(btn => (
            <Radio.Button key={btn.resolution} value={btn.resolution}>
              {datePickerTypeDisplay[btn.resolution as StatisticsPartition]}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>
      <div className={styles.datePickerArea}>
        {/* <span className={styles.label}>日期</span> */}
        {(selectBtn === StatisticsPartition.DAY || selectBtn === StatisticsPartition.MONTH) && (
          <>
            <RangePicker
              value={value}
              disabledDate={disabledDate}
              onChange={onChangeRangePicker}
              picker={selectBtn === StatisticsPartition.DAY ? 'date' : 'month'}
              allowClear={false}
              rangeSwitchBtn={{
                leftBtn: {
                  onClick: () => {
                    const ts =
                      selectBtn === StatisticsPartition.MONTH
                        ? getTs(
                            selectBtn,
                            dayjs(value[0]).subtract(diffMonths + 1, 'months'),
                            dayjs(value[0]).subtract(1, 'months')
                          )
                        : getTs(
                            selectBtn,
                            dayjs(value[0]).subtract(diffDays + 1, 'd'),
                            dayjs(value[0]).subtract(1, 'd')
                          );
                    onQueryChange({
                      resolution: selectBtn,
                      ...ts,
                    });
                  },
                },
                rightBtn: {
                  onClick: () => {
                    const ts =
                      selectBtn === StatisticsPartition.MONTH
                        ? getTs(
                            selectBtn,
                            dayjs(value[1]).add(1, 'months'),
                            dayjs(value[1]).add(diffMonths + 1, 'months')
                          )
                        : getTs(selectBtn, dayjs(value[1]).add(1, 'd'), dayjs(value[1]).add(diffDays + 1, 'd'));
                    onQueryChange({
                      resolution: selectBtn,
                      ...ts,
                    });
                  },
                  disabled:
                    selectBtn === StatisticsPartition.MONTH
                      ? dayjs(value[1])
                          .add(diffMonths + 1, 'months')
                          .endOf('m') > dayjs().endOf('months')
                      : dayjs(value[1])
                          .add(diffDays + 1, 'd')
                          .startOf('d') > dayjs().startOf('d'),
                },
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default DateSwitch;
