import { Button, Modal, EllipsisSpan, Paging, Table, usePaging } from '@maxtropy/components';
import { useQuery } from 'react-query';
import { getNextCircuitPage } from '@/api/lineLoss';
import { useParams } from 'react-router-dom';
import { CircuitType, CircuitTypeFormat } from '@/api/circuit';

const columns = [
  {
    title: '回路编号',
    dataIndex: 'circuitCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '回路名称',
    dataIndex: 'circuitName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '回路类型',
    dataIndex: 'circuitType',
    ellipsis: { showTitle: true },
    render: (v: CircuitType) => <EllipsisSpan value={CircuitTypeFormat[v]} />,
  },
  {
    title: '所属配电柜',
    dataIndex: 'distributionCabinetName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '正向有功是否配置',
    dataIndex: 'positiveActivePowerConfig',
    ellipsis: { showTitle: true },
    render: (v: boolean) => <EllipsisSpan value={v ? '是' : '否'} />,
  },
  {
    title: '反向有功是否配置',
    dataIndex: 'reverseActivePowerConfig',
    ellipsis: { showTitle: true },
    render: (v: boolean) => <EllipsisSpan value={v ? '是' : '否'} />,
  },
];
interface Iporps {
  onCancel?: () => void;
}
const NextCircuitModal: React.FC<Iporps> = props => {
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useQuery(['nextCircuitPage', pageOffset, pageSize, setTotalCount], async () => {
    const res = await getNextCircuitPage({ circuitId: id, page: pageOffset, size: pageSize });
    if (!res) return [];
    setTotalCount(res.total);
    return res.list;
  });

  return (
    <Modal
      open
      footer={
        <Button type="primary" onClick={() => props.onCancel && props.onCancel()}>
          好的
        </Button>
      }
      onCancel={() => props.onCancel && props.onCancel()}
      size="big"
      title="下一级回路"
    >
      <Table sticky rowKey="id" scroll={{ x: 1500 }} columns={columns} dataSource={data} loading={isLoading} />
      <Paging pagingInfo={pagingInfo} />
    </Modal>
  );
};

export default NextCircuitModal;
