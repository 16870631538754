import { Key } from '@maxtropy/components';
import { apiV2NeoBsaGetDatapropertyConfigPost, apiV2DeviceDataPropertyRecentPost } from '@maxtropy/dmes-apis-v2';
import { useRequest } from 'ahooks';
import { chunk } from 'lodash-es';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ShareContext } from '.';
import { rowType } from './TabContents/PCS';

import { getCommonTableData } from './TabContents/utils';
import { DatapropertyType, FirstLevelDevice, MapValueObj, RealData } from './type';

interface Iprops {
  bsaId?: number;
  bsaType?: number;

  dataTab?: number; // 对应bsaDeviceType

  dataType?: 1 | 2; // 1 故障/告警 2 状态
  update?: number;
}
interface DatapropertyIdsResponse {
  datapropertyName?: string;
  datapropertyId?: string;
}
export const useAlarmDatapropertyIds = ({ bsaId, bsaType, dataTab, update }: Iprops) => {
  const { data } = useRequest(
    () => {
      return apiV2NeoBsaGetDatapropertyConfigPost({ bsaId, bsaType, dataTab, dataType: 1 }).then(res => res.list ?? []);
    },
    {
      ready: !!bsaId && !!dataTab,
      refreshDeps: [bsaId, bsaType, dataTab, update],
    }
  );

  return data?.map(item => String(item.datapropertyId!)) ?? [];
};

export const useStatusDatapropertyIds = ({ bsaId, bsaType, dataTab, update }: Iprops) => {
  const { data } = useRequest(
    () => apiV2NeoBsaGetDatapropertyConfigPost({ bsaId, bsaType, dataTab, dataType: 2 }).then(res => res.list ?? []),
    {
      ready: !!bsaId && !!dataTab,
      refreshDeps: [bsaId, bsaType, dataTab, update],
    }
  );

  return data?.map(item => String(item.datapropertyId!)) ?? [];
};

// 根据设备id和数据属性, 获取相关的ts和value, Map的key值为deviceId-propertyId
export const useGetDataPropertyInfo = ({
  deviceIds,
  dataPropertyIds,
}: {
  deviceIds?: number[];
  dataPropertyIds?: number[];
}) => {
  const { data, loading } = useRequest(
    () => {
      return apiV2DeviceDataPropertyRecentPost({
        deviceIds: deviceIds as unknown as number[],
        dataPropertyIds,
      }).then(res => {
        let temp = new Map<string, MapValueObj>();
        res.list?.forEach(item => {
          temp.set(`${item.deviceId}-${item.propertyId}`, { ts: item.ts, value: item.value });
        });
        return temp;
      });
    },
    {
      ready: !!deviceIds && !!dataPropertyIds && deviceIds.length > 0 && dataPropertyIds.length > 0,
      refreshDeps: [deviceIds, dataPropertyIds],
    }
  );

  return { data, loading };
};

// 温控与辅助设备中的数据 分为遥测/遥信
export const useGetTemperTableDataSplit = ({ update, data }: { update?: number; data?: FirstLevelDevice }) => {
  const { bsaId, bsaType, dataType } = useContext(ShareContext);
  const allDatas = data?.dataPropertyValues ?? [];

  // 过滤遥测的数据
  const ycDatas = useMemo(() => {
    return allDatas.filter(item => item.datapropertyType === DatapropertyType.YC);
  }, [allDatas]);

  const alarmPropertyIds = useAlarmDatapropertyIds({ bsaId, bsaType, dataTab: data?.bsaDeviceType, update });

  const statusPropertyIds = useStatusDatapropertyIds({ bsaId, bsaType, dataTab: data?.bsaDeviceType, update });
  // 故障/报警
  const alarmDatas = useMemo(() => {
    return allDatas.filter(
      item => item.datapropertyType === DatapropertyType.YX && alarmPropertyIds?.includes(String(item.datapropertyId!))
    );
  }, [allDatas, alarmPropertyIds]);

  // 状态
  const statusDatas = useMemo(() => {
    return allDatas.filter(
      item => item.datapropertyType === DatapropertyType.YX && statusPropertyIds?.includes(String(item.datapropertyId!))
    );
  }, [allDatas, alarmPropertyIds]);

  return {
    ycDatas,
    alarmDatas,
    statusDatas,
  };
};
// pcs中的数据 分为遥测/遥信
export const useGetPcsTableDataSplit = ({ update, data }: { update?: number; data?: RealData }) => {
  const { bsaId, bsaType } = useContext(ShareContext);

  const allDatas = getCommonTableData(data);

  // 过滤遥测的数据
  const ycDatas = useMemo(() => {
    return allDatas.filter(item => item.datapropertyType === DatapropertyType.YC);
  }, [allDatas]);
  const bsaDeviceType = data?.[0]?.bsaDeviceType;
  const alarmPropertyIds = useAlarmDatapropertyIds({ bsaId, bsaType, dataTab: bsaDeviceType, update });
  const statusPropertyIds = useStatusDatapropertyIds({ bsaId, bsaType, dataTab: bsaDeviceType, update });
  const alarmDatas = useMemo(() => {
    return allDatas
      .filter(
        item =>
          item.datapropertyType === DatapropertyType.YX && alarmPropertyIds?.includes(String(item.datapropertyId!))
      )
      ?.map(item => {
        item.rowType = rowType.alarm;
        return item;
      });
  }, [allDatas, alarmPropertyIds]);

  const statusDatas = useMemo(() => {
    return allDatas
      .filter(
        item =>
          item.datapropertyType === DatapropertyType.YX && statusPropertyIds?.includes(String(item.datapropertyId!))
      )
      ?.map(item => {
        item.rowType = rowType.status;
        return item;
      });
  }, [allDatas, alarmPropertyIds]);

  return {
    ycDatas,
    alarmDatas,
    statusDatas,
  };
};

// 分批查询设备-数据属性值

export const useGetDataPropertyInfoByChunks = (list: RealData, chunksNumber?: number) => {
  const [valueMap, setValueMap] = useState<Map<string, MapValueObj>>();
  let allDatapropertyIds = list?.flatMap(item => item.dataPropertyValues);
  const chunksArr = chunk(allDatapropertyIds, chunksNumber ?? 10);
  useEffect(() => {
    if (!list || list.length === 0) return;
    Promise.all(
      chunksArr.map(chunk => {
        let deviceIds = chunk.map(item => item?.deviceId) as number[];
        let dataPropertyIds = chunk.map(item => item?.datapropertyId) as number[];
        if (deviceIds && dataPropertyIds && deviceIds.length && dataPropertyIds.length) {
          return apiV2DeviceDataPropertyRecentPost({
            deviceIds: deviceIds as unknown as number[],
            dataPropertyIds,
          }).then(res => res.list);
        }
        return Promise.resolve([]);
      })
    ).then(res => {
      let temp = new Map<string, MapValueObj>();
      let flatArr = res?.flat().filter(Boolean);
      flatArr?.forEach(item => {
        temp.set(`${item?.deviceId}-${item?.propertyId}`, { ts: item?.ts, value: item?.value });
      });
      setValueMap(temp);
    });
  }, [list]);

  return valueMap;
};
