import { CircuitPageProps, CircuitType, CircuitTypeFormat } from '@/api/circuit';
import { getLineLossCircuitPage } from '@/api/lineLoss';
import { Form, Input, Modal, Select, EllipsisSpan, Paging, usePaging, Table, CustomFilter } from '@maxtropy/components';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
interface SearchParamsProps {
  codeOrName?: string;
  type?: CircuitType;
  ouId?: number;
  distributionCabinetId?: string;
}
interface Iprops {
  ids?: number[];
  isMore?: boolean; // 单选or多选
  ouId?: number;
  onCancel?: () => void;
  onConfirm?: (ids?: number[]) => void;
}
type FunctionValidator = (ids: number[], type: string) => number[];
const columns = [
  {
    title: '回路编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '回路名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '回路类型',
    dataIndex: 'type',
    ellipsis: { showTitle: true },
    render: (v: CircuitType) => <EllipsisSpan value={CircuitTypeFormat[v]} />,
  },
  {
    title: '所属配电柜',
    dataIndex: 'distributionCabinetName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];
const SelectCircuitModal: React.FC<Iprops> = ({ onCancel, onConfirm, isMore, ids, ouId }) => {
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParamsProps>();
  const [selectedCircuitId, setSelectedCircuitId] = useState<number[]>();
  const { data, isLoading, refetch } = useQuery(
    ['circuitPage', pageOffset, searchParams, pageSize, setTotalCount],
    async () => {
      const res = await getLineLossCircuitPage({ ...searchParams, ouId, page: pageOffset, size: pageSize });
      if (!res) return [];
      setTotalCount(res.total);
      return res.list;
    }
  );
  useEffect(() => {
    ids && setSelectedCircuitId(ids);
  }, [ids]);
  const selectTypeMode = useMemo(() => (isMore ? 'checkbox' : 'radio'), [isMore]);

  const handleSelectedData: FunctionValidator = (ids, type) => {
    if (selectTypeMode === 'radio') {
      return ids;
    }
    // 添加/删除数据
    if (type === 'add') {
      if (!selectedCircuitId) {
        return ids;
      }
      ids.forEach(item => {
        if (!selectedCircuitId.includes(item)) {
          selectedCircuitId.push(item);
        }
      });
      return selectedCircuitId;
    }
    if (!selectedCircuitId) return [];
    ids.forEach(item => {
      let index = selectedCircuitId.indexOf(item);
      if (index < 0) return;
      selectedCircuitId.splice(index, 1);
    });
    return selectedCircuitId;
  };
  const rowSelection = {
    onSelect: (record: CircuitPageProps, selected: boolean) => {
      let id = record.id;
      let type = selected ? 'add' : 'remove';
      let temp = handleSelectedData([id], type);
      setSelectedCircuitId([...temp]);
    },
    onSelectAll: (selected: boolean, selectedRows: CircuitPageProps[], changeRows: CircuitPageProps[]) => {
      if (selected) {
        // 新增的项
        let addIds = selectedRows.map(item => item.id);
        let temp = handleSelectedData(addIds, 'add');
        setSelectedCircuitId([...temp]);
        return;
      }
      // 删除
      let removeIds = changeRows.map(item => item.id);
      let temp = handleSelectedData(removeIds, 'remove');
      setSelectedCircuitId([...temp]);
    },
    selectedRowKeys: selectedCircuitId,
  };
  return (
    <Modal
      open
      size="large"
      onCancel={() => onCancel && onCancel()}
      title="选择回路"
      onOk={() => {
        onConfirm && onConfirm(selectedCircuitId);
        onCancel && onCancel();
      }}
    >
      <CustomFilter
        form={form}
        onFinish={(values: SearchParamsProps) => {
          setSearchParams(values);
          setPageOffset(1);
          refetch();
        }}
        onReset={() => {
          setSearchParams(undefined);
          setPageOffset(1);
        }}
        colSpan={8}
      >
        <Form.Item name="codeOrName" label="回路名称">
          <Input placeholder="请输入回路名称" />
        </Form.Item>
        <Form.Item name="type" label="回路类型">
          <Select placeholder="请选择">
            <Select.Option value={CircuitType.GRID_BILLING_CIRCUIT}>
              {CircuitTypeFormat[CircuitType.GRID_BILLING_CIRCUIT]}
            </Select.Option>
            <Select.Option value={CircuitType.INCOMING_CIRCUIT}>
              {CircuitTypeFormat[CircuitType.INCOMING_CIRCUIT]}
            </Select.Option>
            <Select.Option value={CircuitType.OUTGOING_CIRCUIT}>
              {CircuitTypeFormat[CircuitType.OUTGOING_CIRCUIT]}
            </Select.Option>
            <Select.Option value={CircuitType.BUS_COUPLER_CIRCUIT}>
              {CircuitTypeFormat[CircuitType.BUS_COUPLER_CIRCUIT]}
            </Select.Option>
            <Select.Option value={CircuitType.TRANSFORMER_CIRCUIT}>
              {CircuitTypeFormat[CircuitType.TRANSFORMER_CIRCUIT]}
            </Select.Option>
          </Select>
        </Form.Item>
      </CustomFilter>
      <Table
        rowSelection={{
          type: selectTypeMode,
          ...rowSelection,
        }}
        sticky
        rowKey="id"
        scroll={{ x: 600, y: 403 }}
        columns={columns}
        dataSource={data}
        loading={isLoading}
      />
      <Paging pagingInfo={pagingInfo} />
    </Modal>
  );
};

export default SelectCircuitModal;
