import React, { useMemo, useState } from 'react';
import { CircuitTreeLeafInfo2 } from '@/pages/Statistics/components/Tree';
import styles from '@/pages/Statistics/index.module.scss';
import { Space, Spin } from 'antd';
import Picker from '@/pages/Statistics/CircuitLineLoss/Picker';
import dayjs from 'dayjs';
import {
  // aggregateBy,
  CircuitLineLossData,
  CircuitLineLossDataQuery,
  CircuitLineLossDataReason,
  CircuitLineLossDataType,
  circuitLineLossKeys,
  exportData,
  getCircuitLineLossData,
} from '@/api/circuit-line-loss';
import { useQuery } from 'react-query';
import ReactEcharts from 'echarts-for-react';
import { FormatterItem } from '@/pages/Statistics/components/Chart/helper';
import { isNil } from 'lodash-es';
import { InfoCircleOutlined } from '@ant-design/icons';
import * as echarts from 'echarts';
import { DataGrain } from '@/api/bsa-overview';
import { Button, Empty } from '@maxtropy/components';

export interface CircuitLineLossContentProps {
  circuit?: CircuitTreeLeafInfo2;
}

const TimeResolutionMapper: Record<CircuitLineLossDataType, DataGrain> = {
  [CircuitLineLossDataType.HOUR]: DataGrain.HOUR,
  [CircuitLineLossDataType.DAY]: DataGrain.DAY,
  [CircuitLineLossDataType.MONTH]: DataGrain.MONTH,
};

const CircuitLineLossContent: React.FC<CircuitLineLossContentProps> = ({ circuit }) => {
  const [query, setQuery] = useState({
    dataType: CircuitLineLossDataType.HOUR,
    from: dayjs().subtract(6, 'days').startOf('day').valueOf(),
    to: dayjs().endOf('day').valueOf(),
  });
  const [tab, setTab] = useState<'lossRate' | 'loss'>('lossRate');

  const { data, isLoading } = useQuery(
    circuitLineLossKeys.data(circuit?.circuitId!, {
      from: query.from,
      to: query.to,
      timeResolution: TimeResolutionMapper[query.dataType],
    }),
    () =>
      getCircuitLineLossData(circuit?.circuitId!, {
        from: query.from,
        to: query.to,
        timeResolution: TimeResolutionMapper[query.dataType],
      }),
    {
      enabled: !!circuit,
    }
  );

  // const aggregatedData = useMemo(
  //   () =>
  //     data
  //       ? aggregateBy(data, query.dataType).map(item => ({
  //           ...item,
  //           lossRate: item.lossRate ? item.lossRate * 100 : null,
  //         }))
  //       : undefined,
  //   [data, query.dataType]
  // );
  const circuitName = useMemo(() => {
    return circuit?.name;
  }, [circuit]);

  const hint = useMemo(() => {
    if (!data) {
      return '';
    }
    const total = Math.floor((query.to - query.from) / (3600 * 1000)) + 1;
    const valid = data.filter(item => item.reason === CircuitLineLossDataReason.VALID).length;
    const inCompleteConfiguration = data.filter(
      item => item.reason === CircuitLineLossDataReason.INCOMPLETE_CONFIGURATION
    ).length;
    return `提示：所选择的日期中，共${total}个小时，其中线损有效计算小时数为${valid}个小时。有${
      total - valid - inCompleteConfiguration
    }个小时，回路电表数据不完整，无线损数据；有${inCompleteConfiguration}个小时，因为回路配置不完整，无线损数据。`;
  }, [data, query]);

  return circuit?.hasLineLoss ? (
    <div className={styles.tabContent}>
      <div className={styles.filter}>
        <Picker
          onChange={v => setQuery({ ...query, ...v })}
          dataType={query.dataType}
          from={query.from}
          to={query.to}
        />
        {query.dataType !== CircuitLineLossDataType.HOUR && (
          <div
            style={{
              display: 'inline-block',
              marginLeft: 48,
            }}
          >
            <Button
              type="primary"
              onClick={() =>
                exportData({
                  circuitId: circuit!.circuitId,
                  exportDate: query.to,
                  timeLevel: query.dataType,
                })
              }
            >
              导出线损数据
            </Button>
          </div>
        )}
      </div>
      <div style={{ marginLeft: 0 }}>当前回路名称: {circuitName}</div>
      <Spin spinning={isLoading}>
        <div className={styles.loss_chart_container}>
          <Space size={20} style={{ marginTop: 20, marginLeft: 20 }}>
            <div
              style={{ fontSize: 14, cursor: 'pointer', color: tab === 'lossRate' ? '#4A90E2' : '#FFFFFFA6' }}
              onClick={() => setTab('lossRate')}
            >
              线损率
            </div>
            <div
              style={{ fontSize: 14, cursor: 'pointer', color: tab === 'loss' ? '#4A90E2' : '#FFFFFFA6' }}
              onClick={() => setTab('loss')}
            >
              线损量
            </div>
          </Space>
          {data && (
            <div className={styles.loss_chart_box}>
              <ReactEcharts
                theme={'dark'}
                style={{ height: '100%' }}
                option={getChartOption(tab, query, data)}
                notMerge
              />
            </div>
          )}
          {hint && (
            <div style={{ fontSize: 12, color: '#ffffffA6', margin: '20px 40px' }}>
              <InfoCircleOutlined style={{ color: '#F5AC00' }} /> {hint}
            </div>
          )}
        </div>
      </Spin>
    </div>
  ) : !!circuit ? (
    <div className={styles.loss_chart_empty}>
      <Empty
        description={
          <div>
            回路无线损配置,
            <Button
              type={'link'}
              onClick={() => window.open(`/dmes/circuit/circuit/lineLoss/${circuit?.circuitId}`, '__blank')}
            >
              点击前往配置
            </Button>
          </div>
        }
      />
    </div>
  ) : (
    <div className={styles.loss_chart_empty}>
      <Empty description={'暂无数据'} />
    </div>
  );
};

const getChartOption = (
  chartType: 'loss' | 'lossRate',
  { dataType }: CircuitLineLossDataQuery,
  chartData: CircuitLineLossData[]
) => {
  // let data: number[] = [];
  // if (dataType === CircuitLineLossDataType.HOUR) {
  //   data = Array.from({ length: (to - from) / (3600 * 1000) + 1 }, (_, i) => from + 3600 * 1000 * i);
  // } else if (dataType === CircuitLineLossDataType.DAY) {
  //   data = Array.from({ length: dayjs(to).diff(dayjs(from), 'day') + 1 }, (_, i) => from + 24 * 60 * 60 * 1000 * i);
  // } else {
  //   data = Array.from(
  //     {
  //       length: (dayjs(to).year() - dayjs(from).year()) * 12 + 1 + dayjs(to).month() - dayjs(from).month(),
  //     },
  //     (_, i) => dayjs(from).add(i, 'month').valueOf()
  //   );
  // }

  const data = chartData.map(v => dayjs(v.time).valueOf());

  return {
    backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue('--component-background'),
    grid: {
      left: 50,
      right: 50,
      top: 95,
      bottom: 80,
    },
    dataZoom: [
      {
        type: 'slider',
        start: 0,
        end: 100,
        bottom: 15,
        height: 20,
        labelFormatter() {
          return '';
        },
        textStyle: {
          fontSize: 10,
        },
      },
      { type: 'inside' },
    ],
    tooltip: {
      trigger: 'axis',
      formatter(items: FormatterItem[]) {
        const { axisValue } = items[0];
        const format =
          dataType === CircuitLineLossDataType.HOUR
            ? 'YYYY-MM-DD HH:mm'
            : dataType === CircuitLineLossDataType.DAY
            ? 'YYYY-MM-DD'
            : 'YYYY-MM';
        let str = dayjs(axisValue, 'x').format(format);

        const reason = (items[0].data as any)[2];
        if (reason === CircuitLineLossDataReason.NO_DATA || reason === CircuitLineLossDataReason.INCOMPLETE_DATA) {
          str += `<br><br> 回路电表数据不完整，无线损数据`;
        } else if (reason === CircuitLineLossDataReason.INCOMPLETE_CONFIGURATION) {
          str += `<br><br> 回路配置不完整，无线损数据`;
        } else {
          const tempValue = items[0].data[1];
          const amountValue = !isNil(tempValue) ? tempValue.toFixed(2) : '--';
          const lossRateValue = !isNil(tempValue) ? tempValue.toFixed(2) : '--';
          const unit = chartType === 'lossRate' ? '%' : 'kWh';
          const name = chartType === 'lossRate' ? '线损率' : '线损量';
          const res = chartType === 'lossRate' ? lossRateValue : amountValue;
          str += `<br><br> ${name}: ${res}${unit}`;
        }
        return str;
      },
    },
    xAxis: {
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      type: 'time',
      data,
      axisLabel: {
        formatter(value: number) {
          const format =
            dataType === CircuitLineLossDataType.HOUR
              ? 'MM-DD[\n]HH:mm'
              : dataType === CircuitLineLossDataType.DAY
              ? 'MM-DD'
              : 'YYYY-MM';
          return dayjs(value, 'x').format(format);
        },
      },
    },
    yAxis: {
      type: 'value',
      name: chartType === 'lossRate' ? '%' : 'kWh',
      nameTextStyle: {
        fontSize: 14,
        align: 'right',
      },
      nameGap: 30,
      splitLine: {
        lineStyle: {
          color: '#FFFFFF30',
        },
      },
    },
    series: [
      {
        color: chartType === 'lossRate' ? '#62B97D' : '#52C41A',
        type: chartType === 'lossRate' ? 'line' : 'bar',
        symbol: 'none',
        data: chartData.map(item => [
          item.time,
          chartType === 'lossRate'
            ? !isNil(item.lossRate)
              ? Number((item.lossRate * 100).toFixed(2))
              : null
            : item.loss,
          item.reason,
        ]),
        smooth: true,
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#63BC7F',
            },
            {
              offset: 1,
              color: '#395742',
            },
          ]),
        },
      },
    ],
  };
};

export default CircuitLineLossContent;
