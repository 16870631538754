import React, { useState } from 'react';
import {
  Form,
  Table,
  useBreadcrumbRoutes,
  Wrapper,
  SubContent,
  Button,
  EllipsisSpan,
  FormTitle,
} from '@maxtropy/components';
import { Space, Spin } from 'antd';
import {
  BusTieStatusFormat,
  CircuitType,
  CircuitTypeFormat,
  getCircuitDetail,
  SwitchStatusFormat,
  VoltageLevelFormat,
} from '../../api/circuit';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import EPriceTimeTable from '../../components/EPriceTime/Table';
import {
  E_PRICE_CHARGE_TYPE_MAP,
  E_PRICE_TIMING_TYPE_MAP,
  EPriceChargeType,
  EPriceTimingType,
} from '../../api/electricityPrice';
import { CircuitDevicesRes } from '@/api/device';
import styles from './index.module.scss';

export const deviceColumns = [
  {
    title: '设备编号',
    // dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: CircuitDevicesRes) => <EllipsisSpan value={v.deviceDto.code} />,
  },
  {
    title: '设备名称',
    // dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: CircuitDevicesRes) => <EllipsisSpan value={v.deviceDto.name} />,
  },
  {
    title: '所属类目',
    // dataIndex: 'typeName',
    ellipsis: { showTitle: true },
    render: (v: CircuitDevicesRes) => <EllipsisSpan value={v.deviceDto.typeName} />,
  },
  {
    title: '设备标签',
    // dataIndex: 'tags',
    ellipsis: { showTitle: true },
    render: (v: CircuitDevicesRes) => (
      <EllipsisSpan value={v.deviceDto.tags && v.deviceDto.tags.length > 0 ? v.deviceDto.tags.join('、') : '--'} />
    ),
  },
  {
    title: '所属组织',
    // dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: (v: CircuitDevicesRes) => <EllipsisSpan value={v.deviceDto.customerName} />,
  },
];

const routes = [{ name: '回路详情' }];

const CircuitDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [type, setType] = useState<CircuitType>();
  const navigate = useNavigate();

  const routesContext = useBreadcrumbRoutes();

  const { data, isLoading } = useQuery(['circuitDetail', +id], async () => {
    const res = await getCircuitDetail(+id);
    setType(res.type);
    return res;
  });

  return (
    <Wrapper className={styles.wrapperPadding} routes={[...(routesContext?.routes ?? []), ...routes]}>
      <Spin spinning={isLoading}>
        <Form>
          <FormTitle title="回路详情" />
          <SubContent title="基本信息">
            <Form.Item label="回路名称">{data?.name ?? '--'}</Form.Item>
            <Form.Item label="所属运营单元">{data?.ouName ?? '--'}</Form.Item>
            <Form.Item label="回路类型">{data ? CircuitTypeFormat[data.type] : '--'}</Form.Item>
            {type !== CircuitType.TRANSFORMER_CIRCUIT && (
              <Form.Item label="电压等级">
                {data && data.voltageLevel ? `${VoltageLevelFormat[data.voltageLevel]}kV` : '--'}
              </Form.Item>
            )}
            <Form.Item label="装机容量">{data?.capacity ?? '--'}kVA</Form.Item>
            {type === CircuitType.GRID_BILLING_CIRCUIT && data && (
              <>
                <Form.Item label="户号">{data.electricityAccountNumber ?? '--'}</Form.Item>
              </>
            )}
            {type === CircuitType.INCOMING_CIRCUIT && data && (
              <>
                <Form.Item label="电闸状态">
                  {data.switchStatus || data.switchStatus === 0 ? SwitchStatusFormat[data.switchStatus] : '--'}
                </Form.Item>
              </>
            )}
            {type === CircuitType.TRANSFORMER_CIRCUIT && data && (
              <>
                <Form.Item label="进线电压等级">
                  {data.voltageLevel ? `${VoltageLevelFormat[data.voltageLevel]}kV` : '--'}
                </Form.Item>
                <Form.Item label="出线电压等级">
                  {data.voltageOutLevel ? `${VoltageLevelFormat[data.voltageOutLevel]}kV` : '--'}
                </Form.Item>
                <Form.Item label="变压器容量">{data.transformerCapacity ?? '--'}</Form.Item>
              </>
            )}
            <Form.Item label="备注">{data?.remark ?? '--'}</Form.Item>
          </SubContent>
          <SubContent title="回路关联">
            <Form.Item label="所属配电柜">{data?.distributionCabinetName ?? '--'}</Form.Item>
            {(type === CircuitType.OUTGOING_CIRCUIT ||
              type === CircuitType.TRANSFORMER_CIRCUIT ||
              type === CircuitType.INCOMING_CIRCUIT) &&
              data && (
                <>
                  <Form.Item label="上游回路">{data.upstreamCircuitName ?? '--'}</Form.Item>
                </>
              )}
            {type === CircuitType.BUS_COUPLER_CIRCUIT && data && (
              <>
                <Form.Item label="左上游回路">{data.upstreamCircuitLeftName ?? '--'}</Form.Item>
                <Form.Item label="右上游回路">{data.upstreamCircuitRightName ?? '--'}</Form.Item>
                <Form.Item label="母联状态">
                  {data.busTieStatus || data.busTieStatus === 0 ? BusTieStatusFormat[data.busTieStatus] : '--'}
                </Form.Item>
              </>
            )}
          </SubContent>
          <SubContent title="采集设备">
            <Table
              columns={[...deviceColumns]}
              dataSource={data?.devices ?? []}
              pagination={{
                defaultPageSize: 20,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30', '50', '100'],
                showTotal: () => `共${data?.devices.length ?? 0}条`,
              }}
            />
          </SubContent>
        </Form>
        <Space
          size={8}
          style={{
            marginLeft: 32,
            marginTop: -13,
          }}
          className={'sticky-footer-left'}
        >
          <Button onClick={() => navigate(-1)}>返回</Button>
        </Space>
      </Spin>
    </Wrapper>
  );
};

export default CircuitDetail;
