import request from '@/utils/request';
import { PageRequest, PageResponse } from '@/api/page';

export const pvInverterKeys = {
  all: ['inverters'],
  list: (pvId: number) => [...pvInverterKeys.all, pvId],
  page: (filters: InverterPageQuery) => [...pvInverterKeys.all, { filters }],
};

export interface PVInverter {
  id: number;
  code: string;
  deviceCode: string;
  deviceId: number;
  deviceName: string;
  combinerBoxId: number;
  combinerBoxCode: string;
}

export interface InverterPayload {
  id?: number;
  code: string;
  deviceId?: number;
  combinerBoxId: number;
  pvId: number;
}

export interface InverterQuery {
  pvId: number;
  code?: string;
  combinerBoxId?: number;
}

export interface InverterImportError {
  pvCombinerBoxCode: string;
  deviceCode: string;
  pvInverterCode: string;
  errorMessage: string;
}

export type InverterPageQuery = InverterQuery & PageRequest;

export const getInverterPage = (params: InverterPageQuery) =>
  request<PageResponse<PVInverter>>({ url: `/api/pv-inverter/page`, params });

export const createInverter = (data: InverterPayload) =>
  request<void>({ url: `/api/pv-inverter/create`, method: 'POST', data });

export const updateInverter = (data: InverterPayload) =>
  request<void>({ url: `/api/pv-inverter/update`, method: 'PUT', data });

export const deleteInverter = (id: number) => request<void>({ url: `/api/pv-inverter/delete/${id}`, method: 'DELETE' });

export const removeInverterDevice = (id: number) =>
  request<void>({ url: `/api/pv-inverter/remove-device/${id}`, method: 'PUT' });

export const updateInverterDevice = (id: number, deviceId: number) =>
  request<void>({ url: `/api/pv-inverter/update-device/${id}`, method: 'PUT', params: { deviceId } });

export const deleteAllInverters = (pvId: number) =>
  request<void>({ url: `/api/pv-inverter/delete-all/${pvId}`, method: 'DELETE' });

export const exportInverters = (pvId: number) => window.open(`/api/pv-inverter/output/${pvId}`, '__blank');

export const getInverterList = (pvId: number) =>
  request<PVInverter[]>({ url: `/api/pv-inverter/list`, params: { pvId } });

export const downloadInverterTemplate = () => window.open(`/api/pv-inverter/download`, '__blank');

export const uploadInverterExcel = (pvId: number, file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  return request<InverterImportError[]>({
    url: `/api/pv-inverter/input/${pvId}`,
    method: 'POST',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
