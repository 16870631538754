import { MonthIncomeSort } from '@/api/bsaMap';
import { formateValuesRmb, getValueUnit } from '@/pages/MapStation/utils';
import { Button, EllipsisSpan, Table } from '@maxtropy/components';
import { Badge } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { RightValue } from '../..';
import styles from './index.module.scss';

const columns = [
  {
    title: '排名',
    dataIndex: 'rankIndex',
    width: '23%',
    align: 'center',
    ellipsis: { showTitle: true },
  },
  {
    title: '储能站名称',
    dataIndex: 'name',
    render: (v: string, record: MonthIncomeSort) => (
      <Button
        style={{ padding: 0 }}
        type="link"
        onClick={() => window.open(`${window.MSEDEVDATMIND}/#/energy/profit?bsaId=${record.id}`, '_blank')}
      >
        {v}
      </Button>
    ),
  },
  {
    title: '收益',
    dataIndex: 'income',
    align: 'center',
    render: (v: number) => <EllipsisSpan value={formateValuesRmb(v) + getValueUnit(v)} />,
  },
];

const MonthIncComingRank = () => {
  const rightValue = useContext(RightValue);
  const [dataSource, setDataSource] = useState<MonthIncomeSort[]>([]);

  useEffect(() => {
    if (rightValue?.monthIncomeSort) {
      // let sortData = data.sort((a, b) => {
      //   if (a.inComing === b.inComing) {
      //     return b.inComing - a.inComing;
      //   } else {
      //     return b.inComing - a.inComing;
      //   }
      // });
      rightValue?.monthIncomeSort.forEach((item, index) => {
        if (index === 0) {
          item.rankIndex = <Badge style={{ backgroundColor: '#BA8D0F' }} count={index + 1} />;
        } else if (index === 1) {
          item.rankIndex = <Badge style={{ backgroundColor: '#A09E9A' }} count={index + 1} />;
        } else if (index === 2) {
          item.rankIndex = <Badge style={{ backgroundColor: '#77442B' }} count={index + 1} />;
        } else {
          item.rankIndex = <Badge style={{ backgroundColor: '#4F4F4F' }} count={index + 1} />;
        }
      });
      setDataSource(rightValue?.monthIncomeSort);
    }
  }, [rightValue]);

  return (
    <div>
      <div className={styles.RightInfoTitle}>月度收益排行</div>
      <Table
        size={'small'}
        rowKey={'id'}
        bordered={false}
        dataSource={dataSource}
        // @ts-ignore
        columns={columns}
        pagination={false}
        className={styles.relatedPartyMaint}
      />
    </div>
  );
};

export default MonthIncComingRank;
