import { PermissionsType } from '@/common/permissionsConst';
import CabinetsManage from '.';

const routes = [
  {
    path: '/dmes/circuit/cabinets',
    pemission: PermissionsType.P_CABINET_MANAGE,
    element: <CabinetsManage />,
  },
];
export default routes;
