import styles from './index.module.scss';
interface Iprops {
  title: string;
  value?: number | null;
}
const CardItem = ({ title, value }: Iprops) => {
  return (
    <div className={styles.card_box}>
      <div className={styles.line_title}>{title}</div>
      <div className={styles.line_value}>{typeof value === 'number' ? value.toFixed(2) : '--'}</div>
    </div>
  );
};

export default CardItem;
