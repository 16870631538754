import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { Key } from 'react';
import { PageResponse } from './page';

export interface ChargingStationPageReq {
  ouId?: string;
  name?: string; //充电站名称
  page?: Key;
  size?: Key;
}

export enum ChargingStationStatus {
  DISABLE = 0,
  ENABLE = 1,
}
export const chargingStationStatusFormat = {
  [ChargingStationStatus.DISABLE]: '停用',
  [ChargingStationStatus.ENABLE]: '启用',
};

export interface ChargingStationPageRes {
  id: number;
  name: string;
  ouId: number;
  ouName: string;
  code: string;
  status: ChargingStationStatus;
  uetId: number;
  circuitId: number;
  circuitName: number;
  installedCapacity: number;
  updateByUserId: string;
  updateByUsername: string;
  district: string;
  address: string;
  latitude: number;
  longitude: number;
  adcode: string;
  tenantMcid: string;
  updateTime: number;
}

// 分页查询充电站列表
export function getChargingStationPage(params: ChargingStationPageReq) {
  return fetch<PageResponse<ChargingStationPageRes>>(
    `/api/v2/charging/charging-station/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

// 修改/更新充电站状态
export function updateChargingStationStatus(id: number, status: ChargingStationStatus) {
  return fetch<boolean>(
    `/api/v2/charging/charging-station/status/change`,
    {
      method: 'POST',
      body: JSON.stringify({ id, status }),
    },
    true
  );
}

export interface CostInfoReq {
  investmentCost?: string; //已投成本
  recoveredCost?: string; //收回成本
}

export interface CostInfoRes {
  chargingStationId: number; //充电站id
  investmentCost: number; //投建成本
  recoveredCost: number; //已回收成本
}

// 修改充电站成本信息
export function updateCostInfo(id: number, params: CostInfoReq) {
  return fetch<CostInfoRes>(
    `/api/v2/charging/charging-station/cost-info/edit`,
    {
      method: 'POST',
      body: JSON.stringify({ id: id, ...params }),
    },
    true
  );
}

// 获取充电站成本信息
export function getChargingStationCostInfo(id: Key) {
  return fetch<CostInfoRes>(
    `/api/v2/charging/charging-station/cost-info/info`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export enum ChargeGunLinkType {
  ACHARGINGGUN = 1,
  BCHARGINGGUN = 2,
}

export const ChargeGunLinkFormat = {
  [ChargeGunLinkType.ACHARGINGGUN]: 'A充电枪',
  [ChargeGunLinkType.BCHARGINGGUN]: 'B充电枪',
};

export interface ChargingGunList {
  id: number;
  chargingStationId: number;
  chargingPileId: number;
  chargingGunName: string;
  chargingGunType: ChargeGunLinkType;
  chargingGunDeviceCode: string;
  chargingGunDeviceId: number;
}

export interface ChargingPileAndGunDtoList {
  id: number;
  chargingPileName: string;
  chargingPileType: string;
  chargingPileDeviceCode: string;
  chargingPileDeviceId: number;
  createTime: number;
  chargingGunList: ChargingGunList[];
}

export interface DetailInfoRes {
  id: number;
  name: string;
  ouId: number;
  ouName: string;
  code: string;
  status: number;
  uetId: number;
  circuitId: number;
  circuitName: number;
  installedCapacity: number;
  updateByUserId: string;
  updateByUsername: string;
  createUsername: string;
  district: string;
  address: string;
  latitude: number;
  longitude: number;
  adcode: string;
  updateTime: number;
  createStationTime?: string;
}

// 获取充电站详情
export function getChargingStationDetailInfo(id: Key) {
  return fetch<DetailInfoRes>(
    `/api/v2/charging/charging-station/detail`,
    {
      method: 'POST',
      body: JSON.stringify({
        id: id,
      }),
    },
    true
  );
}

// 获取充电桩列表
export async function getChargingStationListInfo(id: Key) {
  const res = await fetch<{ list: ChargingPileAndGunDtoList[] }>(
    `/api/v2/charging/charging-pile/detail`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
  return res.list;
}

export interface ChargingStationByOuIdPageReq {
  nameOrCode?: string; //名字或编号
  deviceType?: number[]; //设备类型 写死5042
  tag?: string; //标签
  modelId?: string; //物模型型号
  chargingStationId: Key; //充电站id
  page: Key; //1页码,从1开始
  size: Key; //5页大小
}

export interface ChargingStationListByOuIdPageRes {
  id: number;
  code: string;
  name: string;
  deviceType: string;
  modelName: string;
  deviceTags: string[];
  customerName: string;
  typeId: number;
}

// 查询OU中充电桩设备,分页
export function getChargingStationByOuId(ouId: Key, params: ChargingStationByOuIdPageReq) {
  return fetch<PageResponse<ChargingStationListByOuIdPageRes>>(
    `/api/v2/charging/device/page/charging-pile`,
    {
      method: 'POST',
      body: JSON.stringify({ ...params, id: ouId }),
    },
    true
  );
}
// 查询OU中充电枪设备,分页
export function getChargingGunByOuId(ouId: Key, params: ChargingStationByOuIdPageReq) {
  return fetch<PageResponse<ChargingStationListByOuIdPageRes>>(
    `/api/v2/charging/device/page/charging-gun`,
    {
      method: 'POST',
      body: JSON.stringify({ ...params, id: ouId }),
    },
    true
  );
}
// 删除充电桩
export function deleteChargingStation(id: Key): Promise<boolean> {
  return fetch(
    `/api/v2/charging/charging-station/pile/delete`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface CreateChargeStationReq {
  chargingStationId: Key; //充电站id
  deviceId: Key; //充电桩设备id
}

// 新建充电桩
export const createChargeStation = (body: CreateChargeStationReq) => {
  return fetch(
    `/api/v2/charging/charging-station/pile/create`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
};

export interface ChargingStationInfo {
  id: number;
  district: string; //所在地区
  address: string; //详细地址
  latitude: number; //纬度
  longitude: number; //经度
  adcode: string; //高德地图区域编码
  createStationTime: string;
}

// 编辑充电站基础信息
export function updateChargingStation(body: ChargingStationInfo) {
  return fetch<boolean>(
    `/api/v2/charging/charging-station/edit`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}

export interface ModalRes {
  id: number;
  modelNo: string;
  manufacturerId: number;
  deviceTypeId: number;
  createTime: string;
  updateTime: string;
  manufacturerName: string;
}

export interface ModalReq {
  deviceTypeIds?: Key; //设备类目id
  manufacturerId?: Key; //厂商id
}

// 获取物模型型号
export function getModel(params: ModalReq): Promise<ModalRes[]> {
  return fetch(`/api/physical-model/list?${qs.stringify(params, { indices: false })}`);
}

export interface ChargingGunReq {
  chargingStationId: number; //充电站id
  chargingPileId: number; //充电桩id
  deviceId: number; //设备id
  type: ChargeGunLinkType; //设备类型 1充电枪A 2充电枪B
}

// 编辑充电枪
export function updateChargingGun(body: ChargingGunReq) {
  return fetch<boolean>(
    `/api/v2/charging/charging-station/gun/edit`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}
