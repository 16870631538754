import { V2NeoBsaRealTimeDataPostResponse } from '@maxtropy/dmes-apis-v2';

export enum OpStatus {
  SUCCESS,
  FAILURE,
  SENDING,
}

export const OpStatusDisplay = {
  [OpStatus.SUCCESS]: '成功',
  [OpStatus.FAILURE]: '失败',
  [OpStatus.SENDING]: '发送中',
};
export const OpStatusTagColor = {
  [OpStatus.SUCCESS]: 'success',
  [OpStatus.FAILURE]: 'error',
  [OpStatus.SENDING]: 'info',
};
export const AlarmStatusTagColor: Record<number, string> = {
  0: 'success',
  1: 'error',
};
export enum RealTimeDataType {
  METER = 1, // 计量电表
  PCS = 2,
  BMS,
  BATTERY,
  TEMPER,
}
export enum BsaDeviceType {
  INCOMMING = 1, // 进线电表
  TRANSFORM = 2, // 上端变压器电表
  CONNECT = 3, // 并网点电表
}

export enum DatapropertyType {
  YC = 1, // 遥测
  YX, // 遥信
}
export const BsaDeviceTypeDisplay = {
  [BsaDeviceType.INCOMMING]: '进线',
  [BsaDeviceType.TRANSFORM]: '上端变压器',
  [BsaDeviceType.CONNECT]: '并网点',
};
export const BsaDeviceTypeColor = {
  [BsaDeviceType.INCOMMING]: '#52E7FF',
  [BsaDeviceType.TRANSFORM]: '#2D8DFF',
  [BsaDeviceType.CONNECT]: '#CE90D1',
};
export const RealTimeDataTypeDisplay = {
  [RealTimeDataType.METER]: '计量电表',
  [RealTimeDataType.PCS]: 'PCS',
  [RealTimeDataType.BMS]: 'BMS',
  [RealTimeDataType.BATTERY]: '电池',
  [RealTimeDataType.TEMPER]: '温控与辅助设备',
};

export interface ComponentsProps {
  deviceList?: RealData;
}
export type RealData = V2NeoBsaRealTimeDataPostResponse['list'];
export type DataPropertyValues = FirstLevelDevice['dataPropertyValues'];
export type FirstLevelDevice = Exclude<RealData, undefined>[number];
export type NextLevelDeviceInfo = Exclude<FirstLevelDevice['nextLevelsRealTimeData'], undefined>[number];
export type MapValueObj = { ts?: string; value?: number };
