import { fetch } from '@maxtropy/components';

export interface ModelResponse {
  name: string;
  deviceTypeId: string;
  createSource: string;
  createByUserId: string;
}

export function getModel(id: number): Promise<ModelResponse[]> {
  return fetch(`/api/model/list?typeId=${id}`);
}
