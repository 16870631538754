import { FC, useEffect, useState } from 'react';
import { Button, Form, InputNumber, Modal, Table } from '@maxtropy/components';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Row, Space, TableColumnsType } from 'antd';
import { DataItem as PropData } from './PvTable';
import {
  PvThresholdType,
  PvThresholdTypeColor,
  PvThresholdTypeLabel,
  PvThresholdTypeList,
  ThresholdDeviceType,
} from './const';
import styles from './index.module.scss';
import { Rule } from 'antd/es/form';
import type { NamePath } from 'rc-field-form/lib/interface';
import { apiV2PvDispersionRateThresholdSetUpPost } from '@maxtropy/dmes-apis-v2';
import PvFlowModal from './PvFlowModal';

const getFormItemDependencies = (record: TableDataItem, name: string): NamePath[] => {
  return PvThresholdTypeList.filter(item => item !== record.id).map(type => [type, name]);
};

const getFormItemRules = (record: TableDataItem, name: string): Rule[] => {
  return [
    {
      required: true,
      message: '请输入',
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (record.id === PvThresholdTypeList[0]) return Promise.resolve();
        // 小于前一项，但是可以都为0
        if (getFieldValue([record.id + 1, name]) <= value && value !== 0) return Promise.reject('必须小于前一项');
        return Promise.resolve();
      },
    }),
  ];
};

interface TableDataItem {
  id: PvThresholdType;
}

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  data: PropData | undefined;
  onOk: () => void;
}

const PvModal: FC<Props> = props => {
  const { open, setOpen, data } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState<TableDataItem[]>(
    PvThresholdTypeList.map(type => ({
      id: type,
    }))
  );
  const [flowModalOpen, setFlowModalOpen] = useState(false);

  useEffect(() => {
    if (!data || !open) {
      form.resetFields();
      return;
    }
    let formData: any = {};
    data?.thresholdValueResponseList?.forEach(item => {
      formData[item.type!] = { ...item };
    });
    form.setFieldsValue(formData);
  }, [data, open]);

  const columns: TableColumnsType<TableDataItem> = [
    {
      title: '综合等级',
      dataIndex: 'id',
      width: 150,
      render: (v: PvThresholdType) => (
        <div className={styles.thresholdTitle} style={{ color: PvThresholdTypeColor[v] }}>
          <div className={styles.thresholdIcon} style={{ background: PvThresholdTypeColor[v] }}></div>
          {PvThresholdTypeLabel[v]}
        </div>
      ),
    },
    {
      title: '逆变器离散率等级占比下限',
      key: 'threshold',
      render: (_, record) => (
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              className={styles.mb0}
              label="异常"
              name={[record.id, 'abnormalValue']}
              dependencies={getFormItemDependencies(record, 'abnormalValue')}
              rules={getFormItemRules(record, 'abnormalValue')}
            >
              <InputNumber addonAfter="%" precision={2} min={0} max={100}></InputNumber>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className={styles.mb0}
              label="较差"
              name={[record.id, 'differenceValue']}
              dependencies={getFormItemDependencies(record, 'differenceValue')}
              rules={getFormItemRules(record, 'differenceValue')}
            >
              <InputNumber addonAfter="%" precision={2} min={0} max={100}></InputNumber>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className={styles.mb0}
              label="一般"
              name={[record.id, 'generalValue']}
              dependencies={getFormItemDependencies(record, 'generalValue')}
              rules={getFormItemRules(record, 'generalValue')}
            >
              <InputNumber addonAfter="%" precision={2} min={0} max={100}></InputNumber>
            </Form.Item>
          </Col>
        </Row>
      ),
    },
  ];

  const onOk = () => {
    form.validateFields().then(values => {
      setLoading(true);
      apiV2PvDispersionRateThresholdSetUpPost({
        type: ThresholdDeviceType.PV,
        objectId: data?.objectId,
        thresholdValueList: Object.keys(values).map(key => {
          return {
            type: Number(key),
            abnormalValue: values[key].abnormalValue,
            differenceValue: values[key].differenceValue,
            generalValue: values[key].generalValue,
          };
        }),
      })
        .then(() => {
          setOpen(false);
          props.onOk();
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <Modal
      title="离散率阈值设置"
      size="large"
      open={open}
      onCancel={() => {
        setOpen(false);
      }}
      onOk={onOk}
      confirmLoading={loading}
    >
      <Space size={5} align="start" style={{ marginBottom: 16 }}>
        <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
        <span style={{ color: `var(--mx-text-desc-color)` }}>
          请输入光伏站综合表现中不同档次下各逆变器离散率等级的最低占比，判断档次时会按照异常-较差-一般占比上限逐项比较
          <Button
            type="link"
            onClick={() => {
              setFlowModalOpen(true);
            }}
          >
            查看流程图
          </Button>
        </span>
      </Space>

      <Form isOrigin form={form}>
        <Table rowKey="id" dataSource={tableData} columns={columns}></Table>
      </Form>

      <PvFlowModal open={flowModalOpen} setOpen={setFlowModalOpen} />
    </Modal>
  );
};

export default PvModal;
