import dayjs, { Dayjs } from 'dayjs';

export const dateOfMonth = (date: Dayjs, key: number) => {
  const currentMonth = dayjs().format('YYYY-MM') === dayjs(date).format('YYYY-MM');
  const dateNumber = currentMonth ? dayjs().endOf('month').diff(dayjs(), 'd') + 1 : dayjs(date).daysInMonth();
  const firstDay = currentMonth ? dayjs() : dayjs(date).startOf('month');
  let dayOfMonth: { [key: number]: string[] } = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
  };
  for (let i = 0; i < dateNumber; i++) {
    const day = dayjs(firstDay).add(i, 'd');
    const dayOfWeek = day.day();
    dayOfMonth[dayOfWeek].push(dayjs(day).format('YYYY-MM-DD'));
  }
  const res: string[] = [];
  if (key === 7) {
    res.push(...dayOfMonth[0], ...dayOfMonth[6]);
  } else if (key === 8) {
    res.push(...dayOfMonth[1], ...dayOfMonth[2], ...dayOfMonth[3], ...dayOfMonth[4], ...dayOfMonth[5]);
  } else if (key === 9) {
    res.push(
      ...dayOfMonth[0],
      ...dayOfMonth[1],
      ...dayOfMonth[2],
      ...dayOfMonth[3],
      ...dayOfMonth[4],
      ...dayOfMonth[5],
      ...dayOfMonth[6]
    );
  } else {
    res.push(...dayOfMonth[key]);
  }
  return res;
};

export const colorDisc = [
  '#177DDC',
  '#13A8A8',
  '#49AA19',
  '#D8BD14',
  '#D87A16',
  '#D84A1B',
  '#D22129',
  '#CB2B83',
  '#D32029',
  // '#D84A1B',
  // '#D87A16',
  '#D89614',
  // '#D8BD14',
  '#8BBB11',
  // '#49AA19',
  // '#13A8A8',
  // '#177DDC',
  '#2B4ACB',
  '#642AB5',
  // '#CB2B83',
  '#F37370',
  '#F3956A',
  '#F3B765',
  '#F3CC62',
  '#F3EA62',
  '#C9E75D',
  '#8FD460',
  '#58D1C9',
  '#65B7F3',
  '#7F9EF3',
  '#AB7AE0',
  '#F37FB7',
  '#F89F9A',
  '#F8B692',
  '#F8CF8D',
  '#F8DF8B',
  '#F8F48B',
  '#E4F88B',
  '#B2E58B',
  '#84E2D8',
  '#8DCFF8',
  '#A8C1F8',
  '#CDA8F0',
  '#F8A8CC',
];
