import BorderWrapper from '@/components/BorderWrapper';
import { Space } from 'antd';
import { FC, ReactNode } from 'react';
import styles from './index.module.scss';

export interface ExpectPageProps {
  imgUrl: string;
  title?: ReactNode;
}

const ExpectPage: FC<ExpectPageProps> = ({ imgUrl, title }) => {
  return (
    <div className="page" style={{ height: '100%', position: 'relative' }}>
      <BorderWrapper>
        <div className="page__content">
          <div className={styles.content}>
            <Space size={24} align="center" direction="vertical">
              <img className={styles.img} src={imgUrl} alt="" />
              <span className={styles.text}>{title}</span>
            </Space>
          </div>
        </div>
      </BorderWrapper>
    </div>
  );
};

export default ExpectPage;
