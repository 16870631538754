import { Button, PopConfirm, Table, EllipsisSpan } from '@maxtropy/components';
import { Space } from 'antd';
import { ColumnType } from 'antd/es/table';
import { EPricePart, EPriceTime } from '../../api/electricityPrice';
import EPricePeakItem from './PeakItem';

interface EPriceTimeTableProps {
  dataSource: EPricePart[];
  onDelete?: (index: number) => void;
  onEdit?: (index: number) => void;
  disabled?: boolean;
}

const EPriceTimeTable: React.FC<EPriceTimeTableProps> = ({ dataSource, onDelete, onEdit, disabled = false }) => {
  const actionColumn: ColumnType<EPricePart> = {
    title: '操作',
    dataIndex: 'action',
    width: 110,
    fixed: 'right' as const,
    render: (_, _record, index) => (
      <Space size={16}>
        <PopConfirm title="确定移除分时电价？" onConfirm={() => onDelete?.(index)} okText="确定" cancelText="取消">
          <Button type="link">删除</Button>
        </PopConfirm>
        <Button type="link" onClick={() => onEdit?.(index)}>
          编辑
        </Button>
      </Space>
    ),
  };

  return (
    <div>
      <Table columns={disabled ? columns : [...columns, actionColumn]} dataSource={dataSource} pagination={false} />
    </div>
  );
};

const columns: ColumnType<EPricePart>[] = [
  {
    title: '月份',
    dataIndex: 'months',
    width: 180,
    render: (v: number[]) => <EllipsisSpan value={v.map(m => `${m}月`).join('、')} />,
  },
  {
    title: '',
    dataIndex: 'ePriceTimes',
    ellipsis: { showTitle: true },
    render: (v: EPriceTime[]) => <EPricePeakItem disabled times={v} />,
  },
];

export default EPriceTimeTable;
