import { Col, Row } from 'antd';
import { FC } from 'react';
import styles from './index.module.scss';
import currentMonthInComingImg from '../imgs/currentMonthInComing.png';
import currentYearInComingImg from '../imgs/currentYearInComing.png';
import totalInComingImg from '../imgs/totalInComing.png';
import { formateValuesRmb, getValueUnit } from '../utils';
import { BsaDataStaticsGisRes } from '@/api/bsaMap';
import { isNil } from 'lodash-es';

export interface ITopSummary {
  data?: BsaDataStaticsGisRes;
}

const TopSummary: FC<ITopSummary> = props => {
  const { data } = props;

  return (
    <div className={styles.TopSummaryStyle}>
      <Row align="middle" justify="center" wrap={false}>
        <Col className={styles.TopItemInfo} flex={1}>
          <img style={{ width: 57, height: 50 }} src={currentMonthInComingImg} alt="" />
          <div>
            <div className={styles.TopLabel}>本月收益（{getValueUnit(data?.thisMonthIncome)}）</div>
            <div className={styles.TopValue}>{formateValuesRmb(data?.thisMonthIncome)}</div>
            <div>
              环比{' '}
              <span
                style={{
                  color: data?.thisMonthIncomeRate && data?.thisMonthIncomeRate > 0 ? '#FF4D4F' : '#59D744',
                }}
                className={styles.upperLowPercent}
              >
                {data?.thisMonthIncomeRate
                  ? data.thisMonthIncomeRate > 0
                    ? `+${(data.thisMonthIncomeRate * 100).toFixed(2)}%`
                    : `${(data.thisMonthIncomeRate * 100).toFixed(2)}%`
                  : '--'}
              </span>
            </div>
          </div>
        </Col>
        <Col className={styles.TopItemInfo} flex={1}>
          <img style={{ width: 57, height: 50 }} src={currentYearInComingImg} alt="" />
          <div>
            <div className={styles.TopLabel}>本年收益（{getValueUnit(data?.thisYearIncome)}）</div>
            <div className={styles.TopValue}>{formateValuesRmb(data?.thisYearIncome)}</div>
            <div>
              环比{' '}
              <span
                style={{
                  color: data?.thisYearIncomeRate && data?.thisYearIncomeRate > 0 ? '#FF4D4F' : '#59D744',
                }}
                className={styles.upperLowPercent}
              >
                {!isNil(data?.thisYearIncomeRate)
                  ? data?.thisYearIncomeRate! > 0
                    ? `+${(data?.thisYearIncomeRate! * 100).toFixed(2)}%`
                    : `${(data?.thisYearIncomeRate! * 100).toFixed(2)}%`
                  : '--'}
              </span>
            </div>
          </div>
        </Col>
        <Col className={styles.TopItemInfo} flex={1}>
          <img style={{ width: 57, height: 50 }} src={totalInComingImg} alt="" />
          <div>
            <div className={styles.TopLabel}>累计收益（{getValueUnit(data?.totalIncome)}）</div>
            <div className={styles.TopValue}>{formateValuesRmb(data?.totalIncome)}</div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TopSummary;
