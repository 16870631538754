import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/hooks';
import { Button, Form, message, Select, TenantType, useCurrent } from '@maxtropy/components';
import {
  apiV2PvStationDetailPost,
  apiV2PvStationPvTreePost,
  V2PvStationDetailPostResponse,
} from '@maxtropy/dmes-apis-v2';
import { useRequest } from 'ahooks';
import { Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useContext, useEffect, useMemo, useState } from 'react';
import { PollingInterval, PvInfoContext } from '..';
import styles from '../index.module.scss';
export interface Iprops {
  fullBtn: () => void;
  isFullScreen?: boolean;
  data?: V2PvStationDetailPostResponse;
}
const TopNav = ({ fullBtn, isFullScreen, data }: Iprops) => {
  const [timestamp, setTimeStamp] = useState<Dayjs>();
  const hasLayoutBtn = useHasPermission(PermissionsType.B_PVA_LAYOUT);
  const isChannel = useCurrent()?.tenant?.tenantType === TenantType.CHANNEL;
  const [tenantId, setTenantId] = useState<string>();
  const [pvId, setPvId] = useState<number>();
  const pvContext = useContext(PvInfoContext);

  const { data: pvTree } = useRequest(async () => {
    const res = await apiV2PvStationPvTreePost({});
    const list = res.list ?? [];
    // 三级结构重组为二级结构
    const newList = list.map(item => {
      let pvList: { pvId?: number; pvName?: string; createTime?: string }[] = [];
      item.pvCustomerTree?.forEach(customer => {
        pvList = pvList.concat(customer.pvTree ?? []);
      });
      pvList = pvList.sort((a, b) => {
        return dayjs(b.createTime).valueOf() - dayjs(a.createTime).valueOf();
      });
      return {
        ...item,
        pvList: pvList,
      };
    });
    // 设置默认值
    if (newList[0]?.pvList?.[0]) {
      setTenantId(newList[0].tenantMcid);
      setPvId(newList[0].pvList[0].pvId);
    }
    return newList;
  });

  const pvList = useMemo(() => {
    const found = pvTree?.find(item => item.tenantMcid === tenantId);
    return found?.pvList;
  }, [tenantId, pvTree]);

  const onTenantChange = (value: string) => {
    setTenantId(value);
    const found = pvTree?.find(item => item.tenantMcid === value);
    for (const customer of found?.pvCustomerTree ?? []) {
      const pvList = customer.pvTree ?? [];
      if (pvList.length > 0) {
        setPvId(pvList[0].pvId);
        return;
      }
    }
  };
  useEffect(() => {
    pvContext?.setCurrentPvId?.(pvId);
  }, [pvId]);

  useRequest(
    () => {
      return Promise.resolve().then(() => setTimeStamp(dayjs()));
    },
    {
      ready: !!pvId,
      refreshDeps: [pvId],
      pollingInterval: PollingInterval,
      pollingWhenHidden: false,
    }
  );

  return (
    <div className={styles.top_box}>
      <Space>
        <Form isOrigin layout="inline" style={{ marginBottom: 10 }}>
          {isChannel && (
            <Form.Item label="租户" style={{ marginRight: 24 }}>
              <Select
                getPopupContainer={() => document.getElementById('full_screen_box') ?? document.body}
                style={{ width: 200 }}
                options={pvTree?.map(item => ({
                  label: item.tenantName,
                  value: item.tenantMcid,
                }))}
                value={tenantId}
                onChange={onTenantChange}
              ></Select>
            </Form.Item>
          )}
          <Form.Item label="光伏站">
            <Select
              style={{ width: 200 }}
              getPopupContainer={() => document.getElementById('full_screen_box') ?? document.body}
              options={pvList?.map(item => ({
                label: item.pvName,
                value: item.pvId,
              }))}
              value={pvId}
              onChange={value => setPvId(value)}
            ></Select>
          </Form.Item>
          <Form.Item
            colon={false}
            label={
              <div className={styles.time_box}>页面刷新时间: {dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss')}</div>
            }
          ></Form.Item>
        </Form>
      </Space>
      <Space size={8}>
        {hasLayoutBtn && (
          <Button
            type="primary"
            onClick={() => {
              if (!data?.files || data.files.length === 0) {
                message.error('暂无布局图');
              } else {
                window.open(`/pv/running/dashbord/fileView?pvId=${pvId}`, '_blank');
              }
            }}
          >
            查看布局图
          </Button>
        )}

        <Button
          type="primary"
          onClick={() => window.open(`${window.PVANALYSIS}/actual-time-board-v2?loc=1-${pvId}`, '_blank')}
        >
          逆变器看板
        </Button>
        <Button type="primary" onClick={fullBtn}>
          {isFullScreen ? '退出' : '全屏'}
        </Button>
      </Space>
    </div>
  );
};

export default TopNav;
