import { CircuitProps, MonitoringIndicatorProps, queryMonitoringIndicatorList } from '@/api/circuit';
import { DataNameType } from '@/api/const';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { FC, useContext, useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { notEmptyForChart } from '../../utils';
import { isNil } from 'lodash-es';
import styles from '../../index.module.scss';
import { max } from '@/pages/Statistics/utils';
import { DeviceData, getDeviceDataInstantaneousFor1m, getTimeAggrInstantaneous } from '@/api/device';
import isBetween from 'dayjs/plugin/isBetween';
import { Empty } from '@maxtropy/components';
import { ScaleContext } from '@/pages/InComingOverview';
dayjs.extend(isBetween);

interface ICurrentMonthMaxDemand {
  currentCircuit?: number;
  circuitList?: CircuitProps[];
  openTimer: (isOver: boolean) => void;
  timerState?: number;
}

export type FormatterItem = {
  name: string;
  value: number;
  marker: string;
  data: [string, number | null];
  seriesName: string;
  dataIndex: number;
  axisValue: string;
  seriesIndex: number;
};

const icons = [
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjYycHgiIGhlaWdodD0iMzdweCIgdmlld0JveD0iMCAwIDYyIDM3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA0NC4xICg0MTQ1NSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+R3JvdXA8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAiIGZpbGw9IiNGRjZEMkMiPgogICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiB4PSIwIiB5PSIwIiB3aWR0aD0iNjIiIGhlaWdodD0iMjYiPjwvcmVjdD4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlRyaWFuZ2xlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyOS41MDAwMDAsIDMxLjAwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMjkuNTAwMDAwLCAtMzEuMDAwMDAwKSAiIHBvaW50cz0iMjkuNSAyNSA0MCAzNyAxOSAzNyI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+',
];

const xAxis = {
  axisTick: {
    show: false,
  },
  splitLine: {
    show: false,
  },
};

const ACTIVE_POWER = '10121';
const CurrentMonthMaxDemand: FC<ICurrentMonthMaxDemand> = props => {
  const scale = useContext(ScaleContext);
  const { currentCircuit, circuitList, openTimer, timerState } = props;
  const [currentMonthMaxDemand, setCurrentMonthMaxDemand] = useState<number | string>();
  const [maxDemandLoad, setMaxDemandLoad] = useState<string>();
  const [chartData, setChartData] = useState<DeviceData[]>([]);
  const [maxValueTime, setMaxValueTime] = useState<string>();
  const [monitoringData, setMonitoringData] = useState<MonitoringIndicatorProps[]>([]);

  useEffect(() => {
    if (currentCircuit && circuitList) {
      queryMonitoringIndicatorList(String(currentCircuit)).then(res => {
        setMonitoringData(res);
      });
    }
  }, [currentCircuit, circuitList]);
  // 本月最大需量电量
  useEffect(() => {
    if (monitoringData && monitoringData.length > 0) {
      let findCircuitDevice = monitoringData.find(item => item.circuitPhysicalQuantityId === DataNameType.PDMD);
      if (findCircuitDevice && findCircuitDevice.deviceId) {
        // 图表
        openTimer?.(false);
        Promise.all([
          getTimeAggrInstantaneous({
            deviceIds: String(findCircuitDevice.deviceId),
            propertyIds: ACTIVE_POWER,
            resolution: 'D',
            aggrType: 'max',
            startTime: dayjs().startOf('month').valueOf(),
            endTime: dayjs().valueOf(),
          }),
          getDeviceDataInstantaneousFor1m({
            deviceIds: String(findCircuitDevice.deviceId),
            propertyIds: ACTIVE_POWER,
            startTime: dayjs().startOf('month').valueOf(),
            endTime: dayjs().valueOf(),
          }),
        ])
          .then(([res1, res]) => {
            setChartData(res1);
            let newArr: { time: number; value: number | undefined }[] = [];
            if (res.length !== 0) {
              res[0]?.values.forEach(item => {
                newArr.push({
                  time: item.time,
                  value: item.value ?? undefined,
                });
              });
              // 获取最大值
              let maxValue = max(newArr.map(item => item.value));
              setCurrentMonthMaxDemand(Number(maxValue.toFixed(2)));

              // 获取最大负载
              let findCircuitBasicInfo = circuitList?.find(item => item.id === currentCircuit);
              if (findCircuitBasicInfo && findCircuitBasicInfo.capacity) {
                setMaxDemandLoad(((maxValue / findCircuitBasicInfo.capacity) * 100).toFixed(2));
              } else {
                setMaxDemandLoad('--');
              }

              // 获取发生时间
              let findMaxValueTime = newArr.find(item => item.value === maxValue);
              if (findMaxValueTime) {
                setMaxValueTime(dayjs(findMaxValueTime.time).format('YYYY-MM-DD HH:mm:ss'));
              } else {
                setMaxValueTime('--');
              }
            } else {
              setCurrentMonthMaxDemand('--');
              setMaxDemandLoad('--');
              setMaxValueTime('--');
            }
          })
          .finally(() => openTimer?.(true));
      } else {
        setChartData([]);
        setCurrentMonthMaxDemand('--');
        setMaxDemandLoad('--');
        setMaxValueTime('--');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monitoringData, timerState]);

  const getAxis = () => {
    let showDate = Array.from(
      {
        length: dayjs(dayjs().endOf('month').valueOf(), 'x').diff(dayjs(dayjs().startOf('month').valueOf()), 'day') + 1,
      },
      (_, i) => dayjs().startOf('month').valueOf() + 24 * 60 * 60 * 1000 * i
    );
    return {
      ...xAxis,
      type: 'category',
      data: showDate.map(a => dayjs(a, 'x').format('MM-DD')),
    };
  };

  const getOption = () => {
    return {
      title: {
        text: '本月最大需量变化（kW）',
        textStyle: {
          fontStyle: 'normal',
          color: 'rgba(255, 255, 255, 0.65)',
          fontSize: 14,
          fontWight: 'normal',
        },
      },
      grid: {
        left: 60,
        right: 15,
        top: 45,
        bottom: 25,
      },
      color: ['#3DC25B', '#b0f391'],
      tooltip: {
        trigger: 'axis',
        formatter(items: FormatterItem[]) {
          const { axisValue } = items[0];
          let str = axisValue;
          items.forEach(item => {
            const { seriesName, data, marker } = item;
            const value = !isNil(data[1]) ? `${data[1]}kW` : '--';
            str += `<br> ${marker}${seriesName}: ${value}`;
          });
          return str;
        },
      },
      xAxis: getAxis(),
      yAxis: {
        type: 'value',
        // name: 'kW',
        nameTextStyle: {
          fontSize: 12,
          align: 'right',
        },
        splitLine: {
          lineStyle: {
            color: '#FFFFFF30',
          },
        },
      },
      series: [
        ...chartData.map(item => ({
          type: 'line',
          smooth: true,
          color: ['#3DC25B', '#b0f391'],
          name: '最大需量',
          data: item.values.map((a, index) => [index, !isNil(a.value) ? Number(a.value.toFixed(2)) : null]),
          markPoint: {
            label: {
              normal: {
                show: true,
                offset: [0, -4],
              },
            },
            emphasis: {
              show: true,
              offset: [0, -4],
            },
            symbolSize: [40, 26],
            symbolOffset: [0, -13],
            data: [
              {
                name: '最大值',
                xAxis: dayjs(maxValueTime).date() - 1,
                yAxis: currentMonthMaxDemand,
                value: currentMonthMaxDemand,
                symbol: `image://${icons[0]}`,
                symbolSize: [62, 32], // 调整图片的长宽
                itemStyle: {
                  normal: {
                    color: '#e16b31',
                    opacity: 0.75,
                  },
                },
              },
              // {
              //   type: "max",
              //   name: "最大值",
              //   symbol: `image://${icons[0]}`,
              //   itemStyle: {
              //     normal: {
              //       color: "#e16b31",
              //       opacity: 0.75,
              //     },
              //   },
              // },
            ],
            itemStyle: {
              normal: {
                color: '#d6a223',
              },
            },
          },
        })),
      ],
    };
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={8}>
          <p style={{ color: '#14D0E4', fontSize: '22px', fontWeight: 700, margin: 0 }}>
            {!isNil(currentMonthMaxDemand) ? currentMonthMaxDemand + 'kW' : '--'}
          </p>
          <div className={styles.line_through}>
            <span className={styles.line_head}></span>
          </div>
          <p style={{ color: 'rgba(255,255,255,0.65)', margin: 6 }}>本月最大需量</p>
          <p style={{ color: '#14D0E4', fontSize: '22px', fontWeight: 700, margin: 0 }}>
            {!isNil(maxDemandLoad) ? maxDemandLoad + '%' : '--'}
          </p>
          <div className={styles.line_through}>
            <span className={styles.line_head}></span>
          </div>
          <p style={{ color: 'rgba(255,255,255,0.65)', margin: 6 }}>最大需量负载</p>
          <p>
            发生时间：<span style={{ color: '#14D0E4' }}> {!isNil(maxValueTime) ? maxValueTime : '--'}</span>
          </p>
        </Col>
        <Col span={16}>
          {notEmptyForChart(chartData) ? (
            <>
              <ReactEcharts
                option={getOption()}
                style={{ height: '100%', zoom: 1 / scale }}
                notMerge
                lazyUpdate={false}
              />
            </>
          ) : (
            <Empty style={{ padding: 20 }} description={'暂无数据'} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default CurrentMonthMaxDemand;
