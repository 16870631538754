import { Drawer, Spin } from 'antd';
import React, { FC, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { Button, DatePicker, Empty } from '@maxtropy/components';
import { CloseOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import ReactEcharts from 'echarts-for-react';
import { getCurveChartOption } from './chart';
import { useRequest } from 'ahooks';
import { apiV2PvOutlinePvPowerStatisticsPost } from '@maxtropy/dmes-apis-v2';
import { ElecType } from './type';
import { isNil } from 'lodash-es';
import ItemPowerRender, { keepTwoOrLine } from './ItemPowerRender';

export interface ICurveDrawer {
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  drawerOpen: boolean;
  currentPvId?: number;
}

const CurveDrawer: FC<ICurveDrawer> = props => {
  const { setDrawerOpen, drawerOpen, currentPvId } = props;
  const [date, setDate] = useState<Dayjs>(dayjs()); // 时间选择

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > dayjs().endOf('day');
  };

  const onDateChange: DatePickerProps['onChange'] = date => {
    if (date) {
      setDate(date);
    }
  };

  // 查询数据
  const { data: chartData, loading } = useRequest(
    () => {
      return apiV2PvOutlinePvPowerStatisticsPost({
        pvId: currentPvId,
        time: date.valueOf(),
      });
    },
    {
      pollingInterval: 2 * 60000,
      ready: !!currentPvId && !!date && drawerOpen,
      refreshDeps: [currentPvId, date, drawerOpen],
    }
  );

  // 图表配置
  const curveChartOption = useMemo(() => getCurveChartOption(chartData), [chartData]);

  return (
    <Drawer
      className={styles.drawerStyle}
      title={<span className={styles.drawerTitle}>运行曲线</span>}
      placement="right"
      width={640}
      mask={false}
      closable={false}
      open={drawerOpen}
      getContainer={() => document.getElementById('full_screen_box') ?? document.body}
      extra={
        <Button
          type="link"
          style={{ color: 'rgba(255,255,255,0.45)' }}
          icon={<CloseOutlined />}
          onClick={() => {
            setDrawerOpen(false);
          }}
        />
      }
    >
      <Spin spinning={loading}>
        <div className={styles.top_box}>
          <DatePicker
            style={{ width: 320 }}
            allowClear={false}
            disabledDate={disabledDate}
            onChange={onDateChange}
            picker="date"
            value={date}
            // getPopupContainer={() => outerRef}
            getPopupContainer={triggerNode => triggerNode}
          />
        </div>
        <div className={styles.center_box}>
          {chartData ? (
            <ReactEcharts style={{ height: 300 }} option={curveChartOption} notMerge lazyUpdate={false} />
          ) : (
            <Empty />
          )}
        </div>

        <div className={styles.bottom_box}>
          <div className={styles.inverter_total}>
            <span className={styles.title}>逆变器总发电量(kWh)</span>
            <span className={styles.value}>
              {keepTwoOrLine(chartData?.powerList?.find(i => i.type === ElecType.Inverter)?.totalPower)}
            </span>
          </div>

          <ItemPowerRender title="并网点总发电量(kWh)" chartData={chartData} type={ElecType.Consolidation_point} />
          <ItemPowerRender title="消纳电量(kWh)" chartData={chartData} type={ElecType.Consumption} />
          <ItemPowerRender title="上网电量(kWh)" chartData={chartData} type={ElecType.Internet} />
        </div>
      </Spin>
    </Drawer>
  );
};

export default CurveDrawer;
