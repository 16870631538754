import { FormInstance } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { MonitoringIndicatorProps } from '../../api/circuit';
import FormItemDisplay from './FormItemDisplay';
import FormItemWrapper from './FormItemWrapper';
import { InfoCircleOutlined } from '@ant-design/icons';
import EffectiveTimePicker, { EffectiveTimeMode, EffectiveTimeType, EffectiveTimeValue } from './EffectiveTimePicker';
import { useQuery } from 'react-query';
import { queryDeviceListByCircuitId } from '../../api/device';
import { queryDataPropertyList } from '../../api/dataProperty';
import { Dayjs } from 'dayjs';
import { Form, Select } from '@maxtropy/components';

interface EditFormProps {
  row?: Partial<MonitoringIndicatorProps>;
  form: FormInstance;
}

const BindForm: React.FC<EditFormProps> = props => {
  const { row, form } = props;
  const [deviceId, setDeviceId] = useState<number>();
  const firstState = useRef(false);

  const { data: devices } = useQuery(['deviceListByCircuit', row?.circuitId], async () => {
    if (!row?.circuitId) return [];
    const res = await queryDeviceListByCircuitId(row.circuitId);
    return res.map(device => ({ label: device.name, value: device.id }));
  });

  const { data: dataProperties = [] } = useQuery(['dataPropertyList', deviceId], async () => {
    if (!deviceId) return [];
    const res = await queryDataPropertyList({ deviceId: deviceId });
    return res.map(dataProperty => ({ label: dataProperty.name, value: dataProperty.id }));
  });

  useEffect(() => {
    if (row) {
      form.setFieldsValue(row);
      setDeviceId(row.deviceId);
    }
  }, [row, form]);

  useEffect(() => {
    if (dataProperties.length > 0 && row && !firstState.current) {
      if (!dataProperties.find(item => item.value !== row.dataPropertyId)) {
        form.setFieldsValue({ dataPropertyId: null });
      }
      firstState.current = true;
    }
  }, [row, dataProperties, form]);

  return (
    <Form form={form}>
      <Form.Item label="监控指标" name="circuitPhysicalQuantityName">
        <FormItemDisplay />
      </Form.Item>

      <Form.Item label="设备选择" name="deviceId" rules={[{ required: true, message: '请选择设备' }]}>
        <FormItemWrapper extra="如果没有找到设备，请到该回路下绑定设备">
          <Select placeholder="请输入/选择要绑定的设备" options={devices} onChange={setDeviceId} />
        </FormItemWrapper>
      </Form.Item>

      <Form.Item label="数据属性" name="dataPropertyId" rules={[{ required: true, message: '请选择数据属性' }]}>
        <Select
          placeholder="请输入/选择要绑定的数据属性"
          options={dataProperties}
          showSearch
          optionFilterProp="label"
          filterOption={(input, option) => option!.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        />
      </Form.Item>

      <Form.Item
        label="选择数据生效时间"
        name="effectiveTime"
        required
        rules={[{ required: true, message: '请选择数据生效时间' }]}
        // rules={[{validator: checkEffectiveTime(EffectiveTimeMode.BIND, dayjs(row?.circuitCreateTime!))}]}  // s51迭代拿掉校验
      >
        <EffectiveTimePicker mode={EffectiveTimeMode.BIND} />
      </Form.Item>

      <Form.Item {...{ wrapperCol: { span: 16, offset: 6 } }}>
        <>
          <InfoCircleOutlined style={{ color: 'var(--warning-color)', marginRight: 4 }}></InfoCircleOutlined>
          <span>请注意变更后数据存在一定的滞后性！</span>
        </>
      </Form.Item>
    </Form>
  );
};

export default BindForm;

export const checkEffectiveTime = (mode: EffectiveTimeMode, time: Dayjs) => (_: unknown, value: EffectiveTimeValue) => {
  if (!value || (value.type === EffectiveTimeType.SPECIFIED && (!value.date || !value.time))) {
    return Promise.reject(new Error(`选择数据${mode === EffectiveTimeMode.BIND ? '生效时间' : '解绑时间'}`));
  }
  if (value.type === EffectiveTimeType.SPECIFIED && value.date && value.time) {
    const _time = value.date
      .set('hour', value.time.get('hour'))
      .set('minute', value.time.get('minute'))
      .set('second', 0);
    // const _time = value.date.set({ hour: value.time?.get('hour'), minute: value.time?.get('minute'), second: 0 });
    if (_time!.isBefore(time)) {
      if (mode === EffectiveTimeMode.BIND) {
        return Promise.reject(new Error('不能早于该回路的创建时间'));
      } else {
        return Promise.reject(new Error('不能早于该设备数据正在生效的时间'));
      }
    }
  }
  return Promise.resolve();
};
