import { PVStatus } from '@/api/type';
import { FormInstance } from 'antd';
import { useContext, useMemo } from 'react';
import { PvInfoContext } from '..';
import AddressCascader from '../../AddressCascader';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, Form, Input, InputNumber, ShowInput, Tooltip } from '@maxtropy/components';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import DividerContent from '@/components/DividerContent';

interface Iprops {
  getLngLat?: (laglat: string, adcode: string) => void;
  form?: FormInstance;
  adcode?: string;
}
const BaseEdit: React.FC<Iprops> = ({ adcode, getLngLat, form }) => {
  const { pvStatus } = useContext(PvInfoContext);
  // 停用状态装机容量不可更改
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const installDisable = useMemo(() => pvStatus === PVStatus.DISABLE, [pvStatus]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const disabledDate = (current: Dayjs) => {
    return current && current.valueOf() >= dayjs().endOf('day').valueOf();
  };

  return (
    <>
      <DividerContent title="基础资料">
        <Form.Item label="光伏站名称" name="name">
          <ShowInput />
        </Form.Item>
        <Form.Item label="所属组织" name="customerName">
          <ShowInput />
        </Form.Item>
        <Form.Item label="运营单元" name="ouName">
          <ShowInput />
        </Form.Item>
        <Form.Item label="装机容量" rules={[{ required: true, message: '请输入' }]} name="installedCapacity">
          <InputNumber
            min={0.01}
            max={9999999999}
            precision={2}
            style={{ width: '100%' }}
            placeholder="请输入"
            addonAfter="kW"
          />
        </Form.Item>

        {/* 所在地区及详细地址 */}
        <AddressCascader adcode={adcode} getLngLat={getLngLat} form={form} />
        <Form.Item label="备注" name="remark" rules={[{ max: 50, message: '最多输入50个字符' }]}>
          <Input placeholder="请输入" maxLength={50} />
        </Form.Item>
      </DividerContent>
      <DividerContent title="参数设置">
        <Form.Item
          label="STC参考光强"
          name="referenceLighting"
          initialValue={1000}
          rules={[
            {
              required: true,
              message: '请输入参考光强',
            },
          ]}
        >
          <InputNumber addonAfter={'W/㎡'} style={{ width: 340 }} max={10000} min={0} maxLength={10} />
        </Form.Item>

        <Form.Item
          label="碳排因子"
          name="carbonEmissionFactor"
          initialValue={0.998}
          rules={[
            { required: true, message: '请输入' },
            {
              pattern: /^[1-9]*[1-9][0-9]*$|^\d+(\.\d{1,4})+$/,
              message: '仅能输入正数数字和小数点, 精确至小数点后四位',
            },
          ]}
        >
          <Input placeholder="请输入" addonAfter="kgCO2/(kWh)" />
        </Form.Item>
        <Form.Item
          label="太阳能板面积"
          name="solarPanelArea"
          rules={[
            { required: true, message: '请输入' },
            {
              pattern: /^[1-9]*[1-9][0-9]*$|^\d+(\.\d{1,2})+$/,
              message: '仅能输入正数数字和小数点, 精确至小数点后两位',
            },
          ]}
        >
          <Input placeholder="请输入" addonAfter="㎡" />
        </Form.Item>
        <Form.Item
          label="转化效率"
          name="conversionEfficiency"
          rules={[
            { required: true, message: '请输入' },
            {
              pattern: /^100$|^[1-9]{1}[0-9]{1}$|^[1-9]{1}$|^\d{1,2}((\.[1-9]{1})+|(\.\d{1}[1-9]{1})+)$/,
              message: '仅能输入正数数字和小数点, 精确至小数点后两位, 且不允许大于100',
            },
          ]}
        >
          <Input placeholder="请输入" addonAfter="%" />
        </Form.Item>
      </DividerContent>
    </>
  );
};
export default BaseEdit;
