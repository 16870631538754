import { PermissionsType } from '@/common/permissionsConst';
import InComingOverview from '.';

const routes = [
  {
    path: '/dmes/energyData/InComingOverview',
    permission: PermissionsType.P_INCOMINGLINEOVERVIEW,
    element: <InComingOverview />,
  },
];

export default routes;
