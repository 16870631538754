import { FC } from 'react';
import styles from './index.module.scss';
import { formateValuesToFix2 } from '../../utils';

export interface IValueThroughTitle {
  value?: number | string;
  title?: string;
}

const ValueLineTitle: FC<IValueThroughTitle> = props => {
  const { value, title } = props;
  const hasNotInfo = value === '无充电站' || value === '无储能站' || value === '无光伏站';
  return (
    <div className={styles.top}>
      <span className={styles.today_value} title={typeof value === 'string' ? value : formateValuesToFix2(value)}>
        {typeof value === 'string' ? (
          hasNotInfo ? (
            <span className={styles.noInfo}>{value}</span>
          ) : (
            value
          )
        ) : (
          formateValuesToFix2(value)
        )}
      </span>
      <div className={styles.line_through}>
        <span className={styles.line_head}></span>
      </div>
      <span className={styles.elec} title={title}>
        {title}
      </span>
    </div>
  );
};

export default ValueLineTitle;
