import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Select,
  Form,
  Breadcrumb,
  combineURL,
  EllipsisSpan,
  Table,
  useBreadcrumbRoutes,
  Button,
  Modal,
  message,
} from '@maxtropy/components';
import BorderWrapper from '../../../../components/BorderWrapper';

import BsaSteps from '../BsaSteps';
import BsaPageHeader from '../BsaPageHeader';

import { Col, Row, Space } from 'antd';

import styles from './index.module.scss';
import { CaretDownOutlined, CaretUpOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  bindPcs,
  getBsa,
  getBsaBindingPcsList,
  getDraft,
  getFcsByBsaAndHardList,
  getPcsList,
  HardWareVersion,
  multiPcsHardWare,
  multiPcsHardWareWithoutControl,
  saveDraft,
  updatePcsWithMeter,
} from '@/api/bsa';
import { PcsDevice } from '../../../../api/entity';

import { BsaComponentsProps, getNext, getPrevious } from '../../factory';
import { BsaType } from '../../../../api/constans';
import { BSAStatus, DeviceStatus, DeviceStatusDisplay } from '../../../../api/type';
import { padStart } from '../../utils';
import { PCSType } from '../BaseInfo';
import { FCSInfo } from '@/api/strategy';
import { useRequest } from 'ahooks';
import { apiV2BsaMeterBindingPcsPost } from '@maxtropy/dmes-apis-v2';

enum SortEnum {
  UP = 'up',
  DOWN = 'down',
}

interface SortProps {
  up?: boolean;
  down?: boolean;
  onChange?: (v: SortEnum) => void;
}

const Sort: FC<SortProps> = ({ up = true, down = true, onChange }) => (
  <div className={styles.sort}>
    {up && (
      <CaretUpOutlined
        onClick={() => {
          onChange?.(SortEnum.UP);
        }}
      />
    )}
    {down && (
      <CaretDownOutlined
        onClick={() => {
          onChange?.(SortEnum.DOWN);
        }}
      />
    )}
  </div>
);

const columns = [
  {
    title: '所属类目',
    dataIndex: 'typeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '型号',
    dataIndex: 'modelName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '功率',
    dataIndex: 'power',
    ellipsis: { showTitle: true },
    render: (v: string, record: Omit<PcsDevice, 'id'>) => (
      <EllipsisSpan value={v ? `${v ?? ''} ${record.powerUnit ?? ''}` : undefined} />
    ),
  },
  {
    title: '设备状态',
    dataIndex: 'deviceStatus',
    ellipsis: { showTitle: true },
    render: (value: DeviceStatus) => {
      return <EllipsisSpan value={DeviceStatusDisplay[value]} />;
    },
  },
];

const routes = [{ name: `PCS设置` }];

const BsaPcs: FC<BsaComponentsProps> = ({
  disabled = false,
  currentType,
  foucsType,
  typeList,
  pcsStruct,
  status,
  changeType,
  updateFn,
}) => {
  const { id } = useParams<{ id: string }>();
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [selectPcs, setSelectPcs] = useState<Omit<PcsDevice, 'id'>>();

  const routesContext = useBreadcrumbRoutes();

  const [pcsList, setPcsList] = useState<Omit<PcsDevice, 'id'>[]>([]);
  const [fcsByBsaAndHardList, setFcsByBsaAndHardList] = useState<FCSInfo[]>([]);
  const [selectslaveFcs, setSelectslaveFcs] = useState<FCSInfo>();

  const allFcsByBsaAndHardListRef = useRef<FCSInfo[]>([]);
  const singleFcsIdref = useRef<number>();

  const [hardwareVersion, setHardwareVersion] = useState<string>();
  const [softwareVersion, setSoftwareVersion] = useState<string>();
  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      getBsa(id).then(res => {
        setHardwareVersion(res.hardwareVersion);
        setSoftwareVersion(res.fcsSoftware);
        singleFcsIdref.current = res.fcsId;
      });
    }
  }, [id]);

  // 电表列表
  const { data: transformerMeterList } = useRequest(
    () =>
      apiV2BsaMeterBindingPcsPost({ bsaId: +id, type: 1 }).then(res =>
        (res.list ?? []).map(item => ({
          value: item.id,
          label: item.name,
        }))
      ),
    {
      ready: !!id,
      refreshDeps: [id],
    }
  );
  // 并网点列表
  const { data: connectionPointMeterList } = useRequest(
    () =>
      apiV2BsaMeterBindingPcsPost({ bsaId: +id, type: 2 }).then(res =>
        (res.list ?? []).map(item => ({
          value: item.id,
          label: item.name,
        }))
      ),

    {
      ready: !!id,
      refreshDeps: [id],
    }
  );
  const isStackInit = useMemo(() => {
    return (typeList ?? []).includes(BsaType.STACK_INIT);
  }, [typeList]);

  const columnsMasterOrNot = useMemo(() => {
    if (
      hardwareVersion &&
      [...multiPcsHardWare, ...multiPcsHardWareWithoutControl].includes(hardwareVersion as HardWareVersion)
    ) {
      const multisCols = [
        {
          title: '上端变压器电表',
          dataIndex: 'upstreamTransformerMeterId',
          width: 200,
          ellipsis: { showTitle: true },
          render: (v: string, record: any, index: number) => {
            return (
              <Form.Item name={['formList', index, 'upstreamTransformerMeterId']} style={{ marginBottom: 0 }}>
                <Select
                  allowClear
                  optionFilterProp="label"
                  showSearch
                  disabled={disabled}
                  placeholder="请选择"
                  options={transformerMeterList}
                  onChange={v => {
                    if (!v) {
                      form.setFieldValue(['formList', index, 'gridConnectionPointMeterId'], undefined);
                    }
                  }}
                />
              </Form.Item>
            );
          },
        },
        {
          title: '并网点电表',
          dataIndex: 'gridConnectionPointMeterId',
          width: 220,
          ellipsis: { showTitle: true },
          render: (v: string, record: any, index: number) => {
            return (
              <Form.Item
                noStyle
                // shouldUpdate
                dependencies={[['formList', index, 'upstreamTransformerMeterId']]}
              >
                {({ getFieldValue }) => {
                  let value = getFieldValue(['formList', index, 'upstreamTransformerMeterId']);

                  return (
                    <Form.Item
                      name={['formList', index, 'gridConnectionPointMeterId']}
                      style={{ marginBottom: 0 }}
                      validateTrigger={['onSubmit']}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            /**
                             * 1. 校验逻辑
                             * 2. 变压器和并网点--对应
                             * A-C, A-C, B-D, B-D --> 通过
                             * A-C, B-C --> 不通过
                             * A-B, A-C --> 不通过
                             *
                             */
                            let transformerMeterValue = getFieldValue([
                              'formList',
                              index,
                              'upstreamTransformerMeterId',
                            ]);
                            if (!value && !transformerMeterValue) {
                              return Promise.resolve();
                            }

                            let allDatas = getFieldValue('formList').filter(Boolean);

                            let combineIds = allDatas.map(
                              (item: any) => `#${item.upstreamTransformerMeterId}#-#${item.gridConnectionPointMeterId}#`
                            ) as string[];
                            let currentCombineId = `#${transformerMeterValue}#-#${value}#`;

                            let pos = combineIds.indexOf(currentCombineId);
                            combineIds.splice(pos, 1);

                            if (combineIds.includes(currentCombineId)) {
                              return Promise.resolve();
                            }
                            let res = combineIds.some(
                              item =>
                                (value && item.split('-')[1] === `#${value}#`) ||
                                item.split('-')[0] === `#${transformerMeterValue}#`
                            );
                            if (!res) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error(''));
                          },
                        }),
                      ]}
                    >
                      <Select
                        allowClear
                        placeholder={value ? '请选择' : '请先选择上端变压器电表'}
                        disabled={!value || disabled}
                        optionFilterProp="label"
                        showSearch
                        options={connectionPointMeterList}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            );
          },
        },
        {
          title: '软件版本',
          dataIndex: 'fcsSoftware',
          width: 160,
          ellipsis: { showTitle: true },
          render: (v: string, record: Omit<PcsDevice, 'id'>) => (
            <EllipsisSpan value={`${v ?? ''}${v && record.softwareName ? '-' : ''}${record.softwareName ?? ''}`} />
          ),
        },
      ];
      // FM/1.0软件版本没有从控
      if (softwareVersion === 'FM/1.0') {
        return [...multisCols, ...columns];
      }
      return [
        {
          title: '从控FCS',
          dataIndex: 'fcsName',
          ellipsis: { showTitle: true },
          render: (v: string) => <EllipsisSpan value={v} />,
        },
        ...multisCols,
        ...columns,
      ];
    }
    return columns;
  }, [softwareVersion, hardwareVersion, transformerMeterList, connectionPointMeterList, form, disabled, isStackInit]);

  const isComplete = useMemo(() => {
    return (typeList ?? []).includes(BsaType.PCS_INIT);
  }, [typeList]);

  useEffect(() => {
    if (id) {
      if (isAdd) {
        getPcsList(id).then(setPcsList);
      } else {
        setPcsList([]);
      }
    }
  }, [id, isAdd]);

  useEffect(() => {
    if (id) {
      getPcsList(id).then(setPcsList);
    }
  }, [id]);

  // useEffect(() => {
  //   if (id && hardwareVersion) {
  //     getFcsByBsaAndHardList(id, hardwareVersion).then(setFcsByBsaAndHardList);
  //   }
  // }, [id, hardwareVersion]);

  const [data, setData] = useState<Omit<PcsDevice, 'id'>[]>([]);

  const dataIds = useMemo(() => {
    return (data ?? []).map(i => i.deviceId);
  }, [data]);

  // 阵列为单PCS类型时，此处只能添加一个PCS，FM/1.0最多20个pcs 添加按钮置灰
  const addBtnIsDisabled = useMemo(() => {
    let isSigle = pcsStruct === PCSType.SIMPLE && data.length === 1;
    let isFM10 = softwareVersion === 'FM/1.0' && data.length === 20;
    return isSigle || isFM10;
  }, [pcsStruct, data]);

  useEffect(() => {
    // 根据bsaId和硬件版本查询fcs
    if (
      id &&
      hardwareVersion &&
      [...multiPcsHardWare, ...multiPcsHardWareWithoutControl].includes(hardwareVersion as HardWareVersion)
    ) {
      getFcsByBsaAndHardList(id, hardwareVersion).then(res => {
        setFcsByBsaAndHardList(res);
        allFcsByBsaAndHardListRef.current = res;
      });
    }

    if (id) {
      // 已经完成
      if (isComplete) {
        getBsaBindingPcsList(id).then(res => {
          // 剔除原有的从控fcs
          if (multiPcsHardWare.includes(hardwareVersion as HardWareVersion)) {
            if (allFcsByBsaAndHardListRef.current && allFcsByBsaAndHardListRef.current.length !== 0) {
              const newList = allFcsByBsaAndHardListRef.current.filter(
                item => !res.map(i => i.fcsId).includes(item.id)
              );
              setFcsByBsaAndHardList(newList);
            }
          }
          setData(res);
          form.setFieldsValue({
            formList: res,
          });
        });
      } else {
        // 未完成，查看是否有草稿
        getDraft(id).then(draft => {
          const content: Omit<PcsDevice, 'id'>[] = draft.content ? JSON.parse(draft.content) : undefined;
          if (Array.isArray(content)) {
            // 剔除原有的从控fcs
            if (hardwareVersion && multiPcsHardWare.includes(hardwareVersion as HardWareVersion)) {
              if (allFcsByBsaAndHardListRef.current && allFcsByBsaAndHardListRef.current.length !== 0) {
                const newList = allFcsByBsaAndHardListRef.current.filter(
                  item => !content.map(i => i.fcsId).includes(item.id)
                );
                setFcsByBsaAndHardList(newList);
              }
            }
            setData(content);
            form.setFieldsValue({ formList: content });
          }
        });
      }
    }
  }, [id, isComplete, hardwareVersion, form]);

  const selectPcsList = useMemo(() => {
    return (pcsList ?? []).filter(i => !(dataIds ?? []).includes(i.deviceId));
  }, [pcsList, dataIds]);

  const sortData = (index: number, sort: SortEnum) => {
    if (sort === SortEnum.UP) {
      if (index > 0) {
        setData([
          ...data.slice(0, index - 1),
          ...data.slice(index, index + 1),
          ...data.slice(index - 1, index),
          ...data.slice(index + 1),
        ]);
      }
    } else {
      if (index < data.length - 1) {
        setData([
          ...(index === 0 ? [] : data.slice(0, index)),
          ...data.slice(index + 1, index + 2),
          ...data.slice(index, index + 1),
          ...data.slice(index + 2),
        ]);
      }
    }
  };

  const tableFormValidate = () => {
    return form
      .validateFields()
      .catch(_ => {
        Modal.warning({
          title: '相同的上端变压器电表所关联的PCS，必须选择相同的并网点电表，请修改。',
          onOk: () => {},
          okText: '好的',
        });
        return undefined;
      })
      .then(res => res.formList);
  };
  const onDelete = (index: number, row: Omit<PcsDevice, 'id'>) => {
    let temp = fcsByBsaAndHardList.find(item => item.id === data[index].fcsId);
    if (temp) {
      temp.selected = false;
      setFcsByBsaAndHardList([...fcsByBsaAndHardList]);
    } else {
      // fcsByBsaAndHardList为空时, 删除列表数据要回填到下拉列表里面
      fcsByBsaAndHardList.push({
        id: row.fcsId!,
        name: row.fcsName!,
        softwareVersion: row.fcsSoftware,
        softwareName: row.softwareName,
      });

      setFcsByBsaAndHardList([...fcsByBsaAndHardList]);
    }
    setData([...data.slice(0, index), ...data.slice(index + 1)]);
  };

  const buildColumns = [
    {
      title: '名称',
      dataIndex: 'deviceName',
      render: (v: string, record: Omit<PcsDevice, 'id'>, index: number) => (
        <div className={styles.sortTd}>
          <EllipsisSpan value={v} />
          {!disabled && !isStackInit && (
            <Sort
              up={index !== 0}
              down={index < (data ?? []).length - 1}
              onChange={(sort: SortEnum) => {
                sortData(index, sort);
              }}
            />
          )}
        </div>
      ),
    },
    {
      title: 'PCS编号',
      dataIndex: 'sequence',
      render: (v: undefined, record: Omit<PcsDevice, 'id'>, index: number) => (
        <EllipsisSpan value={padStart(index + 1)} />
      ),
    },
    ...columnsMasterOrNot,
    {
      title: '操作',
      width: 180,
      fixed: 'right' as const,
      render: (v: undefined, record: Omit<PcsDevice, 'id'>, index: number) => (
        <Space>
          {!disabled && !isStackInit && (
            <a
              href="#/"
              onClick={() => {
                onDelete(index, record);
              }}
            >
              删除
            </a>
          )}
          <a
            target="_blank"
            href={combineURL(window.IOTPLATFORMORIGIN, `/device/manage/device/${record.deviceId}/detail`)}
            rel="noreferrer"
          >
            查看详情
          </a>
        </Space>
      ),
    },
  ];

  const onPrevious = () => {
    if (Array.isArray(typeList) && foucsType) {
      const type = getPrevious(foucsType, typeList);
      if (type) {
        changeType?.(type);
      }
    }
  };

  const onNext = () => {
    const type = getNext(foucsType!, typeList ?? [], currentType);
    if (type) {
      changeType?.(type);
    }
  };

  const onSaveDraft = async () => {
    let res = form.getFieldsValue();
    let formData = res?.formList ?? [];
    data.forEach((v, i) => {
      v.upstreamTransformerMeterId = formData[i]?.upstreamTransformerMeterId;
      v.gridConnectionPointMeterId = formData[i]?.gridConnectionPointMeterId;
    });
    saveDraft({
      bsaId: Number(id),
      content: JSON.stringify(data),
    }).then(() => {
      Modal.success({
        content: '保存成功',
      });
    });
  };

  const onFinish = async () => {
    if (!id) return;
    if (disabled) {
      onNext();
      return;
    }
    if (data.length === 0) {
      Modal.warning({
        title: '请先选择PCS',
      });
      return;
    }
    let res = await tableFormValidate();
    if ([...multiPcsHardWare, ...multiPcsHardWareWithoutControl].includes(hardwareVersion as HardWareVersion)) {
      if (!res) return;
    }
    let params = {
      bsaId: Number(id),
      pcsList: data.map((v, i) => ({
        deviceId: v.deviceId,
        sequence: i,
        fcsId: v.fcsId ?? singleFcsIdref.current,
        upstreamTransformerMeterId: res?.[i]?.upstreamTransformerMeterId,
        gridConnectionPointMeterId: res?.[i]?.gridConnectionPointMeterId,
      })),
    };
    if (isStackInit) {
      updatePcsWithMeter(params).then(res => {
        onNext();
      });
    } else {
      bindPcs(params).then(({ result: res }) => {
        if (res) {
          if (isComplete) {
            onNext();
          } else {
            // 更新Bsa
            updateFn?.();
          }
        }
      });
    }
  };

  return (
    <>
      <div className="page">
        <div className="page__header">
          <Breadcrumb routes={[...(routesContext?.routes ?? []), ...routes]} />
        </div>
        <BorderWrapper>
          <div className="page__content" style={{ minHeight: 'calc(100vh - 128px)' }}>
            <BsaPageHeader
              title="PCS设置"
              extraContent={
                <>
                  <Button type="primary" onClick={onPrevious}>
                    上一步
                  </Button>
                  <Button type="primary" onClick={onFinish}>
                    下一步
                  </Button>
                  {!disabled && !isComplete && status !== BSAStatus.INVALID && (
                    <Button type="primary" onClick={onSaveDraft}>
                      存草稿
                    </Button>
                  )}
                </>
              }
            >
              <BsaSteps typeList={typeList} foucsType={foucsType} />
            </BsaPageHeader>
            {!disabled && !isStackInit && (
              <div className={styles.addPCS}>
                <Button
                  type="primary"
                  disabled={addBtnIsDisabled}
                  onClick={() => {
                    setIsAdd(true);
                  }}
                >
                  +增加PCS
                </Button>
              </div>
            )}
            <Space direction="vertical">
              <Space size={20}>
                <div>PCS数采配置</div>
                <div style={{ color: 'red' }}>请注意相同的上端变压器电表所关联的PCS，必须选择相同的并网点电表</div>
                {/* <Button onClick={() => form.validateFields().then(res => console.log(res))}>哈哈哈哈</Button> */}
              </Space>
              <Form form={form}>
                <Table
                  rowKey="deviceId"
                  columns={buildColumns}
                  dataSource={data}
                  rowClassName={(record: Omit<PcsDevice, 'id'>) => {
                    return record.deviceStatus === DeviceStatus.INVALID ? 'device-invalid' : '';
                  }}
                />
              </Form>
            </Space>
          </div>
        </BorderWrapper>
      </div>
      <Modal
        title="关联PCS"
        width={700}
        open={isAdd}
        onCancel={() => {
          setSelectPcs(undefined);
          setSelectslaveFcs(undefined);
          setIsAdd(false);
        }}
        onOk={() => {
          if (hardwareVersion && multiPcsHardWare.includes(hardwareVersion as HardWareVersion)) {
            if (selectslaveFcs) {
              let findInfo = fcsByBsaAndHardList.find(item => item.id === selectslaveFcs.id);
              if (findInfo) {
                findInfo.selected = true;
                setFcsByBsaAndHardList([...fcsByBsaAndHardList]);
              }
            } else {
              message.error('请选择PCS所属从控FCS');
              return;
            }
          }

          if (selectPcs) {
            // 如果选择了从控fcs，那么排除在外
            const findIndex = data.findIndex(i => i.deviceId === selectPcs.deviceId);
            if (findIndex === -1) {
              if (selectslaveFcs) {
                setData([
                  ...data,
                  {
                    ...selectPcs,
                    fcsId: selectslaveFcs?.id,
                    fcsName: selectslaveFcs.name,
                    fcsSoftware: selectslaveFcs.softwareVersion,
                    softwareName: selectslaveFcs.softwareName,
                  },
                ]);
              } else {
                setData([...data, selectPcs]);
              }
              setSelectPcs(undefined);
              setSelectslaveFcs(undefined);
              setIsAdd(false);
            }
          }
        }}
      >
        <Space direction="vertical" style={{ width: '100%', height: 150 }}>
          <Space>
            关联PCS
            <a
              href={combineURL(window.IOTPLATFORMORIGIN, `/device/manage/device/create`)}
              target="_blank"
              rel="noreferrer"
            >
              <InfoCircleOutlined />
              找不到？点这里去创建PCS
            </a>
          </Space>
          <Select
            style={{ width: '80%' }}
            onChange={(v: number) => {
              const pcs = selectPcsList.find(i => i.deviceId === v);
              if (pcs) {
                setSelectPcs(pcs);
              }
            }}
            value={selectPcs?.deviceId}
          >
            {(selectPcsList ?? []).map(i => (
              <Select.Option
                key={i.deviceId}
                value={i.deviceId}
              >{`${i.deviceName} ${i.typeName} ${i.modelName}`}</Select.Option>
            ))}
          </Select>
          <Row>
            <Col span={12}>没有配置数采配置的PCS在这里是选不到的</Col>
            <Col span={12}>
              <a
                href={combineURL(window.IOTPLATFORMORIGIN, `/device/manage/device/daq-deployment`)}
                target="_blank"
                rel="noreferrer"
              >
                <InfoCircleOutlined />
                还没配置？点这里去创建PCS数采配置
              </a>
            </Col>
          </Row>
          {!multiPcsHardWare.includes(hardwareVersion as HardWareVersion) ? null : (
            <Row>
              <Col span={24}>
                <Form.Item
                  label="PCS所属从控FCS"
                  extra={`软件版本：${
                    selectslaveFcs
                      ? `${selectslaveFcs?.softwareVersion ?? ''}-${selectslaveFcs?.softwareName ?? ''}`
                      : '--'
                  }`}
                  required
                  rules={[{ required: true, message: '请选择从控FCS' }]}
                >
                  <Select
                    placeholder="请选择"
                    style={{ width: 300 }}
                    showSearch
                    optionFilterProp="label"
                    onChange={(v: number) => {
                      const fcs = fcsByBsaAndHardList.find(i => i.id === v);
                      if (fcs) {
                        setSelectslaveFcs(fcs);
                      }
                    }}
                    value={selectslaveFcs?.id}
                    options={fcsByBsaAndHardList
                      .filter(item => !item.selected)
                      .map(item => ({
                        label: item.name,
                        value: item.id,
                      }))}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Space>
      </Modal>
    </>
  );
};

export default BsaPcs;
