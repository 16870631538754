import { useBreadcrumbRoutes, useUpdate, Wrapper } from '@maxtropy/components';
import styles from './index.module.scss';
import { useRequest } from 'ahooks';
import dayjs, { Dayjs } from 'dayjs';
import TopNav from './TopNav';
import DrawBox, { DrawBoxRef } from './DrawBox';
import { Portal } from '@antv/x6-react-shape';
import { createContext, useEffect, useRef, useState } from 'react';
import { Graph, Node } from '@antv/x6';
import { cancelFullscreen, goFullscreen, resetAllEdges } from './utils';

import LeftTopInfo from './LeftTopInfo';
import AlarmDrawer from './AlarmDrawer';
import CurveDrawer from './CurveDrawer';
import { apiV2PvOutlineAlarmDeviceListPost, apiV2PvStationDetailPost } from '@maxtropy/dmes-apis-v2';
import { NodeTreeData } from './const';

interface GraphContextProps {
  graph?: Graph;
  setGraph?: React.Dispatch<React.SetStateAction<Graph | undefined>>;
  currentNodeInfo?: NodeTreeData;
  setCurrentNodeInfo?: React.Dispatch<React.SetStateAction<NodeTreeData | undefined>>;
  alarmDeviceList?: number[];
}
interface PvInfoContextProps {
  currentPvId?: number;
  setCurrentPvId?: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const X6ReactPortalProvider = Portal.getProvider();
export const PollingInterval = 2 * 60 * 1000;
export const GraphContext = createContext<GraphContextProps>({});
export const PvInfoContext = createContext<PvInfoContextProps>({});

const PvRunningDashbord = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [currentNodeInfo, setCurrentNodeInfo] = useState<NodeTreeData>();
  const [currentPvId, setCurrentPvId] = useState<number>();
  const [alarmDrawerOpen, setAlarmDrawerOpen] = useState(false); // 报警抽屉的弹窗
  const [curveDrawerOpen, setCurveDrawerOpen] = useState(false); // 运行曲线抽屉的弹窗
  const outerDivRef = useRef<HTMLDivElement>(null);
  const drawBoxRef = useRef<DrawBoxRef>(null);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false); // 是否全屏

  const { data: alarmDeviceList } = useRequest(
    () => {
      return apiV2PvOutlineAlarmDeviceListPost({ id: currentPvId }).then(res => {
        // 重置边的颜色
        resetAllEdges(drawBoxRef.current?.graph);
        return res?.list ?? [];
      });
    },
    {
      ready: !!currentPvId,
      refreshDeps: [currentPvId],
      pollingInterval: PollingInterval,
      pollingWhenHidden: false,
    }
  );

  useEffect(() => {
    setCurrentNodeInfo(undefined);
  }, [currentPvId]);

  const handleFullScreen = () => {
    setIsFullScreen(document.fullscreenElement !== null);
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreen);
    return () => document.removeEventListener('fullscreenchange', handleFullScreen);
  }, []);

  const fullBtn = () => {
    if (outerDivRef.current) {
      isFullScreen ? cancelFullscreen() : goFullscreen(outerDivRef.current);
    }
  };

  // 查看光伏站详情，1.布局图，没有图片不跳转给提示，2.报警页面带资产跳转
  const { data } = useRequest(
    () =>
      apiV2PvStationDetailPost({
        id: currentPvId,
      }).then(res => {
        return res;
      }),
    {
      ready: !!currentPvId,
      refreshDeps: [currentPvId],
    }
  );

  return (
    <>
      <Wrapper routes={breadcrumbRoutes?.routes} className={styles.wrapper}>
        <div
          className={styles.outer_box}
          ref={outerDivRef}
          id="full_screen_box"
          style={{ padding: isFullScreen ? 20 : 0 }}
        >
          <PvInfoContext.Provider value={{ currentPvId, setCurrentPvId }}>
            <div className={styles.nav_box}>
              <TopNav fullBtn={fullBtn} isFullScreen={isFullScreen} data={data} />
            </div>

            <div className={styles.draw_box}>
              <div className={styles.leftTop_info_box}>
                <LeftTopInfo
                  addressCode={data?.adcode}
                  alarmCounts={alarmDeviceList?.length ?? 0}
                  setAlarmDrawerOpen={setAlarmDrawerOpen}
                  setCurveDrawerOpen={setCurveDrawerOpen}
                />
              </div>
              <GraphContext.Provider value={{ currentNodeInfo, setCurrentNodeInfo, alarmDeviceList }}>
                <X6ReactPortalProvider />
              </GraphContext.Provider>
              <DrawBox ref={drawBoxRef} getCurrentNodeInfo={setCurrentNodeInfo} />
            </div>
          </PvInfoContext.Provider>
        </div>
      </Wrapper>
      {/* 报警抽屉 */}
      <AlarmDrawer
        setDrawerOpen={setAlarmDrawerOpen}
        drawerOpen={alarmDrawerOpen}
        currentPvId={currentPvId}
        data={data}
      />
      {/* 运行曲线抽屉 */}
      <CurveDrawer setDrawerOpen={setCurveDrawerOpen} drawerOpen={curveDrawerOpen} currentPvId={currentPvId} />
    </>
  );
};

export default PvRunningDashbord;
