import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

const ImagePage: FC = () => {
  const [searchParams] = useSearchParams();
  const imageUrl = searchParams.get('imageUrl');
  return (
    <>
      {imageUrl ? (
        <div>
          <img
            style={{
              width: '100%',
              objectFit: 'contain',
            }}
            src={imageUrl}
            alt=""
          />
        </div>
      ) : null}
    </>
  );
};

export default ImagePage;
