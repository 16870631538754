import {
  BathCircuitStatisticsResponse,
  v2GetCircuitStatistics,
  v2GetCircuitStatisticsBatch,
  StatisticsPartition,
} from '@/api/circuit';
import { DataType } from '@/api/const';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

const useRequestDataUpdate = (
  currentCircuitId: number,
  currentChildCircuitIds: number[],
  currentCircuitHasOu: boolean,
  currentChildCircuitHasOu: boolean,
  openTimer?: (isOver: boolean) => void,
  timerState?: number
) => {
  const currentSplitTime = useMemo(() => {
    if (timerState) {
    }
    return Math.floor(dayjs().minute() / 15) * 15;
  }, [timerState]);

  // 本月时间范围
  const [currentMonthFrom, currentMonthTo] = useMemo(() => {
    let splitRange = dayjs().isBetween(dayjs().startOf('month'), dayjs().startOf('month').add(15, 'minute'));
    if (splitRange) {
      return [
        dayjs().subtract(1, 'month').startOf('month').valueOf(),
        dayjs().subtract(1, 'month').endOf('month').valueOf(),
      ];
    } else {
      return [dayjs().startOf('month').valueOf(), dayjs().startOf('hour').add(currentSplitTime, 'minute').valueOf()];
    }
  }, [currentSplitTime]);

  // 上月时间范围
  const [lastMonthFrom, lastMonthTo] = useMemo(() => {
    return [
      dayjs().subtract(1, 'month').startOf('month').valueOf(),
      dayjs().subtract(1, 'month').startOf('hour').add(currentSplitTime, 'minute').valueOf(),
    ];
  }, [currentSplitTime]);
  const { data, isLoading } = useQuery(
    [
      'dataList',
      currentMonthFrom,
      currentMonthTo,
      lastMonthFrom,
      lastMonthTo,
      currentCircuitId,
      currentChildCircuitIds,
      currentCircuitHasOu,
      currentChildCircuitHasOu,
    ],
    async () => {
      if (
        currentCircuitId &&
        currentMonthFrom &&
        currentMonthTo &&
        lastMonthFrom &&
        lastMonthTo &&
        currentCircuitHasOu
      ) {
        // 本月用电量
        const promiseCurr = v2GetCircuitStatistics({
          circuitId: currentCircuitId,
          dataType: DataType.EP,
          timeResolution: StatisticsPartition.MINUTE_15,
          from: currentMonthFrom,
          to: currentMonthTo,
        });
        // 环比用电量(上月同期)
        const promiseLast = v2GetCircuitStatistics({
          circuitId: currentCircuitId,
          dataType: DataType.EP,
          timeResolution: StatisticsPartition.MINUTE_15,
          from: lastMonthFrom,
          to: lastMonthTo,
        });
        // 下游回路用电情况
        const promiseBath =
          currentChildCircuitIds.length > 0 && currentChildCircuitHasOu
            ? v2GetCircuitStatisticsBatch({
                circuitIds: currentChildCircuitIds.toString(),
                dataType: DataType.EP,
                // timeResolution: StatisticsPartition.MINUTE_15,
                from: currentMonthFrom,
                to: currentMonthTo,
              })
            : Promise.resolve([] as BathCircuitStatisticsResponse[]);
        openTimer?.(false);
        let res = await Promise.all([promiseCurr, promiseLast, promiseBath])
          .then(([currData, lastData, bathData]) => {
            let currVal = currData[0]?.data,
              lastVal = lastData[0]?.data;
            let currSum: number | undefined, lastSum: number | undefined;
            if (currVal) {
              let tempData = currVal.filter(item => item.value || item.value === 0);
              if (tempData && tempData.length > 0) {
                currSum = tempData.reduce((sum, obj) => (sum += obj.value!), 0);
              }
            }
            if (lastVal) {
              let tempData = lastVal.filter(item => item.value || item.value === 0);
              if (tempData && tempData.length > 0) {
                lastSum = tempData.reduce((sum, obj) => (sum += obj.value!), 0);
              }
            }
            return {
              currentMonthElec: currSum,
              lastMonthElec: lastSum,
              currentBathData: bathData.sort((a, b) => b.sum - a.sum),
            };
          })
          .catch(() => undefined);
        openTimer?.(true);
        return res;
      }
    }
  );

  return {
    currentMonthElec: data?.currentMonthElec,
    lastMonthElec: data?.lastMonthElec,
    currentBathData: data?.currentBathData ?? [],
    isLoading,
  };
};

export default useRequestDataUpdate;
