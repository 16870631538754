import { FC, ReactNode } from 'react';
import Icon from '@ant-design/icons';
import OnlineSvg from './online';
import OfflineSvg from './offline';
import ChargeSvg from './charge';
import DisChargeSvg from './discharge';
import StandbySvg from './standby';
import styles from './index.module.scss';

interface CustomTagProps {
  color: string;
  backgroundColor: string;
  borderColor: string;
  icon?: ReactNode;
  children?: ReactNode;
}

const CustomTag: FC<CustomTagProps> = ({ icon, children, color, backgroundColor, borderColor }) => {
  return (
    <div className={styles.tag} style={{ color: color, backgroundColor: backgroundColor, borderColor: borderColor }}>
      {icon}
      <span style={{ marginLeft: icon ? 7 : 0 }}>{children}</span>
    </div>
  );
};

export const OnlineTag = () => (
  <CustomTag
    icon={<Icon component={OnlineSvg} />}
    backgroundColor="#162312"
    color="#49AA19"
    borderColor="rgba(89,215,68,0.3)"
  >
    正常
  </CustomTag>
);

export const OfflineTag = () => (
  <CustomTag
    icon={<Icon component={OfflineSvg} />}
    backgroundColor="#2A1215"
    color="#E9494B"
    borderColor="rgba(233,73,75,0.3)"
  >
    离线
  </CustomTag>
);

export const ChargeTag = () => (
  <CustomTag
    icon={<Icon component={ChargeSvg} />}
    backgroundColor="#162312"
    color="#57FB8B"
    borderColor="rgba(87,251,139,0.3)"
  >
    充电
  </CustomTag>
);

export const DisChargeTag = () => (
  <CustomTag
    icon={<Icon component={DisChargeSvg} />}
    backgroundColor="#0E2528"
    color="#12C6FF"
    borderColor="rgba(18,198,255,0.3)"
  >
    放电
  </CustomTag>
);

export const StandByTag = () => (
  <CustomTag
    icon={<Icon component={StandbySvg} />}
    backgroundColor="rgba(255,255,255,0.08)"
    color="rgba(255,255,255,0.8)"
    borderColor="rgba(255,255,255,0.2)"
  >
    待机
  </CustomTag>
);
