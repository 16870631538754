import React, { Key, useEffect, useMemo, useState } from 'react';
import { Col, Row, Space } from 'antd';
import { Select, Button, Form, Modal, Radio } from '@maxtropy/components';
import { useQuery } from 'react-query';
import { fetchVersionList, getBsaListBySystemVersion } from '@/api/strategy';
import { FormStatus } from '@/types';
import { StrategyTemplate } from '../types';
import ShowInput from '@/components/ShowInput';

interface SelectVersionModalProps {
  status: FormStatus;
  onCancel: () => void;
  row?: StrategyTemplate;
  onOK: (version: number, versionName: string, bsaId: Key, name: string) => void;
}

const SelectVersionModal: React.FC<SelectVersionModalProps> = ({ onCancel, onOK, status, row }) => {
  const [form] = Form.useForm();
  const [version, setVersion] = useState<number>();
  const [bsaId, setBsaId] = useState<number>();

  const { data: versions } = useQuery('versionList', fetchVersionList);

  const sysVersion = useMemo(() => {
    return versions?.find(item => item.id === version)?.version;
  }, [versions, version]);
  const sysVersionName = useMemo(() => {
    return versions?.find(item => item.id === version)?.name;
  }, [versions, version]);

  const { data: bsaList, isLoading: isBSALoading } = useQuery(
    ['bsaList', sysVersion],
    () => getBsaListBySystemVersion(sysVersion),
    {
      enabled: !!sysVersion,
    }
  );
  const bsaName = useMemo(() => {
    return bsaList?.find(item => item.id === bsaId)?.name;
  }, [bsaId, bsaList]);
  const confirmBtn = () => {
    form.validateFields().then(values => {
      onOK(values.version, `${sysVersion ?? ''}-${sysVersionName ?? ''}`, values.bsaId, bsaName ?? '');
    });
  };
  useEffect(() => {
    if (row?.sysVersionId) {
      setVersion(+row.sysVersionId);
    }
  }, [row, form, bsaList]);

  const onRadioChange = (v: number) => {
    setVersion(v);
    form.setFieldsValue({
      bsaId: undefined,
    });
    setBsaId(undefined);
  };

  return (
    <Modal
      open
      title={status === FormStatus.Create ? '选择策略模板版本' : '复制策略模板'}
      maskClosable={false}
      contentClassName="modal-form-content"
      onCancel={onCancel}
      footer={
        <Space>
          <Button onClick={onCancel}>取消</Button>
          <Button type="primary" onClick={confirmBtn}>
            确定
          </Button>
        </Space>
      }
    >
      <Form form={form}>
        <Row>
          <Col span={24}>
            {status === FormStatus.Create && (
              <Form.Item label="策略类型版本" name="version" rules={[{ required: true, message: '请选择策略版本' }]}>
                <Radio.Group onChange={e => onRadioChange(e.target.value)}>
                  <Space size={8} direction="vertical">
                    {versions?.map(item => (
                      <Radio key={item.id} value={item.id}>
                        {`${item.version}-${item.name}`}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
            )}
            {status === FormStatus.Copy && (
              <Form.Item label="策略类型版本">
                <ShowInput value={sysVersion} />
              </Form.Item>
            )}
          </Col>
          <Col span={24}>
            <Form.Item
              label="储能阵列"
              name="bsaId"
              rules={[{ required: true, message: '请选择储能阵列' }]}
              shouldUpdate
            >
              <Select
                loading={isBSALoading}
                onChange={setBsaId}
                placeholder="请选择"
                disabled={status === FormStatus.Create && !version}
              >
                {bsaList?.map(item => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default SelectVersionModal;
