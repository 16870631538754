import { FC, useContext, useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import styles from './index.module.scss';
import { DataType } from '@/api/const';
import { v2GetCircuitStatistics, StatisticsPartition } from '@/api/circuit';
import { FormatterItem } from '@/pages/Statistics/components/Chart/helper';
import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash-es';
import { DatePicker, Empty } from '@maxtropy/components';
import { ScaleContext } from '@/pages/InComingOverview';

interface Iprops {
  currentCircuit?: number; // 回路id
  openTimer: (isOver: boolean) => void;
  timerState?: number;
}

interface LineChartData {
  time: string;
  value: number | null;
}

interface QueryDate {
  from: number;
  to: number;
}

const DailyPowerCurve: FC<Iprops> = ({ currentCircuit, openTimer, timerState }) => {
  const scale = useContext(ScaleContext);

  const [chartData, setChartData] = useState<LineChartData[]>([]);

  const [date, setDate] = useState<QueryDate>({
    from: dayjs().startOf('day').valueOf(),
    to: dayjs().valueOf(),
  });

  const onDateChange = (from: number, to: number) => {
    setDate({ from, to });
  };

  const disabledDate = (current: Dayjs) => {
    return (
      current &&
      (current.valueOf() > dayjs().endOf('month').valueOf() || current.valueOf() <= dayjs().startOf('month').valueOf())
    );
  };

  useEffect(() => {
    (async () => {
      if (currentCircuit) {
        openTimer?.(false);
        const response = await v2GetCircuitStatistics({
          circuitId: currentCircuit,
          dataType: DataType.P,
          timeResolution: StatisticsPartition.MINUTE_15,
          ...date,
        })
          .then(res => res)
          .catch(_ => []);
        openTimer?.(true);
        let data = response[0]?.data?.map(m => {
          return {
            time: dayjs(m.time).format('HH:mm'),
            value: !isNil(m.value) ? Number(m.value.toFixed(2)) : null,
          };
        });
        setChartData(data);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCircuit, date, timerState]);

  const getChartOption = () => {
    return {
      grid: {
        left: 50,
        right: 20,
        top: 50,
        bottom: 20,
      },
      tooltip: {
        trigger: 'axis',
        formatter(items: FormatterItem[]) {
          const { name, seriesName, value } = items[0];
          return `${name}<br>${seriesName}：${value ?? '--'} kW`;
        },
      },
      xAxis: {
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        type: 'category',
        data: chartData.map(m => m.time),
        axisLabel: {},
      },
      yAxis: {
        type: 'value',
        name: 'kW',
        nameTextStyle: {
          fontSize: 14,
          align: 'right',
        },
        nameGap: 30,
        splitLine: {
          lineStyle: {
            color: '#FFFFFF30',
          },
        },
      },
      legend: {
        show: false,
      },
      series: [
        {
          smooth: true,
          symbol: 'none',
          type: 'line',
          color: '#FFD100',
          name: '功率',
          data: chartData.map(m => m.value),
        },
      ],
    };
  };

  return (
    <div className={styles.picker_pos}>
      <div className={styles.picker_box}>
        选择日期：
        <DatePicker
          format="YYYY-MM-DD"
          allowClear={false}
          showToday={false}
          defaultValue={dayjs()}
          disabledDate={disabledDate}
          onChange={value => {
            const from = value!.startOf('day').valueOf();
            const to = value!.endOf('day').valueOf();
            onDateChange(from, to);
          }}
        />
      </div>
      {chartData?.length ? (
        <ReactEcharts
          option={getChartOption()}
          style={{ height: '98%', zoom: 1 / scale }}
          notMerge
          lazyUpdate={false}
        />
      ) : (
        <Empty style={{ padding: 60 }} description={'暂无数据'} />
      )}
    </div>
  );
};

export default DailyPowerCurve;
