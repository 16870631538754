import { ComponentType } from 'react';
import Battery from './TabContents/Battery';
import Meter from './TabContents/Meter';
import Pcs from './TabContents/PCS';
import Temper from './TabContents/Temper';

import { ComponentsProps, RealTimeDataType } from './type';

export const CurrentComponentByType: Partial<Record<RealTimeDataType, ComponentType<ComponentsProps>>> = {
  [RealTimeDataType.METER]: Meter,
  [RealTimeDataType.PCS]: Pcs,
  [RealTimeDataType.BMS]: Pcs,
  [RealTimeDataType.BATTERY]: Battery,
  [RealTimeDataType.TEMPER]: Temper,
};
