import React, { useEffect, useMemo, useState } from 'react';
import {
  Upload,
  Button,
  Form,
  message,
  useBreadcrumbRoutes,
  Wrapper,
  FormTitle,
  SubContent,
} from '@maxtropy/components';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import AlarmRulesConfig from './AlarmRulesConfig';
import DataCardConfig, { tabsConfig } from './DataCardConfig';
import BasicInfoConfig from './BasicInfoConfig';
import {
  BsaConfig,
  BsaDataConfig,
  BsaDataConfigType,
  BsaDeviceResponse,
  BsaFcsList,
  getBsaConfig,
  getBsaDevices,
  saveBsaConfig,
} from '../../api/bsaConfig';
import { Col, Image, Row, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import defaultImg from '@/assets/images/defaultImg.png';
import defaultLogo from '@/assets/images/defaultLogo.png';
import WiringDiagram from './WiringDiagram';
import { HardWareVersion, hasLineConfig, multiPcsHardWare } from '@/api/bsa';

const routes = [{ name: '就地端配置' }];

export interface BsaConfigScreenProps {}

const BsaConfigScreen: React.FC<BsaConfigScreenProps> = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const { bsaId } = useParams<{ bsaId: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [bsaConfig, setBsaConfig] = useState<BsaConfig>();
  const [devices, setDevices] = useState<BsaDeviceResponse>();
  const [errors, setErrors] = useState<any>();
  // 硬件结构版本
  const [hardwareVersion, setHardwareVersion] = useState<HardWareVersion>();
  const [wiringDiagram, setWiringDiagram] = useState<BsaFcsList[]>([]);

  const routesContext = useBreadcrumbRoutes();

  const filteredTabsConfig = useMemo(() => {
    // FB/1.1版本不显示空调和消防数据
    if (bsaConfig?.fcsSoftwareVersion !== 'FB/1.1') return tabsConfig;
    return tabsConfig.slice(2);
  }, [bsaConfig]);
  // 就地端接线图显示条件, 主控FCS软件版本=FC/1.2时，把就地端配置中的就地端接线图配置项隐藏
  const showLineConfig = useMemo(() => {
    return (
      hardwareVersion &&
      multiPcsHardWare.includes(hardwareVersion) &&
      hasLineConfig.includes(bsaConfig?.fcsSoftwareVersion!)
    );
  }, [bsaConfig, hardwareVersion]);
  useEffect(() => {
    if (!bsaConfig || !devices) {
      return;
    }
    setErrors((errors: any) => {
      const newErrors = { ...errors };
      filteredTabsConfig.forEach(tab => {
        const key = tab.key + 'Config';
        const errorKey = tab.key + 'Error';
        const config = bsaConfig[key as keyof BsaConfig] as BsaDataConfig | undefined;
        const configDevices = devices[(tab.key + 'Devices') as keyof BsaDeviceResponse];
        if (!config) {
          return;
        }
        if (!newErrors[errorKey]) {
          newErrors[errorKey] = {};
        }
        if (config.display && !config.name) {
          newErrors[errorKey].name = '请输入卡片名称';
        } else if (config.name && config.name.length > 12) {
          newErrors[errorKey].name = '最多12个字符';
        } else {
          newErrors[errorKey].name = undefined;
        }
        if (config.display === undefined) {
          newErrors[errorKey].display = '请选择';
        } else {
          newErrors[errorKey].display = undefined;
        }
        if (config.display && tab.canByDevice && config.configType === undefined) {
          newErrors[errorKey].configType = '请选择';
        } else {
          newErrors[errorKey].configType = undefined;
        }

        configDevices.forEach(device => {
          const deviceConfig = config.deviceItems?.find(item => item.deviceId === device.id);
          if (!deviceConfig && config.display && config.configType === BsaDataConfigType.BY_DEVICE) {
            newErrors[errorKey][device.id] = '请选择';
          } else {
            newErrors[errorKey][device.id] = undefined;
          }
        });
      });
      return newErrors;
    });
  }, [bsaConfig, devices]);

  const hasError = useMemo(() => {
    return Object.values(Object.values(errors || {}) || {}).some((o: any) => Object.values(o).some(v => !!v));
  }, [errors]);

  useEffect(() => {
    setIsLoading(true);
    getBsaDevices(+bsaId)
      .then(devices => {
        setDevices(devices);
        return getBsaConfig(+bsaId);
      })
      .then(res => {
        setBsaConfig(res);
        if (res) {
          setWiringDiagram(res.bsaFcsList!);
          setHardwareVersion(res.hardwareVersion);
          form.setFieldsValue({
            logoIcon: res.logoIcon,
            powerOnIcon: res.powerOnIcon,
          });
        }
      })
      .finally(() => setIsLoading(false));
  }, [bsaId, form]);

  const onSave = () => {
    if (!bsaConfig || hasError) {
      return message.error('请检查配置项');
    }

    setIsLoading(true);
    saveBsaConfig({
      ...bsaConfig,
      ...form.getFieldsValue(),
      triggerStopRules: bsaConfig.triggerStopRules?.map(item => item.id),
      triggerProtectionRules: bsaConfig.triggerProtectionRules?.map(item => item.id),
      bsaFcsConfig: wiringDiagram.map(i => {
        return { fcsId: i.fcsId, wiringLogo: i.wiringLogo ? i.wiringLogo : null };
      }),
    })
      .then(() => {
        message.success('保存成功');
        navigate(`/energy-storage/basic/bsa`, { replace: true });
      })
      .finally(() => setIsLoading(false));
  };

  const onCancel = () => {
    navigate(`/energy-storage/basic/bsa`, { replace: true });
  };

  const onWiringDiagramChange = (value: BsaFcsList[]) => {
    setWiringDiagram(value);
  };

  return (
    <Wrapper isLoading={isLoading} routes={[...(routesContext?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title="就地端配置" />
      <SubContent title="基础信息">
        <BasicInfoConfig config={bsaConfig}></BasicInfoConfig>
      </SubContent>

      <Form layout={'vertical'} form={form}>
        <SubContent title="报警规则标签配置">
          <AlarmRulesConfig onChange={setBsaConfig} config={bsaConfig} />
        </SubContent>
        <SubContent title="就地端UI设置">
          <UIsetImg></UIsetImg>
        </SubContent>

        {!multiPcsHardWare.includes(hardwareVersion!) && (
          <SubContent title="就地端数据展示设置">
            {bsaConfig && devices && (
              <DataCardConfig errors={errors} config={bsaConfig} devices={devices} onChange={setBsaConfig} />
            )}
          </SubContent>
        )}
        {/* {showLineConfig && ( */}
        <SubContent title="就地端接线图">
          <WiringDiagram onChange={onWiringDiagramChange} picValues={wiringDiagram} />
        </SubContent>
        {/* // )} */}
      </Form>

      <Space className={'sticky-footer-left'}>
        <Button type="primary" onClick={onSave}>
          保存
        </Button>
        <Button onClick={onCancel}>取消</Button>
      </Space>
    </Wrapper>
  );
};
const DefaultImg: React.FC = () => {
  return (
    <div className={styles.imgDefaultBox}>
      <Image preview={false} src={defaultImg} />
    </div>
  );
};
const DefaultLogo: React.FC = () => {
  return (
    <div className={styles.logoDefaultBox}>
      <Image preview={false} src={defaultLogo} />
    </div>
  );
};
const UIsetImg: React.FC = () => {
  return (
    <>
      <Row gutter={30}>
        <Col span={12}>
          <div className={styles.imgDefault}>
            <Form.Item label="logo" name="logoIcon">
              <Upload
                accept={['.png']}
                tip="支持.png格式, 大小不超过1MB, 若不上传, 则显示默认画面, 建议尺寸380px*60px"
              />
            </Form.Item>
            <DefaultLogo />
          </div>
        </Col>
        <Col span={12}>
          <div className={styles.imgDefault}>
            <Form.Item label="开机画面" name="powerOnIcon">
              <Upload
                accept={['.png']}
                tip={'支持.png格式, 大小不超过1MB, 若不上传, 则显示默认画面, 建议尺寸1280px*720px'}
              />
            </Form.Item>
            <DefaultImg />
          </div>
        </Col>
      </Row>
    </>
  );
};
export default BsaConfigScreen;
