import AssetsAlarmOverview from '.';
import Detail from './Detail';

import { PermissionsType } from '@/common/permissionsConst';

const routes = [
  {
    path: '/assets/alarm/overview',
    element: <AssetsAlarmOverview />,
    permission: PermissionsType.P_ASSET_KANBAN_PAGE,
  },
  {
    path: '/assets/alarm/overview/detail/:id',
    element: <Detail />,
  },
];

export default routes;
