import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { Key } from 'react';
import { BsaType } from '@/pages/Overview/type';

// 查询今日，本月，总统计数据
export interface BsaDataStatics {
  time: number;
  income: number;
  produce: number;
  consume: number;
  totalCharge: number;
  totalDischarge: number;
  summitCharge: number;
  summitDischarge: number;
  peakCharge: number;
  peakDischarge: number;
  plainCharge: number;
  plainDischarge: number;
  valleyCharge: number;
  valleyDischarge: number;
}
export interface BsaDataStaticsRes {
  day: BsaDataStatics;
  month: BsaDataStatics;
  total: BsaDataStatics;
}

export enum DataGrain {
  DAY = 'D',
  MONTH = 'M',
  HOUR = 'H',
}
export interface BsaStaticsByGrainReq {
  bsaId: Key;
  type?: BsaType;
  timeResolution: DataGrain;
  from: number;
  to: number;
  incomeDisplay?: boolean;
}

// 根据数据颗粒度查询统计数据
export const getBsaStaticsByGrain = (query: BsaStaticsByGrainReq) =>
  fetch<BsaDataStatics[]>(`/api/bsaData/data?${qs.stringify(query, { indices: false })}`);

// 小时/日/月充放电量
export const getBsaStaticsByGrainCharge = (query: BsaStaticsByGrainReq) =>
  fetch<{ list: BsaDataStatics[] }>(
    `/api/v2/bsa/data`,
    {
      method: 'POST',
      body: JSON.stringify({
        ...query,
      }),
    },
    true
  ).then(res => res.list);

// 根据数据颗粒度查询统计数据
export const getBsaStaticsByGrain15Minu = (query: Exclude<BsaStaticsByGrainReq, 'timeResolution'>) =>
  fetch<{ list: BsaDataStatics[] }>(
    `/api/v2/bsa/data/quarter`,
    {
      method: 'POST',
      body: JSON.stringify({
        ...query,
        timeResolution: '15M',
      }),
    },
    true
  ).then(res => res.list);

export const exportChargingData = (query: BsaStaticsByGrainReq) =>
  window.open(`/api/v2/bsa/charging/downloadExcel?${qs.stringify(query, { indices: false })}`, '_blank');

// 导出
export const exportData = (query: BsaStaticsByGrainReq) =>
  window.open(`/api/v2/bsaData/data/export?${qs.stringify(query, { indices: false })}`, '_blank');

// 查询今日电价区间
export const getBsaDataPrices = (query: { bsaId?: Key }) =>
  fetch<BsaDataStaticsRes[]>(`/api/bsaData/prices?${qs.stringify(query, { indices: false })}`);
