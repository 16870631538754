import React, { FC, useContext, useMemo } from 'react';
import { SitePerforAnalysisYearContext } from '../..';
import styles from './index.module.scss';
import tittleDecoration from '../../imgs/tittleDecoration.png';
import ReactEcharts from 'echarts-for-react';
import { Spin } from 'antd';
import { Button, Empty } from '@maxtropy/components';
import { DoubleRightOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { apiV2PvPerformanceAnalyseLossAnalysisPost } from '@maxtropy/dmes-apis-v2';
import { getChartOption } from './chart';

export interface ILossAnalysis {
  isShowMore: boolean;
}

const LossAnalysis: FC<ILossAnalysis> = props => {
  const { isShowMore } = props;
  const { pvId, resolution, date } = useContext(SitePerforAnalysisYearContext);

  // 损耗分析
  const { data: chartData, loading } = useRequest(
    () => {
      return apiV2PvPerformanceAnalyseLossAnalysisPost({
        id: pvId,
        type: resolution,
        time: date?.valueOf(),
      });
    },
    {
      ready: !!pvId && !!date && !!resolution,
      refreshDeps: [pvId, date, resolution],
    }
  );

  const isShowEmpty = useMemo(() => {
    if ((chartData?.list ?? []).length) {
      return false;
    }
    return true;
  }, [chartData]);

  // 图表
  const chartOption = useMemo(() => getChartOption(chartData, resolution), [chartData, resolution]);

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <img src={tittleDecoration} alt="pic" className={styles.titleBorder} />
        <span className={styles.title}>损耗分析</span>
        {pvId && isShowMore && (
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => window.open(`${window.PVANALYSIS}/neec/statistics?stationId=${pvId}`, '_blank')}
          >
            查看更多
            <DoubleRightOutlined />
          </Button>
        )}
      </div>
      <div className={styles.content_box}>
        <div className={styles.chart_box}>
          {loading ? (
            <Spin spinning={true} className={styles.spin_box} />
          ) : isShowEmpty ? (
            <Empty className={styles.empty_box} />
          ) : (
            <ReactEcharts style={{ height: 340 }} option={chartOption} notMerge lazyUpdate={false} />
          )}
        </div>
      </div>
    </div>
  );
};

export default LossAnalysis;
