import { PermissionsType } from '@/common/permissionsConst';
import ChargingStationManagement from '.';
import EditChargingStation from './components/EditChargingStation';

const routes = [
  {
    path: '/device/chargingStation/management',
    permission: PermissionsType.P_CHARGINGMANAGE,
    element: <ChargingStationManagement />,
  },
  {
    path: '/device/chargingStation/management/edit/:id',
    permission: PermissionsType.P_CHARGINGEDIT,
    element: <EditChargingStation isEdit />,
  },
  {
    path: '/device/chargingStation/management/detail/:id',
    permission: PermissionsType.P_CHARGINGVIEW,
    element: <EditChargingStation />,
  },
];
export default routes;
