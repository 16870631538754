import React, { useState, useEffect } from 'react';
import { Row, Col, Space } from 'antd';
import {
  createAlarmWorkOrder,
  WorkOrderDetailResponse,
  getAlarmWorkOrderDetail,
  AlarmLevelDisplay,
  AlarmLevelColorDisplay,
} from '@/api/alarm';
import ShowInput from '@/components/ShowInput';
import dayjs from 'dayjs';
import { qingflowWorkDetailPath } from './const';
import { Form, Input, Tag, Modal } from '@maxtropy/components';

type WorkOrderModalProps = {
  open: boolean;
  closeWorkOrderModal: () => void;
  id: string;
  forceUpdate?: () => void;
};

const dateFormat = 'YYYY/MM/DD HH:mm:ss';

export default function WorkOrderModal(props: WorkOrderModalProps) {
  const { open, closeWorkOrderModal, id, forceUpdate } = props;
  const [form] = Form.useForm();
  const [data, setData] = useState<WorkOrderDetailResponse>();

  useEffect(() => {
    if (id) {
      getAlarmWorkOrderDetail(id!).then(res => {
        setData(res);
      });
    }
  }, [id]);

  return (
    <Modal
      destroyOnClose
      size="large"
      title="创建工单"
      open={open}
      onOk={() => {
        form.validateFields().then(valid => {
          createAlarmWorkOrder({ name: valid.name, ruleLogId: id! })
            .then(_ => {
              // 跳转到轻流工单详情
              window.open(qingflowWorkDetailPath, '_blank');
            })
            .finally(() => {
              // 清空输入框
              forceUpdate?.();
              form.setFieldValue('name', '');
              closeWorkOrderModal();
            });
        });
      }}
      onCancel={() => {
        form.setFieldValue('name', '');
        closeWorkOrderModal();
      }}
    >
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={8}>
            {data?.name ? (
              <Form.Item label="工单名称">
                <ShowInput value={data.name} />
              </Form.Item>
            ) : (
              <Form.Item
                label="工单名称"
                name="name"
                rules={[
                  { required: true, message: '请输入' },
                  { max: 15, message: '最多输入15个字符' },
                ]}
              >
                <Input placeholder="请输入" maxLength={15}></Input>
              </Form.Item>
            )}
          </Col>
          <Col span={8}>
            <Form.Item label="设备名称">
              <ShowInput value={data?.deviceName} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="报警等级">
              <ShowInput value="">
                <Tag border="solid" color={AlarmLevelColorDisplay[data?.alarmLevel!]}>
                  {AlarmLevelDisplay[data?.alarmLevel!]}
                </Tag>
              </ShowInput>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="报警规则">
              <Space>
                <ShowInput value={data?.ruleName} />
              </Space>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="报警时间">
              <ShowInput value={dayjs(data?.alarmTime).format(dateFormat)} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
