import { PermissionsType } from '@/common/permissionsConst';
import StorageRemoteControl from '.';
import RemoteControlPage from './RemoteControlPage';

const routes = [
  {
    path: '/energy-storage/remote/control',
    permission: PermissionsType.P_REMOTE_CONTROL,
    element: <StorageRemoteControl />,
  },
  {
    path: '/energy-storage/remote/record',
    permission: PermissionsType.P_REMOTE_CONTROL_LOG,
    element: <RemoteControlPage />,
  },
];
export default routes;
