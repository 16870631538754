import { Checkbox } from '@maxtropy/components';
import { ReactNode, useEffect, useState } from 'react';

interface Iprops {
  value?: 0 | 1;
  onChange?: (v?: 0 | 1) => void;
  children?: ReactNode;
  disabled?: boolean;
}
const CustomCheckBox = (props: Iprops) => {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(!!props.value);
  }, [props.value]);
  return (
    <Checkbox
      checked={checked}
      onChange={e => {
        props.onChange?.(e.target.checked ? 1 : 0);
      }}
      disabled={props.disabled}
    >
      {props.children}
    </Checkbox>
  );
};

export default CustomCheckBox;
