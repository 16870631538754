import { Calendar, Spin, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import DateCellRender from './components/DateCellRender';
import HeaderRender from './components/HeaderRender';
import { fetchCalendar, LocalCalendarProps } from '../../api/strategy';
import { CloseCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { colorDisc } from './components/utils';
import { useUpdate } from '@maxtropy/components';
import errPic from './error.png';
import qs from 'qs';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(updateLocale);

interface StrategyCalendarProps {
  isForm?: boolean;
  onChange?: (value: string[]) => void;
  value?: string[];
  bsaId?: number;
  setExistingStrategyNumber?: (v: number) => void;
  dataSoureFun?: (v: LocalCalendarProps[]) => void;
  x?: number;
}

const StrategyCalendar: React.FC<StrategyCalendarProps> = ({
  isForm,
  onChange,
  bsaId,
  setExistingStrategyNumber,
  dataSoureFun,
  x,
}) => {
  const [selectDate, setSelectDate] = useState<string[]>([]);
  const [dataSource, setDataSource] = useState<LocalCalendarProps[]>([]);
  const [versionColorDisc, setVersionColorDisc] = useState<{ [key: number]: string }>();
  const [selectMonth, setSelectMonth] = useState(dayjs());
  const [f, forceUpdate] = useUpdate();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const query: any = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const copyDate = query.date;
  dayjs.updateLocale('zh-cn', {
    weekdaysMin: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
  });
  useEffect(() => {
    if (bsaId) {
      setLoading(true);
      const params = {
        bsaId,
        year: dayjs(selectMonth).format('YYYY'),
        month: dayjs(selectMonth).format('MM'),
      };
      fetchCalendar(params)
        .onError(err => {
          setErr(true);
          throw err;
        })
        .then(res => {
          const versionId = Array.from(new Set(res.map(i => i.globalVersion))).sort();
          const color: { [key: number]: string } = {};
          versionId.forEach((v, index) => {
            color[v] = colorDisc[index];
          });
          setVersionColorDisc(color);
          setDataSource(res);
          if (dataSoureFun) dataSoureFun(res);
          setErr(false);
          // 拉取到就地策略, 缓存查询条件
        })
        .finally(() => setLoading(false));
    } else {
      setDataSource([]);
      if (dataSoureFun) dataSoureFun([]);
      setLoading(false);
    }
    if (!isForm) setSelectDate([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bsaId, selectMonth, f, x]);

  useEffect(() => {
    if (onChange) onChange(selectDate.sort((a, b) => dayjs(a).valueOf() - dayjs(b).valueOf()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectDate]);

  const dateRender = useMemo(() => {
    let data: { [key: string]: number[] } = {};
    selectDate.forEach(i => {
      const k = i.substring(0, 7);
      if (data.hasOwnProperty(k)) {
        data[k].push(dayjs(i).valueOf());
      } else {
        data[k] = [dayjs(i).valueOf()];
      }
    });
    Object.keys(data).forEach(i => data[i].sort());
    return data;
  }, [selectDate]);

  const deleteAction = (value: number) => {
    setSelectDate(selectDate.filter(i => i !== dayjs(value).format('YYYY-MM-DD')));
  };

  const headerChange = (val: string[]) => {
    const v = val.filter(i => i !== copyDate);
    const choosableList = dataSource.map(i => dayjs(i.day).format('YYYY-MM-DD'));
    if (isForm) {
      if (setExistingStrategyNumber) {
        const selectDateNum = selectDate.filter(
          i =>
            dayjs(i).format('YYYY-MM') === dayjs(selectMonth).format('YYYY-MM') &&
            choosableList.includes(dayjs(i).format('YYYY-MM-DD'))
        ).length;
        const currentDateNum = v.filter(i => choosableList.includes(i)).length;
        setExistingStrategyNumber(currentDateNum - selectDateNum);
      }
      setSelectDate(v);
    } else {
      setSelectDate(v.filter(i => choosableList.includes(i)));
    }
  };

  return (
    <div className={styles.calendarContent}>
      {err ? (
        <div className={styles.err}>
          <img src={errPic} alt="" />
          <p>
            <ExclamationCircleFilled className={styles.errIcon} />
            通讯失败，无法拉取到就地策略
          </p>
        </div>
      ) : (
        <>
          <Spin spinning={loading}>
            <Calendar
              onPanelChange={d => setSelectMonth(d)}
              className={styles.calendar}
              dateFullCellRender={val => (
                <DateCellRender
                  val={val}
                  dataSource={dataSource}
                  selectDate={selectDate}
                  bsaId={bsaId}
                  onSelectDate={v => setSelectDate(v)}
                  isForm={isForm}
                  setExistingStrategyNumber={setExistingStrategyNumber}
                  forceUpdate={forceUpdate}
                  selectMonth={selectMonth}
                  versionColorDisc={versionColorDisc}
                />
              )}
              headerRender={val => <HeaderRender config={val} selectDate={selectDate} onSelectDate={headerChange} />}
            />
          </Spin>
          {isForm && (
            <div className={styles.bottomBox}>
              <p style={{ minWidth: 80 }}>选中日期:</p>
              <div className={styles.dates}>
                {Object.keys(dateRender).map(i => (
                  <div className={styles.dateRender}>
                    <p>{dayjs(i).format('YYYY年MM月')}：</p>
                    <div className={styles.right}>
                      {dateRender[i].map(m => {
                        const color = colorDisc[dayjs(m).day()];
                        const rgbaC = [
                          parseInt('0x' + color.slice(1, 3)),
                          parseInt('0x' + color.slice(3, 5)),
                          parseInt('0x' + color.slice(5, 7)),
                          0.2,
                        ];
                        const rgba = `rgba(${rgbaC.join(',')})`;
                        return (
                          <Tag style={{ borderColor: color, backgroundColor: rgba }}>
                            {dayjs(m).format('YYYY-MM-DD')}
                            <CloseCircleOutlined style={{ marginLeft: 5 }} onClick={() => deleteAction(m)} />
                          </Tag>
                        );
                      })}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default StrategyCalendar;
