import { DetailInfoRes, getChargingStationDetailInfo, updateChargingStation } from '@/api/chargingStationManagement';
import { Form, Button, SubContent, useBreadcrumbRoutes, useUpdate, Wrapper, FormTitle } from '@maxtropy/components';
import { Space } from 'antd';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BasicInfoForm from '../BasicInfoForm';
import DeviceListForm from '../DeviceListForm';
import styles from './index.module.scss';
import dayjs from 'dayjs';

const FORMATTIME = 'YYYY-MM-DD';

export interface IEditChargingStation {
  isEdit?: boolean;
}

const EditChargingStation: FC<IEditChargingStation> = props => {
  const { isEdit } = props;
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [data, setData] = useState<DetailInfoRes>();
  const [updateState, updateFn] = useUpdate();
  const exportsInfo = useRef<{ lngLats: string; adcode: string }>({ lngLats: '', adcode: '' });

  useEffect(() => {
    if (id) {
      getChargingStationDetailInfo(id).then(setData);
    }
  }, [id, updateState]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        code: data.code,
        name: data.name,
        ouName: data.ouName,
        location: data.district?.split('/'),
        address: data.address,
        adcode: data.adcode,
        createStationTime: data.createStationTime && dayjs(data.createStationTime),
      });
      if (data.adcode && data.latitude && data.longitude) {
        exportsInfo.current = {
          lngLats: data.longitude + ',' + data.latitude,
          adcode: data.adcode,
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const routes = useMemo(() => {
    return [{ name: `${isEdit ? '编辑' : '查看'}充电站基础信息` }];
  }, [isEdit]);

  const onFinish = async (values: any) => {
    let { lngLats, adcode } = exportsInfo?.current || {};
    let tempArr = lngLats.split(',');
    updateChargingStation({
      id: data?.id!,
      district: values.location.join('/'),
      address: values.address,
      latitude: Number(tempArr[1]),
      longitude: Number(tempArr[0]),
      createStationTime: dayjs(values.createStationTime).format(FORMATTIME),
      adcode,
    }).then(_ => {
      updateFn();
      navigate(`/device/chargingStation/management`);
    });
  };

  const getLngLat = (lngLats: string, adcode: string) => {
    exportsInfo.current = {
      lngLats,
      adcode,
    };
  };

  return (
    <Wrapper className={styles.wrapper} routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}>
      <Form form={form} layout="horizontal" onFinish={onFinish}>
        <FormTitle
          title={<div>充电站基础信息</div>}
          extraContent={
            <Space className="sticky-footer" style={{ zIndex: 99 }}>
              {isEdit && (
                <Button type="primary" htmlType="submit">
                  保存
                </Button>
              )}
              <Button
                onClick={() => {
                  navigate(`/device/chargingStation/management`);
                }}
              >
                {isEdit ? '取消' : '返回'}
              </Button>
            </Space>
          }
        />
        <SubContent>
          <BasicInfoForm form={form} isEdit={isEdit} data={data} getLngLat={getLngLat} />
          <div className={styles.DeviceListContentSty}>
            <DeviceListForm isEdit={isEdit} data={data} />
          </div>
        </SubContent>
      </Form>
    </Wrapper>
  );
};

export default EditChargingStation;
