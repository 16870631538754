import { v2GetCircuitStatistics, StatisticsPartition } from '@/api/circuit';
import { DataType } from '@/api/const';
import { FallOutlined, RiseOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import styles from '../../index.module.scss';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

interface ICurrentMonthElecConsumption {
  currentCircuit?: number;
  openTimer: (isOver: boolean) => void;
  timerState?: number;
}

const CurrentMonthElecConsumption: FC<ICurrentMonthElecConsumption> = props => {
  const { currentCircuit } = props;
  const [currentMonthElec, setCurrentMonthElec] = useState<string>();
  const [lastYearMonthElec, setLastYearMonthElec] = useState<string>();
  const [lastMonthElec, setLastMonthElec] = useState<string>();

  // 当月的用电量
  useEffect(() => {
    if (currentCircuit) {
      let time = Math.floor(dayjs().minute() / 15) * 15;
      props.openTimer?.(false);
      Promise.all([
        v2GetCircuitStatistics({
          // 当月的，包含跨年的判断
          circuitId: currentCircuit,
          dataType: DataType.EP,
          timeResolution: StatisticsPartition.MINUTE_15,
          from: dayjs().isBetween(dayjs().startOf('month'), dayjs().startOf('month').add(15, 'minute'))
            ? dayjs().subtract(1, 'month').startOf('month').valueOf()
            : dayjs().startOf('month').valueOf(),
          to: dayjs().isBetween(dayjs().startOf('month'), dayjs().startOf('month').add(15, 'minute'))
            ? dayjs().subtract(1, 'month').endOf('month').valueOf()
            : dayjs().startOf('hour').add(time, 'minute').valueOf(),
        }),
        v2GetCircuitStatistics({
          circuitId: currentCircuit,
          dataType: DataType.EP,
          timeResolution: StatisticsPartition.MINUTE_15,
          from: dayjs().subtract(1, 'year').startOf('month').valueOf(),
          to: dayjs().subtract(1, 'year').startOf('hour').add(time, 'minute').valueOf(),
        }),
        v2GetCircuitStatistics({
          circuitId: currentCircuit,
          dataType: DataType.EP,
          timeResolution: StatisticsPartition.MINUTE_15,
          from: dayjs().subtract(1, 'month').startOf('month').valueOf(),
          to: dayjs().subtract(1, 'month').startOf('hour').add(time, 'minute').valueOf(),
        }),
      ])
        .finally(() => {
          props.openTimer?.(true);
        })
        .then(([res1, res2, res3]) => {
          if (res1[0]?.data.length > 0) {
            let total: number = 0;
            res1[0]?.data.forEach(item => {
              if (item.value) {
                total += item.value;
              }
            });
            if (total === 0) {
              setCurrentMonthElec('--');
            } else {
              setCurrentMonthElec(total.toFixed(2));
            }
          } else {
            setCurrentMonthElec('--');
          }

          if (res2[0]?.data.length > 0) {
            let total: number = 0;
            res2[0]?.data.forEach(item => {
              if (item.value) {
                total += item.value;
              }
            });
            if (total === 0) {
              setLastYearMonthElec('--');
            } else {
              setLastYearMonthElec(total.toFixed(2));
            }
          } else {
            setLastYearMonthElec('--');
          }

          if (res3[0]?.data.length > 0) {
            let total: number = 0;
            res3[0]?.data.forEach(item => {
              if (item.value) {
                total += item.value;
              }
            });
            if (total === 0) {
              setLastMonthElec('--');
            } else {
              setLastMonthElec(total.toFixed(2));
            }
          } else {
            setLastMonthElec('--');
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCircuit, props.timerState]);

  const calculateLastMonth = () => {
    if (currentMonthElec === '--' || lastMonthElec === '--' || !!!currentMonthElec || !!!lastMonthElec) {
      return '--';
    } else {
      return ((Number(currentMonthElec) - Number(lastMonthElec)) / Number(lastMonthElec)) * 100;
    }
  };
  const calculateLastYearMonth = () => {
    if (currentMonthElec === '--' || lastYearMonthElec === '--' || !!!currentMonthElec || !!!lastYearMonthElec) {
      return '--';
    } else {
      return ((Number(currentMonthElec) - Number(lastYearMonthElec)) / Number(lastYearMonthElec)) * 100;
    }
  };

  return (
    <>
      <p style={{ color: '#14D0E4', fontSize: '22px', fontWeight: 700, margin: 0 }}>
        {currentMonthElec ? currentMonthElec + 'kWh' : '--'}
      </p>
      <div className={styles.line_through}>
        <span className={styles.line_head}></span>
      </div>
      <p style={{ color: 'rgba(255,255,255,0.65)' }}>本月用电量</p>
      <Row gutter={16}>
        <Col span={12}>
          <p>
            较<span style={{ color: '#14D0E4' }}>{dayjs().subtract(1, 'month').format('YYYY-MM')}</span>同期
          </p>
          <p>
            {calculateLastMonth() === '--' ? (
              ''
            ) : calculateLastMonth() < 0 ? (
              <FallOutlined style={{ fontSize: '24px', color: '#59D744' }} />
            ) : (
              <RiseOutlined style={{ fontSize: '24px', color: '#F5222D' }} />
            )}
            <span
              style={{
                color: calculateLastMonth() === '--' ? undefined : calculateLastMonth() < 0 ? '#59D744' : '#F5222D',
                fontSize: '24px',
              }}
            >
              {' '}
              {calculateLastMonth() === '--' ? '--' : Math.abs(calculateLastMonth() as number).toFixed(2) + '%'}
            </span>
          </p>
        </Col>
        <Col span={12}>
          <p>
            较<span style={{ color: '#14D0E4' }}>{dayjs().subtract(1, 'year').format('YYYY-MM')}</span>同期
          </p>
          <p>
            {calculateLastYearMonth() === '--' ? (
              ''
            ) : calculateLastYearMonth() < 0 ? (
              <FallOutlined style={{ fontSize: '24px', color: '#59D744' }} />
            ) : (
              <RiseOutlined style={{ fontSize: '24px', color: '#F5222D' }} />
            )}
            <span
              style={{
                color:
                  calculateLastYearMonth() === '--' ? undefined : calculateLastYearMonth() < 0 ? '#59D744' : '#F5222D',
                fontSize: '24px',
              }}
            >
              {calculateLastYearMonth() === '--' ? '--' : Math.abs(calculateLastYearMonth() as number).toFixed(2) + '%'}
            </span>
          </p>
        </Col>
      </Row>
    </>
  );
};

export default CurrentMonthElecConsumption;
