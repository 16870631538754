import request from '@/utils/request';

export const pvCabinetKeys = {
  list: (filters: CabinetQuery) => ['cabinets', 'list', { filters }],
};

export enum PVCabinetType {
  SVG,
  GRID_TIE_OUTGOING_CABINET,
  PV_INCOMING_CABINET,
  TRANSFORMER,
  STATION_ELECTRIC_METER,
}

export namespace PVCabinetType {
  export const MAP = {
    [PVCabinetType.SVG]: 'SVG',
    [PVCabinetType.GRID_TIE_OUTGOING_CABINET]: '并网出线柜',
    [PVCabinetType.PV_INCOMING_CABINET]: '光伏进线柜',
    [PVCabinetType.TRANSFORMER]: '变压器',
    [PVCabinetType.STATION_ELECTRIC_METER]: '站用电电表',
  };
}

export interface PVCabinet {
  id: number;
  code: string;
  deviceCode: string;
  deviceId: number;
  deviceName: string;
  gridConnectionPointId: number;
  gridConnectionPointName: string;
}

export interface CabinetPayload {
  id?: number;
  name: string;
  deviceId?: number;
  gridConnectionPointId: number;
  pvId: number;
  type: PVCabinetType;
}

export interface CabinetQuery {
  pvId: number;
}

export const getCabinetList = (params: CabinetQuery) =>
  request<PVCabinet[]>({ url: `/api/pv-high-voltage-cabinet/list`, params });

export const createCabinet = (data: CabinetPayload) =>
  request<void>({ url: `/api/pv-high-voltage-cabinet/create`, method: 'POST', data });

export const updateCabinet = (data: CabinetPayload) =>
  request<void>({ url: `/api/pv-high-voltage-cabinet/update`, method: 'PUT', data });

export const deleteCabinet = (id: number) =>
  request<void>({ url: `/api/pv-high-voltage-cabinet/delete/${id}`, method: 'DELETE' });

export const deleteAllCabinets = (pvId: number) =>
  request<void>({ url: `/api/pv-high-voltage-cabinet/delete-all/${pvId}`, method: 'DELETE' });

export const removeCabinetDevice = (id: number) =>
  request<void>({ url: `/api/pv-high-voltage-cabinet/remove-device/${id}`, method: 'PUT' });

export const updateCabinetDevice = (id: number, deviceId: number) =>
  request<void>({ url: `/api/pv-high-voltage-cabinet/update-device/${id}`, method: 'PUT', params: { deviceId } });
