import React, { useEffect, useState } from 'react';
import {
  Modal,
  useBreadcrumbRoutes,
  useUpdate,
  Tabs,
  Button,
  Form,
  Select,
  Wrapper,
  CustomFilter,
} from '@maxtropy/components';
import { useForm } from 'antd/es/form/Form';
import { Space } from 'antd';
import { useQuery } from 'react-query';
import { CalendarReq, changeStatus, getBsaListBySystemVersion, LocalCalendarProps } from '../../../api/strategy';
import StrategyCalendar from '../../../components/StrategyCalendar';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { LocalCalendarStatus } from '../../../api/type';
import { ExclamationCircleFilled } from '@ant-design/icons';
import qs from 'qs';
import styles from './index.module.scss';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);
const { TabPane } = Tabs;

const StrategyCalendarList: React.FC = () => {
  const routesContext = useBreadcrumbRoutes();
  const [form] = useForm();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useState<CalendarReq>();
  const [selectDate, setSelectDate] = useState<string[]>([]);
  const [dataSoure, setDataSoure] = useState<LocalCalendarProps[]>([]);
  const [x, forceUpdate] = useUpdate();
  const [loading, setLoading] = useState(false);
  const query: any = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  const { data: bsaList, isLoading: isBsaLoading } = useQuery(['bsaList'], () => getBsaListBySystemVersion());

  const copy = () => {
    const info = dataSoure.find(i => dayjs(i.day).isSame(dayjs(selectDate[0])));
    navigate(
      `/dmes/energy-storage/strategy/${info!.templateId}/launch/copy/${searchParams!.bsaId}/${info!.id}?date=${
        info?.day
      }`
    );
  };
  const relieve = () => {
    const list = dataSoure.filter(i => selectDate.includes(dayjs(i.day).format('YYYY-MM-DD')));
    Modal.confirm({
      title: '批量解除策略',
      content: `${list.length}个策略将被解除设置，确定吗？`,
      okText: '确定',
      cancelText: '我再检查一下',
      onOk: () => {
        setLoading(true);
        const params = {
          strategyIds: list.map(i => i.id),
          status: LocalCalendarStatus.INVALID,
          version: list[0].version,
          globalVersion: list[0].globalVersion,
        };
        changeStatus(searchParams!.bsaId, params)
          .onError(err => {
            const { errorMessage } = err?.cause;
            Modal.error({
              title: errorMessage,
            });
            throw err;
          })
          .then(res => {
            if (res) forceUpdate();
          })
          .finally(() => setLoading(false));
      },
    });
  };
  useEffect(() => {
    // 说明是由编辑策略跳转过来的
    if (query.bsaId) {
      // 读取本地缓存的值
      let localDataStr = sessionStorage.getItem('calendar');
      if (!localDataStr) return;
      let tempParams = JSON.parse(localDataStr);
      if (tempParams.bsaId !== Number(query.bsaId)) return;
      let { bsaId } = tempParams;
      form.setFieldsValue({
        bsaId,
      });
      // 拉取子组件中的就地策略
      setSearchParams({
        bsaId,
        year: dayjs().format('YYYY'),
        month: dayjs().format('MM'),
      });
      return;
    }
    if (bsaList && bsaList.length > 0) {
      form.setFieldsValue({
        bsaId: bsaList[0].id,
      });
      setSearchParams({
        bsaId: bsaList[0].id,
        year: dayjs().format('YYYY'),
        month: dayjs().format('MM'),
      });
    }
  }, [query.bsaId, form, bsaList]);
  return (
    <>
      <Wrapper routes={routesContext?.routes} className={styles.wrapperTabsPadding} isLoading={loading}>
        <Tabs activeKey="2" onChange={v => navigate(`/dmes/energy-storage/strategy${v === '2' ? '/calendar' : ''}`)}>
          <TabPane tab="策略模板" key="1" />
          <TabPane tab="就地策略日历" key="2" />
        </Tabs>
        <CustomFilter
          form={form}
          onFinish={(values: any) => {
            const params = {
              bsaId: values.bsaId,
              year: dayjs().format('YYYY'),
              month: dayjs().format('MM'),
            };
            setSearchParams(params);
            forceUpdate();
            // 如果存在fcsId, 先缓存查询条件
            if (values.bsaId) {
              let tempParams = {
                ...values,
                ...params,
              };
              sessionStorage.setItem('calendar', JSON.stringify(tempParams));
            }
          }}
          onReset={() => {
            setSearchParams(undefined);
            sessionStorage.removeItem('calendar');
          }}
        >
          <Form.Item label="储能站" name="bsaId">
            <Select
              loading={isBsaLoading}
              placeholder="请输入/选择储能站"
              showSearch
              optionFilterProp="label"
              options={bsaList?.map(item => ({ value: item.id, label: item.name }))}
            ></Select>
          </Form.Item>
          <Form.Item noStyle dependencies={['bsaId']}>
            {({ getFieldValue }) => {
              let bsaId = getFieldValue('bsaId');
              let bsaName = bsaList?.find(item => item.id === bsaId)?.name;
              return (
                <Button
                  type="link"
                  disabled={!bsaId}
                  style={{ paddingLeft: 0 }}
                  onClick={() => {
                    if (!bsaName) return;
                    window.open(`/energy-storage/basic/bsa?bsaName=${encodeURIComponent(bsaName)}`, '_blank');
                  }}
                >
                  查看储能站
                </Button>
              );
            }}
          </Form.Item>
        </CustomFilter>
        <Space size={8} style={{ marginBottom: 10 }}>
          <Button type="primary" disabled={selectDate.length !== 1} onClick={copy}>
            复制单日策略到其他日期
          </Button>
          <Button type="primary" disabled={selectDate.length === 0} onClick={relieve}>
            批量解除
          </Button>
        </Space>
        {searchParams?.bsaId ? (
          <StrategyCalendar
            bsaId={searchParams.bsaId}
            dataSoureFun={setDataSoure}
            x={x}
            onChange={v => setSelectDate(v)}
          />
        ) : (
          <div style={{ textAlign: 'center', padding: '10% 0' }}>
            <ExclamationCircleFilled style={{ color: '#F3B765', fontSize: 30 }} />
            <p style={{ marginTop: 10 }}>请选择需要查询的储能阵列后进行策略日历查询！</p>
          </div>
        )}
      </Wrapper>
    </>
  );
};

export default StrategyCalendarList;
