import { CircuitType, CircuitUpperLower, VoltageLevel } from './circuit';
import { EPricePeakType } from './electricityPrice';

export enum CircuitPhysicalQuantityType {
  INSTANT = 2,
  ACCUMULATION = 1,
}

export const CircuitPhysicalQuantityTypeDisplay = {
  [CircuitPhysicalQuantityType.INSTANT]: '瞬时量',
  [CircuitPhysicalQuantityType.ACCUMULATION]: '累积量',
};

export enum DataType {
  EP = 1, // 电量
  PDMD = 2, // 最大需量
  LOAD_RATE = 3, // 负载率
  COSQ = 4, // 功率因数
  P = 5, // 有功功率
  U = 6, // 相电压
  LINE_VOLTAGE = 7, // 线电压
  I = 8, // 电流
  T = 9, // 线缆温度
  PE, // 有功电能
  F = 11, //频率
  UNBALANCED_V, // 三相电压不平衡度
  UNBALANCED_A, // 三相电流不平衡度
  RESIDUAL_A, // 剩余电流
  VOLTAGE_H, // 电压谐波
  CURRENT_H, // 电流谐波
}

export enum DataNameType {
  EP = 1, // 电量
  PDMD = 2, // 最大需量
  COSQ = 3, // 功率因数
  P = 4, // 有功功率
  UA = 5, // A相电压
  UB = 6, // B相电压
  UC = 7, // C相电压
  UAB = 8, // AB线电压
  UBC = 9, // BC线电压
  UCA = 10, // CA线电压
  IA = 11, // A相电流
  IB = 12, // B相电流
  IC = 13, // C相电流
  TA = 14, // A相温度
  TB = 15, // B相温度
  TC = 16, // C相温度
  FPE = 17, // 正向有功电能,
  BPE = 18, // 反向有功电能
  F = 19, //频率
  LOAD_RATE = 20, // 负载率
  UNBALANCED_V, // 三相电压不平衡度
  UNBALANCED_A, // 三相电流不平衡度
  RESIDUAL_A, // 剩余电流
  VOLTAGE_H, // 电压谐波
  CURRENT_H, // 电流谐波
  VH_A, // A相电压谐波
  VH_B, // B相电压谐波
  VH_C, // C相电压谐波
  CH_A, // A相电流谐波
  CH_B, // B相电流谐波
  CH_C, // C相电流谐波
}

export const dataNameTypeDisplay = {
  [DataNameType.EP]: '电量',
  [DataNameType.PDMD]: '最大需量',
  [DataNameType.COSQ]: '功率因数',
  [DataNameType.P]: '有功功率',
  [DataNameType.UA]: 'A相电压',
  [DataNameType.UB]: 'B相电压',
  [DataNameType.UC]: 'C相电压',
  [DataNameType.UAB]: 'AB线电压',
  [DataNameType.UBC]: 'BC线电压',
  [DataNameType.UCA]: 'CA线电压',
  [DataNameType.IA]: 'A相电流',
  [DataNameType.IB]: 'B相电流',
  [DataNameType.IC]: 'C相电流',
  [DataNameType.TA]: 'A相温度',
  [DataNameType.TB]: 'B相温度',
  [DataNameType.TC]: 'C相温度',
  [DataNameType.LOAD_RATE]: '负载率',
  [DataNameType.F]: '频率',
  [DataNameType.BPE]: '反向有功电能',
  [DataNameType.FPE]: '正向有功电能',
  [DataNameType.UNBALANCED_V]: '三相电压不平衡度',
  [DataNameType.UNBALANCED_A]: '三相电流不平衡度',
  [DataNameType.RESIDUAL_A]: '剩余电流',
  [DataNameType.VOLTAGE_H]: '电压谐波',
  [DataNameType.CURRENT_H]: '电流谐波',
  [DataNameType.VH_A]: 'A相电压谐波',
  [DataNameType.VH_B]: 'B相电压谐波',
  [DataNameType.VH_C]: 'C相电压谐波',
  [DataNameType.CH_A]: 'A相电流谐波',
  [DataNameType.CH_B]: 'B相电流谐波',
  [DataNameType.CH_C]: 'C相电流谐波',
};

export const E_PRICE_PEAK_TYPE_LABEL_MAP = {
  [EPricePeakType.SUMMIT]: '尖',
  [EPricePeakType.PEAK]: '峰',
  [EPricePeakType.PLAIN]: '平',
  [EPricePeakType.VALLEY]: '谷',
};

interface IdNameType {
  id: number;
  name: string;
}

export interface SimpleCircuit extends IdNameType {
  hasOu?: boolean;
  createTime?: string;
  capacity?: number | null;
  hasLineLoss?: boolean;
}

export interface SelectOptionProp {
  name?: string;
  id?: number | string;
}

export interface SelectCircuit extends SelectOptionProp {
  dataType?: DataType;
  capacity?: number;
}

export interface SimpleCabinet extends IdNameType {
  circuits: SimpleCircuit[];
}

export interface CircuitTreeData {
  value: number;
  desc: string;
  cabinets: SimpleCabinet[];
}

export interface CircuitTreeData2 extends SimpleCircuit {
  voltageLevelIn: CircuitUpperLower;
  voltageLevel?: VoltageLevel;
  hasOu: boolean;
  cabinetId: number;
  type: CircuitType;
  children?: CircuitTreeData2[];
}
