import { PageRequest, PageResponse } from '@/api/page';
import request from '@/utils/request';
import { fetch } from '@maxtropy/components';

export const pvStringKeys = {
  all: ['strings'],
  list: (pvId: number) => [...pvStringKeys.all, pvId],
  page: (filters: StringPageQuery) => [...pvStringKeys.all, { filters }],
};

export interface PVString {
  id: number;
  code: string;
  deviceCode: string;
  deviceId: number;
  deviceName: string;
  pvInverterId: number;
  pvInverterCode: string;
}

export interface StringPayload {
  id?: number;
  code: string;
  pvInverterId: number;
  deviceId?: number;
  pvId: number;
}

export interface StringQuery {
  pvStringCode?: string;
  pvInverterCode?: string;
  pvId: number;
}
export interface CompsQuery {
  pvModuleCode?: string;
  pvStringCode?: string;
  pvId: number;
}

export interface StringImportError {
  pvStringCode: string;
  deviceCode: string;
  pvInverterCode: string;
  errorMessage: string;
}
export interface ModuleImportError {
  pvModuleCode: string;
  deviceCode: string;
  pvStringCode: string;
  errorMessage: string;
}

export type StringPageQuery = StringQuery & PageRequest;

export const getStringPage = (params: StringPageQuery) =>
  fetch<PageResponse<PVString>>('/api/v2/string/page', { method: 'POST', body: JSON.stringify(params) }, true);
export const getStringList = (params: { pvId: number }) =>
  fetch<{ list: PVString[] }>(
    `/api/v2/string/list`,
    { method: 'POST', body: JSON.stringify({ ...params, id: params.pvId }) },
    true
  ).then(res => res.list);

export const createString = (data: StringPayload) =>
  fetch<void>(`/api/v2/string/create`, { method: 'POST', body: JSON.stringify(data) }, true);

export const updateString = (data: StringPayload) =>
  fetch<void>('/api/v2/string/update', { method: 'POST', body: JSON.stringify(data) }, true);

export const deleteString = (id: number) =>
  fetch<void>(`/api/v2/string/delete`, { method: 'POST', body: JSON.stringify({ id }) }, true);

export const deleteAllStrings = (pvId: number) =>
  fetch<void>('/api/v2/string/delete-all', { method: 'POST', body: JSON.stringify({ id: pvId }) }, true);

export const removeStringDevice = (id: number) =>
  fetch<void>('/api/v2/string/remove-device', { method: 'POST', body: JSON.stringify({ id }) }, true);

export const updateStringDevice = (id: number, deviceId: number) =>
  fetch<void>('/api/v2/string/update-device', { method: 'POST', body: JSON.stringify({ id, deviceId }) }, true);

export const exportStrings = (pvId: number) => window.open(`/api/v2/string/output/${pvId}`, '__blank');
export const exportComps = (pvId: number) => window.open(`/api/v2/module/output/${pvId}`, '__blank');

export const downloadStringTemplate = () => window.open(`/api/v2/string/download`, '__blank');

export const uploadStringExcel = (pvId: number, file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('pvId', pvId.toString());
  return request<{ data: { list: StringImportError[] } }>({
    url: '/api/v2/string/input',
    method: 'POST',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then(res => {
    return res?.data?.list || [];
  });
};

export const downloadModuleTemplate = () => window.open(`/api/v2/module/download`, '__blank');
export const uploadModuleExcel = (pvId: number, file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  return request<{ data: { list: ModuleImportError[] } }>({
    url: `/api/v2/module/input/${pvId}`,
    method: 'POST',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then(res => res?.data?.list || []);
};
