import { V2BsaSecondEditionCabientListPostResponse } from '@maxtropy/dmes-apis-v2';

export enum CabinetType {
  BATTERY_CABINET = 1,
  PCS_CABINET,
  BOOSTING_AND_CONVERTING_CABINET,
}

export const CabinetTypeMap = {
  [CabinetType.BATTERY_CABINET]: '储能柜',
  [CabinetType.PCS_CABINET]: 'PCS柜',
  [CabinetType.BOOSTING_AND_CONVERTING_CABINET]: '升压变流一体柜',
};

export const CabinetTypeOptions = Object.entries(CabinetTypeMap).map(([k, v]) => ({
  label: v,
  value: Number(k),
}));

export interface OptionItem {
  [x: string]: any;
  label: string;
  value: number;
}

export type CabinetItem = Exclude<V2BsaSecondEditionCabientListPostResponse['list'], undefined>[number];

export type DeviceItem = Exclude<CabinetItem['deviceList'], undefined>[number] & {
  cabinetId?: number;
};
