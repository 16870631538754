import { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from 'react';

import { BaseInfoIprops, BaseRefProps, PvInfoContext } from '../index';
import BaseDetail from './Detail';
import BaseEdit from './Edit';
import dayjs from 'dayjs';
import { Form } from '@maxtropy/components';

const formLayout = {
  labelCol: { flex: '140px' },
  wrapperCol: { flex: '340px' },
};
const Base = forwardRef<BaseRefProps, BaseInfoIprops>((props, childRef) => {
  const { isView } = useContext(PvInfoContext);
  const [form] = Form.useForm();
  const exportsInfo = useRef<{ lngLats: string; adcode: string }>({ lngLats: '', adcode: '' });
  const checkForm = () => form.validateFields();
  const getLngLat = (lngLats: string, adcode: string) => {
    exportsInfo.current = {
      lngLats,
      adcode,
    };
  };
  useImperativeHandle(childRef, () => ({
    checkForm,
    exportsInfo,
  }));
  useEffect(() => {
    const time = props.createStationTime ? dayjs(props.createStationTime, 'YYYY-MM-DD') : undefined;
    form.setFieldsValue({
      ...props,
      createStationTime: isView ? time?.format('YYYY-MM-DD') : time,
    });
    if (props.latitude && props.adcode) {
      exportsInfo.current = {
        lngLats: props.longitude + ',' + props.latitude,
        adcode: props.adcode,
      };
    }
  }, [props, form, isView]);
  return (
    <>
      <Form labelAlign="left" labelWrap form={form} {...formLayout}>
        {isView ? <BaseDetail detail={props} /> : <BaseEdit getLngLat={getLngLat} form={form} adcode={props.adcode} />}
      </Form>
    </>
  );
});

export default Base;
