import { FC } from 'react';
import styles from './index.module.scss';
import useElecImg from '../../../imgs/useElec.png';
import chargeRightIconImg from '../../../imgs/chargeRightIcon.png';
import disChargeRightIconImg from '../../../imgs/disChargeRightIcon.png';
import { GwhFormat } from '@/pages/MapStation/utils';

export interface IOperateItemInfo {
  title: string;
  chargeValue?: number;
  disChargeValue?: number;
  secondTitle?: string;
}

const OperateItemInfo: FC<IOperateItemInfo> = props => {
  const { title, chargeValue, disChargeValue, secondTitle } = props;

  return (
    <div className={styles.RightInfoItemElec}>
      <div className={styles.itemLine}>
        <div className={styles.monthTitle}>{title}</div>
        <div>
          <img src={useElecImg} alt="" />
        </div>
      </div>
      <div className={styles.itemLine}>
        <div className={styles.itemLineImgTitle}>
          <img src={chargeRightIconImg} alt="" />
          <span>
            {secondTitle ?? ''}充电量（{GwhFormat(chargeValue).unit ?? '--'}）
          </span>
        </div>
        <div className={styles.chargeValueStyle}>{GwhFormat(chargeValue).value}</div>
      </div>
      <div className={styles.itemLine}>
        <div className={styles.itemLineImgTitle}>
          <img src={disChargeRightIconImg} alt="" />
          <span>
            {secondTitle ?? ''}放电量（{GwhFormat(disChargeValue).unit ?? '--'}）
          </span>
        </div>
        <div className={styles.disChargeValueStyle}>{GwhFormat(disChargeValue).value}</div>
      </div>
    </div>
  );
};

export default OperateItemInfo;
