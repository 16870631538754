import React, { useEffect, useState } from 'react';
import {
  Tooltip,
  Select,
  Input,
  Form,
  combineURL,
  useBreadcrumbRoutes,
  useUpdate,
  CustomFilter,
  Wrapper,
} from '@maxtropy/components';
import { useHasPermission } from '@/utils/hooks';
import { BsaListProps, BsaPageReq, getBsaList, HardWareVersion, multiPcsHardWare } from '@/api/bsa';
import { bsaStatusFormat } from '@/api/type';
import { getCustomerUserList } from '@/api/customer';
import { Descriptions, Divider, List, Space } from 'antd';
import styles from './index.module.scss';
import defaultPic from './pic.png';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import { getOuId, getOuListByMcid, Ou } from '@/api/ou';
import { DesktopOutlined } from '@ant-design/icons';
import SetAndAllots from './SetAndAllots';
import ActionContent from './ActionContent';
import { PermissionsType } from '@/common/permissionsConst';
import qs from 'qs';
import DataPointMirror from './DataPointMirror';
import { useLocation } from 'react-router-dom';

const formateUnitForPower = (num?: number) => {
  if (!num) return '--';
  return num < 0.5 ? `${1000 * num}kW` : `${num}MW`;
};
const formateUnitForCapacity = (num?: number) => {
  if (!num) return '--';
  return num < 1 ? `${1000 * num}kWh` : `${num}MWh`;
};

const BSAList: React.FC = () => {
  const routesContext = useBreadcrumbRoutes();
  const hasAllotPermission = useHasPermission(PermissionsType.B_CONFIG_DRIVER);
  const hasBsaPointMirrorPermission = useHasPermission(PermissionsType.B_BSAPOINTMIRROR);
  const [form] = Form.useForm();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const bsaName = urlSearchParams.get('bsaName') || undefined;
  const [searchParams, setSearchParams] = useState<BsaPageReq>({ name: bsaName });
  const [ouList, setOuList] = useState<Ou[]>([]);
  const [data, setData] = useState<BsaListProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [updateState, update] = useUpdate();

  const { data: customerUserList } = useQuery(['customerUserList'], () => getCustomerUserList());

  useEffect(() => {
    setIsLoading(true);
    getBsaList({ ...searchParams }).then(res => {
      setData(res);
      setIsLoading(false);
    });
  }, [searchParams, updateState]);

  // const { data, isLoading, refetch } = useQuery(['bsaPage', searchParams], async () => {
  //   console.log(searchParams);
  //   const res = await getBsaList({ ...searchParams });
  //   if (!res) return [];
  //   return res;
  // });

  const onSelectMcid = async (v: string) => {
    form.setFieldsValue({
      ouId: undefined,
    });
    const ouType = (await getOuId())?.filter(i => i.key === 'MICRONET')[0]?.id;
    if (ouType) {
      getOuListByMcid(v, ouType).then(res => {
        setOuList(res);
      });
    }
  };
  useEffect(() => {
    if (bsaName) {
      form.setFieldsValue({ name: bsaName });
    }
  }, [bsaName, form]);

  const filters = (
    <CustomFilter
      form={form}
      onFinish={(values: any) => {
        setSearchParams(values);
      }}
      onReset={() => {
        setSearchParams({});
        setOuList([]);
      }}
    >
      <Form.Item label="组织名称" name="mcid">
        <Select placeholder="请选择" onSelect={onSelectMcid}>
          {customerUserList?.map(i => (
            <Select.Option key={i.mcid} value={i.mcid}>
              {i.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="运营单元" name="ouId">
        <Select placeholder="请选择">
          {ouList.map(i => (
            <Select.Option key={i.id} value={i.id}>
              {i.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="储能站名称" name="name">
        <Input placeholder="请输入储能站名称" />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper routes={[...(routesContext?.routes ?? [])]} filters={filters} className={styles.wrapper}>
      <List
        className={styles.list}
        itemLayout="vertical"
        dataSource={data}
        loading={isLoading}
        renderItem={item => (
          <List.Item className={styles.content}>
            <div className={styles.leftContent}>
              <Space>
                <DesktopOutlined className={styles.icon} />
                <Tooltip title={item.name}>
                  <p className={styles.name}>{item.name}</p>
                </Tooltip>
              </Space>
              <img src={defaultPic} alt="" />
              <div className={styles.img_bottom_line}>
                额定功率/装机容量 :
                <span className={styles.img_bottom_num}>
                  {formateUnitForPower(item.ratedPower) + ' / ' + formateUnitForCapacity(item.installedCapacity)}
                </span>
              </div>
              <div className={styles.img_bottom_line}>
                硬件结构版本 :
                <span className={styles.img_bottom_num}>{`${item.hardwareVersion ?? ''}-${item.hardwareName}`}</span>
              </div>
            </div>
            <div>
              <Descriptions
                layout="vertical"
                colon={false}
                column={
                  item.hardwareVersion && multiPcsHardWare.includes(item.hardwareVersion as HardWareVersion) ? 10 : 9
                }
              >
                <Descriptions.Item label="状态">{bsaStatusFormat[item.status] ?? '--'}</Descriptions.Item>
                <Descriptions.Item label="运营单元">
                  {item.ouId ? (
                    <span
                      style={{ color: '#4a90e2' }}
                      onClick={() =>
                        window.open(
                          combineURL(window.IOTPLATFORMORIGIN, `/device/ou/production-unit/detail/${item.ouId}`),
                          '_blank'
                        )
                      }
                    >
                      {item.ouName}
                    </span>
                  ) : (
                    '--'
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  label={!multiPcsHardWare.includes(item.hardwareVersion as HardWareVersion) ? '对应FCS' : '主控FCS'}
                >
                  {item.fcsId ? (
                    <span
                      style={{ color: '#4a90e2' }}
                      onClick={() =>
                        window.open(
                          combineURL(window.IOTPLATFORMORIGIN, `/device/fcs/list/detail/${item.fcsId}`),
                          '_blank'
                        )
                      }
                    >
                      {item.fcsName}
                    </span>
                  ) : (
                    '--'
                  )}
                </Descriptions.Item>
                {multiPcsHardWare.includes(item.hardwareVersion as HardWareVersion) &&
                item.softwareVersion !== 'FM/1.0' ? (
                  <Descriptions.Item label={'主控FCS所在组网'}>
                    {item.edgeNetworkId ? (
                      <span
                        style={{ color: '#4a90e2' }}
                        onClick={() => {
                          const params = {
                            netNo: item.edgeNetworkNo,
                          };
                          window.open(
                            `${window.IOTPLATFORMORIGIN}/device/networking/networkingList?${qs.stringify(params)}`,
                            '_blank'
                          );
                        }}
                      >
                        {item.edgeNetworkName}
                      </span>
                    ) : (
                      '--'
                    )}
                  </Descriptions.Item>
                ) : null}
                <Descriptions.Item label="储能柜个数">1</Descriptions.Item>
                <Descriptions.Item label="与PCS连接方式">阵列内包含PCS</Descriptions.Item>
                <Descriptions.Item label="总电量">{item.totalPower.toString()}</Descriptions.Item>
                {/* 配置与阵列下发按钮 */}

                <Descriptions.Item
                  labelStyle={{ display: 'grid' }}
                  span={1}
                  label={
                    <>
                      {hasAllotPermission && (
                        <SetAndAllots
                          status={item.status}
                          bsaId={item.id}
                          bsaUiUpdate={item.bsaUiUpdate}
                          bsaConfig={item.bsaConfig}
                          onClick={update}
                          bsaDeliverStatus={item.bsaDeliverStatus}
                        />
                      )}
                    </>
                  }
                  // children={null}
                >
                  {hasBsaPointMirrorPermission &&
                    (multiPcsHardWare.includes(item.hardwareVersion as HardWareVersion) &&
                    item.softwareVersion !== 'FM/1.0' ? (
                      <DataPointMirror bsaInfo={item} />
                    ) : (
                      <></>
                    ))}
                </Descriptions.Item>
              </Descriptions>

              <Divider />
              <Descriptions layout="vertical" colon={false} column={5}>
                <Descriptions.Item
                  label="拓扑结构"
                  contentStyle={{
                    paddingRight: 10,
                  }}
                >
                  储能柜组-储能柜-PCS{item.hasRack && '-电池簇'}
                  {item.hasPack && '-电池组'}
                  {item.hasCell && '-电池芯'}
                </Descriptions.Item>
                <Descriptions.Item label="创建时间">
                  {dayjs(item.createTime).format('YYYY-MM-DD HH:mm:ss')}
                </Descriptions.Item>
                <Descriptions.Item label="创建人">{item.createUser ?? '--'}</Descriptions.Item>
                <Descriptions.Item label="最后编辑时间">
                  {dayjs(item.updateTime).format('YYYY-MM-DD HH:mm:ss')}
                </Descriptions.Item>
                <Descriptions.Item label="最后编辑人">{item.lastUpdateUser ?? '--'}</Descriptions.Item>
              </Descriptions>
              {/* 操作行 */}
              <ActionContent
                id={item.id}
                bsaConfig={item.bsaConfig}
                bsaDeliverStatus={item.bsaDeliverStatus}
                bsaUiAirConfigByDevice={item.bsaUiAirConfigByDevice}
                status={item.status}
                hasRack={item.hasRack}
                hasPack={item.hasPack}
                hasCell={item.hasCell}
                hardwareVersion={item.hardwareVersion}
                update={update}
              />
            </div>
          </List.Item>
        )}
      />
    </Wrapper>
  );
};

export default BSAList;
