import { V2ReportListPostResponse } from '@maxtropy/dmes-apis-v2';
import { Table as AntdTable } from 'antd';
import React from 'react';
import { customFixed } from '@/utils/utils';
import { EllipsisSpan } from '@maxtropy/components';
import styles from './index.module.scss';
import classnames from 'classnames';

type SummaryKey = keyof Exclude<V2ReportListPostResponse['total'], undefined>;

export const getTableSummary = (
  data: V2ReportListPostResponse['total'],
  showIncome: boolean,
  showTimePeriod: boolean
) => {
  const list: { key: SummaryKey; hidden?: boolean }[] = [
    {
      key: 'totalChargeGeneration',
    },
    {
      key: 'summitChargeGeneration',
      hidden: !showTimePeriod,
    },
    {
      key: 'peakChargeGeneration',
      hidden: !showTimePeriod,
    },
    {
      key: 'plainChargeGeneration',
      hidden: !showTimePeriod,
    },
    {
      key: 'valleyChargeGeneration',
      hidden: !showTimePeriod,
    },
    {
      key: 'totalChargeOnGrid',
    },
    {
      key: 'summitChargeOnGrid',
      hidden: !showTimePeriod,
    },
    {
      key: 'peakChargeOnGrid',
      hidden: !showTimePeriod,
    },
    {
      key: 'plainChargeOnGrid',
      hidden: !showTimePeriod,
    },
    {
      key: 'valleyChargeOnGrid',
      hidden: !showTimePeriod,
    },
    {
      key: 'totalChargeConsumption',
    },
    {
      key: 'consumptionRate',
    },
    {
      key: 'summitChargeConsumption',
      hidden: !showTimePeriod,
    },
    {
      key: 'peakChargeConsumption',
      hidden: !showTimePeriod,
    },
    {
      key: 'plainChargeConsumption',
      hidden: !showTimePeriod,
    },
    {
      key: 'valleyChargeConsumption',
      hidden: !showTimePeriod,
    },
    {
      key: 'onlineIncome',
      hidden: !showIncome,
    },
    {
      key: 'summitOnlineIncome',
      hidden: !showTimePeriod || !showIncome,
    },
    {
      key: 'peakOnlineIncome',
      hidden: !showTimePeriod || !showIncome,
    },
    {
      key: 'plainOnlineIncome',
      hidden: !showTimePeriod || !showIncome,
    },
    {
      key: 'valleyOnlineIncome',
      hidden: !showTimePeriod || !showIncome,
    },
    {
      key: 'consumptionIncome',
      hidden: !showIncome,
    },
    {
      key: 'summitConsumptionIncome',
      hidden: !showTimePeriod || !showIncome,
    },
    {
      key: 'peakConsumptionIncome',
      hidden: !showTimePeriod || !showIncome,
    },
    {
      key: 'plainConsumptionIncome',
      hidden: !showTimePeriod || !showIncome,
    },
    {
      key: 'valleyConsumptionIncome',
      hidden: !showTimePeriod || !showIncome,
    },
    {
      key: 'subsidyIncome',
      hidden: !showIncome || !showIncome,
    },
    {
      key: 'income',
      hidden: !showIncome || !showIncome,
    },
  ];

  return (
    <AntdTable.Summary fixed="top">
      <AntdTable.Summary.Row>
        <AntdTable.Summary.Cell index={0}>总计</AntdTable.Summary.Cell>
        {list
          .filter(item => !item.hidden)
          .map((item, index) => {
            return (
              <AntdTable.Summary.Cell index={index + 1}>
                <EllipsisSpan
                  value={customFixed(data?.[item.key])}
                  className={classnames(styles.warningColor, styles.textNoWrap)}
                ></EllipsisSpan>
              </AntdTable.Summary.Cell>
            );
          })}
      </AntdTable.Summary.Row>
    </AntdTable.Summary>
  );
};
