import React from 'react';
import styles from './index.module.scss';
import TestDesc from './components/TestDesc';
import TodayRunning from './components/TodayRunning';
import EfficiencyAnalysis from './components/EfficiencyAnalysis';
import { useRequest } from 'ahooks';
import { apiV2PvNoticeboardIndicatorPost } from '@maxtropy/dmes-apis-v2';

const CenterLeftBox = () => {
  // 指标数据(今日运行数据、效率分析数据)
  const { data } = useRequest(() => {
    return apiV2PvNoticeboardIndicatorPost({});
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <TestDesc />
      </div>
      <div className={styles.center}>
        <TodayRunning data={data} />
      </div>
      <div className={styles.bottom}>
        <EfficiencyAnalysis data={data} />
      </div>
    </div>
  );
};

export default CenterLeftBox;
