import React, { FC, useContext, useState } from 'react';
import styles from './index.module.scss';
import { ClockCircleOutlined, FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { Tag, Tooltip } from '@maxtropy/components';
import { Space } from 'antd';
import location from '../../imgs/location.png';
import codepen from '../../imgs/codepen.png';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import { ShareDataContext } from '../..';
import { keepTwoOrLine } from '../../utils';

interface IHeaderProps {
  fullBtn: () => void;
  isFullScreen: boolean;
}

const Header: FC<IHeaderProps> = props => {
  const { fullBtn, isFullScreen } = props;
  const { pvInfo } = useContext(ShareDataContext);
  const [currentTime, setCurrentTime] = useState<string>();

  useRequest(
    async () => {
      setCurrentTime(dayjs().format('YYYY-MM-DD HH:mm:ss'));
    },
    {
      pollingInterval: 2 * 60000,
    }
  );

  return (
    <div className={styles.header_area_box}>
      <div className={styles.date_box}>
        <Space size={16}>
          <div className={styles.location}>
            <img className={styles.imgSty} src={location} alt="" />
            <Tooltip
              title={(pvInfo?.location ?? '-') + (pvInfo?.address ?? '-')}
              getPopupContainer={() => document.getElementById('pv_full_screen_box') ?? document.body}
            >
              <div className={styles.addressSty}>地点：{(pvInfo?.location ?? '-') + (pvInfo?.address ?? '-')}</div>
            </Tooltip>
          </div>
          <Tag>
            <img className={styles.tag_img} src={codepen} alt="" />
            容量：{keepTwoOrLine(pvInfo?.installedCapacity)}kW
          </Tag>
        </Space>
      </div>
      <div className={styles.title_box}>分布式智能光伏实证基地</div>
      <div className={styles.set_box}>
        {/* 全屏按钮 */}
        <div className={styles.refreshTimeSty}>
          <ClockCircleOutlined style={{ marginRight: 4 }} />
          页面刷新时间：{currentTime}
        </div>
        <div className={styles.btn} onClick={fullBtn}>
          {isFullScreen ? (
            <FullscreenExitOutlined className={styles.icon} />
          ) : (
            <FullscreenOutlined className={styles.icon} />
          )}
          &nbsp;
          <span className={styles.text}>{isFullScreen ? '退出' : '全屏'}</span>
          <div className={styles.bottomLine} />
        </div>
      </div>
    </div>
  );
};

export default Header;
