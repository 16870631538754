import { AutoComplete } from 'antd';

import { Key, useEffect, useState } from 'react';

/**
 *
 *  型号下拉框可输可选
 */
interface Iprops {
  value?: Key;
  onChange?: (value: Key) => void;
  disabled?: boolean;
  options?: { label: string; value: Key }[];
}
const DeviceModelSelect = ({ value, onChange, disabled, options }: Iprops) => {
  const [currentValue, setCurrentValue] = useState<Key>();

  useEffect(() => {
    if (value) {
      setCurrentValue(value);
    }
  }, [value]);

  return (
    <AutoComplete
      disabled={disabled}
      style={{ width: '100%' }}
      showSearch
      placeholder="请输入"
      value={currentValue}
      onChange={v => {
        onChange?.(v);
      }}
      options={options}
      filterOption={(inputValue, option) => option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
    />
  );
};

export default DeviceModelSelect;
