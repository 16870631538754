import { Table } from '@maxtropy/components';
import { useContext, useMemo } from 'react';
import { ShareContext } from '../..';
import { BsaDeviceType, RealData } from '../../type';
import { getCommonTableData, sortDeviceList, useGetCommonTableColumns } from '../utils';
import styles from './index.module.scss';
interface Iprops {
  list?: RealData;
}
const CommonTable = ({ list }: Iprops) => {
  const { realData } = useContext(ShareContext);
  const data = useMemo(() => {
    let temp = realData?.filter(item => (item.bsaDeviceType as number) !== BsaDeviceType.CONNECT);
    return sortDeviceList(temp);
  }, [realData]);
  const columns = [
    {
      title: '',
      children: [
        {
          title: '名称',
          dataIndex: 'datapropertyName',
          width: 150,
          fixed: 'left' as const,
        },
      ],
    },
    ...useGetCommonTableColumns(data),
  ];
  const dataSource = getCommonTableData(data);
  return (
    <div className={styles.common_table}>
      <Table rowKey="datapropertyId" columns={columns} dataSource={dataSource} scroll={{ x: 'max-content' }} />
    </div>
  );
};
export default CommonTable;
