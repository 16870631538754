import { changeCombinerBoxDevice, CombinerBoxProps, deleteCombinerBox, removeCombinerBoxDevice } from '@/api/pv';
import { PVStatus } from '@/api/type';
import SelectDeviceModal, { PVType } from '@/components/SelectDeviceModal';
import { Button, PopConfirm } from '@maxtropy/components';
import { useMemo, useState } from 'react';

interface Iporps extends CombinerBoxProps {
  update?: () => void;
  deleteOk?: () => void;
  editBtn: () => void;
  status?: PVStatus;
  ouId: number;
  pvId: any;
}
const ListBtns: React.FC<Iporps> = props => {
  const [deviceModalVisible, setDeviceModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState<CombinerBoxProps>();
  const [deviceModalLoading, setDeviceModalLoading] = useState(false);
  const showDeleteBtn = useMemo(
    () => props.status === PVStatus.DRAFT || props.status === PVStatus.DISABLE,
    [props.status]
  );

  // 移除设备
  const removeDevice = (id: any) => {
    removeCombinerBoxDevice(id).then(res => {
      if (!res) return;
      props.update && props.update();
    });
  };
  // 更换设备
  const changeDevice = (v: CombinerBoxProps) => {
    setDeviceModalVisible(true);
    setSelectedRow(v);
  };
  // 移除汇流箱
  const removeCombinerBox = (id?: number) => {
    deleteCombinerBox(id).then(res => {
      if (!res) return;
      // 删除最后一页最后一条需要考虑页码情况
      props.deleteOk && props.deleteOk();
    });
  };
  // 选择设备确认按钮
  const deviceModalConfirm = (deviceId: number) => {
    setDeviceModalLoading(true);
    changeCombinerBoxDevice(selectedRow?.id, deviceId)
      .then(res => {
        if (!res) return;
        setDeviceModalVisible(false);
        props.update && props.update();
      })
      .finally(() => setDeviceModalLoading(false));
  };
  // 设备弹框取消
  const deviceModalCancel = () => {
    setDeviceModalVisible(false);
  };
  return (
    <>
      <div className="action-buttons">
        <Button danger type="link" onClick={props.editBtn}>
          编辑
        </Button>
        <PopConfirm
          title={
            <>
              <div>请注意 , 移除后无法查询该设备数据 ! </div>
              <div>你还要继续吗 ?</div>
            </>
          }
          onConfirm={() => removeDevice(props.id)}
          okText="确定"
          cancelText="取消"
        >
          <Button type="link" disabled={!props.deviceId}>
            移除设备
          </Button>
        </PopConfirm>
        <Button type="link" onClick={() => changeDevice(props)}>
          {props.deviceId ? '更换设备' : '绑定设备'}
        </Button>
        {showDeleteBtn && (
          <PopConfirm
            title="确认删除汇流箱吗?"
            onConfirm={() => removeCombinerBox(props.id)}
            okText="确定"
            cancelText="取消"
          >
            <Button type="link">删除</Button>
          </PopConfirm>
        )}
      </div>
      {/* 更换设备弹框 */}
      {deviceModalVisible && (
        <SelectDeviceModal
          pvId={props.pvId}
          pvType={PVType.COMBINER_BOX}
          ouId={props.ouId}
          loading={deviceModalLoading}
          initialDeviceId={selectedRow?.deviceId}
          typeName="汇流箱"
          onOk={deviceModalConfirm}
          onCancel={deviceModalCancel}
        />
      )}
    </>
  );
};

export default ListBtns;
