import {
  ammeterColorList,
  bsaColor,
  BsaType,
  dash2LegendIcon,
  inPowerColor,
  inPowerWithoutBsaColor,
  pcsColorList,
  solidLegendIcon,
  TimeGranularity,
  upperTransformerMeterPowerChartsColorList,
} from '@/pages/BsaHistoryData/const';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  apiV2BsaOperationOverviewBsaOperatingCurveOldNeoPost,
  V2BsaOperationOverviewBsaOperatingCurveOldNeoPostResponse,
} from '@maxtropy/dmes-apis-v2';
import { isNil } from 'lodash-es';
import { Chart_Grid_Right, getEndLabel } from '.';
import styles from '@/pages/BsaHistoryData/components/OperatingPower/index.module.scss';
import ReactEcharts from 'echarts-for-react';
import { Empty } from '@maxtropy/components';
import { Spin } from 'antd';

interface Props {
  bsaId: number | undefined;
  bsaType: BsaType | undefined;
  date: Dayjs;
  timeGranularity: TimeGranularity;
}

const MinuteChart: FC<Props> = props => {
  const { bsaId, bsaType, date, timeGranularity } = props;
  const [resData, setResData] = useState<V2BsaOperationOverviewBsaOperatingCurveOldNeoPostResponse>();
  const [loading, setLoading] = useState(false);

  // 请求参数，防止快速切换时，接口返回速度慢导致数据显示错乱
  const cacheParams = useRef({ bsaId, date, timeGranularity });

  const fetchData = async () => {
    if (isNil(bsaId) || isNil(bsaType) || isNil(date) || isNil(timeGranularity)) return;
    cacheParams.current = { bsaId, date, timeGranularity };
    const res = await apiV2BsaOperationOverviewBsaOperatingCurveOldNeoPost({
      bsaId,
      bsaType,
      timeResolution: timeGranularity === TimeGranularity.ONE_MINUTE ? '1M' : '15M',
      time: date.startOf('day').unix().toString(),
    });
    if (
      cacheParams.current.bsaId !== bsaId ||
      cacheParams.current.date !== date ||
      cacheParams.current.timeGranularity !== timeGranularity
    ) {
      return;
    }
    setResData(res);
  };

  useEffect(() => {
    setLoading(true);
    setResData(undefined);
    fetchData()
      .then()
      .finally(() => setLoading(false));
    if (date?.isSame(dayjs(), 'day')) {
      const timer = setInterval(() => {
        fetchData().then();
      }, 1000 * 60);
      return () => clearInterval(timer);
    }
  }, [bsaId, bsaType, date, timeGranularity]);

  const echartsOption = useMemo(() => {
    const option = {
      grid: {
        left: 50,
        right: Chart_Grid_Right + 10,
        top: 70,
        bottom: 80,
        containLabel: true,
      },
      xAxis: {
        type: 'time',
        axisLabel: {
          color: 'rgba(255,255,255,0.85)',
        },
        axisLine: {
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
      },
      yAxis: {
        name: '功率(kW)',
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        type: 'value',
        axisLabel: {
          color: 'rgba(255,255,255,0.85)',
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
        scale: true,
        boundaryGap: ['5%', '5%'],
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderColor: 'transparent',
        textStyle: {
          color: 'rgba(255,255,255,0.65)',
        },
        formatter(items: any[]) {
          const { axisValueLabel } = items[0];
          let str: string = axisValueLabel;

          items.forEach(item => {
            const { seriesName, data, marker } = item;
            const value = !isNil(data[1]) ? `${data[1].toFixed(4)}` : '--';
            str += `<div style="display:flex;margin-top:4px;justify-content:space-between;gap:30px;"><span>${marker}${seriesName}</span> <span>${value}kW</span></div>`;
          });

          return str;
        },
      },
      legend: {
        show: true,
        type: 'scroll',
        right: 20,
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
          fontSize: '14px',
        },
        itemWidth: 16,
        itemHeight: 4,
        data: [],
      },
      dataZoom: [{ start: 0, end: 100 }],
      series: [],
    };

    let legendData = option.legend.data as any[];
    let series = option.series as any[];

    legendData.push({
      name: '储能功率',
      icon: solidLegendIcon,
      itemStyle: {
        color: bsaColor,
      },
    });
    series.push({
      name: '储能功率',
      type: 'line',
      showSymbol: false,
      itemStyle: {
        color: bsaColor,
      },
      data: resData?.bsaPower?.map(item => [item.ts, item.value]),
      ...getEndLabel(resData?.bsaPower?.map(item => [item.ts, item.value]) as any[]),
    });

    // 生产负载(不含储能的进线功率)
    legendData.push({
      name: '不含储能的进线功率',
      icon: solidLegendIcon,
      itemStyle: {
        color: inPowerWithoutBsaColor,
      },
    });
    series.push({
      name: '不含储能的进线功率',
      type: 'line',
      showSymbol: false,
      itemStyle: {
        color: inPowerWithoutBsaColor,
      },
      data: resData?.incomingWzBsaPower?.map(item => [item.ts, item.value]),
      ...getEndLabel(resData?.incomingWzBsaPower?.map(item => [item.ts, item.value]) as any[]),
    });

    // 进线功率
    legendData.push({
      name: '进线功率',
      icon: solidLegendIcon,
      itemStyle: {
        color: inPowerColor,
      },
    });
    series.push({
      name: '进线功率',
      type: 'line',
      showSymbol: false,
      itemStyle: {
        color: inPowerColor,
      },
      data: resData?.incomingPower?.map(item => [item.ts, item.value]),
      ...getEndLabel(resData?.incomingPower?.map(item => [item.ts, item.value]) as any[]),
    });

    // 上端变压器功率
    let upTransformerList =
      bsaType === BsaType.OLD ? resData?.electricityMeterUpperEnd : resData?.upperTransformerMeterPower;
    upTransformerList?.forEach((item, index) => {
      legendData.push({
        name: item.name,
        icon: solidLegendIcon,
        itemStyle: {
          color: ammeterColorList[index % ammeterColorList.length],
        },
      });
      series.push({
        name: item.name,
        type: 'line',
        showSymbol: false,
        itemStyle: {
          color: ammeterColorList[index % ammeterColorList.length],
        },
        data: item.list?.map(dataItem => [dataItem.ts, dataItem.value]) ?? [],
        ...getEndLabel(item.list?.map(dataItem => [dataItem.ts, dataItem.value]) as any[]),
      });
    });

    // 各pcs功率
    resData?.pcsCurve?.forEach((item, index) => {
      legendData.push({
        name: item.name,
        icon: dash2LegendIcon,
        itemStyle: {
          color: pcsColorList[index % pcsColorList.length],
        },
      });
      series.push({
        name: item.name,
        type: 'line',
        showSymbol: false,
        itemStyle: {
          color: pcsColorList[index % pcsColorList.length],
        },
        lineStyle: {
          type: [6, 6],
        },
        smooth: true,
        data: item.list?.map(dataItem => [dataItem.ts, dataItem.value]) ?? [],
        ...getEndLabel(item.list?.map(dataItem => [dataItem.ts, dataItem.value]) as any[]),
      });
    });

    // 不含储能的上端变压器功率
    const upperTransformerMeterPower =
      bsaType === BsaType.OLD ? resData?.transformerPower : resData?.upperTransformerMeterPowerV2;
    upperTransformerMeterPower?.forEach((item, index) => {
      legendData.push({
        name: (item.name ?? '--') + '（不含储能）',
        icon: solidLegendIcon,
        itemStyle: {
          color: upperTransformerMeterPowerChartsColorList[index % upperTransformerMeterPowerChartsColorList.length],
        },
      });
      series.push({
        name: (item.name ?? '--') + '（不含储能）',
        type: 'line',
        showSymbol: false,
        itemStyle: {
          color: upperTransformerMeterPowerChartsColorList[index % upperTransformerMeterPowerChartsColorList.length],
        },
        data: item.list?.map(dataItem => [dataItem.ts, dataItem.value]) ?? [],
        ...getEndLabel(item.list?.map(dataItem => [dataItem.ts, dataItem.value]) as any[]),
      });
    });

    return option;
  }, [resData, bsaType]);

  const notEmpty = useMemo(() => {
    return echartsOption.series?.some((item: any) => {
      return item.data?.length;
    });
  }, [echartsOption]);

  return (
    <Spin spinning={loading} wrapperClassName={styles.wrap}>
      {notEmpty ? (
        <ReactEcharts option={echartsOption} notMerge className={styles.chart}></ReactEcharts>
      ) : (
        <Empty className={styles.empty} />
      )}
    </Spin>
  );
};

export default MinuteChart;
