import { SettingOutlined } from '@ant-design/icons';
import { Button, EllipsisSpan, Tag } from '@maxtropy/components';
import { apiV2DeviceDataPropertyRecentPost } from '@maxtropy/dmes-apis-v2';
import { Space } from 'antd';
import { spawn } from 'child_process';
import dayjs, { Dayjs } from 'dayjs';
import { chunk } from 'lodash-es';
import qs from 'qs';
import { Key, useEffect, useState } from 'react';
import { isInputElement } from 'react-router-dom/dist/dom';
import { useGetDataPropertyInfo, useGetDataPropertyInfoByChunks } from '../hooks';
import { AlarmStatusTagColor, BsaDeviceType, MapValueObj, OpStatusTagColor, RealData } from '../type';
import MeterTitle from './Meter/MeterTitle';
import { rowType, RowTypeDisplay } from './PCS';
import PcsTitle from './PCS/PcsTitle';

/**
 * 获取数据属性
 * @param list
 * @returns
 */
export const getDataPropertyList = (list: RealData) => {
  let dataPropertyValues = list?.flatMap(item => item.dataPropertyValues ?? []);
  // 记录datapropertyId和其他值的映射关系
  let tempMap = new Map<
    number,
    { datapropertyType?: number; isWriteable?: boolean; enumMap?: any; generalName?: string }
  >();

  // 去重处理
  let obj: Record<string, string> = {};
  dataPropertyValues?.forEach(item => {
    if (!item.datapropertyId) return;
    if (obj[item.datapropertyId]) return;
    tempMap.set(item.datapropertyId, {
      datapropertyType: item.datapropertyType,
      isWriteable: item.isWriteable,
      enumMap: item.enumMap,
      generalName: item.generalName,
    });
    obj[item.datapropertyId] = item.datapropertyName ?? '';
  });
  let newList = Object.entries(obj).map(([key, value]) => {
    return {
      datapropertyId: +key,
      datapropertyName: value,
      ...(tempMap.get(+key) ?? {}),
    };
  });

  // 数据排序
  /**
   * 1.
   *   每个电表第一个数据属性，都要是总有功功率10017, 其他数据属性展示顺序以数据属性id从小到大展示
   *   遥测值区域: 第一个电池堆充电电量(10398)，第二个展示电池堆放电电量(10399)，第三个电池堆的SOC(10055)
   * 2. 遥测、报警/故障、状态三个分类
   * 3. 可写的数据点，排在最前面, id展示递增
   *
   */
  let headIdList = [10017, 10055, 10398, 10399];
  let headListData = newList
    .filter(item => headIdList.includes(item.datapropertyId))
    .sort((a, b) => a.datapropertyId - b.datapropertyId);
  let socIndex = headListData.findIndex(item => item.datapropertyId === 10055);
  if (socIndex >= 0) {
    let socData = headListData[socIndex];
    headListData.splice(socIndex, 1);
    headListData = [...headListData, socData];
  }
  let endListData = newList.filter(item => !headIdList.includes(item.datapropertyId));

  let isWriteList = endListData.filter(item => item.isWriteable);
  let unWriteList = endListData.filter(item => !item.isWriteable);
  let sortList = [isWriteList, unWriteList].flatMap(item => item.sort((a, b) => a.datapropertyId - b.datapropertyId));
  return [...headListData, ...sortList];
};

// 设备列表排序 进线电表排在第一个
export const sortDeviceList = (list: RealData) => {
  let newList = [...(list ?? [])];
  let inLineIndex = newList.findIndex(item => (item.bsaDeviceType as number) === BsaDeviceType.INCOMMING);
  if (newList.length && inLineIndex && inLineIndex > 0) {
    let tempData = newList[inLineIndex];
    newList.splice(inLineIndex, 1);
    newList = [tempData, ...newList];
  }
  return newList;
};

// 获取设备列表
export const getDeviceList = (list: RealData) => {
  return list?.map(({ deviceId, deviceName, bsaDeviceType, multiplier, dataPropertyValues }) => ({
    deviceId,
    deviceName,
    bsaDeviceType,
    multiplier,
    datapropertyIds: dataPropertyValues?.filter(item => item.datapropertyId).map(item => item.datapropertyId!) ?? [],
  }));
};
// 获取唯一key, 数据属性+设备id, 后续查询数据用
export const getDeviceWithDatapropertyKey = (list: RealData, datapropertyId: number) => {
  let temp = list?.map(({ deviceId }, index) => ({
    [`data${index}`]: `${deviceId}-${datapropertyId}-data`,
    [`time${index}`]: `${deviceId}-${datapropertyId}-time`,
  }));
  // 解构数组对象
  return temp?.reduce((acc, curr) => {
    return { ...acc, ...curr };
  }, {});
};

// 非并网带点电表表格数据
export const getCommonTableData = (list: RealData) => {
  let tempList = getDataPropertyList(list);

  return tempList.map(item => {
    return {
      ...item,
      rowType: rowType.yc, // 用来区分 遥测/故障告警/状态
      ...getDeviceWithDatapropertyKey(list, item.datapropertyId),
    };
  });
};
// 非并网带点电表表格结构
export const useGetCommonTableColumns = (list: RealData) => {
  let deviceList = getDeviceList(list) ?? [];
  const valuesMap = useGetDataPropertyInfoByChunks(list);

  let dataCounts = deviceList.length;
  let headList = deviceList?.map((item, index) => {
    // 在表格中所在的列数
    const columnIndex = index * 2 + 1;
    const showBg = columnIndex % 4 === 1 && dataCounts > 1;
    return {
      title: (
        <MeterTitle
          type={item.bsaDeviceType}
          value={item.multiplier}
          deviceId={item.deviceId}
          title={item.deviceName}
        />
      ),
      children: [
        {
          title: '数据',
          dataIndex: 'data' + index,
          width: 200,
          className: showBg ? 'self_color' : '', // 符合4n + 1
          render: (v: string, record: any) => {
            let [deviceId, datapropertyId] = v?.split('-') ?? [];

            return getValueByPropertyId({
              datapropertyId,
              datapropertyIds: item.datapropertyIds,
              deviceId,
              valuesMap,
              type: record.rowType,
              unit: record.generalName,
              enumMap: record.enumMap,
            });
          },
        },
        {
          title: '更新时间',
          dataIndex: 'time' + index,
          width: 200,
          className: showBg ? 'self_color' : '',
          render: (v: string) => {
            let [deviceId, datapropertyId] = v?.split('-') ?? [];

            return getTsByPropertyId(datapropertyId, deviceId, valuesMap);
          },
        },
      ],
    };
  });

  return [...headList];
};

// pcs设备表格结构
interface PcsTableColumnsProps {
  list: RealData;
  remoteOp?: (record: any) => void;
  setOp?: (v: number) => void;
  hasRemoteBtn?: boolean;
  hasConfigBtn?: boolean;
}
export const useGetPcsTableColumns = ({ list, remoteOp, setOp, hasRemoteBtn, hasConfigBtn }: PcsTableColumnsProps) => {
  const sharedOnCell = (_: any, index: number) => {
    // if (rowNumber.includes(index)) {
    //   return { colSpan: 0 };
    // }
    return {};
  };
  const valuesMap = useGetDataPropertyInfoByChunks(list);

  let deviceList = getDeviceList(list) ?? [];
  let dataCounts = deviceList.length;
  let headList = deviceList?.map((item, index) => {
    // 在表格中所在的列数
    let columnIndex = index * 2 + 1;
    let showBg = columnIndex % 4 === 1 && dataCounts > 1;
    return {
      title: <PcsTitle title={item.deviceName} deviceId={item.deviceId} />,
      children: [
        {
          title: '数据',
          dataIndex: 'data' + index,
          width: 200,
          className: showBg ? 'self_color' : '', // 符合4n + 1
          render: (v: string, record: any) => {
            let [deviceId, datapropertyId] = v?.split('-') ?? [];

            return getValueByPropertyId({
              datapropertyId,
              datapropertyIds: item.datapropertyIds,
              deviceId,
              valuesMap,
              type: record.rowType,
              unit: record.generalName,
              enumMap: record.enumMap,
            });
          },
        },
        {
          title: '更新时间',
          dataIndex: 'time' + index,
          width: 200,
          className: showBg ? 'self_color' : '',
          render: (v: string) => {
            let [deviceId, datapropertyId] = v?.split('-') ?? [];
            return getTsByPropertyId(datapropertyId, deviceId, valuesMap);
          },
        },
        {
          title: '操作',
          dataIndex: 'data' + index,
          width: 100,
          className: showBg ? 'self_color' : '',
          render: (v: string, record: any) => {
            let [deviceId, datapropertyId] = v?.split('-') ?? [];
            let hasConfig = item.datapropertyIds.includes(+datapropertyId);
            if (record.isWriteable && hasRemoteBtn && hasConfig) {
              return (
                <Button type="link" onClick={() => remoteOp?.({ ...record, deviceId: item.deviceId })}>
                  远控
                </Button>
              );
            }
            return '--';
          },
        },
      ],
    };
  });

  return [
    {
      title: '',
      fixed: 'left' as const,
      children: [
        {
          title: '名称',
          dataIndex: 'datapropertyName',
          width: 180,
          fixed: 'left' as const,
          // onCell: (_: any, index: number) => ({
          //   colSpan: rowIndexList.includes(index) ? restColumns.length * 3 + 1 : 1,
          // }),
          render: (v: any, record: any, index: number) => {
            if ([rowType.alarm, rowType.status].includes(record.datapropertyName)) {
              return (
                <Space size={8}>
                  {RowTypeDisplay[v]}
                  {hasConfigBtn && (
                    <Space
                      size={5}
                      style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
                      onClick={() => {
                        setOp?.(v);
                      }}
                    >
                      <SettingOutlined />
                      配置
                    </Space>
                  )}
                </Space>
              );
            }
            return v;
          },
        },
      ],
    },
    ...headList,
  ];
};
// 数值格式化
interface GetValueByPropertyIdProps {
  datapropertyIds?: number[]; // 当前设备配置的属性
  datapropertyId?: Key;
  deviceId?: Key;
  valuesMap?: Map<string, MapValueObj>;
  unit?: string;
  type?: number; // 遥测? 故障告警? 状态?
  enumMap?: any;
}
export const getValueByPropertyId = ({
  datapropertyIds,
  datapropertyId,
  deviceId,
  valuesMap,
  unit,
  type,
  enumMap,
}: GetValueByPropertyIdProps) => {
  if (!datapropertyId || !deviceId) return '--';
  if (datapropertyIds && !datapropertyIds.includes(+datapropertyId)) {
    return <EllipsisSpan value={'未进行数据点配置'} />;
  }
  let tempValues = valuesMap?.get(`${deviceId}-${datapropertyId}`)?.value;
  if (isEmpty(tempValues)) return '--';
  let mapLabel = (enumMap ?? {})[tempValues!] ?? '';
  let mapColor = AlarmStatusTagColor[tempValues!] ?? 'info';
  if (type === rowType.alarm) {
    return (
      <>
        <Space>
          {tempValues}
          {mapLabel ? (
            <Tag border="solid" color={mapColor}>
              {mapLabel}
            </Tag>
          ) : (
            ''
          )}
        </Space>
      </>
    );
  }
  if (type === rowType.status) {
    return `${tempValues}${mapLabel ? '(' + mapLabel + ')' : ''}`;
  }

  return typeof tempValues === 'number' ? parseFloat(tempValues.toFixed(4)) + (unit ?? '') : '--';
};

// 时间格式化
export const getTsByPropertyId = (properId?: Key, deviceId?: Key, valueMap?: Map<string, MapValueObj>) => {
  if (properId) {
    let tempValues = valueMap?.get(`${deviceId}-${properId}`)?.ts;
    return <EllipsisSpan value={tempValues ? dayjs(tempValues).format('YYYY-MM-DD HH:mm:ss') : '--'} />;
  }
  return '--';
};

// 跳转到dc设备历史数据页面

export const goToDeviceHistoryPage = (deviceId?: Key, specialCategoryId?: number) => {
  const params = qs.stringify(
    {
      deviceId,
      specialCategoryId,
    },
    { addQueryPrefix: true }
  );
  window.open(`${window.IOTPLATFORMORIGIN}/data/history/device${params}`, '_self');
};

export const isEmpty = (value: any) => {
  if (value === undefined || value === null) return true;
  return false;
};
