import { fetch } from '@maxtropy/components';
import { Key } from 'react';
import { BsaDeviceTypeEnum, DataProperty } from './type';
import qs from 'qs';

export function getRackDataPropertyList(bsaId: Key) {
  return fetch<DataProperty[]>(`/api/data-property/list/rack?bsaId=${bsaId}`);
}

export function getPackDataPropertyList(bsaId: Key) {
  return fetch<DataProperty[]>(`/api/data-property/list/pack?bsaId=${bsaId}`);
}

export function getCellDataPropertyList(bsaId: Key) {
  return fetch<DataProperty[]>(`/api/data-property/list/cell?bsaId=${bsaId}`);
}

export const getDataPropertyList = (bsaId: Key, type: BsaDeviceTypeEnum) => {
  return type === BsaDeviceTypeEnum.PACK
    ? getPackDataPropertyList(bsaId)
    : type === BsaDeviceTypeEnum.CELL
    ? getCellDataPropertyList(bsaId)
    : getRackDataPropertyList(bsaId);
};

export const queryDataPropertyList = (query: { deviceId: number }) =>
  fetch<DataProperty[]>(`/api/data-property/list?${qs.stringify(query, { indices: false })}`);
