import { isNil } from 'lodash-es';
import {
  BIT_MODE,
  handleModeFormatter1,
  handleModeFormatter2,
  handleModeFormatter3,
  LENGTH,
  OBJECT_TYPE,
  valueTypeFormatter1,
  valueTypeFormatter2,
  valueTypeFormatter3,
  valueTypeFormatter4,
} from '../../../../../api/type';

export const getRange = (min: number | undefined, max: number | undefined) => {
  if (!isNil(min) && !isNil(max)) {
    return `${min} ~ ${max}`;
  } else if (!isNil(min) && isNil(max)) {
    return `${min} ~`;
  } else if (isNil(min) && !isNil(max)) {
    return `~ ${max}`;
  } else {
    return '--';
  }
};

export function getHandleModeMapping(objectType?: OBJECT_TYPE, length?: LENGTH) {
  let newHandleMode;
  if (objectType === OBJECT_TYPE.SAVEREGISTER || objectType === OBJECT_TYPE.INPUTREGISTER) {
    if (length === LENGTH.ONE) {
      newHandleMode = handleModeFormatter1;
    } else if (length === LENGTH.TWO) {
      newHandleMode = handleModeFormatter2;
    } else if (length === LENGTH.FOUR) {
      newHandleMode = handleModeFormatter3;
    }
  }
  return newHandleMode;
}

export function getValueTypeMapping(objectType: OBJECT_TYPE, length: LENGTH, bitMode?: BIT_MODE) {
  let newValueType;
  if (objectType === OBJECT_TYPE.COILSTATUS || objectType === OBJECT_TYPE.INPUTSTATUS) {
    newValueType = valueTypeFormatter1;
  } else if (objectType === OBJECT_TYPE.SAVEREGISTER || objectType === OBJECT_TYPE.INPUTREGISTER) {
    if (length === LENGTH.ONE) {
      if (bitMode === BIT_MODE.NULL || bitMode === BIT_MODE.BYTE) {
        // 无或者按字节
        newValueType = valueTypeFormatter2;
      } else if (bitMode === BIT_MODE.BIT) {
        // 按位
        newValueType = valueTypeFormatter1;
      }
    } else if (length === LENGTH.TWO) {
      newValueType = valueTypeFormatter3;
    } else if (length === LENGTH.FOUR) {
      newValueType = valueTypeFormatter4;
    }
  }
  return newValueType;
}

export const checkNum = (_: unknown, value: string) => {
  if (value) {
    const num = Number(value);
    if (isNaN(num)) {
      return Promise.reject(new Error('请输入数字'));
    }
    if (num > 1e9 - 1 || num < -(1e9 - 1)) {
      return Promise.reject(new Error('请输入-999999999~999999999的数字'));
    } else if (((num + '').split('.')[1] ?? []).length > 7) {
      return Promise.reject(new Error('最多输入7位小数'));
    }
  }
  return Promise.resolve();
};

export function kOrbDisabled(objectType: OBJECT_TYPE) {
  const disabled = !(objectType === OBJECT_TYPE.SAVEREGISTER || objectType === OBJECT_TYPE.INPUTREGISTER);
  return disabled;
}
