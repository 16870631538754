import { fetch } from '@maxtropy/components';
import qs from 'qs';

interface DistributionCabinetListReq {
  ouId?: number;
}

export interface DistributionCabinetProps {
  id: number;
  name: string;
  ouId: number;
}

export const getDistributionCabinetList = (query?: DistributionCabinetListReq) =>
  fetch<DistributionCabinetProps[]>(`/api/distribution-cabinet/list?${qs.stringify(query, { indices: false })}`);
