import { DeviceData, PointValue } from '@/api/device';
import { isNil } from 'lodash-es';

// 校验图非空
export const notEmpty = (arr: PointValue[]) => arr.filter(item => !isNil(item.value)).length > 0;

export const notEmptyForChart = (arr: DeviceData[]) => arr.some(i => i.deviceId && i.propertyId && notEmpty(i.values));

export const notEmptyForChart1 = (arr: number[]) => arr.some(i => i);

export function flexible(proportionalLayoutEl: any) {
  function setScale() {
    if (document.documentElement.clientWidth > 1920) {
      let deviceWidth = document.documentElement.clientWidth; //获取当前分辨率下的可是区域宽度
      let scale = deviceWidth / 1920; // 分母——设计稿的尺寸
      // @ts-ignore
      proportionalLayoutEl.style.zoom = scale; //放大缩小相应倍数
      proportionalLayoutEl.parentNode.style.background = 'var(--component-background)';
      // proportionalLayoutEl.style.height = '100%';
    }
  }

  setScale();

  // reset rem unit on page resize
  window.addEventListener('resize', setScale);

  return function unsubscribe() {
    window.removeEventListener('resize', setScale);
  };
}
