import { PermissionsType } from '@/common/permissionsConst';
import StorageCharts from '@/pages/Storage';

const routes = [
  {
    path: '/dmes/energy-storage/charts',
    permission: PermissionsType.P_MONITOR_LINE_STORAGE,
    element: <StorageCharts />,
  },
];

export default routes;
