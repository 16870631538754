import { FC, useMemo, useState } from 'react';
import { Empty, Input, Key, Tree } from '@maxtropy/components';
import { SearchOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { apiV2NeoBsaTenantCustomerTreePost } from '@maxtropy/dmes-apis-v2';
import { DataNode } from 'antd/es/tree';
import { Spin } from 'antd';
import tenantIcon from '@/assets/images/bsa/tenant.svg';
import orgIcon from '@/assets/images/bsa/org.svg';
import bsaIcon from '@/assets/images/bsa/bsa.svg';
import { BsaType } from '../../const';
import styles from './index.module.scss';

interface Props {
  onSelect?: (bsaId: number, bsaType: BsaType) => void;
  defaultBsaId?: number;
  defaultBsaType?: BsaType;
}

const BsaTree: FC<Props> = props => {
  const { onSelect, defaultBsaId, defaultBsaType } = props;

  const [searchText, setSearchText] = useState('');
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);

  const { data: treeData, loading } = useRequest(async () => {
    const res = await apiV2NeoBsaTenantCustomerTreePost({});
    const list = res.list ?? [];
    const expandedKeys: string[] = [];
    let firstBsa: any;
    const treeData: DataNode[] = list.map(tenantItem => {
      const level1Key = 'L1-' + tenantItem.tenantMcid!;
      expandedKeys.push(level1Key);
      return {
        key: level1Key,
        title: tenantItem.tenantName,
        icon: <img src={tenantIcon} alt="" />,
        selectable: false,
        children: (tenantItem.bsaCustomerTree ?? []).map(customerItem => {
          const level2key = 'L2-' + customerItem.mcid!;
          expandedKeys.push(level2key);
          return {
            key: level2key,
            title: customerItem.customerName,
            icon: <img src={orgIcon} alt="" />,
            selectable: false,
            children: (customerItem.bsaTree ?? []).map(bsaItem => {
              if (!firstBsa) firstBsa = bsaItem;
              return {
                key: (bsaItem.bsaType === BsaType.NEW ? 'new-' : 'old-') + bsaItem.bsaId!,
                title: bsaItem.bsaName,
                icon: <img src={bsaIcon} alt="" />,
                info: bsaItem,
              };
            }),
          };
        }),
      };
    });
    setExpandedKeys(expandedKeys);
    // 传了defaultBsaId，则默认选中
    if (defaultBsaId) {
      setSelectedKeys([(defaultBsaType === BsaType.NEW ? 'new-' : 'old-') + defaultBsaId]);
    }
    // 默认选中第一个储能站
    if (!defaultBsaId && firstBsa) {
      setSelectedKeys([(firstBsa.bsaType === BsaType.NEW ? 'new-' : 'old-') + firstBsa.bsaId]);
      onSelect?.(firstBsa.bsaId, firstBsa.bsaType);
    }
    return treeData;
  });

  const filteredTreeData = useMemo(() => {
    return treeData?.map(tenantItem => {
      return {
        ...tenantItem,
        children: tenantItem.children?.map(customerItem => {
          return {
            ...customerItem,
            children: customerItem.children?.filter((bsaItem: any) => {
              return bsaItem.info.bsaName.includes(searchText);
            }),
          };
        }),
      };
    });
  }, [searchText, treeData]);

  return (
    <div className={styles.wrap}>
      <Input
        className={styles.searchInput}
        placeholder="请输入储能站名称"
        suffix={<SearchOutlined />}
        value={searchText}
        onChange={e => {
          setSearchText(e.target.value);
        }}
      ></Input>

      <div className={styles.divider}></div>

      <Spin spinning={loading}>
        {treeData?.length ? (
          <Tree
            className={styles.tree}
            showIcon
            expandedKeys={expandedKeys}
            onExpand={setExpandedKeys}
            selectedKeys={selectedKeys}
            onSelect={(selectedKeys, e: any) => {
              if (!e.selected) return;
              setSelectedKeys(selectedKeys);
              onSelect?.(e.node.info.bsaId, e.node.info.bsaType);
            }}
            treeData={filteredTreeData}
          ></Tree>
        ) : (
          <Empty></Empty>
        )}
      </Spin>
    </div>
  );
};

export default BsaTree;
