import { Key } from 'react';

export enum StrategyType {
  PEAK_SHAVING = 1,
}

export enum ProtectType {
  DOD = 1,
  MONOMER_VOLTAGE,
  LOWER_LEVEL,
}

export const PROTECT_TYPE_MAP = {
  [ProtectType.DOD]: 'DOD',
  [ProtectType.MONOMER_VOLTAGE]: '单体电压',
  [ProtectType.LOWER_LEVEL]: '由下级BMS系统调控',
};

export const TIME_PERIOD_UNIT = 0.5;
export const TIME_PERIOD_TOTAL = 24;

export enum TimePeriodDimension {
  HOUR = 1,
  HALF_HOUR,
}

export const TIME_PERIOD_DIMENSION_MAP = {
  [TimePeriodDimension.HOUR]: '整点',
  [TimePeriodDimension.HALF_HOUR]: '半点',
};

export enum PowerType {
  PERCENTAGE = 1,
  CONCRETE_NUMBER,
}

export const POWER_TYPE_MAP = {
  [PowerType.PERCENTAGE]: '按具体PCS额定功率%',
  [PowerType.CONCRETE_NUMBER]: '按具体数值',
};

export enum LoadProtectType {
  PERCENTAGE = 1,
  CONCRETE_NUMBER,
}

export const LOAD_PROTECT_TYPE_MAP = {
  [LoadProtectType.PERCENTAGE]: '按变压器容量%',
  [LoadProtectType.CONCRETE_NUMBER]: '按具体数值',
};

export enum ChargeType {
  CHARGE = 1,
  DISCHARGE,
}

export enum DischargeStrategy {
  REACH_THRESHOLD = 1,
  IMMEDIATELY,
  BY_DEMAND,
  BY_LOAD_RATE,
}

export const DISCHARGE_STRATEGY_MAP = {
  [DischargeStrategy.REACH_THRESHOLD]: '负载达到阈值放电',
  [DischargeStrategy.IMMEDIATELY]: '时间到立即放电',
  [DischargeStrategy.BY_DEMAND]: '需量控制模式',
  [DischargeStrategy.BY_LOAD_RATE]: '负载率控制模式',
};

export enum EmptyStrategy {
  DEFINITE_TIME = 1,
  LINEAR_REGRESSION,
}

export const EMPTY_STRATEGY_MAP = {
  [EmptyStrategy.DEFINITE_TIME]: '定时清空',
  [EmptyStrategy.LINEAR_REGRESSION]: '线性回归清空',
};

export interface StrategySection {
  type: ChargeType;
  beginTime: number;
  endTime: number;
  powerPercent?: number;
  power?: number;
  chargingLoadProtect: boolean;
  dischargeStrategy?: DischargeStrategy;
  transformerCapacity?: number;
  recoverTransformerCapacity?: number;
  loadThreshold?: number;
  recoverLoadThreshold?: number;
  emptyStrategy: EmptyStrategy;
  emptyTime?: string;
  chargingLoadProtectType?: LoadProtectType;
  targetLoadRate?: number;
  targetDemand?: number;
}

export interface StrategySectionFormData extends Omit<StrategySection, 'beginTime' | 'endTime' | 'powerPercent'> {
  time?: [number | undefined, number | undefined];
}

export interface StrategyFormData {
  type: number;
  name: string;
  protectType: ProtectType;
  dod?: [number | undefined, number | undefined];
  cellVoltageMax?: number;
  cellVoltageMin?: number;
  standbyPower: boolean;
  timeGranularity: TimePeriodDimension;
  powerType: PowerType;
  sections: StrategySectionFormData[];
}

export enum StrategyTemplateStatus {
  DISABLED,
  ENABLED,
  INVALID,
}

export namespace StrategyTemplateStatus {
  export const MAP = {
    [StrategyTemplateStatus.DISABLED]: '停用',
    [StrategyTemplateStatus.ENABLED]: '启用',
    [StrategyTemplateStatus.INVALID]: '作废',
  };
}

export interface StrategyTemplate {
  id: number;
  status: StrategyTemplateStatus;
  name: string;
  sysVersionId: Key;
  sysVersionName: string;
  bsaName: string;
  lastUpdateUser: string;
  updateTime: string;
  code: string;
  bsaId: number;
}

export enum StrategyRunningStatus {
  OUT_OF_SERVICE = 0,
  PUT_INTO_OPERATION = 1,
}

export namespace StrategyRunningStatus {
  export const MAP = {
    [StrategyRunningStatus.OUT_OF_SERVICE]: '停运',
    [StrategyRunningStatus.PUT_INTO_OPERATION]: '投运',
  };
}

export interface FieldStrategy extends Omit<StrategyTemplate, 'status'> {
  executiveTime: string;
  globalVersion: number;
  executiveTimeFrom: string;
  executiveTimeTo: string;
  status: StrategyRunningStatus;
  version: number;
  templateId: number;
  templateCode: string;
}
