import { FC, ReactNode } from 'react';
import styles from './LoadingModal.module.scss';
import loadingImg from './loading.png';

interface Props {
  loading: boolean;
  content: ReactNode;
}

const LoadingModal: FC<Props> = props => {
  const { loading, content } = props;

  if (!loading) return <></>;

  return (
    <div className={styles.wrap}>
      <div className={styles.contentWrap}>
        <div className={styles.topLine}></div>
        <img src={loadingImg} className={styles.loadingImg} />
        <div>{content}</div>
      </div>
    </div>
  );
};

export default LoadingModal;
