import { Graph, Node } from '@antv/x6';
import { apiV2PvOutlineAreaStringListPost, apiV2PvOutlinePvDeviceTreePost } from '@maxtropy/dmes-apis-v2';
import { useRequest } from 'ahooks';
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from 'react';
import { PvInfoContext } from '..';
import { NodeTreeData } from '../const';

import styles from '../index.module.scss';
import LoadingModal from '../Loading/LoadingModal';
import { apiData, areaListApiData } from '../mock';
import '../Nodes/registerNodes';
import { changeOutEdges, initTreeLayout, useInitGraph } from '../utils';
interface Iprops {
  getCurrentNodeInfo?: (info?: NodeTreeData) => void;
}
export interface DrawBoxRef {
  graph?: Graph;
}
const DrawBox = forwardRef<DrawBoxRef, Iprops>((props, ref) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { currentPvId } = useContext(PvInfoContext);

  const graph = useInitGraph(containerRef.current);

  const { data: allTreeDatas, loading: canvasLoading } = useRequest(
    () => {
      return Promise.all([
        apiV2PvOutlinePvDeviceTreePost({ id: currentPvId }).then(res => res.list),
        apiV2PvOutlineAreaStringListPost({ id: currentPvId }).then(res => res.list),
      ]);
    },
    { ready: !!currentPvId, refreshDeps: [currentPvId] }
  );
  useImperativeHandle(ref, () => ({
    graph,
  }));
  useEffect(() => {
    if (graph) {
      graph.clearCells();
      const [treeData, areaData] = allTreeDatas ?? [];
      // initTreeLayout(graph, apiData, areaListApiData);
      initTreeLayout(graph, treeData, areaData);
    }
  }, [allTreeDatas]);

  useEffect(() => {
    graph?.on('blank:click', ({ e, x, y }) => {
      props?.getCurrentNodeInfo?.(undefined);
      changeOutEdges(graph);
    });
    graph?.on('node:collapse', ({ node }: { node: Node }) => {
      const data = node?.getData<NodeTreeData>();
      node.updateData({
        isCollapsed: !data.isCollapsed,
      });

      const run = (pre: Node) => {
        const succ = graph.getSuccessors(pre, { distance: 1 });

        if (succ) {
          succ.forEach(node => {
            node.toggleVisible(!!data.isCollapsed);
            if (!node?.getData().isCollapsed) {
              run(node as any);
            }
          });
        }
      };
      run(node);
    });
  }, [graph]);
  return (
    <>
      {canvasLoading && <LoadingModal loading={canvasLoading} content={'画布加载中'} />}
      <div className={styles.graph_box} ref={containerRef}></div>;
    </>
  );
});

export default DrawBox;
