import { Button } from '@maxtropy/components';
import { Progress } from 'antd';
import { FC } from 'react';
import styles from './index.module.scss';

export interface ICurrentMonthMaxMinPoint {
  title: string;
  name: string;
  id?: number;
  percent?: number;
  strokeColor?: string;
}

const CurrentMonthMaxMinPoint: FC<ICurrentMonthMaxMinPoint> = props => {
  const { title, name, percent, strokeColor, id } = props;
  return (
    <div className={styles.currentMonthMaxMinPointSty}>
      <div className={styles.percentTitleAndName}>
        <div>{title}</div>
        <div>
          <Button
            style={{ padding: 0, height: 'unset' }}
            type="link"
            onClick={() => window.open(`${window.BSSANALYSIS}/#/energy/analysis?bsaId=${id}`, '_blank')}
          >
            {name}
          </Button>
        </div>
      </div>
      <div>
        <Progress
          format={percent => (percent === 100 ? '100%' : `${percent}%`)}
          percent={percent}
          size="small"
          strokeColor={strokeColor ?? '#51D9EE'}
          trailColor={'#FFFFFF20'}
        />
      </div>
    </div>
  );
};

export default CurrentMonthMaxMinPoint;
