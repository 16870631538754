import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { CircuitType } from './circuit';
import { Ou } from './ou';
import { PageRequest, PageResponse } from './page';

export enum EPriceScope {
  // 电网计费电价
  POWER_GRID = 1,
  // 出线电价
  OUTLET,
}

export enum EPriceChargeType {
  // 单一制
  SINGLE = 1,
  // 两部制
  DOUBLE,
}

export enum EPriceTimingType {
  // 分时
  TIMING = 1,
  // 不分时
  NO_TIMING,
}

export enum EPricePeakType {
  // 尖
  SUMMIT = 1,
  // 峰
  PEAK,
  // 平
  PLAIN,
  // 谷
  VALLEY,
}

export const E_PRICE_SCOPE_MAP = {
  [EPriceScope.POWER_GRID]: '电网计费电价',
  [EPriceScope.OUTLET]: '出线电价',
};

export const E_PRICE_CHARGE_TYPE_MAP = {
  [EPriceChargeType.SINGLE]: '单一制',
  [EPriceChargeType.DOUBLE]: '两部制',
};

export const E_PRICE_TIMING_TYPE_MAP = {
  [EPriceTimingType.TIMING]: '分时',
  [EPriceTimingType.NO_TIMING]: '不分时',
};

export const E_PRICE_PEAK_TYPE_MAP = {
  [EPricePeakType.SUMMIT]: '尖峰',
  [EPricePeakType.PEAK]: '高峰',
  [EPricePeakType.PLAIN]: '平段',
  [EPricePeakType.VALLEY]: '低谷',
};

export const E_PRICE_PEAK_TYPE_COLORS_MAP = {
  [EPricePeakType.SUMMIT]: '#FF6D2C',
  [EPricePeakType.PEAK]: '#CE90D1',
  [EPricePeakType.PLAIN]: '#0DB6D9',
  [EPricePeakType.VALLEY]: '#63BC7F',
};

// 电量尖峰平谷加上没有配置尖峰平谷的电量
export enum EPricePeakTypeEP {
  // 尖
  SUMMIT = 1,
  // 峰
  PEAK,
  // 平
  PLAIN,
  // 谷
  VALLEY,
  // 无
  NONE,
}
// 电量尖峰平谷加上没有配置尖峰平谷的电量
export const E_PRICE_PEAK_TYPE_LABEL_MAP_EP = {
  [EPricePeakTypeEP.SUMMIT]: '尖电量',
  [EPricePeakTypeEP.PEAK]: '峰电量',
  [EPricePeakTypeEP.PLAIN]: '平电量',
  [EPricePeakTypeEP.VALLEY]: '谷电量',
  [EPricePeakTypeEP.NONE]: '电量（未配置尖峰平谷）',
};
// 电量尖峰平谷加上没有配置尖峰平谷的电量
export const E_PRICE_PEAK_TYPE_COLORS_MAP_EP = {
  [EPricePeakTypeEP.SUMMIT]: '#FF6D2C',
  [EPricePeakTypeEP.PEAK]: '#CE90D1',
  [EPricePeakTypeEP.PLAIN]: '#0DB6D9',
  [EPricePeakTypeEP.VALLEY]: '#63BC7F',
  [EPricePeakTypeEP.NONE]: '#1890FF',
};

export interface EPricePart {
  summitEnergyRate?: number | null;
  peakEnergyRate?: number | null;
  plainEnergyRate?: number | null;
  valleyEnergyRate?: number | null;
  months: number[];
  ePriceTimes: EPriceTime[];
}

export interface EPrice {
  id: number;
  name: string;
  ouId: number;
  code: string;
  ou?: Ou;
  scopeType: EPriceScope;
  chargeType: EPriceChargeType;
  timingType: EPriceTimingType;
  demandRate?: number;
  capacityRate?: number;
  fixedEnergyRate?: number;
  ePriceParts: EPricePart[];
  isPrivate: boolean;
}

export interface EPriceRecord {
  isAdmin: boolean;
  id: number;
  code: string;
  name: string;
  scopeType: EPriceScope;
  ou: Ou;
  usageAmount: number;
  updateTime: string;
  updateByUsername: string;
}

export interface EPriceTime {
  type: EPricePeakType;
  intervalStart: number;
  intervalEnd: number;
}

export interface EPriceQueryParams {
  ouId?: number;
  codeOrName?: string;
}

export const fetchEPricePage = (params: EPriceQueryParams & PageRequest) =>
  fetch<PageResponse<EPriceRecord>>(`/api/e-price/page?${qs.stringify(params, { indices: false })}`);

export const updateEPrice = (id: number, data: Omit<EPrice, 'id'>) =>
  fetch<void>(`/api/e-price/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
  });

export const createEPrice = (data: Omit<EPrice, 'id'>) =>
  fetch<void>(`/api/e-price`, {
    method: 'POST',
    body: JSON.stringify(data),
  });

export const removeEPrice = (id: number) => fetch<void>(`/api/e-price/${id}`, { method: 'DELETE' });

export const fetchEPrice = (id: number) => fetch<EPrice>(`/api/e-price/${id}`);

interface EPriceListReq {
  type: CircuitType;
  ouId: number;
}

export interface EPriceListProps {
  code: string;
  id: number;
  isPrivate: boolean;
  name: string;
  ouId: number;
}

export const getEPriceList = (query: EPriceListReq) =>
  fetch<EPriceListProps[]>(`/api/e-price/list?${qs.stringify(query, { indices: false })}`);
