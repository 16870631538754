import { FC, useEffect, useMemo, useState } from 'react';
import { Form, InputNumber, Modal, ShowInput } from '@maxtropy/components';
import { DataItem } from './index';
import { Col, Row } from 'antd';
import { monthList } from './const';
import { apiV2PvPerformanceConfigSetUpPost } from '@maxtropy/dmes-apis-v2';
import { customFixed } from '@/pages/PvPerformance/const';
import { isNil } from 'lodash-es';

const getMonthFormItems = () => {
  return monthList.map(item => {
    return (
      <Col span={12} key={item.month}>
        <Form.Item
          label={item.monthName + '月'}
          name={['month', item.month]}
          rules={[
            {
              validator: (rule, value) => {
                if (value === 0) {
                  return Promise.reject('请输入大于0的数字');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber addonAfter="kWh" precision={2} placeholder="请输入" min={0} />
        </Form.Item>
      </Col>
    );
  });
};

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  year: number | string;
  data: DataItem | undefined;
  onOk: () => void;
}

const ConfigModal: FC<Props> = props => {
  const { open, setOpen, year, data, onOk } = props;
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const formMonth = Form.useWatch('month', form);

  useEffect(() => {
    if (!open || !data) {
      form.resetFields();
      return;
    }
    const monthValueObj: Record<number, number | undefined> = {};
    monthList.forEach(item => {
      monthValueObj[item.month] = data?.configValueList?.find(config => config.month === item.month)?.value;
    });
    form.setFieldsValue({ ...data, month: monthValueObj });
  }, [data, open]);

  const yearPlanPower = useMemo(() => {
    let total = 0;
    formMonth?.forEach((value: number | undefined) => {
      total += value || 0;
    });
    return Number(total.toFixed(2));
  }, [formMonth]);

  const onConfirm = () => {
    form.validateFields().then(values => {
      const configValueList = (values.month as (number | undefined)[])
        .map((value, index) => {
          return {
            month: index,
            value,
          };
        })
        .filter(item => !isNil(item.value));
      setLoading(true);
      apiV2PvPerformanceConfigSetUpPost({
        pvId: data?.pvId,
        performanceId: data?.performanceId,
        configValueList,
        year: year,
      })
        .then(() => {
          onOk();
          setOpen(false);
        })
        .finally(() => setLoading(false));
    });
  };

  return (
    <Modal
      title="配置计划"
      contentClassName="modal-form-content"
      open={open}
      confirmLoading={loading}
      onCancel={() => {
        setOpen(false);
      }}
      onOk={onConfirm}
    >
      <Form form={form} isOrigin>
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item label="光伏站名称" name="pvName">
              <ShowInput />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="装机容量">
              <ShowInput value={customFixed(data?.capacity)} addonAfter="kW" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="年度">{year}</Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="年度计划发电量">
              <ShowInput
                style={{ color: 'var(--warning-color)' }}
                value={customFixed(yearPlanPower)}
                addonAfter="kWh"
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="月度计划发电量" colon={false} style={{ marginBottom: 16 }}></Form.Item>
        <Row gutter={48}>{getMonthFormItems()}</Row>
      </Form>
    </Modal>
  );
};

export default ConfigModal;
