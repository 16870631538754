import React from 'react';
import { Row } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

type FormItemWrapperProps = any;

const FormItemWrapper: React.FC<FormItemWrapperProps> = props => {
  const { extra, children, ...resetProps } = props;

  const childProps = {
    ...children.props,
    ...resetProps,
  };

  ['onChange'].forEach(eventName => {
    childProps[eventName] = (...args: any[]) => {
      resetProps[eventName]?.(...args);
      children.props[eventName]?.(...args);
    };
  });

  return (
    <Row align="middle">
      {children && React.cloneElement(children, childProps)}
      {extra && (
        <div className={styles.extra}>
          <InfoCircleOutlined style={{ color: 'var(--warning-color)', marginRight: 4 }}></InfoCircleOutlined>
          <span>{extra}</span>
        </div>
      )}
    </Row>
  );
};

export default FormItemWrapper;
